import React, { Fragment, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import styles from './instruction.module.css'
import {exclamatoryTable, retry} from './assets'

const TableError=({fetchBrokerDetails})=>{
    const [fetching, setFetching] = useState(false);
    const [counter, setCounter] = useState(0)
    const onTryAgain= async ()=>{
        if(counter<3){
            if(!fetching){
                setFetching(true)
                await fetchBrokerDetails()
                setFetching(false)
                setCounter(counter+1);
            }            
        }      
    }
    return (
        <div className='d-flex flex-column align-items-center p-4' style={{fontWeight:600}}>
            <img src={exclamatoryTable} style={{width:'40px'}}/>
            <span className='mt-2'>{counter<3?'Unable To Fetch Data':'Something Went Wrong'}</span>
            <span className='mt-2' style={{color:'#0061D3', cursor:'pointer'}}>
                {counter<3? 
                    <Fragment>
                        {fetching ? 
                            <Spinner animation="border" className={styles.spinnerColor}  style={{width:'16px',height:'16px'}}/>
                            : <img src={retry} />
                        }
                        <span style={{fontSize:'12px',paddingLeft:'6px',opacity:fetching ? 0.6:1}} className={styles.link} onClick={onTryAgain}>Try Again</span>
                    </Fragment>
                    :<span className={styles.link} onClick={()=>window.fcWidget.open()}>Get Help</span>
                }                
            </span>
        </div>
    )
}
export default TableError