import React from 'react'
import styles from './StockDetail.module.css'
import PropTypes from 'prop-types'

const stockDetailBox = ({ icon, value, width }) => {
  // highlightsData.ratings.percentage
	return (
		<div className={styles.detailBox}>
			<img src={icon} alt='icon'></img>
			<p className={styles.boxValue}>{value}</p>
		</div>
	)
}

stockDetailBox.proptype = {
	icon: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	width: PropTypes.string.isRequired
}

export default stockDetailBox
