import React,{useEffect, useState} from 'react'
import styles from '../OtherCountryAccount/otherCountryAccount.module.css'
import stylesheet from './Fairexpay.module.css'
import ProgressBar from './ProgessBar'
import { Row, Col,OverlayTrigger,Tooltip } from 'react-bootstrap';

import { fairexpayMakePayment, reviewKYC } from '../../api'



const ReviewDetail = ({
	setcurrent,
  next,
	buttonColor,
  initiateCapitalTransaction,
  hashId,
  initiateQuote
}) => {
  const [loading,setLoading] = useState(true)
  const [errormsg,seterrormsg] = useState('')
  const [termsandcondition,settermsandcondition] = useState(false)


  const asyncFetch = async () => {
    console.log('hi');
    try {
      const res = await reviewKYC(hashId)
      console.log(res);
    } catch (e) {

    }
  };


  useEffect(() => {
    if(Object.keys(initiateCapitalTransaction).length > 0) {
      setLoading(false)
    }
  },[initiateCapitalTransaction])

  const {quoteDetail = {} ,partnerData=[],amount = ''} = initiateQuote
  const partner = partnerData.filter((item) => item.partnerid === 20)

  const info = [
    {'Bank Curreny Exchange Rate' : partner && partner.length  ? partner[0].reverseExchangeRate.toFixed(2) : '--' },
		{
			'Source Amount': `USD ${initiateCapitalTransaction.toAmount ? initiateCapitalTransaction.toAmount : '--'}`
		},
		// {
		// 	'Taxes': initiateCapitalTransaction.bankCharge + initiateCapitalTransaction.handleCharge + initiateCapitalTransaction.gst
		// },
    {
			'TCS': initiateCapitalTransaction.tcs ? initiateCapitalTransaction.tcs : '--'
		},
    {
			'GST': initiateCapitalTransaction.gst ? initiateCapitalTransaction.gst : '--'
		},
    {
			'Bank Charges': initiateCapitalTransaction.bankCharge ? initiateCapitalTransaction.bankCharge : '--'
		},
    {
			'Handling Charges': initiateCapitalTransaction.handleCharge ? initiateCapitalTransaction.handleCharge : '--'
		},
    {
			'Total Amount': `INR ${initiateCapitalTransaction.finalFromAmount  ? initiateCapitalTransaction.finalFromAmount.toFixed(2) :'--'}`
		},

	]


  const onClickHanlder = async () => {
   setLoading(true)
   try{
    //  const data = {conditionsAgreed:termsandcondition}
     const response = await fairexpayMakePayment(hashId)
     if(response.code !== 200){
      setLoading(false)
     }
   }
   catch(error){
    setLoading(false)
     seterrormsg('Something went wrong')
   }
  }


	return (
		<div className={stylesheet.A2UploadCard}>
        {/* <Row className='d-block d-md-none'>
				<Col md={12}>
					<div
						className={stylesheet.A2UploadTitle}
						style={{ cursor: 'pointer' }}
            >
						<span> Digitally Sign A2 form</span>
					</div>
				</Col>
			</Row> */}
      <Row className={stylesheet.borderBottom}>
				<Col md={10} className='pr-0'>
					<ProgressBar steps='5' current={3} color='rgb(0, 93, 214)' status='Transfer funds'/>
				</Col>
			</Row>
				<Col style={{ marginTop: '20px' }} className={`pl-0 ${stylesheet.reviewMobile}`}>
					<div className={stylesheet.review}>Review</div>
				</Col>
    	<Row style={{ marginTop: '10px',marginBottom:'150px'}}>
				<Col md={9} className={styles.otherDiv}>
					{info.map((trav, i) => {
						return (
							<Row
								key={i}
								style={{
									marginTop: '16px',
									paddingBottom: '16px',
									borderBottom: '1px solid #EFEFF1'
								}}>
								<Col style={{ padding: '0px' }} >
									<div className={styles.key} style={{display:'contents'}}>{Object.keys(trav)[0]}</div>
                  {Object.keys(trav)[0] === 'TCS' || Object.keys(trav)[0] === 'Bank Curreny Exchange Rate' ?
                  <OverlayTrigger
                    placement='bottom'
                    trigger={['hover', 'focus','click']}
                    overlay={
                      <Tooltip>
                        {Object.keys(trav)[0] === 'TCS' ?
                         'Please note that 5% TCS will be deducted if you have already processed 7 lakhs in this Financial Year as per Income Tax Act guidelines. This can be claimed/ adjusted against Tax liability while filing it' :
                       'There might be some variation in currency exchange rates depending on the interbank exchange rates variation'}
                      </Tooltip>
                    }>
                    <span
                  className="fa fa-info-circle pl-1 pt-1"
                  style={{cursor: "pointer",position:'absolute'}}></span>
                </OverlayTrigger> : ''}
								</Col>
								<Col style={{ textAlign: 'end', padding: '0px' }}>
									<div className={styles.value}>{Object.values(trav)[0]}</div>
								</Col>
							</Row>
						)
					})}
				</Col>
			</Row>
      {errormsg === '' ? '' : <div className={styles.erorrMessage}>{errormsg}</div>}

			<Row className={styles.fixedDiv}>
				<Col>
					<button
						onClick={onClickHanlder}
						type='button'
            disabled={loading}
						style={{ backgroundColor: buttonColor }}
						className={`btn btn-primary btn-sm ${styles.addfunds} themeButton`}>
						<span className={styles.addfundstext}>
						   Make Payment
						</span>
					</button>
				</Col>
			</Row>
		</div>
	)
}

export default ReviewDetail
