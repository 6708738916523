import React, { useState, useEffect} from 'react'
import { getTransactionDetails } from '../../api'
import { addCommasinPrice } from '../../utils'
import Copy from '../../components/AddFund/Transfer/Online/copy'
import CashMgmtToolTip from './Tooltip'
import styles from './TransactionDetailsModal.module.css'
import { EmailShareButton } from 'react-share'
import modalCrossButton from '../../public/images/modalCrossButton.svg'
import fraudIcon from '../../public/images/fraudFlag.svg'
import shareIcon from '../../public/images/shareIcon.svg'
import moment from 'moment';

const TransactionDetailsModal = (props) => {

    const { stoTranId , onHide } = props

    const [transactionDetails, setTransactionDetails] = useState()

    const maskAccountNumber = (accountNumber) => {
        return accountNumber.replace(/\d(?=\d{4})/g, "X")
    }

    const userAccountNumber = () => {
        if(transactionDetails) {
            if(transactionDetails.stoCashAccount && transactionDetails.stoCashAccount.accountNum){
                return maskAccountNumber(transactionDetails.stoCashAccount.accountNum)
            }
        }
        else {
            return '--'
        }
    }

    const payeeAccountNumber = () => {
        if(transactionDetails) {
            if(transactionDetails.contact && transactionDetails.contact.payeeAccountNum){
                return maskAccountNumber(transactionDetails.contact.payeeAccountNum)
            }
        }
        else {
            return '--'
        }
    }

    const findTransactionDetails = () => {
        getTransactionDetails(stoTranId).then(response => {
            if(response.code === 200){
                setTransactionDetails(response.data ? response.data : '')
            }
        }).catch((err)=>{
            console.error(err)
        })
    }

    const date = new Date(transactionDetails ? transactionDetails.tranDt : null)

    const getModalDate = () => {
        if(date) {
            const newDate = date.toString('YYYY-MM-dd').split(' ')

            const finalDate = `${newDate[2]} ${newDate[1]} ${newDate[3] ? newDate[3].slice(-2) : null}`
            const finalTime = `${newDate[4] ? moment(date).format('h:mm A z') : null}`

            const ModalDate = `${finalDate} , ${finalTime} IST`

            return ModalDate
        }
    }

    useEffect(()=>{
        findTransactionDetails()
    },[])


    return (
        <div className={styles.modalContainer} style={{minHeight:"587.5px"}}>
            {transactionDetails ? (
            <div>
                <div className={styles.modalHeader} style={{backgroundColor: transactionDetails ? transactionDetails.tranStatus === "settled" ? "#0DC014" :  transactionDetails.tranStatus === "pending" || transactionDetails.tranStatus === "completed" ? "#FA5D05" : transactionDetails.tranStatus === "declined" ? "#FA274A" : "" : ""}}>
                    <div className={styles.transactionStatus}>
                        <p className={`${styles.status} ${styles.textWhite}`}>{`Transaction ${ transactionDetails ? transactionDetails.tranStatus === "settled" ? "Successful" :  transactionDetails.tranStatus === "pending" || transactionDetails.tranStatus === "completed" ? "Pending" : transactionDetails.tranStatus === "declined" ? "Declined" : "" : ""}`}</p>
                        <p className={`${styles.modalText} ${styles.textWhite}`}>{transactionDetails && (transactionDetails.tranStatus === 'pending' || transactionDetails.tranStatus === 'completed') ? 'This may take upto 3 working days.' : getModalDate()}</p>
                    </div>
                    <img src={modalCrossButton} alt="cross-button" onClick={onHide}></img>
                </div>
                <div className={styles.transactionInfo}>
                    <p className={`${styles.modalText} ${styles.textGrey}`}>{transactionDetails ? transactionDetails.tranDesc : ""}</p>
                    { transactionDetails && transactionDetails.tranStatus === "settled"  ? (
                        <p className={`${styles.amount} ${styles.textBlack}`}><span>{transactionDetails && transactionDetails.tranType === "debit" ? '-' : '+'}</span>{transactionDetails ? `$${addCommasinPrice(parseFloat(transactionDetails.tranAmt))}` : ""}</p>
                    ) : (
                        <p className={`${styles.amount} ${styles.textGrey}`}>{transactionDetails ? `$${addCommasinPrice(parseFloat(transactionDetails.tranAmt))}` : ""}</p>
                    )}

                    {transactionDetails && transactionDetails.tranRemarks !== null ?
                        (<div className={styles.transactionPurpose}>
                            <p className={`${styles.modalText} ${styles.textGrey} ${styles.textCenter}`}>{transactionDetails.tranRemarks}</p>
                        </div>) : ''
                    }
                </div>
                <div className={styles.transactionDetails}>
                    <hr className={styles.divider}/>

                    <div className={styles.modalRow}>
                        <p className={` ${styles.modalText} ${styles.textGrey}`}>Transaction ID</p>
                        <div className={` ${styles.modalRow} ${styles.modalRowInfo}`}>
                            <p className={` ${styles.modalText} ${styles.textBlack}`}>{transactionDetails ? transactionDetails.stoTranId ? transactionDetails.stoTranId : '--' : '--'}</p>
                            {transactionDetails && transactionDetails.stoTranId ? <Copy isPrimaryIcon={true} data={{label:'Transaction ID', value:transactionDetails.stoTranId}}/> : ''}
                        </div>
                    </div>

                    <div className={styles.modalRow}>
                        <p className={` ${styles.modalText} ${styles.textGrey}`}>Transaction Type</p>
                        <p className={` ${styles.modalText} ${styles.textBlack}`}>
                            {transactionDetails ? transactionDetails.transferType === 'INTRABANK' ? 'Intrabank Transfer': transactionDetails.transferType === 'ACH' ? 'ACH Transfer': transactionDetails.transferType === 'DOM_WIRE' ? 'Domestic Wire Transfer': transactionDetails.transferType === 'INTL_WIRE' ? 'International Wire Transfer': transactionDetails.transferType === 'CARD' ? 'Card/Online Payment': '--' : '--'}
                        </p>
                    </div>

                    <hr className={styles.divider}/>

                    <div className={styles.modalRow}>
                        <p className={` ${styles.modalText} ${styles.textGrey}`}>Debited From</p>
                        <div className={` ${styles.modalRow} ${styles.modalRowInfo}`}>
                            <p className={` ${styles.modalText} ${styles.textBlack}`}>{transactionDetails ? transactionDetails.tranType === "debit"  ? userAccountNumber() : transactionDetails.tranType === "credit" && transactionDetails.transferType === 'CARD' ? transactionDetails.cardMerchantName.length > 15 ? `${transactionDetails.cardMerchantName.substring(0, 15)}...` : transactionDetails.cardMerchantName : transactionDetails.payeeName : '--'}</p>
                            <CashMgmtToolTip content={'The amount has been debited from this account'}/>
                        </div>
                    </div>

                    <div className={styles.modalRow}>
                        <p className={` ${styles.modalText} ${styles.textGrey}`}>Credited To</p>
                        <div className={` ${styles.modalRow} ${styles.modalRowInfo}`}>
                            <p className={` ${styles.modalText} ${styles.textBlack}`}>{transactionDetails ? transactionDetails.tranType === "debit" && transactionDetails.transferType !== 'CARD' ? payeeAccountNumber() : transactionDetails.transferType === 'CARD' ? transactionDetails.cardMerchantName.length > 15 ? `${transactionDetails.cardMerchantName.substring(0, 15)}...` : transactionDetails.cardMerchantName : userAccountNumber() : '--'}</p>
                            <CashMgmtToolTip content={'The amount has been credited to this account'}/>
                        </div>
                    </div>

                    <div className={styles.modalRow}>
                        <p className={` ${styles.modalText} ${styles.textGrey}`}>Transaction Charges</p>
                        <div className={` ${styles.modalRow} ${styles.modalRowInfo}`}>
                            <p className={` ${styles.modalText} ${styles.textBlack}`}>{transactionDetails ? transactionDetails.tranFees !== null ? `$${transactionDetails.tranFees}` : '--' : '--'}</p>
                            <CashMgmtToolTip content={'Amount of the transaction charges'}/>
                        </div>
                    </div>
                </div>
                <div className={`${styles.modalRow} ${styles.modalButtonContainer}`}>
                    <EmailShareButton
                            url=''
                            subject={'Transaction Details'}
                            body={`Find the details of your transaction with Transaction ID - ${transactionDetails.stoTranId} :

                                   Date & Time of Transaction : ${getModalDate()}
                                   ${transactionDetails.tranDesc} : $${addCommasinPrice(parseFloat(transactionDetails.tranAmt))}
                                   Transaction ID : ${transactionDetails.stoTranId}
                                   Transaction Status : ${transactionDetails.tranStatus}
                                   Transaction Type : ${transactionDetails.tranType}
                                   Debited From : ${transactionDetails.tranType === "debit"? userAccountNumber() : transactionDetails.payeeName}
                                   Credited to : ${transactionDetails.tranType === "debit"? payeeAccountNumber() : userAccountNumber()}
                                   Transaction Amount : $${addCommasinPrice(parseFloat(transactionDetails.tranAmt))}
                                   Transaction Charges : ${transactionDetails.tranFees ? `$${transactionDetails.tranFees}` : '--'}
                                   Remarks : ${transactionDetails.tranRemarks}
                                `}
                    >
                        <button className={`${styles.modalButton} ${styles.modalButtonBlue}`}>
                            <img src={shareIcon} alt="share-icon"></img>
                            <p className={styles.modalText}>Share Details</p>
                        </button>
                    </EmailShareButton>
                    <a href="mailto:customersuccess@stockal.com" target="_blank" rel="noopener noreferrer">
                        <button className={`${styles.modalButton} ${styles.modalButtonRed}`}>
                            <img src={fraudIcon} alt="support-icon"></img>
                            <p className={styles.modalText}>Report Fraud</p>
                        </button>
                    </a>
                </div>
            </div>
            ) : ''}
        </div>
    )
}

export default TransactionDetailsModal
