import React, { useState, useContext, useEffect, useRef } from 'react'
import { Row, Col, Form, Card } from 'react-bootstrap'
import { Formik } from 'formik'
import * as yup from 'yup'
import { ThemeContext } from '../../Context/ThemeContext'
import AlertError from '../AlertError/AlertError'
import { useHistory } from 'react-router-dom'
import { checkHostByPartner, userLogin, socketLogin } from '../../api'
import SignUpButton from '../SignUp/SignUpButton'
import PasswordHide from '../../public/images/newOnboarding/PasswordHide.svg'
import PasswordShow from '../../public/images/newOnboarding/PasswordShow.svg'
import Usersvg from '../../public/images/newOnboarding/User.svg'
import PasswordSvg from '../../public/images/newOnboarding/Password.svg'
import styles from './Login.module.css'
import { Link } from 'react-router-dom'
import queryString from 'query-string'
import $, { event } from 'jquery'
import NewHeader from '../NewSignupComponents/NavBar/NewHeader'
import SignUpInput from '../SignUp/SignUpInput'
// import { useDispatch } from 'react-redux'
// import { USER_INFO } from '../../store/actions/action'
// import {setCurrentPlan} from '../../common'
import OnboardingFooter from '../Footer/OnboardingFooter'
import Logo from '../../public/images/newlogo.svg'
import { partnerList } from '../../constants'
import LoginBanner from './LoginBanner'

const Login = props => {
	if (sessionStorage.getItem('currentSignUp'))
		sessionStorage.removeItem('currentSignUp')
	let history = useHistory()
	const { siteLogo, shortLogo, partnerCode, fetchTheme, partnerConfig } = useContext(ThemeContext)
	const [errormessage, setErrormessage] = useState('')
	const [username, setUsername] = useState('')
	const [userError, setUserError] = useState('')
	const [passwordType, setPasswordType] = useState('password')
	const editFieldRef = useRef(null)
	const [screenDetails, setScreenDetails] = useState(null)
	const { isPartner } = useContext(ThemeContext)
  const [loading,setLoading] = useState(false)
	const showPoweredLogo =
		isPartner && !window.location.href.includes('landing-page')

	// const dispatchAction = useDispatch()
	useEffect(() => {
		editFieldRef.current.focus()
		let queryUrl = queryString.parse(props.location.search, {
			parseNumbers: false
		})
		if (queryUrl.username) {
			setUsername(queryUrl.username)
		}
		// else
		// setUsername('')
		let deviceData = {}
		try {
			deviceData = {
				viewport: `${Math.max(
					document.documentElement.clientWidth || 0,
					window.innerWidth || 0
				)} X ${Math.max(
					document.documentElement.clientHeight || 0,
					window.innerHeight || 0
				)}`
			}
			if (window.screen) {
				deviceData.screenSize = `${window.screen.width} X ${window.screen.height}`
				if (window.devicePixelRatio)
					deviceData.resolution = `${
						window.screen.width * window.devicePixelRatio
					} X ${window.screen.height * window.devicePixelRatio}`
			}
		} catch (error) {
			console.log('Error while parsing screen details')
		}
		setScreenDetails(deviceData)
	}, [])

	// const setAllUserData = async () => {
	//   try {
	//     // plan infio
	//     await setCurrentPlan();
	//     // const planData = await currentPlan();
	//     // const planInfo = planData.data ? planData.data :{}
	//     // if (Object.keys(planInfo).length !== 0) {
	//     //   if(planInfo.details.defaultPlan === 'true' || planInfo.details.defaultPlan === true){
	//     //     planInfo.exclusiveUser = true;
	//     //   }else{
	//     //     planInfo.exclusiveUser = false;
	//     //   }
	//     //   sessionStorage.setItem('planInfo', JSON.stringify(planInfo))
	//     // }

	//     // user Info
	//     const userInfores = await getAccountInfo();
	//     if(userInfores){
	//       dispatchAction({ payload: userInfores, type: USER_INFO })
	//       sessionStorage.setItem('userInfo',JSON.stringify(userInfores))
	//     }
	//   }
	//   catch (error) {
	//     console.error(error);
	//   }
	// }


	const isRedirect = (code, partnerCode) => {
		const basePartnerCode = code.replace('-GLOBAL', '');
		if (
			(partnerList.includes(code)) &&
			(partnerList.includes(partnerCode))
		) {
			if(
				(partnerCode === 'HDFC' && code === 'HDFCGLOBAL') ||
				(code === 'HDFC' && partnerCode === "HDFCGLOBAL") ||
				(code === 'STOCKAL' && partnerCode === 'STOCKALGLOBAL') ||
				(code === 'STOCKALGLOBAL' && partnerCode === 'STOCKAL')){
				fetchTheme(code.includes('GLOBAL') ? "IN" : "OTHER")
				return false
			}
			if (
				(partnerCode === 'STOCKAL' && (code === 'HDFC' || code === 'HDFCGLOBAL')) ||
				(partnerCode === 'HDFC' && (code === 'STOCKAL' || code === 'STOCKALGLOBAL')) ||
				(partnerCode === 'STOCKALGLOBAL' && (code === 'HDFC' || code === 'HDFCGLOBAL')) ||
				(partnerCode === 'HDFCGLOBAL' && (code === 'STOCKAL' || code === 'STOCKALGLOBAL'))
			) {
				return true;
			}
		}else if(code === partnerCode){
			return false
		}else if(partnerCode.includes(basePartnerCode) && partnerCode !== code){
			fetchTheme(!code.includes('-GLOBAL') ? "IN" : "OTHER");
			return false
		}else 
		return true
	}

	const onBlurUserName = async username => {
		try {
			if (username) {
				const response = await checkHostByPartner(username)
				if (
					response &&
					response.code &&
					response.code === 200 &&
					response.data
				) {
					if (
						response.data.isPartnerWhiteLabel &&
						response.data.riaId !== partnerCode
					) {
						//only for two partnercodes  for one partner
						//  if(((partnerCode || response.data.riaId) !== 'HDFC') ||
						//   ((partnerCode || response.data.riaId) !== 'HDFCGLOBAL') ||
						//   ((partnerCode || response.data.riaId) !== 'STOCKAL') ||
						//   ((partnerCode || response.data.riaId) !== 'STOCKALGLOBAL'))
						// alert(isTwoCodeForPartner(partnerCode))
						// alert(isRedirect(response.data.riaId, partnerCode))
						if (isRedirect(response.data.riaId, partnerCode)) {
							$('#mdlPartner').modal('show')
							setTimeout(function () {
								$('#mdlPartner').modal('hide')
								window.location.href =
									response.data.partnerUrl + '/signin?username=' + username
								// window.location.href = response.data.partnerUrl
							}, 3000)
							return false
						}
					}
				} else if (
					response.code === 404 &&
					response.message === 'Username not found'
				)
					setUserError('Username not found')
				else {
					console.error(response)
					setErrormessage('Oops! Something went wrong.')
				}
				// else if(response.status === 500){
				//   setErrormessage(response.message)
				// }else
				// setErrormessage('Invalid Username')
			}
      return true
		} catch (error) {
			console.error(error)
		}
	}

	const keyPressHandler = (event, submitCallback) => {
		if (event.which === 13) {
			submitCallback()
		}
	}

	let formInitialValues = {
		username: username,
		password: ''
	}
	const showLandingBanner = partnerConfig && partnerConfig?.[0]?.landingBanner ? true : false
	return (
		<>
			{/* {errormessage === '' ? null : (
				<AlertError color='#E81346' message={errormessage} />
			)} */}
			<NewHeader
				message='Don’t have an account?'
				linkName='Sign Up'
				onClickLink={() => history.push('/signup')}
			/>
			{partnerConfig && showLandingBanner ?
			<LoginBanner partnerConfig={partnerConfig} /> : ""}
			<div className={showLandingBanner ? styles.cardDivWithBanner : styles.cardDiv}>
				<Card className={styles.cardSignUp}>
					<Card.Body className={styles.cardBodySignup}>
						<Row className={styles.title}>Welcome back!</Row>
						<Row className={styles.subTitle}>
							Enter your unique username and password to log in.
						</Row>
						<Formik
							initialValues={formInitialValues}
							validationSchema={yup.object({
								username: yup
									.string()
									.required('User Name is a required field'),
								password: yup.string().required('Password is a required field')
							})}
							enableReinitialize
							onSubmit={async values => {
                setLoading(true)
								setErrormessage('')
								const data = {
									username: values.username.toLowerCase(),
									password: values.password
								}
								try {
				// change it back
                const respUser = await onBlurUserName(values.username)
				// const respUser = true
				if(respUser){
									const resp = await userLogin(data, screenDetails)
									if (resp && resp.code === 200) {
										// webengage login
										// eslint-disable-next-line no-undef
										webengage.user.login(values.username.toLowerCase())

										if (
											!resp.data.accessFor ||
											resp.data.accessFor.length === 0 ||
											(resp.data.accessFor &&
												resp.data.accessFor.includes('web'))
										) {
											sessionStorage.setItem('login', true)
											const token = JSON.parse(sessionStorage.getItem('token'))
											try {
												const socketToken = await socketLogin(
													token && token.accessToken
												)
												if (socketToken) {
													sessionStorage.setItem('socketToken', socketToken)
												}
											} catch (err) {
												console.log('error in authentication soket token')
											}
                      let queryUrl = queryString.parse(props.location.search, {
                        parseNumbers: false
                      })
                      if('redirect_uri' in queryUrl){
                        const decoderedirect_uri = decodeURIComponent(queryUrl.redirect_uri)
                        if(decoderedirect_uri.startsWith('/')){ //checking if contains pathname
                          history.push(decoderedirect_uri)
                        }
                        else{
                          const url = new URL(decoderedirect_uri)
                          if(url.host === window.location.host ){    //checking if redirect_uri and our host's domain match
                            window.location = decoderedirect_uri
                          }
                          else{
                            history.push('/home')
                          }
                       }
                      }
                      else{
											history.push('/home')
                      }
										} else {
											setErrormessage('WEB access is not allowed')
											sessionStorage.clear()
											localStorage.clear()
										}
									} else if (resp && resp.code === 401) {
										if (resp.data && resp.data.attempts > 2) {
											if (resp.data.attempts === 5)
												setErrormessage(
													'You have exhausted maximum attempts. Please reset Password'
												)
											else {
												const remainingAttempt =
													resp.data.attempts === 3 ? 'two' : 'one'
												setErrormessage(
													'Invalid Password. You are left with ' +
														remainingAttempt +
														' more attempt before you lock the account'
												)
											}
										} else {
											setErrormessage(
												'Username and/or password do not match in our records'
											)
										}
									} else if (resp && resp.code === 500) {
										setErrormessage('Oops! Something went wrong.')
									} else {
										setErrormessage(
											'Username and/or password do not match in our records'
										)
									}
                 }
								} catch (error) {
									console.error(error)
								}
                setLoading(false)
							}}>
							{({
								handleChange,
								handleBlur,
								handleSubmit,
								values,
								isSubmitting,
								touched,
								errors
							}) => {
								return (
									<>
										<Row className='ml-0 mr-0 mt-4'>
											<SignUpInput
												label='Username'
												placeholder='Enter Username'
												className={styles.emailInput}
												refValue={editFieldRef}
												tabIndex='1'
												type='text'
												name='username'
												value={values.username.toLowerCase()}
												onChange={event => {
													setUsername(username)
													setErrormessage('', setUserError(''))
													handleChange(event)
												}}
												// onBlur={event => {
												//   onBlurUserName(event.target.value)
												//   // handleBlur(event)
												// }}
												onKeyPress={e => keyPressHandler(e, handleSubmit)}
												isInvalid={
													(touched.username && errors.username) ||
													userError ||
													errormessage
												}
												onPaste={e => {
													e.preventDefault()
													return false
												}}
												onCopy={e => {
													e.preventDefault()
													return false
												}}
												errorMessage={errors.username}
												icon={Usersvg}
											/>
											{userError ? (
												<div className={styles.userError}>
													{userError}
													<Link to='/signup'>
														<span
															style={{
																color: '#0061D3',
																fontFamily: 'geomanistbook'
															}}>
															{' '}
															Sign Up?
														</span>
													</Link>
												</div>
											) : (
												''
											)}
										</Row>
										<Row
											className='mr-0 ml-0'
											style={{ position: 'relative', marginTop: '20px' }}>
											<SignUpInput
												containerClasses={styles.passwordInvalid}
												label='Password'
												placeholder='Enter Password'
												type={passwordType}
												name='password'
												tabIndex='2'
												value={values.password}
												onChange={e => {
													setErrormessage('')
													handleChange(e)
												}}
												autoComplete='new-password'
												// onBlur={handleBlur}
												// onBlur={event => {
												//   handleBlur(event)
												// }}
												onKeyPress={e => keyPressHandler(e, handleSubmit)}
												isInvalid={
													(touched.password && errors.password) || errormessage
												}
												onPaste={e => {
													e.preventDefault()
													return false
												}}
												onCopy={e => {
													e.preventDefault()
													return false
												}}
												errorMessage={errors.password || errormessage}
												icon={PasswordSvg}
											/>
											{
												<img
													className={styles.toggle}
													src={
														passwordType === 'password'
															? PasswordHide
															: PasswordShow
													}
													onClick={() => {
														const type =
															passwordType === 'password' ? 'text' : 'password'
														setPasswordType(type)
													}}></img>
											}
										</Row>
										<Row className={styles.forgotRowDiv}>
											<div style={{ paddingBottom: '28px' }}>
													<Link to='forgot-password' style={{textDecoration:'none'}}>
                          <span className={styles.forgotText}>
                             Forgot Password?
                          </span>
                          </Link>
												<span className={styles.forgotSeperator}>/</span>
													<Link to='forgot-username' style={{textDecoration:'none'}}>
                          <span className={styles.forgotText}>Forgot Username?	</span> </Link>
											</div>
											<SignUpButton
												tabIndex='3'
												title='Login'
												onClick={handleSubmit}
                        loading={loading}
											/>
										</Row>
										{/* </form> */}
									</>
								)
							}}
						</Formik>
					</Card.Body>
				</Card>
			</div>

			<div className='modal fade' id='mdlPartner'>
				<div className='modal-dialog modal-dialog-centered'>
					<div className='modal-content'>
						<div className='modal-body text-center p-4'>
							{/* <p>Your Stockal account is held via a partner</p> */}
							<p>Please wait, redirecting you to the partner website</p>
						</div>
					</div>
				</div>
			</div>
			<div
				style={{
					marginTop: '50px',
					display: 'flex',
					justifyContent: 'center'
				}}>
				{showPoweredLogo ? (
					<Col
						md={4}
						xl={4}
						lg={4}
						style={{ textAlign: 'center' }}
						>
						<span className={styles.poweredText}>Powered by</span>
						<img src={Logo} alt='powered by' width='64px' height='16px' />
					</Col>
				) : (
					<Col md={2} xl={3} className={`d-none d-md-flex`}></Col>
				)}
			</div>
			<OnboardingFooter />
		</>
	)
}

export default Login
