import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import styles from './watchListData.module.css'
import { Link } from 'react-router-dom'
import { calPriceChange, calcCurrVal, calReturn, isMarketOpen ,decimalAdjust, addCommasinPrice} from '../../utils'
import { getLivePrice } from '../../api'



const WatchListData = ({ img, removeTicker,setInwatchList,pageType}) => {
  const link = img.stockType === 'stack' ? `/stackdetail/${img.code}` : img.stockType === 'stocks' ? `/stockdetail/${img.code}` : img.stockType === 'etf' ? `/etfdetail/${img.code}` : img.stockType === 'adr' ? `/stockdetail/${img.code}` : ''
	const [remove, setRemove] = useState(false)
  const [isLoaded, setIsloaded] = useState(true)
  const [livePrices, setLivePrices] = useState({});
  // const removeFromWatchList = (e) => {
  //   deleteWatchListTicker(e)
  // }

  const removeTickercall = (e) => {
    removeTicker(e)
    if(pageType === "detail"){
      setInwatchList()
    }
  }

  // useEffect(() => {
  //   const setLivePrice = async () => {
  //     if ((stockSymbols && stockSymbols.length > 0) || (etfSymbols && etfSymbols.length > 0)) {
  //       await getLivePriceNonStacks([...stockSymbols, ...etfSymbols])
  //     }
  //   }

  //   const setCV = async () => {
  //     if (stackSymbols && stackSymbols.length > 0) {
  //         await getCurrentValueStacks(stackSymbols);
  //     }
  //   }

  //   const priceId = setInterval(() => {
  //     if(isMarketOpen(currentMarketStatus)){
  //       setLivePrice();
  //     }
  //   }, PORTFOLIO_LIVE_PRICE_INTERVAL);

  //   const valueId = setInterval(() => {
  //     if (isMarketOpen(currentMarketStatus)) {
  //       setCV();
  //     }
  //   }, PORTFOLIO_CURRENT_VALUE_INTERVAL);
  //   return () => {
  //     clearInterval(valueId);
  //     clearInterval(priceId);
  //   }
  // }, [currentMarketStatus, dispatchAction, etfSymbols, stackPortfoliosList, stockSymbols, userPortfolio, stackSymbols])


  // const getLivePriceNonStacks = async(tickers) => {
  //   try {
  //     tickers = tickers.join(',')
  //     let livePrices = await getLivePrice(tickers);
  //     setLivePrices(keyBy(livePrices, 'symbol'));
  //   } catch (err) {
  //     console.log("error fetching live prices");
  //   }
  // }



  return (
		<div
      onMouseEnter={() => setRemove(true)}
      onMouseLeave={() => setRemove(false)}>
			<Row
				className={`${styles.stockList}`}>
        <Link to={link} className={styles.link}>
				<Col md={2} xs={2} style={{ paddingLeft: '0%' }}>
					{/* <img className={styles.stockImage} src={img.logo}></img> */}
          {img.logo && isLoaded ? (
          <img
            onError={() => {
              setIsloaded(false)
            }}
            className={styles.stockImage}
            src={img.logo}
            alt={img.logo.substring(0, 1)}></img>
        ) : (
          <h3 className={`text-center ${styles.stockImage}`}>
            {img.code.substring(0, 1)}
          </h3>
        )}
				</Col>
        </Link>
				<Col md={5} xs={5} className='pl-1'>
					<div className={styles.stockTitle}>{img.code}</div>
					<div className={styles.stockName}>
						{img.company.length > 20
							? `${img.company.substring(0, 20)}...`
							: img.company}
					</div>
				</Col>
				<Col style={{ paddingRight: '0%' }}>
					{remove ? (
						<div type='button' className={styles.stockRemove}
             onClick={()=>removeTickercall(img.code)}
             >
							Remove
						</div>
					) : (
						<div>
							<div className={styles.stockValue}>${img.price}</div>
							<div className={img.percentChange > 0 ? styles.stockIncrement : styles.redPercentChange}>{img.percentChange}%</div>
						</div>
					)}
				</Col>
			</Row>
		</div>
	)
}

export default WatchListData
