import React, { useState, useEffect } from 'react'
import {  Row, Col } from 'react-bootstrap'
import styles from './addWatchListModal.module.css'
import crossImage from '../../public/images/cross.png'
import { FormControl } from 'react-bootstrap'
import { searchBarDataAPI, postWatchListTicker, watchListDataAPI } from '../../api'
import BlueStar from '../../public/images/BlueStar.svg'
import BlackStar from '../../public/images/BlackStar.svg'
// import { Link } from 'react-router-dom'
import AddedTick from '../../public/images/AddedTick.svg'
import watchListSearchBarSearchSign from '../../public/images/watchlistSearchBarSearchSign.svg'
import watchListSearchBarCross from '../../public/images/watchListSearchBarCross.svg'



const AddWatchlistModal = () => {
  const [searchList, setsearchList] = useState([])
	const [searchItem, setsearchItem] = useState('')
  const [data, setData] = useState([])

	const onHandleChange = event => {
		setsearchItem(event.target.value)
	}

	const asynchFetch = async () => {
		const res = await searchBarDataAPI(searchItem)
		setsearchList(res)
	}

  useEffect(() => {
    const handler = setTimeout(() => asynchFetch(), 1000);

    return () => clearTimeout(handler);
  // asynchFetch()
	}, [searchItem])

  const getWatchlistData = async () => {
		const res = await watchListDataAPI()
		setData(res)
	}

	useEffect(() => {
		getWatchlistData()
	}, [])


  const addTickerToWatchList = async (e) => {
    const res = await postWatchListTicker(e)
    if(res.code === 200){
      getWatchlistData()
    } else{
      console.log('something went wrong')
    }
  }

  // const addTickerToWatchList = (e) => {
  //   postWatchListTicker(e)
  //   getWatchlistData()
  // }

  const goBack = () => {
    window.history.back()
  }

	return (
    <>
     <div className={styles.crossImage} onClick={() => goBack()}>
        <img
          alt='watchlist-icon'
          type='button'
          data-testid='img-div'
          src={crossImage}
        />
      </div>
    <div className={styles.marginAdjust}>
      <p className={styles.heading}>Add to WatchList</p>
      {/* searchBar */}
      <div className={styles.inputbox}>
        <img className={styles.watchListSearchBarSearchSign} src={watchListSearchBarSearchSign}/>
        <img className={styles.watchlistsearchbarCross} onClick={()=>setsearchItem('')} src={watchListSearchBarCross}/>
        <FormControl
          className={styles.searchInput}
          placeholder='search stocks, ETF, stacks...'
          aria-describedby='basic-addon1'
          onChange={onHandleChange}
          value={searchItem}
        />
			</div>
      {searchItem && searchItem.length > 0 ?
      <div className={styles.searchList}>
        {searchList &&
          searchList.map((item, index) => {
            const link = item.stockType === 'stack' ? `/stackdetail/${item.code}` : item.stockType === 'stocks' ? `/stockdetail/${item.code}` : item.stockType === 'etf' ? `/stockdetail/${item.code}` : ''
            return (
              // <Link to={link} className={styles.link}>
              <Row className={`${styles.searchItem}`}>
                <Col md={2} sm={3} xs={3} className={styles.stockTitle}>
                  {item.code}
                </Col>
                <Col xs={6} sm={6} md={7} className={styles.stockName}>{item.company}</Col>
                <Col md={2} sm={2} xs={2} className={styles.stockType}>
                  {item.stockType}
                </Col>
                {/* {data && data.length > 0 ? */}
                <Col md={1} sm={1} xs={1} style={{paddingLeft:"0px"}}>
                {data.some((obj) => obj.code === item.code) ?
                <div className={`${styles.watchListStar}`}>
                  <span>
                  <img
                    className={styles.blueStar}
                    alt='watchlist-icon'
                    type='button'
                    data-testid='img-div'
                    src={AddedTick}
                  />
                  </span>
                </div>
                :
                  <div className={`${styles.watchListStar}`} onClick={() => addTickerToWatchList(item.code)}>
                    <span>
                    <img
                      className={styles.blueStar}
                      alt='watchlist-icon'
                      type='button'
                      data-testid='img-div'
                      src={BlueStar}
                    />
                    </span>
                  </div>}
                </Col>
                {/* : ""} */}
              </Row>
              // </Link>
            )
          })}
      </div> : "" }
    </div>
    </>
	)
}

export default AddWatchlistModal

