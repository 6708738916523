import React, { useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Col } from 'react-bootstrap'
import Slider from 'react-slick'
import { getHomeNews, getMarketDetails, getPerformingStacks, getYearHighStocks, getYearLowStocks } from '../../store/actions/index'
import {
  TrendingStockCardSkeleton,
  PopularStockSkeleton,
  StackCardSkeleton,
  NewsCardSkeleton,
  CostDetailCardSkeleton,
  PortfolioSkeleton,
  WeekHighLowSkeleton,
  ThemeSkeleton
} from '../../skeletons/index'
import {
  HomePortfolio,
  StackCard,
  PopularStock,
  PopularStockWithoutGraph,
  TrendingStocksCard,
  SideBar,
  StockWeek,
  ExploreThemes,
  NewsCard,
  Header,
  StockPieChart,
  StockLineChart,
  Footer,
  StartBanner,
  AppBanner
} from '../../components'
import {
  getPopularStocks,
  getStocksETF,
  getGainerLoser,
  getThemes,
  gethighLowStocks,
  getNews,
  getTopPerformingStacks,
  getPopularEtf,
  getAccountInfo,
  getTrendingStocks,
  summaryData,
  getPANDetails,
  getSiteNotification,
  postSiteNotification,
  fpslAgreeSiteNotification,
  getPopularHalalStocks,
  getPartnerData,
  getSitePopupNotification,
  postSitePopupNotification,
  subscribeSiteNotification
} from '../../api'
import { USER_INFO } from '../../store/actions/action'
// import HomeSkeleton from './homeSkeleton'
import styles from './home.module.css'
// import newsCardSkeleton from '../../skeletons/NewsCardSkeleton'
import { FundMyAccountData, FundMyAccountDataWithNoPlan, ApplicationBack, ApplicationUnderProcess, ExpirePlanStatusData, BeforeExpirePlanStatusData, NoPlanStatusData, UpgradePlanStatusData, DocumentPending,DocumentPendingIND,DocumentOldPending, KYCPending, DocumentRejected, KYCRejected, FewMoreDetailsPending,DocumentRejectedIND,ApplicationBackIND
,PoorPhotoQuality,InvalidDoc,NameDobNotMatch,NameNotMatch,DobNotMatch } from './bannerStatusData'
import { setCurrentPlan } from '../../common'
import PricingPlan from '../../components/PricingPlan/index'
import BannerSkeleton from '../../skeletons/BannerSkeleton'
import rightArrow from '../../public/images/rightArrow.svg'
import { Link } from 'react-router-dom'
import Success from '../../components/Modal/Success'
import { ThemeContext } from '../../Context/ThemeContext'
import StartBeneficiaryBanner from './homeBeneficiaryBanner'
import PANBanner from './homePANBanner'
import $ from 'jquery'
import { Spinner } from "react-bootstrap"
import warningIcon from "../../public/images/alert.svg"
import CetfBanner from './cetfBanner'
import SignUpInput from '../../components/SignUp/SignUpInput'
import tickImage from '../../public/images/GreenTickMark.svg'


const Home = (props) => {
  // const [loading, setloading] = useState(true)
  const [popularStocks, setPopularStocks] = useState([])
  const [trendingStocks, setTrendingStocks] = useState([])
  const [gainerStocks, setGainerStocks] = useState([])
  const [loserStocks, setLoserStocks] = useState([])
  const [highStocks, setHighStocks] = useState([])
  const [lowStocks, setLowStocks] = useState([])
  const [themes, setthemes] = useState([])
  const [news, setnews] = useState([])
  const [topPerformingStack, setTopPerformingStack] = useState([])
  const [popularEtf, setPopularEtf] = useState([])
  const [slideeffect, setslideeffect] = useState(false)
  const [activeSlide, setActiveSlide] = useState(0)
  const [firstTimeOnboard, setFirstTime] = useState(false)
  const [popularStocksLoad, setPopularStocksLoad] = useState(false)
  const [popularEtfLoad, setPopularEtfLoad] = useState(false)
  const [topGainerLoad, setTopGainerLoad] = useState(false)
  const [topLoserLoad, setTopLoserLoad] = useState(false)
  const [yearHighLoad, setYearHighLoad] = useState(true)
  const [yearLowLoad, setYearLowLoad] = useState(true)
  const [firstName, setFirstName] = useState('--')
  const [statusBanner, setStatusBanner] = useState(false)
  const [bannerData, setBannerData] = useState({})
  const [IsSkeleton, setIsSkeleton] = useState(true)

  const [showPlanModal, setShowPlanModal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [showPANBanner, setShowPANBanner] = useState(false)
  const [remainingDays, setRemainingDays] = useState('')
  const [liquidateOnly, setLiquidateOnly] = useState(false)

  const { buttonColor, contactMail, location,fetchTheme } = useContext(ThemeContext)
  const [showSavingsBanner, setShowSavingsBanner] = useState(false);
  const [siteModalData, setSiteModalData] = useState(null)
  const [sitePopupData, setSitePopupData] = useState(null)
  const [fpslError, setFpslError] = useState(false)
  const [fpslSubmitting, setFpslSubmitting] = useState(false)
  const [popupNotificationSubmitting, setPopupNotificationSubmitting] = useState(false)
  const [popularHalalStocks, setPopularHalalStocks] = useState([])
  const [partnerCodeData, setPartnerCodeData] = useState()
  const [userLocation, setUserLocation] = useState()
  const [appBannerContent, setAppBannerContent] = useState('');
  const [showStaticAppBanner, setShowStaticAppBanner] = useState(false);
  const [signature, setSignature] = useState('');
  const [nameMatchError, setNameMatchError] = useState(false);
  const [laodingOfSignatureMatch, setLoadingOfSignatureMatch] = useState(false);
  const [reActivatedSuccessfully, setReActivatedSuccessfully] = useState(false);
  const { getSimilarity } = require('calculate-string-similarity');

  var intervalSet = '';

  const handlePlanClose = (from) => {
    setShowPlanModal(false)
    if (from && from === 'success') {
      setSuccessModal(true)
      setSuccessMessage('Plan successfully added')
    }
  }

  const fetchPANDetails = async () => {
    const response = await getPANDetails();
    if (response && response.code === 200 && response.data) {
      if(response.data.showBanner){
        setRemainingDays(response.data.remainingDay || '')
        setLiquidateOnly(response.data.liquidateOnly || false)
        setShowPANBanner(response.data.showBanner);
      }else setShowPANBanner(false);
    }
    else {
      setShowPANBanner(false);
      console.log('something went wrong')
    }
  }

  const dispatchAction = useDispatch()
  const state = useSelector(state => state)
  const {
    userAccountSummary: {
      userSummary: { accountSummary = {}, portfolioSummary = {} } = {}
    } = {},
    userDetails: { userInfo = {} } = {},
    marketDetails: { marketDetails: { currentMarketStatus = {} } = {} } = {},
    homeNews: { homeNews } = {},
    topStacks: { topStacks = {'check':'true'} } = {},
    yearHighStock: { yearHighStock } = {},
    yearLowStock: { yearLowStock } = {}
  } = state

  const accountReopen = (data) => {
    setNameMatchError(false)
    const fullNameCustomer = state.userDetails && state.userDetails.userInfo && state.userDetails.userInfo.fullname
    const isMatchingTheFullName = getSimilarity(signature.toUpperCase(), fullNameCustomer.toUpperCase(), { caseSensitive: false });
    if (parseFloat(isMatchingTheFullName) >= 60) {
      setNameMatchError(false)
      setLoadingOfSignatureMatch(true)
      startAccountReopenProcess(data)
    }else{
      setNameMatchError(true)
    }
  }

  useEffect(() => {
    if (reActivatedSuccessfully) {
      const timer = setTimeout(() => {
        $('#notificationModal').modal('hide');
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [reActivatedSuccessfully]);

  const startAccountReopenProcess = async(data) => {
    const custId = state.userDetails && state.userDetails.userInfo && state.userDetails.userInfo.customerId
    setReActivatedSuccessfully(true)
    const res = await subscribeSiteNotification(custId,data.notificationID,signature)
    if(res.code == 200 ){
      setReActivatedSuccessfully(true)
    }else{
      setReActivatedSuccessfully(false)
    }
  }

  const {
    etfPortfolio = {},
    stackPortfolio = {},
    stockPortfolio = {}
  } = portfolioSummary

  const fetchSiteNotification = async ()=>{
    let userInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')): ''
    if(!userInfo)
      userInfo = await getAccountInfo();
      let custId = userInfo.customerId
      const res = await getSiteNotification(custId);
    if(res && res.code === 200){
      if(userInfo && !userInfo['fpslAgreement'] && !userInfo['fpslProhibited']){
        setSiteModalData(res.data)
        $('#notificationModal').modal('show')
        // await postSiteNotification(false)
      }
    }
  }

  const fetchSitePopupNotification = async ()=>{
    const res = await getSitePopupNotification()
    if(res && res.code === 200 && res.data){
      setSitePopupData(res.data)
        $('#notificationPopup').modal('show')
    }
  }

  // API from REDUX
  useEffect(() => {
    fetchSiteNotification()
    fetchSitePopupNotification();
    if (Object.keys(state.topStacks).length === 0) {
      dispatchAction(getPerformingStacks())
    }
    if (Object.keys(state.yearHighStock).length === 0) {
      dispatchAction(getYearHighStocks())
      setYearHighLoad(true)
    }
    if (Object.keys(state.yearLowStock).length === 0) {
      dispatchAction(getYearLowStocks())
      setYearLowLoad(true)
    }
    if (Object.keys(state.homeNews).length === 0) {
      dispatchAction(getHomeNews())
    }
    Object.keys(state.marketDetails).length === 0 &&
      dispatchAction(getMarketDetails())
  }, [])

  useEffect(()=> {
    dispatchAction(getPerformingStacks())
  },[])

  const data = [[40.9], [50.5], [60.4], [70], [80], [120], [120], [150], [60]]

  const settingPlanInfo = async () => {
    try {
      const planInfo = await setCurrentPlan();
      // const response = JSON.parse(sessionStorage.getItem('planInfo'))
      //in one go then only show popup
      let nextStep = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')).nextStep : '';
      if (!planInfo && nextStep === 'COMPLETE' && ((props.location.state && props.location.state.showPlanPopUp) || props.location.search.includes('showPlanPopUp'))) {
        setShowPlanModal(true);
      }
      // else{
      //   console.log(response.message)
      // }
      return planInfo
    }
    catch (error) {
      console.error(error);
    }
  }


  const accountInfoInterval = async()=>{
    const userInfores = await settingAccountInfo();
    const planInfo = sessionStorage.getItem('planInfo') ? JSON.parse(sessionStorage.getItem('planInfo')) : '';
    await settingAccountBanner(userInfores, planInfo)
    if(intervalSet && userInfores && userInfores.statusDetails && userInfores.statusDetails.status !== 'pending'){
      clearInterval(intervalSet)
      return
    }

  }

  const changeTheme = async(userInfores) => {
    const partnerCode = userInfores.partner && userInfores.partner.code ? userInfores.partner.code.toUpperCase() : '';
    // if (partnerCode === 'STOCKAL' || partnerCode === 'STOCKALGLOBAL' || partnerCode === 'HDFC' || partnerCode === 'HDFCGLOBAL') {
     const countryPartner = userInfores.country && userInfores.country === 'IND' ? 'IN' : userInfores.country
     await fetchTheme(countryPartner)
    // }
  }

  const settingAccountBanner = async (userInfores, planInfo) => {
    // user and plan Info
    try {
      let bannerFlag = false;
      //  const {cashBalance} =  accountSummary;
      // const userInfores = JSON.parse(sessionStorage.getItem('userInfo'));
      // console.log('statusDetails',userInfores.statusDetails)
      // console.log('accountSummary',accountSummary)
      // console.log('cashBalance',cashBalance)
      // if(cashBalance !== undefined){
      //  if(userInfores && userInfores.statusDetails){
      //    dispatchAction({ payload: userInfores, type: USER_INFO })
      //    sessionStorage.setItem('userInfo',JSON.stringify(userInfores));
      //  setFirstName(userInfores.firstName ? userInfores.firstName : '')
      const currentStatus = userInfores.statusDetails;

      if (currentStatus.status === 'pending') {
        if (userInfores.nextStep === "DOCUMENTS_OLD") {
          setBannerData(DocumentOldPending);
        }
        else if (userInfores.nextStep === "FEW_MORE_DETAILS") {
          sessionStorage.setItem('currentSignUp', 'PhoneNumber');
          setBannerData(FewMoreDetailsPending);
        } else if (userInfores.nextStep === "DOCUMENTS" || userInfores.nextStep === "PAN_DETAILS") {
          await changeTheme(userInfores)
          const documentBanner = location === 'India' || location === 'INDIA'  ? DocumentPendingIND : DocumentPending
          setBannerData(documentBanner);
        }
        else if (userInfores.nextStep === "PERSONAL_DETAILS" ||
          userInfores.nextStep === 'ADDRESS' ||
          userInfores.nextStep === 'FINANCIAL_DETAILS' ||
          userInfores.nextStep === 'POLITICALLY_EXPOSED' ||
          userInfores.nextStep === 'LEGAL_DETAILS'
        ) {
          setBannerData(KYCPending);
        }
        // else if(currentStatus.type === 'Document'){
        //   setBannerData(DocumentPending);
        // }
        // else if(currentStatus.type === 'KYC'){
        //   setBannerData(KYCPending);
        // }
        // else if(currentStatus.type === 'Account'){
        else {
          setBannerData(ApplicationUnderProcess);
          if(!intervalSet){
            const accountInt = setInterval(async() => {
            await accountInfoInterval()
            }, 10000);
            intervalSet = accountInt
          }
        }
        setStatusBanner(true);
        bannerFlag = true;
      }
      else if (currentStatus.status === 'approved') {
         //show pan banner
        await fetchPANDetails();
        const summaryRes = await summaryData()
        // console.info('accountSummary', summaryRes)
        // now plan
        if (!planInfo) {
          // setBannerData(NoPlanStatusData);
          setBannerData(FundMyAccountDataWithNoPlan);
          setStatusBanner(true);
          bannerFlag = true;
        }
        else if (summaryRes && summaryRes.code === 200 && summaryRes.data &&
          (summaryRes.data.accountSummary && !summaryRes.data.accountSummary.cashBalance) &&
          (summaryRes.data.portfolioSummary && !summaryRes.data.portfolioSummary.totalInvestmentAmount)
        ) {
          setBannerData(FundMyAccountData);
          setStatusBanner(true);
          bannerFlag = true;
        }
        else if (summaryRes.status === 500 || (summaryRes.code === 200 && Object.keys(summaryRes.data).length === 0)) {
          setBannerData(FundMyAccountData);
          setStatusBanner(true);
          bannerFlag = true;
        }
      }
      else if (currentStatus.status === 'rejected') {
        if (userInfores.nextStep === "DOCUMENTS_OLD") {
          setBannerData(DocumentOldPending);
        }
        else if (currentStatus.type === 'Document') {
          await changeTheme(userInfores)
          const documentBanner = location === 'India' || location === 'INDIA' ? DocumentRejectedIND : DocumentRejected
          setBannerData(documentBanner);
        }
        else if (currentStatus.type === 'KYC') {
          setBannerData(KYCRejected);
        }else if(currentStatus.type === 'NAME_DOB_NOT_MATCH'){
          sessionStorage.setItem('rejected','NAME_DOB_NOT_MATCH')
          setBannerData(NameDobNotMatch)
        }
        else if(currentStatus.type === 'NAME_NOT_MATCH'){
          sessionStorage.setItem('rejected','NAME_NOT_MATCH')
          setBannerData(NameNotMatch)
        }
        else if(currentStatus.type === 'DOB_NOT_MATCH'){
          sessionStorage.setItem('rejected','DOB_NOT_MATCH')
          setBannerData(DobNotMatch)
        }
        else if(currentStatus.type === 'INVALID_DOC'){
          sessionStorage.setItem('rejected','INVALID_DOC')
          setBannerData(InvalidDoc)
        }
        else if(currentStatus.type === 'POOR_DOC_QUALITY'){
          sessionStorage.setItem('rejected','POOR_DOC_QUALITY')
          setBannerData(PoorPhotoQuality)
        }
        else {
          // else if(currentStatus.type === 'Account'){
            await changeTheme(userInfores)
          const documentBanner = location === 'India' || location === 'INDIA' ? ApplicationBackIND : ApplicationBack
          setBannerData(documentBanner);
        }
        setStatusBanner(true);
        bannerFlag = true;
      }
      //if not banner data then
      if (!bannerFlag) {
        // for both after and before 7 days expiry
        if (planInfo && planInfo.showRenew) {
          if (planInfo.planStatus === 'expire') {
            setBannerData(ExpirePlanStatusData);
            setStatusBanner(true);
          } else {
            setBannerData(BeforeExpirePlanStatusData);
            setStatusBanner(true);
          }
        }
        //show pan banner
        fetchPANDetails();
      }
      setIsSkeleton(false)

      //  }
      // }

    } catch (error) {
      console.error(error);
    }
  }


  useEffect(() => {
    window.scrollTo(0, 0)
    settingPlanBanner()
  }, [location])


  const settingAccountInfo = async () => {

    const userInfores = await getAccountInfo();
    if (userInfores && userInfores.statusDetails) {
      dispatchAction({ payload: userInfores, type: USER_INFO })
      const scopeUser = userInfores.scope ? userInfores.scope.split(' ') : ''
      if(scopeUser && scopeUser.length && scopeUser.includes('cash-mgmt')){
        sessionStorage.setItem('savingAccount',true)
        if(userInfores.accountStatus && userInfores.accountStatus === 2 &&
          userInfores.hasOwnProperty('savingAccountStatus') === false &&
          userInfores.isSavingAccount !== true){
          setShowSavingsBanner(true)
        }
      }
      sessionStorage.setItem('userInfo', JSON.stringify(userInfores));
      // eslint-disable-next-line no-undef
      // if (typeof (Intercom) !== "undefined") {
      //   let icSetting = {}
      //   let fullname = `${userInfores.firstName} ${userInfores.middleName} ${userInfores.lastName}`
      //   if (fullname) {
      //     icSetting.name = fullname
      //   }
      //   if (userInfores.emailAddress1) {
      //     icSetting.email = userInfores.emailAddress1
      //   }
      //   if (userInfores.username) {
      //     icSetting.username = userInfores.username
      //   }
      //   if (userInfores.phoneHome) {
      //     icSetting.phone = userInfores.phoneHome
      //   }
      //   if (userInfores.partner && userInfores.partner.code) {
      //     icSetting.partner = userInfores.partner.code
      //   }
      //   // eslint-disable-next-line no-undef
      //   Intercom('update', icSetting);
      // }

      setFirstName(userInfores.firstName ? userInfores.firstName : '')
      setPartnerCodeData(userInfores.partner && userInfores.partner.code ? userInfores.partner.code : '')
      return userInfores
    }
    return;
  }



  const settingPlanBanner = async (planInfo = '') => {
    try {
      let userInfores = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : '';
      if (!planInfo) {
        //first time
        userInfores = await settingAccountInfo();
        planInfo = await settingPlanInfo();
        props.isUserSet()
      }
      // else {
      //   setIsSkeleton(true)
      // }

      //setting banner

      // if (!planInfo) {
      //   setBannerData(NoPlanStatusData);
      //   setStatusBanner(true);
      // } else if (planInfo && planInfo.showRenew) {
      //   setBannerData(BeforeExpirePlanStatusData);
      //   setStatusBanner(true);
      // } else if (planInfo && planInfo.planStatus === 'expire') {
      //   setBannerData(ExpirePlanStatusData);
      //   setStatusBanner(true);
      // } else if (userInfores) {
      //   settingAccountBanner(userInfores)
      // }
      settingAccountBanner(userInfores, planInfo)
      // setIsSkeleton(false)
    } catch (error) {
      console.error(error);
    }
  }



  // const updatePlanData = (planInfo) => {
  //   //dont remove this
  // }


  useEffect(() => {
    const asynchFetch = async () => {
      // performing stacks
      // try {
      //   var performingstackres = await getTopPerformingStacks('cagr');
      //   setTopPerformingStack(
      // 		performingstackres && performingstackres.length > 0
      // 			? [...performingstackres]
      // 			: []
      // 	)
      // } catch (error) {
      //   console.error(error);
      // }
      // high stock
      // try {
      //   var highstocksres = await gethighLowStocks('yearHigh');
      //   setHighStocks(
      //     highstocksres && highstocksres.length > 0 ? [...highstocksres] : []
      //   )
      //   setWeekHighLoad(true)
      // } catch (error) {
      //   console.error(error);
      // }
      // low stock
      // try {
      //   var lowstocksres = await gethighLowStocks('yearLow');
      //   setLowStocks(
      //     lowstocksres && lowstocksres.length > 0 ? [...lowstocksres] : []
      //   )
      //   setWeekLowLoad(true)
      // } catch (error) {
      //   console.error(error);
      // }

      // theme
      // try {
      //   var themesres = await getThemes();
      //   setthemes(themesres && themesres.length > 0 ? [...themesres] : [])
      // } catch (error) {
      //   console.error(error);
      // }

      // news
      // try {
      //   var newsres = await getNews();
      //   // setnews(newsres && newsres.length > 0 ? [...newsres] : [])
      // } catch (error) {
      //   console.error(error);
      // }

      // themes
      getThemes().then(themesres => {
        if (themesres && themesres.length > 0) {
          setthemes(
            themesres && themesres.length > 0
              ? [...themesres]
              : null
          )
        }
        else {
          console.log('no themes found')
        }
      })



      // popular stocks
      getPopularStocks().then(popularstockres => {
        if (popularstockres) {
          setPopularStocks(
            popularstockres && popularstockres.length > 0
              ? [...popularstockres]
              : null
          )
          setPopularStocksLoad(true)
        }
        else {
          console.log('no data found')
          setPopularStocks(null)
        }
      })
      // try {
      //   var popularstockres = await getPopularStocks();
      //   setPopularStocks(
      //     	popularstockres && popularstockres.length > 0
      //     		? [...popularstockres]
      //     		: []
      //   )
      //   setPopularStocksLoad(true)
      // } catch (error) {
      //   console.error(error);
      // }


      //top gainer stock
      getGainerLoser('topGainers').then(gainerstocksres => {
        if (gainerstocksres && gainerstocksres.length > 0) {
          setGainerStocks(
            gainerstocksres && gainerstocksres.length > 0
              ? [...gainerstocksres]
              : null
          )
          setTopGainerLoad(true)
        }
        else {
          console.log('no data found')
          setGainerStocks(null)
        }
      })
      // try {
      //   var gainerstocksres = await getGainerLoser('topGainers');
      //   setGainerStocks(
      //     gainerstocksres && gainerstocksres.length > 0
      //       ? [...gainerstocksres]
      //       : []
      //   )
      //   setTopGainerLoad(true)
      // } catch (error) {
      //   console.error(error);
      // }


      // loser stock
      getGainerLoser('topLosers').then(loserstocksres => {
        if (loserstocksres && loserstocksres.length > 0) {
          setLoserStocks(
            loserstocksres && loserstocksres.length > 0 ? [...loserstocksres] : null
          )
          setTopLoserLoad(true)
        }
        else {
          console.log('no data found')
          setLoserStocks(null)
        }
      })
      // try {
      //   var loserstocksres = await getGainerLoser('topLosers');
      //   setLoserStocks(
      //     loserstocksres && loserstocksres.length > 0 ? [...loserstocksres] : []
      //   )
      //   setTopLoserLoad(true)
      // } catch (error) {
      //   console.error(error);
      // }


      // popular Etf
      getPopularEtf().then(popularetfres => {
        if (popularetfres && popularetfres.length > 0) {
          setPopularEtf(
            popularetfres && popularetfres.length > 0 ? [...popularetfres] : null
          )
          setPopularEtfLoad(true)
        }
        else {
          console.log('no data found')
          setPopularEtf(null)
        }
      })
      // try {
      //   var popularetfres = await getPopularEtf();
      //   setPopularEtf(
      //     popularetfres && popularetfres.length > 0 ? [...popularetfres] : []
      //   )
      //   setPopularEtfLoad(true)
      // } catch (error) {
      //   console.error(error);
      // }

      // user Info
      // try {
      //   var userInfores = await getAccountInfo();
      // } catch (error) {
      //   console.error(error);
      // }

      // trending stocks
      getTrendingStocks().then(trendingstocksres => {
        if (trendingstocksres && trendingstocksres.length > 0) {
          setTrendingStocks(
            trendingstocksres && trendingstocksres.length > 0
              ? [...trendingstocksres]
              : null
          )
          setPopularEtfLoad(true)
        }
        else {
          console.log('no data found')
          setTrendingStocks(null)
        }
      })
      // try {
      //   var trendingstocksres = await getTrendingStocks();
      //   // ask anjali about the trending stocks

      //   setTrendingStocks(
      // 	trendingstocksres && trendingstocksres.length > 0
      // 		? [...trendingstocksres]
      // 		: []
      //   )
      // } catch (error) {
      //   console.error(error);
      // }

      // let [
      // 	trendingstocksres,
      // 	loserstocksres,
      // 	highstocksres,
      // 	lowstocksres,
      // 	themesres,
      // 	newsres,
      // 	performingstackres,
      // 	popularetfres,
      // 	userInfores
      // ] = await Promise.all([
      // 	getStocksETF(false),
      // 	getGainerLoser('topGainers'),
      // 	getGainerLoser('topLosers'),
      // 	gethighLowStocks('yearHigh'),
      // 	gethighLowStocks('yearLow'),
      // 	getThemes(),
      // 	getNews(),
      // 	getTopPerformingStacks('cagr'),
      // 	getPopularEtf(),
      // 	getAccountInfo()
      // ])

      // setTrendingStocks(
      // 	trendingstocksres && trendingstocksres.length > 0
      // 		? [...trendingstocksres]
      // 		: []
      // )

      // setLoserStocks(
      // 	loserstocksres && loserstocksres.length > 0 ? [...loserstocksres] : []
      // )
      // setHighStocks(
      // 	highstocksres && highstocksres.length > 0 ? [...highstocksres] : []
      // )
      // setLowStocks(
      // 	lowstocksres && lowstocksres.length > 0 ? [...lowstocksres] : []
      // )
      // setthemes(themesres && themesres.length > 0 ? [...themesres] : [])
      // setnews(newsres && newsres.length > 0 ? [...newsres] : [])
      // setTopPerformingStack &&
      // 	setTopPerformingStack(
      // 		performingstackres && performingstackres.length > 0
      // 			? [...performingstackres]
      // 			: []
      // 	)
      // setPopularEtf(
      // 	popularetfres && popularetfres.length > 0 ? [...popularetfres] : []
      // )
    }
    asynchFetch()
  }, [])

  const handleEffect = () => {
    setslideeffect(prevState => setslideeffect(!prevState))
  }
  const totalSum =
    stockPortfolio.currentValue +
    stackPortfolio.currentValue +
    etfPortfolio.currentValue +
    accountSummary.cashBalance
  const etfPercentage =
    etfPortfolio.currentValue > 0
      ? (etfPortfolio.currentValue / totalSum) * 100
      : 0
  const stackPercentage =
    stackPortfolio.currentValue > 0
      ? (stackPortfolio.currentValue / totalSum) * 100
      : 0
  const stockPercentage =
    stockPortfolio.currentValue > 0
      ? (stockPortfolio.currentValue / totalSum) * 100
      : 0
  const cashBalancePercentage = accountSummary.cashBalance > 0 ?
    (accountSummary.cashBalance / totalSum) * 100 :
    0
  const pieChartData = [
    {
      name: 'ETF',
      y: etfPercentage
    },
    {
      name: 'Stack',
      y: stackPercentage
    },
    {
      name: 'Stock',
      y: stockPercentage
    },
    {
      name: 'Cash',
      y: cashBalancePercentage
    }
  ]
  const newsFilter = homeNews && homeNews.length > 0 && homeNews.filter((item, index) => {
    // if (item.Summary !== '') { //commented as Summary is not comming from api now
      return true
    // }
  })
  var themeSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    className: 'center',
    centerMode: true,
    centerPadding: '10px',
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 424,
        settings: { centerPadding: '0px', infinite: true }
      },
      {
        breakpoint: 321,
        settings: { centerPadding: '50px', slidesToShow: 1, infinite: true }
      }
    ]
  }
  var settingsNews = {
    dots: false,
    className: 'center',
    autoplaySpeed: 3000,
    infinite: true,
    centerMode: true,
    centerPadding: '30px',
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 2,
    slidesPerRow: 1,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          centerPadding: '40px',
          infinite: true
        }
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 426,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 321,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '10px'
        }
      }
    ]
  }
  var settings = {
    dots: false,
    className: 'center',
    autoplaySpeed: 3000,
    infinite: true,
    centerMode: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding:'10px'
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding:'20%'
        }
      },
      {
        breakpoint:480,
        settings: {
          slidesToShow:1,
          slidesToScroll:1,
          centerMode:true,
          centerPadding:'20%'
        }
      },
      {
        breakpoint:425,
        settings: {
          slidesToShow:1,
          slidesToScroll:1,
          centerMode:true,
          centerPadding:'15%'
        }
      },
      {
        breakpoint:375,
        settings: {
          slidesToShow:1,
          slidesToScroll:1,
          centerMode:true,
          centerPadding:'20px'
        }
      },
    ]
  }
  var trendingsettings = {
    dots: false,
    className: 'center',
    autoplaySpeed: 3000,
    infinite: true,
    centerMode: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding:'0px'
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding:'28%'
        }
      },
      {
        breakpoint:480,
        settings: {
          slidesToShow:1,
          slidesToScroll:1,
          centerMode:true,
          centerPadding:'25%'
        }
      },
      {
        breakpoint:425,
        settings: {
          slidesToShow:1,
          slidesToScroll:1,
          centerMode:true,
          centerPadding:'20%'
        }
      },
      {
        breakpoint:375,
        settings: {
          slidesToShow:1,
          slidesToScroll:1,
          centerMode:true,
          centerPadding:'40px'
        }
      },
    ]
  }

  var newsetting = {
    className: 'center',
    centerMode: true,
    infinite: true,
    adaptiveHeight: true,
    centerPadding: '60px',
    slidesToShow: 1,
    speed: 500,
    slidesToScroll: 1
    // rows: 2,
    // slidesPerRow: 1
  }

  var graphsettings2 = {
    afterChange: next => {
      setActiveSlide(next)
    },
    customPaging: function (i) {
      if (activeSlide === i) {
        return <div className={styles.customPaging}></div>
      } else {
        return <div className={styles.customPagingInactive}></div>
      }
    },
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }
  // (
  // <Container>
  // 	TrendingStockCardSkeleton
  // 	<TrendingStockCardSkeleton />
  // 	PopularStockSkeleton,
  // 	<PopularStockSkeleton />
  // 	StackCardSkeleton,
  // 	<StackCardSkeleton />
  // 	NewsCardSkeleton,
  // 	<NewsCardSkeleton />
  // 	CostDetailCardSkeleton,
  // 	<CostDetailCardSkeleton />
  // 	PortfolioSkeleton
  // 	<PortfolioSkeleton />
  // </Container>
  // )
  // return loading ? (
  // 	<HomeSkeleton />
  // ) :
  // const acceptSiteNotification = async()=>{
  //   if(fpslError){
  //     $('#notificationModal').modal('hide')
  //     return
  //   }
  //   if(!fpslSubmitting){
  //     if(fpslError)
  //       setFpslError(false)
  //     setFpslSubmitting(true)
  //     const res= await fpslAgreeSiteNotification()
  //     if(res && res.code===200){
  //       const res = await getAccountInfo()
  //       if (res){
  //         sessionStorage.setItem('userInfo', JSON.stringify(res));
  //       }
  //       setFpslSubmitting(false)
  //       $('#notificationModal').modal('hide')
  //     }else{
  //       await postSiteNotification(true)
  //       setFpslError(true)
  //       setFpslSubmitting(false)
  //     }
  //   }
  // }
  const acceptSitePopupNotification = async()=>{
    setPopupNotificationSubmitting(true);
    await postSitePopupNotification(false);
    setPopupNotificationSubmitting(false);
      $('#notificationPopup').modal('hide')
  }

  const getPopularHalalStocksList = async() => {
    const response = await getPopularHalalStocks()
    if(response && response.code === 200 && response.data) {
      const popularHalalStocksData = response.data
      if(popularHalalStocksData.length >= 8) {
        setPopularHalalStocks(popularHalalStocksData.slice(0,8))
      } else if(popularHalalStocksData.length >= 4 && popularHalalStocksData.length < 8) {
        setPopularHalalStocks(popularHalalStocksData.slice(0,4))
      } else {
        setPopularHalalStocks([])
      }
    } else {
      setPopularHalalStocks([])
    }
  }

  const getPartnerInformation = async() => {
    const response = await getPartnerData(partnerCodeData)
    if(response && response.code === 200 && response.data) {
      setUserLocation(response.data.location ? response.data.location : '')
    } else {
      setUserLocation('')
    }
  }

  useEffect(()=>{
    getPopularHalalStocksList()
  },[])

  useEffect(()=>{
    if(partnerCodeData) {
      getPartnerInformation()
    }
  },[partnerCodeData])

  const bannerText = "All U.S. exchanges, banks, and financial institutions that are members of the Federal Reserve System, will be closed in observance of Thanksgiving on Thursday, November 28th, 2024, and will close at 1 P.M. ET on Friday, November 29th, 2024"

  let d1=new Date("2024-11-30, 04:00:00");

  let todayDate = new Date() ? new Date : "";

  useEffect(() => {
    const appBanner = async() => {
      let userInfo =  await getAccountInfo();
      if(userInfo && userInfo.userRestriction){
        setAppBannerContent(userInfo.userRestriction.short_msg)
      }
      let bannerShow = todayDate && todayDate !== null ? d1 > todayDate ? true : false : false;
      let StaticBannerShow =  userInfo && userInfo.userRestriction == undefined && bannerShow ? true : false;
      setShowStaticAppBanner(StaticBannerShow)
    }
    appBanner();
  },[])

  return (
    <>
      <Header/>
      {appBannerContent !== "" || showStaticAppBanner ?
      <AppBanner bannerContent={appBannerContent} StaticBannerShow={showStaticAppBanner} bannerText={bannerText} /> : ""}
      <Container style={{marginTop : appBannerContent !== "" || showStaticAppBanner ? "95px" : "48px"}} className={slideeffect ? styles.shiftPos : styles.originalPos}>
        {IsSkeleton ?
          <BannerSkeleton />
          :

          showPANBanner ?
            <PANBanner
            remainingDays={remainingDays}
            liquidateOnly={liquidateOnly}
            firstName={firstName}
            />
            :

          statusBanner ? (
            <>
              {/* {bannerData.type !== "DocumentRejected" && bannerData.type !==  "ApplicationUnderProcess" && bannerData.type !== "KYCPending"  && bannerData.type !== "DocumentPending" && bannerData.type !== "ApplicationBack" && bannerData.type !==  "KYCRejected" ?
                <CetfBanner bannerContent={appBannerContent}/> :""
              } */}
              <div className={bannerData.type === "DocumentRejected" || bannerData.type === "DocumentPending" || bannerData.type === "ApplicationBack" || bannerData.type ===  "KYCRejected" ? styles.firstTimeBigBanner : styles.firstTimeBanner}>
                <StartBanner
                  bannerData={bannerData}
                  firstName={firstName}
                  buttonColor={buttonColor}
                  contactMail={contactMail}
                  location={location}
                />
              </div>
            </>
          ) :
          (
              <>
                <div>
                {/* {showPANBanner ?
                  <PANBanner
                  remainingDays={remainingDays}
                  liquidateOnly={liquidateOnly}
                  firstName={firstName}
                  />
                  : */}
                {/* {bannerData.type !== "DocumentRejected" && bannerData.type !==  "ApplicationUnderProcess" && bannerData.type !== "KYCPending"  && bannerData.type !== "DocumentPending" && bannerData.type !== "ApplicationBack" && bannerData.type !==  "KYCRejected" ?
                  <CetfBanner/> :""
                } */}
                </div>
                <div className={styles.homeMargin}>
                  <HomePortfolio
                    marketOpenTime={currentMarketStatus}
                    userName={firstName}
                  />
                </div>
                <div>
                  <Row className='d-block d-md-none'>
                    <Col>
                      <p className={styles.perform}>Performance</p>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className={styles.legendGraph}></div>
                        <span style={{ marginLeft: '12px' }}>Portolio</span>
                        <div className={styles.legendGraph} style={{ background: '#1E1F24', marginLeft: '16px' }}></div>
                        <span style={{ marginLeft: '12px' }}>S&P500</span>
                      </div>
                      {/* <p className={styles.compare}>Compare with S&P 500</p> */}
                    </Col>
                  </Row>
                </div>
                <div className={styles.graphrowMargin}>
                  {/* for desktop */}
                  <Row className='d-none d-md-flex'>
                    <Col md={7} className={`pl-0 pr-0 ${styles.chartBorder}`}>
                      <StockLineChart />
                    </Col>
                    <Col md={5} className={`pl-0 pr-0 ${styles.chartBorder}`}>
                      <StockPieChart
                        width='300'
                        height='200'
                        data={pieChartData}></StockPieChart>
                    </Col>
                  </Row>
                  {/* for mobile */}
                  <Row className='d-block d-md-none'>
                    <Slider {...graphsettings2} className={styles.grapherSlider}>
                      <StockLineChart />
                      <StockPieChart
                        width='300'
                        height='200'
                        data={pieChartData}></StockPieChart>
                    </Slider>
                  </Row>
                </div>
              </>
        )

        }
        {Object.keys(topStacks).length === 0 ? "" :
        <div className={styles.cardrowMargin}>
          <Row className={styles.heading} style={{ marginBottom: '4px' }}>
            <Col md={6} sm={8} xs={8} style={{ paddingLeft: "0px" }}>
              top performing stacks
            </Col>
            {topStacks && topStacks.length > 0 ?
              <Col md={6} xs={4} sm={4} className={styles.seeAllButton} style={{ textAlign: "right" }}>
                <Link to='/allStacks' className={styles.noLinkStyle}>
                  see all
                </Link>
                <img className={styles.seeAllArrow} src={rightArrow}></img>
              </Col> : ""}
          </Row>
          <Row className={styles.subHeading}>Expert made model portfolios</Row>
          {/* for larger screens*/}
          <Row
            className={`${styles.cardtopMargin} ${styles.trendingWeb} d-none d-md-none d-lg-none d-xl-flex`}>
            {topStacks && topStacks.length > 0 ? (
              topStacks.slice(0, 4).map((item, index) => {
                return (
                  <Col key={index} xl={3}>
                    <StackCard
                      image={item?.logo}
                      ticker={item?.ticker}
                      createdBy={item?.createdByTitle}
                      desc={item?.description}
                      cagr={item?.cagr}
                      minInvest={item?.minimumInvestment}
                    />
                  </Col>
                )
              })
            ) : (
              <div style={{ display: 'flex' }}>
                <StackCardSkeleton />
                <StackCardSkeleton />
                <StackCardSkeleton />
                <StackCardSkeleton />
              </div>
            )}
          </Row>

          {/* for desktop */}
          <Row
            className={`${styles.cardtopMargin} ${styles.trendingWeb} d-none d-md-none d-lg-flex d-xl-none`}>
            {topStacks && topStacks.length > 0 ? (
              topStacks.slice(0, 3).map((item, index) => {
                return (
                  <Col key={index} lg={4} xs={9}>
                    <StackCard
                      image={item?.logo}
                      ticker={item?.ticker}
                      createdBy={item?.createdByTitle}
                      desc={item?.description}
                      cagr={item?.cagr}
                      minInvest={item?.minimumInvestment}
                    />
                  </Col>
                )
              })
            ) : (
              <div style={{ display: 'flex' }}>
                <StackCardSkeleton />
                <StackCardSkeleton />
                <StackCardSkeleton />
              </div>
            )}
          </Row>
          {/* for mobile */}
          <Row
            className={`${styles.cardtopMargin} ${styles.trendingMobile} d-block d-md-block d-lg-none d-xl-none`}>
            {topStacks && topStacks.length > 0 ?
              <Slider {...settings} className={styles.trendingSlider}>
                {topStacks && topStacks.length > 0 && topStacks.slice(0, 4).map((item, index) => {
                  return (
                    // <Col key={index} md={4} sm={3} xs={9}>
                    <StackCard
                      image={item?.logo}
                      ticker={item?.ticker}
                      createdBy={item?.createdByTitle}
                      desc={item?.description}
                      cagr={item?.cagr}
                      minInvest={item?.minimumInvestment}
                    />
                    // </Col>
                  )
                })}
              </Slider>
              :
              <div style={{ display: 'flex' }}>
                <StackCardSkeleton />
                <StackCardSkeleton />
              </div>
            }
          </Row>
        </div>}
        {userLocation === 'MENA' ? <>
        {
       popularHalalStocks !== null && popularHalalStocks.length > 0 ? (
          <div className={styles.cardrowMargin}>
            <Row className={styles.heading}>Popular Halal Stocks</Row>
            {/* for desktop   */}
            <Row className='d-none d-lg-flex'>
              {popularHalalStocks && popularHalalStocks.length > 0 ? (
                popularHalalStocks.slice(0, 4).map((item, index) => {

                  return (
                    <Col key={index} md={3}>
                      <TrendingStocksCard
                        stockImage={item.logo}
                        stockTitle={item.code}
                        stockName={item.company}
                        stockValue={item.price}
                        stockIncrement={item.percentChange}
                        stockChange={item.change}
                        // graphData={data}
                        close={item.price - item.change}
                        increment={
                          item.percentChange > 0 ? true : false
                        }
                        isHalal={true}
                        rating={item.ranking}
                        ></TrendingStocksCard>
                    </Col>
                  )
                })
              ) : (
                <div style={{ display: 'flex' }}>
                  <TrendingStockCardSkeleton />
                  <TrendingStockCardSkeleton />
                  <TrendingStockCardSkeleton />
                  <TrendingStockCardSkeleton />
                </div>
              )}
            </Row>
            <Row className='d-none d-lg-flex' >
              {
                popularHalalStocks && popularHalalStocks.length > 0
                  ? popularHalalStocks.slice(4, 8).map((item, index) => {
                    return (
                      <Col key={index}>
                        <TrendingStocksCard
                          stockImage={item.logo}
                          stockTitle={item.code}
                          stockName={item.company}
                          stockValue={item.price}
                          stockIncrement={item.percentChange}
                          close={item.price - item.change}
                          stockChange={item.change}
                          // graphData={data}
                          increment={
                            item.percentChange > 0 ? true : false
                          }
                          isHalal={true}
                          rating={item.ranking}
                          ></TrendingStocksCard>
                      </Col>
                    )
                  })
                  :
                  <div style={{ display: 'flex' }}>
                    <TrendingStockCardSkeleton />
                    <TrendingStockCardSkeleton />
                    <TrendingStockCardSkeleton />
                    <TrendingStockCardSkeleton />
                  </div>
              }
            </Row>
            {/* for mobile   */}
            <Row className={`d-block d-lg-none`}>
              <Col>
                <Slider
                  {...trendingsettings}
                  className={styles.trendingStocksSlider}>
                  {popularHalalStocks.map((item, index) => {
                    return (
                        <TrendingStocksCard
                          stockImage={item.logo}
                          stockTitle={item.code}
                          stockName={item.company}
                          stockValue={item.price}
                          stockIncrement={item.percentChange}
                          close={item.price - item.change}
                          // graphData={data}
                          increment={
                            item.percentChange > 0 ? true : false
                          }
                          isHalal={true}
                          rating={item.ranking}
                          ></TrendingStocksCard>
                    )
                  })}
                </Slider>
              </Col>
            </Row>
          </div>
        ) : (
          ''
        )}
        </> : ''}
        {popularEtf === null || popularStocks === null ?
          "" :
          <div className={styles.cardrowMargin}>
            <Row>
              <Col
                // className={styles.popularPadding}
                className={styles.mobilePadding}
                md={6}>
                <Row className={`${styles.heading} ${styles.popularMargin}`}>
                  popular ETFs
                </Row>
                {popularEtf !== null && popularEtf.length > 0 && popularStocksLoad && popularEtfLoad ? (
                  popularEtf.slice(0, 5).map((item, index) => {
                    // getPricesFromSocket(item.code);
                    return (
                      <Row
                        key={index}
                        style={{
                          display: 'contents'
                        }}>
                        <PopularStock
                          stockImage={item?.logo}
                          stockTitle={item?.code}
                          stockName={item?.company}
                          stockValue={item?.price}
                          stockIncrement={item?.percentChange}
                          stockChange={item?.change}
                          stockType={item?.stockType}
                          close={item?.price - item?.change}
                          // graphData={data}
                          increment={
                            item?.percentChange > 0 ? true : false
                          }></PopularStock>
                      </Row>
                    )
                  })
                ) : (
                  <div>
                    <PopularStockSkeleton />
                    <PopularStockSkeleton />
                    <PopularStockSkeleton />
                    <PopularStockSkeleton />
                    <PopularStockSkeleton />
                    {/* <PopularStockSkeleton /> */}
                  </div>
                )}
              </Col>
              <Col
                // className={`${styles.popularPadding} ${styles.marginStocks}`}
                className={styles.mobilePadding}
                md={6}>
                <Row className={`${styles.heading} ${styles.popularMargin} ${styles.mobileMarginPopularStocks}`}>
                  popular stocks
                </Row>
                {popularStocks !== null && popularStocks.length && popularStocksLoad && popularEtfLoad > 0 ? (
                  popularStocks.slice(0, 5).map((item, index) => {
                    return (
                      <Row key={index} style={{ display: 'contents' }}>
                        <PopularStock
                          stockImage={item?.logo}
                          stockTitle={item?.code}
                          stockName={item?.company}
                          stockValue={item?.price}
                          stockIncrement={item?.percentChange}
                          stockChange={item?.change}
                          stockType={item?.stockType}
                          close={item?.price - item?.change}
                          // graphData={data}
                          increment={
                            item?.percentChange > 0 ? true : false
                          }></PopularStock>
                      </Row>
                    )
                  })
                ) : (
                  <div>
                    <PopularStockSkeleton />
                    <PopularStockSkeleton />
                    <PopularStockSkeleton />
                    <PopularStockSkeleton />
                    <PopularStockSkeleton />
                    {/* <PopularStockSkeleton /> */}
                  </div>
                )}
              </Col>
            </Row>
          </div>}
       {
       trendingStocks !== null && trendingStocks.length > 0 ? (
          <div className={styles.cardrowMargin}>
            <Row className={styles.heading}>trending stocks</Row>
            {/* for desktop   */}
            <Row className='d-none d-lg-flex'>
              {trendingStocks && trendingStocks.length > 0 ? (
                trendingStocks.slice(0, 4).map((item, index) => {
                  return (
                    <Col key={index} md={3}>
                      <TrendingStocksCard
                        stockImage={item?.logo}
                        stockTitle={item?.code}
                        stockName={item?.company}
                        stockValue={item?.price}
                        stockIncrement={item?.percentChange}
                        stockChange={item?.change}
                        // graphData={data}
                        close={item?.price - item?.change}
                        increment={
                          item?.percentChange > 0 ? true : false
                        }></TrendingStocksCard>
                    </Col>
                  )
                })
              ) : (
                <div style={{ display: 'flex' }}>
                  <TrendingStockCardSkeleton />
                  <TrendingStockCardSkeleton />
                  <TrendingStockCardSkeleton />
                  <TrendingStockCardSkeleton />
                </div>
              )}
            </Row>
            <Row className='d-none d-lg-flex' >
              {
                trendingStocks && trendingStocks.length > 0
                  ? trendingStocks.slice(4, 8).map((item, index) => {
                    return (
                      <Col key={index}>
                        <TrendingStocksCard
                          stockImage={item.logo}
                          stockTitle={item.code}
                          stockName={item.company}
                          stockValue={item.price}
                          stockIncrement={item.percentChange}
                          close={item.price - item.change}
                          stockChange={item.change}
                          // graphData={data}
                          increment={
                            item.percentChange > 0 ? true : false
                          }></TrendingStocksCard>
                      </Col>
                    )
                  })
                  :
                  <div style={{ display: 'flex' }}>
                    <TrendingStockCardSkeleton />
                    <TrendingStockCardSkeleton />
                    <TrendingStockCardSkeleton />
                    <TrendingStockCardSkeleton />
                  </div>
              }
            </Row>
            {/* for mobile   */}
            <Row className={`d-block d-lg-none`}>
              <Slider
                {...trendingsettings}
                className={styles.trendingStocksSlider}>
                {trendingStocks.slice(0, 8).map((item, index) => {
                  return (
                    <Col key={index} md={12}>
                      <TrendingStocksCard
                        stockImage={item.logo}
                        stockTitle={item.code}
                        stockName={item.company}
                        stockValue={item.price}
                        stockIncrement={item.percentChange}
                        close={item.price - item.change}
                        // graphData={data}
                        increment={
                          item.percentChange > 0 ? true : false
                        }></TrendingStocksCard>
                    </Col>
                  )
                })}
              </Slider>
            </Row>
          </div>
        ) : (
          ''
        )}
        <div className={styles.AllYearCardPadding}>
          <Row>
            <Col
              md={6}
              lg={4}
              // style={{
              // 	display: highStocks.length === 0 && 'none'
              // }}
              className={styles.popularPadding}
            >
              <Row className={`${styles.heading}`}>
                52 week high
              </Row>
              {yearHighStock && yearHighStock.length > 0 && yearLowStock && yearLowStock.length > 0 ? (
                yearHighStock.slice(0, 5).map((item, index) => {
                  return (
                    <Row key={index} className={styles.responsiveMargin}>
                      <PopularStockWithoutGraph
                        stockImage={item.logo}
                        stockTitle={item.code}
                        stockName={item.company}
                        stockValue={item.price}
                        stockIncrement={item.percentChange}
                        stockType={item.stockType}
                        stockChange={item.change}
                        close={item.price - item.change}
                        // graphData={data}
                        increment={
                          item.percentChange > 0 ? true : false
                        }></PopularStockWithoutGraph>
                    </Row>
                  )
                })
              ) : (
                <div>
                  <WeekHighLowSkeleton />
                  <WeekHighLowSkeleton />
                  <WeekHighLowSkeleton />
                  <WeekHighLowSkeleton />
                  <WeekHighLowSkeleton />
                </div>
              )}
            </Col>
            <Col
              md={6}
              lg={4}
            // className={`${styles.popularPadding} ${styles.marginStocks}`}
            // style={{ display: lowStocks.length === 0 && 'none' }}
            >
              <Row className={`${styles.heading} ${styles.weekLowMobileMargin}`}>
                52 week low
              </Row>
              {yearLowStock && yearLowStock.length > 0 && yearHighStock && yearHighStock.length > 0 ? (
                yearLowStock.slice(0, 5).map((item, index) => {
                  return (
                    <Row
                      key={index}
                      style={{ display: yearLowStock.length === 0 && 'none' }}
                      className={styles.responsiveMargin}>
                      <PopularStockWithoutGraph
                        stockImage={item.logo}
                        stockTitle={item.code}
                        stockName={item.company}
                        stockValue={item.price}
                        stockType={item.stockType}
                        stockIncrement={item.percentChange}
                        stockChange={item.change}
                        close={item.price - item.change}
                        // graphData={data}
                        increment={
                          item.percentChange > 0 ? true : false
                        }></PopularStockWithoutGraph>
                    </Row>
                  )
                })
              ) : (
                <div>
                  <WeekHighLowSkeleton />
                  <WeekHighLowSkeleton />
                  <WeekHighLowSkeleton />
                  <WeekHighLowSkeleton />
                  <WeekHighLowSkeleton />
                </div>
              )}
            </Col>
            <Col md={12} lg={4}>
              <Row style={{ marginBottom: '70px' }}></Row>
              <StockWeek />
            </Col>
          </Row>
        </div>
        <div className={styles.cardrowMargin}>
					<Row className={styles.heading} style={{ marginBottom: '24px' }}>
            <Col md={6} sm={8} xs={8} style={{ paddingLeft: "0px" }}>
              explore themes
            </Col>
            <Col md={6} xs={4} sm={4} className={styles.seeAllButton} style={{ textAlign: "right" }}>
                <Link to='/themes' className={styles.noLinkStyle}>
                  see all
                </Link>
                <img className={styles.seeAllArrow} src={rightArrow}></img>
            </Col>
					</Row>
					<Row
						className='d-none d-lg-flex'
						style={{ marginLeft: '0px', marginRight: '0px' }}>
						{themes && themes.length > 0 ?
            (themes.slice(0, 4).map((item, index) => {
							return (
								<Col key={index}>
									<ExploreThemes image={item.image} text={item.name} />
								</Col>
							)
						}))
            :
            <div style={{ display: 'flex' }}>
              <ThemeSkeleton/>
              <ThemeSkeleton/>
              <ThemeSkeleton/>
              <ThemeSkeleton/>
            </div>
            }
					</Row>
					<Row className={`d-block d-lg-none ${styles.responsiveMargin}`}>
						<Slider {...themeSettings} className={styles.themeSlider}>
							{themes.slice(0, 4).map((item, index) => {
								return (
									<Col key={index}>
										<ExploreThemes image={item.image} text={item.name} />
									</Col>
								)
							})}
						</Slider>
					</Row>
				</div>
        {gainerStocks == null || loserStocks == null ?
          "" :
          <div className={styles.topGainerLoserPadding}>
            <Row>
              <Col
                md={6}
                lg={6}
              // className={styles.popularPadding}
              >
                <Row className={`${styles.heading} ${styles.mobileMargin52}`}>
                  top gainers
                </Row>
                {gainerStocks.length > 0 && topGainerLoad && topLoserLoad ?
                  gainerStocks.slice(0, 5).map((item, index) => {
                    return (
                      <Row key={index} style={{ display: 'contents' }}>
                        <PopularStock
                          stockImage={item.logo}
                          stockTitle={item.code}
                          stockName={item.company}
                          stockValue={item.price}
                          stockIncrement={item.percentChange}
                          stockType={item.stockType}
                          stockChange={item.change}
                          close={item.price - item.change}
                          increment={
                            item.percentChange > 0 ? true : false
                          }></PopularStock>
                      </Row>
                    )
                  }) : (
                    <div>
                      <PopularStockSkeleton />
                      <PopularStockSkeleton />
                      <PopularStockSkeleton />
                      <PopularStockSkeleton />
                      <PopularStockSkeleton />
                    </div>
                  )}
              </Col>
              <Col
                md={6}
                lg={6}
              // className={`${styles.popularPadding} ${styles.marginStocks}`}
              >
                <Row className={`${styles.heading} ${styles.mobileMargin52}`}>
                  top losers
                </Row>
                {loserStocks.length > 0 && topGainerLoad && topLoserLoad ?
                  loserStocks.slice(0, 5).map((item, index) => {
                    return (
                      <Row key={index} style={{ display: 'contents' }}>
                        <PopularStock
                          stockImage={item.logo}
                          stockTitle={item.code}
                          stockName={item.company}
                          stockValue={item.price}
                          stockIncrement={item.percentChange}
                          stockType={item.stockType}
                          stockChange={item.change}
                          close={item.price - item.change}
                          // graphData={data}
                          increment={
                            item.percentChange > 0 ? true : false
                          }></PopularStock>
                      </Row>
                    )
                  }) : (
                    <div>
                      <PopularStockSkeleton />
                      <PopularStockSkeleton />
                      <PopularStockSkeleton />
                      <PopularStockSkeleton />
                      <PopularStockSkeleton />
                    </div>
                  )}
              </Col>
            </Row>
          </div>}
        <div className={`${styles.cardrowMargin} mb-3`}>
          <Row className={styles.heading}>News</Row>
          <div className='d-none d-lg-block'>
            <Row>
              <Col>
                {newsFilter && newsFilter.length > 0 ? (
                  newsFilter.slice(0, 3).map((item, index) => {
                    return (
                      <NewsCard
                        date={item.Date}
                        image={item.Images[0]}
                        headline={item.Title}
                        description={item.Summary}
                        targetModal={`modal${index}`}
                        link={item.Url}
                        index={index}
                      />
                    )
                  })
                ) : (
                  <div style={{ display: 'flex' }} className='d-none d-lg-flex'>
                    <NewsCardSkeleton />
                    <NewsCardSkeleton />
                    <NewsCardSkeleton />
                    <NewsCardSkeleton />
                  </div>
                )}
              </Col>
              <Col>
                {newsFilter && newsFilter.length > 0 ? (
                  newsFilter.slice(3, 6).map((item, index) => {
                    return (
                      <NewsCard
                        date={item.Date}
                        image={item.Images[0]}
                        headline={item.Title}
                        description={item.Summary}
                        targetModal={`modal1${index}`}
                        link={item.Url}
                        index={index}
                      />
                    )
                  })
                ) : (
                  <div style={{ display: 'flex' }} className='d-none d-lg-flex'>
                    <NewsCardSkeleton />
                    <NewsCardSkeleton />
                    <NewsCardSkeleton />
                    <NewsCardSkeleton />
                  </div>
                )}
              </Col>
            </Row>
          </div>
          <Row className={`d-block d-lg-none ${styles.responsiveMargin}`}>
            <Slider {...settingsNews} className={styles.newsSlider}>
              {newsFilter && newsFilter.length > 0 && newsFilter.slice(0, 10).map((item, index) => {
                return (
                  <Col key={index}>
                    <NewsCard
                      date={item.Date}
                      image={item.Images[0]}
                      headline={item.Title}
                      description={item.Summary}
                      targetModal={`modal${index}`}
                      link={item.Url}
                    />
                  </Col>
                )
              })}
            </Slider>
          </Row>
        </div>
        {/* <div className={styles.footerMargin}>
          <Footer />
        </div> */}
        <div className={styles.sideBarMobileHide}>
          <SideBar handleEffect={handleEffect} />
        </div>
        {showPlanModal ? <PricingPlan
          setPlanButton={settingPlanBanner}
          pageType="new"
          show={showPlanModal}
          handleClose={handlePlanClose}
          isUserSet={() => props.isUserSet()}
        // redirectionTo="/home"
        />
          : ''}
        {successModal ? <Success show={successModal} message={successMessage} /> : ''}
      </Container>
      <div className="modal" id="notificationModal" data-backdrop="static" data-keyboard="false">
          <div className={`modal-dialog modal-lg modal-dialog-centered ${styles.notificationModal}`}>
            <div className="modal-content">
                <div style={{padding: '1rem', background: '#F4F4F4'}}>
                  <p className={`modal-title ${styles.notificationHeading}`}>{siteModalData&&siteModalData.headerText}</p>
                </div>
              {reActivatedSuccessfully ?
              <div className={`my-3 ${styles.notificationModalOuter}`}>
                <div className={`modal-body pt-0 ${styles.notificationModalBody}`}>
                  <img src={tickImage} style={{width:"16px",height:'16px',marginRight:"12.68px",textAlign:"center"}}/>
                  {siteModalData.successMsg}
                </div>
              </div>
              :
              <>
              <div className={`my-3 ${styles.notificationModalOuter}`}>
                <div className={`modal-body pt-0 ${styles.notificationModalBody}`}>
                  <div dangerouslySetInnerHTML={{__html: siteModalData&&siteModalData.template}}/>
                </div>
              </div>
              <div className={styles.notificationFooter}>
                <div className='d-flex flex-column'>
                <SignUpInput
									label='Your Signature'
									placeholder='Enter Your Signature'
									type='text'
									name='signature'
									value={signature}
									onChange={(e) => setSignature(e.target.value)}
								/>
								<div className={styles.signatureText} style={{color : nameMatchError ?  "red" : "#767C8F"}}>
                  Please enter your full name as your signature.
								</div>
                  {/* <span>By clicking on accept you agree to the updated terms & conditions</span> */}
                  {/* {fpslError && <span className={styles.notificationAcceptError}>We are not able to enable Securities lending facility for you. Please retry later</span>} */}
                </div>

                <button type="button" className={`${styles.notificationBtn} ${fpslSubmitting &&styles.notificationDisableBtn}`}
                disabled={laodingOfSignatureMatch}
                onClick={() => accountReopen(siteModalData)}
                // onClick={acceptSiteNotification}
                >
                    {fpslSubmitting && <Spinner animation="border" className={styles.spinnerColor}/>}
                    {/* <span>{!fpslError? 'Accept':'Remind me later'}</span> */}
                    <span>{siteModalData && siteModalData.buttonText}</span>
                </button>
              </div>
              </>}
            </div>
          </div>
        </div>
      <div className="modal" id="notificationPopup" data-backdrop="static" data-keyboard="false">
        <div className={`modal-dialog modal-lg modal-dialog-centered ${styles.notificationModal}`}>
          <div className="modal-content">
            <div style={{padding: '1rem', background: '#F4F4F4'}}>
              <p className={`modal-title ${styles.notificationHeading}`}>{sitePopupData&&sitePopupData.headerText}
                {sitePopupData&&sitePopupData.showCloseBtn ?
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true" className={styles.cancelBtn}>&times;</span>
                </button>:''}
              </p>
            </div>
            <div className={`my-3 ${styles.notificationModalOuter}`}>
              <div className={`modal-body pt-0 ${styles.notificationModalBody}`}>
                <div dangerouslySetInnerHTML={{__html: sitePopupData&&sitePopupData.template}}/>
              </div>
            </div>
            <div className={styles.notificationFooter}>
              <div className='d-flex flex-column'>
                <span></span>
              </div>
              <button type="button" className={`${styles.notificationBtn} ${popupNotificationSubmitting &&styles.notificationDisableBtn}`} onClick={acceptSitePopupNotification}>
                {popupNotificationSubmitting && <Spinner animation="border" className={styles.spinnerColor}/>}
                <span>{sitePopupData&&sitePopupData.buttonText}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home
