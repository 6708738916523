import React, { useState, useEffect } from 'react'
import Select from 'react-select';
import styles from './setupOtherBankAccount.module.css'
import { Row, Col } from 'react-bootstrap'
import backImage from '../../public/images/back.png'
import ProfileDropdown from '../Profile/EditUserBasicDetail/ProfileDropDown'
import { getBankList, getPartnerCountries } from '../../api'
import { map, keyBy, filter } from 'lodash'
import { validateFields } from '../../utils'
import { BLANK_FIELD_ERR_MSG } from '../../displayMessages'

const customStyle = () => (
{
	container: provided => ({
		...provided,
    backgroundColor:'#efeff1',
    color:'black',
		width: '100%',
		top: '50%',
    borderRadius:'8px'
	}),
	option: (styles, { isFocused, isSelected }) => {
		return {
			...styles,
			color: isSelected || isFocused ? 'black' : 'black', // setting
			backgroundColor: isSelected || isFocused ? '#EFEFF1' : 'white',

			':active': {
				...styles[':active'],
				backgroundColor: 'white'
			}
		}
	},
	singleValue: styles => ({
    ...styles,
    color: '#1e1f24',
	}),
	indicatorSeparator: provided => ({ ...provided, visibility: 'hidden' }),
	control: base => ({
		...base,
		borderWidth: 0,
    borderRadius: 8,
    height: 48,
    'font-size': 14,
	'line-height': 20,
	'letter-spacing': 0.6,
	  color: '#9096a8',
    // 'font-family':,
    backgroundColor:'#EFEFF1',
		border: 0,
		boxShadow: 'none',
		'&:hover': {
			border: 0
    },
	}),
	menu: base => ({
		...base,
		borderRadius: 8,
	}),
})
const accountTypeOptions = [{value: "CHECKING", label: "CHECKING"}, {value: "SAVINGS", label: "SAVINGS"}];

const requiredKeys = [
  'accountNumber',
  'beneficiaryAccountTypeWithdrawal',
  'beneficiarySwiftABA',
  'bankName',
  'bankAddress',
  'bankCity',
  'bankState',
  'bankPostal',
  'bankCountry',
  'isMyAccount',
];

const SetupOtherBankAccount = ({
	buttonColor,
	setBankDetail,
	bankDetail,
  setCurrent,
  savedBank,
  saveBankAndReview,
  errorMessage,
  OTHER_BANK_NAME,
  editableFields,
  loading,
  countryID,
  fullname,
  back,
  error
}) => {

  const [check, setCheck] = useState(true)
  const [name, setName] = useState('')
  const [bankName, setBankName] = useState({label: bankDetail['bank']['name'], value: bankDetail['bank']['code']});
  const [bankNameObject, setBankNameObject] = useState({label: bankDetail['bank']['name'], value: bankDetail['bank']['code']});
  const [bankCountryObj, setBankCountryObj] = useState({label: '', value: bankDetail['bankCountry']});
  const [accountTypeObj, setAccountTypeObj] = useState({label: bankDetail['beneficiaryAccountTypeWithdrawal'], value: bankDetail['beneficiaryAccountTypeWithdrawal']});
  const [otherbankName, setotherbankName] = useState(bankDetail['bankName']);
  const [beneficiaryAccountTypeWithdrawal, setAccountType] = useState(bankDetail['beneficiaryAccountTypeWithdrawal'])
  const [bankCountry, setBankCountry] = useState(bankDetail['bankCountry'])
  const [bankNameOptions, setBankNameOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [uniqueBankNames, setUniqueBanks] = useState({});
	const handleChange = e => {
		const re = /^[A-Za-z0-9\s]+$/
		if (e.target.value === '' || re.test(e.target.value)) {
			setBankDetail({
				...bankDetail,
				[e.target.name]: e.target.value
			})
		}
  }

  const isValidated = (field, value) => {
    if(field === 'beneficiarySwiftABA') {
      if(!(value.length <= 11)){
        return false;
      }
    }
    return true;
  }

  const onChangeNormalInputs = e => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value
    if(isValidated(fieldName, fieldValue)){
      setBankDetail({
        ...bankDetail,
        [e.target.name]: e.target.value
      })
    }
  }

	useEffect(() => {
		const { firstName, lastName, middleName } = JSON.parse(sessionStorage.getItem('userInfo'))
    setName(fullname)

    getBankNameList();
    getCountries();
  }, [])



  const checkWithdrawConditions = () => {
    saveBankAndReview();
  }

  const getBankNameList = async() => {
    try {
      let resp = await getBankList();
      if(resp.data && resp.data.length > 0) {
        const uniBankNames = keyBy(resp.data, 'name')
        setUniqueBanks(uniBankNames)
        let opt = map(uniBankNames, ({code, name, _id}) => {
          return { label: name, value: code, bankID: _id}
        })
        setBankNameOptions(opt);
      }
    } catch(error){
      setBankName('');
      setBankNameOptions([]);
    }
  }

  const getCountries = async() => {
    try {
      let selectedCountry;
      const resp = await getPartnerCountries('sourceoffunds');
      if(resp &&resp.code === 200 && resp.data && resp.data.length > 0) {
        let opt = [...new Set(map(resp.data, ({country, countryName}) => {
          return { label: countryName, value: country}
        }))]
        setCountryOptions(opt);
        if(bankCountry && bankCountry !== ""){
          selectedCountry = filter(opt, ({value}) => value === bankCountry )
          if(selectedCountry.length === 0){
            selectedCountry = [{label: bankCountry, value: bankCountry}]
          }

        } else {
          selectedCountry = filter(opt, ({value}) => value === countryID )
          setBankDetail({
            ...bankDetail,
            bankCountry: countryID
          })
        }
        setBankCountryObj(selectedCountry[0])
      }
    } catch(error){
      setBankCountry('');
      setCountryOptions([]);
    }
  }

  useEffect(() => {
    let {blankKeys, missingKeys} = validateFields(requiredKeys, bankDetail);
    if ((blankKeys && blankKeys.length <= 0 && missingKeys.length <= 0)) {
      if((OTHER_BANK_NAME.includes(bankName) && otherbankName === '') || !bankDetail.isMyAccount){
        setCheck(true);
      } else {
        setCheck(false);
      }
    } else {
      setCheck(true);
    }
  }, [bankDetail, otherbankName, bankName])


	return (
		<div className={styles.card}>
			<Row>
				<Col>
					<div
						className={styles.title}
						onClick={() => setCurrent(back)}
						style={{ cursor: 'pointer' }}>
						<img src={backImage} />
						<span
							style={{
								paddingLeft: '20px'
							}}>
							Setup Withdrawal
						</span>
					</div>
				</Col>
			</Row>
			<Row>
				<Col>
					<div className={styles.description}>
						<span className={styles.important}>Important:</span> Please make
						sure to enter correct bank details as this will be set as default
						and you will be allowed to withdraw funds only from this account.
					</div>
				</Col>
			</Row>
			<Row>
				<Col md={12}>
					<div className={styles.holder}>Account holder Name</div>
				</Col>
				<Col md={12}>
					<div className={styles.name}>{name}</div>
				</Col>
			</Row>
			<Row>
				<Col md={6} style={{ marginTop: '32px' }}>
        <div className={`${styles.heading} ${styles.requiredInput}`}>Bank’s Name</div>
        <div className={`m-0 ${styles.dropdownInputFont}`}>
        <Select
        placeholder=''
        value={bankNameObject}
        onChange={(value) => {
          setBankNameObject(value)
          setBankName(value.value)
          setBankDetail({
            ...bankDetail,
            bankName: value.label,
            nationalBankId: value.bankID,
          })
        }}
        options={bankNameOptions}
        styles={customStyle()}
        isSearchable={true}
        isDisabled={editableFields.bankName}
      />
      </div>
				</Col>
        {
          bankNameObject.value !== '' && OTHER_BANK_NAME.includes(bankNameObject.value) ?
          <Col md={6} style={{ marginTop: '32px' }}>
            <div className={`${styles.heading} ${styles.requiredInput}`}>Specify Other Bank Name</div>
            <input
              className={styles.bankInput}
              placeholder='Specify Bank name'
              onChange={(e) => {
                setotherbankName(e.target.value);
                onChangeNormalInputs(e);
              }}
              value={otherbankName}
              name='otherbankName'
              disabled={editableFields.bankName}
            />
				  </Col> : null
        }
        <Col md={6} style={{ marginTop: '32px' }}>
					<div className={`${styles.heading} ${styles.requiredInput}`}>Bank account number / IBAN</div>
					<input
						className={styles.bankInput}
						placeholder='Enter account number / IBAN'
						onChange={onChangeNormalInputs}
            value={bankDetail.accountNumber}
            name="accountNumber"
            disabled={editableFields.accountNumber}
					/>
				</Col>
        <Col md={6} style={{ marginTop: '32px' }}>
        <div className={`${styles.heading} ${styles.requiredInput}`}>Account Type</div>
        <div className={`m-0 ${styles.dropdownInputFont}`}>
          <Select
          placeholder=''
          value={accountTypeObj}
          onChange={(value) => {
            setAccountTypeObj(value)
            setAccountType(value.value)
            setBankDetail({
              ...bankDetail,
              beneficiaryAccountTypeWithdrawal: value.value
            })
          }}
          options={accountTypeOptions}
          styles={customStyle()}
          isSearchable={true}
          isDisabled={editableFields.beneficiaryAccountTypeWithdrawal}
        />
        </div>
				</Col>
        <Col md={6} style={{ marginTop: '32px' }}>
					<div className={styles.heading}>IFSC</div>
					<input
						className={styles.bankInput}
						placeholder='EnterIFSC'
						onChange={onChangeNormalInputs}
            value={bankDetail.bankIFSC}
            name="bankIFSC"
            disabled={editableFields.bankIFSC}
					/>
				</Col>
        <Col md={6} style={{ marginTop: '32px' }}>
					<div className={`${styles.heading} ${styles.requiredInput}`}>Bank's SWIFT/ABA</div>
					<input
						className={styles.bankInput}
						placeholder="Enter Bank's SWIFT/ABA"
						onChange={onChangeNormalInputs}
            value={bankDetail.beneficiarySwiftABA}
            name="beneficiarySwiftABA"
            disabled={editableFields.beneficiarySwiftABA}
					/>
				</Col>
        <Col md={6} style={{ marginTop: '32px' }}>
					<div className={`${styles.heading} ${styles.requiredInput}`}>Bank’s Address</div>
					<input
						className={styles.bankInput}
						placeholder='Enter Bank address'
						onChange={onChangeNormalInputs}
            value={bankDetail.bankAddress}
            name="bankAddress"
            disabled={editableFields.bankAddress}
					/>
				</Col>
        <Col md={6} style={{ marginTop: '32px' }}>
					<div className={`${styles.heading} ${styles.requiredInput}`}>Bank City</div>
					<input
						className={styles.bankInput}
						placeholder='Enter Bank City'
						onChange={onChangeNormalInputs}
            value={bankDetail.bankCity}
            name="bankCity"
            disabled={editableFields.bankCity}
					/>
				</Col>
        <Col md={6} style={{ marginTop: '32px' }}>
					<div className={`${styles.heading} ${styles.requiredInput}`}>Bank State/Provience</div>
					<input
						className={styles.bankInput}
						placeholder='Enter Bank State/Provience'
						onChange={onChangeNormalInputs}
            value={bankDetail.bankState}
            name="bankState"
            disabled={editableFields.bankState}
					/>
				</Col>
        <Col md={6} style={{ marginTop: '32px' }}>
					<div className={`${styles.heading} ${styles.requiredInput}`}>Bank ZIP/Postal Code</div>
					<input
						className={styles.bankInput}
						placeholder='Enter Bank ZIP/Postal Code'
            onChange={handleChange}
            value={bankDetail.bankPostal}
            name="bankPostal"
            disabled={editableFields.bankPostal}
					/>
				</Col>
        <Col md={6} style={{ marginTop: '32px' }}>
        <div className={`${styles.heading} ${styles.requiredInput}`}>Bank Country</div>
        <div className={`m-0 ${styles.dropdownInputFont}`}>
          <Select
          placeholder=''
          value={bankCountryObj}
          onChange={(value) => {
            setBankCountryObj(value)
            setBankCountry(value.value)
            setBankDetail({
              ...bankDetail,
              bankCountry: value.value
            })
          }}
          options={countryOptions}
          styles={customStyle()}
          isSearchable={true}
          isDisabled={editableFields.bankCountry}
        />
        </div>
				</Col>
        <Col md={6} style={{ marginTop: '32px' }}>
					<div className={styles.heading}>Intermediary/Correspondent Bank name</div>
					<input
						className={styles.bankInput}
						placeholder='Intermediary/Correspondent Bank name'
            onChange={e =>
							setBankDetail({ ...bankDetail, intermediaryBank: e.target.value })
						}
            value={bankDetail.intermediaryBank}
            disabled={editableFields.intermediaryBank}
					/>
				</Col>
        <Col md={6} style={{ marginTop: '32px' }}>
					<div className={styles.heading}>Intermediary/Correspondent Swift number</div>
					<input
						className={styles.bankInput}
						placeholder='Intermediary/Correspondent swift number'
            onChange={e =>
							setBankDetail({ ...bankDetail, intermediarySwiftNumber: e.target.value })
						}
            value={bankDetail.intermediarySwiftNumber}
            disabled={editableFields.intermediarySwiftNumber}
					/>
				</Col>
			</Row>
			<Row style={{ marginTop: '24px' }} className={styles.marginBankAccount}>
				<Col>
					<div>
						<input
							type='checkbox'
							onChange={() =>
								setBankDetail({
									...bankDetail,
									isMyAccount: !bankDetail.isMyAccount
								})
							}
							className={styles.box}
							checked={bankDetail.isMyAccount}
						/>

						<span className={styles.agree}>
							I agree that this bank account belongs to me
						</span>
					</div>
				</Col>
			</Row>
      {
        errorMessage && errorMessage !== '' || error !== '' ?
        <Row style={{ marginTop: '24px' }}>
				 <p className={styles.withdrawErrorText}>{errorMessage || error}</p>
			</Row> : null
      }
			<Row className={styles.fixedDiv}>
				<Col>
					<button
						onClick={checkWithdrawConditions}
						type='button'
						style={{ backgroundColor: buttonColor }}
						disabled={check || loading}
						className={`btn btn-primary btn-sm ${styles.addfunds} themeButton`}
            >
						<span className={styles.addfundstext}>Continue</span>
					</button>
				</Col>
			</Row>
		</div>
	)
}

export default SetupOtherBankAccount
