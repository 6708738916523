import React from 'react'
import { Row, Col } from 'react-bootstrap'
import ReactHighCharts from 'react-highcharts'

const IncomeCashFlowChart = ({data,categories}) => {

  const custoumOption = () => {
   return{
    chart: {
      type: 'column',
      height:250
    },
    title: {
        text: undefined
    },
    xAxis: {
      categories: categories,
      crosshair: true,
      lineWidth:1,
      tickWidth:0,
  },
  yAxis: {
    visible: false
  },
  legend: {
    enabled: false
  },
  tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
  },
  credits: {
    enabled: false
  },
  plotOptions: {
      column: {
          pointPadding: 0.2,
          borderWidth: 0
      },
      series: {
        borderRadius: 0,
        dataLabels: {
          enabled: true
      }
    }
  },
  // colors:['#AAACB2','#3C404A'],
  series: [{
      name: '',
      data: data,
      color: {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 1
        },
        stops: [
          [0, '#3C404A'],
          [1, '#9096A8']
        ]
      }
    }]
   }
  }

  return(
    <div>
      <ReactHighCharts config={custoumOption()}/>
    </div>
  )

}
export default IncomeCashFlowChart
