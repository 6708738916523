import React, { useEffect, useState } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { Header ,SideBar } from '../../components'
import styles from './themes.module.css'
import {getThemes, getThemesDetail} from '../../api'
import { ExploreThemes } from '../../components'
import { ThemeSkeleton } from '../../skeletons'

const Themes = () => {
  const [themes, setthemes] = useState([])
  const [slideeffect, setslideeffect] = useState(false)
  const [themeDetails,setThemeDetail] = useState([])

  const handleEffect = () => {
		setslideeffect(prevState => setslideeffect(!prevState))
	}

  useEffect(() => {
    const asynchFetch = async () => {
      getThemes().then(themesres => {
        if (themesres && themesres.length > 0) {
          setthemes(
            themesres && themesres.length > 0
              ? [...themesres]
              : null
          )
        }
        else {
          console.log('no themes found')
        }
      })
    }
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
  asynchFetch()
  }, [])

  // const themeClick = async (e) => {
  //     getThemesDetail(e).then(themeDetailres => {
  //       if(themeDetailres && themeDetailres.length > 0 ){
  //         setThemeDetail(themeDetailres)
  //       }
  //     }
  //   )
  // }

  return(
    <>
      <Header/>
      <Container className={slideeffect ? styles.shiftPos : styles.originalPos}>
        <div className={styles.cardrowMargin}>
					<Row className={styles.heading}>
            <Col md={6} sm={8} xs={8}>
              Themes
            </Col>
					</Row>
					<Row>
						{themes && themes.length > 0 ?
            (themes.map((item, index) => {
							return (
                <Col lg={3} sm={6} md={6} xs={6} className={styles.themeRowMargin}>
                <ExploreThemes image={item.image} text={item.name} />
                </Col>
							)
						})):
            <>
            <div className={styles.webShimmerTheme}>
              <div style={{ display: 'flex' }}>
                <ThemeSkeleton/>
                <ThemeSkeleton/>
                <ThemeSkeleton/>
                <ThemeSkeleton/>
              </div>
              <div style={{ display: 'flex' }}>
              <ThemeSkeleton/>
              <ThemeSkeleton/>
              <ThemeSkeleton/>
              <ThemeSkeleton/>
              </div>
              <div style={{ display: 'flex' }}>
              <ThemeSkeleton/>
              <ThemeSkeleton/>
              <ThemeSkeleton/>
              <ThemeSkeleton/>
            </div>
            </div>

            {/* mobile shimmer */}
            <div className={styles.tabletShimmer}>
              <div style={{ display: 'flex' }}>
                <ThemeSkeleton/>
                <ThemeSkeleton/>
              </div>
              <div style={{ display: 'flex' }}>
                <ThemeSkeleton/>
                <ThemeSkeleton/>
              </div>
              <div style={{ display: 'flex' }}>
                <ThemeSkeleton/>
                <ThemeSkeleton/>
              </div>
            </div>
            </>
            }
					</Row>
					{/* <Row className={`d-block d-lg-none ${styles.responsiveMargin}`}>
						<Slider {...themeSettings} className={styles.themeSlider}>
							{themes.slice(0, 4).map((item, index) => {
								return (
									<Col key={index}>
										<ExploreThemes image={item.image} text={item.name} />
									</Col>
								)
							})}
						</Slider>
					</Row> */}
				</div>


        <div className={styles.sideBarMobileHide}>
          <SideBar handleEffect={handleEffect} />
        </div>
      </Container>
    </>
  )
}

export default Themes
