import { initialState } from '../initialState'
import { USER_PORTFOLIO } from '../actions/action'

const userPortfolio = (state = initialState.userPortfolio, action) => {
	switch (action.type) {
		case USER_PORTFOLIO:
			return { ...state, userPortfolio: action.payload }

		default:
			return state
	}
}

export default userPortfolio
