import React,{useEffect,useState} from 'react'
import Dropzone from 'react-dropzone'
import DownloadIcon from '../../../../public/images/easyfund/download.svg'
import styles from './easyfund.module.css'

const UploadAdhar = ({
	files,
	onDrop,
	dropRef,
	openDialog,
	onRemove,
	name,
  accept,
  title
}) => {

  const [fileSize,setFileSize] = useState('')
  useEffect(() => {
    if(files && files.length){
    setFileSize(files[0].size)
  }
  },[files])

	return(
		<Dropzone
			onDrop={onDrop}
			ref={dropRef}
			noClick
			maxSize={5000000}
      accept={accept}
      multiple={false}
			// accept='image/jpeg, image/png, image/gif, image/pdf, image/jpg, .pdf'
      >
			{({ getRootProps, getInputProps }) => (
         files.length === 0 ?
					<div
						{...getRootProps({ className: 'dropzone' })}
						className={styles.dropImageCenter}>
						<input {...getInputProps()} />
            <div className={styles.uploadBox} 	onClick={openDialog}>
              <div style={{paddingBottom:'16px',paddingTop:'16px'}}>
             <img src={DownloadIcon} className={styles.downloadImage}></img>
             </div>
             <span className={styles.uploadTitle}>{title}</span>
            </div>
					</div> :
          files.map(file => {
            return (
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <div className={styles.uploadBox} style={{width:'fit-content'}}>
                {fileSize > 5000000 ?
                <>
                <div className={styles.fileSize} style={{color:'#FA274A',paddingBottom:'16px',paddingTop:'16px'}}>
                <div style={{marginBottom:'16px'}}> File should be under 5MB</div>
                <div className={styles.uploadTitle} onClick={() => onRemove(name)}>Re-upload</div>
                </div>
                </>:
                <div className={styles.title} style={{margin:'0px'}}>
                <img src={file.preview} className={styles.uploadedImage}/>
                <div className={styles.uploadTitle} style={{color:'#0061D3',paddingTop:'16px'}} onClick={() => onRemove(name)}>Re-upload</div>
                </div>}
              </div>
              </div>
            )
          })
			)}
		</Dropzone>

	)
}

export default UploadAdhar
