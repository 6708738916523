import React, { useEffect, useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import styles from '../Profile.module.css'
import InputLabel from '../../InputLabel/InputLabel'
import axios from 'axios'
import { setUserInfoSession } from '../../../common'
import { updateProfile } from '../../../api';

const EditAddress = props => {
	const [addressLine1, setAddressLine1] = useState(props.addressLine1 && props.addressLine1 !== 'x' ? props.addressLine1 :'')
	const [addressLine1Check, setAddressLine1Check] = useState(false)
	const [addressLine2, setAddressLine2] = useState(props.addressLine2 && props.addressLine2 !== 'x' ? props.addressLine2  :'')
	const [addressLine2Check, setAddressLine2Check] = useState(false)
	const [city, setCity] = useState(props.city)
	const [cityCheck, setcityCheck] = useState(false)
	const [state, setState] = useState(props.state)
	const [stateCheck, setstateCheck] = useState(false)
	const [zipCode, setZipCode] = useState(props.zipCode)
	const [zipCodeCheck, setZipCodeCheck] = useState(false)
	const [proceedIdentityProof, setProceedIdentityProof] = useState(true)
	// const [panCardImage, setPanCardImage] = useState()
	const [frontImageUploaded, setFrontImageUploaded] = useState(false)
	const [backImageUploaded, setBackImageUploaded] = useState(false)
	const [processComplete, setProcessComplete] = useState(false)
	const [aadharFrontImage, setFrontImage] = useState()
	const [aadharBackImage, setBackImage] = useState()
	// const [errorMessage, setErrorMessage] = useState('')
	const [address1Error, setaddress1Error] = useState('')
	const [address2Error, setaddress2Error] = useState('')
	const [cityError, setcityError] = useState('')
	const [stateError, setStateError] = useState('')
	const [zipcodeError, setzipcodeError] = useState('')

	// const onSavingChanges = () => {
	//     setProceedIdentityProof(false)
	// }

	const hiddenFileInput = React.useRef(null)
	const firstFileInput = React.useRef(null)

	const frontUplaodImage = event => {
		firstFileInput.current.click()
	}

	const backUploadImage = event => {
		hiddenFileInput.current.click()
	}

	const frontImage = event => {
		const fileUploaded = URL.createObjectURL(event.target.files[0])
		//  console.log('file',fileUploaded)
		setFrontImage(fileUploaded, setFrontImageUploaded(true))
	}

	const secondImage = event => {
		const fileUploaded = URL.createObjectURL(event.target.files[0])
		//  console.log('file',fileUploaded)
		setBackImage(fileUploaded, setBackImageUploaded(true))
	}

	const removeImage = e => {
		if (e === 'front') {
			setFrontImage(null, setFrontImageUploaded(false))
		} else if (e === 'back') {
			setBackImage(null, setBackImageUploaded(false))
		}
	}

	const finishChanges = () => {
		if (frontImageUploaded && backImageUploaded) {
			setProcessComplete(true)
		}
	}

	const handleInputBox = e => {
		if (e.target.name === 'AddressLine1') {
      const reg= /\d/;
			if (addressLine1.length < 1 ) {
				setaddress1Error('Address1 cannot be Empty')
				setAddressLine1Check(true)
			}
      // validation of address
      // else if(addressLine1 === 'P.O BOX' || addressLine1 === 'PO BOX'){
      //   setaddress1Error('Enter A Valid Address')
      //   setAddressLine1Check(true)
      // } else if(reg.test(addressLine1) === false){
      //   setaddress1Error('Enter A Valid Address')
      //   setAddressLine1Check(true)
      // }
      else {
				setaddress1Error('')
				setAddressLine1Check(false)
			}
		}
    // else if (e.target.name === 'AddressLine2') {
    //   const reg= /\d/;
		// 	if (addressLine2.length < 1) {
		// 		setaddress2Error('Address2 cannot be Empty')
		// 		setAddressLine2Check(true)
		// 	}
    //   // else if(addressLine2 === 'P.O BOX' || addressLine2 === 'PO BOX'){
    //   //   setaddress2Error('Enter A Valid Address')
    //   //   setAddressLine2Check(true)
    //   // } else if(reg.test(addressLine2) === false){
    //   //   setaddress2Error('Enter A Valid Address')
    //   //   setAddressLine2Check(true)
    //   // }
    //    else {
		// 		setaddress2Error('')
		// 		setAddressLine2Check(false)
		// 	}
		// }
     else if (e.target.name === 'Zipcode') {
			if (zipCode.length < 1) {
				setzipcodeError('Zipcode Cannot be Empty')
				setZipCodeCheck(true)
			} else {
				setzipcodeError('')
				setZipCodeCheck(false)
			}
		} else if (e.target.name === 'City') {
			if (city.length < 1) {
				setcityError('City Cannot be Empty')
				setcityCheck(true)
			} else {
				setcityError('')
				setcityCheck(false)
			}
		} else if (e.target.name === 'State') {
			if (state.length < 1) {
				setStateError('State Cannot be Empty')
				setstateCheck(true)
			} else {
				setStateError('')
				setstateCheck(false)
			}
		}
	}

	// button Api call
	const handleSubmit = e => {
		if (
			addressLine1 !== '' &&
			city !== '' &&
			state !== '' &&
			zipCode !== '' &&
      !addressLine1Check
		) {
			const update = {
				body: {
					address1: addressLine1,
					address2: addressLine2,
					city: city,
					state: state,
					postalCode: zipCode,
          otp:props.otp,
          hashId:props.hashId
				}
			}

      updateProfile(update)
				.then(function (response) {
					//   onSavingChanges()
					props.setIsVerifyOtp(false)
          props.setIsEdit(false)
          if(response.data.code === 200){
              const data = {
                addressLine1,
                addressLine2,
                city,
                zipPostalCode:zipCode,
                stateProvince:state
                }
              setUserInfoSession(data);
              props.setDetails(data)
          }
				})
				.catch(function (error) {
					console.log('address', error)
				})
		} else {
			console.log('something not filled')
		}
	}

	return (
		<>
			<div style={{ width: '400px' }} className={styles.profileModalBody}>
				{proceedIdentityProof ? (
					<>
						<Row className={`p-0 mt-0 ${styles.basicDetail}`}>
							<Col className={`p-0 ${styles.modalHeadingText}`}>Address</Col>
						</Row>
						<Row className={`${styles.inputRowsEdit}`}>
							<Col>
								<div className={`mb-1 ${styles.profileInputLabel}`}>
									Address Line 1
								</div>
								<input
									type='text'
									label='Address Line 1'
									placeholder='Address Line 1'
									name='AddressLine1'
									className={
										addressLine1Check
											? styles.profileErrorInputBox
											: styles.profileInputBox
									}
									value={addressLine1}
									onBlur={event => handleInputBox(event)}
									onChange={event => setAddressLine1(event.target.value)}
								/>
								<p className={styles.profileErrorText}>{address1Error}</p>
								{/* <InputLabel
                    type="text"
                    label='Address Line 1'
                    placeholder='Address Line 1'
                    name='Address Line 1'
                    value={addressLine1}
                    onChange={event => setAddressLine1(event.target.value)}>
                </InputLabel> */}
							</Col>
						</Row>
						<Row className={`${styles.inputRowsEdit}`}>
							<Col>
								<div className={`mb-1 ${styles.profileInputLabel}`}>
									Address Line 2 (optional)
								</div>
								<input
									label='Address Line 2'
									placeholder='Address Line 2'
									name='AddressLine2'
									type='text'
									value={addressLine2}
									className={
										addressLine2Check
											? styles.profileErrorInputBox
											: styles.profileInputBox
									}
									onBlur={event => handleInputBox(event)}
									onChange={event => setAddressLine2(event.target.value)}
								/>
								<p className={styles.profileErrorText}>{address2Error}</p>
								{/* <InputLabel
                type="text"
                label='Address Line 2'
                placeholder='Address Line 2'
                name='Address Line 2'
                value={addressLine2}
                onChange={event => setAddressLine2(event.target.value)}
                ></InputLabel> */}
							</Col>
						</Row>
						<Row className={`${styles.inputRowsEdit}`}>
							<Col>
								<div className={`mb-1 ${styles.profileInputLabel}`}>
									Zipcode
								</div>
								<input
									type='number'
									label='Zipcode'
									placeholder='Zipcode'
									name='Zipcode'
									value={zipCode}
									className={
										zipCodeCheck
											? styles.profileErrorInputBox
											: styles.profileInputBox
									}
									onBlur={event => handleInputBox(event)}
                  onChange={event => setZipCode(event.target.value)}
                  onPaste={(e)=>{
                    e.preventDefault()
                    return false;
                  }} onCopy={(e)=>{
                    e.preventDefault()
                    return false;}}
								/>
								<p className={styles.profileErrorText}>{zipcodeError}</p>
								{/* <InputLabel
                label='Zipcode'
                placeholder='Zipcode'
                name='Zipcode'
                value={zipCode}
                onChange={event => setZipCode(event.target.value)}
                ></InputLabel> */}
							</Col>
						</Row>
						<Row className={`${styles.inputRowsEdit}`}>
							<Col>
								<div className={`mb-1 ${styles.profileInputLabel}`}>City</div>
								<input
									type='text'
									label='City'
									placeholder='City'
									name='City'
									className={
										cityCheck
											? styles.profileErrorInputBox
											: styles.profileInputBox
									}
									value={city}
									onBlur={event => handleInputBox(event)}
									onChange={event => setCity(event.target.value)}
								/>
								<p className={styles.profileErrorText}>{cityError}</p>
								{/* <InputLabel
                label='City'
                placeholder='City'
                name='City'
                value={city}
                onChange={event => setCity(event.target.value)}
                ></InputLabel> */}
							</Col>
						</Row>
						<Row className={`${styles.inputRowsEdit}`}>
							<Col>
								<div className={`mb-1 ${styles.profileInputLabel}`}>State</div>
								<input
									type='text'
									label='State'
									placeholder='State'
									name='State'
									className={
										stateCheck
											? styles.profileErrorInputBox
											: styles.profileInputBox
									}
									value={state}
									onBlur={event => handleInputBox(event)}
									onChange={event => setState(event.target.value)}
								/>
								<p className={styles.profileErrorText}>{stateError}</p>
								{/* <InputLabel
                label='State'
                placeholder='State'
                name='State'
                value={state}
                onChange={event => setState(event.target.value)}
                ></InputLabel> */}
							</Col>
						</Row>
            <Row className={styles.disclaimer}>
              If you will like to update the address of different country, please contact customer support
            </Row>
						<Row>
							<Col md={6} xs={6} className={`${styles.bottomButton}`}>
								<span onClick={props.handleClose}>Cancel</span>
							</Col>
							<Col md={6} xs={6} className={`${styles.saveBottomButton}`}>
								<Button
									className={`${styles.editSaveButton} themeButton`}
									onClick={handleSubmit}
									style={{ background: props.buttonColor }}>
									save changes
								</Button>
							</Col>
						</Row>
					</>
				) : (
					<>
						{processComplete ? (
							<Row className={styles.requestRow}>
								<Col md={12} className={styles.RequestHead}>
									Request submitted successfully
								</Col>
								<Col md={12} className={styles.RequestSubHead}>
									Your details will be updated in 2 working days
								</Col>
							</Row>
						) : (
							<>
								<Row className={`p-0 mt-0 ${styles.basicDetail}`}>
									<Col className={`p-0 ${styles.basicDetailText}`}>
										<span className={styles.porfileBackArrow}>
											<img
												src={require('../../../public/images/ProfileBackArrow.svg')}
												// onClick={onSavingChanges}
											></img>
										</span>
										Upload Identity Proof
									</Col>
								</Row>
								<Row className={`mt-2 ${styles.profileSubHeading}`}>
									<Col className={`p-0 m-0 ${styles.sadText}`}>
										We need your Aadhar card/ Address proof to continue with
										name change request.
									</Col>
								</Row>
								<Row>
									<Col md={6} className={`${styles.bottomButton}`}>
										<span onClick={props.handleClose}>Cancel</span>
									</Col>
									<Col md={6} className={`${styles.saveBottomButton}`}>
										<Button
											style={{ background: props.buttonColor }}
											className={`${styles.editSaveButton} themeButton`}
											onClick={finishChanges}>
											save changes
										</Button>
									</Col>
								</Row>
								{frontImageUploaded ? (
									<Row>
										<Col>
											<div className={styles.profileRemoveButtonCenter}>
												<button
													className={styles.profileRemoveButton}
													onClick={() => removeImage('front')}>
													REMOVE
												</button>
											</div>
											<img
												className={styles.profileUploaded}
												src={aadharFrontImage}
											/>
										</Col>
									</Row>
								) : (
									<Row className='p-0 m-0'>
										<Col md={12} className={styles.addharCardHeading}>
											FRONT SIDE
										</Col>
										<Col md={12} className={styles.photoUploadBox}>
											<div className={styles.profileUploadButtonCenter}>
												<button
													className={styles.profileUploadButton}
													onClick={frontUplaodImage}>
													Upload Photo
												</button>
												<input
													type='file'
													style={{ display: 'none' }}
													ref={firstFileInput}
													onChange={frontImage}
												/>
											</div>
										</Col>
									</Row>
								)}
								{backImageUploaded ? (
									<>
										<Row>
											<Col>
												<div className={styles.profileRemoveButtonCenter}>
													<button
														className={styles.profileRemoveButton}
														onClick={() => removeImage('back')}>
														REMOVE
													</button>
												</div>
												<img
													className={`mt-2 ${styles.profileUploaded}`}
													src={aadharBackImage}
												/>
											</Col>
										</Row>
									</>
								) : (
									<>
										<Row className='p-0 m-0'>
											<Col md={12} className={styles.addharCardHeading}>
												BACK SIDE
											</Col>
											<Col md={12} className={styles.photoUploadBox}>
												<div className={styles.profileUploadButtonCenter}>
													<button
														className={styles.profileUploadButton}
														onClick={backUploadImage}>
														Upload Photo
													</button>
													<input
														type='file'
														style={{ display: 'none' }}
														ref={hiddenFileInput}
														onChange={secondImage}
													/>
												</div>
											</Col>
										</Row>
									</>
								)}
							</>
						)}
					</>
				)}
			</div>
		</>
	)
}

export default EditAddress
