import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import SkeletonElement from '../../skeletons/SkeletonElement'
import Shimmer from '../../skeletons/Shimmer'
import styles from './stockdetail.module.css'

const AboutSkeleton = () => {
	return (
		<>
			<Card className='skeletonCard' style={{ width: '100%' }}>
				<Container className={styles.bigScreen}>
					<Row>
						<Col md={12} className='mt-4'>
							<SkeletonElement width='700px' height='20px'></SkeletonElement>
						</Col>
					</Row>
					<Row>
						<Col md={12} className='mt-4'>
							<SkeletonElement width='700px' height='20px'></SkeletonElement>
						</Col>
					</Row>
					<Row>
						<Col md={12} className='mt-4 mb-4'>
							<SkeletonElement width='700px' height='20px'></SkeletonElement>
						</Col>
					</Row>
				</Container>
			</Card>
			<Shimmer />
		</>
	)
}
export default AboutSkeleton
