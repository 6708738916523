import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Tab, Tabs, TabContainer, Modal } from 'react-bootstrap'
import {
  StackDetail,
  Header,
  StackSummary,
  StackBuy,
  Footer,
  StocksWeightsTable,
  StackCard,
  TrendingStocksCard,
  CustomModal,
} from '../../components'
import {
  getStackDetail,
  getStackAllocation,
  getLikedStacks,
  getLikedStocks,
  getLivePrice,
  postWatchListTicker,
  watchListDataAPI,
  deleteWatchListTicker
} from '../../api'
import $ from 'jquery'
import Slider from 'react-slick'
import { getMarketDetails } from '../../store/actions/index'
import EmptyPage from '../../components/EmptyPage/EmptyPage'
import { STACK_PAGE } from '../../constants'
import crossImage from '../../public/images/BlackTickMark.svg'
import AddedTick from '../../public/images/added.svg'
import watchlisticon from '../../public/images/bookmarkOutline.svg'
import watchlisticonfilled from '../../public/images/bookmarkFilled.svg'
import { useSelector, useDispatch } from 'react-redux'
import styles from './stackdetail.module.css'
import { keyBy, map, keys, isEqual } from 'lodash'
import { calPriceChange, isMarketOpen } from '../../utils'
import { PORTFOLIO_LIVE_PRICE_INTERVAL } from '../../constants'
import { SideBar } from '../../components'
import blurrImage from '../../public/images/stackBlurr.png'
import researchImage from '../../public/images/research.png'
import { useHistory } from 'react-router-dom'
import { getCurrentAccountStatusLink } from '../../common'

const Stackdetail = props => {
  const [stackAllocation, setStackAllocation] = useState([])
  const [stack, setStack] = useState('')
  const [likedStack, setLikedStack] = useState([])
  const [likedStock, setLikedStock] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [sellCheck, setSellCheck] = useState(false)
  const [stackNotFound, setStackNotFound] = useState(false)
  const [isAddedWatchList, setisAddedWatchList] = useState(false)
  const [loadingWatchlist, setLoadingWatchlist] = useState(false)
  const [slideeffect, setslideeffect] = useState(false)
  const [activePlan, setActivePlan] = useState()
  const [exclusive, setExclusive] = useState()
  const [stockName, setStockName] = useState(props.match &&
    props.match.params &&
    props.match.params.code &&
    props.match.params.code)
  const [live, setLive] = useState([])
  const dispatchAction = useDispatch()
  const state = useSelector(state => state)


  let stocks = [];
  let etf = [];

  const {
    marketDetails: { marketDetails: { currentMarketStatus = '' } = {} } = {}
  } = state
  if (Object.keys(state.marketDetails).length === 0) {
    dispatchAction(getMarketDetails())
  }
  useEffect(() => {
    const fetchData = async () => {
      const [response, response2, respStackLike, respStockLike] =
        await Promise.all([
          getStackDetail(props.match.params.code),
          getStackAllocation(props.match.params.code, ''),
          getLikedStacks(props.match.params.code),
          getLikedStocks(props.match.params.code)
        ])
      if (response && response.code === 200 && response.data) {
        setStack(response.data)
        response2 && setStackAllocation(response2)
        setLikedStack(
          respStackLike && respStackLike.length > 0 ? respStackLike : []
        )
        setLikedStock(
          respStockLike && respStockLike.length > 0 ? respStockLike : []
        )
      }
      else if (response.code === 400 || response.code === 500) {
        setStackNotFound(true)
        $(window).unbind('scroll')
      }
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    fetchData()
  }, [props.match.params.code])

  const handleLiveApi = async data => {
    data = data.map(trav => trav.symbol).join(',')
    let response = await getLivePrice(data)
    setLive(keyBy(response, 'symbol'))
  }


  if (
    stackAllocation && stackAllocation.length > 0
    && live && Object.keys(live).length > 0
    && isEqual(map(stackAllocation, 'symbol'), keys(live))
  ) {
    stackAllocation.map(item => {
      let { lastTrade, close, priorClose } = live[item.symbol];
      lastTrade = isMarketOpen(currentMarketStatus) ? lastTrade : close

      let { change, changePer } = calPriceChange({
        currPrice: lastTrade,
        close: close || item.close,
        priorClose: priorClose || item.priorClose,
        isMarketOpen: isMarketOpen(currentMarketStatus)
      });
      const obj = {
        image: item.logo ? item.logo : '',
        name: item.symbol ? item.symbol : '',
        creator: item.company ? item.company : '',
        price: lastTrade,
        pricePercentage: changePer,
        amount: item.allocationAmount ? item.allocationAmount : '',
        allocationPer: item.allocationPer ? item.allocationPer : '',
        isImgLoaded: true,
        change,
      }
      if (item.stockType !== 'etf') {
        stocks = [...stocks, obj]
        stocks.sort(function(a,b){
          return a.allocationPer-b.allocationPer
        }).reverse()
      } else {
        etf = [...etf, obj]
        etf.sort(function(a,b){
          return a.allocationPer-b.allocationPer
        }).reverse()
      }
    })
  }

  useEffect(() => () => $(window).unbind('scroll'), [])
  useEffect(() => {
    $(window).scroll(function() {
      let scrollPos = parseInt(
        window.pageYOffset || document.documentElement.scrollTop
      )
      let scrollStop = parseInt($('#scrollStop').position().top) - 680
      if (scrollPos > scrollStop) {
        $('.floatingdiv').css({ position: 'absolute', top: scrollStop })
      } else if (scrollPos <= 0) {
        $('.floatingdiv').css({ position: 'fixed' })
      } else {
        $('.floatingdiv').css({ position: 'fixed', top: 380 })
      }
    })
  }, [])

  useEffect(() => {
    document.querySelector('.floatingdiv').style.position = 'fixed'
  }, [stack])


  const { factsheet = '' } = stack
  const headerStock = ['Stocks', 'price', 'allocation %', 'allocation amt']
  const headerEtf = ['ETF', 'price', 'allocation %', 'allocation amt']

  var settings = {
    dots: false,
    className: 'center',
    autoplaySpeed: 3000,
    infinite: true,
    centerMode: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding:'0px'
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding:'28%'
        }
      },
      {
        breakpoint:480,
        settings: {
          slidesToShow:1,
          slidesToScroll:1,
          centerMode:true,
          centerPadding:'25%'
        }
      },
      {
        breakpoint:425,
        settings: {
          slidesToShow:1,
          slidesToScroll:1,
          centerMode:true,
          centerPadding:'20%'
        }
      },
      {
        breakpoint:375,
        settings: {
          slidesToShow:1,
          slidesToScroll:1,
          centerMode:true,
          centerPadding:'40px'
        }
      },
    ]
  }


  var settingsForStackCards = {
    dots: false,
    className: 'center',
    autoplaySpeed: 3000,
    infinite: true,
    centerMode: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding:'10px'
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding:'20%'
        }
      },
      {
        breakpoint:480,
        settings: {
          slidesToShow:1,
          slidesToScroll:1,
          centerMode:true,
          centerPadding:'20%'
        }
      },
      {
        breakpoint:425,
        settings: {
          slidesToShow:1,
          slidesToScroll:1,
          centerMode:true,
          centerPadding:'15%'
        }
      },
      {
        breakpoint:375,
        settings: {
          slidesToShow:1,
          slidesToScroll:1,
          centerMode:true,
          centerPadding:'20px'
        }
      },
    ]
  }

  const handleShow = () => setShowModal(true)
  const handleClose = () => setShowModal(false)


  const getWatchlistData = async () => {
    try {
      const res = await watchListDataAPI()
      if (res.length > 0) {
        res.map((item) => {
          if (item.code == stack.ticker) {
            setisAddedWatchList(true)
          }
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const addToWatchList = async () => {
    setLoadingWatchlist(true)
    const res = await postWatchListTicker(stack.ticker)
    if (res.code === 200) {
      setisAddedWatchList(true)
    } else {
      console.log('something went wrong')
    }
    setLoadingWatchlist(false)
  }

  const deleteFromWatchList = async () => {
    setLoadingWatchlist(true)
    try {
      const res = await deleteWatchListTicker(stack.ticker)
      if (res.code === 200) {
        setisAddedWatchList(false)
      }
    } catch (error) {
      console.log(error)
    }
    setLoadingWatchlist(false)
  }

  useEffect(() => {
    getWatchlistData()
  }, [stack])

  useEffect(() => {
    JSON.parse(sessionStorage.getItem('planInfo')) && JSON.parse(sessionStorage.getItem('planInfo')).exclusiveUser ? setExclusive(JSON.parse(sessionStorage.getItem('planInfo')).exclusiveUser) : setExclusive(false)
    JSON.parse(sessionStorage.getItem('planInfo')) && JSON.parse(sessionStorage.getItem('planInfo')).planStatus
      ? JSON.parse(sessionStorage.getItem('planInfo')).planStatus === 'active'
        ? setActivePlan(JSON.parse(sessionStorage.getItem('planInfo')).planStatus)
        : setActivePlan('expire')
      : setActivePlan('expire')
  }, [stockName])

  useEffect(() => {
    stackAllocation && stackAllocation.length > 0 && handleLiveApi(stackAllocation)
  }, [stackAllocation])

  useEffect(() => {
    const setLivePrice = async () => {
      if (stackAllocation && stackAllocation.length > 0) {
        await handleLiveApi(stackAllocation)
      }
    }

    const priceId = setInterval(() => {
      if (isMarketOpen(currentMarketStatus)) {
        setLivePrice();
      }
    }, PORTFOLIO_LIVE_PRICE_INTERVAL);

    return () => {
      clearInterval(priceId);
    }
  }, [stackAllocation, currentMarketStatus])

  const handleEffect = () => {
    setslideeffect(prevState => setslideeffect(!prevState))
  }

  let history = useHistory()
  const redirectProfile = async() => {
    //logic to redirect user based on account status
    const link = await getCurrentAccountStatusLink();
    history.push(link)
  }

  return (
    <>
        <Header />
      <Container className={styles.bigScreen}>
        {stackNotFound ? <EmptyPage type={STACK_PAGE} /> :
          <>
            <div className={styles.headerMargin}>
              <Row className='d-block d-lg-none mb-3'>
                <Col style={{ textAlign: 'end', whiteSpace: 'nowrap' }}>
                  {isAddedWatchList ?
                    <div style={{ width: '178px', maxWidth: '178px', display: 'inline-block', textAlign: 'center',cursor: 'pointer' }}
                         className={styles.addWatchList} onClick={deleteFromWatchList}>
                      <img
                        src={watchlisticonfilled}
                        className={`mr-2`}></img>
                      <span className={styles.addWatchListText}>Added To Watchlist</span>
                    </div> :
                    <div style={{ width: '178px', maxWidth: '178px', display: 'inline-block', textAlign: 'center',cursor: 'pointer' }}
                         className={styles.addWatchList} onClick={addToWatchList}>
                      <img
                        src={watchlisticon}
                        ></img>{' '}
                      <span className={styles.addWatchListText}>Add to watchlist</span>
                    </div>}
                </Col>
              </Row>
              <StackDetail
                ticker={
                  stack.hasOwnProperty('ticker') && stack.ticker !== null
                    ? stack.ticker
                    : ''
                }
                image={
                  stack.hasOwnProperty('logo') && stack.logo !== null
                    ? stack.logo
                    : ''
                }
                creator={
                  stack.hasOwnProperty('createdByTitle') &&
                  stack.createdByTitle !== null
                    ? stack.createdByTitle
                    : ''
                }
                risk={
                  stack.hasOwnProperty('riskMeter') && stack.riskMeter !== null
                    ? stack.riskMeter
                    : ''
                }
                ranking={
                  stack.hasOwnProperty('ranking') && stack.ranking !== null
                    ? stack.ranking
                    : ''
                }
                indexValue={
                  stack.hasOwnProperty('price') && stack.price !== null
                    ? stack.price
                    : ''
                }
                cagr={
                  stack.hasOwnProperty('cagr') && stack.cagr !== null
                    ? stack.cagr
                    : ''
                }
                cagrInfo={
                  stack.hasOwnProperty('cagrInfo') && stack.cagrInfo !== null
                    ? stack.cagrInfo
                    : ''
                }
                createdByTitle={
                  stack.hasOwnProperty('createdByTitle') && stack.createdByTitle !== null
                    ? stack.createdByTitle
                    : ''
                }
                createdByProfilePictureLink={
                  stack.hasOwnProperty('createdByProfilePictureLink') && stack.createdByProfilePictureLink !== null
                    ? stack.createdByProfilePictureLink
                    : ''
                }
                isAddedWatchList={isAddedWatchList}
                deleteFromWatchList={deleteFromWatchList}
                loadingWatchlist={loadingWatchlist}
                addToWatchList={addToWatchList}
                isUserSet={() => props.isUserSet()}
              />
            </div>
            <Row className={styles.navMargin} style={{ paddingBottom: '64px' }}>
              <TabContainer className={styles.tabWidth}>
                <Row className={styles.mobileTab}>
                  <Col md={12} lg={8} xs={12} className={`${styles.mobilePadding} ${styles.widthCol} pr-0`}>
                    <Tabs
                      defaultActiveKey='summary'
                      id='uncontrolled-tab-example'
                      className={styles.navWidth}>
                      <Tab eventKey='summary' title='Summary'>
                        <div className={styles.desciptionMargin}>
                          <StackSummary stack={stack} />
                        </div>
                      </Tab>
                      <Tab eventKey='stocksweight' title='Stocks & weights'>
                        <Row
                          className={`ml-0 ${(stack.activationFee == 0 && activePlan === 'active') ? styles.mobileMargin : styles.mobileMarginNonExclusive}`}>
                          {(stack.activationFee == 0 && activePlan === 'active') ?
                            <>
                              <StocksWeightsTable
                                rowHeaders={headerStock}
                                data={stocks}
                                activePlan={activePlan}
                                exclusive={exclusive}
                              />
                              <StocksWeightsTable
                                rowHeaders={headerEtf}
                                data={etf}
                                activePlan={activePlan}
                                exclusive={exclusive}
                              />
                            </>
                            :

                            <div>
                              <img src={blurrImage} className={styles.blurrImage} />
                              <div className={styles.absoluteCover}>
                                <Col className={styles.cardBorder} style={{ padding: '0px' }}>
                                  <Row style={{ margin: '0px' }}>
                                    <Col xs={12} md='auto' style={{ textAlign: 'center' }}>
                                      <img src={researchImage} className={styles.imagePadding} />
                                    </Col>
                                    <Col>
                                      <div className={styles.header}>
                                        Make better investment decisions
                                      </div>
                                      <div className={styles.description}>
                                        Unlock access to invest in premium stack
                                      </div>
                                      <div className={styles.buttonDist}>
                                        <div className={`${styles.icon}`} onClick={redirectProfile}
                                             style={{ cursor: 'pointer' }}>
                                          <button className={`${styles.plan}`}>
                                            UPGRADE PLAN
                                          </button>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </div>
                            </div>
                          }
                        </Row>
                      </Tab>
                    </Tabs>
                  </Col>
                  <Col className={`d-none d-lg-block ${styles.buyCardPadding}`}>
                    <div className={styles.buyCardMargin}>
                      <Row className='mb-3'>
                        <Col
                          className={`${styles.factSheet} pr-0`}
                          style={{ cursor: 'pointer' }} lg={5}>
                          {factsheet === '' ? (
                            ''
                          ) : (
                            <a href={factsheet} target='_blank'>
                              <img
                                src={require('../../public/images/Download.svg')}
                                className='mr-2'></img>
                              factsheet
                            </a>
                          )}
                        </Col>
                        <Col lg={7} className={`${styles.paddingWatchList} ${isAddedWatchList ? styles.addWatchLiaddWatchListstPadding : styles.addedWatchListPadding}`}>
                          {isAddedWatchList ?
                            <div className={styles.addWatchList} onClick={deleteFromWatchList} style={{ cursor: 'pointer' }}>
                              <img
                                src={watchlisticonfilled}
                                className={`mr-2`} ></img>
                              <span className={styles.addWatchListText}>Added To Watchlist</span>
                            </div> :
                            <div className={styles.addWatchList} onClick={addToWatchList} style={{ cursor: 'pointer' }}>
                              <img
                                src={watchlisticon}></img>{' '}
                              <span className={styles.addWatchListText}>Add to watchlist</span>
                            </div>}
                        </Col>
                      </Row>
                      <div className='floatingdiv d-none d-lg-block'>
                        {stack && stack.ticker ?
                          <StackBuy
                            stack={stack}
                            setStackAllocation={setStackAllocation}>
                            isUserSet={props.isUserSet}
                          </StackBuy> : ''}
                      </div>
                    </div>
                  </Col>
                </Row>
              </TabContainer>
            </Row>
            <div id='scrollStop'></div>
            {likedStack && likedStack.length > 0 ? (
              <div className={styles.graphDiver}>
                <Row className={styles.stackLike}>Stacks you might like</Row>
                <Row className={styles.stacktext}>
                  Expert made model portfolios
                </Row>
                <Row className={`${styles.cardMargin} d-none d-md-none d-lg-none d-xl-flex`}>
                  {likedStack.slice(0, 4).map((item, index) => {
                    return (
                      <Col key={index} md={3} xs={9}>
                        <StackCard
                          image={item.logo}
                          ticker={item.ticker}
                          createdBy={item.createdByTitle}
                          desc={item.description}
                          cagr={item.cagr}
                          minInvest={item.minimumInvestment}
                        />
                      </Col>
                    )
                  })}
                </Row>
                <Row className={`${styles.cardMargin} d-none d-md-none d-lg-flex d-xl-none`}>
                  {likedStack.slice(0, 3).map((item, index) => {
                    return (
                      <Col key={index} md={4} xs={9}>
                        <StackCard
                          image={item.logo}
                          ticker={item.ticker}
                          createdBy={item.createdByTitle}
                          desc={item.description}
                          cagr={item.cagr}
                          minInvest={item.minimumInvestment}
                        />
                      </Col>
                    )
                  })}
                </Row>
                <Row className='d-block d-lg-none' style={{ marginTop: '16px' }}>
                  <Col>
                    <Slider {...settingsForStackCards} className={styles.trendingSlider}>
                      {likedStack &&
                      likedStack.slice(0, 4).map((item, index) => {
                        return (
                          <StackCard
                            image={item.logo}
                            ticker={item.ticker}
                            createdBy={item.createdByTitle}
                            desc={item.description}
                            cagr={item.cagr}
                            minInvest={item.minimumInvestment}
                          />
                        )
                      })}
                    </Slider>
                  </Col>
                </Row>
              </div>
            ) : (
              ''
            )}
            {likedStock && likedStock.length > 0 ? (
              <div style={{ marginBottom: '64px' }}>
                <Row className={styles.stackLike}>Stocks you might like</Row>
                <Row className={`${styles.cardMargin} d-none d-lg-flex`}>
                  {likedStock.slice(0, 4).map((item, index) => {
                    return (
                      <Col key={index}>
                        <TrendingStocksCard
                          stockImage={item.logo}
                          stockTitle={item.code}
                          stockName={item.company}
                          stockValue={item.price}
                          stockIncrement={item.percentChange}
                          stockChange={item.change}
                          // graphData={data}
                          increment={
                            item.percentChange > 0 ? true : false
                          }></TrendingStocksCard>
                      </Col>
                    )
                  })}
                </Row>
                <Row className='d-block d-lg-none' style={{ marginTop: '16px' }}>
                  <Col>
                    <Slider {...settings} className={styles.trendingSlider}>
                      {likedStock &&
                      likedStock.slice(0, 4).map((item, index) => {
                        return (

                          <TrendingStocksCard
                            stockImage={item.logo}
                            stockTitle={item.code}
                            stockName={item.company}
                            stockValue={item.price}
                            stockIncrement={item.percentChange}
                            stockChange={item.change}
                            increment={
                              item.percentChange > 0 ? true : false
                            }></TrendingStocksCard>
                        )
                      })}
                    </Slider>
                  </Col>
                </Row>
              </div>
            ) : (
              ''
            )}
          </>}
        {/* <Footer /> */}

        {/* for mobile */}
        <Row style={{
          position: 'fixed',
          bottom: '0',
          width: '100%',
          textAlign: 'center',
          zIndex: 999
        }} className={`d-flex d-lg-none ${styles.fixedBuyTablet}`}>
          <Col>
            <Row>
              <Col className='p-0'>
                <div className={styles.buttonDiv}>
                  <button type='button' className={styles.buyButton}
                          onClick={() => setSellCheck(false, handleShow())}>{`Buy stack`}</button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>


        {/* stack buy modal for mobile */}
        <CustomModal show={showModal} handleClose={handleClose} className={`d-block d-lg-none`}>
          <div>
            {sellCheck ? '' :
              <StackBuy
                stack={stack}
                setStackAllocation={setStackAllocation}
                isUserSet={props.isUserSet}
              >
              </StackBuy>
            }

          </div>
        </CustomModal>

      </Container>
      <div className={styles.sideBarMobileHide}>
        {/* <SideBar handleEffect={handleEffect} change={isAddedWatchList}/> */}
        <SideBar handleEffect={handleEffect} change={isAddedWatchList} pageType={'detail'}
                 setInwatchList={setisAddedWatchList} />
      </div>
    </>
  )
}

export default Stackdetail
