import React from 'react'
import { Row, Col } from 'react-bootstrap'
import styles from './SignUp.module.css'
import { withRouter } from 'react-router-dom'
import 'react-phone-number-input/style.css'
import OutsideClickHandler from 'react-outside-click-handler'
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  formatPhoneNumberIntl
} from 'react-phone-number-input'
import { ThemeContext } from '../../Context/ThemeContext'
import { validateUserName, geoLocation, LeadCapture,createUser, socketLogin } from '../../api'
import SignUpButton from './SignUpButton'
import { setUserInfoSession } from '../../common'
import { Formik } from 'formik'
import * as yup from 'yup'
import { getCountryCallingCode } from 'react-phone-number-input/input'
import SignUpInput from './SignUpInput'
import SignUpDropDown from './SignUpDropDown'
import DropDownButton from './DropDownButton'
import queryString from 'query-string'
import { isArray } from 'lodash'
import EditSvg from '../../public/images/newOnboarding/Edit.svg';
import PasswordSvg from '../../public/images/newOnboarding/Password.svg';
import UserSvg from '../../public/images/newOnboarding/User.svg';
import PasswordHide from '../../public/images/newOnboarding/PasswordHide.svg'
import PasswordShow from '../../public/images/newOnboarding/PasswordShow.svg'


class PhoneNumber extends React.Component {
  constructor(props) {
    super(props)
    const { formData } = this.props
    this.state = {
      password: '',
      phoneCountryCode: formData.phoneCountryCode ? formData.phoneCountryCode : '',
      phoneNumber: formData.phoneNumber ? formData.phoneNumber : '',
      isPhoneParam: formData.isPhoneParamPresent ? formData.isPhoneParamPresent : '',
      phoneNumberFromParam:formData.phoneNumber ? formData.phoneNumber : '',
      userName:formData.userName ? formData.userName : formData.email ? formData.email : '',
      showUserName: false,
      passwordType: 'password',
      phoneNumberValidation:'',
      passwordValidatioError:'',
      userNameValidationError:'',
      loading:false,
      screenDetails:null,
      showDropDown:false,
      activeCountries: this.props.activeCountries,
      phoneCountry:formData.phoneCountry ? formData.phoneCountry : '',
      phoneFlagCountry:formData.phoneFlagCountry ? formData.phoneFlagCountry : '',
      showLabelPhoneNumber:false,
      errorMessage:''
    }
    this.myRef = React.createRef();
  }

  static contextType = ThemeContext

  componentDidMount() {
    let deviceData = {}
    try{
      deviceData = {viewport: `${Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)} X ${Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)}`}
      if(window.screen){
        deviceData.screenSize=`${window.screen.width} X ${window.screen.height}`
        if(window.devicePixelRatio)
          deviceData.resolution = `${window.screen.width * window.devicePixelRatio} X ${window.screen.height * window.devicePixelRatio}`
      }
    }catch(error){
      console.log('Error while parsing screen details')
    }
      this.getCurrentCountryCode()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      const { formData = {},activeCountries=[] } = this.props
      this.setState({activeCountries })
    }
  }

  getCurrentCountryCode = () => {
    if(this.props.formData && this.props.formData.countryCode){
      const {country='',countryCode='',flagCode=''} = this.props.formData
      this.setState({phoneCountry:country,phoneFlagCountry:flagCode},this.calculateCallingCode(flagCode))
    }else{
      geoLocation().then(response => {
        if (response && response.code === 200 && response.data) {
          const {country_code = '',name = ''} = response.data
          this.setState({phoneCountry:name,phoneFlagCountry:country_code},this.calculateCallingCode(country_code))
        }
      })
   }
  }

  // getCountryCode = (value) => {
  //    if(value){
  //      const filter = this.state.activeCountries.filter((item) => item.code2 === value)
  //      if(filter){
  //        return filter[0].code
  //      }
  //    }
  //   return;
  // }


  generateError = (error) => {
    if(error.includes('username')){
      this.setState({userNameValidationError:error})
    }
    else if(error.includes('phonenumber')){
      this.setState({phoneNumberValidation:error})
    }
    else{
      this.setState({errorMessage:error})
    }
  }

  onCreatingUser = async() => {
    if (
      isValidPhoneNumber(this.state.phoneNumber) &&
      isPossiblePhoneNumber(this.state.phoneNumber.trim())
    ) {
      let leadData = this.props.leadData;
      if (leadData) {
        leadData = Object.assign(leadData, {
          ProspectStage:'Lead',
          email: this.props.formData.email,
          phone: this.state.phoneNumber
        })
        LeadCapture(leadData);
      }

        const {
        email = '',
        country='',
        countryCode='',
        userReferralCode='',
        hashID = '',
        otp='',
        partnerToken = ''
      } = this.props.formData
      const filter = this.state.activeCountries.filter((item) => item.label === country)
      const countryCode2 =  filter[0].code
      const data = {
        countryOfResidence:countryCode2,
        userReferralCode,
        email: email.toLowerCase(),
        hashID,
        otp,
        partnerToken,
        username:this.state.userName,
        phone:this.state.phoneNumber.replace(" ",''),
        password:this.state.password,
        riaId: this.props.partnerCode,
        nextStep: 'DOCUMENTS'
      }
      const {partnerCode} = this.props
      const {fetchTheme} = this.context
      if (partnerCode === 'STOCKAL' || partnerCode === 'STOCKALGLOBAL') {
        // for stockal only
        data.riaId = (countryCode2 === "IND") ? 'STOCKAL' : 'STOCKALGLOBAL';
        fetchTheme(filter[0].code2).then((response) => console.log('res first',response) )
      }
      else if (partnerCode === 'HDFC' || partnerCode === 'HDFCGLOBAL') {
        // for hdfc only
        data.riaId = (countryCode2 === "IND") ? 'HDFC' : 'HDFCGLOBAL';
        fetchTheme(filter[0].code2).then((response) => console.log('res',response) )
      }
      else {
        const basePartnerCode = partnerCode.replace('-GLOBAL', '');
        const finalPartnerCode = (countryCode2 === "IND") ? basePartnerCode : `${basePartnerCode}-GLOBAL`;
        if(partnerCode !== finalPartnerCode){
          const resp = await fetchTheme(filter[0].code2)
          data.riaId = resp.code && resp.code
        }else{ 
          data.riaId = finalPartnerCode;
        }
      }
      sessionStorage.setItem('partnerCode',data.riaId)
      this.setState({loading:true})
      createUser(data,this.state.screenDetails).
      then(async res => {
        if(res.code === 200){
          const obj = {
            partnerToken: partnerToken,
            country: countryCode2,
            phoneHome: this.state.phoneNumber,
            emailAddress1: email.toLowerCase(),
            username:this.state.userName,
            nextStep: 'DOCUMENTS'
          }
          setUserInfoSession(obj)
          const {customerId = ''} = res.data
          sessionStorage.setItem('custId',customerId)

          // Get socketToken using the accessToken
          const token = JSON.parse(sessionStorage.getItem('token'));

          try {
            const socketToken = await socketLogin(
              token && token.accessToken
            )
            if (socketToken) {
              sessionStorage.setItem('socketToken', socketToken)
            }
          } catch (err) {
            console.error('error in authentication soket token', err);
          }

        let queryUrl = queryString.parse(window.location.search, {
          parseNumbers: false
        })

          //webengage
        // eslint-disable-next-line no-undef
        webengage.user.login(this.state.userName);
        // eslint-disable-next-line no-undef
        webengage.user.setAttribute('we_email', email.toLowerCase());
        // eslint-disable-next-line no-undef
        webengage.user.setAttribute('Partner', data.riaId);
         // eslint-disable-next-line no-undef
         webengage.user.setAttribute('we_phone', this.state.phoneNumber);
        // eslint-disable-next-line no-undef
        webengage.user.setAttribute('user_name', this.state.userName.toLowerCase());
        // eslint-disable-next-line no-undef
        webengage.track("User Registered II")
          this.props.history.push('/verifyidentity')
        }
      })
        .catch(error => {
          if(error && error.message)
          this.generateError(error.message)
          this.setState({loading:false})
        })
    } else {
      this.setState({ phoneNumberValidation: 'Enter Valid phone number',loading:false })
    }
  }

  keyPressHandler = (event,submitCallback) => {
    if (event.which === 13) {
      submitCallback()
    }
  }

  passwordValidation = (event, handleChange) => {
		this.setState({ passwordValidatioError: '' })
    let errorMessage = ''
		const testLength = /^(?=.{8,})/.test(event.target.value)
		const testAplha = /^(?=.*[a-z])/.test(event.target.value)
		const testUpperCase = /^(?=.*[A-Z])/.test(event.target.value)
    const testNumber = /^(?=.*[0-9])/.test(event.target.value)
    const testSpecialChar = /^(?=.*[!@#$%^&*])/.test(event.target.value)
    if(!testLength){
      errorMessage = 'Password must have 8 or more characters'
    }
    else if(!testAplha){
      errorMessage='Password needs to include atleast one lowercase alphabet'
    }
    else if(!testUpperCase){
      errorMessage='Password needs to include atleast one Uppercase alphabet'
    }
    else if(!testNumber){
      errorMessage='Password needs to include atleast number'
    }
    else if(!testSpecialChar){
      errorMessage='Password needs to include atleast one special symbol'
    }
    else{
      errorMessage=''
    }

    this.setState({passwordValidatioError:errorMessage})
	}

  togglePassword = () => {
    const type = this.state.passwordType === 'password' ? 'text' : 'password'
    this.setState({ passwordType: type })
  }

  // userNameValidation = (event,handleChange) => {
  //   this.setState({ userName: event.target.value.toLowerCase()},handleChange(event))
  // }

  calculateCallingCode = (country) => {
    const countryCode = getCountryCallingCode(country)
    const countryNumber = `+${countryCode} `
    if(!this.state.showDropDown) {
      this.setState({isPhoneParam:false})
    }
    if(this.state.phoneNumberFromParam && this.state.isPhoneParam && this.state.isPhoneParam === true) {
      this.setState({showLabelPhoneNumber:true})
      if(this.state.phoneNumberFromParam.includes('+')) {
          this.setState({phoneNumber:this.state.phoneNumberFromParam,phoneCountryCode:countryNumber})
      } else {
          const newPhoneNumber = countryNumber + this.state.phoneNumberFromParam
          this.setState({phoneNumber:newPhoneNumber,phoneCountryCode:countryNumber})
      }
    } else {
      this.setState({phoneNumber:countryNumber,phoneCountryCode:countryNumber})
    }
  }

  onPhoneNumberHandler = (event,handleChange,value) => {
    var numberRegex = /^\s*[+-]?(\d+|\d*\.\d+|\d+\.\d*)([Ee][+-]?\d+)?\s*$/
    const checkNumber =numberRegex.test(event.target.value.slice(-1))
    if(event.target.value.length >= this.state.phoneCountryCode.length){
      if(value.length > event.target.value.length){
        handleChange(event)
      }else if(checkNumber){
        handleChange(event)
      }
    }
  }

  render() {
    const { password,loading,phoneNumber,showUserName,passwordType,passwordValidatioError,phoneNumberValidation,userName,userNameValidationError } = this.state
    return (
      <>
        <Row className={styles.title}>Create an account</Row>
        <Row className={styles.subTitle}>Enter your mobile number and create a password for the username associated with your account.
        </Row>
        {showUserName ? '' :
        <Row className={styles.assignUser}>
          <Col className='p-0'>
             <div className={styles.assignUserText}>Assigned Login Username</div>
             <div className={styles.subTitle} style={{color:'#3C404A'}}>{this.state.userName}</div>
          </Col>
          <Col md={2} xs={2} className='p-0 text-center'>
            <span onClick={() => this.setState({showUserName:true})}>
              <img src={EditSvg} className={styles.iconEdit}></img>
            </span>
          </Col>
        </Row>
        }
        <Formik
					initialValues={{
					 password:password,
           phoneNumber:phoneNumber,
           userName:userName
					}}
					enableReinitialize
					validationSchema={yup.object({
						password: yup.string().required('Password is required field'),
            phoneNumber:yup.string().required('We need your contact number to register your account'),
            userName: yup.string().trim()
            .matches(/^[\Sa-zA-Z0-9.@]+$/, 'Only Alphabets, numbers, @ and . are allowed in User Name.')
            .required('User Name is required ')
          })}
          onSubmit={values => {
            const { userName, password,phoneNumber } = values
            this.setState(
              {errorMessage:'', userNameValidationError: '',phoneNumberValidation:'',passwordValidatioError:'',
               password:password,
               phoneNumber,userName
            })
            validateUserName(values.userName)
              .then(res => {
                if (res.code === 200 && res.data) {
                  if (res.data.availableWithUs) {
                    this.onCreatingUser()
                    //do api call
									} else {
                    if(phoneNumber.length === this.state.phoneCountryCode.length)
                    {
                     this.setState({phoneNumberValidation:'We need your contact number to register your account'})
                    }
										this.setState({
											userNameValidationError: 'This username is already taken. Try another?',
                      showUserName:true
										})
									}
								}else{
                  this.setState({
                    userNameValidationError: 'Only Alphabets, numbers, @ and . are allowed in User Name',
                    showUserName:true
                  })
                }
							})
							.catch(error => {
								console.log('error', error.response)
							})
					}}>
					{({
						handleChange,
						handleBlur,
						handleSubmit,
						values,
						dirty,
						touched,
						errors,
            isSubmitting
					}) => {
						return (
              <>
              {showUserName ?
                 <Row className={`${styles.inputs}`}>
                 <SignUpInput
                    label='Username'
                    placeholder = 'Enter Username'
                    type='text'
                    name='userName'
                    value={values.userName}
                    onChange={event => {
                      if(userNameValidationError)
                        this.setState({
                          userNameValidationError: ''
                        })
                        handleChange(event)
                        }
                    }
                    onBlur={e => handleBlur(e)}
                    isInvalid={
                      (touched.userName && errors.userName) ||
                      userNameValidationError !== ''
                    }
                    isValid={
                      values.userName !== '' &&
                      !errors.userName &&
                      userNameValidationError === ''
                    }
                    onPaste={(e) => {
                      e.preventDefault()
                      return false;
                    }} onCopy={(e) => {
                      e.preventDefault()
                      return false;
                    }}
                    errorMessage=	{errors.userName || userNameValidationError}
                    icon={UserSvg}
                />
             </Row>
              :''}
               <Row className={`${styles.inputs} d-block`}>
               <div className={styles.label}>Contact Number</div>
                 <div className={styles.phoneNumberDropdown}
                 style={(touched.phoneNumber && values.phoneNumber.length === this.state.phoneCountryCode.length) || errors.phoneNumber || phoneNumberValidation
                 ? {border:'1.5px solid #FA274A'} : {}}>
                      <DropDownButton
                         classes={styles.phoneButton}
                         onClick={() => this.setState({showDropDown : !this.state.showDropDown})}
                         showDropDown={this.state.showDropDown}
                         >
                          <img className={styles.flagImgCode} src={`${process.env.REACT_APP_ASSESTS}/flags/${this.state.phoneFlagCountry}.svg`}></img>
                      </DropDownButton>
                      <SignUpInput
                          containerClasses={styles.phoneContainer}
                          classes={styles.phoneInputBorder}
                          // placeholder = 'Contact Number'
                          type='text'
                          name='phoneNumber'
                          refValue={this.myRef}
                          value={values.phoneNumber}
                          onChange={event => this.onPhoneNumberHandler(event,handleChange,values.phoneNumber)}
                          onPaste={(e) => {
                            e.preventDefault()
                            return false;
                          }} onCopy={(e) => {
                            e.preventDefault()
                            return false;
                          }}
                          // onBlur={handleBlur}
                          onFocus={() => this.setState({showLabelPhoneNumber:true})}
                      />
                      <div
                      className={!this.state.showLabelPhoneNumber ?  styles.phoneNumberLabel : 'd-none' }
                      onClick={() => this.setState({showLabelPhoneNumber : true},() => this.myRef.current.focus())}>Enter Contact Number</div>
                    <OutsideClickHandler onOutsideClick={() => this.setState({showDropDown:false})} display='contents'>
                     <SignUpDropDown
                         classes={styles.phoneNumberList}
                         data={this.state.activeCountries}
                         value={this.state.phoneCountry}
                         showDropDown={this.state.showDropDown}
                         onChange={(item) => {
                          const getCountryCode = item.value
                          this.setState({phoneCountry:item.label,phoneFlagCountry:item.code2,showDropDown:false},this.calculateCallingCode(item.code2))
                           }}
                         placeholder='Country'
                      />
                     </OutsideClickHandler>
                  </div>
                  <div className={styles.errorState}>
                    {(touched.phoneNumber && values.phoneNumber.length === this.state.phoneCountryCode.length )
                    ?
                    'We need your contact number to register your account'
                    : phoneNumberValidation}
                  </div>
                 </Row>
                 <Row className={`${styles.inputs}`} style={{position:'relative',marginTop:'20px'}}>
                     <SignUpInput
                        containerClasses={styles.passwordInvalid}
                        label='Create Password'
                        placeholder = 'Create Password'
                        type={passwordType}
                        name='password'
                        value={values.password}
                        onChange={event =>{
                          this.setState({passwordValidatioError:''})
                          this.passwordValidation(event)
                          handleChange(event)
                        }
                        }
                        // onBlur={e => handleBlur(e)}
                        // isValid={
                        //   touched.password &&
                        //   !errors.password &&
                        //   passwordValidatioError === ''
                        // }
                        onKeyPress={(e) => this.keyPressHandler(e,handleSubmit)}
                        isInvalid={touched.password && errors.password || passwordValidatioError}
                        onPaste={(e) => {
                          e.preventDefault()
                          return false;
                        }} onCopy={(e) => {
                          e.preventDefault()
                          return false;
                        }}
                        errorMessage=	{errors.password || passwordValidatioError}
                        icon={PasswordSvg}
                    />
                       {
                          <img
                          className={styles.toggle}
                          src={
                            passwordType === 'password'
                              ? PasswordHide
                              : PasswordShow
                          }
                          onClick={() => {
                            const type = passwordType === 'password' ? 'text' : 'password'
                            this.setState({passwordType:type})
                          }}></img>}
                 </Row>
                {(touched.password && errors.password )|| passwordValidatioError ? '' :
                  <div className={styles.passwordValidationText}>Passwords should be a min. of 8 characters with a mix of letters, numbers & symbols (Example: Abcd@123)</div>
                }
              <Row className={styles.buttonMargin}>
              <div className={styles.errorState} style={{paddingBottom:'10px'}}>{this.state.errorMessage}</div>
                  <SignUpButton
                    title='Create Account'
                    onClick={handleSubmit}
                    loading={loading}
                    // disabled={!disableButton || isSubmitting}
                  />
              </Row>
            </>
            )
            }}
        </Formik>

      </>
    )
  }
}

export default withRouter(PhoneNumber)
