import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styles from './indianBankAccount.module.css'
import { Row, Col,Modal,Button } from 'react-bootstrap'
import backImage from '../../public/images/back.png'
import OtherBankImage from '../../public/images/otherbankimage.png'



const IndianBankAccount = ({
	setModalData,
	data,
	setToggel,
	setCheck,
	back,
	setReview,
	review,
	setPickupInformation,
	banksList,
	setClickedBankDetails,
	foundAccount,
	otherCountryDetails,
	setBanksList,
	stringCollection,
  selectedBank,
  hashId
}) => {
  let history = useHistory()
	const [onlineTransfer, setOnlineTransfer] = useState([])
	const [offlineTransfer, setOfflineTransfer] = useState([])
	const [visitBank, setVisitBank] = useState([])
	const [temp, setTemp] = useState([])
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {partner = {}} = JSON.parse(sessionStorage.getItem('userInfo'))
  let easyFundTransfer = false;

  if (banksList) {
    banksList.forEach(bank => {
      if(bank.isInstaPay) {
        easyFundTransfer = true;
      }
    });
  }
	const handleCheck = (value, name, id) => {

		!foundAccount &&
			setPickupInformation(
				{
					applicantPan: '',
							selfPickup: true,
							hasDoneAnyPreviousLRSinYear: false,
							transactionNumber: '',
							pickUpDate: '',
							pickUpTime: '',
							address1: '',
							address2: '',
							country: 'India',
							postalCode: '',
							city: '',
							state: ''

				},
				setReview(
					{
						bankName: '',
						ifsc: '',
						accountNumber: '',
						bankAddress: ''
					},

					setClickedBankDetails(
						{ bankName: name, bankType: value, bankId: id },
						setCheck(value, setModalData(data))
					)
				)
			)

		// foundAccount &&
		// 	(value === 'other'
		// 		? setModalData(stringCollection.review)
		// 		: value === 'pickup'
		// 		? setModalData(stringCollection.pickup)
		// 		: setModalData(stringCollection.instruction))


		foundAccount &&
			setClickedBankDetails(
				{ bankName: name, bankType: value, bankId: id },
				value === 'other'
        ? otherRedirection()
					: value === 'pickup'
					? setModalData(stringCollection.pickup)
					: setModalData(stringCollection.instruction)
			)
	}

	useEffect(() => {
		if (foundAccount) {
      selectedBank &&
      selectedBank.bankName &&
      banksList &&
      banksList.map((item, i) => {
        item.name ===
          ( selectedBank &&
            selectedBank.bankName
            ? selectedBank.bankName
            : '') && setTemp(prevState => [...prevState, item])
      })
        // setBanksList(temp)
		}
    else{
			banksList &&
			banksList.map((item, i) => {
				item && item.type && item.type === 'online_transfer'
					? setOnlineTransfer(prevState => [...prevState, item])
					: item.type === 'from_pickup'
					? setOfflineTransfer(prevState => [...prevState, item])
					: setVisitBank(prevState => [...prevState, item])
			})
    }
	}, [banksList, foundAccount, selectedBank])


  const otherRedirection = () => {
    // if(easyFundTransfer){
    //   return  history.push(`/funds/${hashId}/insta-pay`)
    // }
    // else{
    return setModalData(stringCollection.review)
    // }
  }

  const netBanking = (onlineTransfer) => {
    if (onlineTransfer && onlineTransfer.length > 0)
    {
      return (<>
			<Row
				style={{
					marginTop: '40px'
				}}>
				<Col>
					<div className={styles.transfer}>Netbanking</div>
				</Col>
			</Row>
      <Row
        className='d-none d-md-flex'
				style={{
					paddingLeft: '30px',
				}}>
				{onlineTransfer.length &&
					onlineTransfer.map((trav, i) => {
						return (
							<Row
								key={i}
								className={styles.bankCard}
								onClick={() => {
									handleCheck('online', trav.name, trav._id)
								}}>
								<Col sm={3} md={3} style={{ paddingTop: '10px'}}>
									{trav.logo && (
										<img src={trav.logo} width='32px' height='32px' />
									)}
								</Col>
								<Col>
									<div
										className={styles.bankName}
										style={{
											paddingTop: '15px',
											textAlign: 'start'
										}}>
										{trav.name && trav.name}
									</div>
								</Col>
							</Row>
						)
					})}
			</Row>
      <Row className='d-flex d-md-none'>
          {
            onlineTransfer.length &&
            onlineTransfer.map((trav, i) => {
              return(
                <Col sm={3} xs={4} style={{textAlign:"center"}} key={i}>
                  <div onClick={() => {
									handleCheck('online', trav.name, trav._id)
								}}>
                  <div className={styles.bankCard}>
                  {trav.logo && (
										<img src={trav.logo} width='32px' height='32px' />
									)}
                  </div>

                  <div className={styles.bankName}>
                  {trav.name && trav.name}
                  </div>
                  </div>
                </Col>
              )
            })
          }
      </Row>
      </>);
    }
    return '';
  }

  const offlineBanking = (offlineTransfer) => {
    if(offlineTransfer  && offlineTransfer.length > 0) {
      return (<>
        <Row
          style={{
            marginTop: '48px',
          }}>
          <Col>
            <div className={styles.transfer}>Offline Transfer</div>
          </Col>
        </Row>
        <Row
        className='d-none d-md-flex'
          style={{
            paddingLeft: '30px'
          }}>
          {offlineTransfer.length &&
            offlineTransfer.map((trav, i) => {
              return (
                <Row
                  key={i}
                  className={styles.bankCard}
                  onClick={() => {
                    handleCheck('pickup', trav.name, trav._id)
                  }}>
                  <Col sm={3} md={3} style={{ paddingTop: '10px' }}>
                    {trav.logo && (
                      <img src={trav.logo} width='32px' height='32px' />
                    )}
                  </Col>
                  <Col>
                    <div
                      className={styles.bankName}
                      style={{
                        paddingTop: '15px',
                        textAlign: 'start'
                      }}>
                      {trav.name && trav.name}
                    </div>
                  </Col>
                </Row>
              )
            })}
        </Row>
        <Row  className='d-flex d-md-none'>
            {
              offlineTransfer.length &&
              offlineTransfer.map((trav, i) => {
                return(
                  <Col sm={3} xs={4} style={{textAlign:"center"}} key={i}>
                    <div onClick={() => {
                    handleCheck('pickup', trav.name, trav._id)
                  }}>
                    <div className={styles.bankCard}>
                    {trav.logo && (
                      <img src={trav.logo} width='32px' height='32px' />
                    )}
                    </div>

                    <div className={styles.bankName}>
                    {trav.name && trav.name}
                    </div>
                    </div>
                  </Col>
                )
              })
            }
        </Row>
        </>);
    }
    return '';
  }



  const otherAndInstaPay = (visitBank, easyFundTransfer) => {
    if(visitBank && visitBank.length > 0) {
      return (<>
        <Row
          style={{
            marginTop: '48px',
          }}>
          <Col>
            {
              easyFundTransfer ?
                <div className={styles.transfer}>Online Transfer</div> :
                <div className={styles.transfer}>Other Bank</div>
            }

          </Col>
        </Row>
        <Row style={{paddingLeft:'30px'}} className='d-none d-md-flex'>
          <Row
            className={styles.bankCard}
            onClick={() => {
              easyFundTransfer ?  handleShow():handleCheck('other', '', otherId)
            }}>
            <Col sm={3} md={3} style={{ paddingTop: '10px' }}>
                <img src={OtherBankImage} />
            </Col>
            <Col>
              <div
                className={styles.bankName}
                style={{
                  paddingTop: easyFundTransfer ? '4px' : '15px',
                  textAlign: 'start'
                }}>
                  {easyFundTransfer ?
                  <>
                  <span>
                  Stockal EasyFund Transfer
                  </span>
                  <span className={styles.betaTitle}>beta</span>
                  </>
                  : 'Other Bank'}
                {/* Other Bank */}
              </div>
            </Col>
          </Row>
        </Row>

         {/* for mobile  */}
        <Row  className='d-flex d-md-none'>
         <Col sm={3} xs={4} style={{textAlign:"center"}}>
            <div  onClick={() => {
              easyFundTransfer ?  handleShow():handleCheck('other', '', otherId)
            }}>
            <div className={styles.bankCard}>
              <img src={OtherBankImage} width='32px' height='32px' />
            </div>

            <div className={styles.bankName}>
            {easyFundTransfer ?  <>
                <span>
                 Stockal EasyFund Transfer
                 </span>
                 <span className={styles.betaTitle}>beta</span>
                 </> : 'Other Bank'}
            </div>
            </div>
          </Col>
        </Row>






            {/* <div className={styles.transfer} 	onClick={() => {
                    handleCheck('other', '', otherId)
                  }} style={{cursor:'pointer'}}>Other Bank</div> */}

        {/* <Row
        className='d-none d-md-flex'
          style={{
            paddingLeft: '30px'
          }}>
          {visitBank.length &&
            visitBank.map((trav, i) => {
              return (
                <Row
                  key={i}
                  className={styles.bankCard}
                  onClick={() => {
                    handleCheck('other', trav.name, trav._id)
                  }}>
                  <Col sm={3} md={3} style={{ paddingTop: '10px' }}>
                    {trav.logo && (
                      <img src={trav.logo} width='32px' height='32px' />
                    )}
                  </Col>

                  <Col>
                    <div
                      className={styles.bankName}
                      style={{
                        paddingTop: '15px',
                        textAlign: 'start'
                      }}>
                      {trav.code && trav.code}
                    </div>
                  </Col>
                </Row>
              )
            })}
        </Row>

        <Row  className='d-flex d-md-none'>
            {
              visitBank.length &&
              visitBank.map((trav, i) => {
                return(
                  <Col sm={3} xs={3} style={{textAlign:"center"}}>
                    <div onClick={() => {
                    handleCheck('other', trav.name, trav._id)
                  }}>
                    <div className={styles.bankCard}>
                    {trav.logo && (
                      <img src={trav.logo} width='32px' height='32px' />
                    )}
                    </div>

                    <div className={styles.bankName}>
                    {trav.code && trav.code}
                    </div>
                    </div>
                  </Col>
                )
              })
            }
        </Row> */}
        </>);
    }
    return '';

  }

  useEffect(()=>{
    if(foundAccount && selectedBank && selectedBank.bank && selectedBank.bank.code.includes('OTHER_INDIAN')){
      setCheck('other')
      otherRedirection()
    }
    temp &&
      temp.map((item, i) => {
        item && item.type && item.type === 'online_transfer'
          ? setOnlineTransfer([item])
          : item.type === 'from_pickup'
          ? setOfflineTransfer([item])
          : setVisitBank([item])
      })
  },[temp])
  const filter = banksList.filter((item) => item.code === 'OTHER_INDIAN')
  const otherId = filter.length ? filter[0]._id : ''
  const arrMap = []
  if (easyFundTransfer) {
    arrMap.push(otherAndInstaPay(visitBank, easyFundTransfer), netBanking(onlineTransfer), offlineBanking(offlineTransfer),otherAndInstaPay(visitBank, false))
  } else {
    arrMap.push(netBanking(onlineTransfer), offlineBanking(offlineTransfer), otherAndInstaPay(visitBank, easyFundTransfer));
  }
	return (
		<div className={styles.card}>
			<Row
        className={styles.responsiveDiv}>
				<Col>
					<div onClick={() =>
								setClickedBankDetails(
									{ bankName: '', bankType: '', bankId: '' },
									setCheck('', setModalData(back))
								)
							}>
						<img src={backImage}
              style={{cursor: 'pointer'}}/>
						<span
							className={styles.title}
							style={{
								paddingLeft: '20px',
                cursor:'pointer'
							}}>
							{'Indian Bank Accounts'}
						</span>
					</div>
				</Col>
			</Row>
      {
        arrMap.map(item => item)
      }


      {/* instapay modal */}
       <Modal show={show} centered>
        <Modal.Body className={styles.bankName} style={{textAlign:'start',whiteSpace:'unset',width:'100%'}}>
          <p>The Easyfund payment functionality had been available on the platform as a part of the RBI sandbox. While we got great results from customers on their active participation of using this mode to fund account, this notice is to inform you that the sandbox will be paused for a few weeks starting <b className={styles.transfer}>8th Feb 2022</b> to evaluate the results. As a result of this, the Stockal Easyfund payment mode will temporarily not be available starting <b>8th Feb 2022.</b></p>
           <p>
           We will be back soon with an upgraded version and will keep you posted.
           </p>
           <p>
           All the transactions until 7th Feb EOD will be processed and can be tracked by the customer in their remittance history.
           </p>
           <p>
           Should you have any questions, please do not hesitate to contact us at <a href={"mailto:customersuccess@stockal.com"}>stockal.com</a>
           </p>
           <div style={{textAlign:'end'}}>
           <Button onClick={handleClose} className={styles.okbutton}>OK</Button>
           </div>
        </Modal.Body>
      </Modal>
		</div>
	)
}

export default IndianBankAccount
