import { initialState } from '../initialState'
import { YEAR_HIGH } from '../actions/action'

const yearHighStock = (state = initialState.yearHighStock, action) => {
	switch (action.type) {
		case YEAR_HIGH: {
			return { ...state, yearHighStock: action.payload }
		}
		default: {
			return state
		}
	}
}

export default yearHighStock

