import { initialState } from '../initialState'
import { MY_NOTIFICATION } from '../actions/action'

const AllNotification = (state = initialState.myNotification, action) => {
	switch (action.type) {
		case MY_NOTIFICATION: {
			return { ...state, myNotification: action.payload }
		}
		default: {
			return state
		}
	}
}

export default AllNotification

