import {decimalAdjust, addCommasinPrice} from './utils'

const portfolioObject = (hold) => {
return {
  Symbol: hold.Symbol ? hold.Symbol : '',
  Name: hold.Name ? hold.Name : '',
  Price: hold.Price ? `$${hold.Price}` : '',
  'PriceChange':hold['PriceChange'] ? `${hold['PriceChange']}%` :'',
  Shares: hold.Shares ? hold.Shares : '',
  'Avg.Cost': hold['Avg.Cost'] ? `$${decimalAdjust('floor',hold['Avg.Cost'],-2)}` : '' ,
  'TotalInvested': hold['Total Invested'] ? `$${addCommasinPrice(hold['Total Invested'])}` : '' ,
  'TotalReturn': hold['Total Return'] ? `$${hold['Total Return']}` : '' ,
  'ReturnPercent': hold['Return Percent'] ? `${hold['Return Percent']}%`: '',
  'CurrentValue': hold['Current Value'] ? `$${addCommasinPrice(hold['Current Value'])}` : '',
  Type: hold.Type,
}
}


function convertToCSV(objArray, type) {
  var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  var str = '';


  for (var i = 0; i < array.length; i++) {
      var line = '';

    switch(type) {
      case "portfolio" : var object_ = portfolioObject(array[i]); break;
      default:
    }

    Object.keys(object_).forEach(key=>{
        if (line != '') line += ','
        line += `"${object_[key]}"`;
    })

      str += line + '\r\n';
  }

  return str;
}


export const exportCSVFile = ( items, fileTitle, headers, type) => {
  // if (headers) {
  //     items.unshift(headers);
  // }

  // Convert Object to JSON
  var jsonObject = JSON.stringify(items);

  var csv = headers + convertToCSV(jsonObject, type);

  var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

  var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
      var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      }
  }
}
