import React, { Fragment, useState, useEffect, createRef } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { Formik } from 'formik'
import * as yup from 'yup'
import {isPossiblePhoneNumber, isValidPhoneNumber} from 'react-phone-number-input'
import Dropdown from '../../DropDown/DropDown'
import DateInput from './DateInput';
import PhoneInput from './PhoneInput';
import styles from './Nominee.module.css'
import profileStyles from '../Profile.module.css'
import {PRIMARY, CONTINGENT, CONTINGENT_LINK} from './type'
import {postNomineeList} from '../../../api'
import info from '../../../public/images/info.svg'

let keyIndex=0;
const EditNominee=(props)=>{
    const [primaryList, setPrimaryList] = useState([{beneficiaryType:'PERSON', index:0}]);
    const [contingentList, setContingentList] = useState([]);
    const [primaryCount, setPrimaryCount] = useState(1);
    const [newNominee, setNewNominee]=useState({section:PRIMARY,index:0});
    const [contingentCount, setContingentCount] = useState(0); 
    const [activeSection, setActiveSection]=useState(PRIMARY);
    const [showError, setShowError]=useState(false);
    const [showPercentageError, setShowPercentageError]=useState(false);
    const [showSaveError, setShowSaveError]=useState({});
    const [isSaving, setIsSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const [saveText, setSaveText] = useState('Save');

    const priamryErrorCount=[0,0,0,0];
    const contingentErrorCount=[0,0,0,0];

    useEffect(()=>{
        if(props.primaryList.length > 0){
            setPrimaryList(props.primaryList);
            setPrimaryCount(props.primaryList.length);
            setNewNominee({})
            keyIndex=props.primaryList.length+props.contingentList.length-1;
        }
        if(props.contingentList.length > 0){
            setContingentList(props.contingentList);
            setContingentCount(props.contingentList.length);
        }
        if(props.editSection!==PRIMARY){
            setActiveSection(props.editSection);
        }
        setLoading(false)
    },[props])
    const onChangeField=(section, index, name , value)=>{
        if(section===PRIMARY){
            let updateList;
            updateList=[...primaryList];
            updateList[index] ={...updateList[index], [name]: value};
            setPrimaryList(updateList);
        }
        else if(section===CONTINGENT){
            let updateList;
            updateList=[...contingentList]
            updateList[index] ={...updateList[index], [name]: value}
            setContingentList(updateList);
        }
    }
    const onDelete = (section, index)=>{
        if(section===PRIMARY){
            setPrimaryCount(primaryCount-1);
            let updateList = [...primaryList];
            updateList.splice(index,1);
            setPrimaryList(updateList)
            priamryErrorCount.splice(index, 1)
            priamryErrorCount.push(0)
        }else if(section===CONTINGENT){
            setContingentCount(contingentCount-1);
            let updateList = [...contingentList];
            updateList.splice(index,1);
            setContingentList(updateList)
            contingentErrorCount.splice(index, 1)
            contingentErrorCount.push(0)
        }
        if(newNominee.section === section){
            if(newNominee.index===index){
                setNewNominee({});
            }else if(newNominee.index > index){
                setNewNominee({...newNominee, index:newNominee.index-1})
            }
        }
    }
    const onAdd=(section)=>{
        keyIndex++;
        if(section===PRIMARY){
            setNewNominee({section,index:primaryCount});
            setShowError(false)
            setPrimaryList([...primaryList,{beneficiaryType:'PERSON',index:keyIndex}]);
            setPrimaryCount(primaryCount+1);
        }else if(section===CONTINGENT){
            setNewNominee({section,index:contingentCount});
            setShowError(false)
            setContingentList([...contingentList,{beneficiaryType:'PERSON',index:keyIndex}])
            setContingentCount(contingentCount+1);
        }
    }
    //check if current section total percentage is valid and move to next section
    const changeSection = async (target)=>{
        let totalPercentage=0;
        if(activeSection===PRIMARY){
            totalPercentage=primaryList.reduce((previous, current)=>previous+parseFloat(current.benefitPct), 0);
        }
        else if(activeSection===CONTINGENT){
            totalPercentage=contingentList.reduce((previous, current)=>previous+parseFloat(current.benefitPct), 0);
        }
        if(!(totalPercentage ===0 || totalPercentage === 100)){
            setShowPercentageError(true);
            return;
        }
        else{
            setShowPercentageError(false);
        }
        setActiveSection(target)
    }
    const onSave = async()=>{
        let totalErrorCount=0;
        totalErrorCount=priamryErrorCount.reduce((previous, current)=>previous+current)
        totalErrorCount=contingentErrorCount.reduce((previous, current)=>previous+current, totalErrorCount)
        if(totalErrorCount>0){
            setShowSaveError({show:true,type:'field',message:'Please enter valid values before saving!!!'});
            setShowError(true)
            return;
        }
        else{
            setShowSaveError({});
            setShowError(false);
        }
        setIsSaving(true);
        var x = 0;
        const text=['Saving.','Saving..','Saving...'];
        setSaveText(text[0]);
        const dotInterval = setInterval((current)=>{
            x++;
            setSaveText(text[x % 3]);
        }, 750);
        setShowSaveError({});
        let totalPercentage=0;
        totalPercentage = primaryList.reduce((previous, current)=>previous+parseFloat(current.benefitPct), 0);
        if(totalPercentage !== 100){
            setShowPercentageError(true);
            clearInterval(dotInterval);
            setSaveText('Save');
            setIsSaving(false);
            return
        }
        totalPercentage = contingentList.reduce((previous, current)=>previous+parseFloat(current.benefitPct), 0);
        if(!(totalPercentage ===0 || totalPercentage === 100)){
            setShowPercentageError(true);
            clearInterval(dotInterval);
            setSaveText('Save');
            setIsSaving(false);
           return 
        }
        setShowPercentageError(false);
        const data=[];
        primaryList.forEach(nominee=>{
            const current={...nominee}
            delete current.index;
            if(current.beneficiaryType==='PERSON'){
                delete current.formationDt;
                delete current.primaryContact;
            }else if(current.beneficiaryType==='ENTITY'){
                delete current.beneficiaryDob;
            }
            current.isPrimary=true;
            current.benefitPct=parseFloat(current.benefitPct/100);
            if(current.stoBeneficiaryId)
                current.stoBeneficiaryId=parseInt(current.stoBeneficiaryId);
            data.push(current);
        })
        contingentList.forEach(nominee=>{
            const current={...nominee}
            delete current.index;
            if(current.beneficiaryType==='PERSON'){
                delete current.formationDt;
                delete current.primaryContact;
            }else if(current.beneficiaryType==='ENTITY'){
                delete current.beneficiaryDob;
            }
            current.isPrimary=false;
            current.benefitPct=parseFloat(current.benefitPct/100)
            if(current.stoBeneficiaryId)
                current.stoBeneficiaryId=parseInt(current.stoBeneficiaryId)
            data.push(current);
        })
        const payload={beneficiaries:data}
        try{
            await postNomineeList(payload)
        }catch(error){
            setShowSaveError({show:true,type:'api',message:'Sorry, Could not update your nominee list at the moment. Please try again later!!!'})
            setIsSaving(false);
            clearInterval(dotInterval);
            setSaveText('Save');
            return;
        }
        clearInterval(dotInterval);
        setSaveText('Save');
        setIsSaving(false);
        props.onSave();
    }
    const validatePercentage=(value)=>{
        const pattern = /^[0-9]*$/
        if(value &&(!pattern.test(value)))
            return false;
        return true
    }
    //function to make sure no field error exists before adding new nominee or moving to next section
    const validate = (next)=>{
        let count=0;
        if(activeSection===PRIMARY){
            count = priamryErrorCount.reduce((previous, current)=>previous+current);
        }
        else if(activeSection===CONTINGENT){
            count = contingentErrorCount.reduce((previous, current)=>previous+current);
        }else{
            count = priamryErrorCount.reduce((previous, current)=>previous+current);
            count+= contingentErrorCount.reduce((previous, current)=>previous+current);
        }
        if(count>0)
            setShowError(true);
        else{
            if(showSaveError.type==='field')
                setShowSaveError({})
            setNewNominee({});
            next();
        }
    }
    yup.addMethod(yup.mixed, "phoneValidation", function(message){
        return this.test("phoneValidation", message, function (value) {
            const { path, createError } = this;
            if (isPossiblePhoneNumber(value+'')&&isValidPhoneNumber(value+'')) {
                return true
            }else{
                return createError({ path, message });
            }        
        });
    });
    const getContingentTitle=()=>{
        return <Fragment>
            <span>Contingent Nominee</span>
            <img src={info} className={styles.popOverImage} onClick={(e)=>{ window.open(CONTINGENT_LINK, '_blank').focus();e.stopPropagation();}}/>
        </Fragment>
    }
    const getActiveTemplate=(section, count, list)=>{
        let templateList = [];
        const schema = yup.object().shape({
            beneficiaryName: yup.string().trim()
            .required(`Nominee's Name is required`).nullable(),
            benefitPct: yup.number()
            .required('Percentage is required')
            .typeError('Percentage must be a number')
            .lessThan(101, 'Percentage must be less than or equal to 100')
            .moreThan(0, 'Percentage must be greater than or equal to 1'),
            beneficiaryType: yup.string().trim()
            .required('Nominee Type is required').nullable(),
            beneficiaryEmail: yup.string().trim()
				.email('Invalid Email')
				.required('Email is required').nullable(),
            beneficiaryDob: yup.string().trim()
            .required('Date of Birth is required').nullable(),
            beneficiaryPhone: yup.mixed()
            .phoneValidation('Enter valid phone number')
            .required('Date of Birth is required'),
            formationDt: yup.string().trim()
            .required('Formation Date is required').nullable(),
            primaryContact: yup.string().trim()
            .required('Primary Contact Name is required').nullable()
        });
        templateList.push(<Row key={`activeHeader${section}`} className={`mb-4 d-block ${profileStyles.name} ${styles.section}`}>{section===PRIMARY?'Primary Nominee':getContingentTitle()}</Row>)
        for(let i=0;i<count;i++){
            let defaultError={};
            if(newNominee.section===section && newNominee.index===i){
                defaultError={
                    beneficiaryName: `Nominee's Name is required`,
                    benefitPct: 'Percentage is required',
                    beneficiaryEmail: 'Email is required',
                    beneficiaryDob: 'Date of Birth is required',
                    beneficiaryPhone: 'Phone Number is required',
                    formationDt: 'Formation Date is required',
                    primaryContact: 'Primary Contact Name is required'
                }
            }
            templateList.push(
                <Formik
                key={`nominee${list[i].index}`}
                initialValues={{...list[i]}}
                validationSchema={schema}
                initialErrors={defaultError}
                enableReinitialize
                    >
       {({ errors, touched, handleChange, handleBlur }) => {
            let count = 0;
            for(let key in errors){
                    if(errors[key] && list[i]['beneficiaryType']==='PERSON'&&key!=='formationDt'&&key!=='primaryContact')
                        count++;
                    else if(errors[key] && list[i]['beneficiaryType']==='ENTITY'&&key!=='beneficiaryDob')
                        count++;
                    else if(errors[key] && key==='beneficiaryType')
                        count++;
            }
            if(section===PRIMARY)
                priamryErrorCount[i]=count;
            else
                contingentErrorCount[i]=count;
            return(
            <Form key={`edit${list[i].index}`}>
                <Row>
                    <Col xl={12} className={styles.subHeading}><span>Nominee {i+1}</span>
                        {!(section===PRIMARY && primaryCount===1) ?<Button className={profileStyles.editButton} onClick={()=>onDelete(section, i)}>
                            <img
                                src={require('../../../public/images/deleteAccount.svg')}
                                className='mr-1'></img>
                                Delete
                        </Button>:null} 
                   </Col>
                    <Col xl={4} lg={4} md={6} sm={6} className={styles.nomineeInput}>
                        <Form.Group className={`form-focus ${list[i] && list[i].beneficiaryName ? 'focused' : null} `}>
                            <Form.Control className={`${styles.textInput} ${styles.textBorder}`} type="text" id={`beneficiaryName${list[i].index}`} name="beneficiaryName" autoComplete='off'
                                onChange={(e)=>{onChangeField(section, i, e.target.name, e.target.value);handleChange(e) }} value={list[i]?list[i].beneficiaryName:null} 
                                onBlur={handleBlur}></Form.Control>
                            <Form.Label className='focus-label'>Full Name (As per ID)</Form.Label>
                            {errors.beneficiaryName && (touched.beneficiaryName || showError) && <div className={styles.errorText}>{errors.beneficiaryName}</div>}
                        </Form.Group>
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={6} className={styles.nomineeInput}>
                    <Form.Group className={`form-focus ${list[i] && list[i].benefitPct ? 'focused' : null}`}>
                            <Form.Control className={`${styles.textInput} ${styles.textBorder}`} type="text" id={`benefitPct${list[i].index}`} name="benefitPct" autoComplete='off'
                            onChange={(e)=>{
                                if(validatePercentage(e.target.value)){
                                    onChangeField(section, i, e.target.name, e.target.value);
                                    handleChange(e)
                                }
                            }} 
                            value={list[i] && list[i].benefitPct?list[i].benefitPct:''}
                            onBlur={handleBlur}></Form.Control>
                            <Form.Label className='focus-label'>Percentage</Form.Label>
                            {errors.benefitPct && (touched.benefitPct || showError) && <div className={styles.errorText}>{errors.benefitPct}</div>}
                        </Form.Group>
                      
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={6} className={styles.nomineeInput}>
                        <Dropdown
                                data={[{ value: 'PERSON', label: 'PERSON' },{ value: 'ENTITY', label: 'ENTITY' }]}
                                name={`beneficiaryType${list[i].index}`}
                                value={list[i].beneficiaryType}
                                className={styles.dropdown}
                                placeholder={`Nominee's Type`}
                                isLabeled={true}
                                disableSearch={true}
                                onChange={value => {onChangeField(section, i, 'beneficiaryType', value);handleChange({target:{name:'beneficiaryType', value:value}})}}
                            />
                        {errors.beneficiaryType && <div className={styles.errorText}>{errors.beneficiaryType}</div>}

                    </Col>
                    <Col xl={4} lg={4} md={6} sm={6} className={styles.nomineeInput}>
                        <Form.Group className={`form-focus ${list[i] && list[i].beneficiaryEmail ? 'focused' : null}`}>
                        <Form.Control className={`${styles.textInput} ${styles.textBorder}`} type="email" id={`beneficiaryEmail${list[i].index}`} name="beneficiaryEmail" autoComplete='off'
                            onChange={(e)=>{onChangeField(section, i, e.target.name, e.target.value);handleChange(e)}} value={list[i]?list[i].beneficiaryEmail:null}
                            onBlur={handleBlur}></Form.Control>
                        <Form.Label className='focus-label'>Email</Form.Label>
                        {errors.beneficiaryEmail && (touched.beneficiaryEmail || showError) && <div className={styles.errorText}>{errors.beneficiaryEmail}</div>}
                        </Form.Group>
                    </Col>
                    {list[i] && list[i].beneficiaryType==="PERSON" ?<Col xl={4} lg={4} md={6} sm={6} className={styles.nomineeInput}>
                        <DateInput name="Date of Birth" className={styles.nomineeInput} id={`beneficiaryDob${list[i].index}`} value={list[i]?list[i].beneficiaryDob:null} onChange={(value)=>{onChangeField(section, i, 'beneficiaryDob', value);handleChange({target:{name:'beneficiaryDob', value:value}})}}
                        onBlur={()=>handleBlur({target:{name:'beneficiaryDob', value: list[i]?list[i].beneficiaryDob:null}})}/>
                        {errors.beneficiaryDob && (touched.beneficiaryDob || showError) && <div className={styles.errorText}>{errors.beneficiaryDob}</div>}
                    </Col>:null}
                    <Col xl={4} lg={4} md={6} sm={6} className={styles.nomineeInput}>
                        <PhoneInput value={list[i]?list[i].beneficiaryPhone:null} className={styles.nomineeInput} id={`beneficiaryPhone${list[i].index}`} onChange={(value)=>{onChangeField(section, i, 'beneficiaryPhone', value);handleChange({target:{name:'beneficiaryPhone', value:value}})}}
                        onBlur={()=>handleBlur({target:{name:'beneficiaryPhone', value: list[i]?list[i].beneficiaryPhone:null}})}/>
                        {errors.beneficiaryPhone && (touched.beneficiaryPhone || showError) && <div className={styles.errorText}>{errors.beneficiaryPhone}</div>}
                    </Col>
                    {list[i] && list[i].beneficiaryType==="ENTITY"?<Fragment>
                    <Col xl={4} lg={4} md={6} className={`d-sm-none d-md-block ${styles.nomineeInput}`}>
                        <DateInput name="Formation Date Of Entity" id={`formationDt${list[i].index}`} value={list[i]?list[i].formationDt:null} onChange={(value)=>{onChangeField(section, i, 'formationDt', value);handleChange({target:{name:'formationDt', value:value}})}}
                        onBlur={()=>handleBlur({target:{name:'formationDt', value: list[i]?list[i].formationDt:null}})}/>
                        {errors.formationDt && (touched.formationDt || showError) && <div className={styles.errorText}>{errors.formationDt}</div>}
                    </Col>
                    <Col sm={6} className={`d-none d-sm-block d-md-none ${styles.nomineeInput}`}>
                        <DateInput name="Formation Date" id={`formationDt${list[i].index}`} value={list[i]?list[i].formationDt:null} onChange={(value)=>{onChangeField(section, i, 'formationDt', value);handleChange({target:{name:'formationDt', value:value}})}}
                        onBlur={()=>handleBlur({target:{name:'formationDt', value: list[i]?list[i].formationDt:null}})}/>
                        {errors.formationDt && (touched.formationDt || showError) && <div className={styles.errorText}>{errors.formationDt}</div>}
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={6} className={styles.nomineeInput}>
                        <Form.Group className={`form-focus ${list[i] && list[i].primaryContact ? 'focused' : null}`}>
                            <Form.Control className={`${styles.textInput} ${styles.textBorder}`} type="text" id={`primaryContact${list[i].index}`} name="primaryContact" autoComplete='off'
                                onChange={(e)=>{onChangeField(section, i, e.target.name, e.target.value);handleChange(e)}} value={list[i]?list[i].primaryContact:null}
                                onBlur={handleBlur}></Form.Control>
                            <Form.Label className='focus-label'>Primary Contact Name</Form.Label>
                            {errors.primaryContact && (touched.primaryContact || showError) && <div className={styles.errorText}>{errors.primaryContact}</div>}
                        </Form.Group>
                    </Col>
                    </Fragment>:null}
                </Row>
            </Form> )}  
            }   
            </Formik>);
        }
        templateList.push(<Row key={`percentageError${section}`} className='justify-content-between'>
            <Col>
                {showPercentageError?<div className={styles.errorText}>{section===PRIMARY?'Primary':'Contingent'} Nominee(s) total percentage should be equal to 100</div>:null}
            </Col>
        </Row>);
        templateList.push(<Row key={`add${section}`} className='justify-content-between'>
            <Col col={6}>{count<4?<Button className={`mt-3 ${styles.addButton}`} onClick={()=>validate(()=>onAdd(section))}>
                <span className={styles.plusSymbol}>Add Nominee</span>
            </Button>:null}</Col>
        </Row>);
        templateList.push(section!==CONTINGENT?<hr key={`divider${section}`} className={styles.borderBottom}/>:null)
        return templateList
    }
    const getInActiveTemplate=(section)=>{
        let templateList = [];
        templateList.push(
            <Row key={`inactiveHeader${section}`} className={`mb-4 justify-content-between ${profileStyles.name} ${styles.section}`}>
                <span>{section===PRIMARY?'Primary Nominee':getContingentTitle()}</span>
                    <Button className={profileStyles.editButton} onClick={()=>validate(()=>changeSection(section))}>
                    {section===PRIMARY || contingentList.length>0 ? <Fragment><img
                            src={require('../../../public/images/edit.svg')}
                            className='mr-1'></img>
                            Edit
                    </Fragment>: <span className={styles.plusSymbol}>Add</span>}
                    </Button>
            </Row>)  
            let current=section===PRIMARY?primaryList:contingentList;
            if(current.length>0)
                templateList.push(<Row key={`inactiveBody${section}`}>
                    <Col className={profileStyles.value}>
                        {current.map((item, i)=>`${i!==0?', ':''}${item.beneficiaryName}(${item.benefitPct}%)`)}
                    </Col>
                </Row>)          
        return (<Fragment>
                <div className={styles.clickable} onClick={()=>validate(()=>changeSection(section))}>{templateList}</div>
                {section!==CONTINGENT?<hr className={styles.borderBottom} />:null}
            </Fragment>);
    }
    return(
    <Fragment>{!loading?
        <Fragment>
            {activeSection===PRIMARY? getActiveTemplate(PRIMARY, primaryCount, primaryList):getInActiveTemplate(PRIMARY)}
            {activeSection===CONTINGENT? (contingentCount===0 ? 
                    <Fragment>
                        <Row className={`mb-4 d-block ${profileStyles.name}`}>{getContingentTitle()}</Row>
                        <Row className='flex-column align-items-center'>
                            <p className={styles.info}>Contingent Nominee(s) are optional. Do you wish to add?</p>
                            <Button className={`m-auto ${profileStyles.editButton} ${styles.noNomineeButton}`} onClick={()=>validate(()=>onAdd(CONTINGENT))}>
                                    <span className={styles.plusSymbol}>Add Contingent</span>
                            </Button>
                        </Row>
                    </Fragment>:getActiveTemplate(CONTINGENT, contingentCount, contingentList))
                :getInActiveTemplate(CONTINGENT)}
            <Row className="d-none d-md-flex">
                <Col className="d-flex justify-content-between mt-5">
                    <span className={`p-0 d-flex align-items-center ${styles.errorText}`}>
                        {showSaveError.show?showSaveError.message:null}
                    </span>
                    <div className="d-flex">
                        <Button className={`${styles.cancelButton} ${styles.btn}`} onClick={props.onCancel}  disabled={isSaving}>
                            Cancel
                        </Button>
                        <Button className={`${profileStyles.saveButton} ${styles.btn} ${isSaving?styles.saveDisabled:null}`} onClick={onSave} disabled={isSaving}>
                            <div className={isSaving?styles.saving:null}>{saveText}</div>
                        </Button>
                    </div>                    
                </Col>                 
            </Row>
            <Row className="d-flex d-md-none">
                <Col className="mt-5" sm={12}>
                    <span className={`p-0 ${styles.errorText}`}>
                        {showSaveError.show?showSaveError.message:null}
                    </span>                                       
                </Col>
                <Col  className="d-flex justify-content-end mt-2" sm={12}>
                    <Button className={`${styles.cancelButton} ${styles.btn}`} onClick={props.onCancel} disabled={isSaving}>
                        Cancel
                    </Button>
                    <Button className={`${profileStyles.saveButton} ${styles.btn} ${isSaving?styles.saveDisabled:null}`} onClick={onSave} disabled={isSaving}>
                        <div className={isSaving?styles.saving:null}>{saveText}</div>
                    </Button>
                </Col>              
            </Row>
        </Fragment>:null}
    </Fragment>);
}

export default EditNominee;