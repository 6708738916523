import React, {useEffect, useState} from 'react'
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
import { getPartnerCountries } from '../../../api'
import styles from './Nominee.module.css'
import CustomInput from 'react-phone-number-input/input'
import {parsePhoneNumber} from 'react-phone-number-input'
import en from 'react-phone-number-input/locale/en.json'
import { Form } from 'react-bootstrap'

const PhoneInput = ({value: phoneNumber, onChange, onBlur, id})=>{
    const [countryList, setCountryList] = useState([]);
    const [countryCode, setCountryCode] = useState('');
    useEffect(()=>{
        setCountryList(getCountries())
        setCustomerCountry()
    }, [])
   const setCustomerCountry = async ()=>{
     if(!phoneNumber){
      let code = 'IN'
      if(sessionStorage.getItem('userInfo')){
        const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
        if(userInfo && userInfo.country){
          try{
            const res = await getPartnerCountries();
            if(res && res.data && res.data.activeCountry){
              const list = res.data.activeCountry
                for(let i =0; i<list.length; i++){
                  if(userInfo.country === list[i].country){
                    code=list[i].country
                    break
                  }
              }
            }
          }catch(error){
            console.log(error)
          }
        }
      }
      setCountryCode(code)
    }
   }
    useEffect(()=>{
      if(phoneNumber&& !countryCode){
        const data = parsePhoneNumber(phoneNumber);
        if(data)
          setCountryCode(data.country)
      }
    }, [phoneNumber])
    return (
    <Form.Group className={`form-focus ${
       phoneNumber ? 'focused' : null
    } ${styles.phoneInput}`}>
        <span>
        {countryCode ?
              <img className={styles.flagImg} alt={countryCode} src={`${process.env.REACT_APP_ASSESTS}/flags/${countryCode}.svg`}/>
        :''}
        </span>
    <select
    id="customSelectBox"
    name="customSelectBox"
    className={styles.dropdownButton}
      value={countryCode}
      onChange={event =>{ setCountryCode(event.target.value);onChange(null)}} >
      {countryList.map((country) => (
        <option className="" key={country} value={country}>
        {en[country]} +{getCountryCallingCode(country)}
        </option>
      ))}
    </select>
      <CustomInput
        id={id} name="phoneWithCode" 
        className={`form-control ${styles.phoneInputText}`}
        autoComplete='off'
        country={countryCode}
        value={phoneNumber}
        onChange={value =>{onChange(value);}}
        onBlur={onBlur}
        onPaste={(e) => {
          e.preventDefault()
          return false;
        }} onCopy={(e) => {
          e.preventDefault()
          return false;
        }}
      />
    <Form.Label className={`focus-label ${styles.phoneLabel}`}>Phone Number</Form.Label>
  </Form.Group>);

}
export default PhoneInput;
