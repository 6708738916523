import React, { useContext, useEffect, useRef, useState } from "react"
import style from "./addFund.module.css"
import InvestmentIcon from "../../public/images/investment.svg"
import SavingIcon from "../../public/images/saving.svg"
import ArrowRightIcon from "../../public/images/arrowRight.svg"
import { AddFundContext } from "./AddFundContext"
import { useSelector } from "react-redux"
import { addCommasinPrice } from "../../utils"
import { fetchSavingAccount } from "../../api"

const Account = ()=>{
    const {setAccountType, setPage, savingDetails, setSavingDetails, isSaving, PAGE} = useContext(AddFundContext)
    const investRef = useRef()
    const state = useSelector(state=>state)
    const [fetchingSaving, setFetchingSaving] = useState(false)
    const [savingsError, setSavingsError] = useState(null)
    const {
        userAccountSummary: {
          userSummary: { accountSummary = {} } = {}
        } = {}
      } = state
    useEffect(()=>{
        isSaving && fetchSavingDetails()
    },[])
    const fetchSavingDetails=async()=>{
        if(!fetchingSaving){
            setFetchingSaving(true)
            try{
                const res = await fetchSavingAccount()
                if(res && res.data && res.data.code===200)
                {
                    setSavingDetails(res.data.data)
                    setSavingsError(false)
                }else{
                    setSavingsError(true)
                }
            }catch(error){
                    setSavingsError(true)
            }
            setFetchingSaving(false)                
        }            
    }
    const onAccount = (account)=>{
        setAccountType(account);
        setPage(PAGE.FUNDSELECT)
    }
    return (<>
        <div className={style.subHeader}>
            <div>Select Account Type</div>
         </div>
        <button ref={investRef} className={style.optionContainer} onClick={()=>onAccount('Investment')}>
            <div className="d-flex align-items-center">
                <img className={style.optionLogo} src={InvestmentIcon} />
                <div className="d-flex flex-column align-items-start">
                    <span className={style.option}>Investment Account</span>
                    <span className={style.balance}>{`Balance: $${accountSummary && accountSummary.cashBalance>=0? addCommasinPrice(accountSummary.cashBalance):'--'}`}</span>
                </div>
            </div>
            <img src={ArrowRightIcon}/>                            
        </button>
        {isSaving && <button className={style.optionContainer} onClick={()=>onAccount('Saving')}>
            <div className="d-flex align-items-center">
                <img className={style.optionLogo} src={SavingIcon} />
                <div className="d-flex flex-column align-items-start">
                    <span className={style.option}>Cash Account</span>
                    {fetchingSaving && <span className={style.balance}>Fetching balance...</span>}
                    {!fetchingSaving && savingsError && <span className={style.errorLinkContainer}>Unable to fetch balance<span onClick={(e)=>{fetchSavingDetails(); e.stopPropagation();}}>Retry</span></span>}
                    {!fetchingSaving && !savingsError && <span className={style.balance}>Balance: {savingDetails && savingDetails.balanceAmt ?`$${savingDetails.balanceAmt}`:'--'}</span>}
                </div>
            </div>
            <img src={ArrowRightIcon}/>                            
        </button>}      
    </>)
}
export default Account