import React, { useState, useEffect } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import InputLabel from '../../InputLabel/InputLabel'
import styles from '../Profile.module.css'
import axios from 'axios'
import { validatePassword, updatePassword } from '../../../api'


const ResetScreen = props => {
	const [oldPassWord, setOldPassword] = useState('')
	const [newPassword, setNewPassWord] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [noMatchErrorMessage, setNoMatchErrorMessage] = useState('')
	const [isPasswordMatched, setPasswordMatch] = useState(false)
	const [eightChar, seteightChar] = useState(false)
	const [uplowCase, setuplowCase] = useState(false)
	const [useNum, setuseNum] = useState(false)
	const [validPasswordError, setValidPasswordError] = useState('')
	const [oldPasswordError, setOldPasswordError] = useState(false)
	const [oldPasswordErrorMessage, setOldPasswordErrorMessage] = useState('')
	const [buttonText, setButtonText] = useState('Change Password')
  const [successOrErrorMessage, setSucessOrErrorMessage] = useState('')
	const[buttonDisabled, setButtonDisabled] = useState(false)
	// console.log('new',isPasswordMatched)
	// console.log('conf',confirmPassword)
	const PasswordVerification = (e, from) => {
		if (from == 'newPassWord') {
			if (e.target.value !== '' && confirmPassword !== '') {
				if (newPassword !== confirmPassword) {
					setNoMatchErrorMessage('Password does not match')
					setPasswordMatch(false)
				} else {
					setNoMatchErrorMessage('')
					setPasswordMatch(true)
          setSucessOrErrorMessage('')
				}
			}
		} else if (from == 'confirmPassword') {
			if (e.target.value !== '' && newPassword !== '') {
				if (newPassword !== confirmPassword) {
					setNoMatchErrorMessage('Password does not match')
					setPasswordMatch(false)
				} else {
					setNoMatchErrorMessage('')
					setPasswordMatch(true)
          setSucessOrErrorMessage('')
				}
			}
		}
	}
	// console.log('num',useNum)
	const passwordValidation = e => {
		setNewPassWord(e.target.value)
		// setuplowCase(false)
		// setuseNum(false)
		var upandLowCase = /(?=.*[a-z])(?=.*[A-Z])/
		var oneDigit = /[\d]{1}/
		var specialCharacter = /(?=.*?[#?!@$%^&*-])/
		console.log(
			'special',
			specialCharacter.test(e.target.value),
			oneDigit.test(e.target.value)
		)
		setuplowCase(upandLowCase.test(e.target.value))
		if (
			oneDigit.test(e.target.value) &&
			specialCharacter.test(e.target.value)
		) {
			setuseNum(true)
		} else if (
			oneDigit.test(e.target.value) === false ||
			specialCharacter.test(e.target.value) === false
		) {
			setuseNum(false)
		}
		if (e.target.value.length >= 8) {
			seteightChar(true)
		} else if (e.target.value.length < 8) {
			seteightChar(false)
		}
	}

	const VerifyPassword = () => {
		const update = {
			body: {
				password: oldPassWord
			}
		}

    // new Api
    validatePassword(update).then(response=>{
      if (response.data.code === 200) {
        if (eightChar && useNum && uplowCase && isPasswordMatched) {
          setOldPasswordError(false)
          setOldPasswordErrorMessage('')
          UpdatePasswordApiCall()
        } else if (!isPasswordMatched) {
          setOldPasswordError(false)
          setOldPasswordErrorMessage('')
          setNoMatchErrorMessage('Password does not match')
        } else if (!eightChar || !useNum || !uplowCase) {
          setOldPasswordError(false)
          setOldPasswordErrorMessage('')
          setValidPasswordError('Enter a Valid Password')
        }
      } else {
        setOldPasswordError(true)
        setOldPasswordErrorMessage('Invalid old password')
      }
    })
    .catch(function (error) {
      setOldPasswordError(true)
      setOldPasswordErrorMessage('Wrong Password')
      // setError('something went wrong! Resend the Otp')
    })
	}

	const UpdatePasswordApiCall = () => {
		const update = {
			body: {
				password: newPassword,
				hashID: props.hashId,
				otp: props.otp
			}
		}

    updatePassword(update.body.password,update.body.hashID,update.body.otp).then(response=>{
      if (response.code === 200) {
        setValidPasswordError('')
        setNoMatchErrorMessage('')
        setButtonText('Password Updated')
        setSucessOrErrorMessage('Password Updated SuccessFully!')
        setTimeout( () => {
          props.resetTabs('basic detail')
          props.onVerifyOtp(false)},
          2000
        )
      } else {
        if(response.message.length > 0 && response.message !== undefined){
          if(response.code === 440){
            setButtonDisabled(true)
            // setTimeout( () => {
            //   props.resetTabs('basic detail')
            //   props.onVerifyOtp(false)},
            //   2000
            // )
          }
          setSucessOrErrorMessage(response.message)
        } else {
        console.log('not Updated')
        setSucessOrErrorMessage('Something Went Wrong!')
        }
      }
    })
    .catch(function (error) {
      console.log('error', error)
      console.log('something went wrong')
      setSucessOrErrorMessage('Something Went Wrong!')
    })
	}

	const ApiCall = () => {
		if (oldPassWord !== '') {
			VerifyPassword()
		} else if (oldPassWord === '') {
			setOldPasswordError(true)
			setOldPasswordErrorMessage('Cannot be empty')
		}
		// else if (newPassword === ""){
		// 	setValidPasswordError('Cannot be empty')
		// } else if (confirmPassword === ""){
		// 	setNoMatchErrorMessage('Cannot be empty')
		// }
	}

  const OldPasswordSet = (e) => {
    setOldPassword(e.target.value)
    setOldPasswordErrorMessage('')
    setOldPasswordError(false)
  }

  const goBack = () => {
    props.onVerifyOtp(false)
    props.sendOtpApi('change_password')
  }

	return (
		<>
			<Row className={styles.resetPasswordHeading}>Reset Password</Row>
			<Row>
				<Col md={9}>
					<Row className='m-0 mt-4'>
						<Col md={5} className='pl-0'>
							<div className={`mb-1 ${styles.profileInputLabel}`}>
								Enter old password
							</div>
							<input
								type='password'
								name='oldPassWord'
								placeholder='Enter old password'
								value={oldPassWord}
								className={
									oldPasswordError
										? styles.profileErrorInputBox
										: styles.profileInputBox
								}
                onChange={event => OldPasswordSet(event)}
                onPaste={(e)=>{
                  e.preventDefault()
                  return false;
                }} onCopy={(e)=>{
                  e.preventDefault()
                  return false;}}
							/>
							<p className={styles.profileErrorText}>
								{oldPasswordErrorMessage}
							</p>
						</Col>
					</Row>
					<Row className='m-0 mt-4'>
						<Col md={5} sm={12} className='pl-0'>
							<div className={`mb-1 ${styles.profileInputLabel}`}>
								Enter new password
							</div>
							<input
								type='password'
								name='newPassWord'
								placeholder='Enter new password'
								value={newPassword}
								className={
									validPasswordError
										? styles.profileErrorInputBox
										: styles.profileInputBox
								}
								// onChange={(event)=> setNewPassWord(event.target.value)}
								onChange={e => passwordValidation(e)}
								// onKeyPress={(e) => passwordValidation(e)}
								// onKeyDown={(e) => passwordValidation(e)}
								// onChange= {(e) => passwordValidation(e)}
                onBlur={e => PasswordVerification(e, 'newPassWord')}
                onPaste={(e)=>{
                  e.preventDefault()
                  return false;
                }} onCopy={(e)=>{
                  e.preventDefault()
                  return false;}}
							/>
							<p className={styles.profileErrorText}>{validPasswordError}</p>
						</Col>
						<Col md={7} sm={12} className={styles.passwordCheckMargin}>
							{eightChar ? (
								<p className={styles.passCheckPad}>
									<span className='mr-2'>
										<img
											src={require('../../../public/images/GreenTickMark.svg')}
										/>
									</span>
									<span className={styles.profilePasswordCheckGreen}>
										use 8 or more characters
									</span>
								</p>
							) : (
								<p className={styles.profilePasswordCheck}>
									<span className='mr-2'>
										<img
											src={require('../../../public/images/BlackTickMark.svg')}
										/>
									</span>
									<span>use 8 or more characters</span>
								</p>
							)}
							{uplowCase ? (
								<p className={styles.passCheckPad}>
									<span className='mr-2'>
										<img
											src={require('../../../public/images/GreenTickMark.svg')}
										/>
									</span>
									<span className={styles.profilePasswordCheckGreen}>
										use upper and lowercase alphabets (e.g Aa)
									</span>
								</p>
							) : (
								<p className={styles.profilePasswordCheck}>
									<span className='mr-2'>
										<img
											src={require('../../../public/images/BlackTickMark.svg')}
										/>
									</span>
									<span>use upper and lowercase alphabets (e.g Aa)</span>
								</p>
							)}

							{useNum ? (
								<p className={styles.passCheckPad}>
									<span className='mr-2'>
										<img
											src={require('../../../public/images/GreenTickMark.svg')}
										/>
									</span>
									<span className={styles.profilePasswordCheckGreen}>
										Use Atleast One Number and One Special Character
									</span>
								</p>
							) : (
								<p className={styles.profilePasswordCheck}>
									<span className='mr-2'>
										<img
											src={require('../../../public/images/BlackTickMark.svg')}
										/>
									</span>
									<span>Use Atleast One Number and One Special Character</span>
								</p>
							)}
						</Col>
					</Row>
					<Row className='m-0 mt-2'>
						<Col md={5} className='pl-0'>
							<div className={`mb-1 ${styles.profileInputLabel}`}>
								Re-enter new password
							</div>
							<input
								type='password'
								placeholder='Re-enter new password'
								value={confirmPassword}
								name='confirmPassword'
								className={
									noMatchErrorMessage
										? styles.profileErrorInputBox
										: styles.profileInputBox
								}
								onChange={event => setConfirmPassword(event.target.value)}
                onBlur={e => PasswordVerification(e, 'confirmPassword')}
                onPaste={(e)=>{
                  e.preventDefault()
                  return false;
                }} onCopy={(e)=>{
                  e.preventDefault()
                  return false;}}
							/>
							<p className={styles.profileErrorText}>{noMatchErrorMessage}</p>
						</Col>
					</Row>
					<Row className={styles.buttonMargin}>
						<Button
							style={{ backgroundColor: props.buttonColor }}
							className={`${styles.resetSaveButton} themeButton`}
							// style={{ height: '48px', width: '181px'}}
							onClick={ApiCall}
							disabled={buttonDisabled}
						>
							{buttonText}
						</Button>
            {buttonDisabled ?
            <div className={styles.goBackButton} onClick={goBack}>
                  Go Back
            </div>
            : ""}
					</Row>
          <Row>
            <Col>
            <div className={successOrErrorMessage === 'Password Updated SuccessFully!' ? styles.updateBox :  styles.redupdateBox}>
              {successOrErrorMessage ? successOrErrorMessage : ''}
            </div>
            </Col>
          </Row>
				</Col>
				<Col></Col>
			</Row>
		</>
	)
}

export default ResetScreen
