import React, {  createContext, useState } from "react"

export const AddFundContext = createContext()
let onErrorRetry=null
const PAGE={
    ACCOUNT:'Account',
    FUNDSELECT:'FundSelect',
    BANK:'Bank',
    DIRECT: 'Direct',
    ONLINE: 'Online',
    OFFLINE: 'Offline',
    ADD: 'Add',
    TRANSFER: 'Transfer',
    EASYFUND:'EasyFund'
}
const SUBPAGE={
    [PAGE.BANK]:{LIST:'List', MANAGE:'Manage'},
    [PAGE.DIRECT]:{OTP:'Otp', UPLOAD:'Upload', ERROR:'Error'},
    [PAGE.ONLINE]:{INSTRUCTION:'Instruction', TRANSACTION:'Transaction'},
    [PAGE.OFFLINE]:{DETAILS:'Details', LRSDETAILS:'LRSDetails', REVIEW:'Review', INSTRUCTION:'Instruction', TRANSACTION:'Transaction'},
    [PAGE.ADD]:{FORM:'Form', VERIFY:'Verify'},
    [PAGE.EASYFUND]:{ACTIVATE:'Activate',VERIFYAADHAR:'VerifyAadhar',VERIFYAADHARAUTO:'VerifyAadharAuto', REVIEW:'Review',UPLOADSTATEMENT:'UploadStatement',AUTOUPLOADSTATEMENT:'AutoUploadStatement'},
    SUCCESS:'Success'
}
const AddFundContextProvider = ({children})=>{
    const [page, setPage] = useState(PAGE.ACCOUNT)
    const [subPage, setSubPage] = useState(null)
    const [accountType, setAccountType] = useState(null)
    const [bankList, setBankList] = useState([])
    const [selectedAccount, setSelectedAccount] = useState(null)
    const [hashID, setHashID] = useState(null)
    const [fund, setFund] = useState(null)
    const [lrsID, setLrsID] = useState(null)
    const [trace, setTrace] = useState({page: [], subPage:[]})
    const [transferOptions, setTransferOptions] = useState(null)
    const [noHeader, setNoHeader] = useState(false)
    const [noClose, setNoClose] = useState(false)
    const [showGenericError, setShowGenericError] = useState(false)
    const [showGenericErrorOption, setShowGenericErrorOption] = useState(false)
    const [showCancel, setShowCancel] = useState(false)
    const [cancelDetails, setCancelDetails] = useState(null)
    const [isSaving, setIsSaving]=useState(false)
    const [savingDetails, setSavingDetails]=useState(null)
    const [transactionId, setTransactionId]=useState('')
    const [easyfundTransDetail,seteasyfundTransDetail] = useState(null)
    const [manageFund, setManageFund]=useState(null)
    const [uploadStatement,setUploadStatement] = useState(SUBPAGE[PAGE.EASYFUND].AUTOUPLOADSTATEMENT)
    const [showTryAgain,setShowTryAgain] = useState(true)
    const [errorAmount, setErrorAmount] = useState('')
    const [fundTitle,setFundTitle] = useState('Add Bank Account')
    const reset =()=>{
        setPage(PAGE.FUNDSELECT)
        setSubPage(null)
        setAccountType(null)
        setSelectedAccount(null)
        setHashID(null)
        setFund(null)
        setLrsID(null)
        setTrace({page: [], subPage:[]})
        setNoHeader(false)
        setNoClose(false)
        setShowGenericError(false)
        setShowGenericErrorOption(false)
        setShowCancel(false)
        setCancelDetails(null)
        setSavingDetails(null)
        setTransactionId('')
        seteasyfundTransDetail(null)
        setManageFund(null)
        setUploadStatement(SUBPAGE[PAGE.EASYFUND].AUTOUPLOADSTATEMENT)
        setShowTryAgain(true)
        setErrorAmount('')
        setFundTitle('Add Bank Account')
    }
    const setOnErrorRetry = (func)=>{
        onErrorRetry=func
    }
    const popTrace = ()=>{
        if(noHeader){
            setNoHeader(false)
            setNoClose(false)
        }
        if(trace.subPage.length >0)
        {
            const temp = [...trace.subPage]
            setSubPage(temp.pop())
            setTrace({page:[...trace.page], subPage:[...temp]})
        }else{
            const temp = [...trace.page]
            let prevPage= temp.pop()
            if(prevPage===page){
                setPage(temp.pop())
            }else{
                setPage(prevPage)
            }
            setTrace({page:[...temp], subPage:[...trace.subPage]})
        }
        resetScroll()
    }
    const onBack = ()=>{
        if(page===PAGE.DIRECT && (subPage===SUBPAGE[PAGE.DIRECT].OTP || subPage===SUBPAGE[PAGE.DIRECT].UPLOAD)){
            setCancelDetails({msg:'Are you sure you want to cancel the transaction?', onConfirm:popTrace})
            setShowCancel(true)
        }else if((page===PAGE.ONLINE&&subPage===SUBPAGE[PAGE.ONLINE].TRANSACTION) || (page===PAGE.OFFLINE&&(subPage===SUBPAGE[PAGE.OFFLINE].INSTRUCTION||subPage===SUBPAGE[PAGE.OFFLINE].TRANSACTION))){
            setCancelDetails({msg:'Are you sure you want to go back?', onConfirm:popTrace})
            setShowCancel(true)
        }else if(page === PAGE.EASYFUND && subPage ===SUBPAGE[PAGE.EASYFUND].REVIEW){
          gotoList()
        }else if(page === PAGE.FUNDSELECT){
            setPage(PAGE.ACCOUNT)
        }
        else{
            popTrace()
        }
    }
    const captureSubPageTrace = (next)=>{
        if(noHeader){
            setNoHeader(false)
            setNoClose(false)
        }
        if(next === SUBPAGE.SUCCESS){
            setNoClose(true)
            setNoHeader(true)
        }
        if(page!==trace.page[trace.page.length-1]){
            setTrace({page: [...trace.page, page], subPage:[]})
        }else if(subPage){
            setTrace({page: [...trace.page], subPage:[...trace.subPage, subPage]})
        }
        setSubPage(next)
        resetScroll()
    }
    const capturePageTrace = (next)=>{
        if(noHeader){
            setNoHeader(false)
            setNoClose(false)
        }
        if(page!==trace.page[trace.page.length-1]){
            setTrace({page: [...trace.page, page], subPage:[...trace.subPage]})
        }
        setSubPage(null)
        setPage(next)
        resetScroll()
    }
    const resetScroll = ()=>{
        document.getElementById('modal-body').scrollTop=0
    }
    const gotoList = ()=>{
        setSubPage(null)
        setPage(PAGE.BANK)
        setTrace({page: [PAGE.ACCOUNT, PAGE.BANK], subPage:[]})
    }
    return(<AddFundContext.Provider
        value={{page,
            setPage: capturePageTrace,
            subPage,
            setSubPage: captureSubPageTrace,
            accountType,
            setAccountType,
            bankList,
            setBankList,
            selectedAccount,
            setSelectedAccount,
            reset,
            hashID,
            setHashID,
            fund,
            setFund,
            lrsID,
            setLrsID,
            onBack,
            transferOptions,
            setTransferOptions,
            noHeader,
            setNoHeader,
            onErrorRetry,
            setOnErrorRetry,
            showGenericError,
            showGenericErrorOption,
            setShowGenericError: (val)=>{setShowGenericError(val); val&&setNoHeader(true)},
            setShowGenericErrorOption :(val)=>{setShowGenericErrorOption(val); val&&setNoHeader(true)},
            showCancel,
            setShowCancel,
            cancelDetails,
            setCancelDetails,
            savingDetails,
            setSavingDetails,
            gotoList,
            isSaving,
            setIsSaving,
            noClose,
            setNoClose,
            PAGE,
            SUBPAGE,
            transactionId,
            setTransactionId,
            easyfundTransDetail,
            seteasyfundTransDetail,
            manageFund,
            setManageFund,
            uploadStatement,
            setUploadStatement,
            showTryAgain,
            setShowTryAgain,
            setErrorAmount,
            errorAmount,
            fundTitle,
            setFundTitle
            }}>
        {children}
    </AddFundContext.Provider>)
}

export default AddFundContextProvider
