import React, {useContext,useState,useEffect} from 'react'
import {  Spinner} from 'react-bootstrap'
import styles from '../ReviewDetail/reviewDetail.module.css'
import stylesheet from './Fairexpay.module.css'
import ProgressBar from './ProgessBar'
import stylesSpinner from './Fairexpay.module.css'
import {Row,Col} from 'react-bootstrap'
import { ThemeContext } from '../../Context/ThemeContext'

const A2wait = ({title}) => {
  const [hotlineText,setHotlineText] = useState('')
  const { contactNumber } = useContext(ThemeContext)

  useEffect(() => {
    const hotlineSupport = (sessionStorage.getItem('planInfo') &&
    JSON.parse(sessionStorage.getItem('planInfo')).details &&
     JSON.parse(sessionStorage.getItem('planInfo')).details.hotlineSupport) ?
     JSON.parse(sessionStorage.getItem('planInfo')).details.hotlineSupport : [];
      let phone = "";
    if (hotlineSupport.length > 0 && hotlineSupport[0].phone) {
      phone = hotlineSupport[0].phone;
    }else{
      phone = contactNumber;
    }
    setHotlineText(phone);
  },[]);

  return(
  	<div className={styles.card}>
      	<Row className='d-block d-md-none'>
				<Col md={12}>
					<div
						className={styles.title}
						style={{ cursor: 'pointer' }}
            >
						<span> Digitally Sign A2 form</span>
					</div>
				</Col>
			</Row>
       <Row className={stylesheet.borderBottom}>
				<Col md={10} className='pr-0'>
					<ProgressBar steps='5' current={2} color='rgb(0, 93, 214)' status='Transfer funds'/>
				</Col>
			</Row>
			<Row>
				<Col md={12}>
          <div className={stylesSpinner.spinner}>
          <Spinner animation="border" className={stylesSpinner.spinnerColor}/>
          </div>
					<div className={stylesSpinner.loadingCenter}>
						{title}
					</div>
          <div className={stylesSpinner.note}>If you are not able to proceed, please call customer helpline.{hotlineText}</div>
				</Col>
			</Row>
    </div>
  )
}


export default A2wait
