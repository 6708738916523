import React, { useEffect, useRef, useState } from 'react'
// import { format } from 'date-fns'
import uploadIcon from '../../../../public/images/upload.svg'
import deleteIcon from '../../../../public/images/delete.svg'
import fileIcon from '../../../../public/images/file.svg'

import styles from './FileUpload.module.css'

const FileUpload = ({ onFileSelectSuccess, onFileSelectError, file }) => {
	const [fileUploaded, setFileUploaded] = useState(false)
	const [fileName, setFileName] = useState('')
	// const [uploadDate, setUploadDate] = useState('')
	const fileRef = useRef(null)
	useEffect(()=>{
		if(file){
			setFileName(file.name)
			setFileUploaded(true)
		}else{
			setFileName('')
			setFileUploaded(false)
		}
	},[file])
	const handleClick = () => {
		fileRef.current.click()
	}

	const handleFileChange = e => {
		const target = e.target
		const fileObj = target.files[0]
		const fileSizeMB = fileObj.size / 1024 / 1024
		// const date = new Date()
		// const currDate = format(date, 'dd MMM yy, HH:mm')

		if (fileSizeMB > 3.5) {
			onFileSelectError &&
				onFileSelectError('Maximum size allowed - 3.5 MB')
		}else {
			const name = fileObj.name.split('.')
			if(!['pdf', 'jpeg', 'png'].includes(name[name.length - 1])){
				onFileSelectError('Please upload PDF/JPEG files')
				return
			}
			onFileSelectError && onFileSelectError(null)
			onFileSelectSuccess && onFileSelectSuccess(fileObj)
		}
	}

	const handleDelete = () => {
		onFileSelectSuccess && onFileSelectSuccess(null)
	}

	return (
		<div>
			{!fileUploaded ? (
				<>
					<input
						ref={fileRef}
						type='file'
						style={{ display: 'none' }}
						accept='.pdf,.jpeg,.png'
						onChange={handleFileChange}
					/>
					<div className={styles.btnContainer} onClick={handleClick}>
						<div className={styles.btnWrapper}>
							<img src={uploadIcon} alt='upload-icon' />
							<span>Upload Statement</span>
						</div>
					</div>
				</>
			) : (
				<>
					<div className={styles.fileContainer}>
						<div className={styles.fileWrapper}>
							<div className={styles.fileLabelWrapper}>
								<img src={fileIcon} alt='file-icon' />
								<div className={styles.fileLabel}>
									<div className={styles.fileTitle}>{fileName}</div>
									{/* <div className={styles.fileDate}>{uploadDate}</div> */}
								</div>
							</div>
							<div className={styles.deleteIcon} onClick={handleDelete}>
								<img src={deleteIcon} alt='delete-icon' />
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	)
}

export default FileUpload
