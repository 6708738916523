import React, { useEffect } from "react"
import { useContext } from "react"
import { AddFundContext } from "../AddFundContext"
import styles from './addBank.module.css'
import pennyDropLottie from "../../../public/lottie/pennyDrop.json"
import Lottie from 'react-lottie-player';
import RefreshIcon from "../../../public/images/refresh.svg"

const Verify = ({error, retry, resetAddBank}) =>{
    const {setNoHeader, noHeader, onBack} = useContext(AddFundContext)
    useEffect(()=>{
        if(!noHeader)
            setNoHeader(true)
    },[noHeader])
    return(<div className={styles.verifyContainer}>
         <Lottie
            rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
            loop={true}
            animationData={pennyDropLottie}
            play
            style={{ width: 132, height: 132 }}
        />
        {!error?<>
            <div className={styles.header}>Verifying bank account</div>
            <div className={styles.description}>
                <span>Sending across ₹1 to verify your account.</span>
                <span>Hold on, it’ll just take a minute.</span>
            </div>
        </>
        :<>
            <div className={`${styles.header} ${styles.headerError}`}>Verification failed</div>
            <div className={styles.description}>
                <span>{error.msg}</span>
                {!error.limit?<div className={styles.noLimitCTA}>
                        {error.tryAgain && <div className={styles.tryAgain} onClick={retry}>
                            <img src={RefreshIcon}/>
                            Try Again
                        </div>}
                        <div className={styles.textLink} onClick={onBack}>Go Back</div>
                    </div>:
                    <div className={styles.limitCTA}>
                        <button className={styles.continueBtn} onClick={()=>{resetAddBank();onBack();}}>Add Another Bank Account</button>
                        <div className={styles.linkContainer}>
                            <span className={styles.textLink} onClick={onBack}>Go Back</span>
                            <span className={styles.textLink} onClick={()=>window.fcWidget.open()}>Contact Support</span>
                        </div>
                    </div>
                }
            </div>
        </>}
    </div>)
}

export default Verify