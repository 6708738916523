import React, { useState, useContext } from 'react'
import { Row, Col } from 'react-bootstrap'
import styles from './orderSuccess.module.css'
import {Link} from 'react-router-dom'
import { ThemeContext } from '../../Context/ThemeContext'

function Success({ stockData }) {
  // const [data, setData] = useState(null)
  const { buttonColor } = useContext(ThemeContext)
  const [isLoaded, setIsloaded] = useState(true)
  // console.log('stockData', stockData)
  // const {
  // 	logo = '',
  // 	code = '',
  // } = data && Object.keys(data).length > 0
  // useEffect(() => {
  //   setData(stockData)
  // }, [stockData])
  return (
    <div className='h-100'>
      <Row>
        <Col>
          <div className={styles.card}>
            <Row>
              <Col md={12} className="mx-auto pt-5">
                <div className={styles.circle}>
                  <div className={styles.tick}>
                    <img alt="" src={require('../../public/images/tick.svg')} />
                  </div>
                  {
                      stockData ? (stockData.logo && isLoaded ? <img alt="logo" src={stockData.logo} width="95%" className="rounded-circle"
                      onError={() => {
                      setIsloaded(false)
                      }}/>
                    : <div className={styles.logoSymbol}>{stockData.code.slice(0,1)}</div>): null
                  }
                </div>

              </Col>
            </Row>
            <Row>
              <Col md={12} className="mx-auto pt-4">
                <p className={styles.success}>Order placed!</p>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mx-auto">
                {/* <p className={styles.pieceText}>You own a piece of {data.code} now</p> */}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <div className={`d-flex d-sm-none flex-column justify-content-end align-items-center ${styles.footer}`}>
        <Link to='/Orders'>Check Order Status</Link>  
        <Link to='/portfolio'><button className={`${styles.button} themeButton`} style={{backgroundColor:buttonColor}}>Go to holdings</button></Link>      
      </div>
     
    </div>
  )
}

export default Success
