import React, { useState, useEffect, useRef, useContext } from 'react'
import { Row, Form, Col } from 'react-bootstrap'
import { Formik } from 'formik'
import UpdatePassword from './Updatepassword'
import { validateOtp, resendOtp, onBoardingValidateOtp } from '../../api'
import * as yup from 'yup'
import styles from './ForgotPassword.module.css'
import SignUpButton from '../SignUp/SignUpButton'
import { Link } from 'react-router-dom'
import { ThemeContext } from '../../Context/ThemeContext'
import PinInput from 'react-pin-input'

const VerifyEmail = ({ hashID, setErrormessage, setShowVerify, setHashID }) => {
	const { shortLogo } = useContext(ThemeContext)
	const [verifyOtp, setVerifyOtp] = useState(false)
	const [otp, setOtp] = useState('')
	const [resendOtpTimer, setresendOtpTimer] = useState(180)
	const [otpSendMessage, setOtpSendMessage] = useState('')
	const [otpError, setOtpError] = useState('')
	const [loading, setLoading] = useState(false)
	const myRef = useRef(null)

	const handleChange = value => {
		setOtp(value)
	}

  useEffect(() => {
   if(otp.length === 6){
    handleSubmit()
   }
  },[otp])

	useEffect(() => {
		startTimer()
	})

	// disable copy and paste in otp-input
	useEffect(() => {
		const pinInputs = document.getElementsByClassName('pincode-input-text')

		if (pinInputs && pinInputs?.length > 0) {
			for (let i = 0; i < pinInputs.length; i++) {
				pinInputs[i].addEventListener('keydown', e => {
					if (
						e.key.toLowerCase() === 'control' ||
						e.key.toLowerCase() === 'meta' ||
						e.key.toLowerCase() === 'v' ||
						e.key.toLowerCase() === 'c'
					) {
						e.preventDefault()
					}
				})
			}
		}
	}, [])

	const startTimer = () => {
		const timer = setTimeout(() => {
			setresendOtpTimer(resendOtpTimer - 1)
		}, 1000)
		if (resendOtpTimer === 178) {
			setOtpSendMessage('')
		}
		if (resendOtpTimer === 0) {
			clearInterval(timer)
		}
	}

	const onResend = () => {
		setOtp('')
		setOtpError('')
		myRef.current.clear()
		setLoading(true)
		resendOtp(hashID).then(res => {
			if (res.code === 200) {
				console.log(res)
				setHashID(res.data.hashID)
				setresendOtpTimer(180)
				setOtpSendMessage('OTP has been resend')
				startTimer()
			} else if (res.code === 400) {
				setOtpError('Resend OTP Fail')
			}
			setLoading(false)
		})
	}

	useEffect(() => {
		myRef.current.focus()
	}, [])

	const inputStyle = {
		spinInput: {
			border: otpError === '' ? '1px solid #D3D4DA' : '1px solid #FA274A',
			textAlign: 'center',
			width: '42px',
			height: '44px',
			background: '#FFFFFF',
			borderRadius: '6px',
			marginLeft: '16px',
			fontFamily: 'geomanistregular',
			fontStyle: 'normal',
			fontWeight: '400',
			fontSize: '14px',
			lineHeight: '20px',
			color: '#3C404A'
		}
	}

	const handleSubmit = async () => {
		setErrormessage('',setLoading(true))
		if (!otp || otp.length !== 6) {
			setOtpError('Incorrect OTP',setLoading(false))
			return
		}

		if (otp && otp.length === 6) {
			try {
				const response = await onBoardingValidateOtp(hashID, otp)
				console.log('response', response)
				if (response && response.data.code === 200) {
					setOtp(otp)
					setVerifyOtp(true)
				} else {
					// if (response.code === 429) {
					// 	setOtpError(
					// 		response.message,
					// 		setTimeout(() => {
					// 			setShowVerify(false, setOtpError(''))
					// 		}, 800)
					// 	)
					// } else {
					setOtpError(response.data.message,  setLoading(false))
					//}
				}
			} catch (error) {
				setOtpError(error.message,setLoading(false))
				//setOtpError('Unable to proceed ahead. Please try again after sometime')
				//console.log('error', error.code)
			}
		}
	}

	return (
		<>
			<Row className='justify-content-center'></Row>

			{verifyOtp ? (
				<UpdatePassword
					hashID={hashID}
					otp={otp}
					//setErrormessage={setErrormessage}
					setShowVerify={setShowVerify}
					setVerifyOtp={setVerifyOtp}
				/>
			) : (
				<>
					<form
						onSubmit={handleSubmit}
						style={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
						<Row className={styles.title}>Verify your Email</Row>
						<Row className={styles.subTitle} style={{ marginTop: '4px' }}>
							Enter the 6-digit OTP sent to your email address
						</Row>

						{/* <Row className={styles.inputRow}>
										<Col md={7} xs={8}>
											<Form.Group
												className={`form-focus ${
													touched.otp && !errors.otp ? 'focused' : null
												}`}>
												<Form.Control
													className={styles.verifyInput}
													name='otp'
													ref={editFieldRef}
													tabIndex='1'
													value={values.otp}
													maxLength='6'
													onChange={e => {
														// setOtpSendMessage('')
														const regex = new RegExp('^[0-9]*$')
														if (regex.test(e.target.value)) handleChange(e)
													}}
													onKeyPress={e => keyPressHandler(e, handleSubmit)}
													onFocus={e => setotpSendMessage('')}
													// onChange={handleChange}
													onBlur={handleBlur}
													onPaste={e => {
														e.preventDefault()
														return false
													}}
													onCopy={e => {
														e.preventDefault()
														return false
													}}
													isInvalid={touched.otp && errors.otp}
													isValid={touched.otp && !errors.otp}></Form.Control>
												<Form.Label className='focus-label'>
													Enter OTP
												</Form.Label>
												<Form.Control.Feedback type='invalid'>
													{errors.otp}
												</Form.Control.Feedback>
											</Form.Group>
										</Col>
										<Col md={2} xs={4}>
											<SignUpButton
												title='Verify OTP'
												classes={styles.buttonPassword}
												disabled={values.otp.length === 6 ? false : true}
												onClick={handleSubmit}
											/>
										</Col>
									</Row>  */}

						<Row className={styles.otpInput}>
							<PinInput
								length={6}
								initialValue=''
								value={otp}
								onChange={(value, index) => {
									setOtpError('')
									const regex = new RegExp('^[0-9]*$')
									if (regex.test(value)) handleChange(value)
								}}
								type='numeric'
								inputMode='number'
								onComplete={(value, index) => {
									// const regex = new RegExp('^[0-9]*$')
									// if (regex.test(value)) handleChange(value)
								}}
								inputStyle={{ ...inputStyle.spinInput }}
								inputFocusStyle={{ border: '1px solid #3C404A' }}
								autoSelect={true}
								regexCriteria={/[0-9]/}
								ref={myRef}
							/>
						</Row>
						{!!otpError && (
							<div
								className={styles.otpError}
								style={{
									color: '#FA274A'
									//marginLeft: resendOtpTimer > 0 ? '5px' : '0px'
								}}>
								{otpError}
							</div>
						)}
						{/* {otpSendMessage ? (
							<Row
								className={styles.otpSuccess}
								style={{
									justifyContent: 'center',
									color: otpSendMessage === 'OTP sent' ? '#0DC014' : ''
								}}>
								<Col
									md={12}
									xs={12}
									style={{ textAlign: 'center', marginTop: '20px' }}>
									{otpSendMessage}
								</Col>
							</Row>
						) : (
							''
						)} */}

						<div style={{ display: 'flex', marginTop: '16px' }}>
							{resendOtpTimer > 0 ? (
								otpSendMessage ? (
									<>
										<span
											className={styles.goBackText}
											style={{ color: '#767c8f' }}>
											{otpSendMessage}
										</span>
									</>
								) : (
									<>
										<span
											className={
												styles.timer
											}>{`Expires in ${resendOtpTimer}s`}</span>

										<span
											className={
												resendOtpTimer > 0 ? styles.subTitle : styles.goBackText
											}
											style={
												{
													// color: 'rgba(22, 37, 66, 0.25)',
													// borderBottom: 'rgba(22, 37, 66, 0.25)'
												}
											}>
											{!resendOtpTimer ? 'Resend OTP' : ''}
										</span>
									</>
								)
							) : (
								<span
									className={styles.goBackText}
									onClick={() => {
										!loading && onResend()
									}}
									style={{ cursor: 'pointer' }}>
									Resend OTP
								</span>
							)}
						</div>
						<Row className={styles.verifyOtpButton}>
							<SignUpButton
								classes={styles.verifyOtp}
								title='Verify OTP'
                loading={loading}
								onClick={handleSubmit}
							/>
						</Row>
					</form>
				</>
			)}
		</>
	)
}

export default VerifyEmail
