import React, { useState, useEffect, useContext } from 'react'
import { Row, Card, Col, FormControl, Button, Accordion, useAccordionToggle, AccordionContext} from 'react-bootstrap'
import OtpInput from 'react-otp-input'
import PinInput from "react-pin-input"
import {
	getUserPortfolioDetail,
	getLivePrice,
	verifySpin,
	tradeStack,
  getPortfolioDetailAllocations
} from '../../api'
import Purchased from '../StackBuy/Purchased'
import styles from './StackSell.module.css'
import stackBuyStyles from '../StackBuy/StackBuy.module.css'
import StocksWeightsTable from '../Stocks&Weights/StocksWeightsTable'
import AngleDown from '../../public/images/AngleDown.png'
import DropDownIcon from '../../public/images/DropDownIcon.png'
import crossImage from '../../public/images/cross.png'
import { SELL, MARKET_ORDER, STACK_CAT, SPIN_INVALID, SPIN_LENGTH, ETF_CAT, PORTFOLIO_LIVE_PRICE_INTERVAL } from '../../constants'
import { handleErrorResponse } from '../../errorResponse'
import { map, filter, keyBy } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { getMarketDetails } from '../../store/actions'
const { decimalAdjust, isMarketOpen, calPriceChange } = require('../../utils');

const headerStock = ['Stocks', 'price', 'Quantity', 'invested Amt']
const headerEtf = ['ETF', 'price', 'Quantity', 'invested Amt']

const inputStyle = {
  spinInput: {
    border: 'none',
    textAlign: 'center',
    width: '40px',
    height: '48px',
    background: '#EFEFF1',
    borderRadius: '8px',
    marginRight: '16px',
    // },
    // inputStyle:{
    fontFamily: 'Geomanist',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: '0.6px',
    color: '#D3D4DA'
  }
}

const StackSell = ({ stack, currentValue, investedValue,  showSell,closeModal }) => {
	const sellValue = 100
  const dispatchAction = useDispatch()
  const [livePrices, setLivePrices] = useState({});
	const [spin, setSpin] = useState('')
	const [inputError, setInputError] = useState('')
	const [orderLoading, setOrderLoading] = useState(false)
	const [sellAll, setSellAll] = useState(false)
	const [currentValuePending, setcurrentValuePending] = useState(decimalAdjust('floor',currentValue, -2))
	const [sellAmount, setSellAmount] = useState(sellValue)
	const [disableButton, setdisableButton] = useState(true)
	const [stackAllocation, setStackAllocation] = useState([])
  const [loadingEtf, setLoadingEtf] = useState(true)
  const [loadingNonEtf, setLoadingNonEtf] = useState(true)
  const [apiLoading, setApiLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [selled,setSelled] = useState(false)
  const [isStackSell, setIsStackSell] = useState(false);
  const isInputSecure=true;
  const state = useSelector(state => state)

  const {
    marketDetails: { marketDetails: { currentMarketStatus = {} } = {} } = {}
  } = state

  Object.keys(state.marketDetails).length === 0 &&
    dispatchAction(getMarketDetails())

	const {
		ticker = '',
		logo = '',
    name = '',
    tos='',
  } = stack

  useEffect(()=>{
    let data = JSON.parse(sessionStorage.getItem('userInfo'));
    if(data && data.length > 0 && data.userRestriction){
      setIsStackSell(data.userRestriction && data.userRestriction.stack_trade_sell ? data.userRestriction.stack_trade_sell : false);
    }
  })

	useEffect(() => {
		const fetch = async () => {
      try {
        const response = await getUserPortfolioDetail(ticker)
        if(response){
          setcurrentValuePending(response.availableInvestment)
        }
      } catch(error){
        setErrorMessage(handleErrorResponse(error))
      }
		}
		fetch()
		const amount = currentValuePending <= sellAmount ? currentValuePending : sellAmount;
		setSellAmount(decimalAdjust('floor',amount, -2));
  }, [currentValue, ticker, currentValuePending, sellAmount])

  const getLivePriceNonStacks = async(tickers) => {
    try {
      tickers = tickers.join(',')
      let livePrices = await getLivePrice(tickers);
      setLivePrices(keyBy(livePrices, 'symbol'));
    } catch (err) {
      console.log("error fetching live prices");
    }
  }


const stocks = [];
const etf = [];

stackAllocation.map((item, index) => {
    let currPrice = livePrices[item.ticker] && livePrices[item.ticker].lastTrade;
    let {change, changePer} = calPriceChange({currPrice, close: item.close, priorClose: item.priorClose, isMarketOpen: isMarketOpen(currentMarketStatus)})
    const obj = {
      image: item.logo ? item.logo : '',
      name: item.ticker ? item.ticker : '',
      creator: item.company ? item.company : '',
      price: currPrice,
      pricePercentage: decimalAdjust('floor',changePer, -2),
      amount: item.totalInvestment ? item.totalInvestment : '',
      shares: item.qty ? item.qty : '',
      isImgLoaded: true,
    }
    if (item.category !== 'etf') {
      stocks.push(obj)
    } else {
      etf.push(obj)
    }
  })

  useEffect(() => {
    const pfDetailAllocations = async(amount=sellAmount) => {
      if(amount >= 100 && amount <= currentValuePending){
        try {
          const pfAllocations = await getPortfolioDetailAllocations(ticker, amount)
          setErrorMessage('')
          if(pfAllocations && Array.isArray(pfAllocations) && pfAllocations.length > 0) {
            setStackAllocation(pfAllocations)
            const tickers = map(pfAllocations, 'ticker');
            await getLivePriceNonStacks(tickers)
          }
        } catch(error){
          console.log("error ", error);
          setErrorMessage(handleErrorResponse(error))
        }
        setApiLoading(false);
      }
    }
    pfDetailAllocations();
  }, [currentValuePending, sellAmount, ticker])

  useEffect(() => {
    if(!apiLoading){
      if(stocks.length === filter(stackAllocation, ({category}) => category !== 'etf').length){
        setLoadingNonEtf(false);
      }
      if(etf.length === filter(stackAllocation, ({category}) => category === 'etf').length){
        setLoadingEtf(false);
      }
    }
  }, [apiLoading, etf.length, stackAllocation, stocks.length])

  /**
   * Current Value for the stacks | livePrice for nonStacks
   * running every 10 seconds for now => need to change to every 5 seconds.
   */
  useEffect(() => {
    const setLivePrice = async () => {
      if (stackAllocation && stackAllocation.length > 0) {
        await getLivePriceNonStacks(map(stackAllocation, 'ticker'))
      }
    }
    const priceId = setInterval(() => {
      if(isMarketOpen(currentMarketStatus)){
        setLivePrice();
      }
    }, PORTFOLIO_LIVE_PRICE_INTERVAL);

    return () => {
      clearInterval(priceId);
    }
  }, [currentMarketStatus, dispatchAction, showSell, stackAllocation])


	const onSell = async () => {
		setOrderLoading(true)
		setErrorMessage('')
		setErrorMessage('')
		if (spin === '' || (spin && spin.length < SPIN_LENGTH)) {
			setErrorMessage(SPIN_INVALID)
			return
		}
		try {
			// await verifySpin(spin)
			placeOrder();
		} catch (error) {
			setOrderLoading(false)
			setErrorMessage(handleErrorResponse(error))
		}
  }
  /**
   * Placing Order
   * When amount >= currentValue
   */

	const placeOrder = async () => {
		let sellOption = null;
		let amount = sellAmount;
		if (currentValuePending === sellAmount) {
		setSellAll(true);
		sellOption = 'ALL';
		amount = currentValuePending;
		}
		let data = {
			symbol: ticker,
			side: SELL,
			category: STACK_CAT,
			type: MARKET_ORDER,
			amount,
			spin: spin,
			sellOption
		};
		try {
    	await tradeStack(data);
      setSelled(true)
		} catch (error) {
      setErrorMessage(handleErrorResponse(error));
      setdisableButton(true);
		} finally {
			setOrderLoading(false);
		}
	}

	const handleChange = event => {
		setInputError('')
		if (event.target.value < sellValue) {
			setInputError(`Please enter amount greated than ${sellValue}`)
			setdisableButton(true)
		} else if (event.target.value > currentValuePending) {
			setInputError(`Please enter amount less than ${currentValuePending}`)
			setdisableButton(true)
		} else {
			setdisableButton(false)
    }
    setSellAmount(event.target.value)
  }

  const stockAndWeightsRenderComponent = (type) => (
    <StocksWeightsTable
      rowHeaders={type === ETF_CAT ? headerEtf : headerStock}
      data={type === ETF_CAT ? etf : stocks}
      isLoading={type === ETF_CAT ? loadingEtf : loadingNonEtf}
    />
  )

  const setValuesSellAll = (e) => {
    if(e.target.id === 'clear'){
      setSellAll(false)
      setSellAmount(sellValue)
    } else {
      setSellAll(!sellAll)
      setdisableButton(false)
    }
    setErrorMessage('')
  }


  useEffect(() => {
    if(sellAll) {
      setSellAmount(currentValuePending)
    } else {
      setSellAmount(sellAmount)
    }
  }, [currentValuePending, sellAll, sellAmount])

  useEffect(() => {
    if(spin && spin.length === SPIN_LENGTH && ( sellAmount >= sellValue || sellAll) && !isStackSell){
      setdisableButton(false)
    } else {
      setdisableButton(true)
    }
  }, [spin,sellAmount, sellAll])

  const reset = (value) => {
    setErrorMessage('')
    if(value.length === 0){
      setSpin('')
    }
    // setShowError(false)
  }

	return (
		<>
      {selled ? <Purchased onClick={closeModal}/> :
			<Row className={`m-0 ${stackBuyStyles.modalWidth}`}>
				<Col className='p-0  d-none d-md-block ' md={8} lg={9}>
					<Card className={stackBuyStyles.reviewCard} style={{ borderLeft: '0px' }}>
						<Card.Body className={`${stackBuyStyles.reviewCardBody} pl-0`}>
							<Row className={`m-0 ${stackBuyStyles.reviewOrderTitle}`}>
								Stocks & Weights
							</Row>
							<Row className={`${stackBuyStyles.scrollDiv}`}>
								{/* <StocksWeightsTable
									rowHeaders={headerStock}
                  data={stockAllocation}
                  isLoading={loadingNonEtf}
								/> */}
                { stockAndWeightsRenderComponent(!ETF_CAT)}
                { stockAndWeightsRenderComponent(ETF_CAT)}
								{/* <StocksWeightsTable
									rowHeaders={headerEtf}
                  data={etfAllocation}
                  isLoading={loadingEtf}
								/> */}
							</Row>
							<Row className={stackBuyStyles.footer}>
								<Col className={stackBuyStyles.footerStack}>
									{stocks.length} stocks & {etf.length} ETF’s
								</Col>
								<Col
									className={stackBuyStyles.footerStack}
									style={{ textAlign: 'end' }}>
									current value : $
									{currentValue === '' || currentValue === null
										? '--'
										: currentValue}
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>
				<Col md={4} lg={3} xs ={12} className={`p-0 ${stackBuyStyles.reviewScroll}`}>
					<Card className={stackBuyStyles.reviewCard}>
						<Card.Body className={`${stackBuyStyles.reviewCardBody} pr-0`}>
							<Row className={`m-0`}>
								<Col className={stackBuyStyles.reviewOrderTitle}  xs={10}>Review Order</Col>
								<Col xs={1} className={`${stackBuyStyles.closeCard} d-block d-md-none`}>
										<img
											src={crossImage}  onClick={closeModal}></img>
									</Col>
							</Row>
							<Row className={`d-none d-md-flex m-0 mt-4`}>
								<Col md={3} xs={3} className='p-0'>
									<img src={logo} className={stackBuyStyles.stackImage}></img>
								</Col>
								<Col className={stackBuyStyles.detail} xs={9}>
									<div className={stackBuyStyles.stackName}>{ticker}</div>
									<div className={stackBuyStyles.stackCreator}>{name.length > 21 ? `${name.substring(0,18)}...`: name}</div>
								</Col>
							</Row>
              <Row className={`d-flex d-md-none m-0 mt-2`}>
                <Col className='d-flex justify-content-between align-items-center p-0'>
                  <div className={stackBuyStyles.detail}>
                    <div className={stackBuyStyles.stackName}>{ticker}</div>
                    <div className={stackBuyStyles.stackCreator}>{name.length > 21 ? `${name.substring(0,18)}...`: name}</div>
                  </div>
                  <img src={logo} className={stackBuyStyles.stackImage}></img>
                </Col>
              </Row>
							<Row className='mt-4'>
								<Col className='d-flex justify-content-between'>
                  <span className={stackBuyStyles.investing}>Sell stack of</span>
                  <span
                    className={styles.sellAll}
                    style={{ cursor: 'pointer' }}
                    id={sellAll ? 'clear' : 'Sell all'}
                    onClick={(e) => {
                      setValuesSellAll(e)
                    }}>
                    {sellAll ? 'clear' : 'Sell all'}
                  </span>
                </Col>
                {/* <Col>
                  {errorMessage}
                </Col> */}
              </Row>
							<Row className='m-0 mt-3' style={{ position: 'relative' }}>
								<span className={stackBuyStyles.dollarIcon}>
									<img src={require('../../public/images/dollar.svg')}></img>
								</span>
								<FormControl
									className={stackBuyStyles.amountInput}
									placeholder='Enter Amount'
									value={sellAll ? currentValuePending : sellAmount}
									onChange={handleChange}
									readOnly={sellAll}
								/>
								{inputError !== '' ? (
									<span
										className={`${stackBuyStyles.feeNote} mt-2`}
										style={{ color: '#E51446' }}>
										{inputError}
									</span>
								) : (
									''
								)}
							</Row>
							<Row className={`m-0 mt-2 ${stackBuyStyles.feeNote}`}>
              Commission will be deducted from sell amount
							</Row>
							<Row style={{marginTop:'32px', marginLeft:'0px'}}>
								<div className={stackBuyStyles.enterPinText}>Enter PIN *</div>
							</Row>
							<Row className='m-0 mt-2'>
								{/* <OtpInput
									value={spin}
                  onChange={spin => setSpin(spin)}
                  isInputSecure={isInputSecure}
                  onPaste={(e)=>{
                    e.preventDefault()
                    return false;
                  }} onCopy={(e)=>{
                    e.preventDefault()
                    return false;}}
									numInputs={SPIN_LENGTH}
									inputStyle={styles.spinInput}
									separator={<span  className={styles.otpInput}></span>}
								/> */}
                 <PinInput
                    length={SPIN_LENGTH}
                    initialValue=""
                    secret
                    onChange={(value, index) => {
                      reset(value)
                     }}
                    onPaste={(e) => {
                      e.preventDefault()
                      return false;
                    }} onCopy={(e) => {
                      e.preventDefault()
                      return false;
                    }}
                    type="numeric"
                    inputMode="number"
                    inputStyle={{...inputStyle.spinInput }}
                    inputFocusStyle={{ background: '#EFEFF1' }}
                    onComplete={(value, index) => {
                      setSpin(value)
                     }}
                    autoSelect={true}
                    regexCriteria={/[0-9]/}
                  />
								{errorMessage !== '' ? (
									<span
										className={`${stackBuyStyles.feeNote} mt-2`}
										style={{ color: '#E51446' }}>
										{errorMessage}
									</span>
								) : (
									''
								)}
							</Row>

              {/* Accodion for mobile */}
              {/* <hr></hr> */}
                <hr className='d-block d-md-none'></hr>
                <div className='d-block d-md-none overflow-auto'>
                   <Accordion>
                   <CustomToggle
                      eventKey='0'
                      srno='1'></CustomToggle>
                      <Accordion.Collapse eventKey='0'>
                        <>
                        { stockAndWeightsRenderComponent(!ETF_CAT)}
                        { stockAndWeightsRenderComponent(ETF_CAT)}
                        {/* <StocksWeightsTable
                          rowHeaders={headerStock}
                          data={stockAllocation}
                          isLoading={loadingNonEtf}
                        />
                        <StocksWeightsTable
                          rowHeaders={headerEtf}
                          data={etfAllocation}
                          isLoading={loadingEtf}
                        /> */}
                          </>
                        </Accordion.Collapse>
                   </Accordion>
                </div>

                <div className={stackBuyStyles.confirmFixed}>
              <Row
              className={`m-0 ${stackBuyStyles.termsConditions}`}
              style={{ display: "inline-block"}}
              >
								<span>By clicking "Sell" you are agreeing to the stack </span>
								<span className={stackBuyStyles.terms}>
                  <a
                  href={tos}
                  style={{ color: "#005dd6"}}
                  target="_blank" rel="noopener noreferrer">Terms & Conditions</a>
                </span>
							</Row>
							<Row className={`m-0 mt-3 ${stackBuyStyles.buttonRow}`}>
								<Button
									className={`${stackBuyStyles.buyButton} ${styles.buyButton}`}
									onClick={onSell}
									disabled={disableButton || orderLoading}>
									{orderLoading ? 'Loading...' : 'Confirm Order'}
								</Button>
							</Row>
              </div>
						</Card.Body>
					</Card>
				</Col>
			</Row> }
		</>
	)
}


const CustomToggle = ({ eventKey, callback }) => {
	const currentEventKey = useContext(AccordionContext)

	const decoratedOnClick = useAccordionToggle(
		eventKey,
		() => callback && callback(eventKey)
	)

	const isCurrentEventKey = currentEventKey === eventKey

	return (
		<Row onClick={decoratedOnClick} className='m-0 mt-3'>
			<Col className='p-0' xs={8}>
				<div className={stackBuyStyles.stocksWeightMobile}>Stocks & Weights</div>
			</Col>
			<Col style={{textAlign:'end'}}>
				<img
					src={
						isCurrentEventKey
							? AngleDown
							: DropDownIcon
					}></img>
			</Col>
		</Row>
	)
}

export default StackSell
