import React, { Component } from 'react'
import styles from './Plan.module.css'
import { Row,Col } from 'react-bootstrap'
// import CheckBox from './../CheckBox/CheckBox'
import checkBoxStyles from './../CheckBox/CheckBox.module.css'
// import active from './../../public/images/active.svg'
// import inactive from './../../public/images/inactive.svg'
import Modal from 'react-bootstrap/Modal'
import PlanCard from './PlanCard'
import PinInput from "react-pin-input"
import CrossImage from '../../public/images/cross.png'





class PlanComponent extends Component {
  constructor(props){
    super()
    this.state={
      createpin:true,
      heightCalculation:0
    }
  }

  componentDidUpdate(previousProps,previousState){
    let maxLength = 0;
    this.props.planList.map((item,i)=>{
      maxLength = Math.max(maxLength, item.features.length)
    })
    if (previousState.heightCalculation < maxLength ) {
      this.setState({
        heightCalculation:(maxLength*40)
      })
    }
  }



	render() {
    return (
    <>
    {this.state.createpin ?
    (<div>
    <Modal
    backdrop={!sessionStorage.getItem('login') && this.props.pageType=== 'new' ? "static" : true}
    keyboard={!sessionStorage.getItem('login') && this.props.pageType=== 'new' ? false : true}

    // backdrop={this.props.pageType=== 'new' ? "static" : true}
    // keyboard={this.props.pageType=== 'new' ? false : true}


     className={`modal-dialog-scrollable ${styles.responsiveModal}`}
     show={this.props.show} onHide={this.props.handleClose}
     dialogClassName={styles.responsiveModal}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
    <div className={`overlayLoading themeLoaderColor ${this.props.loader ? '' : 'd-none'}`}></div>
        <div className={styles.closeIcon}>
        <img src={CrossImage} onClick={this.props.handleClose}/>
        </div>
       <div>
        <p className={"mt-2 "+styles.heading}>
          {this.props.pageTitle}
        </p>
        </div>
      {/* <Modal.Body className={`p-0 ${styles.modalBody}`}> */}
      <Modal.Body className='p-0'>
        <Row  className={styles.mainDiv}>
          {this.props.planList
            ? (
                this.props.planList.map((item,i)=>{
            return(

              <PlanCard
                i={i}
                size={this.props.planList.length}
                features={item.features}
                planName={item.planName}
                planTitleAdjacent={item.planTitleAdjacent}
                planTitleAmount={item.planTitleAmount}
                subscriptionAmount={item.subscriptionAmount}
                freeTrades = {item.freeTrades}
                planId={item.planId}
                selectPlan={this.props.selectPlan}
                planList={this.props.planList}
                selectedPlan={this.props.selectedPlan}
                featuresv2={item.featuresv2}
                heightCalculation={this.state.heightCalculation}
                currency={item.currency}
                buttonColor={this.props.buttonColor}
              />
              )
              })
            ) : ''
          }
                {this.props.couponArea && this.props.couponInputBox?<>
          <Col xs='auto' className='d-flex d-xl-none' style={{marginTop:"24px"}}>
                <input type="text" className={styles.couponInput} style={{borderRightWidth: '0px'}} id="txtCounponCode" value={this.props.couponCode} placeholder="Enter Coupon Code" onChange={(e)=>this.props.handleCoupon(e)} />
                <div className="input-group-prepend" onClick={()=>this.props.applyRemoveButton('apply')}>
                  <div className={"input-group-text "+ styles.counponApply}>Apply</div>
                </div>
          </Col>
          {/* {this.props.couponMsg? */}
           <Col xs='auto' className='d-flex d-xl-none'>
                          <p className={styles.couponText+' '+styles.errorText}>{this.props.couponMsg}</p>
          </Col>
          {/* // :<Col xs='auto' className="d-xl-none"></Col>} */}

          </>
          :''}
        </Row>

{this.props.pageType === 'new' ?
			<div className="m-4 text-center">
				{/* <CheckBox
						className={styles.termText}
						label="I agree to Stockal’s Terms & Conditions <a href={termsAndConditionsLink} target='_blank'>
            Terms and Conditions
          </a> and the Disclosures <a href={updatedDisclosuresLink} target='_blank'>
          <span className={styles.disclosures}> Disclosures</span>
        </a>"
						id='isTermsAndCondition'
						name='isTermsAndCondition'
						value={this.props.isTermsAndCondition}
						// onChange={(e)=>this.props.HandleCheckBox(e)}
						isChecked={this.props.isTermsAndCondition}
					/> */}

            <div className={`custom-control custom-checkbox ${checkBoxStyles.checkBox}`}>
                  <input
                    type='checkbox'
                    className={`custom-control-input ${checkBoxStyles.checkBoxInput}`}
                    id='isTermsAndCondition'
                    name='isTermsAndCondition'
                    value={this.props.isTermsAndCondition}
                    // onChange={(e)=>this.props.HandleCheckBox(e)}
                    checked={this.props.isTermsAndCondition}
                  />
                  <label
                    className={`custom-control-label ${checkBoxStyles.checkMark}`}
                    htmlFor='isTermsAndCondition'>
                    I agree to Stockal’s <a href={this.props.termsAndConditionsLink} target='_blank' rel="noopener noreferrer">
            Terms and Conditions
          </a> and the <a href={this.props.updatedDisclosuresLink} target='_blank' rel="noopener noreferrer">
          <span className={styles.disclosures}> Disclosures</span>
        </a>
                  </label>
                </div>


			</div>:''}

    {/* </div> */}


      {/* {(this.props.planMsg && this.props.planMsg !== '') ? <p style={{textAlign:'center',color:'red'}}>{this.props.planMsg}</p>:''} */}
      </Modal.Body>
      <Modal.Footer style={{display:'block'}} className={styles.footerPadding}>
      {(!this.props.planMsg || this.props.planMsg === '') ?

        <Row className={styles.mobileMarginBottom}>
        <Col className={styles.removePadding}>

        {this.props.couponArea
              ? this.props.couponInputBox ?

              <div className="d-none d-xl-flex">
                <input type="text" className={styles.couponInput} style={{borderRightWidth: '0px'}} id="txtCounponCode" value={this.props.couponCode} placeholder="Enter Coupon Code" onChange={(e)=>this.props.handleCoupon(e)} />
                <div className="input-group-prepend" onClick={()=>this.props.applyRemoveButton('apply')}>
                  <div className={"input-group-text "+ styles.counponApply}>Apply</div>
                </div>
                <p className={styles.couponText+' '+styles.errorText}>{this.props.couponMsg}</p>
              </div>

          :
                <> <div>
                  <span className={styles.couponName}>{this.props.couponCode}</span>
                  <span className={styles.remove} onClick={()=>this.props.applyRemoveButton('remove')}>REMOVE</span>
                </div>
                <div className={styles.discountAmount}>{this.props.couponMsg}</div> </>

         :''}


        </Col>
        <Col xl='auto' className={styles.removePadding} style={{textAlign:'end'}}>
        {/* <div className={`d-xl-none custom-control custom-checkbox ${checkBoxStyles.checkBox}`}>
                  <input
                    type='checkbox'
                    className={`custom-control-input ${checkBoxStyles.checkBoxInput}`}
                    id='isTermsAndCondition'
                    name='isTermsAndCondition'
                    value={this.props.isTermsAndCondition}
                    // onChange={(e)=>this.props.HandleCheckBox(e)}
                    checked={this.props.isTermsAndCondition}
                  />
                  <label
                    className={`custom-control-label ${checkBoxStyles.checkMark}`}
                    htmlFor='isTermsAndCondition'>
                    I agree to Stockal’s <a href={this.props.termsAndConditionsLink} target='_blank' rel="noopener noreferrer">
            Terms and Conditions
          </a> and the <a href={this.props.updatedDisclosuresLink} target='_blank' rel="noopener noreferrer">
          <span className={styles.disclosures}> Disclosures</span>
        </a>
                  </label>
                </div> */}
                <div
                  className={`${styles.footerButton} ${styles.onlyXl}`}
                  style={{background:this.props.buttonColor,whiteSpace:'nowrap'}}
                  dangerouslySetInnerHTML={{__html: this.props.buttonText}}
                  onClick={()=>this.props.continuePayment()}
                  >
                  </div>
        </Col>
      </Row>

      :
      (this.props.planMsg && this.props.planMsg !== '') ? <p style={{textAlign:'center',color:'red'}}>{this.props.planMsg}</p>:''
      }
      </Modal.Footer>
    </Modal>
</div>):(
      <Modal
       className={`modal-dialog-scrollable ${styles.responsiveModal}`}
       show={this.props.show} onHide={this.props.handleClose}
       dialogClassName={styles.responsiveModal}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{width:"100%"}}
      >
            <div style={{padding:"50px"}}>
         <Row style={{margin:'0px',textAlign:'center',justifyContent:"center"}}>
           <Col xs={12}>
             <div style={{
                  fontFamily: 'geomanistMedium',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '24px',
                  lineHeight: '32px',
                  textAlign: 'center',
                  letterSpacing: '0.6px',
                  color: '#3C404A',
            }}>
             Create a PIN to keep your account safe
             </div>
           </Col>
           <Col xs={6} style={{marginTop:'9px'}}>
              <div style={{
                      fontFamily: 'geomanistRegular',
                      fontStyle: 'normal',
                      fontWeight: 'normal',
                      fontSize: '16px',
                      lineHeight: '24px',
                      textAlign: 'center',
                      letterSpacing: '0.4px',
                      color: '#626777',
                      marginTop:'9px'
                }}>
                  This will be asked for each time you process any monetary transaction so please remember it for the future
              </div>
           </Col>
           <Col xs={12} style={{marginTop:"26px"}}>
           <PinInput
            length={4}
            initialValue=""
            inputStyle={{borderColor: '#D3D4DA',width:'62px',height:'62px',margin:"5px"}}
            // secret
            // onChange={(value, index) => { reset() }}
            // onPaste={(e)=>{
            //   e.preventDefault()
            //   return false;
            // }} onCopy={(e)=>{
            //   e.preventDefault()
            //   return false;}}
            // type="numeric"
            // inputMode="number"
            // inputFocusStyle={{ background: '#EFEFF1' }}
            // onComplete={(value, index) => { setSpin(value) }}
            // autoSelect={true}
            // regexCriteria={/[0-9]/}
          />
           </Col>
           <Col xs={12} style={{marginTop:"30px"}}>
             <div style={{padding:'30px',paddingTop:'20px',paddingBottom:"20px",display:'inline-block',  fontFamily: 'geomanistMedium',
                      fontStyle: 'normal',
                      fontWeight: 'normal',
                      fontSize: '16px',
                      lineHeight: '24px',
                      textAlign: 'center',
                      letterSpacing: '0.3px',
                      color: '#FFFFFF',
                      background:'#0061D3'
              }}>
              Confirm PIN
             </div>
           </Col>
         </Row>
         </div>

        </Modal>
)
}
</>
)


	}
}

export default PlanComponent
