import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import styles from './StackBuy.module.css'

const purchased = props => {
	return (
		<Card className={styles.cardBorder}>
			<Card.Body className={styles.reviewCardBody}>
				<Row className={`m-0`}>
					<Col md={1} style={{textAlign:'end'}} className={`${styles.closeCard} d-block d-md-none`} onClick={props.onClick}>
						<img src={require('../../public/images/modalClose.png')}></img>
					</Col>
				</Row>
				<Row className={`justify-content-center ${styles.purchaseImage}`}>
					<img src={require('../../public/images/stackOrder.png')}></img>
				</Row>
				<Row className={styles.orderPlacedText}>
					Great. Your order has been placed.
				</Row>
			</Card.Body>
		</Card>
	)
}

export default purchased
