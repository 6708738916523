import { Color } from 'highcharts'
import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import ReactHighcharts from 'react-highcharts'
import styles from './StockPieChart.module.css'
import {decimalAdjust} from '../../utils'

const colors = ['#A35EE8', '#3CC68A', '#1315F3', '#F8B100']
const colors2 = ['#1315F3','#A35EE8', '#00D6AF', '#F5A812', '#E34918']

const StockPieChart = ({ width, height, color, data, fromEtf=false }) => {
	const [legendName, setlegendName] = useState('')
	const [legendValue, setlegendValue] = useState(0)
  const [mouseOver,setMouseOver] = useState('')
	const [legendColor, setlegendColor] = useState('')
	useEffect(() => {
    if(data.length > 0){
		const highestedIndex = Math.max.apply(
			Math,
			data.map(function (o) {
        if(o.y !== undefined){
				return o.y
        }
			})
		)
		const showValue = data.findIndex(item => item.y === highestedIndex)
		if (showValue >= 0) {
			setlegendName(data[showValue].name)
			setlegendValue(data[showValue].y)
			setlegendColor(colors[showValue])
		}
  }
	}, [data])


	const custoumOption = () => {
		return {
			chart: {
				type: 'pie',
				style: {
					height: height
				},
        spacingLeft:-150,
			},
			legend: {
				enabled: true,
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
        padding: 0,
        itemMarginTop: 10,
        itemMarginBottom: 10,
        labelFormatter: function () {
          return `${decimalAdjust('floor',this.percentage,-2)}% ${this.name}`
        },
        floating:true,
        symbolRadius: 3,
        symbolHeight: 14,
        symbolWidth: 14,
        x: navigator.userAgent.includes('Firefox') ? '' : -50,
			},
			credits: {
				enabled: false
			},
			title: {
				text: undefined
			},
			tooltip: {
				enabled: false
			},
			colors: colors,
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					borderWidth: 0,
					dataLabels: {
						enabled: false
					},
					showInLegend: true
				},
				series: {
					states: {
						hover: {
							enabled: false
						}
					}
				}
			},
			series: [
				{
					name: 'Value',
					point: {
					},
					colorByPoint: true,
					size: '90%',
					innerSize: '75%',
					data: data
				}
			]
		}
	}

  const custoumOption2 = () => {
    return {
      chart: {
        type: 'pie',
        style: {
          height: height
        },
        marginLeft: -550
      },
      legend: {
        enabled: true,
        // align: 'middle',
        verticalAlign: 'middle',
        layout: 'vertical',
        padding: 0,
        itemMarginTop: 10,
        itemMarginBottom: 10,
        labelFormatter: function () {
          return `${decimalAdjust('floor',this.y,-2)}% ${this.name}`
        },
        // floating:false,
        symbolRadius: 3,
        symbolHeight: 14,
        symbolWidth: 14,
        // x: 250,
      },
      credits: {
        enabled: false
      },
      title: {
        text: undefined
      },
      tooltip: {
        enabled: false
      },
      colors: colors2,
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          borderWidth: 0,
          dataLabels: {
            enabled: false
          },
          showInLegend: true
        },
        series: {
          states: {
            hover: {
              enabled: false
            }
          }
        }
      },
      series: [
        {
          name: 'Value',
          point: {
          },
          colorByPoint: true,
          size: '90%',
          innerSize: '75%',
          data: data
        }
      ],
      responsive: {
        rules: [
        {
          condition: {
              minWidth:690
          },
          chartOptions: {
            chart:{
              marginLeft:-490,
            },
              legend: {
                  align: 'center',
                  verticalAlign: 'middle',
                  layout: 'vertical',
                  y: 0,
                  x:0
              }
          }
      },{
        condition: {
            maxWidth: 545,
        },
        chartOptions: {
          chart:{
            marginLeft:0,
          },
            legend: {
                align: 'center',
                verticalAlign: 'bottom',
                layout: 'vertical',
                x: -50
            }
        }
    },
    {
      condition: {
          maxWidth: 345,
      },
      chartOptions: {
        chart:{
          marginLeft:0,
        },
          legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'vertical',
              x: -30
          }
      }
  },
    ]
    }
    }
  }





	return (
		<div>
			<ReactHighcharts config={fromEtf ? custoumOption2() : custoumOption()}></ReactHighcharts>
			<Row style={{display:fromEtf && 'none'}} className='text-center' >
				<Col className={styles.portfolio}>Portfolio allocation</Col>
			</Row>
		</div>
	)
}

export default StockPieChart



