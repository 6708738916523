import React, { useEffect, useState } from 'react'
import styles from './fillBankDetail.module.css'
import { Row, Col } from 'react-bootstrap'
import {HdfcLrs,IciciLRS,IndusindLRS,KotakLrs,IdfcLRS} from '../lrsForm'
// import info from './brokerDetail'
import backImage from '../../public/images/back.png'

const FillBankDetail = ({
	setModalData,
	data,
	back,
	bankName,
  bankId,
	buttonColor,
  brokerDetail,
  enterBrokerInformation,
  fetchBrokerDetails
}) => {

  let info = null
  if(brokerDetail){
    info={
      name: brokerDetail && brokerDetail.beneficiaryName ? brokerDetail.beneficiaryName : '--',
      address: brokerDetail && brokerDetail.beneficiaryAddress ? brokerDetail.beneficiaryAddress  :'--',
      country: brokerDetail  && brokerDetail.beneficiaryAddressDetails && brokerDetail.beneficiaryAddressDetails.country ? brokerDetail.beneficiaryAddressDetails.country : '--' ,
      city: brokerDetail && brokerDetail.beneficiaryAddressDetails && brokerDetail.beneficiaryAddressDetails.city ? brokerDetail.beneficiaryAddressDetails.city : '--' ,
      state: brokerDetail && brokerDetail.beneficiaryAddressDetails && brokerDetail.beneficiaryAddressDetails.stateProvince ? brokerDetail.beneficiaryAddressDetails.stateProvince : '--' ,
      pinCode: brokerDetail && brokerDetail.beneficiaryAddressDetails && brokerDetail.beneficiaryAddressDetails.zipPostalCode ? brokerDetail.beneficiaryAddressDetails.zipPostalCode : '--' ,
      contactNumber: brokerDetail && brokerDetail.contactNumber ? brokerDetail.contactNumber : '--' ,
      currency: brokerDetail && brokerDetail.currency ? brokerDetail.currency : '--' ,
      nickName: brokerDetail && brokerDetail.nickname ? brokerDetail.nickname : '--',
      bankName: brokerDetail && brokerDetail.bankName ? brokerDetail.bankName : '--' ,
      bankAddress: brokerDetail && brokerDetail.bankAddress ? brokerDetail.bankAddress : '--',
      bankCountry: brokerDetail && brokerDetail.bankAddressDetails && brokerDetail.bankAddressDetails.country ? brokerDetail.bankAddressDetails.country : '--' ,
      accountNumber: brokerDetail && brokerDetail.bankAccountNumber ? brokerDetail.bankAccountNumber : '--' ,
      SWIFTCode: brokerDetail && brokerDetail.bankSwiftCode ? brokerDetail.bankSwiftCode  : '--',
      ABANumber: brokerDetail && brokerDetail.ABANumber ? brokerDetail.ABANumber : '--',
      remarks: 'Your investing account number'
    }
  }

	const [transfer, setTransfer] = useState(false)
  const getBankInstruction = () => {
    if(bankName.includes('ICICI')){
      return(
              <IciciLRS info={info} fetchBrokerDetails={fetchBrokerDetails}/>
            )
    }
    else if(bankName.includes('HDFC')){
       return(
          <HdfcLrs info={info} fetchBrokerDetails={fetchBrokerDetails}/>
        )
    }
    else if(bankName.includes('IDFC')){
          return(
          <IdfcLRS info={info} fetchBrokerDetails={fetchBrokerDetails}/>
        )
    }
    else if(bankName.includes('INDUSIND') || bankName.includes('IndusInd') ){
      return(
          <IndusindLRS info={info} fetchBrokerDetails={fetchBrokerDetails}/>
        )
       }
       else if(bankName.includes('KOTAK')){
        return(
          <KotakLrs info={info} fetchBrokerDetails={fetchBrokerDetails}/>
          )
         }
    else{
      return( 'No Bank Found ')
    }
  }

  const accountNo = JSON.parse(sessionStorage.getItem('userInfo')) ? JSON.parse(sessionStorage.getItem('userInfo')).accountNo : ''

  const isVirtualAccount = JSON.parse(sessionStorage.getItem('userInfo')) && JSON.parse(sessionStorage.getItem('userInfo')).virtualAccount  ? JSON.parse(sessionStorage.getItem('userInfo')).virtualAccount : ''

	return (
		<div className={styles.card}>
			<Row className={`${styles.responsiveDiv} ${styles.fixedAccount}`}>
				<Col xs={10} md={6}>
          <div 	onClick={() => setModalData(back)}>
					<img src={backImage} />
					<span
						className={`${styles.title}`}
						style={{
							paddingLeft: '20px',
							cursor: 'pointer'
						}}>
						{'Indian Bank Account'}
					</span>
          </div>
				</Col>
        {/* <Col className={styles.accountNumber} md={6}>
        Your Account No: {accountNo}
        </Col> */}
			</Row>
			<Row style={{ marginTop: '85px', marginBottom: '106px' }}>
				<Col className={styles.body}>
          {getBankInstruction()}
					{/* <div className='accordion' id='accordionExample'>
						<Instruction index={true} />
						<Instruction index={false} />
					</div> */}
				</Col>
			</Row>
			<Row className={styles.fixedDiv}>
				<Col>
					<button
						type='button'
            disabled={isVirtualAccount ? false : true}
						style={{ backgroundColor: buttonColor }}
						className={`btn btn-primary btn-sm ${styles.addfunds} themeButton`}
						onClick={() => {
              enterBrokerInformation()
              setTransfer(true, setModalData(data))
              }}>
						<span className={styles.addfundstext}>Transfer Complete</span>
					</button>
				</Col>
			</Row>
		</div>
	)
}

export default FillBankDetail
