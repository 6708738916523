import React, { useState, useEffect, useRef } from 'react'
import { Row } from 'react-bootstrap'
import { fpslChange, getAccountInfo } from '../../../api'
import styles from '../Profile.module.css'
import SignUpButton from '../../SignUp/SignUpButton'
import PinInput from 'react-pin-input'
import $ from 'jquery'

const SecLendingOTP = ({
	hashID,
	sendOtpApi,
	changeCurrent,
}) => {
	const [otp, setOtp] = useState('')
	const [resendOtpTimer, setresendOtpTimer] = useState(180)
	const [otpSendMessage, setOtpSendMessage] = useState('')
	const [otpError, setOtpError] = useState('')
	const [loading, setLoading] = useState(false)
	const myRef = useRef(null)
    const [email, setEmail] = useState(null)
    const [fetchLoading, setFetchLoading] = useState(false)
    const [fetchError, setFetchError] = useState(false)
	const [isSubmitted, setIsSubmitted] = useState(false)
	const fetchEmail = async () => {
		setFetchError(false)
		setFetchLoading(true)
		const res = await getAccountInfo();
		if (res && Object.keys(res).length > 0) {
			setEmail(res['emailAddress1'])
		}
		else {
			setFetchError(true)
		}
		setFetchLoading(false)
	}
	useEffect(() => {
        fetchEmail()
		myRef.current.focus()
	}, [])

	// disable copy and paste in otp-input
	useEffect(() => {
        $("#pin-input  input:nth-child(3)").after(`<div style="display:inline-block;width: 16px;height: 2px;background: #D3D4DA;border-radius: 2px;margin:0 16px 2px 0"></div>`);
		const pinInputs = document.getElementsByClassName('pincode-input-text')
		if (pinInputs && pinInputs?.length > 0) {
			for (let i = 0; i < pinInputs.length; i++) {
				pinInputs[i].addEventListener('keydown', e => {
					if (
						e.key.toLowerCase() === 'control' ||
						e.key.toLowerCase() === 'meta' ||
						e.key.toLowerCase() === 'v' ||
						e.key.toLowerCase() === 'c'
					) {
						e.preventDefault()
					}
				})
			}
		}
	}, [])

	const handleChange = value => {
		setOtp(value)
	}

  useEffect(() => {
    if(otp.length === 6 && !isSubmitted){
     handleSubmit()
    }
   },[otp])

	useEffect(() => {
		startTimer()
	})

	const startTimer = () => {
		const timer = setTimeout(() => {
			setresendOtpTimer(resendOtpTimer - 1)
		}, 1000)

		if (resendOtpTimer === 178) {
			setOtpSendMessage('')
		}

		if (resendOtpTimer === 0) {
			clearInterval(timer)
		}
	}

	const onResend = async() => {
		setOtp('')
		setOtpError('')
		myRef.current.clear()
		setLoading(true)
		const res = await sendOtpApi()
		if(res){
			setresendOtpTimer(180)
			setOtpSendMessage('OTP has been resend')
			startTimer()
		}else{
			setOtpError('Resend OTP Fail')
		}
		setLoading(false)		
	}
	const inputStyle = {
		spinInput: {
			border: otpError === '' ? '1.5px solid #D3D4DA' : '1.5px solid #FA274A',
			textAlign: 'center',
			width: '42px',
			height: '44px',
			background: '#FFFFFF',
			borderRadius: '6px',
			marginRight: '16px',
            marginBottom: '8px',
			fontFamily: 'geomanistregular',
			fontStyle: 'normal',
			fontWeight: '400',
			fontSize: '14px',
			lineHeight: '20px',
			color: '#3C404A'
		}
	}

	const handleSubmit = async () => {
		if(!isSubmitted)
			setIsSubmitted(true)
    	setLoading(true)
		if (!otp || otp.length !== 6) {
			setOtpError('Incorrect OTP',	setLoading(false))
			return
		}
		if (otp && otp.length === 6) {
			const response = await fpslChange(true, otp, hashID)
			if (response && response.code === 200) {
				setOtp(otp)
				changeCurrent('profile')
			} else {
				setOtpError(response.message,	setLoading(false))
			}
		}
    setLoading(false)
	}

	return (
		<div className={styles.secLendingOTPContainer}>
			<form
				onSubmit={handleSubmit}
				className={styles.verifyCard}
				style={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
				<Row className={styles.title}>Verify OTP</Row>
				<Row className={styles.subTitle}>
					Enter the 6-digit OTP sent to your email address
				</Row>
				<Row className={styles.emailContainer}>
					{!fetchLoading && !fetchError && <span className={styles.email}>{email}</span>}
					{fetchLoading && !fetchError && <span className={styles.subTitle}>Fetching Email...</span>}
					{fetchError && <span className={styles.subTitle} style={{color:'#fa274a'}}>Unable to fetch Email at the moment</span>}
					{!fetchError ? <span className={styles.goBackText} style={{ cursor: 'pointer' }} onClick={()=>{changeCurrent('profile')}}>Edit</span>
					:<span className={styles.goBackText} style={{ cursor: 'pointer' }} onClick={fetchEmail}>Try Again</span>}
				</Row>
				<Row className={styles.pinInput} id='pin-input'>
					<PinInput
						length={6}
						initialValue=''
						value={otp}
						onChange={(value, index) => {
							setOtpError('')
							const regex = new RegExp('^[0-9]*$')
							if (regex.test(value)) handleChange(value)
						}}
						type='numeric'
						inputMode='number'
						inputStyle={{ ...inputStyle.spinInput }}
						inputFocusStyle={{ border: '1.5px solid #3C404A' }}
						autoSelect={true}
						regexCriteria={/[0-9]/}
						ref={myRef}
					/>
				</Row>
				{otpError && <div className={styles.otpError}>{otpError}</div>}

				<div className={styles.resendOtp}>
					{resendOtpTimer > 0 ? (
						otpSendMessage ? (
							<>
								<span
									className={styles.goBackText}
									style={{ color: '#767c8f' }}>
									{otpSendMessage}
								</span>
							</>
						) : (
							<>
								<span
									className={
										styles.timer
									}>{`Expires in ${resendOtpTimer}s`}</span>
							</>
						)
					) : (
						<>
							<span
								className={styles.goBackText}
								onClick={() => {
									!loading && onResend()
								}}
								style={{ cursor: 'pointer' }}>
								Resend OTP
							</span>
						</>
					)}
				</div>
				<Row className={styles.buttonMargin}>
					<SignUpButton
						title='Verify OTP'
						loading={loading}
						onClick={handleSubmit}
					/>
				</Row>
			</form>
		</div>
	)
}

export default SecLendingOTP
