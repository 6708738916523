import React, { Component } from "react";
import calendarImage from "../../../public/images/calendarIcon.png"
import { Calendar } from "react-date-range";
// import { format } from "date-fns";
import $ from 'jquery'
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

class ReportDatePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayCalendar: false,
      inputTxt: this.dateFormatter(this.props.value),
      readOnly: true,
      calendarMaxDate: new Date(),
      calendarMinDate: new Date('2019-01-01'),
    };

    this.showCalendar = this.showCalendar.bind(this);
    this.hideCalendar = this.hideCalendar.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.dateFormatter = this.dateFormatter.bind(this);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({ inputTxt: this.dateFormatter(nextProps.value) })
  }
  dateFormatter(mydate) {
    var curr_date = mydate.getDate();
    var curr_month = mydate.getMonth() + 1;
    var curr_year = mydate.getFullYear();
    if (curr_date < 10) {
      curr_date = "0" + curr_date;
    }
    if (curr_month < 10) {
      curr_month = "0" + curr_month;
    }
    mydate = `${curr_date}-${curr_month}-${curr_year}`;
    return mydate;
  }

  showCalendar() {
    this.setState({
      displayCalendar: true,
    });
    const that = this;
    $(document).mouseup(function (e) {
      var container = $('#' + that.props.id + "_cal");
      if (container) {
        // if the target of the click isn't the container nor a descendant of the container
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          that.hideCalendar();
        }
      }
    });
  }
  hideCalendar() {
    this.setState({
      displayCalendar: false,
    });
  }
  handleSelect(date) {
    this.props.changeDate(date, this.props.id)
    date = this.dateFormatter(date);
    this.setState(
      {
        inputTxt: date,
      },
      // this.props.changeDate(date, this.props.id)
    );
    this.hideCalendar();
  }

  render() {
    return (
      <div className="row">
        <span className="col-md-7 col-7" style={{ paddingRight: "0px" }}>
          <input
            className="form-control"
            id="ex1"
            type="text"
            style={{
              background: "#F4F4F4",
              borderRadius: "4px",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
              border: "0px",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "14px",
              lineHeight: "20px",
              letterSpacing: "0.6px",
              color: "#1E1F24",
            }}
            onClick={() => this.showCalendar()}
            value={this.state.inputTxt}
          />
        </span>
        <span
          onClick={() => this.showCalendar()}
          className="col-md-auto col-2"
          style={{
            paddingTop: "3px",
            paddingLeft: "0px",
            background: "#F4F4F4",
            borderRadius: "4px",
            borderTopLeftRadius: "0px",
            borderBottomLeftRadius: "0px",
            cursor: 'pointer'
          }}
        >
          <span>
            <img src={calendarImage} />
          </span>
        </span>
        <div
          id={this.props.id + "_cal"}
          style={{
            display: this.state.displayCalendar ? "block" : "none",
            position: "absolute",
            zIndex: "1000",
            top: "35px",
          }}
        >
          <Calendar
            date={new Date(this.props.value)}
            onChange={this.handleSelect}
            maxDate={this.state.calendarMaxDate}
            minDate={this.state.calendarMinDate}
          />
        </div>
      </div>
    );
  }
}
export default ReportDatePicker;
