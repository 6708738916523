import React from 'react';
import styles from './addFund.module.css';
import BankIcon from "../../public/images/bankIcon.svg"

const SelectTransferType = ({brokerAvailable,setSelectedBank,setPage,PAGE,selectedBank}) => {

    const selectBank = (e) => {
        setSelectedBank(e)
        setPage(PAGE.BANK)
    }

    return(
        <>
            <div className={styles.subHeader}>
                <div>Select Transfer Type</div>
            </div>
            <div className={styles.transferTypeBanks}>
            {brokerAvailable && brokerAvailable.length > 0 && brokerAvailable.map((data,index) => {
                return(
                    <div className={`align-items-center ${styles.transferType}`}  onClick={() => selectBank(data.brokerName)}>
                        <div className={styles.borderImage}>
                            <img 
                            src={data?.logo || BankIcon} 
                            className={data?.logo ? styles.bankLogoWithoutPad : styles.bankLogo}/>
                        </div>
                        <p className={styles.bankName}>{data.brokerName}</p>
                    </div>
                )
            })}
                <div className={`align-items-center ${styles.transferType}`} onClick={() => selectBank('OTHER')}>
                    <div className={styles.borderImage}>
                        <img className={styles.bankLogo}  src={BankIcon}/>
                    </div>
                        <p className={styles.bankName}>OTHER</p>
                </div>
            </div>
        </>
    )
}

export default SelectTransferType