import React , { useState, useEffect } from 'react'
import { freezeVirtualCard } from '../../api'
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import styles from './TransactionDetailsModal.module.css'
import modalCrossButton from '../../public/images/modalCrossButton.svg'
import freezeIcon from '../../public/images/redFreezeIcon.svg'

const FreezeVirtualCardModal = (props) => {
    
    const { onHide, cardId , setCardStatus } = props

    const [ isDisabled , setIsDisabled ] = useState(false)
    const [ cardFreezed , setCardFreezed ] = useState(false)

    const freezeCard = () => {

        setIsDisabled(true)

        const data = {cardId: cardId}
        
        freezeVirtualCard(data).then(response => {
            if(response.data.code === 200){
                setCardStatus(response.data.data.cardStatus)
                setCardFreezed(true)
                setIsDisabled(false)
                setTimeout(onHide, 2000)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <div className={styles.modalContainer}>
            <div className={styles.modalHeader} style={{backgroundColor:"#F7F7F8"}}>
                <div className={styles.freezeCard}>
                    <span><img src={freezeIcon} alt="freeze-icon"/></span>
                    <p className={`${styles.status} ${styles.textRed}`}>Freeze this Virtual Card?</p>
                </div>
                <img src={modalCrossButton} alt="cross-button" onClick={onHide}></img>
            </div>
            <div className={styles.accountInfo}>
                <p className={`${styles.modalText} ${styles.textGrey}`}>
                    {cardFreezed ? 'As requested, your virtual card has been freezed temporarily.' : 'Freezing your card temporarily pauses all outgoing transactions. You can unfreeze your virtual card at any time to resume your usage.'}
                </p>
            </div>
            {cardFreezed ? '' : (
                <div className={`${styles.modalRow} ${styles.modalButtonContainer}`}>
                    {isDisabled ? (
                        <Button variant="danger" disabled className={`${styles.modalButton} ${styles.modalButtonRed} ${styles.modalButtonSolidRed}`}>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </Button>
                    ) : (
                        <button className={`${styles.modalButton} ${styles.modalButtonRed} ${styles.modalButtonSolidRed}`} onClick={freezeCard}>
                            <p className={`${styles.modalText} ${styles.textWhite}`}>Freeze Card</p>
                        </button>
                    )} 
                    <button onClick={onHide} className={`${styles.modalButton} ${styles.modalButtonGrey}`}>
                        <p className={styles.modalText}>Cancel</p>
                    </button>   
                </div>
            )} 
        </div>
    )
}

export default FreezeVirtualCardModal