import React,{useEffect, useState} from 'react'
import {Row,Col,Card} from 'react-bootstrap'
import {RoeChart,IncomeCashFlowChart} from './index'
import {getStocksChart} from '../../api'
import styles from './chart.module.css'

const StocksMoreChart = ({code}) => {
  const [roeData,setroeData] = useState([])
  const [dividentData,setdividentData] = useState([])
  const [incomeData,setIncomeData] = useState([])
  const [activeIncome,setActiveIncome] = useState('netincome')

  const getRoeChartData = async () => {
   try{
      const response = await getStocksChart(code,'Y','roe')
      if(response && response.code == 200 && response.data && response.data.roe.length > 0){
        setroeData(response.data.roe)
      }
   }catch(error){
     console.log(error)
   }
  }

  const getDividentChartData  = async () => {
    try{
       const response = await getStocksChart(code,'Y','cashdividendspershare')
       if(response && response.code == 200 && response.data && response.data.cashdividendspershare.length > 0){
        setdividentData(response.data.cashdividendspershare)
       }
    }catch(error){
      console.log(error)
    }
   }

  const getIncomeChartData = async () => {
    try{
       const response = await getStocksChart(code,'Y',activeIncome)
       if(response && response.code == 200 && response.data && response.data.netincome.length > 0){
        setIncomeData(response.data.netincome)
       }
    }catch(error){
      console.log(error)
    }
   }

  useEffect(() => {
      getRoeChartData()
      getDividentChartData()
  },[])

  // useEffect(() => {
  //   getIncomeChartData()
  // },[activeIncome])

  const roeCategories = []
  const roeDataSeries = []
  roeData.map((item) => {
    roeCategories.push(item.fiscalYear)
    roeDataSeries.push(item.value)
  })

  const dividentCategories = []
  const dividentDataSeries = []
  dividentData.map((item) => {
    dividentCategories.push(item.fiscalYear)
    dividentDataSeries.push(item.value)
  })

  // const incomeCategories = []
  // const incomeDataSeries = []
  // incomeData.map((item) => {
  //   incomeCategories.push(item.fiscalYear)
  //   incomeDataSeries.push(item.value)
  // })


 return(
   <div className={styles.modalWidth}>
   <Row>
     <Col xs={12} md={12} lg={6}>
      <Card className={styles.chartCard}>
        <Card.Body className={styles.chartCardBody}>
        <div className={styles.chartTitle}>ROE</div>
        <RoeChart data={roeDataSeries} categories={roeCategories} name='ROE'/>
        </Card.Body>
      </Card>
     </Col>
      <Col xs={12} md={12} lg={6} className={styles.mobileMargin} >
      <Card className={styles.chartCard}>
        <Card.Body className={styles.chartCardBody}>
        <div className={styles.chartTitle}>Dividends Paid</div>
        <RoeChart data={dividentDataSeries} categories={dividentCategories} name='Dividents Paid'/>
        </Card.Body>
      </Card>
      </Col>
   </Row>
   {/* <Row className={styles.rowMargin}>
     <Col>
      <Card className={styles.chartCard}>
        <Card.Body className={styles.chartCardBody}>
        <div className={styles.chartTitle}>Income</div>
        <IncomeCashFlowChart data={incomeDataSeries} categories={incomeCategories}/>
        </Card.Body>
      </Card>
     </Col>
      <Col>
      <Card className={styles.chartCard}>
        <Card.Body className={styles.chartCardBody}>
        </Card.Body>
      </Card>
      </Col>
   </Row> */}
   </div>
 )
}
export default StocksMoreChart
