import React, {useEffect, useState} from 'react'
import styles from '../OtherCountryAccount/otherCountryAccount.module.css'
import stylesheet from './Fairexpay.module.css'
import ProgressBar from './ProgessBar'
import { Row, Col } from 'react-bootstrap'
import {getQuote,fairexpayIntiateTransaction,getBrokerDetail,getA2Form} from '../../api'
import downloadImage from '../../public/images/Download.svg'

const A2Upload = ({
	setcurrent,
  next,
	buttonColor,
  buttonTitle,
  hashId
}) => {

 const [quoteData,setQuoteData] = useState([])
 const [loading,setLoading] = useState(true)
 const [brokerDetail,setBrokerDetail] = useState({})
 const [info,setinfo]= useState([])
 const [a2FormLink,seta2FormLink] = useState('')


  const asyncFetch = async () => {
    setLoading(true)
    try{
    const response = await getQuote(hashId)
    const res = await getBrokerDetail()
    const res1 = await getA2Form(hashId)
    if(res.code === 200){
      setBrokerDetail(res.data)
    }
    if(res1.code === 200){
      seta2FormLink(res1.data)
    }
    if(response.code === 200 && response.data){
      setQuoteData(response.data)
    }
  }catch(error){
    console.log('something went wrong')
  }
  setLoading(false)
  }

  useEffect(() => {
    asyncFetch()
  },[])

const {quoteDetail = {} ,partnerData=[],amount = ''} = quoteData
const partner = partnerData.filter((item) => item.partnerid === 20)

const initiateTrans = async() => {
  setLoading(true)
  try{
    const response = await fairexpayIntiateTransaction(hashId)
    if(response.code !== 200){
      setLoading(false)
    }
  }catch(error){
    setLoading(false)
    console.log(error)
  }
}



useEffect(() => {
 const update = [
    {'Amount':amount},
    {
      'From Currency': quoteDetail ?  quoteDetail.sourceCurencyCode : '--'
    },
    {
      'To Currency': quoteDetail ? quoteDetail.targertCurencyCode : '--'
    },
    {
      'Beneficiary Name':
        brokerDetail && brokerDetail.beneficiaryName
          ? brokerDetail.beneficiaryName
          : '--'
    },
    {
      'Beneficiary Address':
        brokerDetail && brokerDetail.beneficiaryAddress
          ? brokerDetail.beneficiaryAddress
          : '--'
    },
    {
      Country:
        brokerDetail && brokerDetail.country ? brokerDetail.country : '--'
    },
    {
      'Beneficiary Contact number':
        brokerDetail && brokerDetail.beneficiaryContactNumber
          ? brokerDetail.beneficiaryContactNumber
          : '--'
    },
    {
      'Beneficiary Bank Name':
        brokerDetail && brokerDetail.beneficiaryBankName
          ? brokerDetail.beneficiaryBankName
          : '--'
    },
    {
      'Beneficiary Bank Address:':
        brokerDetail && brokerDetail.beneficiaryBankAddress
          ? brokerDetail.beneficiaryBankAddress
          : '--'
    },
    {
      'Beneficiary Account Number:':
        brokerDetail && brokerDetail.beneficiaryAccountNumber
          ? brokerDetail.beneficiaryAccountNumber
          : '--'
    },
    {
      'SWIFT Code of Beneficiary bank':
        brokerDetail && brokerDetail.beneficiarySwiftCode
          ? brokerDetail.beneficiarySwiftCode
          : '--'
    },
    {
      'ABA number':
        brokerDetail && brokerDetail.swiftABANumber
          ? brokerDetail.swiftABANumber
          : '--'
    },
    {
      'Sender to Receiver Information/Remarks':
        brokerDetail && brokerDetail.remarks ? brokerDetail.remarks : '--'
    },
    {
      'Expected Curreny Exchange Rate*': partner  && partner.length ?  partner[0].reverseExchangeRate.toFixed(2) : '--'
    }
  ]
  setinfo(update)
},[brokerDetail,amount])



	return (
		<div className={stylesheet.A2UploadCard}>
			{/* <Row> */}

      <Row className={stylesheet.borderBottom}>
				<Col md={10} className='pr-0'>
					<ProgressBar steps='5' current={3} color='rgb(0, 93, 214)' status=' Transfer funds'/>
				</Col>
			</Row>
        <Col md={12} className={`mt-2 mb-2 pl-0 ${styles.key}`}>
        Congratulations!!! You have successfully signed the A2 and declaration form . You would have received the completed form in your registered email Id.
        </Col>
				<Col style={{ marginTop: '33px' }} className='pl-0'>
					<div className={stylesheet.review}>Review</div>
				</Col>
      <Row>
      <Col md={9} className={styles.otherDiv}>
        {info.map((item,i) => {
          return(
            <Row
            key={i}
            style={{
              marginTop: '16px',
              paddingBottom: '16px',
              borderBottom: '1px solid #EFEFF1'
            }}>
            <Col style={{ padding: '0px' }} >
              <div className={styles.key}>{Object.keys(item)[0]}</div>
            </Col>
            <Col style={{ textAlign: 'end', padding: '0px' }}>
              <div className={styles.value}>{Object.values(item)[0]}</div>
            </Col>
          </Row>
          )
        })}
         <Row className={stylesheet.noteitalic} style={{marginBottom:'150px'}}>* There might be some variation in currency exchange rate depending on the interbank exchange rate variations</Row>
        </Col>
      </Row>
			<Row className={styles.fixedDiv}>
				<Col>
					<button
						onClick={() => {
              initiateTrans()
              //call /:id/initiateTrans
              // setcurrent(next)
            }}
            disabled={loading}
						type='button'
						style={{ backgroundColor: buttonColor }}
						className={`btn btn-primary btn-sm ${styles.addfunds} themeButton`}>
						<span className={styles.addfundstext}>
							{buttonTitle}
						</span>
					</button>
				</Col>
			</Row>
		</div>
	)
}

export default A2Upload
