import React from 'react'
import styles from './withDrawFund.module.css'
import { Row, Col } from 'react-bootstrap'
import noteImage from '../../public/images/note.png'

const WithDrawFund = ({ setModalData, data, title, buttonColor }) => {
	return (
		<div className={styles.card}>
			<Row>
				<Col md={12}>
					<div className={styles.title}>{title}</div>
				</Col>
				<Col md={9}>
					<div className={styles.description}>Select bank account</div>
				</Col>
				<Col md={12}>
					<select className={styles.inputAnkit}>
						<option>Ankit Mishra</option>
					</select>
				</Col>
				<Col md={12}>
					<div className={styles.otp}>Enter Amount</div>
				</Col>
				<Col md={12}>
					<input
						placeholder='$ Enter Withdrawl amount'
						className={styles.inputAnkit}
					/>
				</Col>
				<Col md={10} style={{ marginTop: '16px' }}>
					<img src={noteImage} />
					<span className={styles.business}>
						$35 withdrawl fee is charged and funds will be credited in 1-4
						business days.
					</span>
				</Col>
				<Col md={12} style={{ textAlign: 'center' }}>
					<button
						className={`${styles.verify} themeButton`}
						style={{ backgroundColor: buttonColor }}>
						Withdraw Funds
					</button>
				</Col>
			</Row>
		</div>
	)
}

export default WithDrawFund
