import React, { useState, useEffect, useRef } from 'react'
import styles from './fundAmount.module.css'
import { Row, Col } from 'react-bootstrap'
import { postFund } from '../../api'
import queryString from 'query-string'
import { useSelector } from 'react-redux'

const FundAmount = ({
  setModalData,
  data,
  setFund,
  fund,
  title,
  withdrawClicked,
  otp,
  hashId,
  buttonColor,
  setHashId,
  fundApi,
  withdrawFund,
  setWithdrawFund,
  errorWithdrawAmount,
  setAdditionalInstructions,
  withdrawLoading,
  stringCollection,
  FundsForWithdraw
}) => {

  const {partner = {}} = JSON.parse(sessionStorage.getItem('userInfo'))
  const easyFundTransfer = true

  const [loading, setLoading] = useState(false)
  const [touch, setTouch] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const editFieldRef = useRef(null);

  const handleChange = e => {
    let minAmount = 50;
    if(easyFundTransfer){
      minAmount=1
    }
    let re = /^[0-9]+$/
    if (withdrawClicked) {
      minAmount = 1
      re = /^[0-9]*\.?[0-9]*$/
    }
    e.target.value !== '' && parseInt(e.target.value) >= minAmount
        ? setCheck(false) : setCheck(true, setFlag(false))

    if (e.target.value === '' || re.test(e.target.value)) {
      setFund(e.target.value)
    }
  }

  const [check, setCheck] = useState(true)
  const [flag, setFlag] = useState(true)

  useEffect(() => {

    let queryUrl = queryString.parse(window.location.search, {
			parseNumbers: false
    })
		if (queryUrl.modal && queryUrl.bank === 'other') {
      setModalData(stringCollection.fairexpay)
		}

    editFieldRef.current.focus()
    fund === '' ? setCheck(true) : setCheck(false)

    if(!withdrawClicked){
      setFund(100);
      setCheck(false);
    }
  }, [])

  const handleTouch = () =>{
    setTouch(true)

  }
  // add funds ==== API
  const asynchFetch = async value => {
    setLoading(true)
    try {
        const res = await postFund(fund)
        if (res && res.code === 200 && res.data.hashId) {
          //returns hashId
          setHashId(res.data.hashId, setModalData(value))
        }
        else {
          setErrorMessage('Something Went Wrong')
        }
    } catch (error) {
      setErrorMessage('Something Went Wrong')
    }
    setLoading(false)
  }

  const keyPressHandler = (event) => {
    if (event.which === 13) {
      asynchFetch(data)
    }
  }



  return (
    <div className={`${styles.card} ${styles.fundAmountContainer}`}>
      <Row>
        <Col className='d-none d-md-block'>
          <div className={styles.title}>{title}</div>
        </Col>
        <Col style={{ textAlign: 'center' }} className='d-block d-md-none'>
          <div className={styles.mobileTitle}>{title}</div>
        </Col>
      </Row>
      <Row className={styles.responsiveEnter}>
        <Col md={12} style={{ textAlign: 'center' }}>
          <div className={styles.transferred}>Amount to be {withdrawClicked ? 'withdrawn' : 'transferred'} </div>
          {/* for desktop */}
          <div
            className={`${styles.inputResponsive} d-none d-md-block`}>
           <span className={styles.dollar}>$</span>
            <span style={{ display: 'inline-block' }}>
              <input
                className={styles.input}
                placeholder='100'
                onChange={handleChange}
                value={fund}
                tabindex='1'
                ref={editFieldRef}
                onKeyPress={keyPressHandler}
              />
            </span>
          </div>
          {withdrawClicked && FundsForWithdraw === '$0' ?
          <div className={styles.erorrMessage}>
            You do not have sufficient funds to withdraw
          </div> : ""}

          {/* for mobile */}
          <div
            className={`${styles.inputResponsive} d-block d-md-none`}>
            <span className={styles.dollar} style={{ position: 'static' }}>$</span>
            <span style={{ display: 'inline-block' }}>
              <input
                className={styles.input}
                placeholder='100'
                onChange={handleChange}
                onTouchMove={()=>handleTouch()}
                value={fund}
                tabindex='1'
                ref={editFieldRef}
                onKeyPress={keyPressHandler}
              />
            </span>
          </div>

          {withdrawClicked && FundsForWithdraw === '$0' ?
          <div className={styles.MobileErorrMessage}>
            You do not have sufficient funds to withdraw
          </div> : ""}

          {/* {withdrawClicked ? '' :
            <div className={styles.minimum}>
              Minimum amount recommended: <span className={styles.fifty}>$50</span>
            </div>
          } */}
          <div style={{ textAlign: 'center' }}>
                {/* <
                  input
                  className={styles.addInstWithdraw}
                  placeholder='Additional instructions...'
                  onChange={e => {
                    setAdditionalInstructions(e.target.value)
                  }}
                  name='additionalInstruction'
                /> */}
                <div className={styles.oneTime} style={{ marginTop: '30px' }}>
                  {withdrawClicked ? 'Service charges of $10 will be applicable on every withdrawal' :
                    '$5 one-time fee deducted for W8-ben form'
                  }
                </div>
          </div>
        </Col>
      </Row>
      {
        withdrawClicked ?
          errorWithdrawAmount === '' ? '' :
            <div className={styles.erorrMessage}>{errorWithdrawAmount}</div> :
          errorMessage === '' ? '' :
            <div className={styles.erorrMessage}>{errorMessage}</div>
      }
      <Row className={styles.fixedDiv}>
        <Col>
        {FundsForWithdraw === '$0' ?
          <button
          type='button'
          style={{ backgroundColor: buttonColor }}
          className={`btn btn-primary btn-sm ${styles.addfunds} themeButton`}
          disabled={true}
          >
          <span className={styles.addfundstext}>Continue</span>
          </button>
        :
          <button
            type='button'
            style={{ backgroundColor: buttonColor }}
            className={`btn btn-primary btn-sm ${styles.addfunds} themeButton`}
            disabled={ withdrawClicked ? withdrawLoading || check : check || loading}
            onClick={() => withdrawClicked ? fundApi() : asynchFetch(data)}
          >
            <span className={styles.addfundstext}>Continue</span>
          </button> }
        </Col>
      </Row>
    </div>
  )
}

export default FundAmount
