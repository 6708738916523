import React, { useEffect, useState } from 'react'
import { Row, Col, Container, Modal, Button } from 'react-bootstrap'
import styles from './Reports.module.css'
import { Footer,Header,Report } from '../../components'

const Reports = () => {
    // const [monthlyModalShow, setMonthlyModalShow] = useState(false);
    // const [dividendModalShow, setDividendModalShow] = useState(false);
    // const [financialModalShow, setFinancialModalShow] = useState(false);
    // const [tradeModalShow, setTradeModalShow] = useState(false);
    // const [lrsModalShow, setLrsModalShow] = useState(false);
    // const [profitModalShow, setProfitModalShow] = useState(false);


    // const [modalValue, setValue] = useState();
    // const handleClose = () =>
    // setProfitModalShow(false);
    // setMonthlyModalShow(false);

    // const handleShow = (msg) => {
    // if(msg === "Monthly Account Statement"){
    //     setMonthlyModalShow(true);
    // }else if(msg === "Dividend Tax Statement-1042s" ){
    //     console.log('Dividend Tax Statement-1042s')
    // }else if(msg === "P&L" ){
    //     setProfitModalShow(true);
    // }
    // }

    return(<>

			<Header />


        <Container className={styles.marginConatiner}>
        <Report/>
        {/* <Row className={styles.ReportRow}>
            <Col md={4}>
                <div className={styles.ReportCard}>
                    <p className={styles.ReportHeading}>Monthly Account Statement</p>
                    <p className={styles.ReportSubHeading}>Official account statement for this account.</p>
                    <button className={styles.ReportButton} onClick={()=>setMonthlyModalShow(true)}><span>Run Report</span></button>
                </div>
            </Col>
            <Col md={4}>
                <div className={`mx-auto ${styles.ReportCard}`}>
                    <p className={styles.ReportHeading}>Dividend Tax Statement-1042s</p>
                    <p className={styles.ReportSubHeading}>Official US tax forms for this account.</p>
                    <button className={styles.ReportButton} onClick={()=>setDividendModalShow(true)}><span>Run Report</span></button>
                </div>
            </Col>
            <Col md={4}>
                <div className={styles.ReportCard}>
                    <p className={styles.ReportHeading}>Financial statement</p>
                    <p className={styles.ReportSubHeading}>Financial statements for past years can be found here.</p>
                    <button className={styles.ReportButton} onClick={()=>setFinancialModalShow(true)}><span>Run Report</span></button>
                </div>
            </Col>
        </Row>

        <Row className={styles.SecondReportRow}>
            <Col md={4}>
                <div className={styles.ReportCard}>
                    <p className={styles.ReportHeading}>Trade Confirmation</p>
                    <p className={styles.ReportSubHeading}>Trade confirmation statement for this account.</p>
                    <button className={styles.ReportButton} onClick={()=>setTradeModalShow(true)}><span>Run Report</span></button>
                </div>
            </Col>
            <Col md={4}>
                <div className={`mx-auto ${styles.ReportCard}`}>
                    <p className={styles.ReportHeading}>P&L</p>
                    <p className={styles.ReportSubHeading}>Provides names, account numbers, account type and BOD cash balance.</p>
                    <button className={styles.ReportButton}  onClick={()=>setProfitModalShow(true)}><span>Run Report</span></button>
                </div>
            </Col>
            <Col md={4}>
                <div className={styles.ReportCard}>
                    <p className={styles.ReportHeading}>LRS Details</p>
                    <p className={styles.ReportSubHeading}>LRS Details</p>
                    <button className={styles.ReportButton} onClick={()=>setLrsModalShow(true)}><span>Run Report</span></button>
                </div>
            </Col>
        </Row>

        <MonthlyModal handleClose={handleClose}
        show={monthlyModalShow}/>
        <ProfitModal handleClose={handleClose}
        show={profitModalShow}/> */}


        {/* <div className={styles.footerMargin}>
            <Footer />
        </div> */}
        </Container>
        </>
    )
}

export default Reports
