import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import store from '../src/store/configureStore'
import { Provider } from 'react-redux'
import './axios.config'
import { BrowserRouter } from 'react-router-dom'
import ThemeContextProvider from './Context/ThemeContext'
import * as serviceWorker from './serviceWorker'
import './public/css/overlayLoding.css'
// import ErrorBoundary from './ErrorBoundary'

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<React.StrictMode>
				<ThemeContextProvider>
          {/* <ErrorBoundary> */}
					  <App />
          {/* </ErrorBoundary> */}
				</ThemeContextProvider>
			</React.StrictMode>
		</BrowserRouter>
	</Provider>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
