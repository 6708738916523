import React from 'react'
import InputRange from 'react-input-range'
import 'react-input-range/lib/css/index.css'
import './RangeSlider.css'
import {addCommasinPrice} from '../../utils'
import styled from 'styled-components';
import styles from './slider.module.css';

const RangeSliderContainer = styled.div`
  .input-range__track--active{
    background: ${props => props.color};
    border-color: ${props => props.color};
  }
`;

const RangeSlider = ({value,onChange,color,defaultValue}) => {
  return(
    <RangeSliderContainer color={color}>
      <div className={styles.widthSlider}>
        <span className={styles.minSliderValue}>
          ${value.min}
        </span>
        <span className={styles.maxSliderValue}>
          ${value.max.toFixed(0)}
        </span>
      </div>
      <InputRange
        maxValue={defaultValue.max}
        minValue={defaultValue.min}
        formatLabel={value => `$${addCommasinPrice(value)}`}
        value={value}
        activeTrack={color}
        onChange={(value) => onChange(value)}
      />
    </RangeSliderContainer>
  )
}

// class RangeSlider extends React.Component {
// 	state = {
// 		value: {
// 			min: 5,
// 			max: 10
// 		}
// 	}

// 	render() {
// 		return (
// 			<InputRange
// 				maxValue={20}
// 				minValue={0}
// 				formatLabel={value => `${value}$`}
// 				value={this.state.value}
// 				onChange={value => this.setState({ value: value })}
// 			/>
// 		)
// 	}
// }

export default RangeSlider
