import { getUserInvestmentHistory } from '../../api'
import { USER_INVESTMENT } from './action'

const getUserInvestment = () => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			getUserInvestmentHistory().then(response => {
				if (response && response.code === 200 && response.data) {
					resolve(dispatch({ type: USER_INVESTMENT, payload: response.data }))
				}
			})
		})
	}
}

export default getUserInvestment
