import React from 'react'
import styles from '../Thankyou/thankYou.module.css'
import { Row, Col } from 'react-bootstrap'
import FundTransfer from '../../public/images/addFund.png'

const Complete = () => {
	return (
		<div className={styles.card}>
			<Row style={{ textAlign: 'center', marginTop: '120px' }}>
				<Col  xs={11} sm={10} md={12} lg={12}>
          <div>
            <img src={FundTransfer}></img>
          </div>
					<div className={styles.title}>
          Fund transfer initiated
					</div>
          <div className={styles.subTitle}>Rest assured, funds will reflect in your account in 2-3 business days</div>
				</Col>
			</Row>
		</div>
	)
}

export default Complete
