import { getMarketTime } from '../../api'
import { MARKET_DETAILS } from './action'

const getMarketDetails = () => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			getMarketTime(new Date().toISOString()).then(response => {
				if (response && response.code === 200 && response.data) {
					resolve(dispatch({ type: MARKET_DETAILS, payload: response.data }))
				}
			})
		})
	}
}

export default getMarketDetails
