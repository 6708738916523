import React, { useState, useRef, useEffect, useContext } from 'react'
import { Row, Col, Form, Card } from 'react-bootstrap'
import { Formik } from 'formik'
import * as yup from 'yup'
import styles from './UpdateApplication.module.css'
import { ThemeContext } from '../../Context/ThemeContext'
import SignUpInput from '../SignUp/SignUpInput'
import SignUpButton from '../SignUp/SignUpButton'
import USerSvg from '../../public/images/newOnboarding/User.svg'
import NewHeader from '../NewSignupComponents/NavBar/NewHeader'
import { useHistory } from 'react-router-dom'
import OnboardingFooter from '../Footer/OnboardingFooter'
import Logo from '../../public/images/newlogo.svg'

const UpdateCashName = () => {
	if (sessionStorage.getItem('currentSignUp'))
		sessionStorage.removeItem('currentSignUp')
	//let history = useHistory()
	//const { shortLogo } = useContext(ThemeContext)
	//const [showVerify, setShowVerify] = useState(false)
	const [errormessage, setErrormessage] = useState('')
	//const [hashID, setHashID] = useState('')
	const [firstname, setFirstName] = useState('')
	const [middlename, setMiddleName] = useState('')
	const [lastname, setLastName] = useState('')
	//const [otpSendMessage, setOtpSendMessage] = useState('')
	const [loading, setLoading] = useState(false)
	const editFieldRef = useRef(null)
	const formInitialValues = {
		firstname,
		middlename,
		lastname
	}
	const { isPartner } = useContext(ThemeContext)
	const showPoweredLogo =
		isPartner && !window.location.href.includes('landing-page')

	useEffect(() => {
		editFieldRef.current.focus()
	}, [])

	const validationSchema = yup.object({
		firstname: yup
			.string()
			.trim()
			// .matches(/^[a-zA-Z0-9_.@]*$/ , 'Only Alphabets, numbers, _ , @ and . are allowed in Username.')
			.required('Firstname is required '),
		lastname: yup
			.string()
			.trim()
			// .matches(/^[a-zA-Z0-9_.@]*$/ , 'Only Alphabets, numbers, _ , @ and . are allowed in Username.')
			.required('Lastname is required ')
	})

	const keyPressHandler = (event, submitCallback) => {
		if (event.which === 13) {
			submitCallback()
		}
	}

	return (
		<>
			<NewHeader
				message=''
				linkName='Do it Later'
				//onClickLink={() => history.push('/signin')}
			/>
			<div className={styles.forgotPasswordContainer}>
				<div className={styles.cardContainer}>
					<Card className={styles.cardSignUp}>
						<Card.Body className={styles.cardBodySignup}>
							<>
								<Row className={styles.title}>Update Application</Row>
								<Row className={styles.subTitle}>
									You need to update your passport or name. Make sure entered
									name matches the name in document
								</Row>
								<Formik
									initialValues={formInitialValues}
									validationSchema={validationSchema}
									onSubmit={async values => {
										// try {
										// 	callAPI('forgotpass')
										// } catch (error) {
										// 	console.log(error)
										// }
									}}>
									{({
										handleChange,
										handleBlur,
										dirty,
										handleSubmit,
										values,
										touched,
										errors
									}) => {
										return (
											<div>
												<Row className={styles.inputs}>
													<SignUpInput
														label='First Name'
														placeholder='Enter Your First Name'
														type='text'
														name='username'
														refValue={editFieldRef}
														tabIndex='1'
														value={values.firstname.toLowerCase()}
														onChange={e => {
															setErrormessage('')
															setFirstName(e.target.value.toLowerCase())
															handleChange(e)
														}}
														onKeyPress={e => keyPressHandler(e, handleSubmit)}
														//onBlur={event => handleBlur(event)}
														isInvalid={
															(touched.firstname && errors.firstname) ||
															errormessage
														}
														// isValid={
														// 	touched.firstname &&
														// 	!errors.firstname &&
														// 	errormessage === ''
														// }
														errorMessage={errors.firstname}
														onPaste={e => {
															e.preventDefault()
															return false
														}}
														onCopy={e => {
															e.preventDefault()
															return false
														}}
														//icon={USerSvg}
													/>
												</Row>

												<Row className={styles.inputs}>
													<SignUpInput
														label='Middle Name (Optional)'
														placeholder='Enter Your Middle Name'
														type='text'
														name='middlename'
														tabIndex='1'
														value={values.middlename.toLowerCase()}
														onChange={e => {
															setErrormessage('')
															setMiddleName(e.target.value.toLowerCase())
															handleChange(e)
														}}
														onKeyPress={e => keyPressHandler(e, handleSubmit)}
														//onBlur={event => handleBlur(event)}
														isInvalid={
															(touched.middlename && errors.middlename) ||
															errormessage
														}
														// isValid={
														// 	touched.middlename &&
														// 	!errors.middlename &&
														// 	errormessage === ''
														// }
														errorMessage={''}
														onPaste={e => {
															e.preventDefault()
															return false
														}}
														onCopy={e => {
															e.preventDefault()
															return false
														}}
														//icon={USerSvg}
													/>
												</Row>

												<Row className={styles.inputs}>
													<SignUpInput
														label='Last Name'
														placeholder='Enter Your Last Name'
														type='text'
														name='lastname'
														tabIndex='1'
														value={values.lastname.toLowerCase()}
														onChange={e => {
															setErrormessage('')
															setLastName(e.target.value.toLowerCase())
															handleChange(e)
														}}
														onKeyPress={e => keyPressHandler(e, handleSubmit)}
														//onBlur={event => handleBlur(event)}
														isInvalid={
															(touched.lastname && errors.lastname) ||
															errormessage
														}
														// isValid={
														// 	touched.lastname &&
														// 	!errors.lastname &&
														// 	errormessage === ''
														// }
														errorMessage={errors.lastname}
														onPaste={e => {
															e.preventDefault()
															return false
														}}
														onCopy={e => {
															e.preventDefault()
															return false
														}}
														//icon={USerSvg}
													/>
												</Row>

												<Row className={styles.buttonMargin}>
													<SignUpButton
														classes={styles.buttonSignUP}
														title='Save And Continue'
														onClick={handleSubmit}
														loading={loading}
													/>
												</Row>
											</div>
										)
									}}
								</Formik>
							</>
						</Card.Body>
					</Card>
				</div>
			</div>
			<div
				style={{
					marginTop: '50px',
					display: 'flex',
					justifyContent: 'center'
				}}>
				{showPoweredLogo ? (
					<Col
						md={4}
						xl={4}
						lg={4}
						style={{ textAlign: 'center' }}
						className='d-none d-md-block'>
						<span className={styles.poweredText}>Powered by</span>
						<img src={Logo} alt='powered by' width='64px' height='16px' />
					</Col>
				) : (
					<Col md={2} xl={3} className={`d-none d-md-flex`}></Col>
				)}
			</div>
			<OnboardingFooter />
			{/* </div> */}
		</>
	)
}

export default UpdateCashName
