import React, { useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import styles from './Notification.module.css'
import NoNotification from '../../public/images/NoNotifications.svg'
import moment from 'moment'



const notificationData = ({data}) => {

  // const currentDate = new Date();
  // const isoCurrentDate = currentDate.toISOString();

  // useEffect(() => {
  //   if(data.length > 0) {
  //     timeCalculate();
  //   }
  // },[])

  const timeCalculate = (e) => {
    if(data.length > 0){
    var newDiff =  moment(e).fromNow();
    if(newDiff === "a few seconds ago"){
      return "Just Now"
    }
    if(newDiff === "in a few seconds ago"){
      return "Just Now"
    }
    if(newDiff === "a minute ago"){
      return "Just Now"
    }
    if(newDiff === "an hour ago"){
      return "1 hour ago"
    }
    // var diff = moment.duration(moment(isoCurrentDate).diff(moment(e)));
    // var hours = diff._data.hours
    // var minutes = `${diff._data.minutes} min`
    else if(newDiff !== "a few seconds ago" && newDiff !== "in a few seconds ago" && newDiff !== "a minute ago") {
    return newDiff
    }
    } else{
      return '--'
    }
  }

		return (
			<React.Fragment>
			{data && data.length > 0 ? data.map((item, index) => {
        return(
          <div className={styles.notificationContainer}>
              <div className={styles.titleAndTime}>
                  <p className={styles.textBlack}>
                    {item.subject && item.subject.length > 50 ? `${item.subject.substring(0, 50)}...` : item.subject == null ? "" : item.subject}
                  </p>
                  <p className={styles.textGrey}>
                    {timeCalculate(item.createdAt)}
                  </p>
              </div>
              <p className={styles.textGrey}>
                {item.longMessage && item.longMessage.length > 80 ? `${item.longMessage}` : item.longMessage && item.longMessage !== null ? item.longMessage : item.longMessage == null ? '' : '' }
              </p>
          </div>
        )
        }) :
        <>
        </>
			}
			</React.Fragment>
		)
}


export default notificationData
