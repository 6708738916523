import React from 'react'
import styles from './ProfileCheckBox.module.css'

const ProfileCheckBox = ({ id, label, onChange, isChecked, name }) => {
	return (
		<div className={`custom-control profileCheckBox custom-checkbox ${styles.checkBox}`}>
			<input
				type='checkbox'
				className={`custom-control-input ${styles.checkBoxInput}`}
				id={id}
				name={name}
				onChange={onChange}
				checked={isChecked}
			/>
			<label
				className={`custom-control-label ${styles.ProfileCheckMark}`}
				htmlFor={id}>
				{label}
			</label>
		</div>
	)
}

export default ProfileCheckBox
