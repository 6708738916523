import React from 'react'
import SignUp from '../SignUp/SignUp'
import SignUpButton from '../SignUp/SignUpButton'
import styles from './review.module.css'
import questionSvg from '../../public/images/newOnboarding/question.svg'
import BasicButton from './BasicButton'
import cross from '../../public/images/newOnboarding/cross.svg'

const Popup = ({ onYesClick, show, onNoClick,onClose }) => {
	return show ? (
		<div className={styles.overlay}>
			<div className={styles.popup}>
				<div className={styles.popupHeader}>
					<span>
						<img src={questionSvg} style={{ paddingRight: '8px' }} />
						<span className={styles.popupHeading}>
							Do you have a last name?
						</span>
					</span>
					<img src={cross} onClick={onClose} style={{ cursor: 'pointer' }} />
				</div>
				<div className={styles.popupBody}></div>
				<div style={{ padding: '24px' }} className={styles.subTitle}>
					<div style={{ marginBottom: '24px' }}>
						We need to know your exact details for legal and security reasons.
						Please verify and make sure that the legal document submitted
						doesn’t have any Last Name mentioned. Is it mentioned?
					</div>

					<div style={{ display: 'flex', gap: '10px' }}>
						<BasicButton
							title='Yes'
							onClick={onYesClick}
							buttonColor='#FFFFFF'
						/>
						<SignUpButton title='No' onClick={onNoClick} />
					</div>
				</div>
			</div>
		</div>
	) : null
}

export default Popup
