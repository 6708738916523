import React from 'react'
import { Row, Col, Container, OverlayTrigger,Tooltip } from 'react-bootstrap'
import styles from './ExploreThemes.module.css'
import { Link } from 'react-router-dom'


const exploreThemes = ({ image, text }) => {

  const encodedText = encodeURIComponent(text)

	return (
		// <Container fluid>
    <>
    <Link to={`/theme/${encodedText}`}>
    <div className={styles.overlayImage}>
			{/* <Col md={4}> */}
			<img
      src={image}
      // style={{backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 62.03%, rgba(0, 0, 0, 0.5) 100%), url(${image})`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center'}}
      className={styles.image}></img>
			{/* </Col> */}
    </div>
    <OverlayTrigger
        placement='bottom'
        trigger={['hover', 'focus','click']}
        overlay={
          <Tooltip>
          {text}
          </Tooltip>
        }>
        <div className={styles.overText}>
        {text.length > 20 ? `${text.substring(0, 20)}...` : text}
        </div>
      </OverlayTrigger>
    </Link>
    </>
		// </Container>
	)
}

export default exploreThemes
