import React, { useContext } from 'react'
import styles from './onboardingFooter.module.css'
import { ThemeContext } from '../../Context/ThemeContext'
import hotline from '../../public/images/hotline.svg'
import email from '../../public/images/mail.png'

const OnboardingFooter = () => {
	const { siteDisclaimer, siteDisclaimerLink, contactMail } =
		useContext(ThemeContext)

	const { contactNumber } = useContext(ThemeContext)
	const hotlineSupport =
		sessionStorage.getItem('planInfo') &&
		JSON.parse(sessionStorage.getItem('planInfo')).details &&
		JSON.parse(sessionStorage.getItem('planInfo')).details.hotlineSupport
			? JSON.parse(sessionStorage.getItem('planInfo')).details.hotlineSupport
			: []
	let label,
		phone = ''
	if (
		hotlineSupport.length > 0 &&
		hotlineSupport[0].phone &&
		window.location.href.includes('document')
	) {
		phone = hotlineSupport[0].phone
		label = hotlineSupport[0].text ? hotlineSupport[0].text : ''
	} else {
		phone = contactNumber
		label = 'Hotline'
	}

	return (
		<>
			<div className={styles.footerContainer}>
				<div className={styles.footer}>
					<div className={styles.leftPart}>
						<span className={styles.footerRegular}>
							©Borderless Investing Inc.
						</span>
						<span style={{ marginLeft: '8px' }} className={styles.footerText}>
							All Rights Reserved {new Date().getFullYear()}
						</span>

						<span
							className={styles.footerRegular}
							style={{ marginLeft: '8px', cursor: 'pointer' }}>
							<a
								href={siteDisclaimerLink}
								target='_blank'
								rel='noopener noreferrer'>
								{' '}
								Disclaimer
							</a>
						</span>
					</div>
					<div>
						<span style={{ marginRight: '7px' }}>
							<img src={email} alt='email' />
						</span>
						<span className={styles.footerRegular}>
							Email ID: {contactMail}
						</span>
						<span style={{ marginLeft: '33px' }}>
							<img src={hotline} alt='hotline' />
						</span>
						<span
							className={styles.footerRegular}
							style={{ marginLeft: '7px' }}>
							{label}: {phone}
						</span>
					</div>
				</div>
				<div className={styles.mobileFooter}>
					<div
						className={styles.mobileLeft}
						style={{ display: 'flex', justifyContent: 'space-between' }}>
						<span>
							<span className={styles.footerText} style={{ color: '#3C404A' }}>
								Borderless Investing Inc. ©
							</span>
							<br />
							<span className={styles.footerText}>
								All Rights Reserved {new Date().getFullYear()}
							</span>
						</span>
						<span
							className={`${styles.footerText} ${styles.underline}`}
							style={{
								marginLeft: '8px',
								cursor: 'pointer'
							}}>
							<a
								href={siteDisclaimerLink}
								target='_blank'
								rel='noopener noreferrer'
								//style={{ color: '#3C404A' }}
							>
								Disclaimer
							</a>
						</span>
					</div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							marginTop: '20px'
						}}>
						<span>
							<span style={{ marginRight: '7px' }}>
								<img src={email} alt='email' />
							</span>

							<span className={styles.footerText}>Support Email</span>
						</span>
						<span
							className={`${styles.footerText} ${styles.underline}`}
							style={{ color: '#3C404A' }}>
							{contactMail}
						</span>
					</div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							marginTop: '14px'
						}}>
						<div>
							<span style={{ marginRight: '8px' }}>
								<img src={hotline} alt='hotline' />
							</span>
							<span className={styles.footerText}>{label}</span>
						</div>
						<span
							className={`${styles.footerText} ${styles.underline}`}
							style={{ color: '#3C404A' }}>
							{phone}
						</span>
					</div>
				</div>
			</div>
		</>
	)
}

export default OnboardingFooter
