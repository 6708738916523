import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import SkeletonElement from './SkeletonElement'
import Shimmer from './Shimmer'

const newsCardSkeleton = () => {
	return (
		<Card className='skeletonCard'>
			<Card.Body>
				<>
					<SkeletonElement width='220px' height='100px'></SkeletonElement>

					<SkeletonElement width='220px' height='50px'></SkeletonElement>

					<SkeletonElement width='220px' height='30px'></SkeletonElement>

					<Shimmer />
				</>
			</Card.Body>
		</Card>
	)
}

export default newsCardSkeleton
