import React, { useEffect, useState, useContext } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
// import InputLabel from '../../InputLabel/InputLabel'
import { ThemeContext } from '../../../Context/ThemeContext'
import styles from '../Profile.module.css'
import CustomModal from '../../../components/CustomModal/CustomModal'
import VerifyBasicOtp from './VerifyOTP'
// import EditBasicDetail from '../EditUserBasicDetail/EditBasicDeatail'
import EditPersonalData from '../EditUserBasicDetail/EditPersonalDetail'
import moment from 'moment'
// import { capitalize } from 'lodash'

const PersonalDetail = props => {
	const { profileData } = props
	const [isEdit, setIsEdit] = useState(false)
	const [dateOfBirth, setDateOfBirth] = useState('--')
	const [martialStatus, setMartialStatus] = useState('')
	const [gender, setGender] = useState('')
	const [showModal, setShowModal] = useState(false)
	const [isVerifyOtp, setIsVerifyOtp] = useState(false)
  const [getOtp, setOtp] = useState('')
  const [politicallyExposed, setPoliticallyExposed] = useState('--')
  const [politicallyExposedNames, setPoliticallyExposedNames] = useState('--')
	const { buttonColor } = useContext(ThemeContext)

  const setDetails=(profileData)=>{
    setDateOfBirth(
			profileData['dob'] ? moment(profileData['dob']).format('MMMM D, Y') : '--'
		)
		setMartialStatus(
			profileData['marital'] ? capitalize(profileData['marital']) : '--'
		)
    setGender(profileData['gender'] ? capitalize(profileData['gender']) : '--')
    if(profileData['politicallyExposedNames'] && profileData['politicallyExposedNames'].trim()){
      setPoliticallyExposed(true)
      setPoliticallyExposedNames(profileData['politicallyExposedNames'])
    }else{
      setPoliticallyExposed(false)
      setPoliticallyExposedNames('')
    }
  }

	useEffect(() => {
		setDetails(profileData);
	}, [profileData])

	function capitalize(str) {
		const arrOfWords = str.split(' ')
		const arrOfWordsCased = []
		for (let i = 0; i < arrOfWords.length; i++) {
			const word = arrOfWords[i]
			arrOfWordsCased.push(word[0].toUpperCase() + word.slice(1).toLowerCase())
		}
		return arrOfWordsCased.join(' ')
	}

	const onSave = () => {
		setIsEdit(false, setIsVerifyOtp(false))
	}

	const onEdit = () => {
		setShowModal(true)
		setIsEdit(true)
    props.sendOtpApi()
	}

	// const onVerifyOtp = () => {
	// 	setIsVerifyOtp(true)
	// }

	const handleClose = () => {
		setShowModal(false, setIsVerifyOtp(false))
	}
	return (
		<>
			{isEdit ? (
				<CustomModal className={styles.customModalPadding} show={showModal} handleClose={handleClose}>
					{isVerifyOtp ? (
						<EditPersonalData
              sendOtpApi={props.sendOtpApi}
              profileData= {profileData}
							dateOfBirth={dateOfBirth}
							martialStatus={martialStatus}
							politicallyExposed={politicallyExposed}
							politicallyExposedNames={politicallyExposedNames}
							gender={gender}
							handleClose={handleClose}
							onSave={onSave}
							userID={profileData['userID']}
							setIsVerifyOtp={setIsVerifyOtp}
							setIsEdit={setIsEdit}
              buttonColor={buttonColor}
              setPersonalDetails={setDetails}
              otp={getOtp}
              hashId={props.hashId}
						/>
					) : (
						<VerifyBasicOtp
							profile={'edit your profile'}
							onVerifyOtp={setIsVerifyOtp}
							hashId={props.hashId}
							getOtp={setOtp}
							buttonColor={buttonColor}
							sendOtpApi={onEdit}
						/>
					)}
				</CustomModal>
			) : (
				''
			)}
			<Row className={styles.basicDetail}>
				<Col className={styles.basicDetailText}>Personal details</Col>
        {profileData['accountStatus'] && profileData['accountStatus'] == 3 || profileData['accountStatus'] == 1 ? "" :
        <>
        {/* {profileData && profileData['accountStatus'] == 1 && profileData['nextStep'] !== 'COMPLETE'  ? "" : */}
				<Col style={{ textAlign: 'end' }}>
					<Button className={styles.editButton} onClick={onEdit}>
						<img
							src={require('../../../public/images/edit.svg')}
							className='mr-1' alt="edit"></img>
						Edit
					</Button>
				</Col>
        {/* } */}
        </>}
			</Row>
			<Row className={`m-0 mt-2 ${styles.borderBottom}`}>
				<Col md={4} xs={6} className='mb-3 mt-3'>
					<div className={styles.label}>Date of birth</div>
					<div className={styles.value}>{dateOfBirth}</div>
				</Col>
				<Col md={4} xs={6} className='mb-3 mt-3'>
					<div className={styles.label}>Marital status</div>
					<div className={styles.value}>{martialStatus}</div>
				</Col>
				<Col md={4} xs={6} className='mb-3 mt-3'>
					<div className={styles.label}>Politically exposed</div>
					<div className={styles.value}>
						{politicallyExposed === true ? (
							<>
								{' '}
								Yes{' '}
								<span className={styles.profileExposeName}>
									{' '}
            ,{' '}{politicallyExposedNames}
								</span>
							</>
						) : (
							'No'
						)}
					</div>
				</Col>
				<Col md={4} xs={6} className='mb-3 mt-3'>
					<div className={styles.label}>Gender</div>
					<div className={styles.value}>{gender}</div>
				</Col>
			</Row>

			{/* <Row className={`m-0 mt-4 ${styles.borderBottom}`}>
					<Col>
						<div className={styles.label}>Gender</div>
						<div className={styles.value}>{gender}</div>
					</Col>
				</Row> */}
		</>
	)
}

export default PersonalDetail
