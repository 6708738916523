import React, { useState, useEffect } from 'react'
import { Card, Row, Col, Button, Modal } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { getPortfolioDetailAllocations, getLivePrice } from '../../api'
import { StocksWeightsTable } from '../index'
import CustomModal from '../CustomModal/CustomModal'
import { useSelector, useDispatch } from 'react-redux'
import { getMarketDetails } from '../../store/actions/index'
import { isNumber, keyBy, filter } from 'lodash'
import { CORPORATE_FUNDS, PORTFOLIO_HEADERS, paths } from '../../constants'
import { decimalAdjust, calPriceChange, isMarketOpen, addCommasinPrice } from '../../utils'
import styles from './PortfolioTable.module.css'
import Open from '../../public/images/withdraw.svg'
import PortfolioDisableSell from '../../public/images/portfolioDIsableSell.svg'
import { SELL_ALREADY_IN_PROGRESS, SELL_REBALANCING, STACK_DELISTED_MSG } from '../../displayMessages'
import StackSell from '../StackSell/StackSell'
import {getLivePricesFromTicker} from '../../common'

const {
  stack,
  stock,
  etf,
  logo,
  name,
  price,
  change,
  totalInvestment,
  totalReturn,
  totalReturnPer,
  currentValue,
  totalUnit,
  averageCost,
  tos
} = PORTFOLIO_HEADERS;

const PortfolioTableCard = ({
  title,
  logo,
  code,
  shares = '',
  price,
  avgCost = '',
  totalInvested,
  totalReturn,
  totalRetPer,
  currentValue,
  change,
  rowHeaders,
  isStackActive,
  changePer,
  item
}) => {
  const [isLoaded, setIsloaded] = useState(true)
  const [open, setOpen] = useState(false)
  const [live, setLive] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [data, setData] = useState([])
  const [loadingEtf, setLoadingEtf] = useState(true)
  const [loadingNonEtf, setLoadingNonEtf] = useState(true)
  const [apiLoading, setApiLoading] = useState(true)
  const [showMobileModal, setShowMobileModal] = useState(false)
  const dispatchAction = useDispatch()
  const history = useHistory();
  const state = useSelector(state => state)
  const {
    marketDetails: { marketDetails: { currentMarketStatus = {} } = {} } = {}
  } = state
  let stockdata = [];
  let etfdata = [];
  let sellDisabledMessage = '';
  let buyDisabledMessage = '';
  const stockheaders = ['Stocks', 'Price', 'Shares', 'Invested Amt.']
  const etfheaders = ['ETF', 'Price', 'Shares', 'Invested Amt.']

  Object.keys(state.marketDetails).length === 0 &&
    dispatchAction(getMarketDetails())

  const handleViewAllocation = async () => {
    let response = await getPortfolioDetailAllocations(code)
    response && response.length > 0 && setData(response)
    setApiLoading(false);
  }

  const handleLiveApi = async data => {
    data = data.map(trav => trav.ticker)
    let response = await getLivePricesFromTicker(data)
    setLive(keyBy(response, 'symbol'))
  }

  useEffect(() => {
    data && data.length > 0 && handleLiveApi(data)
  }, [data])

  if (data && data.length > 0 && live && Object.keys(live).length > 0) {
    data.map(trav => {
      let { lastTrade, priorClose, close } = live[trav.ticker];
      lastTrade = isMarketOpen(currentMarketStatus) ? lastTrade : close;
      let { change, changePer } = calPriceChange({
        currPrice: lastTrade,
        close: close || trav.close,
        priorClose: priorClose || trav.priorClose,
        isMarketOpen: isMarketOpen(currentMarketStatus)
      });
      const obj = {
        image: trav.logo ? trav.logo : '',
        name: trav.ticker ? trav.ticker : '',
        creator: trav.company ? trav.company : '',
        price: lastTrade,
        pricePercentage: changePer,
        amount: trav.totalInvestment ? trav.totalInvestment : '',
        shares: trav.qty ? trav.qty : '',
        isImgLoaded: true,
      }
      if (trav.category !== 'etf') {
        stockdata = [...stockdata, obj]
      } else {
        etfdata = [...etfdata, obj]
      }
    })
  }

  const link = title === 'Stocks' ? 'stockdetail' : title === 'Stacks' ? 'stackdetail' : title === 'ETFs' ? 'etfdetail' : ''

  const handleClose = () => setShowModal(false)
  const handleShow = () => setShowModal(true, handleViewAllocation())
  const handleMobileClose = () => setShowMobileModal(false)
	const handleMobileShow = () => setShowMobileModal(true)


  const goToDetailsPage = ({category, side, symbol,type}) => {
    //const detailPath = category === 'stack' ? paths.stackDetail : paths.stockDetail;
    const detailPath = type === 'stack' ? paths.stackDetail : type === 'etf' ? paths.etfDetail : paths.stockDetail;
    const path = side ? `${detailPath}${symbol}?${side}` : `${detailPath}${symbol}`
    history.push(path);
  }
  const handleBuy = () => {
    let { category, symbol, type} = item;
    goToDetailsPage({category, symbol, side:'buy', type});
  }

  const handleSell = () => {
    let { symbol, category , type} = item;
    if(category !== 'stack') {
      goToDetailsPage({category, symbol, side:'sell', type});
    }
    // else if(category === 'stack'){
    //   setShowMobileModal(true)
    // }
    handleMobileShow();
  }

  let disabledSell = false
  let disabledBuy = false;
  disabledSell = currentValue && isNumber(currentValue) && currentValue > 0 ? false : true
  if(item.category === 'stack') {
    if(item.isSellPending) {
      disabledSell = true;
      sellDisabledMessage = SELL_ALREADY_IN_PROGRESS
    }
    if(item.isRebalancing){
      disabledSell = true;
      sellDisabledMessage = SELL_REBALANCING
    }
    if(!item.isStackActive){
      disabledBuy = true
    }
  } else if (item.isDelisted) {
    disabledBuy = true;
    buyDisabledMessage = STACK_DELISTED_MSG;
  }

  useEffect(() => {
    data && data.length > 0 && handleLiveApi(data)
    if(!apiLoading){
      if(stockdata.length === filter(data, ({category}) => category !== 'etf').length){
        setLoadingNonEtf(false);
      }
      if(etfdata.length === filter(data, ({category}) => category === 'etf').length){
        setLoadingEtf(false);
      }
    }
  }, [apiLoading, data, etfdata.length, stockdata.length])

  const validateChange = (val) => {
    if(isNaN(val) || !isNumber(val)) {
      return '--'
    }
    else if(val >= 0) {
      return `$${addCommasinPrice(decimalAdjust('floor',val,-2))}`
    }
    else return `-$${addCommasinPrice(decimalAdjust('floor',val,-2).toString().substring(1))}`
  }

  const validateChangePer = (val) => {
    if(isNaN(val) || !isNumber(val)) {
      return []
    }
    else {
      return `[${addCommasinPrice(decimalAdjust('floor',val,-2))}%]`
    }
  }

  return (
    <>
      <Card className={styles.exploreCard} onClick={() => setOpen(!open)}>
        <Card.Body className={styles.cardBody}>
          <Row>
            <Col xs={2}>
              <div className={styles.imageDiv}>
                {logo && isLoaded ? (
                  <img
                    onError={() => {
                      setIsloaded(false)
                    }}
                    onClick={()=>item.type==='fund' && window.open(`${CORPORATE_FUNDS}/${item.Fund}`, '_blank')}
                    className={styles.stockImage}
                    src={logo}
                    alt={code.toString().substring(0, 1)}></img>
                ) : (
                  <h3 className={`text-center ${styles.imagePaddingRemove}`}>
                    {code.toString().substring(0, 1)}
                  </h3>
                )}
              </div>
            </Col>
            <Col xs={6} style={{margin:'auto'}} >
              <div className={styles.stockTitle} onClick={()=>item.type==='fund' && window.open(`${CORPORATE_FUNDS}/${item.Fund}`, '_blank')}>{code}</div>
              {shares === '' ? '' : <div className={styles.shares}>{decimalAdjust('floor', shares, -8)} shares</div>}
            </Col>
            {title !== 'Funds' ? <Col xs={4} className='pr-0' style={{ textAlign: 'end' }}>
              <div className={styles.mobilePrice}>
                {
                  currentValue && !isNaN(currentValue) && isNumber(currentValue) ?
                  `$${addCommasinPrice(decimalAdjust('floor', currentValue, -2))}` : '$--'
                }
              </div>
              <div className={styles.changeColor} style={{
                fontSize: '12px',
                lineHeight: '16px',
                color: totalReturn === 0 ? '' : totalReturn > 0 ? '#0DC014' : '#E51446'
              }}>
                {
                  validateChange(totalReturn)
                }
                {
                  validateChangePer(totalRetPer)
                }
              </div>
              </Col>
              :<Col xs={4} className='pr-0' style={{ textAlign: 'end', margin:'auto' }}>
                  <a href={item.Report} target='_blank' rel='noopener noreferrer'>Download</a>
              </Col>}
          </Row>
          {open && title !== 'Funds' ?
            <>
              <Row className='mt-3'>
                <Col>
                  <div className={styles.investTitle}>Invested</div>
                  <div className={styles.investValue}>${addCommasinPrice(decimalAdjust('floor', totalInvested, -2))}</div>
                </Col>
                {/* <Col>
           <div className={styles.investTitle}>Shares value</div>
           <div className={styles.investValue}>${decimalAdjust('floor',currentValue,-2)}</div>
           </Col> */}
                {avgCost === '' ? '' :
                  <Col>
                    <div className={styles.investTitle}>Avg.cost</div>
                    <div className={styles.investValue}>${addCommasinPrice(decimalAdjust('floor', avgCost, -2))}</div>
                  </Col>}
                <Col className='pr-0'>
                  <div className={styles.investTitle}>Price</div>
                  <div className={styles.investValue}>
                    {/* ${addCommasinPrice(decimalAdjust('floor', price, -2))} */}
                    { validateChange(price)}
                  </div>
                </Col>
                <Col className='pr-0' style={{textAlign:'end'}}>
                  <div className={styles.investTitle}>Price Change</div>
                  <div className={styles.investValue} style={{
                    fontSize: '12px',
                    lineHeight: '16px',
                    color: change >= 0 ? '#0DC014' : 'red'
                  }}>
                    {/* {change >= 0
                    ? + '$' + decimalAdjust('floor', change, -2) + '[' + decimalAdjust('floor', changePer, -2) + '%]'
                    : '$' + decimalAdjust('floor', change, -2) + '[' + decimalAdjust('floor', changePer, -2) + '%]'
                    } */}
                    {
                      validateChange(change)
                    }
                    {
                      validateChangePer(changePer)
                    }
                    </div>
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col>
                  {/* <Link to={`/${link}/${code}`}> */}
                    <Button
                      className={`btn btn-outline-danger ${styles.buttonSell}`}
                      onClick={handleSell}
                      disabled={disabledSell}
                    >Sell</Button>
                  {/* </Link> */}
                </Col>
                <Col className='pr-0'>
                  {/* <Link to={`/${link}/${code}`}> */}
                    <Button
                      className={styles.buttonBuy}
                      disabled={disabledBuy}
                      onClick={handleBuy}
                    >Buy</Button>
                  {/* </Link> */}
                </Col>
              </Row>
              {
              disabledSell ?
              <Row className='mt-3'>
                <Col className='pr-0'>
                  <img src={PortfolioDisableSell} className='mr-2' alt=''></img>
                  <span  className={styles.sellDisableMessage}>{sellDisabledMessage}</span>
                </Col>
              </Row>
              : ''
            }
             {
              disabledBuy ?
              <Row className='mt-3'>
                <Col className='pr-0'>
                  <img src={PortfolioDisableSell} className='mr-2' alt=''></img>
                  <span  className={styles.sellDisableMessage}>{buyDisabledMessage}</span>
                </Col>
              </Row>
              : ''
            }
            </>
            : ''}
          {open && title === 'Stacks' ?
            <>
              <Row className='mt-4' style={{paddingLeft:'15px'}}>
                <span className={styles.view} onClick={handleShow}>View allocation</span>
                <span><img src={Open} className={styles.viewImage}></img></span>
              </Row>
            </>
            :
            ''
          }
        </Card.Body>
      </Card>

      <CustomModal show={showModal} handleClose={handleClose}>
        <StocksWeightsTable rowHeaders={stockheaders} data={stockdata} isLoading={loadingNonEtf}/>

        <StocksWeightsTable rowHeaders={etfheaders} data={etfdata} isLoading={loadingEtf}/>
      </CustomModal>

     {/* for mobile */}
      <Modal show={showMobileModal} onHide={handleMobileClose} dialogClassName={styles.modalDialog} className='d-block d-lg-none'>
        <StackSell
          currentValue={item[currentValue]}
          stack={{ticker: item[stack], logo: logo, name: item[name], tos: item[tos] }}
          investedValue={item[totalInvestment]}
          closeModal={handleMobileClose}
        />
    </Modal>
    </>
  )
}

export default PortfolioTableCard
