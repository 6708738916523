import React from "react";
import styles from './Login.module.css'

const LoginBanner = ({partnerConfig})=>{
    const header = partnerConfig && partnerConfig?.[0].landingBanner?.heading
    const subHeader = partnerConfig && partnerConfig?.[0].landingBanner?.subHeading
    return(
        <div className={styles.panBannerContainer +' '+styles.beneficiaryBannerContainer}>
            <div className={styles.row1}>
                <div className="d-flex">
                    <div className="d-lg-flex">
                        <div>
                            <h2 className={styles.bannerHead}>{header}</h2>
                            <p className={styles.bannerSubHead}>{subHeader}</p>
                        </div>            
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginBanner
