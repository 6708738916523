import React, { useEffect, useState, useContext } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
// import InputLabel from '../../InputLabel/InputLabel'
import { ThemeContext } from '../../../Context/ThemeContext'
import styles from '../Profile.module.css'
import CustomModal from '../../../components/CustomModal/CustomModal'
import VerifyBasicOtp from './VerifyOTP'
import EditBasicDetail from '../EditUserBasicDetail/EditBasicDeatail'
import { toPascalCase } from '../../../utils'
// import lastDayOfDecade from 'date-fns/fp/lastDayOfDecade/index'

const BasicDetail = props => {
	const { profileData } = props
	// var proData = profileData.data
	// const pro = profileData["firstName"]
	// console.log('pro',pro)
	const { buttonColor } = useContext(ThemeContext)
	const [isEdit, setIsEdit] = useState(false)
	const [firstName, setFirstName] = useState('')
	const [middleName, setMiddleName] = useState('')
	const [lastName, setLastName] = useState('')
	const [emailAddress, setEmailAddress] = useState('')
	const [phone, setPhone] = useState('')
	const [showModal, setShowModal] = useState(false)
	const [isVerifyOtp, setIsVerifyOtp] = useState(false)
	const [mycountry, setMyCountry] = useState({ key: '', value: '' })
	const [getOtp, setOtp] = useState('')

  const setBasicDetails=(profileData)=>{
    	setFirstName(profileData['firstName'] || '--')
		setMiddleName(profileData['middleName'] || '')
		setLastName(profileData['lastName'] || '--')
		setEmailAddress(profileData['emailAddress1'] || '--')
		setPhone(profileData['phoneHome'] || '--')
		setMyCountry(profileData['countryID'])
  }

  useEffect(() => {
		setBasicDetails(profileData);
		// setCountryList(props.countryList)
	}, [profileData])


  useEffect(() => {
		const countryID = profileData['country']
		if (countryID && props.countryList && props.countryList.length) {
      // const [val] = props.countryList.filter(item => item.country === countryID);
      let countryObj;
      for(let i =0; i<props.countryList.length; i++){
        if(props.countryList[i] && (props.countryList[i].country === countryID)){
          countryObj = props.countryList[i];
          break
        }
    }


      setMyCountry({value: countryID,
        label: countryObj ? countryObj.countryName : ''})
		} else {
			setMyCountry({
				key: '--',
				value: '--'
			})
		}
	}, [profileData, props.countryList])

	const handleClose = () => {
		setShowModal(false, setIsVerifyOtp(false))
	}

	const onEdit = () => {
		setShowModal(true)
		setIsEdit(true)
		props.sendOtpApi('update_user')
	}

	// const onSave = () => {
	// 	setIsEdit(false, setIsVerifyOtp(false))
  // }

  return (
		<>
			{isEdit ? (
				<CustomModal className={styles.customModalPadding} show={showModal} handleClose={handleClose}>
					{isVerifyOtp ? (
						<EditBasicDetail
              sendOtpApi={props.sendOtpApi}
              profileData={profileData}
							buttonColor={buttonColor}
							firstName={firstName}
							middleName={middleName}
							lastName={lastName}
							emailAddress={emailAddress}
							phone={phone}
							country={mycountry}
              countryValue={mycountry.value}
							handleClose={handleClose}
							setIsEdit={setIsEdit}
							showModal={showModal}
							setIsVerifyOtp={setIsVerifyOtp}
              userID={profileData['userID']}
              setBasicDetails={setBasicDetails}
              countryList={props.countryList}
              accountStatus={profileData['accountStatus']}
              otp={getOtp}
              hashId={props.hashId}
							// onSave={onSave}
						/>
					) : (
						<VerifyBasicOtp
							buttonColor={buttonColor}
							profile={'edit your profile'}
							onVerifyOtp={setIsVerifyOtp}
							hashId={props.hashId}
							getOtp={setOtp}
							sendOtpApi={onEdit}
						/>
					)}
				</CustomModal>
			) : (
				''
			)}
			<Row className={styles.basicDetail}>
				<Col className={styles.basicDetailText}>Basic Details</Col>
        {profileData && profileData['accountStatus'] && profileData['accountStatus'] == 3 || profileData['accountStatus'] == 1 ? "" :
        <>
				<Col style={{ textAlign: 'end' }}>
					<Button className={styles.editButton} onClick={onEdit}>
						<img
							src={require('../../../public/images/edit.svg')}
							className='mr-1'></img>
						Edit
					</Button>
				</Col>
        </>}
			</Row>
			<Row className={`m-0 mt-2 ${styles.borderBottom}`}>
				<Col md={4} xs={6} className='mb-3 mt-3'>
					<div className={styles.label}>First name</div>
					<div className={styles.value}>{toPascalCase(firstName)}&nbsp;{middleName ? toPascalCase(middleName) : ""}</div>
				</Col>
				{/* <Col md={4} xs={6} className='mb-3 mt-3'>
					<div className={styles.label}>Middle name</div>
					<div className={styles.value}>{middleName}</div>
				</Col> */}
				<Col md={4} xs={6} className='mb-3 mt-3'>
					<div className={styles.label}>Last name</div>
					<div className={styles.value}>{toPascalCase(lastName)}</div>
				</Col>
				<Col md={4} xs={6} className='mb-3 mt-3'>
					<div className={styles.label}>Email address</div>
					<div className={styles.value}>{emailAddress}</div>
				</Col>
				<Col md={4} xs={6} className='mb-3 mt-3'>
					<div className={styles.label}>Phone</div>
					<div className={styles.value}>{phone}</div>
				</Col>
				<Col md={4} xs={6} className='mb-3 mt-3'>
					<div className={styles.label}>Country</div>
					<div className={styles.value}>{mycountry && mycountry.label ? mycountry.label : "--"}</div>
				</Col>
			</Row>

			{/* <Row className={`m-0 mt-4 ${styles.borderBottom}`}>
					<Col>
						<div className={styles.label}>Email address</div>
						<div className={styles.value}>{emailAddress}</div>
					</Col>
					<Col>
						<div className={styles.label}>Phone</div>
						<div className={styles.value}>{phone}</div>
					</Col>
					<Col>
						<div className={styles.label}>Country</div>
						<div className={styles.value}>{mycountry.value}</div>
					</Col>
				</Row> */}

			{/* <Row className={`m-0 mt-4 ${styles.borderBottom}`}>
					<Col>
						<div className={styles.label}>Language</div>
						<div className={styles.value}>{userLanguage}</div>
					</Col>
				</Row> */}
		</>
	)
}

export default BasicDetail
