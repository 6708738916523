import React, { useState, useEffect, useContext } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { ThemeContext } from '../../../Context/ThemeContext'
// import InputLabel from '../../InputLabel/InputLabel'
import styles from '../Profile.module.css'
import CustomModal from '../../../components/CustomModal/CustomModal'
import VerifyBasicOtp from './VerifyOTP'
// import EditBasicDetail from '../EditUserBasicDetail/EditBasicDeatail'
import EditIdentificationData from '../EditUserBasicDetail/EditIdentificationData'
import { getPANDetails } from '../../../api';

const Identification = (props) => {
  const {profileData} = props;
  const [countryCode, setCountryCode] = useState('')
  const [idNo, setIdNo] = useState('')
	const [showModal, setShowModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isPanEdit, setIsPanEdit] = useState(false)
  const [panVerification, setPanVerification] = useState('')
  const [getOtp, setOtp] = useState('')
	const [isVerifyOtp, setIsVerifyOtp] = useState(false)
	const { buttonColor } = useContext(ThemeContext)

	useEffect(() => {
	let countrySign = profileData["citizenship"]
	if(countrySign && props.citizenshipList && props.citizenshipList.length){
  // const [val] = props.citizenshipList.filter(item => item.code3 === countrySign);
  let citizenshipObj;
  for(let i =0; i<props.citizenshipList.length; i++){
    if(props.citizenshipList[i] && (props.citizenshipList[i].citizenship === countrySign)){
      citizenshipObj = props.citizenshipList[i];
      break
    }
}
	setCountryCode({
		key: countrySign,
		value: citizenshipObj ? citizenshipObj.citizenshipName : ""})
	}else {
		// setCountryCode({
		// key: 'IND',
		// value: 'India'}
    // )
  }
	},[profileData, props.citizenshipList])

  const onEdit = () => {
		setShowModal(true)
		setIsEdit(true)
    props.sendOtpApi()
	}
	const handleClose = () => {
		setShowModal(false, setIsVerifyOtp(false))
  }
  // const onSave = () => {
	// 	setIsEdit(false, setIsVerifyOtp(false))
	// }

  const setDetails=()=>{
    fetchPANDetails();
  }

  const fetchPANDetails = async () => {
    const response = await getPANDetails();
    if (response && response.code === 200 && response.data) {
      if(response.data.idNo)
        setIdNo(response.data.idNo);
      setIsPanEdit(response.data.showEdit)
      setPanVerification(response.data.panVerification)

    }
    else {
      console.log('something went wrong')
    }
  }

  useEffect(() => {
      fetchPANDetails();
  },[])
	return(
		<>
    	{isEdit ? (
				<CustomModal className={styles.customModalPadding} show={showModal} handleClose={handleClose}>
					{isVerifyOtp ? (
						<EditIdentificationData
              // sendOtpApi={props.sendOtpApi}
              profileData= {profileData}
							idNo={idNo}
							handleClose={handleClose}
							// onSave={onSave}
							// userID={profileData['userID']}
							// setIsVerifyOtp={setIsVerifyOtp}
							// setIsEdit={setIsEdit}
              buttonColor={buttonColor}
              setDetails={setDetails}
              // otp={getOtp}
              // hashId={props.hashId}
						/>
					) : (
						<VerifyBasicOtp
							profile={'edit your profile'}
							onVerifyOtp={setIsVerifyOtp}
							hashId={props.hashId}
							getOtp={setOtp}
							buttonColor={buttonColor}
							sendOtpApi={onEdit}
						/>
					)}
				</CustomModal>
			) : (
				''
			)}
			<Row className={styles.basicDetail}>
				<Col className={styles.basicDetailText}>Identification</Col>
        <Col style={{ textAlign: 'end' }}>
        { profileData && profileData['accountStatus'] &&
        (profileData['accountStatus'] === 2)  &&
        isPanEdit ?
					<Button className={styles.editButton} onClick={onEdit}>
						<img
							src={require('../../../public/images/edit.svg')}
							className='mr-1' alt="edit"></img>
						Edit
					</Button>
          :''}

				</Col>
			</Row>
			<Row className={`m-0 mt-2 ${styles.borderBottom}`}>
				<Col md={4} xs={6} className="mb-3 mt-3">
					<div className={styles.label}>Government ID no.</div>
          <div className={styles.value}>{profileData["idNo"] && profileData["idNo"] !== null ?

           (panVerification && (panVerification === 'PENDING' || panVerification === 'RESUBMITTED') ?

          <> {profileData["idNo"]}<span className={styles.processingIcon}><i class="fa fa-clock-o" aria-hidden="true"></i> Processing</span></>

          : profileData["idNo"])

          :
          "--" }</div>
				</Col>
				<Col md={4} xs={6} className="mb-3 mt-3">
					<div className={styles.label}>US tax payer</div>
					<div className={styles.value}>{profileData["usTaxPayer"] === true ? 'Yes' :  profileData["usTaxPayer"] === false ? 'No' : '--'}</div>
				</Col>
				<Col md={4} xs={6} className="mb-3 mt-3">
					<div className={styles.label}>Country of citizenship</div>
					<div className={styles.value}>{countryCode.value ? countryCode.value : "--"}</div>
				</Col>
			</Row>
		</>
	)
}

export default Identification
