
import React, { useContext,useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import {
	ForgotPassword,
	Login,
	SignUp,
  ForgotUserName,
  AccountSetUp,
  Document,
  ViewPlanContainer,
  Header,
  Logout,
  VerifyIdentity,
  SavingsSignUp,UpdateKYC
} from './components'
import { ThemeContext } from './Context/ThemeContext'
import LandingPage from './LandingPages'
import TransitionPage from './TransitionPage'
import NotFound from './components/NotFound'
import LoggedOutRoute from './LoggedOutRoute'
import LoggedInRoute from './LoggedInRoute'
import { Home,Portfolio,StackDetail,AllStacks,StockDetail,EtfDetail,Explore, AddWatchList,ProfilePage,Remittance,Orders,Reports,Fund, Themes, SelectedTheme, InstaPay, Sso } from './containers'
import SsoPartner from './containers/Sso/sso-signin'
import CashManagement from './containers/CashManagement/CashManagement'
import UpdateCashName from './components/UpdateCashName/UpdateApplication'
import SavingsBanner from './components/SavingsBanner/SavingsBanner'
import StatusScreen from './components/AddFund/Transfer/EasyFund/StatusScreen'
import newFund from './containers/NewFund/funds'
import VerifyPan from './components/Document/IndDocument/VerifyPan'
import RejectionDocument from './components/Document/IndDocument/RejectionDocument'
import AccountTransfer from './components/Profile/AccountTrasnfer/AccountTransfer'
import AccountTabs from './components/Profile/AccountTrasnfer/AccountTabs'

const CustomRoute = () => {
// const [isAuthenticated, setisAuthenticated] = useState(sessionStorage.getItem('token') ? true : false)
const { landingUrl } = useContext(ThemeContext)

useEffect(() => {
  // eslint-disable-next-line no-undef
  webengage.onReady(function() {
    // eslint-disable-next-line no-undef
    webengage.reload();
});
},[])

// useEffect(() => {
//  const status =  sessionStorage.getItem('token') ? true :false
//  console.log('updated authenticated status',status)
//   setisAuthenticated(status)
// },[sessionStorage.getItem('token')])
// console.info('isAuthenticated',isAuthenticated)
	return (
    <>
		<Switch>
			<Route
				path='/'
				exact
				render={props =>
					landingUrl !== '' ? (
						<TransitionPage landingUrl={landingUrl} {...props} />
					) : null
        }
			/>
			<LoggedOutRoute exact path='/landing-page' component={LandingPage} />
      <LoggedInRoute export path='/savings/status' component={SavingsBanner}/>
      <LoggedOutRoute exact path='/updateCashName' component={UpdateCashName} />
			<LoggedOutRoute exact path='/signin' component={Login} />
			<LoggedOutRoute exact path='/forgot-username' component={ForgotUserName} />
			<LoggedOutRoute exact path='/forgot-password' component={ForgotPassword} />
      <LoggedOutRoute exact path='/plans' component={ViewPlanContainer} />
      <LoggedOutRoute exact path='/signup' component={SignUp}/>
      <LoggedInRoute exact path='/updatekyc' component={UpdateKYC}/>
      <LoggedInRoute exact path='/verifyidentity' component={VerifyIdentity}/>
      <LoggedInRoute exact path='/verifypan' component={VerifyPan}/>
      <LoggedInRoute exact path='/uploaddocument' component={RejectionDocument}/>
      <LoggedInRoute exact path='/savings/signup' component={SavingsSignUp}/>
      <LoggedInRoute exact path='/document' component={Document} />
			<LoggedInRoute exact path='/accountsetup' component={AccountSetUp} />
			<LoggedInRoute exact path='/home' component={Home} />
			<LoggedInRoute exact path='/portfolio' component={Portfolio} />
			<LoggedInRoute exact path='/stackdetail/:code' component={StackDetail} />
			<LoggedInRoute exact path='/stockdetail/:code' component={StockDetail} />
      <LoggedInRoute exact path='/etfdetail/:code' component={EtfDetail} />
			<LoggedInRoute exact path='/funds' component={Fund} />
      {/* <LoggedInRoute exact path='/funds/:code/insta-pay' component={InstaPay} /> */}
			<LoggedInRoute export path='/remittance' component={Remittance} />
			<LoggedInRoute export path='/orders' component={Orders} />
			<LoggedInRoute export path='/reports' component={Reports} />
			<LoggedInRoute export path='/profile' component={ProfilePage} />
      <LoggedInRoute export path='/explore' component={Explore} />
      <LoggedInRoute export path='/addWatchList' component={AddWatchList} />
      <LoggedInRoute export path='/allStacks' component={AllStacks} />
      <LoggedInRoute export path='/themes' component={Themes} />
      <LoggedInRoute export path='/theme/:code' component={SelectedTheme} />
      <LoggedInRoute export path='/savings' component={CashManagement}/>
      <LoggedInRoute export path='/account-transfer' component={AccountTabs}/>
      <Route exact path='/easyfund' component={StatusScreen} />
      <Route exact path='/redirecting' component={StatusScreen} />
      <Route exact path='/sso' component={Sso}/>
      <Route exact path='/sso-signin' component={SsoPartner}/>
      <Route exact path='/logout' component={Logout}/>
      <Route path='*' component={NotFound} />
    </Switch>
    </>
  )
}
export default CustomRoute
