import React, {useState,useEffect} from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import styles from './CostDetailCard.module.css'
import PropTypes from 'prop-types'
import {getAccountSummary} from '../../api'
import { hasData } from 'jquery'
import {calReturn,decimalAdjust,calcCurrVal} from '../../utils'
import downImage from '../../public/images/ProfileDropDownClose.svg'
import upImage from '../../public/images/Profileup.svg'


const CostDetailCard = ({ data, stockName, livePrice ='' }) => {
  const [res, setRes] = useState({})
  const [check, setCheck] = useState(false)
  const [totalData, setTotalData] = useState({ret : '--', retPer : '--%'})
  const {  ret = '--', retPer = '--' } =  totalData;
  const {  accountSummary:{cashBalance='--'}={},portfolioSummary: { totalCurrentValue = '--', totalInvestmentAmount = '--'} = {} } = res

  const asynchFetch = async () => {
    const response = await getAccountSummary()
    response && response.data && setRes(response.data)
	}

  useEffect(()=>{
    asynchFetch()
  },[stockName])



  useEffect(()=>{
    data && data.totalUnit && data.totalInvestment && livePrice && setTotalData(calReturn(calcCurrVal(data.totalUnit,livePrice),data.totalInvestment))

  },[res])

	return (
    <>
    <div className='d-block d-lg-none'>
    <div className="accordion" id="accordionExample" >
       <div className="card" style={{border:'none'}}>
          <div className="card-header" id="headingOne" style={{border:'none', background:"none",padding:'0px'}} onClick={check ? ()=>setCheck(false) : ()=>setCheck(true) }>
            <h2 className="mb-0">
              {/* <div type="button" data-toggle="collapse" data-target={check  ? "" :"#collapseOne"} aria-expanded="true" aria-controls="collapseOne">ji</div> */}
              <Row>
                <Col xs={6}>
                  <div className={styles.own}>You own</div>
                  <div className={styles.title}>
                      Shares
                  </div>
                  <div className={`${styles.amount}`}>
                      {data ? data.totalUnit ? data.totalUnit : '--' : '--'}
                  </div>
                </Col>
                <Col xs={6}>
                  <div style={{textAlign:"end"}}>
                    <img src={check ?  upImage : downImage } />
                    <div className={styles.title}>Shares value</div>
                      <div className={`${styles.amount}`}>
                        {livePrice ? data ? data.totalUnit ? `$${decimalAdjust('floor',calcCurrVal(data.totalUnit,livePrice),-2)}` : '$--' : '$--' : '$--'}
                      </div>
                  </div>
                </Col>
              </Row>
            </h2>
          </div>

          <div id="collapseOne" className={`collapse ${check ? "show" : ""}`} aria-labelledby="headingOne" data-parent="#accordionExample">
          <div className="card-body" style={{padding:"0px"}}>
            <Row style={{marginTop:"24px"}}>
              <Col>
              <div className={`${styles.rowTitle} mt-3`}>Avg.cost</div>
              <div className={`${styles.rowTitle} mt-3`}>Portfolio diversity</div>
              <div className={`${styles.rowTitle} mt-3`} style={{ borderBottom: 'none',paddingBottom: '0px'}}>Total return</div>
              </Col>
              <Col>
              <div className={`${styles.rowValue} mt-3`}>
                {data ? data.totalInvestment ? data.totalUnit ? `$${decimalAdjust('floor',(data.totalInvestment/data.totalUnit),-2)}` : '$--': '$--' : '$--'}
              </div>
              <div className={`${styles.rowValue} mt-3`}>
              {livePrice ? data ? data.totalUnit ? cashBalance!=='--'  ? totalCurrentValue!=='--' ? decimalAdjust('floor',((data.totalUnit*livePrice*100)/(cashBalance+totalCurrentValue)),-3) == 0.000 ? 'negligible' : `${decimalAdjust('floor',((data.totalUnit*livePrice*100)/(cashBalance+totalCurrentValue)),-3)}%` : '--%' : '--%' : '--%':'--%':'--%'}
              </div>
              <div className={`${styles.rowValue} mt-3`} style={{ borderBottom: 'none',paddingBottom: '0px'}}>
                {`$${decimalAdjust('floor',ret,-2)} [${decimalAdjust('floor',retPer,-2)}%]`}
              </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
    </div>


      <Row className='d-none d-lg-flex'>
        <Col>
          <div className={styles.title}>
            Shares
          </div>
          <div className={`${styles.amount}`}>
            {data ? data.totalUnit ? data.totalUnit : '--' : '--'}
          </div>
          <Row>
            <Col>
              <div className={`${styles.rowTitle} mt-3`}>Total return</div>
              <div className={`${styles.rowTitle} mt-3`}>Portfolio diversity</div>
            </Col>
            <Col>
              <div className={`${styles.rowValue} mt-3`}>
                {`$${decimalAdjust('floor',ret,-2)} [${decimalAdjust('floor',retPer,-2)}%]`}
              </div>
              <div className={`${styles.rowValue} mt-3`}>
                {livePrice ? data ? data.totalUnit ? cashBalance!=='--'  ? totalCurrentValue!=='--' ? decimalAdjust('floor',((data.totalUnit*livePrice*100)/(cashBalance+totalCurrentValue)),-3) == 0.000 ? 'negligible' : `${decimalAdjust('floor',((data.totalUnit*livePrice*100)/(cashBalance+totalCurrentValue)),-3)}%` : '--%' : '--%' : '--%':'--%':'--%'}
              </div>
            </Col>
          </Row>
        </Col>
        <Col>
          <div className={styles.title}>Shares value</div>
          <div className={`${styles.amount}`}>
            {livePrice ? data ? data.totalUnit ? `$${decimalAdjust('floor',data.totalUnit*livePrice,-2)}` : '$--' : '$--' : '$--'}
          </div>
          <Row>
          <Col>
            <div className={`${styles.rowTitle} mt-3`}>Avg.cost</div>
          </Col>
          <Col>
          <div className={`${styles.rowValue} mt-3`}>
            {data ? data.totalInvestment ? data.totalUnit ? `$${decimalAdjust('floor',(data.totalInvestment/data.totalUnit),-2)}` : '$--': '$--' : '$--'}
          </div>
          </Col>
        </Row>
        </Col>
      </Row>
    </>

	)
}

CostDetailCard.prototype = {
	data: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	amount: PropTypes.string.isRequired
}

export default CostDetailCard

{/* <Row>
                    <Col>
                    <div className={styles.title}>
                      Shares
                    </div>
                    <div className={`${styles.amount}`}>
                      {data ? data.availableQuantity ? data.availableQuantity : '--' : '--'}
                    </div>
                    </Col>
                    <Col xs={6}>
                    <div className={styles.title}>Shares value</div>
                      <div className={`${styles.amount}`}>
                        {livePrice ? data ? data.availableQuantity ? `$${decimalAdjust('floor',data.availableQuantity*livePrice,-2)}` : '$--' : '$--' : '$--'}
                      </div>
                    </Col>
                  </Row> */}

// <Card className={styles.cardDetail}>
		// 	<Card.Body className='p-0'>
		// 		<Row className={styles.title}>{title}</Row>
		// 		<Row className={`mt-1 ${styles.amount}`}>{amount}</Row>
		// 		{data.map((item, index) => {
		// 			return (
		// 				<Row className='mt-3' key={index}>
		// 					<Col md={4} className={styles.rowTitle}>
		// 						{item.title}
		// 					</Col>
		// 					<Col md={4} className={styles.rowValue}>
		// 						{item.value}
		// 					</Col>
		// 				</Row>
		// 			)
		// 		})}
		// 	</Card.Body>
		// </Card>


