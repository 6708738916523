import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import styles from './PortFolioTable.module.css'
import { Col } from 'react-bootstrap'
import CustomModal from '../../components/CustomModal/CustomModal'
import PortfolioModalHeader from '../../components/PortfolioModalHeader/PortfolioModalHeader'
import StocksWeightsTable from '../../components/Stocks&Weights/StocksWeightsTable'
import { getPortfolioDetailAllocations, getLivePrice } from '../../api'
import { useSelector, useDispatch } from 'react-redux'
import { getMarketDetails } from '../../store/actions/index'
import { isNumber, keyBy, filter } from 'lodash'
import { CORPORATE_FUNDS, PORTFOLIO_HEADERS, paths } from '../../constants'
import StackSell from '../StackSell/StackSell'
import { calPriceChange, isMarketOpen,decimalAdjust, addCommasinPrice } from '../../utils';
import PortfolioDisableSell from '../../public/images/portfolioDIsableSell.svg'
import { SELL_REBALANCING, SELL_ALREADY_IN_PROGRESS, STACK_DELISTED_MSG } from '../../displayMessages';
import {getLivePricesFromTicker} from '../../common'

const {
  stack,
  stock,
  etf,
  logo,
  name,
  price,
  change,
  totalInvestment,
  totalReturn,
  totalReturnPer,
  currentValue,
  totalUnit,
  averageCost,
  tos
} = PORTFOLIO_HEADERS;

const renderPerValues = (value) => {
  return (
    <span
    style={{
      fontSize: '12px',
      lineHeight: '16px',
      color: value === 0 ? '': value >= 0 ? '#0DC014' : 'red',
      marginLeft: '2px',
    }}>
    {isNaN(value) ? '' : value === 0 ? '0.0%' :
    value  > 0
      ? '+' + addCommasinPrice(decimalAdjust('floor',value,-2))+ '%'
      : addCommasinPrice(decimalAdjust('floor',value,-2)) + '%'}
  </span>
  )
}

const renderValues = (value) => {
  return (
    isNaN(value)
      ? '--'
      : value >= 0
        ? '$' + addCommasinPrice(decimalAdjust('floor',value,-2))
        : '-$' + addCommasinPrice(decimalAdjust('floor',value,-2).toString().substring(1))
  )
}

const TableRow = ({ item, index, rowHeaders }) => {
	const [data, setData] = useState([])
	const [live, setLive] = useState([])
	const [hover, setHover] = useState(false)
	const [stackhover, setStackHover] = useState(false)
	const [showModal, setShowModal] = useState(false)
  const [isLoaded, setIsloaded] = useState(true)
  const [showSell, setShowSell] = useState(false)
  const [loadingEtf, setLoadingEtf] = useState(true)
  const [loadingNonEtf, setLoadingNonEtf] = useState(true)
  const [apiLoading, setApiLoading] = useState(true)

  const history = useHistory();

	let stockdata = [];
  let etfdata = [];
  let sellDisabledMessage = '';
  let buyDisabledMessage = '';
	const dispatchAction = useDispatch()
	const state = useSelector(state => state)

	const {
		marketDetails: { marketDetails: { currentMarketStatus = {} } = {} } = {}
	} = state

	Object.keys(state.marketDetails).length === 0 &&
		dispatchAction(getMarketDetails())

	const handleViewAllocation = async () => {
		let response = await getPortfolioDetailAllocations(item['Stack'])
    response && response.length > 0 && setData(response)
    setApiLoading(false);
	}

	const handleLiveApi = async data => {
		data = data.map(trav => trav.ticker)
		let response = await getLivePricesFromTicker(data)
		setLive(keyBy(response, 'symbol'))
	}

	if (data && data.length > 0 && live && Object.keys(live).length > 0) {
    data.map(trav => {
      let {lastTrade, priorClose, close} = live[trav.ticker];
      lastTrade = isMarketOpen(currentMarketStatus) ? lastTrade : close;
      let {change, changePer} = calPriceChange({
        currPrice: lastTrade,
        close: close || trav.close,
        priorClose: priorClose || trav.priorClose,
        isMarketOpen: isMarketOpen(currentMarketStatus)});
      const obj = {
        image: trav.logo ? trav.logo : '',
        name: trav.ticker ? trav.ticker : '',
        creator: trav.company ? trav.company : '',
        price: lastTrade,
        pricePercentage: changePer,
        amount: trav.totalInvestment ? trav.totalInvestment : '',
        shares: trav.qty ? trav.qty : '',
        isImgLoaded: true,
      }
      if(trav.category !== 'etf') {
        stockdata = [...stockdata, obj]
      } else {
        etfdata = [...etfdata, obj]
      }
    })
	}
	const handleClose = () => setShowModal(false)
  const handleShow = () => setShowModal(true, handleViewAllocation())
  const handleSellClose = () => setShowSell(false)
	const handleSellShow = () => setShowSell(true)
  const handleSellFromAllocation = () => {
    handleClose();
    handleSellShow();
  }

  const goToDetailsPage = ({category, side, symbol, type}) => {
    const detailPath = type === 'stack' ? paths.stackDetail : type === 'etf' ? paths.etfDetail : paths.stockDetail;
    // console.log("side ", side)
    const path = side ? `${detailPath}${symbol}?${side}` : `${detailPath}${symbol}`
    history.push(path);
  }
  const handleBuy = () => {
    let { category, symbol, type} = item;
    goToDetailsPage({category, symbol, side:'buy',type});
    // const path = category === 'stack' ? `${paths.stackDetail}${Stack}?buy` : `${paths.stockDetail}${Stock}?buy`;
    // history.push(path);
  }

  const handleSell = () => {
    let { symbol, category,type } = item;
    if(category !== 'stack') {
      goToDetailsPage({category, symbol, side:'sell',type});
    }
    handleSellShow();
  }

	const stockheaders = ['Stock', 'Price', 'Shares', 'Invested Amt.']
  const etfheaders = ['ETF', 'Price', 'Shares', 'Invested Amt.']
  let disabledSell = false
  let disabledBuy = false;
  disabledSell = item[currentValue] && isNumber(item[currentValue]) && item[currentValue] > 0 ? false : true
  if(item.category === 'stack') {
    if(item.isSellPending) {
      disabledSell = true;
      sellDisabledMessage = SELL_ALREADY_IN_PROGRESS
    }
    if(item.isRebalancing){
      disabledSell = true;
      sellDisabledMessage = SELL_REBALANCING
    }

    if(!item.isStackActive){
      disabledBuy = true
    }
  }
  if(item.isDelisted) {
    disabledBuy = true;
    buyDisabledMessage = STACK_DELISTED_MSG;
  }


  useEffect(() => {
    data && data.length > 0 && handleLiveApi(data)
    if(!apiLoading){
      if(stockdata.length === filter(data, ({category}) => category !== 'etf').length){
        setLoadingNonEtf(false);
      }
      if(etfdata.length === filter(data, ({category}) => category === 'etf').length){
        setLoadingEtf(false);
      }
    }
  }, [apiLoading, data, etfdata.length, stockdata.length])

	return (
    <>
      <tr
        className={(sellDisabledMessage || buyDisabledMessage) === '' ? styles.tableborder : ''}
        key={index}
        onMouseEnter={
          item['Stack']
            ? () => setStackHover(true, setHover(true))
            : () => {
                setHover(true)
              }
        }
        onMouseLeave={
          item['Stack']
            ? () => setStackHover(false, setHover(false))
            : () => {
                setHover(false)
              }
        }>
        <td
          // className={styles.tableborder}
          style={{ textAlign: 'start', paddingLeft: '0px' }}>
          {isLoaded ? (
            <img
              onError={() => {
                setIsloaded(false)
              }}
              src={item['Image'] || ''}
              className={styles.imageCompany}
              alt = "S"
              onClick={() => {
                if (item.type === 'fund'){
                  window.open(`${CORPORATE_FUNDS}/${item.Fund}`, '_blank')
                }else{
                  goToDetailsPage({
                    type: item.type,
                    category: item.category,
                    symbol: item.symbol
                  });
                }
              }}
            />
          ) : (
            <h3 style={{ paddingRight: '10px' }}>
              {item.symbol && item.symbol.substring(0, 1)}
            </h3>
          )}
        </td>
        {
        rowHeaders.map((bodyContent, i) => {
          const nameStyle = ["Stack", "Stock", "Etf", "Fund"].includes(bodyContent) ? { cursor: 'pointer'} : null;
          return ( <td
            style={{ width: bodyContent === 'Total Return' && '152px' }}
            className={
              bodyContent === 'Stack'
                ? styles.stacktableData
                : bodyContent === 'Stock' || bodyContent === 'ETF' || bodyContent === 'Fund'
                ? styles.stocktableData
                : styles.tableData
            }
            key={i}>
            {
            bodyContent === totalInvestment ? (
              '$' + addCommasinPrice(item[bodyContent])
            ) : bodyContent === name && item[bodyContent] && item[bodyContent].length > 14 ? (
              item[bodyContent].length >= 14 &&
              item[bodyContent].slice(0, 14) + '...'
            ) : bodyContent === price ? (
              <span>
                <span>{
                  renderValues(item[bodyContent])
                }</span>
                 {renderPerValues(item['Chng'])}
                {/* <span
                  style={{
                    fontSize: '12px',
                    lineHeight: '16px',
                    color: item['Chng'] >= 0 ? '#0DC014' : 'red'
                  }}>
                  {item['Chng'] >= 0
                    ? '+' + addCommasinPrice(decimalAdjust('floor',item['Chng'],-2)) + '%'
                    : addCommasinPrice(decimalAdjust('floor',item['Chng'],-2)) + '%'}
                </span> */}
              </span>
            ) : bodyContent === currentValue ? (
              hover ? (
                <span>
                  <button
                    type='button'
                    className={`btn btn-outline-danger btn-sm ${styles.buyButtons}`}
                    onClick={handleSell}
                    disabled={disabledSell}
                  >
                    Sell
                  </button>
                  <button
                    type='button'
                    className={`btn btn-outline-success btn-sm ${styles.buttonMargin} ${styles.buyButtons}`}
                    onClick={handleBuy}
                    disabled={disabledBuy}
                    >
                    Buy
                  </button>
                </span>
              ) : (
                  isNaN(item[bodyContent]) ? '--':
                '$' + addCommasinPrice(decimalAdjust('floor',item[bodyContent],-2))
              )
            ) : bodyContent === 'Total Return' ? (
              stackhover ? (
                <div
                  type='button'
                  onClick={handleShow}
                  className={styles.allocation}>
                  View Allocation
                </div>
              ) : (
                <span>
                  <span>{isNaN(item[bodyContent]) ? '--'
                  : item[bodyContent] >= 0 ? '$' + addCommasinPrice(decimalAdjust('floor',item[bodyContent],-2))
                  : '-$' + addCommasinPrice(decimalAdjust('floor',item[bodyContent],-2).toString().substring(1))
                  }
                  </span>
                  <span
                    style={{
                      fontSize: '12px',
                      lineHeight: '16px',
                      color: item['Return Percent'] === 0 ? '' : item['Return Percent'] > 0 ? '#0DC014' : 'red',
                      marginLeft: '2px',
                    }}>
                    {isNaN(item['Return Percent']) ? '' :
                    item['Return Percent']  === 0 ? '0.0%' :
                    item['Return Percent']  > 0
                      ? '+' + addCommasinPrice(decimalAdjust('floor',item['Return Percent'],-2))+ '%'
                      : addCommasinPrice(decimalAdjust('floor',item['Return Percent'],-2)) + '%'}
                  </span>
                </span>
              )
            ) : bodyContent === 'Avg.Cost' ? `$${addCommasinPrice(decimalAdjust('floor',item['Avg.Cost'],-2))}`
              : bodyContent === 'Report' ? (<a href={item.Report} target='_blank' rel='noopener noreferrer'>Download</a>)
              : (
                <span
                onClick={() => {
                  if (['Stack', 'Etf', 'Stock'].includes(bodyContent)){
                    goToDetailsPage({
                      type: item.type,
                      category: item.category,
                      side: item.side,
                      symbol: item.symbol
                    });
                  }else if(bodyContent === 'Fund'){
                    window.open(`${CORPORATE_FUNDS}/${item.Fund}`, '_blank')
                  }
                }}
                style={nameStyle}
                >
                  {item[bodyContent]}
                </span>
            )}
          </td>
        )
              })}
        {
          item.category === 'stack' ?
          <>
            <CustomModal show={showModal} handleClose={handleClose}>
              <PortfolioModalHeader
                item={item}
                disabledSell={disabledSell}
                handleBuy={handleBuy}
                handleSell={handleSellFromAllocation}
              />
              <div style={{marginTop:'32px'}}>
              <StocksWeightsTable rowHeaders={stockheaders} data={stockdata} isLoading={loadingNonEtf}/>
              </div>
              <div style={{marginTop:'32px'}}>
              <StocksWeightsTable rowHeaders={etfheaders} data={etfdata} isLoading={loadingEtf}/>
              </div>
            </CustomModal>
            <CustomModal show={showSell} handleClose={handleSellClose}>
              <StackSell
                currentValue={item[currentValue]}
                stack={{ticker: item[stack], logo: item[logo], name: item[name], tos:item[tos] }}
                investedValue={item[totalInvestment]}
                showSell={showSell}
              />
            </CustomModal>
          </>
          : null
        }
        {/* <span>{ sellDisabledMessage } </span> */}
      </tr>
      {sellDisabledMessage !== '' ?
      <tr className={styles.tableborder} >
        <td colSpan="7" className='pl-0 pt-0'>
          <img src={PortfolioDisableSell} className='mr-2'></img>
          <span className={styles.sellDisableMessage}>{sellDisabledMessage}</span> </td>
      </tr> : ''}
      {buyDisabledMessage !== '' ?
      <tr className={styles.tableborder} >
        <td colSpan="7" className='pl-0 pt-0'>
          <img src={PortfolioDisableSell} className='mr-2'></img>
          <span className={styles.sellDisableMessage}>{buyDisabledMessage}</span> </td>
      </tr> : ''}
    </>
	)
}

export default TableRow
