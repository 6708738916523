import React, { Component } from "react";
import downloadImage from "../../../public/images/download.png";
import excelImage from "../../../public/images/excel.png";
import pdfImage from "../../../public/images/pdf.png";
// import ReportDatePicker from "../reportDatePicker/reportDatePicker";
import Select from 'react-select';
import FinancialReportCalendar from "../reportCalendar/financialreportCalendar";
import moment from "moment";
// import TradeReportCalendar from "../reportCalendar/tradereportCalendar";
// import LrsReportCalendar from "../reportCalendar/lrsreportCalendar";
// import exportFromJSON from 'export-from-json'
// import jsPDF from 'jspdf'
// import 'jspdf-autotable'
class MonthlyReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDropdown : false,
      year : {},
      header:[]
      // currentYear: this.props.currentYear,
      // flag: false
    };
    this.monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  }

  // componentWillReceiveProps(nextProps, nextContext) {
  //   this.setState({ currentYear: nextProps.currentYear })
  // }

  componentDidUpdate(prevProps) {
    if (this.props.modalName !== prevProps.modalName) {
      if(this.props.modalName === "Invoices"){
        let tempHeader = [];
        tempHeader = [{value:'All',label:'All'},...this.state.header];
        this.setState({header:tempHeader,year:tempHeader[0]})
      } else{
        const header = []
        this.props.header && this.props.header.map((item) => {
          header.push({value:item,label:item})
        })
        this.setState({header:header,year:header[0]})
      }
    }
  }

  componentDidMount(){
    const header = []
    this.props.header && this.props.header.map((item) => {
      header.push({value:item,label:item})
    })
    this.setState({header:header,year:header[0]})
  }

  convertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    for (let i = 0; i < array.length; i++) {
      let line = '';
      let object_ = {
        'Transaction Date(UTC)': array[i].tranWhen ? array[i].tranWhen : '',
        'Type': array[i].finTranTypeID ? array[i].finTranTypeID : '',
        'Amount': array[i].accountAmount ? array[i].accountAmount : '',
        'Account Balance': array[i].accountBalance ? array[i].accountBalance : '',
        'Order #': array[i].orderID ? array[i].orderID : '',
        'Comment': array[i].comment ? array[i].comment : ''
      };

      Object.keys(object_).forEach(key => {
        if (line != '') line += ','
        line += `"${object_[key]}"`;
      })

      str += line + '\r\n';
    }

    return str;
  }

  downloadExcel(data, fileName = 'FinTrans.csv') {
    var jsonObject = JSON.stringify(data);
    const headers = "Transaction Date(UTC),Type,Amount,Account Balance,Order #,Comment \r\n";
    try {
      var csv = headers + this.convertToCSV(jsonObject);
      var exportedFilenmae = fileName;

      var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  // downloadPDF(data) {
  //   const tableData = this.generateTable(data);
  //   this.setState({ tableData }, this.setState({ flag: true }));
  //   if (this.state.flag) {
  //     const doc = new jsPDF()
  //     doc.autoTable({ html: '#transactionTable' })
  //     doc.save('transaction.pdf')
  //   }
  // }
  // generateTable(data) {
  //   console.log("data", data);
  //   const htmlData =
  //     <table className="table re table-striped table-hover table-responsive" id="transactionTable" style={{ fontSize: "12px" }}>
  //       <thead>
  //         <tr>
  //           <th scope="col-md-2">DATE</th>
  //           <th scope="col-md-2">ORDER#</th>
  //           <th scope="col-md-2">TYPE</th>
  //           <th scope="col-md-2">AMOUNT</th>
  //           <th scope="col-md-2">BALANCE</th>
  //           <th scope="col-md-2">COMMENT</th>
  //         </tr></thead><tbody>

  //         {data.map((item, index) => {
  //           return (
  //             <tr key={index}>
  //               <td scope="row">{item.tranDate}</td>
  //               <td>{item.orderID ? item.orderID : '-'}</td>
  //               <td>{item.finTranTypeID}</td>
  //               <td>{item.accountAmount}</td>
  //               <td>{item.accountBalance}</td>
  //               <td>
  //                 {item.comment}
  //               </td>
  //             </tr>
  //           )
  //         })
  //         }
  //       </tbody></table>;


  //   return htmlData;
  // }

  handleSelect = (value) => {
   this.setState({year:value})
   this.props.handleDropdown(value.value)
  }

  downloadInvoices(url){
    window.open(url, "_blank")
  }

  render() {
    return (
      <>
        <div className="row">
          <div className="col">
            {this.props.modalName === "Monthly Account Statement & Holdings Report" ||
            this.props.modalName === "Dividend Tax Statement 1042-S" ||
            this.props.modalName === "Invoices" ? (
              <div style={{width:'100px'}}>
              <Select
              placeholder='Year'
              value={this.state.year}
              onChange={(value) => this.handleSelect(value)}
              options={this.state.header}
              styles={customStyle()}
              isSearchable={false}
              />
              </div>
              // <div>
              //   <button
              //     className="btn"
              //     type="button"
              //     style={{
              //       border: "0px",
              //       background: "#F7F7F8",
              //       borderRadius: "4px",
              //       color: "#211E33",
              //     }}
              //     data-toggle="dropdown"
              //     aria-haspopup="true"
              //     aria-expanded="false"
              //   >
              //     {this.props.currentYear}
              //   </button>
              //   <div
              //     className="dropdown-menu"
              //   >
              //     {this.props.header.map((item, i) => {
              //       return (
              //         <a className="dropdown-item text-center" style={{ cursor: 'pointer' }} key={i}
              //           onClick={() => this.props.handleDropdown(item)}>
              //           {item}
              //         </a>
              //       );
              //     })}
              //   </div>
              // </div>
            ) : (this.props.modalName === "Financial statement" || this.props.modalName === "Trade Confirmation" || this.props.modalName === "LRS Details") ?
              <FinancialReportCalendar
                changeDate={this.props.changeDate}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                errorMessages={this.props.errorMessages}
              />
              : ''
            }

          </div>
        </div>
        {/* adding header for inovoices */}
        {this.props.modalName === "Invoices" &&
        this.props.statementData.length > 0 ?
        <InvoicesHeader /> : ""}
        <div>
          {
            this.props.modalName === "Financial statement" ?


              Object.keys(this.props.statementData).length > 0 ?
                Object.keys(this.props.statementData).map((item, key) => (

                  <div
                    className="row"
                    key={key}
                    style={{
                      background: "#F7F7F8",
                      borderRadius: "4px",
                      margin: "0px",
                      marginBottom: "10px",
                      marginTop: "10px",
                      padding: "10px",
                    }}
                  >
                    <div className="col-md-7 col-12">
                      <span>{item}</span>

                    </div>
                    <div className="col-md-5 col-12">
                      <span
                        style={{
                          fontStyle: "normal",
                          fontWeight: "normal",
                          fontSize: "14px",
                          lineHeight: "20px",
                          letterSpacing: "0.6px",
                          color: "#9096A8",
                        }}
                      >
                        Download as:
                    </span>
                      <span style={{ cursor: 'pointer' }}
                        onClick={() => this.downloadExcel(this.props.statementData[item])}>
                        <span style={{ paddingLeft: "10px" }}>
                          <img src={excelImage} />
                        </span>
                        <span
                          style={{
                            paddingLeft: "1px",
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: "14px",
                            lineHeight: "20px",
                            letterSpacing: "0.6px",
                            color: "#1E1F24"
                          }}
                        >
                          Excel
                    </span>
                      </span>
                      <span style={{ cursor: 'pointer' }}
                        onClick={() => this.props.downloadPDF(this.props.statementData[item])}>
                        <span style={{ paddingLeft: "10px" }}>
                          <img src={pdfImage} />
                        </span>
                        <span
                          style={{
                            paddingLeft: "1px",
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: "14px",
                            lineHeight: "20px",
                            letterSpacing: "0.6px",
                            color: "#1E1F24"
                          }}
                        >
                          PDF
                    </span>
                      </span>
                    </div>
                  </div>

                ))
                :
                <div className="text-center" style={{ verticalAlign: 'middle', lineHeight: '45vh' }}>No transactions
                  available for this period</div>
                :
                this.props.modalName === "Invoices" ?
                  this.props.statementData.length > 0 ?
                    this.props.statementData.map((item, key) => (
                      <div
                        className="row"
                        key={key}
                        style={{
                          background: "#F7F7F8",
                          borderRadius: "4px",
                          margin: "0px",
                          marginBottom: "10px",
                          marginTop: "10px",
                          padding: "10px",
                        }}
                        >
                        <div className="col-md-5 col-4 col-sm-4 col-xs-4 inovicesTableValue">
                          <span>{item.invoiceId}</span>
                        </div>
                        <div className="col-md-2 col-3 col-sm-3 col-xs-3 inovicesTableValue">
                          <span>{item.amount}</span>
                        </div>
                        <div className="col-md-3 col-3 col-sm-3 col-xs-3 inovicesTableValue">
                          <span>{item.invoiceDate ? moment(item.invoiceDate).format("MMM DD, YYYY") : ""}</span>
                        </div>
                        <div className="col text-right col-2 col-xs-2 col-sm-2 inovicesTableValue" style={{ cursor: 'pointer' }}
                      onClick={() => this.downloadInvoices(item.invoiceUrl)}>
                      <span style={{ paddingRight: "5px" }}>
                        <img style={{height:"12px"}} src={downloadImage} />
                      </span>
                      <span
                        style={{
                          textDecoration: "underline",
                          fontFamily: "Lato",
                          fontStyle: "normal",
                          fontWeight: "normal",
                          fontSize: "12px",
                          lineHeight: "14px",
                          color: "#005DD6",
                        }}
                      >
                        Download
                    </span>
                    </div>
                      </div>
                    ))
                    :
                    <div className="text-center" style={{ verticalAlign: 'middle', lineHeight: '45vh' }}>No transactions
                      available for this period</div>
                  :
              this.props.statementData.length > 0 ?
                this.props.statementData.map((item, key) => {
                  return (<div
                    className="row"
                    key={key}
                    style={{
                      background: "#F7F7F8",
                      borderRadius: "4px",
                      margin: "0px",
                      marginBottom: "10px",
                      marginTop: "10px",
                      padding: "10px",
                    }}
                  >
                    <div className="col-md-7 col-7">
                      {this.props.modalName !== "LRS Details" ?
                        <span>{item.displayName.replace('Trade Confirm', '').replace('Statement', '')}</span>
                        :
                        <span>{item.displayName.replace('Statement', '')}</span>
                        // <span> {this.monthNames[new Date(item.createdAt).getMonth()] + ' ' + new Date(item.createdAt).getDate() + ', ' + new Date(item.createdAt).getFullYear()}</span>
                      }
                    </div>

                    <div className="col text-right" style={{ cursor: 'pointer' }}
                      onClick={e => this.props.getStatementsUrl(item.fileKey || item.requestId)}>
                      <span style={{ paddingRight: "5px" }}>
                        <img src={downloadImage} />
                      </span>
                      <span
                        style={{
                          textDecoration: "underline",
                          fontFamily: "Lato",
                          fontStyle: "normal",
                          fontWeight: "normal",
                          fontSize: "12px",
                          lineHeight: "14px",
                          color: "#005DD6",
                        }}
                      >
                        Download
                    </span>
                    </div>

                  </div>
                  )

                })
                :
                <div className="text-center" style={{ verticalAlign: 'middle', lineHeight: '45vh' }}>No transactions
                  available for this period</div>

          }
          {this.props.errorMessage === '' ? '' : <div
          style={{textAlign:'center',marginTop:'10px',fontSize:'14px',color:'#FA274A'}}>{this.props.errorMessage}</div> }

        </div>

        {/* <div id="tableData" className="d-none">{this.state.tableData}</div> */}

      </>
    );
  }
}

const InvoicesHeader = () => {
    return(
      <div className="row"
      style={{
        margin: "0px",
        marginBottom: "5px",
        marginTop: "8px",
        padding: "5px",
        marginLeft:"5px"
      }}
      >
        <div className="col-md-5 col-sm-4 col-xs-4 col-4">
          <span className="invoiceTableHead">Invoice ID</span>
        </div>
        <div className="col-md-2 col-3 col-sm-3 col-xs-3">
          <span className="invoiceTableHead">Amount</span>
        </div>
        <div className="col-md-3 col-3 col-sm-3 col-xs-3">
          <span className="invoiceTableHead">Invoice Date</span>
        </div>
      </div>
    )
}


const customStyle = () => ({
	container: provided => ({
		...provided,
    backgroundColor:'red',
    color:'red',
		width: '100%',
		top: '50%',
    borderRadius:'12px'
	}),
	option: (styles, { isFocused, isSelected }) => {
		return {
			...styles,
			color: isSelected || isFocused ? 'black' : 'black', // setting
			backgroundColor: isSelected || isFocused ? '#EFEFF1' : 'white',

			':active': {
				...styles[':active'],
				backgroundColor: 'white'
			}
		}
	},
	singleValue: styles => ({
		...styles,
		color: '#666666'
	}),
	indicatorSeparator: provided => ({ ...provided, visibility: 'hidden' }),
	control: base => ({
		...base,
		borderWidth: 0,
		borderRadius: 12,
    backgroundColor:'#EFEFF1',
		border: 0,
		boxShadow: 'none',
		'&:hover': {
			border: 0
		}
	}),
	menu: base => ({
		...base,
		borderRadius: 12,
	})
})

export default MonthlyReport;
