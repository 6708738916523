import React,{useState,createRef,useContext,useEffect} from 'react'
import { Row,Col } from 'react-bootstrap'
import styles from './easyfund.module.css'
import UploadAdhar from './UploadAdhar'
import {verifyAdharStatus,getKycStatus} from '../../../../api'
import SignUpButton from '../../../SignUp/SignUpButton'
import { AddFundContext } from "../../AddFundContext"
import { useDispatch } from 'react-redux';
import {closeFundModal} from '../../../../store/actions'
import AdharLoading from "./AdharLoading"
import GenericErrorIcon from "../../../../public/images/genericError.svg"

const VerifyAdhar = () => {
  const {setSubPage, PAGE,selectedAccount,easyfundTransDetail,transactionId, setMsg,SUBPAGE,setShowGenericErrorOption,setOnErrorRetry,showGenericErrorOption,setAdharLoading,setNoHeader, setNoClose} = useContext(AddFundContext)
  const dispatchAction = useDispatch()
  const [errorStatus,setErrorStatus] = useState(false)
  const [errorMessage,setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [kycStatus,setKycStatus] = useState('')
  const [adharFront,setAdharFront] = useState([])
  const [adharBack,setAdharBack] = useState([])
  const adharFrontRef = createRef()
  const adharBackRef = createRef()
  const EASYFUND = PAGE.EASYFUND

  useEffect(() => {
    setNoHeader(true)
    return () =>{
    setNoHeader(false)
    }
  },[])

  useEffect(() => {
    if(easyfundTransDetail && easyfundTransDetail?.paymentStatus && easyfundTransDetail.paymentStatus === 'HOLD'){
      //do nothing
    }else{
      if(kycStatus === 'REJECTED' || kycStatus === 'rejected' || kycStatus === 'declined'){
        setErrorStatus(true)
      }
      else if (kycStatus !== 'verified') {
        var summaryInterval = setInterval(() => {
          getStatusKycApi();
        }, 10000);
      }else{
        setSubPage(SUBPAGE[EASYFUND].REVIEW,setNoHeader(false,setNoClose(false)))
      }
  }
    return () => {
      clearInterval(summaryInterval);
    }
  },[kycStatus])

  const getStatusKycApi = async () => {
    let success = true
    try{
        const resp = await getKycStatus()
        if(resp && resp.doc && resp.doc.kyc){
          setKycStatus(resp.doc.kyc)
        }
        success=true
    }catch(error){
      success=false
    }
    if(success && showGenericErrorOption){
      setShowGenericErrorOption(false)
      setOnErrorRetry(null)
      }else if(!success){
          setOnErrorRetry((showGenericErrorOption) => getStatusKycApi())
          setShowGenericErrorOption(true)
      }
  }


  const onDropPan = (name , file) => {
    setErrorMessage('')
    file[0].preview = URL.createObjectURL(file[0])
    if(name === 'adharFront'){
      setAdharFront(file)
    }
    else if(name === 'adharBack'){
      setAdharBack(file)
    }
	}


	const onRemove = (name) => {
    if(name === 'adharFront'){
      setAdharFront([])
    }
    else if(name === 'adharBack'){
      setAdharBack([])
	}
  openPanDialog(name)
}

	const openPanDialog = (name) => {
	 if(name === 'adharFront' && adharFrontRef.current){
      adharFrontRef.current.open()
    }
    else  if(name === 'adharBack' && adharBackRef.current){
      adharBackRef.current.open()
    }
	}

  const createForm = (side,file) => {
    const body = new FormData()
     body.append('front',adharFront[0])
     body.append('back',adharBack[0])
     return body
  }

  const onSubmit = async() => {
    let success = true
    if(adharFront.length === 0 || adharBack.length === 0){
      setErrorMessage('Please Upload the document')
    }
    else{
      setLoading(true)
      try{
        const adharFrontPayload = createForm()
        const resp = await verifyAdharStatus(adharFrontPayload,transactionId)
        if(resp.status === 200){
          if(easyfundTransDetail && easyfundTransDetail?.paymentStatus && easyfundTransDetail.paymentStatus === 'HOLD'){
            dispatchAction(closeFundModal())
          }else{
          setKycStatus('pending')
          }
        }
        success = true
      }catch(error){
        success= false
        // setLoading(false)
      }
      if(success && showGenericErrorOption){
        setShowGenericErrorOption(false)
        setOnErrorRetry(null)
        }else if(!success){
            setOnErrorRetry((showGenericErrorOption) => onSubmit())
            setShowGenericErrorOption(true)
        }
    }
  }

   return(
     <>
    {errorStatus ?
      <div className={styles.cancelContainer}>
      <div>
          <div className={styles.cancelHeader}>
              <img src={GenericErrorIcon}/>
              <span className='text-center'>Your Aadhaar Card details couldn’t be processed</span>
          </div>
          <div className={styles.cancelBody}>
              <span className={styles.tryAgainContainer} style={{color:'#0061D3',cursor:'pointer'}} onClick={()=>window.fcWidget.open()}>
              Contact support
            </span>
            </div>
        </div>
    </div> :
    loading ?
     <AdharLoading /> :
     <div className={styles.verifyAdhar}>
      <Row className={styles.title}>Verify your Aadhaar</Row>
      <Row className={styles.subtitle}>
         Please upload front and back images of your Aadhaar card for verification.
      </Row>
      <Row className={styles.adharContainer}>
       <div className={styles.uploadtext}>Images uploaded need to be clear and unobstructed.</div>
       <Row className='m-0 mt-2' style={{width:'100%',height:'130px'}}>
         <Col md={6} sm={6} xs={6} className='p-0 pr-2'>
           <UploadAdhar
             files={adharFront}
             onDrop={(file) => onDropPan('adharFront',file)}
             dropRef={adharFrontRef}
             openDialog={() => openPanDialog('adharFront')}
             onRemove={onRemove}
             name='adharFront'
             accept='image/jpeg, image/png, image/jpg'
             maxSize='5000000'
             title='Upload Front'
           />
         </Col>
         <Col md={6} sm={6} xs={6} className='p-0 pl-2'>
         <UploadAdhar
             files={adharBack}
             onDrop={(file) => onDropPan('adharBack',file)}
             dropRef={adharBackRef}
             openDialog={() => openPanDialog('adharBack')}
             onRemove={onRemove}
             name='adharBack'
             accept='image/jpeg, image/png, image/jpg'
             maxSize='5000000'
             title='Upload Back'
           />
         </Col>
       </Row>
      </Row>
      <div className={styles.fileSize} style={{color:'#FA274A',paddingTop:'8px'}}>{errorMessage}</div>
      <Row style={{margin:'0px',marginTop:'40px'}}>
        <SignUpButton
         title='Submit Aadhar'
         onClick={onSubmit}
        />
      </Row>
     </div>}
     </>
   )
}

export default VerifyAdhar
