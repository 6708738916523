import React from 'react'
import { Row, FormControl } from 'react-bootstrap'
import styles from './DropDown.module.css'
import DropdownClose from '../../public/images/DropdownClose.png'
import DropdownOpen from '../../public/images/DropdownOpen.png'
import $ from 'jquery'

class Dropdown extends React.Component {
	state = {
		showDropDown: false,
		value: this.props.value,
		showList: this.props.data,
    data: this.props.data,
    isSelectedFromDropdown:true
	}

  componentDidUpdate(prevProps,prevState){
    if(prevProps.value !== this.props.value){
      if(this.props.isLabeled && this.props.isLabeled){
       //do nothing
      }
      else{
      this.setState({value:this.props.value})
      }
    }
  }

	toggleDropDown = () => {
    this.setState({ showDropDown: !this.state.showDropDown })
    // if(this.state.showDropDown === true){
    //  this.outsideClick()
    // }
	}

// outsideClick=()=>{
//   const that = this;
//   $(document).mouseup(function (e) {
//     var container = $('#' + that.props.name+'_dropdown');
//     if (container) {
//       // if the target of the click isn't the container nor a descendant of the container
//       if (!container.is(e.target) && container.has(e.target).length === 0) {
//         that.closeDropDown();
//       }
//     }
//   });
// }

  closeDropDown = () => {
    this.setState({ showDropDown: false })
    if(!this.state.isSelectedFromDropdown){
      this.props.onChange('')
      this.setState({value:'',showList: this.props.data})
    }
  }

	openDropDown = () => {
    // this.setState({ showDropDown: true },this.outsideClick())
    if(!this.props.disable) {
		this.setState({ showDropDown: true })
	}
  }



  sorter = (a, b) => {
    return a.stringIndex - b.stringIndex;
  };

  sortByIndex = arr => {
      arr.sort(this.sorter);
  };

  isOptionNotSelected=()=>{
    const filteredList = this.state.showList.filter(item => {
      return item.value === this.state.value
    })
    if(this.state.value === '' || filteredList.length === 0 ){
      this.props.onChange('')
    }
  }

	onChange = event => {
		const updatedList = this.state.data.filter(item => {
			// if((item.value || '').toLowerCase().includes(event.target.value.toLowerCase())) {
			// 	return item
      // }
      const index = item.value.toLowerCase().indexOf(event.target.value.toLowerCase());
      if(index !== -1) {
        item.stringIndex = index;
        return item
      }
    })
    // matching by index
    this.sortByIndex(updatedList);

    this.setState({ value: event.target.value, showList: updatedList,isSelectedFromDropdown:false },this.isOptionNotSelected())

	}

	onSelectItem = (value, label) => {
		this.props.onChange(value)
		if (this.props.flag) {
			this.setState({ showDropDown: false, value: value })
		} else {
			this.setState({
				showDropDown: false,
				value: label
			})
    }
    this.setState({isSelectedFromDropdown : true})
	}


	render() {
		let flagSvg = ''
		const { className, placeholder, name } = this.props
		if (this.props.flag) {
			flagSvg = this.state.data.filter(item => {
				return item.value === this.state.value
			})
    }

    //outside click issue
      const that = this;
      $(document).click(function (e) {
        if(that.props && that.props.name && that.state.showDropDown){
          var container = $('#' + that.props.name+'_dropdown');
          if (container) {
            // if the target of the click isn't the container nor a descendant of the container
            if (!container.is(e.target) && container.has(e.target).length === 0) {
              that.closeDropDown();
            }
          }
        }
      });

		return (
			<Row className={styles.dropDown} id={this.props.name+'_dropdown'}>
				<div className={styles.container}>
					{/* {this.state.showDropDown === false &&
					this.state.value !== '' &&
					this.props.flag ? (
						<span className={styles.flag}>
							{getUnicodeFlagIcon(flagSvg[0].code)}
						</span>
					) : (
						''
					)} */}
					<FormControl
						type='text'
						// style={
						// 	this.props.flag &&
						// 	this.state.showDropDown === false &&
						// 	this.state.value !== ''
						// 		? { paddingLeft: '40px' }
						// 		: {}
						// }
						autoComplete='off'
						placeholder={placeholder}
						name={name}
						className={className}
						onFocus={this.openDropDown}
						value={this.state.value}
						onChange={this.onChange}
						id={this.props.id}
						readOnly={this.props.disableSearch || this.props.disable}
					/>
					<label>{placeholder}</label>
					<span className={styles.dropDownImage} id="dropDownImage">
						<img
							src={this.state.showDropDown ? DropdownClose : DropdownOpen}
							onClick={!this.props.disable ? this.toggleDropDown : null} alt="^"></img>
					</span>
					{this.state.showDropDown && this.state.showList.length > 0 ? (
						<>
							<div className={styles.bankDropdown} id="dropDownCard">
								<ul className={styles.dropdownCard}>
									{this.state.showList.map((item, index) => {
										return (
											<li
												className={styles.dropDownItem}
												onClick={() =>
													this.onSelectItem(item.value, item.label)
												}
												key={index}>
												{item.label}
												<span className={styles.dropDownAlt}>
													{item.alt ? `(${item.alt})` : ''}
												</span>
											</li>
										)
									})}
								</ul>
							</div>
						</>
					) : (
						''
					)}
				</div>
			</Row>
		)
	}
}
export default Dropdown
