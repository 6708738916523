import React, { useState, useEffect } from 'react'
import styles from './AppBanner.module.css'
import iconStar from '../../../src/public/images/icon-star.svg'
import iconClose from '../../../src/public/images/icon-close.svg'

const AppBanner = ({bannerContent, StaticBannerShow, bannerText}) => {
    const [showBanner, setShowBanner] = useState(true)
    const [applink, setAppLink] = useState()

    const hasWindow = typeof window !== 'undefined';

    const androidOrIOS = () => {
        if(hasWindow) {
            const userAgent = navigator.userAgent;
            if(/android/i.test(userAgent)){
                return 'android';
            }
            if(/iPad|iPhone|iPod/i.test(userAgent)){
                return 'ios';
            }
        }
    }

    const handleClick = () => {
        setShowBanner(current => !current);
    }

    useEffect(() => {
       setAppLink(androidOrIOS())
    },[])

    return (
        <div>
            {showBanner && (
                <div className={styles.banner}>
                    <div className='container'>
                        <div className={styles.content}>
                            <img src={iconStar} alt="icon-star" width="20px" height="20px"></img>
                            {/* <p className={styles.titleDesktop}>Invest, Save and Track your Global Portfolio on the go with the Stockal App — Available on <a href="https://apps.apple.com/in/app/stockal/id1577380112" target="_blank" rel="noopener noreferrer">iOS</a> and <a href="https://play.google.com/store/apps/details?id=com.stockal.mobileapp" target="_blank" rel="noopener noreferrer">Android</a></p> */}
                            {/* <p className={styles.titleMobile}>Invest, Save and Track your Global Portfolio on the go with the Stockal App — <a href={applink === 'ios' ? 'https://apps.apple.com/in/app/stockal/id1577380112' : 'https://play.google.com/store/apps/details?id=com.stockal.mobileapp'} target="_blank" rel="noopener noreferrer">Download</a></p> */}
                            {StaticBannerShow ? 
                            <>                            
                            <p className={styles.titleDesktop}>{bannerText}</p>
                            <p className={styles.titleMobile}>{bannerText}</p>
                            </>
                            :
                            <>
                            <p className={styles.titleDesktop} dangerouslySetInnerHTML={{__html: bannerContent}}/>
                            <p className={styles.titleMobile} dangerouslySetInnerHTML={{__html: bannerContent}}/>
                            </>
                            }
                        </div>
                        <img src={iconClose} alt="icon-close" width="20px" height="20px" onClick={handleClick} style={{cursor:"pointer"}}></img>
                    </div>
                </div>
            )}
        </div>
    )
}

export default AppBanner