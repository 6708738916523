import React, { useState, useEffect } from 'react'
import {Col} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import styles from './Plan.module.css'
import tickImage from '../../public/images/GreenTickMark.svg'
import {addCommasinPrice} from '../../utils'
import { color } from 'highcharts'

const PlanCard = ({features=[],
  featuresv2=[],
  planName='--',
  planTitleAdjacent='',
  planTitleAmount='',
  subscriptionAmount='0',
  freeTrades='0',
  i,
  size,
  planId,
  planList,
  selectPlan,
  selectedPlan,
  heightCalculation='auto',
  currency='',
  buttonColor,
  forView=false}) =>{
  return(
    <>
    <Col xl='auto'
      style={{
        padding:'0px',
        marginRight: i!==(size-1) ? "32px" : '0px',
        minHeight:'550px',
        cursor: !forView && 'pointer'
      }}
      className={`${styles.border} d-none d-xl-block`}
      onClick={()=> !forView && selectPlan(planList, i)}
    >
        <div style={{textAlign:"center"}}>
          <div className={`${styles.goldMargin} ${styles.title}`}>
            {planName}
          </div>
          {/* <div className={styles.planCost}>
          {currency + ' ' + addCommasinPrice(subscriptionAmount)}
          {subscriptionAmount ? <span className={styles.year}>/year</span>:''}
          </div> */}

<div className={styles.planCost}>
{currency + ' ' + (planTitleAmount ? planTitleAmount : addCommasinPrice(subscriptionAmount))}
          {/* {subscriptionAmount ? <span className={styles.year}>/year</span>:''} */}
          {(subscriptionAmount || planTitleAdjacent) ?
            <span className={styles.year}> {planTitleAdjacent ? planTitleAdjacent: (subscriptionAmount ? '/year' :'')} </span>
          :''}
          </div>

          {forView ? (<Link to='/signup'>
          <div
            className={styles.signupDiv}
            style={{
              background: forView ? '#FFFFFF'  : planId === selectedPlan.planId ? buttonColor : '#FFFFFF',
              color: forView ? '#005DD6' : planId === selectedPlan.planId ? '#FFFFFF' : '#005DD6' ,
              border: forView ? '1px solid #005DD6' : planId !== selectPlan.planId && '1px solid #005DD6'
            }}
          >
            {forView ? 'Sign Up Now' : planId === selectedPlan.planId ? 'Selected Plan' : 'Select Plan'}
          </div>
          </Link>) : (
             <div
             className={styles.signupDiv}
             style={{
               background: forView ? '#FFFFFF'  : planId === selectedPlan.planId ? buttonColor : '#FFFFFF',
               color: forView ? '#005DD6' : planId === selectedPlan.planId ? '#FFFFFF' : '#005DD6' ,
               border: forView ? '1px solid #005DD6' : planId !== selectPlan.planId && '1px solid #005DD6'
             }}
           >
             {forView ? 'Sign Up Now' : planId === selectedPlan.planId ? 'Selected Plan' : 'Select Plan'}
           </div>
          )}
          <div>
            <div style={{textAlign:'start',display: 'inline-block',marginTop:"16px",height:`${heightCalculation}px`}}>
              {
                features.map((item,i)=>{
                  return(
                    <div style={{marginTop:"16px"}}>
                    <img src={tickImage} style={{width:"8.62px",height:'6.27px',marginRight:"12.68px"}}/>
                    <span key={i} className={styles.featuresCss} dangerouslySetInnerHTML={{__html: item.text}}>
                    </span>
                    </div>
                  )
                })
              }
          </div>

          <hr style={{border:'1px dashed #EFEFF1',marginTop:"32px",marginBottom:"16px",width:"256px"}}/>
        <div>
          <div style={{textAlign:'start',display: 'inline-block',width:"196.672px"}}>
              {
                featuresv2.map((item,i)=>{
                  return(
                    <div style={{marginTop:"16px"}}>
                    <div style={{marginBottom:'7px'}}>
                    <img src={tickImage} style={{width:"8.62px",height:'6.27px',marginRight:"12.68px"}}/>
                    <span key={i} className={styles.featuresCss} dangerouslySetInnerHTML={{__html: item.featureName}}></span>
                    </div>
                    <div key={i} className={styles.description} dangerouslySetInnerHTML={{__html: item.description}}></div>
                    </div>
                  )
                })
              }
          </div>
        </div>
      </div>
      </div>
    </Col>
   <Col
      xs={12}
       style={{
         padding:'0px',
         cursor:'pointer',
         marginBottom: i !==(size-1) && '24px',
         display:'flex',
         justifyContent:"center"
       }}
       className={`d-xl-none`}
       onClick={()=> !forView && selectPlan(planList, i)}
     >
        <div style={{textAlign:"center", margin:'12px',minHeight:'500px', border: '1px solid #EFEFF1',  borderRadius:'8px',display:"inline-block"}}>
          <div className={`${styles.goldMargin} ${styles.title}`}>
            {planName}
          </div>
          {/* <div className={styles.planCost}>
            {currency + ' ' + addCommasinPrice(subscriptionAmount)}<span className={styles.year}>/year</span>
          </div> */}

          <div className={styles.planCost}>
          {/* {currency + ' ' + addCommasinPrice(subscriptionAmount)} */}
          {currency + ' ' + (planTitleAmount ? planTitleAmount : addCommasinPrice(subscriptionAmount))}
          {/* {subscriptionAmount ? <span className={styles.year}>/year</span>:''} */}
          {(subscriptionAmount || planTitleAdjacent) ?
            <span className={styles.year}> {planTitleAdjacent ? planTitleAdjacent: (subscriptionAmount ? '/year' :'')} </span>
          :''}
          </div>
          {forView ? (<Link to='/signup'>
          <div
            className={styles.signupDiv}
            style={{
              background: forView ? '#FFFFFF'  : planId === selectedPlan.planId ? buttonColor : '#FFFFFF',
              color: forView ? '#005DD6' : planId === selectedPlan.planId ? '#FFFFFF' : '#005DD6' ,
              border: forView ? '1px solid #005DD6' : planId !== selectPlan.planId && '1px solid #005DD6'
            }}
          >
            {forView ? 'Sign Up Now' : planId === selectedPlan.planId ? 'Selected Plan' : 'Select Plan'}
          </div>
          </Link>) : (
             <div
             className={styles.signupDiv}
             style={{
               background: forView ? '#FFFFFF'  : planId === selectedPlan.planId ? buttonColor : '#FFFFFF',
               color: forView ? '#005DD6' : planId === selectedPlan.planId ? '#FFFFFF' : '#005DD6' ,
               border: forView ? '1px solid #005DD6' : planId !== selectPlan.planId && '1px solid #005DD6'
             }}
           >
             {forView ? 'Sign Up Now' : planId === selectedPlan.planId ? 'Selected Plan' : 'Select Plan'}
           </div>
          )}
          <div>
            <div style={{textAlign:'start',display: 'inline-block',marginTop:"16px"}}>
              {
                features.map((item,i)=>{
                  return(
                    <div style={{marginTop:"16px"}}>
                    <img src={tickImage} style={{width:"8.62px",height:'6.27px',marginRight:"12.68px"}}/>
                    <span key={i} className={styles.featuresCss} dangerouslySetInnerHTML={{__html: item.text}}>
                    </span>
                    </div>
                  )
                })
              }
          </div>

          <hr style={{border:'1px dashed #EFEFF1',marginTop:"32px",marginBottom:"16px",width:"256px"}}/>
          <div>
          <div style={{textAlign:'start',display: 'inline-block',width:"196.672px"}}>
              {
                featuresv2.map((item,i)=>{
                  return(
                    <div style={{marginTop:"16px"}}>
                    <div style={{marginBottom:'7px'}}>
                    <img src={tickImage} style={{width:"8.62px",height:'6.27px',marginRight:"12.68px"}}/>
                    <span key={i} className={styles.featuresCss} dangerouslySetInnerHTML={{__html: item.featureName}}></span>
                    </div>
                    <div key={i} className={styles.description} dangerouslySetInnerHTML={{__html: item.description}}></div>
                    </div>
                  )
                })
              }
          </div>
        </div>
      </div>
      </div>
    </Col>

     </>
  )
}

export default PlanCard
