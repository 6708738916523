import React,{useRef,useEffect} from 'react'
import { Card,Row,Col } from 'react-bootstrap'
import styles from './AccountTransfer.module.css'

const DtccSearchList = ({list,onSelect,setShowDtccList,searchdtccList}) => {
  const searchRef = useRef(null);
  useOnClickOutside(searchRef, () => setShowDtccList(false,searchdtccList('')));

    return(
        <Card className={styles.searchCard} ref={searchRef}>
          <Card.Body className={styles.cardBody}>
            {/* <Row className={styles.stock}>Search Results</Row> */}
            <div className={styles.searchResultDivider}></div>
            <div className={styles.searchList}>
              {list &&
                list.map((item, index) => {
                  return (
                    <>
                      <Row className={`${styles.searchItem}`} onClick={() => onSelect(item.accountName,item.dtccId)}>
                        <Col md={9} sm={9} xs={9} className={styles.stockTitle}>
                            {item.accountName.length > 25 ? `${item.accountName.substring(0,27)}...` : item.accountName}
                        </Col>
                        <Col md={3} sm={3} xs={3} className={styles.stockType}>
                            {item.dtccId}
                        </Col>
                      </Row>
                
                    <div className={styles.resultDivider}></div>
                    </>
                  )
                })}
            </div>
          </Card.Body>
        </Card>
    )
}

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    [ref, handler]
  );
}


export default DtccSearchList