import { currentPlan, getAccountInfo, geoLocation ,getLivePrice} from './api'
import {ANALYST_RATING_FEATURE} from './constants'
export const currencyFormatter = (currency, number, fraction = 0) => {
  if (currency) {
    let format = (currency && currency === "USD" ? 'en-US' : 'en-IN');
    return new Intl.NumberFormat(format, {
      style: 'currency',
      currency,
      minimumFractionDigits: fraction
    }).format(number)
  }
}
export function setRazorPaySetting(razorpaySetting) {
  // theme   // "razorpaySetting": "[{rzpuserid: 'rzp_test_u7KReLXGzc48Z8', currency:'USD'},{rzpuserid: 'rzp_test_NVh60hNyKfXGAQ',currency:'INR'}]"
  // if (document.querySelector('meta[name="razorpaySetting"]')) {
  //   let razorpaySetting = (document.querySelector('meta[name="razorpaySetting"]').content);
  //   // console.log(typeof razorpaySetting)
  //   // console.log(razorpaySetting)
  //   razorpaySetting = eval(razorpaySetting);
  //   // console.log(typeof razorpaySetting)
  //   // console.log(razorpaySetting)
  //   return razorpaySetting;
  // }
  if (razorpaySetting) {
    razorpaySetting = eval(razorpaySetting);
    return razorpaySetting;
  }
  return [{ rzpuserid: process.env.REACT_APP_DEFAULT_RZPID_USD, currency: 'USD' }, { rzpuserid: process.env.REACT_APP_DEFAULT_RZPID_INR, currency: 'INR' }];
}

export function getRazorPayUserId(razorpaySetting, rzpCurrency) {
  const index = razorpaySetting.findIndex(key => key.currency === rzpCurrency);
  if (index !== -1)
    return (razorpaySetting[index].rzpuserid);
}

export const setCurrentPlan = async () => {
  // plan info
  const planData = await currentPlan();
  // console.info('planData*******', planData)
  const planInfo = planData.data ? planData.data : {}
  if (Object.keys(planInfo).length !== 0) {
    if (planInfo.details.premiumFeatures &&
      planInfo.details.premiumFeatures.length !== 0 &&
      planInfo.details.premiumFeatures.includes(ANALYST_RATING_FEATURE)) {
      planInfo.exclusiveUser = true;
    } else {
      planInfo.exclusiveUser = false;
    }
    sessionStorage.setItem('planInfo', JSON.stringify(planInfo))
    // eslint-disable-next-line no-undef
    // if (typeof (Intercom) !== "undefined") {
    //   let icSetting = {}
    //   if (planInfo.planId) {
    //     icSetting.planName = planInfo.planId
    //   }
    //   // eslint-disable-next-line no-undef
    //   Intercom('update', icSetting);
    // }

    return planInfo;
  }
  return
}
export const setUserInfoSession = (data) => {
  const userInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : {}
  const newUserInfo = { ...userInfo, ...data }
  sessionStorage.setItem('userInfo', JSON.stringify(newUserInfo));
}

export const checkExclusiveUser = () => {
  const planInfo = sessionStorage.getItem('planInfo') ? JSON.parse(sessionStorage.getItem('planInfo')) : ''
  if (planInfo && planInfo.exclusiveUser)
    return true
  else return false
}
export const fetchLocation = async()=>{
 const response = await geoLocation();
  if (response && response.code === 200 && response.data && response.data.country_code) {
    return response.data.country_code;
  }
  return;
}

export const getCurrentAccountStatusLink = async() => {
  //logic to redirect user based on account status
  const userInfores = await getAccountInfo();
  let link = '/profile';
  if (userInfores && userInfores.statusDetails){
    sessionStorage.setItem('userInfo', JSON.stringify(userInfores));
    const currentStatus = userInfores.statusDetails;
    if (currentStatus.status === 'pending') {
      if (userInfores.nextStep === "DOCUMENTS_OLD") {
        link = '/verifyidentity';
      }
      else if (userInfores.nextStep === "FEW_MORE_DETAILS") {
        sessionStorage.setItem('currentSignUp', 'PhoneNumber');
        link = '/signup';
      } else if (userInfores.nextStep === "DOCUMENTS") {
        link = '/verifyidentity';
      }
      else if (userInfores.nextStep === "PERSONAL_DETAILS" ||
        userInfores.nextStep === 'ADDRESS' ||
        userInfores.nextStep === 'FINANCIAL_DETAILS' ||
        userInfores.nextStep === 'LEGAL_DETAILS'
      ) {
        link = '/accountsetup'
      }
      else {
        link = '/home'
      }
    }
    else if (currentStatus.status === 'approved') {
      // const summaryRes = await summaryData()
      // now plan
      const planInfo = await setCurrentPlan();
      if (!planInfo) {
        link = '/profile?modal=true&redirectTo=home'
      }
      //upgrade condition
      else if(planInfo.exclusiveUser === false || planInfo.exclusiveUser === 'false'){
        link = '/profile?modal=true&redirectTo=home'
      }
      // else if (summaryRes && summaryRes.code === 200 && summaryRes.data &&
      //   (summaryRes.data.accountSummary && !summaryRes.data.accountSummary.cashBalance) &&
      //   (summaryRes.data.portfolioSummary && !summaryRes.data.portfolioSummary.totalInvestmentAmount)
      // ) {
      //  link = '/funds?modal=true'
      // }
      // else if (summaryRes.status === 500 || (summaryRes.code === 200 && Object.keys(summaryRes.data).length === 0)) {
      //   link = '/funds?modal=true'
      // }
    }

    else if (currentStatus.status === 'rejected') {
      if (userInfores.nextStep === "DOCUMENTS_OLD") {
        link= '/document'
      }
      else if (currentStatus.type === 'Document') {
        link = '/profile'
      }
      else if (currentStatus.type === 'KYC') {
        link = '/profile'
      }
      else {
        link = '/profile'
      }
    }
  }
  return link;
  }

  export const getLivePricesFromTicker = async(tickers) => {
    try {
      const result = [];
      const chunkSize = 400;
      for (let i = 0; i < tickers.length; i = i+chunkSize) {
          const chunk = tickers.slice(i, i + chunkSize);
          const tickerJoin = chunk.join(',')
          const settled = await getLivePrice(tickerJoin)
          result.push({...settled});
      }
      const liveprices = Object.assign({},...result)
      return liveprices
    } catch (err) {
      console.log("error fetching live prices");
    }
  }
