import React, {useState,useEffect} from "react";
import {Row,Col,Spinner} from 'react-bootstrap'
import ReportModalHeader from "./reportModalHeaders";
import downloadImage from "../../public/images/download.png";
import {getPnLStatement,getAnnualPnlStatement} from '../../api'
import Select from 'react-select';
import styles from './reportModal.module.css'


const ReportModalBody = () => {
  const [type,setType] = useState({value:'Annual',label:'Annual'})
  const [period,setPeriod] = useState({value:'Apr-Mar',label:'Apr-Mar'})
  const [periodYear,setPeriodYear] = useState()
  const [periodYearOptions,setPeriodYearOptions] = useState([])
  const [month,setMonth] = useState()
  const [dataList,setDataList] = useState([])
  const [loading,setLoading] = useState(false)
  const [errorMessage,setErrorMessage]= useState('')
  const [data,setData] = useState('')
  const typeOptions = [{value:'Annual',label:'Annual'},{value:'Monthly',label:'Monthly'}]
  const periodsOptions = [{value:'Apr-Mar',label:'Apr-Mar'},{value:'Jan-Dec',label:'Jan-Dec'}]
  const monthlyNames = [{key:"Jan",value:'01'},{key:"Feb",value:'02'},{key:"Mar",value:'03'},{key:"Apr",value:'04'},{key:"May",value:'05'},{key:"Jun",value:'06'},
  {key:"Jul",value:'07'},{key:"Aug",value:'08'},{key:"Sep",value:'09'}, {key:"Oct",value:'10'}, {key:"Nov",value:'11'}, {key:"Dec",value:'12'}];
  const monthlyOptions = []
  monthlyNames.map((i) => {
    return monthlyOptions.push({value:i.key,label:i.key})
  })

  const userId = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')).userID : '';

  const date = new Date().getUTCFullYear()

  const calculateYear = () => {
    let options = []
    for(let i=date;i>=2020;i--){
      if(i !== date){
      options.push({value:i,label:i})
      }
    }
    setPeriodYearOptions(options)
  }

  const calculateFinYear = () => {
    let options = []
    const todayMonth = new Date().getMonth()
    const startYear = todayMonth > 2 ? date : date - 1
    for(let i=startYear;i>2020;i--){
      const valueFin = `${i-1} - ${i}`
      options.push({value:valueFin,label:valueFin})
    }
    setPeriodYearOptions(options)
  }

  useEffect(() => {
    const asyncFetch = async () => {
     setData('')
     setLoading(true,setErrorMessage(''))
     let resp
      try{
       if(type.value === 'Monthly'){
         if(periodYear && periodYear.value && month && month.value){
         const monthValue = monthlyNames.filter((i) => {
           return i.key === month.value
         })
         resp = await getAnnualPnlStatement('monthly',periodYear.value,monthValue[0].value,userId)
        }
       }
       else if(type.value === 'Annual'){
         if(period && period.value && periodYear && periodYear.value ){
         const year = period.value === 'Jan-Dec' ? periodYear.value : periodYear.value.substr(periodYear.value.length - 4)
         resp = await getAnnualPnlStatement('annual',year,period.value,userId)
         }
       }
       if(resp && resp.data){
         setData(resp)
        }
        else{
          setErrorMessage('No data Found')
        }
       setLoading(false)
      }catch(error){
        if(error.response && (error.response.status === 404 || error.response.status === 400)){
          setErrorMessage('No data Found')
        }
        setLoading(false)
      }
    }
    asyncFetch()
   },[type,period,periodYear,month])


   const onDownload = () => {
     // eslint-disable-next-line no-undef
     webengage.track("P&L_downloaded")
    const blob = new Blob([data.data], {
      type: data.headers["content-type"],
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `tax_report_${new Date().getTime()}.xlsx`;
    link.click();
   }

  useEffect(()=>{
    setPeriodYear('')
    calculateFinYear()
   if(period.value === 'Jan-Dec')
   {
     calculateYear()
   }
  },[period])

  useEffect(() => {
    setPeriodYear('',setMonth(''))
    if(type.value === 'Annual'){
     calculateFinYear()
    }else{
      calculateYear()
    }
  },[type])


  return(
   <>
    {/* dropdowns */}
    <Row>
     <Col lg={3} md={4} className={styles.margin}>
       <span className={styles.labelTitle}>Type</span>
     {/* <Select
      placeholder='Type'
      value={type}
      onChange={(value) => setType(value)}
      options={typeOptions}
      styles={customStyle()}
      isSearchable={false}
     ></Select> */}
     <input
        className={styles.rectangle}
        // type='number'
        value={type.value}
        readOnly={true}
      />
     </Col>
     {type.value === 'Annual' ?
     <Col lg={3} md={4} className={styles.margin}>
     <span className={styles.labelTitle}>Period</span>
     <Select
      placeholder='Period'
      value={period}
      onChange={(value) => setPeriod(value)}
      options={periodsOptions}
      styles={customStyle()}
      isSearchable={false}
     ></Select>
     </Col>:''}
     <Col lg={3} md={4} className={styles.margin}>
     <span className={styles.labelTitle}>Year</span>
     <Select
      placeholder='Year'
      value={periodYear}
      onChange={(value) => setPeriodYear(value)}
      options={periodYearOptions}
      styles={customStyle()}
      isSearchable={false}
     ></Select>
     </Col>
     {type.value === 'Monthly' ?
     <Col lg={3} md={4} className={styles.margin}>
     <span className={styles.labelTitle}>Month</span>
     <Select
      placeholder='Month'
      value={month}
      onChange={(value) => setMonth(value)}
      options={monthlyOptions}
      styles={customStyle()}
      isSearchable={false}
     ></Select>
     </Col>:''}
     {data && Object.keys(data).length > 0 ?
     <Col lg={3} md={4} className={styles.margin} style={{paddingTop:'25px'}}>
       <div onClick={onDownload} style={{cursor:'pointer'}}>
      <span style={{ paddingRight: "5px" }}>
        <img src={downloadImage} />
      </span>
      <span
        style={{
          textDecoration: "underline",
          fontFamily: "Lato",
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: "12px",
          lineHeight: "14px",
          color: "#005DD6",
        }}
      >
        Download
    </span>
    </div>
     </Col> : ''}
    </Row>
    {/* list */}
    {errorMessage === '' ?
     dataList && dataList.length > 0 &&
     dataList.map((item,key) => {
       return(
         <div></div>
       )
     })
    :
    <div className={styles.error}>{errorMessage}</div>
    }
    {
      loading ?
      <div className={styles.spinner}><Spinner animation="border" className={styles.spinnerColor}/></div>
      :
      ''
    }
   </>
  )
}

const customStyle = () => ({
	container: provided => ({
		...provided,
    backgroundColor:'red',
    color:'red',
		width: '100%',
    borderRadius:'12px'
	}),
	option: (styles, { isFocused, isSelected }) => {
		return {
			...styles,
			color: isSelected || isFocused ? 'black' : 'black', // setting
			backgroundColor: isSelected || isFocused ? '#EFEFF1' : 'white',

			':active': {
				...styles[':active'],
				backgroundColor: 'white'
			}
		}
	},
	singleValue: styles => ({
		...styles,
		color: '#666666'
	}),
	indicatorSeparator: provided => ({ ...provided, visibility: 'hidden' }),
	control: base => ({
		...base,
		borderWidth: 0,
		borderRadius: 12,
    backgroundColor:'#EFEFF1',
		border: 0,
		boxShadow: 'none',
		'&:hover': {
			border: 0
		}
	}),
	menu: base => ({
		...base,
		borderRadius: 12,
	})
})

// class ReportModalBody extends Component {
//   constructor(props){
//     super(props)
//     this.state = {
//       reportData:this.props.plData,
//       accordiandata : [],
//       reportHtml: '',
//     }
//     this.downloadStatement = this.downloadStatement.bind(this)
//   }

//  async downloadStatement(fileName){
//     let href = ''
//     let getSessionData = sessionStorage.getItem("userInfo");
//     getSessionData = JSON.parse(getSessionData);
//     const userID = getSessionData.userID;
//     // const filter = this.state.reportData.filter((item) => {
//     //   return item.displayName === name
//     // })
//     // const fileName = filter[0].fileKey
//     console.log(fileName)
//     const response = await getPnLStatement(userID,fileName)
//     if(response.code === 200 && response.data!==''){
//       href=response.data
//       let element = document.createElement('a');
//       element.setAttribute('href', href);
//       element.setAttribute('download', '');
//       element.setAttribute('target', '_blank');

//       element.style.display = 'none';
//       document.body.appendChild(element);
//       element.click();
//       document.body.removeChild(element);
//     }
//   }

//   renderPnlList(reports, index, data) {
//     if (index >= reports.length) {
//       console.log('Index out of range', index);
//       return data;
//     }
//     if (reports[index].reports) {
//       // Annual Report
//       const quaterlyReports = reports[index].reports;
//       const { displayName, fileKey } = reports['index'];
//       console.log('Annual', index, reports[index].displayName, quaterlyReports);
//       this.renderPnlList(quaterlyReports, 0, data);
//     }
//     console.log(reports[index].displayName);
//     this.renderPnlList(reports, index + 1, data);
//   }

//   render() {
//     const reportData = this.props.plData
//     console.log(this.props.plData);
//     let data = '';
//     return (
//        <>
//         {
//           reportData.length === 0 ?
//             <div className='m-auto text-center'>{this.props.message}</div>
//             :
//             (<div
//               className="accordion md-accordion"
//               id="accordionEx"
//               role="tablist"
//               aria-multiselectable="true"
//             >
//               {
//                 reportData.map((annualReport, i) => {
//                   const { displayName, fileKey, reports: quaterlyReports } = annualReport;
//                   return (
//                     <div
//                       className="card"
//                       style={{background: "#EFEFF1", marginBottom: "10px"}}
//                     >
//                       <div className="card-header" role="tab" id={`headingTwo${i}`}>
//                         <a
//                           style={{textDecoration: "none"}}
//                           className="collapsed"
//                           data-toggle="collapse"
//                           data-parent="#accordionEx"
//                           href={`#collapseTwo${i}`}
//                           aria-controls={`collapseTwo${i}`}
//                         >
//                           <ReportModalHeader data={{displayName, fileKey}} downloadStatement={(fileKey) => this.downloadStatement(fileKey)} showAccordion={quaterlyReports.length > 0}/>
//                         </a>
//                       </div>
//                       {
//                         quaterlyReports.length > 0 ?
//                           <div
//                             id={`collapseTwo${i}`}
//                             className="collapse"
//                             role="tabpanel"
//                             aria-labelledby={`headingTwo${i}`}
//                             data-parent="#accordionEx"
//                           >
//                             <div
//                               className="card-body"
//                               style={{paddingBottom: "0px", paddingTop: "5px"}}
//                             >
//                               {
//                                 quaterlyReports.map((x, y) => (
//                                     <div
//                                       className="row"
//                                       key={y}
//                                       style={{marginBottom: "10px"}}
//                                     >
//                                       <div className="col">
//                                         <span
//                                           style={{
//                                             fontStyle: "normal",
//                                             fontWeight: "normal",
//                                             fontSize: "14px",
//                                             lineHeight: "20px",
//                                             letterSpacing: "0.6px",
//                                             color: "#626777",
//                                           }}
//                                         >
//                                           {x.displayName}
//                                         </span>
//                                       </div>
//                                       <div className="col text-right">
//                                         <span style={{paddingRight: "5px"}}>
//                                           <img src={downloadImage}/>
//                                         </span>
//                                         <span
//                                           style={{
//                                             textDecoration: "underline",
//                                             fontFamily: "Lato",
//                                             fontStyle: "normal",
//                                             fontWeight: "normal",
//                                             fontSize: "12px",
//                                             lineHeight: "14px",
//                                             color: "#005DD6",
//                                             cursor:'pointer'
//                                           }}
//                                           onClick={() => this.downloadStatement(x.fileKey)}
//                                         >
//                                           Download
//                                         </span>
//                                       </div>
//                                     </div>
//                                   )
//                                 )
//                               }
//                             </div>
//                           </div>
//                           :
//                           ''
//                       }
//                     </div>
//                   );
//                 })
//               }
//             </div>)
//         }
//       </>
//     );
//   }
// }

export default ReportModalBody;

// return (
//   <div
//     className="row"
//     style={{
//       background: "#F7F7F8",
//     }}
//   >
//     <div
//       className="col"
// style={{
//   fontStyle: "normal",
//   fontWeight: "normal",
//   fontSize: "14px",
//   lineHeight: "20px",
//   letterSpacing: "0.6px",
//   color: "#626777",
// }}
//     >
//       {x}
//     </div>
//     <div className="col">
//       <span>
//         <img src={downloadImage} />
//       </span>
//       <span
//         style={{
//           fontFamily: "Lato",
//           fontStyle: "normal",
//           fontWeight: "normal",
//           fontSize: "12px",
//           lineHeight: "14px",
//           color: "#005DD6",
//           letterSpacing: "0.6px",
//         }}
//       >
//         Download
//       </span>
//     </div>
//   </div>
// );
