import React, { useEffect, useState, useContext } from 'react'
import { init } from 'onfido-sdk-ui';
import { useHistory } from 'react-router-dom'
import { createApplicant, getSdkToken, identityInitiate, updatedAccount,logout,LeadCapture,identityDocIndian,uploadDocumentApi } from '../../api'
import { handleErrorResponse } from '../../errorResponse'
import OnBoardingScreen from '../OnBordingScreen/OnBoardingScreen'
import AlertError from '../AlertError/AlertError'
import { ThemeContext } from '../../Context/ThemeContext'
import styles from './Document.module.css'
import { setUserInfoSession } from '../../common';
import IDSelection from './IDSelection'
import AddressSelection from './AddressSelection'
import DocumentSkeleton from '../../skeletons/DocumentSkeleton'
import AadharPanSelection from './AadharPanSelection'
import NewHeader from '../NewSignupComponents/NavBar/NewHeader'
import KycScreen from './IndDocument/kycScreen'
import UploadDocument from './UploadDocument/UploadDocument';
import { set, get } from 'idb-keyval';

const Document = props => {
  const [serverError, setServerError] = useState('')
  const { buttonColor,leadSqCompID,location,partnerConfig } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false)
  // let userData = {}
  // if(props.location && props.location.state){
  //   userData = props.location.state
  // }else{
  const userData = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : ''
  // }
  const [email] = useState(userData.emailAddress1 ? userData.emailAddress1 : '')
  const [countryOfResidence] = useState(userData.country ? userData.country : 'IND')
  const [phoneNumber] = useState(userData.phoneHome ? userData.phoneHome : '')
  const [showSelection, setShowSelection] = useState('flex')
  const [showAddress, setShowAddress] = useState('none')
  const [showAddressPan,setShowAddressPan] = useState(false)
  const [showUpload,setShowUpload]= useState(false)
  const [documentTitle,setDocumentTitle] = useState('');
  const [showBackOfID, setShowBackOfID] = useState(false);
  const [documentSecletedType, setDocumnetSelectedType] = useState();
  const [documentType, setDocumentType] = useState();
  const [buttonLoader,setButtonLoader] = useState(false);
  const [partnerConfigAddressProofRequired, setPartnerConfigAddressProofRequired]= useState('required');
  const [configList,setConfigList] = useState([]);
  const [ifMenaIndiaSelect, setIfMenaIndiaSelect] = useState(false);
  
  let history = useHistory()

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
    // make sure to block unwanted direct page load
    if (userData && (userData.nextStep === "DOCUMENTS_OLD" || userData.nextStep === "DOCUMENTS" || userData.nextStep === "PAN_DETAILS"))
    {
     return
    }
    else
      history.push('/home');
  },[history, userData])
  
  useEffect(() => {
    if(partnerConfig !== undefined){
      let data = partnerConfig?.[0]?.documents
      const addressProofDoc = data && data.find((item) => item.label === "addressProof");
      const idProofList = data && data.find((item) => item.label === "identityProof");
      const isDocAvailable = addressProofDoc ? addressProofDoc.active : true;
      setPartnerConfigAddressProofRequired(isDocAvailable === false ? 'not Required' : 'required');
      setConfigList(idProofList);
    }
  },[partnerConfig])

  // const customUI = {
  //   colorBackgroundButtonPrimary: buttonColor ? buttonColor : '#0061D3',
  //   colorBackgroundButtonPrimaryHover:buttonColor ? buttonColor : '#0061D3',
  //   colorBorderButtonSecondary:buttonColor ? buttonColor : '#0061D3',
  //   colorBackgroundButtonSecondary:'#FFFFFF',
  //   colorBackgroundButtonSecondaryHover:'#FFFFFF',
  //   borderRadiusButton:'8px',
  //   colorBackgroundLinkHover:'transparent',
  //   colorContentLinkTextHover:'#3C404A',
  //   fontSizeTitle:'28px',
  //   fontSizeSubtitle:'16px',
  //   fontWeightTitle:'500',
  //   fontWeightSubtitle:'400',
  //   fontFamilyTitle: 'geomanistmedium',
  //   fontFamilySubtitle: 'geomanistregular',
  //   fontFamilyBody: 'geomanistregular',
  //   borderRadiusSurfaceModal:'16px',
  //   colorBorderSurfaceModal:'#EFEFF1',
  //   colorBackgroundSurfaceModal:'#FFFFFF'
  // }
  // const applicantsReqData = {
  //   first_name: email,
  //   last_name: email,
  //   email: email,
  // }



  const uploadedData = async (data) => {
    updatedAccount({ nextStep: 'PERSONAL_DETAILS' })
    .then(res => {
      if (res.code === 200) {
        const details = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : '';
        // eslint-disable-next-line no-undef
        webengage.user.login(details.username);
        // eslint-disable-next-line no-undef
        webengage.track("KYC upload completed");

        setUserInfoSession({ nextStep: 'PERSONAL_DETAILS' })
        props.history.push('/accountsetup', { data, applicantId: sessionStorage.getItem('oaid') })
      }
      else {
        // eslint-disable-next-line no-undef
        webengage.track("KYC Document Upload Failed", {
          "Reason": "Api Error"
        });
      }
    })
    .catch(error => {
      // eslint-disable-next-line no-undef
      webengage.track("KYC Document Upload Failed", {
        "Reason": "Api Error"
      });
      console.log(error)
    })
  }

  //for indian
  // const onIndianSelection = (doc) => {
  //   //for cash account
  //   const getCashAccount = JSON.parse(localStorage.getItem('isCashAccount'))
  //   const savingAccount = JSON.parse(sessionStorage.getItem('savingAccount')) ? JSON.parse(sessionStorage.getItem('savingAccount')) : false
  //   if(savingAccount && getCashAccount){
  //     //  onSelection(doc,onPassport)
  //   }else{
  //   }
  // }


  // const nonIndianSelection = (doc) => {
  //   onSelection(doc,onAddress)
  // }

  // when selecting the type of document this function runs
  const onNonIndianSelection = (doc) => {
    setDocumnetSelectedType(doc.title)
    setShowSelection('none')
    setDocumentType(doc.type)
    if(doc.type === "INDIA_KYC"){
      setIfMenaIndiaSelect(true)
      return
    }
    if(doc.title === "Passport"){
      setShowUpload(false)
      setShowBackOfID(true)
    }else{
      setShowUpload(true)
      setShowBackOfID(false)
    }
    // setShowUpload(true)
    setDocumentTitle(`Upload Front of ${doc.title}`)
    setLoading(true)
    try{
    setLoading(false)
    }catch(error){
    }
  }

  const onNationalId = async() => {
    setButtonLoader(true, setShowSelection('none'),setShowUpload(false))
    try{
      const resp = await onUploadDocument("ID")
      if(resp){
        setShowUpload(false)
        setLoading(false)
        setButtonLoader(false)
        if(partnerConfigAddressProofRequired === "required"){
          setDocumentTitle(`Upload Address Proof`)
        }else if(partnerConfigAddressProofRequired === "not Required"){
          uploadedData()
        }
      }else{
        setLoading(false)
        setButtonLoader(false)
      }
    }catch(error){
      setLoading(false)
      setButtonLoader(false)
    }
  }

  const onAddressId = async() => {
    setButtonLoader(true, setShowSelection('none'),setShowUpload(false))
    setDocumentType('OTHER')
    try{
     let res = await onUploadDocument("ADDRESS")
     if(res == true){
      uploadedData()
      setLoading(false)
      setButtonLoader(false)
      }
    }catch(error){
      setLoading(false)
      setButtonLoader(false)
    }
  }


  // const onUploadDocument = async () => {
  //   setButtonLoader(true)
  //   try{
  //     const body = new FormData();
  //     get('front').then(async(image) => {
  //       body.append('front',image)
  //       get('back').then(async(image) => {
  //         body.append('back',image)
  //         const res = await uploadDocumentApi(body)
  //         if(res && res.message == "success"){
  //           setButtonLoader(false)
  //           return true
  //         }else{
  //           setServerError('Something Went wrong. Please Try Again!')
  //           setButtonLoader(false)
  //         }
  //       })
  //     })
  //   }catch(error){
  //     setButtonLoader(false)
  //     setServerError('Something Went wrong. Please Try Again!')
  //   }
  // }

  const onUploadDocument = async (proofType) => {
    setButtonLoader(true);
    try {
        const body = new FormData();
        const frontImage = await get('front');
        const backImage = await get('back');
        body.append('front', frontImage);
        body.append('back', backImage);
        // if (documentType !== "PASSPORT" && proofType !== "ADDRESS") {
        //   body.append('back', backImage);
        // }
        body.append('type', proofType === "ADDRESS" ?  "OTHER" : documentType);
        body.append('proofType', proofType);
        console.log('body',body);
        const res = await uploadDocumentApi(body);
        if (res && res.message === 'success') {
            setButtonLoader(false);
            return true;
        } else {
            setServerError('Something Went wrong. Please Try Again!');
            setButtonLoader(false);
            return false;
        }
    } catch (error) {
        setServerError('Something Went wrong. Please Try Again!');
        setButtonLoader(false);
        return false;
    }
}

  const frontOfDocument = () => {
    setDocumentTitle(`Upload Back of ${documentSecletedType}`)
    setShowBackOfID(true);
    setShowUpload(false)
  }

  // const onSelection = async (doc,callBack) => {
  //   // alert(JSON.stringify(doc));
  //   setShowSelection('none')
  //   setShowAddressPan(false)
  //   setLoading(true)
  //   try {
  //     const applicantsData = await createApplicant({...applicantsReqData,source:'INVEST_ACCOUNT',proofType:'ID'})
  //     const { id } = applicantsData.data
  //     const SdkTokenData = await getSdkToken(id)
  //     sessionStorage.setItem('oaid', id)
  //     const onfidoStepsConfig = [
  //       {
  //         "type": "document",
  //         "options": {
  //           "documentTypes": {
  //             [doc.code]: {
  //               "country": countryOfResidence
  //             },
  //           }
  //         }
  //       },]
  //     // console.log(JSON.stringify(onfidoStepsConfig))
  //     setLoading(false)
  //     let onfidoObj = init({
  //       token: SdkTokenData.data.token,
  //       containerId: 'document-placeholder',
  //       onError:function(error){
  //         console.log('id error', JSON.stringify(error))
  //       },
  //       onUserExit:function(userExitCode){
  //         console.log('id Exit', JSON.stringify(userExitCode))
  //       },
  //       onComplete: async function (data) {
  //         // console.log('everything is complete')
  //         // console.log(data);
  //         onfidoObj.tearDown()
  //         let document = []
  //         Object.keys(data).filter(d => {
  //           if (d === 'document_front' || d === 'document_back') {
  //             document.push({
  //               id: data[d].id,
  //               type: doc.code,
  //               side: data[d].side,
  //               proofType: 'ID',
  //               proofName: doc.title
  //             })
  //           }
  //         })
  //         // console.log(document)
  //         const webengageEventName = location === 'India' ? 'KYC id uploaded' : 'KYC MENA id uploaded'
  //          // eslint-disable-next-line no-undef
  //           webengage.track(webengageEventName)

  //           const LeadData = {
  //             searchBy: 'email',
  //             relatedCompanyId: leadSqCompID,
  //             ProspectStage:'KYC document uploaded',
  //             email: email,
  //             phone: phoneNumber
  //           }
  //           LeadCapture(LeadData)
  //           sessionStorage.setItem('documents', JSON.stringify(document))
  //         // await identityDocIndian(sessionStorage.getItem('oaid'), document)
  //         callBack(data)
  //       },
  //       language:{
  //         phrases:{
  //           doc_submit: {
  //             "button_link_upload": "or upload photo (no scans or photocopies)",
  //             "button_primary": "Continue on phone",
  //             "subtitle": "Take a photo with your phone",
  //             "title_id_back": `Upload the back of your ${doc.title}`,
  //             "title_id_front": `Upload the front of your ${doc.title}`,
  //          }
  //         }
  //       },
  //       steps: onfidoStepsConfig,
  //       customUI,
  //       userDetails: {
  //         smsNumber: phoneNumber
  //       }
  //     })
  //   }
  //   catch (e) {
  //     console.log(e)
  //     setServerError('Something Went wrong. Please Try Again!')
  //   }
  // }


  // const onPassport = async (doc) => {
  //   // alert(JSON.stringify(doc));
  //   setLoading(true)
  //   try {
  //     const applicantsData = await createApplicant({...applicantsReqData,source:'CASH_ACCOUNT',proofType:'ID'})
  //     const { id } = applicantsData.data
  //     const SdkTokenData = await getSdkToken(id)
  //     const onfidoStepsConfig = [
  //       {
  //         "type": "document",
  //         "options": {
  //           "documentTypes": {
  //             national_identity_card: {
  //               "country": countryOfResidence
  //             },
  //           }
  //         }
  //       }]

  //     // console.log(JSON.stringify(onfidoStepsConfig))
  //     setLoading(false)
  //     let onfidoObj = init({
  //       token: SdkTokenData.data.token,
  //       containerId: 'document-placeholder',
  //       onError:function(error){
  //         console.log('id error', JSON.stringify(error))
  //       },
  //       onUserExit:function(userExitCode){
  //         console.log('id Exit', JSON.stringify(userExitCode))
  //       },
  //       onComplete: async function (data) {
  //         // console.log('everything is complete')
  //         // console.log(data);
  //         onfidoObj.tearDown()
  //         let document = []
  //         Object.keys(data).filter(d => {
  //           if (d === 'document_front' || d === 'document_back') {
  //             document.push({
  //               id: data[d].id,
  //               type: 'passport',
  //               side: data[d].side,
  //               proofType: 'ID',
  //               proofName: 'Passport'
  //             })
  //           }
  //         })
  //         // console.log(document)

  //         await identityDocIndian(id, document)
  //          // eslint-disable-next-line no-undef
  //            webengage.track("KYC passport uploaded")

  //            const LeadData = {
  //             searchBy: 'email',
  //             relatedCompanyId: leadSqCompID,
  //             ProspectStage:'Cash KYC document uploaded',
  //             email: email,
  //             phone: phoneNumber
  //           }
  //           LeadCapture(LeadData)

  //         onFace()
  //         // setShowAddress('flex')
  //       },
  //       language:{
  //         phrases:{
  //           doc_submit: {
  //             "button_link_upload": "or upload photo (no scans or photocopies)",
  //             "button_primary": "Continue on phone",
  //             "subtitle": "Take a photo with your phone",
  //             "title_id_back": "Upload the back of your Passport",
  //             "title_id_front": "Upload the front of your Passport",
  //          }
  //         }
  //       },
  //       steps: onfidoStepsConfig,
  //       customUI,
  //       userDetails: {
  //         smsNumber: phoneNumber
  //       }
  //     })
  //   }
  //   catch (e) {
  //     console.log(e)
  //     setServerError('Something Went wrong. Please Try Again!')
  //   }
  // }



  // const onAddress = async (doc) => {
  //   // alert(JSON.stringify(doc));
  //   const country = userData.country ? userData.country : 'IND'
  //   console.log('country',country)
  //   setShowAddress('none')
  //   setLoading(true)
  //   try {
  //     // const SdkTokenData = await getSdkToken(sessionStorage.getItem('oaid'))
  //     const applicantsData = await createApplicant({...applicantsReqData,source:'INVEST_ACCOUNT',proofType:'ADDRESS'})
  //     const { id } = applicantsData.data
  //     const SdkTokenData = await getSdkToken(id)
  //     let onfidoStepsConfig = []
  //       onfidoStepsConfig = [
  //         {
  //           "type": "poa",
  //           "options": {
  //             "country": country,
  //             "documentTypes": {
  //               bank_building_society_statement: true,
  //               utility_bill: true,
  //               government_letter: true
  //             }
  //           }
  //         }]
  //     setLoading(false)
  //     let onfidoObj = init({
  //       token: SdkTokenData.data.token,
  //       containerId: 'document-placeholder',
  //       onError:function(error){
  //         console.log('address error', JSON.stringify(error))
  //       },
  //       onUserExit:function(userExitCode){
  //         console.log('address Exit', JSON.stringify(userExitCode))
  //       },
  //       onComplete: async function (data) {
  //         // console.log('everything is complete')
  //         // console.log(data);
  //         onfidoObj.tearDown()
  //         let document = []
  //         Object.keys(data).filter(d => {
  //           if (d === 'document_front' || d === 'document_back' || d === 'poa' ) {
  //             document.push({
  //               id: data[d].id,
  //               type: doc.code,
  //               side: data[d].side,
  //               proofType: 'ADDRESS',
  //               proofName: doc.title
  //             })
  //           }
  //         })
  //         // console.log(document)
  //         await identityDocIndian(id, document)
  //          // eslint-disable-next-line no-undef
  //           webengage.track("KYC MENA address uploaded")
  //         onFace()
  //       },

  //       steps: onfidoStepsConfig,
  //       customUI,
  //       userDetails: {
  //         smsNumber: phoneNumber
  //       }
  //     })
  //   }
  //   catch (e) {
  //     console.log(e)
  //     setServerError('Something Went wrong. Please Try Again!')
  //   }
  // }

  // const onFace = async () => {
  //   setLoading(true)
  //   try {
  //     const SdkTokenData = await getSdkToken(sessionStorage.getItem('oaid'))
  //     let onfidoStepsConfig = ['face', 'complete']

  //     setLoading(false)
  //     let onfidoObj = init({
  //       token: SdkTokenData.data.token,
  //       containerId: 'document-placeholder',
  //       onError:function(error){
  //         console.log('face error', JSON.stringify(error))
  //       },
  //       onUserExit:function(userExitCode){
  //         console.log('face Exit', JSON.stringify(userExitCode))
  //       },
  //       onComplete: async function (data) {
  //         // console.log('everything is complete')
  //         // console.log(data);
  //         onfidoObj.tearDown()
  //         const documents = JSON.parse(sessionStorage.getItem('documents')) ? JSON.parse(sessionStorage.getItem('documents')) : []
  //         await identityDocIndian(sessionStorage.getItem('oaid'),documents)
  //         uploadedData(data)
  //       },
  //       steps: onfidoStepsConfig,
  //       customUI,
  //       userDetails: {
  //         smsNumber: phoneNumber
  //       }
  //     })
  //   }
  //   catch (e) {
  //     console.log(e)
  //     setServerError('Something Went wrong. Please Try Again!')
  //   }
  // }

  const onClickLogout = () => {
    var token = JSON.parse(sessionStorage.getItem('token'))
    //webengage logout
    // eslint-disable-next-line no-undef
    webengage.user.logout()
    // delete token.refreshToken
    var body = {
      accessToken: token && token.accessToken
    }
    // var accessToken = JSON.stringify(token)
    logout(JSON.stringify(body)).then(response => {
      sessionStorage.clear()
      localStorage.clear()
        history.push('/')
    })
  }
 
  return (
    <>
    <NewHeader
    message=''
    linkName='Log Out'
    onClickLink={onClickLogout}
     />
      {serverError === '' ? null : (
        <AlertError color='#E81346' message={serverError} />
      )}
      <div style={{marginTop:'20px'}}>
        {loading ? <DocumentSkeleton /> :
          (location === 'India' || location === 'INDIA') || (location === "MENA" && ifMenaIndiaSelect) ?
            <KycScreen setServerError={setServerError} 
            setDocTitle={setDocumentTitle} 
            location={location}
            ifMenaIndiaSelect={ifMenaIndiaSelect}
            setIfMenaIndiaSelect={setIfMenaIndiaSelect}
            setShowUpload={setShowUpload}
            setShowBackOfID={setShowBackOfID}/>
          // <AadharPanSelection onSelection={onIndianSelection} display={showSelection}/>
          :
           <IDSelection onSelection={onNonIndianSelection} display={showSelection} documentType={setDocumentType} configList={configList}/>
            }
          {showUpload === true && showBackOfID === false ?
          <UploadDocument
          title={documentTitle}
          onSubmit={frontOfDocument}
          loading={buttonLoader} 
          /> :
          showUpload === false && showBackOfID === true ?
          <>
          <UploadDocument
            title={documentTitle}
            loading={buttonLoader}
            onSubmit={documentTitle === 'Upload Address Proof' ? onAddressId : onNationalId}
          />
          </>
          :''
          }
         {/* <div id="document-placeholder" className={styles.documentPlaceholder} style={{ minHeight: showSelection === 'none' && showAddress === 'none' ? '500px' : 'auto', position: 'relative' }}></div> */}
      </div>
    </>
  )

  // return (
  //   <>
  //     {serverError === '' ? null : (
  //       <AlertError color='#E81346' message={serverError} />
  //     )}
  //     <OnBoardingScreen>
  //       <IDSelection country={countryOfResidence} onSelection={onSelection} display={showSelection} />
  //       <AddressSelection country={countryOfResidence} onSelection={onAddress} display={showAddress} />
  //       {loading ? <DocumentSkeleton /> : <div id="document-placeholder" className={styles.documentPlaceholder} style={{ minHeight: showSelection === 'none' && showAddress === 'none' ? '500px' : 'auto', position: 'relative' }}></div>}
  //     </OnBoardingScreen>
  //   </>
  // )
}

export default Document;
