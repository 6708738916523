import React, { useEffect, useState, useContext } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { ThemeContext } from '../../../Context/ThemeContext'
import InputLabel from '../../InputLabel/InputLabel'
import styles from '../Profile.module.css'
import CustomModal from '../../../components/CustomModal/CustomModal'
import VerifyBasicOtp from './VerifyOTP'
import EditAddress from '../EditUserBasicDetail/EditAddress'

const Address = props => {
	const { profileData } = props
	const [isEdit, setIsEdit] = useState(false)
	const [isVerifyOtp, setIsVerifyOtp] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [getOtp, setOtp] = useState('')
  const { buttonColor } = useContext(ThemeContext)

  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [state, setState] = useState('')
  const userData = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : ''
  const setDetails=(data)=>{
    setAddress1(data['addressLine1'] ? data['addressLine1'] : "")
    setAddress2(data['addressLine2'] ? data['addressLine2'] :"")
    setCity(data['city'] ? data['city'] : '')
    setZipCode(data['zipPostalCode'] ? data['zipPostalCode'] : '')
    setState(data['stateProvince'] ? data['stateProvince'] : '')
  }

  useEffect(() => {
		setDetails(profileData);
  }, [profileData])

	const handleClose = () => {
		setShowModal(false, setIsVerifyOtp(false))
	}

	// const onVerifyOtp = () => {
	// 	setIsVerifyOtp(true)
	// }

	const onEdit = () => {
		setShowModal(true)
		setIsEdit(true)
		props.sendOtpApi()
	}

	const onSave = () => {
		setIsEdit(false, setIsVerifyOtp(false))
	}


	return (
		<>
			{isEdit ? (
				<CustomModal className={styles.customModalPadding} show={showModal} handleClose={handleClose}>
					{isVerifyOtp ? (
						<EditAddress
              buttonColor={buttonColor}

							addressLine1={address1}
							addressLine2={address2}
							zipCode={zipCode}
							city={city}
              state={state}
              setDetails={setDetails}

							handleClose={handleClose}
							onSave={onSave}
							setIsEdit={setIsEdit}
							setIsVerifyOtp={setIsVerifyOtp}
							userID={profileData['userID']}
              otp={getOtp}
              hashId={props.hashId}
						/>
					) : (
						<VerifyBasicOtp
							profile={'edit your profile'}
							onVerifyOtp={setIsVerifyOtp}
							buttonColor={buttonColor}
							hashId={props.hashId}
							getOtp={setOtp}
							sendOtpApi={onEdit}
						/>
					)}
				</CustomModal>
			) : (
				''
			)}
			<Row className={styles.basicDetail}>
				<Col className={styles.basicDetailText}>Address</Col>
        {userData && userData?.nextStep === 'COMPLETE' ?
				<Col style={{ textAlign: 'end' }}>
					<Button className={styles.editButton} onClick={onEdit}>
						<img
							src={require('../../../public/images/edit.svg')}
							className='mr-1'></img>
						Edit
					</Button>
				</Col>
       :'' }
			</Row>
			<Row className={`m-0 mt-2 ${styles.borderBottom}`}>
				<Col md={4} xs={6} className='mb-3 mt-3'>
					<div className={styles.label}>Address line 1</div>
					<div className={styles.value}>{address1}</div>
				</Col>
        {address2 ?
				<Col md={4} xs={6} className='mb-3 mt-3'>
					<div className={styles.label}>Address line 2</div>
					<div className={styles.value}>{address2}</div>
				</Col> : ''}
				<Col md={4} xs={6} className='mb-3 mt-3'>
					<div className={styles.label}>City</div>
					<div className={styles.value}>{city}</div>
				</Col>
				<Col md={4} xs={6} className='mb-3 mt-3'>
					<div className={styles.label}>State</div>
					<div className={styles.value}>{state}</div>
				</Col>
				<Col md={4} xs={6} className='mb-3 mt-3'>
					<div className={styles.label}>Zipcode</div>
					<div className={styles.value}>{zipCode}</div>
				</Col>
			</Row>

			{/* <Row className={`m-0 mt-4 ${styles.borderBottom}`}>
				<Col>
					<div className={styles.label}>State</div>
					<div className={styles.value}>{profileData["stateProvince"]}</div>
				</Col>
				<Col>
					<div className={styles.label}>Zipcode</div>
					<div className={styles.value}>{profileData["zipPostalCode"]}</div>
				</Col>
				<Col></Col>
			</Row> */}
		</>
	)
}

export default Address
