import React, { useState } from 'react'
import styles from './PortfolioModalHeader.module.css'
import { Col, Row } from 'react-bootstrap'

const PortfolioModalHeader = ({ item, disabledSell, handleBuy, handleSell }) => {
	const [isLoaded, setIsloaded] = useState(true)
	return (
		<Row className={styles.topRow}>
			<Col className={styles.paddingRemove} md={1}>
				{isLoaded ? (
					<img
						onError={() => {
							setIsloaded(false)
						}}
						src={item['Image']}
						className={styles.imageCompanyModal}
					/>
				) : (
					<h3 className={`text-center`}>{item['Stack'].substring(0, 1)}</h3>
				)}
			</Col>
			<Col md={5}>
				<div className={styles.stockTitleModal}>{item['Stack']}</div>
				<div className={styles.stockDescriptionModal}>{item['Name']}</div>
			</Col>
			<Col className='text-right' md={6}>
				<div className={styles.buttonsDiv}>
					<span className={styles.buyBtn}>
						<button
							// style={{
							// 	background: '#FA274A'
							// }}
							className={styles.sellButton}
              disabled={disabledSell}
              onClick={handleSell}
              >
							Sell
						</button>
					</span>
					<span className={styles.buyBtn}>
						<button
							// style={{
							// 	background: '#0DC014'
							// }}
							className={styles.buyButton}
							onClick={handleBuy}>
							Buy
						</button>
					</span>
				</div>
			</Col>
		</Row>
	)
}

export default PortfolioModalHeader
