import React, { Component } from 'react'
import { getPlansByPartner, geoLocation } from '../../api';
import { setRazorPaySetting, getRazorPayUserId } from '../../common'
import { ThemeContext } from '../../Context/ThemeContext'
import ViewPlan from './viewPlan'

class ViewPlanContainer extends Component {
  constructor(props) {
    super(props);
    const pageType = 'list'
    this.state = {
      loader: false,
      isTermsAndCondition: true,
      couponArea: true,
      couponInputBox: true,
      isCouponApplied: false,
      buttonText: "Complete Payment",
      email: sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')).emailAddress1 : '',
      name: sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')).firstName + ' ' + JSON.parse(sessionStorage.getItem('userInfo')).lastName : '',
      pageType: pageType,
      pageTitle: pageType === 'new' ? 'Choose your Plan' : (pageType === 'upgrade' ? 'Upgrade Plan' : 'Renew Plan'),
      oldPlan: (pageType !== 'new' && sessionStorage.getItem('planInfo')) ? JSON.parse(sessionStorage.getItem('planInfo')) : '',
      currency: '',
      planList: [],
      selectedPlan: {},
      selectedPlanAmount: '',
      payableAmount: '',
      couponCode: '',
      couponMsg: '',
      rzpuserid: '',
      data: {},
      orderId: '',
      buttonColor: '',
    }
  }
  static contextType = ThemeContext


  async planListAPI(value) {
    this.setState({ loader: true });
    let partnerCode = this.context.partnerCode;
    if(!partnerCode){
      const { fetchTheme } = this.context
      await fetchTheme();
      partnerCode = this.context.partnerCode;
    }
    const resp = await getPlansByPartner(value, partnerCode);
    this.setState({ loader: false });
    return resp;
  }


  componentDidMount = () => {

    geoLocation().then(response => {
      if (response && response.code === 200 && response.data && response.data.country_code) {
        response.data.country_code === 'IN' ? this.planList('INR') : this.planList('USD')
      }
    })
    const site = this.context
    this.setState({ razorpaySetting: site.razorpaySetting });
    const { buttonColor, disclosuresLink, termsAndConditionsLink } = this.context
    // this.planList();
    let updatedDisclosuresLink = disclosuresLink
    if (disclosuresLink === '') {
      const country = sessionStorage.getItem('userInfo') && JSON.parse(sessionStorage.getItem('userInfo')).countryID ? JSON.parse(sessionStorage.getItem('userInfo')).countryID : ''
      const citizenship = sessionStorage.getItem('userInfo') && JSON.parse(sessionStorage.getItem('userInfo')).country ? JSON.parse(sessionStorage.getItem('userInfo')).country : ''
      updatedDisclosuresLink = `https://apps.drivewealth.com/disclosures/?citizenship=${citizenship}&country=${country ? country : citizenship}&accountType=Individual`
    }
    this.setState({ buttonColor, termsAndConditionsLink, updatedDisclosuresLink })
  }

  async planList(value) {
    const planListResp = await this.planListAPI(value);
    if (planListResp.code === 200 && planListResp.data) {
      if (planListResp.data.length !== 0) {
        let selectedPlanIndex = planListResp.data.findIndex(plan => plan.defaultPlan === 'true' || plan.defaultPlan === true);
        if (selectedPlanIndex === -1) {
          selectedPlanIndex = planListResp.data.length - 1;
        }
        const currency = planListResp.data[selectedPlanIndex].currency || 'INR';
        const razorpaySetting = setRazorPaySetting(this.state.razorpaySetting);
        const rzpuserid = getRazorPayUserId(razorpaySetting, currency)
        this.setState({
          planList: planListResp.data,
          currency,
          rzpuserid
        }
        // , () => this.selectPlan(planListResp.data, selectedPlanIndex)
        );
      }
      else {
        console.log('Plans are not available.')
      }
    } else {
      console.log(planListResp.message)
    }
  }





  render() {
    return <>
      {
        <ViewPlan planList={this.state.planList} loader={this.state.loader} />
      }
    </>
  }
}

export default ViewPlanContainer
