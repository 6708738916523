import { initialState } from '../initialState'
import { FUND_MODAL } from '../actions/action'

const fund = (state = initialState.fund, action) => {
	switch (action.type) {
		case FUND_MODAL: {
			return { ...state, ...action.payload }
		}
		default: {
			return state
		}
	}
}

export default fund