import React from 'react'
import { Row,Form} from 'react-bootstrap'
import { Formik } from 'formik'
import * as yup from 'yup'
import OutsideClickHandler from 'react-outside-click-handler'
import { generateOtp,geoLocation ,LeadCapture,getPartnerCountries} from '../../api'
import { ThemeContext } from '../../Context/ThemeContext'
import queryString from 'query-string'
import { isArray } from 'lodash'
import styles from './SignUp.module.css'
import SignUpButton from './SignUpButton'
import SignUpInput from './SignUpInput'
import EmailSvg from '../../public/images/newOnboarding/Email.svg'
import ReferralSvg from '../../public/images/newOnboarding/Referral.svg'
import SignUpDropDown from './SignUpDropDown'
import DropDownButton from './DropDownButton'
import { withRouter } from 'react-router-dom';


class SignUp extends React.Component {
	constructor(props) {
		super(props)
		const { formData} = this.props
		this.state = {
			email: formData.email ? formData.email : '',
      refCode: formData.userReferralCode ? formData.userReferralCode : '',
      activeCountries: [],
      country:'',
      countryCode:'',
      flagCode:formData.flagCode ? formData.flagCode : '',
      countryError:'',
      showDropDown:false,
      commonError:'',
      emailError:'',
      loading:false,
      initialized: false,
		}
    this.myRef = React.createRef();
    this.checkThemeLoadingInterval = null;
	}

  static contextType = ThemeContext


  componentDidMount() {
    this.checkThemeLoading();
    this.checkThemeLoadingInterval = setInterval(this.checkThemeLoading, 100); // Check every 100ms
  }

  componentWillUnmount() {
    if (this.checkThemeLoadingInterval) {
      clearInterval(this.checkThemeLoadingInterval);
    }
  }

  checkThemeLoading = () => {
    const { isThemeLoading } = this.context;
    if (!isThemeLoading && !this.state.initialized) {
      console.log('Component initialized');
      clearInterval(this.checkThemeLoadingInterval);
    }
  }

  componentDidMount = () => {
    const { singUpValid, siteFeatures, partnerConfig} = this.context
      this.previousContext = this.context;
      let queryUrl = queryString.parse(window.location.search, {
        parseNumbers: false
      })
      let countryList = partnerConfig?.[0]?.countryList || this.props.activeCountries;
      if (countryList) {
        this.setState({
          activeCountries: countryList
        });
      }

      // if(!singUpValid){
      //   this.redirectToAnotherPage();
      // }
      if (!singUpValid && siteFeatures !== "") {
        this.redirectToAnotherPage();
      }
      if(queryUrl && queryUrl['utm_source']){
        const utmSource = isArray(queryUrl['utm_source']) ? queryUrl['utm_source'][0] : queryUrl['utm_source']
        // eslint-disable-next-line no-undef
        webengage.user.setAttribute('UTM_Source_Reg1',utmSource)
      }
        if(queryUrl && queryUrl['utm_medium']){
          const utmMedium = isArray(queryUrl['utm_medium']) ? queryUrl['utm_medium'][0] : queryUrl['utm_medium']
          // eslint-disable-next-line no-undef
        webengage.user.setAttribute('UTM_Medium_Reg1',utmMedium)
      }
      if(queryUrl && queryUrl['utm_campaign']){
        const utmCompaign = isArray(queryUrl['utm_campaign']) ? queryUrl['utm_campaign'][0] : queryUrl['utm_campaign']
        // eslint-disable-next-line no-undef
      webengage.user.setAttribute('UTM_Campaign_Reg1',utmCompaign)
    }
      // eslint-disable-next-line no-undef
      webengage.user.setAttribute('UTM_Landingpage_Reg1',window.location.href)
      // eslint-disable-next-line no-undef
      webengage.track("Prospect")
    //  this.getCurrentCountryCode()
  }

  redirectToAnotherPage = () => {
    this.props.history.push('/signin')
  };

  getCountryList = async(list) => {
      let countryData = []
			list.map(item => {
				if (item) {
					const temp = {
						value:
							item['countryName'],
						label:
							item['countryName'],
						code: item['country'],
						code2:
							item['countryCode2']
					}
					countryData.push(temp)
				}
			})
		return countryData
	}


  componentDidUpdate = async(prevProps, prevState) => {
    const { singUpValid: currentSingUpValid, siteFeatures: currentSiteFeatures, partnerConfig, isThemeLoading} = this.context;
    if (prevProps !== this.props && isThemeLoading) {
      console.log('isThemeLoading',isThemeLoading);
      const { formData = {}, activeCountries = [] } = this.props;
      const { email = '', refCode = '' } = formData;
      this.setState({ email, refCode: refCode });
      try {
        const countryList = await partnerConfig?.[0]?.countryList;
        if (countryList) {
          let countryData = await this.getCountryList(countryList);
          this.setState({
            activeCountries: countryData,
            country: "",
            flagCode: "",
            loading: false,
          });
        } else {
          this.setState({ activeCountries: activeCountries, loading: false });
          this.getCurrentCountryCode()
        }
      } catch (error) {
        console.error('Error fetching country list:', error);
        this.setState({ loading: false });
      }
    }
    const { singUpValid: prevSingUpValid, siteFeatures: prevSiteFeatures } = this.previousContext || {};
    if (prevSingUpValid !== currentSingUpValid || prevSiteFeatures !== currentSiteFeatures) {
      if (!currentSingUpValid && currentSiteFeatures !== "") {
        this.redirectToAnotherPage();
      }
    }
    this.previousContext = this.context;
  }

  getCurrentCountryCode = () => {
    geoLocation().then(response => {
      if (response && response.code === 200 && response.data) {
         const {country_code = '',name = ''} = response.data
         getPartnerCountries('residence').then(countryResponse => {
          if (countryResponse && countryResponse.code === 200 ) {
             const list = countryResponse.data;
             const filterCountry = list.filter(item => item.countryCode2  === country_code)
             if(filterCountry.length){
              this.setState({country:name,countryCode:country_code,flagCode:country_code})
            }
          }
        })}
    })
  }

  emailValidation = (event, handleChange) => {
    this.setState({ email: event.target.value.toLowerCase(),commonError:'' })
    handleChange(event)
  }

  keyPressHandler = (event,submitCallback) => {
    if (event.which === 13) {
      submitCallback()
    }
  }

  render() {
    const { userReferralCode } = this.context
    return (
      <>
     <Row className={styles.title}>Let’s get started!</Row>
        <Row className={styles.subTitle}>Select your country of residence and enter a valid email address. That’s where you’ll receive a confirmation code.</Row>
				<Formik
					initialValues={{
						email: this.state.email,
            refCode: this.state.refCode,
					}}
					enableReinitialize
					validationSchema={yup.object({
						email: yup
							.string()
							.email('Enter a valid email such as example@email.com')
							.required('Email is a required field'),
            refCode:yup.string()
          })}
          onSubmit={values => {
            const { email,refCode} = values
            this.setState({commonError:'',loading:true})
             if(this.state.country !== ''){
              generateOtp(email.toLowerCase())
                .then(response => {
                  if (response.code === 200) {
                    const { data: { hashID = '' } = {} } = response
                    const data = {
                      email: email.toLowerCase(),
                      country:this.state.country,
                      countryCode:this.state.countryCode,
                      flagCode:this.state.flagCode,
                      userReferralCode:refCode,
                      hashID
                    }
                    let leadData = this.props.leadData;
                    if (leadData) {
                      leadData = Object.assign(leadData, {
                        email: email
                      })
                      LeadCapture(leadData);
                    }
                    this.props.updateFormData(data)
                    this.props.changeCurrent('VerifyEmail')
                  } else {
                    this.setState({commonError:
                      'Failed to generate OTP'
                    })
                  }
                })
                .catch(error => {
                   if(error && error.data.code === 400){
                     this.setState({emailError:error.data.message})
                   }
                   else{
                    this.setState({commonError:
                      'Failed to generate OTP'
                    })
                   }
                })
              }else{
                this.setState({countryError:'Country of Residence is required'})
              }
              this.setState({loading:false})
					}}>
					{({
						handleChange,
						handleBlur,
						handleSubmit,
						values,
						dirty,
						touched,
						errors,
            isSubmitting
					}) => {
						return (
							<>
               <Row className={styles.inputs} style={{display:'block'}}>
                  <div className={styles.label}>Country of Residence</div>
                   <div>
                      <DropDownButton
                         onClick={() => this.setState({showDropDown : !this.state.showDropDown,countryError:''})}
                         showDropDown={this.state.showDropDown}
                         error={this.state.countryError}
                         >
                          {this.state.flagCode ? <> <img className={styles.flagImgCode}
                          src={`${process.env.REACT_APP_ASSESTS}/flags/${this.state.flagCode}.svg`}></img>
                           <span className={styles.countryLabel}>{this.state.country}</span>
                          </> :
                           <span className={styles.selectDropDown}>Select</span>  }
                      </DropDownButton>
                      <OutsideClickHandler onOutsideClick={() => this.setState({showDropDown:false})}>
                      <SignUpDropDown
                         data={this.state.activeCountries}
                         value={this.state.country}
                         showDropDown={this.state.showDropDown}
                         onChange={(item) => {
                          const getCountryCode = item.value
                          this.setState({country:item.label,countryCode:getCountryCode,showDropDown:false,flagCode:item.code2.toUpperCase()})
                           }}
                         placeholder='Country'
                      />
                      </OutsideClickHandler>
                    </div>
                    <div className={styles.errorState}>{this.state.countryError}</div>
                </Row>
								<Row className={styles.inputs}>
                    <SignUpInput
                        label='Email Address'
                        placeholder = 'Enter Email Address'
                        type='text'
                        name='email'
                        tabIndex='1'
                        value={values.email}
                        onChange={event =>
                          this.emailValidation(event, handleChange)
                        }
                        // onBlur={event => handleBlur(event)}
                        onKeyPress={(e) => this.keyPressHandler(e,handleSubmit)}
                        isInvalid={(touched.email && errors.email) || this.state.emailError}
                        isValid={(touched.email && !errors.email && this.state.emailError === '')}
                        errorMessage={errors.email || this.state.emailError}
                        icon={EmailSvg}
                    />
                </Row>
                {userReferralCode ?
                <Row className={styles.inputs}>
                    <SignUpInput
                        label='Referral Code'
                        placeholder = 'Enter Referral Code (Optional)'
                        type='text'
                        name='refCode'
                        tabIndex='2'
                        value={values.refCode}
                        onChange={(e) => {
                          this.setState({refCode:e.target.value})
                          handleChange(e)
                        }}
                        // onBlur={handleBlur}
                        errorMessage={errors.refCode}
                        icon={ReferralSvg}
                    />
                </Row> : ''}
								<Row className={styles.buttonMargin}>
                    <div className={styles.errorState} style={{paddingBottom:'10px'}}>{this.state.commonError}</div>
										<SignUpButton
											title='Sign Up'
                      tabIndex='3'
											onClick={handleSubmit}
                      loading={this.state.loading}
											// disabled={!disableButton || isSubmitting}
										/>
								</Row>
							</>
						)
					}}
				</Formik>
			</>
		)
	}
}

export default withRouter(SignUp)
