import React, { useContext, useState } from "react"
import { Spinner } from "react-bootstrap"
import { putTransaction } from "../../../../api"
import { AddFundContext } from "../../AddFundContext"
import styles from "./common.module.css"
import AlertErrorIcon from "../../../../public/images/alertError.svg"

const Transaction =()=>{
    const {setSubPage, hashID, setOnErrorRetry, setShowGenericError, SUBPAGE} = useContext(AddFundContext)
    const [tranxID, setTranxID] = useState('')
    const [error, setError] = useState(false)
    const [validate, setValidate] = useState(false)
    const [loadingContinue, setLoadingContinue] = useState(false)
    const [loadingSkip, setLoadingSkip] = useState(false)
    const submitData = async(showGenericError)=>{
        let success = true
        try{
            const res = await putTransaction(hashID, tranxID)
            if(res && res.code === 200){
                setSubPage(SUBPAGE.SUCCESS)
            }else{
                success=false
            }
        }catch(error){
            success=false
        }
        if(success && showGenericError){
            setShowGenericError(false)
            setOnErrorRetry(null)
        }else if(!success){
            setOnErrorRetry(submitData)
            setShowGenericError(true)
        }
    }
    const onContinue = async(transReq)=>{
        if(!validate){
            setValidate(true)
        }
       
        if(!loadingContinue){
            if(transReq && !tranxID){
                setError(true)
                return
            }
            if(transReq)
                setLoadingContinue(true)
            else
                setLoadingSkip(true)
            await submitData()
            if(transReq)
                setLoadingContinue(false)
            else
                setLoadingSkip(false)
        }    
        
    }
    const onChange = (e)=>{
        setTranxID(e.target.value)
        if(validate){
            if(!e.target.value){
                setError(true)
            }else{
                setError(false)
            }
        }
    }
    return (<>
        <label className={styles.label}>Reference / Transaction Number</label>
        <div className={`${styles.input} ${error && styles.errorInput}`}>
            <input className="border-0 w-100" placeholder="Enter Reference / Transaction number" value={tranxID} onChange={onChange}/>
            {error&&<img src={AlertErrorIcon}/>}
        </div>
        {error&&<span className={styles.error}>This Field is required</span>}
        <div className={styles.footerContainer}>
            <button className={styles.continueBtn} onClick={()=>onContinue(true)}>
                {loadingContinue && <Spinner animation="border" className={styles.spinnerColor}/>}
                Continue
            </button>
            <button className={styles.tertiaryBtn} onClick={()=>onContinue(false)}>
                {loadingSkip && <Spinner animation="border" className={styles.spinnerColorBlue}/>}
                Skip
            </button>
        </div>
    </>)
}

export default Transaction