import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import SkeletonElement from './SkeletonElement'
import Shimmer from './Shimmer'

const ThemeSkeleton = () => {
	return (
		<Card className='skeletonCard'>
			<Card.Body>
				<>
					<Row className='pb-4'>
						<Col md={12}>
							<SkeletonElement
								height='50px'
								width='150px'></SkeletonElement>
						</Col>
						<Col>
							<SkeletonElement width='70px' height='20px'></SkeletonElement>
							<SkeletonElement width='120px' height='15px'></SkeletonElement>
						</Col>
					</Row>
					<Row>
						<Col md={5}>
							{/* <SkeletonElement width='70px' height='20px'></SkeletonElement>
							<SkeletonElement width='40px' height='15px'></SkeletonElement> */}
						</Col>
						<Col md={12}>
							<SkeletonElement height='50px' width='100%'></SkeletonElement>
						</Col>
					</Row>
					<Shimmer />
				</>
			</Card.Body>
		</Card>
	)
}

export default ThemeSkeleton
