import React from "react";
import styles from './funds.module.css'
import Lottie from 'react-lottie-player';
import SuccessLottie from "../../public/lottie/success.json"
import { Modal } from "react-bootstrap"


const OpenEmailPopUp = (props) => {
  return(
    <Modal show={props.isShow}>
      <Modal.Header closeButton onClick={()=>props.setIsShow(!props.isShow)}>
        <Modal.Title className={styles.emailSendImage}>
        <Lottie
          rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
          loop={false}
          animationData={SuccessLottie}
          play
          style={{ width: 200, height: 200 }}
        />
        <div className={styles.successDesc}>
          The SWIFT copy for this transaction has been sent to your registered email ID.
        </div>
        </Modal.Title>
      </Modal.Header>
    </Modal>
  )
}


export default OpenEmailPopUp
