import React, {Component} from "react";
import './mobileModal.css'

class MobileTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      header: [],
      tableData: []
    }
  }

  componentDidMount(){
    const yearSet = new Set()
    const tagNameSet = new Set()
    const rowData = []
    const data = []

      // this.tabelRef.current.scrollTo(0, 0)
      this.props.data && this.props.data.map((item, index) => {
        const headerYear = `FY ${item._id.fiscalYear}`
        yearSet.add(headerYear)
        item.entries.map((entry) => {
          tagNameSet.add(entry.tagName)
        })
      })
      const tagArray = [...tagNameSet]
      tagArray.map((tag, index) => {
        this.props.data.map((item, index) => {
          item.entries.map((entry) => {
            if (entry.tagName === tag) {
              const objKey = `FY ${entry.fiscalYear}`
              const value = entry.value == 0 ? '--' : entry.value
              let obj = {
                tagName: tag,
                [objKey]: value
              }

              rowData.push(obj)
            }
          })
        })
      })
      for (let i = 0; i < tagArray.length; i++) {
        const filtered = rowData.filter((item) => {
          return item.tagName === tagArray[i]
        })
        data.push(Object.assign({}, ...filtered))
      }
      this.setState({ header: [...yearSet], tableData: data })
  }

  componentDidUpdate(prevProps) {
    if (this.props.current === 'annually') {
      const yearSet = new Set()
      const tagNameSet = new Set()
      const rowData = []
      const data = []
      if (prevProps.data !== this.props.data) {
        this.props.data.map((item, index) => {
          yearSet.add(item._id.fiscalYear)
          item.entries.map((entry) => {
            tagNameSet.add(entry.tagName)
          })
        })
        const tagArray = [...tagNameSet]
        tagArray.map((tag, index) => {
          this.props.data.map((item, index) => {
            item.entries.map((entry) => {
              if (entry.tagName === tag) {
                const value = entry.value == 0 ? '--' : entry.value
                let obj = {
                  tagName: tag,
                  [entry.fiscalYear]: value
                }

                rowData.push(obj)
              }
            })
          })
        })
        for (let i = 0; i < tagArray.length; i++) {
          const filtered = rowData.filter((item) => {
            return item.tagName === tagArray[i]
          })
          data.push(Object.assign({}, ...filtered))
        }
        this.setState({header: [...yearSet], tableData: data})
      }
    }
    if (this.props.current === 'quarterly') {
      const yearSet = new Set()
      const entriesArray = []
      const tagNameSet = new Set()
      const rowData = []
      const data = []
      if (prevProps.data !== this.props.data) {
        this.props.data.map((item) => {
          const yearHeader = `${item._id.fiscalPeriod} ${item._id.fiscalYear}`
          yearSet.add(yearHeader)
          item.entries.map((entry) => {
            tagNameSet.add(entry.tagName)
          })
        })
        const tagArray = [...tagNameSet]
        tagArray.map((tag, index) => {
          this.props.data.map((item, index) => {
            item.entries.map((entry) => {
              if (entry.tagName === tag) {
                const objKey = `${entry.fiscalPeriod} ${entry.fiscalYear}`
                const value = entry.value == 0 ? '--' : entry.value
                let obj = {
                  tagName: tag,
                  [objKey]: value
                }
                rowData.push(obj)
              }
            })
          })
        })

        for (let i = 0; i < tagArray.length; i++) {
          const filtered = rowData.filter((item) => {
            return item.tagName === tagArray[i]
          })
          data.push(Object.assign({}, ...filtered))
        }
        this.setState({header: [...yearSet], tableData: data})
      }
    }
  }

  render() {
    this.state.header.sort().reverse()
    if (this.state.header.length > 0) {
      this.state.header.sort((a, b) => {
        const slicefirst = a.toString().slice(a.length - 2)
        const slicesecond = b.toString().slice(b.length - 2)
        return parseInt(slicesecond) - parseInt(slicefirst)
      })
    }
    return (
      <div
        className="table-responsive"
        style={{
          marginTop: "5%",
        }}
      >
        <table className="table mobile-table table-bordered" style={{width: "200%"}}>
          <thead className="table-header mobile-table-header">
          <tr>
            <th
              scope="col"
              className='stickyHeader finTableHeader mobileTableWidth'
              style={{zIndex: '1000'}}
            >
              {this.props.current === 'quarterly' ? 'Quarter' : 'Annual'}
            </th>
            {this.state.header.map((item, index) => {
              return <th scope="col" key={index} className='stickyHeader finTableHeader'>{item}</th>
            })}
          </tr>
          </thead>
          <tbody>
          {this.state.tableData.map((item, index) => {
            return (
              <tr>
                <td className='stickyHeader finTableData overflowTag'>{item.tagName}</td>
                {this.state.header.map((headerValue) => {
                  const spiltArray = item[headerValue] ? item[headerValue].split('.') : ''
                  const value = spiltArray.length > 0 ? parseInt(spiltArray[1]) > 0 ? item[headerValue] : spiltArray[0] : ''
                  return <td
                    className='mobileTableWidth finTableData'>{value ? (value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) : '--'}</td>
                })}
              </tr>
            )
          })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default MobileTable;
