import { MY_NOTIFICATION } from './action'
import { getNotification } from '../../api'


const getAllNotification = () => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			getNotification(1).then(response => {
        if(response && response.data.code === 200 && response.data.data.length > 0){
					resolve(dispatch({ type: MY_NOTIFICATION, payload: response.data.data }))
				}else {
          resolve(dispatch({ type: MY_NOTIFICATION, payload: []}))
        }
			})
		})
	}
}

export default getAllNotification

