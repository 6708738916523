import React, { useEffect, useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import styles from '../Profile.module.css'
import InputLabel from '../../InputLabel/InputLabel'
import ProfileCheckBox from './ProfileCheckBox'
import ProfileDropdown from './ProfileDropDown'
// import axios from 'axios'
import { setUserInfoSession } from '../../../common'
import Select from 'react-select';
import { map, keyBy } from 'lodash'
import { updateProfile } from '../../../api';
import { employeeTypeOptions, employeePositionsOptions, employmentStatusOptions } from '../../AccountSetUp/Forms/FinancialDetail/FinancialData'



const customStyle = () => (
  {
    container: provided => ({
      ...provided,
      backgroundColor:'#efeff1',
      color:'black',
      width: '100%',
      // top: '50%',
      borderRadius:'8px',
      zIndex:10
    }),
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        color: isSelected || isFocused ? 'black' : 'black', // setting
        backgroundColor: isSelected || isFocused ? '#EFEFF1' : 'white',

        ':active': {
          ...styles[':active'],
          backgroundColor: 'white'
        }
      }
    },
    singleValue: styles => ({
      ...styles,
      color: '#1e1f24',
    }),
    indicatorSeparator: provided => ({ ...provided, visibility: 'hidden' }),
    control: base => ({
      ...base,
      borderWidth: 0,
      borderRadius: 8,
      height: 48,
      fontSize: 14,
      color: '#9096a8',
      backgroundColor:'#EFEFF1',
      border: 0,
      boxShadow: 'none',
      '&:hover': {
        border: 0
      },
    }),
    menu: base => ({
      ...base,
      borderRadius: 8,
    }),
  })

  const errorCustomStyle = () => (
    {
      container: provided => ({
        ...provided,
        backgroundColor:'#efeff1',
        color:'black',
        width: '100%',
        // top: '50%',
        borderRadius:'8px',
        zIndex:10
      }),
      option: (styles, { isFocused, isSelected }) => {
        return {
          ...styles,
          color: isSelected || isFocused ? 'black' : 'black', // setting
          backgroundColor: isSelected || isFocused ? '#EFEFF1' : 'white',

          ':active': {
            ...styles[':active'],
            backgroundColor: 'white'
          }
        }
      },
      singleValue: styles => ({
        ...styles,
        color: '#1e1f24',
      }),
      indicatorSeparator: provided => ({ ...provided, visibility: 'hidden' }),
      control: base => ({
        ...base,
        borderWidth: 0,
        borderRadius: 8,
        height: 48,
        fontSize: 14,
        color: '#9096a8',
        backgroundColor:'#EFEFF1',
        border: '1px solid #e61446',
        boxShadow: 'none',
        '&:hover': {
          border: 0
        },
      }),
      menu: base => ({
        ...base,
        borderRadius: 8,
      }),
    })



const EditEmployment = props => {
	const [status, setStatus] = useState(props.status && props.status !== '--' ? props.status :'')
	const [statusLabel] = useState(props.status)
  const [business, setBusiness] = useState(props.business ? props.business.value : '')
  const [position, setPosition] = useState(props.position ? props.position.value : '')

	const [employeeBroker, setEmployeeBroker] = useState(
		props.employeeBroker === 'Yes' ? true : false
	)
	const [employer, setEmployer] = useState(
		props.employerCompany && props.employerCompany !== '--' ? props.employerCompany : ''
	)
	const [companyDirector, setCompanyDirector] = useState(
		props.companyDirector === "Yes" ? true : false
	)
	const [directorName, setDirectorName] = useState(
		props.directorOf ? props.directorOf : ''
	)
  const [employerCompany, setEmployerCompany] = useState(
		props.employerCompany ? props.employerCompany : ''
	)
  const [employerCountryError, setemployerCountryError] = useState('')
	const [employedOrSelfEmployedCheck, setEmployedOrSelfEmployedCheck] =
		useState(
			props.status === 'EMPLOYED' || props.status === 'SELF_EMPLOYED' || props.status === 'Self Employed / Business Owner' || props.status === 'Employed'
				? true
				: false
		)
  const [businessError, setBusinessError] = useState('')
  const [positionError, setPositionError] = useState('')

	// const [employerNameError, setEmployerError] = useState('')
	const [directorNameError, setDirectorNameError] = useState('')
	const [employerNameError, setEmployerNameError] = useState('')
  const [countryOptions, setCountryOptions] = useState([]);
  const [sourceOfFunds, setSourceOfFunds] = useState(props.sourceOfFunds)
  const [sourceOfFundsCode,setSourceOfFundsCode] = useState(props.sourceOfFundsCode && props.sourceOfFundsCode !== '--' ? props.sourceOfFundsCode : "");
  const [successOrErrorMessage, setSucessOrErrorMessage] = useState('')
  const[buttonDisabled, setButtonDisabled] = useState(false)

  const checkBoxChange = () => {
		setEmployeeBroker(true)
	}

  useEffect(()=>{
    if(props.countryOptions && props.countryOptions.length > 0) {
    let opt = [...new Set(map(props.countryOptions, ({country, countryName}) => {
          return { label: countryName, value: country}
    }))]
    setCountryOptions(opt)
    }
  },[])

	const directorCheckbox = () => {
		setCompanyDirector(true)
	}

	// const statusOption = [
	// 	{ value: 'EMPLOYED', label: 'Employed' },
	// 	{ value: 'RETIRED', label: 'Retired' },
	// 	{ value: 'STUDENT', label: 'Student' },
	// 	{ value: 'UNEMPLOYED', label: 'Not Employed' },
	// 	{ value: 'SELF_EMPLOYED', label: 'Self Employed / Business Owner' }
	// ]

	// const businessOption = [
	// 	{
	// 		value: 'AGRICULTURE',
	// 		label: 'Agriculture, Forestry, Fishing and Hunting'
	// 	},
	// 	{ value: 'MINING', label: 'Mining, Quarrying, and Oil and Gas Extraction' },
	// 	{ value: 'UTILITIES', label: 'Utilities' },
	// 	{ value: 'CONSTRUCTION', label: 'Construction' },
	// 	{ value: 'MANUFACTURING', label: 'Manufacturing' },
	// 	{ value: 'WHOLESALE', label: 'Wholesale Trade' },
	// 	{ value: 'RETAIL', label: 'Retail Trade' },
	// 	{ value: 'TRANSPORT', label: 'Transportation and Warehousing' },
	// 	{ value: 'INFORMATION', label: 'Information' },
	// 	{ value: 'FINANCE', label: 'Finance and Insurance' },
	// 	{ value: 'REAL_ESTATE', label: 'Real Estate and Rental and Leasing' },
	// 	{
	// 		value: 'PROFESSIONAL',
	// 		label: 'Professional, Scientific, and Technical Services'
	// 	},
	// 	{ value: 'MANAGEMENT', label: 'Management of Companies and Enterprises' },
	// 	{ value: 'EDUCATION', label: 'Educational Services' },
	// 	{ value: 'HEALTH', label: 'Health Care and Social Assistance' },
	// 	{ value: 'ART', label: 'Arts, Entertainment, and Recreation' },
	// 	{ value: 'FOOD', label: 'Accommodation and Food Services' },
	// 	{ value: 'PUBLIC', label: 'Public Administration' },
	// 	{
	// 		value: 'WASTE',
	// 		label:
	// 			'Administrative and Support and Waste Management and Remediation Services'
	// 	}
	// ]

	const checkingStatus = event => {
		if (event === 'EMPLOYED' || event === 'SELF_EMPLOYED' || event === 'Self Employed / Business Owner' || event === 'Employed') {
			return setEmployedOrSelfEmployedCheck(true)
		} else {
      setEmployedOrSelfEmployedCheck(false)
      setBusiness('')
      setPosition('')
      setEmployeeBroker(false)
      setEmployer('')
      setCompanyDirector(false)
      setDirectorName('')
      // setSourceOfFunds('')
		}
	}

  useEffect(() => {
    if(props.status){
      checkingStatus(props.status)
    }
  }, [props.status])

	const handleSubmit = e => {
		const update = {
			type: 'EMPLOYMENT_INFO',
			body: {
				// status: status,
				// company : employer,
				// type: employedOrSelfEmployedCheck ? business : null ,
				// broker: employeeBroker,
				// directorOf: directorName

				employmentStatus: status,
				// employmentCompany: employerCompany,
        employmentType: employedOrSelfEmployedCheck ? business : '',
        // employmentPosition: employedOrSelfEmployedCheck ? position : '',

				isBroker: employeeBroker,
        directorOf: directorName,
        employmentCompany:employerCompany,
        // director: companyDirector,
        employmentCountry: (sourceOfFundsCode && sourceOfFundsCode !== "--")  ? sourceOfFundsCode : '',
        otp:props.otp,
        hashId:props.hashId
			}
    }


    // new Api call
    updateProfile(update).then(response=>{
      props.onSave()
      if(response.data.code === 200){
        const data = {
          employmentStatus:status,
          employerCompany:employerCompany,
          employerBusiness:business,
          // employmentPosition:position,
          employerIsBroker:employeeBroker,
          employerCountryID:sourceOfFundsCode,
          director:companyDirector,
          directorOf:directorName
        }
        setUserInfoSession(data);
        props.setDetails(data)
      }else {
        setSucessOrErrorMessage('Something Went Wrong!')
      }
    }).catch(function (error) {
        if(error.response.data.code === 440){
          setButtonDisabled(true)
        }
        setSucessOrErrorMessage(error.response.data.message)
      console.log('basicError', error)
    })
	}

	const ApiCall = () => {
		if (status === 'EMPLOYED' || status === 'SELF_EMPLOYED') {
			if (
        business !== '' &&
        // position !== '' &&
				// employerCompany !== '' &&
        // sourceOfFunds !== '' &&
				employeeBroker === false &&
				companyDirector === false
			) {
				handleSubmit()
				setDirectorNameError('')
				setEmployerNameError('')
        setBusinessError('')
        setPositionError('')
        setemployerCountryError('')
			} else if (
        business !== '' &&
        // position !== '' &&
				// employerCompany !== '' &&
        // sourceOfFunds !== '' &&
				employeeBroker === true &&
        employerCompany !== '' &&
				companyDirector &&
				directorName !== ''
			) {
				handleSubmit()
				setDirectorNameError('')
				setEmployerNameError('')
        setBusinessError('')
        setPositionError('')
        setemployerCountryError('')
			} else if (
        business !== '' &&
        // position !== '' &&
				// employerCompany !== '' &&
        // sourceOfFunds !== '' &&
				employeeBroker === true &&
        employerCompany !== '' &&
				companyDirector === false
			) {
				handleSubmit()
				setDirectorNameError('')
				setEmployerNameError('')
        setBusinessError('')
        setPositionError('')
        setemployerCountryError('')
			} else if (
        business !== '' &&
        // position !== '' &&
				// employerCompany !== '' &&
        // sourceOfFunds !== '' &&
				employeeBroker === false &&
				companyDirector === true &&
				directorName !== ''
			) {
				handleSubmit()
				setDirectorNameError('')
				setEmployerNameError('')
        setemployerCountryError('')
			}
      else if (business === '') {
				setBusinessError('Buinsess cannot be empty')
      }
      // else if (position === '') {
			// 	setPositionError('Position cannot be empty')
      // }
      else if (companyDirector && directorName === '') {
				setDirectorNameError('Director Name Cannot be empty')
			}
      else if (employeeBroker && employerCompany === '') {
				setEmployerNameError('Company Name Cannot be empty')
			}
      // else if (employerCompany === '') {
			// 	setEmployerNameError('Employer Name cannot be empty')
			// }
      else if(sourceOfFunds === '') {
        setemployerCountryError('Country of source of funds cannot be empty')
      }
		} else {
			handleSubmit()
		}
	}

  const goBack = () => {
    props.setIsVerifyOtp(false)
    props.sendOtpApi()
  }
// console.info('sourceOfFunds',sourceOfFunds)
// console.info("sourceOfFundsCode",sourceOfFundsCode)
  const sourceOfFundsDiv = <Row className={`${styles.inputRowsEdit}`}>
  <Col className={`m-0 ${styles.dropdownInputFont}`}>
    <div className={`mb-1 ${styles.profileInputLabel}`}>
    Country of source of funds
    </div>
    <Select
    // placeholder='Country of source of funds'
    value={sourceOfFunds}
    onChange={(value) => {setSourceOfFunds(value ? value :null)
    setSourceOfFundsCode(value ? value.value :null)
  }
  }
    options={countryOptions}
    styles={employerCountryError ? errorCustomStyle() : customStyle()}
    isSearchable={true}
    isClearable={sourceOfFundsCode ? true : false}
    />
    <p className={styles.profileErrorText}>{employerCountryError}</p>
  </Col>
</Row>
  return (
		<>
			<div style={{ width: '400px' }} className={styles.profileModalBody}>
				<Row className={`p-0 mt-0 ${styles.basicDetail}`}>
					<Col className={`p-0 ${styles.modalHeadingText}`}>Employment</Col>
				</Row>
				<Row className={`${styles.inputRowsEdit}`}>
					<Col>
						<ProfileDropdown
							label='Employment Status'
							placeholder='Status'
							name='Status'
							data={employmentStatusOptions}
							labelValue={statusLabel}
							value={status}
							onChange={value => {
								const event = value
								setStatus(event)
								checkingStatus(event)
								// handleChange(event)
							}}
							className={styles.inputHeight}
						/>
					</Col>
				</Row>
				{employedOrSelfEmployedCheck ? (
					<>
						<Row className={`${styles.inputRowsEdit}`}>
							<Col>
								<ProfileDropdown
									label='Business'
									placeholder='Business'
									name='Business'
									data={employeeTypeOptions}
									labelValue={business}
									value={business}
									onChange={value => {
										const event = value
										setBusiness(event)
										// handleChange(event)
									}}
									className={styles.inputHeight}
								/>
								<p className={styles.profileErrorText}>{businessError}</p>
							</Col>
						</Row>
						{/* <Row className={`${styles.inputRowsEdit}`}>
							<Col>
								<div className={`mb-1 ${styles.profileInputLabel}`}>
									Company
								</div>
								<input
									label='Employer Name'
									placeholder='Company'
									name='employer'
									value={employerCompany}
									onChange={event => setEmployer(event.target.value)}
									className={
										employerNameError
											? styles.profileErrorInputBox
											: styles.profileInputBox
									}
								/>
								<p className={styles.profileErrorText}>{employerNameError}</p>
							</Col>
						</Row> */}
            {/* <Row className={`${styles.inputRowsEdit}`}>
							<Col>
								<ProfileDropdown
									label='Position'
									placeholder='Position'
									name='Position'
									data={employeePositionsOptions}
									labelValue={position}
									value={position}
									onChange={value => {
										const event = value
										setPosition(event)
										// handleChange(event)
									}}
									className={styles.inputHeight}
								/>
								<p className={styles.profileErrorText}>{positionError}</p>
							</Col>
						</Row> */}
            {sourceOfFundsDiv}
						{/* checkBox */}
						<Row className={styles.profileMarginCheckbox}>
							<Col>
								<ProfileCheckBox
									label='My employer is brokerage firm'
									id='brokerage'
									name='brokerage'
									onChange={checkBoxChange}
									isChecked={employeeBroker}
								/>
							</Col>
						</Row>
						{employeeBroker === true ? (
							<Row className={`${styles.inputRowsEdit}`}>
              <Col>
                <input
                  label='Company Name'
                  placeholder='Company Name'
                  name='employerCompany'
                  value={employerCompany}
                  className={
                    employerNameError
                      ? styles.profileErrorInputBox
                      : styles.profileInputBox
                  }
                  // onBlur={(event) => handleInputBox(event)}
                  onChange={event => setEmployerCompany(event.target.value)}
                />
                <p className={styles.profileErrorText}>{employerNameError}</p>
              </Col>
            </Row>
						) : (
							''
						)}
						{/* {employeeBroker === true ?
            <>
                <Row className={`${styles.inputRowsEdit}`}>
                    <Col>
                    <input
                    label='Employer Name'
                    placeholder='Official Names here'
                    name='employer'
                    value={employer}
                    onChange={event => setEmployer(event.target.value)}
                    className={employerNameError ? styles.profileErrorInputBox :styles.profileInputBox}/>
                    <p className={styles.profileInputBelowText}>Are you affiliated with or employed by a stock exchange, member firm of an exchange or FINRA, or a municipal securities broker-dealer?</p>
                    <p className={styles.profileErrorText}>{employerNameError}</p>
                    </Col>
                </Row>
            </>
            :
            "" } */}
						<Row className={styles.profileMarginCheckbox}>
							<Col>
								<ProfileCheckBox
									label='I am the director/Officer of a public company'
									id='director'
									name='director'
									onChange={directorCheckbox}
									isChecked={companyDirector}
								/>
							</Col>
						</Row>
						{companyDirector ? (
							<Row className={`${styles.inputRowsEdit}`}>
								<Col>
									<input
										label='Company director'
										placeholder='Company director'
										name='CompanyDirector'
										value={directorName}
										className={
											directorNameError
												? styles.profileErrorInputBox
												: styles.profileInputBox
										}
										// onBlur={(event) => handleInputBox(event)}
										onChange={event => setDirectorName(event.target.value)}
									/>
									<p className={styles.profileInputBelowText}>
										Is the account holder(s) a control person of a publicly
										traded company? A Director, Officer or 10% stock owner?
									</p>
									<p className={styles.profileErrorText}>{directorNameError}</p>
								</Col>
							</Row>
						) : (
							''
						)}
					</>
				) : <>{sourceOfFundsDiv}</>}

				{/* end */}
				{/* <Row className={`${styles.inputRowsEdit}`}>
                <Col>
                    <InputLabel
                        label='employee is broker?'
                        placeholder='employee is broker?'
                        name='employeeBroker'
                        value={employeeBroker}
                        onChange={event => setEmployeeBroker(event.target.value)}></InputLabel>
                </Col>
            </Row> */}
				{/* <Row className={`${styles.inputRowsEdit}`}>
                <Col>
                    <InputLabel
                        label='employer'
                        placeholder='employer'
                        name='employer'
                        value={employer}
                        onChange={event => setEmployer(event.target.value)}></InputLabel>
                </Col>
            </Row> */}
				{/* <Row className={`${styles.inputRowsEdit}`}>
                <Col>
                    <InputLabel
                        label='employer country'
                        placeholder='employer country'
                        name='sourceOfFunds'
                        value={sourceOfFunds}
                        onChange={event => setSourceOfFunds(event.target.value)}></InputLabel>
                </Col>
            </Row> */}
        <Row className={styles.SuccessOrErrorMessage}>{successOrErrorMessage && successOrErrorMessage.length > 0 ? successOrErrorMessage : "" }</Row>
        {buttonDisabled ?
        <Row>
          <Col className={`${styles.bottomButton}`}>
          <div className={styles.goBackButton} style={{marginLeft:"-50px"}} onClick={goBack}>
            Go Back
          </div>
          </Col>
        </Row> :
				<Row>
					<Col md={6} xs={6} className={`${styles.bottomButton}`}>
						<span onClick={props.handleClose}>Cancel</span>
					</Col>
					<Col md={6} xs={6} className={`${styles.saveBottomButton}`}>
						<Button
							className={`${styles.editSaveButton} themeButton`}
							onClick={ApiCall}
							disabled={!sourceOfFundsCode?true:false}
							style={{ backgroundColor: props.buttonColor }}>

							save changes
						</Button>
					</Col>
				</Row>}
			</div>
		</>
	)
}

export default EditEmployment
