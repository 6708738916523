import React from 'react'
import styles from './reviewWithDate.module.css'
import { Row, Col } from 'react-bootstrap'
import backImage from '../../public/images/back.png'

const ReviewWithDate = ({
	setModalData,
	data,
	displayData,
	back,
	pickupInformation,
	review,
	buttonColor,
  enterBrokerInformation,
  enterBrokerDetail,
	fund
}) => {
	return (
		<div className={styles.card}>
			<Row>
				<Col md={12}>
					<div
						className={styles.title}
						onClick={() => setModalData(back)}
						style={{ cursor: 'pointer' }}>
						<img src={backImage} />
						<span
							style={{
								paddingLeft: '20px'
							}}>{`Indian Bank Account`}</span>
					</div>
				</Col>
				<Col style={{ marginTop: '33px' }}>
					<div className={styles.review}>Review</div>
				</Col>
			</Row>
			<Row
				style={{
					margin: '0px',
					marginTop: '32px',
					borderBottom: '1px solid #EFEFF1',
					paddingBottom: '32px'
				}}>
				{displayData.map((trav, i) => {
					return (
						<Col key={i}>
							<div className={styles.header}>{trav.header}</div>
							<div className={styles.data}>{trav.data}</div>
						</Col>
					)
				})}
			</Row>
			<Row style={{ marginTop: '32px' }}>
				<Col md={4}>
					<div className={styles.pickup}>Pickup Address:</div>
					<div className={styles.description}>
						{`${pickupInformation.address1}, ${pickupInformation.address2}, ${pickupInformation.city}, ${pickupInformation.postalCode}, ${pickupInformation.state}, ${pickupInformation.country}`}
					</div>
				</Col>
				<Col className={styles.marginPickUp}>
					<div className={styles.pickup}>Pickup Date & Time:</div>
					<div
						className={
							styles.description
						}>{`${pickupInformation.pickUpDate}, ${pickupInformation.pickUpTime}`}</div>
					<div
						className={styles.change}
						onClick={() => setModalData(back)}
						style={{ cursor: 'pointer' }}>
						Change address & time
					</div>
				</Col>
			</Row>

			<Row className={styles.fixedDiv}>
				<Col>
					<button
						onClick={() => {
              setModalData(data)
              enterBrokerInformation()
            }}
						type='button'
						style={{ backgroundColor: buttonColor }}
						className={`btn btn-primary btn-sm ${styles.addfunds} themeButton`}>
						<span className={styles.addfundstext}>
							Proceed to Download Form
						</span>
					</button>
				</Col>
			</Row>
		</div>
	)
}

export default ReviewWithDate
