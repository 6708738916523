export const USER_SUMMARY = 'USER_SUMMARY'
export const USER_INFO = 'USER_INFO'
export const USER_PORTFOLIO = 'USER_PORTFOLIO'
export const USER_INVESTMENT = 'USER_INVESTMENT'
export const MARKET_DETAILS = 'MARKET_DETAILS'
// export const USER_PLANS_INFO = 'USER_PLANS_INFO'
export const COUNTRY_LIST = 'COUNTRY_LIST'
export const HOME_NEWS = 'HOME_NEWS'
export const TOP_STACKS = 'TOP_STACKS'
export const YEAR_HIGH = 'YEAR_HIGH'
export const YEAR_LOW = 'YEAR_LOW'
export const MY_NOTIFICATION = 'MY_NOTIFICATION'
export const FUND_MODAL = 'FUND_MODAL'
