import React, { useState, useEffect, useRef } from 'react'
import { Card, Col, Row, FormControl, Button } from 'react-bootstrap'
import PinInput from "react-pin-input"
import styles from './ReviewOrderCard.module.css'
import { Success } from '../../components'
import { tradeStock } from '../../api'
import { handleErrorResponse } from '../../errorResponse'
import {MARKET_TOUCHED,MARKET_TOUCHED_ORDER} from '../../constants'
import ResetPassword from '../Profile/ResetPassword/ResetPassword'
import { Link } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import infoIcon from '../../public/images/greyInfoIcon.svg'

const ReviewOrderCard = ({ setReviewFunc, stockName, livePrice, reviewData, stockData, isTickerCommAvailable, fundToolTip }) => {
  const [success, setSuccess] = useState(false)
  const [stockPrice, setStockPrice] = useState(null);   // current live price
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [spin, setSpin] = useState('');
  const [placingOrder, setPlacingOrder] = useState(false);

  const myRef = useRef()

  useEffect(() => {
    myRef && myRef.current && myRef.current.focus()
  },[])

  useEffect(()=>{
    showError && setSpin('', myRef.current.clear())
  }, [showError])



  useEffect(() => {
    if (livePrice && Object.keys(livePrice).length) {
      const { lastTrade = '' } = livePrice
      setStockPrice(lastTrade);
    }
  }, [livePrice])

  const placeOrder = async () => {
    setPlacingOrder(true)
    const orderObj = {
      category: "stock",
      type: reviewData.orderType.toUpperCase(),
      // amount: reviewData.totalCost,
      side: reviewData.side,
      symbol: stockName,
      spin
    }
    if(orderObj.type === MARKET_TOUCHED.toUpperCase()){
      orderObj.type = MARKET_TOUCHED_ORDER
    }
    if (orderObj.type === 'MARKET') {
      reviewData.investTypeSelect === 1 ? orderObj.quantity = reviewData.quantity : orderObj.amount = reviewData.totalCost
    }
    else {
      orderObj.price = reviewData.price
      if (orderObj.type === 'LIMIT') {
        orderObj.quantity = parseInt(reviewData.quantity)
      } else {
        orderObj.quantity = reviewData.quantity
      }
    }
    if(reviewData.extendedHour){
      orderObj.extendedHours = true
    }

    if(reviewData.isOtc){
      orderObj.isOTC = true
    }

    try {
      await tradeStock(orderObj)
      setSuccess(true)
      setPlacingOrder(false)
    } catch (error) {
      console.error(error)
      setPlacingOrder(false)
      if (error.data && error.data.code && error.data.code === 404) {
        if (error.data.message && error.data.message === 'Please set your PIN to start trading') {
          setErrorMessage(<span>Please <Link to="/profile?limitover=true">set</Link> your PIN to start trading</span>)
        }
      }
      else if(error.data && error.data.code && error.data.code === 409){
        setErrorMessage(error.data.message )
      }
     else {
        setErrorMessage(handleErrorResponse(error))
      }
      setShowError(true)
    }
  }

  const reset = () => {
    setErrorMessage('')
    setShowError(false)
  }

  const buttonClass = () => {
    let classname = "btn "
    if (reviewData.side === 'BUY') classname += `${styles.buyButton}`
    else classname += `${styles.sellButton}`
    if (!spin || showError)
      classname += ` ${styles.disable}`
    return classname
  }

  return success ? (
    <Success stockData={stockData} />
  ) : (
    <Card className={styles.reviewCard}>
      <Card.Body>
        <Row md='auto' className={`mt-3 ${styles.noteDiv}`}  onClick={() => setReviewFunc(false)}>
          <div>
            <i
              className='fa fa-chevron-left'
              aria-hidden='true'
              style={{ fontSize: '13px',cursor:'pointer' }}></i>
          </div>
          <div
            className={styles.backText}
            style={{cursor:'pointer'}}
            >
            back
          </div>
        </Row>
        <Row className={`mt-3 ${styles.reviewOrder}`}>Review order</Row>
        <Row className='mt-3'>
          <Col className={styles.stockName}>{stockName}</Col>
          <Col className={styles.stockPrice}>${stockPrice}</Col>
        </Row>
        <Row className='mt-3'>
          <Col className={styles.orderInfoTitle}>number of shares</Col>
          <Col className={styles.orderInfo}>{reviewData.quantity}</Col>
        </Row>
        <Row className={`mt-3 ${styles.borderGrey}`}>
          <Col className={styles.orderInfoTitle}>order type</Col>
          <Col className={styles.orderInfo}>{reviewData.orderType.toUpperCase()}</Col>
        </Row>
        <Row className='mt-3'>
          <Col className={styles.orderInfoTitle}>total cost</Col>
          <Col className={styles.orderInfo}>${reviewData.totalCost}</Col>
        </Row>
        <Row className='mt-3'>
          <Col xs={8} lg={8} className={styles.orderInfoTitle}>
            {isTickerCommAvailable ? <span>Brokerage Commission<OverlayTrigger
              placement="top"
              trigger={['hover', 'focus','click']}
              delay={{ show: 250, hide: 800 }}
              overlay={fundToolTip}
            >
              <img src={infoIcon} alt='icon' style={{width:"16.5px",marginLeft: "2px"}}/>
            </OverlayTrigger></span> :'Est. commission'}</Col>
          <Col xs={4} lg={4}  className={styles.orderInfo}>${reviewData.commission}</Col>
        </Row>
        <Row className={`mt-3 ${styles.borderGrey}`}>
          <Col className={styles.orderInfoTitle}>order value</Col>
          <Col className={styles.orderInfo}>${reviewData.orderValue}</Col>
        </Row>
        <Row className={`mt-3 ${styles.enterSpinText}`}>Enter PIN</Row>
        <Row className='mt-4 justify-content-center'>
          <PinInput
            length={4}
            initialValue=""
            secret
            ref={myRef}
            onChange={(value, index) => { reset() }}
            onPaste={(e) => {
              e.preventDefault()
              return false;
            }} onCopy={(e) => {
              e.preventDefault()
              return false;
            }}
            type="numeric"
            inputMode="number"
            inputStyle={{ ...style.spinInput }}
            // inputStyle={{...style.inputStyle}}
            inputFocusStyle={{ background: '#EFEFF1' }}
            onComplete={(value, index) => { setSpin(value) }}
            autoSelect={true}
            regexCriteria={/[0-9]/}
          />
        </Row>
        {showError ? (
          <Row style={{ marginTop: '16px' }}>
            <div className={styles.error}>
              <Row className='pt-2'>
                <Col xs={1} lg={1} className={styles.info}>
                  &#9432;
                </Col>
                <Col xs={10} lg={10} className={styles.errorText}>
                  {errorMessage}
                </Col>
              </Row>
            </div>
          </Row>
        ) : (
          ''
        )}
        <Row className={`mt-4 justify-content-center ${styles.reviewDiv}`}>
          <button
            // className={`btn ${styles.buyButton}`}
            className={buttonClass()}
            onClick={() => placeOrder()}
            disabled={spin === '' || showError || placingOrder}
          >
            {reviewData.side === 'BUY' ? 'Buy' : 'Sell'} {stockName}
          </button>
        </Row>
        <Row className={`mt-3 ${styles.noteDiv}`}>
          <div>
            <i className='fa fa-exclamation-circle' aria-hidden='true'></i>
          </div>
          <div className={styles.note}>
            please be aware that commisions might vary during order execution
          </div>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default ReviewOrderCard

const style = {
  spinInput: {
    border: 'none',
    textAlign: 'center',
    width: '40px',
    height: '48px',
    background: '#EFEFF1',
    borderRadius: '8px',
    marginLeft: '16px',
    // },
    // inputStyle:{
    fontFamily: 'Geomanist',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: '0.6px',
    color: '#D3D4DA'
  }
}
