import React from 'react'
import { Row, FormControl } from 'react-bootstrap'
import styles from './ProfileDropDown.module.css'

class ProfileDropdown extends React.Component {
	state = {
		showDropDown: false,
		value: this.props.value,
		showList: this.props.data,
		data: this.props.data,
		labelValue : this.props.labelValue
	}

	toggleDropDown = () => {
		this.setState({ showDropDown: !this.state.showDropDown })
	}

	openDropDown = () => {
		this.setState({ showDropDown: true })
	}

	onChange = event => {
		const updatedList = this.state.data.filter(item => {
			if (
				(item.value || '')
					.toLowerCase()
					.includes(event.target.value.toLowerCase())
			) {
				return item
			}
		})
		this.setState({ value: event.target.value, showList: updatedList })
	}

	onSelectItem = value => {
		this.props.onChange(value.value)
		this.setState({ showDropDown: false, value: value.value, labelValue: value.label })
	}

  container = React.createRef();
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.container.current &&
      !this.container.current.contains(event.target)
    ) {
      this.setState({
        showDropDown: false,
      });
    }
  };

	render() {
		const { className, placeholder, name, styleProps, disabled } = this.props
		return (
			<Row className={styles.dropDown} ref={this.container}>
				<div className={styles.ProfileDropDownContainer}>
                    <label className={`mb-1 ${styles.ProfileDropdownLabel} ${styleProps}`}>{placeholder}</label>
          <FormControl
						type='list'
						placeholder={placeholder}
						name={name}
						onFocus={this.openDropDown}
						className={styles.editProfileDropDown}
						value={this.state.labelValue}
						onChange={this.onChange}
            readOnly
            disabled={disabled}
					/>
				</div>
				<span className={styles.ProfielDropDownImage}>
					<img
						src={
							this.state.showDropDown
								? require('../../../public/images/chevronUp.svg')
								: require('../../../public/images/ProfileDropDownClose.svg')
						}
						onClick={this.toggleDropDown}></img>
				</span>
				{this.state.showDropDown ? (
					<>
						<div className={styles.profileBankDropdown}>
							<ul className={styles.dropdownCard}>
								{this.state.showList.map((item, index) => {
									return (
										<li
											className={styles.dropDownItem}
											onClick={() => this.onSelectItem(item)}
											key={index}>
											{item.label}
											<span className={styles.ProfileDropDownAlt}>
												{item.alt ? `(${item.alt})` : ''}
											</span>
										</li>
									)
								})}
							</ul>
						</div>
					</>
				) : (
					''
				)}
			</Row>
		)
	}
}
export default ProfileDropdown
