import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { Formik } from 'formik'
import * as yup from 'yup'
import DropDown from '../DropDown/DropDown'
import SignInputField from '../SignInputField/SignInputField'
import styles from './AccountFundingSetUp.module.css'

const BankDetail = ({
	selectedBank,
	bankOptions,
	bankAccountno,
	onChangeHandler
}) => {
	return (
		<>
			<Row className={styles.title}>Bank Details</Row>
			<Row className={styles.note} style={{ color: '#009C06' }}>
				<b style={{ display: 'contents' }}>Important:</b> Please make sure that
				your savings account is not an overdraft account (OD Account) and is KYC
				compliant
			</Row>
			<Formik
				initialValues={{ selectedBank, bankAccountno }}
				validationSchema={yup.object({
					selectedBank: yup.string().required('Selected Bank is required'),
					bankAccountno: yup
						.string()
						.required('Bank Account number is required')
				})}>
				{({
					handleChange,
					handleBlur,
					handleSubmit,
					values,
					touched,
					errors
				}) => {
					return (
						<Row className={styles.inputRow}>
							<Col>
								<DropDown
									placeholder='Your Bank'
									data={bankOptions}
									name='selectedBank'
									value={values.selectedBank}
									onChange={value => {
										const event = { target: { name: 'selectedBank', value } }
										onChangeHandler(event)
										handleChange(event)
									}}
									className={styles.inputHeight}
								/>
								<Form.Control.Feedback
									type='invalid'
									style={{ display: 'block' }}>
									{touched.selectedBank && errors.selectedBank
										? errors.selectedBank
										: ''}
								</Form.Control.Feedback>
							</Col>
							<Col>
								<Form.Group
									className={`form-focus ${
										values.bankAccountno !== '' ? 'focused' : null
									}`}>
									<Form.Control
										className={styles.inputHeight}
										type='text'
										name='bankAccountno'
										value={values.bankAccountno}
										onChange={event => {
											onChangeHandler(event)
											handleChange(event)
										}}
										onBlur={handleBlur}
										isInvalid={touched.bankAccountno && errors.bankAccountno}
										isValid={
											touched.bankAccountno && !errors.bankAccountno
										}></Form.Control>
									<Form.Label className='focus-label'>
										Your Bank Account Number
									</Form.Label>
									<Form.Control.Feedback type='invalid'>
										{errors.bankAccountno}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
					)
				}}
			</Formik>
		</>
	)
}

export default BankDetail
