import React, { useEffect, useState } from 'react'
import { Row, Col,Spinner } from 'react-bootstrap'
import styles from './howAddFund.module.css'
import indiaImage from '../../public/images/india.png'
import globeImage from '../../public/images/globe.png'
import backImage from '../../public/images/back.png'
import { getBankAccount, getBankList } from '../../api'

const HowAddFund = ({
	setModalData,
	data,
	data2,
	data3,
	back,
	setToggel,
	setOtherCountryDetails,
	setBanksList,
	banksList,
	clickedBankDetails,
  otherCountryDetails,
	setClickedBankDetails,
	setFoundAccount,
	setBankDetails,
  setSelectedBank
}) => {
	setToggel(true)
  const [loading,setLoading] = useState(false)
  const [errorMessage,setErrorMessage] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try{
      const response = await getBankAccount()
        const res = await getBankList()
        if(res && res.code === 200){
          setBanksList(res.data)
        }
        setOtherCountryDetails(response)
        response.length ? setSelectedBank(response[0]):setSelectedBank([])
        if(response.length){
          response[0].bank.isIndian ? setFoundAccount(true, setModalData(data3)) :
          setFoundAccount(true,setToggel(false, setModalData(data3)))
        }
      }catch(error){
        setErrorMessage('Something Went Wrong')
      }
      setLoading(false)
    }
    fetchData()
  },[])

	const asynchFetch = async value => {
    setLoading(true)
		if (value === 'other') {
        const filter = banksList.filter((item) => item.code === 'OTHER_NONINDIAN')
        if(filter.length){
          setClickedBankDetails({ bankName: '', bankType: '', bankId: filter[0]._id})
        }
        setToggel(
          false,
          otherCountryDetails.length
              ? setFoundAccount(true, setModalData(data3))
              : setFoundAccount(true, setModalData(data))
        )
			// const res = await getBankAccount()
			// res && res.code === 200 &&
			// 	setToggel(
			// 		false,
			// 		setOtherCountryDetails(
			// 			res.data,
			// 			res.data.length
			// 				? setFoundAccount(true, setModalData(data3))
			// 				: setFoundAccount(true, setModalData(data))
			// 		)
			// 	)
		}
    else {
        setFoundAccount(false, setModalData(data2))
          // otherCountryDetails.length === 0
          // ? res && res.code === 200 && res.data.length && banksList && !banksList.length
          //   ? setBanksList(res.data, setFoundAccount(false, setModalData(data2)))
          //   : setFoundAccount(false, setModalData(data2))
          // : res && res.data.length && banksList && !banksList.length
          // ?  setFoundAccount(true, setModalData(data3))
          // : setFoundAccount(
          //     true,
          //       setModalData(data3, setToggel(true))
          //   )

			// const response = await getBankAccount()
			// const res = await getBankList()
			// response && response.code === 200 && response.data.length === 0
			// 	? res && res.code === 200 && res.data.length && banksList && !banksList.length
			// 		? setBanksList(res.data, setFoundAccount(false, setModalData(data2)))
			// 		: setFoundAccount(false, setModalData(data2))
			// 	: res && res.data.length && banksList && !banksList.length
			// 	? setBanksList(
			// 			res.data,
			// 			setOtherCountryDetails(
			// 				response.data,
			// 				setFoundAccount(true, setModalData(data3))
			// 			)
			// 	  )
			// 	: setFoundAccount(
			// 			true,
			// 			setOtherCountryDetails(
			// 				response.data,
			// 				setModalData(data3, setToggel(true))
			// 			)
			// 	  )
	}
  setLoading(false)
}
	useEffect(() => {
		setClickedBankDetails({ bankName: '', bankType: '', bankId: '' })
	}, [])

	return (
		<div className={styles.card}>
      {loading ? <div className={styles.spinner}><Spinner animation="border" className={styles.spinnerColor}/></div> :
      <>
			<Row>
				<Col xs={1} md={12}>
					<div
						className={` ${styles.title}`}
						style={{ cursor: 'pointer' }}
						onClick={() => setModalData(back)}>
						<img src={backImage} />
						<span
            className={`d-none d-md-inline-block ${styles.howDiv}`}
			        >{`How are you adding funds?`}</span>
					</div>
				</Col>
        <Col style={{textAlign:'center'}} className='d-block d-md-none'>
          <div className={styles.mobileTitle}>Add funds</div>
        </Col>
			</Row>
      <Row className='d-block d-md-none'>
        <Col>
          <div className={`${styles.title}`}>
          {`How are you adding funds?`}
          </div>
        </Col>
      </Row>
			<Row className={styles.responsiveBoth}>
				<Col xl={4} lg={6} md={6}>
					<div
						className={styles.contain}
						style={{ paddingTop: '48px' }}
						onClick={() => asynchFetch('indian')}>
						<img src={indiaImage} />
						<div className={styles.name} style={{ marginTop: '32px' }}>
							Indian Bank account
						</div>
					</div>
				</Col>
				<Col className={styles.howResponsive}>
					<div
						className={styles.contain}
						style={{ paddingTop: '35px' }}
						onClick={() => asynchFetch('other')}>
						<img src={globeImage} />
						<div className={styles.name} style={{ marginTop: '25px' }}>
							Other country account
						</div>
					</div>
				</Col>
			</Row>
      </>}
		</div>
	)
}

export default HowAddFund
