import React, { useEffect, useState, useContext } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import InputLabel from '../../InputLabel/InputLabel'
import { ThemeContext } from '../../../Context/ThemeContext'
import styles from '../Profile.module.css'
import CustomModal from '../../../components/CustomModal/CustomModal'
import VerifyBasicOtp from './VerifyOTP'
import EditInvestingProfile from '../EditUserBasicDetail/EditInvestingProfile'
import { Modal } from 'react-bootstrap'
import crossImage from '../../../public/images/cross.png'
import warningIcon from "../../../public/images/genericError.svg"
import RetryIcon from "../../../public/images/refresh.svg"
import { Spinner } from "react-bootstrap"
import { fpslChange } from '../../../api'

const InvestingProfile = props => {
  const { profileData, current } = props
	const [isEdit, setIsEdit] = useState(false)
	const [netWorthLiquid, setNetWorthLiquid] = useState('')
	const [netWorthTotal, setNetWorthTotal] = useState('')
	const [annualIncome, setAnnualIncome] = useState('')
	const [objectives, setObjectives] = useState('')
	const [experience, setExperience] = useState('')
	const [riskTolerance, setRiskTolerance] = useState('')
	const [isVerifyOtp, setIsVerifyOtp] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [getOtp, setOtp] = useState('')
	const { buttonColor } = useContext(ThemeContext)
	const [enableSecLending, setEnableSecLending] = useState(false)
	const [showDisableModal, setShowDisableModal] = useState(false)
	const [showErrorModal, setShowErrorModal] = useState(false)
	const [isAccountApproved, setIsAccountApproved] = useState(false)
	const [loading, setLoading] = useState(false)
	const [retryLoading, setRetryLoading] = useState(false)
	const [retryFunc, setRetryFunc] = useState(null)
  // useEffect(() => {
	// 	setNetWorthLiquid(props.profileData['networthLiquid'])
	// 	setNetWorthTotal(props.profileData['networthTotal'] || '--')
	// 	setAnnualIncome(props.profileData['annualIncome'])
	// 	setObjectives(
	// 		objectiveObject && objectiveObject.value
	// 			? objectiveObject.label
	// 			: props.profileData['investmentObjectives']
	// 			? props.profileData['investmentObjectives']
	// 			: '--'
	// 	)
	// 	setExperience(
	// 		experienceObj && experienceObj.value
	// 			? experienceObj.label
	// 			: props.profileData['investmentExperience']
	// 			? props.profileData['investmentExperience']
	// 			: '--'
	// 	)
	// 	setRiskTolerance(
	// 		riskOptionsObject && riskOptionsObject.value
	// 			? riskOptionsObject.label
	// 			: props.profileData['riskTolerance']
	// 			? props.profileData['riskTolerance']
	// 			: '--'
	// 	)
	// }, [props.profileData])
	useEffect(()=>{
		props.fetchProfileData()
	},[current])
	useEffect(()=>{
		setEnableSecLending(profileData['fpslAgreement'])
	},[profileData])
  const setDetails=(profileData)=>{
    let objectiveObject = objectivesOption.find(
      o => o.value === profileData['investmentObjectives']
    )
    let experienceObj = experienceOptions.find(
      o => o.value === profileData['investmentExperience']
    )
    let riskOptionsObject = riskOptions.find(
      o => o.value === profileData['riskTolerance']
    )
    setNetWorthLiquid(profileData['networthLiquid'] ? profileData['networthLiquid']  : "--" );
    setNetWorthTotal(profileData['networthTotal'] ? profileData['networthTotal'] : '--')
		setAnnualIncome(profileData['annualIncome'] ? profileData['annualIncome'] : "--")
    setObjectives(
			objectiveObject && objectiveObject.value
				? objectiveObject.label
				: props.profileData['investmentObjectives']
				? props.profileData['investmentObjectives']
				: '--'
		)
		setExperience(
			experienceObj && experienceObj.value
				? experienceObj.label
				: profileData['investmentExperience']
				? profileData['investmentExperience']
				: '--'
		)
		setRiskTolerance(
			riskOptionsObject && riskOptionsObject.value
				? riskOptionsObject.label
				: profileData['riskTolerance']
				? profileData['riskTolerance']
				: '--'
		)
  }

  useEffect(() => {
		setDetails(profileData);
		if(profileData && profileData.accountStatus ===2)
			setIsAccountApproved(true)
		else
			setIsAccountApproved(false)
	}, [profileData])




	const objectivesOption = [
		{ value: 'LONG_TERM', label: 'Long–term buy & hold investing' },
		{
			value: 'INFREQUENT',
			label: 'Trading infrequently when I see an opportunity'
		},
		{ value: 'FREQUENT', label: 'Frequent trader, depending on the market' },
		{ value: 'ACTIVE_DAILY', label: 'Active trader, daily trader' },
		{ value: 'NEW', label: 'New to investing' }
	]


	const netWorthLiquidOptions = [
		{ value: '$0-$4,999', label: '$0-$4,999' },
		{ value: '$5,000-$99,999', label: '$5,000-$99,999' },
		{ value: '$100,000-$199,999', label: '$100,000-$199,999' },
		{ value: '$200,000+', label: '$200,000+' }
	]
	let netWorthLiquidObject = netWorthLiquidOptions.find(
		o => o.value === profileData['networthLiquid']
	)
	// console.log('net',netWorthLiquidObject && netWorthLiquidObject.value ? netWorthLiquidObject.label : "")

	const riskOptions = [
		{ value: 'LOW', label: 'Low Risk' },
		{ value: 'MODERATE', label: 'Medium Risk' },
		{ value: 'SPECULATION', label: 'Speculative Risk' },
		{ value: 'HIGH', label: 'High Risk' }
	]


	const experienceOptions = [
		{ value: 'NONE', label: 'None' },
		{ value: 'YRS_1_2', label: '1–2 years' },
		{ value: 'YRS_3_5', label: '3–5 years' },
		{ value: 'YRS_5_10', label: '5–10 years' },
		{ value: 'YRS_10_', label: '10+ years' }
	]

	const handleClose = () => {
		setShowModal(false, setIsVerifyOtp(false))
	}

	const onVerifyOtp = () => {
		setIsVerifyOtp(true)
	}

	// const onSave = () => {
	// 	setIsEdit(false,setIsVerifyOtp(false));
	// }

	const onEdit = () => {
		setShowModal(true)
		setIsEdit(true)
    props.sendOtpApi()
	}
	const onDisable=()=>{
		if(isAccountApproved){
			setShowDisableModal(true)
		}
	}
	const onConfirmDisable = async()=>{
		if(!loading){
			setLoading(true)
			const response = await fpslChange(false)
			if (response && response.code === 200) {
				await props.fetchProfileData()
				setShowDisableModal(false)
				if(showErrorModal)
					setShowErrorModal(false)
			} else {
				setShowDisableModal(false)
				setRetryFunc('disable')
				setShowErrorModal(true)
			}
			setLoading(false)
		}
	}
	const onEnable = async ()=>{
		if(isAccountApproved && !loading){
			setLoading(true)
			const status = await props.sendOtpApi()
			if(status){
				props.onChangeCurrent('secLendingOTP')
			}
			else{
				setRetryFunc('enable')
				setShowErrorModal(true)
			}
			setLoading(false)
		}
	}
	const onRetry = async ()=>{
		if(isAccountApproved && !retryLoading){
			setRetryLoading(true)
			if(retryFunc === 'enable'){
				const status = await props.sendOtpApi()
				if(status)
					props.onChangeCurrent('secLendingOTP')
				if(showErrorModal)
					setShowErrorModal(false)
			}else if(retryFunc === 'disable'){
				await onConfirmDisable()
			}
			setRetryLoading(false)
		}
	}
	return (
		<>
			{isEdit ? (
				<CustomModal className={styles.customModalPadding} show={showModal} handleClose={handleClose}>
					{isVerifyOtp ? (
						<EditInvestingProfile
							netWorthLiquid={netWorthLiquid}
							netWorthTotal={netWorthTotal}
							annualIncome={annualIncome}
							objectives={profileData['investmentObjectives']}
							experience={profileData['investmentExperience']}
							riskTolerance={profileData['riskTolerance']}
							riskLabel={riskTolerance}
							handleClose={handleClose}
							buttonColor={buttonColor}
							// onSave={onSave}
							setIsVerifyOtp={setIsVerifyOtp}
							setIsEdit={setIsEdit}
							userID={profileData['userID']}
              // profileDataSet={profileDataSet}
              setDetails={setDetails}
              otp={getOtp}
              hashId={props.hashId}
              sendOtpApi={props.sendOtpApi}
						/>
					) : (
						// <VerifyBasicOtp profile={"profile"}
						// onVerifyOtp={onVerifyOtp}/>
						<VerifyBasicOtp
							profile={'edit your profile'}
							onVerifyOtp={setIsVerifyOtp}
							hashId={props.hashId}
							getOtp={setOtp}
							buttonColor={buttonColor}
							sendOtpApi={onEdit}
						/>
					)}
				</CustomModal>
			) : (
				''
			)}

			<Row className={styles.basicDetail}>
				<Col className={styles.basicDetailText}>Investing Profile</Col>
        {profileData && profileData['accountStatus'] == 3 || profileData['accountStatus'] == 1 ? "" :
        <>
        {/* {profileData && profileData['accountStatus'] == 1 && profileData['nextStep'] !== 'COMPLETE'  ? "" : */}
				<Col style={{ textAlign: 'end' }}>
					<Button className={styles.editButton} onClick={onEdit}>
						<img
							src={require('../../../public/images/edit.svg')}
							className='mr-1'></img>
						Edit
					</Button>
				</Col>
        {/* } */}
        </>
        }
			</Row>
			<div className={`pb-3 ${styles.borderBottom}`}>
				<Row className="m-0 mt-2 mb-4">
					<Col md={4} xs={6} className='mb-3 mt-3'>
						<div className={styles.label}>Net worth (liquid)</div>
						<div className={styles.value}>
            {netWorthLiquid == '--' ? netWorthLiquid : `$${netWorthLiquid}`}
			</div>
					</Col>
					<Col md={4} xs={6} className='mb-3 mt-3'>
						<div className={styles.label}>Net worth (total)</div>
						<div className={styles.value}>
            {netWorthTotal == '--' ? netWorthTotal : `$${netWorthTotal}`}
			</div>
					</Col>
					<Col md={4} xs={6} className='mb-3 mt-3'>
						<div className={styles.label}>Annual income</div>
						<div className={styles.value}>
				{annualIncome == '--' ? annualIncome : `$${annualIncome}`}
				{/* {profileData['annualIncome'] ? profileData['annualIncome'] : "--"} */}
			</div>
					</Col>
					{/* <Col md={4} xs={6} className='mb-3 mt-3'>
						<div className={styles.label}>Objectives</div>
						<div className={styles.value}>{objectives}
						</div>
					</Col> */}
					<Col md={4} xs={6} className='mb-3 mt-3'>
						<div className={styles.label}>Experience</div>
						<div className={styles.value}>{experience}</div>
					</Col>
					<Col md={4} xs={6} className='mb-3 mt-3'>
						<div className={styles.label}>Risk tolerance</div>
						<div className={styles.value}>{riskTolerance}</div>
					</Col>
				</Row>
				{profileData&&!profileData['fpslProhibited']&&
				<div className={`mb-4 ${styles.seclending}`}>
					<div className={styles.content}>
						<div className={styles.header}>Securities Lending</div>
						<div className={styles.description}>Securities lending involves the owner of shares transferring them temporarily to a borrower.
							In return, the borrower transfers other shares, bonds or cash to the lender as collateral and
							pays a borrowing fee as per the DriveWealth’s <span onClick={()=>window.open('https://stockal.freshdesk.com/support/solutions/articles/43000690721-broker-disclaimer')}>Terms & Conditions</span> and Fully Paid Securities
							Lending <span onClick={()=>window.open('https://stockal.freshdesk.com/support/solutions/articles/43000690845-fully-paid-securities-lending-disclosure')}>Disclosures.</span>
						</div>
					</div>
					{!enableSecLending ?
						<button className={`${styles.enable} ${!isAccountApproved && styles.disableBtn}`} onClick={onEnable} style={{backgroundColor:buttonColor}}>
							{loading &&<Spinner animation="border" className={styles.spinnerSecondaryColor}/>}
							Enable Now
						</button>
						:<button className={`${styles.disable} ${!isAccountApproved && styles.disableBtn}`}
							onClick={onDisable}>
							Disable Now
						</button>}
				</div>}
			</div>
			<Modal show={showDisableModal} onHide={()=>setShowDisableModal(false)} centered className={styles.secLendingModal}>
				<img className={styles.crossIcon} alt='close-icon' src={crossImage} onClick={()=>setShowDisableModal(false)}/>
				<div className={styles.warningContainer}>
					<img className={styles.warningIcon} alt='alert-icon' src={warningIcon}/>
				</div>
				<div className={styles.secLendingHeader}>Disable Securities Lending</div>
				<div className={styles.secLendingDescription}>Are you sure you want to disable Securities Lending on your account? You will stop earning interest income on your holdings.</div>
				<div className={styles.secLendingAction}>
					<button className={styles.secLendingPrimary} onClick={onConfirmDisable}>
						{loading &&<Spinner animation="border" className={styles.spinnerColorDisable}/>}
						Yes, Disable
					</button>
					<button className={styles.secLendingSecondary} onClick={()=>setShowDisableModal(false)}>No, Go Back</button>
				</div>
			</Modal>
			<Modal show={showErrorModal} onHide={()=>setShowErrorModal(false)} centered className={styles.secLendingModal}>
				<img className={styles.crossIcon} alt='close-icon' src={crossImage} onClick={()=>setShowErrorModal(false)}/>
				<div className={styles.warningContainer}>
					<img className={styles.warningIcon} alt='alert-icon' src={warningIcon}/>
				</div>
				<div className={styles.secLendingHeader}>Something went wrong</div>
				<div className={styles.secLendingDescription}>We’re not able to proceed with your request at the moment. Please try again in a bit.</div>
				<div className={styles.secLendingAction}>
					<span className={styles.actionText} onClick={()=>setShowErrorModal(false)}>Go Back</span>
					<span className={styles.actionText} onClick={onRetry}>
						{retryLoading ?<Spinner animation="border" className={styles.spinnerColor}/>
							:<img src={RetryIcon} style={{width:'18px',height:'18px'}}/>}{' '}
						Try Again
					</span>
				</div>
			</Modal>
			{/* <Row className={`m-0 mt-4 ${styles.borderBottom}`}>
					<Col md={4} xs={6} className="mb-3 mt-3">
						<div className={styles.label}>Objectives</div>
						<div className={styles.value}>{objectiveObject && objectiveObject.value ? objectiveObject.label : objectives}</div>
					</Col>
					<Col md={4} xs={6} className="mb-3 mt-3">
						<div className={styles.label}>Experience</div>
						<div className={styles.value}>{experience}</div>
					</Col>
					<Col md={4} xs={6} className="mb-3 mt-3">
						<div className={styles.label}>Risk tolerance</div>
						<div className={styles.value}>{riskTolerance}</div>
					</Col>
				</Row> */}
		</>
	)
}

export default InvestingProfile
