import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form} from 'react-bootstrap'
import styles from './AccountTransfer.module.css'
import Dropdown from '../../DropDown/DropDown'
import PartialTransfer from "./PartialTransferAddStocks";
import { dtccClearingList, acatTransactionSubmit } from '../../../api'
import DtccSearchList from "./DtccSearchList";
import { Spinner } from 'react-bootstrap'
import SuccessAcatTransfer from "./suceesTransfer";
import CheckBox from '../../CheckBox/CheckBox';


const AccountTransfer = ({setSelectedKey,setCallStatusApi,statusData}) => {
    const[primaryList, setPrimaryList] = useState([]);
    const[name,setName] = useState('')
    const[sourceAccountType,setSourceAccountType] = useState('INDIVIDUAL');
    const[acatType,setAcatType] = useState('FULL');
    const[sourceAccountNumber, setSourceAccountNumber] = useState('');
    // const[destinationAccountNumber, setDestinationAccountNumber] = useState('');
    const[showDtccList, setShowDtccList] = useState(false);
    const[dtccList, setDtccList] = useState([]);
    const[dtccVal,setDtccVal] = useState('');
    const[dtccCode,setDtccCode] = useState('');
    const[filterDtccList,setFilterDtccList] = useState([]);
    const[buttonError,setButtonError] = useState('');
    const[inputCash,setInputCash] = useState('');
    const[stockAdded,setStockAdded] = useState([]);
    const[loading,setLoading] = useState(false);
    const[successMessage,setSuccessMessgae] = useState('');
    const[showSuccessScreen, setSuccessScreen] = useState(false);
    const[alreadyExist,setAlreadyExist] = useState('');
    const[minimumRequiredBalanceCheck, setIsMinimumRequiredBalanceCheck] = useState(false);
    const[requiredFieldsError,setRequiredFieldEror] = useState([
        {sourceAccountError:"Source Account Number is Required!",isSourceError:false},
        {dtccClearingError:"DTCC Clearing Number is Required!",isDtccError:false},
        {cashtransferError:"Cash Transfer is Required!",isCashTransferError:false},
        {stockAddedError:"At least one security must be added!",isStockAddedError:false},
    ]);
   
    const getTemplate=(list)=>{
        let templateList = []
        templateList.push(
            <>
            <Row key={`header`}>
                <Col md={12} lg={12}>
                <p className={`${styles.name}`}>Effortless Brokerage Account Transfer</p> 
                </Col>
                <Col md={12} lg={12}>
                <p className={styles.subtext}>Transfer stocks digitally from an external brokerage account to Stockal!</p>  
                </Col>
            </Row>
            </>
        )        
        return templateList
    }

    const SelectedVal = (e,dropdownValue) => {
        if(dropdownValue == 'AccountType'){
            setSourceAccountType(e);
        }else{
            setAcatType(e)
        }
    }
    const proofOptions = [
        { value: 'FULL', label: 'FULL' },
        { value: 'PARTIAL', label: 'PARTIAL' }
    ]   

    const asynchFetch = async () => {
        const res = await dtccClearingList()
        const sortedRes = res.data.sort((a, b) => {
            const nameA = a.accountName.toUpperCase();
            const nameB = b.accountName.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        setDtccList(sortedRes)
        setFilterDtccList(sortedRes)
    }

    useEffect(() => {
        asynchFetch()
        setSuccessScreen(false)
    },[])

    const selectedDtccVal = (value,code) => {
        setDtccVal(value)
        setDtccCode(code)
        setShowDtccList(false)
        setFilterDtccList(dtccList)
    }

    const searchdtccList = (e) => {
        setDtccVal(e)
        let query = e.toLowerCase();
        let filterData = dtccList.filter(account =>
            account.accountName.toLowerCase().includes(query) ||
            account.dtccId.includes(query))
        setFilterDtccList(filterData);
    }

    const updateErrorState = (fieldName, errorMessage, isError, isValid) => {
        setRequiredFieldEror(prevState => prevState.map(field => {
            if (field[fieldName] === errorMessage) {
                return { ...field, [isError]: isValid};
            }
            return field;
        }));
    };

    const clearAllErrors = () => {
        setRequiredFieldEror(prevState => prevState.map(field => ({
            ...field,
            isSourceError: false,
            isDtccError: false,
            isCashTransferError: false
        })));
    };
   
    const ifSourceAccountExist = () =>{
        const sourceAccountExists = statusData.some(item => 
            item.sourceAccount === sourceAccountNumber  &&
            (item.status == 'STARTED' || 
            item.status == "PENDING" ||
            item.status == "ON-HOLD")
            );    
        return sourceAccountExists;
    }

    const submitTransaction = () => {
        setSuccessMessgae('');
        let isFilled;
        if (acatType === "PARTIAL" && parseInt(inputCash) === 0) {
            isFilled = sourceAccountNumber !== "" && dtccVal !== "" && stockAdded.length > 0;
        } else {
            isFilled = sourceAccountNumber !== "" && dtccVal !== "" && inputCash !== "";
        }
        if (isFilled && minimumRequiredBalanceCheck) {
            if(minimumRequiredBalanceCheck){
                if(!ifSourceAccountExist()){
                    clearAllErrors();
                    setButtonError('');
                    initiateTransaction();
                    setLoading(true);
                    setAlreadyExist('')
                }else{
                    setButtonError('');
                    setAlreadyExist("An account transfer is currently in progress for this source account. Please await its completion before reinitiating the process.");
                }
            }else{
                setButtonError('');
                setAlreadyExist("Please confirm that you have read the above note");
            }
        } else {
            let errorsToUpdate = [];
            if (sourceAccountNumber.length === 0) {
                errorsToUpdate.push({ fieldName: "sourceAccountError", errorMessage: "Source Account Number is Required!", isError: "isSourceError", isValid:true});
            } 
            else if(sourceAccountNumber.length > 0){
                errorsToUpdate.push({ fieldName: "sourceAccountError", errorMessage: "Source Account Number is Required!", isError: "isSourceError", isValid:false});
            }
            if (dtccVal.length === 0) {
                errorsToUpdate.push({ fieldName: "dtccClearingError", errorMessage: "DTCC Clearing Number is Required!", isError: "isDtccError",isValid:true});
            }
            else if(dtccVal.length > 0){
                errorsToUpdate.push({ fieldName: "dtccClearingError", errorMessage: "DTCC Clearing Number is Required!", isError: "isDtccError",isValid:false});
            }
            if (acatType == "PARTIAL" && inputCash.length == 0) {
                errorsToUpdate.push({ fieldName: "cashtransferError", errorMessage: "Cash Transfer is Required!", isError: "isCashTransferError",isValid:true});
            }
            else if(acatType == "PARTIAL" && inputCash.length > 0){
                errorsToUpdate.push({ fieldName: "cashtransferError", errorMessage: "Cash Transfer is Required!", isError: "isCashTransferError",isValid:false});
            }
            if (acatType === "PARTIAL" && stockAdded.length == 0) {
                errorsToUpdate.push({ fieldName: "stockAddedError", errorMessage: "At least one security must be added!", isError: "isStockAddedError",isValid:true});
            }
            else if(acatType === "PARTIAL" && stockAdded.length > 0){
                errorsToUpdate.push({ fieldName: "stockAddedError", errorMessage: "At least one security must be added!", isError: "isStockAddedError",isValid:false});
            }
            if (errorsToUpdate.length > 0) {
                console.log('errortoupdate',errorsToUpdate);
                errorsToUpdate.forEach(error => updateErrorState(error.fieldName, error.errorMessage, error.isError,error.isValid));
                setButtonError('Fill all the Required Fields');
                setAlreadyExist('');
                if (errorsToUpdate.every(error => !error.isValid)) {
                    if(minimumRequiredBalanceCheck && acatType === "FULL"){
                        if (!ifSourceAccountExist()) {
                            clearAllErrors();
                            initiateTransaction();
                            setLoading(true);
                            setAlreadyExist('');
                            setButtonError('');
                        }
                        else{
                            setButtonError('');
                            setAlreadyExist("An account transfer is currently in progress for this source account. Please await its completion before reinitiating the process.");
                        }
                    }else{
                        setButtonError('');
                        setAlreadyExist("Please confirm that you have read the above note");
                    }
                }
            } 
            // else {
            //     if(!ifSourceAccountExist()){
            //         clearAllErrors();
            //         initiateTransaction();
            //         setLoading(true);
            //         setAlreadyExist('')
            //         setButtonError('')
            //     }else{
            //         setAlreadyExist("An account transfer is currently in progress for this source account. Please await its completion before reinitiating the process.");
            //     }
            // }
        }
    };  

    const initiateTransaction = async() => {
        let body = {
            sourceAccountType: sourceAccountType,
            acatType: acatType,
            source: sourceAccountNumber,
            clearingNo: dtccCode,
            cash: inputCash,
            comment: "Hey! Welcome to DriveWealth Developer Docs!",
            metadata: {
              myCustomKey: "myCustomValue"
            },
            positions: stockAdded
        }
        if (acatType === "FULL") {
            delete body.positions; 
            delete body.cash;
        }
        try{
        const resp = await acatTransactionSubmit(body)
            if(resp.code == 200){
                setLoading(false)
                setSuccessMessgae('Successfully Initiated!')
                setSuccessScreen(true)
                setTimeout(() => {
                    setSourceAccountNumber('');
                    setDtccVal('');
                    setDtccCode('');
                    setInputCash('');
                    setStockAdded([]);
                    setSuccessMessgae('');
                    setSelectedKey('status acat')
                    setCallStatusApi(true)
                    setSuccessScreen(false)
                    setRequiredFieldEror([
                        {sourceAccountError:"Source Account Number is Required!",isSourceError:false},
                        {dtccClearingError:"DTCC Clearing Number is Required!",isDtccError:false},
                        {cashtransferError:"Cash Transfer is Required!",isCashTransferError:false},
                        {stockAddedError:"At least one security must be added!",isStockAddedError:false},
                    ])
                },5000)
            }else{
                setLoading(false)
                setSuccessMessgae('Something Went Wrong!')
                setSuccessScreen(false)
            }
        }catch(err){
            setLoading(false)
            setSuccessMessgae(err.message)
            console.log('err',err);
            setSuccessScreen(false)
        }
    }

    const minimumBalnceCheck = () => {
        setIsMinimumRequiredBalanceCheck(!minimumRequiredBalanceCheck)
    }

    return(
        showSuccessScreen ? 
        <SuccessAcatTransfer/> :
        <>
            <div className={`${styles.borderBottom} ${styles.section}`}>
                {getTemplate(primaryList)}
            </div>
            <Row className={styles.acatInputSection}>
                <Col xl={4} lg={4} md={6} sm={6} className={styles.nomineeInput}>
                    <Form.Group className={`form-focus focused`}>
                        <Form.Control 
                            className={`${styles.textInput} ${styles.textBorder}`} 
                            type="text"  
                            autoComplete='off'
                            value={sourceAccountNumber}
                            onChange={(e) => {
                                let newValue = e.target.value.trim().toUpperCase();
                                newValue = newValue.slice(0, 15); 
                                setSourceAccountNumber(newValue);
                            }}
                        />
                        <Form.Label className='focus-label'>Source Account Number</Form.Label>
                        {requiredFieldsError.find(field => field.sourceAccountError === "Source Account Number is Required!" && field.isSourceError) && (
                            <div className={styles.errorText}>Source Account Number is Required!</div>
                        )}
                    </Form.Group>
                </Col>
                <Col xl={4} lg={4} md={6} sm={6} className={styles.nomineeInput}>
                    <Dropdown
                        data={[{ value: 'INDIVIDUAL', label: 'INDIVIDUAL' },{ value: 'JOINT', label: 'JOINT' }]}
                        name={`beneficiaryType`}
                        value={sourceAccountType}
                        className={styles.dropdown}
                        placeholder={`Source Account type`}
                        isLabeled={true}
                        disableSearch={true}
                        onChange={(e) => SelectedVal(e,'AccountType')}
                    />
                </Col>
                <Col xl={4} lg={4} md={6} sm={6} className={styles.nomineeInput}>
                    <Form.Group className={`form-focus focused`}>
                        <Form.Control 
                        className={`${styles.textInput} ${styles.textBorder}`} 
                        type="text" id={`beneficiaryName`} 
                        name="beneficiaryName" autoComplete='off'
                        value={dtccVal}
                        onChange={(e) => searchdtccList(e.target.value)}
                        onClick={() =>  setShowDtccList(true)}
                        ></Form.Control>
                        <Form.Label className='focus-label'>DTCC clearing number</Form.Label>
                        {requiredFieldsError.find(field => field.dtccClearingError === "DTCC Clearing Number is Required!" && field.isDtccError) && (
                            <div className={styles.errorText}>DTCC Clearing Number is Required!</div>
                        )}
                    </Form.Group>
                    {showDtccList ?
                    <DtccSearchList setShowDtccList={setShowDtccList} searchdtccList={searchdtccList} list={filterDtccList} onSelect={selectedDtccVal}/> : ""}
                </Col>
                <Col xl={4} lg={4} md={6} sm={6} className={styles.nomineeInput}>
                    <Dropdown
                        data={proofOptions}
                        name='addressProof'
                        value={acatType}
                        onChange={(e) => SelectedVal(e,'ACAT')}
                        placeholder='Account Transfer Type'
                        className={styles.dropdown}
                    />
                    <p className={styles.helperText}>{acatType === "PARTIAL" ? "Selected positions and cash will be transferred"
                        : "All positions and cash will be transferred."}
                    </p>
                </Col>
                {acatType === "PARTIAL" ? 
                <Col xl={4} lg={4} md={6} sm={6} className={styles.nomineeInput}>
                    <Form.Group className={`form-focus focused`}>
                        <span className={styles.cashDollar}>$</span>
                        <Form.Control 
                            className={`${styles.textInput} ${styles.textBorder}`} 
                            type="number"  
                            autoComplete='off'
                            value={inputCash}
                            onChange={(e) => {
                                let newValue = e.target.value.trim();
                                newValue = newValue.replace(/[^\d]/g, ''); 
                                newValue = newValue.slice(0, 15); 
                                setInputCash(newValue);
                            }}
                        />
                        <Form.Label className='focus-label'>Cash Transfer</Form.Label>
                    </Form.Group>
                    <p className={styles.helperText}>The amount of cash to be moved from the source account.</p>
                    {requiredFieldsError.find(field => field.cashtransferError === "Cash Transfer is Required!" && field.isCashTransferError) && (
                            <div className={styles.errorText}>Please enter an amount (0 or greater)</div>
                    )}       
                </Col> : ""}
            </Row>
            {acatType === "PARTIAL" ? 
            <>
            <PartialTransfer stockAdded={stockAdded} setStockAdded={setStockAdded} requiredFieldsError={requiredFieldsError}/> 
            <hr/>
            </>
            : ""}
            <Row className={styles.marginCheckBox}>
                <Col>
                    <p>Most external brokers charge a 50-150$ fee for an account transfer. Kindly check with your external broker for the exact transfer fees and hold the necessary funds in the cash balance of your external brokerage account.</p>
                    <CheckBox
                        label='Thanks and noted'
                        id='LRSCheckbox'
                        name='isDirectorChecked'
                        onChange={minimumBalnceCheck}
                        isChecked={minimumRequiredBalanceCheck}
                    />
                </Col>
            </Row>
            {buttonError.length > 0 ? 
            <p className={styles.Error}>{buttonError}</p> : ""}
            {alreadyExist.length > 0 ? 
            <p className={styles.Error}>{alreadyExist}</p> : ""}
            {successMessage.length > 0 ? 
            <p className={successMessage !== "Successfully Initiated!" ? styles.Error : styles.SuccessMessage}>{successMessage}</p> : ""}
            <button className={styles.confirmTransferButton} onClick={() =>  loading ? "" : submitTransaction()}>
            {loading ? 
            <Spinner animation="border" className={styles.spinnerColor}/> : 
            "Confirm Transfer"}</button> 
        </> 
    )
}

export default AccountTransfer