import React, { useState, useEffect } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import styles from './orderCard.module.css'
import moment from 'moment'
import {MARKET_TOUCHED_ORDER} from '../../constants'
import { diff } from 'lodash'

const LastWeekCard = (props) => {
  const [isLoaded, setIsloaded] = useState(true)

  const Capitalize = (string) => {
    let str = string.toLowerCase();
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const type =  props.item.type === MARKET_TOUCHED_ORDER ? 'MARKET IF TOUCHED' : props.item.type
  props.item.quantity = props.item.category !== 'stack' && props.item.cumulativeQuantity > 0 ? props.item.cumulativeQuantity : props.item.quantity

  // let sortedArray = props.data.sort((a, b) =>  moment(a.executedDate).valueOf() - moment(b.executedDate).valueOf())
  // let finalArray = sortedArray.reverse();

  // const date = new Date();
  // const formattedDate = date.toLocaleDateString('en-GB', {
  //   day: 'numeric', month: 'short', year: 'numeric'
  // }).replace(/ /g, '-');
  // console.log('formated',formattedDate);

	return (
		<>
    {props.item.status === "REBALANCE" ? "" :
      <>
				<Row>
					<Col md={1} xs={2} sm={2}>
            <div className={styles.imageMarginLeft}>
              {props.item.logo && isLoaded ? (
                <div className={styles.imageDiv}>
                  <img
                  onError={() => {
                    setIsloaded(false)
                  }}
                  className={styles.stockImage}
                  src={props.item.logo}
                  alt={props.item.symbol.substring(0, 1)}></img>
                </div>
               ) : (
                <div className={styles.imageDiv}>
                  <div className={`text-center ${styles.letterSize}`}>
                    {props.item.symbol.substring(0, 1)}
                  </div>
                </div>
              )}
            </div>
					</Col>
          <Col md={5} xs={5} sm={5} className='pr-0'>
              <div className={styles.stockTitle}>{props.item.symbol}</div>
              {/* for desktop */}
              <div className={`${styles.stockBuyDate} d-none d-md-block `}>
                {props.item.side}
                <span className={styles.orderBuyDate}>&nbsp;•&nbsp;{moment(props.item.sortKey).format('D MMMM, Y') || '--'}</span>
                &nbsp;•&nbsp;{type}
                {props.item.extendedHours ? ' • Extended' : ''}
                 </div>
                {/* for mobile */}
              <div className={`${styles.stockBuyDate} d-block d-md-none`}>{props.item.side} •<span className={styles.orderBuyDate}>&nbsp;{moment(props.item.sortKey).format('D MMM, Y') || '--'}</span> </div>
              <div className={`${styles.stockBuyDate} d-block d-md-none`}>{type} {props.item.extendedHours ? '• Extended' : ''}</div>
          </Col>
          {/* <Col md={2} xs={1} sm={1}>
          </Col> */}
					<Col md={6} xs={5} sm={5} className='pl-0'>
						<div className={styles.stockStatus}>{props.item.side === "BUY" ?  props.item.status === "FILLED" ? <> <img src={require('../../public/images/GreenBackTick.svg')}/> Completed</> : props.item.status === "CANCELED" ? "Cancelled" :  Capitalize(props.item.status) : props.item.side === "SELL" ?  props.item.status === "FILLED" ? <> <img src={require('../../public/images/GreenBackTick.svg')}/> Completed</> : props.item.status === "CANCELED" ? "Cancelled" : Capitalize(props.item.status) :""}</div>
						{/* <div className={styles.stockQuantity}>{props.item.category === "stock" ? props.item.type === "MARKET" ? props.item.quantity === 0 ? <>For ${props.item.tradeAmount}</> :<>{props.item.quantity} Shares</>  :<>{props.item.quantity} Shares at ${props.item.tradeAmount}</> : <>Ordered for ${props.item.totalOrderAmount}</>}</div> */}
            <div className={styles.stockQuantity}>{props.item.category === "stock" ? props.item.status === "CANCELED" || props.item.status === "REJECTED" ? <>{props.item.quantity} Share for ${props.item.tradeAmount}</> : <>{props.item.quantity} Share at ${props.item.averagePrice}</> : <>Ordered for ${props.item.totalOrderAmount}</>}</div>
          </Col>
				</Row>
        {/* <Row className='d-flex d-md-none'>
        <Col md={1} xs={2} sm={2}></Col>
        <Col>
        <div className={styles.stockBuyDate}>{type} {props.item.extendedHours ? '• Extended' : ''}</div>
        </Col>
        </Row> */}


				{/* <hr className={styles.borderMargin}></hr> */}
        {props.item && props.length - 1  === props.index ?
        "" : <hr className={styles.borderMargin}></hr>}
        </>
        }
		</>
	)
}

export default LastWeekCard
