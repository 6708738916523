import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { Formik } from 'formik'
import * as yup from 'yup'
import styles from './AccountFundingSetUp.module.css'

const LrsAmount = ({ lrsAmount, lrsBankAccountno, onChangeHandler }) => {
	return (
		<>
			<Row className={styles.detail} style={{ marginTop: '5px' }}>
				<b style={{ display: 'contents' }}>What is this?</b>Mention the
				cumulative amount of LRS done from the same bank.
			</Row>
			<Formik
				initialValues={{ lrsAmount, lrsBankAccountno }}
				validationSchema={yup.object({
					lrsAmount: yup
						.number()
						.typeError('Amount must be valid number')
						.required('amount is required'),
					lrsBankAccountno: yup
						.string()
						.required('bank account number is required')
				})}>
				{({
					handleChange,
					handleBlur,
					handleSubmit,
					values,
					touched,
					errors
				}) => {
					return (
						<Row className={styles.inputRow}>
							<Col>
								<Form.Group
									className={`form-focus ${
										values.lrsAmount !== '' ? 'focused' : null
									}`}>
									<Form.Control
										className={styles.inputHeight}
										type='text'
										name='lrsAmount'
										value={values.lrsAmount}
										onChange={event => {
											onChangeHandler(event)
											handleChange(event)
										}}
										onBlur={handleBlur}
										isInvalid={touched.lrsAmount && errors.lrsAmount}
										isValid={
											touched.lrsAmount && !errors.lrsAmount
										}></Form.Control>
									<Form.Label className='focus-label'>Amount in $</Form.Label>
									<Form.Control.Feedback type='invalid'>
										{errors.lrsAmount}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group
									className={`form-focus ${
										values.lrsBankAccountno !== '' ? 'focused' : null
									}`}>
									<Form.Control
										className={styles.inputHeight}
										type='text'
										name='lrsBankAccountno'
										value={values.lrsBankAccountno}
										onChange={event => {
											onChangeHandler(event)
											handleChange(event)
										}}
										onBlur={handleBlur}
										isInvalid={
											touched.lrsBankAccountno && errors.lrsBankAccountno
										}
										isValid={
											touched.lrsBankAccountno && !errors.lrsBankAccountno
										}></Form.Control>
									<Form.Label className='focus-label'>
										Bank Account Number
									</Form.Label>
									<Form.Control.Feedback type='invalid'>
										{errors.lrsBankAccountno}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
					)
				}}
			</Formik>
		</>
	)
}

export default LrsAmount
