import React, { useEffect, useState, useRef } from 'react'
import styles from './ShariahFilter.module.css'
import Rating from 'react-rating'
import shariyaStarIcon from '../../public/images/shariyaStarIcon.svg'
import shariyaStarIconGrey from '../../public/images/shariyaStarIconGrey.svg'
import dropdownIcon from '../../public/images/dropdownIcon.svg'

const ShariahFilter = (props) => {

    const { isResetHalalFilter, setIsResetHalalFilter, showHalalStocks, setShowHalalStocks, setHalalRanking, halalRanking, halalText, setHalalText } = props

    const [ showDropDown, setShowDropDown] = useState(false)

    const filterRef = useRef()
    useOnClickOutside(filterRef, () => setShowDropDown(false))

    const handleDropDown = () => {
        setShowHalalStocks(!showHalalStocks)
        setShowDropDown(!showDropDown)
    }

    const handleRating = (value) => {
        setHalalRanking(value)
        setHalalText(`Halal - ${value} Star`)
        setShowDropDown(false)
    }

    useEffect(()=>{
        if(isResetHalalFilter) {
            setShowHalalStocks(false)
            setIsResetHalalFilter(false)
        }
    },[isResetHalalFilter])

    useEffect(()=>{
        if(halalRanking === 0){
            setHalalText('Show Only Halal')
        }
    },[halalRanking])

    return (
        <div ref={filterRef}>
            <div className={styles.shariahRatingFilter}>
                <div className={styles.shariahHeader} onClick={() => {setShowDropDown(!showDropDown)}}>
                    <p>
                        {showHalalStocks ? halalText : 'Show Halal & Not Halal'}
                    </p>
                    <img src={dropdownIcon} alt='icon'></img>
                </div>
                {showHalalStocks ? <div className={styles.ratingContainer}>
                    <Rating 
                        fractions={1} 
                        initialRating={halalRanking} 
                        onChange={(value)=>{handleRating(value)}} 
                        emptySymbol={<img src={shariyaStarIconGrey} alt="icon"></img>} 
                        fullSymbol={<img src={shariyaStarIcon} alt="icon"></img>}
                    />
                </div> : ''}
            </div>
            {showDropDown ? 
            <div className={styles.shariahDropDown} onClick={() => {handleDropDown()}}>
                <p className={styles.shariahText}>{showHalalStocks ? 'Show Halal & Not Halal' : 'Show Only Halal'}</p>
            </div> : ''}
        </div>
    )
}

const useOnClickOutside = (ref, handler) => {
    useEffect(
      () => {
        const listener = (event) => {
          if (!ref.current || ref.current.contains(event.target)) {
            return
          }
          handler(event)
        }
        document.addEventListener('mousedown', listener)
        document.addEventListener('touchstart', listener)
        return () => {
          document.removeEventListener('mousedown', listener)
          document.removeEventListener('touchstart', listener)
        }
      },
      [ref, handler]
    )
}

export default ShariahFilter