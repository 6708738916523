import React, {useEffect,useState} from 'react'
import { Row, Col,Modal } from 'react-bootstrap'
import ReactHighCharts from 'react-highcharts'
import StocksMoreChart from './StocksMoreChart'
import CustomModal from '../CustomModal/CustomModal'
import openImage from '../../public/images/open.svg'
import styles from './chart.module.css'
import {getStocksChart} from '../../api'


const EarningsChart = ({code}) => {
  const [data,setData] = useState([])
  const [column,setColumn] = useState([])
  const [showModal,setShowModal] = useState(false)

  const fetchData = async() => {
    const response = await getStocksChart(code,'Q','adjbasiceps')
    if( response && response.code === 200 && response.data && response.data.adjbasiceps){
    const tempData = []
    const categories = []

    response.data.adjbasiceps.reverse().map((item) => {
           const tempRow = `${item.fiscalPeriod} ${item.fiscalYear.toString().substring(2)}`
           const tempColumn = item.value
           tempData.push(tempColumn)
           categories.push(tempRow)
        })
        setData(tempData)
        setColumn(categories)
      }
  }

  useEffect(() => {
    fetchData()
  },[])


  const custoumOption = () => {
   return{
    chart: {
      type: 'column',
      height:250
      // width:600,
    },
    title: {
        text: undefined
    },
    legend: {
      enabled: false
    },
    xAxis: {
      categories: column,
      crosshair: true,
      lineWidth:0,
      tickWidth:0,
  },
  yAxis: {
      min: 0,
      // tickInterval:100,
      gridLineWidth:0,
      title: {
          text:undefined
      }
  },
  tooltip: {
      headerFormat: '',
      pointFormat: '<tr><td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
  },
  credits: {
    enabled: false
  },
  plotOptions: {
      column: {
          pointPadding: 0.2,
          borderWidth: 0
      },
      series: {
        borderRadius: 10,
        pointWidth: 16
    }
  },
  colors:['#3C404A'],
  series: [{
      name: '',
      data: data

  }]
   }
  }

  const quater = column.length > 0 ? column[column.length - 1].split(' ') : ''
  return(
    <>
    <div>
      <ReactHighCharts config={custoumOption()}/>
      <Row style={{paddingTop:'15px'}}>
      {quater.length > 0 ?
        <Col style={{paddingLeft:'100px'}}>
        <div>
        <div className={styles.circle}></div>
        <span className={styles.qvalue}>{`${quater[0]} ${quater[1].slice(-2)} actual eps`}</span>
          </div>
          <div className={styles.value}>{data[data.length - 1]}</div>
        </Col> : '' }
        <Col style={{textAlign:'end',paddingTop:'15px'}}>
        <div className={styles.seeMore}  onClick={() => setShowModal(true)}>
          show more charts
          <img src={openImage} className='ml-2'></img>
          </div>
        </Col>
      </Row>
    </div>

      {/* more chart modal */}
      <CustomModal show={showModal} handleClose={() => setShowModal(false)}>
          <StocksMoreChart code={code}/>
      </CustomModal>

    </>
  )

}
export default EarningsChart
