import React from 'react'
import { useState } from 'react'
import { Row, Button } from 'react-bootstrap'
import InputLabel from '../../InputLabel/InputLabel'
import styles from '../Profile.module.css'
import axios from 'axios'
import { validatePassword } from '../../../api'

const VerifyPin = props => {
	const [password, setPassword] = useState('')
	const [errorMessage, setErrorMessage] = useState('')
	const [wrongPassword, setWrongPassword] = useState(false)
  const ssoShown = sessionStorage.getItem('Sso_flag') ? sessionStorage.getItem('Sso_flag') : false

	const onVerifyPassWord = () => {
    setErrorMessage('')
		if (password !== '') {
       const update = {
          body: {
            password: password
          }
        }
      // new Api
      if(ssoShown){    // for sso login
        props.setisVerifyPin(true)
          setErrorMessage('')
          props.askPassword(password)
      }else{
      validatePassword(update).then(response=>{
        if (response.data.code === 200) {
          props.setisVerifyPin(true)
          setErrorMessage('')
          setWrongPassword(false)
          props.askPassword(password)
        } else {
          setErrorMessage('Something went wrong.')
          setWrongPassword(true)
        }
      }).catch(function (error) {
        setErrorMessage('Please enter a valid password')
        setWrongPassword(true)
        console.log('basicError', error)
      })
    }
    }
    else {
			setErrorMessage('Enter the Password to Change the PIN')
		}
	}


  const handleChangeOtp = e => {
		let a = e.target.value.replace(/[^0-9]/g, '').length
		const re = /^[0-9\b]+$/
		if (e.target.value === '' || re.test(e.target.value)) {
			a <= 6 && setPassword(e.target.value)
		}
	}

	return (
		<>
			<div className={`${styles.pinHeadingMargin} changePinOtp`} >
				<Row className={styles.verifyOtpText}>
          {ssoShown ? 'Verify OTP to change PIN' : 'Verify password to change PIN'}
				</Row>
				<Row className={styles.verifyOtpTextResponsive}>

          {ssoShown ? 'Verify OTP' : 'Verify password'}
          </Row>
				<Row className={styles.verifyOtpSpinResponsive}>

          {ssoShown ? 'Enter OTP to change your PIN' : 'Enter password to change your PIN'}
				</Row>
				<Row className={`m-0 mt-4 ${styles.widthProfileSpinInput}`}>
					{/* <InputLabel
					label='verify password'
					placeholder='verify password'></InputLabel> */}

					<div className={`mb-1 ${styles.profileInputLabel}`}>
            {ssoShown ? 'Verify OTP' :'Verify password'}
					</div>
          {ssoShown ?
          <input          // for otp
						type='number'
						placeholder='Verify OTP'
						name='verifyPassword'
						className={
							errorMessage
								? styles.profileErrorInputBox
								: styles.profileInputBox
						}
						value={password}
            onChange={event => handleChangeOtp(event)}
            onPaste={(e)=>{
              e.preventDefault()
              return false;
            }} onCopy={(e)=>{
              e.preventDefault()
              return false;}}
					/>
          :
          <input        // for password
          type='password'
          placeholder='Verify password'
          name='verifyPassword'
          autoComplete="new-password"
          className={
            errorMessage
              ? styles.profileErrorInputBox
              : styles.profileInputBox
          }
          value={password}
          onChange={event => setPassword(event.target.value)}
          onPaste={(e)=>{
            e.preventDefault()
            return false;
          }} onCopy={(e)=>{
            e.preventDefault()
            return false;}}
        />}
					<p className={styles.profileErrorText}>{errorMessage}</p>
				</Row>
				<Row className={styles.verifyButtonMargin}>
					<Button
						style={{ backgroundColor: props.buttonColor }}
						className={`${styles.saveButtonSpin} themeButton`}
						onClick={onVerifyPassWord}>
						Proceed to change PIN
					</Button>
				</Row>
			</div>
		</>
	)
}

export default VerifyPin
