import { initialState } from '../initialState'
import { COUNTRY_LIST } from '../actions/action'

const setCountryList = (state = initialState.countryList, action) => {
	switch (action.type) {
		case COUNTRY_LIST: {
			return { ...state, countryList: action.payload }
		}
		default: {
			return state
		}
	}
}

export default setCountryList
