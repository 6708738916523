import React,{useEffect,useState} from 'react'
import { Col, ProgressBar, Row } from 'react-bootstrap'
import styles from './AboutRating.module.css'
// import exclamation from '../../public/images/group.svg'
import openImage from '../../public/images/open.svg'
import CustomModal from '../../components/CustomModal/CustomModal'
import Shimmer from '../../skeletons/Shimmer'

const AboutRating = ({analystData,stockName}) => {

	const data = ['Strong buy', 'Buy', 'Hold', 'Sell', 'Strong sell']
  const [valueData,setValueData] = useState([])
  const [numReporting, setNumReporting] = useState()
  const [showModal, setShowModal] = useState(false)
  const [toggel, setToogel] = useState('analyst')
  const [substractValue, setSubstractValue] = useState()
  const [dataRecieved, setDataRecieved] = useState(true)
  const [screenCheck, setScreenCheck] = useState()

  let dataValue = [0,0,0,0,0]
  const handleClose = () => setShowModal(false)

  const handleShow = () => {
      // eslint-disable-next-line no-undef
      webengage.track('Tipranks ratings viewed')
      setShowModal(true)
  }

  useEffect(()=>{
    setScreenCheck(window.screen.width)
  },[])

  // useEffect(()=>{
  //   analystData && (Object.keys(analystData).length === 0) ? setDataRecieved(false) : setDataRecieved(true)
  //   analystData && analystData.rating && analystData.rating.strongSell && analystData.rating.strongSell.length>0 && analystData.rating.strongSell[0].current && (dataValue = [...dataValue.slice(0,-1),analystData.rating.strongSell[0].current])
  //   analystData && analystData.rating && analystData.rating.strongBuy && analystData.rating.strongBuy.length>0 && analystData.rating.strongBuy[0].current && (dataValue = [analystData.rating.strongBuy[0].current,...dataValue.slice(1)])
  //   analystData && analystData.rating && analystData.rating.moderateBuy && analystData.rating.moderateBuy.length>0 && analystData.rating.moderateBuy[0].current && (dataValue = [...dataValue.slice(0,1),analystData.rating.moderateBuy[0].current,...dataValue.slice(2)])
  //   analystData && analystData.rating && analystData.rating.moderateSell && analystData.rating.moderateSell.length>0 && analystData.rating.moderateSell[0].current && (dataValue= [...dataValue.slice(0,3),analystData.rating.moderateSell[0].current,...dataValue.slice(4)])
  //   analystData && analystData.rating && analystData.rating.hold && analystData.rating.hold.length>0 && analystData.rating.hold[0].current && (dataValue = [...dataValue.slice(0,2),analystData.rating.hold[0].current,...dataValue.slice(3)])
  //   setValueData([...dataValue])
  //   analystData && analystData.numReporting && setNumReporting((100/analystData.numReporting))
  // },[analystData])


  const StrongBuy = ['Strong', <br />, 'Buy'];
  const StrongSell = ['Strong', <br />, 'Sell'];
	return (
		<>
      {/* <Row className='d-none d-md-flex'>
        <Col md={9}>
          <span className={styles.title}>Analyst ratings</span>
        </Col>
        <Col >
          <div className={styles.zack} style={{marginTop:"7px"}}>
            {`Source: Zacks`}
          </div>
        </Col>
      </Row>
      <Row className='d-none d-md-flex'>
        <Col md={9} >
          {
            data.map((index,i)=>(
              <Row key={i} style={{marginBottom:'16px',marginTop:"16px"}}>
                <Col md={3}>
                  <div className={styles.defaultValue} style={{color: i === 0 || i=== 1 ? '#0DC014' : '#010113' }}>{`${valueData[i]} ${index}`}</div>
                </Col>
                <Col style={{marginTop:"5px"}}>
                  <ProgressBar now={numReporting && valueData && (numReporting*valueData[i])} className={ i=== 0 || i===1 ? styles.defaultbar : styles.normal  }/>
                </Col>
              </Row>
            ))
          }
        </Col>
        <Col style={{ margin:'auto'}}>
          <div
            className={
              analystData
              ? analystData.maxData
                ? analystData.maxData.current
                  ? analystData.maxData.current === 'strongBuy' || analystData.maxData.current === 'strongSell'
                    ? styles.sell
                    : styles.buy
                  : styles.buy
                : styles.buy
              : styles.buy
            }
            style={{
              color:dataRecieved ? analystData
              ? analystData.maxData
                ? analystData.maxData.current
                  ? analystData.maxData.current !== 'strongBuy' && analystData.maxData.current !== 'buy'
                    ? '#9096A8'
                    : '#0DC014'
                  :'#0DC014'
                :'#0DC014'
              :'#0DC014'
              :'#9096A8',

              background:dataRecieved ? analystData
              ? analystData.maxData
                ? analystData.maxData.current
                  ? analystData.maxData.current !== 'strongBuy' && analystData.maxData.current !== 'buy'
                    ? '#EFEFF1'
                    : '#dbf6dc'
                  :'#dbf6dc'
                :'#dbf6dc'
              :'#dbf6dc'
              :'#EFEFF1'
            }}
            >
            {!(dataRecieved && analystData && analystData.rating && ((analystData.rating.strongSell && analystData.rating.strongSell.length>0)|| (analystData.rating.strongBuy && analystData.rating.strongBuy.length>0)||
                (analystData.rating.moderateBuy && analystData.rating.moderateBuy.length>0) || (analystData.rating.moderateSell && analystData.rating.moderateSell.length>0) || (analystData.rating.hold && analystData.rating.hold.length>0)))
              ? 'NA'
              : analystData
                ? analystData.maxData
                  ? analystData.maxData.current
                    ? analystData.maxData.current === 'strongBuy'
                      ? <p>{StrongBuy}</p>
                        : analystData.maxData.current === 'strongSell'
                          ? <p>{StrongSell}</p>
                          : analystData.maxData.current === 'hold'
                        ? 'Hold'
                      : analystData.maxData.current === 'sell'
                    ? 'Sell'
                  : 'Buy'
                :'Buy'
              :'Buy'
            :'Buy'
            }
          </div>
        </Col>
      </Row> */}
      <Row className='d-none d-md-flex' style={{marginTop:'24px'}}>
      <Col sm={2} lg={3} xl={2}>
        <div className={styles.icon}>
					<div className={`${styles.exclusive}`}>EXCLUSIVE</div>
          </div>
				</Col>
				<Col>
					<div className={styles.insider} onClick={()=> screenCheck >= 426 && screenCheck <= 768 ? setSubstractValue('150px',handleShow()) : setSubstractValue('500px',handleShow())}>
						{`See Research & Insider Transactions `}
            <img src={openImage}/>
					</div>
				</Col>
        </Row>

       {/* Mobile  */}

       <Row className='d-flex d-md-none' style={{marginTop:'24px'}}>
        <Col xs={4} md={2}>
        <div className={styles.icon}>
          <div className={`${styles.exclusive}`}>EXCLUSIVE</div>
        </div>
        </Col>
        <Col xs={12}>
        <div className={styles.insider} onClick={()=>setSubstractValue('0px',handleShow())}>
						{`See Research & Insider Transactions `}
            <img src={openImage}/>
					</div>
        </Col>
      </Row>
     {/* <Row className='d-flex d-md-none' style={{marginTop:"16px"}}>
        <Col  style={{paddingTop:'10px'}}>
					<span className={styles.title}>Analyst ratings</span>
          <div className={styles.zack}>{`Source: Zacks`}</div>
			  </Col>
		  <Col xs='auto'>
      <div
            className={
              analystData
              ? analystData.maxData
                ? analystData.maxData.current
                  ? analystData.maxData.current === 'strongBuy' || analystData.maxData.current === 'strongSell'
                    ? styles.sell
                    : styles.buy
                  : styles.buy
                : styles.buy
              : styles.buy
            }
            style={{
              color:dataRecieved ? analystData
              ? analystData.maxData
                ? analystData.maxData.current
                  ? analystData.maxData.current !== 'strongBuy' && analystData.maxData.current !== 'buy'
                    ? '#9096A8'
                    : '#0DC014'
                  :'#0DC014'
                :'#0DC014'
              :'#0DC014'
              :'#9096A8',

              background:dataRecieved ? analystData
              ? analystData.maxData
                ? analystData.maxData.current
                  ? analystData.maxData.current !== 'strongBuy' && analystData.maxData.current !== 'buy'
                    ? '#EFEFF1'
                    : '#dbf6dc'
                  :'#dbf6dc'
                :'#dbf6dc'
              :'#dbf6dc'
              :'#EFEFF1'
            }}
            >
            {!(dataRecieved && analystData && analystData.rating && ((analystData.rating.strongSell && analystData.rating.strongSell.length>0)|| (analystData.rating.strongBuy && analystData.rating.strongBuy.length>0)||
                (analystData.rating.moderateBuy && analystData.rating.moderateBuy.length>0) || (analystData.rating.moderateSell && analystData.rating.moderateSell.length>0) || (analystData.rating.hold && analystData.rating.hold.length>0)))
              ? 'NA'
              : analystData
                ? analystData.maxData
                  ? analystData.maxData.current
                    ? analystData.maxData.current === 'strongBuy'
                      ? <p>{StrongBuy}</p>
                        : analystData.maxData.current === 'strongSell'
                          ? <p>{StrongSell}</p>
                          : analystData.maxData.current === 'hold'
                        ? 'Hold'
                      : analystData.maxData.current === 'sell'
                    ? 'Sell'
                  : 'Buy'
                :'Buy'
              :'Buy'
            :'Buy'
            }
          </div>
			</Col>
      </Row>
      <Row className='d-flex d-md-none'>
      <Col xs={12} >
          {
            data.map((index,i)=>(
              <Row key={i} style={{marginBottom:'16px',marginTop:"16px"}}>
                <Col xs={3} md={3}>
                  <div className={styles.defaultValue} style={{color: i === 0 || i=== 1 ? '#0DC014' : '#010113' }}>{`${valueData[i]} ${index}`}</div>
                </Col>
                <Col style={{marginTop:"5px"}}>
                  <ProgressBar now={numReporting && valueData && (numReporting*valueData[i])} className={ i=== 0 || i===1 ? styles.defaultbar : styles.normal  }/>
                </Col>
              </Row>
            ))
          }
        </Col>
      </Row> */}
      <CustomModal show={showModal} handleClose={handleClose}>
        <Row style={{borderBottom:'1px solid #EFEFF1'}}>
          <Col xs={5} lg={3} style={{paddingLeft:'0px'}}>
            <div className={toggel === 'analyst' ? styles.active : styles.Inactive } onClick={()=>setToogel('analyst')}>
              Analyst consensus
              </div>
          </Col>
          <Col >
            <div className={toggel === 'insider' ? styles.active : styles.Inactive} onClick={()=>setToogel('insider')}>
              Insider Transactions
              </div>
          </Col>
        </Row>
      <iframe
        src={toggel === 'analyst' ? `https://widgets.tipranks.com/content/stockal/analystratingslato/analysts-widget.html?ticker=${stockName}` : `https://widgets.tipranks.com/content/stockal/insiderslato/insiders-widget.html?ticker=${stockName}`
        }
        frameBorder="0"
        id="rankIframe"
        style={{height: 'calc(100vh - 50px)', width: `calc(100vw - ${substractValue})`,marginTop:'4px'}}
      />
      </CustomModal>
		</>
	)
}

export default AboutRating


