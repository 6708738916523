import React, { useEffect, useState } from 'react'
import Rating from 'react-rating'
import { getShariahComplianceRating, getPartnerData } from '../../api'
import styles from './StockDetail.module.css'
import StockDetailBox from './stockDetailBox'
import BuyIcon from '../../public/images/buyIcon.svg'
import OwnIcon from '../../public/images/OwnIcon.svg'
import watchEyeIcon from '../../public/images/watchEyeIcon.svg'
import infoIconOrange from '../../public/images/infoIconOrange.svg'
import infoIcon from '../../public/images/greyInfoIcon.svg'
import shariyaStarIcon from '../../public/images/shariyaStarIcon.svg'
import shariyaStarIconGrey from '../../public/images/shariyaStarIconGrey.svg'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const StockHighlights = (props) => {
    
    const { highlightsData , isOtc, stockName, isEtf } = props

    const [ isShariahAvailable, setIsShariahAvailable] = useState(false)
    const [ isShariahCompliant, setIsShariahCompliant] = useState()
    const [ shariahRating, setShariahRating ] = useState()
    const [ shariahData, setShariahData ] = useState()
    const [ shariahCompliantStatus, setShariahCompliantStatus] = useState()
    const [ shariahTextColor, setShariahTextColor] = useState('#767C8F')
    const [ userLocation, setUserLocation] = useState('')

    const getShariahComplianceData = (stockName) => {
        getShariahComplianceRating(stockName).then(response => {
            if(response && response.code === 200 && response.data){
               const shariahData = response.data
               setIsShariahAvailable( shariahData.isAvailable ? shariahData.isAvailable : false )
               setShariahCompliantStatus( shariahData.status ? shariahData.status : '')
               setShariahRating( shariahData.ranking ? shariahData.ranking : 0)
            } else {
                setIsShariahAvailable(false)
            }
        }).catch((err)=>{
            console.error(err)
        })
    }

    const setShariahComplianceData = () => {
        if(isShariahAvailable) {
            if(shariahCompliantStatus && shariahCompliantStatus === 'COMPLIANT'){
                setIsShariahCompliant(true)
                setShariahData(`${shariahRating}-Star Halal`)
            } else if(shariahCompliantStatus && shariahCompliantStatus === 'NON_COMPLIANT') {
                setIsShariahCompliant(false)
                setShariahData(`Not Halal`)
                setShariahTextColor('#FA274A')
            } else if(shariahCompliantStatus && shariahCompliantStatus === 'QUESTIONABLE') {
                setIsShariahCompliant(false)
                setShariahData(`Doubtful`)
                setShariahTextColor('#FA5D05')
            } else if(shariahCompliantStatus && shariahCompliantStatus === 'NOT_UNDER_COVERAGE') {
                setIsShariahCompliant(false)
                setShariahData(`Not Covered`)
            } 
        } else {
            setIsShariahCompliant(false)
            setShariahData(`Not Covered`)
        }
    }

    const otcSecurityToolTip = (props) => {
        return (
          <Tooltip {...props} className={styles.otcToolTip}>
              <p className={styles.otcToolTipHeader}>Over-the-Counter (OTC) Security</p>
              <p className={styles.otcToolTipText}>This security isn’t listed on a supported exchange. However, an over-the-counter trade may be facilitated only during core trading hours (9:30 AM - 4:00 PM EST).</p>
          </Tooltip>
        )
    }

    const shariyaComplianceToolTip = (props) => {
        return (
            <Tooltip {...props} className={styles.shariyaToolTip}>
              {isShariahCompliant && isShariahCompliant === true ? 
                    <div className={styles.ratingContainer} style={{marginBottom:"9px"}}>
                        <Rating 
                            fractions={2} 
                            initialRating={shariahRating} 
                            readonly
                            emptySymbol={<img src={shariyaStarIconGrey} alt="icon"></img>} 
                            fullSymbol={<img src={shariyaStarIcon} alt="icon"></img>}
                        />
                    </div> : ''
                }
              <p className={styles.otcToolTipHeader} style={{marginBottom:"4px"}}>Sharia Compliance based on AAOIFI methodology</p>
              <p className={styles.otcToolTipText}><a href="https://musaffa-ng.s3.amazonaws.com/public-files/Amanah+Advisors+Shariah+Certificate+-+Musaffa-001.docx.pdf" rel="noopener noreferrer" target="_blank">Powered by Musaffa</a></p>
            </Tooltip>
        )
    }

    const userInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : ''
    const partnerCode = userInfo && userInfo.partner && userInfo.partner.code ? userInfo.partner.code :''

    const getUserLocation = async() => {
        const response = await getPartnerData(partnerCode)
        if(response && response.code === 200 && response.data) {
          setUserLocation(response.data.location ? response.data.location : '')
        } else {
          setUserLocation('')
        }
    }

    useEffect(()=>{
        if(partnerCode) {
            getUserLocation()
        }
    },[])

    useEffect(()=> {
            getShariahComplianceData(stockName)
    },[stockName])

    useEffect(()=>{
        setShariahComplianceData()
    },[isShariahAvailable, shariahCompliantStatus, shariahRating])

    return (
        <div className={styles.stockHighlightsContainer}>
            <div className={styles.otcIndicator} style={{display: isOtc && isOtc === true ? 'flex':'none'}}>
                <p>OTC Security</p>
                <OverlayTrigger
                    placement="top"
                    trigger={['hover', 'focus','click']}
                    delay={{ show: 250, hide: 400 }}
                    overlay={otcSecurityToolTip}
                >
                    <img src={infoIconOrange} alt='icon'/>
                </OverlayTrigger>     
            </div>
            <div style={{display : highlightsData && highlightsData.watching && highlightsData.watching !== 0 ? 'flex':'none'}}>
                <StockDetailBox
                    icon={watchEyeIcon}
                    value={
                    highlightsData
                        ? highlightsData.watching
                        ? `${highlightsData.watching} Watching`
                        : '0 Watching'
                        : '0 Watching'
                    }
                />
            </div>
            <div style={{display : highlightsData && highlightsData.owning && highlightsData.owning !== 0 ? 'flex':'none'}}>
                <StockDetailBox
                    icon={OwnIcon}
                    value={
                    highlightsData
                        ? highlightsData.owning
                        ? `${highlightsData.owning} Own`
                        : '0 Own'
                        : '0 Own'
                    }
                />
            </div>
            <div style={{display : highlightsData && highlightsData.ratings && highlightsData.ratings.percentage && highlightsData.ratings.percentage !== 0 ? 'flex':'none'}}>
                <StockDetailBox
                    icon={BuyIcon}
                    value={
                    highlightsData
                        ? highlightsData.ratings
                        ? highlightsData.ratings.percentage
                            ? `${Math.round(highlightsData.ratings.percentage)}% Buy`
                            : '0% Buy'
                        : '0% Buy'
                        : '0% Buy'
                    }
                />
            </div>
            {isEtf || userLocation !== 'MENA' ? '' : 
                <div className={styles.shariyaComplianceIndicator}>
                    {isShariahCompliant && isShariahCompliant === true ? <img src={shariyaStarIcon} alt='icon'/> : ''}
                    <p>
                        <span style={{color:shariahTextColor}}>{shariahData}</span>
                    </p>
                    <OverlayTrigger
                        placement="top"
                        trigger={['hover', 'focus','click']}
                        delay={{ show: 250, hide: 800 }}
                        overlay={shariyaComplianceToolTip}
                    >
                        <img src={infoIcon} alt='icon' style={{width:"16.5px"}}/>
                    </OverlayTrigger>     
                </div>
            }

            <style jsx>{`
                .tooltip.show {
                    opacity: 1.0 !important
                }
            `}</style>
        </div>
    )
}

export default StockHighlights