import React, { useEffect } from 'react'
import Route from './route'
import Cookies from './components/Cookies/Cookies'
import axios from 'axios'
import './global.css'
import { withRouter } from 'react-router-dom'

function App() {
	// useEffect(() => {
	//   const handleTabClose = event => {
	//     event.preventDefault();
	//       //webengage logout
	//       // eslint-disable-next-line no-undef
	//       webengage.user.logout()
	//     return ''
	//   };

	//   window.addEventListener('beforeunload', handleTabClose);

	//   return () => {
	//     window.removeEventListener('beforeunload', handleTabClose);
	//   };
	// }, []);

	useEffect(() => {
		const handleBeforeUnload = event => {
			return null
		}

		window.addEventListener('beforeunload', handleBeforeUnload)

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload)
		}
	}, [])

	// useEffect(() => {
	//   let tokenInterval = setInterval(getRefreshToken, 1000 * 40)
	//   return () => {
	//     clearInterval(tokenInterval);
	//   }
	// }, [])

	// const getRefreshToken = async () => {
	//   const token = JSON.parse(sessionStorage.getItem('token'))
	//   if (token && token.refreshToken) {
	//     // alert('refresh token')
	//     const data = { refreshToken: token.refreshToken }
	//     const response = await axios.post(
	//       process.env.REACT_APP_BASE_API + '/v2/refresh-with-user',
	//       data
	//     )
	//     if (response && response.data && response.data.data) {
	//       const accessToken = response.data.data['accessToken']
	//       const refreshToken = response.data.data['refreshToken']
	//       if (accessToken && refreshToken) {
	//         const token = {
	//           accessToken: accessToken,
	//           refreshToken: refreshToken
	//         }
	//         sessionStorage.setItem('token', JSON.stringify(token))
	//       }
	//     }
	//     // else{
	//     //   console.info('redirect to signin')
	//     //   // this.props.history.push('/signin')
	//     // }
	//   }
	// }

	return (
		<>
			{/* <Cookies/> */}
			<Route />
		</>
	)
}

export default withRouter(App)
