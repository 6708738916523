import React, {Fragment} from 'react';
import { Row, Col,Accordion } from 'react-bootstrap'
import infoIcon from '../../public/images/info-icon.svg'
import noteImage from '../../public/images/note.png'
import arrow from '../../public/images/icon-arrow-right.svg'
import styles from './instruction.module.css'
import {circleA, circleB, circleC, circleD, circleE, circleF, circleG, circleH, circleI, circleJ, circleK} from './assets'
import Copy from './copy'
import CustomToggle from './customToggle'
import DownloadInstruction from './downloadInstruction';
import TableError from './tableError'
import DetailError from './detailError';

const IciciLRS= ({info, fetchBrokerDetails}) => {
	const cloudfront = `${process.env.REACT_APP_ASSESTS}/platform/fund/icici`
	return(
		<>
			<Row>
				<Col>
					<div className={styles.bankName}>ICICI Bank</div>
				</Col>
				<Col style={{ textAlign: 'end'}} className='pl-0'>
					<DownloadInstruction bank={'ICICI_BANK'}/>
				</Col>
				<Col md={12} style={{ marginTop: '30px',marginBottom:"30px" }}>
					<div className={styles.transactionCard}>
						<img src={noteImage} />
						<span className={styles.transaction}>
							There is a limit of $25,000 on online transaction per day. If you
							need to remit more, please use Offline Transfer.
						</span>
					</div>
				</Col>
			</Row>
			<Row>
				<Accordion defaultActiveKey='0' className={styles.responsiveInstructions}>
					<CustomToggle
						eventKey='0'
						title='Step 1. Add Beneficiary' />
					<Accordion.Collapse eventKey='0'>
						<div className={`card-body ${styles.cardBody}`}>
							<ol className={styles.list} >
								<li>
									<div className={styles.netbanking}>
										Go to ICICI Bank Money2World using the following web link:
									</div>
									<div className={styles.login}>
										<a href='https://www.icicibank.com/Personal-Banking/onlineservice/online-services/FundsTransfer/outward-remittance/online-transfer/index.page' target="_blank" rel="noopener noreferrer" style={{color:"#0061D3"}}>
											{`Go to ICICI Bank Money2World`}
										</a>
										<a href='https://www.icicibank.com/Personal-Banking/onlineservice/online-services/FundsTransfer/outward-remittance/online-transfer/index.page' target="_blank" rel="noopener noreferrer">
											<img src={arrow} alt="arrow" style={{marginLeft:"7px"}}/>
										</a>
									</div>
								</li>
								<li>
									<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
										If you have an ICICI Bank Account, click on &nbsp;
										<img src={circleA} className={styles.circularPointer} />
										<span className={styles.key}>I Have An ICICI Bank Account </span>
									</div>
									<div className={styles.marginStepSecondary}>
										<img src={`${cloudfront}/img1.jpg`} className={styles.responsiveImage}/>
									</div>
								</li>
								<li>
									<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
										Click On &nbsp;
										<img src={circleA} className={styles.circularPointer} />
										<span className={styles.key}>Overseas Transfer &nbsp;</span>
										<img src={circleB} className={styles.circularPointer} />
										<span className={styles.key}>Send Money Now</span>
									</div>
									<div className={styles.marginStepSecondary}>
										<img src={`${cloudfront}/img2.jpg`} className={styles.responsiveImage}/>
									</div>
								</li>
								<li>
									<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
										Select the Transfer Currency as &nbsp;
										<img src={circleA} className={styles.circularPointer} />
										<span className={styles.key}>US Dollar (USD)</span>
									</div>
									<div className={`${styles.netbanking}`}>
										Click On &nbsp;
										<img src={circleB} className={styles.circularPointer} />
										<span className={styles.key}>Add Receiver</span> to add a new beneficiary.
									</div>
									<div className={styles.marginStepSecondary}>
										<img src={`${cloudfront}/img3.jpg`} className={styles.responsiveImage}/>
									</div>
								</li>
								<li>
									<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
										Select the Receiver Type as &nbsp;
										<img src={circleA} className={styles.circularPointer} />
										<span className={styles.key}>Other than Self (Individual)</span>
									</div>
									<div className={styles.marginStepSecondary}>
										<img src={`${cloudfront}/img4.jpg`} className={styles.responsiveImage}/>
									</div>
								</li>
								<li>
									<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
										Fill in the following columns with the details mentioned under the next point :
									</div>
									<div className={styles.marginStepSecondary}>
										<img src={`${cloudfront}/img5.jpg`} className={styles.responsiveImage}/>
									</div>
								</li>
								<li>
									<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
										Enter the beneficiary details as shown below:
									</div>
									<div style={{ overflow:'hidden'}} className={`${styles.beneficiaryTable} ${styles.marginStepSecondary} ${!info&&styles.beneficiaryTableError}`}>
										{!info ? <TableError fetchBrokerDetails={fetchBrokerDetails}/>
											:<Fragment>
												<div  className={styles.beneficiaryTableRow}>
													<img src={circleA} className={styles.circularPointer} />
													<div className={styles.beneficiaryTableContent}>
														<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
															Receiver Name:
														</div>
														<div className={styles.beneficiaryTableValue}>
															<span>{info.name}</span>
															<Copy data={{label:'Receiver Name', value:info.name}}/>
														</div>
													</div>
												</div>
												<div  className={styles.beneficiaryTableRow}>
													<img src={circleB} className={styles.circularPointer} />
													<div className={styles.beneficiaryTableContent}>
														<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
															Receiver Nick Name:
														</div>
														<div className={styles.beneficiaryTableValue}>
															<span>{info.nickName}</span>
															<Copy data={{label:'Receiver Nick Name', value:info.nickName}} />
														</div>
													</div>
												</div>
												<div  className={styles.beneficiaryTableRow}>
													<img src={circleC} className={styles.circularPointer} />
													<div className={styles.beneficiaryTableContent}>
														<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
															Receiver Address:
														</div>
														<div className={styles.beneficiaryTableValue}>
															<span>{info.address}</span>
															<Copy data={{label:'Receiver Address', value:info.address}} />
														</div>
													</div>
												</div>
												<div  className={styles.beneficiaryTableRow}>
													<img src={circleD} className={styles.circularPointer} />
													<div className={styles.beneficiaryTableContent}>
														<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
															Pin Code:
														</div>
														<div className={styles.beneficiaryTableValue}>
															<span>{info.pinCode}</span>
															<Copy data={{label:'Pin Code', value:info.pinCode}} />
														</div>
													</div>
												</div>
												<div  className={styles.beneficiaryTableRow}>
													<img src={circleE} className={styles.circularPointer} />
													<div className={styles.beneficiaryTableContent}>
														<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
															Country:
														</div>
														<div className={styles.beneficiaryTableValue}>
															<span>{info.country}</span>
															<Copy data={{label:'Country', value:info.country}} />
														</div>
													</div>
												</div>
												<div  className={styles.beneficiaryTableRow}>
													<img src={circleF} className={styles.circularPointer} />
													<div className={styles.beneficiaryTableContent}>
														<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
															Receiver Bank Country:
														</div>
														<div className={styles.beneficiaryTableValue}>
															<span>{info.bankCountry}</span>
															<Copy data={{label:'Receiver Bank Country', value:info.bankCountry}} />
														</div>
													</div>
												</div>
												<div className={`${styles.beneficiaryAccNum} ${styles.beneficiaryTableRow}`}>
												    <img src={circleG} className={styles.circularPointer} />
													<div className={styles.beneficiaryTableContent}>
														<div>
															<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
																Receiver Account Number:
															</div>
															<div className={styles.divider}></div>
														</div>
														<div className={styles.beneficiaryAccNumValue}>
															<div className={styles.beneficiaryTableValue}>
																<span>{info.accountNumber}</span>
																<Copy data={{label:'Receiver Account Number', value:info.accountNumber}} />
															</div>
															<div className={styles.divider}></div>
															<div className={styles.beneficiaryAccInfo}>Everyone has an Unique Beneficiary Account Number.</div>
														</div>
													</div>
												</div>
												<div  className={styles.beneficiaryTableRow}>
													<img src={circleH} className={styles.circularPointer} />
													<div className={styles.beneficiaryTableContent}>
														<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
															Receiver Bank SWIFT Code:
														</div>
														<div className={styles.beneficiaryTableValue}>
															<span>{info.SWIFTCode}</span>
															<Copy data={{label:'Receiver Bank SWIFT Code', value:info.SWIFTCode}} />
														</div>
													</div>
												</div>
												<div  className={styles.beneficiaryTableRow}>
													<img src={circleI} className={styles.circularPointer} />
													<div className={styles.beneficiaryTableContent}>
														<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
															Receiver Bank Name:
														</div>
														<div className={styles.beneficiaryTableValue}>
															<span>{info.bankName}</span>
															<Copy data={{label:'Receiver Bank Name', value:info.bankName}} />
														</div>
													</div>
												</div>
											</Fragment>
										}
									</div>
								</li>
								<li>
									<div className={`d-flex flex-column align-items-start ${styles.netbanking} ${styles.marginStepPrimary}`} >
										<div className={styles.netbanking}>
											After filling all details, click on &nbsp;
											<img src={circleA} className={styles.circularPointer} />
											<span className={styles.key}>Continue</span>
										</div>
										<div className={styles.netbanking}>
											Confirm the added details and Add the Receiver after an <span className={styles.key}>OTP Verification.</span>
										</div>
										<div className={styles.marginStepSecondary}>
											<img src={`${cloudfront}/img6.jpg`} className={styles.responsiveImage}/>
										</div>
									</div>
								</li>
							</ol>
						</div>
					</Accordion.Collapse>
				</Accordion>
				<Accordion defaultActiveKey='0' className={styles.responsiveInstructions}>
					<CustomToggle
					eventKey='1'
					title='Step 2. Transfer funds' />
					<Accordion.Collapse eventKey='1'>
						<div className={`card-body ${styles.cardBody}`}>
							<ol className={styles.list} >
								<li>
									<div className={styles.netbanking}>
										Go to ICICI Bank Money2World using the following web link:
									</div>
									<div className={styles.login}>
										<a href='https://www.icicibank.com/Personal-Banking/onlineservice/online-services/FundsTransfer/outward-remittance/online-transfer/index.page' target="_blank" rel="noopener noreferrer" style={{color:"#0061D3"}}>
											{`Go to ICICI Bank Money2World`}
										</a>
										<a href='https://www.icicibank.com/Personal-Banking/onlineservice/online-services/FundsTransfer/outward-remittance/online-transfer/index.page' target="_blank" rel="noopener noreferrer">
											<img src={arrow} alt="arrow" style={{marginLeft:"7px"}}/>
										</a>
									</div>
								</li>
								<li>
									<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
										If you have an ICICI Bank Account, click on &nbsp;
										<img src={circleA} className={styles.circularPointer} />
										<span className={styles.key}>I Have An ICICI Bank Account </span>
									</div>
									<div className={styles.marginStepSecondary}>
										<img src={`${cloudfront}/img1.jpg`} className={styles.responsiveImage}/>
									</div>
								</li>
								<li>
									<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
										Click On &nbsp;
										<img src={circleA} className={styles.circularPointer} />
										<span className={styles.key}>Overseas Transfer &nbsp;</span>
										<img src={circleB} className={styles.circularPointer} />
										<span className={styles.key}>Send Money Now</span>
									</div>
									<div className={styles.marginStepSecondary}>
										<img src={`${cloudfront}/img2.jpg`} className={styles.responsiveImage}/>
									</div>
								</li>
								<li>
									<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
										Select the Transfer Currency as &nbsp;
										<img src={circleA} className={styles.circularPointer} />
										<span className={styles.key}>US Dollar (USD)</span>
									</div>
									<div className={`${styles.netbanking}`}>
										Click On &nbsp;
										<img src={circleB} className={styles.circularPointer} />
										<span className={styles.key}>Transfer Money</span> to transfer funds.
									</div>
									<div className={styles.marginStepSecondary}>
										<img src={`${cloudfront}/img7.jpg`} className={styles.responsiveImage}/>
									</div>
								</li>
								<li>
									<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
										Fill in the transfer details as shown below:
									</div>
									<ol type = "i" className={styles.list}>
										<li>
											<div className={`d-flex flex-column align-items-start ${styles.netbanking} ${styles.marginStepPrimary} ${styles.leftMargin}`}>
												Select the following receiver to auto fill all beneficiary details:
												{info ?
													<div className={styles.netbanking}>
														<img src={circleA} className={styles.circularPointer} />
														<span className={styles.key}>{info.name}</span>
													</div>
													:<DetailError className='pt-2' fetchBrokerDetails={fetchBrokerDetails} label='Receiver Info'/>
												}

											</div>
										</li>
										<li>
											<div className={`${styles.netbanking} ${styles.marginStepPrimary} ${styles.leftMargin}`}>
												Select Purpose of Remittance under LRS as follows: &nbsp;
												<div className={styles.netbanking}>
													<img src={circleB} className={styles.circularPointer} />
													<span className={styles.key}>Indian Portfolio Investment Abroad - In Equity Shares</span>
												</div>
											</div>
										</li>
										<li>
											<div className={`${styles.netbanking} ${styles.marginStepPrimary} ${styles.leftMargin}`}>
												Select the Country where ultimate service provided as &nbsp;
												<img src={circleC} className={styles.circularPointer} />
												<span className={styles.key}>United States of America.</span>
											</div>
											<div className={styles.marginStepSecondary}>
												<img src={`${cloudfront}/img8.jpg`} className={styles.responsiveImage}/>
											</div>
										</li>
									</ol>
								</li>

								<li>
									<div className={`d-flex flex-column align-items-start ${styles.netbanking} ${styles.marginStepPrimary}`}>
										Fill the following details according to self interest.
										<div className={styles.netbanking}>
											<img src={circleA} className={styles.circularPointer} />
											<span className={styles.key}>Name of Company &nbsp;</span>
											Any stock you’d like to invest in
										</div>
										<div className={styles.netbanking}>
											<img src={circleB} className={styles.circularPointer} />
											<span className={styles.key}>Remitter Beneficiary Relationship &nbsp;</span>
											For Example: Investor
										</div>
										<div className={styles.netbanking}>
											<img src={circleC} className={styles.circularPointer} />
											<span className={styles.key}>Type of Company &nbsp;</span>
											<img src={circleD} className={styles.circularPointer} />
											<span className={styles.key}>Country of Incorporation &nbsp;</span>
											<img src={circleE} className={styles.circularPointer} />
											<span className={styles.key}>Listing Status</span>
										</div>
										<div className={styles.netbanking}>
											<img src={circleF} className={styles.circularPointer} />
											<span className={styles.key}>Listing Stock Exchange &nbsp;</span>
											<img src={circleG} className={styles.circularPointer} />
											<span className={styles.key}>Quantity of Equity Shares to be Acquired</span>
										</div>
										<div className={styles.netbanking}>
											<img src={circleH} className={styles.circularPointer} />
											<span className={styles.key}>Percentage of Equity Shares being acquired</span>
										</div>
										<div className={styles.marginStepSecondary}>
											<img src={`${cloudfront}/img9.jpg`} className={styles.responsiveImage}/>
										</div>
									</div>
								</li>
								<li>
									<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
										Select the Source of Funds as &nbsp;
										<img src={circleA} className={styles.circularPointer} />
										<span className={styles.key}>Own Funds &nbsp;</span>
										and tick the checkbox to confirm.
									</div>
									<div className={styles.marginStepSecondary}>
										<img src={`${cloudfront}/img10.jpg`} className={styles.responsiveImage}/>
									</div>
								</li>
								<li>
									<div className={`d-flex flex-column align-items-start ${styles.netbanking} ${styles.marginStepPrimary}`}>
										Read the Terms & Conditions and Financial Limits & Declarations.
										<div className={styles.netbanking}>
											Tick the box to &nbsp;
											<img src={circleA} className={styles.circularPointer} />
											<span className={styles.key}>Accept and Agree to the Terms & Conditions</span>
										</div>
										<div className={styles.netbanking}>
											Click on &nbsp;
											<img src={circleB} className={styles.circularPointer} />
											<span className={styles.key}>I Accept &nbsp;</span>
											to accept the Financial Limits & Declarations and &nbsp;
											<img src={circleC} className={styles.circularPointer} />
											<span className={styles.key}>Continue</span>
										</div>
										<div className={styles.marginStepSecondary}>
											<img src={`${cloudfront}/img11.jpg`} className={styles.responsiveImage}/>
										</div>
									</div>
								</li>
								<li>
									<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
										Confirm all details and click on <span className={styles.key}>Continue.</span>
									</div>
									<div className={styles.netbanking}>
										Initiate the transfer using <span className={styles.key}>OTP Verification</span> on the <span className={styles.key}>Transaction Confirmation Page.</span>
									</div>
								</li>
								<li>
									<div className={`d-flex flex-column align-items-start ${styles.netbanking} ${styles.marginStepPrimary}`}>
										<div className={styles.netbanking}>
											The process to remit funds is now complete!
										</div>
										<div className={styles.marginStepSecondary}>
											Please note that it takes
											<span className={styles.key}> 2-3 Business Days </span>
											after the Deduction of Funds from your Bank Account for it to reflect in your Investment Account.
										</div>
									</div>
								</li>
							</ol>
						</div>
					</Accordion.Collapse>
				</Accordion>
			</Row>
		</>
	)
}

export default IciciLRS;
