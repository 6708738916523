import React, {Component} from "react";
import vectorOpen from "../../public/images/reportClose.png";
import vectorClose from "../../public/images/reportOpen.png";
import downloadImage from "../../public/images/download.png";

class ReportModalHeader extends Component {
  constructor(props) {
    super();
    this.state = {
      clicked: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(recieve) {
    // console.log(recieve);
    this.setState((prevState) => ({
      clicked: !prevState.clicked,
    }));
  }

  render() {
    return (
      <div className="row">
        <div className="col">
          {this.props.showAccordion ?
          <span style={{paddingRight: "5px"}} onClick={() => this.handleClick("clicked") }>
            <img src={this.state.clicked ? vectorOpen : vectorClose}/>
          </span> : '' }
          <span
            style={{
              color: "black",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "14px",
              lineHeight: "20px",
              letterSpacing: "0.6px",
            }}
          >
            {this.props.data.displayName}
          </span>
        </div>
        <div className="col text-right">
          {
            this.props.data.fileKey ? (<div>
              <span style={{paddingRight: "5px"}}>
                <img src={downloadImage}/>
              </span>
              <span
                style={{
                  textDecoration: "underline",
                  fontFamily: "Lato",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "12px",
                  lineHeight: "14px",
                  color: "#005DD6",
                }}
                onClick={() => this.props.downloadStatement(this.props.data.fileKey)}
              >
                Download
              </span></div>)
              : ''

          }

        </div>
      </div>
    );
  }
}

export default ReportModalHeader;

{
  /* <div className="row" onClick={() => this.handleClick("clicked")}>
<div
  className="col-md-1"
  style={{ padding: "0px", paddingLeft: "35px" }}
>
  <img src={this.state.clicked ? vectorOpen : vectorClose} />
</div>
<div className="col-md-8" style={{ padding: "0px" }}>
  {this.props.data}
</div>
<div className="col-md-1" style={{ padding: "0px", textAlign: "end" }}>
  <img src={downloadImage} />
</div>
<div
  className="col-md-2"
  style={{
    padding: "0px",
    paddingLeft: "10px",
    paddingTop: "4px",
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#005DD6",
  }}
>
  Download
</div>
</div> */
}
