import React, { useState, useRef, useEffect, useContext } from 'react'
import { Row, Col, Form, Card } from 'react-bootstrap'
import { Formik } from 'formik'
import * as yup from 'yup'
import { Link } from 'react-router-dom'
import { generateOtp } from '../../api'
import styles from './ForgotUserName.module.css'
import SignUpInput from '../SignUp/SignUpInput'
import SignUpButton from '../SignUp/SignUpButton'
import EmailSvg from '../../public/images/newOnboarding/Email.svg'

const EmailForm = ({ updateFormData, changeCurrent, updateHashId }) => {
	const [email, setEmail] = useState('')
	const [userExist, setUserExist] = useState('')
	const [errormessage, setErrormessage] = useState('')
  const [loading,setLoading] = useState(false)
	const editFieldRef = useRef(null)

	const formInitialValues = {
		email
	}

	useEffect(() => {
		editFieldRef.current.focus()
	}, [])

	const validationSchema = yup.object({
		email: yup
			.string()
			.email('Enter a valid email such as example@email.com')
			// .matches(
			// 	/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/,
			// 	'Enter a valid email such as example@email.com'
			// )
			.required('Email is a required field')
	})

	const emailValidation = (event, handleChange) => {
		const e = event
		setEmail(e.target.value.toLowerCase())
		setErrormessage('')
		setUserExist('')
		handleChange(e)
	}

	const keyPressHandler = (event, submitCallback) => {
		if (event.which === 13) {
			submitCallback()
		}
	}

	const handleSubmit = values => {
    setLoading(true)
		const { email } = values
		setErrormessage('')
		generateOtp(email.toLowerCase(), 'forgot_username')
			.then(response => {
				if (response.code === 200) {
					const { data: { hashID = '' } = {} } = response
					const data = {
						email: email.toLowerCase()
					}
					updateFormData(data)
					updateHashId(hashID)
					changeCurrent('VerifyEmail')
				} else {
					setErrormessage('Failed to generate OTP')
				}
				setUserExist('',setLoading(false))
			})
			.catch(error => {
				if (error && error.data.code === 400) {
					setErrormessage(error.data.message)
				} else if (error && error.data.code === 404) {
					setUserExist(error.data.message)
				} else {
					setErrormessage('Failed to generate OTP')
				}
        setLoading(false)
			})
	}

	return (
		<>
			<Row className={styles.title}>Forgot username?</Row>
			<Row className={styles.subTitle}>
				Please enter your email address. We’ll be sending you an OTP to verify
				it.
			</Row>
			<Formik
				initialValues={formInitialValues}
				validationSchema={validationSchema}
				onSubmit={values => handleSubmit(values)}>
				{({
					handleChange,
					handleBlur,
					dirty,
					handleSubmit,
					values,
					touched,
					errors
				}) => {
					return (
						<div>
							<Row className={styles.inputs}>
								<SignUpInput
									label='Email Address'
									placeholder='Enter Email Address'
									type='text'
									name='email'
									tabIndex='1'
									refValue={editFieldRef}
									value={values.email}
									onChange={event => emailValidation(event, handleChange)}
									onKeyPress={e => keyPressHandler(e, handleSubmit)}
									isInvalid={(touched.email && errors.email) || errormessage || userExist}
									isValid={
										touched.email && !errors.email && errormessage === '' && userExist === ''
									}
									errorMessage={errors.email || errormessage}
									icon={EmailSvg}
								/>
								{userExist && (
									<div className={styles.userError}>
										{userExist} &nbsp;
										<Link to='/signup'>
											<a>
												<span
													style={{
														color: '#0061D3',
														fontFamily: 'geomanistbook'
													}}>
													Sign Up?
												</span>
											</a>
										</Link>
									</div>
								)}
							</Row>

							<Row className={styles.buttonMargin}>
								<SignUpButton
									classes={styles.verifyOtp}
									title='Send OTP'
                  loading={loading}
									onClick={handleSubmit}
								/>
							</Row>
						</div>
					)
				}}
			</Formik>
		</>
	)
}

export default EmailForm
