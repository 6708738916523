import axios from 'axios'
import { Mutex } from 'async-mutex';
import { sessionLogout } from './services/apiService'

axios.defaults.baseURL = process.env.REACT_APP_BASE_API + '/v2'
axios.defaults.headers.common['Content-Type'] = 'application/json'
const mutex = new Mutex();

const onRetry = async (req) => {
  const { config } = req;

  const { accessToken } = JSON.parse(sessionStorage.getItem('token'))
  const options = {
    method: config.method,
    url: config.url,
    headers: config.headers,
    data: config.data
  }
  options.headers['Authorization'] = accessToken

  const resp = await axios(options)
  return resp;
}

axios.interceptors.response.use(
 async (response) => {
  // if(
  // response.config.url.includes('update-account') ||
  // response.config.url.includes('payment/subscription') ||
  // response.config.url.includes('upgrade-subscription')){
  //   let { refreshToken } = JSON.parse(sessionStorage.getItem('token'))
  //   if (refreshToken) {
  //     const data = { refreshToken }
  //     const option = {
  //       method: 'post',
  //       url: process.env.REACT_APP_BASE_API + '/v2/refresh-with-user',
  //       data,
  //     }
  //     await axios(option)
  //   }
  // }
    if (response.data.data) {
      if (
        response.config.url.includes('login-with-user') ||
        response.config.url.includes('refresh-with-user') ||
        response.config.url.includes('create-account') ||
        response.config.url.includes('create-user')
      ) {
        const accessToken = response.data.data['accessToken']
        const refreshToken = response.data.data['refreshToken']
        if (accessToken || refreshToken) {
          const token = {
            accessToken: accessToken,
            refreshToken: refreshToken
          }
          sessionStorage.setItem('token', JSON.stringify(token))
        }
      }
    }
    return response
  },
  async (error) => {
    try {
      const { status, statusText, data, config } = error.response
      if (status !== 401 || (status === 401 && config.url.includes('login-with-user')) || (status === 401 && config.url.includes('partnersso')) || (status === 401 && config.url.includes('oauth/login'))) {
        return Promise.reject(error)
      }
      if (status === 401) {
        if(config.url.includes('socketAuth')){
          return Promise.resolve()
        }
        else if (status === 401 && config.url.includes('refresh-with-user')) {
          return sessionLogout()
        }
        else {
          const release = await mutex.acquire();
          let { accessToken, refreshToken } = JSON.parse(sessionStorage.getItem('token'))
          let retry = false;
          try {
            if (accessToken != null && accessToken !== error.config.headers.Authorization) {
              retry = true;
            } else {
              if (refreshToken) {
                const data = { refreshToken }
                const option = {
                  method: 'post',
                  url: process.env.REACT_APP_BASE_API + '/v2/refresh-with-user',
                  data,
                }
                const resp = await axios(option)
                if (resp && (resp.code === 401 || resp.code === 403)) {
                  return sessionLogout()
                }
                retry = resp && resp.status === 200;
              } else {
                sessionLogout()
              }
            }
          } finally {
            release()
          }
          if (retry) {
            const resp = await onRetry(error.response);
            return Promise.resolve(resp)
          }
        }
      }
    } catch (err) {
      return Promise.reject(err)
    }
  }
)

axios.interceptors.request.use(
  request => {
    const token = JSON.parse(sessionStorage.getItem('token'))
    if (token && !request.url.includes('refresh-with-user')) {
      request.headers['Authorization'] = token.accessToken
    }
    return request
  },
  error => {
    return Promise.reject(error)
  }
)
