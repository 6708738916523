import { gethighLowStocks } from '../../api'
import { YEAR_LOW } from './action'

const getYearLowStocks = () => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			gethighLowStocks('yearLow').then(response => {
				if (response && response.length > 0) {
					resolve(dispatch({ type: YEAR_LOW, payload: response}))
				}
			})
		})
	}
}

export default getYearLowStocks
