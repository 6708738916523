import React, { useEffect, useState, useContext } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { ThemeContext } from '../../../Context/ThemeContext'
import styles from '../Profile.module.css'
import $ from 'jquery'
import {getAccountClosureDisclosure, accountDeactivation} from '../../../api'
import roundCheck from '../../../public/images/round-check.png'
const AccountClosure = props => {
	const { buttonColor } = useContext(ThemeContext)
  const [disclosure,setDisclosure] = useState({})
  const [showDisclosure,setShowDisclosure] = useState(false)

  const asyncFetch = async () => {
    try{
    const response = await getAccountClosureDisclosure()
    if(response.code === 200){
      setDisclosure(response.data)
    }
    }catch(error){
      console.log('ext','error',error)
    }
  }

  useEffect(() => {
    asyncFetch()
  },[])


  const initiateDeactivation= async()=>{
    // $('#mdlAccountClosure').modal('show')
    try{
      const response = await accountDeactivation()
      if(response.code === 200){
        $('#mdlAccountClosure').modal('show')
      }
      }catch(error){
        console.log('ext','error',error)
      }
  }
  const handleClick=()=>{
    setShowDisclosure(true);
  }

	return (
		<>

			<Row className={styles.basicDetail}>
				<Col className={styles.basicDetailText+" "+styles.heading}>{(disclosure && disclosure.header) ? disclosure.header : 'Account Closure' }</Col>

			</Row>
			<Row className={`m-0 mb-4 ${styles.borderBottom}`}>
				<Col md={7} xs={12} className='mb-3 mt-3'>
					<div className={styles.closureHead}>This is permanent and irreversable action. You can reach out to our support team to ensure that the
           {!showDisclosure ? <u className={styles.closureLink} onClick={()=>handleClick()}> following steps </u> : ' following steps '}

           have been taken.</div>
          {showDisclosure ?
          <>
          <div className={styles.label}>
            <div dangerouslySetInnerHTML={{__html: disclosure.content}}/>
            {/* <ol className={styles.list}><li className="mt-2">Ensure there are no active stock holdings and position present in your account.</li>
              <li className="mt-2">Close your investment account after duly signing the closure form.</li>
              <li className="mt-2">The team will process complete account closure after the user's request upon ensuring the customer has no active holding in any Stock, ETF or Stack.</li>
              </ol> */}
          </div>
          <Button
						style={{ backgroundColor: buttonColor }}
						className="themeButton"
						onClick={() => initiateDeactivation()}>
						Contact Support
					</Button>
      </> :''}
		</Col>

			</Row>



      <div className="modal fade" tabindex="-1" id='mdlAccountClosure'>
  <div className="modal-dialog modal-dialog-centered">
    <div className={`modal-content ${styles.closureModal}`}>
      {/* <div className="modal-header border-0">
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" class={styles.cancelBtn}>&times;</span>
        </button>
      </div> */}
      <div className="modal-body" style={{color:'#1E1F24', padding:'2rem'}}>
        <div>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" class={styles.cancelBtn}>&times;</span>
        </button></div>
        <div> <img src={roundCheck} height="35px" className={styles.closureImg}/></div>
<div className="pt-5">
      <p className={styles.bodyContent}>Your Account Deactivation Request has been registered</p>
              <p className={styles.bodyContentP}>Our support team will reach out to you shortly.</p>
              </div>
      </div>

    </div>
  </div>
</div>

		</>
	)
}

export default AccountClosure
