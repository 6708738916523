import React, { useEffect, useState } from 'react'
import { Row, Col, Container,Spinner,Button,Modal } from 'react-bootstrap'
// import RemittanceHistoryStatus from './RemittanceHistoryStatus'
import styles from './Remittance.module.css'
import { Footer } from '../../components'
import { RemittanceCard, Header, SideBar } from '../../components'
import { getRemittanceHistory,getAccountInfo } from '../../api'
import noHistoryImage from '../../public/images/nohistory.png'
import { Link } from 'react-router-dom'
import DropDown from '../../components/RemittanceHistory/DropDown'

const Remittance = () => {

	const [remittanceData, setremittanceData] = useState([])
  const [slideeffect, setslideeffect] = useState(false)
  const [sorting, setSorting] = useState(true)
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [profileData, setProfileData] = useState({})

  const handleEffect = () => {
		setslideeffect(prevState => setslideeffect(!prevState))
	}

		useEffect(() => {
		const asynchFetch = async () => {
			let [
				remittanceDatares,
			] = await Promise.all([
				getRemittanceHistory(),
			])
      remittanceDatares && setremittanceData(remittanceDatares.length > 0 ? [...remittanceDatares] : [])
      setSorting(false)
    }
		asynchFetch()

    let sessionProfileData=''
    sessionProfileData = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : "";
    setProfileData(sessionProfileData);
    if(sessionProfileData  === ''){
      getProfile()
    }

	}, [])

  const getProfile = async() => {
    const userInfores = await getAccountInfo();
    if (userInfores && userInfores.statusDetails){
      sessionStorage.setItem('userInfo', JSON.stringify(userInfores));
      setProfileData(userInfores);
    }
  }

  if(remittanceData && remittanceData.length > 0){
	  var sortedRemittanceData = remittanceData.sort(function(a,b)
    {
  	  return new Date(b.timestamp) - new Date(a.timestamp)
	  });
  } else{
    var sortedRemittanceData = []
  }

	return (
		<>
    <Header />
		<Container className={slideeffect ? styles.shiftPos : styles.originalPos}>

      <div className={`${styles.remittanceContainer} mb-4`}>
        {
          sorting
          ? (
              <div className={styles.spinner}>
                <Spinner animation="border" className={styles.spinnerColor}/>
              </div>
            )
          : (
              sortedRemittanceData.length
              ? (<div>
                  <Row className={styles.title}>
                    <span>Remittance history</span>
                    {/* <DropDown /> */}
                  </Row>
                  <div className={styles.distance}>
                    <RemittanceCard data={sortedRemittanceData} show={show} handleClose={handleClose} handleShow={handleShow}/>
                  </div>
                </div>
                )
              :(
                <div style={{textAlign:"center"}}>
                <div className={styles.noHistory}>
                <img src={noHistoryImage} className={styles.historyImage}/>
                </div>
                <div className={styles.timeToFund}>Time to fund account</div>
                <div className={styles.nothing}>Nothing makes money like <span className={styles.nothingspan}> money does.</span></div>
                {profileData && profileData['accountStatus'] === 2  ? 
                 <Link to='/funds?modal=true'>
                    <div className={styles.fundsDiv}>
                      <div className={styles.fundText}>
                        Add Funds
                      </div>
                    </div>
                  </Link>
                  : <div className={`${styles.fundsDiv} ${styles.disabeldFundsDiv}`}>
                      <div className={styles.fundText}>
                        Add Funds
                      </div>
                  </div> 
                }                
                </div>

                )
            )
        }
				{/* { sortedRemittanceData
            ? (sortedRemittanceData.length > 0
              ? (
                <div>
                  <Row className={styles.title}>Remittance history</Row>
                  <div className={styles.distance}>
                  <RemittanceCard data={sortedRemittanceData}/>
                  </div>
                </div>
                )
              : (
                <div style={{textAlign:"center"}}>
                <div className={styles.noHistory}>
                <img src={noHistoryImage} className={styles.historyImage}/>
                </div>
                <div className={styles.timeToFund}>Time to fund account</div>
                <div className={styles.nothing}>Nothing makes money like <span className={styles.nothingspan}> money does.</span></div>
                <div className={styles.fundsDiv}>
                  <div className={styles.fundText}>
                    Add Funds
                  </div>
                </div>
                </div>
              )) : (
                <div>loader</div>
              )
        } */}
			</div>


      <Modal show={show} centered>
        <Modal.Body className={styles.bankName} style={{textAlign:'start',whiteSpace:'unset',width:'100%'}}>
          <p>The Easyfund payment functionality had been available on the platform as a part of the RBI sandbox. While we got great results from customers on their active participation of using this mode to fund account, this notice is to inform you that the sandbox will be paused for a few weeks starting <b className={styles.transfer}>8th Feb 2022</b> to evaluate the results. As a result of this, the Stockal Easyfund payment mode will temporarily not be available starting <b>8th Feb 2022.</b></p>
           <p>
           We will be back soon with an upgraded version and will keep you posted.
           </p>
           <p>
           All the transactions until 7th Feb EOD will be processed and can be tracked by the customer in their remittance history.
           </p>
           <p>
           Should you have any questions, please do not hesitate to contact us at <a href={"mailto:customersuccess@stockal.com"}>stockal.com</a>
           </p>
           <div style={{textAlign:'end'}}>
           <Button onClick={handleClose} className={styles.okbutton}>OK</Button>
           </div>
        </Modal.Body>
      </Modal>

				{/* <div style={{ marginTop: '80px' }}>
					<Footer />
				</div> */}
        <div className={styles.sideBarMobileHide}>
          <SideBar handleEffect={handleEffect} />
        </div>
			</Container>
		</>
	)
}

export default Remittance
