import React, { useState, useEffect } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { onAccountDetected } from '../../api'
import UserSvg from '../../public/images/newOnboarding/User.svg'
import InfoSvg from '../../public/images/newOnboarding/Info.svg'
import styles from './ForgotUserName.module.css'
import { useHistory } from 'react-router-dom'

const UserName = ({ formData, changeCurrent }) => {
	let history = useHistory()
	const [userNameDetail, setUserNameDetail] = useState([])
	const { email = '' } = formData

	useEffect(() => {
		const hanldeEffect = async () => {
			try {
				const resp = await onAccountDetected(email)
				if (resp && resp.code === 200) {
					const { data } = resp
					if (data.length > 0) {
						setUserNameDetail([...resp.data])
					} else {
						changeCurrent('EmailForm')
					}
				}
			} catch (error) {
				console.log('error')
			}
		}
		hanldeEffect()
	}, [])

	return (
		<>
			<Row className={styles.title}>
				{userNameDetail && userNameDetail.length > 1
					? 'Accounts detected'
					: 'Account detected'}
			</Row>
			<Row className={styles.subTitle}>
				{userNameDetail && userNameDetail.length > 1
					? 'We’ve found the following usernames linked to your email address:'
					: 'We’ve found the following username linked to your email address:'}
			</Row>
			<Row className={styles.userNameCard}>
				{userNameDetail &&
					userNameDetail.map((item, index) => {
						return (
							<Row
								className={styles.userSigleRow}
								key={index}
								style={
									index + 1 === userNameDetail.length ? { borderBottom: 0 } : {}
								}>
								<Col xs={1}>
									<img src={UserSvg} className={styles.userIcon}></img>
								</Col>
								<Col className={styles.userNameTitle}>{item}</Col>
								<Col
									className={styles.loginLink}
									onClick={() => history.push(`/signin?username=${item}`)}>
									Login
								</Col>
							</Row>
						)
					})}
			</Row>
		</>
	)
}

export default UserName
