import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, FormControl} from 'react-bootstrap'
import styles from './AccountTransfer.module.css'
import searchIconGrey from '../../../public/images/searchIconGrey.svg'
import SearchCardBody from "./SearchCardBody";
import { searchBarDataAPI } from '../../../api'

const PartialTransfer = ({stockAdded,setStockAdded,requiredFieldsError}) => {
    const[stockVal,setStockVal] = useState(200);
    const[showSearch,setShowSearch] = useState(false);
    const[searchList, setsearchList] = useState([])
    const[searchItem, setsearchItem] = useState('')
    const[stockQuantity, setStockQuantity] = useState('');
    const[partialError, setPartialError] = useState('');
   
    const asynchFetch = async () => {
        const res = await searchBarDataAPI(encodeURIComponent(searchItem))
        setsearchList(res)
    }

    useEffect(() => {
        const handler = setTimeout(() => asynchFetch(), 300);
        return () => clearTimeout(handler);
    }, [searchItem])

    const onHandleChange = event => {
        setsearchItem(event.target.value)
	}

    const onSelect = (e) => {
       setsearchItem(e)
       setShowSearch(false)
    }
   
    const addStock = () => {
        if(!showSearch && searchItem !== "" && stockQuantity !== "" & stockQuantity !== "0" && stockAdded.length < 50){
            const itemExists = stockAdded.some(item => item.symbol === searchItem);
            if (!itemExists) {
                let obj = { symbol: searchItem, quantity: stockQuantity };
                setStockAdded([...stockAdded, obj]);
                setStockQuantity('');
                setsearchItem('');
                setPartialError('');
            } else {
                setPartialError('This security has already been added');
            }
        }else if(stockQuantity === "0"){
            setPartialError('Stock Quantity cannot be Zero')
        }else if(stockAdded.length === 50){
            setPartialError('A Maximum of 50 securities are allowed')
        }else if(searchItem == ""){
            setPartialError('Search to add securities')
        }else if(stockQuantity == undefined || stockQuantity == ""){
            setPartialError('Securities Quantity cannot be Empty')
        }
    }

    console.log('partial',partialError);

   const RemoveStock = (e) => {
        let tempArr = [...stockAdded];
        let findStockIndex = tempArr.findIndex((data) => data.symbol === e);
        if (findStockIndex !== -1) {
            tempArr.splice(findStockIndex, 1);
            setStockAdded(tempArr);
        } else {
            console.log("Stock not found in the array.");
        }
    } 

    return(
        <div>
        <p className={styles.partialHeading}>Add Stocks</p>
        <Row> 
            <Col xl={4} lg={4} md={6} sm={6} className={styles.nomineeInput}>
                <Form>
                    <span className={styles.searchIcon}>
                        <img src={searchIconGrey} alt='search-icon'></img>
                    </span>
                    <FormControl
                    className={styles.searchInput}
                    autoComplete="nope"
                    placeholder='Search Stocks, ETFs'
                    aria-describedby='basic-addon1'
                    onChange={onHandleChange}
                    value={searchItem}
                    onClick={() => setShowSearch(true)}
                    />
                </Form>
                {showSearch ? 
                <SearchCardBody searchList={searchList} 
                onSelect={onSelect} 
                setShowSearch={setShowSearch} 
                setsearchItem={setsearchItem}/> : ""}
                {partialError.length > 0 && 
                partialError === "A Maximum of 50 securities are allowed" || 
                partialError === "Search to add securities" || 
                partialError === "This security has already been added"? 
                <p className={styles.Error}>{partialError}</p> : ""}
            </Col>
            <Col xl={4} lg={4} md={6} sm={6} className={styles.nomineeInput}>
                <Form>
                    <FormControl
                    className={styles.searchInput}
                    autoComplete="nope"
                    placeholder='Stock Quantity'
                    aria-describedby='basic-addon1'
                    type="number"
                    onChange={(e) => {
                        let newValue = e.target.value.trim();
                        newValue = newValue.replace(/\D/g, ''); 
                        newValue = newValue.slice(0,4);  
                        setStockQuantity(newValue)}     
                    }
                    value={stockQuantity}
                    />
                </Form>
                {partialError.length > 0 && partialError === "Stock Quantity cannot be Zero" ||
                partialError === "Securities Quantity cannot be Empty" ? 
                <p className={styles.Error}>{partialError}</p> : ""}
            </Col>
            <Col xl={4} lg={4} md={6} sm={6}>
                <button className={styles.addButtonPartial} onClick={() => addStock()}>ADD</button>
            </Col>
        </Row>
            <p className={`${styles.partialHeading} mt-4`}>Review Stocks</p>
            {stockAdded.length == 0 ? 
            <p>
            {requiredFieldsError.find(field => field.stockAddedError === "At least one security must be added!" && field.isStockAddedError) ?
            <span className={styles.Error}>At least one security must be added!</span>:
            "No securities have been added."}</p>
            :
            <Row className={styles.reviewStocksBorder}>
                <Col md={4} className={styles.stockReviewHeading}>Symbol</Col>
                <Col md={4} className={styles.stockReviewHeading}>Quantity</Col>
            </Row>}
            {stockAdded.length > 0 && stockAdded.map((data,index) => {
                return(
                    <Row key={index} className="mt-4 mb-4">
                        <Col md={4} className="d-flex align-items-center">
                        <div className={styles.imageDiv}>
                            <div className={`text-center ${styles.letterSize}`}>
                                {data.symbol.substring(0, 1)}
                            </div>
                        </div>
                        <span className={styles.stockRepresentation}>{data.symbol}</span>
                        </Col>
                        <Col md={4}>
                            <p className={styles.stockRepresentation} style={{marginTop:"20px",marginLeft:"0px"}}>{data.quantity}</p>
                        </Col>
                        <Col md={4}>
                            <p className={styles.removeStock} onClick={() => RemoveStock(data.symbol)}>Remove</p>
                        </Col>
                    </Row>
                ) 
            })}
            </div>
    )
}

export default PartialTransfer