import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import styles from './stockAbout.module.css'
import AboutSkeleton from '../../containers/StockDetail/AboutSkeleton'
import Slider from 'react-slick'
import irImage from '../../public/images/ir.svg'
import { addCommasinPrice, decimalAdjust, parseNumericMillion } from '../../utils'

const StockAbout = ({ stockName, stockData = {}, etf = false, livePrice = {} }) => {
  const [livePriceData, setLivePriceData] = useState({})
  const [loading, setloading] = useState(true)
  const [more, setMore] = useState('More')
  const [desc, setDesc] = useState('')

  const settings = {
    className: 'center',
    centerMode: true,
    infinite: false,
    centerPadding: "10px",
    slidesToShow: 1,
    speed: 500,
    rows: 2,
    slidesPerRow: 3
  };


  useEffect(() => {
    if (livePrice && Object.keys(livePrice).length) {
      setLivePriceData(livePrice)
    }
    setloading(false)
  }, [livePrice])


  const aboutdataStock = [
    {
      heading: 'Open',
      value: livePriceData ? livePriceData.open ? addCommasinPrice(decimalAdjust('floor', livePriceData.open, -2)) : '--' : '--'
    },
    { heading: 'Ask', value: livePriceData ? livePriceData.ask ? addCommasinPrice(decimalAdjust('floor', livePriceData.ask, -2)) : '--' : '--' },
    { heading: 'Bid', value: livePriceData ? livePriceData.bid ? addCommasinPrice(decimalAdjust('floor', livePriceData.bid, -2)) : '--' : '--' },
    // {
    // 	heading: 'Market Cap',
    // 	value:
    //   stockData ? stockData.marketCapitalization
    // 			? '$' +  stockData.marketCapitalization
    // 			: '--':'--'
    // },
    { heading: 'High', value: livePriceData ? livePriceData.high ? addCommasinPrice(decimalAdjust('floor', livePriceData.high, -2)) : '--' : '--' },
    { heading: 'Low', value: livePriceData ? livePriceData.low ? addCommasinPrice(decimalAdjust('floor', livePriceData.low, -2)) : '--' : '--' },
    {
      heading: '52w High',
      value:
        stockData ? stockData['52_week_high']
          ? addCommasinPrice(decimalAdjust('floor', stockData['52_week_high'], -2))
          : '--' : '--'
    },
    {
      heading: '52w Low',
      value:
        stockData ? stockData['52_week_low']
          ? addCommasinPrice(decimalAdjust('floor', stockData['52_week_low'], -2))
          : '--' : '--'
    },
    {
      heading: 'Volume',
      value:
        stockData ? stockData.volume
          ? addCommasinPrice(parseNumericMillion(decimalAdjust('floor', stockData.volume, -2)))
          : '--' : '--'
    },
    {
      heading: 'P/B Ratio',
      value:
        stockData ? stockData.pricetobook
          ? addCommasinPrice(decimalAdjust('floor', stockData.pricetobook, -2))
          : '--' : '--'
    },
    {
      heading: 'P/E Ratio',
      value:
        stockData ? stockData.pricetoearnings
          ? addCommasinPrice(decimalAdjust('floor', stockData.pricetoearnings, -2))
          : '--' : '--'
    },
    {
      heading: 'Dividend Yield',
      value:
        stockData ? stockData.dividendyield
          ? addCommasinPrice(decimalAdjust('floor', stockData.dividendyield, -3)) == 0 ?
            '--' : addCommasinPrice(decimalAdjust('floor', stockData.dividendyield, -3))
          : '--' : '--'
    },
    {
      heading: 'Market Cap',
      value:
        stockData ? stockData.marketcap
          ? '$' + parseNumericMillion(stockData.marketcap)
          : '--' : '--'
    },

  ]

  const aboutdataEtf = [
    {
      heading: 'AUM',
      value:
        stockData && stockData.aum
          ? '$' + addCommasinPrice(parseNumericMillion(stockData.aum))
          : '--'
    },
    {
      heading: 'Average Volume',
      value:
        stockData && stockData.avgVolume
          ? addCommasinPrice(stockData.avgVolume)
          : '--'
    },
    {
      heading: 'Currency',
      value:
        stockData && stockData.currency
          ? stockData.currency
          : '--'
    },
    {
      heading: 'Expense Ratio',
      value:
        stockData && stockData.expenseRatio
          ? decimalAdjust('floor', stockData.expenseRatio, -2) + '%'
          : '--'
    },
    {
      heading: 'Holdings Count',
      value:
        stockData && stockData.holdingsCount
          ? addCommasinPrice(stockData.holdingsCount)
          : '--'
    },
    {
      heading: 'ISIN',
      value:
        stockData && stockData.isin
          ? stockData.isin
          : '--'
    },
    {
      heading: '52w High',
      value:
        stockData ? stockData.HighPriceLast52Weeks
          ? addCommasinPrice(decimalAdjust('floor', stockData.HighPriceLast52Weeks, -2))
          : '--' : '--'
    },
    {
      heading: '52w Low',
      value:
        stockData ? stockData.LowPriceLast52Weeks
          ? addCommasinPrice(decimalAdjust('floor', stockData.LowPriceLast52Weeks, -2))
          : '--' : '--'
    }
    // older one start

    // {
    //   heading: 'Market Cap',
    //   value:
    //     stockData ? stockData.MarketCapitalization
    //       ? '$' + addCommasinPrice(parseNumericMillion(stockData.MarketCapitalization))
    //       : '--' : '--'
    // },
    // {
    //   heading: 'Dividend Yeild',
    //   value:
    //     stockData ? stockData.DividendYieldDaily
    //       ? addCommasinPrice(decimalAdjust('floor', stockData.DividendYieldDaily, -2))
    //       : '--' : '--'
    // },
    // {
    //   heading: 'Shares',
    //   value:
    //     stockData ? stockData.SharesOutstanding
    //       ? addCommasinPrice(decimalAdjust('floor', stockData.SharesOutstanding, -2))
    //       : '--' : '--'
    // },
    // {
    //   heading: 'Last vol. traded',
    //   value:
    //     stockData ? stockData.LastVolume
    //       ? addCommasinPrice(decimalAdjust('floor', stockData.LastVolume, -2))
    //       : '--' : '--'
    // },
    // {
    //   heading: 'Avg. Volume',
    //   value:
    //     stockData ? stockData.AverageDailyVolumeLast52Weeks
    //       ? addCommasinPrice(decimalAdjust('floor', stockData.AverageDailyVolumeLast52Weeks, -2))
    //       : '--' : '--'
    // },
    // {
    //   heading: 'Beta',
    //   value:
    //     stockData ? stockData.Beta
    //       ? addCommasinPrice(decimalAdjust('floor', stockData.Beta, -2))
    //       : '--' : '--'
    // },
    
    // {
    //   heading: 'P/E Ratio',
    //   value:
    //     stockData ? stockData.PERatio
    //       ? addCommasinPrice(decimalAdjust('floor', stockData.PERatio, -2))
    //       : '--' : '--'
    // },
    // {
    //   heading: 'P/B Ratio',
    //   value:
    //     stockData ? stockData.PriceToBook
    //       ? addCommasinPrice(decimalAdjust('floor', stockData.PriceToBook, -2))
    //       : '--' : '--'
    // },
    // {
    //   heading: 'Fund Size',
    //   value:
    //   assetData ? assetData.length ? assetData[0].fundsizeamt
    //       ? addCommasinPrice(decimalAdjust('floor', assetData[0].fundsizeamt, -2))
    //       : '--' : '--' : '--'
    // },
  ]

  useEffect(() => {
    let description = etf
      ? stockData
        ? stockData.BusinessDescription
          ? stockData.BusinessDescription
          : ''
        : ''
      : stockData
        ? stockData.description
          ? stockData.description
          : ''
        : ''
    description = more === 'More' ? description.slice(0, 200) : description
    setDesc(description)
  }, [stockData, more])

  const handleNews = () => {
    more === 'More' ? setMore('Less') : setMore('More')
  }
  return (
    <>
      <Row>
        <Col className={`${styles.title} d-none d-lg-block`}>About
          <a href={`https://www.sec.gov/cgi-bin/browse-edgar?CIK=${stockName}&owner=exclude&action=getcompany`} target="_blank" rel="noopener noreferrer">
            <img src={irImage} style={{ cursor: 'pointer', marginLeft: "10px" }} />
          </a>
        </Col>
        <Col className={`${styles.title} d-block d-lg-none`}>Stats</Col>
      </Row>
      {loading ? (
        <AboutSkeleton />
      ) : (
        <React.Fragment>
          <Row
            className={`${styles.desc} d-none d-lg-block`}
          >
            <Col style={{ display: desc ? 'block' : 'none' }}>
              {desc}
              {desc.length <= 199 ? null : <span onClick={() => handleNews()} className={styles.know}>
                Know{` ${more}`}
              </span>}
            </Col>
          </Row>
          <Row style={{ marginTop: '33px' }} className='d-none d-lg-flex'>
            {etf ? (aboutdataEtf.map((item, i) => {
              return (
                <Col lg={3} key={i} style={{ paddingBottom: '24px' }}>
                  <div className={styles.head}>{item.heading}</div>
                  <div className={styles.val}>{item.value}</div>
                </Col>
              )
            })) : (aboutdataStock.map((item, i) => {
              return (
                <Col xs={2} lg={3} key={i} style={{ paddingBottom: '24px' }}>
                  <div className={styles.head}>{item.heading}</div>
                  <div className={styles.val}>{item.value}</div>
                </Col>
              )
            }))}

          </Row>
          <div className='d-block d-lg-none'>
            <Slider {...settings} className={styles.aboutSlider}>
              {etf ? (aboutdataEtf.map((item, i) => {
                return (
                  <div>
                    <div style={{ marginTop: "20px", marginBottom: '8px' }} className={styles.head}>{item.heading}</div>
                    <div className={styles.val}>{item.value}</div>
                  </div>
                )
              })) : (aboutdataStock.map((item, i) => {
                return (
                  <div key={i}>
                    <div className={styles.head} style={{ marginTop: "20px", marginBottom: '8px' }}>{item.heading}</div>
                    <div className={styles.val}>{item.value}</div>
                  </div>
                )
              }))}
            </Slider>
          </div>
        </React.Fragment>
      )}
    </>
  )
}

export default StockAbout
