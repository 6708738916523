import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { getStockPriceDw } from '../../api'
import moment from 'moment'
import ReactHighstock from 'react-highcharts/ReactHighstock'
import { useSelector, useDispatch } from 'react-redux'
import { getMarketDetails } from '../../store/actions/index'
import { isMarketOpen} from '../../utils';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import styles from './StockDetail.module.css'
import infoIconOrange from '../../public/images/infoIconOrange.svg'

const StockDetailGraph = ({ code , isOtc, change, graphTheme, setGraphTheme}) => {
	const [data, setData] = useState([])
	const [active, setActive] = useState('1d')
	const [isMarket,setisMarket] = useState(false)
	const dispatchAction = useDispatch()
	const state = useSelector(state => state)
	const [closinghours,setClosingHours] = useState(0)
	const [closingminutes,setClosingminutes] = useState(0)
	const [closingsec,setClosingsec] = useState(0)

	const {
		marketDetails: { marketDetails: { currentMarketStatus = {}, marketClosesAt= '',marketOpensAt=''} = {} } = {}
	} = state


	Object.keys(state.marketDetails).length === 0 &&
		dispatchAction(getMarketDetails())

	useEffect(() => {
    if(code === 'CETF'){
      setActive('1w')
      getStockPrice('1w',7)
    }else{
      setActive('1d')
      getStockPrice('1d', 2)
    }

	}, [code])

	useEffect(()=>{
		if(active==="1d"){
			setGraphTheme(change>=0 ? 'positive':'negative')
		}
	},[change, active])
	const otcSecurityToolTip = (props) => {

        return (
          <Tooltip {...props} className={styles.otcToolTip}>
              <p className={styles.otcToolTipHeader}>Over-the-Counter (OTC) Security</p>
              <p className={styles.otcToolTipText}>This security is an OTC security. Prices for OTC securities are delayed by 15 minutes.</p>
          </Tooltip>
        )
    }

  const marketTimer = () => {
    if(marketClosesAt){
    let nowtimeStamp = new Date();
	  nowtimeStamp.setFullYear(nowtimeStamp.getUTCFullYear(), nowtimeStamp.getUTCMonth()-1, nowtimeStamp.getUTCDate());
	  nowtimeStamp.setHours(nowtimeStamp.getUTCHours(), nowtimeStamp.getUTCMinutes(), nowtimeStamp.getUTCSeconds());
    const timeSplit = marketClosesAt.split('T').join(' ')
    const time = timeSplit.slice(0,timeSplit.length - 5).replace(/-/g, "/")
    const closetimeStamp = new Date(time).getTime()
    const distance = closetimeStamp - nowtimeStamp
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    setClosingHours(hours)
    setClosingminutes(minutes)
    setClosingsec(seconds)
    }
  }

  useEffect(() =>{
    marketTimer()
    const info = isMarketOpen(currentMarketStatus)
    setInterval(() =>  marketTimer(), 60000)
    setisMarket(info)
  },[currentMarketStatus])

	const getStockPrice = async(value, days) => {
		try {
			const stockData = []
			const response = await getStockPriceDw(code, days)
			if (response && response.length > 0) {
				const spiltarray = response.split('|')
				spiltarray.forEach(item => {
					const itemsplit = item.split(',')
					const formattedDate = moment(itemsplit[0]).format('D MMM YY, h:mm A')
					const tooltipDate = `${formattedDate} UTC`
					stockData.push([tooltipDate, parseFloat(itemsplit[4])])
				})
				stockData.sort((a, b) => a[0] - b[0])
				setData(stockData)
				if(value!=="1d"){
					setGraphTheme((stockData[stockData.length-1][1]-stockData[0][1]) >=0 ? 'positive':'negative')
				}
			}
		} catch (error) {
			console.log(error)
		}
	}

	const handleChange = (value, days) => {
		setActive(value)
		getStockPrice(value, days)
	}

	const custoumOption = () => {
		return {
			chart: {
			marginRight:0,
			marginLeft:0,
			backgroundColor: graphTheme ==="positive" ? "#ECFAEC" : "#FFEEF1",
			borderRadius:"12px",
			style: {
				overflow: 'visible',
				// width: 700,
				height: 200
			}
			},
			rangeSelector: {
				enabled: false
			},
			scrollbar: {
				enabled: false
			},
			title: {
				text: undefined
			},

			tooltip: {
				crosshairs: false,
				backgroundColor: '#FFFFFF',
				borderColor: '#FFFFFF',
				borderWidth:1,
				borderRadius: 8,
				padding:12,
				style:{
					color:'#767C8F'
				},
				formatter() {
					let s = ''
					this.points.forEach(point => {
						s += '<br><p style="color:#3C404A;font-size:14px;font-weight:600;">$' + point.y + '</p><br>' + point.key
					})

					return s
				},
				positioner(point) {
					//   console.log(point)
					// return {
					// 	x: 80,
					// 	y: 50
					// };
				}
			},
			credits: {
				enabled: false
			},
			navigator: { enabled: false },
			xAxis: {
				visible: false
			},
			yAxis: {
				visible: true,
				gridLineDashStyle:'dash',
				lineColor:"#767C8F",
				labels: {
					enabled: false
				}
			},

			legend: {
				enabled: false
			},

			plotOptions: {
				series: {
					marker: {
						enabled: false,
						states: {
							hover: {
								enabled: false
							}
						}
					}
				}
			},

			series: [
				{
					data: data,
					type: 'line',
					lineWidth: 2,
					lineColor: graphTheme ==="positive" ? '#0DC014' : '#FA274A'
				}
				// {
				// 	data: [916, 24064, 80742, 29851, 32490, 30282, 121, 40434],
				// 	lineWidth: 2,
				// 	lineColor: '#005DD6'
				// }
			]
		}
	}

	return (
		<div>
			<ReactHighstock config={custoumOption()}></ReactHighstock>
			<Row className={styles.graphDurationContainer}>
				<div className={styles.durationTextContainer}>
					<span className={styles.durationText} style={{cursor:"default"}}>Duration:</span>
          {code !== 'CETF' &&
					<span className={active === '1d' ? graphTheme ==="positive" ? styles.activePositive : styles.activeNegative : styles.nonactive} onClick={() => handleChange('1d',2)}>1d</span>}
					<span
						className={active === '1w' ? graphTheme ==="positive" ? styles.activePositive : styles.activeNegative : styles.nonactive}
						onClick={() => handleChange('1w', 7)}>
						1w
					</span>
					<span
						className={active === '1m' ? graphTheme ==="positive" ? styles.activePositive : styles.activeNegative : styles.nonactive}
						onClick={() => handleChange('1m', 30)}>
						1m
					</span>
					<span
						className={active === '1y' ? graphTheme ==="positive" ? styles.activePositive : styles.activeNegative : styles.nonactive}
						onClick={() => handleChange('1y', 365)}>
						1y
					</span>
					<span
						className={active === 'All-Time' ? graphTheme ==="positive" ? styles.activePositive : styles.activeNegative : styles.nonactive}
						onClick={() => handleChange('All-Time', 730)}>
						All-Time
					</span>
				</div>
				<div className={styles.closeTimeContainer}>
					<div className={styles.otcDisclaimer} style={{display: isOtc && isOtc === true ? 'flex':'none'}}>
						<p>All prices are delayed by 15 minutes</p>
						<OverlayTrigger
						placement="top"
						trigger={['hover', 'focus','click']}
						delay={{ show: 250, hide: 400 }}
						overlay={otcSecurityToolTip}
						>
							<img src={infoIconOrange} alt='icon'/>
						</OverlayTrigger>
					</div>
					{isMarket ? <div>
						<span className={styles.marketActive}></span>
						<span className={styles.isLive}>
							CLOSING IN {closinghours >= 1 ? `${closinghours}h` : '' } {closingminutes}m</span>
						</div> :''}
					{/* <span className={styles.delayed}>Delayed Price</span>
					<span className={styles.expand}>
						Expand
						<img
							className='ml-2'
							src={require('../../public/images/expand.png')}></img>
					</span> */}
				</div>
			</Row>

			<style jsx>{`
                .tooltip.show {
                    opacity: 1.0 !important
                }
            `}</style>
		</div>
	)
}

export default StockDetailGraph
