import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { Formik } from 'formik'
import * as yup from 'yup'
import DropDown from '../DropDown/DropDown'
import styles from './AccountFundingSetUp.module.css'

const OtherBankDetail = ({
	selectedBank,
	IFSCcode,
	bankName,
	bankAddress,
	name,
	bankAccountno,
	bankOptions,
	onChangeHandler
}) => {
	return (
		<>
			<Row className={styles.title}>Bank Details</Row>
			<Row className={styles.note} style={{ color: '#009C06' }}>
				<b style={{ display: 'contents' }}>Important:</b> Please make sure that
				your savings account is not an overdraft account (OD Account) and is KYC
				compliant
			</Row>
			<Formik
				initialValues={{
					selectedBank,
					bankAccountno,
					IFSCcode,
					bankAddress,
					bankName,
					name
				}}
				validationSchema={yup.object({
					selectedBank: yup.string().required('Selected Bank is required'),
					bankAccountno: yup
						.string()
						.required('Bank Account number is required'),
					IFSCcode: yup.string().required('IFSC code is required'),
					bankAddress: yup.string().required('Bank Address is required'),
					bankName: yup.string().required('Bank name is required'),
					name: yup.string().required('Your name is required')
				})}>
				{({
					handleChange,
					handleBlur,
					handleSubmit,
					values,
					touched,
					errors
				}) => {
					return (
						<>
							<Row className={styles.inputRow}>
								<Col>
									<DropDown
										placeholder='Your Bank'
										data={bankOptions}
										name='selectedBank'
										value={values.selectedBank}
										onChange={value => {
											const event = { target: { name: 'selectedBank', value } }
											onChangeHandler(event)
											handleChange(event)
										}}
										className={styles.inputHeight}
									/>
									<Form.Control.Feedback
										type='invalid'
										style={{ display: 'block' }}>
										{touched.selectedBank && errors.selectedBank
											? errors.selectedBank
											: ''}
									</Form.Control.Feedback>
								</Col>
								<Col>
									<Form.Group
										className={`form-focus ${
											values.IFSCcode !== '' ? 'focused' : null
										}`}>
										<Form.Control
											className={styles.inputHeight}
											type='text'
											name='IFSCcode'
											value={values.IFSCcode}
											onChange={event => {
												onChangeHandler(event)
												handleChange(event)
											}}
											onBlur={handleBlur}
											isInvalid={touched.IFSCcode && errors.IFSCcode}
											isValid={
												touched.IFSCcode && !errors.IFSCcode
											}></Form.Control>
										<Form.Label className='focus-label'>
											Bank’s SWIFT/IBAN/IFSC Code
										</Form.Label>
										<Form.Control.Feedback type='invalid'>
											{errors.IFSCcode}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
							</Row>
							<Row className={styles.inputRow}>
								<Col>
									<Form.Group
										className={`form-focus ${
											values.bankName !== '' ? 'focused' : null
										}`}>
										<Form.Control
											className={styles.inputHeight}
											type='text'
											name='bankName'
											value={values.bankName}
											onChange={event => {
												onChangeHandler(event)
												handleChange(event)
											}}
											onBlur={handleBlur}
											isInvalid={touched.bankName && errors.bankName}
											isValid={
												touched.bankName && !errors.bankName
											}></Form.Control>
										<Form.Label className='focus-label'>Bank’s Name</Form.Label>
										<Form.Control.Feedback type='invalid'>
											{errors.bankName}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
								<Col>
									<Form.Group
										className={`form-focus ${
											values.bankAddress !== '' ? 'focused' : null
										}`}>
										<Form.Control
											className={styles.inputHeight}
											type='text'
											name='bankAddress'
											value={values.bankAddress}
											onChange={event => {
												onChangeHandler(event)
												handleChange(event)
											}}
											onBlur={handleBlur}
											isInvalid={touched.bankAddress && errors.bankAddress}
											isValid={
												touched.bankAddress && !errors.bankAddress
											}></Form.Control>
										<Form.Label className='focus-label'>
											Bank’s Address
										</Form.Label>
										<Form.Control.Feedback type='invalid'>
											{errors.bankAddress}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
							</Row>
							<Row className={styles.inputRow}>
								<Col>
									<Form.Group
										className={`form-focus ${
											values.name !== '' ? 'focused' : null
										}`}>
										<Form.Control
											className={styles.inputHeight}
											type='text'
											name='name'
											value={values.name}
											onChange={event => {
												onChangeHandler(event)
												handleChange(event)
											}}
											onBlur={handleBlur}
											isInvalid={touched.name && errors.name}
											isValid={touched.name && !errors.name}></Form.Control>
										<Form.Label className='focus-label'>Your's Name</Form.Label>
										<Form.Control.Feedback type='invalid'>
											{errors.name}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
								<Col>
									<Form.Group
										className={`form-focus ${
											values.bankAccountno !== '' ? 'focused' : null
										}`}>
										<Form.Control
											className={styles.inputHeight}
											type='text'
											name='bankAccountno'
											value={values.bankAccountno}
											onChange={event => {
												onChangeHandler(event)
												handleChange(event)
											}}
											onBlur={handleBlur}
											isInvalid={touched.bankAccountno && errors.bankAccountno}
											isValid={
												touched.bankAccountno && !errors.bankAccountno
											}></Form.Control>
										<Form.Label className='focus-label'>
											Your Bank Account Number
										</Form.Label>
										<Form.Control.Feedback type='invalid'>
											{errors.bankAccountno}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
							</Row>
						</>
					)
				}}
			</Formik>
		</>
	)
}

export default OtherBankDetail
