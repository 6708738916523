import React, { useContext } from 'react'
import { ThemeContext } from '../../../Context/ThemeContext'
import { Link } from 'react-router-dom'
import styles from './header.module.css'

const NewHeader = ({ message, linkName, onClickLink }) => {
	const { siteLogo } = useContext(ThemeContext)
	
	return (
		<div className={styles.containerNav}>
			<nav className={`navbar navbar-expand-lg navbar-light ${styles.navbar}`}>
				<div className={styles.navbarImage}>
					{siteLogo ?
					<img src={siteLogo} height='28px' href='#' alt='header image'></img> : 
					<div style={{height: "28px"}}></div>}
				</div>

				<div>
					<span className={styles.subTitle}>{message}</span>
					<span
						className={styles.subTitle}
						style={{ color: '#0061D3', marginLeft: '8px', cursor: 'pointer' }}
						onClick={() => onClickLink()}>
						{linkName}
					</span>
				</div>
			</nav>
		</div>
	)
}

export default NewHeader
