import React, { useContext, useEffect, useState } from 'react'
import { FundConfigContext } from "../../AddFundConfig"
import { AxisGeneratePayment } from '../../../../api';
import GenericLoading from "../../GenericLoading"
import styles from './axis.module.css'
// import Error from '../Direct/Error';
import { closeFundModal } from "../../../../store/actions"
import { useDispatch } from "react-redux"
import AxisInformation from './information';


const AxisBankTransfer = ({fund}) => {
    const{stepsToTransactions,bankId,loading,setShowError,showError,makeVerification,isKycVerified} = useContext(FundConfigContext);
    const[errorMessage,setErrorMessage] = useState('');
    const[counterForButton,setCounterForButton] = useState(0);
    const dispatchAction = useDispatch();

    useEffect(() => {
        if(stepsToTransactions == "GEN_PAYMENT"){
            createPayment()
            setShowError(false)
            setErrorMessage('')
        }
    },[stepsToTransactions])

    const closeModal = ()=>{
        dispatchAction(closeFundModal())
    }

    const reinitiateTransaction = () => {
        setErrorMessage('');
        setShowError(false);
        setCounterForButton(1);
        createPayment();
    }

    const createPayment = async() => {
        try{
            const body = {
                "amount": fund,
                "bankId": bankId,
                "vendorName": "AXIS"
            }
            const resp = await AxisGeneratePayment(body)
            if(resp.status == 200 && resp.doc.paymentLink !== ""){
                window.open(resp.doc.paymentLink, "_blank")
                setShowError(false)
                // closeModal();
                setErrorMessage("Please complete the payment on the Bank's Page.")
            }
            else{
                setShowError(true)
                setErrorMessage('Something went wrong. Please re-conduct a transaction, from the button below.')
            }
        }
        catch(error){
            console.log('error',error)
            setShowError(true)
            setErrorMessage('Something went wrong. Please re-conduct a transaction, from the button below.')
        } 
    }

    const errorReInitiate = () => {
        return(
            <div style={{textAlign:"center"}}>
                <p className={styles.verifiedMessage}>
                    {counterForButton == 1 ?
                    "Something went wrong. Please conduct the transaction again in 5-10 minutes." 
                    : "Something went wrong. Please re-conduct a transaction, from the button below."}
                </p>
                {counterForButton == 1 ? "" :
                <button className={styles.reinitiateButton} 
                onClick={() => reinitiateTransaction()}>
                Reinitiate
                </button>}
            </div>
        )
    }

    const verifiedPayment = () => {
        return(
            <div>
                <p className={styles.verifiedMessage}>Please complete the payment on the Bank's Page.</p>
            </div>
        )
    }
  
    return(
        <div>
        {!showError && makeVerification && isKycVerified == "" ?
            <div className={styles.genericLoading}>
                <GenericLoading /> 
            </div> : 
            stepsToTransactions === "SHOW_INFO" ? 
            <AxisInformation/> :
            errorMessage == "Please complete the payment on the Bank's Page." ? (verifiedPayment()) :
            isKycVerified == "not Verified" || 
            errorMessage === "Something went wrong. Please re-conduct a transaction, from the button below." 
            ? 
            (errorReInitiate())
            : ""
        }
        </div>
    )
}

export default AxisBankTransfer