import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, Container } from 'react-bootstrap'
import moment from 'moment';
import PortfolioCard from './PortfolioCard'
import {
  getUserInvestment,
  getUserAccountSummary,
  getMarketDetails } from '../../store/actions'
import PortfolioSkeleton from '../../skeletons/PortfolioSkeleton'
import styles from './Portfolio.module.css'
import Slider from 'react-slick'
import SkeletonElement from '../../skeletons/SkeletonElement'
import { calReturn, decimalAdjust, addCommasinPrice, isMarketOpen } from '../../utils'
import { PF_SUM_TODAY_CHG, PF_SUM_CURR_VAL, PF_SUM_TOT_INV, PF_SUM_CB, PF_SUM_PL, MARKET_CLOSE_STATUS } from '../../constants'


const changeArrays = [PF_SUM_PL, PF_SUM_TODAY_CHG];

const Portfolio = ({
  portfolioEmpty,
  totalCurrentValuePf,
  tradeCycleDates,
}) => {
	const dispatchAction = useDispatch()
  const state = useSelector(state => state)
  const [invLoaded, setInvLoaded] = useState(false);
	let todayChange = '--';
	let todayChangePercentage = '';
  let currentValue = '--'
  let totalReturn = '--'
  let totalReturnPer = '--'
  let cvWithCashBalance = 0
  let totInWithCashBalance = 0
	const {
		userInvestment: { userInvestmentHistory = [] } = {},
    userAccountSummary: { userSummary = {} } = {},
    marketDetails: { marketDetails: { 
              currentMarketStatus = {}, 
              todaysCalender = {}, 
              previousWorkingDaysCalender = {},
              priorPreviousWorkingDaysCalender = {},
            } = {} } = {}
  } = state
	const {
		accountSummary = {},
		portfolioSummary: {
      totalInvestmentAmount = '',
      totalCurrentValue = ''
    } = {}
	} = userSummary
  const { cashBalance = '' } = accountSummary

	if (Object.keys(state.userInvestment).length === 0) {
    dispatchAction(getUserInvestment())
	}
	if (Object.keys(state.userAccountSummary).length === 0) {
		dispatchAction(getUserAccountSummary())
  }

  Object.keys(state.marketDetails).length === 0 &&
    dispatchAction(getMarketDetails())

    if(totalCurrentValuePf === '' || isNaN(totalCurrentValuePf)) {
      currentValue = totalCurrentValue
    } else {
      currentValue = totalCurrentValuePf;
    }
  cvWithCashBalance = currentValue + cashBalance;
  totInWithCashBalance = totalInvestmentAmount + cashBalance;
  let prevDayDate;
  let prevDayCV;;

    /**
       * HOLIDAY ===> Should Pick Current Value priorClose date.
       * if market is closed  but the day hasn't ended, date should not be priorClose, 
       * as it will calculate the change when the market wasn't even started
    */
	if (userInvestmentHistory.length > 0) {
    if(!isMarketOpen(currentMarketStatus)) {
      const { marketStatus, marketCloseUTC } = todaysCalender;
      if(marketStatus === MARKET_CLOSE_STATUS) {
        prevDayDate = priorPreviousWorkingDaysCalender?.date;
      } else {
        const currentTime = moment().utc().format('HH:mm');
        if (currentTime > marketCloseUTC) {
          prevDayDate = previousWorkingDaysCalender?.date 
        } else {
          prevDayDate = priorPreviousWorkingDaysCalender?.date
        }
      }
    } else {
      prevDayDate = previousWorkingDaysCalender?.date;
    }
    
    if(prevDayDate){
      let currentHistoryData = userInvestmentHistory.filter((his) => his.marketCloseDate === prevDayDate)
      if(currentHistoryData && currentHistoryData.length > 0) {
        prevDayCV = currentHistoryData[0].cashBalanceAndTotalCurrentValue
      }
    }

    if(cvWithCashBalance && prevDayCV && prevDayCV > 0){
      ({ret: todayChange, retPer: todayChangePercentage } = calReturn(cvWithCashBalance, prevDayCV))
    } else {
      todayChange = 0
      todayChangePercentage = 0;
    }
  }

  if(cvWithCashBalance > 0) {
    ({ ret: totalReturn, retPer: totalReturnPer } = calReturn(currentValue, totalInvestmentAmount));
  } else {
    totalReturn = 0
    totalReturnPer = 0;
  }

	// const settings = {
	// 	dots: false,
	// 	infinite: true,
	// 	arrows: false,
	// 	centerMode: true,
	// 	centerPadding: '-50px',
	// 	speed: 500,
	// 	slidesToShow: 3,
	// 	slidesToScroll: 1
  // }
  const settings = {
		dots: false,
		infinite: false,
		arrows: false,
		centerMode: false,
		centerPadding: 0,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1
  }
  const returnChangeColor = totalReturn >= 0 ? '#34B320' : '#FF0000'
  const todayChangeColor = todayChange >= 0 ? '#34B320' : '#FF0000'

  const renderPortfolioCard = ({color, value, title, perChange}) => {
    let renderedvalue;
    if (changeArrays.includes(title) && value < 0) {
      const valueWithoutSub = decimalAdjust('floor', value, -2).toString().substring(1);
      renderedvalue = `-$${addCommasinPrice(valueWithoutSub)}`;
    } else {
      renderedvalue = `$${addCommasinPrice(decimalAdjust('floor', value, -2))}`;
    }
    return (
      <>
      {
        portfolioEmpty ? null :
        <PortfolioCard
        colorValues={title === PF_SUM_PL ? color : ''}
        color={color}
        value={value ? isNaN(value) ? '$--' : renderedvalue : '$--'}
        title={title}
        incrementPercentage={
          perChange ? isNaN(perChange) ? '--'
          : perChange > 0
            ? `+${addCommasinPrice(decimalAdjust('floor',perChange, -2))}%`
            : `${addCommasinPrice(decimalAdjust('floor', perChange, -2))}%` : null
        }></PortfolioCard>
      }
      </>
  )
}

  const renderSkeleteonElement = () => (
    <Row>
      <Col md={4} className='mt-4'>
        <SkeletonElement width='97px' height='35px'></SkeletonElement>
      </Col>
    </Row>
  )

	return (
		<>
			{/* <Row className={styles.portfolioTitle}>
				<Col>portfolio summary</Col>
			</Row> */}
			<Row className='d-none d-md-flex'>
        <Col md={4} lg={2} className='pr-0'>
					{totalReturn !== '--' ? (
            renderPortfolioCard({
              color: returnChangeColor,
              value: totalReturn,
              title: PF_SUM_PL,
              perChange: totalReturnPer
            })
					) : renderSkeleteonElement()
          }
				</Col>
        <Col md={4} lg={2} className='pr-0'>
					{todayChange !== '--' ? (
            renderPortfolioCard({
              color: todayChangeColor,
              value: todayChange,
              title: PF_SUM_TODAY_CHG,
              perChange: todayChangePercentage
            })
					) : renderSkeleteonElement()
          }
				</Col>
				<Col md={4} lg={2} className='pr-0 pl-0'>
					{currentValue !== '--' ? (
            renderPortfolioCard({
              color: returnChangeColor,
              value: currentValue,
              title: PF_SUM_CURR_VAL,
              // perChange: totalReturnPer
            })
					) : renderSkeleteonElement()
          }
				</Col>
				<Col md={4} lg={2}>
          {
          totalInvestmentAmount !== '' ? (
            renderPortfolioCard({
              value: totalInvestmentAmount,
              title: PF_SUM_TOT_INV,
            })
          ) : renderSkeleteonElement()
          }
				</Col>
				<Col md={4} lg={4}>
					{cashBalance !== '' ? (
            renderPortfolioCard({
              value: cashBalance,
              title: PF_SUM_CB,
            })
					) : renderSkeleteonElement()
          }
				</Col>
			</Row>
			<Row className={`d-block d-md-none ${styles.portfolio}`} style={{ marginTop: '40px',height:'60px'}}>
				{/* <Col> */}
					<Slider {...settings} className={styles.portfolioSlider}>
            <div>
              {
                currentValue !== '' ?
                  renderPortfolioCard({
                  color: returnChangeColor,
                  value: currentValue,
                  title: PF_SUM_CURR_VAL,
                  // perChange: totalReturnPer
                  }) : renderSkeleteonElement()
              }
							</div>
						  <div>
								{
                  currentValue !== '' ? (
                    renderPortfolioCard({
                      color: todayChangeColor,
                      value: todayChange,
                      title: PF_SUM_TODAY_CHG,
                      perChange: todayChangePercentage
                    })
                  ) : renderSkeleteonElement()
                }
						</div>
					  <div>
								{
                   totalInvestmentAmount !== '' ? (
                    renderPortfolioCard({
                      value: totalInvestmentAmount,
                      title: PF_SUM_TOT_INV,
                    })
                   ) : renderSkeleteonElement()
                }
						</div>
            <div>
								{
                  cashBalance !== '' ? (
                      renderPortfolioCard({
                        value: cashBalance,
                        title: PF_SUM_CB,
                      })
                    ): renderSkeleteonElement()
                }
							</div>
              <div>
            {
            currentValue !== '' ? (
            renderPortfolioCard({
              color: returnChangeColor,
              value: totalReturn,
              title: PF_SUM_PL,
              perChange: totalReturnPer
            })
					  ) : renderSkeleteonElement()
            }
            </div>
						{/* <Row className='m-0'>
							<Col md={3}>
              {
                currentValue !== '' ?
                  renderPortfolioCard({
                  color: returnChangeColor,
                  value: currentValue,
                  title: PF_SUM_CURR_VAL,
                  perChange: totalReturnPer
                  }) : renderSkeleteonElement()
              }
							</Col>
						</Row>
						<Row>
							<Col>
								{
                  currentValue !== '' ? (
                    renderPortfolioCard({
                      color: todayChangeColor,
                      value: todayChange,
                      title: PF_SUM_TODAY_CHG,
                      perChange: todayChangePercentage
                    })
                  ) : renderSkeleteonElement()
                }
							</Col>
						</Row>
						<Row>
							<Col>
								{
                   totalInvestmentAmount !== '' ? (
                    renderPortfolioCard({
                      value: totalInvestmentAmount,
                      title: PF_SUM_TOT_INV,
                    })
                   ) : renderSkeleteonElement()
                }
							</Col>
						</Row>
						<Row>
							<Col>
								{
                  cashBalance !== '' ? (
                      renderPortfolioCard({
                        value: cashBalance,
                        title: PF_SUM_CB,
                      })
                    ): renderSkeleteonElement()
                }
							</Col>
						</Row> */}
					</Slider>
				{/* </Col> */}
			</Row>
		</>
	)
}

export default Portfolio
