import React from 'react'
import { PopularStockWithoutGraph } from '../../components'
import { Row, Col } from 'react-bootstrap'
import styles from './PortfolioTable.module.css'
import PortfolioTableCard from './PorfolioTableCard'
import {decimalAdjust, addCommasinPrice} from '../../utils'
import { CORPORATE_FUNDS } from '../../constants'

const PortfolioTableMobile = ({ title,data,rowHeaders, currentValue }) => {

	return (
    <div>
      {/* <Row>
      <Col xs={6} style={{ paddingLeft: '0px' }} className={styles.title}>
        {title}
      </Col>
      <Col
        className={styles.value}
        xs={6}
        style={{
          textAlign: 'end',
          paddingRight: '0px'
        }}>
        {isNaN(currentValue) ? '--' : `$${addCommasinPrice(decimalAdjust('floor',currentValue,-2))}`}
      </Col>
      </Row> */}
      <Row >
      {data && data.length ? (
        data.map((item, index) => {
          const codeTitle =  title && title.slice(0,-1)
          return (
              <PortfolioTableCard
                title={title}
                logo={item.Image}
                code={item[codeTitle]}
                price={item.Price}
                shares={title === 'Stacks' ? '' : item.Shares}
                avgCost={title === 'Stacks' ? '' : item['Avg.Cost']}
                change={item.change}
                changePer={item['Chng']}
                totalInvested={item['Total Invested']}
                totalReturn={item.totalReturn}
                totalRetPer={item.totalRetPer}
                currentValue={item['Current Value']}
                category={item.category}
                isStackActive={item.isStackActive}
                item={item}
                tos={item.tos}
              />
          )
        })
      ) : (
        <div className={styles.notfound}><span>{`No ${title} Found`}</span>{title==="Funds" && <>
          &nbsp;-&nbsp;
          <a href={CORPORATE_FUNDS} target='_blank' rel='noopener noreferrer'>Explore Funds</a>
        </>}</div>
      )}
       </Row>
    </div>
  )
}

export default PortfolioTableMobile
