import React, { useState,useEffect } from 'react'
import { Row, Modal, Col } from 'react-bootstrap'
import './modal.css'
import styles from './StockFinancial.module.css'
import financialImage from '../../public/images/open.svg'
import $ from 'jquery'
import NewFinancial from './desktop/newFinancialDataFull'
import CustomModal from '../../components/CustomModal/CustomModal'
import MobileModal from './mobile/mobileModal'

const StockFinancial = ({ code }) => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(()=>{
    setShow(false)
  },[code])

  return (
    <React.Fragment>
      <Row className={styles.finDiv}>
        <Col>
          <ul className={`${styles.markerRemove}`}>
            <li className={`nav-item d-none d-lg-block`}
              data-target="#myModal2"
              data-toggle="modal">
              <div style={{ cursor: 'pointer' }}>

                <span className={styles.financialTitle}>{`See financials stats`} </span>
                <img src={financialImage} />
              </div>
            </li>
            <li className={`nav-item d-block d-lg-none`}
              onClick={() => handleShow()}
            >
              <div style={{ cursor: 'pointer' }}></div>
              <span className={styles.financialTitle}>{`See financials stats`} </span>
              <img src={financialImage} />
            </li>
          </ul>
        </Col>
      </Row>
      <NewFinancial code={code} />
      <CustomModal show={show} handleClose={handleClose}>
        <MobileModal code={code} />
      </CustomModal>
    </React.Fragment>
  )
}

export default StockFinancial
