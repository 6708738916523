import React,{useState,useEffect,useContext} from "react"
import { useHistory } from "react-router"
import styles from './IndDocument.module.css'
import {getonBoardingKycStatus,getDigilockerUrl} from '../../../api'
import GenericErrorIcon from "../../../public/images/genericError.svg"
import LoadingIcon from "../../../public/images/easyfund/Loading.svg"
import { Spinner } from "react-bootstrap"

const DigiLockerScreen = ({setShowDigiLocker,setShowSelection,setDocTitle,setIfMenaIndiaSelect,setShowMenaUpload,setShowBackOfID,ifMenaIndiaSelect,location}) => {
  const [kycStatus,setKycStatus] = useState('')
  const [loading, setLoading] = useState(false)
  const [url,setUrl] = useState('')
  const [errorStatus,setErrorStatus] = useState(false)
  const [errorMessage,setErrorMessage] = useState('Something went wrong')
  const [showUpload,setShowUpload] = useState(false)
  const history = useHistory()
  useEffect(() => {
    fetchData()
  },[])

  const fetchData = async () => {
    setLoading(false,setUrl('',setErrorStatus(false)))
    try{
      const resp = await getDigilockerUrl('ONBOARDING')
      if(resp.doc && resp.doc.url){
        window.open(resp?.doc?.url, "_blank")
        setUrl(resp?.doc?.url,setLoading(true,setKycStatus('pending')))
      }
     else{
      setErrorStatus(true)
     }
    }catch(error){
      setErrorStatus(true,setLoading(false,setShowUpload(false)))
    }
}

  useEffect(() => {
    if(kycStatus === 'REJECTED' || kycStatus === 'FAILURE' || kycStatus === 'declined'){
      // eslint-disable-next-line no-undef
      webengage.track("Aadhar digital verification status",{
        'verification successful': false
      });
      setErrorStatus(true,setLoading(false,setShowUpload(false,
        setErrorMessage('Your Aadhaar Card details couldn’t be processed'))))
    }
    else if (kycStatus !== 'APPROVED') {
      var summaryInterval = setInterval(() => {
        getStatusKycApi();
      }, 10000);
    }else{
      // eslint-disable-next-line no-undef
      webengage.track("Aadhar digital verification status",{
        'verification successful': true
      });
      if(location === "MENA" && ifMenaIndiaSelect){
        setDocTitle(`Upload Address Proof`)
        setIfMenaIndiaSelect(false)
        setShowMenaUpload(false)
        setShowBackOfID(true)
      }else{
        history.push('/verifypan')
      }
    }
    return () => {
      clearInterval(summaryInterval);
    }
  },[kycStatus])

  const getStatusKycApi = async () => {
    let success = true
    try{
        const resp = await getonBoardingKycStatus()
        if(resp && resp.doc && resp.doc.status){
          setKycStatus(resp.doc.status)
        }
        success=true
    }catch(error){
      success=false
    }
  }

  const onRetry = () => {
    setShowSelection(true,setShowDigiLocker(false))
  }

  return(
    <>
    {errorStatus ?
        <div className={styles.cancelContainer}>
        <div>
            <div className={styles.cancelHeader}>
                <img src={GenericErrorIcon}/>
                <span className='text-center'>{errorMessage}</span>
            </div>
            <div className={styles.cancelBody}>
              {showUpload ?
                <div style={{paddingLeft:'30px',paddingRight:'30px'}}>
                  <button className={styles.activeButton}
                  // onClick={() => setSubPage(SUBPAGE[PAGE.EASYFUND].VERIFYAADHAR)}
                  >
                  <div className={styles.activateText} >Upload Aadhar Card</div>
                </button>
                </div> :
                 <>
                <span>
                {kycStatus === 'FAILURE' ? 'We weren’t able to fetch your details. Please try again later or complete your KYC manually.' :
                'The Date of Birth on your Aadhaar Card and your account don’t match. Please try verifying your details again after some time.'}
                </span>
                  <div style={{paddingLeft:'30px',paddingRight:'30px'}}>
                  <button className={styles.activeButton} onClick={() => onRetry()}>
                  <div className={styles.activateText} >Retry KYC Verification</div>
                </button>
                </div>
                </>}
                <span className={styles.contactUs} onClick={()=>window.fcWidget.open()}>
                Contact support
              </span>
            </div>
        </div>
    </div>
    :
    loading ? <div className={styles.cancelContainer}>
               <div className={styles.cancelHeader} style={{paddingBottom:'0px'}} >
                    <img src={LoadingIcon}/>
                    <span className='text-center'>Your Aadhaar Card details are being processed</span>
                </div>
                <div className={styles.cancelBody} style={{paddingTop:"0px",paddingBottom:'0px'}}>
                    <span>
                      KYC Verification is required for international fund transfers. This usually takes a couple of minutes. We’ll notify as soon as your details are verified.
                    </span>
                    <span className={styles.contactUs} onClick={()=>window.fcWidget.open()}>
                    Contact support
                  </span>
                </div></div> :
            <div className={styles.genericErrorLoading} style={{justifyContent:'center'}}>
              <Spinner animation="border" className={styles.spinnerColor}/>
             </div>
    }
    </>
  )
}

export default DigiLockerScreen
