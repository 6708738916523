import React from "react"
import Copy from './copy'
import styles from './online.module.css'

const ICICITable = ({accountDetail})=>{
    return (<div className={styles.beneficiaryTable}>
        <div  className={styles.beneficiaryTableRow}>
           
            <div className={styles.beneficiaryTableContent}>
                <div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
                    Receiver Name
                </div>
                <div className={styles.beneficiaryTableValue}>
                    <span>{accountDetail.name}</span>
                    <Copy data={{label:'Receiver Name', value:accountDetail.name}}/>
                </div>
            </div>
        </div>
        <div  className={styles.beneficiaryTableRow}>
            
            <div className={styles.beneficiaryTableContent}>
                <div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
                    Receiver Nick Name
                </div>
                <div className={styles.beneficiaryTableValue}>
                    <span>{accountDetail.nickName}</span>
                    <Copy data={{label:'Receiver Nick Name', value:accountDetail.nickName}} />
                </div>
            </div>
        </div>
        <div  className={styles.beneficiaryTableRow}>
            
            <div className={styles.beneficiaryTableContent}>
                <div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
                    Receiver Address
                </div>
                <div className={styles.beneficiaryTableValue}>
                    <span>{accountDetail.address}</span>
                    <Copy data={{label:'Receiver Address', value:accountDetail.address}} />
                </div>
            </div>
        </div>
        <div  className={styles.beneficiaryTableRow}>

            <div className={styles.beneficiaryTableContent}>
                <div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
                    Pin Code
                </div>
                <div className={styles.beneficiaryTableValue}>
                    <span>{accountDetail.pinCode}</span>
                    <Copy data={{label:'Pin Code', value:accountDetail.pinCode}} />
                </div>
            </div>
        </div>
        <div  className={styles.beneficiaryTableRow}>
            
            <div className={styles.beneficiaryTableContent}>
                <div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
                    Country
                </div>
                <div className={styles.beneficiaryTableValue}>
                    <span>{accountDetail.country}</span>
                    <Copy data={{label:'Country', value:accountDetail.country}} />
                </div>
            </div>
        </div>
        <div  className={styles.beneficiaryTableRow}>
           
            <div className={styles.beneficiaryTableContent}>
                <div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
                    Receiver Bank Country
                </div>
                <div className={styles.beneficiaryTableValue}>
                    <span>{accountDetail.bankCountry}</span>
                    <Copy data={{label:'Receiver Bank Country', value:accountDetail.bankCountry}} />
                </div>
            </div>
        </div>
        <div className={`${styles.beneficiaryAccNum} ${styles.beneficiaryTableRow}`}>
            
            <div className={styles.beneficiaryTableContent}>
                <div>
                    <div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
                        Receiver Account Number
                    </div>
                </div>
                <div className={styles.beneficiaryAccNumValue}>
                    <div className={styles.beneficiaryTableValue}>
                        <span>{accountDetail.accountNumber}</span>
                        <Copy data={{label:'Receiver Account Number', value:accountDetail.accountNumber}} />
                    </div>
                    <div className={styles.divider}></div>
                    <div className={styles.beneficiaryAccInfo}>Everyone has an Unique Beneficiary Account Number.</div>
                </div>
            </div>
        </div>
        <div  className={styles.beneficiaryTableRow}>
            
            <div className={styles.beneficiaryTableContent}>
                <div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
                    Receiver Bank SWIFT Code
                </div>
                <div className={styles.beneficiaryTableValue}>
                    <span>{accountDetail.SWIFTCode}</span>
                    <Copy data={{label:'Receiver Bank SWIFT Code', value:accountDetail.SWIFTCode}} />
                </div>
            </div>
        </div>
        <div  className={styles.beneficiaryTableRow}>
           
            <div className={styles.beneficiaryTableContent}>
                <div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
                    Receiver Bank Name
                </div>
                <div className={styles.beneficiaryTableValue}>
                    <span>{accountDetail.bankName}</span>
                    <Copy data={{label:'Receiver Bank Name', value:accountDetail.bankName}} />
                </div>
            </div>
        </div>
    </div>)
}

export default ICICITable