import React,{useState} from 'react'
import styles from './mobileBuyButton.module.css'
import { Container, Row, Col, Card } from 'react-bootstrap'
import crossImage from '../../public/images/BlackTickMark.svg'
import { Modal } from 'react-bootstrap'
import {
	BuyCard,
	ReviewOrderCard,

} from '../../components'

const MobileBuyButton = ({stockName,livePrices,accountSummary,stockData,shareData,marketState,isOtc}) => {
  const [buyorsell, setBuyorSell] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [sellCheck, setSellCheck] = useState(false)
  const handleShow = () => setShowModal(true)
  const handleClose = () => setShowModal(false)
  return(
    <>
    {!buyorsell
      ? (<Row>
          <Col >
            <div className={styles.buttonDiv}>
            <button type="button" className={styles.buyButton} onClick={()=>setBuyorSell(true)}>{`Buy / Sell ${stockName}`}</button>
            </div>
          </Col>
        </Row>)
      :
        (<Row className={styles.buysellDiv} >
          <Col>
            <div className={styles.innerDiv}>
              <Row style={{textAlign:"center",alignItems:"center",alignContent:'center'}}>
                <Col xs={12}>
                  <button type="button" className={styles.buyPermannet} onClick={()=>setSellCheck(false,handleShow())}>{`Buy ${stockName}`}</button>
                </Col>
                <Col xs={12}>
                  <button type="button" className={styles.buyPermannet} onClick={()=> setSellCheck(true,handleShow())}>{`Sell ${stockName}`}</button>
                </Col>
                <Col xs={12}
                className={styles.cancelResponsive}
                >
                  <div
                    className={styles.circularDiv}
                    onClick={()=>setBuyorSell(false)}
                  >
                    <img src={crossImage} style={{margin: 'auto',display: 'block'}}/>
                    </div>
                  </Col>
              </Row>
            </div>
          </Col>
        </Row>)}
          <Modal show={showModal} onHide={handleClose}  className={`d-block d-lg-none ${styles.buysellModal}`}>
          <Modal.Body className={styles.bodyResponsive} style={{padding:"0px"}}>
            {/*{!review ? (*/}
            <BuyCard
              // setReviewFunc={setReviewFunc}
              isOtc={isOtc}
              stockName={stockName}
              livePrices={livePrices}
              sellCheck={sellCheck}
              stockData={stockData}
              shareData={shareData}
              marketState={marketState}
              accountSummary={accountSummary}
              handleClose={handleClose}
            />
            {/*: (<ReviewOrderCard setReviewFunc={setReviewFunc} stockName={stockName} />)}*/}

          </Modal.Body>
      </Modal>
    </>
  )
}

export default MobileBuyButton
