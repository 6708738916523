import React, { useState, useEffect } from 'react'
import { Row, Col, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap'
import CustomModal from '../../components/CustomModal/CustomModal'
import {
	getStackPriceHistory,
	getUserPortfolioDetail,
	getCurrentValue
} from '../../api'
import _, { isEmpty } from 'lodash'
import moment from 'moment'
import ReactHighstock from 'react-highcharts/ReactHighstock'
import {StackSummarySkeleton} from '../../skeletons'
import styles from './StackSummary.module.css'
import IndividualStack from '../../components/IndividualStack/IndividualStack'
import StackSell from '../StackSell/StackSell'
import QuestionMark from '../../public/images/TooltipQuestion.svg'
import Open from '../../public/images/seemore.svg'
import Close from '../../public/images/hideIcon.svg'
import method from '../../public/images/withdraw.svg'
import download from "../../public/images/Download.svg"
import PortfolioDisableSell from '../../public/images/portfolioDIsableSell.svg'
import { SELL_ALREADY_IN_PROGRESS, SELL_REBALANCING } from '../../displayMessages'

const StackSummary = ({ stack }) => {
	const [showMore, setShowMore] = useState(false)
	const [stackChart, setStackChart] = useState([])
	const [gspcChart, setgspcChart] = useState([])
	const [portfolioDetail, setPortfolioDetail] = useState({})
	const [showModal, setShowModal] = useState(false)
	const [showSell, setShowSell] = useState(false)
	const [currentvalue, setCurrentvalue] = useState('')
  const [investedValue, setInvestedValue] = useState('')
  const [sellStackError, setSellStackError] = useState('');
  const [disclaimerSeeMore, setdisclaimerSeeMore] = useState(false)
  const [showMobileModal, setShowMobileModal] = useState(false)
  const [disabledSell, setDisabledSell] = useState(false)
  const [active, setActive] = useState('1y')
	const {
		ticker = '',
		description = '',
		fee = '',
		entryFee = '',
		methodology = '',
		themes = [],
		rbFrequencyTitle = '',
		priceYear = '',
    minimumInvestment='',
    factsheet = '',
	disclaimer='',
	} = stack

	let {
		nextFeeDeductionDate='',
		nextRebalanceDate=''
	} = stack;

	// useEffect(() => {
	// 	const fetch = async () => {
	// 		if (ticker !== '') {
	// 			const resstack = await getStackPriceHistory(ticker, priceYear * 365)
	// 			if (resstack.code === 200) {
	// 				const temp = _.orderBy(resstack.data.history, ['timestamp'])
	// 				const stackObj = []
	// 				temp.map(item => {
	// 					stackObj.push([item.timestamp * 1000, parseFloat(item.price)])
	// 				})
	// 				const resgspc = await getStackPriceHistory('^GSPC', priceYear * 365)
	// 				if (resgspc.code === 200) {
	// 					const tempgspc = _.orderBy(resgspc.data.history, ['timestamp'])
	// 					const gspcObj = []
	// 					tempgspc.map(item => {
	// 						gspcObj.push([item.timestamp * 1000, parseFloat(item.price)])
	// 					})
	// 					setStackChart(stackObj)
	// 					setgspcChart(gspcObj)
	// 				}
	// 			}
	// 			const resportfolio = await getUserPortfolioDetail(ticker)
	// 			if (resportfolio && !isEmpty(resportfolio)) {
	// 				setPortfolioDetail(resportfolio)
	// 				setInvestedValue(resportfolio.totalInvestment)
	// 				const { currentValue = '' } = await getCurrentValue(ticker, 'stack')
  //         setCurrentvalue(currentValue)
	// 			}
	// 		}
	// 	}
	// 	fetch()
	// }, [priceYear, ticker])

  useEffect(() => {
		getStackPrice(365)
    setdisclaimerSeeMore(false)
	}, [ticker])

  const handleMobileClose = () => setShowMobileModal(false)
	const handleMobileShow = () => setShowMobileModal(true)

  const getStackPrice = async(days) => {
    			const resstack = await getStackPriceHistory(ticker, days)
				if (resstack && resstack.code === 200) {
					const temp = _.orderBy(resstack.data.history, ['timestamp'])
					const stackObj = []
					temp.map(item => {
						stackObj.push([item.timestamp * 1000, parseFloat(item.price)])
					})
					const resgspc = await getStackPriceHistory('^GSPC', days)
					if (resgspc && resgspc.code === 200) {
						const tempgspc = _.orderBy(resgspc.data.history, ['timestamp'])
						const gspcObj = []
						tempgspc.map(item => {
							gspcObj.push([item.timestamp * 1000, parseFloat(item.price)])
						})
						setStackChart(stackObj)
						setgspcChart(gspcObj)
					}
				}
        const resportfolio = await getUserPortfolioDetail(ticker)
				if (resportfolio && !isEmpty(resportfolio) && typeof resportfolio !== 'string') {
          setPortfolioDetail(resportfolio)
          if (resportfolio && resportfolio.sWeightedPrice > 0) {
            setSellStackError(SELL_ALREADY_IN_PROGRESS)
            setDisabledSell(true)
          } else if(resportfolio && resportfolio.isRebalancing) {
            setSellStackError(SELL_REBALANCING)
            setDisabledSell(true)
          }
					setInvestedValue(resportfolio.totalInvestment)
					const { currentValue = '' } = await getCurrentValue(ticker, 'stack')
          setCurrentvalue(currentValue)
				}
  }

  const handleChange = (value, days) => {
		setActive(value)
		getStackPrice(days)
	}

	var config = {
    chart: {
      marginRight:16,
      marginLeft:16,
      style: {
        overflow: 'visible',
        // width: 700,
        height: 250
      }
    },
		scrollbar: {
			enabled: false
		},
		rangeSelector: {
      enabled:false,
		// 	y: 50,
		// 	verticalAlign: 'bottom',
		// 	allButtonsEnabled: true,
		// 	inputEnabled: false,
    //   buttonPosition: {
    //     align: 'left',
    //     x: -10
    // },
		// 	buttons: [
		// 		{
		// 			type: 'month',
		// 			count: 1,
		// 			text: '1m'
		// 		},
    //     {
		// 			type: 'month',
		// 			count: 3,
		// 			text: '3m'
		// 		},
    //     {
		// 			type: 'month',
		// 			count: 6,
		// 			text: '6m'
		// 		},
		// 		{
		// 			type: 'year',
		// 			count: 1,
		// 			text: '1y'
		// 		},
		// 		{
		// 			type: 'all',
		// 			text: 'max'
		// 		}
		// 	],
		// 	buttonTheme: {
		// 		fill: 'none',
		// 		style: {
		// 			color: '#9096A8',
		// 			fontSize: '14px',
		// 			lineHeight: '16px',
		// 			fontWeight: 'bold'
		// 		},
		// 		width: 20,
		// 		height: 10,
		// 		padding: 12,
		// 		states: {
		// 			hover: {
		// 				fill: 'none',
		// 				style: {
		// 					color: '#1E1F24'
		// 				}
		// 			},
		// 			select: {
		// 				fill: 'none',
		// 				stroke: '#1E1F24',
		// 				'stroke-dashoffset': '57',
		// 				'stroke-dasharray': '35,100',
		// 				'stroke-width': 1,
		// 				style: {
		// 					color: '#1E1F24',
		// 					borderBottom: '1px solid #1E1F24'
		// 				}
		// 			}
		// 		}
		// 	},
		// 	selected: 3
		},
		title: {
			text: undefined
		},
		scrollbar: {
			enabled: false
		},
		xAxis: {
      lineWidth:0,
      tickWidth:0,
      labels: {
        style: {
          'font-style': 'normal',
          'font-weight': 'normal',
          'font-size': '12px',
          'line-height': '16px',
          'font-family':'geomanistregular',
          'color': '#9096A8'
        },
        step:2
        }
			},
		yAxis: {
			visible: false
		},

		plotOptions: {
			series: {
				compare: 'percent',
				showInNavigator: true,
				dataGrouping: {
					enabled: false
				},
				marker: {
					enabled: false,
					states: {
						hover: {
							enabled: false
						}
					}
				}
			}
		},
		navigator: { enabled: false },
		tooltip: {
			crosshairs: false,
			backgroundColor: '#FFFFFF',
			borderColor: '#EFEFF1',
			borderRadius: '0',
			pointFormat:
				'<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.change}%)<br/>',
			valueDecimals: 4,
			split: true
		},

		series: [
			{
				name: ticker,
				data: stackChart.sort((a, b) => a[0] - b[0]),
				type: 'line',
				lineWidth: 2,
				lineColor: '#0DC014'
			},
			{
				name: 'S&P500',
				data: gspcChart.sort((a, b) => a[0] - b[0]),
				type: 'line',
				lineWidth: 2,
				lineColor: '#1E1F24'
			}
		],
		credits: {
			enabled: false
		}
	}

	const handleClose = () => setShowModal(false)
	const handleShow = () => setShowModal(true)
	const handleSellClose = () => setShowSell(false)
	const handleSellShow = () => setShowSell(true)
	const toogleSeeDetail = () => setShowMore(!showMore)
	const returnValue = currentvalue - investedValue
	const returnChange =
		returnValue === 0
			? 0
			: currentvalue > investedValue
			? (returnValue / currentvalue) * 100
      : (returnValue / investedValue) * 100

  const handleSellStackClick = (value) => {
    if(!disabledSell){
      if(value === 'desktop'){
        handleSellShow();
      }
      else if(value === 'mobile'){
        handleMobileShow()
      }
    }
  }

  nextRebalanceDate = nextRebalanceDate ? moment(nextRebalanceDate).format('DD MMM YYYY') : '--';
  nextFeeDeductionDate = nextFeeDeductionDate ? moment(nextFeeDeductionDate).format('DD MMM YYYY') : '--';

  const stackDisabledCss = disabledSell ? null : { cursor: 'pointer' }
	return (
		<>
    {description === '' ?
    <StackSummarySkeleton/> :
    <>
			<Row>
				<Col>
					{!isEmpty(portfolioDetail) && currentvalue !== '' ? (
						<Row className={styles.sell}>
							<Col>
								<div className={styles.title}>Invested</div>
								<div className={styles.sellValue}>
									$
									{
									investedValue === '' || investedValue === null
										? '--'
										: parseFloat(investedValue).toFixed(2)
                  }
								</div>
							</Col>
							<Col>
								<div className={styles.title}>Current value</div>
								<div className={styles.sellValue}>
									$
									{currentvalue === '' || currentvalue === null
										? '--'
										: parseFloat(currentvalue).toFixed(2)}
								</div>
							</Col>
							<Col>
								<div className={styles.title}>Returns</div>
								<div className={styles.value}>
									<span className={styles.sellValue}>
										{parseFloat(returnValue) < 0
											? `-$${parseFloat(returnValue.toFixed(2))
													.toString()
													.substring(1)}`
											: `+$${parseFloat(returnValue.toFixed(2))}`}
									</span>
									<span
										className={styles.sellChange}
										style={returnValue < 0 ? { color: '#E51446' } : {}}>
										{parseFloat(returnChange.toFixed(2))}%
									</span>
								</div>
							</Col>
							<Col xs={12} lg={3} md={3}>
								<div className={`${styles.sellStack} ${stackDisabledCss} d-none d-md-block`} onClick={() => handleSellStackClick('desktop')}>
									Sell Stack
								</div>
                <div className={`${styles.sellStack} ${stackDisabledCss} d-block d-md-none`} onClick={() => handleSellStackClick('mobile')}>
									Sell Stack
								</div>
							</Col>
              {
                sellStackError && sellStackError !== "" ?
                <Col md={11} lg={9}>
                  <img
                    src={PortfolioDisableSell}
                    className='mr-2'
                    alt=""
                  ></img>
                  <span className={styles.sellDisableMessage}>{sellStackError}</span>
                </Col>
                : null
              }
						</Row>
					) : (
						''
					)}
					<Row>
						<Col md={11} lg={9}>
							<div className={styles.descriptiontitle}>Description</div>
							<div
								className={
									showMore || description.length < 400 ? styles.descriptionTotal : styles.description
								}>
								{showMore === false
									? description !== undefined
										? description.substring(0, 400)
										: ''
									: description}
							</div>
              {description.length > 400 ?
							<div className={styles.seeMore} onClick={toogleSeeDetail}>
								{showMore ? 'See less details' : 'See more details'} <img src={showMore ? Close : Open}></img>
							</div> :''}
						</Col>

            {/* for Mobile */}
            <Row className='d-flex d-lg-none ml-0 mr-0 mt-4' style={{width:'100%'}}>
              <Col>
              <div className={styles.title}>Min. Investment</div>
							<div className={`${styles.value}`}>
								${minimumInvestment}
							</div>
              </Col>
              <Col >
              <div className={`${styles.title}`}>
								Entry Fee
								<OverlayTrigger
									placement='top'
									overlay={
										<Tooltip id='button-tooltip-2'>
											This fee is charged one-time per transaction
										</Tooltip>
									}>
									<img src={QuestionMark} className={styles.tooltipImage}></img>
								</OverlayTrigger>
							</div>
							<div className={styles.value}>{`${entryFee == 0 ? 'NA' : `${entryFee}%`}`}</div>
              </Col>
            </Row>

            {/* for mobile */}
            <Row className='d-flex d-lg-none ml-0 mr-0 mt-3' style={{width:'100%'}}>
              <Col>
              <div className={styles.title}>Rebalance frequency</div>
							<div className={styles.value}>{rbFrequencyTitle}</div>
              </Col>
              <Col >
              <div className={styles.title}>Fee</div>
							<div className={`${styles.value}`}>
								{fee}% {rbFrequencyTitle}
							</div>
              </Col>
            </Row>

             {/* for Mobile */}
             {factsheet === '' ? '' :
             <Row className='d-flex d-lg-none ml-3 mr-0 mt-3' style={{width:'100%'}}>
               <a href={factsheet} target='_blank'>
               <span><img src={download}></img></span>
               <span className={styles.downloadFactsheet}>Download factsheet</span>
               </a>
             </Row>}

              {/* for mobile */}
              <Row className={`d-flex d-lg-none ml-3 mr-0 mt-3 pb-3 ${styles.marginBottom}`} style={{width:'100%'}}>
              <div className={`${styles.seeMore} mt-0`} onClick={handleShow}>
								Methodology
								<img src={method} style={{ marginLeft: '10px' }} />
							</div>
             </Row>

            {/* for desktop */}
						<Col className='d-none d-lg-block'>
							<div className={styles.title}>Fee</div>
							<div className={`${styles.value} ${styles.marginBottom}`}>
								{fee}% {rbFrequencyTitle}
							</div>
							<div className={`${styles.title} pt-4`}>
								Entry Fee
								<OverlayTrigger
									placement='top'
									overlay={
										<Tooltip id='button-tooltip-2'>
											This fee is charged one-time per transaction
										</Tooltip>
									}>
									<img src={QuestionMark} className={styles.tooltipImage}></img>
								</OverlayTrigger>
							</div>
							<div className={styles.value}>{`${entryFee == 0 ? 'NA' : `${entryFee}%`}`}</div>
							<div className={`${styles.seeMore} mt-0`} onClick={handleShow}>
								Methodology
								<img src={method} style={{ marginLeft: '10px' }} />
							</div>
						</Col>
						<CustomModal show={showModal} handleClose={handleClose}>
							<div className={styles.modalWidth}>
								<Row className={styles.methodology}>Methodology</Row>
								<Row className={styles.methodologyDetail}>{methodology}</Row>
							</div>
						</CustomModal>
					</Row>
					<Row className={`${styles.themeCss} d-none d-lg-flex`}>
						<Col>
							<div className={styles.title}>Rebalance frequency</div>
							<div className={styles.value}>{rbFrequencyTitle}</div>
						</Col>
						<Col>
							<div className={styles.title}>Next Rebalance</div>
								<div className={styles.value}>{nextRebalanceDate}</div>
						</Col>
						<Col>
							<div className={styles.title}>Next Fee Deduction</div>
								<div className={styles.value}>{nextFeeDeductionDate && nextFeeDeductionDate.split('T')[0]}</div>
						</Col>
					</Row>
					<div className={styles.themeCss}>
						<IndividualStack themes={themes} />
					</div>
					<div style={{ marginTop: '64px' }}>
						<Row className='ml-0'>
							<span className={styles.ticker}>{ticker} vs</span>
							<span className={styles.snp}> S&P 500</span>
						</Row>
            <Row className='ml-0 mt-3 d-block d-lg-none' style={{textAlign:'center'}}>
						<div className={styles.legendCircle}></div><span className={styles.legendTitle} style={{marginRight:'32px'}}>S&P 500</span>
                <div className={styles.legendCircle} style={{backgroundColor:'#0DC014'}}></div><span className={styles.legendTitle} style={{color:'#0DC014'}}>Stack</span>
						</Row>
						<div>
							<ReactHighstock config={config}></ReactHighstock>
						</div>
            <Row className={styles.graphRow}>
              <Col>
              <span className={active === '1m' ? styles.activeValue : styles.nonactive} onClick={() => handleChange('1m',30)}>1m</span>
              <span
                className={active === '3m' ? styles.activeValue : styles.nonactive}
                onClick={() => handleChange('3m', 90)}>
                3m
              </span>
              <span
                className={active === '6m' ? styles.activeValue : styles.nonactive}
                onClick={() => handleChange('6m', 180)}>
                6m
              </span>
              <span
                className={active === '1y' ? styles.activeValue : styles.nonactive}
                onClick={() => handleChange('1y', 365)}>
                1y
              </span>
              <span
                className={active === 'max' ? styles.activeValue : styles.nonactive}
                onClick={() => handleChange('max', priceYear * 365)}>
                max
              </span>
              </Col>
              <Col style={{textAlign:'end'}} className='d-none d-lg-block'>
                {/* <div className={styles.graphLegend}> */}
                <div className={styles.legendCircle}></div><span className={styles.legendTitle} style={{marginRight:'32px'}}>S&P 500</span>
                <div className={styles.legendCircle} style={{backgroundColor:'#0DC014'}}></div><span className={styles.legendTitle} style={{color:'#0DC014'}}>Stack</span>
              {/* </div> */}
              </Col>
            </Row>
             <div className={styles.disclaimer}>
               <div className={styles.disclaimerTitle}>Disclaimer</div>
               {/* <div className={styles.disclaimerText}>{disclaimer}</div> */}
               <div>
                 {disclaimer.length > 260 ?
                  disclaimerSeeMore === true ?
                  <div className={styles.disclaimerText}>{disclaimer}</div> :
                 <>
                 <div className={styles.disclaimerText}>{disclaimer.substring(0,250)}...
                 <span style={{color:'#005DD6',marginLeft:'4px',cursor:'pointer'}} onClick={() => setdisclaimerSeeMore(true)}>See More</span>
                 </div>
                 </>
                 :
                 <div className={styles.disclaimerText}>{disclaimer}</div>}
                 </div>
             </div>
					</div>
				</Col>
			</Row>
			<CustomModal show={showSell} handleClose={handleSellClose} className='d-none d-lg-block'>
				<StackSell
					currentValue={currentvalue}
					stack={stack}
          investedValue={investedValue}
          showSell={showSell}
				/>
			</CustomModal>


      {/* mobile Modal */}
      <Modal show={showMobileModal} onHide={handleMobileClose} dialogClassName={styles.modalDialog} className='d-block d-lg-none'>
      <StackSell
					currentValue={currentvalue}
					stack={stack}
          investedValue={investedValue}
          showSell={showSell}
          closeModal={handleMobileClose}
				/>
      </Modal>
      </> }
		</>
	)
}

export default StackSummary
