import React, { useEffect, useState } from 'react'
import { Row, Card, Button, FormControl, Modal, Col } from 'react-bootstrap'
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux'

import CustomModal from '../CustomModal/CustomModal'
import ReviewOrder from './ReviewOrder'
import { getStackAllocation, getAccountSummaryWithCommission } from '../../api'
// import './modal.css'
import styles from './StackBuy.module.css'
import { ACC_SUMM_WITH_COMM_INTERVAL, paths, planSubStatus, LiquidateMessge } from '../../constants'
import { MIN_INV_MSG, CASH_AVAIL_BEFORE_TRADE_MSG, PLAN_EXPIRATION_MSG, PLAN_SUBSCRIPTION_MSG, INSUFFICIENT_AMOUNT_TRADE_MSG } from '../../displayMessages'
import stackSummarySkeleton from '../../skeletons/StackSummarySkeleton';
import { getCurrentAccountStatusLink } from '../../common';
import { getPANDetails } from '../../api';


const StackBuy = ({ stack, setStackAllocation }) => {
  const dispatchAction = useDispatch()
  const history = useHistory();
	const {
		ticker = '',
		minimumInvestment = 0,
		createdByProfilePictureLink = '',
    createdByTitle = '',
    eligible = true,
    requiresUpgrade,
    activationFee,
	} = stack
	const [showModal, setShowModal] = useState(false)
  const [showMobileModal, setShowMobileModal] = useState(false)
	const [investedvalue, setInvestedvalue] = useState(minimumInvestment)
	const [purchased, setPurchased] = useState(false)
  const [buyDisable, setbuyDisable] = useState(true)
  const [buyDisableMsg, setbuyDisableMsg] = useState('')
  const [buyButtonText, setButtonText] = useState('')
  const [buyButtonClassName, setBuyButtonClassName] = useState('')
  const [isCash, setIsCash] = useState('')
  const [isLiquidated, setIsLiquidated] = useState(false);
  const [isStackBuy, setIsStackBuy] = useState(false);

  const [error, seterror] = useState('')
  const [ planMsg, setPlanMsg] = useState('')
	const handleClose = () => {
    setShowModal(false)
    setPurchased(false);
  }
	const handleShow = () => setShowModal(true)
  const handleMobileClose = () => setShowMobileModal(false)
  const handleMobileShow = () => setShowMobileModal(true)

  const fetchAccountSummaryCashApi = async () => {
    let data;
    try {
      data = await getAccountSummaryWithCommission()
      const {
        currentCashAvailForTrade = '',
        accountSummary: {
          cashAvailableForTrade = ''
        } = {}
      } = data
      if(currentCashAvailForTrade && currentCashAvailForTrade !== ''){
        setIsCash(currentCashAvailForTrade);
      } else {
        setIsCash(cashAvailableForTrade);
      }
    } catch (err) {
      console.log("error fetching portfolioData");
    }
    return data;
  }

  useEffect(()=>{
    let data = JSON.parse(sessionStorage.getItem('userInfo'));
    if(data && data.length > 0 && data.userRestriction){
      setIsStackBuy(data.userRestriction && data.userRestriction.stack_trade_buy ? data.userRestriction.stack_trade_buy : false);
    }
  })

	useEffect(() => {
		setInvestedvalue(minimumInvestment)
	}, [minimumInvestment])

  const renderMessage = (type, text) => {
    return (
      <span>
        {type }
        <Link to={paths.profile}>{text}</Link>
      </span>
    )
  }
 
  useEffect(() => {
    let isPaymentActive = false
    if (JSON.parse(sessionStorage.getItem('planInfo')) && JSON.parse(sessionStorage.getItem('planInfo')).planStatus) {
      let { planStatus } = JSON.parse(sessionStorage.getItem('planInfo'))
      isPaymentActive = planStatus === planSubStatus.ACTIVE
      if (planStatus === planSubStatus.EXPIRE) {
        let msg = renderMessage(PLAN_EXPIRATION_MSG, planSubStatus.RENEW);
        setPlanMsg(msg)
      }
    } else if (JSON.parse(sessionStorage.getItem('userInfo')) && Object.keys(JSON.parse(sessionStorage.getItem('userInfo'))).length > 0) {
      let { nextStep } = JSON.parse(sessionStorage.getItem('userInfo'))
      if (nextStep === 'COMPLETE') {
        let msg = renderMessage(PLAN_SUBSCRIPTION_MSG, 'Subscribe now');
        setPlanMsg(msg)
      }
    }
    const fetch = async () => {
      await fetchAccountSummaryCashApi();
    }
    fetch();
  }, [])

  const fetchPANDetails = async () => {
    const response = await getPANDetails();
    if (response && response.code === 200 && response.data) {
      setIsLiquidated(response.data.liquidateOnly)
      // setbuyDisable(response.data.liquidateOnly)
    }
    else {
      console.log('something went wrong')
    }
  }

  useEffect(() => {
      fetchPANDetails();
  },[])

	useEffect(() => {
    const isBuyDisabled = minimumInvestment > isCash || isStackBuy ? true : false;
    let buyButtonStatus = true;
    if(activationFee !== 0) {
      setButtonText('Upgrade to Buy');
      buyButtonStatus = false;
      setbuyDisable(false)
      setBuyButtonClassName(styles.upgradeButton)
    } else {
      setButtonText('Buy Stack');
      if(isBuyDisabled){
        setbuyDisableMsg(INSUFFICIENT_AMOUNT_TRADE_MSG);
      } else {
        setbuyDisableMsg('');
      }
      buyButtonStatus = isBuyDisabled;
      setbuyDisable(isBuyDisabled)
      setBuyButtonClassName(styles.buyButton)
    }
    if(!buyButtonStatus && isLiquidated){
      setbuyDisable(true)
    }

	}, [isCash, minimumInvestment, activationFee, isLiquidated])

	useEffect(() => {
		const fetch = async () => {
			const value =
				investedvalue >= minimumInvestment ? investedvalue : minimumInvestment
					const response = await getStackAllocation(ticker, value)
					setStackAllocation(response)
		}
		if(investedvalue && investedvalue > 0) {
			fetch()
		}
	}, [investedvalue, minimumInvestment, setStackAllocation, ticker])
	// const onPurchase = () => setPurchased(true)
	const handleChange = event => {
    var rgx = /^[0-9]*\.?[0-9]*$/;
    if(event.target.value.match(rgx) && event.target.value !== '.' && !isLiquidated){
		if (event.target.value < minimumInvestment) {
			setbuyDisableMsg(MIN_INV_MSG)
			setbuyDisable(true)
		} else if (event.target.value > isCash) {
			setbuyDisableMsg(INSUFFICIENT_AMOUNT_TRADE_MSG)
			setbuyDisable(true)
		} else {
			seterror('')
      setbuyDisable(false)
		}
		setInvestedvalue(event.target.value)
    }
  }

  /**
   * Account summary api with commission
   * running every 10 seconds for now => need to change to every 5 seconds.
   */
  useEffect(() => {
    const setCashAvail = async() => {
      await fetchAccountSummaryCashApi()
    }

    const summaryId = setInterval(() => {
      setCashAvail();
    }, ACC_SUMM_WITH_COMM_INTERVAL);

    return () => {
      clearInterval(summaryId);
    }
  }, [])

  const onBuyClick =async (type) => {
    if(requiresUpgrade || stack.activationFee !== 0) {
      // history.push(paths.profile);
      //logic to redirect user based on account status
      const link = await getCurrentAccountStatusLink();
      history.push(link)
    } else {
      switch(type) {
        case 'mobile': handleMobileShow(); break;
        default: handleShow();
      }
    }
  }
  // const buyButtonClassName = activationFee !== 0 ? styles.upgradeButton : styles.buyButton

	return (
		<>
			<Card className={styles.stackCard}>
				<Card.Body className={styles.cardBody}>
					<Row className={styles.stackPrice}>${minimumInvestment}</Row>
					<Row className={`m-0 mt-1 ${styles.minInvest}`}>Min.Investment</Row>
					<Row className={styles.inputControl} style={{ position: 'relative' }}>
						<span className={styles.dollarIcon}>
							<img src={require('../../public/images/dollar.svg')}></img>
						</span>
						<FormControl
							className={styles.amountInput}
							placeholder='Enter Amount'
							// type='number'
							value={investedvalue}
							onChange={event => handleChange(event)}
						/>
						{error !== '' ? (
							<span className={styles.minInvest} style={{ color: '#E51446' }}>
								{error}
							</span>
						) : (
							''
						)}
					</Row>
					<Row className='m-0 mt-4 d-none d-md-block'>
						<Button
							className={`${buyButtonClassName}`}
							onClick={onBuyClick}
							disabled={buyDisable}>
							{buyButtonText}
						</Button>
					</Row>

          {/* mobile button */}
          <Row className='m-0 mt-4 d-block d-md-none'>
						<Button
							className={`${buyButtonClassName}`}
							onClick={() => onBuyClick('mobile')}
              style={{width:'100%'}}
							disabled={buyDisable}>
							{buyButtonText}
						</Button>
					</Row>
					<Row
						className={`m-0 mt-4 justify-content-center ${styles.stackCreatedBy}`}>
						Stack created by
					</Row>
					<Row
						className={`m-0 mt-2 justify-content-center ${styles.stackCreatedBy} ${styles.createdBypadding}`}>
						<span>
							<img
								src={createdByProfilePictureLink}
								className={styles.createdImage}></img>
						</span>
						<span className={styles.stackCreatedBy}>{createdByTitle}</span>
					</Row>
          {
          isLiquidated  ? (
          <Row style={{ marginTop: '16px' }}>
            <div className={styles.noteMessage}>
              <Row className=''>
                <Col xs={1} lg={1} className={styles.info}>
                  &#9432;
                </Col>
              <Col xs={10} lg={10} className={styles.noteMessageText}>
                {LiquidateMessge}
              </Col>
              </Row>
            </div>
          </Row>
        ) : (
          ''
        )
        }
          {
          planMsg !== '' ? (
          <Row style={{ marginTop: '16px' }}>
            <div className={styles.noteMessage}>
              <Row className='pt-2'>
                <Col xs={1} lg={1} className={styles.info}>
                  &#9432;
                </Col>
              <Col xs={10} lg={10} className={styles.noteMessageText}>
                {planMsg}
              </Col>
              </Row>
            </div>
          </Row>
        ) : (
          ''
        )
        }
        {
          buyDisable && buyDisableMsg  ? (
          <Row style={{ marginTop: '16px' }}>
            <div className={styles.noteMessage}>
              <Row className='pt-2'>
                <Col xs={1} lg={1} className={styles.info}>
                  &#9432;
                </Col>
              <Col xs={10} lg={10} className={styles.noteMessageText}>
                {buyDisableMsg}
              </Col>
              </Row>
            </div>
          </Row>
        ) : (
          ''
        )
        }
				</Card.Body>
			</Card>


      {/* mobile Modal */}
      <Modal show={showMobileModal} onHide={handleMobileClose} dialogClassName={styles.modalDialog} className='d-block d-lg-none'>
      <ReviewOrder
					stack={stack}
					investedvalue={investedvalue}
					closeModal={handleMobileClose}
					setPurchased={setPurchased}
					purchased={purchased}
				/>
      </Modal>

			<CustomModal show={showModal} handleClose={handleClose} className='d-lg-block'>
				<ReviewOrder
					stack={stack}
					investedvalue={investedvalue}
					closeModal={handleClose}
					setPurchased={setPurchased}
					purchased={purchased}
				/>
			</CustomModal>
		</>
	)
}



export default StackBuy
