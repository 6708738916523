import React, { useState , useEffect } from 'react'
import { unfreezeVirtualCard , customOTP, validateTheOtp } from '../../api'
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import CashMgmtOtp from './CashMgmtOtp'
import styles from './TransactionDetailsModal.module.css'
import modalCrossButton from '../../public/images/modalCrossButton.svg'
import unfreezeIcon from '../../public/images/unfreezeIcon.svg'

const UnfreezeVirtualCardModal = (props) => {

    const { onHide , cardId, setCardStatus } = props

    const [isDisabled, setIsDisabled] = useState(false)
    const [unfreezedCard, setUnfreezedCard] = useState(false)

    const [otp, setOtp] = useState()
    const [hashId, setHashId] = useState()
    const [otpError , setOtpError] = useState('')
    const [clearOtp, setClearOtp] = useState(false)

    const setUnfreezeCardOtp = (value) => {
        setOtp(value)
    }

    const sendOtp = () => {
        const body = { reason:"unfreeze_card"}
        customOTP(body).then(response=>{
            if(response.code === 200){
                setHashId(response.data.hashID)
            }
            }).catch((err)=>{
            console.error(err)
          })
    }
    
    const unfreezeCard = () => {

        const data = {cardId:cardId}

        unfreezeVirtualCard(data).then(response => {
            if(response.data.code === 200){
                setCardStatus(response.data.data.cardStatus)
                setUnfreezedCard(true)
                setTimeout(onHide, 2000)
                setIsDisabled(false)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const validateOtpForCard = () => {

        setIsDisabled(true)
        
        if(otp && otp.length === 6) {
            const data = {hashID:hashId, otp:otp}
            validateTheOtp(data).then(response => {
                if(response.data.code === 200){
                    setOtpError('')
                    unfreezeCard()
                }
            }).catch((err)=> {
                console.log(err)
                setOtpError('OTP is not valid')
                setClearOtp(true)
                setIsDisabled(false)
            })
        } else {
            setOtpError('Enter a 6-digit OTP')
            setClearOtp(true)
            setIsDisabled(false)
        }
    }

    useEffect(()=>{
        sendOtp()
    },[])

    return (
        <div className={styles.modalContainer}>
            <div className={styles.modalHeader} style={{backgroundColor:"#F7F7F8"}}>
                <div className={styles.freezeCard}>
                    <span><img src={unfreezeIcon} alt="freeze-icon"/></span>
                    <p className={`${styles.status} ${styles.textBlack}`}>Unfreeze this Virtual Card?</p>
                </div>
                <img src={modalCrossButton} alt="cross-button" onClick={onHide}></img>
            </div>
            <div className={styles.accountInfo}>
                <p className={`${styles.modalText} ${styles.textGrey}`}>
                    { unfreezedCard ? 'As requested, your virtual card has been unfreezed.' : 'You need to verify your identity using the code sent to your email address. Once this is done, you can resume outgoing transactions from your virtual card.'}
                </p>
            </div>
            { unfreezedCard ? '' : (
                    <>
                        <CashMgmtOtp setOtp={setUnfreezeCardOtp} resendOtp={sendOtp} otpError={otpError} clearOtp={clearOtp} setClearOtp={setClearOtp}/>
                        <div className={`${styles.modalRow} ${styles.modalButtonContainer}`}>
                            {isDisabled ? (
                                <Button variant="primary" disabled className={`${styles.modalButton} ${styles.modalButtonRed} ${styles.modalButtonSolidRed}`}>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </Button>
                            ) : (
                                <button className={`${styles.modalButton} ${styles.modalButtonBlue} ${styles.modalButtonSolidBlue}`} onClick={validateOtpForCard}>
                                    <p className={`${styles.modalText} ${styles.textWhite}`}>Unfreeze Card</p>
                                </button>
                            )}
                            <button onClick={onHide} className={`${styles.modalButton} ${styles.modalButtonGrey}`}>
                                <p className={styles.modalText}>Cancel</p>
                            </button>
                        </div> 
                    </>
                )
            }
        </div>
    )
}

export default UnfreezeVirtualCardModal