import React from 'react'
import { Row } from 'react-bootstrap'
import PinInput from "react-pin-input"
import { resendOtp,onBoardingValidateOtp,LeadCapture } from '../../api'
import styles from './SignUp.module.css'
import { ThemeContext } from '../../Context/ThemeContext'
import SignUpButton from './SignUpButton'


class VerifyEmail extends React.Component {
  constructor(props) {
		super(props)
    this.myRef = React.createRef()
  }

  state = { otp:'',otpError: '', otpSendMessage: '', resendOtpTimer: 180,loading:false }
  static contextType = ThemeContext;
  componentDidMount() {
    this.myRef.current.focus()
    this.startTimer()

    // disable copy and paste in otp-input
    const pinInputs = document.getElementsByClassName('pincode-input-text')

		if (pinInputs && pinInputs?.length > 0) {
			for (let i = 0; i < pinInputs.length; i++) {
				pinInputs[i].addEventListener('keydown', e => {
					if(e.key.toLowerCase() === "control" || e.key.toLowerCase() === "meta" || e.key.toLowerCase() === "v" || e.key.toLowerCase() === "c"){
						e.preventDefault()
					}
				})
			}
		}
  }

  startTimer = () => {
    const getLoading = this.state.loading === true ? true : false
    const timer = setInterval(() => {
      this.setState({ resendOtpTimer: this.state.resendOtpTimer - 1,loading: getLoading})
      if (this.state.resendOtpTimer === 178) {
        this.setState({ otpSendMessage: ''})
      }
      if (this.state.resendOtpTimer === 0) {
        clearInterval(timer)
      }
    }, 1000)
  }

  onResend = () => {
    const { hashID = '' } = this.props.formData
    this.setState({otp:'',otpError:''})
    this.myRef.current.clear()
    resendOtp(hashID).then(res => {
      if (res.code === 200) {
          if(res.data && res.data.hashID){
            const data = {...this.props.formData,hashID:res.data.hashID}
            this.props.updateFormData(data)
          }
        this.setState({ otpSendMessage: 'OTP has been resend', resendOtpTimer: 180 ,otpError:'',otp:''})
        this.startTimer()
      } else if (res.code === 400) {
        this.setState({ otpError: 'Resend OTP Fail' ,otp:''})
      }
    })
  }

  keyPressHandler = (event,submitCallback) => {
    if (event.which === 13) {
      submitCallback()
    }
  }

  handleSubmit = async() => {
    this.setState({loading:true})
    const {hashID,email=''} = this.props.formData
    if(this.state.otp !== ''){
       try{
          const resp = await onBoardingValidateOtp(hashID,this.state.otp)
          if(resp && resp.data && resp.data.code === 200){
          // eslint-disable-next-line no-undef
            webengage.user.setAttribute('we_email', email.toLowerCase());
            // eslint-disable-next-line no-undef
          webengage.track("User Registered I")
          sessionStorage.setItem('partnerCode',this.props.partnerCode)
          this.props.updateFormData({otp:this.state.otp})
          this.props.changeCurrent('UserName')
          }
       }
       catch(error){
         this.setState({otpError:error.message})
       }
    }else{
      this.setState({otpError:'Incorrect OTP'})
    }
    this.setState({loading:false})
  }

  onChangeOtp = (value) => {
    this.setState({otp:value},() => this.handleSubmit())
  }

  render() {
   const mediaMatch = window.matchMedia('(min-width:300px) and (max-width: 400px)');
   const {otpError} = this.state
   const inputStyle = {
      spinInput: {
        border: otpError === '' ? '1px solid #D3D4DA' : '1px solid #FA274A',
        textAlign: 'center',
        width: '42px',
        height: '44px',
        background: '#FFFFFF',
        borderRadius: '6px',
        marginLeft: mediaMatch.matches ? '7px' :'16px',
        // },
        // inputStyle:{
        fontFamily: 'geomanistregular',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#3C404A',
      }
    }

    return (
      <>
        <Row className={styles.title}>Verify your email</Row>
        <Row className={styles.subTitle}>Enter the 6-digit OTP sent to your email address
        </Row>
        <Row className={styles.emailStyle}>
        {this.props.formData ? this.props.formData.email : ''}
        <span className={styles.goBackText}
            onClick={() => this.props.changeCurrent('SignUp')} style={{marginLeft:'4px'}}>Edit</span>
        </Row>
        <Row className={styles.otpInput}>
            <PinInput
              length={6}
              initialValue=""
              onChange={(value, index) => { this.setState({otpError:''}) }}
              type="numeric"
              inputMode="number"
              onComplete={(value, index) => {
                this.onChangeOtp(value)
               }}
              inputStyle={{...inputStyle.spinInput }}
              inputFocusStyle={{ border: '1px solid #3C404A' }}
              autoSelect={true}
              regexCriteria={/[0-9]/}
              ref={this.myRef}
            />
        </Row>
        {otpError ?  <Row className={`m-0 mt-2 ${styles.resendText}`} style={{color:'#FA274A'}}>{otpError}</Row> : ''}
        <Row className={styles.resendOTP}>
          {this.state.otpSendMessage ?
            (
              <>
                <span
                  className={styles.goBackText}
                  style={{ color: '#767c8f' }}>
                  {this.state.otpSendMessage}
                </span>
              </>
            ) :
            <>
            {this.state.resendOtpTimer > 0 ? (
                <>
                  <span
                    className={styles.resendText}>
                    Expires in {this.state.resendOtpTimer}s
                  </span>
                </>
            ) : ''}
            <span className={ this.state.resendOtpTimer > 0 ? styles.subTitle :styles.goBackText} onClick={ this.state.resendOtpTimer > 0 ?  '' : () => this.onResend()}
            style={this.state.resendOtpTimer > 0 ? {cursor:'auto'} : {cursor:'pointer'}}>
             {this.state.resendOtpTimer?"" :"Resend OTP"}
            </span>
            </>
          }
        </Row>
        <Row className='m-0 mt-4'>
            <SignUpButton
              title='Verify OTP'
              onClick={this.handleSubmit}
              loading={this.state.loading}
              // disabled={!disableButton || isSubmitting}
            />
        </Row>
      </>
    )
  }
}

export default VerifyEmail
