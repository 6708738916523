
  import StartBannerApplicationBack from '../../public/images/StartBannerApplicationBack.svg'
  import StartBannerUnderProcess from '../../public/images/StartBannerUnderProcess.svg'

  export const FundMyAccountData = {
    type: 'FundMyAccountData',
    button: 'Fund my Account',
    buttonLink:'/funds?modal=true',
		bannerText: 'Start Investing by funding your account.',
		image: StartBannerUnderProcess,
		blueText: '',
		helpText: '',
		errorMessage: ''
  }

  export const FundMyAccountDataWithNoPlan = {
    type: 'FundMyAccountDataWithNoPlan',
    button: 'Choose plan & fund',
    buttonLink:'/profile?modal=true&redirectTo=home',
		bannerText: 'Start Investing by choosing a plan and funding your account.',
		image: StartBannerUnderProcess,
		blueText: '',
		helpText: '',
		errorMessage: ''
  }

	export const ApplicationUnderProcess = {
    type: 'ApplicationUnderProcess',
		blueText: 'Your application is under process.',
		button: '',
		bannerText:
			'It generally takes 15-20 minutes for your account to get activated.',
		image: StartBannerUnderProcess,
    helpText: '',
		errorMessage: ''
  }


	export const ApplicationBack = {
    type: 'ApplicationBack',
    button: 'Complete KYC',
    buttonLink:'/profile',
		bannerText:
			'Please complete your KYC. Make sure the proofs match the information that you have given during registration.',
		image: StartBannerApplicationBack,
		helpText:
			'If you need any help, please write to us at contactMail',
		blueText: '',
		errorMessage: 'We’re sorry, your application is sent back.'
  }
  export const ApplicationBackIND = {
    type: 'ApplicationBack',
    button: 'Complete KYC',
    buttonLink:'/profile',
		bannerText:
			'Please complete your KYC. Make sure the proofs match the information that you have given during registration.',
		image: StartBannerApplicationBack,
		helpText:
			'If you need any help, please write to us at contactMail',
		blueText: '',
		errorMessage: 'We’re sorry, your application is sent back.'
  }

  export const DocumentRejected = {
    type: 'DocumentRejected',
    button: 'Complete KYC',
    buttonLink:'/profile',
		bannerText:
			'Please complete your KYC. Make sure the proofs match the information that you have given during registration.',
		image: StartBannerApplicationBack,
		helpText:
			'If you need any help, please write to us at contactMail',
		blueText: '',
		errorMessage: 'We’re sorry, your application is sent back.'
  }
  export const DocumentRejectedIND = {
    type: 'DocumentRejected',
    button: 'Complete KYC',
    buttonLink:'/profile',
		bannerText:
			'Please complete your KYC. Make sure the proofs match the information that you have given during registration.',
		image: StartBannerApplicationBack,
		helpText:
			'If you need any help, please write to us at contactMail',
		blueText: '',
		errorMessage: 'We’re sorry, your application is sent back.'
  }

  export const ExpirePlanStatusData = {
    type: 'ExpirePlanStatusData',
    heading:'Plan Expired.',
    button: 'Renew Plan',
    buttonLink:'/profile?modal=true&redirectTo=home',
		bannerText: 'Renew your plan to have access to exclusive research data and to continue trading at a discounted price.',
		image: StartBannerUnderProcess,
		blueText: '',
		helpText: '',
		errorMessage: ''
  }

  export const BeforeExpirePlanStatusData = {
    type: 'BeforeExpirePlanStatusData',
    heading:'Subscription is going to expire soon',
    button: 'Renew Plan',
    buttonLink:'/profile?modal=true&redirectTo=home',
		bannerText: 'Renew your plan to have access to exclusive research data and to continue trading at a discounted price.',
		image: StartBannerUnderProcess,
		blueText: '',
		helpText: '',
		errorMessage: ''
  }
  export const UpgradePlanStatusData = {
    type: 'UpgradePlanStatusData',
    heading: 'Upgrade Plan',
    button: 'Upgrade Plan',
    buttonLink:'/profile?modal=true&redirectTo=home',
		bannerText: 'Get access to exclusive research data and trade at a discounted price.',
		image: StartBannerUnderProcess,
		blueText: '',
		helpText: '',
		errorMessage: ''
  }

  export const NoPlanStatusData = {
    type: 'NoPlanStatusData',
    heading: 'No Plan',
		blueText: 'Your have not subscribed yet.',
    button: 'Make Payment',
    buttonLink:'/profile?modal=true&redirectTo=home',
		bannerText:
			'Please make the payment to avail all the features',
		image: StartBannerUnderProcess,
		helpText: '',
		errorMessage: ''
	}

  export const DocumentPending = {
    type: 'DocumentPending',
    button: 'Complete KYC',
    buttonLink:'/verifyidentity',
		bannerText:'We are currently enhancing our Manual KYC experience. Your patience is appreciated.',
			// 'Please complete your KYC. Make sure the proofs match the information that you have given during registration.',
		image: StartBannerApplicationBack,
		helpText:
			'If you need any help, please write to us at contactMail',
		blueText: '',
		errorMessage: ''
  }
  export const DocumentPendingIND = {
    type: 'DocumentPending',
    button: 'Complete KYC',
    buttonLink:'/verifyidentity',
		bannerText:
			 'Please complete your KYC. Make sure the proofs match the information that you have given during registration.',
		image: StartBannerApplicationBack,
		helpText:
			'If you need any help, please write to us at contactMail',
		blueText: '',
		errorMessage: ''
  }

  export const DocumentOldPending = {
    type: 'DocumentOldPending',
    button: 'Complete KYC',
    buttonLink:'/verifyidentity',
		bannerText:
			 'Your KYC is pending. Please complete your KYC to start investing',
		image: StartBannerApplicationBack,
		helpText:
			'',
		blueText: '',
		errorMessage: ''
  }

  export const KYCPending = {
    type: 'KYCPending',
    heading:'Your KYC is Pending',
    button: 'Complete Onboarding',
    buttonLink:'/accountsetup',
		bannerText: 'Please complete the onboarding to start investing right away.',
		image: StartBannerApplicationBack,
		// helpText:
		// 	'If you need any help please write to us at contactMail',
    // blueText: 'Your application is under process.',
		errorMessage: ''
  }


  export const FewMoreDetailsPending = {
    // type: 'FewMoreDetailsPending',
    // button: 'Few more details',
    // buttonLink:'/signup',
		// bannerText:
		// 	'Please fill all the details',
		// image: StartBannerApplicationBack,
		// helpText:
		// 	'If you need any help please write to us at contactMail',
		// blueText: '',
    // errorMessage: ''


    type: 'FewMoreDetailsPending',
    heading:'Your KYC is Pending',
    button: 'Complete KYC',
    buttonLink:'/updatekyc',
		bannerText: 'Please complete your KYC process in order to start investing.',
		image: StartBannerApplicationBack,
		// helpText:
		// 	'If you need any help please write to us at contactMail',
    // blueText: 'Your application is under process.',
		errorMessage: ''
  }

  // export const DocumentRejected = {
  //   button: 'Upload documents',
  //   buttonLink:'/document',
	// 	bannerText:
	// 		'Please re-upload your ID proof, Address proof. Make sure the proofs match with information what you have given during registration.',
	// 	image: StartBannerApplicationBack,
	// 	helpText:
	// 		'If you need any help please write to us at contactMail',
	// 	blueText: '',
	// 	errorMessage: 'We’re sorry. your application is sent back.'
  // }

  export const KYCRejected = {
    type: 'KYCRejected',
    button: 'KYC',
    buttonLink:'/profile',
		bannerText:
    'Please fill all the details and Make sure the proofs match the information that you have given during registration.',
		image: StartBannerApplicationBack,
		helpText:
			'If you need any help, please write to us at contactMail',
		blueText: '',
		errorMessage: 'We’re sorry, your application is sent back.'
  }

  export const PoorPhotoQuality = {
    type: 'DocumentRejected',
    button: 'Complete KYC',
    buttonLink:'/profile',
		bannerText:
			'Please re-upload a better image-quality KYC document.',
		image: StartBannerApplicationBack,
		helpText:
			'If you need any help, please write to us at contactMail',
		blueText: '',
		errorMessage: 'We’re sorry, your application is sent back.'
  }

  export const InvalidDoc = {
    type: 'DocumentRejected',
    button: 'Complete KYC',
    buttonLink:'/profile',
		bannerText:
			'Please re-upload the correct KYC document.',
		image: StartBannerApplicationBack,
		helpText:
			'If you need any help, please write to us at contactMail',
		blueText: '',
		errorMessage: 'We’re sorry, your application is sent back.'
  }

  export const NameDobNotMatch = {
    type: 'DocumentRejected',
    button: 'Complete KYC',
    buttonLink:'/profile',
		bannerText:
			"Please update your Name and DOB or re-upload the correct KYC document that matches the applicant's details.",
		image: StartBannerApplicationBack,
		helpText:
			'If you need any help, please write to us at contactMail',
		blueText: '',
		errorMessage: 'We’re sorry, your application is sent back.'
  }

  export const NameNotMatch = {
    type: 'DocumentRejected',
    button: 'Complete KYC',
    buttonLink:'/profile',
		bannerText:
		"Please update your Name or re-upload the correct KYC document that matches the applicant's details.",
		image: StartBannerApplicationBack,
		helpText:
			'If you need any help, please write to us at contactMail',
		blueText: '',
		errorMessage: 'We’re sorry, your application is sent back.'
  }

  export const DobNotMatch = {
    type: 'DocumentRejected',
    button: 'Complete KYC',
    buttonLink:'/profile',
		bannerText:
		"Please update your DOB or re-upload the correct KYC document that matches the applicant's details.",
		image: StartBannerApplicationBack,
		helpText:
			'If you need any help, please write to us at contactMail',
		blueText: '',
		errorMessage: 'We’re sorry, your application is sent back.'
  }
