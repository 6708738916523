import React, { useEffect, useRef, useState } from 'react'
import downArrow from '../../../public/images/downArrow.svg'
import searchIcon from '../../../public/images/searchIconDropdown.svg'
import clearIcon from '../../../public/images/clearIcon.svg'
import styles from './SelectDropDown.module.css'

const SelectDropDown = ({
	selectVal,
	setSelectVal,
	dropDownlist,
	placeholder = 'Please select',
	selectLabelIcon = '',
	disabled = false,
	noOption = false,
	searchBox = true, // searchBox is optional
	error
}) => {
	const [selectIcon, setSelectIcon] = useState(null)
	const [visible, setVisible] = useState(false)
	const [searchValue, setSearchValue] = useState('')
	const [filteredOptionList, setFilteredOptionList] = useState(dropDownlist)
	const dropDownRef=useRef()

	useEffect(()=>{
		function handleClickOutside(event) {
			if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
			  setVisible(false)
			}
		  }
		  document.addEventListener("mousedown", handleClickOutside)

		  return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		  }
	},[])
	
	useEffect(()=>{
		setFilteredOptionList(dropDownlist)
	},[dropDownlist])

	useEffect(()=>{
		if(!selectIcon && selectVal){
			for(let i =0;i<filteredOptionList.length;i++){
				if(filteredOptionList[i].title===selectVal){
					setSelectIcon(filteredOptionList[i].imgUrl)
					break
				}
			}
		}
	},[selectVal])

	const toggle = () => {
		setVisible(prev => !prev)
	}

	const handleChange = e => {
		let target = e.target
		while (target.getAttribute('name') != 'option-wrapper') {
			if (target.getAttribute('name') === 'option-component') {
				break
			}
			target = target.parentNode
		}
		setSelectVal(target.innerText)
		!!target.getAttribute('imgUrl') &&
			setSelectIcon(target.getAttribute('imgUrl'))
		toggle()
	}

	const handleSearch = e => {
		const target = e.target
		const value = target.value.toLowerCase()
		setSearchValue(value)
		let filteredArray = dropDownlist.filter(option =>
			option.title.toLowerCase().includes(value)
		)
		setFilteredOptionList(filteredArray)
	}

	const handleClear = (e) => {
		searchValue && setFilteredOptionList(dropDownlist)
		searchValue && setSearchValue('')
	}

	return (
		<div className={styles.selectDropDownContainer} ref={dropDownRef}>
			<div className={`${styles.select} ${!error&&visible&&styles.inputBorder} ${error && styles.errorInput} ${error&&visible&&styles.errorInputVisible} ${(disabled||noOption)&&styles.disabled}`} onClick={!disabled && !noOption && toggle}>
				<div className={styles.selectWrapper}>
					{(selectIcon || selectLabelIcon)&& (
						<div className={styles.selectIcon}>
							<img src={selectIcon?selectIcon:selectLabelIcon} />
						</div>
					)}
					<div
						className={`${styles.selectValue} ${
							!selectVal ? styles.placeholder : ''
						}`}>
						{!!selectVal ? selectVal : placeholder}
					</div>
					{!noOption && <div
						className={`${styles.downArrow} ${!!visible ? styles.rotate : ''}`}>
						<img src={downArrow} />
					</div>}
				</div>
			</div>
			<div className={`${styles.dropDown} ${!!visible ? styles.visible : ''}`}>
				<div className={`${styles.searchBox} ${!searchBox ? styles.hide : ''}`}>
					<div className={styles.searchInputWrapper}>
						<div className={styles.searchIcon}>
							<img src={searchIcon} />
						</div>
						<input
							className={styles.searchInput}
							placeholder='Bank Name'
							onChange={handleSearch}
							value={searchValue}
						/>
						<div className={styles.clearIcon} onClick={handleClear}>
							<img src={clearIcon} />
						</div>
					</div>
				</div>
				<div
					name='option-wrapper'
					className={styles.optionList}
					onClick={handleChange}>
					{!!filteredOptionList &&
						filteredOptionList.length > 0 &&
						filteredOptionList.map((option, idx) => {
							return (
								<div
									key={idx}
									id={`selectOption-${idx}`}
									className={styles.option}
									optionIndex={idx}
									imgUrl={!!option.imgUrl ? option.imgUrl : ''}
									name='option-component'>
									{!!option.imgUrl && (
										<div className={styles.optIcon} name='option-logo'>
											<img src={option.imgUrl} name='option-logo-img' />
										</div>
									)}

									{option.title}
								</div>
							)
						})}
					{!!filteredOptionList && filteredOptionList.length == 0 && (
						<div className={styles.noResult}>No Result Found</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default SelectDropDown
