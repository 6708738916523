import React, { useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Col } from 'react-bootstrap'
import Slider from 'react-slick'
import Switch from "react-switch";
import {
  Header,
  StockDetails,
  StockFinancial,
  StockDetailGraph,
  StockSeeMore,
  StockReview,
  TrendingStocksCard,
  Footer,
  BuyCard,
  ReviewOrderCard,
  CostDetailCard,
  IndividualStack,
  StackCard,
  MobileBuyButton,
  SideBar,
  ExtendedDisclosureModal,
  ExtendedCard
} from '../../components'
import StockNews from '../../components/StockNews/StockNews'
import MobileStockAbout from '../../components/StockAbout/MobileStockAbout'
import $ from 'jquery'
import styles from './stockdetail.module.css'
import {
  getSimilarStock,
  getLikedStacks,
  getStockDetails,
  addInWatchlist,
  getLivePrice,
  getShareFundamentals,
  getUserPortfolioDetail,
  getAccountSummary,
  getAnalystRatings,
  watchListDataAPI,
  deleteWatchListTicker,
  getStocksInfo,
  getAccountInfo,
  getHighlights
} from '../../api'
import { getMarketDetails } from '../../store/actions'
import StockHeadingSkeleton from './StockHeadingSkeleton'
import watchlistStar from '../../public/images/watchlistStar.svg'
import watchlisticon from '../../public/images/bookmarkOutline.svg'
import watchlisticonfilled from '../../public/images/bookmarkFilled.svg'
import thunderImage from '../../public/images/thunder.svg'
import addedStarImage from '../../public/images/added.svg'
import { PORTFOLIO_LIVE_PRICE_INTERVAL, STOCK_PAGE } from '../../constants'
import EmptyPage from '../../components/EmptyPage/EmptyPage'
import { useHistory } from 'react-router-dom'
import CustomModal from '../../components/CustomModal/CustomModal'
import { getCurrentAccountStatusLink } from '../../common'
import InvestmentDescision from '../../components/StockSeeMore/InvestmentDescision'
import StockHighlights from '../../components/StockDetail/StockHighlights';
import { ThemeContext } from '../../Context/ThemeContext';
import StockBanner from './StockBanner';

const StockDetail = props => {
  const dispatchAction = useDispatch()
  const [stockName, setStockName] = useState(props.match &&
    props.match.params &&
    props.match.params.code &&
    props.match.params.code)
  const [review, setReview] = useState(false)
  const [similarData, setSimilarData] = useState([])
  const [likedData, setLikedData] = useState([])
  const [stockData, setStockData] = useState([])
  const [stockInfo, setStockInfo] = useState([])
  const [loading, setloading] = useState(true)
  const [livePrice, setLivePrice] = useState({})
  const [shareData, setSharedData] = useState({})
  const [stockLivePrices, setStockLivePrices] = useState({})
  const [accountSummary, setAccountSummary] = useState({})
  const [analystData, setAnalystData] = useState({})
  const [inWatchList, setInwatchList] = useState(false)
  const [watchList, setWatchList] = useState([])
  const [hover, setHover] = useState(false)
  const [showModal, setShowModal] = useState(false)
  // const [exclusive, setExclusive] = useState()
  const [premiumFeatures, setPremiumFeatures] = useState([])
  const [activePlan, setActivePlan] = useState()
  const [toggel, setToogel] = useState('analyst')
  const [starRotate, setStarRotate] = useState(false)
  const [slideeffect, setslideeffect] = useState(false)
  const [stockNotFound, setStockNotFound] = useState(false)
  const [showExtended,setShowExtended] = useState(false)
  const [showDisclouser,setShowDisclouser] = useState(false)
  const [acceptExtendedDisclosure,setacceptExtendedDisclosure] = useState(false)
  const [userLoading,setUserLoading]= useState(true)
  const [isOtc, setIsOtc] = useState(false)
  const [highlightsData, setHighlightsData] = useState({})
  const [disableBuyButton, setDisbaleBuyButton] = useState(false);
  const [disableSellButton, setDisbaleSellButton] = useState(false);
  const [isBerkshire, setIsBerkshire] = useState(false);
  const { siteFeatures } = useContext(ThemeContext)

  useEffect(() => {
    if(stockName === "BRK.A"){
      setIsBerkshire(true);
    }else{
      setIsBerkshire(false);
    }
  },[stockName])
  
  const handleClose = () => setShowModal(false)
  const handleShow = () => {
    setShowModal(true)
  }
  const handleEffect = () => {
    setslideeffect(prevState => setslideeffect(!prevState))
  }
  let history = useHistory()

  const redirectProfile = async() => {
    //logic to redirect user based on account status
    const link = await getCurrentAccountStatusLink();
    history.push(link)
  }

  const getProfile = async() => {
    const userInfores = await getAccountInfo();
    if (userInfores && userInfores.statusDetails){
      sessionStorage.setItem('userInfo', JSON.stringify(userInfores));
      setUserLoading(false)
    }
    if(userInfores && userInfores.userRestriction){
      setDisbaleBuyButton(userInfores && userInfores.userRestriction ? userInfores.userRestriction.stock_trade_buy : false)
      setDisbaleSellButton(userInfores && userInfores.userRestriction ? userInfores.userRestriction.stock_trade_sell : false)
    }
  }
  
  useEffect(() => {
  	let sessionProfileData=''
		sessionProfileData = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : "";
		if(sessionProfileData  === ''){
			getProfile()
		}else{
      setUserLoading(false)
      setDisbaleBuyButton(sessionProfileData && sessionProfileData.userRestriction ? sessionProfileData.userRestriction.stock_trade_buy : false)
      setDisbaleSellButton(sessionProfileData && sessionProfileData.userRestriction ? sessionProfileData.userRestriction.stock_trade_sell : false)
    }
  },[])

  useEffect(() => {
    $(window).scroll(function() {
      let scrollPos = parseInt(
        window.pageYOffset || document.documentElement.scrollTop
      )
      let boxHeight = parseInt($('.floatingSell > div').height())
      let scrollStop = parseInt($('#scrollStopDiv').position().top) - boxHeight - 100
      if (scrollPos > scrollStop) {
        $('.floatingSell').css({ position: 'absolute', top: scrollStop })
      } else if (scrollPos < 100) {
        $('.floatingSell').css({ position: 'relative', top: 0 })
      } else {
        $('.floatingSell').css({ position: 'fixed', top: 70 })
      }
    })
  }, [])

  useEffect(() => () => $(window).unbind('scroll'), [])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [stockName])

  const asynchFetch = async () => {
    try {
      const [similar, liked, data, info, live, shared, highlightsData, list] = await Promise.all([
        getSimilarStock(stockName),
        getLikedStacks(stockName),
        getStockDetails(stockName),
        getStocksInfo(stockName),
        getLivePrice(stockName),
        getShareFundamentals(stockName),
        getHighlights(stockName),
        // getAnalystRatings(stockName),
        watchListDataAPI()
      ])

      setSimilarData(await getSimilarStock(stockName))
      setLikedData(liked)
      setStockData(data && { ...data })
      setIsOtc(data && data.isOTC)
      setHighlightsData(highlightsData)
      setStockInfo(info && { ...info })
      stockName && live && live[stockName] && setLivePrice(live[stockName])
      setSharedData(shared)
      // analyst && setAnalystData(analyst)
      setWatchList(list)
      if((data.isOTC === false && data.listed === false && shared === 'No holdings available.') || data.stockType === 'etf' || data.stockType === 'stack'){
          setStockNotFound(true)
      }else{
        setloading(false)
      }
    } catch (error) {
      if (error.code === 500) {
        setStockNotFound(true)
      }
    }
  }

  useEffect(() => {
    stockName && watchList && watchList.map((item, i) => {
      item && item.code && item.code === stockName && setInwatchList(true)
    })
  }, [watchList, stockName])


  useEffect(() => {
    setStockName(props.match &&
      props.match.params &&
      props.match.params.code &&
      props.match.params.code)

  }, [props.match &&
  props.match.params &&
  props.match.params.code &&
  props.match.params.code])

  useEffect(()=>{
    setShowExtended(false,setShowDisclouser(false))
    setInwatchList(false, asynchFetch())
  },[stockName])

  const addToWatchlist = async () => {
    if (!inWatchList) {
      const response = await addInWatchlist(stockName)
      response === 200 && setStarRotate(false, setInwatchList(true))
    } else {
      const res = await deleteWatchListTicker(stockName)
      res.code === 200 && setStarRotate(false, setInwatchList(false))
    }
  }

  const state = useSelector(state => state)
  const {
    marketDetails: { marketDetails: { currentMarketStatus = {} } = {} } = {}
  } = state
  Object.keys(state.marketDetails).length === 0 &&
  dispatchAction(getMarketDetails())

  const fetchAccountSummary = async () => {
    const response = await getAccountSummary()
    setAccountSummary(response)
  }

  useEffect(() => {
    fetchAccountSummary()

    const setLivePrice = async () => {
      try {
        let livePrices = await getLivePrice(stockName)
        setStockLivePrices(livePrices[stockName])
      } catch (err) {
        console.log('error fetching live prices')
      }
    }
    setLivePrice()

    JSON.parse(sessionStorage.getItem('planInfo')) && JSON.parse(sessionStorage.getItem('planInfo')).details && JSON.parse(sessionStorage.getItem('planInfo')).details.premiumFeatures ? setPremiumFeatures(JSON.parse(sessionStorage.getItem('planInfo')).details.premiumFeatures) : setPremiumFeatures([])
    JSON.parse(sessionStorage.getItem('planInfo')) && JSON.parse(sessionStorage.getItem('planInfo')).planStatus
      ? JSON.parse(sessionStorage.getItem('planInfo')).planStatus === 'active'
      ? setActivePlan(JSON.parse(sessionStorage.getItem('planInfo')).planStatus)
      : setActivePlan('expire')
      : setActivePlan('expire')

    const priceInterval = setInterval(() => {
      if (currentMarketStatus === 'Open' || currentMarketStatus === 'Pre-Market' ||  currentMarketStatus === 'Post-Market') {
        setLivePrice()
      }
    }, PORTFOLIO_LIVE_PRICE_INTERVAL)
    return () => {
      clearInterval(priceInterval)
    }

  }, [currentMarketStatus, stockName])

  var settings = {
    dots: false,
    className: 'center',
    autoplaySpeed: 3000,
    infinite: true,
    centerMode: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding:'0px'
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding:'28%'
        }
      },
      {
        breakpoint:480,
        settings: {
          slidesToShow:1,
          slidesToScroll:1,
          centerMode:true,
          centerPadding:'25%'
        }
      },
      {
        breakpoint:425,
        settings: {
          slidesToShow:1,
          slidesToScroll:1,
          centerMode:true,
          centerPadding:'20%'
        }
      },
      {
        breakpoint:375,
        settings: {
          slidesToShow:1,
          slidesToScroll:1,
          centerMode:true,
          centerPadding:'40px'
        }
      },
    ]
  }
  var settings2 = {
    dots: false,
    className: 'center',
    autoplaySpeed: 3000,
    infinite: true,
    centerMode: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding:'10px'
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding:'20%'
        }
      },
      {
        breakpoint:480,
        settings: {
          slidesToShow:1,
          slidesToScroll:1,
          centerMode:true,
          centerPadding:'20%'
        }
      },
      {
        breakpoint:425,
        settings: {
          slidesToShow:1,
          slidesToScroll:1,
          centerMode:true,
          centerPadding:'15%'
        }
      },
      {
        breakpoint:375,
        settings: {
          slidesToShow:1,
          slidesToScroll:1,
          centerMode:true,
          centerPadding:'20px'
        }
      },
    ]
  }

  return (
    <>
      <Header />
      <Container className={slideeffect ? styles.shiftPos : styles.originalPos}>
        {stockNotFound ? <EmptyPage type={STOCK_PAGE} /> :
          <>
            <Row>
            {(loading || userLoading) ? (
                  <StockHeadingSkeleton />
                ) : (
                  <React.Fragment>
                    {isBerkshire ? 
                    <Col lg={12}>
                      <StockBanner/>
                    </Col> : ""}
                    <Col lg={7} xl={8}>
                        <Row className='d-block d-lg-none' style={{ marginTop: '24px', marginBottom: '24px' }}>
                            <Col style={{ textAlign: 'end', whiteSpace: 'nowrap' }} className={styles.watchlistalignment}>
                              <div
                                style={{ width: '178px', maxWidth: '178px', display: 'inline-block', textAlign: 'center' }}
                                className={styles.watchlist}
                                onClick={() => setStarRotate(true, addToWatchlist())}
                              >
                                <img src={inWatchList ? watchlisticonfilled : watchlisticon} style={{width:"20px"}}/>
                                <span className={styles.watchlistText}>{inWatchList ? 'Added to Watchlist' : 'Add to watchlist'}</span>
                              </div>
                            </Col>
                        </Row>
                      <Row className={styles.stockDetailsResponsive}>
                        <Col>
                          <StockDetails
                            isOtc={isOtc}
                            stockName={stockName}
                            livePrices={stockLivePrices}
                            starRotate={starRotate}
                            setStarRotate={setStarRotate}
                            addToWatchlist={addToWatchlist}
                            inWatchList={inWatchList}
                            addedStarImage={addedStarImage}
                            watchlistStar={watchlistStar}
                            />
                        </Col>
                      </Row>
                      {/* <Row className='mt-5 d-none d-lg-block'>
                        <Col>
                          <StockDetailGraph code={stockName} isOtc={isOtc}/>
                        </Col>
                      </Row> */}
                      <Row style={{margin:"0px"}}>
                        <StockHighlights highlightsData={highlightsData} isOtc={isOtc} stockName={stockName} isEtf={false}/>
                      </Row>
                      <hr className={`${styles.horizantalLine} ${styles.marginLine}`} />
                      <Row
                        style={{ display: shareData === 'No holdings available.' || shareData === 'error fetching live prices' ? 'none' : 'block' }}
                        className={styles.marginCost}>
                        <Col>
                          <CostDetailCard
                            data={shareData}
                            stockName={stockName}
                            livePrice={livePrice && livePrice.lastTrade && livePrice.lastTrade}
                            accountSummary={accountSummary}
                          ></CostDetailCard>
                        </Col>
                      </Row>
                      <hr style={{ display: shareData === 'No holdings available.' ? 'none' : 'block' }}
                          className={`${styles.horizantalLine} ${styles.marginCost}`} />


                  {siteFeatures && siteFeatures.includes("analystConsensusAndInsiderTransactions") ?
                  <>
                    <InvestmentDescision
                    analystData={analystData}
                    stockName={stockName}
                    premiumFeatures={premiumFeatures}
                    activePlan={activePlan}/>
                    <hr className={`${styles.horizantalLine} ${styles.investmentLine}`} />
                  </> : ""}

                  <Row className='d-block d-lg-none'>
                    <Col>
                      <MobileStockAbout stockName={stockName}
                                        stockData={stockData && stockData} />
                    </Col>
                  </Row>
                  <hr className={`d-block d-lg-none mb-4 ${styles.horizantalLine} ${styles.marginLine}`} />
                  {siteFeatures && siteFeatures.includes("stockFundamentals") ?
                  <Row style={{ marginTop: shareData === 'No holdings available.' ? '40px' : '0px' }}>
                    <Col>
                      <StockReview
                        stockName={stockName}
                        stockData={{
                          ...stockInfo,
                          description:(stockData?stockData.description:null),
                          '52_week_high':(stockData?stockData.yearHigh:null),
                          '52_week_low':(stockData?stockData.yearLow:null),
                        }}
                        livePrice={stockLivePrices}
                      />
                    </Col>
                  </Row> : ""}
                  {siteFeatures && siteFeatures.includes("stockFinancialStats") ?
                  <Row className={styles.finacialstats}>
                    <Col>
                      <StockFinancial code={stockName} />
                    </Col>
                  </Row> : ""}
                  <Row className='mt-5 d-none d-lg-block'
                      style={{ display: stockData ? stockData.themes ? 'block' : 'none' : 'none' }}>
                    <Col>
                      <IndividualStack
                        themes={stockData && stockData.themes && stockData.themes}
                      />
                    </Col>
                  </Row>
                  {siteFeatures && siteFeatures.includes("stockEarningPerShare") ?
                  <Row style={{ marginTop: '34px' }} className="pb-5">
                    <Col>
                      <StockSeeMore stockName={stockName} analystData={analystData} premiumFeatures={premiumFeatures}
                                    activePlan={activePlan} />
                    </Col>
                  </Row> : ""}
                  <Row className={`d-block d-lg-none ${styles.themeDiv}`}
                      style={{ display: stockData ? stockData.themes ? 'block' : 'none' : 'none' }}>
                    <Col>
                      <IndividualStack
                        themes={stockData && stockData.themes && stockData.themes}
                      />
                    </Col>
                  </Row>
                  {siteFeatures && siteFeatures.includes("stockNews") ?
                  <Row className='d-block'>
                    <Col>
                      <StockNews stockName={stockName} />
                    </Col>
                  </Row> : ""}
                  {/* <Row className='d-block d-lg-none'>
                    <Col>
                      <StockFinancial code={stockName} />
                    </Col>
                  </Row> */}
                    </Col>
                    <Col lg={5} xl={4} className='d-none d-lg-block'>
                        {/* create extended hour  */}
                        <ExtendedCard
                        isOtc={isOtc}
                        showExtended={showExtended}
                        setShowExtended={setShowExtended}
                        setShowDisclouser={setShowDisclouser}
                        />
                      {/* <Row className={styles.responsiveRow}>
                        <Col md={5} className={`${styles.priceResponsive}`}
                            onMouseEnter={() => activePlan === 'active' ? !exclusive && setHover(true) : setHover(true)}
                            onMouseLeave={() => activePlan === 'active' ? !exclusive && setTimeout(() => {
                              setHover(false)
                            }, 800) : setTimeout(() => {
                              setHover(false)
                            }, 800)}
                            onClick={() => activePlan === 'active' && exclusive && handleShow()}
                        >
                          <div className={styles.thunderDiv}>
                            <img src={thunderImage} className={styles.blob} />
                            <span style={{ whiteSpace: 'nowrap' }} className={styles.price}>Price Target</span>
                          </div>
                        </Col>
                        <Col>
                          <div
                            style={{ width: '178px', maxWidth: '178px', display: 'inline-block', textAlign: 'center' }}
                            className={styles.watchlist}
                            onClick={() => setStarRotate(true, addToWatchlist())}
                          >
                            <img className={starRotate ? styles.rotate : null}
                                src={inWatchList ? addedStarImage : watchlistStar} />
                            <span
                              className={styles.watchlistText}>{inWatchList ? 'Added to Watchlist' : 'Add to watchlist'}</span>
                          </div>
                        </Col>
                        {hover ? <div className={styles.customTooltip}>
                          <div className={styles.customTooltipArrow}>
                          </div>
                          <Row style={{ textAlign: 'center', margin: '0px', paddingLeft: '20px', paddingTop: '20px' }}>
                            <Col md={11} style={{ padding: '0px' }}>
                              <div className={styles.customTooltipText}>
                                Access Price target, Analyst ratings and In-depth research by industry experts
                              </div>
                              <div className={styles.icon} onClick={redirectProfile} style={{ cursor: 'pointer' }}>
                                <button className={`btn btn-warning ${styles.customTooltipButtonText}`}>UPGRADE PLAN</button>
                              </div>
                            </Col>
                          </Row>
                        </div> : null}
                      </Row> */}
                      <div className='floatingSell'>
                        <BuyCard
                          // setReviewFunc={setReview}
                          isOtc={isOtc}
                          stockName={stockName}
                          livePrices={stockLivePrices}
                          accountSummary={accountSummary}
                          stockData={stockData}
                          shareData={shareData}
                          marketState={currentMarketStatus}
                          showExtended={showExtended} 
                          disableBuyButton={disableBuyButton} 
                          disableSellButton={disableSellButton}
                          isBerkshire={isBerkshire}
                          />
                      </div>
                      {/* )} */}
                    </Col>
                </React.Fragment>
                )}
            </Row>
            <div id='scrollStopDiv'></div>
            <hr className={`${styles.horizantalLine} ${styles.similarMargin}`} />
            <Row>
              <Col className={styles.similarResponsive}>
                {siteFeatures && siteFeatures.includes("similarStocks") ?
                <>
                <Row
                  className={styles.similarDiv}
                  style={{
                    display: similarData && similarData.length ? 'block' : 'none'
                  }}>
                  <div className={styles.heading2}>Similar Stocks</div>
                </Row>
                <Row
                  className={`d-none d-lg-flex ${styles.responsiveSimilar}`}
                  style={{
                    display: similarData && similarData.length ? 'flex' : 'none'
                  }}>
                  {similarData &&
                  similarData.slice(0, 4).map((item, index) => {
                    return (
                      <Col>
                        <TrendingStocksCard
                          stockImage={item.logo}
                          stockTitle={item.code}
                          stockName={item.company}
                          stockValue={item.price}
                          stockIncrement={item.percentChange}
                          close={item.price - item.change}
                          stockChange={item.change}
                          increment={
                            item.percentChange > 0 ? true : false
                          }></TrendingStocksCard>
                      </Col>

                    )
                  })}
                </Row>
                <Row className='d-block d-lg-none' style={{ marginTop: '16px' }}>
                  <Col>
                    <Slider {...settings} className={styles.trendingSlider}>
                      {similarData &&
                      similarData.slice(0, 4).map((item, index) => {
                        return (
                          <TrendingStocksCard
                            stockImage={item.logo}
                            stockTitle={item.code}
                            stockName={item.company}
                            stockValue={item.price}
                            stockIncrement={item.percentChange}
                            close={item.price - item.change}
                            stockChange={item.change}
                            increment={
                              item.percentChange > 0 ? true : false
                            }></TrendingStocksCard>
                        )
                      })}
                    </Slider>
                  </Col>
                </Row>
                </> : ""}
                <Row
                  className={styles.cardrowMargin}
                  style={{
                    display: likedData && likedData.length ? 'block' : 'none'
                  }}>
                  <div className={styles.heading}>
                    Stacks you may like
                  </div>
                </Row>
                <Row
                  className={styles.subHeading}
                  style={{
                    display: likedData && likedData.length ? 'block' : 'none'
                  }}>
                  <div>Expert made model portfolios</div>
                </Row>
                <Row
                  className={`d-none d-md-none d-lg-none d-xl-flex ${styles.cardtopMargin}`}
                  style={{
                    marginBottom: '60px',
                    display: likedData && likedData.length ? 'flex' : 'none'
                  }}>
                  {likedData &&
                  likedData.slice(0, 4).map((item, index) => {
                    return (
                      <Col key={index} xs={9} lg={3} xl={3}>
                        <StackCard
                          image={item.logo}
                          ticker={item.ticker}
                          createdBy={item.createdByTitle}
                          desc={item.description}
                          cagr={item.cagr}
                          minInvest={item.minimumInvestment}
                        />
                      </Col>
                    )
                  })}
                </Row>
                <Row
                  className={`d-none d-md-none d-lg-flex d-xl-none ${styles.cardtopMargin}`}
                  style={{
                    marginBottom: '60px',
                    display: likedData && likedData.length ? 'flex' : 'none'
                  }}>
                  {likedData &&
                  likedData.slice(0, 3).map((item, index) => {
                    return (
                      <Col key={index} xs={9} lg={4}>
                        <StackCard
                          image={item.logo}
                          ticker={item.ticker}
                          createdBy={item.createdByTitle}
                          desc={item.description}
                          cagr={item.cagr}
                          minInvest={item.minimumInvestment}
                        />
                      </Col>
                    )
                  })}
                </Row>
                <Row className='d-block d-lg-none' style={{ marginTop: '16px' }}>
                  <Col>
                  {likedData && likedData.length < 2 ?
                  likedData && likedData.map((item, index) => {
                    return (
                      <Col sm={6} md={5} className='p-0'>
                        <StackCard
                          image={item.logo}
                          ticker={item.ticker}
                          createdBy={item.createdByTitle}
                          desc={item.description}
                          cagr={item.cagr}
                          minInvest={item.minimumInvestment}
                        />
                      </Col>
                    )
                  }) :
                  <Slider {...settings2} className={styles.trendingSlider}>
                      {likedData &&
                      likedData.slice(0, 4).map((item, index) => {
                        return (
                          <StackCard
                            image={item.logo}
                            ticker={item.ticker}
                            createdBy={item.createdByTitle}
                            desc={item.description}
                            cagr={item.cagr}
                            minInvest={item.minimumInvestment}
                          />
                        )
                      })}
                    </Slider>}

                  </Col>
                </Row>
                <CustomModal show={showModal} handleClose={handleClose}>
                  <Row style={{ borderBottom: '1px solid #EFEFF1' }}>
                    <Col xs={5} lg={3}>
                      <div className={toggel === 'analyst' ? styles.active : styles.Inactive}
                           onClick={() => setToogel('analyst')}>
                        Analyst consensus
                      </div>
                    </Col>
                    <Col>
                      <div className={toggel === 'insider' ? styles.active : styles.Inactive}
                           onClick={() => setToogel('insider')}>
                        Insider Transactions
                      </div>
                    </Col>
                  </Row>
                  <iframe
                    src={toggel === 'analyst' ? `https://widgets.tipranks.com/content/stockal/analystratingslato/analysts-widget.html?ticker=${stockName}` : `https://widgets.tipranks.com/content/stockal/insiderslato/insiders-widget.html?ticker=${stockName}`
                    }
                    frameBorder='0'
                    id='rankIframe'
                    style={{ height: 'calc(100vh - 50px)', width: `calc(100vw - 450px)`, marginTop: '4px' }}
                  />
                </CustomModal>
              </Col>
            </Row>
            <Row className={`d-flex d-lg-none ${styles.buttonresponsive}`}>
              <Col style={{ padding: '0px' }}>
                <MobileBuyButton
                  // setReviewFunc={setReview}
                  isOtc={isOtc}
                  stockName={stockName}
                  livePrices={stockLivePrices}
                  accountSummary={accountSummary}
                  stockData={stockData}
                  shareData={shareData}
                  marketState={currentMarketStatus}
                 />
              </Col>
            </Row>
          </>}
      </Container>
      <div className={styles.sideBarMobileHide}>
        <SideBar handleEffect={handleEffect} change={inWatchList} pageType={'detail'} setInwatchList={setInwatchList} />
      </div>

      {/*  extended hour disclouser modal */}
      <ExtendedDisclosureModal
      showDisclouser={showDisclouser}
      setShowDisclouser={setShowDisclouser}
      setShowExtended={setShowExtended}
      acceptExtendedDisclosure={acceptExtendedDisclosure}
      setacceptExtendedDisclosure={setacceptExtendedDisclosure}
      />
    </>
  )
}

export default StockDetail
