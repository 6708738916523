import React from 'react'
import { Card, Row, Button } from 'react-bootstrap'
import styles from './DidYouKnowUpgrade.module.css'

const didYouKnowUpgrade = () => {
	return (
		<Card className={styles.card}>
			<Card.Body className={styles.cardPadding}>
				<Row className={styles.didKnowText}>Did you know?</Row>
				<Row className={styles.note}>
					You can save 30% more on trading by upgrading to gold account
				</Row>
				<Row className='m-0 mt-4'>
					<Button className={styles.upgradeButton}>Upgrade to Gold</Button>
				</Row>
			</Card.Body>
		</Card>
	)
}

export default didYouKnowUpgrade
