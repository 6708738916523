import React,{useContext,useState,useEffect,useRef} from 'react'
import {Card, Col,Row} from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import OutsideClickHandler from 'react-outside-click-handler'
import { getCountryCallingCode } from 'react-phone-number-input/input'
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber
} from 'react-phone-number-input'
import { setUserInfoSession } from '../../common'
import styles from './SignUp.module.css'
import NewHeader from '../NewSignupComponents/NavBar/NewHeader'
import { logout, getPartnerCountries,geoLocation,onBoardingUpdateUser} from '../../api'
import OnboardingFooter from '../Footer/OnboardingFooter'
import SignUpInput from './SignUpInput'
import SignUpDropDown from './SignUpDropDown'
import DropDownButton from './DropDownButton'
import SignUpButton from './SignUpButton'
import { ThemeContext } from "../../Context/ThemeContext"
import Logo from '../../public/images/newlogo.svg'


const UpdateKYC = () => {
  const phoneRef = useRef()
  const [phoneCountryCode,setPhoneCountryCode] = useState('')
  const [phoneCountry,setPhoneCountry] = useState('')
  const [phoneNumber,setPhoneNumber] = useState('')
  const [phoneNumberValidation,setPhoneNumberValidation]=useState('')
  const [showDropDown,setShowDropDown]=useState(false)
  const [showDropDownCountry,setShowDropDownCountry]=useState(false)
  const [phoneFlagCountry,setPhoneFlagCountry] = useState('')
  const [showLabelPhoneNumber,setShowLabelPhoneNumber] = useState(false)
  const [activeCountries,setActiveCountries] = useState([])
  const [countryError,setCountryError] = useState('')
  const [flagCode,setFlagCode] = useState('')
  const [country,setCountry] = useState('')
  const [countryCode,setCountryCode]= useState('')
  const [loading,setLoading] = useState(false)
  const [errorMessage,setErrorMessage] = useState('')

  const { isPartner } = useContext(ThemeContext);
  let history = useHistory()
  const showPoweredLogo =
  isPartner && !window.location.href.includes('landing-page')


  const {username:userName,nextStep} = JSON.parse(sessionStorage.getItem('userInfo'))

  useEffect(() => {
    if(nextStep && nextStep === 'FEW_MORE_DETAILS'){
      getActiveCountryCitizenship()
      getCurrentCountryCode()
    }else{
      history.push('/home')
     }
  },[])


const getActiveCountryCitizenship = async () => {
    const flagArray = []
    getPartnerCountries('residence').then(response => {
      if (response && response.code === 200) {
        const list = response.data;
        list.map(item => {
          if(item){
          const temp = {
            value: item.country,
            label: item.countryName,
            code: item.country,
            code2: item.countryCode2,
          }
            flagArray.push(temp)
        }
          else{
            console.info("item--",item)
          }

        })
        setActiveCountries([...flagArray])
      }
    })
  }

 const getCurrentCountryCode = () => {
    geoLocation().then(response => {
      if (response && response.code === 200 && response.data) {
        const {country_code = '',name = ''} = response.data
        setPhoneCountry(name,setPhoneFlagCountry(country_code, calculateCallingCode(country_code)))
        setCountry(name,setCountryCode(country_code,setFlagCode(country_code)))
      }
    })
  }


  const onClickLogout = () => {
		var token = JSON.parse(sessionStorage.getItem('token'))
		//webengage logout
		// eslint-disable-next-line no-undef
		webengage.user.logout()
		// delete token.refreshToken
		var body = {
			accessToken: token && token.accessToken
		}
		// var accessToken = JSON.stringify(token)
		logout(JSON.stringify(body)).then(response => {
			sessionStorage.clear()
			localStorage.clear()
			history.push('/')
		})
	}

  const onPhoneNumberHandler = (event) => {
    var numberRegex = /^\s*[+-]?(\d+|\d*\.\d+|\d+\.\d*)([Ee][+-]?\d+)?\s*$/
    const checkNumber =numberRegex.test(event.target.value.slice(-1))
    if(event.target.value.length >= phoneCountryCode.length){
      if(phoneNumber.length > event.target.value.length){
        setPhoneNumber(event.target.value,setPhoneNumberValidation(''))
      }else if(checkNumber){
        setPhoneNumber(event.target.value,setPhoneNumberValidation(''))
      }
    }
  }

  const calculateCallingCode = (country) => {
    const countryCode = getCountryCallingCode(country)
    const countryNumber = `+${countryCode} `
    setPhoneNumber(countryNumber,setPhoneCountryCode(countryNumber))
  }

  const onSubmit = async () => {
    if(phoneNumber.length === phoneCountryCode.length){
      setPhoneNumberValidation('We need your contact number to register your account')
      if(country === '' ){
        setCountryError('Country of residence is required')
      }
    }else{
      if (
        isValidPhoneNumber(phoneNumber) &&
        isPossiblePhoneNumber(phoneNumber.trim())
      ) {
        setLoading(true)
        const filter = activeCountries.filter((item) => item.label === country)
        const countryCode2 =  filter[0].code
        try{
          const data = {
            username: userName,
            countryOfResidence: countryCode2,
            phone: phoneNumber,
            nextStep: 'DOCUMENTS'
          }
          const res = await onBoardingUpdateUser(data)
          if(res.status && res.status === 200){
            const sessionData = {
              country: countryCode2,
              phoneHome: phoneNumber,
              nextStep: 'DOCUMENTS'
            }
            setUserInfoSession(sessionData)
            // eslint-disable-next-line no-undef
            webengage.user.setAttribute('we_phone',phoneNumber);
            history.push('/verifyidentity')
          }
          setLoading(false)
        }catch(error){
            setErrorMessage(error.message,setLoading(false))
        }
      }else{
        setPhoneNumberValidation('Enter Valid phone number')
      }
    }
  }


  return(
    <>
      <NewHeader
        message='' linkName='Log Out' onClickLink={onClickLogout}
      />
      <div className={styles.cardDiv}>
      <Card className={styles.cardSignUp}>
        <Card.Body className={styles.cardBodySignup}>
            <Row className={styles.title}>Update your account</Row>
            <Row className={styles.subTitle}>
              Verify your country of residence and contact number for the username associated with your account.
            </Row>
            <Row className={styles.assignUser}>
              <Col className='p-0'>
                <div className={styles.assignUserText}>Assigned Login Username</div>
                <div className={styles.subTitle} style={{color:'#3C404A'}}>{userName}</div>
              </Col>
            </Row>
            <Row className={styles.inputs} style={{display:'block'}}>
                  <div className={styles.label}>Country of Residence</div>
                   <div>
                      <DropDownButton
                         onClick={() => setShowDropDownCountry(!showDropDownCountry)}
                         showDropDown={showDropDownCountry}
                         error={countryError}
                         >
                          <img className={styles.flagImgCode} src={`${process.env.REACT_APP_ASSESTS}/flags/${flagCode}.svg`}></img>
                          <span className={styles.countryLabel}>{country}</span>
                      </DropDownButton>
                      <OutsideClickHandler onOutsideClick={() => setShowDropDownCountry(false)}>
                      <SignUpDropDown
                         data={activeCountries}
                         value={country}
                         showDropDown={showDropDownCountry}
                         onChange={(item) => {
                          const getCountryCode = item.value
                          setCountry(item.label, setFlagCode(item.code2.toUpperCase(),
                            setCountryCode(getCountryCode,
                              setShowDropDownCountry(false,
                                ))))
                           }}
                         placeholder='Country'
                      />
                      </OutsideClickHandler>
                    </div>
                    <div className={styles.errorState}>{countryError}</div>
                </Row>
            <Row className={`${styles.inputs} d-block`}>
               <div className={styles.label}>Contact Number</div>
                 <div className={styles.phoneNumberDropdown}
                 style={ phoneNumberValidation
                 ? {border:'1.5px solid #FA274A'} : {}}>
                      <DropDownButton
                         classes={styles.phoneButton}
                         onClick={() => setShowDropDown(!showDropDown)}
                         showDropDown={showDropDown}
                         >
                          <img className={styles.flagImgCode} src={`${process.env.REACT_APP_ASSESTS}/flags/${phoneFlagCountry}.svg`}></img>
                      </DropDownButton>
                      <SignUpInput
                          containerClasses={styles.phoneContainer}
                          classes={styles.phoneInputBorder}
                          // placeholder = 'Contact Number'
                          type='text'
                          name='phoneNumber'
                          refValue={phoneRef}
                          value={phoneNumber}
                          onChange={event => onPhoneNumberHandler(event)}
                          onPaste={(e) => {
                            e.preventDefault()
                            return false;
                          }} onCopy={(e) => {
                            e.preventDefault()
                            return false;
                          }}
                          // onBlur={handleBlur}
                          onFocus={() => setShowLabelPhoneNumber(true)}
                      />
                      <div
                      className={!showLabelPhoneNumber ?  styles.phoneNumberLabel : 'd-none' }
                      onClick={() => setShowLabelPhoneNumber(true,phoneRef.current.focus())}>Enter Contact Number</div>
                    <OutsideClickHandler onOutsideClick={() => setShowDropDown(false)} display='contents'>
                     <SignUpDropDown
                         classes={styles.phoneNumberList}
                         data={activeCountries}
                         value={phoneCountry}
                         showDropDown={showDropDown}
                         onChange={(item) => {
                          setPhoneCountry(item.label,
                            setPhoneFlagCountry(item.code2,
                              setShowDropDown(false)),
                              calculateCallingCode(item.code2))
                           }}
                         placeholder='Country'
                      />
                     </OutsideClickHandler>
                  </div>
                  <div className={styles.errorState}>
                    {phoneNumberValidation}
                  </div>
              </Row>
              <Row className={styles.buttonMargin}>
              <div className={styles.errorState} style={{paddingBottom:'10px'}}>{errorMessage}</div>
                  <SignUpButton
                    title='Update Account'
                    onClick={() => onSubmit()}
                    loading={loading}
                  />
              </Row>
        </Card.Body>
      </Card>
      </div>
      <div
				style={{
					marginTop: '50px',
					display: 'flex',
					justifyContent: 'center'
				}}>
				{showPoweredLogo ? (
					<Col
						md={4}
						xl={4}
						lg={4}
						style={{ textAlign: 'center' }}
					//	className='d-none d-md-block'
            >
						<span className={styles.poweredText}>Powered by</span>
						<img src={Logo} alt='powered by' width='64px' height='16px' />
					</Col>
				) : (
					<Col md={2} xl={3} className={`d-none d-md-flex`}></Col>
				)}
			</div>
			<OnboardingFooter />
    </>
  )
}

export default UpdateKYC
