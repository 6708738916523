import React, { Fragment } from 'react';
import { Row, Col } from 'react-bootstrap'
import noteImage from '../../public/images/note.png'
import styles from './instruction.module.css'
import {circleA, circleB, circleC, circleD, circleE, circleF, circleG} from './assets'
import Copy from './copy'
import DownloadInstruction from './downloadInstruction';
import TableError from './tableError'

const IndusindLRS= ({info, fetchBrokerDetails}) => {
	const cloudfront = `${process.env.REACT_APP_ASSESTS}/platform/fund/indusind`
	return(
		<>
			<Row>
				<Col>
					<div className={styles.bankName}>Induslnd Bank</div>
				</Col>
				<Col style={{ textAlign: 'end'}} className='pl-0'>
					<DownloadInstruction bank={'INDUSIND_BANK'}/>
				</Col>
				<Col md={12} style={{ marginTop: '30px',marginBottom:"30px" }}>
					<div className={styles.transactionCard}>
						<img src={noteImage} />
						<span className={styles.transaction}>
							There is a limit of $25,000 on online transaction per day. If you
							need to remit more, please use Offline Transfer.
						</span>
					</div>
				</Col>
			</Row>		
			<Row>
				<div className={`card-body ${styles.cardBody} ${styles.responsiveInstructions}`}>
					<ol className={styles.list} >
						<li>
							<div className={styles.netbanking}>
								Login to your Induslnd Bank Netbanking Account  by clicking on below link:
							</div>
							<div className={styles.login}>
								<a href='https://indusnet.indusind.com/corp/BANKAWAY?Action.RetUser.Init.001=Y&AppSignonBankId=234&AppType=corporate&CorporateSignonLangId=001' target="_blank" rel="noopener noreferrer">
									{`Login to IndusInd Netbanking >`}
								</a>
							</div>
						</li>
						<li> 
							<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
								Visit IndusForex and click on &nbsp;
								<img src={circleA} className={styles.circularPointer} /> 
								<span className={styles.key}>Send Money Abroad</span>
							</div>
							<div className={styles.marginStepSecondary}>
								<img src={`${cloudfront}/img1.png`} className={styles.responsiveImage}/>
							</div>
						</li>				
						<li> 
							<div className={`d-flex flex-column align-items-start ${styles.netbanking} ${styles.marginStepPrimary}`}>
								Select Currency as &nbsp;
								<div className={styles.netbanking}>
									<img src={circleA} className={styles.circularPointer} /> 
									<span className={styles.key}>United States Dollar.</span> 
								</div>
								<div className={styles.netbanking}>
									<img src={circleB} className={styles.circularPointer} /> 
									Enter amount in USD and click on									
									<span className={styles.key}> Get Rate.</span> 
								</div>
								<div className={styles.netbanking}>
									<img src={circleC} className={styles.circularPointer} /> 
									Click on									
									<span className={styles.key}> Proceed.</span> 
								</div>
								<div className={styles.marginStepSecondary}>
									<img src={`${cloudfront}/img2.png`} className={styles.responsiveImage}/>
								</div>
							</div>
						</li>
						<li> 
							<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
								Enter your Mobile Number, click on &nbsp;
								<img src={circleA} className={styles.circularPointer} /> 
								<span className={styles.key}>Verify.</span>
							</div>
							<div className={styles.marginStepSecondary}>
								<img src={`${cloudfront}/img3.png`} className={styles.responsiveImage}/>
							</div>
						</li>
						<li> 
							<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
								Accept the Terms & Conditions, enter OTP and click on &nbsp;
								<img src={circleA} className={styles.circularPointer} /> 
								<span className={styles.key}>Verify.</span>
							</div>
							<div className={styles.marginStepSecondary}>
								<img src={`${cloudfront}/img4.png`} className={styles.responsiveImage}/>
							</div>
						</li>
						<li> 
							<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
								You have to verify your personal details and click on &nbsp;
								<img src={circleA} className={styles.circularPointer} /> 
								<span className={styles.key}>Proceed.</span>
							</div>
							<div className={styles.marginStepSecondary}>
								<img src={`${cloudfront}/img5.png`} className={styles.responsiveImage}/>
							</div>
						</li>
						<li>
							<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
								In Beneficiary details &nbsp;
								<img src={circleA} className={styles.circularPointer} /> 
								Select Purpose of Transaction as
								<span className={styles.key}> Equity Investment PIS. </span>
								<img src={circleB} className={styles.circularPointer} /> 
								Select Wealth Manager as
								<span className={styles.key}> Stockal.</span>
							</div>
							<div className={styles.marginStepSecondary}>
								<img src={`${cloudfront}/img6.png`} className={styles.responsiveImage}/>
							</div>
						</li>
						<li> 
							<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
								Enter the details mentioned under the next point in the prescribed colums:
							</div>
							<div className={styles.marginStepSecondary}>
								<img src={`${cloudfront}/img7.png`} className={styles.responsiveImage}/>
							</div>
						</li>						
						<li>
							<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
								Following are the Beneficiary details.
							</div>
							<div style={{ overflow:'hidden'}} className={`${styles.beneficiaryTable} ${styles.marginStepSecondary} ${!info&&styles.beneficiaryTableError}`}>
								{!info ? <TableError fetchBrokerDetails={fetchBrokerDetails}/>
									:<Fragment>	
										<div  className={styles.beneficiaryTableRow}>
											<img src={circleA} className={styles.circularPointer} />
											<div className={styles.beneficiaryTableContent}>
												<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>										
													Beneficiary Name:
												</div>
												<div className={styles.beneficiaryTableValue}>
													<span>{info.name}</span>
													<Copy data={{label:'Beneficiary Name', value:info.name}}/>
												</div>
											</div>
										</div>
										<div  className={styles.beneficiaryTableRow}>
											<img src={circleB} className={styles.circularPointer} />
											<div className={styles.beneficiaryTableContent}>
												<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>										
													Beneficiary Address:
												</div>
												<div className={styles.beneficiaryTableValue}>
													<span>{info.address}</span>
													<Copy data={{label:'Beneficiary Address', value:info.address}} />
												</div>
											</div>
										</div>
										<div className={`${styles.beneficiaryAccNum} ${styles.beneficiaryTableRow}`}>
											<img src={circleC} className={styles.circularPointer} />
											<div className={styles.beneficiaryTableContent}>
												<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
													Beneficiary Account Number:
												</div>
												<div className={styles.beneficiaryAccNumValue}>
													<div className={styles.beneficiaryTableValue}>
														<span>{info.accountNumber}</span>
														<Copy data={{label:'Beneficiary Account Number', value:info.accountNumber}} />
													</div>
													<div className={styles.beneficiaryAccInfo}>Everyone has an Unique Beneficiary Account Number.</div>										
												</div>
											</div>
										</div>
										<div  className={styles.beneficiaryTableRow}>
											<img src={circleD} className={styles.circularPointer} />
											<div className={styles.beneficiaryTableContent}>
												<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>										
													Beneficiary SWIFT Code:
												</div>
												<div className={styles.beneficiaryTableValue}>
													<span>{info.SWIFTCode}</span>
													<Copy data={{label:'Beneficiary SWIFT Code', value:info.SWIFTCode}} />
												</div>
											</div>
										</div>
										<div  className={styles.beneficiaryTableRow}>
											<img src={circleE} className={styles.circularPointer} />
											<div className={styles.beneficiaryTableContent}>
												<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>										
													Beneficiary Bank Address
												</div>
												<div className={styles.beneficiaryTableValue}>
													<span>{info.bankAddress}</span>
													<Copy data={{label:'Beneficiary Bank Address', value:info.bankAddress}} />
												</div>
											</div>
										</div>
										<div  className={styles.beneficiaryTableRow}>
											<img src={circleF} className={styles.circularPointer} />
											<div className={styles.beneficiaryTableContent}>
												<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>										
													Beneficiary Bank Name:
												</div>
												<div className={styles.beneficiaryTableValue}>
													<span>{info.bankName}</span>
													<Copy data={{label:'Beneficiary Bank Name', value:info.bankName}} />
												</div>
											</div>
										</div>
										<div  className={styles.beneficiaryTableRow}>
											<img src={circleG} className={styles.circularPointer} />
											<div className={styles.beneficiaryTableContent}>
												<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>										
													Sort/ABA/BSB/Transit No:
												</div>
												<div className={styles.beneficiaryTableValue}>
													<span>{info.ABANumber}</span>
													<Copy data={{label:'Sort/ABA/BSB/Transit No', value:info.ABANumber}} />
												</div>
											</div>
										</div>
									</Fragment>
								}
							</div>
						</li>
						<li>
							<div className={`d-flex flex-column align-items-start ${styles.netbanking} ${styles.marginStepPrimary}`}>
								Fill the following details.
								<div className={styles.netbanking}>
									<img src={circleA} className={styles.circularPointer} /> 
									Additional information is 
									<span className={styles.key}> {info.accountNumber}</span> 
								</div>
								<div className={styles.netbanking}>
									<img src={circleB} className={styles.circularPointer} /> 
									Name a company where investment is made for example 									
									<span className={styles.key}> Apple Inc.</span> 
								</div>
								<div className={styles.netbanking}>
									<img src={circleC} className={styles.circularPointer} /> 
									Listed in any Stock Exchange, select 									
									<span className={styles.key}> YES/NO.</span> 
								</div>
								<div className={styles.netbanking}>
									<img src={circleD} className={styles.circularPointer} /> 
									Stock Exchange name  									
									<span className={styles.key}> NYSE.</span> 
								</div>
								<div className={styles.netbanking}>
									<img src={circleE} className={styles.circularPointer} /> 
									Click on							
									<span className={styles.key}> Proceed.</span> 
								</div>
								<div className={styles.marginStepSecondary}>
									<img src={`${cloudfront}/img8.png`} className={styles.responsiveImage}/>
								</div>
							</div>
						</li>
						<li>
							<div className={`d-flex flex-column align-items-start ${styles.netbanking} ${styles.marginStepPrimary}`}>
								<div className={styles.netbanking}>
									After Adding Beneficiary details.
								</div>
								<div className={styles.netbanking}>
									Select FEMA Declaration and Accept all Terms & Conditions:
								</div>
								<div className={styles.netbanking}>
									<img src={circleA} className={styles.circularPointer} /> 
									Select
									<span className={styles.key}> FEMA Declaration.</span> 
								</div>
								<div className={styles.netbanking}>
									<img src={circleB} className={styles.circularPointer} /> 
									Tick the box to 									
									<span className={styles.key}> Accept all Terms & Conditions.</span> 
								</div>
								<div className={styles.netbanking}>
									<img src={circleC} className={styles.circularPointer} /> 
									Click on								
									<span className={styles.key}> Agree.</span> 
								</div>
								<div className={styles.marginStepSecondary}>
									<img src={`${cloudfront}/img9.png`} className={styles.responsiveImage}/>
								</div>
							</div>
						</li>
						<li>
							<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
								Enter coupon code
								<span className={styles.key}> “WIRE500” </span> 
								and click on &nbsp;
								<img src={circleA} className={styles.circularPointer} />
								<span className={styles.key}>Apply </span>
								for a discount of INR 500. &nbsp;
								<img src={circleB} className={styles.circularPointer} />
								Click on
								<span className={styles.key}> Proceed.</span>
							</div>
							<div className={styles.marginStepSecondary}>
								<img src={`${cloudfront}/img10.png`} className={styles.responsiveImage}/>
							</div>
						</li>
						<li>
							<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
								Select
								<span className={styles.key}> “Payment” </span> 
								and click on &nbsp;
								<img src={circleA} className={styles.circularPointer} />
								<span className={styles.key}>Confirm Order & Pay.</span> 
							</div>
							<div className={styles.marginStepSecondary}>
								<img src={`${cloudfront}/img11.png`} className={styles.responsiveImage}/>
							</div>
						</li>					
						<li>
							<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
								Select Payment Mode and complete the payment.
							</div>
							<div className={styles.marginStepSecondary}>
								<img src={`${cloudfront}/img12.png`} className={styles.responsiveImage}/>
							</div>
						</li>
						<li>
							<div className={`d-flex flex-column align-items-start ${styles.netbanking} ${styles.marginStepPrimary}`}>
								<div className={styles.netbanking}>
									The process to Remit the Funds is now complete! 
								</div>
								<div className={styles.netbanking}>
									Please note that it takes
									<span className={styles.key}> 2-3 Business Days </span>									 
									after the Deduction of Funds from your Bank Account for it to reflect in your Investment Account.									
								</div>
							</div>
						</li>
					</ol>					
				</div>
			</Row>

		</>
	)
}

export default IndusindLRS;
