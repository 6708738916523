import React from 'react'
import styles from './IndividualStack.module.css'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from "react-router-dom";

const IndividualStack = ({ themes = [] }) => {

  const themeSelection = (i) => {
    let x = encodeURIComponent(i);
    window.location.href = `/theme/${x}`
  }

  const themeData = themes.map((item, i) => {
    return (
      <Col xs='auto' key={i} className={styles.themeColumn} lg='auto'>
        <span
          onClick={() => themeSelection(item)}
          // onClick={() =>{
          // window.location.href = `/theme/${item}`}}
          className={styles.themeText}
        >
          {item}
        </span>
      </Col>
    )
  })
  return (
    <>
      <Row style={{ display: themeData.length ? 'block' : 'none' }}>
        <Col className={styles.themeTitle}>Themes</Col>
      </Row>
      <Row
        lg={6}
        className={styles.themeDiv}
        style={{ display: themeData.length ? 'flex' : 'none' }}>
        {themeData}
      </Row>
    </>
  )
}
export default IndividualStack
