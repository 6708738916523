import React from 'react'
import { Table, Row, Container } from 'react-bootstrap'
import styles from './PortFolioTable.module.css'
import TableRow from './TableRow'
import { CORPORATE_FUNDS } from '../../constants'

const PortFolioTable = ({ title, rowHeaders, data }) => {
	return (
		<>
			{/* <Row className={`mb-3 ${styles.tableTitle}`}>{title}</Row> */}
			<Table responsive borderless className={styles.dataTable}>
				<thead style={{ display: data && data.length ? 'contents' : 'none' }}>
					<tr>
						<th
							className={styles.tableborder}
							style={{
								paddingRight: '0px',
								paddingLeft: '0px'
								// position: 'static',
								// background: 'blue'
							}}></th>
						{rowHeaders.map((item, index) => {
							return (
								<th key={index} className={styles.tableHeader}>
									{item}
								</th>
							)
						})}
					</tr>
				</thead>
				<tbody>
					{data && data.length > 0 ? (
						data.map((item, index) => {
							return (
                <TableRow
                key={index}
                item={item}
                index={index}
                rowHeaders={rowHeaders}
              />
							)
						})
					) : (
						<div
							style={{
								fontWeight: '500',
								fontSize: '20px',
								padding: '10px',
								marginLeft: '0px',
								paddingLeft: '0px',
								whiteSpace: 'nowrap'
							}}>
								<span>{`No ${title} Found`}</span>
								{title==="Funds" && <>
									&nbsp;-&nbsp;
									<a href={CORPORATE_FUNDS} target='_blank' rel='noopener noreferrer'>Explore Funds</a>
								</>
								}</div>
					)}
				</tbody>
			</Table>
		</>
	)
}

export default PortFolioTable
