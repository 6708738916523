import React from 'react'
import { Modal } from 'react-bootstrap'
import styles from './NotificationModal.module.css'
import closeIcon from '../../public/images/closeIcon.svg'
const NotificationModal = ({ show, handleClose, children }) => {
	return (
		<Modal
			show={show}
			onHide={handleClose}
			className={styles.modalRight}
			dialogClassName={styles.modalDialog}
			scrollable>
			<Modal.Header className={styles.modalHeader}></Modal.Header>
			<Modal.Body className={styles.modalPadding}>{children}</Modal.Body>
			<div className={styles.crossImageStyling}>
				<img className={styles.CrossWidth} src={closeIcon} onClick={() => handleClose()} alt="close-icon"/>
			</div>
		</Modal>
	)
}

export default NotificationModal
