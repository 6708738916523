import React, { useEffect, useState, useContext } from 'react'
import { init } from 'onfido-sdk-ui';
import { useHistory } from 'react-router-dom'
import { createApplicant, getSdkToken, identityInitiate, updatedAccount,logout,LeadCapture,identityDocIndian,uploadDocumentApi } from '../../../api'
import AlertError from '../../AlertError/AlertError'
import { ThemeContext } from '../../../Context/ThemeContext'
import styles from './Document.module.css'
import { setUserInfoSession } from '../../../common';
import IDSelection from '../IDSelection'
import DocumentSkeleton from '../../../skeletons/DocumentSkeleton'
import NewHeader from '../../NewSignupComponents/NavBar/NewHeader'
import KycScreen from './IndDocument/kycScreen'
import UploadDocument from './UploadDocument/UploadDocument';
import { set, get } from 'idb-keyval';
import VerifyPan from './IndDocument/VerifyPan';

const Document = props => {
  const [serverError, setServerError] = useState('')
  const { buttonColor,leadSqCompID,location, partnerConfig } = useContext(ThemeContext)
  const [loading, setLoading] = useState(false)
  // let userData = {}
  // if(props.location && props.location.state){
  //   userData = props.location.state
  // }else{
  const userData = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : ''
  // }
  const [email] = useState(userData.emailAddress1 ? userData.emailAddress1 : '')
  const [countryOfResidence] = useState(userData.country ? userData.country : 'IND')
  const [phoneNumber] = useState(userData.phoneHome ? userData.phoneHome : '')
  const [showSelection, setShowSelection] = useState('flex')
  const [showAddress, setShowAddress] = useState('none')
  const [showAddressPan,setShowAddressPan] = useState(false)
  const [showUpload,setShowUpload]= useState(false)
  const [documentTitle,setDocumentTitle] = useState('');
  const [showBackOfID, setShowBackOfID] = useState(false);
  const [documentSecletedType, setDocumnetSelectedType] = useState();
  const [buttonLoader,setButtonLoader] = useState(false);
  const [panCard,setPanCard] = useState(false);
  const [documentType, setDocumentType] = useState();
  const [ifMenaIndiaSelect, setIfMenaIndiaSelect] = useState(false);
  const [partnerConfigAddressProofRequired, setPartnerConfigAddressProofRequired]= useState('required');
  const [configList,setConfigList] = useState([]);

  useEffect(() => {
    if(partnerConfig !== undefined){
      let data = partnerConfig?.[0]?.documents
      const addressProofDoc = data && data.find((item) => item.label === "addressProof");
      const idProofList = data && data.find((item) => item.label === "identityProof");
      const isDocAvailable = addressProofDoc ? addressProofDoc.active : true;
      setPartnerConfigAddressProofRequired(isDocAvailable === false ? 'not Required' : 'required');
      setConfigList(idProofList);
    }
  },[partnerConfig])

//   let history = useHistory()
//   useEffect(() => {
//     window.history.pushState(null, document.title, window.location.href);
//     window.addEventListener('popstate', function (event) {
//       window.history.pushState(null, document.title, window.location.href);
//     });
//     // make sure to block unwanted direct page load
//     if (userData && (userData.nextStep === "DOCUMENTS_OLD" || userData.nextStep === "DOCUMENTS" || userData.nextStep === "PAN_DETAILS"))
//     {
//      return
//     }
//     else
//       history.push('/home');
//   },[history, userData])

//   const uploadedData = async (data) => {
//     updatedAccount({ nextStep: 'PERSONAL_DETAILS' })
//     .then(res => {
//       if (res.code === 200) {
//         const details = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : '';
//         // eslint-disable-next-line no-undef
//         webengage.user.login(details.username);
//         // eslint-disable-next-line no-undef
//         webengage.track("KYC upload completed");

//         setUserInfoSession({ nextStep: 'PERSONAL_DETAILS' })
//         props.history.push('/accountsetup', { data, applicantId: sessionStorage.getItem('oaid') })
//       }
//       else {
//         // eslint-disable-next-line no-undef
//         webengage.track("KYC Document Upload Failed", {
//           "Reason": "Api Error"
//         });
//       }
//     })
//     .catch(error => {
//       // eslint-disable-next-line no-undef
//       webengage.track("KYC Document Upload Failed", {
//         "Reason": "Api Error"
//       });
//       console.log(error)
//     })
//   }

  // when selecting the type of document this function runs
  const onNonIndianSelection = (doc) => {
    setDocumnetSelectedType(doc.title)
    setShowSelection('none')
    setDocumentType(doc.type)
    if(doc.type === "INDIA_KYC"){
      setIfMenaIndiaSelect(true)
      return
    }
    if(doc.title === "Passport"){
      setShowUpload(false)
      setShowBackOfID(true)
    }else{
      setShowUpload(true)
      setShowBackOfID(false)
    }
    // setShowUpload(true)
    setDocumentTitle(`Upload Front of ${doc.title}`)
    setLoading(true)
    try{
    setLoading(false)
    }catch(error){
    }
  }

  // const onNonIndianSelection = (doc) => {
  //   setDocumnetSelectedType(doc.title)
  //   setShowSelection('none')
  //   if(doc.title === "Passport"){
  //     setShowUpload(false)
  //     setShowBackOfID(true)
  //   }else{
  //     setShowUpload(true)
  //     setShowBackOfID(false)
  //   }
  //   // setShowUpload(true)
  //   setDocumentTitle(`Upload Front of ${doc.title}`)
  //   setLoading(true)
  //   try{
  //   setLoading(false)
  //   }catch(error){
  //   }
  // }

  const onNationalId = async() => {
    setButtonLoader(true, setShowSelection('none'),setShowUpload(false))
    try{
      const resp = await onUploadDocument("ID")
      if(resp){
        setShowUpload(false)
        setLoading(false)
        setButtonLoader(false)
        if(partnerConfigAddressProofRequired === "required"){
          setDocumentTitle(`Upload Address Proof`)
        }
      }else{
        setLoading(false)
        setButtonLoader(false)
      }
    }catch(error){
      setLoading(false)
      setButtonLoader(false)
    }
  }

  // const onNationalId = async() => {
  //   setButtonLoader(true, setShowSelection('none'),setShowUpload(false))
  //   try{

  //     const resp = await uploadDocumentApi()
  //     // if(resp){
  //       // setShowUpload(false)
  //       setDocumentTitle(`Upload Address Proof`)
  //       setLoading(false)
  //       setButtonLoader(false)
  //     // }
  //   }catch(error){
  //     setLoading(false)
  //     setButtonLoader(false)
  //   }
  // }

  const onAddressId = async() => {
    setButtonLoader(true, setShowSelection('none'),setShowUpload(false))
    setDocumentType('OTHER')
    try{
     let res = await onUploadDocument("ADDRESS")
     if(res){
      // uploadedData()
      setLoading(false)
      setButtonLoader(false)
      setShowUpload(false)
      props.setShowSuccessView(true);
    }
    }catch(error){
      setLoading(false)
      setButtonLoader(false)
    }
  }

  // const onAddressId = async() => {
  //   setButtonLoader(true, setShowSelection('none'),setShowUpload(false))
  //   try{
  //     let res = await onUploadDocument("ADDRESS")
  //     if(res == true)
  //   //  uploadedData()
  //    setLoading(false)
  //   }catch(error){
  //     setLoading(false)
  //     setButtonLoader(false)
  //   }
  // }

  const onUploadDocument = async (proofType) => {
    setButtonLoader(true);
    try {
        const body = new FormData();
        const frontImage = await get('front');
        const backImage = await get('back');
        body.append('front', frontImage);
        if (documentType !== "PASSPORT" && proofType !== "ADDRESS") {
          body.append('back', backImage);
        }
        body.append('type', proofType === "ADDRESS" ?  "OTHER" : documentType);
        body.append('proofType', proofType);
        const res = await uploadDocumentApi(body);
        if (res && res.message === 'success') {
            setButtonLoader(false);
            return true;
        } else {
            setServerError('Something Went wrong. Please Try Again!');
            setButtonLoader(false);
            return false;
        }
    } catch (error) {
        setServerError('Something Went wrong. Please Try Again!');
        setButtonLoader(false);
        return false;
    }
}

  // const onUploadDocument = async (e) => {
  //   setButtonLoader(true)
  //   try{
  //     const body = new FormData();
  //     get('front').then(async(image) => {
  //       body.append('front',image)
  //       get('back').then(async(image) => {
  //         body.append('back',image)
  //         const res = await uploadDocumentApi(body)
  //         if(res && res.data ){
  //           setButtonLoader(false)
  //           if(e === "success"){
  //               props.setShowSuccessView()
  //           }
  //         }else{
  //           setServerError('Something Went wrong. Please Try Again!')
  //           setButtonLoader(false)
  //         }
  //       })
  //     })
  //   }catch(error){
  //     setButtonLoader(false)
  //     setServerError('Something Went wrong. Please Try Again!')
  //   }
  // }

  const frontOfDocument = () => {
    setDocumentTitle(`Upload Back of ${documentSecletedType}`)
    setShowBackOfID(true);
    setShowUpload(false)
  }

//   const onClickLogout = () => {
//     var token = JSON.parse(sessionStorage.getItem('token'))
//     webengage.user.logout()
//     var body = {
//       accessToken: token && token.accessToken
//     }
//     logout(JSON.stringify(body)).then(response => {
//       sessionStorage.clear()
//       localStorage.clear()
//         history.push('/')
//     })
//   }

  return (
    <>
      {serverError === '' ? null : (
        <AlertError color='#E81346' message={serverError} />
      )}
      <div style={{marginTop:'20px'}}>
      {!panCard ?
        loading ? <DocumentSkeleton /> :
          (location === 'India' || location === 'INDIA') || ((location !== "India" || location !== "INDIA")&& ifMenaIndiaSelect)?
          <KycScreen
            setPanCard={setPanCard}
            setDocTitle={setDocumentTitle}
            location={location}
            ifMenaIndiaSelect={ifMenaIndiaSelect}
            setIfMenaIndiaSelect={setIfMenaIndiaSelect}
            setShowUpload={setShowUpload}
            setShowBackOfID={setShowBackOfID}
          />
          :
          <IDSelection onSelection={onNonIndianSelection} display={showSelection} documentType={setDocumentType} configList={configList}/>
          :
          <VerifyPan setShowSuccessView={props.setShowSuccessView}/>
        }
        {showUpload === true && showBackOfID === false ?
          <UploadDocument
          title={documentTitle}
          onSubmit={frontOfDocument}
          loading={buttonLoader}
          /> :
          showUpload === false && showBackOfID === true ?
          <>
          <UploadDocument
            title={documentTitle}
            loading={buttonLoader}
            onSubmit={documentTitle==='Upload Address Proof' ? onAddressId:onNationalId}
          />
          </>
          :''
          }
      </div>
    </>
  )

}

export default Document;
