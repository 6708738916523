import React, { useState, useEffect} from 'react'
import { EmailShareButton } from 'react-share'
import { getCashManagementData } from '../../api'
import { AccountDetailsErrorState } from './TransactionListLoadingStates'
import Copy from '../../components/AddFund/Transfer/Online/copy'
import styles from './TransactionDetailsModal.module.css'
import modalCrossButton from '../../public/images/modalCrossButton.svg'
import shareIcon from '../../public/images/shareIcon.svg'
import supportIcon from '../../public/images/supportIcon.svg'
import CashMgmtToolTip from './Tooltip'


const AccountDetailsModal = (props) => {


    const { onHide } = props

    const [ accountDetails, setAccountDetails ] = useState()
    const [ isLoading, setIsLoading ] = useState(true)
    console.log('accountDetails',accountDetails);
    const setAccountType = () => {
        let accountType

        if(accountDetails) {
            if(accountDetails.stoCashAccount.accountType === 'personalChecking') {
                accountType = 'Personal Checking'
            }
            if(accountDetails.stoCashAccount.accountType === 'personalSavings') {
                accountType = 'Personal Savings'
            }
            if(accountDetails.stoCashAccount.accountType === 'businessChecking') {
                accountType = 'Business Checking'
            }
            if(accountDetails.stoCashAccount.accountType === 'businessSavings') {
                accountType = 'Business Savings'
            }
        }

        return accountType
    }

    const getAddress = (...params) => {
        let address = '';
        params.forEach((param)=> {if(param) address+= param + ', '});
        return address;
    }

    const setBankAddress = (e) => {
        let bankAddress

        if(accountDetails && accountDetails.stoCashAccount.bankAddress && e ==="bank") {

            const {addressLine1, addressLine2, addressLine3, addressCity, addressState, addressCountryCd, addressZipPostalCd} = accountDetails.stoCashAccount.bankAddress


            bankAddress = getAddress(addressLine1, addressLine2, addressLine3) + ` ${addressCity} ${addressZipPostalCd}, ` + getAddress(addressState) + ` ${addressCountryCd}`

        } else if(accountDetails && accountDetails.stoCashAccount.intermediaryBankAddress && e ==="intermediary"){

            const {addressLine1, addressLine2, addressLine3, addressCity, addressState, addressCountryCd, addressZipPostalCd} = accountDetails.stoCashAccount.intermediaryBankAddress


            bankAddress = getAddress(addressLine1, addressLine2, addressLine3) + `${addressState} ${addressCity !== null ? addressCity : ""} ${addressZipPostalCd !== null ? addressZipPostalCd : ""}, `+ ` ${addressCountryCd}`

        }else {
            bankAddress = '--'
        }

        return bankAddress
    }

    const getAccountDetails = () => {
        setIsLoading(true)
        getCashManagementData().then(response => {
            if(response.code === 200){
                setAccountDetails(response.data ? response.data : '')
                setIsLoading(false)
            } else {
                setIsLoading(false)
            }
        }).catch((err)=>{
            console.error(err)
            setIsLoading(false)
        })
    }

    const maskAccountNumber = (accountNumber) => {
        return accountNumber.replace(/\d(?=\d{4})/g, "X")
    }

    useEffect(() => {
        getAccountDetails()
    },[])

    return (
        <div className={styles.modalContainer} style={{minHeight:"528px",display:"flex",alignItems:"center",justifyContent:"center"}}>
            {accountDetails ? (
                <div>
                    <div className={styles.modalHeader} style={{backgroundColor:"#F7F7F8"}}>
                        <div className={styles.transactionStatus}>
                            <p className={`${styles.status} ${styles.textBlack}`}>Account Details</p>
                            <p className={`${styles.modalText}`} style={{display:"flex",alignItems:"center",gap:"6px"}}>
                                <div className={styles.dot} style={{backgroundColor: accountDetails ? accountDetails.stoCashAccount.accountStatus === 'active' ? '#0DC014' : '#FA274A' : ''}}></div>
                                {accountDetails ? accountDetails.stoCashAccount.accountStatus === 'active' ? <span className={styles.textGreen}>Active</span> : <span className={styles.textRed}>Inactive</span> : ''}
                            </p>
                        </div>
                        <img src={modalCrossButton} alt="cross-button" onClick={onHide}></img>
                    </div>
                    <div className={styles.accountInfo} style={{display:"flex",gap:"8px",alignItems:"flex-start"}}>
                        <p className={` ${styles.modalText} ${styles.textGrey}`}>{accountDetails ? `${accountDetails.stoCashAccount.bankName} - ${setAccountType()}`: ''}</p>
                        <p className={` ${styles.modalName} ${styles.textBlack}`}>{accountDetails ? accountDetails.userName : ''}</p>
                        <p className={` ${styles.modalText} ${styles.textBlack}`}>{accountDetails ? accountDetails.phoneNum ? accountDetails.phoneNum: '--' : '--'} &nbsp;<span><a href="/profile">Edit</a></span></p>
                    </div>
                    <div className={styles.accountDetails}>
                        <hr className={styles.divider}/>

                        <div className={styles.modalRow}>
                            <p className={` ${styles.modalText} ${styles.textGrey}`}>Account No.</p>
                            <div className={` ${styles.modalRow} ${styles.modalRowInfo}`}>
                                <p className={` ${styles.modalText} ${styles.textBlack}`}>{accountDetails ? accountDetails.stoCashAccount.accountNum ?  maskAccountNumber(accountDetails.stoCashAccount.accountNum) : '--' : '--'}</p>
                                {accountDetails && accountDetails.stoCashAccount.accountNum ? <Copy isPrimaryIcon={true} data={{label:'Account Number', value:accountDetails.stoCashAccount.accountNum}}/> : ''}
                            </div>
                        </div>

                        <div className={styles.modalRow}>
                            <p className={` ${styles.modalText} ${styles.textGrey}`}>ACH/Wire Routing No.</p>
                            <div className={` ${styles.modalRow} ${styles.modalRowInfo}`}>
                                <p className={` ${styles.modalText} ${styles.textBlack}`}>{accountDetails ? accountDetails.stoCashAccount.routingNum ? accountDetails.stoCashAccount.routingNum : '--' : '--'}</p>
                                {accountDetails && accountDetails.stoCashAccount.routingNum ? <Copy isPrimaryIcon={true} data={{label:'Routing Number', value:accountDetails.stoCashAccount.routingNum}}/> : ''}
                            </div>
                        </div>

                        <div className={styles.modalRow}>
                            <p className={` ${styles.modalText} ${styles.textGrey}`}>SWIFT Code</p>
                            <div className={` ${styles.modalRow} ${styles.modalRowInfo}`}>
                                <p className={` ${styles.modalText} ${styles.textBlack}`}>{accountDetails ? accountDetails.stoCashAccount.bankSwiftCode ? '--' : '--' : '--'}</p>
                                {accountDetails && accountDetails.stoCashAccount.bankSwiftCode ? <Copy isPrimaryIcon={false}/> : ''}
                            </div>
                        </div>

                        <hr className={styles.divider}/>

                        <div>
                            <p className={` ${styles.modalText} ${styles.textGrey}`} style={{paddingBottom:"4px"}}>Bank Address</p>
                            <div className={styles.modalRow} style={{minHeight:"40px"}}>
                                <p className={` ${styles.modalText} ${styles.textBlack}`}>{setBankAddress('bank')}</p>
                                {accountDetails && accountDetails.stoCashAccount.bankAddress ? <Copy isPrimaryIcon={true} data={{label:'Bank Address', value:setBankAddress('bank')}}/> : ''}
                            </div>
                        </div>

                        <hr className={styles.divider}/>

                        <div>
                            <p className={` ${styles.modalText} ${styles.textGrey}`} style={{paddingBottom:"4px"}}>Intermediary Bank</p>
                            <div className={styles.modalRow} style={{minHeight:"40px"}}>
                              <p className={` ${styles.modalText} ${styles.textBlack}`}>{accountDetails.stoCashAccount.intermediaryBankName}</p>
                              <div className={` ${styles.modalRow} ${styles.modalRowInfo}`}>
                              <p className={` ${styles.modalText} ${styles.textBlack}`}>{accountDetails ? accountDetails.stoCashAccount.bankSwiftCode ? accountDetails.stoCashAccount.bankSwiftCode : '--' : '--'}</p>
                              <CashMgmtToolTip content={setBankAddress('intermediary')} toolTipHeading={'Intermediary Bank Address'}/>
                              </div>
                            </div>
                        </div>

                    </div>
                    <div className={`${styles.modalRow} ${styles.modalButtonContainer}`}>
                        <EmailShareButton
                            url=''
                            subject={'Account Details'}
                            body={`Find your account details below :

                                   Account Holder : ${accountDetails.userName}
                                   Account Type : ${setAccountType()}
                                   Bank Name : ${accountDetails.stoCashAccount.bankName}
                                   Phone Number : ${accountDetails.phoneNum}
                                   Account Number : ${accountDetails.stoCashAccount.accountNum}
                                   ACH/ Wire Routing Number : ${accountDetails.stoCashAccount.routingNum}
                                   SWIFT Code: --
                                   Bank Address : ${setBankAddress('bank')}
                                   Intermediary Bank Name: ${accountDetails.stoCashAccount.intermediaryBankName}
                                   Intermediary SWIFT Code: ${accountDetails.stoCashAccount.bankSwiftCode}
                                   Intermediary Bank Address: ${setBankAddress('intermediary')}
                                   `}
                        >
                            <button className={`${styles.modalButton} ${styles.modalButtonBlue}`}>
                                <img src={shareIcon} alt="share-icon"></img>
                                <p className={styles.modalText}>Share Details</p>
                            </button>
                        </EmailShareButton>
                        <a href="mailto:customersuccess@stockal.com" target="_blank" rel="noopener noreferrer">
                            <button className={`${styles.modalButton} ${styles.modalButtonGrey}`}>
                                <img src={supportIcon} alt="support-icon"></img>
                                <p className={styles.modalText}>Contact Support</p>
                            </button>
                        </a>


                    </div>

                </div>
            ) : (
                <AccountDetailsErrorState isLoading={isLoading} fetchData={getAccountDetails} />
            )}
        </div>
    )
}


export default AccountDetailsModal

