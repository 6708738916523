import React, { useEffect, useState,useContext } from 'react'
import { ThemeContext } from '../../Context/ThemeContext'
import styles from './VerifyPan.module.css'
import SignUpButton from '../SignUp/SignUpButton'
import { Row, Card } from 'react-bootstrap'

const ReviewSubmission = ({setCurrent}) => {
	const [ischecked, setIschecked] = useState(false)
  const [showCheckedError,setShowCheckedError] = useState(false)
  const { savingAccountDisclaimer } = useContext(ThemeContext)

  useEffect(() => {
   if(ischecked){
    setShowCheckedError(false)
   }
  },[ischecked])

  const onSubmit = () => {
    if(ischecked){
      setCurrent('success')
    }else{
      setShowCheckedError(true)
    }
  }

	return (
		<div className={styles.cardDiv}>
			<Card className={styles.cardSignUp} style={{ marginTop: '80px' }}>
				<Card.Body className={styles.cardBodySignup}>
					{
						<>
							<Row className={styles.title}>Review Your Submission</Row>
							<div
								className={styles.panNumber}
                style={{marginTop:'32px'}}>
								Disclaimer
							</div>
							<div
								className={styles.content}>
							   <p>Welcome  to    https://www.stockal.com/cash  (the  “Site”),  a  website  of  Borderless  Investing  Inc.,
                   (“Bordeless  Investing”)  ,  a Delaware   corporation (“Stockal”, “we,”, “our” or “us”) and owner of the Stockal platform.
                  This page explains the terms by which you may use the financial services we make available to you on our Site or on our mobile application (collectively the “Services”).
                  <span style={{color:'#0061D3',cursor:'pointer'}}>
                    <a href={savingAccountDisclaimer} target='_blank'>
                    Go to Links
                    </a>
                    </span>
                  </p>
							</div>
							<div style={{marginTop:'16px',marginBottom:'32px'}}>
								<div
									className={`custom-control custom-checkbox ${styles.checkBox}`}>
									<input
										type='checkbox'
										className={`custom-control-input ${styles.checkBoxInput}`}
										id='panCheck'
										name='panCheck'
										value={ischecked}
										onChange={() => setIschecked(!ischecked)}
										checked={ischecked}
									/>
									<label
										className={`custom-control-label ${styles.checkMark}`}
										htmlFor='panCheck'>
                      {!showCheckedError ?
                    <span style={{paddingTop:'2px' }}>
                      I have read all the terms and conditions
                    </span> :
                    <span style={{paddingTop:'2px',color:'#FA274A'}}>
                      You need to accept the Terms & Conditions to sign up
                    </span> }
                  </label>
								</div>

							</div>
							<SignUpButton title='Submit' onClick={() => onSubmit()} />
						</>
					}
				</Card.Body>
			</Card>
		</div>
	)
}

export default ReviewSubmission
