import React, { useState, useContext, useEffect, useRef } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { Formik } from 'formik'
import * as yup from 'yup'
import { ThemeContext } from '../../Context/ThemeContext'
import AlertError from '../AlertError/AlertError'
import { useHistory } from 'react-router-dom'
import { checkHostByPartner } from '../../api'
import SignUpButton from '../SignUp/SignUpButton'
import PasswordHide from '../../public/images/newOnboarding/PasswordHide.svg'
import PasswordShow from '../../public/images/newOnboarding/PasswordShow.svg'
import styles from './SsoSignin.module.css'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import SignUpInput from '../SignUp/SignUpInput'
import { Card } from 'react-bootstrap'

const SsoSignin = props => {
	if (sessionStorage.getItem('currentSignUp'))
		sessionStorage.removeItem('currentSignUp')
	let history = useHistory()
	const { siteLogo, shortLogo, partnerCode } = useContext(ThemeContext)
	const [errormessage, setErrormessage] = useState('')
	const [username, setUsername] = useState('')
	const [passwordType, setPasswordType] = useState('password')
  const [loading,setLoading] = useState(false)
	const editFieldRef = useRef(null)
	useEffect(() => {
		editFieldRef.current.focus()
	}, [])

	const isRedirect = (code, partnerCode) => {
		if (
			(code === 'HDFC' ||
				code === 'HDFCGLOBAL' ||
				code === 'STOCKAL' ||
				code === 'STOCKALGLOBAL') &&
			(partnerCode === 'HDFC' ||
				partnerCode === 'HDFCGLOBAL' ||
				partnerCode === 'STOCKAL' ||
				partnerCode === 'STOCKALGLOBAL')
		) {
			if (
				((partnerCode === 'HDFC' || partnerCode === 'HDFCGLOBAL') &&
					(code === 'HDFCGLOBAL' || code === 'HDFC')) ||
				((partnerCode === 'STOCKAL' || partnerCode === 'STOCKALGLOBAL') &&
					(code === 'STOCKALGLOBAL' || code === 'STOCKAL'))
			)
				return false
		}
		return true
	}

	const onBlurUserName = async username => {
		try {
			if (username) {
				const response = await checkHostByPartner(username)
				if (
					response &&
					response.code &&
					response.code === 200 &&
					response.data
				) {
					if (
						response.data.isPartnerWhiteLabel &&
						response.data.riaId !== partnerCode
					) {
						if (isRedirect(response.data.riaId, partnerCode)) {
							$('#mdlPartner').modal('show')
							setTimeout(function () {
								$('#mdlPartner').modal('hide')
								window.location.href = `${response.data.partnerUrl}/sso-signin?username=${username}&client_id=${props.client_id}&redirect_uri=${props.redirect_uri}&response_type=code`
								// window.location.href = response.data.partnerUrl
							}, 3000)
							return
						}
					}
				} else if (
					response.code === 404 &&
					response.message === 'Username not found'
				)
					setErrormessage('Invalid Credentials')
				else {
					console.error(response)
					setErrormessage('Oops! Something went wrong.')
				}
			}
		} catch (error) {
			console.error(error)
		}
	}

	const keyPressHandler = (event, submitCallback) => {
		if (event.which === 13) {
			submitCallback()
		}
	}

	let formInitialValues = {
		username: username,
		password: ''
	}
	const validationSchema = yup.object({
		username: yup.string().required('User Name is a required field'),
		password: yup.string().required('Password is a required field')
	})
	return (
		<>
			{/* {errormessage === '' ? null : (
				<AlertError color='#E81346' message={errormessage} />
			)} */}
			<div>
				{/* <Row className='justify-content-center'>
					<img
						className={styles.stockalImage}
						src={shortLogo !== '' ? shortLogo : siteLogo}></img>
				</Row> */}

				<Formik
					initialValues={formInitialValues}
					validationSchema={validationSchema}
					enableReinitialize
					onSubmit={async values => {
						setErrormessage('')
						const data = {
							username: values.username,
							password: values.password,
							client_id: props.client_id,
							redirect_uri: props.redirect_uri
						}

						try {
              setLoading(true)
							const resp = await props.getSsoLogin(data)
              setLoading(false)
							// webengage login
							// eslint-disable-next-line no-undef
							// webengage.user.login(values.username);
						} catch (error) {
              setLoading(false)
							setErrormessage(error.error)
						}
					}}>
					{({
						handleChange,
						handleBlur,
						handleSubmit,
						values,
						isSubmitting,
						touched,
						errors
					}) => {
						return (
							<>
								<div className={styles.cardContainer}>
									<Card className={styles.cardSignUp}>
										<Card.Body className={styles.cardBodySignup}>
											<Row className={styles.title}>
												Login into your account
											</Row>
											<Row className={styles.inputs}>
												<SignUpInput
													label='Username'
													placeholder='Enter Username'
													type='text'
													name='username'
													refValue={editFieldRef}
													tabIndex='1'
													value={values.username.toLowerCase()}
													onChange={e => {
														setErrormessage('')
														setUsername(username)
														handleChange(e)
													}}
                          onBlur={event => {
                            onBlurUserName(event.target.value)
                            handleBlur(event)
                          }}
                          isInvalid={
														(touched.username && errors.username) ||
														errormessage
													}
													errorMessage={
														errors.username
													}
													onPaste={e => {
														e.preventDefault()
														return false
													}}
													onCopy={e => {
														e.preventDefault()
														return false
													}}
													//icon={USerSvg}
												/>
											</Row>
											<Row
												className={styles.inputs}
												style={{ position: 'relative' }}>
												<SignUpInput
                        	containerClasses={styles.passwordInvalid}
													label='Password'
													placeholder='Enter Password'
													type={passwordType}
													name='password'
													tabIndex='2'
													value={values.password}
													onChange={e => {
														setErrormessage('')
														// setPassword(e.target.value))
														handleChange(e)
													}}
													onKeyPress={e => keyPressHandler(e, handleSubmit)}
													isInvalid={
														(touched.password && errors.password) ||
														errormessage
													}
													errorMessage={
														errors.password ? errors.password : errormessage
													}
													onPaste={e => {
														e.preventDefault()
														return false
													}}
													onCopy={e => {
														e.preventDefault()
														return false
													}}
												/>
												{
													<img
														className={styles.toggle}
														src={
															passwordType === 'password'
																? PasswordHide
																: PasswordShow
														}
														alt=''
														onClick={() => {
															const type =
																passwordType === 'password'
																	? 'text'
																	: 'password'
															setPasswordType(type)
														}}></img>
												}
											</Row>
											<Row className={styles.buttonMargin}>
												<SignUpButton
													classes={styles.buttonSignUP}
													title='Login'
                          tabIndex='3'
                          loading={loading}
													onClick={handleSubmit}
												/>
											</Row>
											{/* <Row className={styles.inputRow}>
													<Col md={8} lg={12} xl={8} xs={12}>
														<input
															type='hidden'
															value='password'
															autoComplete='new-password'
														/>
														<Form.Group
															className={`form-focus ${
																values.username !== '' ? 'focused' : null
															}`}>
															<Form.Control
																className={styles.emailInput}
																ref={editFieldRef}
																tabIndex='1'
																type='text'
																name='username'
																value={values.username}
																onChange={event => {
																	setUsername(username)
																	setErrormessage('')
																	handleChange(event)
																}}
																onBlur={event => {
																	onBlurUserName(event.target.value)
																	handleBlur(event)
																}}
																isInvalid={touched.username && errors.username}
																isValid={touched.username && !errors.username}
																onPaste={e => {
																	e.preventDefault()
																	return false
																}}
																onCopy={e => {
																	e.preventDefault()
																	return false
																}}></Form.Control>
															<Form.Label className='focus-label'>
																Enter UserName
															</Form.Label>
															<Form.Control.Feedback type='invalid'>
																{errors.username}
															</Form.Control.Feedback>
														</Form.Group>
													</Col>
													<Col md={1} className={styles.noColWith}></Col>
												</Row>{' '} */}
											{/* * */}
											{/* <Row className={styles.inputRow}>
													<Col
														md={6}
														lg={10}
														xl={6}
														xs={12}
														className={styles.inputPaddingRight}>
														<Form.Group
															className={`form-focus ${
																touched.password && !errors.password
																	? 'focused'
																	: null
															}`}>
															<Form.Control
																className={styles.passwordInput}
																type={passwordType}
																name='password'
																tabIndex='2'
																value={values.password}
																onChange={handleChange}
																autoComplete='new-password'
																// onBlur={handleBlur}
																onBlur={event => {
																	handleBlur(event)
																}}
																onKeyPress={e =>
																	keyPressHandler(e, handleSubmit)
																}
																isInvalid={touched.password && errors.password}
																isValid={touched.password && !errors.password}
																onPaste={e => {
																	e.preventDefault()
																	return false
																}}
																onCopy={e => {
																	e.preventDefault()
																	return false
																}}></Form.Control>
															<img
																className={styles.toggle}
																src={
																	passwordType === 'password'
																		? PasswordHide
																		: PasswordShow
																}
																onClick={() => {
																	const type =
																		passwordType === 'password'
																			? 'text'
																			: 'password'
																	setPasswordType(type)
																}}></img>
															<Form.Label className='focus-label'>
																Enter Password
															</Form.Label>
															<Form.Control.Feedback type='invalid'>
																{errors.password}
															</Form.Control.Feedback>
														</Form.Group>
														<div className={`${styles.marginResponsive}`}>
															<span
																className={`d-md-none ${styles.forgotText}`}
																style={{ marginTop: '10px' }}>
																<Link to='forgot-password'>
																	Forgot Password?
																</Link>
															</span>
															<span
																className={`d-md-none ${styles.forgotSeperator}`}>
																/
															</span>
															<span
																className={`d-md-none ${styles.forgotText}`}
																style={{ marginTop: '10px' }}>
																<Link to='forgot-username'>
																	Forgot Username?
																</Link>
															</span>
														</div>
													</Col>
													<Col
														md={2}
														lg={2}
														xl={2}
														xs={12}
														className={styles.inputPaddingLeft}>
														<SignUpButton
															tabindex='3'
															classes={styles.button}
															title='Login'
															disabled={isSubmitting}
															onClick={handleSubmit}
														/>
													</Col>
												</Row> */}
										</Card.Body>
									</Card>
								</div>
							</>
						)
					}}
				</Formik>
			</div>

			<div className='modal fade' id='mdlPartner'>
				<div className='modal-dialog modal-dialog-centered'>
					<div className='modal-content'>
						<div className='modal-body text-center p-4'>
							{/* <p>Your Stockal account is held via a partner</p> */}
							<p>Please wait, redirecting you to the partner website</p>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default SsoSignin
