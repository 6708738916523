import { applyMiddleware, createStore, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './rootReducer'
import { initialState } from './initialState'

const store = createStore(
	rootReducer,
	initialState,
	compose(
		applyMiddleware(thunk),
		(window.__REDUX_DEVTOOLS_EXTENSION__ &&
			window.__REDUX_DEVTOOLS_EXTENSION__()) ||
			compose
	)
)
export default store
