import { logout } from '../api'

const sessionLogout = () => {
  var token = JSON.parse(sessionStorage.getItem('token'))
  let ssoFlag = sessionStorage.getItem('Sso_flag') ? sessionStorage.getItem('Sso_flag') : false
  let logoutpage = sessionStorage.getItem('logoutPage') ? sessionStorage.getItem('logoutPage') : ''
   //webengage logout
   // eslint-disable-next-line no-undef
   webengage.user.logout();

  var body = {
    accessToken: token && token.accessToken
  }
  logout(JSON.stringify(body)).then(response => {
    sessionStorage.clear()
    localStorage.clear()
    if(ssoFlag){
      if(logoutpage){
        window.location.href=logoutpage
      }else{
        window.location.href='/logout'
      }
    }
    else{
    window.location.href = '/signin'
    }
  })
}

export {
  sessionLogout,
}
