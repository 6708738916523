import React, { useState, useEffect, useRef} from 'react'
import PinInput from "react-pin-input"
import styles from './cashManagement.module.css';
import { Row, Col} from 'react-bootstrap';
import leftArrow from '../../public/images/leftArrowIcon.svg';
import about from '../../public/images/cashmanagementAbout.svg'
import transferCompleted from '../../public/images/transferSuccessful.svg'
import moment from 'moment';
import { Link } from 'react-router-dom';
import CashMgmtToolTip from './Tooltip'
import Spinner from 'react-bootstrap/Spinner';
import { decimalAdjust } from '../../utils'




const ConfirmTransfer = (props) => {
  // const[addRemark, setAddRemark] = useState('Add a Remark');
  // otp functionality
  const [otp, setOtp] = useState('')
  const [seconds, setSeconds] = React.useState(180)
  const [error, setError] = useState('')
  const [resendButtonToggle, setResendButtonToggle] = useState(false)
  const [transferComplete, setTransferComplete] = useState(false)
  const [width, setWindowWidth] = useState(0);

  const myRef = useRef()

  useEffect(() => {

     updateDimensions();

     window.addEventListener('resize', updateDimensions);
     return () =>
       window.removeEventListener('resize',updateDimensions);
    }, [])

  const updateDimensions = () => {
    const width = window.innerWidth
    setWindowWidth(width)
  }

  useEffect(() => {
    myRef && myRef.current && myRef.current.focus()
  },[])

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000)
    } else {
      setSeconds(0)
      setResendButtonToggle(true)
    }
  })


  const resendOtp = () => {
    props.sendOtpApi()
    setSeconds(180)
    setResendButtonToggle(false)
  }

  const sendDataToInitiateTransfer = () => {
    props.initiateTheTransferProtocol(otp)
  }

  const lastFourDigit = (e) => {
    let sliced = e.slice(11,16);
    return sliced
  }

  useEffect(() => {
    const pinInputs = document.getElementsByClassName('pincode-input-text')
    if (pinInputs && pinInputs?.length > 0) {
      for (let i = 0; i < pinInputs.length; i++) {
        pinInputs[i].addEventListener('keydown', e => {
          if (
            e.key.toLowerCase() === 'control' ||
            e.key.toLowerCase() === 'meta' ||
            e.key.toLowerCase() === 'v' ||
            e.key.toLowerCase() === 'c'
          ) {
            e.preventDefault()
          }
        })
      }
    }
  }, [])

  const amountDebited = props.amount && props.transferCharges >= 0 && props.transferCharges !== null ? +props.amount + +props.transferCharges : "--";
  // const transferTypeCheck = props.transferType === "ACH ODFI Same Day" ? "ACH" : props.transferType === "WIRE" ? "WIRE" : "";
  return(
    <>
    {props.isTransferSuccess ?
    <>
      <div className={styles.completedOfTransferCashMangement}>
        <img className={styles.imageCenterCompletedCM} src={transferCompleted}/>
        <span className={styles.transferInititatedCM}>Transfer Initiated</span>
        <span className={styles.timeOfTransferCM}>{moment(props.transferSuccessTime).format('D MMM YY h:mm:ss a')}</span>
      </div>
    </>
    :
    <>
     <Row>
          <Col lg={1} md={1} sm={1} xs={1}  onClick={props.selectCountry === "United States" ? () => props.setStepsCount(2) : () => props.setStepsCount(3)} style={{cursor:"pointer"}} ><img src={leftArrow}></img></Col>
          <Col lg={11} md={11} sm={11} xs={11} >
            <span className={styles.transferModalHeading}>Confirm Transfer</span>
            <span className={styles.transferModalBalance}>Balance: ${props.balanceAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
          </Col>
      </Row>
      <Row className={styles.transactionModalTopBorder}>
        <Col className={styles.transferModaInputBackground}>
          <span className={styles.transferModalCodeSentText}>Enter the 6-digit code sent to</span>
          <span className={styles.transferModalCodeSentEmail}>{props.email}<span className={styles.transferModalDetailsEditDetails} style={{marginLeft:"5px"}}><Link to="/profile">Edit</Link></span></span>
          <Row className='mt-4 justify-content-center'>
          <PinInput
            length={6}
            initialValue=""
            ref={myRef}
            type="numeric"
            inputMode="number"
            inputStyle={width <= 400 ? responsiveStyle.spinInput : style.spinInput }
            inputFocusStyle={{ background: '#EFEFF1' }}
            onChange={(value, index) => { setOtp(value) }}
            // onComplete={(value, index) => { setOtp(value) }}
            autoSelect={true}
            regexCriteria={/[0-9]/}
          />
        </Row>

        <span className={styles.transferModalExpiryText}>{resendButtonToggle ? <span style={{color:"#FA274A"}}>OTP Expired</span> : `Expires in ${seconds}s`}
        {resendButtonToggle ? <span style={{color:"#0061D3",cursor:"pointer"}} onClick={resendOtp}> Resend Code</span> : ""}</span>

        </Col>
      </Row>
      {props.otpError.length === 0 ? "":
      <Row>
        <Col>
            <span className={styles.otpErrorText}>{props.otpError}</span>
        </Col>
      </Row>}
      <Row>
        <Col className={styles.transferModalDetailsHeading} lg={6} xs={6} sm={6} md={6}>
          <span>Transfer Details</span>
        </Col>
        <Col className={styles.transferModalDetailsEditDetails} lg={6} xs={6} sm={6} md={6}>
          <span style={{cursor:"pointer"}} onClick={props.selectCountry === "United States" ? () => props.setStepsCount(2) : () => props.setStepsCount(3)}>Edit Details</span>
        </Col>
      </Row>
      <hr/>
      <Row className={styles.savingsRightCardMarginBottom}>
        <Col lg={6} xs={6} sm={6} md={6} className={styles.transferModalSavingsRightCardDetails}>
          <span>Transfer Amount</span>
        </Col>
        <Col lg={6} xs={6} sm={6} md={6} className={styles.savingsRightCardDetailsValue}>
          <span>
          ${props.amount}
          </span>
        </Col>
      </Row>
      <Row className={styles.savingsRightCardMarginBottom}>
        <Col lg={6} xs={6} sm={6} md={6} className={styles.transferModalSavingsRightCardDetails}>
          <span>
            <span style={{marginRight:"7px"}}>Transaction Charges</span>
            <CashMgmtToolTip content={'Amount of the transaction charges'}/>
          </span>
        </Col>
        <Col lg={6} xs={6} sm={6} md={6} className={styles.savingsRightCardDetailsValue}>
            <span>${props.transferCharges ? props.transferCharges : "--"}</span>
        </Col>
      </Row>
      <Row className={styles.savingsRightCardMarginBottom}>
        <Col lg={6} xs={6} sm={6} md={6} className={styles.transferModalSavingsRightCardDetails}>
          <span>Total Amount Debited</span>
        </Col>
        <Col lg={6} xs={6} sm={6} md={6} className={styles.savingsRightCardDetailsValue}>
          <span>
            ${decimalAdjust('ceil', amountDebited,-2)}
          </span>
        </Col>
      </Row>
      <hr/>
      <Row className={styles.savingsRightCardMarginBottom}>
        <Col lg={5} xs={6} sm={6} md={5} className={styles.transferModalSavingsRightCardDetails}>
          <span>
            <span style={{marginRight:"7px"}}>Transaction Type</span>
            <CashMgmtToolTip content={'This is the nature of the transaction'}/>
          </span>
        </Col>
        <Col lg={7} xs={6} sm={6} md={7} className={styles.savingsRightCardDetailsValue}>
          <span>{props.transferType}</span>
        </Col>
      </Row>
      <Row className={styles.savingsRightCardMarginBottom}>
        <Col lg={6} xs={6} sm={6} md={6} className={styles.transferModalSavingsRightCardDetails}>
          <span>
            <span style={{marginRight:"7px"}}>From</span>
            <CashMgmtToolTip content={'The amount has been debited from this account'}/>
          </span>
        </Col>
        <Col lg={6} xs={6} sm={6} md={6} className={styles.savingsRightCardDetailsValue}>
          <span >{props.accountNumber ? `XXXXXXXXXX${lastFourDigit(props.accountNumber)}` : ""}</span>
        </Col>
      </Row>
      <Row className={styles.savingsRightCardMarginBottom}>
        <Col lg={6} xs={6} sm={6} md={6} className={styles.transferModalSavingsRightCardDetails}>
        <span>
          <span style={{marginRight:"7px"}}>To</span>
          <CashMgmtToolTip content={'The amount has been credited to this account'}/>
        </span>
        </Col>
        <Col lg={6} xs={6} sm={6} md={6} className={styles.savingsRightCardDetailsValue}>
          <span>{props.creditedTo}</span>
        </Col>
      </Row>
      <Row className={styles.savingsRightCardMarginBottom}>
        <Col className={styles.transferModaAddRemarkBox}>
        <input className={styles.transferModaAddRemark}
        maxlength="450"
        placeholder="Mandatory to add Remark" onChange={e => props.setAddRemark(e.target.value.trim())}>
        </input>
        </Col>
      </Row>
      <Row className={styles.transferTypeModalBottomButton}>
        <Col lg={10} sm={9} xs={9}>
          <Row>
            <Col lg={7} sm={8} xs={8} md={8}>
              <span className={styles.transferModalContinueButton}
              style={{backgroundColor: props.finalTransferButtonClicked ? "grey" : "#0061D3"}}
              onClick={props.finalTransferButtonClicked ? "" : () => sendDataToInitiateTransfer()}>
                {props.finalTransferButtonClicked ?
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  style={{marginRight:"5px"}}
                /> :""}
              Confirm Transfer</span>
            </Col>
            <Col lg={5} sm={4} xs={4} md={4}>
              <span className={styles.transferModalCancelButton} onClick={props.handleClose}>Cancel</span>
            </Col>
          </Row>
        </Col>
        <Col lg={2} sm={3} xs={3} className="pr-0">
        </Col>
      </Row>
    </>}
    </>
  )
}

const responsiveStyle = {
  spinInput: {
    border: "1px solid #3C404A",
    borderRadius: "6px",
    textAlign: 'center',
    width: '30px',
    height: '35px',
    background: '#EFEFF1',
    borderRadius: '8px',
    marginLeft: '13px',
    fontFamily: 'Geomanist',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: '0.6px',
    color: 'black'
  }
}

const style = {
  spinInput: {
    border: "1px solid #3C404A",
    borderRadius: "6px",
    textAlign: 'center',
    width: '40px',
    height: '48px',
    background: '#EFEFF1',
    borderRadius: '8px',
    marginLeft: '16px',
    fontFamily: 'Geomanist',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: '0.6px',
    color: 'black'
  }
}


export default ConfirmTransfer




