import React , {useState, useEffect} from 'react'
import styles from './HeaderDropdown.module.css'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { showFundModal } from '../../store/actions';
import { CashAccountReviewState, CashAccountRejectedState, CashAccountSignupState, CashAccountPausedState } from './NavigationStates';
import { decimalAdjust, addCommasinPrice } from '../../utils';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import stackIcon from '../../public/images/stackIcon.svg'
import themeIcon from '../../public/images/themeIcon.svg'
import portfoliIcon from '../../public/images/portfoliIcon.svg'
import ordersIcon from '../../public/images/ordersIcon.svg'
import usIcon from '../../public/images/usIcon.svg'
import transferIcon from '../../public/images/transferIcon.svg'
import recentIcon from '../../public/images/recentIcon.svg'
import addFundsIcon from '../../public/images/addFundsIcon.svg'
import manageFundsIcon from '../../public/images/manageFundsIcon.svg'

const NavbarTab = (props) => {

  let history = useHistory()
  const dispatchAction = useDispatch()

  const [ key, setKey ] = useState('Explore');

  const { portfolioSummary, pendingOrders, cashAccount, transactions, accountStatus, savingAccountStatus, closeModal, isSavingAccount, addedToWaitlist, isDisabled, addToWaitlist } = props

  const isAccountApproved = accountStatus && accountStatus === 2 ? true : false
  const showReviewState = isAccountApproved && ( savingAccountStatus === 1 || isSavingAccount )

  const portfolioAmount = portfolioSummary && portfolioSummary.totalCurrentValue ? portfolioSummary.totalCurrentValue.toFixed(2) : "";
  const totalInvestmentAmount = portfolioSummary && portfolioSummary.totalInvestmentAmount ? portfolioSummary.totalInvestmentAmount.toFixed(2) : "";

  const profitAndLoss = portfolioAmount && totalInvestmentAmount ?  decimalAdjust('floor',portfolioAmount - totalInvestmentAmount, -2) : ''

  useEffect(()=>{
    const queryUrl = queryString.parse(window.location.search, {
      parseNumbers: false
    })
    if(queryUrl.menu) {
      setKey('Cash A/C')
    }
  },[])

  return (
    <Tabs
      id="controlled-tab-example"
      className={styles.navbarTabs}
      activeKey={key}
      onSelect={(k) => setKey(k)}
    >
      <Tab eventKey="Explore" title="Explore">
        <div className={styles.tabMenuItem}>
          <div onClick={() => history.push('/allStacks')}>
            <TabItem image={stackIcon} title={'Curated Stacks'} desc={'Expert-Made Model Portfolios'}/>
          </div>
          <div onClick={() => history.push('/themes')}>
            <TabItem image={themeIcon} title={'Investment Themes'} desc={'Collections of Stocks & ETFs'}/>
          </div>
          <div className={styles.tabDivider}></div>
          <div onClick={() => history.push('/explore')}>
            <p className={styles.textBlue} style={{padding:"24px 12px"}}>Explore All Stocks, ETFs & Stacks</p>
          </div>
        </div>
      </Tab>
      <Tab eventKey="Investment A/C" title="Investment A/C">
        <div className={styles.tabMenuItem}>
          {isAccountApproved ? (
            <>
              <div onClick={() => history.push('/portfolio')}>
                <TabItem image={portfoliIcon} title={'Portfolio'} desc={ portfolioAmount ? `$${addCommasinPrice(parseFloat(portfolioAmount))}`: '--'} text={profitAndLoss ? profitAndLoss > 0 ? `+$${addCommasinPrice(profitAndLoss)}` : `-$${addCommasinPrice(profitAndLoss).substring(1)}`: ''} textColor={profitAndLoss > 0 ? '#0DC014' : '#FA274A' }/>
              </div>
              <div onClick={() => history.push('/Orders')}>
                <TabItem image={ordersIcon} title={'Orders'}/>
                {/* To be added after socket implementation */}
                {/* desc={pendingOrders && pendingOrders !== 0 ? `• ${pendingOrders} Pending orders`: `No pending orders`} color={pendingOrders && pendingOrders !== 0 ? "#FA5D05": ''} */}
              </div>
              <div className={styles.tabDivider}></div>
              <div className={styles.dropdownRow} style={{padding:"16px 12px"}} onClick={() => history.push('/funds')}>
                  <img src={manageFundsIcon} alt='icon' style={{marginRight:"9.5px"}}/>
                  <p className={styles.textBlue}>Manage Funds</p>
              </div>
              <div className={styles.tabDivider} style={{margin:"0px"}}></div>
              <div className={styles.dropdownRow} style={{padding:"16px 12px"}} onClick={()=> {
                dispatchAction(showFundModal({investment:true,selectFund:true}))
                closeModal()
              }}>
                  <img src={addFundsIcon} alt='icon'style={{marginRight:"11px"}}/>
                  <p className={styles.textBlue}>Add Funds</p>
              </div>
              <div className={styles.tabDivider} style={{margin:"0px"}}></div>
              <div className={styles.dropdownRow} style={{padding:"16px 12px"}} onClick={() => history.push('/account-transfer')}>
                  <img src={manageFundsIcon} alt='icon' style={{marginRight:"9.5px"}}/>
                  <p className={styles.textBlue}>Account Transfer</p>
              </div>
            </>
          ) : (
            <div style={{opacity:"0.65"}}>
                <TabItem image={portfoliIcon} title={'Portfolio'} desc={'--'}/>
                <TabItem image={ordersIcon} title={'Orders'} desc={'--'}/>
                <div className={styles.tabDivider}></div>
                <div className={styles.dropdownRow} style={{padding:"16px 12px"}}>
                  <img src={manageFundsIcon} alt='icon' style={{marginRight:"9.5px"}}/>
                  <p className={styles.textBlue}>Manage Funds</p>
                </div>
                <div className={styles.tabDivider} style={{margin:"0px"}}></div>
                <div className={styles.dropdownRow} style={{padding:"16px 12px"}}>
                  <img src={addFundsIcon} alt='icon'style={{marginRight:"11px"}}/>
                  <p className={styles.textBlue}>Add Funds</p>
                </div>
            </div>
          )}
        </div>
      </Tab>
      { props.showCashManagementFeature ?
      <Tab eventKey="Cash A/C" title="Cash A/C">
        {/* {savingAccountStatus === 2 ? (
          <div className={styles.tabMenuItem}>
              <div onClick={() => history.push('/savings')}>
                <TabItem image={usIcon} title={'Account Details'} desc={cashAccount ? `$${cashAccount}` : '--'}/>
              </div>
              <div onClick={() => history.push('/savings')}>
              <TabItem image={recentIcon} title={'Recent Transactions'} desc={transactions ? `${transactions} in the last 30 days` : `--`}/>
              </div>
              <div className={styles.tabDivider}></div>
              <a href='/savings?transferFund=true'>
                  <div className={styles.dropdownRow} style={{padding:"16px 12px"}}>
                      <img src={transferIcon} alt='icon' style={{marginRight:"9.5px"}}/>
                      <p className={styles.textBlue}>Transfer Funds</p>
                  </div>
              </a>
              <div className={styles.tabDivider} style={{margin:"0px"}}></div>
              <div
                // onClick={()=> {
                // dispatchAction(showFundModal({saving:true}))
                // closeModal()}}
                disabled>
                  <div className={styles.dropdownRow} style={{padding:"16px 12px"}}>
                      <img src={addFundsIcon} alt='icon'style={{marginRight:"11px"}}/>
                      <p className={styles.textBlue} style={{color: "grey"}}>Add Funds</p>
                  </div>
              </div>
          </div>
        ) : savingAccountStatus === 3 ? (
          <div style={{maxWidth:"280px",margin:"auto"}}>
            <CashAccountRejectedState/>
          </div>
        ) : showReviewState ? (
          <div style={{maxWidth:"280px",margin:"auto"}}>
            <CashAccountReviewState/>
          </div>
        ) : (
          <div style={{maxWidth:"280px",margin:"auto"}}>
            <CashAccountSignupState accountApproved={isAccountApproved}/>
          </div>
        )} */}
        <div style={{maxWidth:"280px",margin:"auto"}}>
            <CashAccountPausedState addToWaitlist={addToWaitlist} isDisabled={isDisabled} addedToWaitlist={addedToWaitlist}/>
          </div>
      </Tab> 
      
      : ""}
    </Tabs>
  );
}

const TabItem = (props) => {

  const { image, title, desc, text, color, textColor }  = props

  return (
      <div className={styles.tabContainer}>
          <div className={styles.menuImage}>
              <img src={image} alt='menu-icon'/>
          </div>
          <div className={styles.menuContent}>
              <p className={styles.textBlack}>{title}</p>
              <div style={{display:"flex",gap:"6px"}}>
                  <p style={{color:color ? color : '#767C8F' }}>{desc}</p>
                  <p style={{color:textColor}}>{text ? text : ''}</p>
              </div>
          </div>
      </div>
  )
}

export default NavbarTab
