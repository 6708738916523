import { initialState } from '../initialState'
import { USER_INVESTMENT } from '../actions/action'

const userInvestmentHistory = (
	state = initialState.userInvestmentHistory,
	action
) => {
	switch (action.type) {
		case USER_INVESTMENT:
			return { ...state, userInvestmentHistory: action.payload }
		default:
			return state
	}
}

export default userInvestmentHistory
