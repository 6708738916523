import React, { useEffect, useState } from 'react'
import { Row, Col, Container, Spinner } from 'react-bootstrap'
import styles from './Orders.module.css'
import { Footer } from '../../components'
import { Header } from '../../components'
import { OrderCard, LastWeekCard} from '../../components'
import { pendingOrdersData, lastWeekOrdersData, orderInAWeek } from '../../api'
import { SideBar } from '../../components'
import { cancelOrder } from '../../api'
import moment from 'moment'
import NoOrder from '../../public/images/NoOrderImage.png'
import OrderSkeleton from '../../skeletons/OrderSkeleton'



const Orders = () => {
    const [pendingData, setPedingData] = useState([])
    const [todayData, setTodayData] = useState([])
    const [lastWeekData, setLastWeekData] = useState([])
    const [slideeffect, setslideeffect] = useState(false)
    const [pendingLoader, setPendingLoader] = useState(false)
    const [LastWeekLoader, setLastWeekLoader] = useState(false)
    const [sevenDayBeforeDate, setSevenDayBeforeDate] = useState('')
    const [weekData, setWeekData] = useState([])
    const [tradesCount, setTradesCount] = useState(0)

    const handleEffect = () => {
      setslideeffect(prevState => setslideeffect(!prevState))
    }

    useEffect(() => {
      PendingApiCall()
      LastWeekApi()
      LastweekDate()
      AllOrderInWeek()
        // setInterval(() => PendingApiCall(), 5000)
        const interval = setInterval(() => PendingApiCall(), 5000)
        return () => {
          clearInterval(interval);
        }
	  }, [])

    // useEffect(() => {
    //   PendingApiCall()
    //   LastWeekApi()
    // })

  const AllOrderInWeek = () => {
    const asynchFetch = async () => {
      let [AllOrderInWeekRes] = await Promise.all([orderInAWeek()])
      let trade = 0
      try{
      if(AllOrderInWeekRes && AllOrderInWeekRes.data.data.length > 0 ) {
        AllOrderInWeekRes.data.data.map((item) => {
          trade = trade + item.tradesCount
        })
        setTradesCount(trade)
      }
      }catch(error){
        console.log(error)
      }
    }
    asynchFetch()
  }

  // pending api call using try and catch
  const PendingApiCall = () => {
    const asyncFetch = async () => {
      try{
      let [OrdersDatares] = await Promise.all([pendingOrdersData()])
      if(OrdersDatares.code === 200 && OrdersDatares.data){
        setPedingData(
          OrdersDatares.data && OrdersDatares.data.pendingOrder && Object.keys(OrdersDatares.data.pendingOrder).length > 0
          ? OrdersDatares.data.pendingOrder : []
        )
        setTodayData(OrdersDatares.data.todayOrder && Object.keys(OrdersDatares.data.todayOrder).length > 0 ? OrdersDatares.data.todayOrder : [])
        setPendingLoader(true)
      }
      else{
        setPedingData('')
        setPendingLoader(true)
      }
      }catch(error){
        console.log(error)
        setPendingLoader(true)
      }
    }
    asyncFetch()
  }

  const LastWeekApi = () => {
    const asynchFetch = async () => {
      let [LastWeekDatares] = await Promise.all([lastWeekOrdersData()])
      try{
      if(LastWeekDatares && LastWeekDatares.data && LastWeekDatares.data.length > 0) {
      setLastWeekData(
        LastWeekDatares.data && Object.keys(LastWeekDatares.data).length > 0
        ? LastWeekDatares.data : {}
      )
      setLastWeekLoader(true)
      } else{
        setLastWeekData('')
        setLastWeekLoader(true)
      }
      }catch(error){
        console.log(error)
        setLastWeekLoader(true)
      }
    }
    asynchFetch()
  }

  // cancel order Api
  const OrderCancel = (e) => {
    cancelOrder(e).then(res => {
      if (res.status === 200) {
        const filteredData = pendingData.filter((item) => item.id !== e.id);
        setPedingData(filteredData)
        // PendingApiCall()
        // debugger
        setTodayData(todayData => todayData.concat(e))
        // debugger
        // console.log('hey')
        // setTodayData(...todayData, e)
      }
    })
  }

  const currentDate = new Date();
  const lastSevenDayDate = currentDate - new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
  // console.log('current',lastSevenDayDate.toISOString())
  // console.log('todayDate',new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
  const LastweekDate = () => {
    var today = new Date();
    var nextweekVal = new Date(today.getFullYear(), today.getMonth(), today.getDate()-6);
    let latestdate = JSON.stringify(nextweekVal)
    latestdate = latestdate.slice(1,11)
    return setSevenDayBeforeDate(latestdate)
  }

  const tempArray =  lastWeekData && lastWeekData.map(item => {
    if (item.lastExecuted) {
        Object.assign(item, { sortKey: new Date(item.lastExecuted.replace(/-/g, "/"))});
        return item;
    }
    if (item.lastExecutedDate) {
        Object.assign(item, { sortKey: new Date(item.lastExecutedDate.replace(/-/g, "/")) });
        return item;
    } else {
        Object.assign(item, { sortKey: new Date(item.created.replace(/-/g, "/")) });
        return item;
    }
  });
  let sortedArray = tempArray && tempArray.sort((a, b) => a.sortKey - b.sortKey).reverse();
  // let sortedArray = lastWeekData && lastWeekData.sort((a, b) => new Date(...a.executedDate.split('-')) - new Date(...b.executedDate.split('-'))).reverse()
  // let sortedArray = lastWeekData && lastWeekData.sort((a, b) =>  a.timestamp - b.timestamp).reverse()

  const weekArray =
    sortedArray && sortedArray.filter((item,index) => {
      return (sevenDayBeforeDate.split('-') <= item.executedDate.split('-'))
  })

  const monthArray = sortedArray && sortedArray.filter((item,index) => {
    return (sevenDayBeforeDate.split('-') > item.executedDate.split('-'))
  })

  const pendingTempArray =  pendingData && pendingData.map(item => {
    if (item.created) {
        Object.assign(item, { sortKey: new Date(item.created.replace(/-/g, "/"))});
        return item;
    }
  });

  // const pendingSortArray = pendingData && pendingData.sort((a,b) => a.created - b.created).reverse();
  const pendingSortArray = pendingTempArray && pendingTempArray.sort((a,b) => a.sortKey - b.sortKey).reverse();

	return (
		<>
            <Header />
            {LastWeekLoader && pendingLoader ?
            <Container className={slideeffect ? styles.shiftPos : styles.originalPos}>
            { pendingData.length === 0 && todayData.length === 0 && lastWeekData.length === 0 ?
                <div className={styles.noOrdersFound}>
                  <img src={NoOrder}></img>
                  <p className={styles.noOrdersText}>Place your order</p>
                  <p className={styles.noOrdersTextBottom}>Over {tradesCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} orders placed this week.</p>
                </div>
                :
                <div className={`${styles.orderContainer} mb-5`}>
                    <Row className={styles.title}>Pending</Row>
                    {pendingData && pendingData.length && pendingData !== undefined > 0 ?
                    <>
                      {pendingData && pendingData.length > 0 && pendingSortArray.map((item, index) => {
                      return <OrderCard item={item}
                      index={index}
                      length={pendingData.length}
                      PendingApiCall={PendingApiCall}
                      OrderCancel={OrderCancel}/>})}
                    </>
                    : <p className={styles.noOrdersTextBottom}>No orders in pending!</p>}

                    <Row className={styles.title}>Today</Row>
                    {todayData && todayData.length > 0 ?
                    <>
                      {todayData && todayData.length > 0 && todayData.map((item, index) => {
                      return <LastWeekCard item={item} index={index} length={todayData.length}/>})}
                    </>
                    : <p className={styles.noOrdersTextBottom}>No orders for today!</p>}

                    <Row className={styles.title}>Last 7 Days</Row>
                    {weekArray && weekArray.length > 0 ?
                    <>
                      {weekArray && weekArray.length > 0 && weekArray.map((item, index) => {
                      return <LastWeekCard item={item} index={index} length={weekArray.length}/> })}
                    </>
                    : <p className={styles.noOrdersTextBottom}>No orders in Last 7 days!</p>
                    }


                    <Row className={styles.title}>Last 30 Days</Row>
                    {monthArray && monthArray.length > 0 ?
                    <>
                      {monthArray && monthArray.length > 0 && monthArray.map((item, index) => {
                      return <LastWeekCard item={item} index={index} length={monthArray.length}/> })}
                    </>
                    : <p className={styles.noOrdersTextBottom}>No orders in Last 30 days!</p>}
                </div>
                }
                {/* <div style={{ marginTop: '80px' }}>
                    <Footer />
                </div> */}
                <div className={styles.sideBarMobileHide}>
                <SideBar handleEffect={handleEffect} />
                </div>
            </Container> :
            <Container className="pt-5">
            <OrderSkeleton/>
            <div className="mt-5 mb-5"></div>
            <OrderSkeleton/>
            </Container>
            // <div className={styles.spinner}><Spinner animation="border" className={styles.spinnerColor}/></div>
          }
		</>
	)
}

export default Orders
