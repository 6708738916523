import React, { Fragment, useRef, useState } from "react";
import {copyImg, copiedImg} from './assets'
import styles from './instruction.module.css'
import { Overlay, Tooltip } from "react-bootstrap";

const Copy = (props) => {
    const [copied, setCopied] = useState(false)
    const copyIconRef = useRef(null)
    const onCopy = async ()=>{
        try{
            await navigator.clipboard.writeText(props.data.value)
            setCopied(true)
            setTimeout(()=>{setCopied(false)}, 1000)
        }
        catch(error){
            console.log('Could not copy to clipboard')
            setCopied(false)
        }
    }
    return (
        <Fragment>
            <img src={!copied?copyImg:copiedImg} ref={copyIconRef} className={styles.copyIcon} onClick={onCopy}/>
            <Overlay target={copyIconRef.current} show={copied} placement="top">
                {(props) => (
                <Tooltip  className={`d-none d-sm-block ${styles.tooltipCopy}`} {...props}>
                    Copied          
                </Tooltip>
                )}
            </Overlay>
            {copied && <div  className={`d-block d-sm-none ${styles.tooltipCopyFixed}`} >
                <span>
                    {props.data.label} Copied
                </span>
            </div>}
        </Fragment>
    )    
}
export default Copy