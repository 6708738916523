import React, { useState, useContext } from 'react'
import VerifyOtp from './VerifyOtp'
import ResetScreen from './ResetScreen'
import { ThemeContext } from '../../../Context/ThemeContext'
import VerifyBasicOtp from '../UserBasicDetail/VerifyOTP'
import axios from 'axios'
import OtpModal from '../../../components/CustomModal/OtpModal'
import styles from '../Profile.module.css'
import BackArrow from '../../../public/images/BackArrow.svg'
import { useHistory } from 'react-router-dom'

const ResetPassword = props => {
	const [isVerifyOtp, setIsVerifyOtp] = useState(false)
	const [getOtp, setOtp] = useState('')
	const { buttonColor } = useContext(ThemeContext)

	// const onVerifyOtp = () => {
	// 	setIsVerifyOtp(true)
	// }

	const handleRefresh = () => {
    props.resetTabs('basic detail')
    setIsVerifyOtp(false)
		// window.location.reload()
	}

	const history = useHistory()

	// const hey = () => {
	// 	console.log('hola')
	// 	history.goBack()
	// }

	return isVerifyOtp ? (
		<>
			<div className={`mt-5  ${styles.ResetScreenHideResponsive}`}>
				<ResetScreen
					buttonColor={buttonColor}
					onVerifyOtp={setIsVerifyOtp}
					hashId={props.hashId}
          resetTabs={props.resetTabs}
					otp={getOtp}
          sendOtpApi={props.sendOtpApi}></ResetScreen>
			</div>

			{/* responsive Reset screen */}
			<div className={styles.ResponsiveModalContainer}>
				<div>
					<img src={BackArrow} onClick={handleRefresh} />
				</div>
				<ResetScreen
					buttonColor={buttonColor}
					onVerifyOtp={setIsVerifyOtp}
					hashId={props.hashId}
          resetTabs={props.resetTabs}
					otp={getOtp}
          sendOtpApi={props.sendOtpApi}></ResetScreen>
			</div>
		</>
	) : (
    <>
    {props.myKey === "reset password" ?
		<>
			<div className={`mt-5  ${styles.ResetScreenHideResponsive}`}>
				<VerifyBasicOtp
					buttonColor={buttonColor}
					profile={'reset your password'}
					onVerifyOtp={setIsVerifyOtp}
					hashId={props.hashId}
					getOtp={setOtp}
					sendOtpApi={props.sendOtpApi}
				/>
			</div>

			{/* responsive otp screen */}
			<div className={styles.ResponsiveModalContainer}>
				<div className='mb-2'>
					<img src={BackArrow} onClick={handleRefresh} />
				</div>
				<VerifyBasicOtp
					buttonColor={buttonColor}
					profile={'reset your password'}
					onVerifyOtp={setIsVerifyOtp}
					hashId={props.hashId}
					getOtp={setOtp}
					sendOtpApi={props.sendOtpApi}
				/>
			</div>
		</> : ""}
    </>
	)
}

export default ResetPassword
