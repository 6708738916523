import React, { useState, useEffect } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import styles from './orderCard.module.css'
import moment from 'moment'
import {MARKET_TOUCHED_ORDER} from '../../constants'
import axios from 'axios'
// import { cancelOrder } from '../../api'

const OrderCard = (props)	 => {
	const [indexRow, setIndex] = useState(null);
	// const [stockCancelId, setStockCancelId] = useState('')
	const [openButtonRow, setOpenButtonRow] = useState(null);
  const [isLoaded, setIsloaded] = useState(true)
  const [showCancelYesNo, setShowCancelYesNo] = useState(null)

  // console.log('props',props )
  const date = props.item.created;
  const newDate = date.split(' ')[0];

  const type =  props.item.type === MARKET_TOUCHED_ORDER ? 'MARKET IF TOUCHED' : props.item.type
  props.item.quantity = props.item.category !== 'stack' && props.item.cumulativeQuantity > 0 ? props.item.cumulativeQuantity : props.item.quantity

  const CancelHovering = () => {
    setIndex(null)
    setShowCancelYesNo(null)
  }

	return (
		<>
      <div className={styles.fullRowWidth} onMouseLeave={() => CancelHovering()} >
				<Row>
					<Col md={1} xs={2} sm={2}>
              <div className={styles.imageMarginLeft}>
                {props.item.logo && isLoaded ? (
                  <div className={styles.imageDiv}>
                    <img
                    onError={() => {
                      setIsloaded(false)
                    }}
                    className={styles.stockImage}
                    src={props.item.logo}
                    alt={props.item.symbol.substring(0, 1)}></img>
                  </div>
                ) : (
                  <div className={styles.imageDiv}>
                  <div className={`text-center ${styles.letterSize}`}>
                    {props.item.symbol.substring(0, 1)}
                  </div>
                  </div>
                )}
              </div>
					</Col>
          <Col md={5} xs={6} sm={5} className='pr-0'>
              <div className={styles.stockTitle}>{props.item.symbol}</div>
              {/* for desktop */}
              <div className={`${styles.stockBuyDate} d-none d-md-block `}>
              {props.item.side}
                <span className={styles.orderBuyDate}>&nbsp;•&nbsp;{moment(props.item.sortKey).format('D MMMM, Y') || '--'}</span>
                &nbsp;•&nbsp;{type}
                {props.item.extendedHours ? ' • Extended' : ''}
               </div>
              <div className={`${styles.stockBuyDate} d-block d-md-none`}>
                {props.item.side} •
                <span className={styles.orderBuyDate}>&nbsp;{moment(props.item.sortKey).format('D MMM, Y') || '--'}</span>
                </div>
              <div className={`${styles.stockBuyDate} d-block d-md-none `}>{type} {props.item.extendedHours ? '• Extended' : ''}</div>
          </Col>
          {/* <Col md={2} xs={1} sm={1}>
          </Col> */}
					<Col md={6} sm={6} xs={6} className={styles.queSectionLaptop} onMouseOver={() => setIndex(props.index)}
          // onMouseOut={() => CancelHovering()}
          >
						{indexRow === props.index && props.item.type !== "MARKET" ?
            <>
            {showCancelYesNo !== props.index  ?
						<button className={styles.cancelOrderButton}
            // onClick={(e) =>props.OrderCancel(props.item)}
            onClick={(e) => setShowCancelYesNo(props.index)}
            >
							Cancel Order
						</button> :
            <div className={styles.YesNoButtonSection}>
              <span className={styles.cancelText}>Confirm order cancellation?</span>
              <button className={styles.YesButton} onClick={(e) =>props.OrderCancel(props.item)}>Yes</button>
              <button className={styles.NoButton} onClick={() => CancelHovering()}>No</button>
            </div>
            }
            </>
						:
						<>
						<div className={styles.stockStatus}>{props.item.addToQueue ? "Queued" : "" }</div>
						<div className={styles.stockQuantity}>{props.item.category === "stock" ? props.item.type === "MARKET" ? props.item.quantity === 0 ? <>For ${props.item.tradeAmount}</> :<>{props.item.quantity} Share</>  :<>{props.item.quantity} Share at ${props.item.tradeAmount}</> : <>Ordered for ${props.item.totalOrderAmount}</>}</div>
						</>
						}
					</Col>

					{/* cancel button responsive  */}
					<Col md={6} sm={5} xs={4} className={`${styles.queSectionMobile} pl-0`} onClick={() => setOpenButtonRow(props.index)}>
						<div className={styles.stockStatus}>{props.item.addToQueue ? "Queued" : "" }</div>
            <div className={styles.stockQuantity}>{
            props.item.category === "stock" ? 
            props.item.type === "MARKET" ? 
            props.item.quantity === 0 ? 
            <>For ${props.item.tradeAmount}</> :
            <>{props.item.quantity} Share</>  :
            <>{props.item.quantity} Share at ${props.item.tradeAmount}</> : 
            <>Ordered for ${props.item.totalOrderAmount}</>}
            </div>
					</Col>
					{openButtonRow === props.index && props.item.type !== "MARKET" ?
					<Col xs={12}>
						<button className={styles.mobileCancelOrderButton} onClick={(e) =>props.OrderCancel(props.item)}>
							Cancel Order
						</button>
					</Col>
					: ""}

				</Row>
      </div>

        {/* <hr className={styles.borderMargin}></hr> */}
				{props.item && props.length - 1  === props.index ?
        "" : <hr className={styles.borderMargin}></hr>}
		</>
	)
}

export default OrderCard

