import React from 'react'
import './Skeleton.css'

const SkeletonElement = ({ type, width, height, color, opacity }) => {
	const classes = `skeleton ${type}`
	return (
		<div
			className={classes}
			style={{
				width: width,
				height: height,
				background: color,
				opacity: opacity
			}}></div>
	)
}

export default SkeletonElement
