import React,{useState,useEffect} from 'react'
import {Table} from 'react-bootstrap'
import styles from '../../components/MorningStar/morningStar.module.css'
import open from '../../public/images/open.svg'
import CustomModal from '../../components/CustomModal/CustomModal'
import { decimalAdjust } from '../../utils'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { getCurrentAccountStatusLink } from '../../common'
import { MORNING_STAR_FEATURE } from '../../constants'

const EtfHoldingTable = ({holdingData,premiumFeatures,activePlan}) =>{
  const history = useHistory()
  const [showModal, setShowModal] = useState(false)
  const [substractWidth, setSubstractWidth] = useState(`calc(50vw)`)
  const handleClose = () => setShowModal(false)
  const handleShow = () => {
    setShowModal(true)
  }
  useEffect(()=>{
    if(window.screen.width <= 425){
      setSubstractWidth(`calc(100vw)-10px`)
    }
  },[])

  const dateFormatter = (data) => {
    let year = data.slice(0,4)
    let month = data.slice(5,7)
    let day = data.slice(8,10)
    month = moment().month(month-1).format("MMM")
    return `${day} ${month} ${year}`
  }

  const showData = holdingData && holdingData.length > 0 ? (
    holdingData.map((item, i) => (
      <tr style={{background: i % 2 !== 0 ? '#F7F7F8' : '#FFFFFF'}} key={i}>
        <td className={styles.code}>{item.symbol !== null && item.symbol !== "" ? item.symbol : "--"}</td>
        <td className={styles.rest}>{item.name !== null && item.name !== "" ? item.name : "--"}</td>
        <td className={styles.rest2} style={{float: 'right'}}>
          <div style={{width: '63px'}}>
            {decimalAdjust('floor', item.percent, -2)}%
          </div>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="3" style={{textAlign: 'center'}} className={styles.noData}>Data not available</td>
    </tr>
  )

  return(
    <>
      <div style={{position:"sticky",zIndex:'10', backgroundColor:"white"}} className={styles.tableResponsive}>
         <Table striped className={styles.fixingTable}
          // style={{width:`${substractWidth}`}}
          // style={{width:'calc(50vw)'}}
          >
          <thead>
            <tr className={styles.headingCss}>
              <th className={styles.instrument}>Symbol</th>
              <th>Name</th>
              <th className={styles.allocation}>Allocation</th>
            </tr>
          </thead>
          <tbody className={styles.morningStarTableBody}>
            {showData}
          </tbody>
        </Table>
        </div>
    

    </>
  )
}

export default EtfHoldingTable