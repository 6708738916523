import React from 'react'
import { Button } from 'react-bootstrap'
import styles from './button.module.css'

const BasicButton = ({ classes, title, disabled, onClick, buttonColor }) => {
	return (
		<Button
			className={`${styles.button} ${classes} themeButton`}
			style={{ backgroundColor: buttonColor }}
			disabled={disabled}
			onClick={onClick}>
			{title}
		</Button>
	)
}

export default BasicButton
