import React, { useState, useEffect} from 'react'
import { getFundsTransactionDetails, getTransBankDetail } from '../../api'
import { addCommasinPrice } from '../../utils'
import Copy from '../../components/AddFund/Transfer/Online/copy'
import CashMgmtToolTip from '../CashManagement/Tooltip'
import styles from './transactionDetailModal.module.css'
import { EmailShareButton } from 'react-share'
import modalCrossButton from '../../public/images/modalCrossButton.svg'
import fraudIcon from '../../public/images/fraudFlag.svg'
import shareIcon from '../../public/images/shareIcon.svg'
import moment from 'moment';
import GenericLoading from '../../components/AddFund/GenericLoading'
import { Spinner } from "react-bootstrap"

const errorCode = {
  STOF0017: "Please establish source of funds for LRS transaction",
  STOF0018: "Name on bank statement does not match",
  STOF0019: "NRO account is not eligible for EasyFund",
  STOF0020: "Upload correct bank statement with payment for EasyFund available ",
  STOF0021: "Since bank account is less than 1 year old, provide additional bank statement to establish fund source",
  STOF0022: "Please establish source of funds for LRS transaction",
  STOF0028: "Mismatch of surname.",
  STOF0036: "Please contact support team",
  STOF0037: "Names on Aadhaar AND PAN do not match"
}


const TransactionDetailsModal = (props) => {

    const { stoTranId , onHide,  DownloadPdfSendMail} = props

    const [transactionDetails, setTransactionDetails] = useState()

    const [payNowClickedLoader, setPayNowCickLoader] = useState(false)

    const [paymentStatus, setPaymentStatus] = useState('')

    const [debitedFrom, setDebitedFrom] = useState('')

    const maskAccountNumber = (accountNumber) => {
        return accountNumber.replace(/\d(?=\d{4})/g, "X")
    }

    const userAccountNumber = () => {
        if(transactionDetails) {
            if(transactionDetails.stoCashAccount && transactionDetails.stoCashAccount.accountNum){
                return maskAccountNumber(transactionDetails.stoCashAccount.accountNum)
            }
        }
        else {
            return '--'
        }
    }

    const payeeAccountNumber = () => {
        if(transactionDetails) {
            if(transactionDetails.contact && transactionDetails.contact.payeeAccountNum){
                return maskAccountNumber(transactionDetails.contact.payeeAccountNum)
            }
        }
        else {
            return '--'
        }
    }

    const findTransactionDetails = () => {
        getFundsTransactionDetails(stoTranId).then(response => {
            if(response.status === 200){
              setTransactionDetails(response.doc ? response.doc : '')
              setPaymentStatus(response.doc.paymentStatus)
              if(response.doc.paymentStatus === "FUND_RECEIVED" || response.doc.paymentStatus === "FAILED"){
                if(payNowClickedLoader === true){
                  setPayNowCickLoader(false)
                  onHide()
                }
              }
            }
        }).catch((err)=>{
            console.error(err)
        })
    }

    const fetchBankDetail = async () => {
      try{
        const resp = await getTransBankDetail(stoTranId)
        if(resp && resp.status === 200 && resp.doc){
          setDebitedFrom(resp.doc.accountNumber);
        }
      }catch(error){
      console.log('error while fetcing bank')
      }
    }

    const date = new Date(transactionDetails ? transactionDetails.createdAt : null)

    const getModalDate = () => {
        if(date) {
            const newDate = date.toString('YYYY-MM-dd').split(' ')

            const finalDate = `${newDate[2]} ${newDate[1]} ${newDate[3] ? newDate[3].slice(-2) : null}`
            const finalTime = `${newDate[4] ? moment(date).format('h:mm A z') : null}`

            const ModalDate = `${finalDate} , ${finalTime} IST`

            return ModalDate
        }
    }

    useEffect(()=>{
        findTransactionDetails()
        fetchBankDetail()
    },[])

    const uploadNowButton = () => {
      props.handleShowMobile({investment: true, easyFund:true,transactionDetails})
      onHide()
    }

    const payNowClick = () => {
      window.open(transactionDetails.paymentLink , "_blank")
      setPayNowCickLoader(true)
    }

    useEffect(() => {
      if (paymentStatus !== 'FUND_RECEIVED' && payNowClickedLoader) {
        var summaryInterval = setInterval(() => {
          findTransactionDetails();
        }, 10000);
      }
      return () => {
        clearInterval(summaryInterval);
      }
    },[payNowClickedLoader])

    const digitsAfterDecimal = (num) => {
      if (Number.isInteger(num)) {
        return `₹${addCommasinPrice(parseFloat(num))}.00`;
      }

      const arr = num.toString().split('.');
      console.log('arr',arr[1].length === 2);
      if(arr[1].length === 1){
        return `₹${addCommasinPrice(parseFloat(num))}0`
      }else{
        return `₹${addCommasinPrice(parseFloat(num))}`
      }
    }

    const downloadSwiftMobile = () => {
      DownloadPdfSendMail(stoTranId)
      onHide()
    }

    return (
        <div className={styles.modalContainer}>
            {transactionDetails ? (
            <div>
                <div className={styles.modalHeader} style={{backgroundColor: transactionDetails ? transactionDetails.paymentStatus === "CREDITED" ? "#0DC014" :  transactionDetails.paymentStatus === "FUND_RECEIVED" || transactionDetails.paymentStatus === "COMPLETED" ? "#FA5B05" : transactionDetails.paymentStatus === "FAILED" || transactionDetails.paymentStatus === "CANCELLED" || transactionDetails.paymentStatus === "REJECTED" ? "#FA274A"  : transactionDetails.paymentStatus === "Initiated" ? "#FA5B05" : transactionDetails.paymentStatus === "WAITING_FOR_FUND" ? "#FA5B05" : transactionDetails.paymentStatus === "HOLD" ? "#FA5B05" : transactionDetails.paymentStatus === "PROCESSED" ? "#FA5B05" : transactionDetails.paymentStatus === "REFUNDED" || transactionDetails.paymentStatus === "EXPIRED" ? "#EFEFF1" : "" : ""}}>
                    <div className={styles.transactionStatus}>
                        <p className={`${styles.status} ${styles.textWhite}`} style={{color: transactionDetails.paymentStatus === "REFUNDED" || transactionDetails.paymentStatus === "EXPIRED"  ? "#3C404A" : "#FFFFFF"}}>
                          {`${ transactionDetails ? transactionDetails.paymentStatus === "COMPLETED" ? "Processing" :
                            transactionDetails.paymentStatus === "FUND_RECEIVED" ? "Processing" :
                            transactionDetails.paymentStatus === "CREDITED" ? "Successful" :
                            transactionDetails.paymentStatus === "HOLD" ? "Processing" :
                            transactionDetails.paymentStatus === "REJECTED" ? "Rejected" :
                            transactionDetails.paymentStatus === "Initiated" ? "Processing" :
                            transactionDetails.paymentStatus === "REFUNDED" ? "Refunded" :
                            transactionDetails.paymentStatus === "FAILED" ? "Failed" :
                            transactionDetails.paymentStatus === "CANCELLED" ? "Cancelled" :
                            transactionDetails.paymentStatus === "PROCESSED" ? "Processing" :
                            transactionDetails.paymentStatus === "EXPIRED" ? "Expired" :
                            transactionDetails.paymentStatus === "WAITING_FOR_FUND" ? "Processing" : "--" : "--"}`}
                          </p>
                        <p className={`${styles.modalText} ${styles.textWhite}`} style={{color: transactionDetails.paymentStatus === "REFUNDED" || transactionDetails.paymentStatus === "EXPIRED" ? "#3C404A" : "#FFFFFF", fontSize:"14px"}}>
                        {transactionDetails && (transactionDetails.tranStatus === 'pending' || transactionDetails.tranStatus === 'COMPLETED') ? 'This may take upto 3 working days.' : getModalDate()}</p>
                    </div>
                    <img src={modalCrossButton} alt="cross-button" onClick={onHide}></img>
                </div>
                {payNowClickedLoader ?  <div style={{textAlign:"center",height:"150px", paddingTop:"60px"}}><Spinner animation="border" className={styles.spinnerColor} style={{color:"#0061d3"}}/></div>:
                <>
                {transactionDetails.paymentStatus === "FUND_RECEIVED" ?
                <div className={styles.transactionNotifier}>
                  <p className={styles.statementText}>Upload Statement</p>
                  <p className={styles.uploadButton} onClick={() => uploadNowButton()}>Upload Now</p>
                </div>
                : transactionDetails.paymentStatus === "WAITING_FOR_FUND" ?
                <div className={styles.transactionNotifier}>
                  <p className={styles.statementText}>Complete Transfer</p>
                  <p className={styles.uploadButton} onClick={() => payNowClick()}>Pay Now</p>
                </div>
                : transactionDetails.paymentStatus === "HOLD" ?
                <div className={styles.transactionNotifier}>
                  <p className={styles.statementText}>Document Required</p>
                  <p className={styles.uploadButton} onClick={() => uploadNowButton()}>Upload Now</p>
                </div>
                : transactionDetails.paymentStatus === "COMPLETED" ?
                <div className={`${styles.transactionNotifier} ${styles.swiftMobileDownload}`}>
                  <p className={styles.statementText}>Download Swift</p>
                  <p className={styles.uploadButton} onClick={() => downloadSwiftMobile()}>Download</p>
                </div>:""}
                <div className={styles.transactionInfo}>
                  <p className={`${styles.modalText} ${styles.textGrey}`} style={{color: "#767C8F"}}>Add Funds</p>
                  { transactionDetails && transactionDetails.tranStatus === "settled"  ? (
                      <p className={`${styles.amount} ${styles.textBlack}`}><span>{transactionDetails && transactionDetails.tranType === "debit" ? '-' : '+'}</span>{transactionDetails ? `$${addCommasinPrice(parseFloat(transactionDetails.transactionAmount))}` : ""}</p>
                  ) : (
                      <p className={`${styles.amount} ${styles.textBlack}`}>{transactionDetails ? `$${addCommasinPrice(parseFloat(transactionDetails.transactionAmount))}.00` : ""}</p>
                  )}
                  {transactionDetails.paymentStatus === "HOLD" && transactionDetails.reasonCode ?
                  <div className={styles.transactionPurpose}>
                    <p className={`${styles.modalText} ${styles.textGrey} ${styles.textCenter}`} title={transactionDetails.reasonCode && errorCode[transactionDetails.reasonCode]}>{errorCode[transactionDetails.reasonCode].length > 35 ?  errorCode[transactionDetails.reasonCode].substring(0, 35)+'...' :errorCode[transactionDetails.reasonCode].reasonCode}</p>
                  </div> : ""}
                </div>
                </>}
                <div className={styles.transactionDetails}>
                    <hr className={styles.divider}/>

                    <div className={styles.modalRow}>
                        <p className={` ${styles.modalText} ${styles.textGrey}`}>Transaction ID</p>
                        <div className={` ${styles.modalRow} ${styles.modalRowInfo}`}>
                            <p className={` ${styles.modalText} ${styles.textBlack}`}>{transactionDetails ? transactionDetails.transactionId ?  transactionDetails.transactionId.length > 30 ? `${transactionDetails.transactionId.substring(0, 25)}...` : transactionDetails.transactionId : '--' : '--'}</p>
                            {transactionDetails && transactionDetails.transactionId ? <Copy isPrimaryIcon={true} data={{label:'Transaction ID', value:transactionDetails.transactionId}}/> : ''}
                        </div>
                    </div>

                    <div className={styles.modalRow}>
                        <p className={` ${styles.modalText} ${styles.textGrey}`}>Transaction Method</p>
                        <p className={` ${styles.modalText} ${styles.textBlack}`}>
                          {
                          transactionDetails.transactionSource === "EASY_FUNDS" && transactionDetails.vendorName === "EASY_FUNDS" ? "EasyFund" :
                          transactionDetails.transactionSource === "BANK_LRS" ?  "Offline Wire" :
                          transactionDetails.transactionSource === "OTHER_TRANSACTIONS" ?  "Netbanking" :
                          transactionDetails.transactionSource === "WITHDRAWALS" ?  "Withdrawal" :
                          // hdfc direct
                          transactionDetails.vendorName === "HDFC_DIRECT" ?  "HDFC Direct" :
                          // Axis bank
                          transactionDetails.vendorName === "AXIS" ? "AXIS" : "--"}
                        </p>
                    </div>

                    <hr className={styles.divider}/>

                    <div className={styles.modalRow}>
                        <p className={` ${styles.modalText} ${styles.textGrey}`}>Debited From</p>
                        <div className={` ${styles.modalRow} ${styles.modalRowInfo}`}>
                            <p className={` ${styles.modalText} ${styles.textBlack}`}>{debitedFrom ? debitedFrom : "--"}</p>
                            <CashMgmtToolTip content={'The amount has been debited from this account'}/>
                        </div>
                    </div>

                    <div className={styles.modalRow}>
                        <p className={` ${styles.modalText} ${styles.textGrey}`}>Credited To</p>
                        <div className={` ${styles.modalRow} ${styles.modalRowInfo}`}>
                            <p className={` ${styles.modalText} ${styles.textBlack}`}>Stockal</p>
                            <CashMgmtToolTip content={'The amount has been credited to this account'}/>
                        </div>
                    </div>

                    <div className={styles.modalRow}>
                        <p className={` ${styles.modalText} ${styles.textGrey}`}>Transaction Charges</p>
                        <div className={` ${styles.modalRow} ${styles.modalRowInfo}`}>
                            <p className={` ${styles.modalText} ${styles.textBlack}`}>{transactionDetails ? transactionDetails.transactionCharges == 0 ? `₹${transactionDetails.transactionCharges}` : transactionDetails.transactionCharges ?  digitsAfterDecimal(transactionDetails.transactionCharges) : '--' : '--'}</p>
                            <CashMgmtToolTip content={'Amount of the transaction charges'}/>
                        </div>
                    </div>
                </div>
                <div className={`${styles.modalRow} ${styles.modalButtonContainer}`}>
                    {/* <EmailShareButton
                            url=''
                            subject={'Transaction Details'}
                            body={`Find the details of your transaction with Transaction ID - ${transactionDetails.stoTranId} :

                                   Date & Time of Transaction : ${getModalDate()}
                                   ${transactionDetails.tranDesc} : $${addCommasinPrice(parseFloat(transactionDetails.tranAmt))}
                                   Transaction ID : ${transactionDetails.stoTranId}
                                   Transaction Status : ${transactionDetails.tranStatus}
                                   Transaction Type : ${transactionDetails.tranType}
                                   Debited From : ${transactionDetails.tranType === "debit"? userAccountNumber() : transactionDetails.payeeName}
                                   Credited to : ${transactionDetails.tranType === "debit"? payeeAccountNumber() : userAccountNumber()}
                                   Transaction Amount : $${addCommasinPrice(parseFloat(transactionDetails.tranAmt))}
                                   Transaction Charges : ${transactionDetails.tranFees ? `$${transactionDetails.tranFees}` : '--'}
                                   Remarks : ${transactionDetails.tranRemarks}
                                `}
                    >
                        <button className={`${styles.modalButton} ${styles.modalButtonBlue}`}>
                            <img src={shareIcon} alt="share-icon"></img>
                            <p className={styles.modalText}>Share Details</p>
                        </button>
                    </EmailShareButton> */}
                    <a href="mailto:customersuccess@stockal.com" target="_blank" rel="noopener noreferrer" style={{width:"100%"}}>
                        <button className={`${styles.modalButton} ${styles.modalButtonBlue}`} style={{width:"100%"}}>
                            {/* <img src={fraudIcon} alt="support-icon"></img> */}
                            <p className={styles.modalText}>Contact Support</p>
                        </button>
                    </a>
                </div>
            </div>
            ) : ''}
        </div>
    )
}

export default TransactionDetailsModal
