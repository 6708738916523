import React,{useRef,useEffect} from 'react'
import { Row, Col, Table , Spinner} from 'react-bootstrap'
import styles from './themeTable.module.css'
import { useHistory } from 'react-router-dom'
import ThemeRow from './themeRow'

const rowHeaders = [
	'Symbol',
	'Name',
	'Price',
	'Change(%)',
	// 'Market Cap',
	'52w High',
	'52w Low'
]

const ThemeTable = ({data,setPage,page,prevScroll,setprevScroll,pageLoading,setData,stockType}) => {
  const refDiv = useRef();
  let history = useHistory()

  const scrollDiv = () => {
    if(refDiv.current.scrollTop + refDiv.current.offsetHeight >= refDiv.current.scrollHeight){
      setPage(page + 1)
      setprevScroll(refDiv.current.scrollTop)

     }
  }

  // useEffect(() => {
  //   if(data.length > 0){
  //   refDiv.current.scrollTop = prevScroll
  //   }
  // },[prevScroll])


  const onLink = (link,code) => {
    history.push(`/${link}/${code}`)
  }

  // const updateData = (index) => {
  //   const newArr = [...data]
  //   newArr[index]["isImgLoaded"] = false
  //   setData(newArr)
  // }

	return (
		<>
			<Row className={styles.TableMarginTop}>
				<Col className={styles.stocksEtf}>{stockType === 'Etf' ? "ETFs" : stockType}</Col>
			</Row>
      {data.length > 0 ?
			<Row className={`m-0 mt-4 ${data.length > 7 ? styles.scrollDiv : ''}`}
      ref={refDiv} onScroll={scrollDiv}
      >
				<Table borderless className={styles.dataTable}>
					<thead className={styles.stickyHeader}>
						<tr>
							<th className={styles.tableborder}></th>
							{rowHeaders.map((item, index) => {
								return (
									<th key={index} className={styles.tableHead}>
										{item}
									</th>
								)
							})}
						</tr>
					</thead>
					<tbody>
						{data.map((item, index) => {
              const link = item.stockType === 'etf' ? 'etfdetail' : item.stockType === 'stack' ? 'stackdetail' : 'stockdetail'
              return(
                <ThemeRow link={link}
                logo={item.logo}
                code={item.code}
                company={item.company}
                price={item.price}
                percentChange={item.percentChange}
                marketCapitalization={item.marketCapitalization}
                yearHigh={item.yearHigh}
                yearLow={item.yearLow}
                onLink={onLink}
                close={item.price - item.change}
                />
              )
						})}
            {pageLoading ?
            <tr>
              <td colSpan="7">
                <div className={styles.spinner}><Spinner animation="border" className={styles.spinnerColor}/></div>
             </td>
            </tr> : '' }
					</tbody>
				</Table>
			</Row> : <Row className={`${styles.stocksEtf} ${styles.notFound}`}>Stocks Not found</Row>}
		</>
	)
}

export default ThemeTable
