import React,{useState,useContext, useEffect} from 'react'
import UploadingStatement from './UploadingStatement'
import styles from './easyfund.module.css'
import VerifyAdhar from './VerifyAdhar'
import { AddFundContext } from "../../AddFundContext"
import GenericLoading from "../../GenericLoading"
import SplashScreen from './SplashScreen'
import Success from '../Common/Success'
import AaSplashScreen from './AaSplashScreen'
import ReviewEasyFund from './ReviewEasyFund'
import DigiLockerScreen from './DigiLockerScreen'
import {getKycStatus,getTransBankDetail} from '../../../../api'

const EasyFund = () => {
  const {subPage, setSubPage, hashID, selectedAccount,fund,setErrorAmount,setUploadStatement, transferOptions, setPage, setOnErrorRetry, setShowGenericError,showGenericError, setShowGenericErrorOption, PAGE, SUBPAGE,showGenericErrorOption,easyfundTransDetail,setSelectedAccount,setFund,setTransactionId,setShowTryAgain} = useContext(AddFundContext)
  const [loading, setLoading] = useState(false)
  const [showDesc,setShowDesc] = useState(false)
  const [msg,setMsg] = useState('Transfer request acknowledged')
  const [showRemitance,setShowRemitance] = useState(true)
  const EASYFUND = PAGE.EASYFUND

  useEffect(() => {
    const fetchData = async () => {
      let success = true
      setLoading(true)
      try{
       const resp = await getKycStatus()
       if(resp && resp.doc && resp.doc.kyc){
         if(resp.doc.kyc !== 'verified'){
          setSubPage(SUBPAGE[EASYFUND].ACTIVATE)
         }
         else{
          setSubPage(SUBPAGE[EASYFUND].REVIEW)
         }
       }
       else{
        setSubPage(SUBPAGE[EASYFUND].ACTIVATE)
       }
       setLoading(false)
       success= true
      }
      catch(error){
        success= false
        setLoading(false)
      }
      if(success && showGenericErrorOption){
        setShowGenericErrorOption(false)
        setOnErrorRetry(null)
        }else if(!success){
            setOnErrorRetry((showGenericErrorOption) => fetchData())
            setShowGenericErrorOption(true)
        }
    }

    const fetchBankDetail = async (id) => {
      let success = true
      setLoading(true)
       try{
         const resp = await getTransBankDetail(id)
         if(resp && resp.status === 200 && resp.doc){
         setSelectedAccount(resp?.doc)
         setLoading(false)
         }
         success= true
       }catch(error){
        success= false
        setLoading(false)
       }
       if(success && showGenericError){
        setShowGenericError(false)
        setOnErrorRetry(null)
        }else if(!success){
            setOnErrorRetry((showGenericError) => fetchBankDetail())
            setShowGenericError(true)
        }
    }

    if(fund > 25000){
      setShowGenericErrorOption(true,setShowTryAgain(false,setErrorAmount('More than 25k')))
    }
   else{
    if(easyfundTransDetail){
      // setLoading(true)
      fetchBankDetail(easyfundTransDetail?.transactionId)
      setFund(easyfundTransDetail?.transactionAmount,
        setTransactionId(easyfundTransDetail?.transactionId))
        getMapping(easyfundTransDetail?.paymentStatus)
    }else{
       fetchData()
    }
  }
  },[])

  const getMapping = (value) => {
    if(value === 'FUND_RECEIVED'){
      setSubPage(SUBPAGE[EASYFUND].UPLOADSTATEMENT,setUploadStatement(SUBPAGE[PAGE.EASYFUND].AUTOUPLOADSTATEMENT))
    }
    else if(value === 'HOLD'){
      if(easyfundTransDetail?.bankStatementDoc1){
        setSubPage(SUBPAGE[EASYFUND].VERIFYAADHAR)
      }else{
        setSubPage(SUBPAGE[EASYFUND].UPLOADSTATEMENT, setUploadStatement(SUBPAGE[PAGE.EASYFUND].UPLOADSTATEMENT))
      }
    }
  }

  return(
    <>{loading ? <div className={styles.genericLoading}><GenericLoading /> </div> :
    <>
        {subPage===SUBPAGE[EASYFUND].ACTIVATE && <SplashScreen/>}
        {subPage===SUBPAGE[EASYFUND].VERIFYAADHAR && <VerifyAdhar setMsg={setMsg}/>}
        {subPage===SUBPAGE[EASYFUND].UPLOADSTATEMENT && <UploadingStatement setShowDesc={setShowDesc}/>}
        {subPage===SUBPAGE[EASYFUND].REVIEW && <ReviewEasyFund/>}
        {subPage===SUBPAGE[EASYFUND].AUTOUPLOADSTATEMENT && <AaSplashScreen setMsg={setMsg}
                                                            setShowRemitance={setShowRemitance}/>}
        {subPage===SUBPAGE[EASYFUND].VERIFYAADHARAUTO && <DigiLockerScreen/>}
        {subPage === SUBPAGE.SUCCESS &&
        <Success msg={msg}
        remittance={showRemitance}
        desc={showDesc ? 'Please ensure that you upload your bank statements within 2 business days, else your transfer request may get rejected.' : ''}/>}
    </>}
</>
  )
}

export default EasyFund
