import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import styles from './instruction.module.css'
import {infoError,} from './assets'

const DetailError=({fetchBrokerDetails, label, className})=>{
    const [fetching, setFetching] = useState(false);
    const [counter, setCounter] = useState(0)
    const onTryAgain= async ()=>{
        if(counter<3){
            if(!fetching){
                setFetching(true)
                await fetchBrokerDetails()
                setFetching(false)
                setCounter(counter+1);
            }            
        }      
    }
    return (
        <div style={{color:'#005dd6'}} className={`d-flex align-items-center ${styles.download} ${className}`}>
            {!fetching ? 
                <img src={infoError}    />
                :<Spinner animation="border" className={styles.spinnerColor} style={{width:'20px',height:'20px', margin:'2px'}}/>
            }
            &nbsp;
            {counter<3?
                (!fetching ? 
                    <div className='d-flex'>
                        <div style={{color:'#DC6803'}}>Unable To Fetch {label}</div>
                        <div style={{paddingLeft:'6px'}} className={styles.link} onClick={()=>onTryAgain()}>Try Again</div>
                    </div> 
                    :<span className='d-flex'>Fetching {label}</span>       
                )
                :<div className='d-flex'>
                    <div style={{color:'#DC6803'}}>Something Went Wrong</div>
                    <div style={{paddingLeft:'6px'}} className={styles.link} onClick={()=>window.fcWidget.open()}>Get Help</div>
                </div>
            }            
        </div>
    )   
}
export default DetailError