import React from 'react'
import styles from './ForgotPassword.module.css'
import Lottie from 'react-lottie-player'
import SuccessLottie from '../../public/lottie/success.json'

const UpdatePasswordSuccessful = () => {
	return (
		<>
			{/* <div style={{ display: 'flex', justifyContent: 'center' }}>
				<img src={successfulSvg} />
			</div>*/}

			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<Lottie
					rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
					loop={true}
					animationData={SuccessLottie}
					play
					style={{ width: 200, height: 200 }}
					onComplete={() => {}}
				/>
			</div>
			<div
				className={styles.title}
				style={{ padding: '0px 26px', textAlign: 'center', marginTop: '8px' }}>
				Your password has been updated
			</div>

			<div
				className={styles.subTitle}
				style={{ padding: '0px 85px', marginTop: '8px', textAlign: 'center' }}>
				You can now log in with your updated credentials.
			</div>
		</>
	)
}

export default UpdatePasswordSuccessful
