import React from 'react'
import styles from './InputLabel.module.css'
import { Row, FormControl } from 'react-bootstrap'

const inputLabel = ({ label, onChange, value, placeholder, name, type }) => {
	return (
		<>
			<div className={`mb-1 ${styles.inputLabel}`}>{label}</div>
			<div style={{ width: '100%' }}>
				<FormControl
					value={value}
					onChange={onChange}
					className={styles.input}
					placeholder={placeholder}
					name={name}
					type={type}>
				</FormControl>
			</div>
		</>
	)
}

export default inputLabel
