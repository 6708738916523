import React ,  { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import styles from './CashMgmtModal.module.css'

const CashMgmtModal = ({showModal, openModal, children}) => {

  return (
    <Modal show={showModal} onHide={openModal} className={styles.cashModal} centered>
        <Modal.Body>
          {children}
        </Modal.Body>
    </Modal>
  );
}

export default CashMgmtModal