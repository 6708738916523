import React, { useEffect, useState, useContext, useRef } from 'react'
import {
	Row,
	Card,
	FormCheck,
	OverlayTrigger,
	Tooltip,
	Overlay
} from 'react-bootstrap'
import { logout, onBoardingUserScope} from '../../api'
import InfoLogo from '../../public/images/infoLogo.svg'
import { useHistory } from 'react-router-dom'
import SignUpButton from './SignUpButton'
import { ThemeContext } from '../../Context/ThemeContext'
import styles from './SignUp.module.css'
import NewHeader from '../NewSignupComponents/NavBar/NewHeader'
import VerifyIdentityToolTip from './VerifyTooltip'

const VerifyIdentity = () => {
	let history = useHistory()
	const { isSavingAccount,location} = useContext(ThemeContext)
	const [ischecked, setIschecked] = useState(false)
	const [loading, setLoading] = useState(true)

  const getUserScope = async() => {
    const custId = sessionStorage.getItem('custId') ?
                   sessionStorage.getItem('custId') :
                   sessionStorage.getItem('userInfo') && JSON.parse(sessionStorage.getItem('userInfo')).customerId ?
                   JSON.parse(sessionStorage.getItem('userInfo')).customerId
                   : ''
    try{
      const response = await onBoardingUserScope(custId)
      if(response.code === 200 && response.data){
        const scopeUser =  response.data.split(' ')
        if(scopeUser && scopeUser.length && scopeUser.includes('cash-mgmt')){
          sessionStorage.setItem('savingAccount',true)
        }
        else{
          history.push('/document')
        }
      }
      setLoading(false)
    }catch(error){
     setLoading(false)
    }
  }

	useEffect(() => {
		// const userData = sessionStorage.getItem('userInfo')
		// 	? JSON.parse(sessionStorage.getItem('userInfo'))
		// 	: ''
		// if (!isSavingAccount || userData.country !== 'IND') {
		// 	history.push('/document')
		// }



    // if (!isSavingAccount || location !== 'India') {
		// 	history.push('/document')
		// }
    getUserScope()
	}, [])

	const onClickLogout = () => {
		var token = JSON.parse(sessionStorage.getItem('token'))
		//webengage logout
		// eslint-disable-next-line no-undef
		webengage.user.logout()
		// delete token.refreshToken
		var body = {
			accessToken: token && token.accessToken
		}
		// var accessToken = JSON.stringify(token)
		logout(JSON.stringify(body)).then(response => {
			sessionStorage.clear()
			localStorage.clear()
			history.push('/')
		})
	}

  const onSubmit = () => {
    localStorage.setItem('isCashAccount',ischecked)
    if(ischecked){
       // eslint-disable-next-line no-undef
       webengage.track("User cash account consent")
    }
    history.push('/document')
  }

	return (
		<>
			<NewHeader message='' linkName='Log Out' onClickLink={onClickLogout} />
			{loading ? (
				''
			) : (
				<div className={styles.cardDiv}>
					<Card className={styles.cardSignUp}>
						<Card.Body className={styles.cardBodySignup}>
							<Row className={styles.title}>Select types of accounts</Row>
							<Row className={styles.subTitle}>
								We set up an Investment A/C by default. Would you also like to
								opt for a Cash Account?
							</Row>
							<Row className={styles.verifyCard}>
								<div className={styles.verifyTitle}>
									 Invest Account
									<VerifyIdentityToolTip
										content={
											<div
												style={{
													backgroundColor: '#FFFFFF',
													padding: '10px',
													textAlign: 'left',
													border: '0'
												}}>
												<div className={styles.tooltipLabel}>
													What is an Investment Account?
												</div>
												<div className={styles.tooltipText}>
												  Investment A/C allows you to invest in
													companies that operate in markets across the globe.
													Comes with super-low fees and easy compliance.
												</div>
											</div>
										}
									/>
								</div>
								<div className={styles.verifySubTitle}>
									Requires PAN/Aadhaar Card
								</div>
							</Row>
							<Row
								className={styles.verifyCard}
								style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div>
									<div className={styles.verifyTitle}>
										Cash Account
										<VerifyIdentityToolTip
											content={
												<div
													style={{
														backgroundColor: '#FFFFFF',
														padding: '10px',
														textAlign: 'left',
														border: '0'
													}}>
													<div className={styles.tooltipLabel}>
														What is a Cash Account?
													</div>
													<div className={styles.tooltipText}>
														Cash A/C allows you to make investments in
														USD and spend across the globe without stressing
														over exchange rates.
													</div>
												</div>
											}
										/>
									</div>
									<div className={styles.verifySubTitle}>Requires Passport</div>
								</div>
								<div className={styles.verifyCheckBox}>
									<div
										className={`custom-control custom-checkbox ${styles.checkBox}`}>
										<input
											type='checkbox'
											className={`custom-control-input ${styles.checkBoxInput}`}
											id='panCheck'
											name='panCheck'
											value={ischecked}
											onChange={() => setIschecked(!ischecked)}
											checked={ischecked}
										/>
										<label
											className={`custom-control-label ${styles.checkMark}`}
											htmlFor='panCheck'></label>
									</div>
								</div>
							</Row>
							<Row className='m-0 mt-5'>
								<SignUpButton
									title='Upload Documents'
									onClick={onSubmit}
									// disabled={!disableButton || isSubmitting}
								/>
							</Row>
						</Card.Body>
					</Card>
				</div>
			)}
		</>
	)
}

export default VerifyIdentity
