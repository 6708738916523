import { initialState } from '../initialState'
import { TOP_STACKS } from '../actions/action'

const topStacks = (state = initialState.topStacks, action) => {
	switch (action.type) {
		case TOP_STACKS: {
			return { ...state, topStacks: action.payload }
		}
		default: {
			return state
		}
	}
}

export default topStacks
