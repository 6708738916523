import React, { Component } from 'react'
import { getPlans, checkCouponDiscount, generateOrderId, savePayment, upgradePayment, upgradePlanPrice,LeadCapture } from '../../api';
import { currencyFormatter } from "../../common";
import moment from 'moment';
import { setRazorPaySetting, getRazorPayUserId, setCurrentPlan } from '../../common'
import PlanComponent from './PlanComponent'
// import Error from '../Modal/Error';
// import Success from '../Modal/Success';
// import Alert from '../Modal/Alert';
import { ThemeContext } from '../../Context/ThemeContext'

class Plan extends Component {
  constructor(props) {
    super(props);
    const pageType = props.pageType; //new|renew|upgrade
    const currency = sessionStorage.getItem('planInfo') && JSON.parse(sessionStorage.getItem('planInfo')).details.currency ?
      JSON.parse(sessionStorage.getItem('planInfo')).details.currency
      : (sessionStorage.getItem('userInfo') && JSON.parse(sessionStorage.getItem('userInfo')).country !== 'IND' ? 'USD' : 'INR');

    this.state = {
      loader: false,
      isTermsAndCondition: true,
      couponArea: true,
      couponInputBox: true,
      isCouponApplied: false,
      buttonText: "Complete Payment",

      email: sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')).emailAddress1 : '',
      name: sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')).firstName + ' ' + JSON.parse(sessionStorage.getItem('userInfo')).lastName : '',
      // accessToken:sessionStorage.getItem('token') ? JSON.parse(sessionStorage.getItem('token')).accessToken :'',

      pageType: pageType,
      pageTitle: pageType === 'new' ? 'Choose your Plan' : (pageType === 'upgrade' ? 'Upgrade Plan' : 'Renew Plan'),
      oldPlan: (pageType !== 'new' && sessionStorage.getItem('planInfo')) ? JSON.parse(sessionStorage.getItem('planInfo')) : '',
      currency: currency,
      planList: [],
      selectedPlan: {},
      selectedPlanAmount: '',
      payableAmount: '',
      couponCode: '',
      couponMsg: '',
      rzpuserid: '',
      data: {},
      orderId: '',
      buttonColor: '',
      planMsg:'',
    }
  }
  static contextType = ThemeContext


  setButtonText(amount, selectedPlanAmount) {
    let buttonText;
    if (amount !== selectedPlanAmount)
      buttonText = amount ? 'Proceed to Pay &nbsp; <strike> ' + this.getCurrency(selectedPlanAmount) + ' </strike> &nbsp; ' + this.getCurrency(amount) : 'Continue';
    else
      buttonText = amount ? 'Proceed to Pay ' + this.getCurrency(selectedPlanAmount) : 'Continue';
    return buttonText;
  }

  // setAccessToken(){
  //   this.setState({accessToken:sessionStorage.getItem('token') ? JSON.parse(sessionStorage.getItem('token')).accessToken :''});
  // }
  getCurrency(amount) {
    return currencyFormatter(this.state.currency, amount);
  }

  async savePaymentAPI(data) {
    console.log(data.orderId);
    this.setState({ loader: true });
    const resp = await savePayment(data);
    this.setState({ loader: false });
    return resp;
  }

  async upgradePaymentAPI(data) {
    console.log(data.orderId);
    this.setState({ loader: true });
    const resp = await upgradePayment(data);
    this.setState({ loader: false });
    return resp;
  }

  async planListAPI() {
    this.setState({ loader: true });
    const resp = await getPlans(this.state.currency, this.state.pageType);
    this.setState({ loader: false });
    return resp;
  }

  async generateOrderId() {
    const data = {
      amount: this.state.payableAmount * 100,
      currency: this.state.currency,
      payment_capture: 1
    };
    this.setState({ loader: true });
    const resp = await generateOrderId(data);
    this.setState({ loader: false });
    return resp;
  }

  // async paymentStatusAPI(){
  //   this.setState({loader:true});
  //   const resp =  await checkPaymentStatus(this.state.data.paymentId);
  //   this.setState({loader:false});
  //   return resp;
  // }

  async validateCouponAPI() {
    this.setState({ loader: true });
    const resp = await checkCouponDiscount(this.state.couponCode, this.state.selectedPlan.planId);
    this.setState({ loader: false });
    return resp;
  }

  async upgradePlanPriceAPI(selectedPlanId, amount) {
    this.setState({ loader: true });
    const resp = await upgradePlanPrice(selectedPlanId, amount);
    this.setState({ loader: false });
    return resp;
  }

//   setAmountAndButtonText(currentAmount) {
//     if (this.state.pageType === 'upgrade') {
//       if (this.state.oldPlan && currentAmount) {
//         // console.log(oldPlan)
//         // if paymentAmount exists then record will have paymentDate
//         let startDate  = this.state.oldPlan.startDate;
//           if (!startDate) startDate = this.state.oldPlan.paymentDate;
//           if(startDate){
//             startDate = moment(startDate, "YYYY-MM-DD HH:mm:ss:sss");
//             const today = moment();
//             // console.log("start date--"+startDate+"---today--"+today);
//             const usedDaysOldPlan = today.diff(startDate, 'days');
//             console.info('startDate',startDate)
//             console.info('today',today)
//             console.info('usedDaysOldPlan(today- startDate',usedDaysOldPlan)
//             // if (usedDaysOldPlan) {

//               // if (usedDaysOldPlan < 360) {
//               //   const oldPayableAmount = this.state.oldPlan.paymentAmount;
//               //   const perDayAmount = oldPayableAmount / 360;
//               //   const consumedAmount = usedDaysOldPlan * perDayAmount;
//               //   const amount = Math.round(currentAmount - consumedAmount);
//               //   console.log("---usedDaysOldPlan---:" + usedDaysOldPlan + ",---perDayAmount---" + perDayAmount + ",---consumedAmount -- " + consumedAmount + ",---payable amount---" + amount + "--amount--" + currentAmount);
//               //   // const buttonText = amount ? 'Proceed to Pay &nbsp; <strike> '+this.getCurrency(currentAmount)+' </strike> &nbsp; '+this.getCurrency(amount) :'Continue';
//               //   const buttonText = this.setButtonText(amount, currentAmount);

//               //   return { amount, buttonText };
//               // }
//               // else {
//               //   console.log('usedDaysOldPlan--' + usedDaysOldPlan)
//               //   console.log('Your plan has expired Please renew')
//               // }

//               let newAmount;
//               //new correct logic
//               if(usedDaysOldPlan){
//                const remainDaysNewPlan = 360 - usedDaysOldPlan;
//                const perDayChargeNewPlan = currentAmount/360;
//                newAmount = remainDaysNewPlan * perDayChargeNewPlan;
//                console.info('remainDaysNewPlan(360 - usedDaysOldPlan)',remainDaysNewPlan)
//                console.info('perDayChargeNewPlan(currentAmount/360)',perDayChargeNewPlan)
//                console.info('newAmount(remainDaysNewPlan * perDayChargeNewPlan)',newAmount)
//               }else{
//                 newAmount = currentAmount
//                 console.info('currentAmount',currentAmount)
//               }
// debugger
//               const amount = Math.round(newAmount - this.state.oldPlan.paymentAmount);
//               if(amount <= 0)
//               return {amount:0,buttonText:'Continue'}
//               console.info('this.state.oldPlan.paymentAmount',this.state.oldPlan.paymentAmount)
//               console.info('amount(newAmount - this.state.oldPlan.paymentAmount)',newAmount - this.state.oldPlan.paymentAmount)
//               console.info('amount(Math.round(newAmount - this.state.oldPlan.paymentAmount))',amount)
//               const buttonText = this.setButtonText(amount, currentAmount);
//               return { amount, buttonText };

//           // }
//         }
//       }
//     }
//     return;
//   }



setAmountAndButtonText= async(currentAmount, strikeAmount='', selectedPlanId=this.state.selectedPlan.planId)=> {
  debugger
  if (this.state.pageType === 'upgrade' && this.state.oldPlan && currentAmount) {
    // if (this.state.oldPlan && currentAmount) {
//       // console.log(oldPlan)
//       // if paymentAmount exists then record will have paymentDate
//       let startDate  = this.state.oldPlan.startDate ? this.state.oldPlan.startDate : this.state.oldPlan.subscriptionDate;
//         if (!startDate) startDate = this.state.oldPlan.paymentDate;
//         if(startDate){
//           startDate = moment(startDate, "YYYY-MM-DD HH:mm:ss:sss");
//           const today = moment();
//           // console.log("start date--"+startDate+"---today--"+today);
//           const usedDaysOldPlan = today.diff(startDate, 'days');
//           console.info('startDate',startDate)
//           console.info('today',today)
//           console.info('usedDaysOldPlan(today- startDate',usedDaysOldPlan)
//             let oldAmount;
//             //new correct logic
//             if(usedDaysOldPlan && this.state.oldPlan.paymentType === 'subscription'){
//              const remainDaysOldPlan = 360 - usedDaysOldPlan;
//              if(remainDaysOldPlan > 0){
//              const perDayChargeOldPlan = this.state.oldPlan.paymentAmount/360;
//              console.info('oldPaid amount',this.state.oldPlan.paymentAmount)
//              oldAmount = remainDaysOldPlan * perDayChargeOldPlan;
//              console.info('remainDaysOldPlan(360 - usedDaysOldPlan)',remainDaysOldPlan)
//              console.info('perDayChargeOldPlan(oldPlan.paymentAmount/360)',perDayChargeOldPlan)
//              console.info('oldAmount(remainDaysOldPlan * perDayChargeOldPlan)',oldAmount)
//              }else{
//               oldAmount = this.state.oldPlan.paymentAmount;
//               console.info('currentAmount',currentAmount)
//              }
//             }
//             else{
//               oldAmount = this.state.oldPlan.paymentAmount;
//               console.info('currentAmount',currentAmount)
//             }
//             const amount = Math.round(currentAmount - oldAmount);
//             if(amount <= 0)
//             return {amount:0,buttonText:'Continue'}
// debugger
//             strikeAmount =  strikeAmount ? strikeAmount : currentAmount;
//             // const buttonText = this.setButtonText(amount, currentAmount);
//             const buttonText = this.setButtonText(amount, strikeAmount);
//             return { amount, buttonText };

//         // }
//       }

      const resp = await this.upgradePlanPriceAPI(selectedPlanId, currentAmount);
      if(resp.code === 200)
      {
        const amount = resp.data.payableAmount;
        if(amount <= 0)
          return {amount:0,buttonText:'Continue'}
        strikeAmount =  strikeAmount ? strikeAmount : currentAmount;
        const buttonText = this.setButtonText(amount, strikeAmount);
        return { amount, buttonText };
      }
      else {
        console.log(resp.message)
        this.setState({ planMsg: resp.message })
    // }
    }
  }
  return;
}
  selectPlan= async(planList, selectedPlanIndex)=> {
    const currentAmount = (parseInt(planList[selectedPlanIndex].subscriptionAmount) + parseInt(planList[selectedPlanIndex].accountOpeningFees));
    let buttonText = currentAmount ? 'Proceed to Pay ' + this.getCurrency(currentAmount) : 'Continue';
    let amount = currentAmount;
    const result = await this.setAmountAndButtonText(currentAmount,'', planList[selectedPlanIndex].planId);
    if (result) {
      amount = result.amount;
      buttonText = result.buttonText;
    }
    this.setState({
      couponInputBox: true,
      couponMsg: '',
      couponCode: '',
      isCouponApplied: false,
      selectedPlan: planList[selectedPlanIndex],
      selectedPlanAmount: currentAmount,
      payableAmount: amount,
      buttonText: buttonText,
      // couponArea: amount ? true : false,
    });
  }

  componentDidMount = () => {
    // this.setAccessToken();
    // $('#planModal').modal('show');
    const site = this.context
    console.log('main',site)
    this.setState({ razorpaySetting: site.razorpaySetting });
    this.planList();
    const { buttonColor, disclosuresLink, termsAndConditionsLink } = this.context
    let updatedDisclosuresLink = disclosuresLink
    if (disclosuresLink === '') {
      const country = sessionStorage.getItem('userInfo') && JSON.parse(sessionStorage.getItem('userInfo')).countryID ? JSON.parse(sessionStorage.getItem('userInfo')).countryID : ''
      const citizenship = sessionStorage.getItem('userInfo') && JSON.parse(sessionStorage.getItem('userInfo')).country ? JSON.parse(sessionStorage.getItem('userInfo')).country : ''
      updatedDisclosuresLink = `https://apps.drivewealth.com/disclosures/?citizenship=${citizenship}&country=${country ? country : citizenship}&accountType=Individual`
    }
    this.setState({ buttonColor, termsAndConditionsLink, updatedDisclosuresLink })
  }

  async planList() {
    // if(this.state.accessToken){
    const resp = await this.planListAPI();
    if (resp.code === 200 && resp.data) {
      if (resp.data.length !== 0) {
        let selectedPlanIndex = resp.data.findIndex(plan => plan.defaultPlan === 'true' || plan.defaultPlan === true);
        if (selectedPlanIndex === -1) {
          //if incase no default plan then make last plan selected
          selectedPlanIndex = resp.data.length - 1;
        }
        const currency = resp.data[selectedPlanIndex].currency || 'INR';
        //set key by partner code
        const razorpaySetting = setRazorPaySetting(this.state.razorpaySetting);
        //get key by currency
        const rzpuserid = getRazorPayUserId(razorpaySetting, currency)
        this.setState({
          planList: resp.data,
          currency,
          rzpuserid
        }, () => this.selectPlan(resp.data, selectedPlanIndex));
      }
      else {
        console.log('Plans are not available.')
      }
      // this.setState({planMsg:''})
    } else {
      //error
      this.setState({planMsg: resp.message })
      console.log(resp.message)
      // this.props.handleClose('error')
    }
    // }
    // else{
    //   alert('accessToken not found');
    //   // $('#planModal').modal('close');
    //   // this.props.history.push("/signin");
    // }
  }

  async validateCoupon() {
    const coupounResp = await this.validateCouponAPI();
    if (coupounResp.code === 200) {
      let myObj = {...coupounResp.data};
      // myObj.couponMsgColor = '#43dc43';
      myObj.isCouponApplied = true;
      myObj.buttonText = myObj.payableAmount ? 'Proceed to Pay &nbsp; <strike> ' + this.getCurrency(this.state.selectedPlanAmount) + ' </strike> &nbsp; ' + this.getCurrency(myObj.payableAmount) : 'Continue';
      if(myObj.payableAmount === this.state.selectedPlanAmount)
        myObj.buttonText = 'Proceed to Pay ' + this.getCurrency(this.state.selectedPlanAmount);
debugger

      const result = await this.setAmountAndButtonText(myObj.payableAmount, this.state.payableAmount);
      if (result) {
        myObj.payableAmount = result.amount;
        myObj.buttonText = result.buttonText;
      }

      // document.getElementById("txtDiscountCouponCode").disabled = true;
      this.setState(myObj);
      this.setState({ couponInputBox: !this.state.couponInputBox })
      // if (coupounResp.data.validPaymentMode === 'offline')
      //   this.setState({ displayTransactionId: 'table-row' })
      // else
      //   this.setState({ displayTransactionId: 'none' })
    } else {
      // this.setState({ couponMsg: 'Invalid Coupon',isCouponApplied:false})
      // this.setState({ couponMsg: coupounResp.message === 'invalid request' ? 'Invalid Coupon': coupounResp.message, isCouponApplied:false})
      this.setState({ couponMsg: (coupounResp.message === 'Invalid coupon' && this.state.pageType === 'upgrade') ? 'Sorry! This coupon code is not valid for upgrade' : coupounResp.message, isCouponApplied: false })
    }
  }

  applyRemoveButton = async(from) => {
    if (from === 'remove') {
      let amount = this.state.selectedPlanAmount;
      let buttonText = amount ? 'Proceed to Pay ' + this.getCurrency(this.state.selectedPlanAmount) : 'Continue';
      debugger
      const result = await this.setAmountAndButtonText(this.state.selectedPlanAmount);
      if (result) {
        amount = result.amount;
        buttonText = result.buttonText;
      }
      console.log(this.state)
      // debugger
      this.setState({
        buttonText,
        couponInputBox: !this.state.couponInputBox,
        couponCode: '',
        isCouponApplied: false,
        couponMsg: '',

        // selectedPlan:this.state.planList[selectedPlanIndex],
        // selectedPlanAmount:amount,
        payableAmount: amount,
      })
    }
    else {
      if (this.state.couponCode) {
        this.validateCoupon();
      }
    }
  }

  razorPayPopUp(orderId) {
    let themeLogo;
    const { siteLogo } = this.context
    if (siteLogo)
      themeLogo = siteLogo

    let themePrimaryColor = "#0061D3";
    if (this.state.buttonColor)
      themePrimaryColor = this.state.buttonColor
    const that = this;
    let options = {
      "key": this.state.rzpuserid,
      "key_secret": "9fYykTk74KPsgom6LXJK5anU",
      "amount": this.state.payableAmount * 100,
      "name": this.state.selectedPlan.planName,
      "description": (this.state.selectedPlan.subscription === "true" || this.state.selectedPlan.subscription === true) ? "Annual subscription fees" : "Account opening fees",
      "image": themeLogo,
      "order_id": orderId,
      "handler": async function (response) {
        if (response.razorpay_payment_id) {
          const dataObj = {
            paymentId: response.razorpay_payment_id,
            paymentStatus: 'successful',
            paymentDate: moment().utc().format('YYYY-MM-DD HH:mm:ss:SSS'),
          }
          await that.savePayment(dataObj);
          // const doc = that.checkPaymentStatus(response.razorpay_payment_id);
          // if (doc.data.status === 'captured' || doc.data.status === 'authorized') {
          // }
        } else {
          // that.setState({
          //   errMsg: 'Something goes wrong, Please try again.',
          //   errMsgFlag: 'block',
          //   loading: false
          // })
        }
      },
      "prefill": {
        "name": this.state.name,
        "email": this.state.email,
      },
      "notes": {
        "address": " "
      },
      "theme": {
        "color": themePrimaryColor
      }
    };

    // eslint-disable-next-line no-undef
    const rzp = new Razorpay(options);

    rzp.open();
    // e.preventDefault();
    this.setState({
      loader: false
    })
  }

  async setData(dataObj) {

    let data = {
      planAmount: this.state.selectedPlanAmount,
      type: this.state.pageType,
      paymentAmount: this.state.payableAmount,
      timestamp: moment().utc().valueOf(),
      date: moment().utc().format('YYYY-MM-DD HH:mm:ss:SSS'),
      currency: this.state.currency,
      planId: this.state.selectedPlan.planId,
      // "paymentDate" : "2021-06-10 07:06:46:996",
      // "paymentId" : "pay_HLGh1bpBs4KrZw",
      // "renewalDate" : "2022-06-05 07:06:46:996",
      // "subscriptionDate" : "2021-06-10 07:06:46:996"
    }

    if (this.state.couponCode && this.state.isCouponApplied)
      data.couponCode = this.state.couponCode;

    if (!this.state.payableAmount) {
      const transactionId = "cpnR_" + Math.random().toString(36).substring(7);
      data.paymentGetwayName = 'offline';
      data.paymentStatus = 'successful';
      data.transactionId = transactionId;
      data.orderId = transactionId;
    }
    else {
      if (!dataObj) {
        const resp = await this.generateOrderId();
        if (resp.code === 200) {
          data.orderId = resp.data.id;
        } else {
          this.setState({planMsg: resp.message })
          console.log("generateOrderId resp-- " + resp.message)
        }
      } else {
        data.orderId = this.state.orderId;
      }
      data.paymentGetwayName = 'razorpay';
      data.paymentStatus = 'pending';
    }

    if (this.state.selectedPlan.subscription === false || this.state.selectedPlan.subscription === 'false') {
      data.paymentType = 'pay-per-trade';
    }
    else {
      data.paymentType = 'subscription';
      data.subscriptionType = this.state.selectedPlan.planId;
      // if ((dataObj && !dataObj.paymentId) || !dataObj) {
      if (data.paymentStatus === 'successful' || (dataObj && dataObj.paymentId)) {
        data.subscriptionStatus = 'start';
        data.subscriptionDate = moment().utc().format('YYYY-MM-DD HH:mm:ss:SSS');
        // if renewal happens before expiry then set startDate to current active plan's renewalDate
        if(this.state.oldPlan.showRenew === true && this.state.oldPlan.remainingDays <= 7 && this.state.oldPlan.remainingDays >= 0){
          data.startDate = this.state.oldPlan.renewalDate;
          data.renewalDate = moment(this.state.oldPlan.renewalDate).add(360, 'days').format('YYYY-MM-DD HH:mm:ss:SSS');
        }else{
        data.startDate = moment().utc().format('YYYY-MM-DD HH:mm:ss:SSS');
        data.renewalDate = moment().utc().add(360, 'days').format('YYYY-MM-DD HH:mm:ss:SSS');
        }
        // if (this.state.pageType === 'upgrade') {
        //   // if (this.state.oldPlan && this.state.oldPlan.renewalDate && (this.state.oldPlan.paymentAmount !== '' && this.state.oldPlan.paymentAmount !== '0' && this.state.oldPlan.paymentAmount !== 0))
        //   if (this.state.oldPlan && this.state.oldPlan.renewalDate)
        //     data.renewalDate = this.state.oldPlan.renewalDate;
        // }
      } else {
        data.subscriptionStatus = 'pending';
      }
    }
    debugger
    data = Object.assign(data, dataObj);
    this.setState({ orderId: data.orderId })
    return data;
  }

  continuePayment() {
    const details = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : '';

    const { leadSqCompID } = this.context

    const LeadData = {
      searchBy: 'email',
      relatedCompanyId: leadSqCompID,
      ProspectStage:'Plan selected',
      email: details.emailAddress1,
      phone: details.phoneHome,
      name:details.username
    }
    LeadCapture(LeadData)

    // eslint-disable-next-line no-undef
    webengage.user.login(details.username);
    //SET WEBENGAGE
    // eslint-disable-next-line no-undef
    webengage.user.setAttribute('Plan', this.state.selectedPlan.planName);
    // eslint-disable-next-line no-undef
    webengage.track("Plan Selected");

    this.savePayment();
  }
  getRemainingDays(renewalDate) {
    let remainingDays;
    if (renewalDate) {
      // let d1 = moment("2021-06-28", "YYYY-MM-DD HH:mm:ss:sss"); // -1
      // let d1 = moment("2021-06-29 / 2021-06-30", "YYYY-MM-DD HH:mm:ss:sss");// 0
      let d1 = moment(renewalDate, "YYYY-MM-DD HH:mm:ss:sss"); // 1
      let d2 = moment();
      remainingDays = d1.diff(d2, 'days');
      // alert(remainingDays);
      // alert(oldPlan.details.planSeq)
      // debugger
    }
    return remainingDays;
  }

  async setPlanInfo(data) {
    // if (this.state.selectedPlan){
    //   const planInfo = this.state.selectedPlan;
    //   //update exlusiveUser
    //   if(planInfo.details.defaultPlan === 'true' || planInfo.details.defaultPlan === true){
    //     planInfo.exclusiveUser = true;
    //   }else{
    //     planInfo.exclusiveUser = false;
    //   }
    //   sessionStorage.setItem('planInfo', JSON.stringify(planInfo))
    // }
debugger
    //SET WEBENGAGE
    const details = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : '';
    // eslint-disable-next-line no-undef
    webengage.user.login(details.username);
    // eslint-disable-next-line no-undef
    webengage.user.setAttribute('Plan', this.state.selectedPlan.planName);
    if(data && data.renewalDate) {
      // eslint-disable-next-line no-undef
      webengage.user.setAttribute('renewal date', new Date(data.renewalDate));
    }
    // eslint-disable-next-line no-undef
    webengage.track("Plan Purchased");


    const planInfo = await setCurrentPlan();
    this.props.setPlanButton(planInfo)
    this.props.handleClose('success');
    this.props.isUserSet()
  }

  async savePayment(dataObj) {
    let data = await this.setData(dataObj);
    //save pending state data for offline mode
    if(data.paymentGetwayName === 'offline'){
      const tempData = Object.assign({}, data);
      tempData.paymentStatus = 'pending';
      // change status from successful to pending for this case only
      const resp = await this.savePaymentAPI(tempData);
      if(resp.code !== 200){
        this.setState({planMsg: resp.message })
      }
    }
    // upgrade successful record saving with updating old payment status
    let resp;
    if (data.paymentStatus === 'successful' && this.state.pageType === 'upgrade') {
      if (this.state.selectedPlan.brokerSubscriptionId) {
        data.brokerSubscriptionId = this.state.selectedPlan.brokerSubscriptionId;
      }
      data.oldPaymentStatus = (this.state.oldPlan.planId === this.state.selectedPlan.planId) ? "keep" : (parseInt(this.state.selectedPlan.planSeq) < parseInt(this.state.oldPlan.details.planSeq) ? "downgrade" : "upgrade");
      resp = await this.upgradePaymentAPI(data);
    }
    else {
      resp = await this.savePaymentAPI(data);
    }
    if (resp.code === 200) {
      if (data.paymentStatus === 'successful' && (this.state.pageType === 'renew')) {
        await this.modifyExistingPlan();
      }
      if (!dataObj) {
        if (this.state.payableAmount) {
          this.razorPayPopUp(data.orderId);
        } else {
          // this.setState({successMessage:'Plan successfully added',successModal:true})
          this.setPlanInfo(data);

        }
      } else {
        // this.setState({successMessage:'Plan successfully added',successModal:true})
        this.setPlanInfo(data);
      }
    } else {
      console.log(resp.message);
      this.setState({planMsg: resp.message })
      // this.setState({
      //   errMsg: 'Something goes wrong, Please try again.',
      //   errMsgFlag: 'block',
      // })
    }

  }
  async updateOldPlan() {
    const oldDataObj = {
      type: this.state.oldPlan.type,
      orderId: this.state.oldPlan.orderId,
      paymentStatus: (this.state.oldPlan.planId === this.state.selectedPlan.planId) ? "keep" : (parseInt(this.state.selectedPlan.planSeq) < parseInt(this.state.oldPlan.details.planSeq) ? "downgrade" : "upgrade")
    };
    const resp = await this.savePaymentAPI(oldDataObj);
    // alert('existing plan response - '+JSON.stringify(planResp))
    if(resp.code !== 200){
      this.setState({planMsg: resp.message })
    }
  }

  async modifyExistingPlan() {
    if (this.state.oldPlan) {
      if (this.state.pageType === 'renew') {
        const remainingDays = this.getRemainingDays(this.state.oldPlan.renewalDate);
        if (remainingDays) {
          if (remainingDays <= 0) {
            console.log('plan expired immediate reflect - update old record and process same as first time');
            await this.updateOldPlan();
          } else {
            console.log("remainingDays : " + remainingDays);
            console.log('before expiry reflect - process same as first time');
          }
        }
      }
      // else if(this.state.pageType === 'upgrade'){
      //  await this.updateOldPlan();
      // }
    }
  }

  handleCheckBox(e) {
    this.setState({ isTermsAndCondition: e.target.isChecked })
  }

  handleCoupon(e) {
    this.setState({ couponMsg: '', couponCode: (e.target.value).toUpperCase() });
  }
  render() {
    // console.log(this.state.selectedPlan)
    return <>
      <PlanComponent
        {...this.state}
        continuePayment={() => this.continuePayment()}
        applyRemoveButton={this.applyRemoveButton}
        selectPlan={(list, i) => this.selectPlan(list, i)}
        HandleCheckBox={(e) => this.handleCheckBox(e)}
        handleCoupon={(e) => this.handleCoupon(e)}
        show={this.props.show}
        handleClose={this.props.handleClose}
      />
      {/* {this.state.errorModal ? <Error show={this.state.errorModal} message={this.state.errorMessage}/>:''} */}
      {/* {this.state.alertModal ?  <Alert show={this.state.alertModal} message={this.state.alertMessage}/>:''} */}
      {/* {this.state.successModal ? <Success show={this.state.successModal} message={this.state.successMessage} /> :''} */}
    </>
  }
}

export default Plan
