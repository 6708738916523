import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import SkeletonElement from './SkeletonElement'
import Shimmer from './Shimmer'

const ThemeBannerSkeleton = () => {
	return (
		<Card className='skeletonCard'>
			<Card.Body>
				<>
        <Row>
			<Col md={12} sm={12} xs={12}>
        {/* <SkeletonElement width='100px' height='30px'></SkeletonElement> */}
        <div className="m-1">
        <SkeletonElement  height='20px'></SkeletonElement>
        </div>
        {/* <SkeletonElement width='200px' height='40px'></SkeletonElement> */}
      </Col>
			 {/* <Col md={5} sm={5} xs={6}>
      <SkeletonElement width='300px' height='200px'></SkeletonElement>
      </Col>   */}
		</Row>
					<Shimmer />
				</>
			</Card.Body>
		</Card>
	)
}

export default ThemeBannerSkeleton
