import React, {useEffect,useState} from 'react'
import {  Row, Col} from 'react-bootstrap'
import Switch from "react-switch";
import styles from './ExtendedDisclosureModal.module.css'
import { useSelector, useDispatch } from 'react-redux'
import { getMarketDetails } from '../../store/actions/index'


const ExtendedCard = ({showExtended,setShowExtended,setShowDisclouser,isOtc}) => {
  const [marketTimer,setMarketTimer] = useState('')
  const [showExtendedFeature,setshowExtendedFeature] = useState(false)

  const dispatchAction = useDispatch()
	const state = useSelector(state => state)
  const {
		marketDetails: { marketDetails: { currentMarketStatus = {}, todaysCalender={} } = {} } = {}
	} = state
  const userInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : {}
  const enableToggle = userInfo.accountStatus === 2 ? false : true


	Object.keys(state.marketDetails).length === 0 &&
		dispatchAction(getMarketDetails())

  const onExtended = (checked) => {
    setShowExtended(checked)
    if(checked){
     if(userInfo && userInfo.extendedHoursAgreement){
       //do nothing
     }
     else{
      setShowDisclouser(true)
     }
    }
 }


  useEffect(() => {
    const scopeUser = userInfo && userInfo.scope ? userInfo.scope.split(' ') : ''
     if(scopeUser && scopeUser.length && scopeUser.includes('ext-hours-trade')){
       setshowExtendedFeature(true)
     }
  },[])


 const marketTime = (close) => {
  let nowtimeStamp = new Date();
	nowtimeStamp.setFullYear(nowtimeStamp.getUTCFullYear(), nowtimeStamp.getUTCMonth()-1, nowtimeStamp.getUTCDate());
	nowtimeStamp.setHours(nowtimeStamp.getUTCHours(), nowtimeStamp.getUTCMinutes(), nowtimeStamp.getUTCSeconds());

  const dateFormat = `T${close}:00.000Z`
  const date = todaysCalender && todaysCalender.date ? todaysCalender.date.toString().split('T')[0] :''
  const preClose = `${date}${dateFormat}`
  // const timeSplit = preClose.split('T').join(' ')
  // const time = timeSplit.slice(0,timeSplit.length - 7)
  // const closetimeStamp = new Date(time).getTime()
  // const distance = closetimeStamp - nowtimeStamp
  // var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  // var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  // console.log('hours',hours,minutes)
  // setMarketTimer(`${hours}:${minutes}`)
  let  offset = -5.0
  const preMarketCloseEST = new Date(preClose)
 }


// useEffect(() => {
//   if(  currentMarketStatus === 'Pre-Market' || currentMarketStatus === 'Post-Market'){
//     const close = currentMarketStatus === 'Pre-Market' ? todaysCalender.preMarketCloseUTC : todaysCalender.postMarketCloseUTC
//     marketTime(close)
//     setInterval(() =>  marketTime(close), 60000)
//   const [preCloseHour,preCloseMin] =  currentMarketStatus === 'Pre-Market' ? preMarketCloseUTC.split(':') : postMarketCloseUTC.split(':')
//   startTimer(preCloseHour,preCloseMin)
//   }
// })

const startTimer = (preCloseHour,preCloseMin) => {
  const timer = setTimeout(() => {
    const currentHour = new Date().getHours()
    const currentMin = new Date().getMinutes()
    const remainingHour = preCloseHour-currentHour
    const remainingMinutes = (preCloseMin-currentMin).toString().length > 1 ? preCloseMin-currentMin : `0${preCloseMin-currentMin}`
    setMarketTimer(`${remainingHour}:${remainingMinutes}`)
  }, 10000)
}

  return(
    <>
    {showExtendedFeature ?
    <div className={styles.extendedCard}>
    <Row>
      <Col>
      <div className={styles.extendedTitle}>{isOtc && isOtc === true ? 'Extended Hours Unavailable' : 'Extended Hours Trading'}</div>
      { showExtended ?
       currentMarketStatus === 'Pre-Market' || currentMarketStatus === 'Post-Market' ?
       <div className={styles.extendedSubTitle}>
         <span className={styles.preMarketCircle}></span>
         {`In ${currentMarketStatus} hours`}
         </div>
       :
        '' : <div className={styles.extendedSubTitle}>{isOtc && isOtc === true ? 'Disabled for OTC Securities' : 'Trade in pre and post-market hours'}</div>}
      </Col>
      <Col md={3} xs={3}>
      {isOtc && isOtc === true ? '' : 
        <Switch onChange={(checked) => onExtended(checked)} checked={showExtended}
          onColor='#0DC014'
          offColor='#adaaa8'
          onHandleColor="#FFFFFF"
          handleDiameter={18}
          uncheckedIcon={false}
          checkedIcon={false}
          height={24}
          width={44}
          disabled={enableToggle}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          className="react-switch"/>
      }
      </Col>
    </Row>
  </div> :
  <div className={styles.cardSpace}>

  </div>
  }
  </>
  )
}

export default ExtendedCard
