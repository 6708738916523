import React, { useState, useContext, useEffect, Component, useCallback, useRef } from 'react'
import FundAmount from '../FundAmount/fundAmount'
import CustomModal from '../CustomModal/CustomModal'
import Otp from '../Otp/otp'
import { ThemeContext } from '../../Context/ThemeContext'
import SetUpWithdraw from '../SetupOtherBankAccount/setUpWithdraw'
import OtherBankAccount from '../OtherBankAccount/otherBankAccount'
import FundInitiated from '../Fund/FundForm/WithdrawFundForms/FundInitiated/FundInitiated'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { isNumber, map, keysIn, isBoolean, isEqual, isEmpty } from 'lodash'
import { useSessionStorage } from '../../useSessionStorage'
import { generateOtp, validateOtp, getBankList, getBankAccount, withdrawFunds, postAddBankAccount, updateAddBankAccount, customOTP, resendOtp } from '../../api'
import { handleErrorResponse } from '../../errorResponse'
import { WITH_AMOUNT_AVAIL_MSG } from '../../displayMessages'
import { Modal } from 'react-bootstrap'
import styles from './Fund.module.css'
import crossImage from '../../public/images/cross.png'

const OTHER_BANK_NAME = ['OTHER_INDIAN', 'OTHER_NONINDIAN', "Other Banks"];
const withdrawlKeys = [
  'accountNumber',
  'beneficiaryAccountTypeWithdrawal',
  'beneficiarySwiftABA',
  'bankName',
  'bankAddress',
  'bankCity',
  'bankState',
  'bankPostal',
  'bankCountry',
  // 'intermediarySwiftNumber',
  // 'intermediaryBank',
];

const optionalWithdrawlKeys = [
  'intermediarySwiftNumber',
  'intermediaryBank',
]

const WithdrawFund = ({ showModal, setShowModal, withdrawValue}) => {
  // const [showModal, setShowModal] = useState(true)
  const state = useSelector(state => state);
  const [userInfo] = useSessionStorage('userInfo', {})
  const {
    userAccountSummary: {
      userSummary = {}
    }
  } = state;

  let cashAvailableForWithdrawal;
  if(userSummary && userSummary.accountSummary && !isEmpty(userSummary.accountSummary)){
    ({cashAvailableForWithdrawal} = userSummary.accountSummary);
  }
  const {
    emailAddress1 = '',
    phoneHome = '',
    firstName = '',
    lastName = '',
    country = '',
    countryID = '',
    middleName = '',
  } = userInfo;


  const [fund, setFund] = useState('')
  const [current, setCurrent] = useState('FundAmount')
  const [hashID, setHashID] = useState('');
  const [savedBank, setSavedBank] = useState([]);
  const [errorAmount, setErrorAmount] = useState('');
  const [errorOTP, setErrorOTP] = useState('');
  const [errorWithdraw, setErrorWithdraw] = useState('');
  const [errorReview, setErrorReview] = useState('');
  const [isAccSaved, setAccountSaved] = useState('');
  const [additionalInstruction, setAdditionalInstructions] = useState(false);
  const [editableFields, setEditableFields] = useState({});
  const [fundLoader, setFundLoader] = useState(false)
  const [otpLoader, setOtpLoader] = useState(false)
  const [withSetpLoader, SetWithSetpLoader] = useState(false)
  const [reviewLoader, setReviewLoader] = useState(false)
  const [bankAccountLength,setBankAccountLength] = useState(0)
  const [selectedBankNumber,setSelectedBankNumber] = useState(0)
  const [selectedBank,setSelectedBank]= useState({
    isMyAccount: false,
    bankName: '',
    bankAddress: '',
    bankState:'',
    bankPostal:'',
    accountNumber:'',
    intermediaryBank:'',
    intermediarySwiftNumber:'',
    beneficiaryAccountTypeWithdrawal: '',
    beneficiarySwiftABA: '',
    bankIFSC: '',
    bankCountry:'',
    bank: {
      name: '',
      code: ''
    },})
  const [bankDetail, setBankDetail] = useState({
    isMyAccount: false,
    bankName: '',
    bankAddress: '',
    bankState:'',
    bankPostal:'',
    accountNumber:'',
    intermediaryBank:'',
    intermediarySwiftNumber:'',
    beneficiaryAccountTypeWithdrawal: '',
    beneficiarySwiftABA: '',
    bankIFSC: '',
    bankCountry:countryID,
    bank: {
      name: '',
      code: ''
    },
    });
  const { buttonColor } = useContext(ThemeContext)


  useEffect(() => {
    checkForEditableFields(selectedBank)
    setBankDetail(selectedBank)
  },[selectedBank])

  let fullname = `${firstName} ${lastName}`;
  if(middleName && middleName !== '') {
    fullname = `${firstName} ${middleName} ${lastName}`;
  }
  const sendOTPApi = async() => {
    try {
      let response = await customOTP({reason: 'withdraw'});
      if(response.code ===200){
        setHashID(response.data.hashID);
      }
    } catch(err) {
      errorOTP('Something Went wrong, please try again.')
    }
  }
  /** OTP APIS */
  const setOTP = async () => {
    setCurrent('OTP');
    await sendOTPApi();
  }

  const resendOtpApi = () => {
    try {
      setOtpLoader(true)
      let data = resendOtp(hashID);
      if(data.code !== 200) {
        setErrorOTP('Resend OTP Fail')
      }
    } catch(err) {
      setErrorOTP(handleErrorResponse(err))
    }
  }

  const verifyOtp = async (otp) => {
    try {
      setErrorOTP('');
      setOtpLoader(true)
      let data = await validateOtp(hashID, otp);
      if(!data || data.code !== 200){
        setErrorOTP('OTP is not valid.')
      } else {
       const resp =  await getSavedBankListApi();
       if(resp.length !== 0){
        setSelectedBank(resp[0])
       }
        if(resp.length <= 1 ){
          setCurrent('SetUpOtherAccount')
        }
        else{
        setCurrent('ReviewBank')
        }
      }
    } catch(err) {
      console.log("in catch err ", err);
      setErrorOTP(handleErrorResponse(err))
    } finally {
      setOtpLoader(false)
    }
  }

  const goToOTPPage = async() => {
    setFundLoader(true);
    setErrorOTP('');
    if(fund !== "" && fund > cashAvailableForWithdrawal){
      setErrorAmount(WITH_AMOUNT_AVAIL_MSG);
    } else {
      setOTP();
    }
    setFundLoader(false);
  }


  /** When bank details available */
  // const withdrawReviewClicked = () => {
  //   setCurrent('SetUpOtherAccount');
  // }

  const onAmountChange = (e) => {
    if (cashAvailableForWithdrawal && isNumber(cashAvailableForWithdrawal) && cashAvailableForWithdrawal >= parseInt(e.target.value)) {
      setFund(e.target.value)
    } else {
      setErrorAmount(WITH_AMOUNT_AVAIL_MSG);
    }
  }

  /** Process Withdrawl */


  const onWithdraw = async () => {
    setReviewLoader(true)
    const {
      bankName,
      bankAddress,
      bankState,
      bankPostal,
      accountNumber,
      intermediaryBank,
      intermediarySwiftNumber,
      beneficiarySwiftABA,
      bankCountry,
      bankCity,
      beneficiaryName,
      beneficiaryAccountTypeWithdrawal
    } = bankDetail;
    const data = {
      amount: fund,
      beneficiaryName,
      beneficiaryBankName: bankName,
      beneficiaryAccountNumber: accountNumber,
      beneficiaryBankAddress: bankAddress,
      beneficiaryBankProvince: bankState,
      beneficiaryBankZip: bankPostal,
      beneficiaryBankCountry: bankCountry,
      intermediarySwiftNumber,
      intermediaryBankName: intermediaryBank,
      beneficiaryAccountType: beneficiaryAccountTypeWithdrawal,
      beneficiaryBankCity: bankCity,
      beneficiarySwiftABA,
      userBankId: bankDetail.hashId,
      note: ""
    };
    try {
     const response = await withdrawFunds(data);
     return response
    } catch (err) {
      setErrorReview(handleErrorResponse(err));
      return err
    } finally {
      setReviewLoader(false);
    }
  }

  const withdraw = async () => {
    try{
      if(bankAccountLength <= 1){
        const response = await onWithdraw();
        if(response.code === 200){
        setCurrent('Success')
      }
      }
      else{
      setCurrent('SetUpOtherAccount');
      }
    }catch(error){
      console.log(error)
    }
  }

  /**
   * Saved Bank Details
   */

  const saveBankAndReview = async() => {
    const {
      bankName,
      bankAddress,
      bankState,
      bankPostal,
      accountNumber,
      intermediaryBank,
      intermediarySwiftNumber,
      beneficiarySwiftABA,
      bankCountry,
      bankCity,
      isMyAccount,
      nationalBankId,
      otherbankName,
      bankIFSC,
      beneficiaryAccountTypeWithdrawal,
    } = bankDetail;
    const bankDetailsData = {
      isMyAccount,
      beneficiaryAccountNumber: accountNumber,
      beneficiaryAccountTypeWithdrawal,
      beneficiarySwiftABA,
      bankName: OTHER_BANK_NAME.includes(bankName) ? otherbankName: bankName,
      bankState,
      intermediarySwiftNumber,
      intermediaryBank,
      bankAddress,
      bankPostal,
      bankCountry,
      nationalBankId,
      bankIFSC,
      beneficiaryName: fullname,
      bankCity,
    }
    try {
      SetWithSetpLoader(true)
      const res = await postAddBankAccount(bankDetailsData);
      if(res && res.code === 200){
        await getSavedBankListApi();
        setAccountSaved(true);
        if(bankAccountLength <=1){
          setCurrent('ReviewBank')
        }
        else{
          const response = await onWithdraw();
          if(response.code === 200){
          setCurrent('Success')
        }
        }
      } else {
        setErrorWithdraw('Error saving bank details.');
      }
    } catch(err){
      setErrorWithdraw(handleErrorResponse(err));
    } finally {
      SetWithSetpLoader(false)
    }
  }

  const updateBankAndReview = async() => {
    const {
      bankName,
      bankAddress,
      bankState,
      bankPostal,
      accountNumber,
      intermediaryBank,
      intermediarySwiftNumber,
      beneficiarySwiftABA,
      bankCountry,
      bankCity,
      isMyAccount,
      hashId,
      bankIFSC,
      beneficiaryName,
      beneficiaryAccountTypeWithdrawal,
    } = bankDetail;
    const bankDetailsData = {
      isMyAccount,
      beneficiaryAccountNumber: accountNumber,
      beneficiaryAccountTypeWithdrawal,
      beneficiarySwiftABA,
      bankName,
      bankState,
      intermediarySwiftNumber,
      intermediaryBank,
      bankAddress,
      bankPostal,
      bankCountry,
      bankIFSC,
      beneficiaryName,
      bankCity
    }
    try {
      SetWithSetpLoader(true)
      const res = await updateAddBankAccount(bankDetailsData, hashId);
      if(res && res.code === 200){
        await getSavedBankListApi();
        setAccountSaved(true);
        if(bankAccountLength <= 1){
          setCurrent('ReviewBank')
        }
        else{
          const response = await onWithdraw();
          if(response.code === 200){
          setCurrent('Success')
          }
        }
        // setSavedBank(bankDetailsData);
      } else {
        setErrorWithdraw('Error saving bank details.');
      }
    } catch(err){
      setErrorWithdraw(handleErrorResponse(err));
    } finally {
      SetWithSetpLoader(false)
    }
  }

  const checkForEditableFields = (bankData) => {
    const bankKeys = keysIn(bankData);
    let isDisbaledFields = {};
    map(bankKeys, (key) => {
      if(optionalWithdrawlKeys.includes(key)){
        isDisbaledFields[key] = false
      } else {
        if(bankData[key] && bankData[key] !== ''){
          isDisbaledFields[key] = true
        } else {
          isDisbaledFields[key] = false
        }
      }
    })

    setEditableFields(isDisbaledFields);
  }

  useEffect(()=>{
    setBankAccountLength(savedBank.length)
  },[savedBank])

  const getSavedBankListApi = async() => {
    try {
      const bankList = await getBankAccount('withdraw');
      setSavedBank(bankList);
      if(bankList.length > 0) {
        setAccountSaved(true);
        if(bankList.length <= 1){
        checkForEditableFields(bankList[0]);
        setBankDetail(bankList[0])
        }else{
        checkForEditableFields(selectedBank);
        setBankDetail(selectedBank)
        }
      } else {
        setAccountSaved(false);
      }
      return bankList
      // setSavedBank(bankList);
    } catch(err){
      console.log("error fetching bank list ", err);
    }
  }


  /**
   * Decide to setup bank details for withdrawl or to open review page
   */
  // useEffect(() => {
  //   if(isBoolean(isAccSaved)) {
  //     if(!isAccSaved) {
  //       setCurrent('SetUpOtherAccount')
  //     } else {
  //       if(savedBank && Array.isArray(savedBank) && savedBank.length > 0){
  //         // savedBank[0].bankCity = "test";
  //         let {blankKeys, missingKeys} = validateFields(withdrawlKeys, savedBank[0]);
  //         if (blankKeys.length <= 0 && missingKeys.length <= 0) {
  //           setCurrent('SelectBank')
  //         } else {
  //           setCurrent('SetUpOtherAccount')
  //         }
  //       }
  //     }
  //   }
  // }, [isAccSaved, savedBank])


  const getModalSequence = () => {
    switch (current) {
      case 'FundAmount':
        return (
          <FundAmount title='Withdraw funds'
            withdrawClicked={true}
            fund={fund}
            setFund={setFund}
            fundApi={goToOTPPage}
            buttonColor={buttonColor}
            setWithdrawFund={onAmountChange}
            errorWithdrawAmount={errorAmount}
            setAdditionalInstructions={setAdditionalInstructions}
            withdrawLoading={fundLoader}
            FundsForWithdraw={withdrawValue}
          />
        )
      case 'OTP':
        return (
          <Otp
            buttonColor={buttonColor}
            setCurrent={setCurrent}
            email={emailAddress1}
            phone={phoneHome}
            errorMessage={errorOTP}
            setErrorMessage={setErrorOTP}
            verifyOtp={verifyOtp}
            sendOtpApi={sendOTPApi}
            loading={otpLoader}
          />
        )
      case 'SetUpOtherAccount':
        return (
          <SetUpWithdraw
          buttonColor={buttonColor}
          bankDetail={bankDetail}
          setBankDetail={setBankDetail}
          setCurrent={setCurrent}
          savedBank={savedBank}
          saveBankAndReview={isAccSaved ? updateBankAndReview: saveBankAndReview}
          errorMessage={errorWithdraw}
          error={errorReview}
          OTHER_BANK_NAME={OTHER_BANK_NAME}
          editableFields={editableFields}
          country={country}
          countryID={countryID}
          loading={withSetpLoader}
          fullname={fullname}
          back = {bankAccountLength <= 1 ? 'FundAmount' : 'ReviewBank'}
          />
        )
      case 'ReviewBank':
        return (
          <OtherBankAccount
          withdrawClicked={true}
          buttonColor={buttonColor}
          title='Review Withdrawal'
          withdrawReview={withdraw}
          otherCountryDetails={savedBank}
          fund={fund}
          interSwiftNumber=''
          interBankName=''
          errorWithdraw={errorReview}
          additionalInstruction={additionalInstruction}
          setCurrent={setCurrent}
          reviewWithdrawlLoading={reviewLoader}
          fullname={fullname}
          setSelectedBank={setSelectedBank}
          selectedBank={selectedBank}
          selectedBankNumber={selectedBankNumber}
          setSelectedBankNumber={setSelectedBankNumber}
          backWithDraw={bankAccountLength <= 1 ? 'SetUpOtherAccount' : 'FundAmount'}
          />
        )
      case 'Success':
        return (
          <FundInitiated />
        )
      default:
    }
  }


  return (
    // <CustomModal show={showModal} handleClose={() => setShowModal(false)}>
    //   {getModalSequence()}
    // </CustomModal>
    <Modal
    show={showModal}
    handleClose={() => setShowModal(false)}
			  className={styles.mobileModal}
			  dialogClassName={styles.modalDialog}
			  scrollable>
          <div className={styles.crossImageStyling}>
				<img src={crossImage} onClick={() => setShowModal(false)} />
			</div>
			    <Modal.Body className={styles.modalPadding}>
				  {
            getModalSequence()
          }
            </Modal.Body>
		</Modal>
  )
}

export default WithdrawFund
