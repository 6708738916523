import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { Formik } from 'formik'
import * as yup from 'yup'
import OutsideClickHandler from 'react-outside-click-handler'
import SignUpButton from '../../../SignUp/SignUpButton'
import SignUpInput from '../../../SignUp/SignUpInput'
import SignUpDropDown from '../../../SignUp/SignUpDropDown'
import DropDownButton from '../../../SignUp/DropDownButton'
import { geoCodingApi, updatedAccount } from '../../../../api'
import styles from '../../AccountSetUp.module.css'
import { setUserInfoSession } from '../../../../common'

class Address extends React.Component {
	constructor(props) {
		super(props)
		const {
			formData: { addressDetail }
		} = this.props

      const userInformation = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : ''

	    this.state = {
			addressLine1: addressDetail.addressLine1 ? addressDetail.addressLine1: userInformation && userInformation.addressLine1 ? userInformation.addressLine1 : '',
			addressLine2: addressDetail.addressLine2 ? addressDetail.addressLine2 : userInformation && userInformation.addressLine2 ? userInformation.addressLine2 : '',
			postalCode: addressDetail.postalCode ? addressDetail.postalCode : userInformation && userInformation.zipPostalCode ? userInformation.zipPostalCode : '',
			state: addressDetail.state ? addressDetail.state : userInformation && userInformation.stateProvince ? userInformation.stateProvince : '',
			city: addressDetail.city ? addressDetail.city : userInformation && userInformation.city ? userInformation.city : '',
      flagCode: addressDetail.flagCode? addressDetail.flagCode : '',
      postalError: '',
      showDropDown:false,
      country: addressDetail.country ? addressDetail.country : userInformation && userInformation.country ? userInformation.country : '',
      countryCode: addressDetail.countryCode ? addressDetail.countryCode : userInformation && userInformation.countryID ? userInformation.countryID : '',
      loading:false,
      // countryList:props.countryList,

		}
    this.myRef = React.createRef();
  }

componentDidMount=()=>{
  this.myRef.current.focus()
  //pre set
  const details = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : '';
  const countryCode = details.countryID ? details.countryID : (details.country ? details.country :'')
  if(countryCode){
    const index = this.props.countryList.findIndex(item=>item.code === countryCode);
    if(index !== -1){
      const updatedState = Object.assign(this.state,{ countryCode,country :this.props.countryList[index].value})
    this.setState({countryCode,country :this.props.countryList[index].value,flagCode:this.props.countryList[index].code2},this.updateParentState(updatedState))
  }
}
}

  updateParentState=(updatedState)=>{
    const addressDetail = Object.assign({},updatedState)
    delete addressDetail.countryList;
    delete addressDetail.postalError;
   this.props.setFormPreData({ addressDetail})
  }

  validateAddress = (val) => {
    const validAddressRegex = /^(((p[\s\.]?[o\s][\.]?)\s?)|(post\s?office\s?))((box|bin|b\.?)?\s?(num|number|#)?\s?\d+)/i;
    const d = validAddressRegex.exec(val);
    return !(d && d[0] === val.trim());
  };

	onChangeHandler = event => {
    let addressDetailError = {
      addressLine1Error:'',
      addressLine2Error:'',
      postalCodeError:'',
      countryError:'',
      cityError:'',
      stateError:'',
      errorMessage:'',
      error:false}
      this.props.errorHandler({addressDetailError})


    const name = event.target.name;
    const value = event.target.value;

    if(name === 'addressLine1' || name === 'addressLine2' || name === 'state' || name === 'city'){
      const regex = new RegExp(/\s\s+/g);
      if(regex.test(value))
      return false
    }
    if(name === 'postalCode'){
      if(value.length >= 5){
        this.getGeoCodingApi(event)
      }
    }
    this.onUpdateFormValidation(true)
    const updatedState = Object.assign(this.state,{ [name]: value})
    this.setState({ [name]: value },this.updateParentState(updatedState))
    return true
  }

  onCountryChange = (value,handleChange) => {
    const filter = this.props.countryList.filter(item => {
			return value === (item.value)
    })
    let event = {
      target: { name: 'country', value:'' }
    }
    let obj;
    if(filter.length !== 0){
      // this.setState({
      //   country: filter[0].value,
      //   countryCode: filter[0].code,
      // })
      obj = Object.assign({},{
        country: filter[0].value,
        countryCode: filter[0].code,
      })
     event.target.value=value;
    }else{
      event.target.value='';
      // this.setState({country:'',countryCode:''})
      obj = Object.assign({},{country:'',countryCode:''})
    }
    const updatedState = Object.assign(this.state,obj)
    this.setState(obj,this.updateParentState(updatedState))

    handleChange(event);

  }




	isFormValid = () => {
		const { addressLine1, addressLine2, country, postalCode, state, city } =
			this.state
		if (
			addressLine1 !== '' &&
			addressLine2 !== '' &&
			country !== '' &&
			postalCode !== '' &&
			state !== '' &&
			city !== ''
		) {
			return true
		}
		return false
	}

	// onFormSubmit = () => {
	// 	const { addressLine1, addressLine2, country, postalCode, state, city } =
	// 		this.state

	// 	const addressDetail = {
	// 		addressLine1,
	// 		addressLine2,
	// 		country,
	// 		postalCode,
	// 		state,
	// 		city
	// 	}

	// 	this.props.nextForm({ addressDetail })
  // }

  // componentDidUpdate(prevProps,prevState){
  //   debugger
  //   const addressDetail = {
  //     addressLine1,
  //     addressLine2,
  //     country,
  //     postalCode,
  //     state,
  //     city
  //   }
  //   if(prevProps !== prevState){
  //     this.props.setFormPreData()
  //   }
  // }

	getGeoCodingApi = async event => {
		geoCodingApi(event.target.value).then(response => {
			if (response.code === 200 && response.data) {
        const res = response.data;
				// if (res.country && this.state.country === res.country) {
          // const filter = this.props.countryList.filter((item) => item.label === res.country)
          let obj={}
          // if(filter.length !== 0){
          //   obj = {
          //     country:res.country ? res.country :'',
          //     countryCode:filter[0].code
          //   }
          //   // this.setState(obj)
          // }
          const detailsObj = {
						city: res.city ? res.city : '',
            state: res.state ? res.state : '',
            postalCode: res.postal_code ? res.postal_code :'',
						postalError: ''
          }
          const finalObj = Object.assign(obj,detailsObj)

          const updatedState = Object.assign(this.state,finalObj)
					this.setState(finalObj,this.updateParentState(updatedState))



				// } else {
				// 	this.setState({ postalError: 'Provide correct postalCode' })
				// }
			}
		})
	}

	// onChangeCountry = value => {
	// 	this.setState({ country: value })
	// }

  onKeyPressHandler = (event,submitCallback) => {
    if (event.which === 13) {
      submitCallback()
    }
  }

  errorHandlerMessage = (message) => {
   let addressDetailError = {
      addressLine1Error:'',
      addressLine2Error:'',
      postalCodeError:'',
      errorMessage:'',
      countryError:'',
      cityError:'',
      stateError:'',
      error:false}
    if(message.includes('address')){
      addressDetailError = {...addressDetailError,addressLine1Error:message}
    }
    else if(message.includes('postal')){
      addressDetailError = {...addressDetailError,postalCodeError:message}
    }
    else{
      addressDetailError = {...addressDetailError,errorMessage:message}
    }
    addressDetailError = {...addressDetailError,error:true}
    this.props.errorHandler({addressDetailError})
  }

  onUpdateFormValidation = (value) => {
    if(this.props.formValidation.addressDetail !== value){
    this.props.updateFormValidation({...this.props.formValidation,addressDetail:value})
    }
  }

	render() {
		const { addressLine1, addressLine2, country, postalCode, state, city } =
			this.state
		const {
			formData: { personalDetail }
    } = this.props
		const countryOptions = [{ value: 'India', label: 'India' }]
    const {addressLine1Error,postalCodeError,countryError,cityError,stateError,errorMessage,addressLine2Error} = this.props.errors
		return (
			<>
				<Row className={styles.blackText}>
						Why do we need this?
				</Row>
        <Row className={styles.detail}>
        We need your address to create your account with our partners.</Row>
				<Formik
					initialValues={this.state}
					validationSchema={yup.object({
            addressLine1: yup.string().trim()
            .required('Address Line 1 is required'),
						addressLine2: yup.string().trim(),
            country: yup.string().trim().required('Country is required'),
            postalCode: yup.string().trim()
                .required('Postal Code is required '),
						city: yup.string().trim().required('City is required'),
						state: yup.string().trim().required('State is required')
					})}
					onSubmit={values => {
            this.setState({loading:false})
						const {
							addressLine1,
							addressLine2,
							country,
							postalCode,
							state,
							city
            } = values
            console.log(addressLine1.trim()+' '+addressLine2.trim())
            console.log(this.validateAddress(addressLine1.trim()+' '+addressLine2.trim()))
              if(!this.validateAddress(addressLine1.trim()+' '+addressLine2.trim())){
                let addressDetailError = {
                  addressLine1Error:'Please provide the complete physical address',
                  addressLine2Error:'',
                  postalCodeError:'',
                  errorMessage:'',
                  countryError:'',
                  cityError:'',
                  stateError:'',
                  error:true}
                  this.props.errorHandler({addressDetailError})
                return
              }

            let addressDetailError = {
              addressLine1Error:'',
              addressLine2Error:'',
              postalCodeError:'',
              errorMessage:'',
              countryError:'',
              cityError:'',
              stateError:'',
              error:false}
              this.props.errorHandler({addressDetailError})
						const data = {
							address1: addressLine1,
							address2: addressLine2,
							country: this.state.countryCode,
							postalCode,
							state: state,
							city: city,
							nextStep: 'FINANCIAL_DETAILS'
						}
						updatedAccount(data)
							.then(res => {
								if (res.code === 200) {
                  const userInfo = {
                    addressLine1,
                    addressLine2,
                    countryID:data.country,
                    zipPostalCode:data.postalCode,
                    stateProvince:data.state,
                    city,
                    nextStep: data.nextStep
                  }
                  setUserInfoSession(userInfo)
									const addressDetail = {
										addressLine1,
										addressLine2,
										country,
										postalCode,
										state,
										city
                  }

                  const details = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : '';
                  // eslint-disable-next-line no-undef
                  webengage.user.login(details.username);
                  //webengage
                  // eslint-disable-next-line no-undef
                  webengage.user.setAttribute('City', city);
                  // eslint-disable-next-line no-undef
                  webengage.user.setAttribute('state', state);
                  // eslint-disable-next-line no-undef
                  webengage.user.setAttribute('Pincode',postalCode);
                  // eslint-disable-next-line no-undef
                  webengage.user.setAttribute('Country', country);
                  // eslint-disable-next-line no-undef
                  webengage.user.setAttribute('Address', addressLine1);
                  // eslint-disable-next-line no-undef
                  webengage.track("Address Detail Added")
                  this.onUpdateFormValidation(true)
                  this.props.changeCurrent('FINANCIAL_DETAILS')
									this.props.nextForm({ addressDetail })
								}
							})
							.catch(error => {
                this.setState({loading:false})
								this.errorHandlerMessage(error.message)
							})
					}}>
					{({
						handleChange,
						handleBlur,
						handleSubmit,
						values,
						touched,
						errors,
            isSubmitting
					}) => {
						const {
							addressLine1,
							addressLine2,
							country,
							postalCode,
							state,
							city
            } = values

						const disable =
							addressLine1 !== '' &&
							addressLine2 !== '' &&
              country !== '' &&
              this.state.countryCode !== '' &&
							postalCode !== '' &&
							state !== '' &&
							city !== '' &&
							this.state.postalError === ''
								? true
								: false

            values.city = this.state.city
            values.state = this.state.state
            values.country= this.state.country
						return (
							<>
               		<Row className={styles.personalInputRow}>
                  {this.props.countryList.length !==0 ?
									<Col
										className={styles.mobileMargin}
										md={4}
										xs={12}>
                      <div className={styles.label}>Country of Residence</div>
                      <div>
                          <DropDownButton
                            onClick={() => this.setState({showDropDown : !this.state.showDropDown,countryError:''})}
                            showDropDown={this.state.showDropDown}
                            error={countryError}
                            disabled={true}
                            >
                              <img className={styles.flagImgCode} src={`${process.env.REACT_APP_ASSESTS}/flags/${this.state.flagCode}.svg`}></img>
                              <span className={styles.countryLabel} style={{paddingLeft:'12px'}}>{this.state.country}</span>
                          </DropDownButton>
                          <OutsideClickHandler onOutsideClick={() => this.setState({showDropDown:false})}>
                          <SignUpDropDown
                            data={this.props.countryList}
                            showDropDown={this.state.showDropDown}
                            onChange={(item) => {
                              this.setState({country:item.label,countryCode:item.code,showDropDown:false,flagCode:item.code2.toUpperCase()})
                              }}
                            placeholder='Country'
                          />
                          </OutsideClickHandler>
                        </div>
                      <div className={styles.errorState}>{this.state.countryError}</div>
									</Col>
								:''}
								</Row>
								<Row className={styles.personalInputRow}>
									<Col 	className={styles.mobileMargin}>
                     <SignUpInput
                        label='Address Line 1'
                        placeholder = 'Enter Address Line 1'
                        refValue={this.myRef}
                        tabIndex='1'
												type='text'
												name='addressLine1'
												value={values.addressLine1}
												onChange={event => {
                          if(this.onChangeHandler(event))
                            handleChange(event)
												}}
												isInvalid={(touched.addressLine1 && errors.addressLine1) || addressLine1Error !== ''}
                        errorMessage={errors.addressLine1 || addressLine1Error}
                     />
									</Col>
								</Row>
								<Row className={styles.personalInputRow}>
									<Col className={styles.mobileMargin}>
                     <SignUpInput
                        label='Address Line 2 (optional)'
                        placeholder = 'Enter Address Line 2'
                        type='text'
                        tabIndex='2'
												name='addressLine2'
												value={values.addressLine2}
												onChange={event => {
													if(this.onChangeHandler(event))
                            handleChange(event)
												}}
												onBlur={handleBlur}
												isInvalid={(touched.addressLine2 && errors.addressLine2) || addressLine2Error !== ''}
                        errorMessage={errors.addressLine2 || addressLine2Error}
                     />
									</Col>
								</Row>
                <Row className={styles.personalInputRow}>
                  <Col
                    className={styles.mobileMargin}
                    md={4}
                    xs={12}>
                      <SignUpInput
                        label='Pincode'
                        placeholder = 'Pincode'
                        type='text'
                        tabIndex='3'
												name='postalCode'
                        // tabIndex='4'
												value={values.postalCode}
												onChange={event => {
                          this.setState({ postalError: '' })
                            if(this.onChangeHandler(event))
                            handleChange(event)
												}}
												onBlur={event => {
													handleBlur(event)
												}}
												isInvalid={
													(touched.postalCode && errors.postalCode) ||
													this.state.postalError !== '' || postalCodeError !== ''
												}
                        errorMessage={errors.postalCode|| postalCodeError}
                     />
                  </Col>
                  <Col
                    className={styles.mobileMargin}
                    md={4}
                    xs={12}>
                        <SignUpInput
                        label='City'
                        placeholder = 'City'
                        type='text'
                        name='city'
                        tabIndex='4'
												value={values.city}
												onChange={event => {
													if(this.onChangeHandler(event))
                            handleChange(event)
												}}
												onBlur={handleBlur}
												isInvalid={touched.city && errors.city || cityError !== ''}
                        errorMessage={errors.city || cityError}
                     />
                  </Col>
                  <Col
                    className={styles.mobileMargin}
                    md={4}
                    xs={12}>
                        <SignUpInput
                        label='State'
                        placeholder = 'State'
                        type='text'
                        name='state'
                        tabIndex='5'
												value={values.state}
                        onKeyPress={(e) => this.onKeyPressHandler(e,handleSubmit)}
												onChange={event => {
													if(this.onChangeHandler(event))
                            handleChange(event)
												}}
												onBlur={handleBlur}
												isInvalid={touched.state && errors.state || stateError !== ''}
                        errorMessage={errors.state || stateError}
                     />
                  </Col>
                </Row>

								<Row className={styles.buttonMargin}>
                {errorMessage !== '' ? <div className={styles.erorrMessage}>{errorMessage}</div> : ''}
                  <Col md={2} xs={12} className={styles.mobileMargin}>
										<SignUpButton
											title='Next Section'
                      tabIndex='6'
											classes={styles.nextSectionButton}
											onClick={handleSubmit}
                      loading={this.state.loading}
											// disabled={!disable || isSubmitting}
                      ></SignUpButton>
									</Col>
								</Row>
							</>
						)
					}}
				</Formik>
				{/* <Row className={styles.personalInputRow}>
					<Col className='pl-0'>
						<SignInputField
							placeholder='Address Line 1'
							type='text'
							className={styles.input}
							name='addressLine1'
							value={addressLine1}
							onChange={this.onChangeHandler}
						/>
					</Col>
				</Row>
				<Row className={styles.personalInputRow}>
					<Col className='pl-0'>
						<SignInputField
							placeholder='Address Line 2'
							type='text'
							className={styles.input}
							name='addressLine2'
							value={addressLine2}
							onChange={this.onChangeHandler}
						/>
					</Col>
				</Row>
				<Row className={styles.personalInputRow}>
					<Col className='pl-0'>
						<Dropdown
							data={countryOption}
							name='country'
							value={country}
							className={styles.dropdowmn}
							placeholder='Country'
							isClearable={false}
							onChange={this.onChangeCountry}
						/>
					</Col>
					<Col>
						<SignInputField
							placeholder='Postal Code'
							type='text'
							name='postalCode'
							className={styles.input}
							value={postalCode}
							onChange={this.onChangeHandler}
						/>
					</Col>
				</Row>
				<Row className={styles.personalInputRow}>
					<Col className='pl-0'>
						<SignInputField
							placeholder='city'
							type='text'
							name='city'
							className={styles.input}
							value={city}
							onChange={this.onChangeHandler}
						/>
					</Col>
					<Col>
						<SignInputField
							placeholder='state'
							type='text'
							name='state'
							className={styles.input}
							value={state}
							onChange={this.onChangeHandler}
						/>
					</Col>
				</Row>
				<Row className={styles.personalInputRow}>
					<Col></Col>
					<Col className={styles.right}>
						<SignUpButton
							title='Next Section'
							classes={styles.nextSectionButton}
							onClick={this.onFormSubmit}
							disabled={!this.isFormValid()}></SignUpButton>
					</Col>
				</Row> */}
			</>
		)
	}
}

export default Address
