import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import SkeletonElement from './SkeletonElement'
import Shimmer from './Shimmer'

const DocumentSkeleton = ({ isProfile }) => {
  const SkeletonCard = () => {
    return <Card className='skeletonCard' style={{ margin: 0 }}>
      <Card.Body>
        <>
          <Row className='justify-content-center'>
            <Col md={8}>
              <div>
                <SkeletonElement width='200px' height='20px'></SkeletonElement>
                <SkeletonElement width={isProfile?'200px':'300px'} height='10px'></SkeletonElement>
              </div>
            </Col>
          </Row>
          <Row className='justify-content-center'>
            <Col md={8}>
              <div className='mt-5'>
              </div>
            </Col>
          </Row>
          <Row className='justify-content-center'>
            <Col md={8}>
              <div className='mt-5'>
                <SkeletonElement width='200px' height='20px'></SkeletonElement>
                <SkeletonElement width={isProfile?'200px':'300px'} height='10px'></SkeletonElement>
              </div>
            </Col>
          </Row>
          <Row className='mt-5 justify-content-center'>
            <Col md={8}>
              <div className='mt-5'>
                <SkeletonElement width={isProfile?'200px':'300px'} height='40px'></SkeletonElement>
              </div>
            </Col>
          </Row>
          <Shimmer />
        </>
      </Card.Body>
    </Card>
  }
  return (
    isProfile ?
      <Row className='justify-content-center'>
        <Col xs={12}>
          {SkeletonCard()}
        </Col>
      </Row> :
      <Row className='justify-content-center'>
        <Col xl={6} sm={8} xs={12}>
          {SkeletonCard()}
        </Col>
      </Row>
  )
}

export default DocumentSkeleton
