import { FUND_MODAL } from './action'

// option format
// {
//     investment:true,
//     saving:true
// }

export const showFundModal = (option)=>{
    return {type:FUND_MODAL, payload:{show:true, option}}
}

export const closeFundModal = ()=>{
    return {type:FUND_MODAL, payload:{show:false}}
}


