import React, { useContext} from 'react'
import Footer from '../components/Footer/Footer'
import { ThemeContext } from '../Context/ThemeContext'

const OnboardingLayout = ({component: Component, ...rest}) =>{
  const { contactNumber } = useContext(ThemeContext)
  return (
    <>
   <Component {...rest} />
      {window.location.href.includes('landing-page') ? <div className='container' >
      <div className="row">
        <div className='col text-center'>
          <Footer hotlineText={"Hotline : "+contactNumber} {...rest}/>
        </div>
      </div>
    </div>: null}
  </>
  )
}
export default OnboardingLayout;

