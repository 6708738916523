import React, { useState } from "react";
import styles from './banner.module.css'
// import backIcon from '../../../public/images/backIcon.svg'

const Banner = ()=>{
    return(
        <div className={styles.container}>
            <div className={styles.row1}>
                <div className="d-flex">
                    <div className="d-none d-lg-flex">
                      <span className={styles.tranxNum}>
                        Click on "Add Funds" to update beneficiary details for a seamless fund transfer process.
                      </span>
                    </div>
                </div>
            </div>

            <div className={`d-lg-none ${styles.responsiveRow}`} >
                <div className={styles.tranxNum}>
                  Click on "Add Funds" to update beneficiary details for a seamless fund transfer process.
                </div>
            </div>
        </div>
    )
}

export default Banner
