import React, { useState } from 'react'

import styles from './SelectCheckBox.module.css'

export default function SelectCheckBox({
	value = false,
	handleChange = null,
	error=false
}) {
	return (
		<label className={styles.container}>
			<input type='checkbox' onChange={()=>handleChange&&handleChange(!value)} checked={value}/>
			<span className={`${styles.checkmark} ${error&&styles.error}`}></span>
		</label>
	)
}
