import React from 'react'
import { Row, Col } from 'react-bootstrap'
import ReactHighCharts from 'react-highcharts'

const RoeChart = ({data,categories,name}) => {

  const custoumOption = () => {
   return{
    chart: {
      type: 'line',
      height:250
  },
  title: {
      text: undefined
  },
  credits: {
    enabled: false
  },
  xAxis: {
      categories: categories.length > 0 ? categories.reverse() : [],
      lineWidth:1,
      tickWidth:0
  },
  yAxis: {
    visible: false
  },
  legend: {
    enabled: false
  },
  plotOptions: {
      line: {
          dataLabels: {
              enabled: true,
              formatter: function () {
                // console.log(this)
                return this.y
              },
          },
          // enableMouseTracking: false
      },
      series: {
        lineWidth: 1,
        animation: false,
    }
  },
  series: [{
      name: name,
      data: data.length > 0 ? data.reverse() : [],
      color:'#1E1F24'
  }]
   }
  }

  return(
    <div>
      <ReactHighCharts config={custoumOption()}/>
    </div>
  )

}
export default RoeChart
