import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import infoIcon from '../../public/images/greyInfoIcon.svg'
import styles from './Tooltip.module.css'

const CashMgmtToolTip = (props) => {

    const { content, toolTipHeading } = props

    const customToolTip = (props) => {

        return (
            <Tooltip {...props} className={styles.cashMgmtToolTip}>
              {toolTipHeading ?
                <p className={styles.toolTipHead}>{toolTipHeading}</p> : ""}
                <p className={styles.toolTipText}>{content}</p>
            </Tooltip>
        )
    }

    return (
        <>
            <OverlayTrigger
            placement="top"
            trigger={['hover', 'focus','click']}
            delay={{ show: 250, hide: 400 }}
            overlay={customToolTip}
            >
                <img src={infoIcon} alt="info-icon"/>
            </OverlayTrigger>

            <style jsx>{`
                .tooltip.show {
                    opacity: 1.0 !important
                }
            `}</style>
        </>
    )
}

export default CashMgmtToolTip
