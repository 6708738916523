import React, { useContext } from "react";
import styles from './axis.module.css'
import { FundConfigContext } from "../../AddFundConfig";

const AxisInformation = () => {
    const{fetchData} = useContext(FundConfigContext);
    return(
        <div>
            <div>
                <h3 className={styles.infoHead}>You will be redirected to the Axis Bank website to complete your transaction.</h3> 
                <h4 className={styles.infoSubHead}>Please mind the following:</h4>
            </div>
            <ul className={styles.SubPoints}>
                <li>You will have to log in to your AXIS account to conduct the transaction. Please keep your AXIS BANK credentials handy.</li>
                <li>If your account has been active for less than 12 months, the bank will require a 12 month Bank statement from your other Bank Account(s).</li>
                <li>Please keep in mind the bank statement must be 12 months back dated from the date of the transaction.</li>
                <li>Once the transaction is completed on the Bank’s website, the bank will take 24-48 working hours to review your transaction.</li>
                <li>You will receive a mail to confirm the Success/Failure of the transaction, and the same will reflect in your Investing account.</li>
            </ul>
            <button className={styles.newBankContainer} onClick={() => fetchData()}>Continue</button>
        </div>
    )
}

export default AxisInformation