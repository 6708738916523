import React, { useState, useEffect } from 'react'
import styles from './otp.module.css'
import { Row, Col } from 'react-bootstrap'
import { verifyOtp } from '../../api'
import { ONE_MINUTE_INTERVAL } from '../../constants'

const Otp = ({
  buttonColor,
  verifyOtp,
  email,
  phone,
  errorMessage,
  setErrorMessage,
  sendOtpApi,
  loading
}) => {

	const [otpValue, setOtpValue] = useState('')
  const [check, setCheck] = useState(true)
  const [seconds, setSeconds] = React.useState(ONE_MINUTE_INTERVAL)
  const [resendButtonToggle, setResendButtonToggle] = useState(false)

	const handleChange = e => {
    setErrorMessage('');
		let a = e.target.value.replace(/[^0-9]/g, '').length
		const re = /^[0-9\b]+$/
		if (e.target.value === '' || re.test(e.target.value)) {
			a <= 6 && setOtpValue(e.target.value)
		}
	}
	useEffect(() => {
		otpValue.length === 6 ? setCheck(false) : setCheck(true)
	}, [otpValue])

  const verifyOtpValue = () => {
    verifyOtp(otpValue);
  }

  //timer otp
  useEffect(() => {
    let timer;
		if (seconds > 0) {
			timer = setTimeout(() => setSeconds(seconds - 1), 1000)
		} else {
			setSeconds(0)
			setResendButtonToggle(true)
			clearInterval(timer)
    }
	}, [seconds])

  const resendOtp = () => {
    sendOtpApi()
    setSeconds(ONE_MINUTE_INTERVAL)
    setResendButtonToggle(false)
  }


	return (
		<div className={styles.card}>
			<Row>
				<Col md={12}>
					<div className={styles.title}>Enter OTP to withdraw funds</div>
				</Col>
				<Col md={9}>
					<div className={styles.description}>
						We have sent an OTP to your registered email id.
					</div>
				</Col>
				<Col md={12}>
					<div className={styles.otp}>Please enter OTP</div>
				</Col>
				<Col md={12}>
					<input
						placeholder='Enter 6-digit OTP'
						className={styles.otpInput}
            onChange={handleChange}
            onPaste={(e)=>{
              e.preventDefault()
              return false;
            }} onCopy={(e)=>{
              e.preventDefault()
              return false;}}
						value={otpValue}
						type='text'
						pattern='[0-9]*'
					/>
          <p className={styles.otpErrorText}>{errorMessage}</p>
				</Col>
        {
          seconds > 0 ?
          <Col>
					  <div className={styles.sec}>{seconds} sec left</div>
				  </Col>
        : null
        }
				<Col>
					<div className={styles.resend}>
						Didn’t get OTP? {" "}
            {resendButtonToggle ? (
              <span
                className={styles.otpResend}
                style={{ cursor: 'pointer' }}
                onClick={() => resendOtp()}
              >
								Resend
							</span>
						) : (
              <span
              className={styles.otpResend}
              style={{ color: "#767c8f" }}
             >Resend</span>
						)}
					</div>
				</Col>
				<Col md={12}>
					<button
						style={{ backgroundColor: buttonColor }}
						className={`${styles.verify} themeButton`}
						disabled={check || loading}
            onClick={verifyOtpValue}
            >
						Verify OTP
					</button>
				</Col>
			</Row>
		</div>
	)
}

export default Otp
