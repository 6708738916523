import { format } from "date-fns"
import { getAccountInfo, getPAN, verifyPAN } from "../../api"

const setUserInfo = async() => {
    let userInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : null
    if(!userInfo){
        const res = await getAccountInfo()
        if (res && res.statusDetails){
            sessionStorage.setItem('userInfo', JSON.stringify(res))
            userInfo=res
        }
    }
    return userInfo
}

export const submitPAN=async(PAN, setPanVerifying, setPanError, setPanVerifyLimit, setPAN, setShowGenericError, setOnErrorRetry, showGenericError)=>{
    setPanError(null)
    setPanVerifying(true)
    if(PAN){
        let success=true
        try{
            const userInfo = await setUserInfo()
            if(userInfo){
                const fullname= `${userInfo.firstName} ${userInfo.middleName} ${userInfo.lastName}`
                const dobArr = userInfo.dob.split('-');
                const dob = dobArr.length === 3 ? dobArr[2]+'/'+dobArr[1]+'/'+dobArr[0] : format(new Date(userInfo.dob), "dd/MM/yyyy");

                // const dob=format(new Date(userInfo.dob), "dd/MM/yyyy")
                const res= await verifyPAN({pan:PAN.toUpperCase().trim(), name:fullname, dob})
                if(res.data.code==200 && res.data.data){
                    setPAN({number:PAN.toUpperCase().trim(), masked:res.data.data.maskedPanNumber&&res.data.data.maskedPanNumber.trim().toUpperCase()})
                }else if(res.data && res.data.code===400){
                    setPanError(res.data.message)
                    if(res.data.data && res.data.data.limit){
                        setPanVerifyLimit(true)
                    }else{
                        setPanVerifyLimit(false)
                    }
                }else{
                    success=false
                }
            }else{
                success=false
            }
        }catch(error){
            success=false
        }
        if(success && showGenericError){
            setShowGenericError(false)
            setOnErrorRetry(null)
        }else if(!success){
            setOnErrorRetry((showGenericError)=>submitPAN(PAN, setPanVerifying, setPanError, setPanVerifyLimit, setPAN, setShowGenericError, setOnErrorRetry, showGenericError))
            setShowGenericError(true)
        }
    }else{
        setPanError('PAN invalid. Please provide a valid PAN')
    }
    setPanVerifying(false)
}

export const fetchPAN = async(setPAN, setPanError, setLoading, setShowGenericError, setOnErrorRetry, showGenericError)=>{
    let success=true
    try{
        const res = await getPAN()
        if(res.data.code===200 && res.data.data){
            if(!res.data.data.panVerification || res.data.data.panVerification.toUpperCase() ==="REJECTED"){
                setPAN({number:res.data.data.idNo&&res.data.data.idNo.trim().toUpperCase(), masked:''})
                setPanError('PAN is not verified')
            }else{
                setPAN({number:res.data.data.panNumber&&res.data.data.panNumber.trim().toUpperCase(), masked:res.data.data.maskedPanNumber&&res.data.data.maskedPanNumber.trim().toUpperCase()})
                setPanError(null)
            }

        }else{
            success=false
        }
    }catch(error){
        success=false
    }
    if(success && showGenericError){
        setShowGenericError(false)
        setOnErrorRetry(null)
    }else if(!success){
        setOnErrorRetry((showGenericError)=>fetchPAN(setPAN, setPanError, setLoading, setShowGenericError, setOnErrorRetry, showGenericError))
        setShowGenericError(true)
    }
    setLoading(false)
}
