import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import SkeletonElement from './SkeletonElement'
import Shimmer from './Shimmer'

const fundSkeleton = () => {
	return (
				<>
					<Row>
						<Col>
							<SkeletonElement width='150px' height='25px' color='#777' opacity="0.3"></SkeletonElement>
							<SkeletonElement width='200px' height='30px' color='#777' opacity="0.3"></SkeletonElement>
              <Shimmer />
						</Col>
            <Col style={{textAlign:'-webkit-center'}}>
							<SkeletonElement width='50px' height='20px' color='#777' opacity="0.3"></SkeletonElement>
              <Shimmer />
						</Col>
            <Col>
							<SkeletonElement width='150px' height='25px' color='#777' opacity="0.3"></SkeletonElement>
							<SkeletonElement width='200px' height='30px' color='#777' opacity="0.3"></SkeletonElement>
              <Shimmer />
						</Col>
            <Col style={{textAlign:'-webkit-center'}}>
							<SkeletonElement width='50px' height='20px' color='#777' opacity="0.3" ></SkeletonElement>
              <Shimmer />
						</Col>
					</Row>
          <Row className='mt-5'>
						<Col>
							<SkeletonElement width='150px' height='25px' color='#777' opacity="0.3"></SkeletonElement>
							<SkeletonElement width='200px' height='30px' color='#777' opacity="0.3"></SkeletonElement>
              <Shimmer />
						</Col>
            <Col style={{textAlign:'-webkit-center'}}>
							<SkeletonElement width='50px' height='20px' color='#777' opacity="0.3"></SkeletonElement>
              <Shimmer />
						</Col>
            <Col>
							<SkeletonElement width='150px' height='25px' color='#777' opacity="0.3"></SkeletonElement>
							<SkeletonElement width='200px' height='30px' color='#777' opacity="0.3"></SkeletonElement>
              <Shimmer />
						</Col>
            <Col style={{textAlign:'-webkit-center'}}>
							<SkeletonElement width='50px' height='20px' color='#777' opacity="0.3"></SkeletonElement>
              <Shimmer />
						</Col>
					</Row>
          <Row className='mt-5'>
						<Col>
							<SkeletonElement width='150px' height='25px' color='#777' opacity="0.3"></SkeletonElement>
							<SkeletonElement width='200px' height='30px' color='#777' opacity="0.3"></SkeletonElement>
              <Shimmer />
						</Col>
            <Col style={{textAlign:'-webkit-center'}}>
							<SkeletonElement width='50px' height='20px' color='#777' opacity="0.3"></SkeletonElement>
              <Shimmer />
						</Col>
            <Col>
						</Col>
            <Col style={{textAlign:'-webkit-center'}}>

						</Col>
					</Row>

				</>
	)
}

export default fundSkeleton
