import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getUserAccountSummary, getUserInvestment, getMarketDetails } from '../../store/actions'
import { Row, Col ,OverlayTrigger,Tooltip} from 'react-bootstrap'
import styles from './HomePortfolio.module.css'
import SkeletonElement from '../../skeletons/SkeletonElement'
import { calReturn, decimalAdjust, addCommasinPrice, isMarketOpen } from '../../utils'
import { getTradeCycleDates } from '../../api'
import { MARKET_CLOSE_STATUS } from '../../constants'
import moment from 'moment'


const HomePortfolio = ({ userName, marketOpenTime = '' }) => {

  const [tradeCycleDates, setTradeCycleDates] = useState({})
	useEffect(() => {
		if (Object.keys(state.userInvestment).length === 0) {
			dispatchAction(getUserInvestment())
		}
		if (Object.keys(state.userAccountSummary).length === 0) {
			dispatchAction(getUserAccountSummary())
    }

    const getTradeCycleDatesApi = async() => {
      try {
        let tradeCycleDatesData = await getTradeCycleDates();
        setTradeCycleDates(tradeCycleDatesData);
      } catch (err) {
        console.log("error fetching trade cycle dates");
      }
    }

    getTradeCycleDatesApi();
	}, [])
	const date = new Date()
	const dispatchAction = useDispatch()
	const state = useSelector(state => state)
	let todayChange = 0
	let todayChangePercentage = 0
	let currentValue = 0
  let totalReturn = 0
  let totalReturnPer = 0
  let cvWithCashBalance = 0
  let totInWithCashBalance = 0
  let portfolioValueInfo = "Now includes cash balance"
	const {
		userInvestment: { userInvestmentHistory = [] } = {},
		userAccountSummary: { userSummary = {} } = {},
    marketDetails: { marketDetails: {
      currentMarketStatus = {},
      todaysCalender = {},
      previousWorkingDaysCalender = {},
      priorPreviousWorkingDaysCalender = {},
    } = {} } = {}
	} = state
	const {
		accountSummary = {},
    portfolioSummary: { totalCurrentValue = '',totalInvestmentAmount = '' } = {},
    // marketDetails: { marketDetails: {
      // currentMarketStatus = {} } = {} } = {}
	} = userSummary

  Object.keys(state.marketDetails).length === 0 &&
  dispatchAction(getMarketDetails())

	const { cashBalance = '' } = accountSummary

  currentValue = totalCurrentValue
  cvWithCashBalance = currentValue + cashBalance;
  totInWithCashBalance = totalInvestmentAmount + cashBalance;
  let prevDayCV;
  let prevDayDate;
  if (userInvestmentHistory.length > 0) {
    if(!isMarketOpen(currentMarketStatus)) {
      const { marketStatus, marketCloseUTC } = todaysCalender;
      if(marketStatus === MARKET_CLOSE_STATUS) {
        prevDayDate = priorPreviousWorkingDaysCalender?.date;
      } else {
        const currentTime = moment().utc().format('HH:mm');
        if (currentTime > marketCloseUTC) {
          prevDayDate = previousWorkingDaysCalender?.date
        } else {
          prevDayDate = priorPreviousWorkingDaysCalender?.date
        }
      }
    } else {
      prevDayDate = previousWorkingDaysCalender?.date;
    }
    if(prevDayDate){
      let currentHistoryData = userInvestmentHistory.filter((his) => his.marketCloseDate === prevDayDate)
      if(currentHistoryData && currentHistoryData.length > 0) {
        prevDayCV = currentHistoryData[0].cashBalanceAndTotalCurrentValue
      }
    }
    // if(tradeCycleDates && tradeCycleDates.previousCycleDate){
    //   let currentHistoryData = userInvestmentHistory.filter((his) => his.marketCloseDate === tradeCycleDates.previousCycleDate)
    //   if(currentHistoryData && currentHistoryData.length > 0) {
    //     prevDayCV = currentHistoryData[0].cashBalanceAndTotalCurrentValue
    //   }
    //   // else {
    //   //   if(isMarketOpen(currentMarketStatus)){
    //   //     prevDayCV = userInvestmentHistory[userInvestmentHistory.length - 1].cashBalanceAndTotalCurrentValue;
    //   //   } else {
    //   //     if(userInvestmentHistory.length > 1) {
    //   //       prevDayCV = userInvestmentHistory[userInvestmentHistory.length - 2].cashBalanceAndTotalCurrentValue;
    //   //     } else {
    //   //       prevDayCV = userInvestmentHistory[userInvestmentHistory.length - 1].cashBalanceAndTotalCurrentValue;
    //   //     }
    //   //   }
    //   // }
    // }
  }

  if(cvWithCashBalance !== '' && prevDayCV !== ''){
    ({ret: todayChange, retPer: todayChangePercentage } = calReturn(cvWithCashBalance, prevDayCV))
  }

  if(totalCurrentValue > 0) {
    ({ ret: totalReturn, retPer: totalReturnPer } = calReturn(currentValue, totalInvestmentAmount));
  }


	return (
		<>
			<Row className={styles.increaseMargin}>
				<Col lg={5} md={4} sm={12} className={styles.marginBottom}>
					{userName === '' ? (
						<>
							<SkeletonElement
								width='70px'
								height='30px'
								type='skeletonCard'></SkeletonElement>
							<SkeletonElement
								width='100px'
								height='20px'
								type='skeletonCard'></SkeletonElement>
						</>
					) : (
						<>
							<div className={styles.name}>{`Hi ${userName} ,`}</div>
							<div className={`${styles.title} d-inline`}>{`${date
								.toDateString()
								.substring(0, 3)}, ${date
								.toDateString()
								.substring(3, 10)}, ${marketOpenTime === 'Pre-Market' || marketOpenTime === 'Post-Market' ? marketOpenTime : `Market ${marketOpenTime}`}`}</div>
							{marketOpenTime === 'Open' ? (
								<div className={styles.marketActive}></div>
							) : (
								<div className={styles.marketInActive} style={marketOpenTime === 'Pre-Market' || marketOpenTime === 'Post-Market' ? {background:'#005DD6'} : {}}></div>
							)}
						</>
					)}
				</Col>
				<Col lg={3} md={3} sm={5} xs={7}>
					{currentValue || currentValue === 0 ? (
						<>
							<span className={styles.value}>
                  {isNaN(currentValue) ? '$--' : `$${addCommasinPrice(decimalAdjust('floor', cvWithCashBalance, -2))}`}
							</span>

							<span
								className={`${styles.percentage}`}
								style={{ color: totalReturn >= 0 ? '#34B320' : '#FF0000' }}>
								{/* {todayChange
									? todayChange > 0
										? `+$${todayChangePercentage.toFixed(2)}%`
										: `-$${parseFloat(
												todayChangePercentage.toString().slice(1, -1)
										  ).toFixed(2)}%`
									: '--'} */}

                {/*new logic */}

								{isNaN(totalReturn) ? '--' : cvWithCashBalance === 0 ? ""
									: totalReturn > 0
										? `+${addCommasinPrice(decimalAdjust('floor',totalReturnPer, -2))}%`
										: `${addCommasinPrice(decimalAdjust('floor',totalReturnPer, -2))}%`
							  }

							</span>
							<div className={styles.title}>Portfolio value
              <OverlayTrigger
									placement='bottom'
                  trigger={['hover', 'focus','click']}
									overlay={
										<Tooltip>
										{portfolioValueInfo}
										</Tooltip>
									}>
									<span
                className="fa fa-info-circle pl-1 pt-1"
                style={{cursor: "pointer",position:'absolute'}}></span>
								</OverlayTrigger>
                {/* <span
                className="fa fa-info-circle pl-1"
                style={{cursor: "pointer"}}
                data-toggle="tooltip"
                data-container="body"
                data-placement="top"
                trigger="hover focus manual click"
                title={portfolioValueInfo}
                >
                </span> */}
              </div>
						</>
					) : (
						<>
							<SkeletonElement
								width='70px'
								height='30px'
								type='skeletonCard'></SkeletonElement>
							<SkeletonElement
								width='100px'
								height='20px'
								type='skeletonCard'></SkeletonElement>
						</>
					)}
				</Col>
				<Col lg={2} md={3} sm={4} xs={5}>
					{todayChange === null ? (
						<>
							<SkeletonElement
								width='70px'
								height='30px'
								type='skeletonCard'></SkeletonElement>
							<SkeletonElement
								width='100px'
								height='20px'
								type='skeletonCard'></SkeletonElement>
						</>
					) : (
						<>
							<span className={styles.value}>
              {
								isNaN(todayChange) ? '$--'
									: todayChange > 0
										? `$${addCommasinPrice(decimalAdjust('floor', todayChange, -2))}`
										: `$${addCommasinPrice(decimalAdjust('floor', todayChange, -2))}`
							}
								{/* {todayChange
									? parseFloat(todayChange) > 0
										? typeof todayChange === 'string'
											? '$--'
											: `+$${todayChange
													.toFixed(1)
													.toString()
													.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
										: typeof todayChange === 'string'
										? '$--'
										: `-$${parseFloat(todayChange.toString().slice(1, -1))
												.toFixed(1)
												.toString()
												.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
									: '$--'} */}
							</span>
							<span
								className={styles.percentage}
								style={{ color: todayChange >= 0 ? '#34B320' : '#FF0000' }}>
								{/* {todayChange
									? todayChange > 0
										? typeof todayChangePercentage === 'string'
											? '--'
											: `+$${parseFloat(todayChangePercentage).toFixed(2)}%`
										: typeof todayChangePercentage === 'string'
										? '--'
										: `-$${parseFloat(
												todayChangePercentage.toString().slice(1, -1)
										  ).toFixed(2)}%`
									: '--'} */}

                  {/* new logic */}
                  {
								  isNaN(todayChange) ? '--'
									: todayChange > 0
										? `+${addCommasinPrice(decimalAdjust('floor', todayChangePercentage, -2))}%`
										: `${addCommasinPrice(decimalAdjust('floor', todayChangePercentage, -2))}%`
							    }
							</span>
							<div className={styles.title}>Today's change</div>
						</>
					)}
				</Col>

				<Col lg={2} md={2} sm={3} xs={12}>
					{cashBalance !== null ? (
						<>
							<div className={styles.value}>
								{cashBalance >= 0
									? `$${cashBalance
											.toString()
											.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
									: '$--'}
							</div>
							<div className={styles.title}>Funds available</div>
						</>
					) : (
						<>
							<SkeletonElement
								width='70px'
								height='30px'
								type='skeletonCard'></SkeletonElement>
							<SkeletonElement
								width='100px'
								height='20px'
								type='skeletonCard'></SkeletonElement>
						</>
					)}
				</Col>
			</Row>
		</>
	)
}

export default HomePortfolio

// const HomePortfolio = ({ userName, marketOpenTime = '' }) => {
// 	const date = new Date()
// 	const dispatchAction = useDispatch()
// 	const state = useSelector(state => state)
// 	let todayChange = 0
// 	let todayChangePercentage = 0
// 	let currentValue = 0
// 	const {
// 		userInvestment: { userInvestmentHistory = [] } = {},
// 		userAccountSummary: { userSummary = {} } = {}
// 	} = state
// 	const {
// 		accountSummary = {},
// 		portfolioSummary: { totalCurrentValue = '' } = {}
// 	} = userSummary
// 	if (Object.keys(state.userInvestment).length === 0) {
// 		dispatchAction(getUserInvestment())
// 	}
// 	if (Object.keys(state.userAccountSummary).length === 0) {
// 		dispatchAction(getUserAccountSummary())
// 	}
// 	const { cashBalance = '' } = accountSummary
// 	if (userInvestmentHistory.length > 0) {
// 		todayChange =
// 			totalCurrentValue -
// 			userInvestmentHistory[userInvestmentHistory.length - 1].totalCurrentValue
// 		todayChangePercentage =
// 			(todayChange /
// 				userInvestmentHistory[userInvestmentHistory.length - 1]
// 					.totalCurrentValue) *
// 			100
// 		currentValue = totalCurrentValue
// 	}

// 	return (
// 		<>
// 			<Row>
// 				<Col md={6} className={styles.marginBottom}>
// 					{userName === '' ? (
// 						<>
// 							<SkeletonElement
// 								width='70px'
// 								height='40px'
// 								type='skeletonCard'></SkeletonElement>
// 							<SkeletonElement
// 								width='120px'
// 								height='20px'
// 								type='skeletonCard'></SkeletonElement>
// 						</>
// 					) : (
// 						<>
// 							<div className={styles.name}>{`Hi ${userName} ,`}</div>
// 							<div className={`${styles.title} d-inline`}>{`${date
// 								.toDateString()
// 								.substring(0, 3)}, ${date
// 								.toDateString()
// 								.substring(3, 10)}, market ${marketOpenTime}`}</div>
// 							{marketOpenTime === 'Open' ? (
// 								<div className={styles.marketActive}></div>
// 							) : (
// 								''
// 							)}
// 						</>
// 					)}
// 				</Col>
// 				<Col md={2} xs={4}>
// 					{currentValue || currentValue === 0 ? (
// 						<>
// 							<span className={styles.value}>
// 								{currentValue
// 									? typeof currentValue === 'string'
// 										? '--'
// 										: `$${parseFloat(currentValue)
// 												.toFixed(2)
// 												.toString()
// 												.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
// 									: '$--'}
// 							</span>

// 							<span
// 								className={`${styles.percentage} d-none d-md-inline`}
// 								style={{ color: todayChange >= 0 ? '#34B320' : '#FF0000' }}>
// 								{todayChange
// 									? todayChange > 0
// 										? `+$${todayChangePercentage.toFixed(2)}%`
// 										: `-$${parseFloat(
// 												todayChangePercentage.toString().slice(1, -1)
// 										  ).toFixed(2)}%`
// 									: '--'}
// 							</span>
// 							<div className={styles.title}>current value</div>
// 						</>
// 					) : (
// 						<>
// 							<SkeletonElement
// 								width='70px'
// 								height='40px'
// 								type='skeletonCard'></SkeletonElement>
// 							<SkeletonElement
// 								width='120px'
// 								height='20px'
// 								type='skeletonCard'></SkeletonElement>
// 						</>
// 					)}
// 				</Col>
// 				<Col md={2} xs={4}>
// 					{todayChange < 0 ? (
// 						<>
// 							<SkeletonElement
// 								width='70px'
// 								height='40px'
// 								type='skeletonCard'></SkeletonElement>
// 							<SkeletonElement
// 								width='120px'
// 								height='20px'
// 								type='skeletonCard'></SkeletonElement>
// 						</>
// 					) : (
// 						<>
// 							<span className={styles.value}>
// 								{todayChange
// 									? parseFloat(todayChange) > 0
// 										? typeof todayChange === 'string'
// 											? '$--'
// 											: `+$${todayChange
// 													.toFixed(1)
// 													.toString()
// 													.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
// 										: typeof todayChange === 'string'
// 										? '$--'
// 										: `-$${parseFloat(todayChange.toString().slice(1, -1))
// 												.toFixed(1)
// 												.toString()
// 												.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
// 									: '$--'}
// 							</span>
// 							<span
// 								className={styles.percentage}
// 								style={{ color: todayChange >= 0 ? '#34B320' : '#FF0000' }}>
// 								{todayChange
// 									? todayChange > 0
// 										? typeof todayChangePercentage === 'string'
// 											? '--'
// 											: `+$${parseFloat(todayChangePercentage).toFixed(2)}%`
// 										: typeof todayChangePercentage === 'string'
// 										? '--'
// 										: `-$${parseFloat(
// 												todayChangePercentage.toString().slice(1, -1)
// 										  ).toFixed(2)}%`
// 									: '--'}
// 							</span>
// 							<div className={styles.title}>today's change</div>
// 						</>
// 					)}
// 				</Col>

// 				<Col md={2} xs={4}>
// 					{cashBalance ? (
// 						<>
// 							<div className={styles.value}>
// 								{cashBalance
// 									? `$${cashBalance
// 											.toString()
// 											.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
// 									: '$--'}
// 							</div>
// 							<div className={styles.title}>funds available</div>
// 						</>
// 					) : (
// 						<>
// 							<SkeletonElement
// 								width='70px'
// 								height='40px'
// 								type='skeletonCard'></SkeletonElement>
// 							<SkeletonElement
// 								width='120px'
// 								height='20px'
// 								type='skeletonCard'></SkeletonElement>
// 						</>
// 					)}
// 				</Col>
// 			</Row>
// 		</>
// 	)
// }

// export default HomePortfolio
