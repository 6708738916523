import React,{useState,useEffect,useContext} from "react"
import styles from './easyfund.module.css'
import {getKycStatus,getDigilockerUrl} from '../../../../api'
import { AddFundContext } from "../../AddFundContext"
import GenericLoading from "../../GenericLoading"
import AdharLoading from "./AdharLoading"
import GenericErrorIcon from "../../../../public/images/genericError.svg"

const DigiLockerScreen = () => {
  const [kycStatus,setKycStatus] = useState('')
  const [loading, setLoading] = useState(false)
  const [url,setUrl] = useState('')
  const [errorStatus,setErrorStatus] = useState(false)
  const [errorMessage,setErrorMessage] = useState('Something went wrong')
  const [showUpload,setShowUpload] = useState(true)
  const {setSubPage, PAGE,selectedAccount, SUBPAGE,setShowGenericErrorOption,setOnErrorRetry,showGenericErrorOption,setAdharLoading,setNoHeader, setNoClose} = useContext(AddFundContext)
  const EASYFUND = PAGE.EASYFUND

  useEffect(() => {
    setNoHeader(true)
    fetchData()
  },[])

  const fetchData = async () => {
    setLoading(false,setUrl('',setErrorStatus(false)))
    try{
      const resp = await getDigilockerUrl()
      if(resp.doc && resp.doc.url){
        window.open(resp?.doc?.url, "_blank")
        setUrl(resp?.doc?.url,setLoading(true,setKycStatus('pending')))
      }
     else{
      setErrorStatus(true)
     }
    }catch(error){
      setErrorStatus(true,setLoading(false,setShowUpload(false)))
    }
}

  useEffect(() => {
    setNoHeader(true)
  },[url,errorStatus,loading,showUpload])

  useEffect(() => {
    if(kycStatus === 'REJECTED' || kycStatus === 'rejected' || kycStatus === 'declined'){
      setErrorStatus(true,setLoading(false,setShowUpload(false,
        setErrorMessage('Your Aadhaar Card details couldn’t be processed'))))
    }
    else if (kycStatus !== 'verified') {
      var summaryInterval = setInterval(() => {
        getStatusKycApi();
      }, 10000);
    }else{
      setSubPage(SUBPAGE[EASYFUND].REVIEW,setNoHeader(false,setNoClose(false)))
    }
    return () => {
      clearInterval(summaryInterval);
    }
  },[kycStatus])

  const getStatusKycApi = async () => {
    let success = true
    try{
        const resp = await getKycStatus()
        if(resp && resp.doc && resp.doc.kyc){
          setKycStatus(resp.doc.kyc)
        }
        success=true
    }catch(error){
      success=false
    }
    if(success && showGenericErrorOption){
      setShowGenericErrorOption(false)
      setOnErrorRetry(null)
      }else if(!success){
          setOnErrorRetry((showGenericErrorOption) => getStatusKycApi())
          setShowGenericErrorOption(true)
      }
  }

  return(
    <>
    {errorStatus ?
        <div className={styles.cancelContainer}>
        <div>
            <div className={styles.cancelHeader}>
                <img src={GenericErrorIcon}/>
                <span className='text-center'>{errorMessage}</span>
            </div>
            <div className={styles.cancelBody}>
              {showUpload ?
                <div style={{paddingLeft:'30px',paddingRight:'30px'}}>
                  <button className={styles.activeButton} onClick={() => setSubPage(SUBPAGE[PAGE.EASYFUND].VERIFYAADHAR)}>
                  <div className={styles.activateText} >Upload Aadhar Card</div>
                </button>
                </div> :
                 <>
                <span>
                The Date of Birth on your Aadhaar Card and your account don’t match. Please try verifying your details again after some time.
                </span>
                  <div style={{paddingLeft:'30px',paddingRight:'30px'}}>
                  <button className={styles.activeButton} onClick={() => fetchData()}>
                  <div className={styles.activateText} >Retry KYC Verification</div>
                </button>
                </div>
                </>}
                <span className={styles.tryAgainContainer} style={{color:'#0061D3',cursor:'pointer'}} onClick={()=>window.fcWidget.open()}>
                Contact support
              </span>
            </div>
        </div>
    </div>
    :
    loading ? <AdharLoading/> :
    <div className={styles.genericLoading}><GenericLoading /> </div>
    //  <div style={{transform: 'translate(0, 13%)'}}>
    //    {url === '' ?
    //    <div className={styles.genericLoading}><GenericLoading /> </div>
    //    :
    //    <iframe src={url}
    //    style={{height: 'calc(100vh - 300px)',width:'100%',background: '#FFFFFF',borderBottom: '4px solid #673EFD',
    //           boxShadow: '-2px 2px 13px rgba(0, 0, 0, 0.08)',borderRadius: '14px'}}/>
    //           }
    //  </div>
    }
    </>
  )
}

export default DigiLockerScreen
