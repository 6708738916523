import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import OnboardingLayout from './layouts/onboarding.layout'

const LoggedOutRoute = ({component: Component, ...rest}) =>{
  const authed =  sessionStorage.getItem('token') && JSON.parse(sessionStorage.getItem('token')).accessToken ? true :false
  let nextStep = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')).nextStep : '';

  return (
    <Route
      {...rest}
      render={(props) => authed === true
        ?
        rest.path === '/signup' && nextStep === 'FEW_MORE_DETAILS' ?
        <OnboardingLayout  component={Component} {...props}/> :
         window.history.back(1)
        // <Redirect to={{pathname: '/home', state: {from: props.location}}} />
        :
        // <Component {...props} />
      <OnboardingLayout  component={Component} {...props}/>
      }
    />
  )
}
export default LoggedOutRoute;
