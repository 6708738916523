import React,{useEffect} from 'react'
import { Modal,CloseButton} from 'react-bootstrap'
import styles from './Modal.module.css'
// import Lottie from 'react-lottie';
import Lottie from 'react-lottie-player';
import { useHistory } from 'react-router-dom'
import RedirectModal from './redirectModal'
const AnimationModal =(props)=> {
  const [modalState, setModalState] = React.useState(true);
  const [redirectModal, setRedirectModal] = React.useState(false);

	const history = useHistory()


  useEffect(() => {
    if(modalState){
      setTimeout(()=>{
        successModalClose();
    },[3000])
      }
    },[modalState])


const successModalClose=()=>{
  setModalState(false)
  //for mobile redirection
  if(localStorage.getItem('fromStockalApp') === 'Y' || localStorage.getItem('fromStockalApp') === 'y'){
    localStorage.removeItem('fromStockalApp');
    // postMessage('closeSignupFlow',window)
    if(window.closeSignupFlow)
      window.closeSignupFlow.postMessage('close')
    // window.dispatchEvent('closeSignupFlow')
    setRedirectModal(true)
    setTimeout(function () {
      setRedirectModal(false)
      window.location.href = 'stockal://page/login'
    }, 3000)


  }else{
    if(props.redirectTo)
      history.push('/'+props.redirectTo)
  }
}

  const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: props.animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
  return (
    <>
    <Modal
      // {...props}
      size="lg"
      backdrop="static"
      keyboard="false"
      onHide={modalState}
      show={modalState}
    >
      <Modal.Header className={styles.header}><CloseButton onClick={()=>successModalClose()}/></Modal.Header>
        <Modal.Body>
            <div className={""+styles.message}>
              {props.animationData ?
                // <Lottie options={defaultOptions}
                //   height={200}
                //   width={200}
                //   />:''}
                <Lottie
                rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
      loop={false}
      animationData={props.animationData}
      play
      style={{ width: 200, height: 200,margin:'auto' }}
    />
                  :''}
                <p>
                  {props.message}
                </p>
        </div>
            </Modal.Body>
    </Modal>
    {/* {redirectModal ? <RedirectModal modalState={redirectModal} message="Please wait, redirecting you to the app"/>:''} */}
    </>
  );
}
export default AnimationModal;
