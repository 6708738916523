import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import InfoLogo from '../../public/images/infoLogo.svg'
import styles from './SignUp.module.css'

const VerifyIdentityToolTip = ({ content }) => {
	// const { content } = props
	const customToolTip = props => {
		return (
			<Tooltip className={`${styles.tooltipCopy}`} {...props}>
				{content}
			</Tooltip>
		)
	}
	return (
		<>
			<OverlayTrigger
				placement='bottom'
				trigger={['hover', 'focus', 'click']}
				delay={{ show: 250, hide: 400 }}
				overlay={customToolTip}>
				<img alt='icon' className={styles.infoLogoImg} src={InfoLogo} />
			</OverlayTrigger>
			<style jsx>{`
				.tooltip.show {
					opacity: 1 !important;
				}
			`}</style>
		</>
	)
}
export default VerifyIdentityToolTip
