import React, { useEffect, useState, useRef } from 'react'
import { getThemesDetail } from '../../api'
// import theme from '../../components/ExploreSearch/Theme'
import { Row, Col, Container, Spinner, Card } from 'react-bootstrap'
import { Header ,SideBar, ThemeTable, StackCard } from '../../components'
import styles from './themes.module.css'
import themeBack from '../../public/images/themeBack.svg'
import { Link } from 'react-router-dom'
import { ThemeBannerSkeleton } from '../../skeletons'
import { PopularStock } from '../../components'
import {addCommasinPrice} from '../../utils'
import NoDataImage from '../../public/images/Nointernet.svg'



const SelectedTheme = props => {
  const [themeDetails,setThemeDetail] = useState()
  const [slideeffect, setslideeffect] = useState(false)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const refScroll = useRef()
  const [prevScroll, setprevScroll] = useState()
  const [pageLoading, setPageLoading] = useState(false)
  const [loadSpin, setLoadSpin] = useState(true)
  const [themeDetailImage,setThemeDetailImage] = useState()
  const [noDetailFound, setNoDetailFound] = useState(false)


  const handleEffect = () => {
		setslideeffect(prevState => setslideeffect(!prevState))
	}

  useEffect(() => {
    const fetchData = async () => {
      getThemesDetail(props.match.params.code).then(themeDetailres => {
        if(themeDetailres && Object.keys(themeDetailres).length > 0 ){
          setThemeDetail(themeDetailres)
          setThemeDetailImage(themeDetailres.image)
          setLoading(true)
          setLoadSpin(false)
          setNoDetailFound(false)
        } else {
          setLoadSpin(false)
          setNoDetailFound(true)
        }
      })
    }
  fetchData()
  }, [props.match.params.code])

  const StackArray = themeDetails && themeDetails.stocks.filter((item,index) => {
    return (item.stockType === "stack")
  })

  const StockArray =themeDetails && themeDetails.stocks.filter((item,index) => {
    return (item.stockType === "stocks" || item.stockType === "adr")
  })

  const EtfArray = themeDetails && themeDetails.stocks.filter((item,index) => {
    return (item.stockType === "etf")
  })

  // const tempArray =   themeDetails && themeDetails.stocks && themeDetails.stocks.map(item => {

  //   // if (item.stockType === "stack") {
  //   //   const StackArray = item && item.filter((item,index) => {
  //   //     return (item)
  //   //   })
  //   //   console.log('array',StackArray)
  //   // }
  //   if (item.stockType === "etf") {
  //     console.log('etf',item);
  //   }
  //   else {
  //       console.log('hye')
  //   }
  //   });

  // const TakeStacksOut = () => {
  //   if(themeDetails && newArray.length > 0 ){
  //     for(let i=0 ; i <= newArray.length; i++){
  //       if(newArray){
  //         console.log('array', newArray.stockType)
  //       } else {
  //         console.log('hey')
  //       }
  //       console.log('new', newArray['stockType'])
  //     }
  //   }
  // }


// useEffect(() => {
//   const x = document.getElementById('themeImage');
//   console.log('image',x)
//   if(themeDetailImage  && themeDetailImage !== ""){
//   var rgb = getAverageRGB(document.getElementById('themeImage'));
//   // document.body.style.backgroundColor = 'rgb('+rgb.r+','+rgb.g+','+rgb.b+')';\
//   console.log('rgb',rgb)
//   function getAverageRGB(el) {
//     console.log('el',el)
//     var blockSize = 5, // only visit every 5 pixels
//         defaultRGB = {r:0,g:0,b:0}, // for non-supporting envs
//         debugger
//         canvas = document.createElement('canvas'),
//         context = canvas.getContext && canvas.getContext('2d'),
//         data, width, height,
//         i = -4,
//         length,
//         rgb = {r:0,g:0,b:0},
//         count = 0;

//     if (!context) {
//         return defaultRGB;
//     }

//     height = canvas.height = el.naturalHeight || el.offsetHeight || el.height;
//     width = canvas.width = el.naturalWidth || el.offsetWidth || el.width;

//     context.drawImage(el, 0, 0);

//     try {
//         data = context.getImageData(0, 0, width, height);
//     } catch(e) {
//         /* security error, img on diff domain */
//         return defaultRGB;
//     }

//     length = data.data.length;

//     while ( (i += blockSize * 4) < length ) {
//         ++count;
//         rgb.r += data.data[i];
//         rgb.g += data.data[i+1];
//         rgb.b += data.data[i+2];
//     }

//     // ~~ used to floor values
//     rgb.r = ~~(rgb.r/count);
//     rgb.g = ~~(rgb.g/count);
//     rgb.b = ~~(rgb.b/count);

//     return rgb;
//   }
// }
// },[themeDetailImage])



  useEffect(()=> {
    if(themeDetailImage && themeDetailImage !== '' && themeDetailImage.length > 0){

    // const fac = new FastAverageColor();
    // fac.getColorAsync(themeDetailImage ? themeDetailImage : "")
    // .then(color => {
    //     // container.style.backgroundColor = color.rgba;
    //     // container.style.color = color.isDark ? '#fff' : '#000';

    //     console.log('Average color', color);
    // })
    // .catch(e => {
    //     console.log(e);
    // });
    }
  },[themeDetailImage])




  return(
    <>
      <Header/>

      {/* for mobile banner and back button: we are putting it here as we dont want padding  */}
      <div className={styles.mobileBanner}>
      {themeDetails && Object.keys(themeDetails).length > 0 ?
          <div className={styles.mobileImageShow}>
             <Link to="/themes" className={styles.HeadingColor}>
                <img className={styles.backArrowImageMobile} src={themeBack}></img>
              </Link>
            <img
            //  src={themeDetails.image}
            style={{backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 62.03%, rgba(0, 0, 0, 0.5) 100%), url(${themeDetails.image})`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center'}}
            className={styles.mobileImage}></img>
            <div className={themeDetails && themeDetails.name.length >= 40 ? styles.mobileOverTextHeightIncrease : styles.mobileOverText}>
              {themeDetails.name}
            </div>
            <div className={styles.mobileStockCount}>
              {themeDetails && StockArray && StockArray.length > 0 ?  `${StockArray.length} stock` : "" }
              &nbsp;{themeDetails  && themeDetails.count.stack == 0 ? "" : `•  ${themeDetails.count.stack} stack `}
              &nbsp;{themeDetails  && EtfArray.length == 0 ? "" : `•  ${EtfArray.length} etfs`}
            </div>
          </div>
           :
           (noDetailFound ?
            <div className={styles.noOrdersFound}>
              <img src={NoDataImage}></img>
              <p className={styles.noOrdersText}>No Stocks Found</p>
            </div>
          :<ThemeBannerSkeleton/> )
          }
      </div>
      {/* end here */}


      <Container className={slideeffect ? styles.shiftPos : styles.originalPos}>
        {/* <div className={styles.webBackButton}>
					<Row className={styles.selectedheading}>
            <Col md={6} sm={8} xs={8}>
              <Link to="/themes" className={styles.HeadingColor}>
                <img className={styles.backArrowImage} src={themeBack}></img> Themes
              </Link>
            </Col>
					</Row>
				</div> */}
        <div className={styles.detailsPadding}>
          <div className={styles.webBanner}>
          {themeDetails && Object.keys(themeDetails).length > 0 ?
          <Row >
            <Col lg={9} md={6} sm={6}>
              <div className={styles.themesBackButton}>
                <Link to="/themes" className={styles.HeadingColor}>
                  <img className={styles.backArrowImage} src={themeBack}></img> Themes
                </Link>
              </div>
              <div className={styles.overText}>
                {themeDetails.name}
              </div>
              <div className={themeDetails && themeDetails.name.length >= 46 ? styles.stockCountHeightIncrease :  styles.stockCount}>
                {themeDetails && StockArray && StockArray.length > 0 ? `${StockArray.length} stock` : "" }
                &nbsp;{themeDetails  && themeDetails.count.stack == 0 ? "" : `•  ${themeDetails.count.stack} stack `}
                &nbsp;{themeDetails  && EtfArray.length == 0 ? "" : `•  ${EtfArray.length} etfs`}
              </div>
            </Col>
            <Col lg={3} md={6} sm={6}>
              <img
               src={themeDetailImage}
              // style={{backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 62.03%, rgba(0, 0, 0, 0.5) 100%), url(${themeDetails.image})`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center'}}
              className={styles.bannerImage}
              ></img>

            {/* <img
              style={{backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 62.03%, rgba(0, 0, 0, 0.5) 100%), url(${themeDetails.image})`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center'}}
               className={styles.image}></img> */}
            </Col>

            {/* <Col>
              <img
              // onLoad={() => onImageLoad()}
               src={themeDetailImage}
              // style={{backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 62.03%, rgba(0, 0, 0, 0.5) 100%), url(${themeDetails.image})`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center'}}
               className={styles.image}></img>
              <div className={styles.overText}>
                {themeDetails.name}
              </div>
              <div className={styles.stockCount}>
                {themeDetails && StockArray && StockArray.length > 0 ? `${StockArray.length} stock` : "" }
                &nbsp;{themeDetails  && themeDetails.count.stack == 0 ? "" : `•  ${themeDetails.count.stack} stack `}
                &nbsp;{themeDetails  && EtfArray.length == 0 ? "" : `•  ${EtfArray.length} etfs`}
              </div>
            </Col> */}
          </Row> :
          (noDetailFound ?
            <div className={styles.noOrdersFound}>
              <img src={NoDataImage}></img>
              <p className={styles.noOrdersText}>No Stocks Found</p>
            </div>
          :<ThemeBannerSkeleton/> )
          }
          </div>


          {/* for desktop */}
          <Row className={styles.webThemeTable}>
              {StackArray && StackArray.length > 0  ?
              <Col lg={12} md={12} sm={12} className={styles.stackHeadingDesktop}>Stacks</Col> :
              ""}
              {StackArray && StackArray.length > 0  ?
                StackArray.map((item, index) => {
                return(
                <>
                <Col key={index} md={4} sm={6} xs={6} lg={4}>
                  <StackCard
                    image={item.logo}
                    ticker={item.ticker}
                    createdBy={item.createdByTitle}
                    desc={item.description}
                    cagr={item.cagr}
                    minInvest={item.minimumInvestment}
                  />
                </Col>
                </>
                )
              })

                // <ThemeTable stockType={'Stacks'} data={StackArray} setPage={setPage} page={page} prevScroll={prevScroll} setprevScroll={setprevScroll}
                // pageLoading={pageLoading} />
                :
                ""
              }
            {/* </Col> */}
          </Row>

          <Row className={styles.webThemeTable}>
            <Col md={12} lg={12} xl={12}>
              {StockArray && StockArray.length > 0 && loading  ?
                <ThemeTable stockType={'Stocks'} data={StockArray} setPage={setPage} page={page} prevScroll={prevScroll} setprevScroll={setprevScroll}
                pageLoading={pageLoading} /> :
                <>
                {loadSpin ?
                <div className={styles.spinner}><Spinner animation="border" className={styles.spinnerColor}/></div> : ""}
                </>
              }
            </Col>
          </Row>

          <Row className={styles.webThemeTable}>
            <Col md={12} lg={12} xl={12}>
              {EtfArray && EtfArray.length > 0 ?
                <ThemeTable stockType={'Etf'} data={EtfArray} setPage={setPage} page={page} prevScroll={prevScroll} setprevScroll={setprevScroll}
                pageLoading={pageLoading} /> :
                ""
              }
            </Col>
          </Row>


          {/* for mobile */}

          <div className={styles.mobileThemeTable}>
            {/* stock mobile */}
            {themeDetails && StockArray.length > 0 ?
            <Row className={styles.TableMarginTop}>
              <Col className={styles.stocksEtf}>Stocks</Col>
            </Row> : ""}
            {themeDetails && StockArray.length > 0 ? (
            StockArray.map((item, index) => {
            return (
            <Row
              key={index}
              style={{
                display: 'contents'
              }}>
              <PopularStock
                stockImage={item.logo}
                stockTitle={item.code}
                stockName={item.company}
                stockValue={item.price}
                stockIncrement={item.percentChange}
                stockChange={item.change}
                stockType={item.stockType}
                increment={item.percentChange > 0 ? true : false}
                close={item.price - item.change}
                >
                </PopularStock>
            </Row>)}))
            :
            <>
            {loadSpin ?
              <div className={styles.spinner}><Spinner animation="border" className={styles.spinnerColor}/></div>
            : "" }
            </>
            }
            {/* etf mobile */}
            <Row className={styles.TableMarginTop}>
              {EtfArray && EtfArray.length > 0 ?
              <Col className={styles.stocksEtf}>ETFs</Col> : "" }
            </Row>
            {themeDetails && EtfArray.length > 0 ? (
            EtfArray.map((item, index) => {
            return (
            <Row
              key={index}
              style={{
                display: 'contents'
              }}>
              <PopularStock
                stockImage={item.logo}
                stockTitle={item.code}
                stockName={item.company}
                stockValue={item.price}
                stockIncrement={item.percentChange}
                stockChange={item.change}
                stockType={item.stockType}
                increment={item.percentChange > 0 ? true : false}
                close={item.price - item.change}
                >
                </PopularStock>
            </Row>)}))
            :
            ""
            }
            {/* stacks mobile */}
            <Row className={styles.TableMarginTop}>
            {StackArray && StackArray.length > 0 ?
              <Col className={styles.stocksEtf}>Stacks</Col> : ""}
            </Row>
            {themeDetails && StackArray.length > 0 ? (
            StackArray.map((item, index) => {
            return (
                <>
                <Link to={`/stackdetail/${item.ticker}`} className={styles.navLink}>
                <Col key={index} xs={12}>
                  <Card className={styles.trendingCard}>
                    <Card.Body
                      className={styles.cardBody}
                    >
                      <Row className='mb-3'>
                        <Col xs={3} md={4}>
                          <img className={styles.stockImage} src={item.logo}></img>
                        </Col>
                        <Col xs={8} className={styles.stackCardHeading}>
                          <div className={styles.stockTitle}>{item.ticker}</div>
                          <div className={styles.creator}>
                            {item.name.length < 20
                              ? item.name
                              : `${item.name.substring(0, 25)}...`}
                          </div>
                        </Col>
                      </Row>
                      <Row className='mb-md-2 mb-3'>
                        <Col>
                          <h3 className={styles.stockName}>{`${item.description.substring(
                            0,
                            80
                          )}...`}</h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={3}>
                          <div className={`${styles.minHead} mb-1`}>Min.inv</div>
                          <div className={styles.minValue}>${addCommasinPrice(item.minimumInvestment)}</div>
                        </Col>
                        <Col xs={4}>
                          <div className={`${styles.cagr} mb-1`}>CAGR</div>
                          <div className={styles.cagrValue}>{item.cagr}%</div>
                        </Col>
                        <Col xs={5}>
                          <div className={`${styles.minHead} mb-1`}>Created by</div>
                          <div className={styles.minValue}> {item.name.length <= 12 ?  item.createdByTitle : `${item.createdByTitle.substring(0, 12)}...`} </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                </Link>
                <hr className={styles.borderBottom}></hr>
                </>
            )
            }))
            :
            ""
            }
          </div>
        </div>

        <div className={styles.sideBarMobileHide}>
          <SideBar handleEffect={handleEffect} />
        </div>
      </Container>
    </>
  )
}


export default SelectedTheme
