import React, { useState, useEffect, useContext } from 'react'
// import { useDispatch } from 'react-redux'
import { Row, Col, Button } from 'react-bootstrap'
import BasicDetail from './BasicDetail'
import PersonalDetail from './PersonalDetail'
import Address from './Address'
import Employment from './Employement'
import Identification from './Identification'
import InvestingProfile from './InvestingProfile'
import AccountClosure from './AccountClosure'

import styles from '../Profile.module.css'
import CustomModal from '../../../components/CustomModal/CustomModal'
import VerifyBasicOtp from './VerifyOTP'
import CancelSubscription from './CancelSubscription'
import { ThemeContext } from '../../../Context/ThemeContext'
import {format} from 'date-fns'
import PricingPlan from '../../PricingPlan/index'
import { getPartnerCountries, getAccountInfo, getPlans } from '../../../api'
import queryString from 'query-string'
import KycFormUpdate from './KycFormUpdate'

// import { getCountryList } from '../../../store/actions/index'
import profileAbout from '../../../public/images/profileAbout.svg'
import { checkExclusiveUser } from '../../../common'
import Success from '../../Modal/Success'
import { setCurrentPlan } from '../../../common'

const UserBasicDetail = props => {
	const [showModal, setShowModal] = useState(false)
	const [isVerifyOtp, setIsVerifyOtp] = useState(false)
	const [profileData, setProfileData] = useState({})
  const [countryList, setCountryList] = useState()
  const [citizenshipList, setCitizenshipList] = useState()
  const [countryListForSourceOfFunds, setCountryListForSourceOfFunds] = useState()


	const { buttonColor,partnerCode } = useContext(ThemeContext)
	// const [isEdit, setIsEdit] = useState(false)
  const [getOtp, setOtp] = useState('')

  const [planInfo, setPlanInfo] = useState({})
  const [buttonText, setButtonText] = useState('')
  const [planPageType, setPlanPageType] = useState('')


  const [showPlanModal, setShowPlanModal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')

  const [updateKycFormToggle, setUpdateKycFormToggle] = useState(false)
  const [showKycForm, setShowKycForm] = useState(false)
  const [updatekycForm, setUpdateKycForm] = useState(false)

  const [redirectTo, setRedirectTo] = useState('')

  const handlePlanClose = (from) => {
    setShowPlanModal(false)
    if(from && from === 'success')
    {
      setSuccessModal(true)
      setSuccessMessage('Plan successfully added')
    }
  }

  const getCountryList = async(type, country)=>{
    const response = await getPartnerCountries(type, country);
    if (response && response.code === 200 && response.data)
      return response.data;
    return;
  }

  const getList= async(isUpdateKycForm) =>{
    const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    if(userInfo && userInfo.country){
    // getPartnerCountries1(userInfo.country).then(response => {
		// 	if (response && response.code === 200) {
    //       setCountryList(response.data.activeCountry)
    //       if(isUpdateKycForm)
    //         setUpdateKycForm(false)
    //       setCitizenshipList(response.data.activeCitizenship)
    //       let countryListForSourceOfFunds = response.data.activeCountry.concat(response.data.excludedCountry);
    //     //   response.data.excludedCountry.map(item => {
    //     //     const temp = {
    //     //       name: item.countryName,
    //     //       id: item.country,
    //     //       code3: item.country
    //     //     }
    //     //     countryListForSourceOfFunds.push(temp);
    //     //  })
    //       setCountryListForSourceOfFunds(countryListForSourceOfFunds)
    //   }
    // })
      const activeCountry = await getCountryList('residence', userInfo.citizenship);
      setCountryList(activeCountry);

      const activeCitizenship = await getCountryList('citizenship', userInfo.country);
      setCitizenshipList(activeCitizenship);

      const countryListForSourceOfFunds = await getCountryList('sourceoffunds');
      setCountryListForSourceOfFunds(countryListForSourceOfFunds);

      if(isUpdateKycForm)
        setUpdateKycForm(false)

    }
  }

	// const dispatchAction = useDispatch()

  useEffect(() => {
if(planPageType !== ''){
    let queryUrl = queryString.parse(window.location.search, {
			parseNumbers: false
    })
    // const isExclusive = checkExclusiveUser()
		// if (queryUrl.modal && !isExclusive) {
    if (queryUrl.modal && !successModal) {
      setShowPlanModal(true)
      if(queryUrl.redirectTo)
        setRedirectTo(queryUrl.redirectTo)
    }
  }
  },[planPageType])

  useEffect(() => {
    const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    setProfileData(userInfo);
    // console.log("userInfo",userInfo)

    // set country list
    // getActiveCountryCitizenship().then(response => {
		// 	if (response && response.code === 200) {
    //       setCountryList(response.data.activeCountry)
    //       setCitizenshipList(response.data.activeCitizenship)
    //       let countryListForSourceOfFunds = response.data.activeCountry.concat();
    //       response.data.excludedCountry.map(item => {
    //         const temp = {
    //           name: item.country,
    //           id: item.iso3Code,
    //           code3: item.iso3Code
    //         }
    //         countryListForSourceOfFunds.push(temp);
    //      })
    //       setCountryListForSourceOfFunds(countryListForSourceOfFunds)
    //       // sessionStorage.setItem('countrylist',JSON.stringify(response.data))
    //   }
    // })
    getList(false);

    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

  },[])

  // when kyc form updated Api will be called again
  useEffect(() => {
    if (updatekycForm) {
    fetchProfileData()
    // set country list
    // getActiveCountryCitizenship().then(response => {
		// 	if (response && response.code === 200) {
    //       setCountryList(response.data.activeCountry)
    //         setUpdateKycForm(false)
    //       setCitizenshipList(response.data.activeCitizenship)
    //       let countryListForSourceOfFunds = response.data.activeCountry.concat();
    //       response.data.excludedCountry.map(item => {
    //         const temp = {
    //           name: item.country,
    //           id: item.iso3Code,
    //           code3: item.iso3Code
    //         }
    //         countryListForSourceOfFunds.push(temp);
    //      })
    //       setCountryListForSourceOfFunds(countryListForSourceOfFunds)
    //   }
    // })
      getList(true);
    }
  },[updatekycForm])

  const fetchProfileData = async () => {
      const res = await getAccountInfo();
      if (res && Object.keys(res).length > 0) {
        sessionStorage.setItem('userInfo', JSON.stringify(res))
        setProfileData(res)
      }
      else {
        console.log('something went wrong')
      }
  }


 const updatePlanButton= async(planInfo)=>{
  if(!planInfo || Object.keys(planInfo).length === 0)
  planInfo = sessionStorage.getItem('planInfo') ? JSON.parse(sessionStorage.getItem('planInfo')) : await setCurrentPlan();
  setPlanInfo(planInfo);
  if(planInfo && Object.keys(planInfo).length !== 0){
    if(planInfo.planStatus === 'expire' || (planInfo.showRenew)){
      setPlanPageType('renew');
      setButtonText('Renew Plan')
      return;
    }
    // else if((planInfo.details.defaultPlan === 'false' || planInfo.details.defaultPlan === false) && !planInfo.planActivationMsg){
    // fetch planlist
    let isTopPlan = false;
    const resp = await getPlans(planInfo.details.currency, 'LIST');
    if (resp.code === 200 && resp.data && resp.data.length !== 0) {
      // fetch top plan
      const topPlanId = resp.data[resp.data.length-1].planId;
      // check if current plan is top plan
      isTopPlan = topPlanId === planInfo.planId ? true : false;
    }
    if(!isTopPlan && !planInfo.planActivationMsg){
      setPlanPageType('upgrade');
      setButtonText('Upgrade Plan')
      return;
    }
    else{
      //hide button
      setButtonText('')
      return;
    }
  }else{
    const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    if(userInfo && ((userInfo['nextStep'] && userInfo['nextStep'] === 'COMPLETE') || (!userInfo['nextStep'] && userInfo['accountStatus'] === 2))){
      setPlanPageType('new');
      setButtonText('Make Payment')
    }
    else setButtonText('')
  }
}

 useEffect(() => {
  updatePlanButton()
  },[])


	const handleClose = () => {
		setShowKycForm(false)
	}

	// const onEdit = () => {
	// 	setShowModal(true, setIsEdit(true))
	// }

	const sendOtp = () => {
		setShowModal(true)
		props.sendOtpApi()
  }

  const closeKycForm = () => {
    setUpdateKycFormToggle(false)
  }

  const KycFormShow = () => {
    setUpdateKycFormToggle(true)
  }

  // const KycUpdated = () => {
  //   setUpdateKycForm(true)
  // }
// console.info('countryListForSourceOfFunds',countryListForSourceOfFunds)
  return (
		<>
			<Row className={styles.nameSection}>
        {profileData ?
				<Col md={7} sm={8} xs={12}>
					<Row className={styles.name}>Account No: {profileData.accountNo ? profileData.accountNo : '--'}
          <span className={styles.userNameProfile}><span className='d-none d-lg-block'>/ </span><span className={styles.slashMargin}> Username:</span> {profileData.username}</span>
          </Row>
          <Row className={styles.userDetail}>
            { profileData &&
            profileData['accountStatus'] && profileData["idStatus"] &&
            profileData['accountStatus'] === 3 || profileData["idStatus"] === 3 ?
            <span>
              <>Your KYC is rejected. Please update your details.  </>
            </span> :
            <span>
            {planInfo && planInfo.details ?
            planInfo.renewalDate  && planInfo.details.planName ?
              <>{planInfo.details.planName} Plan {planInfo.planStatus === 'expire'? " expired" :" expires"} on  {format(new Date(planInfo.renewalDate.split(' ')[0]), 'd MMMM, Y')}</>
            :
            "Plan: "+ planInfo.details.planName
            :
            // check KYC complete then show plan
            profileData && profileData['nextStep'] && profileData['nextStep'] === 'COMPLETE' ? 'Plan: --' :''
            }
            </span> }
					</Row>
          {planInfo && planInfo.planActivationMsg ?
          <Row className={`${styles.userDetail} mt-3`}>
            <img src={profileAbout} className="mr-2" alt="info"/>
            <span>
            {/* Premium Silver plan will be activated on 24 Jan 2021 */}
            {"Premium "+planInfo.planActivationMsg}
            </span>
					</Row>
          :''}
				</Col>
        :''}

				<Col md={2} sm={1} xs={12} className={styles.cancelSubscriptionButton}></Col>
        {profileData &&
        profileData['accountStatus'] &&
        profileData["idStatus"]  &&
        profileData['accountStatus'] === 3 || profileData["idStatus"] === 3 ?
        <Col
        sm={3}
        md={3}
        className={styles.UpdateKycRow}
        >
        <Button className={styles.UpdateKycButton} onClick={() => KycFormShow() }>
          Update KYC
        </Button>
        </Col> :
        <>
        {buttonText ?
        <>
				<Col
          sm={3}
					md={3}
					style={{ textAlign: 'end' }}
					className={styles.upgradeButton}>
					<Button
						style={{ backgroundColor: buttonColor }}
						className={`${styles.changePlanButton} themeButton`}
						onClick={() => setShowPlanModal(true)}>
						{buttonText}
					</Button>
				</Col>

				{/* //  upgrade button for Responsive */}
				<Col md={2} xs={6} className={styles.upgradePlanMobileButton}>
					<Button
						style={{ backgroundColor: buttonColor }}
						className={`${styles.changePlanButton} themeButton`}
						onClick={() => setShowPlanModal(true)}>
						{buttonText}
					</Button>
				</Col>
        </>
        :''}
        </> }
			</Row>
			<BasicDetail
				profileData={profileData}
				countryList={countryList}
				sendOtpApi={props.sendOtpApi}
				hashId={props.hashId}
			/>
			<PersonalDetail
				profileData={profileData}
				sendOtpApi={props.sendOtpApi}
				hashId={props.hashId}
			/>
			<Address
				profileData={profileData}
				sendOtpApi={props.sendOtpApi}
				hashId={props.hashId}
			/>
			<Identification
				profileData={profileData}
        // countryList={countryList}
        citizenshipList={citizenshipList}
				sendOtpApi={props.sendOtpApi}
				hashId={props.hashId}
			/>
			<Employment
				profileData={profileData}
        // countryList={countryList}
        countryList={countryListForSourceOfFunds}
				sendOtpApi={props.sendOtpApi}
				hashId={props.hashId}
			/>
			<InvestingProfile
				profileData={profileData}
				sendOtpApi={props.sendOtpApi}
				hashId={props.hashId}
        profileDataSet={setProfileData}
        onChangeCurrent={props.onChangeCurrent}
        fetchProfileData={fetchProfileData}
        current={props.current}
			/>
      	<AccountClosure />

        {updateKycFormToggle ?
        <CustomModal className={styles.customModalPadding} show={updateKycFormToggle} handleClose={closeKycForm}>
          <KycFormUpdate profileData={profileData}
          countryList={countryList}
          KycUpdated={setUpdateKycForm}
          handleClose={closeKycForm}/>
        </CustomModal>
        : ""
        }


			{showModal ? (
				<CustomModal className={styles.customModalPadding} show={showModal} handleClose={handleClose}>
					{isVerifyOtp ? (
						<CancelSubscription handleClose={handleClose} />
					) : (
						<VerifyBasicOtp
							profile={'edit your profile'}
							onVerifyOtp={setIsVerifyOtp}
							sendOtpApi={sendOtp}
							getOtp={setOtp}
							hashId={props.hashId}
							buttonColor={buttonColor}
						/>
					)}
				</CustomModal>
			) : (
				''
			)}
      	{showPlanModal ?	<PricingPlan
          setPlanButton={updatePlanButton}
					pageType={planPageType}
					show={showPlanModal}
          handleClose={handlePlanClose}
          isUserSet={()=>props.isUserSet()}
					/>
          :''}
        {successModal ? <Success message={successMessage} redirectTo={redirectTo} /> :''}

		</>
	)
}

export default UserBasicDetail
