import React from "react";
import styles from './home.module.css'
import { Link } from 'react-router-dom'
import { toPascalCase } from '../../utils'


const PANBanner = (props)=>{
  const firstName = toPascalCase(props.firstName)
    return(
        <div className={styles.panBannerContainer +' '+styles.beneficiaryBannerContainer}>
            <div className={styles.row1}>
                <div className="d-flex">
                    <div className="d-lg-flex">
                        <span className={styles.tranxNum}>
                        {props.liquidateOnly === true || (props.remainingDays && props.remainingDays <= 0) ?
                        <>
                        <div>Dear{props.firstName ? " "+firstName :''},</div>
                        Due to failure to update the accurate PAN, we had to mark your Investment account as liquidate only*
                        </>
                        :
                        // props.remainingDays === 10 ?
                        // <div>Dear{props.firstName ? " "+firstName :''},
                        // The PAN you’ve provided is inaccurate. Please update your PAN under Government ID No. on your <Link to="/profile"><span style={{marginBottom:"0px"}}> profile page. </span></Link>
                        // Please do it within {props.remainingDays} days else your account will be marked as Liquidate only.
                        // </div>
                        // :
                        <div>Dear{props.firstName ? " "+firstName :''},
                        The PAN you’ve provided is incorrect. Please update it on your<Link to="/profile"><span style={{marginBottom:"0px"}}> Profile. </span></Link>
                        {/* <div>Please do it {(!props.remainingDays || props.remainingDays < 0) ? '' : (props.remainingDays === 0 ? 'today' :  'within '+props.remainingDays+' days')} else your account will be marked as Liquidate only.</div> */}
                        Your account will be marked as liquidate only if you fail to do so within {props.remainingDays} days

                        </div>
                        }
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PANBanner
