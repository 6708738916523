import React,{useState,useContext,useEffect} from 'react'
import { useHistory } from "react-router"
import {Card,Row,Col} from 'react-bootstrap'
import { init } from 'onfido-sdk-ui'
import { createApplicant, getSdkToken,identityDocIndian,getDigiLockerHealth,updatedAccount ,uploadDocumentApi} from '../../../../api'
import { ThemeContext } from '../../../../Context/ThemeContext'
import DigiLogo from '../../../../public/images/easyfund/DigiLogo.svg'
import VerifyPan from './VerifyPan'
import { setUserInfoSession } from '../../../../common';
import DigiLockerScreen from './DigiLockerScreen'
import styles from './IndDocument.module.css'
import UploadDocument from '../UploadDocument/UploadDocument'
import { set, get } from 'idb-keyval';

const KycScreen = ({setPanCard,location, ifMenaIndiaSelect, setIfMenaIndiaSelect, setShowUpload, setShowBackOfID, setDocTitle}) => {
  const [showManual,setShowManual] = useState('flex')
  const [showSelection,setShowSelection] = useState(true)
  const [showVerifyPan,setShowVerifyPan] = useState(false)
  const { buttonColor } = useContext(ThemeContext)
  const [showDigiLocker,setShowDigiLocker] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showGoBack,setShowGoBack] = useState(false)
  const [disableDigi,setDisableDigi] = useState(true)
  const [documentTitle,setDocumentTitle] = useState('')
  const userData = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : '';

  const [email] = useState(userData.emailAddress1 ? userData.emailAddress1 : '')
  const [countryOfResidence] = useState(userData.country ? userData.country : 'IND')
  const [phoneNumber] = useState(userData.phoneHome ? userData.phoneHome : '')
  const history = useHistory()

  // useEffect(() => {
  //   window.history.pushState(null, document.title, window.location.href);
  //   window.addEventListener('popstate', function (event) {
  //     window.history.pushState(null, document.title, window.location.href);
  //   });
  //   // make sure to block unwanted direct page load
  //   if (userData && userData.nextStep === "PAN_DETAILS")
  //   {
  //     history.push('/verifypan')
  //   }
  //   else
  //    return;
  // },[history, userData])

  useEffect(() => {
    const fetchData = async () => {
      try{
       const res = await getDigiLockerHealth()
       if(res && res?.doc && res.doc.brokerStatusCd){
         if(res.doc.brokerStatusCd === 'ACTIVE'){
          setDisableDigi(false)
         }
       }
      }catch(error){
        console.log(error)
      }
    }
    fetchData()
  },[])

  const customUI = {
    colorBackgroundButtonPrimary: buttonColor ? buttonColor : '#0061D3',
    colorBackgroundButtonPrimaryHover:buttonColor ? buttonColor : '#0061D3',
    colorBorderButtonSecondary:buttonColor ? buttonColor : '#0061D3',
    colorBackgroundButtonSecondary:'#FFFFFF',
    colorBackgroundButtonSecondaryHover:'#FFFFFF',
    borderRadiusButton:'8px',
    colorBackgroundLinkHover:'transparent',
    colorContentLinkTextHover:'#3C404A',
    fontSizeTitle:'28px',
    fontSizeSubtitle:'16px',
    fontWeightTitle:'500',
    fontWeightSubtitle:'400',
    fontFamilyTitle: 'geomanistmedium',
    fontFamilySubtitle: 'geomanistregular',
    fontFamilyBody: 'geomanistregular',
    borderRadiusSurfaceModal:'16px',
    colorBorderSurfaceModal:'#EFEFF1',
    colorBackgroundSurfaceModal:'#FFFFFF'
  }
  const applicantsReqData = {
    first_name: email,
    last_name: email,
    email: email,
  }

  const onFrontAdhar = (doc) => {
    setShowManual('none',setShowSelection(false,setShowVerifyPan(false,setShowDigiLocker(false))))
    setDocumentTitle('Upload Front of Aadhar Card')
    setLoading(true)
    try{
     setLoading(false)
    }catch(error){

    }
}

const onBackAdhar = async () => {
  setShowManual('none',setShowSelection(false,setShowVerifyPan(false,setShowDigiLocker(false))))
  setDocumentTitle('Upload Back of Aadhar Card')

}

// const onUploadDocument = async () => {
//   setLoading(true)
//   try{
//     const body = new FormData();
//     get('front').then((image) => {
//       body.append('front',image)
//       get('back').then(async(image)=>{
//         body.append('back',image)
//         const res = await uploadDocumentApi(body)
//         if(res && res.data){
//          updatedAccount({ nextStep: 'PAN_DETAILS' })
//                  .then(res => {
//                    if (res.code === 200) {
//                     setPanCard(true)
//                    }
//                  })
//                  .catch(error => {
//                    console.log(error)
//                  })
//         }
//       })
//     })
//   }catch(error){
//     setLoading(false)
//   }
// }

const onUploadDocument = async () => {
  setLoading(true)
  try{
    const body = new FormData();
    get('front').then((image) => {
      body.append('front',image)
      get('back').then(async(image)=>{
        body.append('back',image)
        body.append('type',"NATIONAL_ID_CARD");
        body.append('proofType',"ID");
        const res = await uploadDocumentApi(body);
        if(res && res.message == "success"){
         updatedAccount({ nextStep: 'PAN_DETAILS' })
                 .then(res => {
                   if (res.code === 200) {
                    console.log('is it working ?')
                      if((location !== "India" && location !== "INDIA") && ifMenaIndiaSelect){
                        setDocTitle(`Upload Address Proof`)
                        setIfMenaIndiaSelect(false)
                        setShowUpload(false)
                        setShowBackOfID(true)
                      }else{
                        console.log('should come')
                        setPanCard(true)
                      }
                   }else{
                    setLoading(false);
                   }
                 })
                 .catch(error => {
                    console.log('error1',error.message)
                    setLoading(false);
                 })
        }
      })
    })
  }catch(error){
    // setServerError('Something Went wrong. Please Try Again!')
    setLoading(false)
  }
}

  
  return(
  <>
   {showSelection ?
    <div className={styles.cardDiv}>
    <Card className={styles.cardSignUp}>
      <Card.Body className={styles.cardBodySignup}>
        <Row className={styles.title}>Complete your KYC</Row>
        {/* <Row className={styles.subTitle}>
        Select your preferred KYC method.
        </Row> */}
          {/* <div className={styles.splashKycBox} style={{marginBottom:'20px',marginTop:'20px'}}>
          <Row className={styles.splashItemHeading} style={{marginBottom:'8px'}}>
            <Col md={8} xs={8}>
              <span>Express KYC</span>
              <span className={styles.digiRecom}>Fastest</span></Col>
            <Col style={{textAlign:'end'}} md={4} xs={4}><img src={DigiLogo}></img></Col>
          </Row>
            <div className={styles.subTitle} style={{marginBottom:'18px'}}>
              You must have a valid Aadhaar number linked to your registered mobile number.
              </div>
            <button className={styles.activeButton}
             disabled={disableDigi}
             onClick={
               () => {
                 setShowDigiLocker(true,setShowSelection(false))
                // eslint-disable-next-line no-undef
                webengage.track("Kyc method selected",{
                  'method type': 'digilocker aadhar'
                });
               }
              }
            >
              <div className={styles.activateText} >Enter Aadhaar Number</div>
            </button>
            </div> */}
           <div className={styles.splashKycBox}>
            <div className={styles.splashItemHeading} style={{marginBottom:'8px'}}>Manual KYC</div>
            <div className={styles.subTitle} style={{marginBottom:'18px'}}>
              Aadhaar not linked to mobile? Upload ORIGINAL images of your Aadhaar Card. Your Name and Date of Birth on your profile should match your Aadhaar.
            </div>
            <button className={styles.secondaryBtn}
             onClick={() => {
                // eslint-disable-next-line no-undef
                webengage.track("Kyc method selected",{
                  'method type': 'manual aadhar'
                });
               onFrontAdhar()
              }}
             >
              <div className={styles.activateText}
               style={{color:'#0061D3'}}
             >Upload Aadhaar Card</div>
            </button>
           </div>
      </Card.Body>
    </Card>
  </div>: ''}
  {/* {showVerifyPan ? <VerifyPan onFace={onFace}/> : ''} */}
  {showDigiLocker ?
   <div className={styles.cardDiv}>
   <Card className={styles.cardSignUp}>
     <Card.Body className={styles.cardBodySignup}>
       <DigiLockerScreen setShowDigiLocker={setShowDigiLocker}
                         setShowSelection={setShowSelection}/>
       </Card.Body>
    </Card>
    </div> : ''}
    {!showDigiLocker && !showSelection?
    <UploadDocument
    title={documentTitle}
    loading={loading}
    onSubmit={documentTitle === 'Upload Front of Aadhar Card' ? onBackAdhar : onUploadDocument}
    />
  :''}
  {showGoBack ? <div className={styles.goBackCenter}
      onClick={() => {
        setShowSelection(true,setShowGoBack(false))
      }}>Go Back</div>:''}
  </>
   )
}

export default KycScreen
