import React, {useEffect, useCallback,useState} from 'react'
import { Row, Card, Col, Button } from 'react-bootstrap'
import { debounce } from 'lodash';
import styles from './ExploreSearch.module.css'
import RangeSlider from '../RangeSlider/RangeSlider'
import MarketCap from './MarketCap'
import {getPriceRangeFilter} from '../../api'
import Theme from './Theme'
import Switch from "react-switch";
import Select from 'react-select';
import { range } from 'lodash'
import ShariahFilter from '../ShariahFilter/ShariahFilter';

const sortByOptions = [	{ value: 'ranking_weekly', label: 'Popularity' },
{ value: 'topGainers', label: 'Top performing' },
{ value: 'topLosers', label: 'Least Performing' },
{ value: 'yearHigh', label: '52w High' },
{ value: 'yearLow', label: '52w Low' }]
const priceChangeValueOptions = [	{ value: '1', label: 'Rising' },	{ value: '-1', label: 'Falling' }]
const priceChangeDayOptions = [	{ value: '1', label: '1 Day' },
                               { value: '7', label: '7 Day' },
                               { value: '30', label: '30 Day' },
                               { value: '90', label: '90 Day' }]
const ratingsOptions = [{'Strong Buy':'strongBuy'},{'Strong Buy':'strongBuy'},{'Buy':'moderateBuy'},{'Hold':'hold'},{'Sell':'moderateSell'},{'Strong Sell':'strongSell'}]

const customStyle = () => ({
	container: provided => ({
		...provided,
		width: '100%',
		top: '50%',
    borderRadius:'8px'
	}),
	option: (styles, { isFocused, isSelected }) => {
		return {
			...styles,
			color: isSelected || isFocused ? 'black' : 'black', // setting
			backgroundColor: isSelected || isFocused ? '#EFEFF1' : 'white',

			':active': {
				...styles[':active'],
				backgroundColor: 'white'
			}
		}
	},
	singleValue: styles => ({
		...styles,
		color: '#666666'
	}),
	indicatorSeparator: provided => ({ ...provided, visibility: 'hidden' }),
	control: base => ({
		...base,
		borderWidth: 0,
		borderRadius: 8,
    backgroundColor:'white',
    border: '1.5px solid #D3D4DA',
    boxShadow: '0px 2px 16px rgba(60, 64, 74, 0.08)',
	}),
	menu: base => ({
		...base,
		borderRadius: 8,
	})
})

const  ExploreSearch = ({
    showEtf,
    setShowEtf,
    sortBy,
    setSortBy,
    rangeValue,
    setRangeValue,
    priceChangeDay,
    defaultValue,
    setPriceChangeDay,
    priceChangeValue,
    setPriceChangeValue,
    marketCap,
    setMarketCap,
    rating,
    setRating,
    setUrl,
    color='',
    setPage,
    resetFilter,
    setShowFilter,
    userLocation,
    isResetHalalFilter,
    setIsResetHalalFilter,
    showHalalStocks,
    setShowHalalStocks,
    halalRanking,
    setHalalRanking,
    resetHalalFilter,
    halalText,
    setHalalText
  }) => {

  const onChangeMarketCap = (value) => {
    if(value !== marketCap){
    setMarketCap(value)
    }else{
      setMarketCap('')
    }
  }
  const onChangeRating = (value) => {
    if(value !== rating){
    setRating(value)
    }
    else{
      setRating('')
    }
  }

  const onChangeRange = (value) => {
    // setRangeValue(value)
    setDebonceRange(value)
   }

  const setDebonceRange = useCallback(
     debounce((value) => {
       setRangeValue(value)
     },200),
     []
  );


  useEffect(() => {
    setPage(1)
   let url  = `instrument/stocks?sortBy=${sortBy.value}`
   if(showEtf){
    url=url+`&filter[etf]=${showEtf}`
   }
   if(priceChangeDay && priceChangeValue){
    url=url+`&filter[priceChange][days]=${priceChangeDay.value}&filter[priceChange][direction]=${priceChangeValue.value}`
   }
  //  if(sortBy){
  //     url=url+`&sortBy=${sortBy.value}`
  //  }
   if(rating !== ''){
     const filered = ratingsOptions.filter((item) => {
       return item.hasOwnProperty(rating)
     })
     if(filered){
        url=url+`&filter[rating][name]=${filered[0][rating]}`
     }
   }
   if(marketCap !== ''){
    url=url+`&filter[marketType]=${marketCap.toLowerCase()}`
    }
    if(Object.keys(rangeValue).length >= 0 && rangeValue.min !== undefined && rangeValue.max !== undefined && rangeValue.max > 0){
      url=url+`&filter[price][gte]=${rangeValue.min}&filter[price][lte]=${rangeValue.max}`
    }

    if(showHalalStocks) {
      url = url + `&halal=true`
    }

    if(showHalalStocks && halalRanking) {
      url = url + `&halal=true&halalRanking=${halalRanking}`
    }

    if(isResetHalalFilter) {
      url  = `instrument/stocks?sortBy=${sortBy.value}`
    }

   setUrl(url)
  },[showEtf,sortBy,rangeValue,priceChangeDay,priceChangeValue,marketCap,rating,showHalalStocks,halalRanking])

  const switchHandle = (checked) => {
    setShowEtf(checked)
    // if(checked){
      resetFilter()
    // }
  }

  const userExclusive = JSON.parse(sessionStorage.getItem("planInfo")) && JSON.parse(sessionStorage.getItem("planInfo")).exclusiveUser ? JSON.parse(sessionStorage.getItem("planInfo")).exclusiveUser : false
  const userStatus = JSON.parse(sessionStorage.getItem("planInfo")) && JSON.parse(sessionStorage.getItem("planInfo")).planStatus === 'active' ? true : false

	return (
		<Card className={styles.searchCard}>
			<Card.Body className={styles.cardBody}>
        <Row className={styles.resetButton} onClick={() => {
          resetFilter()
          setShowEtf(false)
          resetHalalFilter()
          setHalalRanking(0)
          }}>Reset</Row>
				<Row className={styles.sortText}>Sort by</Row>
				<div className='m-0 mt-3'>
				<Select
        placeholder='Sort By'
        value={sortBy}
        onChange={(value) => setSortBy(value)}
        options={sortByOptions}
        styles={customStyle()}
        isSearchable={false}
      />
				</div>
       <Row className={styles.rowMargin}>
         <Col className={styles.showEtf} md={7}>
           Show ETFs only
         </Col>
         <Col >
       <Switch onChange={(checked) => switchHandle(checked)} checked={showEtf}
            onColor={color === '' ? "#005DD6" :color}
            onHandleColor="#FFFFFF"
            handleDiameter={18}
            uncheckedIcon={false}
            checkedIcon={false}
            height={24}
            width={44}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            className="react-switch"/>
            </Col>
       </Row>
       {userLocation === 'MENA'? 
          <>
            <Row className={styles.sortText}>Shariah Compliance</Row>
              <div>
                <ShariahFilter 
                  isResetHalalFilter={isResetHalalFilter} 
                  setIsResetHalalFilter={setIsResetHalalFilter} 
                  setShowHalalStocks={setShowHalalStocks} 
                  showHalalStocks={showHalalStocks} 
                  setHalalRanking={setHalalRanking} 
                  halalRanking={halalRanking}
                  halalText={halalText}
                  setHalalText={setHalalText}
                />
              </div>
          </> : ''
        }
				<Row className={styles.sortText} style={{marginTop:'42px'}}>Price</Row>
				<Row className='m-0 mt-3'>
					<RangeSlider value={rangeValue} onChange={onChangeRange} color={color} defaultValue={defaultValue}></RangeSlider>
				</Row>
				<Row className={styles.sortText} style={{marginTop:'80px'}}>Price change</Row>
				<Row className='m-0 mt-2'>
					<Col className='p-0'>
          <Select
            placeholder='Change'
            value={priceChangeDay}
            onChange={(value) => setPriceChangeDay(value)}
            options={priceChangeDayOptions}
            styles={customStyle()}
            isSearchable={false}
          />
						{/* <DropDown title='1 Day'></DropDown> */}
					</Col>
					<Col className={`p-0 ${styles.dropDownMargin}`}>
          <Select
            placeholder='Type'
            value={priceChangeValue}
            onChange={(value) => setPriceChangeValue(value)}
            options={priceChangeValueOptions}
            styles={customStyle()}
            isSearchable={false}
          />
						{/* <DropDown title='Rising'></DropDown> */}
					</Col>
				</Row>
        {showEtf ? '' :
        <>
				<Row className={styles.sortText} style={{marginTop:'70px'}}>Market cap</Row>
				<Row className={`m-0 mt-3 ${styles.nowrap}`}>
					<Col className={styles.marketBlock}>
						<MarketCap title='Large' active={marketCap === 'Large'} userExclusive={true} onChange={onChangeMarketCap}></MarketCap>
					</Col>
					<Col className={styles.marketBlock}>
						<MarketCap title='Mega' active={marketCap === 'Mega'} userExclusive={true} onChange={onChangeMarketCap}></MarketCap>
					</Col>
					<Col className='p-0'>
						<MarketCap title='Micro' active={marketCap === 'Micro'} userExclusive={true} onChange={onChangeMarketCap}></MarketCap>
					</Col>
				</Row>
        <Row className={`m-0 mt-3 ${styles.nowrap}`}>
					<Col className={styles.marketBlock}>
						<MarketCap title='Mid' active={marketCap === 'Mid'} userExclusive={true} onChange={onChangeMarketCap}></MarketCap>
					</Col>
					<Col className={styles.marketBlock}>
						<MarketCap title='Nano' active={marketCap === 'Nano'} userExclusive={true} onChange={onChangeMarketCap}></MarketCap>
					</Col>
					<Col className='p-0'>
						<MarketCap title='Small' active={marketCap === 'Small'} userExclusive={true} onChange={onChangeMarketCap}></MarketCap>
					</Col>
				</Row>
				{/* <Row className={`${styles.sortText} ${styles.nowrap}`} style={{marginTop:'40px'}}>Analyst rating
        <div className={styles.icon}>
					<div className={`${styles.exclusive}`}>EXCLUSIVE</div>
          </div>
        </Row>
				<Row className={`m-0 mt-3 ${styles.marginForStrongBuy} ${styles.nowrap}`}>
					<Col className={styles.marketBlock}>
						<MarketCap title='Strong Buy' active={rating === 'Strong Buy'} userExclusive={userExclusive && userStatus} onChange={userExclusive && userStatus ? onChangeRating : () => console.log('')}></MarketCap>
					</Col>
					<Col className={styles.marketBlock}>
						<MarketCap title='Buy' active={rating === 'Buy'} userExclusive={userExclusive && userStatus} onChange={userExclusive && userStatus ? onChangeRating : () => console.log('')}></MarketCap>
					</Col>
          <Col className={`${styles.marketBlock} pr-0`}>
						<MarketCap title='Hold' active={rating === 'Hold'} userExclusive={userExclusive && userStatus} onChange={userExclusive && userStatus ? onChangeRating : () => console.log('')}></MarketCap>
					</Col>
				</Row>
        <Row className={`m-0 mt-2 ${styles.nowrap}`}>
					<Col className={styles.marketBlock}>
						<MarketCap title='Sell' active={rating === 'Sell'} userExclusive={userExclusive && userStatus} onChange={userExclusive && userStatus ? onChangeRating : () => console.log('')}></MarketCap>
					</Col>
					<Col className={styles.marketBlock} >
						<MarketCap title='Strong Sell' active={rating === 'Strong Sell'} userExclusive={userExclusive && userStatus} onChange={userExclusive && userStatus ? onChangeRating : () => console.log('')}></MarketCap>
					</Col>
				</Row> */}
        </> }
        <Row className={`mt-5 d-block d-xl-none`}>
           <Button className={styles.applyButton} onClick={() => setShowFilter(false)}>Apply</Button>
        </Row>
				{/* <Row className={styles.sortText}>themes</Row>
				<Row className='m-0 mt-3'>
					<DropDown title='choose theme'></DropDown>
				</Row>
				<Row className='m-0'>
					<Theme title='big data'></Theme>
					<Theme title='Fossil free'></Theme>
					<Theme title='Artificial Intelligence'></Theme>
				</Row> */}
			</Card.Body>
		</Card>
	)
}

export default ExploreSearch
