import React, { useEffect, useState } from 'react'
import { Row } from 'react-bootstrap'
import styles from './StockNews.module.css'
import StockNewsData from './StockNewsData'
import NewImage from '../../public/images/stocknews.png'
import { getNews } from '../../api'
import Slider from 'react-slick'

const StockNews = ({ stockName }) => {
	const [newsData, setNewsData] = useState([])
	const asynchFetch = async () => {
		const [display] = await Promise.all([getNews(stockName)])
		setNewsData(display)
	}
	useEffect(() => {
		asynchFetch()
	}, [stockName])

  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: "30px",
    slidesToShow: 1,
    speed: 500,
    rows: 2,
    slidesPerRow: 1,
    // responsive: [
    //   {
    //     breakpoint: 770,
    //     settings: {
    //       centerPadding: "0px",
    //       slidesToShow: 2,
    //     }
    //   }
    // ]
  };

  return (
		<>
			<Row
				style={{ display: newsData && newsData.length ? 'block' : 'none' }}
				className={styles.title}>
				News
			</Row>
      <div className='d-none d-lg-block'>
			{newsData && newsData.slice(0, 3).map((item, i) => {
				return (
					<StockNewsData
						title={item.Title}
						description={item.Summary}
						image={item.Images[0]}
            link={item.Url}
					/>
				)
			})}
      </div>
      <div className='d-block d-lg-none'>
        {/* <Slider {...settings} className={styles.newsSlider} style={{height:'220px',}}> */}
		<Slider {...settings} className={styles.newsSlider}>
        {newsData && newsData.slice(5, 50).map((item, i) => {
				return (
					<StockNewsData
						title={item.Title}
						description={item.Summary}
						image={item.Images[0]}
            link={item.Url}
					/>
				)
			})}
        </Slider>
      </div>
		</>
	)
}

export default StockNews
