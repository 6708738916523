import React, { Component } from "react";
import calendarImage from "../../public/images/calendarIcon.png"
import iconDate from '../../public/images/datePickerIcon.svg'
import { Calendar } from "react-date-range";
// import { format } from "date-fns";
import $ from 'jquery'
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import moment from 'moment';



class CashManagementDatePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayCalendar: false,
      inputTxt: this.dateFormatter(this.props.value),
      readOnly: true,
      calendarMaxDate: new Date(),
      calendarMinDate: new Date('2019-01-01'),
    };

    this.showCalendar = this.showCalendar.bind(this);
    this.hideCalendar = this.hideCalendar.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.dateFormatter = this.dateFormatter.bind(this);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({ inputTxt: this.dateFormatter(nextProps.value) })
  }
  dateFormatter(mydate) {
    var curr_date = mydate.getDate();
    var curr_month = mydate.getMonth() + 1;
    var curr_year = mydate.getFullYear();
    if (curr_date < 10) {
      curr_date = "0" + curr_date;
    }
    if (curr_month < 10) {
      curr_month = "0" + curr_month;
    }
    mydate = `${curr_date}-${curr_month}-${curr_year}`;
    const date = moment(mydate).format('D MMMM Y');
    return mydate;
  }

  showCalendar() {
    this.setState({
      displayCalendar: true,
    });
    const that = this;
    $(document).mouseup(function (e) {
      var container = $('#' + that.props.id + "_cal");
      if (container) {
        // if the target of the click isn't the container nor a descendant of the container
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          that.hideCalendar();
        }
      }
    });
  }
  hideCalendar() {
    this.setState({
      displayCalendar: false,
    });
  }
  handleSelect(date) {
    this.props.changeDate(date, this.props.id)
    date = this.dateFormatter(date);
    this.setState(
      {
        inputTxt: date,
      },
      // this.props.changeDate(date, this.props.id)
    );
    this.hideCalendar();
  }

  render() {
    return (
      <div className="row" style={{paddingLeft:"15px"}}>
        <span className="col-lg-3 col-md-4 col-3"
          style={{
            fontStyle: "geomanistBook",
            fontWeight: "normal",
            fontSize: "12px",
            // lineHeight: "30px",
            letterSpacing: "0.6px",
            color: "#9096A8",
            display:"inline-block",
            paddingRight:"8px",
            textAlign:"center",
            border: "1px solid #D3D4DA",
            borderRadius: "0px",
            borderRight: "0px",
            padding: "9.75px 0px 8.75px 0px",
            borderTopLeftRadius: "6px",
            borderBottomLeftRadius:"6px"
          }}>{this.props.type}</span>
        <span className="col-lg-6 col-md-6 col-7" style={{paddingLeft: "0px", paddingRight:"0px", borderBottom: "1px solid #D3D4DA"}}>
          <input
            className="form-control"
            id="ex1"
            type="text"
            style={{
              background: "#FFFFF",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "12px",
              lineHeight: "20px",
              letterSpacing: "0.6px",
              color: "#3C404A",
              border: "1px solid #D3D4DA",
              borderRadius: "0px",
              borderTopLeftRadius: "0px",
              borderBottomLeftRadius: "0px",
              borderBottom:"none",
              borderRight: "0px",
              borderLeft:"0px",
              padding: "19px 6px 20px 1px"
              // padding: "8.75px 0px 8.75px 0px"
            }}
            onClick={() => this.showCalendar()}
            value={this.state.inputTxt}
          />
        </span>
        <span
            onClick={() => this.showCalendar()}
            className="col-lg-2 col-md-auto col-2"
            style={{
              paddingTop: "3px",
              paddingLeft: "5px",
              background: "#FFFFF",
              cursor: 'pointer',
              border: "1px solid #D3D4DA",
              borderLeft:"0px",
              padding: "5px 8.5px 2.5px 0px",
              borderTopRightRadius: "6px",
              borderBottomRightRadius:"6px"
            }}
          >
            <span>
              <img src={iconDate} style={{width:"14px",height:"14px"}} />
            </span>
        </span>
        <div
          id={this.props.id + "_cal"}
          style={{
            display: this.state.displayCalendar ? "block" : "none",
            position: "absolute",
            zIndex: "1000",
            top: "35px",
          }}
        >
          <Calendar
            date={new Date(this.props.value)}
            onChange={this.handleSelect}
            maxDate={this.props.type === "To" ?  this.state.calendarMaxDate : this.props.maxDate}
            minDate={this.props.type === "From" ? this.state.calendarMinDate : this.props.minDate}
          />
        </div>
      </div>
    );
  }
}
export default CashManagementDatePicker;
