import React, { Component } from "react"
import { Col, Row} from 'react-bootstrap'
import styles from './marketDepth.module.css'

class MarketDepth extends Component {
    constructor(){
        super()
        this.state={
            popoverOpen: false    
        }
    }

    togglePopover = () =>  {    
    this.setState({ popoverOpen: !this.state.popoverOpen })  
    }

    render() {
    return(
        <Row>
            <Col>
               <div className={styles.tooltip}><img className="mr-2" src={require('../../public/images/thunderBolt.svg')} alt="thunderbolt" width="9%"/>market depth<i className={styles.arrowDown}></i>
                <Row className={styles.tooltiptext}>
                    <Col>
                        <Row className="pt-3" >
                            <Col className={styles.head} md={2}>
                                bid
                            </Col>
                            <Col className={styles.head} md={2}>
                                order
                            </Col>
                            <Col className={styles.head} md={2}>
                                qty   
                            </Col>
                            <Col className={styles.head} md={2}>
                                offer
                            </Col>
                            <Col className={styles.head} md={2}>
                                order
                            </Col>
                            <Col className={styles.head} md={2}>
                                qty        
                            </Col>
                        </Row>
                         <Row className="pt-2" >
                            <Col className={styles.bid} md={2}>
                                178.43
                            </Col>
                            <Col className={styles.bid} md={2}>
                               23
                            </Col>
                            <Col className={styles.bid} md={2}>
                                4920   
                            </Col>
                            <Col className={styles.offer} md={2}>
                                178.43
                            </Col>
                            <Col className={styles.offer} md={2}>
                               23
                            </Col>
                            <Col className={styles.offer} md={2}>
                                4920   
                            </Col>
                        </Row>
                        <Row className="pt-2" >
                            <Col className={styles.bid} md={2}>
                                178.43
                            </Col>
                            <Col className={styles.bid} md={2}>
                            23
                            </Col>
                            <Col className={styles.bid} md={2}>
                                4920   
                            </Col>
                            <Col className={styles.offer} md={2}>
                                178.43
                            </Col>
                            <Col className={styles.offer} md={2}>
                            23
                            </Col>
                            <Col className={styles.offer} md={2}>
                                4920   
                            </Col>
                        </Row>
                        <Row className="pt-2" >
                            <Col className={styles.bid} md={2}>
                                178.43
                            </Col>
                            <Col className={styles.bid} md={2}>
                            23
                            </Col>
                            <Col className={styles.bid} md={2}>
                                4920   
                            </Col>
                            <Col className={styles.offer} md={2}>
                                178.43
                            </Col>
                            <Col className={styles.offer} md={2}>
                            23
                            </Col>
                            <Col className={styles.offer} md={2}>
                                4920   
                            </Col>
                        </Row>
                        <hr></hr>
                        <Row>
                            <Col md={3} className={styles.info}>
                                open
                            </Col>
                            <Col md={3} className={styles.infoDetail}>
                               181.10
                            </Col>
                             <Col md={3} className={styles.info}>
                                high
                            </Col>
                            <Col md={3} className={styles.infoDetail}>
                                192.40
                            </Col>
                        </Row>
                         <Row>
                            <Col md={3} className={styles.info}>
                                low
                            </Col>
                            <Col md={3} className={styles.infoDetail}>
                              156.34
                            </Col>
                             <Col md={3} className={styles.info}>
                                close
                            </Col>
                            <Col md={3} className={styles.infoDetail}>
                                189.52
                            </Col>
                        </Row>
                          <Row>
                            <Col md={3} className={styles.info}>
                                volume  
                            </Col>
                            <Col md={3} className={styles.infoDetail}>
                               4.32M
                            </Col>
                             <Col md={3} className={styles.info}>
                                avg.price
                            </Col>
                            <Col md={3} className={styles.infoDetail}>
                                178.48
                            </Col>
                        </Row>
                    </Col>
                </Row>
                </div>
            </Col>
        </Row>
        )
    }
}


export default MarketDepth