import React, { useState,useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import Slider from 'react-slick'
import styles from './StockNews.module.css'

const StockNewsData = ({ title, description, image, link }) => {
  const [toggel,setToggel] = useState(false)

  useEffect(()=>{
    const { innerWidth: width } = window
    width <= 1024  ? setToggel(true) : setToggel(false)
  },[])

  return (
    <>
    <a href={link} style={{textDecoration:'none' }} target="_blank" rel="noopener noreferrer">
    <div className='d-none d-lg-block'>
    <Row className={`${styles.newsBorder}`}>
			<Col md={8} lg={9} style={{ borderBottomLeftRadius:'8px',borderTopLeftRadius:'8px',paddingLeft:'auto',paddingTop:"22px",paddingBottom:'22px'}} >
				<div className={styles.newsTitle}>{toggel ? title && title.length > 0 ? title.slice(0,80) : "" : title && title.length > 0 ? title : "" }</div>
				<div className={styles.newsDesc}>{toggel ? description && description.length > 0 ? description.slice(0,92) : "" : description && description.length > 0 ?  description.slice(0,138) : ""}</div>
			</Col>
			<Col
      md={4}
        lg={3}
				style={{
					textAlign: 'end',
          borderBottomRightRadius:'8px',borderTopRightRadius:'8px',
          paddingRight:'auto',paddingTop:"20px",paddingBottom:'20px'
				}}>
          <div className={styles.imageCss} style={{backgroundImage:`url(${image})`,backgroundRepeat:'no-repeat',backgroundSize:'100px',backgroundPosition:'center'}}></div>
			</Col>
		</Row>
    </div>
    </a>
    {/* mobile */}
    <a href={link} style={{textDecoration:'none' }} target="_blank" rel="noopener noreferrer">
    <div className='d-block d-lg-none'  >
        <Row className={styles.newsMargin}>
          {/* <Col xs='auto' sm={2} style={{padding:'0px'}}>

          <img className={styles.imageCss} src={image}></img>

          </Col> */}
           <Col xs='auto' style={{paddingLeft:'0px',paddingRight:"0px",marginLeft:'15px'}}>
            <div className={styles.imageCss} style={{backgroundImage:`url(${image})`,backgroundRepeat:'no-repeat',backgroundSize:'50px',backgroundPosition:'center'}}></div>
          </Col>
          <Col xs={8} sm={4}>
          <div className={styles.newsTitle}>{title && title.length > 0 ? title.slice(0,40) : ""}</div>
				  <div className={styles.newsDesc}>{description && description.length > 0 ? description.slice(0, 50) : ""}</div>
          </Col>
        </Row>
    </div>
    </a>
    </>
	)
}
export default StockNewsData

  {/* <div className='d-none d-lg-block'>
		<Row className={`${styles.newsBorder}`}>
			<Col lg={9} style={{ paddingTop: '10px' }}>
				<div className={styles.newsTitle}>{title}</div>
				<div className={styles.newsDesc}>{description.slice(0, 138)}</div>
			</Col>
			<Col
				style={{
					textAlign: 'end'
				}}>
				<img className={styles.imageCss} src={image}></img>
			</Col>
		</Row>
    </div> */}
