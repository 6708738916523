import React, { useState, useEffect,useContext } from 'react'
import styles from './sso.module.css'
import { Row,Form,Col } from 'react-bootstrap'
import SignUpButton from '../../components/SignUpButton/SignUpButton'
import {ThemeContext} from '../../Context/ThemeContext'
import {resendotpsso} from '../../api'
import crossImage from '../../public/images/cross.png'
import queryString from 'query-string'

const Otp = ({auth_code,getTokenFromCode,otpError,setotpError}) => {
  const timerSec = 30
	const [otpValue, setOtpValue] = useState('')
  const [check, setCheck] = useState(true)
  const [resendOtpTimer,setresendOtpTimer] = useState(timerSec)
  const [otpSendMessage,setotpSendMessage] = useState('')
  const [resendDisable,setresendDisable] = useState(false)


  let queryUrl = queryString.parse(window.location.search, {
    parseNumbers: false
  })
  const { buttonColor } = useContext(ThemeContext)

  useEffect(() => {
    startTimer()
  },[resendOtpTimer])


  const startTimer = () => {
    const timer = setTimeout(() => {
      setresendOtpTimer(resendOtpTimer - 1)
    }, 1000)
    if (resendOtpTimer === 0) {
      clearInterval(timer)
    }
  }

	const handleChange = e => {
    setotpError('')
    setotpSendMessage('')
		let a = e.target.value.replace(/[^0-9]/g, '').length
		const re = /^[0-9\b]+$/
		if (e.target.value === '' || re.test(e.target.value)) {
			a <= 6 && setOtpValue(e.target.value)
		}
	}
	useEffect(() => {
		otpValue.length === 6 ? setCheck(false) : setCheck(true)
	}, [otpValue])

  const verifyOtpValue = () => {
    //  verifyotp value api
    getTokenFromCode(queryUrl,auth_code,otpValue)
  }


 const keyPressHandler = (event) => {
    if (event.which === 13) {
      verifyOtpValue()
    }
  }

  const onResend = () => {
  	resendotpsso(auth_code).then(res => {
			if (res.status === 200) {
        setotpSendMessage('OTP sent')
        setresendOtpTimer(timerSec)
			  startTimer()
			}
		}).catch(error => {
      if(error.status === 429 ){
        setotpSendMessage('OTP Resend attempts exhausted.',setresendDisable(true))
      }
      else{
      setotpSendMessage('Something went wrong')
      }
    })
  }

  const onClose = () => {
    let logoutpage = sessionStorage.getItem('logoutPage') ? sessionStorage.getItem('logoutPage') : ''
    if(logoutpage){
      window.location.href=logoutpage
    }else{
      window.location.href='/logout'
    }
  }


	return (
    <>
       <div className={styles.crossImageStyling}>
				<img src={crossImage} onClick={onClose}/>
			</div>
		          <Row className={styles.verifyRow}>
                  <Form.Group
                    className={`form-focus ${ otpValue !== '' ? 'focused' : null
                      }`}>
                    <Form.Control
                      className={styles.verifyInput}
                      type='number'
                      name='otp'
                      tabIndex='1'
                      value={otpValue}
                      onKeyPress={(e) => keyPressHandler(e)}
                      onChange={event => handleChange(event)}
                      onPaste={(e) => {
                        e.preventDefault()
                        return false;
                      }}
                      onCopy={(e) => {
                        e.preventDefault()
                        return false;
                      }}
                    ></Form.Control>
                    <Form.Label className='focus-label'>Enter OTP</Form.Label>
                  </Form.Group>
                  <SignUpButton
                    style={{ backgroundColor: buttonColor }}
                    title='Verify OTP'
                    classes={styles.otpButton}
                    disabled={check}
                    onClick={verifyOtpValue}
                  />
                </Row>
                <Row className={styles.otpSuccess}>
                  {otpError}
                  </Row>
                  {resendDisable ? '' :
                  <Row className={styles.resendOTP}>
                    {resendOtpTimer > 0 ? (
                      <>
                        <span className={styles.timer}>{resendOtpTimer}s</span>
                        <span
                          className={styles.goBackText}
                          style={{
                            color: 'rgba(22, 37, 66, 0.25)',
                            borderBottom: 'rgba(22, 37, 66, 0.25)'
                          }}>
                          Resend OTP
                        </span>
                      </>
                    ) : (
                      <span className={styles.goBackText} onClick={onResend}>
                        Resend OTP
                      </span>
                    )}
                </Row> }
                {otpSendMessage ?
                <Row className={styles.otpSuccess} style={{ justifyContent: 'center',color: otpSendMessage === 'OTP sent' ? '#0DC014' :''}}>
                    <Col md={12} xs={12} style={{textAlign:'center',marginTop:"20px"}}>
                    {otpSendMessage}
                  </Col>
                  </Row>
                  :''}
                </>
	)
}

export default Otp
