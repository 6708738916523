import React from 'react'
import styles from './withdrawAdd.module.css'
import { Row, Col } from 'react-bootstrap'
import noteImage from '../../public/images/note.png'

const WithDrawAdd = ({ setModalData, data, buttonColor }) => {
	return (
		<div className={styles.card}>
			<Row style={{ paddingBottom: '40px', borderBottom: '1px solid #EFEFF1' }}>
				<Col md={12}>
					<div className={styles.title}> {'< Add Bank account'}</div>
				</Col>
				<Col md={10}>
					<div className={styles.information}>
						It is important that all information you provide below is correct.
						Incorrect information can lead to delays in your funds being
						received and fees to process your withdrawl request again.
					</div>
				</Col>
				<Col md={12}>
					<div className={styles.details}>Bank details</div>
				</Col>
				<Col md={12}>
					<div className={styles.beneficiary}>Beneficiary name</div>
				</Col>
				<Col md={12}>
					<input
						className={styles.inputAnkit}
						placeholder='Name as per bank account'></input>
				</Col>
				<Col md={12}>
					<div className={styles.beneficiary}>
						Beneficary account number/IBAN
					</div>
				</Col>
				<Col md={12}>
					<input
						className={styles.inputAnkit}
						placeholder='Account number/IBAN'></input>
				</Col>
				<Col md={12}>
					<div className={styles.beneficiary}>Bank name</div>
				</Col>
				<Col md={12}>
					<input
						className={styles.inputAnkit}
						placeholder='Enter bank name'></input>
				</Col>
				<Col md={12}>
					<div className={styles.beneficiary}>Bank address</div>
				</Col>
				<Col md={12}>
					<input
						className={styles.inputAnkit}
						placeholder='Enter bank address'></input>
				</Col>
				<Col md={12}>
					<div className={styles.beneficiary}>Bank state/provience</div>
				</Col>
				<Col md={12}>
					<input
						className={styles.inputAnkit}
						placeholder='Enter bank state/provience'></input>
				</Col>
				<Col md={12}>
					<div className={styles.beneficiary}>Bank zip/postal code</div>
				</Col>
				<Col md={12}>
					<input
						className={styles.inputAnkit}
						placeholder='Bank zip/postal code'></input>
				</Col>
				<Col md={12}>
					<div className={styles.beneficiary}>Beneficiary name</div>
				</Col>
				<Col md={12}>
					<input
						className={styles.inputAnkit}
						placeholder='Name as per bank account'></input>
				</Col>
				<Col md={12}>
					<div className={styles.beneficiary}>Bank country</div>
				</Col>
				<Col md={12}>
					<select className={styles.inputAnkit}>
						<option>India</option>
					</select>
				</Col>
			</Row>
			<Row style={{ marginTop: '32px' }}>
				<Col md={12}>
					<div className={styles.details}>Intermediary details</div>
				</Col>
				<Col md={12}>
					<div className={styles.beneficiary}>
						Intermediary/correspondent bank name (if applicable)
					</div>
				</Col>
				<Col md={12}>
					<input
						className={styles.inputAnkit}
						placeholder='Intermediary/correspondent bank name'></input>
				</Col>
				<Col md={12}>
					<div className={styles.beneficiary}>
						Intermediary/correspondent swift number (if applicable)
					</div>
				</Col>
				<Col md={12}>
					<input
						className={styles.inputAnkit}
						placeholder='Intermediary/correspondent swift number'></input>
				</Col>
				<Col md={12}>
					<div className={styles.beneficiary}>Additional instructions</div>
				</Col>
				<Col md={12}>
					<input
						className={styles.input2}
						placeholder='Intermediary/correspondent swift number'></input>
				</Col>
			</Row>
			<Row>
				<Col>
					<button
						style={{ backgroundColor: buttonColor }}
						className={`${styles.addfunds} themebutton`}
						onClick={() => setModalData(data)}>
						Save Bank Account
					</button>
				</Col>
			</Row>
		</div>
	)
}

export default WithDrawAdd
