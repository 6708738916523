import React,{useEffect,useState} from 'react'
import {Row,Col} from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import DownloadIcon from '../../../../public/images/easyfund/download.svg'
import FileIcon from '../../../../public/images/easyfund/fileIcon.svg'
import DeleteIcon from '../../../../public/images/easyfund/delete.svg'
import styles from './easyfund.module.css'

const UploadImage = ({
	files,
	onDrop,
	dropRef,
	openDialog,
	onRemove,
	name,
  accept,
  changeFileAccept,
  password,
  setPassword
}) => {

  const [fileCalSize,setFileCalSize] = useState('')
  const [fileSize,setFileSize] = useState('')
  const [showPassword,setShowPassword] = useState(false)
  useEffect(() => {
    let size = ''
    if(files && files.length){
    let fileSize = files[0].size.toString();
    if(fileSize.length < 7){
      size =`${Math.round(+fileSize/1024)}KB`}
    else{
      size =`${Math.round(Math.round(+fileSize/1024)/1000)}MB`
    }
    setFileCalSize(size)
    setFileSize(files[0].size)
  }
  },[files])

  useEffect(() => {
    if(fileSize > 5000000 && files.length >0 ){
     changeFileAccept({[name]:false})
    }else if(fileSize <= 5000000 && files.length >0){
      changeFileAccept({[name]:true})
    }
  },[fileCalSize])

	return(
		<Dropzone
			onDrop={onDrop}
			ref={dropRef}
			noClick
      maxSize={5000000}
      accept={accept}
      multiple={false}
      // maxFiles={1}
			// accept='image/jpeg, image/png, image/gif, image/pdf, image/jpg, .pdf'
      >
			{({ getRootProps, getInputProps }) => (
        files.length === 0 ?
					<div
						{...getRootProps({ className: 'dropzone' })}
						className={styles.dropImageCenter}>
						<input {...getInputProps()} />
            <div className={styles.uploadButton} 	onClick={openDialog}>
             <img src={DownloadIcon} className={styles.downloadImage}></img>
             <span className={styles.uploadTitle}>{`Upload Bank Statement (<5MB)`}</span>
            </div>
					</div> :
                <Row {...getRootProps({ className: 'dropzone' })} className={styles.fileBox} style={{height:'fit-content'}}>
                  	<input {...getInputProps()} />
                  <Col md={1} xs={1} className='p-0'>
                  <img src={FileIcon} className={styles.fileImage}></img>
                  </Col>
                  <Col style={{textAlign:'start'}}>
                  <div className={`${styles.title} ${styles.ellipsisText}`} style={{margin:'0px'}}>{files[0].path}</div>
                  {fileSize > 5000000 ?
                  <div className={styles.fileSize} style={{color:'#FA274A'}}>File should be under 5MB
                  <span style={{color:'#0061D3',cursor:'pointer'}} onClick={() => {
                    onRemove(name,openDialog)
                  }}> &nbsp; Re-upload</span></div>:
                  <div className={styles.fileSize}>{fileCalSize}</div>}
                  </Col>
                  <Col md={1} xs={1} className='p-0'>
                  <img src={DeleteIcon} className={styles.deleteBox}onClick={() => {
                    onRemove(name)
                    setShowPassword(false)
                    }}></img>
                  </Col>
                  <div style={{width:'100%'}}>
                    {showPassword ?
                     <div className={styles.inputContainer} style={{marginTop:"8px"}}>
                       <div className={styles.input} style={{padding:'5px 12px'}}>
                               <input type="password" placeholder="Password"
                                value={password}
                               onChange={(e)=>setPassword(e.target.value)} />
                       </div>
                       <div className={styles.fileSize} style={{paddingTop:'4px',textAlign:'left'}}>
                       Transfer may be rejected if the file password is incorrect.
                       </div>
                       <div className={styles.buttonContainer}>
                        <button className={styles.activeButton}>
                          <div className={styles.activateText} onClick={() => setShowPassword(false)}>Save Password</div>
                        </button>
                        <button className={styles.secondaryBtn} onClick={() => setPassword('',setShowPassword(false))}>
                          <div className={styles.activateText} style={{color:'#0061D3'}}>Cancel</div>
                        </button>
                       </div>
                    </div>
                    :
                    password === '' ?
                    <div className={styles.fileSize}>If password protected, provide details
                      <span style={{color:'#0061D3',cursor:'pointer'}} onClick={() => setShowPassword(true)}> here</span>
                    </div> :
                    <div className={styles.fileSize} style={{textAlign:'start',paddingLeft:'40px'}}>Password Updated
                      <span style={{color:'#0061D3',cursor:'pointer'}} onClick={() => setShowPassword(true)}> Edit</span>
                    </div>
                   }
                  </div>
                </Row>
			)}
		</Dropzone>
  )
}

export default UploadImage
