import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import SkeletonElement from './SkeletonElement'
import Shimmer from './Shimmer'

const stackSummarySkeleton = () => {
	return (
		<Card className='skeletonCard ml-0 mr-0'>
			<Card.Body>
				<>
        <Row>
          <Col>
          <SkeletonElement width='500px' height='150px'></SkeletonElement>
          </Col>
          <Col>
          <SkeletonElement width='100px' height='30px'></SkeletonElement>
          <SkeletonElement width='70px' height='10px'></SkeletonElement>
          <div className='mt-5'>
          <SkeletonElement width='100px' height='30px'></SkeletonElement>
          <SkeletonElement width='70px' height='10px'></SkeletonElement>
          </div>
          </Col>
        </Row>

					<Shimmer />
				</>
			</Card.Body>
		</Card>
	)
}

export default stackSummarySkeleton
