import React, {useEffect,useState} from 'react';
import { map } from 'lodash';
import { exportCSVFile } from '../../exportData';
import styles from './portfolio.module.css'
import excelImage from "../../public/images/excel.png";

// const headers = ['Symbol','Name','Name','Shares','Avg.Cost','TotalInvested','TotalReturn','ReturnPercent','CurrentValue','Type',]
const headers = "Symbol,Name,Price,Price Change,Shares,Avg.Cost,Total Invested,Total Return,Return Percent,Current Value,Type \r\n";
const ExportPortfolio = ({
  stockData,etfData,stackData
}) => {
  const [holdingsData,setHoldingsData] = useState([])
  useEffect(() => {
   const holdings = [...stockData,...stackData]
   const data = []
   holdings.map((hold) => {
     const obj = {
          Symbol: hold.category === 'stack' ? hold.Stack && hold.Stack :  hold.category === 'stock' ? hold.Stock && hold.Stock :'' ,
          Name: hold.Name ? hold.Name : '',
          Price: hold.Price ? hold.Price : '',
          'PriceChange':hold['Chng'] ? hold['Chng'] :'',
          Shares: hold.Shares ? hold.Shares : '',
          'Avg.Cost': hold['Avg.Cost'] ? hold['Avg.Cost'] : '' ,
          'Total Invested': hold['Total Invested'] ? hold['Total Invested'] : '' ,
          'Total Return': hold['Total Return'] ? hold['Total Return'] : '' ,
          'Return Percent': hold['Return Percent'] ? hold['Return Percent']: '',
          'Current Value': hold['Current Value'] ? hold['Current Value'] : '',
          Type: hold.category,
     }
     data.push(obj)
   })

   etfData.map((hold) => {
    const obj = {
         Symbol: hold.ETF ? hold.ETF : '' ,
         Name: hold.Name ? hold.Name : '',
         Price: hold.Price ? hold.Price : '',
         'PriceChange':hold['Chng'] ? hold['Chng'] :'',
         Shares: hold.Shares ? hold.Shares : '',
         'Avg.Cost': hold['Avg.Cost'] ? hold['Avg.Cost'] : '' ,
         'Total Invested': hold['Total Invested'] ? hold['Total Invested'] : '' ,
         'Total Return': hold['Total Return'] ? hold['Total Return'] : '' ,
         'Return Percent': hold['Return Percent'] ? hold['Return Percent']: '',
         'Current Value': hold['Current Value'] ? hold['Current Value'] : '',
         Type: 'etf',
    }
    data.push(obj)
  })
   setHoldingsData(data)
  },[stockData,etfData,stackData])

  return(
     <div className={styles.exportDiv}
      onClick={() => exportCSVFile(
        holdingsData,
        'portfolio',
        headers,
        'portfolio'
      )}
      >
       <img src={excelImage}></img>
       <span className={styles.exportText}>Export</span>
      </div>
  )
}

export default ExportPortfolio;
