import React, { Fragment } from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import SkeletonElement from './SkeletonElement'
import Shimmer from './Shimmer'

const NomineeListSkeleton = () => {
	return (
		<Card className='skeletonCard ml-0 mr-0'>
			<Card.Body>
				<Fragment>
                    <Row>
                        <Col className='d-flex justify-content-between'>
                            <SkeletonElement width='220px' height='30px'></SkeletonElement>
                            <SkeletonElement width='70px' height='20px'></SkeletonElement>
                        </Col>
                        <Col xl={12} className='mb-3 mt-3'>                            
                            <SkeletonElement width='200px' height='25px'></SkeletonElement>
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={6} xs={6} className='mb-3 mt-3'>
                            <SkeletonElement width='100px' height='20px'></SkeletonElement>
                            <SkeletonElement width='100px' height='20px'></SkeletonElement>
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={6} xs={6} className='mb-3 mt-3'>
                            <SkeletonElement width='100px' height='20px'></SkeletonElement>
                            <SkeletonElement width='100px' height='20px'></SkeletonElement>
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={6} xs={6} className='mb-3 mt-3'>
                            <SkeletonElement width='100px' height='20px'></SkeletonElement>
                            <SkeletonElement width='100px' height='20px'></SkeletonElement>
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={6} xs={6} className='mb-3 mt-3'>
                            <SkeletonElement width='100px' height='20px'></SkeletonElement>
                            <SkeletonElement width='100px' height='20px'></SkeletonElement>
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={6} xs={6} className='mb-3 mt-3'>
                            <SkeletonElement width='100px' height='20px'></SkeletonElement>
                            <SkeletonElement width='100px' height='20px'></SkeletonElement>
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={6} xs={6} className='mb-3 mt-3'>
                            <SkeletonElement width='100px' height='20px'></SkeletonElement>
                            <SkeletonElement width='100px' height='20px'></SkeletonElement>
                        </Col>
                        <Col xl={12} className='mb-3 mt-3'>
                            <SkeletonElement width='200px' height='25px'></SkeletonElement>
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={6} xs={6} className='mb-3 mt-3'>
                            <SkeletonElement width='100px' height='20px'></SkeletonElement>
                            <SkeletonElement width='100px' height='20px'></SkeletonElement>
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={6} xs={6} className='mb-3 mt-3'>
                            <SkeletonElement width='100px' height='20px'></SkeletonElement>
                            <SkeletonElement width='100px' height='20px'></SkeletonElement>
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={6} xs={6} className='mb-3 mt-3'>
                            <SkeletonElement width='100px' height='20px'></SkeletonElement>
                            <SkeletonElement width='100px' height='20px'></SkeletonElement>
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={6} xs={6} className='mb-3 mt-3'>
                            <SkeletonElement width='100px' height='20px'></SkeletonElement>
                            <SkeletonElement width='100px' height='20px'></SkeletonElement>
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={6} xs={6} className='mb-3 mt-3'>
                            <SkeletonElement width='100px' height='20px'></SkeletonElement>
                            <SkeletonElement width='100px' height='20px'></SkeletonElement>
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={6} xs={6} className='mb-3 mt-3'>
                            <SkeletonElement width='100px' height='20px'></SkeletonElement>
                            <SkeletonElement width='100px' height='20px'></SkeletonElement>
                        </Col>
                    </Row>
                    <Shimmer />
                </Fragment>
            </Card.Body>
		</Card>
	)
}

export default NomineeListSkeleton
