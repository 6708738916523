import React, { useState, useEffect} from 'react'
import styles from './cashManagement.module.css';
import { Row, Col} from 'react-bootstrap';
import ManuallyEnter from '../../public/images/manuallyEnter.svg';
import RightArrow from '../../public/images/rightArrowIcon.svg';
import NameImg from '../../public/images/cashManagementName.svg'
import leftArrow from '../../public/images/leftArrowIcon.svg';
import bankImg from '../../public/images/bankImage.svg'
import PinInput from "react-pin-input"
import about from '../../public/images/cashmanagementAbout.svg'
import indiaflag from '../../public/images/indiaFlag.svg';
import UsFlag from '../../public/images/UsFlag.svg';
import {getTransferContactDetails, getContactBankDetails, createContact,
  customOTP, validateOtp, transferMoney, transactionCharges, geoCodingApi} from '../../api'
import RightTick from '../../public/images/cashManagementRightTick.svg';
import DownIcon from '../../public/images/iconDown.svg';
import ConfirmTransfer from './ConfirmTransfer'
import errorImg from '../../public/images/errorCashManagement.svg';
import location from '../../public/images/locationCashManagement.svg';
import editName from '../../public/images/editNameCashManagement.svg';
// import { useDispatch, useSelector } from 'react-redux'
// import { getUserAccountSummary } from '../../store/actions/index'
import { decimalAdjust,addCommasinPrice } from '../../utils'
import { remove } from 'lodash';




const countryDropDown = [
  {country:'India',
  img:indiaflag,
  code:"IN"},
  {country:'United States',
  img:UsFlag,
  code:"USA"},
]

const bankAccountTypeDropDown = [
  {type:"Business Checking",value:"businessChecking"},
  {type:"Personal Checking", value:"personalChecking"},
  {type:"Business Saving", value:"businessSavings"},
  {type:"Personal Saving", value:"personalSavings"}
]

const otherCountrybankAccountTypeDropDown = [
  {type:"Business",value:"business"},
  {type:"Personal", value:"personal"},
]

const TransferOfFunds = (props) =>{
  const[stepCount, setStepsCount] = useState(1)
  const[stepBeneficiaryCountry, setStepBeneficiaryCountry] = useState(false)
  const[stepSecond, setStepSecond] = useState(false);
  const[stepThird, setThirdStep] = useState(false)
  const[accountHolderName, setAccountHolderName] = useState('');
  const[showCountry, setShowCountry] = useState(false);
  const[selectCountry, setSelectCountry] = useState('India');
  const[selectedCountryImg, setSelectedCountryImg] = useState(indiaflag);
  const[usTypeTransfer, setUsTypeTransfer] = useState('ACH')
  const[fundsValue, setFundsValue] = useState(20);
  const[contactData, setContactData] = useState();
  const[bankAccountNumber, setBankAccountNumber] = useState();
  const[confirmAccountNumber, setConfirmAccountNumber] = useState();
  const[isAccountNumberMatch, setIsAccountNumberMatch] = useState();
  const[savedContact, setSavedContact] = useState(false);
  const[contactName, setContactName] = useState('');
  const[selectAccountType, setAccountType] = useState({type:"Business Checkings",value:"businessChecking"});
  const[showAccountType, setShowAccountType] = useState(false);
  const[isSwiftNoRight, setIsSwiftNoRight] = useState();
  const[bankAddressFromSwiftNo, setBankAddressFromSwiftNo] = useState('');
  const[swiftOrRoutingNumber, setSwiftOrRoutingNumber] = useState();
  const[accountNumberErrorMessage, setAccountNumberErrorMessage] = useState('')
  const[hashID, setHashId] = useState('')
  const[isOtpVerified, setIsOtpVerified] = useState(false)
  const[otpError, setOtpError] = useState('')
  const[contactPayeeId, setContactPayeeID] = useState('')
  const[isTransferSuccess, setIsTransferSuccess] = useState(false);
  const[transferSuccessTime, setIsTransferSuccessTime] = useState('')
  const[transferCharges, setTransferCharges] = useState('')
  const[transferType,setTransferType] = useState('')
  const[brokerId, setBrokerId] = useState()
  const[apiTransactionType, setApiTransactionType] = useState("");
  const[addRemark, setAddRemark] = useState('');
  const[finalTransferButtonClicked, setFinalTransferButtonClicked] = useState(false);
  const[contactLoaded, setContactLoaded] = useState(false);
  const[lenghtOfAccountNumber, setLengthOfAccountNumber] = useState('');

  // beneficairy details
  const[beneficiaryCountry, setBeneficiaryCountry] = useState('India')
  const[beneficiaryPostolCode, setBeneficiaryPostolCode] = useState('')
  const[beneficiaryAddress, setBeneficiaryAddress] = useState('')
  const[beneficiaryCity, setBeneficiaryCity] = useState('')
  const[beneficiaryState, setBeneficiaryState] = useState('')

  useEffect( () => {
    if(selectCountry === "India"){
      setAccountType({type:"Business",value:"business"})
    } else{
      setAccountType({type:"Business Checkings",value:"businessChecking"})
    }
  },[selectCountry])

  const checkAccountNumberMatch = (e,type) => {
    if(type === 'account'){
      if(confirmAccountNumber && confirmAccountNumber.length > 0 && e.target.value.length >= 6){
        setIsAccountNumberMatch(e.target.value === confirmAccountNumber ? true : false)
        setAccountNumberErrorMessage(e.target.value === confirmAccountNumber ? '' : "Account numbers do not match")
        setLengthOfAccountNumber('')
      } else if(e.target.value.length < 6){
        setLengthOfAccountNumber('invalid account number',e.target.value)
        if(confirmAccountNumber && confirmAccountNumber.length > 0){
          setAccountNumberErrorMessage(e.target.value === confirmAccountNumber ? '' : "Account numbers do not match")
          setIsAccountNumberMatch(e.target.value === confirmAccountNumber ? true : false)
        }
      } else{
        setLengthOfAccountNumber('')
      }
    } else{
        if(bankAccountNumber){
        setIsAccountNumberMatch(bankAccountNumber === e.target.value ? true : false)
        setAccountNumberErrorMessage(bankAccountNumber === e.target.value ? '' : "Account numbers do not match")
      }
    }
  }

  const clickOnContact = (e) => {
    setStepsCount(stepCount + 1)
    getContactDetails(e.stoCashAccountPayeeId)
    setStepSecond(true)
    setSavedContact(true)
    setSelectCountry(e.countryCode === "USA" ? "United States" : "India")
    setSelectedCountryImg(e.countryCode === "USA" ? UsFlag : indiaflag)
    setIsAccountNumberMatch(true)
    setIsSwiftNoRight(true)
    setUsTypeTransfer(e.transferType === "DOM_WIRE" ? "WIRE" : "ACH")
  }

  const manuallyEnterDetails = () => {
    setStepsCount(stepCount + 1);
    setStepSecond(true)
    setSavedContact(false)
    setIsAccountNumberMatch()
    setIsSwiftNoRight()
    setContactLoaded(false)
    setBankAccountNumber()
    setConfirmAccountNumber()
    setSwiftOrRoutingNumber()
    setBankAddressFromSwiftNo()
    setContactName('')
    setBeneficiaryPostolCode('')
    setBeneficiaryAddress('')
    setBeneficiaryCity('')
    setBeneficiaryState('')
  }

  const getContactDetails = (e) => {
    getTransferContactDetails(e).then(response=>{
      if(response.code === 200){
        setContactData(response.data)
        setBrokerId(response.data.brokerPayeeId)
        setApiTransactionType(response.data.transferType)
        }
      }).catch((err)=>{
        console.error(err)
    })
  }

  const checkSwiftNumber = (e,type) => {
    setIsSwiftNoRight();
    if(e) {
    getContactBankDetails(e,type).then(response=>{
      if(response.code === 200){
        setBankAddressFromSwiftNo(response.data);
        setIsSwiftNoRight(true);
      }
      if(response.status === 500){
        setIsSwiftNoRight(false);
      }
      })
      .catch((err)=>{
        console.error('err',err)
        setIsSwiftNoRight(false);
    })} else{
      setIsSwiftNoRight(false);
    }
  }

  const ontoNextStep = () => {
    setStepsCount(stepCount + 1)
    sendOtpApi()
    getTransactionCharges()
    setThirdStep(true)
  }

  const sendOtpApi = () => {
    const body = {"reason": "transfer_fund" }
    customOTP(body).then(response=>{
      if(response.code === 200){
        setHashId(response.data.hashID)
        }
      }).catch((err)=>{
        console.error(err)
    })
  }

  const getTransactionCharges = (transferType,code) => {
    transactionCharges(transferType,code).then(response=>{
      if(response.data.code === 200){
        setTransferCharges(response.data.data.fixedFeeAmt)
        setTransferType(response.data.data.feeDesc)
      }
      }).catch((err)=>{
        console.error(err)
    })
  }

  const usTransferOtpSend = () => {
    setStepsCount(4)
    sendOtpApi()
    getTransactionCharges(usTypeTransfer === "ACH" ? "ach" : "wire","US")
  }

  const otherCountryTrasnferNextStep = (e) => {
    if(e !== undefined && e === true){
      setStepsCount(stepCount + 1)
      sendOtpApi()
      getTransactionCharges("wire","IND")
    } else{
      setStepsCount(stepCount + 1)
      setThirdStep(true)
    }
  }
  const fundsNotGreaterThanBalance = fundsValue && props.amount !== undefined && fundsValue > props.amount ? "Not enough balance." : fundsValue >= 25000 ? "Cannot exceed Daily Limit" : "";
  const inputValueMoreThanZero = fundsValue.length === 0 || fundsValue === "" || fundsValue == 0 || fundsNotGreaterThanBalance !== "" ? true : false;
  const disabledContinueButton = fundsNotGreaterThanBalance == "" && !inputValueMoreThanZero && contactName && contactName.trim().length !== 0  && isAccountNumberMatch && isAccountNumberMatch !== undefined && isSwiftNoRight && isSwiftNoRight !== undefined && lenghtOfAccountNumber == "" ? true : false
  const SubmitButton = (e) => {
    if ( disabledContinueButton && savedContact === false){
      return  <span className={styles.transferModalContinueButton} onClick={selectCountry === "United States" ? () => usTransferOtpSend() : () =>otherCountryTrasnferNextStep() }>Continue</span>
    } else if(!inputValueMoreThanZero && savedContact){
      return <span className={styles.transferModalContinueButton} onClick={selectCountry === "United States" ? () => usTransferOtpSend() : () =>otherCountryTrasnferNextStep() }>Continue</span>
    }
    else {
      return  <span className={styles.transferModalContinueButton} style={{backgroundColor:"grey"}} disabled>Continue</span>
    };
  };

  // steps for the transfer initiation 1.otp verification 2.saved the contacts 3.transfer money
  const initiateTheTransferProtocol = (e) => {
   if(e === "" || e === undefined || e.length !== 6){
      setOtpError('Enter a 6-digit OTP')
    } else if(addRemark === "") {
      setOtpError('Add Remarks to proceed further')
    } else{
      verifyingOtp(e)
      setOtpError('')
    }
  }

  const verifyingOtp = async (otp) => {
    if (otp.length === 6) {
      try {
        const response = await validateOtp(hashID, otp)
        if (response && response.code && response.code === 200) {
          setIsOtpVerified(true)
          setOtpError('')
          setFinalTransferButtonClicked(true)
          if(savedContact){
            transferOfMoney()
          } else {
            createANewContact();
          }
        }
        else {
          setOtpError('OTP is not valid')
          setFinalTransferButtonClicked(false)
        }
      } catch (error) {
        setOtpError('Invalid OTP')
        setFinalTransferButtonClicked(false)
      }
    } else {
      setOtpError('Enter a 6-digit OTP')
      setFinalTransferButtonClicked(false)
    }
  }

  const userInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : {}
  const createANewContact = () => {
    const beneficaryBody = {
        "addressType": "wire",
        "line1": beneficiaryAddress.replace(/^\s+|\s+$/g, ""),
        "city": beneficiaryCity,
        "state": beneficiaryState,
        "country": beneficiaryCountry === "India" ? "IN" : "US",
        "postalCode": beneficiaryPostolCode
    }
    const bankAddress = {
        "addressType": bankAddressFromSwiftNo.address.addressType,
        "line1": bankAddressFromSwiftNo.address.line1,
        "line2": bankAddressFromSwiftNo.address.line2,
        "city": bankAddressFromSwiftNo.address.city,
        "state": bankAddressFromSwiftNo.address.state,
        "country": bankAddressFromSwiftNo.address.country,
        "postalCode": bankAddressFromSwiftNo.address.postalCode ? bankAddressFromSwiftNo.address.postalCode : beneficiaryPostolCode
    }
    const update = {
      body: {
        accountId: props.brokerAccountID,
        name: contactName.replace(/^\s+|\s+$/g, ""),
        phone: null,
        email: null,
        accountNumber:bankAccountNumber,
        routingNumber: selectCountry === 'United States' ? swiftOrRoutingNumber : "",
        accountType : selectAccountType.value ,
        // accountType : selectCountry === 'United States' ?  "business" : "business",
        bankName: bankAddressFromSwiftNo.bankName,
        transferType: selectCountry === "India" ? "wire" : usTypeTransfer === "WIRE" ? "wire" : "ach",
        bankAddress:bankAddress,
        // bankAddress: bankBody,
        beneficiaryAddress: selectCountry === 'United States' ? "" : beneficaryBody,
        bankIdentifierType: selectCountry === 'United States' ? "" : "Swift",
        bankIdentifierCode:  selectCountry === 'United States' ? "" : swiftOrRoutingNumber,
        beneficiaryCountry: beneficiaryCountry ==="India" ? "IN" : "US"
      }
    }
    createContact(update).then(response=>{
      setOtpError('');
      if(response.data.code === 200){
          const {brokerPayeeId = "",transferType = ""} = response.data.data ? response.data.data[0] ? response.data.data[0] : response.data.data : response.data.data
          setBrokerId(brokerPayeeId)
          setApiTransactionType(transferType)
          setContactLoaded(true)
      }
      }).catch((err)=>{
        console.log('err',err.data)
        if(err.data.code === 400){
          setOtpError(err.data.message);
          setFinalTransferButtonClicked(false)
        }
        setOtpError(err.data.message ?  err.data.message : "something went wrong!");
    })
  }

  useEffect(() => {
    if(contactLoaded){
      createANewContact()
    }
  },[contactLoaded])

  useEffect(() => {
    if(savedContact === false && brokerId && apiTransactionType && brokerId !== "" && apiTransactionType !== "" && apiTransactionType !== undefined && brokerId!== undefined){
      transferOfMoney()
    }
  },[apiTransactionType])

  const transferOfMoney = () => {
    const data= {
      body: {
        accountId: props.brokerAccountID,
        contactId: brokerId,
        transferType: apiTransactionType ,
        amount: fundsValue,
        description: addRemark
      }
    }
    transferMoney(data).then((response=>{
      setOtpError('');
      if(response.data.code === 200){
        setIsTransferSuccess(true)
        setIsTransferSuccessTime(response.data.data.tranData.tranDt)
        setFinalTransferButtonClicked(false)
        props.setIsTransferOfFundSuccess(true)
        setTimeout(() => props.handleClose() , 3000)
      }
    })).catch((err)=>{
        if(err.response.data.code === 400){
          setOtpError(err.response.data.message);
          setFinalTransferButtonClicked(false)
        }
        console.error(err)
        setOtpError("something went wrong!");
      })
  }

  const twoDecimalPoints = (e) => {
    if(e.target.value === "" || e.target.value < 0){
      setFundsValue('')
    } else{
      const amount = decimalAdjust('ceil', e.target.value,-2)
      setFundsValue(amount)
    }
  }

  const findBankAccount = () => {
    if(contactData && contactData.payeeAddress && contactData.payeeAddress.addressCountryCd ) {
      const findValue = otherCountrybankAccountTypeDropDown.find(obj  => { return  obj.value === contactData.payeeAccountType })
      return findValue.type
    } else {
      const findValue = bankAccountTypeDropDown.find(obj  => { return  obj.value === contactData.payeeAccountType })
      return findValue.type
    }
  }

  const handleChange = (e) => {
    setBankAccountNumber(e.target.value.replace(/[^0-9]/gi,'').trim().slice(0,18))
  }

  const onBlurrFunction = (e) => {
    geoCodingApi(e.target.value).then(response => {
    if (response.code === 200 && response.data) {
      const res = response.data;
      const city = res.city ? res.city : ''
      const state = res.state ? res.state : ''
      setBeneficiaryCity(city)
      setBeneficiaryState(state)
    }
  }).catch(error => {
      console.log(error)
  })
}

const beneficiaryButtonContinue =  !inputValueMoreThanZero && beneficiaryCountry !== "" && beneficiaryCity !== "" && beneficiaryState !== "" && beneficiaryState.trim().length !== 0  && beneficiaryPostolCode !== "" && beneficiaryAddress !== "" && beneficiaryAddress.trim().length !== 0 ? true : false
  const BankAccountValue = savedContact && contactData ? findBankAccount() : "";
  return(
    <div style={{paddingBottom:"10px"}} className={styles.transferModalBody}>
      {/* step 1 */}
      {stepCount === 1 ?
      <>
      <Row>
        <Col lg={12} className={styles.transferModalHeading}>
          Transfer Funds
        </Col>
        <Col lg={12} className={styles.transferModalBalance}>
          Balance: ${props.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </Col>
      </Row>
      {/* <InputBoxValue/> */}
      <Row>
      <Col className={styles.transferModaInputBackground} style={{border: inputValueMoreThanZero ? "1px solid red" : ""}}>
        <span className={styles.transferModalcurrencyinput}>$</span>
        <input className={styles.transferModaInputBox}
        type='number'
        min="0"
        value={fundsValue}
        onChange={(e) => twoDecimalPoints(e)}></input>
        <span className={styles.transferModalCharges}>Min. Amount: $20</span>
      </Col>
      </Row>
      <Row>
        <Col>
            <span className={styles.otpErrorText}>{fundsNotGreaterThanBalance}</span>
        </Col>
      </Row>
      <Row>
        <Col className={styles.transferModalDetailsHeading}>
          <span>Transfer To</span>
        </Col>
      </Row>
      <Row className={styles.trasnferToManuallyBoxMargin}
      onClick={inputValueMoreThanZero ? "" : manuallyEnterDetails}>
        <Col className={styles.trasnferToManuallyBox}>
          <Row>
          <Col md={2} lg={2} sm={2} xs={2}>
          <img src={ManuallyEnter}></img>
          </Col>
          <Col className="pl-0" md={10} lg={10} xs={10} sm={10}>
            <span style={{lineHeight:"32px"}}>
              Manually Enter Details
            <img className={styles.transferManuallyRightArrow} src={RightArrow}/>
            </span>
          </Col>
          </Row>
        </Col>
      </Row>
      {props.contactList && [...props.contactList].reverse().map((data,index) => {
        return(<Row className={styles.trasnferToManuallyBoxMargin} onClick={inputValueMoreThanZero ? "" : () => clickOnContact(data)}>
                <Col className={styles.trasnferToContactBox}>
                  <Row>
                  <Col md={2} lg={2} sm={2} xs={2}>
                  <img style={{marginTop:"3px"}} src={data.countryCode === "USA" ? UsFlag : indiaflag}></img>
                  </Col>
                  <Col className="pl-0" md={10} lg={10} xs={10} sm={10}>
                    <span>
                      {data.payeeName}<span className={styles.transferManuallyTransferType}>{data.transferType ? data.transferType : ""}</span>
                    <img className={styles.transferManuallyRightArrow} src={RightArrow}/>
                    </span>
                    <span className={styles.transferManuallyBankNumber}>
                    {data.payeeAccountNum}<span className={styles.transferManuallyBankName}>{data.payeeBankName}</span>
                    </span>

                  </Col>
                  </Row>
                </Col>
              </Row>)
      })}
      </>
      :
      // step2.1
      stepCount === 3 ?
      <>
      <Row>
        <Col lg={1} md={1} sm={1} xs={1}><img src={leftArrow} onClick={() => setStepsCount(2)}></img></Col>
        <Col lg={11} md={11} sm={11} xs={11} >
          <span className={styles.transferModalHeading}>Transfer Funds To</span>
          <span className={styles.transferModalBalance}>Balance: ${props.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
        </Col>
      </Row>
      <Row>
      <Col className={styles.transferModaInputBackground} style={{border: inputValueMoreThanZero ? "1px solid red" : ""}}>
        <span className={styles.transferModalcurrencyinput}>$</span>
        <input className={styles.transferModaInputBox}
        type='number'
        min="0"
        value={fundsValue}
        onChange={(e) => twoDecimalPoints(e)}></input>
        <span className={styles.transferModalCharges}>Min. Amount: $20</span>
      </Col>
      </Row>
      <Row>
        <Col>
            <span className={styles.otpErrorText}>{fundsNotGreaterThanBalance}</span>
        </Col>
      </Row>
      <Row>
        <Col className={styles.transferModalDetailsHeading}>
          <span>Transfer Details</span>
        </Col>
      </Row>
      <Row className={styles.transferModalHeadingBorder}>
        <Col lg={12}>
          <Row>
            <Col className={styles.transferTypeContactName} onClick={savedContact && contactData ? "" :() => setStepsCount(2)}>
              <img className={styles.editNameImage} src={editName}/>
              <Row>
                  <Col md={2} lg={2} sm={2} xs={2}>
                    <img style={{marginTop:"4px"}} src={savedContact && contactData ? contactData.countryCode === "USA" ? UsFlag : indiaflag : selectCountry && selectCountry === "India" ? indiaflag : UsFlag }></img>
                  </Col>
                  <Col className="pl-0" md={10} lg={10} xs={10} sm={10}>
                    <span>
                      {savedContact && contactData ? contactData.payeeName : contactName}
                    </span>
                    {selectCountry === 'United States' ? "" : <span className={styles.beneficiaryAddressWithNameCM}>
                      {savedContact && contactData ? contactData.payeeAccountNum : bankAccountNumber} 
                      <span style={{color:"#767C8F",marginLeft:"6px"}}>
                        {savedContact && contactData ? contactData.payeeBankName : bankAddressFromSwiftNo && bankAddressFromSwiftNo.bankName ? bankAddressFromSwiftNo.bankName : ''} 
                      </span>
                    </span>}
                  </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={styles.transferTypeRow}>
        <Col md={12} lg={12}>
          <Row>
            <Col className={styles.transferModalTakeInputHeading}>Beneficiary Country</Col>
          </Row>
          <img className={styles.transferTypeModalImage} src={selectedCountryImg}/>
          <span className={styles.transferTypeModalInputCountry}
          style={{display:"block"}}
          onClick={savedContact && contactData ? "" : () => setShowCountry(!showCountry)}>
          {savedContact && contactData ? contactData.payeeAddress.addressCountryCd === "IN" ? "India" : "United States" : beneficiaryCountry}
          <img className={styles.transferTypeModaldownIcon} src={DownIcon}/></span>
          {showCountry ?
          <BankCountryType dropDown={countryDropDown}
          ifbeneficiary={true}
          setBeneficiaryCountry={setBeneficiaryCountry}
          dropDownshow={setShowCountry}
          countrySelect={setSelectCountry}
          countryImg={setSelectedCountryImg}/> : ""}
        </Col>
      </Row>
      <Row className={styles.transferTypeRow}>
        <Col md={12} lg={12}>
          <Row>
            <Col className={styles.transferModalTakeInputHeading}>Beneficiary Postal Code</Col>
          </Row>
          <img className={styles.transferTypeModalImage} src={location}/>
          <input className={styles.transferTypeModalInput}
          type="text"
          disabled={savedContact}
          onBlur={(e) => onBlurrFunction(e)}
          onChange={(e) =>setBeneficiaryPostolCode(e.target.value.replace(/[^0-9]/gi,'').trim().slice(0,12))}
          value={savedContact && contactData ? contactData.payeeAddress.addressZipPostalCd : beneficiaryPostolCode}/>
        </Col>
      </Row>
      <Row className={styles.transferTypeRow}>
        <Col lg={12}>
          <Row>
            <Col className={styles.transferModalTakeInputHeading}>Beneficiary Address</Col>
          </Row>
          <img className={styles.transferTypeModalImage} src={location}/>
          <input className={styles.transferTypeModalInput}
          type="text" id="input"
          disabled={savedContact}
          onChange={(e) =>setBeneficiaryAddress(e.target.value.slice(0,100))}
          value={savedContact && contactData ? contactData.payeeAddress.addressLine1 : beneficiaryAddress}
          />
        </Col>
      </Row>
      <Row className={styles.transferTypeRow}>
        <Col md={6} lg={6} xs={6} sm={6}>
          <Row>
            <Col className={styles.transferModalTakeInputHeading}>Beneficiary City</Col>
          </Row>
          <img className={styles.transferTypeModalImage} src={location}/>
          <input className={styles.transferTypeModalInput}
          type="text" id="input"
          disabled={savedContact}
          onChange={(e) =>setBeneficiaryCity(e.target.value.trim().slice(0,20))}
          value={savedContact && contactData ? contactData.payeeAddress.addressCity : beneficiaryCity}/>
        </Col>
        <Col md={6} lg={6} xs={6} sm={6}>
          <Row>
            <Col className={styles.transferModalTakeInputHeading}>Beneficiary State</Col>
          </Row>
          <img className={styles.transferTypeModalImage} src={location}/>
          <input className={styles.transferTypeModalInput}
          type="text" id="input"
          disabled={savedContact}
          onChange={(e) =>setBeneficiaryState(e.target.value.slice(0,20))}
          value={savedContact && contactData ? contactData.payeeAddress.addressState : beneficiaryState}/>
        </Col>
      </Row>
      {/* </>: ""} */}
      <Row className={styles.transferTypeModalBottomButton}>
        {/* <Col lg={4} md={2} sm={2} xs={2} className="pr-0">
        </Col> */}
        <Col lg={8} md={8} sm={10} xs={10}>
          <Row>
            <Col lg={6} sm={6} xs={6} md={6}>
              <span className={styles.transferModalContinueButton}
              style={{backgroundColor: beneficiaryButtonContinue ?  "" : savedContact && !inputValueMoreThanZero ? "" : "grey" }}
              onClick={beneficiaryButtonContinue  && savedContact === false ?  () => otherCountryTrasnferNextStep(true) : savedContact === true && !inputValueMoreThanZero ? () => otherCountryTrasnferNextStep(true) : ""}>Continue</span>
            </Col>
            <Col lg={6} sm={6} xs={6} md={6} className="text-right">
              <span className={styles.transferModalCancelButton} onClick={props.handleClose}>Cancel</span>
            </Col>
            {/* <Col lg={6} sm={6} xs={6} md={6}>
              <SubmitButton/>
            </Col> */}
          </Row>
        </Col>
      </Row>
      </>
      :
      // stepThird === false && stepSecond ?
      // step 2.2
      stepCount === 2 ?
      <>
      <Row>
        <Col lg={1} md={1} sm={1} xs={1}><img src={leftArrow} onClick={() => setStepsCount(1)}></img></Col>
        <Col lg={11} md={11} sm={11} xs={11} >
          <span className={styles.transferModalHeading}>Transfer Funds To</span>
          <span className={styles.transferModalBalance}>Balance: ${props.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
        </Col>
      </Row>
      <Row>
      <Col className={styles.transferModaInputBackground} style={{border: inputValueMoreThanZero ? "1px solid red" : ""}}>
        <span className={styles.transferModalcurrencyinput}>$</span>
        <input className={styles.transferModaInputBox}
        type='number'
        value={fundsValue}
        onChange={(e) => twoDecimalPoints(e)}></input>
        <span className={styles.transferModalCharges}>Min. Amount: $20</span>
      </Col>
      </Row>
      <Row>
        <Col>
            <span className={styles.otpErrorText}>{fundsNotGreaterThanBalance}</span>
        </Col>
      </Row>
      {/* <InputBoxValue/> */}
      <Row className={styles.transferTypeRow}>
        <Col className={styles.transferModalDetailsHeading}>
          <span>Transfer Details</span>
        </Col>
      </Row>
      <Row className={styles.transferModalHeadingBorder}>
        <Col md={6} sm={6} xs={6} lg={6}>
          <Row>
            <Col className={styles.transferModalTakeInputHeading}>Name</Col>
          </Row>
          <img className={styles.transferTypeModalImage} src={NameImg}/>
          <input className={styles.transferTypeModalInput}
          type="text"
          disabled={savedContact}
          onChange={(e) =>setContactName(e.target.value.slice(0,100).replace(/[^-\w\s]/gi, ""))}
          value={savedContact && contactData && contactData.payeeName !== undefined ? contactData.payeeName : contactName}/>
        </Col>
        <Col md={6} sm={6} xs={6} lg={6}>
          <Row>
            <Col className={styles.transferModalTakeInputHeading}>Bank Country</Col>
          </Row>
          <img className={styles.transferTypeModalImage} src={selectedCountryImg}/>
          <span className={styles.transferTypeModalInputCountry}
          style={{display:"block"}}
          onClick={savedContact ? "" : () => setShowCountry(!showCountry)}>
          {selectCountry}
          <img className={styles.transferTypeModaldownIcon} src={DownIcon}/></span>
          {showCountry ?
          <BankCountryType dropDown={countryDropDown}
          setAccountType={setAccountType}
          dropDownshow={setShowCountry}
          countrySelect={setSelectCountry}
          countryImg={setSelectedCountryImg}/> : ""}
        </Col>
      </Row>
      {selectCountry === "India" ?
      <>
      <Row className={styles.transferTypeRow}>
        <Col lg={12}>
          <Row>
              <Col className={styles.transferModalTakeInputHeading}>Bank Account Number</Col>
          </Row>
          <img className={styles.transferTypeModalImage} src={bankImg}/>
          <input className={styles.transferTypeModalInput}
          autoComplete="new-password"
          style={{border: lenghtOfAccountNumber === "" ? "" : "1px solid red"}}
          maxlength="18"
          type="password"
          disabled={savedContact}
          value={savedContact && contactData  && contactData.payeeAccountNum !== undefined ? contactData.payeeAccountNum :  bankAccountNumber}
          // onChange={ (e) => setBankAccountNumber(e.target.value.trim())}
          onChange={ (e) => handleChange(e)}
          onPaste={(e)=>{e.preventDefault()}}
          onBlur={(e) => checkAccountNumberMatch(e,'account')} />
        </Col>
        <Col className={styles.transferTypeModalInputFieldsErrors}>
          {lenghtOfAccountNumber}
        </Col>
      </Row>
      <Row className={styles.transferTypeRow}>
        <Col lg={12}>
          <Row>
              <Col className={styles.transferModalTakeInputHeading}>Confirm Bank Account Number</Col>
          </Row>
          <img className={styles.transferTypeModalImage} src={bankImg}/>
          <input className={styles.transferTypeModalInput}
           style={{border: accountNumberErrorMessage === "" ? "" : "1px solid red"}}
           type="text"
           disabled={savedContact}
           value={savedContact && contactData  && contactData.payeeAccountNum !== undefined ? contactData.payeeAccountNum :  confirmAccountNumber}
           onChange={(e) => setConfirmAccountNumber(e.target.value.replace(/[^0-9]/gi,'').trim().slice(0,18))}
           onPaste={(e)=>{e.preventDefault()}}
           onBlur={(e) => checkAccountNumberMatch(e,'check')}/>
           {isAccountNumberMatch ?
          <img className={styles.transferTypeModalRightTick} src={RightTick}/> : isAccountNumberMatch === undefined ? "" : <img className={styles.transferTypeModalRightTick} src={errorImg}/>}
        </Col>
        <Col className={styles.transferTypeModalInputFieldsErrors}>
          {accountNumberErrorMessage}
        </Col>
      </Row>
      <Row className={styles.transferTypeRow}>
        <Col lg={12}>
          <Row>
              <Col className={styles.transferModalTakeInputHeading}>Bank SWIFT Code</Col>
          </Row>
          <img className={styles.transferTypeModalImage} src={bankImg}/>
          <input className={styles.transferTypeModalInput}
          value={savedContact && contactData ? contactData.payeeBankIdCode !== null ?  contactData.payeeBankIdCode  : contactData.payeeRoutingNum : swiftOrRoutingNumber}
          disabled={savedContact}
          style={{border: isSwiftNoRight ? "" : isSwiftNoRight === undefined ? "" : "1px solid red"}}
          type="text" onBlur={(e) => checkSwiftNumber(e.target.value.trim(),'swift')}
          onChange={(e) => setSwiftOrRoutingNumber(e.target.value.trim())}/>
          {isSwiftNoRight ?
          <img className={styles.transferTypeModalRightTick} src={RightTick}/> : isSwiftNoRight === undefined ?  "" : <img className={styles.transferTypeModalRightTick} src={errorImg}/>}
        </Col>
        {isSwiftNoRight ?
        <Col className={styles.AccountHolderAddress}>
          {bankAddressFromSwiftNo ? `${bankAddressFromSwiftNo.bankName}, ${bankAddressFromSwiftNo.address.line1},${bankAddressFromSwiftNo.address.line2},${bankAddressFromSwiftNo.address.city},${bankAddressFromSwiftNo.address.state}` : ""}
        </Col> :
        isSwiftNoRight === undefined ? ""
        : <Col className={styles.transferTypeModalInputFieldsErrors}>
          Invalid SWIFT Code
        </Col>}
      </Row>
      <Row className={styles.transferTypeRow}>
        <Col md={12} lg={12}>
          <Row>
            <Col className={styles.transferModalTakeInputHeading}>Bank Account Type</Col>
          </Row>
          {/* <img className={styles.transferTypeModalImage} src={bankImg}/>
          <input className={styles.transferTypeModalInput} type="text" /> */}
           <img className={styles.transferTypeModalImage} src={bankImg}/>
          <span className={styles.transferTypeModalInputCountry}
          style={{display:"block"}}
          onClick={savedContact && contactData ? "" : () => setShowAccountType(!showAccountType)}
          >
          <span className={styles.accountTypeDropDownSelectedValue}>
          {savedContact && contactData && contactData.payeeAccountType && contactData.payeeAccountType !== "" ? BankAccountValue : selectAccountType.type}
          </span>
          <img className={styles.transferTypeModaldownIcon} src={DownIcon}/></span>
          {showAccountType ?
          <BankAccountType dropDown={otherCountrybankAccountTypeDropDown}
          dropDownshow={setShowAccountType}
          accountSelect={setAccountType}
          /> : ""}
        </Col>
      </Row>
      </>
      :
      <UsaInputs
      handleChange={handleChange}
      bankAccountNumber={bankAccountNumber}
      confirmAccountNumber={confirmAccountNumber}
      swiftOrRoutingNumber={swiftOrRoutingNumber}
      accountNumberErrorMessage={accountNumberErrorMessage}
      setSwiftOrRoutingNumber={setSwiftOrRoutingNumber}
      setTypeTransfer={setUsTypeTransfer}
      typeTransfer={usTypeTransfer}
      setShowAccountType={setShowAccountType}
      showAccountType={showAccountType}
      setAccountType={setAccountType}
      selectAccountType={selectAccountType}
      setConfirmAccountNumber={setConfirmAccountNumber}
      checkAccountNumberMatch={checkAccountNumberMatch}
      setBankAccountNumber={setBankAccountNumber}
      isAccountNumberMatch={isAccountNumberMatch}
      bankAddressFromSwiftNo={bankAddressFromSwiftNo}
      isSwiftNoRight={isSwiftNoRight}
      selectCountry={selectCountry}
      selectedCountryImg={selectedCountryImg}
      checkSwiftNumber={checkSwiftNumber}
      savedContact={savedContact}
      contactData={contactData}
      BankAccountValue={BankAccountValue}
      lenghtOfAccountNumber={lenghtOfAccountNumber}
      />
      }
      <Row className={styles.transferTypeModalBottomButton}>
        {/* <Col lg={4} sm={4} xs={4} className="pr-0">
        <input type="checkbox" name="html" value="html" checked style={{marginRight:"8px", position:"absolute",top:"7px"}}/>
        <span className={styles.transferModalSaveDetails}>Save Details</span>
        </Col> */}
        <Col lg={8} sm={8} xs={8}>
          <Row>
            <Col lg={6} sm={6} xs={6} md={6}>
              <SubmitButton/>
            </Col>
            <Col lg={6} sm={6} xs={6} md={6}>
              <span className={styles.transferModalCancelButton} onClick={props.handleClose}>Cancel</span>
            </Col>
          </Row>
        </Col>
      </Row>
      </> :
      // step 3 otp confirmation
      <>
      <ConfirmTransfer createANewContact={createANewContact}
      balanceAmount={props.amount}
      email={userInfo.emailAddress1}
      thirdStep={setThirdStep}
      hashID={hashID}
      sendOtpApi={sendOtpApi}
      initiateTheTransferProtocol={initiateTheTransferProtocol}
      otpError={otpError}
      amount={fundsValue}
      creditedTo={savedContact ? contactData.payeeName : contactName}
      accountNumber={props.accountNumber}
      transferSuccessTime={transferSuccessTime}
      isTransferSuccess={isTransferSuccess}
      transferCharges={transferCharges}
      transferType={transferType}
      setAddRemark={setAddRemark}
      finalTransferButtonClicked={finalTransferButtonClicked}
      setStepsCount={setStepsCount}
      selectCountry={selectCountry}
      handleClose={props.handleClose}
      />
      </>
    }
    </div>
  )
}

const BankCountryType = (props) => {
  return(
    <div className={styles.transactionModalTypeContainer}>
      {props.dropDown.map((e,index) => {
          return( <span className={styles.transactionTypeValue}
            style={{color:e.color}}
            onClick={() => props.ifbeneficiary=== true ? props.setBeneficiaryCountry(e.country, props.countryImg(e.img), props.dropDownshow(false)) : props.countrySelect(e.country, props.countryImg(e.img), props.dropDownshow(false))}><img src={e.img} style={{marginRight:"10px"}}></img>{e.country}</span>)
        })
      }
    </div>
  )
}

const BankAccountType = (props) => {
  return(
    <div className={styles.transactionModalTypeContainerBankType}>
      {props.dropDown.map((e,index) => {
          return( <span className={styles.transactionTypeValue} onClick={() => props.dropDownshow(false, props.accountSelect(e))}>{e.type}</span>)
        })
      }
    </div>
  )
}

const UsaInputs = (props) => {
  return(
    <>
      <Row style={{marginTop:"20px",marginBottom:"20px"}}>
        <Col className={styles.selectTransferType}>
         <Row className='justify-content-around'>
          <Col lg={5} md={5} xs={5} sm={5} className={props.typeTransfer === "ACH" ? styles.selectedTransferType : styles.notSelectedTransferType} onClick={props.savedContact ? "" : () => props.setTypeTransfer('ACH')}>ACH</Col>
          <Col lg={5} md={5} xs={5} sm={5} className={props.typeTransfer === "WIRE" ? styles.selectedTransferType : styles.notSelectedTransferType} onClick={props.savedContact ? "" : () => props.setTypeTransfer('WIRE')}>WIRE</Col>
         </Row>
        </Col>
      </Row>
      <Row className={styles.transferTypeRow}>
        <Col lg={12}>
          <Row>
              <Col className={styles.transferModalTakeInputHeading}>Bank Account Number</Col>
          </Row>
          <img className={styles.transferTypeModalImage} src={bankImg}/>
          <input className={styles.transferTypeModalInput}
          autoComplete="new-password"
          type="password"
          style={{border: props.lenghtOfAccountNumber === "" ? "" : "1px solid red"}}
          maxlength="18"
          disabled={props.savedContact}
          value={props.savedContact && props.contactData && props.contactData.payeeAccountNum !== undefined ? props.contactData.payeeAccountNum : props.bankAccountNumber}
          // onChange={(e) => props.setBankAccountNumber(e.target.value.trim())}
          onPaste={(e)=>{e.preventDefault()}}
          onChange={ (e) => props.handleChange(e)}
          onBlur={(e) => props.checkAccountNumberMatch(e,'account')} />
        </Col>
        <Col className={styles.transferTypeModalInputFieldsErrors}>
          {props.lenghtOfAccountNumber}
        </Col>
      </Row>
      <Row className={styles.transferTypeRow}>
        <Col lg={12}>
          <Row>
              <Col className={styles.transferModalTakeInputHeading}>Confirm Bank Account Number</Col>
          </Row>
          <img className={styles.transferTypeModalImage} src={bankImg}/>
          <input className={styles.transferTypeModalInput}
          style={{border: props.accountNumberErrorMessage === "" ? "" : "1px solid red"}}
          type="text"
          disabled={props.savedContact}
          value={props.savedContact && props.contactData && props.contactData.payeeAccountNum !== undefined ? props.contactData.payeeAccountNum : props.confirmAccountNumber }
          onChange={(e) => props.setConfirmAccountNumber(e.target.value.replace(/[^0-9]/gi,'').trim().slice(0,18))}
          onPaste={(e)=>{e.preventDefault()}}
          onBlur={(e) => props.checkAccountNumberMatch(e,'check')}
          />
          {props.isAccountNumberMatch ?
          <img className={styles.transferTypeModalRightTick} src={RightTick}/> : props.isAccountNumberMatch === undefined ? "" : <img className={styles.transferTypeModalRightTick} src={errorImg}/>}
        </Col>
        <Col className={styles.transferTypeModalInputFieldsErrors}>
          {props.accountNumberErrorMessage}
        </Col>
      </Row>
      <Row className={styles.transferTypeRow}>
        <Col lg={12}>
          <Row>
              <Col className={styles.transferModalTakeInputHeading}>Bank Routing Number</Col>
          </Row>
          <img className={styles.transferTypeModalImage} src={bankImg}/>
          <input className={styles.transferTypeModalInput}
          style={{border: props.isSwiftNoRight ? "" : props.isSwiftNoRight === undefined ? "" : "1px solid red"}}
          value={props.savedContact && props.contactData ? props.contactData.payeeBankSwiftCode !== null ?  props.contactData.payeeBankSwiftCode  : props.contactData.payeeRoutingNum : props.swiftOrRoutingNumber}
          disabled={props.savedContact}
          type="text"  onBlur={(e) => props.checkSwiftNumber(e.target.value.trim(),'aba')}
          onChange={(e) => props.setSwiftOrRoutingNumber(e.target.value.trim())}
          />
          {props.isSwiftNoRight ?
          <img className={styles.transferTypeModalRightTick} src={RightTick}/> : props.isSwiftNoRight === undefined ?  "" : <img className={styles.transferTypeModalRightTick} src={errorImg}/>}
        </Col>
        {props.isSwiftNoRight ?
        <Col className={styles.AccountHolderAddress}>
          {props.savedContact && props.contactData ? props.contactData.payeeBankName ?  props.contactData.payeeBankName : props.bankAddressFromSwiftNo ? `${props.bankAddressFromSwiftNo.bankName}` : "" : ""}
        </Col> :
        props.isSwiftNoRight === undefined ? ""
        : <Col className={styles.transferTypeModalInputFieldsErrors}>
          Invalid Routing Number
        </Col>}
      </Row>
      <Row className={styles.transferTypeRow} style={{marginBottom:"20px"}}>
        <Col md={12} lg={12}>
          <Row>
            <Col className={styles.transferModalTakeInputHeading}>Bank Account Type</Col>
          </Row>
          <img className={styles.transferTypeModalImage} src={bankImg}/>
          <span className={styles.transferTypeModalInputCountry}
          style={{display:"block"}}
          onClick={props.savedContact && props.contactData ? "" : () => props.setShowAccountType(!props.showAccountType)}
          >
          <span className={styles.accountTypeDropDownSelectedValue}>
            {props.savedContact && props.contactData && props.contactData.payeeAccountType && props.contactData.payeeAccountType !== "" ? props.BankAccountValue : props.selectAccountType.type}
            {/* {props.selectAccountType.type} */}
          </span>
          <img className={styles.transferTypeModaldownIcon} src={DownIcon}/></span>
          {props.showAccountType ?
          <BankAccountType dropDown={bankAccountTypeDropDown}
          dropDownshow={props.setShowAccountType}
          accountSelect={props.setAccountType}
          /> : ""}
        </Col>
      </Row>
    </>
  )
}

export default TransferOfFunds
