import React from 'react'
import { Row, Col } from 'react-bootstrap'
import researchImage from '../../public/images/research.png'
import blurrImage from '../../public/images/rating.png'
import styles from './StockSeeMore.module.css'
import AboutRating from '../AboutRating/AboutRating'
import { useHistory } from 'react-router-dom'
// import Rating from 'react-rating'
import { getCurrentAccountStatusLink } from '../../common'
import { ZACKS_RATING_FEATURE, TIP_RANK_FEATURE  } from '../../constants'

const InvestmentDescision = ({analystData,stockName,premiumFeatures,activePlan}) => {
  let history = useHistory()
  const redirectProfile = async() => {
    //logic to redirect user based on account status
    const link = await getCurrentAccountStatusLink();
    history.push(link)
  }
	return (
    <Row className={styles.exclusiveImage} style={(premiumFeatures.includes(TIP_RANK_FEATURE)||premiumFeatures.includes(ZACKS_RATING_FEATURE)) && activePlan === 'active' ? {} : {marginBottom:'45px',marginTop:'25px'}}>
      <Col>
			{
      		(premiumFeatures.includes(TIP_RANK_FEATURE)||premiumFeatures.includes(ZACKS_RATING_FEATURE)) && activePlan === 'active'
            ?
            <AboutRating analystData={analystData} stockName={stockName}/>
            : ''
          }
			</Col>

       {/* upgrade plan code */}

      <div style={{display: (premiumFeatures.includes(TIP_RANK_FEATURE)||premiumFeatures.includes(ZACKS_RATING_FEATURE)) && activePlan === 'active' ? 'none' : 'block'}} className={styles.absoluteCover}>
			<Col className={styles.cardBorder} style={{padding:'0px'}}>
				<Row style={{margin:'0px'}}>
					<Col xs={12} md='auto' style={{textAlign:"center"}}>
						<img src={researchImage} className={styles.imagePadding}/>
					</Col>
					<Col>
						<div className={styles.header}>
							Make better investment decisions
						</div>
						<div className={styles.description}>
							Unlock access to in-depth research by
							industry professionals and Insider transaction data.
						</div>
            <div className={styles.buttonDist}>
						<div className={`${styles.icon}`} onClick={redirectProfile} style={{cursor:'pointer'}}>
							<button
                className={`${styles.plan}`}
               >
								UPGRADE PLAN
							</button>
						</div>
            </div>
					</Col>
				</Row>
			</Col>
      </div>
		</Row>
	)
}
export default InvestmentDescision
