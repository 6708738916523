import React from 'react'
import { useState,useEffect } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import UserBasicDetail from './UserBasicDetail/UserBasicDetail'
import ResetPassword from './ResetPassword/ResetPassword'
import ChangePin from './ChangePin/ChangePin'
import Nominee from './Nominee/Nominee'
// import ChangePlan from '../ChangePlan/ChangePlan'
import './nav.css'
// import axios from 'axios'
import { customOTP } from '../../api'
import queryString from 'query-string'
import RedeemCoupon from './RedeemCoupon/RedeemCoupon'
import SecLendingOTP from './UserBasicDetail/SecLendingOTP'

const Profile= (props) => {
	const [current, setCurrent] =  useState('profile')
	const [hashId, sethashId] = useState('')
	const [pinModal, setPinModal] = useState(false)


  useEffect(() => {
    let queryUrl = queryString.parse(window.location.search, {
			parseNumbers: false
    })
		if (queryUrl.limitover) {
      setKey('change PIN')
		}
	}, [])

	// const [tabIndex, setTabIndex] = useState(0)
	// const [basicDetail, setBasicDetail] = useState(true);
	// const [changPassword, setChangPassword] = useState(false);
	// const [changePin, setChangePin] = useState(false);
	// const [showPinModal, setShowPinModal] = useState(false)
  const [key, setKey] = useState('basic detail');

	const onChangeCurrent = value => {
		setCurrent(value)
	}
  const ssoShown = sessionStorage.getItem('Sso_flag') ? sessionStorage.getItem('Sso_flag') : false
  const details = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : '';

  const webengageEvent = async(eventtitle) => {
    //webengage
    try{
      const publicIp = require('public-ip');
      const ipAddress = await publicIp.v4();
      // eslint-disable-next-line no-undef
      webengage.user.setAttribute('IP', ipAddress);
      // eslint-disable-next-line no-undef
      webengage.user.setAttribute('dwUsername', details.username);
      // eslint-disable-next-line no-undef
      webengage.user.setAttribute('platform', 'web');
      // eslint-disable-next-line no-undef
      webengage.track(eventtitle)
      }catch(error){
        console.log('error',error)
      }
  }


  const setTabs = (e) => {
    if(e === 'basic detail'){
    	setKey('basic detail')
    }else if(e ==='reset password'){
      webengageEvent('Reset Password')
			sendOtp("change_password");
      setKey('reset password')
		}else if(e === "change PIN"){
      webengageEvent('Pin setup or changed')
      if(ssoShown){
        sendOtp('change_pin')
      }
			setKey('change PIN')
		}else if(e === "nominee"){
			setKey('nominee')
		}
		else if(e === "redeem coupon"){
			setKey('redeem coupon')
		}
		else if(e === "Account Transfer"){
			setKey('Account Transfer')
		}
  }

	// const openPage = (e) => {
	// 	if(e === 'basic'){
	// 		setBasicDetail(true)
	// 		setChangPassword(false)
	// 		setChangePin(false)
	// 	}else if(e === 'pass'){
	// 		setChangPassword(true)
	// 		setBasicDetail(false)
	// 		setChangePin(false)
	// 	}else if(e === 'pin'){
	// 		setChangPassword(false)
	// 		setBasicDetail(false)
	// 		setChangePin(true)
	// 	}
	// }

	const sendOtp = (e) => {
    const check = e === "change_password" ? "change_password" : "update_user";
    const body = { reason: check }
		// const body = { reason: "change_password" }
    return customOTP(body).then(response=>{
        if(response.code === 200){
        	sethashId(response.data.hashID)
			return true
		}
		return false
      }).catch((err)=>{
        console.error(err)
		return false
      })
	}

	const callingSendOtp = (e) => {
		if(e ==='reset password'){
			sendOtp();
		} else if(e === "change PIN"){
			openPinModal();
		}
	}

	const openPinModal = () => {
		setPinModal(true);
	}

	// const tabClickRedirect = () =>{
	// 	setTabIndex(0)
	// }
	const isNomineeAllowed = ()=>{
		if(sessionStorage.getItem('userInfo')){
			const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
			if(userInfo.nextStep === 'COMPLETE' && userInfo.statusDetails && userInfo.statusDetails.status==='approved')
				return true;
		}
		return false;
	}
	return (
		<>
		{current === 'profile' && (
				<>
				<Tabs activeKey={key} id='uncontrolled-tab-example' className="mt-5"
				// onSelect={callingSendOtp}
		onSelect={(k) => setTabs(k)}
				>
					<Tab eventKey='basic detail' title='Basic Detail'>
						<UserBasicDetail
						className="basicDetail"
						onChangeCurrent={onChangeCurrent}
						sendOtpApi={sendOtp}
			hashId={hashId}
			isUserSet={()=>props.isUserSet()}
			current={current}
			/>
					</Tab>
			{ssoShown ? '' :
					<Tab eventKey='reset password' title='Reset Password' >
						<ResetPassword hashId={hashId}
						sendOtpApi={() => sendOtp('change_password')}
			resetTabs={setKey}
			myKey={key}
						// onChangeCurrent={onChangeCurrent}
						/>
					</Tab>}
					<Tab eventKey='change PIN' title='Set/Reset PIN'>
						<ChangePin
						setPinModal={setPinModal}
						openPinModal={openPinModal}
			resetTabs={setKey}
						pinModal={pinModal}
			hashId={hashId}/>
					</Tab>
					{isNomineeAllowed()?
					<Tab eventKey='nominee' title='Nominee'>
						<Nominee/>
					</Tab>:null}
					<Tab eventKey='redeem coupon' title='Redeem Coupon'>
						<RedeemCoupon resetTabs={setKey}/>
					</Tab>
				</Tabs>
				</>
			) }
		{current ==='secLendingOTP' && (
			<SecLendingOTP changeCurrent={onChangeCurrent} sendOtpApi={sendOtp} hashID={hashId}/>
			// <ChangePlan onChangeCurrent={onChangeCurrent} />
		)}

		</>	)
}

export default Profile
