import React, { useEffect, useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import styles from '../Profile.module.css'
import axios from 'axios'
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { first } from 'lodash'
import { setUserInfoSession } from '../../../common'
import Select from 'react-select';
import { map } from 'lodash'
import { updateProfile } from '../../../api';

const customStyle = () => (
  {
    container: provided => ({
      ...provided,
      backgroundColor:'#efeff1',
      color:'black',
      width: '100%',
      // top: '50%',
      borderRadius:'8px',
      zIndex:10
    }),
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        color: isSelected || isFocused ? 'black' : 'black', // setting
        backgroundColor: isSelected || isFocused ? '#EFEFF1' : 'white',

        ':active': {
          ...styles[':active'],
          backgroundColor: 'white'
        }
      }
    },
    singleValue: styles => ({
      ...styles,
      color: '#1e1f24',
    }),
    indicatorSeparator: provided => ({ ...provided, visibility: 'hidden' }),
    control: base => ({
      ...base,
      borderWidth: 0,
      borderRadius: 8,
      height: 48,
      fontSize: 14,
      color: '#9096a8',
      backgroundColor:'#EFEFF1',
      border: 0,
      boxShadow: 'none',
      '&:hover': {
        border: 0
      },
    }),
    menu: base => ({
      ...base,
      borderRadius: 8,
    }),
  })

  const errorCustomStyle = () => (
    {
      container: provided => ({
        ...provided,
        backgroundColor:'#efeff1',
        color:'black',
        width: '100%',
        // top: '50%',
        borderRadius:'8px',
        zIndex:10
      }),
      option: (styles, { isFocused, isSelected }) => {
        return {
          ...styles,
          color: isSelected || isFocused ? 'black' : 'black', // setting
          backgroundColor: isSelected || isFocused ? '#EFEFF1' : 'white',

          ':active': {
            ...styles[':active'],
            backgroundColor: 'white'
          }
        }
      },
      singleValue: styles => ({
        ...styles,
        color: '#1e1f24',
      }),
      indicatorSeparator: provided => ({ ...provided, visibility: 'hidden' }),
      control: base => ({
        ...base,
        borderWidth: 0,
        borderRadius: 8,
        height: 48,
        fontSize: 14,
        color: '#9096a8',
        backgroundColor:'#EFEFF1',
        border: '1px solid #e61446',
        boxShadow: 'none',
        '&:hover': {
          border: 0
        },
      }),
      menu: base => ({
        ...base,
        borderRadius: 8,
      }),
  })

const EditBasicDetail = props => {
	const [firstName, setFirstName] = useState(props.firstName)
	const [firstNameCheck, setFirstNameCheck] = useState(false)
	const [middleName, setMiddleName] = useState(props.middleName)
	const [lastName, setLastName] = useState(props.lastName)
	const [lastNameCheck, setLastNameCheck] = useState(false)
	const [emailAddress, setEmailAddress] = useState(props.emailAddress)
	const [emailAddressCheck, setEmailAddressCheck] = useState(false)
	const [emailError, setEmailError] = useState('')
	const [phone, setPhone] = useState(props.phone ? props.phone : '--')
	const [phoneCheck, setPhoneCheck] = useState(false)
	const [phoneError, setPhoneError] = useState('')
	// const [otpVerify, setOtpVerify] = useState(props.otpVerify)
	const [showModal, setShowModal] = useState(props.showModal)
	const [errorMessage, setErrorMessage] = useState('')
  const [countryOptions, setCountryOptions] = useState([]);
  const [country, setCountry] = useState(props.countryValue)
  const [CountryValue,setCountryValue] = useState(props.country);
  const [CountryError, setCountryError] = useState('')
  const [successOrErrorMessage, setSucessOrErrorMessage] = useState('')
  const[buttonDisabled, setButtonDisabled] = useState(false)

  useEffect(()=>{
    if(props.countryList && props.countryList.length > 0) {
    let opt = [...new Set(map(props.countryList, ({country, countryName}) => {
          return { label: countryName, value: country}
    }))]
    setCountryOptions(opt)
    }
  },[props.countryList])

	const handleInputBox = e => {
		if (e.target.name === 'firstName') {
			if (firstName.length < 1) {
				setErrorMessage('Field Cannot be Empty', setFirstNameCheck(true))
			} else {
				setErrorMessage('', setFirstNameCheck(false))
			}
		} else if (e.target.name === 'lastName') {
			if (lastName.length < 1) {
				setErrorMessage('Field Cannot be Empty', setLastNameCheck(true))
			} else {
				setErrorMessage('', setLastNameCheck(false))
			}
		}
    //  else if (e.target.name === 'emailAddress') {
		// 	if (emailAddress.length < 1) {
		// 		setEmailError('Email Cannot be Empty')
		// 		setEmailAddressCheck(true)
		// 	} else {
		// 		setEmailError('')
		// 		// setEmailAddressCheck(false)
		// 	}
		// }
    else if (e.target.name === 'phone') {
			if (phone === undefined || phone.length < 1) {
				setPhoneError('Phone Cannot be Empty')
				setPhoneCheck(true)
			} else if(e.target.value.length < 7){
        console.log('hey')
        setPhoneError('Enter Valid Phone Number')
				setPhoneCheck(true)
      }
      else {
				setPhoneError('')
				setPhoneCheck(false)
			}
		}
	}

	const emailVerification = e => {
		setEmailAddress(e.target.value)
		let regEmail =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if(e.target.value < 1){
      setEmailError('Email Cannot be Empty')
      setEmailAddressCheck(false)
    }
      else if (!regEmail.test(e.target.value)) {
			setEmailError('Invalid Email')
			setEmailAddressCheck(!regEmail.test(e.target.value))
		}
     else {
			setEmailError('')
			setEmailAddressCheck(false)
		}
	}

	// const phoneValidation = (e) => {
	//     // console.log('e',e.target.value)
	//     // setPhone()
	//     let isValid = phone ? (isValidPhoneNumber(phone) ? undefined : 'Invalid phone number') : 'Phone number required'
	//     console.log('phoneValid',isValidPhoneNumber(e.target.value))
	// }

	// const NameOnlyCharacter = e => {
	// 	let value = e.target.value
	// 	value = value.replace(/[^A-Za-z]/gi, '')
	// 	if (e.target.name === 'firstName') {
	// 		setFirstName(value)
	// 	} else if (e.target.name === 'lastName') {
	// 		setLastName(value)
	// 	}
  // }

  const onChangeHandler = e => {
    if (e.target.name === 'firstName')
      setFirstName((e.target.value).replace(/[^A-Za-z ]/ig, '').trim())
    else if (e.target.name === 'lastName'){
      const regex = new RegExp('^[a-zA-Z]*[.]{0,1}[a-zA-Z]*$');
      if(regex.test(e.target.value))
        setLastName(e.target.value)
    }
	}

	// button Api call
	const handleSubmit = e => {
    if(country === '' || country === undefined){
      setCountryError('Country cannot be empty')
    }
		else if (
			firstName !== '' &&
			lastName !== '' &&
			phone !== '' &&
			emailAddress !== '' &&
			emailAddressCheck === false &&
			!phoneCheck && country !== ''
		) {
			const update = {
				// type: 'BASIC_INFO',
				body: {
					firstName: firstName,
					lastName: lastName,
					phone: phone,
					email: emailAddress,
					country: country,
          otp:props.otp,
          hashId:props.hashId
				}
			}
      // new Api calling
      updateProfile(update).then(response=>{
        props.setIsVerifyOtp(false)
        props.setIsEdit(false)
        if(response.data.code === 200){
          const data = {
            firstName,
            lastName,
            emailAddress1:emailAddress,
            phoneHome:phone,
            countryID:props.country
          }
          setUserInfoSession(data)
          props.setBasicDetails(data)
        } else {
          setSucessOrErrorMessage('Something Went Wrong!')
        }
      }).catch(function (error) {
          if(error.response.data.code === 440){
            setButtonDisabled(true)
          }
          setSucessOrErrorMessage(error.response.data.message)
        console.log('basicError', error)
      })
		} else {
			console.log('something not filled')
		}
	}

  const goBack = () => {
    props.setIsVerifyOtp(false)
    props.sendOtpApi()
  }

	return (
		<>
			<div style={{ width: '400px' }} className={styles.profileModalBody}>
				<Row className={`p-0 mt-0 ${styles.basicDetail}`}>
					<Col className={`p-0 ${styles.modalHeadingText}`}>Basic Details</Col>
				</Row>
				<Row className={`${styles.inputRowsEdit}`}>
					<Col>
						<div className={`mb-1 ${styles.profileInputLabel}`}>First name</div>
						<input
							type='text'
							placeholder='First name'
							name='firstName'
							className={
								firstNameCheck
									? styles.profileErrorInputBox
									: styles.profileInputBox
							}
							value={firstName}
							onBlur={event => handleInputBox(event)}
							onChange={event => onChangeHandler(event)}
              disabled = {props.profileData['accountStatus'] == 2 ? true : false}
              style={props.profileData['accountStatus'] == 2 ?{background:"#D3D4DA" }: {background:""}}
						/>
						{firstNameCheck && (
							<p className={styles.profileErrorText}>Field Cannot Be Empty</p>
						)}
					</Col>
				</Row>
				{/* <Row className={`${styles.inputRowsEdit}`}>
                <Col>
                <div className={`mb-1 ${styles.profileInputLabel}`}>Middle name</div>
                <input
				type='text'
				placeholder='Middle name'
                name='middleName'
				className={styles.profileInputBox}
                value={middleName}
                onChange={event => setMiddleName(event.target.value)}
				/>
                </Col>
            </Row> */}
				<Row className={`${styles.inputRowsEdit}`}>
					<Col>
						<div className={`mb-1 ${styles.profileInputLabel}`}>Last name</div>
						<input
							type='text'
							placeholder='Last name'
							name='lastName'
							className={
								lastNameCheck
									? styles.profileErrorInputBox
									: styles.profileInputBox
							}
							value={lastName}
							onBlur={event => handleInputBox(event)}
							onChange={event => onChangeHandler(event)}
              disabled = {props.profileData['accountStatus'] == 2 ? true : false}
              style={props.profileData['accountStatus'] == 2 ?{background:"#D3D4DA" }: {background:""}}
						/>
						{lastNameCheck && (
							<p className={styles.profileErrorText}>Field Cannot Be Empty</p>
						)}
						{/* <InputLabel
                label='last name'
                placeholder='last name'
                name='lastName'
                value={lastName}
                onChange={event => setLastName(event.target.value)}
                ></InputLabel> */}
					</Col>
				</Row>
				<Row className={`${styles.inputRowsEdit}`}>
					<Col>
						<div className={`mb-1 ${styles.profileInputLabel}`}>
							Email address
						</div>
						<input
							type='text'
							placeholder='Email address'
							name='emailAddress'
							className={
								emailAddressCheck
									? styles.profileErrorInputBox
									: styles.profileInputBox
							}
							value={emailAddress}
							onBlur={event => handleInputBox(event)}
							// onChange={event => setEmailAddress(event.target.value)}
							onChange={event => emailVerification(event)}
						/>
						<p className={styles.profileErrorText}>{emailError}</p>
					</Col>
				</Row>
				<Row className={`${styles.inputRowsEdit}`}>
					<Col>
						<div className={`mb-1 ${styles.profileInputLabel}`}>Phone no.</div>
						{/* <input
				label='phone'
                placeholder='phone'
                name='phone'
				className={phoneCheck ? styles.profileErrorInputBox : styles.profileInputBox}
                value={phone}
                onBlur={(event) => handleInputBox(event)}
                onChange={event => setPhone(event.target.value)}
				/> */}
						<PhoneInput
							className={
								phoneCheck
									? styles.phoneErrorInputBox
									: styles.phoneInputBox
							}
							placeholder='Enter phone number'
							name='phone'
							value={phone}
              onBlur={event => handleInputBox(event)}
              onPaste={(e)=>{
                e.preventDefault()
                return false;
              }} onCopy={(e)=>{
                e.preventDefault()
                return false;}}
							onChange={e => setPhone(e)}></PhoneInput>
						{/* Is possible: {value && isPossiblePhoneNumber(value) ? 'true' : 'false'} */}
						<p className={styles.profileErrorText}>{phoneError}</p>
					</Col>
				</Row>
        {props.profileData['accountStatus'] === 2 ?
        <Row className={`${styles.inputRowsEdit}`}>
					<Col>
						<div className={`mb-1 ${styles.profileInputLabel}`}>
              Country
						</div>
						<input
							type='text'
							placeholder='Country'
							name='Country'
              style={{background:"#D3D4DA"}}
							className={CountryError ? styles.phoneErrorInputBox : styles.profileInputBox}
							value={CountryValue ? CountryValue.label : "--"}
              disabled
						/>
            <p className={styles.profileErrorText}>{CountryError}</p>
					</Col>
				</Row> :
        <Row className={`${styles.inputRowsEdit}`}>
          <Col className={`m-0 ${styles.dropdownInputFont}`}>
            <div className={`mb-1 ${styles.profileInputLabel}`}>
              Country
            </div>
            <Select
            placeholder='Country'
            value={CountryValue}
            onChange={(value) => {setCountry(value.value)
            setCountryValue(value)}}
            options={countryOptions ? countryOptions : "" }
            styles={CountryError ? errorCustomStyle() : customStyle()}
            isSearchable={true}
            />
            <p className={styles.profileErrorText}>{CountryError}</p>
          </Col>
        </Row> }
        <Row className={styles.SuccessOrErrorMessage}>{successOrErrorMessage && successOrErrorMessage.length > 0 ? successOrErrorMessage : "" }</Row>
        {buttonDisabled ?
        <Row>
          <Col className={`${styles.bottomButton}`}>
          <div className={styles.goBackButton} style={{marginLeft:"-50px"}} onClick={goBack}>
            Go Back
          </div>
          </Col>
        </Row> :
				<Row>
					<Col md={6} xs={6} className={`${styles.bottomButton}`}>
						<span onClick={props.handleClose}>Cancel</span>
					</Col>
					<Col md={6} xs={6} className={`${styles.saveBottomButton}`}>
						<Button
							className={`${styles.editSaveButton} themeButton`}
							onClick={handleSubmit}
							style={{ backgroundColor: props.buttonColor }}>
							Save Changes
						</Button>
					</Col>
				</Row>}
			</div>
		</>
	)
}

export default EditBasicDetail
