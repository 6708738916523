import React , { useState, useRef, useEffect } from 'react'
import styles from './VirtualCardStates.module.css'
import cardstateactivate from '../../public/images/cardstateactivate.png'
import pauseIcon from '../../public/images/pauseIcon.svg';
import stockalLogoWhite from '../../public/images/stockalLogoWhite.svg';
import rightArrow from '../../public/images/right-arrow.svg';
import stockalLogoBlue from '../../public/images/stockalShapeLogo.svg';
import visa from '../../public/images/visaCashManagement.svg';
import retryWhite from '../../public/images/retryWhite.svg'
import cardPoster from '../../public/images/cardPoster.png'
import UnfreezeVirtualCardModal from './UnfreezeVirtualCardModal';
import VirtualCardPinModal from './VirtualCardPinModal'
import CashMgmtModal from './CashMgmtModal';
import VirtualCardVideo from '../../public/videos/VirtualCardVideo.mp4'

const FrozenVirtualCard = (props) => {

    const { setCardStatus, virtualCard } = props

    const [ showModal, setShowModal ] = useState(false)

    const openModal = () => {
        setShowModal(!showModal)
    }

    const vidRef=useRef();

    useEffect(() => { vidRef.current.play(); },[]);


    return (
        <>
        <div className={`${styles.virtualCardContainer} ${styles.shadowFrozen}`} onClick={openModal}>
            <video loop muted poster={cardPoster} playsinline autoPlay={false} controls={false} ref={ vidRef }className={`${styles.cardVideo} ${styles.filterFrozen}`}>
                <source src={VirtualCardVideo} type="video/mp4"/>
            </video>
            <div className={styles.cardContent}>
                <div className={styles.cardInfo} style={{height:"142px",background:"#F7F7F8"}}>
                    <div className={styles.cardRow} style={{marginBottom:"28px"}}>
                        <img src={stockalLogoBlue} alt="stockal-logo"></img>
                        <div className={`${styles.cardRow} ${styles.cardStatus}`}>
                            <img src={pauseIcon} alt="pause-icon"></img>
                            <p className={`${styles.cardName} ${styles.textGrey}`}>Virtual Card</p>
                        </div>
                    </div>
                    <p className={`${styles.cardHeader} ${styles.textBlack}`} style={{marginBottom:"6.5px"}}>Card Temporarily Frozen</p>
                    <p className={`${styles.cardText} ${styles.textBlue}`} onClick={openModal} style={{cursor:"pointer"}}>Unfreeze Card</p>
                </div>
                <div className={`${styles.cardHolder} ${styles.cardRow}`}>
                    <p className={`${styles.cardName} ${styles.textWhite} ${styles.fontWeight}`}>{virtualCard ? virtualCard.displayName : '--'}</p>
                    <img src={visa} alt="visa-card"></img>
                </div>
            </div>
        </div>
        {showModal ?
        <CashMgmtModal showModal={showModal} openModal={openModal}>
            <UnfreezeVirtualCardModal onHide={openModal} cardId={virtualCard.brokerCardId} setCardStatus={setCardStatus}/>
        </CashMgmtModal> : ''}
        </>
    )
}

const ActivateVirtualCard = (props) => {

    const { virtualCard, setCardStatus, setIsCardActivated } = props

    const [ showModal, setShowModal ] = useState(false)

    const openModal = () => {
        setShowModal(!showModal)
    }
    return (
        <>
        <div className={`${styles.virtualCardContainer}`} onClick={openModal}>
            <video autoPlay loop muted poster={cardPoster} className={styles.cardVideo}>
                <source src={VirtualCardVideo} type="video/mp4"/>
            </video>
            <div className={`${styles.cardInfo} ${styles.cardContent}`}>
                <img src={stockalLogoWhite} alt="stockal-logo" style={{marginBottom:"55px"}}></img>
                <p className={`${styles.cardHeader} ${styles.textWhite}`} style={{marginBottom:"12px"}}>Activate your <br/> Stockal Virtual Card</p>
                <div className={styles.cardStatus} style={{cursor:"pointer"}} onClick={openModal}>
                    <p className={`${styles.cardText} ${styles.textWhite}`} style={{marginRight:"4px"}}>Get Started</p>
                    <img src={rightArrow} alt="pause-icon"></img>
                </div>
            </div>
        </div>
        {showModal ?
        <CashMgmtModal showModal={showModal} openModal={openModal}>
            <VirtualCardPinModal resetPin={false} title={'Activate Virtual Card'} buttonText={'Activate Card'} onHide={openModal} cardId={virtualCard.brokerCardId} virtualCard={virtualCard} setCardStatus={setCardStatus} setIsCardActivated={setIsCardActivated}/>
        </CashMgmtModal> : ''}
        </>
    )
}

const LoadingVirtualCard = (props) => {
    return (
        <div className={`${styles.fetchData}`}>
            <figure style={{background: "linear-gradient(0deg, #FA274A, #FA274A)",borderRadius:"16px",height:"198px"}}>
                <video autoPlay loop muted poster={cardPoster} className={`${styles.cardVideo} ${styles.loadingVideo}`}>
                  {/* {props.width > 992  ? */}
                    <source src={VirtualCardVideo} type="video/mp4"/>
                  {/* :""} */}
                </video>
            </figure>
            <div className={`${styles.cardInfo} ${styles.cardContent}`}>
                <img src={stockalLogoWhite} alt="stockal-logo" style={{marginBottom:"80.5px"}}></img>
                <p className={`${styles.cardHeader} ${styles.textWhite}`} style={{marginBottom:"12px"}}>Loading Data...</p>
            </div>
        </div>
    )
}

const UnableToFetchDataVirtualCard = (props) => {

    const { fetchData } = props

    return (
        <div className={`${styles.fetchData}`} onClick={fetchData}>
            <figure style={{background: "linear-gradient(0deg, #FA274A, #FA274A)",borderRadius:"16px",height:"198px"}}>
                <video autoPlay loop muted poster={cardPoster} className={`${styles.cardVideo} ${styles.loadingVideo}`}>
                    <source src={VirtualCardVideo} type="video/mp4"/>
                </video>
            </figure>
            <div className={`${styles.cardInfo} ${styles.cardContent}`}>
                <img src={stockalLogoWhite} alt="stockal-logo" style={{marginBottom:"80.5px"}}></img>
                <p className={`${styles.cardHeader} ${styles.textWhite}`} style={{marginBottom:"8.75px"}}>Unable to fetch data</p>
                <div className={styles.cardStatus}>
                    <img src={retryWhite} alt="retry-icon"></img>
                    <p className={`${styles.cardText} ${styles.textWhite}`}>Try Again</p>
                </div>
            </div>
        </div>
    )
}

const UpComingVirtualCard = (props) => {

    const { isCardActivated } = props

    return (
        <>
            <div className={`${styles.virtualCardContainer}`} style={{cursor:"default"}}>
                <video autoPlay loop muted poster={cardPoster} className={styles.cardVideo}>
                    <source src={VirtualCardVideo} type="video/mp4"/>
                </video>
                <div className={`${styles.cardInfo} ${styles.cardContent}`}>
                    <img src={stockalLogoWhite} alt="stockal-logo" style={{marginBottom:"55px"}}></img>
                    <>
                        {
                            isCardActivated ? (
                                <p className={`${styles.cardHeader} ${styles.textWhite}`} style={{marginBottom:"12px"}}>We are reconfiguring <br/> your Stockal Virtual Card</p>
                            ) : (
                                <p className={`${styles.cardHeader} ${styles.textWhite}`} style={{marginBottom:"12px"}}>Your Stockal Virtual Card</p>
                            )
                        }
                    </>
                    {
                        isCardActivated ? (
                            ''
                        ) : (
                            <div className={styles.cardStatus}>
                                <p className={`${styles.cardText} ${styles.textWhite}`} style={{marginRight:"4px"}}>Coming Soon...</p>
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    )
}


export { FrozenVirtualCard, ActivateVirtualCard, LoadingVirtualCard, UnableToFetchDataVirtualCard, UpComingVirtualCard }
