import React, { useState, useEffect, useRef, useContext } from 'react'
import { Container, Row, Col, Modal, Spinner } from 'react-bootstrap'
import $ from 'jquery'
import Slider from 'react-slick'
import { getFilterStocks, getTopPerformingStacks, getPriceRangeFilter, getPartnerData } from '../../api'
import Open from '../../public/images/seemore.svg'
import Close from '../../public/images/hideIcon.svg'
import Filter from '../../public/images/filterExplore.png'
import Sort from '../../public/images/sortExplore.png'
import BackArrow from '../../public/images/BackArrow.svg'
import { Header, Footer, ExploreSearch, ExploreStocksEtf, StackCard } from '../../components'
import ExploreStocksMobile from '../../components/ExploreStocksEtf/ExploreStocksMobile'
import styles from './Explore.module.css'
import { ThemeContext } from '../../Context/ThemeContext'
import { SideBar } from '../../components'

const priceChangeValueOptions = [{ value: '1', label: 'Rising' }, { value: '-1', label: 'Falling' }]
const priceChangeDayOptions = [{ value: '1', label: '1 Day' },
{ value: '7', label: '7 Day' },
{ value: '30', label: '30 Day' },
{ value: '90', label: '90 Day' }]
const sortByOptions = [{ value: 'ranking_monthly', label: 'Popularity' },
{ value: 'topGainers', label: 'Top performing' },
{ value: 'topLosers', label: 'Least Performing' },
{ value: 'yearHigh', label: '52w High' },
{ value: 'yearLow', label: '52w Low' }]

const Explore = (props) => {
  const [url, setUrl] = useState('instrument/stocks')
  const [data, setData] = useState([])
  const [topPerformingStack, setTopPerformingStack] = useState([])
  const [stackList, setStackList] = useState([])
  const [stackListMediumScreen, setStackListMediumScreen] = useState([])
  const [stackSeeMore, setStackSeeMore] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [showSort, setShowSort] = useState(false)
  const [showEtf, setShowEtf] = useState(false)
  const [sortBy, setSortBy] = useState(sortByOptions[0])
  const [rangeValue, setRangeValue] = useState({ max: 0, min: 0 })
  const [defaultValue, setDefaultValue] = useState({ max: 0, min: 0 })
  const [priceChangeDay, setPriceChangeDay] = useState()
  const [priceChangeValue, setPriceChangeValue] = useState()
  const [marketCap, setMarketCap] = useState('')
  const [rating, setRating] = useState('')
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const refScroll = useRef()
  const { buttonColor } = useContext(ThemeContext)
  const [slideeffect, setslideeffect] = useState(false)
  const [prevScroll, setprevScroll] = useState()
  const [pageLoading, setPageLoading] = useState(false)
  const [ isResetHalalFilter, setIsResetHalalFilter] = useState(false)
  const [ showHalalStocks, setShowHalalStocks] = useState(false)
  const [ halalRanking, setHalalRanking] = useState(0)
  const [ halalText, setHalalText ] = useState('Show Only Halal')
  const [userLocation, setUserLocation] = useState('')

  const resetHalalFilter = () => {
    setIsResetHalalFilter(true)
  }

  const userInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : ''
  const partnerCode = userInfo && userInfo.partner && userInfo.partner.code ? userInfo.partner.code :''

  const getUserLocation = async() => {
      const response = await getPartnerData(partnerCode)
      if(response && response.code === 200 && response.data) {
        setUserLocation(response.data.location ? response.data.location : '')
      } else {
        setUserLocation('')
      }
  }

  useEffect(()=>{
      if(partnerCode) {
          getUserLocation()
      }
  },[])


  useEffect(() => {
    const asyncFetch = async () => {
      try {
        const resp = await getTopPerformingStacks('cagr')
        setTopPerformingStack(
          resp && resp.length > 0
            ? [...resp]
            : []
        )
        setStackList(resp && resp.length > 0
          ? resp.slice(0, 4)
          : [])

        setStackListMediumScreen(resp && resp.length > 0
          ? resp.slice(0, 3)
          : [])
        getRangePrice()
        // const response = await getPriceRangeFilter()
        //  if(response.code ===200 && response.data){
        //    const obj ={
        //         min: response.data.minPrice,
        //         max: response.data.maxPrice
        //          }
        //          setRangeValue(obj)
        //          setDefaultValue(obj)
        // }
      } catch (error) {
        console.log(error)
      }
    }
    asyncFetch()
  }, [])


  const getRangePrice = async () => {
    const response = await getPriceRangeFilter()
    if (response.code === 200 && response.data) {
      const obj = {
        min: response.data.minPrice,
        max: response.data.maxPrice
      }
      setRangeValue(obj)
      setDefaultValue(obj)
    }
  }

  const resetFilter = () => {
    setPriceChangeDay('')
    setPriceChangeValue('')
    setMarketCap('')
    setRating('')
    setSortBy(sortByOptions[0])
    getRangePrice()
  }

  const asyncFetch = async (url) => {
    try {
      //  const resp = await getTopPerformingStacks('cagr')
      //  setTopPerformingStack(
      //   resp && resp.length > 0
      //     ? [...resp]
      //     : []
      // )
      // setStackList(resp && resp.length > 0
      //   ? resp.slice(0,4)
      //   : [])
      setLoading(true)
      const response = await getFilterStocks(`${url}&page=1&pageSize=10`)
      if (response.code === 200 && response.data) {
        setData(response.data)
      }
      else {
        setData([])
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    asyncFetch(url)
  }, [url])

  useEffect(() => {
    const fetch = async () => {
      try {
        setPageLoading(true)
        const response = await getFilterStocks(`${url}&page=${page}&pageSize=10`)
        if (response.code === 200 && response.data) {
          setData([...data, ...response.data])
        }
      } catch (error) {
        console.log(error)
      }
      setPageLoading(false)
    }
    if (page !== 1) {
      fetch()
    }
  }, [page])

  useEffect(() => {
    if (stackSeeMore) {
      setStackList(topPerformingStack)
      setStackListMediumScreen(topPerformingStack)
    }
    else {
      setStackList(topPerformingStack.slice(0, 4))
      setStackListMediumScreen(topPerformingStack.slice(0,3))
    }
  }, [stackSeeMore])

  var settings = {
    dots: false,
    className: 'center',
    // autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    centerMode: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    rows:1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding:'10px'
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding:'20%'
        }
      },
      {
        breakpoint:480,
        settings: {
          slidesToShow:1,
          slidesToScroll:1,
          centerMode:true,
          centerPadding:'20%'
        }
      },
      {
        breakpoint:425,
        settings: {
          slidesToShow:1,
          slidesToScroll:1,
          centerMode:true,
          centerPadding:'10%'
        }
      },
      {
        breakpoint:375,
        settings: {
          slidesToShow:1,
          slidesToScroll:1,
          centerMode:true,
          centerPadding:'12px'
        }
      },
    ]
  }

  // useEffect(() => () => $(window).unbind('scroll'), [])

  // useEffect(() => {
  //   if(window.screen.width < 500){
  //   window.addEventListener('scroll',function () {
  // 		let scrollPos = parseInt(
  // 			window.pageYOffset || document.documentElement.scrollTop
  // 		)
  // 		if (refScroll && refScroll.current && scrollPos >= refScroll.current.offsetHeight) {
  //       console.log('in explore',page)
  //       const updatedPage = page
  //       setPage(updatedPage + 1)
  //     }
  // 	})
  //   }
  // },[])


  const scrollDiv = () => {
    if (refScroll.current.scrollTop + refScroll.current.offsetHeight >= refScroll.current.scrollHeight) {
      setPage(page + 1)
    }
  }


  const handleEffect = () => {
    setslideeffect(prevState => setslideeffect(!prevState))
  }

  return (
    <>
      <Header />
      <Container className={slideeffect ? styles.shiftPos : styles.originalPos}>
        <div className={`${styles.marginBottom} ${styles.marginConatiner}`}>
          <Row className={styles.title}>
            <Col> Stacks</Col>
          </Row>
          <Row className={styles.subTitle}>
            <Col> Expert made model portfolios</Col>
          </Row>
          <Row className='d-none d-md-none d-lg-none d-xl-flex'>
            {stackList.length > 0 ? (
              stackList.map((item, index) => {
                return (
                  <Col key={index} md={3} xs={9} className={styles.marginConatiner}>
                    <StackCard
                      image={item.logo}
                      ticker={item.ticker}
                      createdBy={item.createdByTitle}
                      desc={item.description}
                      cagr={item.cagr}
                      minInvest={item.minimumInvestment}
                    />
                  </Col>
                )
              })) : ''}
          </Row>
          <Row className='d-none d-md-none d-lg-flex d-xl-none'>
            {stackListMediumScreen.length > 0 ? (
              stackListMediumScreen.map((item, index) => {
                return (
                  <Col key={index} md={4} xs={9} className={styles.marginConatiner}>
                    <StackCard
                      image={item.logo}
                      ticker={item.ticker}
                      createdBy={item.createdByTitle}
                      desc={item.description}
                      cagr={item.cagr}
                      minInvest={item.minimumInvestment}
                    />
                  </Col>
                )
              })) : ''}
          </Row>
          {stackSeeMore ?
            <Row className={`${styles.rowSeeMore} d-none d-lg-flex`} onClick={() => setStackSeeMore(false)}>
              <span className={styles.seeMore}>See Less</span>
              <span className='ml-2' style={{ cursor: 'pointer' }}><img src={Close}></img></span>
            </Row> :
            <Row className={`${styles.rowSeeMore} d-none d-lg-flex`} onClick={() => setStackSeeMore(true)}>
              <span className={styles.seeMore}>See more</span>
              <span className='ml-2' style={{ cursor: 'pointer' }}><img src={Open}></img></span>
            </Row>}
          <Row
            className={`${styles.cardtopMargin} d-block d-lg-none`}>
            <Slider {...settings} className={styles.trendingSlider}>
              {topPerformingStack.map((item, index) => {
                return (
                  <StackCard
                    image={item.logo}
                    ticker={item.ticker}
                    createdBy={item.createdByTitle}
                    desc={item.description}
                    cagr={item.cagr}
                    minInvest={item.minimumInvestment}
                  />
                )
              })}
            </Slider>
          </Row>
        </div>
        <Row className={`${styles.marginBottom} d-none d-md-flex`}>
          <Col md={12} lg={12} xl={9}>
            {loading ? <div className={styles.spinner}><Spinner animation="border" className={styles.spinnerColor} /></div> :
              <ExploreStocksEtf
                data={data}
                setData={setData}
                setPage={setPage}
                page={page}
                prevScroll={prevScroll}
                setprevScroll={setprevScroll}
                pageLoading={pageLoading}
                userLocation={userLocation}
                />
                }
          </Col>
          <Col xl={3} className={styles.showExplore}>
            <ExploreSearch setUrl={setUrl} showEtf={showEtf}
              setShowEtf={setShowEtf}
              sortBy={sortBy}
              setSortBy={setSortBy}
              rangeValue={rangeValue}
              setRangeValue={setRangeValue}
              priceChangeDay={priceChangeDay}
              setPriceChangeDay={setPriceChangeDay}
              priceChangeValue={priceChangeValue}
              setPriceChangeValue={setPriceChangeValue}
              marketCap={marketCap}
              setMarketCap={setMarketCap}
              rating={rating}
              setRating={setRating}
              defaultValue={defaultValue}
              setPage={setPage}
              resetFilter={resetFilter}
              color={buttonColor} 
              userLocation={userLocation}
              isResetHalalFilter={isResetHalalFilter}
              setIsResetHalalFilter={setIsResetHalalFilter}
              showHalalStocks={showHalalStocks}
              setShowHalalStocks={setShowHalalStocks}
              halalRanking={halalRanking}
              setHalalRanking={setHalalRanking}
              resetHalalFilter={resetHalalFilter}
              halalText={halalText}
              setHalalText={setHalalText}
              />
          </Col>
        </Row>
        <div className={`${styles.mobileMargin} d-block d-md-none`}>
          <Row className={styles.title}>
            <Col> Stocks & ETF</Col>
            <Col className={styles.stockLength}>{data.length} Stocks</Col>
          </Row>
          {data.length > 0 ?
            <Row className={styles.mobileScroll} ref={refScroll} onScroll={scrollDiv}>
              {data.map((item) => {
                return (<ExploreStocksMobile
                  logo={item.logo}
                  code={item.code}
                  company={item.company}
                  rating={item.meanRecommendedRating ? item.meanRecommendedRating : ''}
                  price={item.price}
                  change={item.change}
                  changePercentage={item.percentChange}
                  stockType={item.stockType}
                  close={item.price - item.change}
                  shariahStatus={item.status}
                  shariahRating={item.ranking}
                  userLocation={userLocation}
                />)
              })}
            </Row> : <Row className={`${styles.title} ${styles.notFound}`} style={{ justifyContent: 'center' }}>Stocks Not found</Row>}
        </div>
        {/* <Footer/> */}
        <div className={styles.sideBarMobileHide}>
          <SideBar handleEffect={handleEffect} />
        </div>
      </Container>
      <Row className={`${styles.fixedBottom} d-flex d-xl-none`}>
        {/* <Col className={styles.sortColumn} onClick={() => setShowSort(true)}>
             <span><img src={Sort}></img></span>
             <span className={styles.sortTitle}>Sort</span>
          </Col> */}
        <Col className={styles.sortColumn} onClick={() => setShowFilter(true)}>
          <span><img src={Filter}></img></span>
          <span className={styles.sortTitle}>Filter</span>
        </Col>
      </Row>

      {/* for Mobile */}
      <Modal
        show={showFilter}
        onHide={() => setShowFilter(false)}
        className={styles.modalRight}
        dialogClassName={styles.modalDialog}
        scrollable>
        <Modal.Body className={styles.modalPadding}>
          {/* <div className='mb-2'>
					<img src={BackArrow} onClick={() => setShowFilter(false)} />
				</div> */}
          <ExploreSearch setUrl={setUrl} showEtf={showEtf}
            setShowFilter={setShowFilter}
            setShowEtf={setShowEtf}
            sortBy={sortBy}
            setSortBy={setSortBy}
            rangeValue={rangeValue}
            setRangeValue={setRangeValue}
            priceChangeDay={priceChangeDay}
            setPriceChangeDay={setPriceChangeDay}
            priceChangeValue={priceChangeValue}
            setPriceChangeValue={setPriceChangeValue}
            marketCap={marketCap}
            setMarketCap={setMarketCap}
            rating={rating}
            defaultValue={defaultValue}
            setRating={setRating}
            setPage={setPage}
            resetFilter={resetFilter}
            userLocation={userLocation}
            isResetHalalFilter={isResetHalalFilter}
            setIsResetHalalFilter={setIsResetHalalFilter}
            showHalalStocks={showHalalStocks}
            setShowHalalStocks={setShowHalalStocks}
            halalRanking={halalRanking}
            setHalalRanking={setHalalRanking}
            resetHalalFilter={resetHalalFilter}
            halalText={halalText}
            setHalalText={setHalalText}
            color='#1E1F24' />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Explore
