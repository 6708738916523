import { MY_NOTIFICATION } from './action'

const socketNotification = (data) => {
  return dispatch => {
		return new Promise((resolve, reject) => {
      if (data && data.length > 0) {
        resolve(dispatch({ type: MY_NOTIFICATION, payload:data }))
      }
		})
	}
}

export default socketNotification
