import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { Formik } from 'formik'
import * as yup from 'yup'
import CheckBox from '../CheckBox/CheckBox'
import styles from './AccountFundingSetUp.module.css'

const AmountTransfer = ({
	amountTranfered,
	onChangeHandler,
	checkedLrs,
	onCheckedLrs
}) => {
	return (
		<>
			<Row className={styles.title}>
				How much to transfer into your trading account?
			</Row>
			<Row className={styles.detail} style={{ marginTop: '5px' }}>
				<b style={{ display: 'contents' }}>What is this?</b> This is the amount
				that will be transferred into your final trading account that will be
				used by you for investing in the stocks, bonds, ETFs, etc. The latest
				conversion rate will be used for this.
			</Row>
			<Formik
				initialValues={{ amountTranfered }}
				validationSchema={yup.object({
					amountTranfered: yup
						.number()
						.typeError('Amount must be valid number')
						.required('amount is required')
				})}>
				{({
					handleChange,
					handleBlur,
					handleSubmit,
					values,
					touched,
					errors
				}) => {
					return (
						<>
							<Row className={styles.inputRow}>
								<Col>
									<Form.Group
										className={`form-focus ${
											values.amountTranfered !== '' ? 'focused' : null
										}`}>
										<Form.Control
											className={styles.inputHeight}
											type='text'
											name='amountTranfered'
											value={values.amountTranfered}
											onChange={event => {
												onChangeHandler(event)
												handleChange(event)
											}}
											onBlur={handleBlur}
											isInvalid={
												touched.amountTranfered && errors.amountTranfered
											}
											isValid={
												touched.amountTranfered && !errors.amountTranfered
											}></Form.Control>
										<Form.Label className='focus-label'>
											Amount to be transferred
										</Form.Label>
										<Form.Control.Feedback type='invalid'>
											{errors.amountTranfered}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
							</Row>
							<Row className={styles.marginCheckBox}>
								<CheckBox
									label='I have not done any LRS in the current financial year'
									id='LRSCheckbox'
									name='isDirectorChecked'
									onChange={onCheckedLrs}
									isChecked={checkedLrs}
								/>
							</Row>
						</>
					)
				}}
			</Formik>
			{/* <Row className={styles.inputRow}>
				<Col>
					<SignInputField
						type='text'
						placeholder='Amount to be transferred'
						name='amountTranfered'
						value={amountTranfered}
						onChange={onChangeHandler}
						className={styles.inputHeight}
					/>
				</Col>
			</Row>
			<Row className={styles.marginCheckBox}>
				<CheckBox
					label='I have not done any LRS in the current financial year'
					id='LRSCheckbox'
					name='isDirectorChecked'
					onChange={onCheckedLrs}
					isChecked={checkedLrs}
				/>
			</Row> */}
		</>
	)
}

export default AmountTransfer
