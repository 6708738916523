import React, { useState, useEffect, lazy } from 'react';

import styles from './EmptyPage.module.css'
import { EMPTY_HEAD_PF_MSG, EMPTY_SUB_PF_MSG, STACK_NOT_FOUND_HEAD, STACK_NOT_FOUND_SUB,SOMETHING_WENT_WRONG_SUB, SOMETHING_WENT_WRONG_HEAD } from '../../displayMessages';
import { PORTFOLIO_PAGE, STACK_PAGE ,STOCK_PAGE,SOMETHING_WENT_WRONG} from '../../constants';
import emptyPf from '../../public/images/StartInvesting.png';
import emptyStack from '../../public/images/notstackfound.png'

/** Import Images */


const EmptyPage = ({type}) => {
  const [imagePath, setImagePath] = useState('');
  const [headText, setHeadText] = useState('');
  const [subText, setSubText] = useState('')

  const setTypes = () => {
    switch(type){
      case PORTFOLIO_PAGE:
        setImagePath(emptyPf);
        setHeadText(EMPTY_HEAD_PF_MSG);
        setSubText(EMPTY_SUB_PF_MSG);
        break;
      case STACK_PAGE:
         setImagePath(emptyStack)
         setHeadText(STACK_NOT_FOUND_HEAD)
         setSubText(STACK_NOT_FOUND_SUB)
         break;
      case STOCK_PAGE:
          setImagePath(emptyStack)
          setHeadText(STACK_NOT_FOUND_HEAD)
          setSubText(STACK_NOT_FOUND_SUB)
          break;
      case SOMETHING_WENT_WRONG:
          setImagePath(emptyStack)
          setHeadText(SOMETHING_WENT_WRONG_HEAD)
          setSubText(SOMETHING_WENT_WRONG_SUB)
          break;
      default:
    }
  }
  useEffect(() => {
    setTypes();
  }, [type])
  return (
    <div className={styles.emptyPageParent}>
      <div className={styles.emptyPageImage}>
        <img src={imagePath} alt="Couldn't See you there yet!" />
      </div>
      <div className={styles.emptyPageHeadText}>{headText}</div>
      <div className={styles.emptyPageSubText}>{subText}</div>
    </div>
  )
}

export default EmptyPage;
