import { getNews } from '../../api'
import { HOME_NEWS } from './action'

const getHomeNews = () => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			getNews().then(response => {
				if (response && response.length > 0) {
					resolve(dispatch({ type: HOME_NEWS, payload: response}))
				}
			})
		})
	}
}

export default getHomeNews
