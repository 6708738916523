import React from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import { format } from 'date-fns'
import SignUpButton from '../SignUpButton/SignUpButton'
import UploadImage from '../AccountSetUp/UploadImage/UploadImage'
import BankDetail from './BankDetail'
import OtherBankDetail from './OtherBankDetail'
import AmountTransfer from './AmountTransfer'
import LrsAmount from './LrsAmount'
import LrsPickUp from './LrsPickUp'
import styles from './AccountFundingSetUp.module.css'
import { ThemeContext } from '../../Context/ThemeContext'

class AccountFundingSetUp extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			showModal: true,
			selectedBank: '',
			bankAccountno: '',
			IFSCcode: '',
			bankName: '',
			bankAddress: '',
			name: '',
			amountTranfered: '',
			lrsAmount: '',
			lrsBankAccountno: '',
			checkedLrs: true,
			checkedLrsPick: true,
			dateofPickup: '',
			timeSlotPickup: '',
			pickupAddressLine1: '',
			pickupAddressLine2: '',
			pickupCountry: '',
			pickupPostalCode: '',
			pickupCity: '',
			pickupState: '',
			showCalendar: false,
			panFile: []
		}
		this.panRef = React.createRef()
	}
	static contextType = ThemeContext

	openModal = () => {
		this.setState({ showModal: true })
	}

	closeModal = () => {
		this.setState({ showModal: false })
	}

	onChangeHandler = event => {
		this.setState({
			[event.target.name]: event.target.value
		})
	}

	onCheckedLrs = () => {
		this.setState({ checkedLrs: !this.state.checkedLrs })
	}

	onCheckedLrsPick = () => {
		this.setState({ checkedLrsPick: !this.state.checkedLrsPick })
	}

	formatDateDisplay = date => {
		if (typeof date === 'string') {
			return date
		}
		return format(date, 'MM/dd/yyyy')
	}

	toggleCalender = () => {
		this.setState({
			showCalendar: !this.state.showCalendar
		})
	}
	onOutsideClick = () => {
		this.setState({ showCalendar: false })
	}

	onHandleDate = date => {
		this.setState({
			dateofPickup: date
		})
		this.toggleCalender()
	}

	// onChangeBank = value => {
	// 	this.setState({ yourBank: value })
	// }

	onDropPan = panFile => {
		panFile[0].preview = URL.createObjectURL(panFile[0])
		this.setState({ panFile })
	}

	onRemove = value => {
		this.setState({ [value]: [] })
	}

	openPanDialog = () => {
		if (this.panRef.current) {
			this.panRef.current.open()
		}
	}

	render() {
		const {
			selectedBank,
			bankAccountno,
			panFile,
			amountTranfered,
			IFSCcode,
			bankName,
			bankAddress,
			name,
			checkedLrs,
			lrsAmount,
			lrsBankAccountno,
			checkedLrsPick,
			dateofPickup,
			timeSlotPickup,
			pickupAddressLine1,
			pickupAddressLine2,
			pickupCity,
			pickupCountry,
			pickupPostalCode,
			pickupState,
			showCalendar
		} = this.state
		const bankOptions = [
			{ value: 'Yes Bank', label: 'Yes Bank' },
			{ value: 'HDFC Bank', label: 'HDFC Bank' },
			{ value: 'Axis Bank', label: 'Axis Bank' },
			{ value: 'Other Bank', label: 'Other Bank' }
		]
		const pickupSlotOptions = [
			{ value: '8:00 am to 12:00 pm', label: '8:00 am to 12:00 pm' },
			{ value: '12:00 pm to 4:00 pm', label: '12:00 pm to 4:00 pm' },
			{ value: '4:00 pm to 8:00 pm', label: '4:00 pm to 8:00 pm' }
		]
		const countryOptions = [{ value: 'India', label: 'India' }]
		console.log(dateofPickup)
		return (
			<>
				<Modal show={this.state.showModal} dialogClassName={styles.dialogClass}>
					<Modal.Body className={styles.background}>
						<Row className={styles.header}>
							<Col className={styles.brand}>
								<span>
									{/* <img
										src={require('../../public/images/StockalBrand.png')}
										className={styles.brandImg}></img> */}
                     <img
						className={styles.brandImg}
						src={this.context.shortLogo}></img>
								</span>
								<span className={styles.investment}>
									Investment Account Setup
								</span>
							</Col>
							<Col className={styles.save} md={2}>
								save & exit
							</Col>
						</Row>
						<div className={styles.container}>
							<Row className={styles.detail}>
								<b style={{ display: 'contents' }}>Why do we need this?</b>{' '}We
								need to know who you are for legal and security reasons. This is
								the information we’ll be using for your final application to
								create your investment account in USA. So please make sure this
								is all correct!
							</Row>
							<Row className={styles.note}>
								<b style={{ display: 'contents' }}>Note:</b> While you can
								initiate remittance now, please note that it will be credited to
								your brokerage account only after the account is approved and
								activated. More details
							</Row>
							<div className={styles.borderBottom}>
								{selectedBank === 'Other Bank' ? (
									<OtherBankDetail
										bankOptions={bankOptions}
										selectedBank={selectedBank}
										bankAccountno={bankAccountno}
										IFSCcode={IFSCcode}
										bankName={bankName}
										bankAddress={bankAddress}
										name={name}
										onChangeHandler={this.onChangeHandler}
									/>
								) : (
									<BankDetail
										bankOptions={bankOptions}
										bankAccountno={bankAccountno}
										selectedBank={selectedBank}
										onChangeHandler={this.onChangeHandler}
									/>
								)}
							</div>
							<div className={styles.borderBottom}>
								<Row>
									<Col>
										<div className={styles.title}>Upload PAN Card</div>
										<div style={{ marginTop: '15px' }}>
											<img
												src={
													panFile.length === 0
														? require('../../public/images/passwordCircle.svg')
														: require('../../public/images/PasswordCheck.svg')
												}
												className={panFile.length === 0 ? styles.circle : ''}
											/>
											<span className={styles.validation}>
												Allowed types: jpg, gif, png, pdf, jpeg
											</span>
										</div>
										<div style={{ marginTop: '10px' }}>
											<img
												src={
													panFile.length === 0
														? require('../../public/images/passwordCircle.svg')
														: require('../../public/images/PasswordCheck.svg')
												}
												className={panFile.length === 0 ? styles.circle : ''}
											/>
											<span className={styles.validation}>
												File size must be less than 10MB
											</span>
										</div>
									</Col>
									<Col>
										<UploadImage
											files={panFile}
											onDrop={this.onDropPan}
											dropRef={this.panRef}
											openDialog={this.openPanDialog}
											onRemove={this.onRemove}
											name='panFile'
										/>
									</Col>
								</Row>
							</div>
							<div className={styles.borderBottom}>
								<AmountTransfer
									amountTranfered={amountTranfered}
									onChangeHandler={this.onChangeHandler}
									checkedLrs={checkedLrs}
									onCheckedLrs={this.onCheckedLrs}
								/>
								{checkedLrs ? (
									''
								) : (
									<LrsAmount
										lrsAmount={lrsAmount}
										lrsBankAccountno={lrsBankAccountno}
										onChangeHandler={this.onChangeHandler}
									/>
								)}
							</div>

							<div>
								<LrsPickUp
									checkedLrsPick={checkedLrsPick}
									onCheckedLrsPick={this.onCheckedLrsPick}
									dateofPickup={dateofPickup}
									countryOptions={countryOptions}
									pickupSlotOptions={pickupSlotOptions}
									pickupAddressLine1={pickupAddressLine1}
									pickupAddressLine2={pickupAddressLine2}
									pickupCountry={pickupCountry}
									pickupPostalCode={pickupPostalCode}
									pickupCity={pickupCity}
									pickupState={pickupState}
									onChangeHandler={this.onChangeHandler}
									showCalendar={showCalendar}
									toggleCalender={this.toggleCalender}
									onOutsideClick={this.onOutsideClick}
									onHandleDate={this.onHandleDate}
									formatDateDisplay={this.formatDateDisplay}
								/>
							</div>
							<Row style={{ marginTop: '50px', marginBottom: '100px' }}>
								<Col>
									<SignUpButton
										title='Proceed to confirm LRS detail'
										classes={styles.completeLrsButton}
									/>
								</Col>
							</Row>
							<Row style={{ marginTop: '100px' }}></Row>
						</div>
					</Modal.Body>
				</Modal>
			</>
		)
	}
}

export default AccountFundingSetUp
