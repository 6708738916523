import React from 'react'
import {Row,Col} from 'react-bootstrap'
import styles from './ProgressBar.module.css'

const getColor = (index, current, color) => {
	let derivedColor = {
		color: color,
		background: 'white'
	}
	if (index < current) {
		derivedColor = {
			color: 'white',
			background: color
		}
	}
	return derivedColor
}

const showTimeLine = (steps, current, color) => {
	const items = []
	const indexIncrement = 1
	const totalWidth = 100
	const distance = Math.floor(totalWidth / (steps - indexIncrement))
	const startIndex = 0
	for (let index = startIndex; index < steps; index++) {
		const marginLeft = `${distance * index}%`
		const derivedColor = getColor(index, current, color)
		items.push(
      <>
			<div
				className={styles.marker}
				style={{
					left: marginLeft,
					color: derivedColor.color,
					borderColor: color,
					backgroundColor: derivedColor.background
				}}
				key={index}>
				{index + indexIncrement}
			</div>
      </>
		)
	}
	return items
}


const ProgressBar = ({ steps, current, color,status }) => {
	return (
    <>
		<div id='timeline-wrap' className={`mt-4 mb-3 ${styles.timelineWrap}`}>
			<div
				id='timeline'
				className={styles.timeline}
				style={{ backgroundColor: color }}
			/>
			{showTimeLine(steps, current, color,status)}

      <Row className={`mt-4 d-block d-md-none ${styles.timlineWidth}`} >
         {status}
      </Row>

      <Row className={`mt-4 d-none d-md-flex ${styles.timlineWidth}`} >
        <Col className={`pl-0 ${current >= 1 ?  styles.stepActive : styles.step}`} md={2}>
        Update Bank details
        </Col>
        <Col  style={{textAlign:'center'}} className={`pl-0 pr-0 ${current >= 2 ?  styles.stepActive : styles.step}`} >
          PAN & AADHAAR verification
        </Col>
        {/* <Col  style={{textAlign:'center'}} className='pl-0 pr-0' md={2}>
        AADHAAR verification
        </Col> */}
        <Col  style={{textAlign:'center'}} className={`${current >= 3 ?  styles.stepActive : styles.step} pr-0`} >
        Digitally Sign A2 form
        </Col>
        <Col  style={{textAlign:'center'}} className={`${current >= 4 ?  styles.stepActive : styles.step} pr-0`} >
        Transfer funds
        </Col>
        <Col className={`pl-0 pr-0 ${current >= 5 ?  styles.stepActive : styles.step}`}style={{textAlign:'end'}}>
        Upload Bank statement
        </Col>
      </Row>
		</div>

    </>
	)
}



export default ProgressBar
