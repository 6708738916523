import React, { useEffect, useState, useContext } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import InputLabel from '../../InputLabel/InputLabel'
import styles from '../Profile.module.css'
import { ThemeContext } from '../../../Context/ThemeContext'
import CustomModal from '../../../components/CustomModal/CustomModal'
import VerifyBasicOtp from './VerifyOTP'
import EditEmployment from '../EditUserBasicDetail/EditEmployment'
import { employeePositionsOptions, employmentStatusOptions, employeeTypeOptions  } from '../../AccountSetUp/Forms/FinancialDetail/FinancialData'

const Employment = props => {
  const { profileData } = props
	const [isEdit, setIsEdit] = useState(false)
	const [status, setStatus] = useState('')
  const [business, setBusiness] = useState('--')
  const [position, setPosition] = useState('--')

	const [employeeBroker, setEmployeeBroker] = useState('--')
	const [employerCompany, setEmployerCompany] = useState('')
  const [companyDirector, setCompanyDirector] = useState('--')
  const [directorName, setDirectorName] = useState('--')
  // const [sourceOfFunds, setEmployerCountry] = useState('')
  const [sourceOfFunds, setSourceOfFunds] = useState('')

	const [isVerifyOtp, setIsVerifyOtp] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [selfEmployedStatus] = useState(
		profileData['employmentStatus'] === 'EMPLOYED' ||
			profileData['employmentStatus'] === 'SELF_EMPLOYED'
			? true
			: true
	)
	const [getOtp, setOtp] = useState('')
	const { buttonColor } = useContext(ThemeContext)

  useEffect(() => {
    const countryID = profileData['employerCountryID']
		if (countryID && props.countryList && props.countryList.length) {
			// const [val] = props.countryList.filter(item => item.country === profileData['employerCountryID'])
      let countryObj;
      for(let i =0; i<props.countryList.length; i++){
        if(props.countryList[i] && (props.countryList[i].country === profileData['employerCountryID'])){
          countryObj = props.countryList[i];
          break
        }
    }

      setSourceOfFunds({
				value: countryID,
				label: countryObj ? countryObj.countryName : ''
			})
		} else {
			setSourceOfFunds({
				value: '',
				label: ''
			})
		}
  },[profileData, props.countryList])

  const setDetails=(data)=>{
    let statusObj = employmentStatusOptions.find(
      o => o.value === data['employmentStatus']
    )
    let businessObj = employeeTypeOptions.find(
      o => o.value === data['employerBusiness']
    )
    let positionObj = employeePositionsOptions.find(
      o => o.value === data['employmentPosition']
    )
    console.log('statusobj',statusObj)
    setStatus(statusObj && statusObj.value ? statusObj : data['employmentStatus'] ?  data['employmentStatus']  :"--")
    setBusiness(businessObj && businessObj.value ? businessObj : '--')
    setPosition(positionObj && positionObj.value ? positionObj : '--')

		// console.log(moment(data["dob"]).format('MMMM D, Y'));
		setEmployeeBroker((data['employerIsBroker'] === true || data['employerIsBroker'] === "true") ? 'Yes' : 'No')
		setEmployerCompany(
			data['employerCompany'] ? data['employerCompany'] : '--'
		)
    setCompanyDirector((data['director'] === true || data['director'] === "true") ? 'Yes' : 'No')
    setDirectorName(data['directorOf']  ? data['directorOf']:'')

    // setEmployerCountry(data['employerCountryID'] ? data['employerCountryID'] : "--")
    let countrySign = data['employerCountryID']
		if (props.countryList) {
      if(countrySign){
      // const [val] = props.countryList.filter(item => item.code3 === countrySign)

      let countryObj;
      for(let i =0; i<props.countryList.length; i++){
        if(props.countryList[i] && (props.countryList[i].country === countrySign)){
          countryObj = props.countryList[i];
          break
        }
    }

      setSourceOfFunds({
				value: countrySign,
				label: countryObj ? countryObj.countryName : ''
			})} else{
      setSourceOfFunds({
        value: '',
        label: ''})
      }
    // else {
		// 	setEmployerCountry({
		// 		key: 'IND',
		// 		value: 'India'
		// 	})
		// }
    }
  }

	useEffect(() => {
		setDetails(profileData)
	}, [profileData],[props.countryList])

	// useEffect(() => {

	// }, [props.countryList])

	// const statusOption = [
	// 	{ value: 'EMPLOYED', label: 'Employed' },
	// 	{ value: 'RETIRED', label: 'Retired' },
	// 	{ value: 'STUDENT', label: 'Student' },
	// 	{ value: 'UNEMPLOYED', label: 'Not Employed' },
	// 	{ value: 'SELF_EMPLOYED', label: 'Self Employed / Business Owner' }
	// ]



	// const businessOption = [
	// 	{
	// 		value: 'AGRICULTURE',
	// 		label: 'Agriculture, Forestry, Fishing and Hunting'
	// 	},
	// 	{ value: 'MINING', label: 'Mining, Quarrying, and Oil and Gas Extraction' },
	// 	{ value: 'UTILITIES', label: 'Utilities' },
	// 	{ value: 'CONSTRUCTION', label: 'Construction' },
	// 	{ value: 'MANUFACTURING', label: 'Manufacturing' },
	// 	{ value: 'WHOLESALE', label: 'Wholesale Trade' },
	// 	{ value: 'RETAIL', label: 'Retail Trade' },
	// 	{ value: 'TRANSPORT', label: 'Transportation and Warehousing' },
	// 	{ value: 'INFORMATION', label: 'Information' },
	// 	{ value: 'FINANCE', label: 'Finance and Insurance' },
	// 	{ value: 'REAL_ESTATE', label: 'Real Estate and Rental and Leasing' },
	// 	{
	// 		value: 'PROFESSIONAL',
	// 		label: 'Professional, Scientific, and Technical Services'
	// 	},
	// 	{ value: 'MANAGEMENT', label: 'Management of Companies and Enterprises' },
	// 	{ value: 'EDUCATION', label: 'Educational Services' },
	// 	{ value: 'HEALTH', label: 'Health Care and Social Assistance' },
	// 	{ value: 'ART', label: 'Arts, Entertainment, and Recreation' },
	// 	{ value: 'FOOD', label: 'Accommodation and Food Services' },
	// 	{ value: 'PUBLIC', label: 'Public Administration' },
	// 	{
	// 		value: 'WASTE',
	// 		label:
	// 			'Administrative and Support and Waste Management and Remediation Services'
	// 	}
	// ]



	const handleClose = () => {
		setShowModal(false, setIsVerifyOtp(false))
	}

	const onVerifyOtp = () => {
		setIsVerifyOtp(true)
	}

	const onEdit = () => {
		setShowModal(true)
		setIsEdit(true)
    props.sendOtpApi()
		// setShowModal(true, setIsEdit(true))
	}

	const onSave = () => {
		setIsEdit(false, setIsVerifyOtp(false))
	}

  return (
		<>
			{isEdit ? (
				<CustomModal className={styles.customModalPadding} show={showModal} handleClose={handleClose}>
					{isVerifyOtp ? (
						<EditEmployment
              sendOtpApi={props.sendOtpApi}
              setIsVerifyOtp={setIsVerifyOtp}
							status={status.value}
              business={business}
              position={position}
							employeeBroker={employeeBroker}
							employerCompany={employerCompany}
							companyDirector={companyDirector}
							directorOf={
								directorName=== 'null'
									? ''
									: directorName
							}
							sourceOfFunds={sourceOfFunds}
              sourceOfFundsCode={sourceOfFunds.value}
							handleClose={handleClose}
							// setIsVerifyOtp={setIsVerifyOtp}
							onSave={onSave}
							buttonColor={buttonColor}
              userID={props.profileData['userID']}
              setDetails={setDetails}
              countryOptions={props.countryList}
              otp={getOtp}
              hashId={props.hashId}
						/>
					) : (
						<VerifyBasicOtp
							profile={'edit your profile'}
							onVerifyOtp={onVerifyOtp}
							hashId={props.hashId}
							getOtp={setOtp}
							sendOtpApi={onEdit}
							buttonColor={buttonColor}
						/>
					)}
				</CustomModal>
			) : (
				''
			)}
			<Row className={styles.basicDetail}>
				<Col className={styles.basicDetailText}>Employment</Col>
        {profileData['accountStatus'] === 3 || profileData['accountStatus'] === 1 ? "" :
        <>
        {/* {profileData && profileData['accountStatus'] == 1 && profileData['nextStep'] !== 'COMPLETE'  ? "" : */}
				<Col style={{ textAlign: 'end' }}>
					<Button className={styles.editButton} onClick={onEdit}>
						<img
							src={require('../../../public/images/edit.svg')}
							className='mr-1'></img>
						Edit
					</Button>
				</Col>
        {/* } */}
        </>
        }
			</Row>
			<Row className={`m-0 mt-2 ${styles.borderBottom}`}>
				<Col md={4} xs={6} className='mb-3 mt-3'>
					<div className={styles.label}>Status</div>
					{/* <div className={styles.value}>{profileData['employmentStatus'] ? profileData['employmentStatus'] : '--'}</div> */}
         {status && status.label ? <div className={styles.value}>{status.label}</div>:'--'}
        </Col>
				{/* {selfEmployedStatus ? ( */}
					{/* <> */}
						<Col md={4} xs={6} className='mb-3 mt-3'>
							<div className={styles.label}>Business</div>
              {/* <div className={styles.value}>{profileData['employerBusiness'] ? profileData['employerBusiness'] : "--"}</div> */}
            	{business && business.label ?<div className={styles.value}>{business.label}</div>:'--'}
						</Col>
						<Col md={4} xs={6} className='mb-3 mt-3'>
							<div className={styles.label}>Employer is broker?</div>
							<div className={styles.value}>{employeeBroker}</div>
						</Col>
						<Col md={4} xs={6} className='mb-3 mt-3'>
							<div className={styles.label}>Employer</div>
							<div className={styles.value}>{employerCompany}</div>
						</Col>
						<Col md={4} xs={6} className='mb-3 mt-3'>
							<div className={styles.label}>Company director</div>
        {directorName ? <div className={styles.value}>{companyDirector+", "+ directorName}</div> :'--'}
						</Col>
						<Col md={4} xs={6} className='mb-3 mt-3'>
							<div className={styles.label}>Country of source of funds</div>
							<div className={styles.value}>
								{sourceOfFunds && sourceOfFunds.label ? sourceOfFunds.label : '--'}
							</div>
						</Col>

            {/* <Col md={4} xs={6} className='mb-3 mt-3'>
							<div className={styles.label}>Position</div>
            	{position && position.label ? <div className={styles.value}>{position.label}</div> : '--'}
						</Col> */}
			</Row>
			{/* {selfEmployedStatus ?
				<Row className={`m-0 mt-4 ${styles.borderBottom}`}>
					<Col>
						<div className={styles.label}>Employer</div>
						<div className={styles.value}>{employerCompany}</div>
					</Col>
					<Col>
						<div className={styles.label}>Company director</div>
						<div className={styles.value}>{companyDirector}</div>
					</Col>
					<Col>
						<div className={styles.label}>Employer country</div>
						<div className={styles.value}>{sourceOfFunds ? sourceOfFunds.value : '--'}</div>
					</Col>
				</Row>
				: ""} */}
		</>
	)
}

export default Employment
