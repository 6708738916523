import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import IdCard from '../../public/images/IDCard.svg'
import InfoLogo from '../../public/images/infoLogo.svg'
import Arrow from '../../public/images/ProfileBackArrow.svg'
import styles from './AadharPanSelection.module.css'

const selectionData = [
	{
		icon: IdCard,
		title: 'PAN Card',
		subTitle: 'Front and Back',
		code: 'national_identity_card',
		countryList: ['IND']
	},
  {
		icon: IdCard,
		title: 'Aadhaar Card',
		subTitle: 'Front and Back',
		code: 'national_identity_card',
		countryList: ['IND']
	},
]

const AadharPanSelection = ({onSelection,display}) => {
	const Cards = () => {
		let ui = []

		selectionData.forEach((doc, idx) => {
			let { icon, title, subTitle } = doc

			ui.push(
				<>
					<Card className={styles.card} onClick={() => onSelection(doc)}>
						<Card.Body className={styles.cardBody}>
							<Row style={{ margin: '0px' }}>
								<Col style={{ padding: '0px' }} xs={3} md={2}>
									<img alt='icon' className={styles.logoImg} src={icon}></img>
								</Col>
								<Col xs={7} md={8}>
									<div className={styles.cardTitle}>
										{title}
										{/* <img
											alt='icon'
											className={styles.infoLogoImg}
											src={InfoLogo}></img> */}
									</div>
									<div className={styles.cardSubTitle}>{subTitle}</div>
								</Col>
								<Col
									style={{ padding: '0px' }}
									xs={2}
									md={2}
									className={styles.cardAction}>
									<img alt='action' src={Arrow}></img>
								</Col>
							</Row>
						</Card.Body>
					</Card>
					{idx !== selectionData.length - 1 && (
						<div className={styles.orLabel}>OR</div>
					)}
				</>
			)
		})

		return ui
	}

	return (
		<>
			<div
				style={{display}} className='m-0 justify-content-center'>
				<Card className={styles.cardWrapper}>
					<Card.Body className={styles.cardWrapperBody}>
						<Row style={{ margin: '0px' }}>
							<div className={styles.title}>Upload documents</div>
							<div className={styles.subTitle}>
								We need pictures of your Official Identity Document(s) to
								proceed further.
							</div>
							{Cards()}
						</Row>
					</Card.Body>
				</Card>
			</div>
		</>
	)
}

export default AadharPanSelection
