import React, { useEffect, useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import styles from '../Profile.module.css'
import InputLabel from '../../InputLabel/InputLabel'


const CancelSubscription = (props) => {
    const[selectedReason, setSelectedReason] = useState('');
    const[continueButton, setContinueButton] = useState(false)
    const [saveChanges, setSaveChanges] = useState(true)

    const onContinue = () => {
        setSaveChanges(false)
    }

    return (
        <>
         <div style={{width:"400px"}}>
            {saveChanges ? 
            <>
            <Row className={`p-0 mt-0 ${styles.basicDetail}`}>
                <Col className={`p-0 ${styles.basicDetailText}`}>Cancel Subscription</Col>
            </Row>
            <Row className={`mt-2 ${styles.enterOtpText}`}>
                <Col className={`p-0 m-0 ${styles.sadText}`}>We’re sad to see you leave :( . Please tell us why.</Col>
            </Row>
            <Row className="mt-5">
                <Col md={12}>
                    <div className={selectedReason === "I found a better platform" ? styles.cancelReasonBlack  : styles.cancelReasonGrey} onClick={() => setSelectedReason("I found a better platform")}>
                        <p className={selectedReason === "I found a better platform" ? styles.reasonTextWhite  : styles.reasonTextBlack}>
                            I found a better platform
                        </p>
                    </div>
                </Col>
                <Col md={12}>
                    <div className={selectedReason === "Not happy with overall experience" ? styles.cancelReasonBlack  : styles.cancelReasonGrey} onClick={() => setSelectedReason("Not happy with overall experience")}>
                        <p className={selectedReason === "Not happy with overall experience" ? styles.reasonTextWhite  : styles.reasonTextBlack}>
                            Not happy with overall experience
                        </p>
                    </div>
                </Col>
                <Col md={12}>
                    <div className={selectedReason === "I am not an active investor" ? styles.cancelReasonBlack  : styles.cancelReasonGrey} onClick={() => setSelectedReason("I am not an active investor")}>
                        <p className={ selectedReason === "I am not an active investor" ? styles.reasonTextWhite  : styles.reasonTextBlack}>
                            I am not an active investor
                        </p>
                    </div>
                </Col>
                <Col md={12}>
                    <div className={selectedReason === "Other" ? styles.cancelReasonBlack  : styles.cancelReasonGrey} onClick={() => setSelectedReason("Other")}>
                        <p className={selectedReason === "Other" ? styles.reasonTextWhite  : styles.reasonTextBlack}>
                           Other
                        </p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12} className={` ${styles.reasonTextBlack}`}>Tell us why...</Col>
                <Col md={12}>
                    <textarea
                    placeholder="Write reason here..." 
                    className={styles.cancelTextArea} rows="4" cols="50">

                    </textarea>
                </Col>
            </Row>
             <Row>
                <Col md={6} className={`${styles.bottomButton}`}>
                    <span onClick={props.handleClose}>Cancel</span>
                </Col>
                <Col md={6} className={`${styles.saveBottomButton}`}>
                    <Button className={styles.continueButton} onClick={onContinue}>
                        Continue
                    </Button>
                </Col>
            </Row>
            </>
            :
            <Row className={`p-0 mt-0 ${styles.basicDetail}`}>
                <p className={styles.ReachOutText}>Please reach out to our customer support at +91-22-42792110 to cancel your subscription.</p>   
            </Row> 
            }
        </div>
        </>
    )
}

export default CancelSubscription