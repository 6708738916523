import React from 'react';
import { useState, useEffect} from 'react';
import style from "./beneficiary.module.css";
import NoteIcon from "../../public/images/aboutIcon.svg";
import HDFCTable from "../../components/AddFund/Transfer/Online/HDFCTable";
import ICICITable from "../../components/AddFund/Transfer/Online/ICICITable";
import KOTAKTable from "../../components/AddFund/Transfer/Online/KotakTable";
import TableError from "../../components/AddFund/Transfer/Online/TableError";
import NonIndianTable from "../../components/AddFund/Transfer/Online/NonIndianTable";
import DownloadIcon from "../../public/images/downloadSecondary.svg";
import { fetchSavingAccount, getVirtualAccountDetail, lrsPDF, getBankList} from "../../api";
import { Spinner } from "react-bootstrap";
import DownloadInstruction from "../../components/AddFund/Transfer/Common/DownloadInstruction";
import SelectDropDown from "../../components/AddFund/Add/SelectDropDown";
import BankSecondaryIcon from '../../public/images/bankSecondary.svg'



const FundBenefeiciary = (props) => {
  const [bank, setBank] = useState('')
  const [accountDetail, setAccountDetail] = useState(null)
  const [loadingPDF, setLoadingPDF] = useState(false)
  const [tableError, setTableError] = useState(false)
  const [loadingDetails, setLoadingDetails] = useState(false)
  const [accountType, setAccountType] = useState('')
  const [savingDetails, setSavingDetails] = useState('')
  const [PDFError, setPDFError] = useState(false)
  const [bankList, setBankList] = useState()

  useEffect(()=>{
    fetchAccountDetail()
    fetchBankList()
  },[])


  const fetchBankList = async() =>{
    try{
        const res = await getBankList()
        if(res && res.code === 200){
            setBankList(res.data)
            return res.data
        }else{
          console.log('hi')
        }
    }catch(error){
        console.log('hi',error)
    }
  }

const fetchAccountDetail = async()=>{
    if(!loadingDetails){
        setLoadingDetails(true)
        try{
            let brokerDetail=null
            // if(accountType==='Saving'){
            //   console.log('hi')
            //     if(savingDetails)
            //         brokerDetail=savingDetails
            //     else{
            //         const res = await fetchSavingAccount()
            //         if(res && res.data && res.data.code===200)
            //         {
            //             setSavingDetails(res.data.data)
            //             brokerDetail=savingDetails
            //             setTableError(false)
            //         }else{
            //             setTableError(true)
            //         }
            //     }
            // }
            if(accountType !== 'Saving'){
                const res = await getVirtualAccountDetail()
                if(res  && res.code==200){
                    brokerDetail = res.data
                    if(tableError)
                        setTableError(false)
                }
            }
            if(brokerDetail){
                const data={
                    name: brokerDetail && brokerDetail.beneficiaryName ? brokerDetail.beneficiaryName : '--',
                    address: brokerDetail && brokerDetail.beneficiaryAddress ? brokerDetail.beneficiaryAddress  :'--',
                    country: brokerDetail  && brokerDetail.beneficiaryAddressDetails && brokerDetail.beneficiaryAddressDetails.country ? brokerDetail.beneficiaryAddressDetails.country : '--' ,
                    city: brokerDetail && brokerDetail.beneficiaryAddressDetails && brokerDetail.beneficiaryAddressDetails.city ? brokerDetail.beneficiaryAddressDetails.city : '--' ,
                    state: brokerDetail && brokerDetail.beneficiaryAddressDetails && brokerDetail.beneficiaryAddressDetails.stateProvince ? brokerDetail.beneficiaryAddressDetails.stateProvince : '--' ,
                    pinCode: brokerDetail && brokerDetail.beneficiaryAddressDetails && brokerDetail.beneficiaryAddressDetails.zipPostalCode ? brokerDetail.beneficiaryAddressDetails.zipPostalCode : '--' ,
                    contactNumber: brokerDetail && brokerDetail.contactNumber ? brokerDetail.contactNumber : '--' ,
                    currency: brokerDetail && brokerDetail.currency ? brokerDetail.currency : '--' ,
                    nickName: brokerDetail && brokerDetail.nickname ? brokerDetail.nickname : '--',
                    bankName: brokerDetail && brokerDetail.bankName ? brokerDetail.bankName : '--' ,
                    bankAddress: brokerDetail && brokerDetail.bankAddress ? brokerDetail.bankAddress : '--',
                    bankCountry: brokerDetail && brokerDetail.bankAddressDetails && brokerDetail.bankAddressDetails.country ? brokerDetail.bankAddressDetails.country : '--' ,
                    accountNumber: brokerDetail && brokerDetail.bankAccountNumber ? brokerDetail.bankAccountNumber : '--' ,
                    SWIFTCode: brokerDetail && brokerDetail.bankSwiftCode ? brokerDetail.bankSwiftCode  : '--',
                    ABANumber: brokerDetail && brokerDetail.ABANumber ? brokerDetail.ABANumber : '--',
                    achRoutingNumber: brokerDetail && brokerDetail.achRoutingNumber ? brokerDetail.achRoutingNumber:'--',
                    intermediaryBankName: brokerDetail && brokerDetail.intermediaryBankName ? brokerDetail.intermediaryBankName:'--',
                    intermediaryBankSwiftCode: brokerDetail && brokerDetail.intermediaryBankSwiftCode ? brokerDetail.intermediaryBankSwiftCode:'--'
                }
                setAccountDetail(data)
            }else{
                setTableError(true)
            }

        }catch(error){
            setTableError(true)
        }
        setLoadingDetails(false)
    }
}

  const onDownloadPDF = async()=>{
    if(!loadingPDF){
        setLoadingPDF(true)
        let bankName = bank.replace(' ', '_').toUpperCase()
        try{
            const response= await lrsPDF(bankName)
            if (response.status || response.code === 200) {
                let href = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
                // download the file
                let element = document.createElement('a');
                element.setAttribute('href', href);
                element.setAttribute('download', '');
                element.style.display = 'none';
                document.body.appendChild(element);
                element.dispatchEvent(new MouseEvent('click'));
                document.body.removeChild(element);
                if(PDFError)
                    setPDFError(false)
            }else{
                setPDFError(true)
            }
        }catch(error){
            setPDFError(true)
        }
        setLoadingPDF(false)
    }
  }


  const onChange = (value, name)=>{
    setBank(value)
  }

  const getBankDropDownOptions = ()=>{
    const list =[]
    let otherBank = null
    const addedBanks = []
    const filterBanks = bankList && bankList.filter(bank => bank.type ===  "online_transfer")
    filterBanks && filterBanks.forEach((item)=>{
        if(item.code!=='OTHER_NONINDIAN'){
            if(!addedBanks.includes(item.name)){
                if(item.code==='OTHER_INDIAN'){
                    otherBank={imgUrl:item.logo, title:'Other'}
                }else{
                    addedBanks.push(item.name)
                    list.push({imgUrl:item.logo, title:item.name})
                }
            }
        }
    })
    if(otherBank)
        list.push(otherBank)
    return list
}


  return(
    <div className={style.instructionContainer}>
      <div className={style.header}>
        <div className="d-flex flex-column">
          <span className={style.primary}>Your Unique Beneficiary Details</span>
          <span className={style.secondary}>Funds need to be wired to this account.</span>
        </div>
      </div>
      {bank!=="Others" && <>
        <div className={style.instructionContainerGrey}>
          <div className={style.inputContainer}>
            <label>Pick a Bank</label>
            <SelectDropDown style={{background:"white"}} selectVal={bank} setSelectVal={(value)=>onChange(value, 'bank')} dropDownlist={getBankDropDownOptions()} placeholder={"Select Bank"} selectLabelIcon={BankSecondaryIcon} searchBox={false}/>
          </div>
          {!PDFError?<button className={style.downloadPDF} onClick={onDownloadPDF} disabled={bank ? false : true}>
              {!loadingPDF?<img src={DownloadIcon} style={{marginRight:"10px"}}/>:<Spinner animation="border" className={style.spinnerColor}/>}
              Download PDF
          </button>:<DownloadInstruction fetchPDF={onDownloadPDF}/>}
          <div className={style.limitNote}>
            <img src={NoteIcon} style={{marginTop:"5px"}}/>
          <div>There is a limit of $25,000 on online transactions per day. If you need to remit more, please use offline method.</div>
          </div>
        </div>
      </>}
      {accountDetail && !tableError&&!loadingDetails&& <>
          <HDFCTable accountDetail={accountDetail}/>
          {/* {bank==="ICICI Bank" &&<ICICITable accountDetail={accountDetail}/>}
          {bank==="KOTAK Bank" &&<KOTAKTable accountDetail={accountDetail}/>}
          {bank=="Others" && <NonIndianTable accountDetail={accountDetail}/>} */}
      </>
      }
      {tableError?<TableError fetchBrokerDetails={fetchAccountDetail}/>:loadingDetails&&<div className={style.loadingContainer}>
          <Spinner animation="border" className={`${style.spinnerColor} ${style.tableLoading}`}/>
          Loading...
      </div> }
    </div>
  )
}

export default FundBenefeiciary
