import { combineReducers } from 'redux'
import setUserSummary from './reducers/UserAccountreducer'
import userInfo from './reducers/UserInforeducer'
import userPortfolio from './reducers/UserPortfolioreducer'
import userInvestmentHistory from './reducers/UserInvestmentHistory'
import marketDetails from './reducers/MarketDetailreducer'
// import userPlanInfo from './reducers/UserPlanInfoReducer'
import setCountryList from './reducers/CountryListreducer'
import homeNews from './reducers/HomeNewsreducer'
import topStacks from './reducers/TopPerformingStacksreducer'
import yearHighStock from './reducers/AllYearHighStockReducer'
import yearLowStock from './reducers/AllYearLowStockReducer'
import AllNotification from './reducers/MyNotificationReducer'
import fund from './reducers/FundReducer'

export default combineReducers({
	userAccountSummary: setUserSummary,
	userDetails: userInfo,
	userPortfolio: userPortfolio,
	userInvestment: userInvestmentHistory,
	marketDetails: marketDetails,
  // userPlanInfo: userPlanInfo,
  countryList: setCountryList,
  homeNews: homeNews,
  topStacks: topStacks,
  yearHighStock: yearHighStock,
  yearLowStock: yearLowStock,
  AllNotification: AllNotification,
  fund:fund
})
