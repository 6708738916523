import React,{useRef,useEffect, useState} from 'react'
import { Row, Col, Table , Spinner} from 'react-bootstrap'
import styles from './ExploreStocksEtf.module.css'
import { useHistory } from 'react-router-dom'
import ExploreRow from './ExploreRow'


const rowHeaders = [
	'Name',
	'Market Price',
	'Market Cap',
	'52w High',
	'52w Low',
]

const shariahRowheaders = [
	'Name',
	'Market Price',
	'Market Cap',
	'52w High',
	'52w Low',
  'Shariah Compliance'
]

const ExploreStocksEtf = ({data,setPage,page,prevScroll,setprevScroll,pageLoading,setData,userLocation}) => {
  const refDiv = useRef();
  let history = useHistory()

  const [tableHeadings, setTableHeadings] = useState(rowHeaders)

  const calculateRating = (value) => {
    let tempValue = '--'
    let color = ''
    if( value > 0.0 && value <= 1.5){
      tempValue = 'Strong Buy'
      color = '#0DC014'
     }
     else if(value > 1.5 && value <= 2.5){
      tempValue = 'Buy'
      color='#0DC014'
     }
     else if(value > 2.5 && value <= 3.5){
      tempValue = 'Hold'
      color='#1E1F24'
     }
     else if(value > 3.5  && value <= 4.5){
      tempValue = 'Sell'
      color='rgb(250, 93, 5)'
     }
     else if(value > 4.5){
      tempValue = 'Strong Sell'
      color='rgb(250, 93, 5)'
     }
     let item = <>
     <div className={styles.ratingColor} style={{backgroundColor:color}}></div><span>{tempValue}</span>
     </>
     return item
  }

  const scrollDiv = () => {
    if(refDiv.current.scrollTop + refDiv.current.offsetHeight >= refDiv.current.scrollHeight){
      setPage(page + 1)
      setprevScroll(refDiv.current.scrollTop)

     }
  }

  useEffect(() => {
    if(data.length > 0){
    refDiv.current.scrollTop = prevScroll
    }
  },[prevScroll])


  const onLink = (link,code) => {
    history.push(`/${link}/${code}`)
  }

  useEffect(()=>{
    if(userLocation === 'MENA') {
      setTableHeadings(shariahRowheaders)
    }
  },[userLocation])

  // const updateData = (index) => {
  //   const newArr = [...data]
  //   newArr[index]["isImgLoaded"] = false
  //   setData(newArr)
  // }

	return (
		<>
			<Row className='m-0'>
				<Col className={styles.stocksEtf}>Stocks & ETF</Col>
				<Col className={styles.totalStocks}>{data.length} Stocks</Col>
			</Row>
      {data.length > 0 ?
			<Row className={`m-0 mt-4 ${data.length > 7 ? styles.scrollDiv : ''}`} ref={refDiv} onScroll={scrollDiv}>
				<Table borderless className={styles.dataTable}>
					<thead className={styles.stickyHeader}>
						<tr>
							<th className={styles.tableborder}></th>
							{tableHeadings.map((item, index) => {
								return (
									<th key={index} className={styles.tableHead}>
										{item}
									</th>
								)
							})}
						</tr>
					</thead>
					<tbody>
						{data.map((item, index) => {
              const link = item.stockType === 'etf' ? 'etfdetail' : 'stockdetail'
              const close = item.price - item.change
              return(
                <ExploreRow link={link}
                logo={item.logo}
                code={item.code}
                company={item.company}
                price={item.price}
                change={item.change}
                percentChange={item.percentChange}
                marketCapitalization={item.marketCapitalization}
                yearHigh={item.yearHigh}
                yearLow={item.yearLow}
                onLink={onLink}
                close={close}
                shariahStatus={item.status}
                shariahRating={item.ranking}
                userLocation={userLocation}
                />
              )
						})}
            {pageLoading ?
            <tr>
              <td colSpan="7">
                <div className={styles.spinner}><Spinner animation="border" className={styles.spinnerColor}/></div>
             </td>
            </tr> : '' }
					</tbody>
				</Table>
			</Row> : <Row className={`${styles.stocksEtf} ${styles.notFound}`}>Stocks Not found</Row>}
		</>
	)
}

export default ExploreStocksEtf
