import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import SkeletonElement from './SkeletonElement'
import Shimmer from './Shimmer'

const stackDetailSkeleton = () => {
	return (
		<Card className='skeletonCard ml-0 mr-0'>
			<Card.Body>
				<>
        <Row>
          <Col lg={2}>
          <SkeletonElement width='100px' height='100px'></SkeletonElement>
          </Col>
          <Col>
          <SkeletonElement width='100px' height='30px'></SkeletonElement>
          <SkeletonElement width='70px' height='10px'></SkeletonElement>
          </Col>
          <Col md={2} lg={2}>
          <SkeletonElement width='100px' height='30px'></SkeletonElement>
          <SkeletonElement width='70px' height='10px'></SkeletonElement>
          </Col>
          <Col  md={2} lg={2}>
          <SkeletonElement width='100px' height='30px'></SkeletonElement>
          <SkeletonElement width='70px' height='10px'></SkeletonElement>
          </Col>
          <Col md={2} lg={2}>
          <SkeletonElement width='100px' height='30px'></SkeletonElement>
          <SkeletonElement width='70px' height='10px'></SkeletonElement>
          </Col>
        </Row>

					<Shimmer />
				</>
			</Card.Body>
		</Card>
	)
}

export default stackDetailSkeleton
