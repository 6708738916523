import React, { useState, useEffect, useContext, useRef } from 'react'
import { Row, Card, Col, FormControl, Button, Accordion, 	useAccordionToggle, AccordionContext } from 'react-bootstrap'
// import OtpInput from 'react-otp-input'
import PinInput from "react-pin-input"
import {
	getStackCommission,
	getStackAllocation,
	verifySpin,
	getLivePrice,
	tradeStack,
} from '../../api'
import Purchased from './Purchased'
import { useSelector, useDispatch } from 'react-redux'
import { getMarketDetails } from '../../store/actions/index'
import styles from './StackBuy.module.css'
import crossImage from '../../public/images/cross.png'
import StocksWeightsTable from '../Stocks&Weights/StocksWeightsTable'
import AngleDown from '../../public/images/AngleDown.png'
import DropDownIcon from '../../public/images/DropDownIcon.png'
import { SPIN_INVALID, SPIN_LENGTH, BUY, STACK_CAT, MARKET_ORDER, PORTFOLIO_LIVE_PRICE_INTERVAL, ETF_CAT } from '../../constants'
import { handleErrorResponse } from '../../errorResponse'
import { decimalAdjust, calPriceChange, isMarketOpen } from '../../utils'
import { keyBy, filter } from 'lodash'
import { SPIN_INVALID_MSG } from '../../displayMessages'

const headerStock = ['Stocks', 'price', 'allocation %', 'allocation amt']
const headerEtf = ['ETF', 'price', 'allocation %', 'allocation amt']

const inputStyle = {
  spinInput: {
    border: 'none',
    textAlign: 'center',
    width: '40px',
    height: '48px',
    background: '#EFEFF1',
    borderRadius: '8px',
    marginLeft: '16px',
    // },
    // inputStyle:{
    fontFamily: 'Geomanist',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: '0.6px',
    color: '#D3D4DA',
  }
}


const ReviewOrder = ({
	closeModal,
	setPurchased,
	purchased,
	stack,
	investedvalue
}) => {
	const [investwithoutCommission, setinvestwithoutCommission] = useState(investedvalue)
	const [spin, setSpin] = useState('')
	const [errormMessage, setErrorMessage] = useState('')
	const [orderLoading, setOrderLoading] = useState(false)
  const [disableButton, setdisableButton] = useState(true)
  const [loadingEtf, setLoadingEtf] = useState(true)
  const [loadingNonEtf, setLoadingNonEtf] = useState(true)
  const [apiLoading, setApiLoading] = useState(true)
	const [stackAllocation, setStackAllocation] = useState([])
  const [live, setLive] = useState([])
	const dispatchAction = useDispatch()
	const state = useSelector(state => state)
  const [activeNav, setActiveNav] = useState('summary')
  const isInputSecure = true;
	const {
		marketDetails: { marketDetails: { currentMarketStatus = '' } = {} } = {}
	} = state
	if (Object.keys(state.marketDetails).length === 0) {
		dispatchAction(getMarketDetails())
  }
  const myRef = useRef()
  let stocks = []
  let etf = []
	const {
		ticker = '',
		aumForBuy = '',
		entryFee = '',
		aumFrequency = '',
		logo = '',
		name = '',
		rbFrequencyTitle = '',
    fee = '',
    tos='',
	} = stack
	useEffect(() => {
		const fetch = async () => {
			if ( ticker !== '') {
        setApiLoading(true);
        try {
				  const response = await getStackAllocation(ticker, investwithoutCommission)
          setStackAllocation(response)
        } catch(error){
          console.log("error ", error);
          setErrorMessage(handleErrorResponse(error))
        }
        setApiLoading(false);
			}
		}
		if (investwithoutCommission > 0) {
			fetch()
		}
	}, [investwithoutCommission, ticker])

  useEffect(() => {
    if(!apiLoading){
      if(stocks.length === filter(stackAllocation, ({stockType}) => stockType !== 'etf').length){
        setLoadingNonEtf(false);
      }
      if(etf.length === filter(stackAllocation, ({stockType}) => stockType === 'etf').length){
        setLoadingEtf(false);
      }
    }
  }, [apiLoading, etf, stackAllocation, stocks])

	useEffect(() => {
		const fetch = async () => {
			try {
				const response = await getStackCommission(
					aumForBuy,
					entryFee,
					investedvalue,
					aumFrequency,
          ticker || ''
				)
				setinvestwithoutCommission(decimalAdjust('floor', investedvalue - response.totalCommission, -2))
			} catch (error) {
				setErrorMessage(handleErrorResponse(error))
			}
		}
		fetch()
	}, [aumForBuy, aumFrequency, entryFee, investedvalue])

  const handleLiveApi = async data => {
		data = data.map(trav => trav.symbol).join(',')
		let response = await getLivePrice(data)
		setLive(keyBy(response, 'symbol'))
  }

  if (stackAllocation && stackAllocation.length > 0 && live && Object.keys(live).length > 0) {
    stackAllocation.map(item => {
	  let {lastTrade, close, priorClose} = live[item.symbol];
	  lastTrade = isMarketOpen(currentMarketStatus) ? lastTrade : close
      let {change, changePer} = calPriceChange({
        currPrice: lastTrade,
        close: close || item.close,
        priorClose: priorClose || item.priorClose,
        isMarketOpen: isMarketOpen(currentMarketStatus)});
      const obj = {
        image: item.logo ? item.logo : '',
        name: item.symbol ? item.symbol : '',
        creator: item.company ? item.company : '',
        price: lastTrade,
        pricePercentage: changePer,
        amount: item.allocationAmount ? item.allocationAmount : '',
        allocationPer: item.allocationPer ? item.allocationPer : '',
        isImgLoaded: true,
        change,
      }
      if(item.stockType !== 'etf') {
        stocks = [...stocks, obj]
      } else {
        etf = [...etf, obj]
      }
    })
  }

	useEffect(() => {
    stackAllocation && stackAllocation.length > 0 && handleLiveApi(stackAllocation)
  }, [stackAllocation])

  useEffect(() => {
    const setLivePrice = async () => {
      if (stackAllocation && stackAllocation.length > 0) {
        await handleLiveApi(stackAllocation);
      }
    }

    const priceId = setInterval(() => {
      if(isMarketOpen(currentMarketStatus)){
        setLivePrice();
      }
    }, PORTFOLIO_LIVE_PRICE_INTERVAL);

    return () => {
      clearInterval(priceId);
    }
  }, [stackAllocation, currentMarketStatus])

	const onPurchase = async () => {
		setOrderLoading(true)
		setErrorMessage('')
		// if (spin === '' || (spin && spin.length < SPIN_LENGTH)) {
		// 	setErrorMessage(SPIN_INVALID_MSG)
		// 	return
		// }
		try {
			// await verifySpin(spin)
			placeOrder();
		} catch(error) {
      setOrderLoading(false)
			setErrorMessage(handleErrorResponse(error))
		}
	}

	const placeOrder = async () => {
		let data = {
			symbol: ticker,
			side: BUY,
			category: STACK_CAT,
			type: MARKET_ORDER,
			amount: investedvalue ? parseFloat(investedvalue) : investedvalue,
			spin: spin,
		}
		try {
			await tradeStack(data);
			setPurchased(true);
		} catch(error) {
			setPurchased(false);
      setErrorMessage(handleErrorResponse(error));
      setdisableButton(true,setSpin('',myRef.current.clear()));
		} finally {
			setOrderLoading(false);
		}
  }

  const stockAndWeightsRenderComponent = (type) => (
    <StocksWeightsTable
      rowHeaders={type === ETF_CAT ? headerEtf : headerStock}
      data={type === ETF_CAT ? etf : stocks}
      isLoading={type === ETF_CAT ? loadingEtf : loadingNonEtf}
    />
  )

  useEffect(() => {
    if(spin && spin.length === SPIN_LENGTH){
      setdisableButton(false)
    } else {
      setdisableButton(true)
    }
  }, [spin])

  const reset = (value) => {
    setSpin(value)
    setErrorMessage('')
    // setShowError(false)
  }



	return (
		<>
			{purchased ? (
				<Purchased onClick={closeModal}></Purchased>
			) : (
				<Row className='m-0' className={styles.modalWidth}>
					<Col className='p-0  d-none d-md-block' md={8} lg={9}>
						<Card className={styles.reviewCard} style={{ borderLeft: '0px' }}>
							<Card.Body className={`${styles.reviewCardBody} pl-0`}>
								<Row className={`m-0 ${styles.reviewOrderTitle}`}>
									Stocks & Weights
								</Row>
								<Row className={`${styles.scrollDiv}`}>
                { stockAndWeightsRenderComponent(!ETF_CAT)}
                { stockAndWeightsRenderComponent(ETF_CAT)}
									{/* <StocksWeightsTable
										rowHeaders={headerStock}
										data={stocks}
									/>
									<StocksWeightsTable
										rowHeaders={headerEtf}
										data={etf}
									/> */}
								</Row>
								<Row className={styles.footer}>
									<Col className={styles.footerStack}>
										{stocks.length} stocks & {etf.length}{' '}
										ETF’s
									</Col>
									<Col
										className={styles.footerStack}
										style={{ textAlign: 'end' }}>
										your investment: ${investwithoutCommission}
									</Col>
								</Row>
							</Card.Body>
						</Card>
					</Col>
					<Col md={4} lg={3} xs ={12} className={`p-0 ${styles.reviewScroll}`}>
						<Card className={styles.reviewCard}>
							<Card.Body className={`${styles.reviewCardBody} pr-0`}>
								<Row className={`m-0`}>
									<Col className={styles.reviewOrderTitle} xs={10}>Review Order</Col>
									<Col xs={1} sm ={2} className={`${styles.closeCard} d-block d-md-none`}>
										<img
											src={crossImage} onClick={closeModal}></img>
									</Col>
								</Row>
								<Row className={`d-none d-md-flex m-0 mt-4`}>
									<Col md={3} xs={3} className={styles.reviewImage}>
										<img src={logo} className={styles.stackImage}></img>
									</Col>
									<Col className={styles.detail} xs={9}>
										<div className={styles.stackName}>{ticker}</div>
										<div className={styles.stackCreator}>{name.length > 21 ? `${name.substring(0,18)}...`: name}</div>
									</Col>
								</Row>
								<Row className={`d-flex d-md-none m-0 mt-2`}>
									<Col className='d-flex justify-content-between align-items-center p-0'>
										<div className={styles.detail}>
											<div className={styles.stackName}>{ticker}</div>
											<div className={styles.stackCreator}>{name.length > 21 ? `${name.substring(0,18)}...`: name}</div>
										</div>
										<img src={logo} className={styles.stackImage}></img>
									</Col>
								</Row>
								<Row className={`m-0 mt-4 ${styles.investing}`}>
									You are investing
								</Row>
								<Row className='m-0 mt-3' style={{ position: 'relative' }}>
									<span className={styles.dollarIcon}>
										<img src={require('../../public/images/dollar.svg')}></img>
									</span>
									<FormControl
										className={styles.amountInput}
										placeholder='Enter Amount'
										value={investedvalue}
										readOnly
									/>
								</Row>
								<Row className={`m-0 mt-2 ${styles.feeNote}`}>
									{fee}% {rbFrequencyTitle} fee & {entryFee}% Entry fee
									is deducted from total investment value
								</Row>
								<Row style={{marginTop:'32px', marginLeft:'0px'}}>
									<div className={styles.enterPinText}>Enter PIN *</div>
								</Row>
								<Row className='m-0 mt-2 flex-column'>
									{/* <OtpInput
										value={spin}
                    onChange={spin => setSpin(spin)}
                    isInputSecure={isInputSecure}
                    onPaste={(e)=>{
                      e.preventDefault()
                      return false;
                    }}
                    onCopy={(e)=>{
                      e.preventDefault()
                      return false;}}
										numInputs={SPIN_LENGTH}
										inputStyle={styles.spinInput}
										separator={<span className={styles.pinPadding}
                    ></span>}
									/> */}
                   <PinInput
                    length={SPIN_LENGTH}
                    initialValue=""
                    secret
                    onChange={(value, index) => { reset(value) }}
                    onPaste={(e) => {
                      e.preventDefault()
                      return false;
                    }} onCopy={(e) => {
                      e.preventDefault()
                      return false;
                    }}
                    type="numeric"
                    inputMode="number"
                    inputStyle={{...inputStyle.spinInput }}
                    inputFocusStyle={{ background: '#EFEFF1' }}
                    onComplete={(value, index) => { setSpin(value) }}
                    autoSelect={true}
                    regexCriteria={/[0-9]/}
                    ref={myRef}
                  />
									{errormMessage !== '' ? (
										<span
											className={`${styles.feeNote} mt-2`}
											style={{ color: '#E51446' }}>
											{errormMessage}
										</span>
									) : (
										''
									)}
								</Row>

                 {/* Accodion for mobile screen */}
                <hr className='d-block d-md-none'></hr>
                <div className='d-block d-md-none overflow-auto'>
                   <Accordion>
                   <CustomToggle
                      eventKey='0'
                      srno='1'></CustomToggle>
                      <Accordion.Collapse eventKey='0'>
                        <>
                        { stockAndWeightsRenderComponent(!ETF_CAT)}
                        { stockAndWeightsRenderComponent(ETF_CAT)}
                          {/* <StocksWeightsTable
                            rowHeaders={headerStock}
                            data={stocks}
                          />
                          <StocksWeightsTable
                            rowHeaders={headerEtf}
                            data={etf}
                          /> */}
                          </>
                        </Accordion.Collapse>
                   </Accordion>
                </div>

                <div className={styles.confirmFixed}>
                <Row
                  className={`m-0 ${styles.termsConditions}`}
                  style={{ display: "inline-block"}}
                >
                  <span>By clicking "Confirm" you are agreeing to the stack </span>
								<span className={styles.terms}>
                  <a href={tos} style={{ color: "#005dd6"}} target="_blank" rel="noopener noreferrer">Terms & Conditions</a>
                </span>
								</Row>
								<Row className={`m-0 mt-3 ${styles.buttonRow}`}>
									<Button className={styles.buyButton} onClick={onPurchase}
											disabled={disableButton || orderLoading}
										> {orderLoading ? 'Loading...' : 'Confirm Purchase'}
									</Button>
								</Row>
                </div>
							</Card.Body>
						</Card>
					</Col>
          {/* <Row className={styles.footer}>
									<Col className={styles.footerStack} md={3}>
										{stockAllocation.length} stocks & {etfAllocation.length}{' '}
										ETF’s
									</Col>
									<Col
										className={styles.footerStack}
										style={{ textAlign: 'end' }} md={4}>
										your investment: ${investwithoutCommission}
									</Col>
					</Row> */}
				</Row>
			)}
		</>
	)
}


const CustomToggle = ({ eventKey, callback }) => {
	const currentEventKey = useContext(AccordionContext)

	const decoratedOnClick = useAccordionToggle(
		eventKey,
		() => callback && callback(eventKey)
	)

	const isCurrentEventKey = currentEventKey === eventKey

	return (
		<Row onClick={decoratedOnClick} className='m-0 mt-3'>
			<Col className='p-0' xs={8}>
				<div className={styles.stocksWeightMobile}>Stocks & Weights</div>
			</Col>
			<Col style={{textAlign:'end'}}>
				<img
					src={
						isCurrentEventKey
							? AngleDown
							: DropDownIcon
					}></img>
			</Col>
		</Row>
	)
}

export default ReviewOrder
