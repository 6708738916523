import React, {useContext,useEffect,useState} from 'react'
import ClickSvg from '../../../../public/images/easyfund/Click.svg'
import ClockSvg from '../../../../public/images/easyfund/Clock.svg'
import upiSvg from '../../../../public/images/easyfund/scan.svg'
import Lottie from 'react-lottie-player';
// import EasyFund from '../../../../public/images/EasyFund.png'
import EasyFundLottie from '../../../../public/lottie/fetchError.json'
import GenericErrorIcon from "../../../../public/images/genericError.svg"
import GenericLoading from "../../GenericLoading"
import styles from './easyfund.module.css'
import { AddFundContext } from "../../AddFundContext"
import {createConsent,getConsentStatus} from "../../../../api"

const SplashScreen = ({setMsg,setShowRemitance}) => {
  const {setSubPage, PAGE, SUBPAGE,setPage,setNoClose,easyfundTransDetail,selectedAccount,setNoHeader,setUploadStatement,transactionId,setShowCancel} = useContext(AddFundContext)
  const [uploadStatus,setUploadStatus] = useState('')
  const [errorStatus,setErrorStatus] = useState(false)
  const [bankId,setBankID] = useState('')
  const [consentUrl,setConsenturl] = useState('')
  const EASYFUND = PAGE.EASYFUND
  const renderArray = [
    {
      heading: 'Statement Period',
      subHeading: '12 Month statement from today.',
      img: ClockSvg
    },
    {
      heading: 'Stored Data Life',
      subHeading: 'We store your statements for 2 days.',
      img: ClickSvg
    },
    {
      heading: 'Auto-Fetch Purpose',
      subHeading: 'To validate fund transfers.',
      img: upiSvg
    }
  ]

  useEffect(() => {
    getConsent(easyfundTransDetail.stoUserBankId)
    setNoClose(true,setNoHeader(true))
  },[easyfundTransDetail])

  useEffect(() => {
    setNoClose(true,setNoHeader(true))
    if(uploadStatus === 'PENDING'){
      var summaryInterval = setInterval(() => {
        getConsent(bankId);
      }, 10000);
    }
    return () => {
      clearInterval(summaryInterval);
    }
  },[uploadStatus,bankId])

  const getConsent = async (bank) => {
    try{
      const resp = await getConsentStatus(bank)
      if(resp && resp.doc && resp.doc.length){
        const status = resp.doc[0]?.status
        if((status === 'PENDING' || status === 'REJECTED') && !bankId){
          setUploadStatus('show')
        }
        else if(status === 'EXPIRED') {
          setUploadStatus('EXPIRED')
        }else if(status === 'REJECTED'){
            setErrorStatus(true,setNoClose(true,setNoHeader(true),setUploadStatus(status)))
        }else if(status === 'ACTIVE'){
            setMsg('Your bank details have been linked successfully',setShowRemitance(false),
            setSubPage(SUBPAGE.SUCCESS),setNoClose(true,setNoHeader(true)))
            setUploadStatus(status)
        }
        // else if(status === 'PENDING' && !bankId){
        //   setUploadStatus('show')
        // }
      }else{
        setUploadStatus('show')
      }
     }catch(error){
       setErrorStatus(true)
     }
  }

  const onSubmit = async () => {
    setUploadStatus('PENDING')
    try{
     const resp = await createConsent({transactionId:transactionId,redirectUrl:'stockal.com'})
     if(resp && resp.doc && resp.doc.bankId)
     if(resp.doc.consentURL){
     setBankID(resp.doc.bankId,setConsenturl(resp.doc.consentURL))
    //  window.open(resp.doc.consentURL, "_blank")
     }else{
      setErrorStatus(true,setNoClose(true,setNoHeader(true),setUploadStatus('REJECTED')))
     }
    }catch(error){
      setErrorStatus(true)
    }
  }
  return (errorStatus ?
          <div className={styles.cancelContainer}>
          <div>
              <div className={styles.cancelHeader}>
                  <img src={GenericErrorIcon}/>
                  <span>Unable to find account</span>
              </div>
              <div className={styles.cancelBody}>
                  <span>We weren’t able to find any account associated with your mobile number.</span>
                  <div style={{paddingLeft:'30px',paddingRight:'30px'}}>
                    <button className={styles.activeButton} onClick={() => setUploadStatement(SUBPAGE[EASYFUND].UPLOADSTATEMENT,setSubPage(SUBPAGE[EASYFUND].UPLOADSTATEMENT))}>
                    <div className={styles.activateText} >Upload Manually</div>
                  </button>
                  </div>
              </div>
          </div>
      </div>
    :
    uploadStatus === 'PENDING' || uploadStatus === '' ?
    consentUrl !== '' ?
    <div>
    <iframe
    src={consentUrl
    }
    frameBorder='0'
    id=''
    style={{height: 'calc(100vh - 80px)',width:'100%',background: '#FFFFFF',
          boxShadow: '-2px 2px 13px rgba(0, 0, 0, 0.08)',borderRadius: '14px'}}
  /></div> :
    <div className={styles.genericLoading}><GenericLoading /> </div>
    :
    <div className={styles.container}>
       <Lottie
            rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
            loop={false}
            animationData={EasyFundLottie}
            play
            style={{ width: 200, height: 200 }}
            // onComplete={()=>onComplete && onComplete()}
        />
      <div className={styles.content}>
        <div style={{marginBottom:'10px'}}>
          <span style={{marginRight:'8px'}}>
           <img src={ selectedAccount && selectedAccount.bank && selectedAccount.bank.logo} className={styles.easyFundLogo}/>
          </span>
          <span className={styles.bankName}>
         {selectedAccount && selectedAccount.bankName} - {selectedAccount && selectedAccount.accountNumber.toString().slice(-4)}
          </span>
        </div>
        <div className={styles.seamHeading} style={{marginBottom:'8px'}}>
        Automatically fetch your bank statements
        </div>
        <div className={styles.splashItemSubHeading} style={{color:'#767C8F',marginBottom:'20px',textAlign:'center'}}>We’ll handle fetching the bank statements so you can focus on investing </div>

        <div className={styles.renderArray}>
          {renderArray.map(item => {
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <div style={{ marginRight: '17px' }}>
                  <img src={item?.img} alt='img-svg' />
                </div>
                <div>
                  <div className={styles.splashItemHeading}>
                    {item?.heading}
                  </div>
                  <div className={styles.splashItemSubHeading}>
                    {item?.subHeading}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <div className={styles.lowerSection} style={{marginTop:'30px'}}>
          <button className={styles.activeButton} disabled={true} onClick={() => onSubmit()}>
            <div className={styles.activateText} >Grant Access</div>
          </button>
          <button className={styles.secondaryBtn} onClick={() => setUploadStatement(SUBPAGE[EASYFUND].UPLOADSTATEMENT,setSubPage(SUBPAGE[EASYFUND].UPLOADSTATEMENT))}>
            <div className={styles.activateText} style={{color:'#0061D3'}}>Upload Manually</div>
          </button>
        </div>
      </div>
    </div>
  )
}

export default SplashScreen
