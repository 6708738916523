import React, { useState, useEffect, useRef, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SocketContext  } from '../../socket-context';
import queryString from 'query-string'
import styles from './Header.module.css'
import Modal from 'react-bootstrap/Modal';
import { SearchBar, Notification } from '../index'
import { Explore, InvestmentAccount, CashAccount, ProfileDropdown } from './HeaderDropdown'
import {addDays} from "date-fns";
import NavbarTab from './NavbarTab'
import searchIconGrey from '../../public/images/searchIconGrey.svg'
import cross from '../../public/images/closeIcon.svg'
import { getNotification, pendingOrdersData, getCashManagementData, getAllTransactions, addToCashManagementWaitlist, checkCashMgmtWaitlist } from '../../api'
import { Link, useLocation } from 'react-router-dom'
import navigationBarStockalLogo from '../../public/images/navigationBarStockalLogo.svg'
import BackArrow from '../../public/images/BackArrow.svg'
import Watchlist from '../WatchList/watchList'
import { watchListDataAPI, getAccountInfo, deleteWatchListTicker } from '../../api'
import { setCurrentPlan } from '../../common';
import angleDown from '../../public/images/AngleDown.png'
import angleUp from '../../public/images/down.png'
import { FormControl } from 'react-bootstrap'
import { getMarketDetails, getUserAccountSummary } from '../../store/actions/index'
import bellGrey from '../../public/images/bellGrey.svg'
import saveIcon from '../../public/images/saveIcon.svg'
import addFundsGrey from '../../public/images/addFundsGrey.svg'
import accountIcon from '../../public/images/accountIcon.svg'
import dollarIcon from '../../public/images/dollarIcon.svg'
import rewardsIcon from '../../public/images/rewardsIcon.svg'
import reportIcon from '../../public/images/reportIcon.svg'
import logoutIcon from '../../public/images/logoutIcon.svg'
import navigationIcon from '../../public/images/navigationIcon.svg'
import { useHistory } from 'react-router-dom'
import { logout } from '../../api'
import { getAllNotification } from '../../store/actions/index'
import { toPascalCase, dateFormatter } from '../../utils'
import { ThemeContext } from '../../Context/ThemeContext'
import { addCommasinPrice } from '../../utils'

const Header = (props) => {
  const socket = useContext(SocketContext);
  const [notification, setNotification] = useState(false)
  const [profile, setProfile] = useState(false)
  const [notificationData, setNotificationData] = useState([])
  const [firstName, setfirstName] = useState()
  const [lastName, setLastName] = useState()
  const [accountNumber, setAccountNumber] = useState()
  const [navToggle, setNavToggle] = useState(false)
  const [showProfileOption, setShowProfileOption] = useState(false)
  const [watchlistToggle, setWatchlistToggle] = useState(false)
  const [watchListdata, setWatchListData] = useState([])
  const [searchComponent, setSearchComponent] = useState(true)
  const [option] = useState(true)
  const { siteLogo } = useContext(ThemeContext)
  const [accountStatus, setAccountStatus] = useState()
  const [message, setMessages] = useState({})
  const [notificationBling, setNotificationBling] = useState(false);
  const [showMoreNotification, setShowMoreNotification] = useState(false)
  const [newNotificationArray, setNewNotificationArray] = useState([])
  const [pendingOrders, setPendingOrders] = useState()
  const [cashAccount, setCashAccount] = useState()
  const [cashAccountId, setCashAccountId] = useState()
  const [cashAccountApiError, setCashAccountApiError] = useState(false)
  const date = new Date();
  const [startDate, setStartDate] = useState(addDays(date, -30))
  const [endDate, setEndDate] = useState(date)
  const [transactions, setTransactions] = useState()
  const [showCashManagementFeature,setshowCashManagementFeature] = useState(false)
  const [cashAccountStatus, setCashAccountStatus] = useState()
  const [isSavingAccount, setIsSavingAccount] = useState()
  const [ addedToWaitlist, setAddedToWaitlist ] = useState()
  const [ isDisabled, setIsDisabled ] = useState(false)
  const [ username, setUsername] = useState()
  const [ userEmail, setUserEmail] = useState()

  const checkWaitlist = async() => {
    
    const data = {
      "source": "Cash Management",
      "email": userEmail,
      "status": "ACTIVE",
      "username": username
    }

    const response = await checkCashMgmtWaitlist(data)
    if(response && response.code && response.code === 200) {
      const existsInWaitlist = response.data && response.data.isUserAddedToWaitlist ? response.data.isUserAddedToWaitlist : false
      setAddedToWaitlist(existsInWaitlist)
    }
  }

  useEffect(()=>{
    if(userEmail && username) {
      checkWaitlist()
    }
  },[userEmail, username])

  const addToWaitlist = async() => {
    setIsDisabled(true)
 
    const data = {
      "source": "Cash Management",
      "email": userEmail,
      "status": "ACTIVE",
      "username": username
    }

    const response = await addToCashManagementWaitlist(data)
    if(response && response.code && response.code === 200) {
      setAddedToWaitlist(true)
    }
  }

  // const [showPlanModal, setShowPlanModal] = useState(false)
  // const handlePlanClose = () => setShowPlanModal(false)
  // const location = useLocation();
  const dispatchAction = useDispatch()
  const state = useSelector(state => state)
  const {
    userAccountSummary: {
      userSummary: { accountSummary = {}, portfolioSummary = {} } = {}
    } = {},
    AllNotification: { myNotification } = {}
  } = state

  const setUserDetailsAndPlanInfo = async() => {
    setCurrentPlan()    
    const userInfoResponse = await getAccountInfo();
    if(userInfoResponse) {
      sessionStorage.setItem('userInfo', JSON.stringify(userInfoResponse));
    }
  }

  useEffect(()=>{
    setUserDetailsAndPlanInfo()
  },[])

  useEffect(() => {
    if(myNotification && myNotification.length > 0){
      const blingArray = myNotification.filter(element => {
        return element.read === false
      })
      if(blingArray && blingArray.length > 0){
        setNotificationBling(true)
      } else if(blingArray.length === 0 || blingArray === undefined){
        setNotificationBling(false)
      }
    }
  },[myNotification])

  useEffect(() => {
    if(newNotificationArray && newNotificationArray.length >= 1){
      const blingArray = newNotificationArray.filter(element => {
        return element.read === false
      })
      if(blingArray && blingArray.length > 0){
        setNotificationBling(true)
      } else if(blingArray.length === 0 || blingArray === undefined){
        setNotificationBling(false)
      }
    }
  },[newNotificationArray])

  useEffect(() => {
    if(myNotification == undefined ) {
      dispatchAction(getAllNotification())
    }
  }, [])

  useEffect(() => {
    let accountSummaryInterval = null
    if (Object.keys(state.userAccountSummary)) {
      accountStatus === 2 ?
        accountSummaryInterval = setInterval(() => {
          dispatchAction(getUserAccountSummary())
        }, 5000) : dispatchAction(getUserAccountSummary())
    }
    return () => {
      if (accountSummaryInterval)
        clearInterval(accountSummaryInterval)
    }
  }, [accountStatus])



  useEffect(() => {
    let marketInterval = null
    marketInterval = setInterval(() => {
      dispatchAction(getMarketDetails())
    }, 10000)
    return () => {
      if (marketInterval)
        clearInterval(marketInterval)
    }
  }, [])



  let history = useHistory()
  const ssoShown = sessionStorage.getItem('Sso_flag') ? sessionStorage.getItem('Sso_flag') : false
  let logoutpage = sessionStorage.getItem('logoutPage') ? sessionStorage.getItem('logoutPage') : ''
  const userInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : {}
  const savingAccountStatus = userInfo.savingAccountStatus ? userInfo.savingAccountStatus : ''
  const isSavingAccountTrue = userInfo.isSavingAccount ? userInfo.isSavingAccount : ''

  useEffect(()=>{
    setCashAccountStatus(savingAccountStatus)
  },[savingAccountStatus])

  useEffect(()=>{
    setIsSavingAccount(isSavingAccountTrue)
  },[isSavingAccountTrue])

  const [scopeUser, setScopeUser] = useState()

  const accountDetails = () => {
    getAccountInfo().then(response => {
        if(response && response.scope){
          setScopeUser(response.scope)
        }
    }).catch((err)=>{
        console.error(err)
    })
  }

  useEffect(() => {
    accountDetails()
  },[])

  useEffect(() => {
    if(scopeUser && scopeUser.length && scopeUser.includes('cash-mgmt')){
    setshowCashManagementFeature(true)
    }else {
    setshowCashManagementFeature(true)
    }
  },[scopeUser])

  const logoutPageCal = () => {
    if(logoutpage){
      window.location.href=logoutpage
    }else{
      window.location.href='/logout'
    }
  }

  const onClickLogout = () => {
    var token = JSON.parse(sessionStorage.getItem('token'))
    //webengage logout
    // eslint-disable-next-line no-undef
    webengage.user.logout()
    // delete token.refreshToken
    var body = {
      accessToken: token && token.accessToken
    }
    // var accessToken = JSON.stringify(token)
    logout(JSON.stringify(body)).then(response => {
      sessionStorage.clear()
      localStorage.clear()
      if(ssoShown){
        logoutPageCal()
      }
      else{
        history.push('/')
      }
    })
  }

  const onCloseApp = () => {
    if(localStorage.getItem('fromStockalApp') === 'Y' || localStorage.getItem('fromStockalApp') === 'y'){
      logoutPageCal()
      window.closeSite.postMessage('close')
    }
    else{
      onClickLogout()
    }
  }


  function closeNotification(value) {
    value === 'notification' &&
    setNotification(prevState => {
      setNotification(!prevState, setProfile(false))
      if (notification === false) {
        getNotification(1).then(response => {
          if (response && response.data && response.data.code === 200) {
            setNotificationData(prevState => {
              setNotificationData(prevState, [response.data.data])
            })
          } else {
            console.log('no data found')
          }
        })
      }
    })
    value === 'arrow' &&
      setProfile(prevState => {
      setProfile(!prevState)
      setNotification(false)
    })
    value === 'close' && setNotification(false,setProfile(false))
  }

  const userNameFetch = async () => {
    if (firstName !== undefined && firstName === '') {
      setfirstName(
        sessionStorage.getItem('userInfo')
          ? JSON.parse(sessionStorage.getItem('userInfo'))
          ? JSON.parse(sessionStorage.getItem('userInfo')).firstName
          : ''
          : ''
      )
      setLastName(
        sessionStorage.getItem('userInfo')
          ? JSON.parse(sessionStorage.getItem('userInfo'))
          ? JSON.parse(sessionStorage.getItem('userInfo')).lastName
          : ''
          : ''
      )
      setUsername(
        sessionStorage.getItem('userInfo')
          ? JSON.parse(sessionStorage.getItem('userInfo'))
          ? JSON.parse(sessionStorage.getItem('userInfo')).username
          : ''
          : ''
      )
      setUserEmail(
        sessionStorage.getItem('userInfo')
          ? JSON.parse(sessionStorage.getItem('userInfo'))
          ? JSON.parse(sessionStorage.getItem('userInfo')).emailAddress1
          : ''
          : ''
      )
      setAccountNumber(
        sessionStorage.getItem('userInfo')
          ? JSON.parse(sessionStorage.getItem('userInfo'))
          ? JSON.parse(sessionStorage.getItem('userInfo')).accountNo
          : ''
          : ''
      )
    } else {
      const res = await getAccountInfo()
      if (res && res.firstName) {
        setAccountStatus(res.accountStatus)
        setCashAccountStatus(res.savingAccountStatus)
        setIsSavingAccount(res.isSavingAccount)
        setfirstName(res.firstName)
        setLastName(res.lastName)
        setAccountNumber(res.accountNo)
        setUsername(res.username)
        setUserEmail(res.emailAddress1)
      } else {
        console.log('not getting data')
        // setfirstName('--')
      }
    }
  }
  useEffect(() => {
    userNameFetch()
  }, [])

  const asynchFetch = async () => {
    const res = await watchListDataAPI()
    setWatchListData(res)
  }


  useEffect(() => {
    asynchFetch()
  }, [])


  const mobileModalClose = () => {
    setSearchComponent(true)
    setNavToggle(false)
  }

  const removeFromWatchList = async (e) => {
    const res = await deleteWatchListTicker(e)
    if (res.code === 200) {
      asynchFetch()
    } else {
      console.log('something went wrong')
    }
  }

  const openNotification = () => {
    if(!notification){
      setNotification(true)
    }
  }

  const getPendingOrders = () => {
    pendingOrdersData().then(response => {
        if(response.code === 200){
            setPendingOrders(response.data.pendingOrder.length)
        }
    }).catch((err)=> {
        console.log(err)
        setPendingOrders('--')
    })
  }

  const cashAccountDetails = () => {
    getCashManagementData().then(response=>{
      if(response.code && response.code === 200){
        setCashAccount(response.data.balanceAmt)
        setCashAccountId(response.data.stoCashAccountId)
        setCashAccountApiError(false)
      } else {
        setCashAccountApiError(true)
      }
      }).catch((err)=>{
        console.error(err)
        setCashAccountApiError(true)
      })
  }

    const getTransactions = () => {
      const start_date = dateFormatter(startDate)
      const end_date = dateFormatter(endDate)
      getAllTransactions(start_date,end_date,cashAccountId).then(response=>{
        if(response.code === 200){
            setTransactions(response.data.total)
            setCashAccountApiError(false)
          }
        }).catch((err)=>{
          console.error(err)
          setCashAccountApiError(true)
      })
  }

  useEffect(()=>{
    if(cashAccountId){
      getTransactions()
    }
  },[cashAccountId])

  useEffect(() => {
    const transactionListener = () => {
       cashAccountDetails()
       if(cashAccountId ) {
         getTransactions()
       }
    };

    if(socket) {
      socket.on("data_refresh",transactionListener)
    }

    return () => {
      if(socket){
          socket.off("data_refresh", transactionListener)
      }
    }
  }, [socket, cashAccountId])

  useEffect(() => {
    const statusListener = (value) => {
      setCashAccountStatus(value.savingAccountStatus)
      cashAccountDetails()
      if(cashAccountId) {
        getTransactions()
      }
    };

    if(socket) {
      socket.on("savings-status", statusListener)
    }

    return () => {
      if(socket){
          socket.off("savings-status", statusListener)
      }
    }
  }, [socket, cashAccountId])

  useEffect(()=>{
    getPendingOrders()
    if(savingAccountStatus === 2){
      cashAccountDetails()
    }
  },[])

  useEffect(()=>{
    const queryUrl = queryString.parse(window.location.search, {
      parseNumbers: false
    })
    if(queryUrl.menu) {
      setNavToggle(true)
    }
  },[])

  return (
    <>
      {/* Desktop Navigation */}
      <div className={styles.bigscreenHeader}>
        <div className={styles.navigationBar}>
          <div className={styles.logoSearchContainer}>
              {siteLogo ?
              <div onClick={() => history.push('/home')} style={{cursor:"pointer"}}>
                <img src={siteLogo} height="28px"/>
              </div> : 
              <div style={{height: "28px"}}>
              </div>
              }
              <div>
                <SearchBar/>
              </div>
          </div>
          <div className={styles.navigationItemsContainer}>
            <div>
              <Explore/>
            </div>
            <div>
              <InvestmentAccount 
              portfolioSummary={portfolioSummary}
              pendingOrders={pendingOrders} 
              userInfo={userInfo}
              accountStatus={accountStatus}/>
            </div>
            {showCashManagementFeature ?
            <div>
              <CashAccount 
                cashAccount={cashAccount} 
                transactions={transactions} 
                CashAccountApiError={cashAccountApiError} 
                accountStatus={cashAccountStatus} 
                accountApproved={accountStatus} 
                isSavingAccount={isSavingAccount} 
                openTranferFundsHeader={props.openTranferFundsHeader}
                addedToWaitlist={addedToWaitlist}
                isDisabled={isDisabled}
                addToWaitlist={addToWaitlist}
              />
            </div>
            : ""}
            <div className={styles.horizontalDivider}></div>
            <div>
              <Notification
                closeNotification={closeNotification}
                myNotification={myNotification}
                setNotificationBling= {setNotificationBling}
                notificationBling={notificationBling}
                setShowMoreNotification={setShowMoreNotification}
                showMoreNotification={showMoreNotification}
                setNewNotificationArray={setNewNotificationArray}
              />
            </div>
            <div>
              <ProfileDropdown firstName={firstName} lastName={lastName} accountNumber={accountNumber}/>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Navigation */}

      <div className={`${styles.mobileNavigation}`}>
        <div className={styles.mobileNavigationBar}>
          <div onClick={() => history.push('/home')} style={{cursor:"pointer",maxWidth:"90px"}}>
            {siteLogo ? 
            <img
                src={siteLogo}
                height="24px"
              ></img> : 
              <div style={{height: "24px"}}>
              </div>}
          </div>
          <div className={styles.tabletSearchBar}>
            <SearchBar />
          </div>
          <div className={`${styles.watchListStar}`}>
            <img
              alt='watchlist-icon'
              onClick={() => setWatchlistToggle(true)}
              data-testid='img-div'
              src={saveIcon}
            />
          </div>
          <div className={`${styles.bellIconMobile}`}>
            <span className={`${notificationBling  ? styles.bellIconCircle : ""}`}></span>
            <img
              alt='bell-icon'
              type='button'
              onClick={() => closeNotification('notification')}
              data-testid='img-div'
              src={bellGrey}
            />
          </div>
          <div className={styles.navbarButton}>
            <img
              alt='navbar-icon'
              onClick={() => setNavToggle(true)}
              data-testid='img-div'
              src={navigationIcon}
            />
          </div>
          {/* mobile watchlist */}
          {watchlistToggle ? (
            <div className={styles.ResponsiveModalContainer}>
              <div>
                <Watchlist
                  // active={active}
                  BackArrow={BackArrow}
                  option={option}
                  data={watchListdata}
                  setWatchlistToggle={setWatchlistToggle}
                  callWatchlist={asynchFetch}
                  removeTicker={removeFromWatchList}
                  // closeSideBar={closeSideBar}
                  // watchlist={watchlist}
                  // collection={collection}
                />
              </div>
            </div>
          ) : (
            ''
          )}
          {/* mobile Notification */}
          {notification ? (
              <div className={styles.ResponsiveModalContainer}>
                <Notification
                  closeNotification={closeNotification}
                  myNotification={myNotification}
                  setNotificationBling= {setNotificationBling}
                  notificationBling={notificationBling}
                  setShowMoreNotification={setShowMoreNotification}
                  showMoreNotification={showMoreNotification}
                  setNewNotificationArray={setNewNotificationArray}
                />
              </div>
          ) : (
            ''
          )}

          <div>
            <Modal show={navToggle} onHide={mobileModalClose} className={`${styles.navModal} d-lg-none`}>
              <div className={styles.mobileNavbar}>
                <div onClick={mobileModalClose} style={{display:"flex",justifyContent:"flex-end"}}>
                  <img src={cross} alt='nav-close'/>
                </div>
                {searchComponent ? (
                  <>
                  <div style={{marginTop:"30px"}} className={styles.modalSearchBar}>
                    <span className={styles.searchIcon}>
                      <img src={searchIconGrey} alt='search-icon'></img>
                    </span>
                    <FormControl
                      className={styles.searchInput}
                      placeholder='Search Stocks, ETF, Stacks...'
                      aria-describedby='basic-addon1'
                      onFocus={() => setSearchComponent(false)}
                    />
                  </div>
                  <div style={{marginTop:"30px"}} className={styles.profileMenu}>
                    <div className={styles.navDropdownHeader} onClick={() => setShowProfileOption(!showProfileOption)}>
                      <div className={styles.profileData}>
                          <p style={{fontSize:"16px",lineHeight:"24px",color:"#3C404A"}}>{firstName && lastName ? `${toPascalCase(firstName)} ${toPascalCase(lastName)}` : '--'}</p>
                          <p style={{fontSize:"14px",lineHeight:"20px",color:"#767C8F",fontFamily: 'geomanistRegular'}}>{accountNumber ? accountNumber : '--'}</p>
                      </div>
                      {showProfileOption ? (
                        <span>
                          <img src={angleDown} alt='arrow'></img>
                        </span>
                      ) : (
                        <span>
                          <img src={angleUp} alt='arrow'></img>
                        </span>
                      )}
                    </div>
                    <div  className={styles.profileOptionsDropdown}>
                      {showProfileOption && (
                        <div>

                          <div className={styles.resultDivider}></div>

                          <div className={styles.dropdownRow} style={{paddingLeft:"19px"}} onClick={() => history.push('/funds')}>
                            <img src={addFundsGrey} alt='icon' style={{marginRight:"11px"}} width="12px"/>
                            <p>Add Funds</p>
                          </div>

                          <div className={styles.dropdownRow} style={{paddingLeft:"18.25px"}} onClick={() => history.push('/profile')}>
                            <img src={accountIcon} alt='icon' style={{marginRight:"10.25px"}} width="13.5px"/>
                            <p>Account Settings</p>
                          </div>

                          <div className={styles.dropdownRow} style={{paddingLeft:"16.75px"}} onClick={() => history.push('/remittance')}>
                            <img src={dollarIcon} alt='icon' style={{marginRight:"8.75px"}} width="16.5px"/>
                            <p>Remittance History</p>
                          </div>

                          <div className={styles.dropdownRow} style={{paddingLeft:"18.25px"}} onClick={() => history.push('/reports')}>
                            <img src={reportIcon} alt='icon' style={{marginRight:"10.25px"}} width="13.5px"/>
                            <p>Reports & Statements</p>
                          </div>

                          {ssoShown ? (
                            <div className={styles.dropdownRow} style={{paddingLeft:"17.5px"}} onClick={onCloseApp}>
                              <img src={logoutIcon} alt='icon' style={{marginRight:"9.5px"}} width="15px"/>
                              <p style={{color:"#FA274A"}}>Close</p>
                            </div>
                          ) : (
                            <div className={styles.dropdownRow} style={{paddingLeft:"17.5px"}} onClick={onClickLogout}>
                              <img src={logoutIcon} alt='icon' style={{marginRight:"9.5px"}} width="15px"/>
                              <p style={{color:"#FA274A"}}>Logout</p>
                            </div>
                          )}

                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <NavbarTab
                      showCashManagementFeature={showCashManagementFeature}
                      portfolioSummary={portfolioSummary}
                      pendingOrders={pendingOrders}
                      accountStatus={accountStatus}
                      cashAccount={cashAccount}
                      transactions={transactions}
                      savingAccountStatus={cashAccountStatus}
                      closeModal={mobileModalClose}
                      isSavingAccount={isSavingAccount}
                      addedToWaitlist={addedToWaitlist}
                      isDisabled={isDisabled}
                      addToWaitlist={addToWaitlist}
                    />
                  </div>
                </>
                ) : (
                  <div style={{marginTop:"30px"}} className={styles.modalSearchBar}>
                    <SearchBar/>
                  </div>
                )}
              </div>
              <style jsx>{`
                  @media(min-width:992px) {
                      .fade.modal-backdrop {
                        display:none;
                    }
                  }
              `}</style>
            </Modal>
          </div>
        </div>
      </div>

    </>
  )
}


function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
        handler(event)
      }
      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    [ref, handler]
  )
}

export default Header
