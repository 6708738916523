import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Col, Row, } from 'react-bootstrap'
import { debounce, values, isEmpty } from 'lodash';
import {add,subtract,multiply,divide} from 'js-big-decimal';
import styles from './buyCard.module.css'
// import SellCard from '../SellCard/sellCard'
import backImage from '../../public/images/backBuy.svg'
import alertIcon from '../../public/images/alertIcon.svg'
import { ReviewOrderCard, ExtendedDisclosureModal,ExtendedCard } from '../../components'
import { getStockCommission, getAccountSummaryWithCommission } from '../../api'
import { decimalAdjust,addCommasinPrice } from '../../utils'
import { getMarketDetails } from '../../store/actions/index'
import { Link } from 'react-router-dom';
import {MARKET_TOUCHED} from '../../constants'
import { STACK_DELISTED_MSG } from '../../displayMessages';
import { getPANDetails } from '../../api';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import infoIcon from '../../public/images/greyInfoIcon.svg'
import Tooltip from 'react-bootstrap/Tooltip'
import Rating from 'react-rating'
import shariyaStarIconGrey from '../../public/images/shariyaStarIconGrey.svg'
import shariyaStarIcon from '../../public/images/shariyaStarIcon.svg'



const BuyCard = ({ stockName, livePrices, handleClose, stockData, shareData, marketState,sellCheck,showExtended, isOtc, disableBuyButton, disableSellButton, isBerkshire }) => {

  const investmentTypes = { BUY: { 1: 'Invest in shares', 2: 'Invest in dollars' }, SELL: { 1: 'Number of shares', 2: 'Sell in dollars' } };
  const [side, setSide] = useState('BUY');
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [noteMessage, setNoteMessage] = useState('');
  const [planMsg, setPlanMsg] = useState('');
  const [warningMessage, setWarningMessage] = useState('');
  const [orderType, setOrderType] = useState('Market');
  const [openDropdown, setOpenDropdown] = useState(false);
  // const [investmentType, setInvestmentType] = useState(Object.keys(investmentTypes));
  const [investTypeSelect, setInvestTypeSelect] = useState(1); //current investment type
  const [openInvestDropDown, setOpenInvestDropDown] = useState(false);
  // const [buyingPower, setBuyingPower] = useState(null);
  const [investingValue, setInvestingValue] = useState(null);
  const [commission, setCommission] = useState(null);   // calculated commission
  const [stockPrice, setStockPrice] = useState(null);   // current live price
  const [totalCost, setTotalCost] = useState(0.00);     // trade amount
  const [quantity, setQuantity] = useState(null);       // trade quantity
  const [orderValue, setOrderValue] = useState(null);   // final order amount = cost + commission
  const [price, setPrice] = useState(null);             // target price for type limit/stop
  const [accountSummary, setAccountSummary] = useState({})
  const [review, setReview] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [reviewData, setReviewData] = useState({})
  const [isSellAll, setIsSellAll] = useState(false)
  const [isAccountActive, setIsAccountActive] = useState(JSON.parse(sessionStorage.getItem('userInfo')).accountStatus === 2)
  const [isLiquidated, setIsLiquidated] = useState(false)
  // const [isPaymentActive, setIsPaymentActive] = useState(JSON.parse(sessionStorage.getItem('planInfo')) ? JSON.parse(sessionStorage.getItem('planInfo')).planStatus === 'active' : false)
  const [ask,setAsk] = useState('')
  const [bid,setBid] = useState('')

  const [showExtendedToggel,setShowExtendedToggel] = useState(false)
  const [showDisclouser,setShowDisclouser] = useState(false)
  const [showPriceWarning,setShowPriceWarning] = useState('')
  const dispatchAction = useDispatch()
	const state = useSelector(state => state)
	const [isTickerCommAvailable,SetIsTickerCommAvailable] = useState(false)
  const {
		marketDetails: { marketDetails: { currentMarketStatus = {} } = {} } = {}
	} = state

	Object.keys(state.marketDetails).length === 0 &&
		dispatchAction(getMarketDetails())

  useEffect(() => {
    setReview(false)
    setIsSellAll(false)
    setIsDisabled(true)
    setOpenInvestDropDown(false)
    setShowError(false)
    setOrderType('Market')
    setSide('BUY')
    setShowExtendedToggel(false)
    setShowDisclouser(false)
    setPrice(null)
    resetFields()
  },[stockName])

  useEffect(()=> {
    if(side == "BUY" && isBerkshire){
      setInvestTypeSelect(1)
    }
  },[side,isBerkshire])

  const fetchPANDetails = async () => {
    const response = await getPANDetails();
    if (response && response.code === 200 && response.data) {
      setIsLiquidated(response.data.liquidateOnly)
    }
    else {
      console.log('something went wrong')
    }
  }

  useEffect(() => {
      fetchPANDetails();
    getIfTickerCommission('BUY')
  },[stockName])

  // update account summary data
  useEffect(() => {
    const fetchAccountSummary = async () => {
      try {
        let { accountSummary, currentCashAvailForTrade, unsettledAmount } = await getAccountSummaryWithCommission();
        setAccountSummary({
          buyingPower: currentCashAvailForTrade,
          unsettledAmount,
          restricted: accountSummary.restricted,
          cashAvailableForTrade: accountSummary.cashAvailableForTrade
        });
      } catch (err) {
        console.log("error fetching live prices");
      }
    }
    fetchAccountSummary();
    if (marketState === 'Open') {
      var summaryInterval = setInterval(() => {
        fetchAccountSummary();
      }, 10000);
    }
    return () => {
      clearInterval(summaryInterval);
    }

  }, [marketState])

  const buy = () => {
    setSide('BUY');
    getIfTickerCommission('BUY')
    resetFields()
  }
  const sell = () => {
    setSide('SELL');
    getIfTickerCommission('SELL')
    setShowError(false);
    resetFields()
  }


  // for sell key available in url query param
  useEffect(() => {
    window.location.href.includes('?sell') || sellCheck ? setSide('SELL', setShowError(false)) : setSide('BUY')
    if( window.location.href.includes('?sell') || sellCheck) {
      getIfTickerCommission('SELL')
    }
  }, [stockName])

  // for extended hours
  useEffect(() => {
   if(showExtended || showExtendedToggel){
     setOrderType('Limit',setQuantity(null))
   }else{
    setOrderType('Market',setQuantity(null))
   }
  },[showExtended,showExtendedToggel])

  const dropDownMenu = () => {
    setOpenDropdown(!openDropdown);
    setOpenInvestDropDown(false)
  }

  const handleChange = prop => {
    setOrderType(prop);
    setOpenDropdown(!openDropdown);
    // if (prop === 'stop order') {
    //   setErrorMessage('As per norms stop price to be atleast 0.05 cents higher than current trading price.');
    //   setShowError(true);
    // } else {
    //   setShowError(false);
    // }
    resetFields()
  }

  const investDropDown = () => {
    setOpenInvestDropDown(!openInvestDropDown);
    openDropdown && setOpenDropdown(!openDropdown)
  }


  //reset all filed data
  const resetFields = () => {
    setQuantity(null);
    setTotalCost(null);
    setOrderValue(null);
    setInvestingValue(null);
    setCommission(null);
    setOpenInvestDropDown(false);
    setIsDisabled(true);
    setWarningMessage('')
  }


  const investHandleChange = (value) => {
    if (parseInt(value) !== investTypeSelect) {
      resetFields()
    }
    setInvestTypeSelect(parseInt(value));
    setOpenInvestDropDown(!openInvestDropDown);

  }

  //on input cahnge calculate total cost,qantity etc
  const valueChange = (value, inputType) => {
    var rgx = /^[0-9]*\.?[0-9]*$/;
    if(value.toString().match(rgx) && value !== '.'){
    setIsSellAll(false)
    if (orderType === 'Market') {
      if (investTypeSelect === 1) {
        if (getPrecision(value) > 8) value = parseFloat(value).toFixed(8)
        setInvestingValue(value)
      } else if (investTypeSelect === 2) {
        if (getPrecision(value) > 2) value = parseFloat(value).toFixed(2)
        setInvestingValue(value)
      }
      if (side === 'BUY') {
        if (investTypeSelect === 1) {
          // if (value * stockPrice > buyingPower) {
          //   setErrorMessage('oops! you do not have sufficient funds to execute the order.');
          //   setShowError(true);
          // } else {
          //   setShowError(false);
          // }
          let amount = multiply(value, stockPrice)
          let quantity = value
          setValues(amount, quantity)
        } else if (investTypeSelect === 2) {
          // if (value > buyingPower) {
          //   setErrorMessage('oops! you do not have sufficient funds to execute the order.');
          //   setShowError(true);
          // } else {
          //   setShowError(false);
          // }
          let amount = value
          let quantity = divide(value , stockPrice)
          setValues(amount, quantity)
        }
      } else {
        if (investTypeSelect === 1) {
          // if (value * stockPrice > buyingPower) {
          //   setErrorMessage('oops! you do not have sufficient funds to execute the order.');
          //   setShowError(true);
          // } else {
          //   setShowError(false);
          // }
          let amount = multiply(value , stockPrice)
          let quantity = value
          setValues(amount, quantity)
        } else if (investTypeSelect === 2) {
          // if (value > buyingPower) {
          //   setErrorMessage('oops! you do not have sufficient funds to execute the order.');
          //   setShowError(true);
          // } else {
          //   setShowError(false);
          // }
          let amount = value
          let quantity = divide(value , stockPrice)
          setValues(amount, quantity)
        }
      }
    }
    else {
      let p, q = 0
      if (inputType === 'quanity') {
        if (getPrecision(value) > 8) value = parseFloat(value).toFixed(8)
        setQuantity(value)
        q = value
        p = price
      }
      else if (inputType === 'price') {
        if (getPrecision(value) > 2) value = parseFloat(value).toFixed(2)
        setPrice(value)
        p = value
        q = quantity
      }
      if (p && q) {
        if (side === 'BUY') {
          if (multiply(p , q) > accountSummary.buyingPower) {
            setErrorMessage('oops! you do not have sufficient funds to execute the order.');
            setShowError(true);
          } else {
            setShowError(false);
          }
        }
        else {

        }
        setValues(multiply(p , q), q)
      }
    }
  }
  }

  // useEffect(() => {
  //   if (side === 'BUY') {
  //     if (orderType === 'Market') { }
  //     else {
  //       if (price * quantity > accountSummary.buyingPower) {
  //         setErrorMessage('you do not have sufficient funds to execute the order.');
  //         setShowError(true);
  //       } else {
  //         setShowError(false);
  //       }
  //     }
  //   }
  //   else {
  //     // if (price * quantity > accountSummary.buyingPower) {
  //     //   setErrorMessage('you do not have sufficient funds to execute the order.');
  //     //   setShowError(true);
  //     // } else {
  //     //   setShowError(false);
  //     // }
  //   }
  //   price && quantity && setValues(price * quantity, quantity)
  // }, [price, quantity])

  //set total cost, quantity & get commission

  const setValues = async (amount, quantity) => {
    if (getPrecision(amount) > 2) amount = decimalAdjust('ceil',amount,-2)
    setTotalCost(amount)
    if (getPrecision(quantity) > 8) quantity = decimalAdjust('floor',quantity,-8)
    setQuantity(parseFloat(quantity))
    getCommission(amount, quantity, side, orderType)
  }

  //get commission & set order value
  const getCommission = useCallback(
    debounce(async (amount, quantity, side, orderType) => {
      let comm = await getStockCommission(amount, quantity, orderType,side, stockName)
      setCommission(comm)
      if (side === 'BUY')
        setOrderValue(decimalAdjust('floor', add(parseFloat(amount) , comm), -2))
      else
        setOrderValue(decimalAdjust('floor', subtract(parseFloat(amount) , comm), -2))
    }, 200),
    [stockName]
  );

  //for disabling the trade button depending on conditions
  useEffect(() => {
    let isPaymentActive = false
    let isDelisted = false;
    if(stockData && !isEmpty(stockData)) {
      if(stockData.sellOnly) {
        isDelisted = true;
        let msg = (<sapn>{STACK_DELISTED_MSG}</sapn>)
        setPlanMsg(msg)
      }
    }
    if (JSON.parse(sessionStorage.getItem('planInfo')) && JSON.parse(sessionStorage.getItem('planInfo')).planStatus) {
      let { planStatus, details} = JSON.parse(sessionStorage.getItem('planInfo'))
      isPaymentActive = planStatus === 'active'
      if (planStatus === 'expire') {
        let msg = (<sapn>Your plan has expired. Please <Link to="/profile"><u>renew</u></Link></sapn>)
        setPlanMsg(msg)
      }
    } else if (JSON.parse(sessionStorage.getItem('userInfo')) && Object.keys(JSON.parse(sessionStorage.getItem('userInfo'))).length > 0) {
      let { nextStep } = JSON.parse(sessionStorage.getItem('userInfo'))
      if (nextStep === 'COMPLETE') {
        let msg = (<sapn>Please subscribe to the plan to continue. <Link to="/profile"><u>Subscribe now</u></Link></sapn>)
        setPlanMsg(msg)
      }
    }

    if (side === 'SELL') {
      if (disableSellButton || showError || !isAccountActive || !totalCost || !quantity || (orderType === 'Market' && (marketState === 'Close' || marketState === 'Pre-Market' || marketState === 'Post-Market'))) {
        setIsDisabled(true)
      } else {
        setIsDisabled(false)
      }
    } else if (disableBuyButton || showError || !isAccountActive || !isPaymentActive || !totalCost || !quantity || (orderType === 'Market' && (marketState === 'Close' || marketState === 'Pre-Market' || marketState === 'Post-Market')) || isDelisted || isLiquidated) {
      setIsDisabled(true)
    }
    else {
      setIsDisabled(false)
    }
  }, [totalCost, showError, isAccountActive, orderType, marketState, quantity, stockData, isLiquidated])

  //for updating live price
  useEffect(() => {
    if (livePrices && Object.keys(livePrices).length) {
      const { lastTrade = '',ask='',bid='',close='' } = livePrices
      const value = currentMarketStatus === 'Close' ? close : lastTrade
      setStockPrice(value);
      setAsk(ask)
      setBid(bid)
      if(!price && livePrices.symbol===stockName){
        setPrice(decimalAdjust('floor', lastTrade, -2))
      }
    }
  }, [livePrices,currentMarketStatus])

  //Show order review screen
  const showReview = () => {
    setReview(true)
    setReviewData({
      orderType,
      quantity,
      totalCost,
      commission,
      orderValue,
      side,
      investTypeSelect,
      price,
      extendedHour: showExtended || showExtendedToggel,
      isOtc
    })
  }

  // for sell all START
  const sellAll = async () => {
    setInvestTypeSelect(1)
    setIsSellAll(true)
  }
  useEffect(() => {
    if (isSellAll && shareData && shareData.totalUnit && shareData.totalUnit > 0) {
      valueChange(shareData.totalUnit, 'quanity')
    }
  }, [isSellAll]);
  // for sell all END

  function toSentenceCase(theString) {
    if(typeof theString !== 'object'){
      var newString = theString.toLowerCase().replace(/(^\s*\w|[\.\!\?]\s*\w)/g,function(c){return c.toUpperCase()});
      return newString;
    }
    else return theString
  }



  const typeDropdown = () => {
    return openDropdown ? (
      <div className={styles.dropdownMenu}>
        <ul className={styles.dropUl}>
          <li onClick={() => handleChange('Market')}>
            <h6 className={styles.dropHead}>Market order</h6>
            <p className={styles.subhead}>
              A market order is a type of stock<br></br>order that
              executes at the best <br></br> available price on the
              market
            </p>
          </li>
          <hr />
          <li onClick={() => handleChange('Limit')}>
            <h6 className={styles.dropHead}>Limit order</h6>
            {isOtc && isOtc === true ?
              <p className={styles.subhead}>
              With a buy limit order, a stock is purchased at your limit price or lower. Your limit price should be
              the maximum price you want to pay per share. For OTC securities,<br/> it will expire after 1 day if not executed.
              </p> :
              <p className={styles.subhead}>
                With a buy limit order, a stock is <br></br> purchased
                at your limit price or<br></br> lower. Your limit
                price should be <br></br> the maximum price you want
                to pay per share. Will expire after 90 days if not executed.
              </p>
            }
          </li>
          {isOtc && isOtc === true ? '' : <>
            <hr />
            <li onClick={() => handleChange(MARKET_TOUCHED)}>
              <h6 className={styles.dropHead}>Market if Touched</h6>
              <p className={styles.subhead}>
              Alternative to limit orders when fractional orders are desired with lack of guarantee of price and triggered at a specific price
              </p>
            </li>
          </>}
          <hr/>
          <li onClick={() => handleChange('Stop')}>
            <h6 className={styles.dropHead}>Stop order</h6>
            {isOtc && isOtc === true ?
              <p className={styles.subhead}>
                A stop order is an order triggered to buy or sell a stock once the price of the stock reaches a specific price, known as the stop price. For OTC securities, it will expire after 1 day if not executed.
              </p> :
              <p className={styles.subhead}>
                A stop order is an order triggered to buy or
                sell a stock once the price of the
                stock reaches a specific price,
                known as the stop price. Will expire after 90 days if not executed.
              </p>
            }
          </li>
        </ul>
      </div>
    ) : (
      ''
    )
  }

  const buttonClass = () => {
    let classname = ""
    if (side === 'BUY')
      classname += `${styles.reviewButton}`
    else classname += `${styles.sellReviewButton}`
    if (isDisabled)
      classname += ` ${styles.disable}`
    return classname
  }

  //for showing message - warning|note|error
  useEffect(() => {
    let { restricted, buyingPower, unsettledAmount } = accountSummary
    if (orderType === 'Market') {
      if (marketState === 'Close' || marketState === 'Pre-Market' || marketState === 'Post-Market') setNoteMessage('Note : Market order not allowed in non market hours.')
    } else if (orderType === 'Limit') {
      setNoteMessage('Fractional shares trading is not allowed in limit orders')
    }
    else {
      setNoteMessage('')
      setShowError(false);
    }
    if (side === 'BUY') {
      if (totalCost > buyingPower) {
        setErrorMessage('oops! you do not have sufficient funds to execute the order.');
        setShowError(true);
      }
      else {
        setErrorMessage('');
        setShowError(false);
      }
      let currentBuyingPower = parseFloat(subtract(buyingPower , unsettledAmount))
      if (!restricted && (parseFloat(totalCost) > currentBuyingPower) && unsettledAmount > 0) {
        setWarningMessage('Warning! You are using unsettled funds to make the transaction. This could result in violation if sold before settlement')
      }
      else {
        setWarningMessage('')
      }
    }
    if (side === 'SELL' && shareData) {
      let remQuantity = parseFloat(subtract((shareData.totalUnit || 0) , (shareData.sWeightedQuantity || 0)))
      if (parseFloat(quantity) > remQuantity) {
        setErrorMessage(`Unfortunately your order quantity exceeds your existing holdings. Existing quantity : ${remQuantity}`);
        setShowError(true);
      }
      else {
        setErrorMessage('');
        setShowError(false);
      }
    }

  }, [orderType, marketState, totalCost, accountSummary, quantity])

  useEffect(()=>{
    if(orderType === 'Stop'){
      if(side === 'BUY'){
        // if(parseFloat(price) <= parseFloat(ask + 0.05).toFixed(2)){
          setShowPriceWarning(`Price for STOP BUY order should be at least $0.05 above current ask price ${addCommasinPrice(decimalAdjust('floor', ask, -2))} or else order may be rejected`)
        // }
      }
      if(side === 'SELL'){
      //  if( parseFloat(price) >= parseFloat(bid - 0.05).toFixed(2)){
        setShowPriceWarning(`Price for STOP SELL  order should be at least $.05 below current bid price  ${addCommasinPrice(decimalAdjust('floor', bid, -2))} or else order may be rejected`)
      //  }
     }
   }

   if(orderType === MARKET_TOUCHED){
    if(side === 'BUY'){
      // if(parseFloat(price) <= parseFloat(bid + 0.05).toFixed(2)){
        setShowPriceWarning(`Price for Market if Touched BUY order should be at least $0.05 above current bid price ${addCommasinPrice(decimalAdjust('floor', bid, -2))} or else order may be rejected`)
      // }
    }
    if(side === 'SELL'){
    //  if( parseFloat(price) >= parseFloat(ask - 0.05).toFixed(2)){
      setShowPriceWarning(`Price for Market if Touched SELL  order should be at least $.05 below current ask price  ${addCommasinPrice(decimalAdjust('floor', ask, -2))} or else order may be rejected`)
    //  }
   }
 }

    if(orderType === 'Limit'){
       if(side === 'BUY'){
        //  if(parseFloat(price) >= parseFloat(ask).toFixed(2)){
          setShowPriceWarning(`Price for LIMIT BUY order should be below current ask price ${addCommasinPrice(decimalAdjust('floor', ask, -2))} or else order may be rejected`)
        //  }
       }
       if(side === 'SELL'){
        // if(parseFloat(price) <= parseFloat(bid).toFixed(2)){
          setShowPriceWarning(`Price for LIMIT SELL order should be above current bid price ${addCommasinPrice(decimalAdjust('floor', bid, -2))} or else order may be rejected`)
      //  }
      }
    }
    // if(price === '' ){
    //   setErrorMessage(`Price should be greater then 0`,setShowError(true))
    // }
    if(orderType === 'Market'){
      setShowPriceWarning('')
    }

  },[price,ask,bid,orderType,quantity,side])

  const ref = useRef();
  useOnClickOutside(ref, () => setOpenDropdown(false))

  const ref2 = useRef();

  useOnClickOutside(ref2, () => setOpenInvestDropDown(false))

  function useOnClickOutside(ref, handler) {
    useEffect(
      () => {
        const listener = (event) => {
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
          handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
          document.removeEventListener("mousedown", listener);
          document.removeEventListener("touchstart", listener);
        };
      },
      [ref, handler]
    );
  }

  /**
    * Prevent Dot for while selected limit order
    */
  function preventDot(e) {
    let key = e.charCode ? e.charCode : e.keyCode;
    if (orderType === "Limit") {
      if (key === 46 || key === 45) {
        e.preventDefault();
      }
    } 
    if (isBerkshire && side == "BUY"){
      if (key === 46) {
        e.preventDefault();
      }
    } 
    else {
      if (key === 46 && e.target.value.split('.').length === 2) {
        e.preventDefault();
      }
    }
  }
  function preventNegative(e) {
    let key = e.charCode ? e.charCode : e.keyCode;
    if (key === 45) {
      e.preventDefault();
    }
    if (isBerkshire && orderType === "Market" && side == "BUY"){
      if (key === 46) {
        e.preventDefault();
      }
    }
  }

  function getPrecision(num) {
    let numAsStr = parseFloat(num).toFixed(10); //number can be presented in exponential format, avoid it
    numAsStr = numAsStr.replace(/0+$/g, '');

    return String(numAsStr).replace('.', '').length - parseFloat(num).toFixed().length;
  }

  function getIfTickerCommission(tradeSide){
    if (JSON.parse(sessionStorage.getItem('planInfo')) &&
      JSON.parse(sessionStorage.getItem('planInfo')).details) {
      let { details } = JSON.parse(sessionStorage.getItem('planInfo'))
      if (details && details.tickerCommission && details.tickerCommission.length > 0) {
        const tickerComm = details.tickerCommission.find((tc) => tc.ticker === stockName);
        if (tickerComm !== undefined && tradeSide === 'BUY') {
          SetIsTickerCommAvailable(true)
        } else {
          SetIsTickerCommAvailable(false)
        }
      }
    }
  }
  const fundToolTip = (props) => {
    return (
      <Tooltip {...props} className={styles.shariyaToolTip}>
        <p className={styles.otcToolTipText}>For more details, visit the CETF fund <a href="https://www.stockal.com/funds/cetf/drivewealth-ice-100-index-etf-cetf" rel="noopener noreferrer" target="_blank">page</a></p>
      </Tooltip>
    )
  }

  return (
    <>
    {
    review ?
      <ReviewOrderCard
        setReviewFunc={setReview}
        stockName={stockName}
        livePrice={livePrices}
        accountSummary={accountSummary}
        reviewData={reviewData}
        stockData={stockData}
        isTickerCommAvailable={isTickerCommAvailable}
        fundToolTip={fundToolTip}
      /> :
      <div className={styles.card}>
        <Row className='d-flex d-lg-none' style={{ paddingTop: '15px', paddingBottom: "15px" }}>
          <Col >
            <img alt="close" src={backImage} onClick={() => handleClose()} />
          </Col>
          <Col className={styles.buyTitle} > {side === 'BUY' ? 'Buy' : 'Sell'} {stockName}</Col>
          <Col className={
              side === 'BUY' ? styles.buyPrices : styles.sellPrices
            } >{stockPrice ? `$${parseFloat(stockPrice).toFixed(2)} ` : '--'}</Col>
        </Row>


          <Row className='d-flex d-lg-none'>
              <ExtendedCard
              isOtc={isOtc}
              showExtended={showExtendedToggel}
              setShowExtended={setShowExtendedToggel}
              setShowDisclouser={setShowDisclouser}
              // onExtended={onExtended}
              />
          </Row>

        <Row className='d-none d-lg-flex'>
          <Col
            lg={2}
            onClick={buy}
            className={side === 'BUY' ? styles.buy : styles.fade}>
            Buy
            <div className={side === 'BUY' ? styles.greenBar : ''}></div>
          </Col>
          <Col
            lg={2}
            onClick={sell}
            className={side === 'BUY' ? styles.fade : styles.sell}>
            Sell
            <div className={side === 'BUY' ? '' : styles.redBar}></div>
          </Col>
          <Col
            lg={8}
            className={
              side === 'BUY' ? styles.buyPrices : styles.sellPrices
            }>
            {stockPrice ? `$${parseFloat(stockPrice).toFixed(2)} ` : '--'}
          </Col>
        </Row>
        {showExtended || showExtendedToggel ?
         <Row className='mt-2 ml-0'>
          <Col xs={1} lg={1} className='p-0'>
            &#9432;
          </Col>
          <Col xs={10} lg={10} className={styles.extendedmessage}>
          For extended hours execution, only limit orders with same day expiration will be allowed
          </Col>
        </Row>
         :
        <Row style={{ marginTop: '24px'}}>
          <Col xs={4} lg={4} className={styles.typeText}>
            Order type
          </Col>
          <Col xs={8} lg={8} className='text-right'
            ref={ref}
          >
            <button
              type='button'
              className={styles.DropdownButton}
              style={orderType === MARKET_TOUCHED ? {width:'160px'} : {}}
              onClick={dropDownMenu}>
              {orderType}
              <i className={styles.arrowDownMarket}></i>
            </button>
            {typeDropdown()}
          </Col>
        </Row>}

        {orderType === 'Market' ?
          <Row style={{ marginTop: '24px', whiteSpace: 'nowrap' }}>
            <Col xs={7} lg={7}
              ref={ref2}
            >
              <button
                type='button'
                className={styles.investDropdownButton}
                onClick={isBerkshire && side === "BUY" ? "" : investDropDown}
                style={{ whiteSpace: 'nowrap' }}>
                {investmentTypes[side][investTypeSelect]}
                {isBerkshire && side === "BUY" ? "":
                <i className={styles.arrowDown}></i>}
              </button>
              {side === 'SELL' ? (
                <span className={shareData && shareData.totalUnit && shareData.totalUnit > 0 ? styles.see : styles.seeGreyed} onClick={sellAll}>
                  sell all
                </span>
              ) : (
                ''
              )}
              {openInvestDropDown ? (
                <div className={styles.investDropdownMenu}>
                  <ul className={styles.dropUl}>
                    {Object.keys(investmentTypes[side]).map((data, i) => {
                      return (
                        <li
                          key={i}
                          className={styles.investDropHead}
                          onClick={() => investHandleChange(data)}>
                          <h6 className={styles.investOption}>{investmentTypes[side][data]}</h6>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              ) : (
                ''
              )}
            </Col>
            <Col xs={5} lg={5}>
              <input
                className={styles.rectangle}
                // type='number'
                inputMode="decimal"
                value={investingValue || ''}
                onInput={(event) => valueChange(event.target.value)}
                onKeyPress={preventNegative}
                onPaste={(e)=>{
                  e.preventDefault()
                  return false;
                }} onCopy={(e)=>{
                e.preventDefault()
                return false;}}
              />
            </Col>
          </Row>
          : <><Row style={{ marginTop: '24px', whiteSpace: 'nowrap' }}>
            <Col xs={7} lg={7} className={styles.typeText}>
              Quantity<br />
              {side === 'SELL' && orderType === 'Stop' || side === 'SELL' && orderType === MARKET_TOUCHED ? (
                <span className={shareData && shareData.totalUnit && shareData.totalUnit > 0 ? styles.see : styles.seeGreyed} style={{ marginTop: '0px' }} onClick={sellAll}>
                  sell all
                </span>
              ) : (
                ''
              )}
            </Col>
            <Col xs={5} lg={5}>
              <input
                className={styles.rectangle}
                style={orderType === MARKET_TOUCHED ? {width:'160px'} : {}}
                type='number'
                inputMode="decimal"
                value={quantity || quantity === 0 ? quantity : ''}
                onInput={(event) => valueChange(event.target.value, 'quanity')}
                onKeyPress={preventDot}
                onPaste={(e)=>{
                  e.preventDefault()
                  return false;
                }} onCopy={(e)=>{
                e.preventDefault()
                return false;}}
              />
            </Col>
          </Row>
            <Row style={{ marginTop: '24px', whiteSpace: 'nowrap' }}>
              <Col xs={7} lg={7} className={styles.typeText}>
                {orderType === MARKET_TOUCHED || orderType === 'Stop' ? 'Trigger price' : 'Target price'}
              </Col>
              <Col xs={5} lg={5}>
                <input
                  className={styles.rectangle}
                  style={orderType === MARKET_TOUCHED ? {width:'160px'} : {}}
                  type='number'
                  min="0"
                  inputMode="decimal"
                  value={price || ''}
                  onInput={(event) => valueChange(event.target.value, 'price')}
                  onKeyPress={preventNegative}
                  onPaste={(e)=>{
                    e.preventDefault()
                    return false;
                  }} onCopy={(e)=>{
                  e.preventDefault()
                  return false;}}
                />
              </Col>
            </Row></>}
        <hr style={{ marginTop: '24px' }} />
        <Row>
          {investTypeSelect === 1 ? (
            <Col xs={6} lg={6} className={styles.costBreakdownText}>
              {side === 'BUY' ? 'Total Investment' : 'Amount'}
            </Col>
          ) : (
            <Col xs={6} lg={6} className={styles.costBreakdownText}>
              Est. quantity
            </Col>
          )}
          <Col xs={6} lg={6} className={styles.costBreakdown}>
            {investTypeSelect === 1
              ? totalCost ? totalCost : '0.00'
              : quantity ? quantity : '0.00'}
          </Col>
        </Row>
        {side === 'BUY' ?
          <Row style={{ paddingTop: '16px' }}>
            <Col xs={6} lg={6} className={styles.costBreakdownText}>
              Buying power
            </Col>
            <Col xs={6} lg={6} className={styles.buyingPower}>
              {accountSummary.buyingPower || '0.00'}
            </Col>
          </Row> : ''
        }
        <Row style={{ paddingTop: '16px' }}>
          <Col xs={8} lg={8} className={styles.costBreakdownText}>
            {isTickerCommAvailable ? <span>Brokerage Commission<OverlayTrigger
              placement="top"
              trigger={['hover', 'focus','click']}
              delay={{ show: 250, hide: 800 }}
              overlay={fundToolTip}
            >
              <img src={infoIcon} alt='icon' style={{width:"16.5px",marginLeft: "2px"}}/>
            </OverlayTrigger></span>  :'Est. commission'}
          </Col>
          <Col xs={4} lg={4} className={styles.buyingPower}>
            {commission ? '$' + commission : '0.00'}
          </Col>
        </Row>
        <Row style={{ paddingTop: '16px' }}>
          <Col xs={6} lg={6} className={styles.orderValue}>
            Order value
          </Col>
          <Col xs={6} lg={6} className={styles.Value}>
            {orderValue || '0.00'}
          </Col>
        </Row>
        {isLiquidated ? (
          <Row style={{ marginTop: '16px' }}>
            <div className={styles.error}>
              <Row className='align-items-center h-100'>
                <Col xs={1} lg={1} className={styles.info}>
                  &#9432;
                </Col>
                <Col xs={10} lg={10} className={styles.errorText}>
                  {toSentenceCase(`Account has been marked liquidate only. You won't be able to buy anything further`)}
                </Col>
              </Row>
            </div>
          </Row>
        ) : (
          ''
        )}
        {showError ? (
          <Row style={{ marginTop: '16px' }}>
            <div className={styles.error}>
              <Row className='align-items-center h-100'>
                <Col xs={1} lg={1} className={styles.info}>
                  &#9432;
                </Col>
                <Col xs={10} lg={10} className={styles.errorText}>
                  {toSentenceCase(errorMessage)}
                </Col>
              </Row>
            </div>
          </Row>
        ) : (
          ''
        )}
        {warningMessage ? (
          <Row style={{ marginTop: '16px' }}>
            <div className={styles.error}>
              <Row className='align-items-center h-100'>
                <Col xs={1} lg={1} className={styles.info}>
                  &#9432;
                </Col>
                <Col xs={10} lg={10} className={styles.errorText}>
                  {warningMessage}
                </Col>
              </Row>
            </div>
          </Row>
        ) : (
          ''
        )}
         {showPriceWarning ? (
          <Row style={{ marginTop: '16px' }}>
            <div className={styles.error}>
              <Row className='align-items-center h-100'>
                <Col xs={1} lg={1} className={styles.info}>
                  &#9432;
                </Col>
                <Col xs={10} lg={10} className={styles.errorText}>
                  {showPriceWarning}
                </Col>
              </Row>
            </div>
          </Row>
        ) : (
          ''
        )}
              {noteMessage !== '' ? (
          <Row style={{ marginTop: '16px' }}>
            <div className={styles.noteMessage}>
              <Row className='align-items-center h-100'>
                <Col xs={1} lg={1} className={styles.info}>
                  &#9432;
                </Col>
                <Col xs={10} lg={10} className={styles.noteMessageText}>
                  {noteMessage}
                </Col>
              </Row>
            </div>
          </Row>
        ) : (
          ''
        )}

        {isOtc && isOtc === true ?
          <Row className={styles.otcAlertMessageContainer}>
            <img src={alertIcon} alt="alert-icon"></img>
            <p>All OTC Orders are only valid for a day.</p>
          </Row> : ''
        }

        <Row className={styles.fixedDiv}>
          <button
            className={buttonClass()}
            onClick={showReview}
            disabled={isDisabled}
          >Review order
          </button>
        </Row>

        {planMsg !== '' ? (
          <Row style={{ marginTop: '16px' }}>
            <div className={styles.noteMessage}>
              <Row className='align-items-center h-100'>
                <Col xs={1} lg={1} className={styles.info}>
                  &#9432;
                </Col>
                <Col xs={10} lg={10} className={styles.noteMessageText}>
                  {toSentenceCase(planMsg)}
                </Col>
              </Row>
            </div>
          </Row>
        ) : (
          ''
        )}
      </div>}

       {/*  extended hour disclouser modal */}
       <ExtendedDisclosureModal
      showDisclouser={showDisclouser}
      setShowDisclouser={setShowDisclouser}
      setShowExtended={setShowExtendedToggel}
      />
      </>
  )
}

export default BuyCard
