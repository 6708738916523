import React, { Fragment } from 'react'
import infoIcon from '../../public/images/info-icon.svg'
import noteImage from '../../public/images/note.png'
import styles from './instruction.module.css'
import {circleA, circleB, circleC, circleD, circleE, circleF, circleG, circleH, circleI,circleJ} from './assets'
import arrow from '../../public/images/icon-arrow-right.svg'
import Copy from './copy'
import { Row, Col,	Accordion } from 'react-bootstrap'
import CustomToggle from './customToggle'
import DownloadInstruction from './downloadInstruction'
import TableError from './tableError'
import DetailError from './detailError'

const HdfcLrs = ({info, fetchBrokerDetails}) => {
	const cloudfront = `${process.env.REACT_APP_ASSESTS}/platform/fund/hdfc`
	return(
		<>
			<Row>
				<Col>
					<div className={styles.bankName}>HDFC Bank</div>
				</Col>
				<Col style={{ textAlign: 'end'}} className='pl-0'>
					<DownloadInstruction bank={'HDFC_BANK'}/>
				</Col>
				<Col md={12} style={{ marginTop: '30px',marginBottom:"30px" }}>
					<div className={styles.transactionCard}>
						<img src={noteImage} />
						<span className={styles.transaction}>
							There is a limit of $25,000 on online transaction per day. If you
							need to remit more, please use Offline Transfer.
						</span>
					</div>
				</Col>
			</Row>
			<Row>
				<Accordion defaultActiveKey='0' className={styles.responsiveInstructions}>
					<CustomToggle
						eventKey='0'
						title='Step 1. Add Beneficiary' />
					<Accordion.Collapse eventKey='0'>
						<div className={`card-body ${styles.cardBody}`}>
							<ol className={styles.list} >
								<li>
									<div className={styles.netbanking}>
										Login into your HDFC Netbanking Account.
									</div>
									<div className={styles.login}>
										<a href='https://netbanking.hdfcbank.com/netbanking/' target="_blank" rel="noopener noreferrer" style={{color:"#0061D3"}}>
											{`Login to Netbanking`}
										</a>
										<a href='https://netbanking.hdfcbank.com/netbanking/' target="_blank" rel="noopener noreferrer">
											<img src={arrow} alt="arrow" style={{marginLeft:"7px"}}/>
										</a>
									</div>
								</li>
								<li>
									<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
										Click on &nbsp;
										<img src={circleA} className={styles.circularPointer} />
										<span className={styles.key}>Funds Transfer &nbsp;</span>
										<img src={circleB} className={styles.circularPointer} />
										<span className={styles.key}>Request &nbsp;</span>
										<img src={circleC} className={styles.circularPointer} />
										<span className={styles.key}>Add a Beneficiary &nbsp;</span>
										<img src={circleD} className={styles.circularPointer} />
										<span className={styles.key}>Remit Now</span>
									</div>
									<div className={styles.marginStepSecondary}>
										<img src={`${cloudfront}/img1.jpg`} className={styles.responsiveImage}/>
									</div>
								</li>
								<li>
									<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
										Fill in the following columns with the details mentioned under the next point :
									</div>
									<div className={styles.marginStepSecondary}>
										<img src={`${cloudfront}/img2.jpg`} className={styles.responsiveImage}/>
									</div>
								</li>
								<li>
									<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
										Enter the beneficiary details as shown below:
									</div>
									<div style={{ overflow:'hidden'}} className={`${styles.beneficiaryTable} ${styles.marginStepSecondary} ${!info&&styles.beneficiaryTableError}`}>
										{!info ? <TableError fetchBrokerDetails={fetchBrokerDetails}/>
											:<Fragment>
												<div  className={styles.beneficiaryTableRow}>
													<img src={circleA} className={styles.circularPointer} />
													<div className={styles.beneficiaryTableContent}>
														<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
															Beneficiary Name:
														</div>
														<div className={styles.beneficiaryTableValue}>
															<span>{info.name}</span>
															<Copy data={{label:'Beneficiary Name', value:info.name}}/>
														</div>
													</div>
												</div>
												<div  className={styles.beneficiaryTableRow}>
													<img src={circleB} className={styles.circularPointer} />
													<div className={styles.beneficiaryTableContent}>
														<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
															Beneficiary Address:
														</div>
														<div className={styles.beneficiaryTableValue}>
															<span>{info.address}</span>
															<Copy data={{label:'Beneficiary Address', value:info.address}} />
														</div>
													</div>
												</div>
												<div  className={styles.beneficiaryTableRow}>
													<img src={circleC} className={styles.circularPointer} />
													<div className={styles.beneficiaryTableContent}>
														<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
															Country:
														</div>
														<div className={styles.beneficiaryTableValue}>
															<span>{info.country}</span>
															<Copy data={{label:'Country', value:info.country}} />
														</div>
													</div>
												</div>
												<div  className={styles.beneficiaryTableRow}>
													<img src={circleD} className={styles.circularPointer} />
													<div className={styles.beneficiaryTableContent}>
														<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
															Currency:
														</div>
														<div className={styles.beneficiaryTableValue}>
															<span>{info.currency}</span>
															<Copy data={{label:'Currency', value:info.currency}} />
														</div>
													</div>
												</div>
												<div  className={styles.beneficiaryTableRow}>
													<img src={circleE} className={styles.circularPointer} />
													<div className={styles.beneficiaryTableContent}>
														<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
															Beneficiary Contact Number:
														</div>
														<div className={styles.beneficiaryTableValue}>
															<span>{info.contactNumber}</span>
															<Copy data={{label:'Beneficiary Contact number', value:info.contactNumber}} />
														</div>
													</div>
												</div>
												<div  className={styles.beneficiaryTableRow}>
													<img src={circleF} className={styles.circularPointer} />
													<div className={styles.beneficiaryTableContent}>
														<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
															Beneficiary Bank Name:
														</div>
														<div className={styles.beneficiaryTableValue}>
															<span>{info.bankName}</span>
															<Copy data={{label:'Beneficiary Bank Name', value:info.bankName}} />
														</div>
													</div>
												</div>
												<div  className={styles.beneficiaryTableRow}>
													<img src={circleG} className={styles.circularPointer} />
													<div className={styles.beneficiaryTableContent}>
														<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
															Beneficiary Bank Address:
														</div>
														<div className={styles.beneficiaryTableValue}>
															<span>{info.bankAddress}</span>
															<Copy data={{label:'Beneficiary Bank Address', value:info.bankAddress}} />
														</div>
													</div>
												</div>
												<div className={`${styles.beneficiaryAccNum} ${styles.beneficiaryTableRow}`}>
													<img src={circleH} className={styles.circularPointer} />
													<div className={styles.beneficiaryTableContent}>
														<div>
															<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
																Beneficiary Account Number:
															</div>
															<div className={styles.divider}></div>
														</div>
														<div className={styles.beneficiaryAccNumValue}>
															<div className={styles.beneficiaryTableValue}>
																<span>{info.accountNumber}</span>
																<Copy data={{label:'Beneficiary Account Number', value:info.accountNumber}} />
															</div>
															<div className={styles.divider}></div>
															<div className={styles.beneficiaryAccInfo}>Everyone has a Unique Beneficiary Account Number.</div>
														</div>
													</div>
												</div>
												<div  className={styles.beneficiaryTableRow}>
													<img src={circleI} className={styles.circularPointer} />
													<div className={styles.beneficiaryTableContent}>
														<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
															SWIFT Code of Beneficiary Bank:
														</div>
														<div className={styles.beneficiaryTableValue}>
															<span>{info.SWIFTCode}</span>
															<Copy data={{label:'SWIFT Code of Beneficiary bank', value:info.SWIFTCode}} />
														</div>
													</div>
												</div>
												<div  className={styles.beneficiaryTableRow}>
													<img src={circleJ} className={styles.circularPointer} />
													<div className={styles.beneficiaryTableContent}>
														<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
															ABA Number/Routing Number:
														</div>
														<div className={styles.beneficiaryTableValue}>
															<span>{info.ABANumber}</span>
															<Copy data={{label:'ABA/Routing number', value:info.ABANumber}} />
														</div>
													</div>
												</div>
											</Fragment>
										}
									</div>
								</li>
								<li>
									<div className={`d-flex flex-column align-items-start ${styles.netbanking} ${styles.marginStepPrimary}`} >
										<div className={styles.netbanking}>
											After filling all details, read out the Terms & Conditions and click on &nbsp;
											<img src={circleA} className={styles.circularPointer} />
											<span className={styles.key}>Continue. </span><br/>
										</div>
										<div className={styles.netbanking}>
											<span>Confirm the added details and Add the Beneficiary after an <span className={styles.key}>OTP Verification.</span></span>
										</div>
										<div className={styles.marginStepSecondary}>
											<img src={`${cloudfront}/img3.jpg`} className={styles.responsiveImage}/>
										</div>
									</div>
								</li>
							</ol>
						</div>
					</Accordion.Collapse>
				</Accordion>
				<Accordion className={styles.responsiveInstructions}>
					<CustomToggle
					eventKey='1'
					title='Step 2. Transfer funds' />
					<p className={styles.warning}>Proceed only when beneficiary has been added</p>
					<Accordion.Collapse eventKey='1'>
						<div className={`card-body ${styles.cardBody}`}>
							<ol className={styles.list} >
								<li>
									<div className={styles.netbanking}>
										Login into your HDFC Netbanking Account.
									</div>
									<div className={styles.login}>
										<a href='https://netbanking.hdfcbank.com/netbanking/' target="_blank" rel="noopener noreferrer" style={{color:"#0061D3"}}>
											{`Login to Netbanking`}
										</a>
										<a href='https://netbanking.hdfcbank.com/netbanking/' target="_blank" rel="noopener noreferrer">
											<img src={arrow} alt="arrow" style={{marginLeft:"7px"}}/>
										</a>
									</div>
								</li>
								<li>
									<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
										Click on &nbsp;
										<img src={circleA} className={styles.circularPointer} />
										<span className={styles.key}>Funds Transfer &nbsp;</span>
										<img src={circleB} className={styles.circularPointer} />
										<span className={styles.key}>Remit Now</span>
									</div>
									<div className={styles.marginStepSecondary}>
										<img src={`${cloudfront}/img4.jpg`} className={styles.responsiveImage}/>
									</div>
								</li>
								<li>
									<div className={`d-flex flex-column align-items-start ${styles.netbanking} ${styles.marginStepPrimary}`}>
										Select the following beneficiary to auto fill all beneficiary details:
										{info ?
											<div className={styles.netbanking}>
												<img src={circleA} className={styles.circularPointer} />
												<span className={styles.key}>{info.name}</span>
											</div>
											:<DetailError className='pt-2' fetchBrokerDetails={fetchBrokerDetails} label='Beneficiary'/>
										}
									</div>
									<div className={styles.marginStepSecondary}>
										<img src={`${cloudfront}/img5.jpg`} className={styles.responsiveImage}/>
									</div>
								</li>
								<li>
									<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
										Fill in the transfer details as shown below:
									</div>
									<ol type = "i" className={styles.list}>
										<li>
											<div className={`${styles.netbanking} ${styles.marginStepPrimary} ${styles.leftMargin}`}>
												Enter the &nbsp;
												<img src={circleA} className={styles.circularPointer} />
												<span><span className={styles.key}>Transfer Amount</span> in USD.</span>
											</div>
										</li>
										<li>
											<div className={`${styles.netbanking} ${styles.marginStepPrimary} ${styles.leftMargin}`}>
												Select Mode of Remittance as &nbsp;
												<img src={circleB} className={styles.circularPointer} />
												<span className={styles.key}>SWIFT – Wire Transfer.</span>
											</div>
										</li>
										<li>
											<div className={`${styles.netbanking} ${styles.marginStepPrimary} ${styles.leftMargin}`}>
												Select Purpose of Remittance as &nbsp;
												<img src={circleC} className={styles.circularPointer} />
												<span className={styles.key}>Indian Portfolio Investment Abroad India – Equity.</span>
											</div>
										</li>
										<li>
											<div className={`${styles.netbanking} ${styles.marginStepPrimary} ${styles.leftMargin}`}>
												Select Source of Funds as &nbsp;
												<img src={circleD} className={styles.circularPointer} />
												<span className={styles.key}>Own Funds.</span>
											</div>
										</li>
										<li>
											<div className={`d-flex flex-column align-items-start ${styles.netbanking} ${styles.marginStepPrimary} ${styles.leftMargin}`}>
											Read the conditions under the capital account transactions
											under liberalized remittance scheme (LRS).
											</div>
										</li>
										<li>
											<div className={`${styles.netbanking} ${styles.marginStepPrimary} ${styles.leftMargin}`}>
												<div className={styles.netbanking}>
													Tick the box to &nbsp;
													<img src={circleE} className={styles.circularPointer} />
													<span className={styles.key}>Agree and Accept the Self-Declaration</span>
												</div>
												<div className={styles.marginStepSecondary}>
													<img src={`${cloudfront}/img6.jpg`} className={styles.responsiveImage}/>
												</div>
											</div>
										</li>
									</ol>
								</li>
								<li>
									<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
										Fill in the remaining transfer details as shown below:
									</div>
									<ol type = "i" className={styles.list}>
										<li>
										<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
											Verify your &nbsp;
											<img src={circleA} className={styles.circularPointer} />
											<span className={styles.key}>Email ID</span>
											displayed by default.
										</div>
										</li>
										<li>
											<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
											    Select the Correspondent Bank Charges to be borne by &nbsp;
												<img src={circleB} className={styles.circularPointer} />
												<span className={styles.key}>Self</span>
											</div>
										</li>
										<li>
											<div className={`d-flex flex-column align-items-start ${styles.netbanking} ${styles.marginStepPrimary}`}>
												Enter the Sender to Receiver Info as follows: &nbsp;
												<div className={styles.netbanking}>
													{info ?
														<div>
															<img src={circleC} className={styles.circularPointer} />
															<span className={styles.key}>{info.accountNumber}</span>
														</div>
														:<DetailError className='pt-2' fetchBrokerDetails={fetchBrokerDetails} label='Info'/>
													}
												</div>
											</div>
										</li>
										<li>
											<div className={`d-flex flex-column align-items-start ${styles.netbanking} ${styles.marginStepPrimary}`}>
												Read the terms and conditions of the bank regarding charges and deductions.
												<div className={styles.netbanking}>
												    Tick the box to &nbsp;
													<img src={circleD} className={styles.circularPointer} />
													<span className={styles.key}>Agree and Accept the Terms & Conditions</span>
												</div>
											</div>
										</li>
										<li>
											<div className={`d-flex flex-column align-items-start ${styles.netbanking} ${styles.marginStepPrimary}`}>
												<div className={styles.netbanking}>
													Select the appropriate option for the following: &nbsp;
													<div className={styles.netbanking}>
														<img src={circleE} className={styles.circularPointer} />
														<span className={styles.key}>Confirm that you are a Resident Individual as per the Income Tax Act, 1961</span>
													</div>
												</div>
												<div className={styles.marginStepSecondary}>
													<img src={`${cloudfront}/img7.jpg`} className={styles.responsiveImage}/>
												</div>
											</div>
										</li>
									</ol>
								</li>

								<li>
									<div className={`d-flex flex-column align-items-start ${styles.marginStepPrimary}`}>
										<div className={styles.netbanking}>
											<span>If this is your first time transferring USD for the current financial year,&nbsp;</span>
										</div>
										<div className={styles.netbanking}>
										<span>Leave all fields blank and enter &nbsp;</span>
											<img src={circleC} className={styles.circularPointer} />
											<span></span><span className={styles.key}>Amounts as Zero (0).</span>
										</div>
										<div className={styles.netbanking}>
											Else, fill in your remittance details for the current financial year as follows:
										</div>
										<div className={styles.netbanking}>
											<img src={circleA} className={styles.circularPointer} />
											<span className={styles.key}>Date of Transaction &nbsp;</span>
											<img src={circleB} className={styles.circularPointer} />
											<span className={styles.key}>Currency &nbsp;</span>
											<img src={circleC} className={styles.circularPointer} />
											<span className={styles.key}>Amount &nbsp;</span>
											<img src={circleD} className={styles.circularPointer} />
											<span className={styles.key}>Bank Name & Branch &nbsp;</span>
										</div>
										<div className={styles.marginStepSecondary}>
											<img src={`${cloudfront}/img8.jpg`} className={styles.responsiveImage}/>
										</div>
									</div>
								</li>
								<li>
									<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
										After filling all the remittance/transaction details, click on &nbsp;
										<img src={circleA} className={styles.circularPointer} />
										<span className={styles.key}>Continue.</span>
										<div className={styles.marginStepSecondary}>
											<img src={`${cloudfront}/img9.jpg`} className={styles.responsiveImage}/>
										</div>
									</div>
								</li>
								<li>
									<div className={`d-flex flex-column align-items-start ${styles.netbanking} ${styles.marginStepPrimary}`}>
										<div className={styles.netbanking}>
											Confirm all details and click on
											<span className={styles.key}> Continue.</span>
										</div>
										<div className={styles.netbanking}>
											Initiate the transfer using
											<span className={styles.key}> OTP Verification </span>
											on the
											<span className={styles.key}> Confirm Transaction Page.</span>
										</div>
										<div className={styles.marginStepSecondary}>
											<img src={`${cloudfront}/img10.jpg`} className={styles.responsiveImage}/>
										</div>
									</div>
								</li>
								<li>
									<div className={`d-flex flex-column align-items-start ${styles.netbanking} ${styles.marginStepPrimary}`}>
										<div className={styles.netbanking}>
											<span>Once the OTP has been verified, an acknowledgment of the process is displayed on the</span>
											<span className={styles.key}> Acknowledgement Page.</span>
										</div>
										<div className={styles.netbanking}>
											The process to remit funds is now complete!
										</div>
										<div className={styles.netbanking}>
											<span>Please note that it takes </span>
											<span className={styles.key}> 2-3 Business Days after </span>
											<span>after the Deduction of Funds from your Bank Account for it to reflect in your Investment Account.</span>
										</div>
										<div className={styles.marginStepSecondary}>
											<img src={`${cloudfront}/img11.jpg`} className={styles.responsiveImage}/>
										</div>
									</div>
								</li>
							</ol>
						</div>
					</Accordion.Collapse>
				</Accordion>
		</Row>
		</>
	)
}


export default HdfcLrs
