import React, { useEffect, useState } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { Footer } from '../../components'
import { AddWatchlistModal,Header  } from '../../components'


const AddWatchList = () => {
    return(
        <>
            <Header/>
            <Container>
                <AddWatchlistModal/>
            </Container>
        </>
    )
}

export default AddWatchList
