import React, { useState} from 'react'
import { Row, Col, Modal } from 'react-bootstrap'
import styles from './RemittanceHistory.module.css'
import { addCommasinPrice } from '../../utils'
import { format } from 'date-fns'
import Copy from '../AddFund/Transfer/Online/copy'
import InfoIcon from "../../public/images/info.svg"

const statusStyle={
	successful:{class:'success', label:'Successful'},
	pending:{class:'pending', label:'Pending'},
	initiated:{class:'initiated', label:'Initiated'},
	declined:{class:'declined', label:'Declined'},
	suspense:{class:'suspense', label:'In Suspense'},
	other:{class:'initiated', label:'Other'}
}

const RemittanceCard = (props) => {
	const [showModal, setShowModal] = useState(false)
	const [modalType, setModalType] = useState()

	const getStatusLabel=(type)=>{
		let temp=type
		if(!statusStyle[temp])
			temp='other'
		const theme=statusStyle[temp].class
		return(<div className={styles.statusLabel}>
			<div className={`${styles.dot} ${styles['bg-'+theme]}`}></div>
			<div className={styles[theme]}>
				{temp!=='other'?statusStyle[temp].label:type}
			</div>
		</div>)
	}
	const getModalHeader=()=>{
		return (
			<Modal.Header closeButton>
				<Modal.Title>
					<div className={styles.modalHeader}>
						<img src={InfoIcon} className={styles.info}/>
						{modalType==='Edit' && 'Reference Number'}
						{modalType==='Delete' && 'Delete'}
					</div>
				</Modal.Title>
			</Modal.Header>
		)
	}
	const getModalBody = ()=>{
		return (
			<Modal.Body>
				{modalType==='Edit'&&<div className={styles.refInput}>
					<input placeholder='Enter Reference Number' />
				</div>}
				{modalType==='Delete'&&<div className={styles.deleteBody}>
					Are you sure you want to delete this transaction?
				</div>
				}
			</Modal.Body>
		)
	}
	const getModalFooter=()=>{
		return (
		<Modal.Footer>
			<div className={styles.footerContainer}>
				<button className={styles.cancelBtn} onClick={()=>setShowModal(false)}>Cancel</button>
				<button className={styles.confirmBtn}>
					{modalType==='Edit' && 'Update'}
					{modalType==='Delete' && 'Delete'}
				</button>
			</div>
		</Modal.Footer>
		)
	}
	const getWithdrawStatus = (status)=>{
		if(status==='successful'){
			return 'successful'
		}else if(status==='failed'){
			return 'declined'
		}else if(status === 'returned'){
      return 'declined'
    }else{
			return 'pending'
		}
	}
  return (<>
	{props.data.map((item, index) => {
      return (
		<div className={styles.remittanceCard}>
			<div className={styles.remittanceDetails}>
				<div className='d-flex flex-column' style={{minWidth:0}}>
					<span className={styles.main}>
						{format(new Date(item.timestamp), 'dd MMMM yyyy, p')}
					</span>
					<span className={`${styles.transID} ${styles.sub}`}>
						<span>ID: {item.amount >= 0 && item.finTranReference? item.finTranReference : item.id}</span>
						<Copy isPrimaryIcon={true} data={{label:'ID', value:item.amount >= 0 ? item.finTranReference : item.id}}/>
					</span>
				</div>
				<div className='d-flex flex-column align-items-end'>
					<span className={styles.main}>
						{item.amount > 0 ? `+$${addCommasinPrice(item.amount)}`: `-$${addCommasinPrice(Math.abs(item.amount))}`}
					</span>
					<span className={styles.sub}>
						{item && item.isInstaPay && item.step ? getStatusLabel(item.step.toLowerCase()==='complete'?'successful':'pending') :
							<>
								{item && item.amount > 0 ? getStatusLabel('successful') : item.status && item.status.message && getStatusLabel(getWithdrawStatus(item.status.message.toLowerCase()))}
							</>
						}
					</span>
				</div>
          </div>
		  {/* Edit and delete option */}
		  {/* {item.status && item.status.message && item.status.message.toLowerCase()==='pending'&&
				<div className={styles.cta}>
					<div className={styles.refID}>
						<span>Ref. ID: 9998711189966043</span>
						<span className={styles.textLink} onClick={()=>{setModalType('Edit');setShowModal(true);}}>Edit</span>
					</div>
					<div className={styles.textLink} onClick={()=>{setModalType('Delete');setShowModal(true);}}>
						Delete
					</div>
				</div>
			} */}
		</div>
	  )
    })}
	<Modal show={showModal} onHide={()=>setShowModal(false)} className={styles.remittanceModal}>
		{getModalHeader()}
		{getModalBody()}
		{getModalFooter()}
	</Modal>
  </>
  )
}

export default RemittanceCard
