import React, { Fragment } from 'react';
import styles from './Nominee.module.css'
import profileStyles from '../Profile.module.css'
import { Row, Col, Button} from 'react-bootstrap'
import { CONTINGENT, PRIMARY, CONTINGENT_LINK } from './type';
import info from '../../../public/images/info.svg'

const NomineeDetail=(props)=>{
    console.log('props',props);
    const getContingentTitle=()=>{
        return <Fragment>
            <span>Contingent Nominee</span>
            <img src={info} className={styles.popOverImage} onClick={(e)=>{ window.open(CONTINGENT_LINK, '_blank').focus();e.stopPropagation();}}/>
        </Fragment>
    }
    const getTemplate=(section, list)=>{
        let templateList = []
        templateList.push(
            <Row key={`header${section}`} className={`mb-3 justify-content-between ${profileStyles.name}`}>
                <span>{section===PRIMARY?'Primary Nominee':getContingentTitle()}</span>
                    <Button className={profileStyles.editButton} onClick={()=>props.onEdit(section)}>
                        <img
                            src={require('../../../public/images/edit.svg')}
                            className='mr-1'></img>
                            Edit
                    </Button>
            </Row>)     
            for(let i=0;i<list.length;i++){
             templateList.push(<Row key={`list${i}`}>
                <Col xl={12} className={`mt-2 ${styles.subHeading}`}>
                    <span>Nominee {i+1}</span>
                </Col>
                <Col xl={4} lg={4} md={4} sm={6} xs={5} className='mb-3 mt-2'>
                    <div className={profileStyles.label}>Full Name</div>
                    <div className={profileStyles.value}>{list[i].beneficiaryName}</div>
                </Col>
                <Col xl={4} lg={4} md={4} sm={6} xs={7} className='mb-3 mt-3'>
                    <div className={profileStyles.label}>Percentage</div>
                    <div className={profileStyles.value}>{list[i].benefitPct}</div>
                </Col>
                <Col xl={4} lg={4} md={4} sm={6} xs={5} className='mb-3 mt-3'>
                    <div className={profileStyles.label}>Type</div>
                    <div className={profileStyles.value}>{list[i].beneficiaryType}</div>
                </Col>
                <Col xl={4} lg={4} md={4} sm={6} xs={7} className='mb-3 mt-3 text-break'>
                    <div className={profileStyles.label}>Email</div>
                    <div className={profileStyles.value}>{list[i].beneficiaryEmail}</div>
                </Col>
                {list[i] && list[i].beneficiaryType==="PERSON" ?
                    <Fragment>
                        <Col xl={4} lg={4} md={4} sm={6} xs={5} className='mb-3 mt-3'>
                            <div className={profileStyles.label}>DOB</div>
                            <div className={profileStyles.value}>{list[i].beneficiaryDob}</div>
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={6} xs={7} className='mb-3 mt-3'>
                            <div className={profileStyles.label}>Phone</div>
                            <div className={profileStyles.value}>{list[i].beneficiaryPhone}</div>
                        </Col>
                    </Fragment>
                    :<Col xl={4} lg={4} md={4} sm={6} xs={5} className='mb-3 mt-3'>
                        <div className={profileStyles.label}>Phone</div>
                        <div className={profileStyles.value}>{list[i].beneficiaryPhone}</div>
                    </Col>}
                
                {list[i] && list[i].beneficiaryType==="ENTITY"?<Fragment>   
                    <Col xl={4} lg={4} md={4} sm={6} xs={7} className='mb-3 mt-3'>
                        <div className={profileStyles.label}>Formation Date</div>
                        <div className={profileStyles.value}>{list[i].formationDt}</div>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={6} xs={5} className='mb-3 mt-3'>
                        <div className={profileStyles.label}>Primary Contact Name</div>
                        <div className={profileStyles.value}>{list[i].primaryContact}</div>
                    </Col>
                </Fragment>:null}
                </Row>);
        }
        return templateList
    }
    return (
       <Fragment>
           <div className={`${profileStyles.borderBottom} ${styles.section}`}>
                {getTemplate(PRIMARY, props.primaryList)}
           </div>
           <div className={`${profileStyles.borderBottom} ${styles.section}`}>
            {props.contingentList.length>0? 
                getTemplate(CONTINGENT, props.contingentList)
                :<Fragment>
                    <Row className={`mb-4 justify-content-between ${profileStyles.name}`}>
                        <span>{getContingentTitle()}</span>
                        <Button className={profileStyles.editButton} onClick={()=>props.onEdit(CONTINGENT)}>
                            <span className={styles.plusSymbol}>Add</span>
                        </Button>
                    </Row>
                    <Row className='flex-column align-items-center'>
                        <p className={styles.info}>No Contingent Nominee(s) added</p>
                    </Row>
                </Fragment>
            }
           </div>
       </Fragment>
    );
}

export default NomineeDetail;