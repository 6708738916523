import React, { useState, useRef, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { updatePassword } from '../../api'
import { Formik } from 'formik'
import * as yup from 'yup'
import styles from './ForgotPassword.module.css'
import SignUpInput from '../SignUp/SignUpInput'
import SignUpButton from '../SignUp/SignUpButton'
import UpdatePasswordSuccessful from './UpdatedPasswordSuccessful'
import PasswordSvg from '../../public/images/newOnboarding/Password.svg'
import PasswordHide from '../../public/images/newOnboarding/PasswordHide.svg'
import PasswordShow from '../../public/images/newOnboarding/PasswordShow.svg'

const UpdatePassword = ({ hashID, otp, setVerifyOtp, setShowVerify }) => {
	const [disable, setDisable] = useState(true)

	const editFieldRef = useRef(null)
	const [showGoBack, setShowGoBack] = useState(false)
	const [errorCode, setErrorCode] = useState('No')
	const [errorMessage, setErrormessage] = useState('')
	const [updateSuccessful, setUpdateSuccessful] = useState(false)
	const [passwordType, setPasswordType] = useState('password')
	const [passwordTypeConfirm, setPasswordTypeConfirm] = useState('password')
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		editFieldRef.current.focus()
	}, [])

	const passwordValidation = event => {
		setErrormessage('')
		let errorMessage = ''
		const testLength = /^(?=.{8,})/.test(event)
		const testAplha = /^(?=.*[a-z])/.test(event)
		const testUpperCase = /^(?=.*[A-Z])/.test(event)
		const testNumber = /^(?=.*[0-9])/.test(event)
		const testSpecialChar = /^(?=.*[!@#$%^&*])/.test(event)
		if (!testLength) {
			errorMessage = 'Password must have 8 or more characters'
		} else if (!testAplha) {
			errorMessage = 'Password needs to include atleast one lowercase alphabet'
		} else if (!testUpperCase) {
			errorMessage = 'Password needs to include atleast one Uppercase alphabet'
		} else if (!testNumber) {
			errorMessage = 'Password needs to include atleast number'
		} else if (!testSpecialChar) {
			errorMessage = 'Password needs to include atleast one special symbol'
		} else {
			errorMessage = ''
		}
		setErrormessage(errorMessage)
		return errorMessage
	}

	const keyPressHandler = (event, submitCallback) => {
		if (event.which === 13) {
			submitCallback()
		}
	}

	// const goBack = () => {
	// 	setShowVerify(false)
	// 	setVerifyOtp(false)
	// }

	return (
		<>
			{!updateSuccessful ? (
				<>
					<Row className={styles.title}>Update password</Row>
					<Row className={styles.subTitle}>
						Create a new password for your account. For security reasons, it
						can’t be the same as your last 3 passwords.
					</Row>
					<Formik
						initialValues={{ password: '', confirmpassword: '' }}
						validationSchema={yup.object({
							password: yup.string().required('Password is required field'),

							// confirmpassword: yup.string().required('Confirm Password is required field')
							// .matches(
							//   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
							//   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
							// )
							confirmpassword: yup
								.string()
								.required('Confirm Password is required field')
								.oneOf([yup.ref('password'), null], 'Passwords must match')
						})}
						onSubmit={async values => {
							setLoading(true)
							// setErrormessage('')

							const response = await updatePassword(
								values.password,
								hashID,
								otp
							)
							console.log('resp', response)
							if (response && response.code === 200) {
								// setErrormessage(response.message)
								// setFormMessage(response.message)
								// setTimeout(function () {
								// 	history.push('/signin')
								// }, 1000)
								setUpdateSuccessful(true)
							} else {
								if (response && response.code === 440) {
									setDisable(true)
									setShowGoBack(true)
									setErrorCode('Yes')
								}
								//setFormMessage(response.message)
								setErrormessage(response?.message, setLoading(false))
							}
						}}>
						{({
							handleChange,
							handleBlur,
							handleSubmit,
							values,
							dirty,
							touched,
							errors
						}) => {
							// if (
							// 	values.password !== '' &&
							// 	values.confirmpassword !== '' &&
							// 	values.password === values.confirmpassword &&
							// 	passwordValidation.oneAlpha &&
							// 	passwordValidation.oneNumber &&
							// 	passwordValidation.oneUpperCaseAplha &&
							// 	passwordValidation.oneSpecialChar &&
							// 	passwordValidation.eightChar &&
							// 	errorCode === 'No'
							// ) {
							// 	setErrormessage('')
							// 	setDisable(false)
							// } else {
							// 	setDisable(true)
							// }
							return (
								<>
									{/* <Row className={styles.inputRow}>
								<Col md={9} xs={12}>
									<Form.Group
										className={`form-focus ${
											touched.password && !errors.password ? 'focused' : null
										}`}>
										<Form.Control
											className={styles.verifyInput}
											type='password'
											name='password'
											ref={editFieldRef}
											tabIndex='1'
											value={values.password}
											// onChange={handleChange}
											onChange={event =>
												handlePasswordValidation(event, handleChange)
											}
											onBlur={handleBlur}
											isInvalid={touched.password && errors.password}
											isValid={touched.password && !errors.password}
											onPaste={e => {
												e.preventDefault()
												return false
											}}
											onCopy={e => {
												e.preventDefault()
												return false
											}}></Form.Control>
										<Form.Label className='focus-label'>
											Enter Password
										</Form.Label>
										<Form.Control.Feedback type='invalid'>
											{errors.password}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
							</Row> */}

									<Row
										className={styles.inputs}
										style={{ position: 'relative' }}>
										<SignUpInput
											label='Create New Password'
											placeholder='Enter New Password'
											type={passwordType}
											name='password'
											refValue={editFieldRef}
											tabIndex='2'
											value={values.password}
											onChange={event => {
												setErrormessage('')
												handleChange(event)
												//passwordValidation(event, handleChange)
											}}
											autoComplete='new-password'
											//onBlur={handleBlur}
											isInvalid={
												(touched.password && errors.password) || errorMessage
											}
											// isValid={
											// 	touched.password &&
											// 	!errors.password &&
											// 	errorMessage === ''
											// }
											onPaste={e => {
												e.preventDefault()
												return false
											}}
											onCopy={e => {
												e.preventDefault()
												return false
											}}
											containerClasses={styles.passwordInvalid}
											errorMessage={errors.password || errorMessage}
											icon={PasswordSvg}
											onKeyPress={e => keyPressHandler(e, handleSubmit)}
										/>
										{
											<img
												className={styles.toggle}
												src={
													passwordType === 'password'
														? PasswordHide
														: PasswordShow
												}
												alt=''
												onClick={() => {
													const type =
														passwordType === 'password' ? 'text' : 'password'
													setPasswordType(type)
												}}></img>
										}
									</Row>

									{!errorMessage && (
										<Row className={styles.passwordSpecification}>
											Passwords should be a min. of 8 characters with a mix of
											letters, numbers & symbols (Example: Abcd@123)
										</Row>
									)}

									<Row
										className={styles.inputs}
										style={{ position: 'relative' }}>
										<SignUpInput
											label='Confirm New Password'
											placeholder='Confirm New Password'
											type={passwordTypeConfirm}
											name='confirmpassword'
											value={values.confirmpassword}
											containerClasses={styles.passwordInvalid}
											onChange={handleChange}
											//onBlur={handleBlur}
											onKeyPress={e => keyPressHandler(e, handleSubmit)}
											isInvalid={
												(touched.confirmpassword && errors.confirmpassword) ||
												errorMessage ===
													'New password cannot be same as the last 3 passwords'
											}
											// isValid={
											// 	touched.confirmpassword &&
											// 	!errors.confirmpassword &&
											// 	errorMessage !==
											// 		'New password cannot be same as the last 3 passwords'
											// }
											onPaste={e => {
												e.preventDefault()
												return false
											}}
											onCopy={e => {
												e.preventDefault()
												return false
											}}
											errorMessage={
												errors.confirmpassword
													? errors.confirmpassword
													: errorMessage ===
													  'New password cannot be same as the last 3 passwords'
													? 'We need this to make sure we register your password correctly'
													: ''
											}
											icon={PasswordSvg}
										/>
										{
											<img
												className={styles.toggle1}
												src={
													passwordTypeConfirm === 'password'
														? PasswordHide
														: PasswordShow
												}
												alt=''
												onClick={() => {
													const type =
														passwordTypeConfirm === 'password'
															? 'text'
															: 'password'
													setPasswordTypeConfirm(type)
												}}></img>
										}
									</Row>

									<Row className={styles.buttonMargin}>
										<SignUpButton
											title='Update'
											classes={styles.buttonPassword}
											loading={loading}
											onClick={() => {
												// console.log('PASSWordup', values)
												if (!passwordValidation(values.password)) {
													handleSubmit(values)
												}
											}}
										/>
									</Row>

									<Row className={styles.inputRow}>
										<Col md={7} xs={8}>
											{/* <Form.Group
										className={`form-focus ${
											// touched.confirmpassword && !errors.confirmpassword ? 'focused' : null
											// touched.confirmpassword  && values.password !== values.confirmpassword	? 'focused' : null
											values.confirmpassword !== '' ? 'focused' : null
										}`}>
										<Form.Control
											className={styles.verifyInput}
											type='password'
											tabIndex='2'
											name='confirmpassword'
											value={values.confirmpassword}
											onChange={handleChange}
											onBlur={handleBlur}
											onKeyPress={e => keyPressHandler(e, handleSubmit)}
											isInvalid={
												touched.confirmpassword &&
												errors.confirmpassword &&
												values.password !== values.confirmpassword
											}
											isValid={
												touched.confirmpassword && !errors.confirmpassword
											}
											onPaste={e => {
												e.preventDefault()
												return false
											}}
											onCopy={e => {
												e.preventDefault()
												return false
											}}></Form.Control>
										<Form.Label className='focus-label'>
											Enter Confirm Password
										</Form.Label>
										<Form.Control.Feedback type='invalid'>
											{errors.confirmpassword}
										</Form.Control.Feedback>
									</Form.Group> */}
										</Col>
										{/* <Col md={2} xs={4}>
									<SignUpButton
										title='Change Password'
										classes={styles.buttonPassword}
										disabled={disable}
										onClick={handleSubmit}
									/>
								</Col> */}
									</Row>
								</>
							)
						}}
					</Formik>
				</>
			) : (
				<UpdatePasswordSuccessful />
			)}
		</>
	)
}

export default UpdatePassword
