import React, {useEffect, useState} from 'react'
import {Row, Col } from 'react-bootstrap'
import styles from './StartBanner.module.css'
import { Link } from 'react-router-dom'
import { toPascalCase } from '../../utils'

const StartBanner = (props) => {
  const [disableButton,setDisableButton] = useState(false)

  useEffect(() => {
  if((props.bannerData.type === 'DocumentPending' && props.location !== 'India') || (props.bannerData.type === 'DocumentPending' && props.location !== 'India') ){
    setDisableButton(true)
  }
  },[props.location])
  // const  firstName = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')).firstName : ''
    const firstName = toPascalCase(props.firstName)
    return (
		<Row>
			<Col md={7} sm={12} xs={12} className={styles.textAreaStartBanner}>
                <p className={styles.headingStartBanner}>
                  { props.bannerData.heading ? props.bannerData.heading :
                  `Hi${props.firstName ? " "+firstName :''},`
                  }
                  </p>
                {props.bannerData.errorMessage ?
                <p className={styles.ErrorMessageStartBanner}>{props.bannerData.errorMessage}</p> : ""}
                {props.bannerData.blueText ?
                <p className={styles.blueTextStartBanner}>{props.bannerData.blueText}</p> : ""}
                <p className={props.bannerData.bannerText ? styles.shortBannerTextWidth : styles.bannerTextStartBanner}>{props.bannerData.bannerText}</p>
                {props.bannerData.button && props.bannerData.buttonLink ?
                  <Link to={
                    props.bannerData.buttonLink
                  }><button
                  className={styles.buttonStartBanner}>{props.bannerData.button}</button> </Link>
                : ""}
                {props.bannerData.helpText ?
                <p className={styles.helpTextStartBanner}>
                  {/* {props.bannerData.helpText.replace('contactMail',props.contactMail)} */}
                  If you need any help please write to us at <a href={"mailto:"+props.contactMail}>{props.contactMail}</a>
                  </p> : ""}
			</Col>
			<Col md={5} sm={5} xs={6} className={styles.ImageCol}>
                <img className={styles.imageStartBanner} src={props.bannerData.image} alt="bannerImage"></img>
            </Col>
		</Row>
	)
}

export default StartBanner
