import React from 'react'
import { Button } from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import SignUpButton from '../../SignUpButton/SignUpButton'
import styles from '../AccountSetUp.module.css'

const uploadImage = ({
	files,
	onDrop,
	dropRef,
	openDialog,
	onRemove,
	name,
  accept,
  allowedType,
  allowedSize
}) => {
	return files.length === 0 ? (
		<Dropzone
			onDrop={onDrop}
			ref={dropRef}
			noClick
			maxSize={10485760}
      accept={accept}
			// accept='image/jpeg, image/png, image/gif, image/pdf, image/jpg, .pdf'
      >
			{({ getRootProps, getInputProps }) => (
				<div className={styles.uploadDiv}>
					<div
						{...getRootProps({ className: 'dropzone' })}
						className={styles.dropImageCenter}>
						<input {...getInputProps()} />
						<SignUpButton
							title='Upload File'
							onClick={openDialog}
							classes={styles.uploadButton}
						/>
						<p className={styles.dropTitle}>Or drag & drop here</p>
            <p className={styles.dropTitle} style={{marginBottom:'5px'}}>{`Allowed types:  ${allowedType}`}</p>
            <p className={styles.dropTitle}>{allowedSize}</p>
					</div>
				</div>
			)}
		</Dropzone>
	) : (
		files.map(file => {
			return (
				<div className={styles.uploadImageSection}>
          {accept.includes('pdf') ?
          <embed src={file.preview} width="400px" height="250px" />
          :
					<img src={file.preview} className={styles.uploadedImage} />}
					<Button className={styles.imageButton} onClick={() => onRemove(name)}>
						Remove
					</Button>
				</div>
			)
		})
	)
}

export default uploadImage
