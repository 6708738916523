import React from "react"
import Copy from './copy'
import styles from './online.module.css'

const HDFCTable = ({accountDetail})=>{
    return (<div className={styles.beneficiaryTable}>
        <div  className={styles.beneficiaryTableRow}>
           
            <div className={styles.beneficiaryTableContent}>
                <div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
                    Beneficiary Name
                </div>
                <div className={styles.beneficiaryTableValue}>
                    <span>{accountDetail.name}</span>
                    <Copy data={{label:'Beneficiary Name', value:accountDetail.name}}/>
                </div>
            </div>
        </div>
        <div  className={styles.beneficiaryTableRow}>
           
            <div className={styles.beneficiaryTableContent}>
                <div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
                    Beneficiary Address
                </div>
                <div className={styles.beneficiaryTableValue}>
                    <span>{accountDetail.address}</span>
                    <Copy data={{label:'Beneficiary Address', value:accountDetail.address}} />
                </div>
            </div>
        </div>
        <div  className={styles.beneficiaryTableRow}>
           
            <div className={styles.beneficiaryTableContent}>
                <div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
                    Country
                </div>
                <div className={styles.beneficiaryTableValue}>
                    <span>{accountDetail.country}</span>
                    <Copy data={{label:'Country', value:accountDetail.country}} />
                </div>
            </div>
        </div>
        <div  className={styles.beneficiaryTableRow}>
         
            <div className={styles.beneficiaryTableContent}>
                <div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
                    Currency
                </div>
                <div className={styles.beneficiaryTableValue}>
                    <span>{accountDetail.currency}</span>
                    <Copy data={{label:'Currency', value:accountDetail.currency}} />
                </div>
            </div>
        </div>
        <div  className={styles.beneficiaryTableRow}>
           
            <div className={styles.beneficiaryTableContent}>
                <div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
                    Beneficiary Contact Number
                </div>
                <div className={styles.beneficiaryTableValue}>
                    <span>{accountDetail.contactNumber}</span>
                    <Copy data={{label:'Beneficiary Contact number', value:accountDetail.contactNumber}} />
                </div>
            </div>
        </div>
        <div  className={styles.beneficiaryTableRow}>
           
            <div className={styles.beneficiaryTableContent}>
                <div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
                    Beneficiary Bank Name
                </div>
                <div className={styles.beneficiaryTableValue}>
                    <span>{accountDetail.bankName}</span>
                    <Copy data={{label:'Beneficiary Bank Name', value:accountDetail.bankName}} />
                </div>
            </div>
        </div>
        <div  className={styles.beneficiaryTableRow}>
           
            <div className={styles.beneficiaryTableContent}>
                <div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
                    Beneficiary Bank Address
                </div>
                <div className={styles.beneficiaryTableValue}>
                    <span>{accountDetail.bankAddress}</span>
                    <Copy data={{label:'Beneficiary Bank Address', value:accountDetail.bankAddress}} />
                </div>
            </div>
        </div>
        <div className={`${styles.beneficiaryAccNum} ${styles.beneficiaryTableRow}`}>
           
            <div className={styles.beneficiaryTableContent}>
                <div>
                    <div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
                        Beneficiary Account Number
                    </div>
                </div>
                <div className={styles.beneficiaryAccNumValue}>
                    <div className={styles.beneficiaryTableValue}>
                        <span>{accountDetail.accountNumber}</span>
                        <Copy data={{label:'Beneficiary Account Number', value:accountDetail.accountNumber}} />
                    </div>
                    <div className={styles.divider}></div>
                    <div className={styles.beneficiaryAccInfo}>Everyone has a Unique Beneficiary Account Number.</div>
                </div>
            </div>
        </div>
        <div  className={styles.beneficiaryTableRow}>
           
            <div className={styles.beneficiaryTableContent}>
                <div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
                    SWIFT Code of Beneficiary Bank
                </div>
                <div className={styles.beneficiaryTableValue}>
                    <span>{accountDetail.SWIFTCode}</span>
                    <Copy data={{label:'SWIFT Code of Beneficiary bank', value:accountDetail.SWIFTCode}} />
                </div>
            </div>
        </div>
        <div  className={styles.beneficiaryTableRow}>
           
            <div className={styles.beneficiaryTableContent}>
                <div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
                    ABA Number/Routing Number
                </div>
                <div className={styles.beneficiaryTableValue}>
                    <span>{accountDetail.ABANumber}</span>
                    <Copy data={{label:'ABA/Routing number', value:accountDetail.ABANumber}} />
                </div>
            </div>
        </div>
    </div>)
}

export default HDFCTable