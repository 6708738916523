import React, {useState} from 'react'
import styles from './reviewDetail.module.css'
import { Row, Col, Spinner } from 'react-bootstrap'
import backImage from '../../public/images/back.png'
import { infoError } from '../lrsForm/assets'

const ReviewDetail = ({
	setModalData,
	data,
	displayData,
	back,
	review,
	buttonColor,
  enterBrokerInformation,
  enterBrokerDetail,
  buttonTitle
}) => {
	const [counter, setCounter] = useState(0)
    const [fetching, setFetching] = useState(false)
    const [error, setError] = useState(false)
	const onProceed= async()=>{
        if(!fetching && counter <3){
            setFetching(true)
            setError(false)
            try{
                const success= await enterBrokerInformation()
                if (success) {
                    setCounter(0)
					setModalData(data)
                }else{
					setError(true)
               		setCounter(counter+1);
				}
            }catch(error){
                setError(true)
                setCounter(counter+1);
            }        
            setFetching(false)
        }        
    }
	return (
		<div className={styles.card}>
			<Row>
				<Col md={12}>
					<div
						className={styles.title}
						style={{ cursor: 'pointer' }}
						onClick={() => setModalData(back)}>
						<img src={backImage} />
						<span
							style={{
								paddingLeft: '20px'
							}}>{`Indian Bank Account`}</span>
					</div>
				</Col>
				<Col style={{ marginTop: '33px' }}>
					<div className={styles.review}>Review</div>
				</Col>
			</Row>
			<Row>
				{displayData.map((trav, i) => {
					return (
						<Col key={i} xs={12} md={3} className={styles.marginMobile}>
							<div className={styles.header}>{trav.header}</div>
							<div className={styles.data}>{trav.data}</div>
						</Col>
					)
				})}
			</Row>

			<Row className={styles.fixedDiv}>
				<Col>
					{counter<3 ? (!error ?
							<button
								onClick={onProceed}
								type='button'
								style={{ backgroundColor: buttonColor }}
								className={`btn btn-primary btn-sm ${styles.addfunds} themeButton`}>
								<span className={styles.addfundstext}>
									{fetching?
										<>
											<Spinner animation="border" className={styles.spinnerColor}/>
											Fetching Form
										</>
										:buttonTitle
									}
									
								</span>
							</button>
						:<button className={styles.errorButton} onClick={onProceed}>
							<img src={infoError} style={{height:'fit-content'}}/>
							<div className={styles.errorText}>
								<span style={{color:'#DC6803', marginLeft:'6px'}}>Unable To Fetch Form</span>
								<span style={{paddingLeft:'6px'}}>Try Again</span>
							</div>							
                    	</button> )
						:<button className={styles.errorButton}  onClick={()=>window.fcWidget.open()}>
							<img src={infoError} style={{height:'fit-content'}}/>
							<div className={styles.errorText}>
								<span style={{color:'#DC6803', marginLeft:'6px'}}>Something Went Wrong</span>
								<span style={{paddingLeft:'6px'}}>Get Help</span>
							</div>							
						</button>
					}
				</Col>
			</Row>
		</div>
	)
}

export default ReviewDetail
