import React,{useRef,useEffect} from 'react'
import { Card,Row,Col } from 'react-bootstrap'
import styles from './AccountTransfer.module.css'

const SearchCardBody = ({searchList,onSelect,setShowSearch,setsearchItem}) => {
  const searchRef = useRef(null);
  useOnClickOutside(searchRef, () => setShowSearch(false,setsearchItem('')));

    return(
        <Card className={styles.searchCard} ref={searchRef}>
          <Card.Body className={styles.cardBody}>
            <div className={styles.searchResultDivider}></div>
            <div className={styles.searchList}>
              {searchList &&
                searchList.map((item, index) => {
                  return (
                    <>
                      <Row className={`${styles.searchItem}`} onClick={() => onSelect(item.code)}>
                        <Col md={3} sm={3} xs={3} className={styles.stockTitle}>
                          {item.code}
                        </Col>
                        <Col xs={6} className={styles.stockName}>{item.company}</Col>
                        <Col md={3} sm={3} xs={3} className={styles.stockType}>
                          {item.stockType}
                        </Col>
                      </Row>
                
                    <div className={styles.resultDivider}></div>
                    </>
                  )
                })}
            </div>
          </Card.Body>
        </Card>
    )
}

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    [ref, handler]
  );
}

export default SearchCardBody