import React from 'react'
import { Modal } from 'react-bootstrap'
import crossImage from '../../public/images/cross.png'
import styles from './OtpModal.module.css'

const OtpModal = ({ show, handleClose, children }) => {
	return (
		<Modal
			style={{margin:"0px"}}
			show={show}
			onHide={handleClose}
			className={styles.modalRight}
			dialogClassName={styles.modalDialog}
			scrollable>
			{/* <Modal.Header className={styles.modalHeader}></Modal.Header> */}
			<Modal.Body className={styles.modalPadding}>{children}</Modal.Body>
			<div
				style={{
					position: 'absolute',
					right: '28px',
					top: '28px',
					cursor: 'pointer'
				}}>
				<img src={crossImage} onClick={() => handleClose()} />
			</div>
		</Modal>
	)
}

export default OtpModal

