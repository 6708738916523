import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import styles from './StockSeeMore.module.css'
import StockNews from '../StockNews/StockNews'
import { EarningsChart } from '../StocksChart'
import InvestmentDescision from './InvestmentDescision'

const StockSeeMore = ({ stockName, analystData, premiumFeatures, activePlan }) => {
  const [seemore, setSeemore] = useState(false)

  useEffect(() => {
    const { innerWidth: width } = window
    width < 992 ? setSeemore(true) : setSeemore(false)
  }, [stockName])

  return (
    <>
      <Row className={styles.investResponsive}>
        {seemore ? (
          <>
            {/* <Col>
              <InvestmentDescision analystData={analystData} stockName={stockName} premiumFeatures={premiumFeatures} activePlan={activePlan} />
            </Col> */}
            <Col lg={12}>
              <div className={styles.earningGraph}>Earnings per share</div>
              <EarningsChart code={stockName} />
            </Col>
            {/* <Col lg={12} className='d-none d-lg-block' style={{ marginTop: '64px' }}>
              <StockNews stockName={stockName} />
            </Col> */}
            <Col onClick={() => setSeemore(false)} className='d-none d-lg-flex'>
              <span className={styles.title}>
                Hide earnings charts
              </span>
              <span className={styles.seemoreIcon}>
                <img src={require('../../public/images/hideIconNew.svg')}></img>
              </span>
            </Col>
          </>
        ) : (

          <Col onClick={() => setSeemore(true)} className='d-none d-lg-flex'>
            <span className={styles.title}>See earnings charts</span>
            <span className={styles.seemoreIcon}>
              <img src={require('../../public/images/seemoreNew.svg')}></img>
            </span>
          </Col>
        )}
      </Row>
    </>

  )
}

export default StockSeeMore
