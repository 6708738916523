import React, { useState, useEffect } from 'react'
import Header from '../Header/Header'
import { getAccountInfo } from '../../api'
import { CashAccountReviewState, CashAccountRejectedState, DriveWealthNotApprovedState, CashAccountSignupState  } from '../Header/NavigationStates'
import styles from './SavingsBanner.module.css'

const SavingsBanner = () => {

    const [dwAccountStatus, setDwAccountstatus] = useState()
    const [savingAccountStatus, setSavingAccountStatus] = useState()

    const accountDetails = () => {
        getAccountInfo().then(response => {
            if(response && response.accountStatus){
              setDwAccountstatus(response.accountStatus)
              setSavingAccountStatus(response.savingAccountStatus)
            }
        }).catch((err)=>{
            console.error(err)
        })
      }
      useEffect(()=>{
        accountDetails()
    },[dwAccountStatus, savingAccountStatus])

    return (
        <>
            <Header/>
            <div className={styles.bannerContainer}>
                { dwAccountStatus && dwAccountStatus === 2 ? (
                    <>
                        {savingAccountStatus && savingAccountStatus === 1 ? (
                           <CashAccountReviewState/> 
                        ) : savingAccountStatus && savingAccountStatus === 3 ? (
                            <CashAccountRejectedState/>
                        ) : (
                            <CashAccountSignupState accountApproved={true}/>
                        )}
                    </>
                ) : (
                    <DriveWealthNotApprovedState/>
                )}
            </div>
        </>
    )
}

export default SavingsBanner