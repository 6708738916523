import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import SkeletonElement from './SkeletonElement'
import Shimmer from './Shimmer'

const popularStockSkeleton = () => {
	return (
		<Card className='skeletonCard'>
			<Card.Body>
				<>
					<Row>
						<Col md={2}>
							<SkeletonElement
								type='circle'
								height='50px'
								width='50px'></SkeletonElement>
						</Col>
						<Col md={4}>
							<SkeletonElement width='70px' height='20px'></SkeletonElement>
							<SkeletonElement width='100px' height='15px'></SkeletonElement>
						</Col>
						<Col md={3}>
							<SkeletonElement width='70px' height='42px'></SkeletonElement>
						</Col>
						<Col md={3}>
							<SkeletonElement width='70px' height='20px'></SkeletonElement>
							<SkeletonElement width='40px' height='15px'></SkeletonElement>
						</Col>
					</Row>
					<Shimmer />
				</>
			</Card.Body>
		</Card>
	)
}

export default popularStockSkeleton
