import { initialState } from '../initialState'
import { YEAR_LOW } from '../actions/action'

const yearLowStock = (state = initialState.yearLowStock, action) => {
	switch (action.type) {
		case YEAR_LOW: {
			return { ...state, yearLowStock: action.payload }
		}
		default: {
			return state
		}
	}
}

export default yearLowStock

