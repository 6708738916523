import React,{useState,useEffect,useContext} from 'react'
import {Card,Row} from 'react-bootstrap'
import { format } from "date-fns"
import { useHistory} from 'react-router-dom'
import {getPAN,verifyPAN} from '../../api'
import SignUpButton from '../SignUp/SignUpButton'
import SignUpInput from '../SignUp/SignUpInput'
import styles from './VerifyPan.module.css'
import SkeletonPan from './Skeleton'
import Success from '../../public/images/newOnboarding/clearState.svg'
import { ThemeContext } from '../../Context/ThemeContext'
import CheckBox from '../CheckBox/CheckBox'

const Pan = ({setCurrent}) => {
  const [panNumber,setPanNumber] = useState('')
  const [panError,setPanError] = useState('')
  const [disablePan,setDisablePan] = useState(true)
  const [ischecked,setIschecked] = useState(false)
  const [loading,setLoading] = useState(true)
  const [buttonloading,setButtonloading] = useState(false)
  const [showTermsError,setShowTermsError] = useState(false)
  const { savingAccountDisclaimer } = useContext(ThemeContext)
  const history = useHistory()

  const userInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : {}

  useEffect(() => {
     const fetchPan = async () => {
      try{
        const res = await getPAN()
        if(res.data.code===200 && res.data.data){
            if(res.data.data.panVerification.toUpperCase() ==="REJECTED" || res.data.data.panVerification ===""){
                setPanNumber(res.data.data.idNo)
                setPanError('PAN is not verified',setDisablePan(false))
            }else{
              setPanNumber(res.data.data.maskedPanNumber)
              setDisablePan(true)
              //  setCurrent('document')
            }
        }
    }catch(error){
      console.log('err',error)
    }
    setLoading(false)
     }

      // eslint-disable-next-line no-undef
      webengage.track('interested cash account')
    //  if(userInfo && userInfo.savingAccountStatus){
    //    history.push('/home')
    //  }
    //  else{
     fetchPan()
    //  }
  },[])

  const verifyPanSubmit = async() =>{
    if(checkPan()){
      try{
        setButtonloading(true)
        if(userInfo){
            const fullname= `${userInfo.firstName} ${userInfo.middleName} ${userInfo.lastName}`
            let dob = '';
            if(userInfo.dob !== '') {
              const dobArr = userInfo.dob.split('-');
              dob = dobArr.length === 3 ? dobArr[2]+'/'+dobArr[1]+'/'+dobArr[0] : format(new Date(userInfo.dob), "dd/MM/yyyy");
            }
            // const dob= userInfo.dob !== '' ? format(new Date(userInfo.dob), "dd/MM/yyyy") : ''
            const res= await verifyPAN({pan:panNumber.toUpperCase(), name:fullname, dob})
            if(res.data && res.data.code === 200){
              setButtonloading(false)
              if(disablePan){
                setCurrent('document')
              }else{
                setDisablePan(true)
                setPanNumber(res.data.data.maskedPanNumber)
              }
            }else{
              setButtonloading(false)
              if(res.data && res.data.message){
              setPanError(res.data.message)
              }else{
                setPanError('Something went wrong')
              }
          }
        }
    }catch(error){
      setButtonloading(false)
      console.log('error',error)
    }
  }
  }

  const onSubmit = async() => {
   //setButtonloading(true)
    if(ischecked){
    if(disablePan){
        setCurrent('document')
      }
      else{
        verifyPanSubmit()
      }
    }else{
      setShowTermsError(true)
    }
   // setButtonloading(false)
  }

  const checkPan  = () => {
    if(!/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(panNumber)){
      setPanError('Please Enter Valid PAN Number')
       return false
    }
    return true
  }

  const keyPressHandler = (event) => {
    if (event.which === 13) {
      onSubmit()
    }
  }

  return(
    <>
    {loading ? <SkeletonPan/> :
    <div className={styles.cardDiv}>
    <Card className={styles.cardSignUp}>
      <Card.Body className={styles.cardBodySignup}>
        <Row className={styles.title}>Let’s get started</Row>
        <Row className={styles.subTitle}>We need to verify your identity. This will be associated with your Cash Account.</Row>
        <Row className={styles.panDiv}>
          {
            disablePan ?
            <>
             <div className={styles.panBackGround}>
              <div className={styles.panLabel}>Permanent Account Number (PAN)</div>
              <div className={styles.flexs}>
                <div className={styles.panNumber}>{panNumber}</div>
                <div style={{lineHeight:'17px'}}>
                  <img src={Success}></img>
                </div>
              </div>
              </div>
            </>
            :
            <>
            <SignUpInput
            label='Permanent Account Number (PAN)'
            placeholder = 'Enter Permanent Account Number'
            classes={styles.panCardCapital}
            tabIndex='1'
            type='text'
            name='panNumnber'
            value={panNumber}
            onChange={event => {
              if(event.target.value.length <= 10){
              /^[A-Za-z0-9]*$/.test(event.target.value) &&
              setPanNumber(event.target.value,setPanError(''))
              }
            }}
            onKeyPress={(e) => keyPressHandler(e)}
            // isValid={panNumber && panError === ''}
            isInvalid={panError !== ''}
            onPaste={(e)=>{
              e.preventDefault()
              return false;
            }} onCopy={(e)=>{
              e.preventDefault()
              return false;}}
          />
          <div>
            <span className={styles.errorMessgae}>{panError}</span>
            <span className={styles.errorMessgae}
              style={{color:'#0061D3',cursor:'pointer'}}
              onClick={verifyPanSubmit}
            > Verify Now</span>
          </div>
          </>
          }
        </Row>
        <Row className={styles.verifyCheckBox}>
                <div className={`custom-control custom-checkbox ${styles.checkBox}`}>
                  <input
                    type='checkbox'
                    className={`custom-control-input ${styles.checkBoxInput}`}
                    id='panCheck'
                    name='panCheck'
                    value={ischecked}
                    onChange={() =>setIschecked(!ischecked)}
                    checked={ischecked}
                  />
                  <label
                    className={`custom-control-label ${styles.checkMark}  ${showTermsError ? styles.checkBoxError : ''}`}
                    htmlFor='panCheck'>
                      {
                      showTermsError ?
                       <span style={{color:'#FA274A'}}>You need to accept the
                         <a href={savingAccountDisclaimer} target='_blank' className={styles.disclosures}> Terms & Conditions </a>
                          to sign up</span>
                      :
                     <span>I have read, understood and agree to the
                        <a href={savingAccountDisclaimer} target='_blank' className={styles.disclosures}> Terms & Conditions </a>
                      </span>
                     }
                  </label>
                </div>
        </Row>
        <Row className='ml-0 mr-0' style={{marginTop:'32px'}}>
          <SignUpButton
            title='Sign Up'
            onClick={onSubmit}
            loading={buttonloading}
          />
        </Row>
      </Card.Body>
    </Card>
    </div> }
    </>
  )
}

export default Pan
