import React, { useContext } from 'react'
import { Button } from 'react-bootstrap'
import { ThemeContext } from '../../Context/ThemeContext'
import styles from './SignUpButton.module.css'

const SignUpButton = ({ classes, title, disabled, onClick }) => {
	const { buttonColor } = useContext(ThemeContext)
	return (
		<Button
			className={`${styles.button} ${classes} themeButton`}
			style={{ backgroundColor: buttonColor }}
			disabled={disabled}
			onClick={onClick}>
			{title}
		</Button>
	)
}

export default SignUpButton
