import React, { useState, useEffect, useRef} from 'react'
import { Col, Row } from 'react-bootstrap'
import styles from './cashManagement.module.css';
import logo from '../../public/images/stockalShapeLogo.svg';
import lock from '../../public/images/lockIcon.svg';
import visa from '../../public/images/visaCashManagement.svg';
// import animation from '../../public/images/virtualCardAnimation.svg';
import virtualAnimation from '../../public/images/virtualCardAnimation.mp4';
import cardImage from '../../public/images/virtualCardAnimation.svg';
import {getShowToken} from '../../api';
import { customOTP, validateTheOtp } from '../../api'
import PinInput from "react-pin-input"



const verygoodvault = "https://js.verygoodvault.com/vgs-show/1.3/"

const VirtualCardCash = (props) => {
  const [showCardNumber, setShowCardNumber] = useState(false);
  const [isPinEntered, setPinEntered] = useState(false)
  const [seconds, setSeconds] = useState(0)
  const [otp, setOtp] = useState()
  const [hashId, setHashId] = useState()
  const [askForOtpValid, setAskForOtpValid] = useState(false)
  const [showResendButton, setShowResendBUtton] = useState(false)
  const [otpError, setOtpError] = useState('')
  const [loadingText, setLoadingText] = useState(false)
  const [cardTimer, setCardTimer] = useState(0)
  const [clicked, setClicked] = useState(false)
  const [width, setWindowWidth] = useState(0);

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () =>
      window.removeEventListener('resize',updateDimensions);
   }, [])

  const updateDimensions = () => {
    const width = window.innerWidth
    setWindowWidth(width)
  }

  const sendOtp = () => {
    const body = { reason:"show_card"}
    customOTP(body).then(response=>{
        if(response.code === 200){
            setHashId(response.data.hashID)
            setShowResendBUtton(false)
            setClicked(false)
            setOtpError('')
        }
        }).catch((err)=>{
        console.error(err)
        setOtpError('error!')
        setShowResendBUtton(true)
      })
  }

  const resendOtp = () => {
    setSeconds(180)
    sendOtp()
    setShowResendBUtton(false)
  }

  const validateOtpForCard = (e) => {
    setOtp(e)
    setClicked(true)
    const data = {hashID:hashId, otp:e}
    validateTheOtp(data).then(response => {
      if(response.data.code === 200){
        setShowCardNumber(true)
        setAskForOtpValid(false)
        setOtpError('')
        setSeconds(0)
        showtheCardDetails()
        setTimeout(() => {
            setShowCardNumber(false)
          }, 180000);
        setCardTimer(180)
        setLoadingText(true)
      }else {
        // setSeconds(0)
        setOtpError('Incorrect OTP')
        // setShowResendBUtton(true)
        // setOtp('')
      }
    }).catch((err)=> {
        // setSeconds(0)
        setOtpError('Incorrect OTP')
        // setShowResendBUtton(true)
    })
  }

  const showNum = () => {
    sendOtp()
    setSeconds(180)
    setAskForOtpValid(true)
    setShowResendBUtton(false)
    setClicked(true)
  }

  useEffect(() => {
    if(loadingText){
      setTimeout(() => {
        setLoadingText(false)
      },5000)
    }
  },[loadingText])

  // useEffect(() => {
  //   if (askForOtpValid && seconds > 0) {
  //     const x = setTimeout(() => setSeconds(seconds - 1), 1000)
  //     if(clicked === true ){
  //       return () => clearTimeout(x);
  //     }
  //   }
  //   else if(seconds === 0 && askForOtpValid) {
  //     setShowResendBUtton(true)
  //     setSeconds(180)
  //   }
  // })

  useEffect(() => {
    if (seconds > 0) {
      const x = setTimeout(() => setSeconds(seconds - 1), 1000)
      if(clicked === true ){
        return () => clearTimeout(x);
      }
    }
    else {
      setSeconds(0)
      setShowResendBUtton(true)
    }
  })

  useEffect(() => {
    if (cardTimer > 0) {
      setTimeout(() => setCardTimer(cardTimer - 1), 1000)
    }
  },[cardTimer])

  const showtheCardDetails = () => {
    getShowToken(props.cardId).then(response=>{
      if(response.code === 200){
          getCardSecrets(response.data.showToken)
        }
      }).catch((err)=>{
        console.error(err)
    })
  }

  function addVgsScript(orgId, callback) {
    var vgsScript = document.createElement('script');
    vgsScript.id = 'vgs-script';
    vgsScript.src = verygoodvault+orgId+'.js';
    vgsScript.type = 'text/javascript';
    if(callback)
    vgsScript.onload = callback;
    document.head.append(vgsScript);
  }

  const getCardSecrets = (e) => {
    var orgId = process.env.REACT_APP_CASH_MANAGEMENT_VGS_ORG_ID;
    var vaultId = process.env.REACT_APP_CASH_MANAGEMENT_VGS_VAULT_ID;
    var cardId = props.cardId;
    var showToken = e;
    if(orgId !== '' && vaultId !== '' && cardId !== '' && showToken !== '') {

      addVgsScript(orgId, function() {
            var show = window.VGSShow.create(vaultId);
            var headers = {'sd-show-token' : showToken}

            /**
             * card number
             */
             const cardNoiframe = show.request({
              name: 'cardNoIframe',
              method: 'GET',
              headers,
              path: '/v1/card/'+cardId+'/show',
              jsonPathSelector: 'cardNumber',
              serializers: [show.SERIALIZERS.replace('(\\d{4})(\\d{4})(\\d{4})(\\d{4})', '$1 $2 $3 $4')],
          });
          cardNoiframe.render('#card-number', {color:'#3C404A', fontSize: '18px', display: 'block', height: '20px', overflow: 'hidden', fontFamily: 'monospace'});

          /**
           * cvv
           */
          const cvvIframe = show.request({
              name: 'cvvIframe',
              method: 'GET',
              headers,
              path: '/v1/card/'+cardId+'/show',
              jsonPathSelector: 'cvv'
          });
          cvvIframe.render('#cvv', {color:'#3C404A', fontSize: '16px', display: 'block', height: '16px', overflow: 'hidden', fontFamily: 'monospace'});

          /**
           * expiryMonth
           */
           const expiryMonthIframe = show.request({
              name: 'expiryMonthIframe',
              method: 'GET',
              headers,
              path: '/v1/card/'+cardId+'/show',
              jsonPathSelector: 'expiryMonth'
          });
          expiryMonthIframe.render('#exp-month', { color: '#3C404A', fontSize: '16px', display: 'block', height: '16px', overflow: 'hidden', fontFamily: 'monospace'});

          /**
           * expiryYear
           */
           const expiryYearIframe = show.request({
              name: 'expiryYearIframe',
              method: 'GET',
              headers,
              path: '/v1/card/'+cardId+'/show',
              jsonPathSelector: 'expiryYear'
          });
          expiryYearIframe.render('#exp-year', { color: 'black', fontSize: '16px', display: 'block', height: '16px', overflow: 'hidden', fontFamily: 'monospace'});
          });
    }
}

// const vidRef=useRef();

// useEffect(() => { vidRef.current.play(); },[]);
  useEffect(() => {
    const pinInputs = document.getElementsByClassName('pincode-input-text')
    if (pinInputs && pinInputs?.length > 0) {
      for (let i = 0; i < pinInputs.length; i++) {
        pinInputs[i].addEventListener('keydown', e => {
          if (
            e.key.toLowerCase() === 'control' ||
            e.key.toLowerCase() === 'meta' ||
            e.key.toLowerCase() === 'v' ||
            e.key.toLowerCase() === 'c'
          ) {
            e.preventDefault()
          }
        })
      }
    }
  }, [clicked])

  const userInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : {}
  const email = userInfo.emailAddress1
  return (
    <div className={styles.cashManagementVirtualCardOuter} onClick={askForOtpValid || showCardNumber ? "" : showNum}>
      {askForOtpValid && showCardNumber === false?
      <>
      <div className={styles.cashManagementVirtualCard}>
      <Row style={{marginBottom:"30px"}}>
        <Col lg={4} md={4} sm={4} xs={4}>
          <img src={logo}></img>
        </Col>
        <Col lg={8} md={8} sm={8} xs={8} className={styles.cashManagementVirtualCardHeading}>
          {/* {showResendButton === false ? */}
          {otpError === "" && !showResendButton ?
          <span>Expires in {seconds}s</span>
          : showResendButton === false ?
          <span>
          <span style={{color:"#FA274A",marginRight:"3px"}}>{otpError}</span>Expires in {seconds}s</span>
          :
          <span style={{color:"#FA274A"}}>OTP Expired
          <span className={styles.textBlue}
          style={{color:"#0061D3", cursor:"pointer",marginLeft:"3px"}}
          onClick={resendOtp}>
          Resend</span>
          </span>
          }
        </Col>
      </Row>
       <p className={styles.sixDigitCodeText}>Enter the 6-digit code sent to</p>
          <p className={styles.emailBlueText}>{email}  <a href='/profile' className={styles.textBlue}>Edit</a></p>
            <PinInput
                length={6}
                initialValue=""
                // secret
                type="numeric"
                inputMode="number"
                inputStyle={width <= 1200 && width >= 992  ? responsiveStyle.input : width <=370 ? responsiveStyle.input : style.input }
                style={{paddingTop:'16px',paddingBottom:'28px'}}
                // inputStyle={{border:"1px solid #D3D4DA",backgroundColor:"#FFFFFF",borderRadius:"6px",width:"40px",height:"40px",margin:"0px 4px"}}
                inputFocusStyle={{borderColor: '#3C404A'}}
                onComplete={(value, index) => validateOtpForCard(value)}
                value={otp}
                autoSelect={true}
                regexCriteria={/[0-9]/}
            />
        </div>
      </>
      :
      <>
      <div className={styles.cashManagementVirtualCard}>
      <Row style={{marginBottom:"30px"}}>
        <Col lg={6} md={6} sm={6} xs={6}>
          <img src={logo}></img>
        </Col>
        <Col lg={6} md={6} sm={6} xs={6} className={styles.cashManagementVirtualCardHeading}>
          {showCardNumber ? loadingText ?
          <span className={styles.laodingData}>
          Loading Data...
          </span> :
          <span>{cardTimer}</span> : <span> <img className={styles.cashManagementVirtualCardLockImg} src={lock}></img> Virtual Card</span>}
        </Col>
      </Row>
      {showCardNumber ?
      <>
      {/* {loadingText ?
      <span className={styles.laodingData}>
        Loading Data...
      </span>
      :
      <> */}
      <Row>
        <Col lg={12} className={styles.cashManagementVirtualCardEncodedNumber} style={{height:"30px"}}>
          <span id="card-number" className={styles.cardNumberFontFamily}></span>
        </Col>
      </Row>
      <Row style={{marginTop:"4px",marginBottom:"26px", height:"20px"}}>
        <Col lg={6} md={6} sm={6} xs={6}>
          <div className={styles.cashManagementVirtualCardCVV} style={{display:"flex"}}>
          <span className={styles.cashManagementVirtualCardExpiry}>Expiry</span>
          <span id="exp-month" style={{width:"18px",marginTop:"4px", marginLeft:"4px"}}></span>
          <span>/</span>
          <span id="exp-year" style={{width:"40px",marginTop:"4px"}}></span>
          </div>
        </Col>
        <Col lg={6} md={6} sm={6} xs={6} style={{height:"20px"}} className={styles.cashManagementVirtualCardExipiry} onClick={showNum}>
          <div style={{display:"block"}}>
          <span className={styles.cashManagementVirtualCardExpiry}>cvv</span>
          <span className={styles.cashManagementVirtualCardCVViFrame} id="cvv"></span>
          </div>
        </Col>
      </Row>
      {/* </> } */}
      </>
      :
      <>
      <Row>
        <Col lg={12} className={styles.cashManagementVirtualCardEncodedNumber}>
          <span>{`XXXX XXXX XXXX ${props.details ? props.details.cardNum : ""}`}</span>
        </Col>
      </Row>
      <Row style={{marginTop:"4px",marginBottom:"26px"}}>
        <Col lg={6} md={6} sm={6} xs={6} className={styles.cashManagementVirtualCardAmount}>
          <span className={styles.cashManagementVirtualCardExpiry}>Expiry</span><span className={styles.cashManagementVirtualCardCVV}>-- / --</span>
        </Col>
        <Col lg={6} md={6} sm={6} xs={6} className={styles.cashManagementVirtualCardExipiry} >
          <span>Tap to Unlock</span>
        </Col>
      </Row>
      </>}
      </div>
      <div style={{position:"relative"}}>
        <img className={styles.cashManagementVirtualCardImageBackground} src={cardImage} />
      {/* <video loop muted playsinline autoPlay={false} controls={false} poster={cardImage}  ref={ vidRef } className={styles.cashManagementVirtualCardImageBackground} style={{pointerEvents:"none"}}>
        {width > 992  ?
        <source src={virtualAnimation} type="video/mp4" style={{height:"56px"}} />
        : ""}
      </video> */}
      {/* <Row className={styles.cashManagementVirtualCardBottomRowText}>
            <Col lg={8} className={styles.cashManagementVirtualCardIssuedTo}>
              <span>
              {props.details ? props.details.displayName : "--"}
              </span>
            </Col>
            <Col lg={4} className={styles.cashManagementVirtualCardVisa}>
              <img src={visa}></img>
            </Col>
      </Row> */}
      <div className={styles.cardholderNameAndVisa}>
        <p style={{display:"inline-block"}} className={styles.cashManagementVirtualCardIssuedToName}>{props.details.displayName ? props.details.displayName : '--'}</p>
        <img src={visa} alt="visa-card" className={styles.visaCardImage}></img>
      </div>
      </div>
      </>}
    </div>
  )
}


const responsiveStyle = {
  input: {
    width: '30px',
    height: '35px',
    marginLeft:"4px",
    marginRight:"4px",
    border:"1px solid #D3D4DA",
    backgroundColor:"#FFFFFF",
    borderRadius:"6px"
  }
}

const style = {
  input: {
    width: '40px',
    height: '40px',
    marginLeft:"4px",
    marginRight:"4px",
    border:"1px solid #D3D4DA",
    backgroundColor:"#FFFFFF",
    borderRadius:"6px"
  }
}

export default VirtualCardCash
