import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import SkeletonElement from './SkeletonElement'
import Shimmer from './Shimmer'
import styles from './Allskeleton.module.css'

const popularStockSkeleton = () => {
	return (
		<Card className='skeletonCard' style={{ width: '100%', margin: '20px' }}>
			<Card.Body>
				<>
					<Row>
						<Col md={4}>
							<SkeletonElement
								type='circle'
								height='50px'
								width='50px'></SkeletonElement>
						</Col>
						<Col>
							<SkeletonElement width='70px' height='20px'></SkeletonElement>
							<SkeletonElement width='100px' height='15px'></SkeletonElement>
						</Col>
					</Row>
					<Row className='mb-3'>
						<Col>
							<SkeletonElement width='100%' height='20px' className={styles.rectangle}></SkeletonElement>
						</Col>
					</Row>
					<Row>
						<Col md={6}>
							<SkeletonElement width='70px' height='20px'></SkeletonElement>
							<SkeletonElement width='40px' height='15px'></SkeletonElement>
						</Col>
						<Col md={6}>
							<SkeletonElement width='70px' height='20px'></SkeletonElement>
							<SkeletonElement width='40px' height='15px'></SkeletonElement>
						</Col>
					</Row>
				</>
			</Card.Body>
		</Card>
	)
}

export default popularStockSkeleton
