import React, { useState, useEffect,createRef,useContext} from 'react'
import {Row,Col, Spinner} from 'react-bootstrap'
import styles from './easyfund.module.css'
import UploadFile from './UploadFile'
import UploadLater from './UploadLater'
import GenericLoading from "../../GenericLoading"
import { AddFundContext } from "../../AddFundContext"
import {uploadBankStatement,intiateTransactionEasyfund,getTransactionStatus} from "../../../../api"

const UploadingStatement = ({setShowDesc}) => {
  const {subPage, setSubPage, hashID, selectedAccount, easyfundTransDetail,transferOptions, seteasyfundTransDetail,setPage, setOnErrorRetry, setShowGenericError,showGenericError,setShowTryAgain, PAGE, SUBPAGE,transactionId,setUploadStatement,uploadStatement
  ,setNoClose,setNoHeader} = useContext(AddFundContext)
  const [statementCount,setStatementCount] = useState(1)
  const [loadingContinue, setLoadingContinue] = useState(false)
  const [showUploadLater,setShowUploadLater]= useState(false)
  const [accountStatement1, setaccountStatement1] = useState([])
	const [accountStatement2, setaccountStatement2] = useState([])
	const [accountStatement3, setaccountStatement3] = useState([])
	const [accountStatement4, setaccountStatement4] = useState([])
  const [password1,setPassword1] = useState('')
  const [password2,setPassword2] = useState('')
  const [password3,setPassword3] = useState('')
  const [password4,setPassword4] = useState('')
  const [fileAccept,setFileAccept] = useState({account1:true,account2:true,account3:true,account4:true})
  const accountStatement1Ref = createRef()
  const accountStatement2Ref = createRef()
  const accountStatement3Ref = createRef()
  const accountStatement4Ref = createRef()
  const [paymentStatus,setpaymentStatus] = useState('')
  const [errorMessage,setErrorMessage] =useState('')
  const EASYFUND = PAGE.EASYFUND

  useEffect(() => {
    setNoClose(true,setNoHeader(true))
    if(transactionId !== ''){
      getPaymentStatus()
    }
  },[])

  useEffect(() => {
    setNoClose(true,setNoHeader(true))
    if(paymentStatus === 'FAILED' || paymentStatus === 'REJECTED'){
      setShowGenericError(true,setShowTryAgain(false))
    }else{
    if(paymentStatus === 'HOLD'){
      setpaymentStatus('FUND_RECEIVED')
    }
    else if (paymentStatus !== 'FUND_RECEIVED') {
      var summaryInterval = setInterval(() => {
        getPaymentStatus();
      }, 10000);
    }
    // if(paymentStatus === 'FUND_RECEIVED' && uploadStatement === SUBPAGE[EASYFUND].AUTOUPLOADSTATEMENT){
    //   setSubPage(SUBPAGE[EASYFUND].AUTOUPLOADSTATEMENT)
    // }
  }
    return () => {
      clearInterval(summaryInterval);
    }

  },[paymentStatus,transactionId])

  useEffect(() => {
    setShowDesc(showUploadLater)
  },[showUploadLater])

  const getPaymentStatus = async () => {
    let success = true
    try{
        const resp = await getTransactionStatus(transactionId)
        if(resp && resp.doc){
          seteasyfundTransDetail(resp.doc)
          setpaymentStatus(resp.doc.paymentStatus)
        }
        success=true
    }catch(error){

      success=false
    }
    if(success && showGenericError){
      setShowGenericError(false)
      setOnErrorRetry(null)
      }else if(!success){
          setOnErrorRetry((showGenericError) => getPaymentStatus())
          setShowGenericError(true)
      }
  }

  const changeFileAccept = (value) => {
    setFileAccept({...fileAccept,...value})
  }

  const onDropPan = (name , file) => {
    console.log('file',file)
    if(file.length !== 0){
    file[0].preview = URL.createObjectURL(file[0])
    if(name === 'account1'){
      setaccountStatement1(file)
    }
    else if(name === 'account2'){
      setaccountStatement2(file)
    }
    else if(name === 'account3'){
      setaccountStatement3(file)
    }
    else if(name === 'account4'){
      setaccountStatement4(file)
    }
    setStatementCount(statementCount + 1)
  }
	}


	const onRemove = (name,callback) => {
    if(name === 'account1'){
      setaccountStatement1([],setPassword1(''))
    }
    else if(name === 'account2'){
      setaccountStatement2([],setPassword2(''))
    }
    else if(name === 'account3'){
      setaccountStatement3([],setPassword3(''))
    }
    else if(name === 'account4'){
      setaccountStatement4([],setPassword4(''))
    }
    if(callback){
      callback(name)
    }
	}

	const openPanDialog = (name) => {
	 if(name === 'account1' && accountStatement1Ref.current){
      accountStatement1Ref.current.open()
    }
    else if(name === 'account2' && accountStatement2Ref.current){
      accountStatement2Ref.current.open()
    }
    else if(name === 'account3' && accountStatement3Ref.current){
      accountStatement3Ref.current.open()
    }
    else if(name === 'account4' && accountStatement4Ref.current){
      accountStatement4Ref.current.open()
    }
	}


  const createForm = (file) => {
    const body = new FormData()
     body.append('file',file)
     return body
  }


  const UploadDoc = async() => {
    let res
    setLoadingContinue(true)
    try{
    if(accountStatement1.length && fileAccept.account1){
       res = await uploadBankStatement(transactionId,accountStatement1[0],createForm(accountStatement1[0]),password1)
    }
    if(accountStatement2.length && fileAccept.account2){
      res = await uploadBankStatement(transactionId,accountStatement2[0],createForm(accountStatement2[0]),password2)
    }
    if(accountStatement3.length && fileAccept.account3){
      res = await uploadBankStatement(transactionId,accountStatement3[0],createForm(accountStatement3[0]),password3)
    }
    if(accountStatement4.length && fileAccept.account4){
      res = await uploadBankStatement(transactionId,accountStatement4[0],createForm(accountStatement4[0]),password4)
    }
    if((accountStatement1.length || accountStatement2.length || accountStatement3.length ||  accountStatement4.length) && res ){
    onIntiateTransaction()
    }else{
      setShowDesc(true,setSubPage(SUBPAGE.SUCCESS))
    }
  }catch(error){
    setErrorMessage('Something went wrong; unable to upload file',setLoadingContinue(false))
   }
  }

  const onIntiateTransaction = async() => {
    let success = true
    try{
      const responseSubmit = await intiateTransactionEasyfund({transactionId:transactionId})
      if(responseSubmit.status === 200){
      setSubPage(SUBPAGE.SUCCESS)
      }else{
        success= false
      }
    }catch(error){
      success= false
    }
    if(success && showGenericError){
      setShowGenericError(false)
      setOnErrorRetry(null)
      }else if(!success){
          setOnErrorRetry((showGenericError) => onIntiateTransaction())
          setShowGenericError(true)
      }
  }

  const getStatement = () => {
   return(
     <>
        <UploadFile
        files={accountStatement1}
        changeFileAccept={changeFileAccept}
        onDrop={(file) => onDropPan('account1',file)}
        dropRef={accountStatement1Ref}
        openDialog={() => openPanDialog('account1')}
        onRemove={onRemove}
        name='account1'
        accept='image/jpeg, image/png, image/jpg, image/pdf, .pdf'
        maxSize='5000000'
        password={password1}
        setPassword={setPassword1}
      />
      {statementCount >= 2 ?
      <div style={{marginTop:'20px',width:'100%'}}>
        <UploadFile
        files={accountStatement2}
        changeFileAccept={changeFileAccept}
        onDrop={(file) => onDropPan('account2',file)}
        dropRef={accountStatement2Ref}
        openDialog={() => openPanDialog('account2')}
        onRemove={onRemove}
        name='account2'
        accept='image/jpeg, image/png, image/jpg, image/pdf, .pdf'
        maxSize='5000000'
        password={password2}
        setPassword={setPassword2}
      />
      </div>
      :''}
         {statementCount >= 3 ?
          <div style={{marginTop:'20px',width:'100%'}}>
        <UploadFile
        files={accountStatement3}
        changeFileAccept={changeFileAccept}
        onDrop={(file) => onDropPan('account3',file)}
        dropRef={accountStatement3Ref}
        openDialog={() => openPanDialog('account3')}
        onRemove={onRemove}
        name='account3'
        accept='image/jpeg, image/png, image/jpg, image/pdf, .pdf'
        maxSize='5000000'
        password={password3}
        setPassword={setPassword3}
      />
      </div>
      :''}
      {statementCount >= 4 ?
       <div style={{marginTop:'20px',width:'100%'}}>
        <UploadFile
        files={accountStatement4}
        changeFileAccept={changeFileAccept}
        onDrop={(file) => onDropPan('account4',file)}
        dropRef={accountStatement4Ref}
        openDialog={() => openPanDialog('account4')}
        onRemove={onRemove}
        name='account4'
        accept='image/jpeg, image/png, image/jpg, image/pdf, .pdf'
        maxSize='5000000'
        password={password4}
        setPassword={setPassword4}
      />
      </div>
      :''}
    </>
   )
  }


  return(
    <>{paymentStatus !== 'FUND_RECEIVED' ?
            <div className={styles.cancelContainer}>
              <div className={styles.cancelBody} style={{alignItems:'center'}}>
              <Spinner animation="border" className={styles.spinnerColor}/>
                 {paymentStatus === 'WAITING_FOR_FUND'? <span >
                  Your pop-up blocker may be preventing you from proceeding. To continue, please disable your pop-up blocker.
                </span>:""}
              </div>
            </div>
       :
    <>{
    showUploadLater ?
    <UploadLater setShowUploadLater={setShowUploadLater} onSubmit={() => setSubPage(SUBPAGE.SUCCESS)}/>
    :
    <>
    <div style={{marginBottom:'90px'}}>
      <Row className={`m-0`}>
        <Col md={1} xs={1} className='p-0'>
        <img src={ selectedAccount && selectedAccount.bank && selectedAccount.bank.logo} className={styles.easyFundLogo}/>
        </Col>
        <Col className={styles.bankName}>
         {selectedAccount && selectedAccount.bankName} - {selectedAccount && selectedAccount.accountNumber.toString().slice(-4)}
        </Col>
        <Col className={styles.textEasyFund}>
        EasyFund
        </Col>
      </Row>
      <Row className={`${styles.title}`}>
      Provide bank statement(s)
      </Row>
      <Row className={`${styles.subTitle} m-0 mt-1`}>
      Please upload bank statement(s) for the last 12 months including this transaction. You need to do this within 2 business days.
      </Row>
      <Row className={styles.uploadDiv}>
       {getStatement()}
    </Row>
    <Row className={styles.errorMessage} style={{marginLeft:'0px'}}>{errorMessage}</Row>
       {accountStatement1.length === 0 && accountStatement2.length === 0 && accountStatement3.length === 0 && accountStatement4.length === 0 ?
        <Row className={`${styles.subTitle} ${styles.laterBox}`}>
        I don’t have bank statements?
        <span style={{color:'#0061D3',cursor:'pointer'}} onClick={() => setShowUploadLater(true)}> &nbsp;I’ll Do It Later</span>
        </Row>:''}
    </div>
      <div className={styles.footerContainer}>
      <button className={styles.continueBtn} onClick={accountStatement1.length === 0 &&
                                                      accountStatement2.length === 0 &&
                                                      accountStatement3.length === 0 &&
                                                      accountStatement4.length === 0 ?
                                                      () => setShowUploadLater(true) :
                                                      () => UploadDoc()} disabled={loadingContinue}>
          {loadingContinue&&<Spinner animation="border" className={styles.spinnerColor}/>}
          Continue
      </button>
      {/* {transferOptions && Object.keys(transferOptions).length>=1 && <button className={styles.tertiaryBtn} onClick={()=>!loadingContinue&&setPage(PAGE.TRANSFER)}>Try Another Method</button>} */}
  </div>
  </>}
  </>}
  </>
  )

}

export default UploadingStatement
