import React, { useState, useEffect, useContext } from 'react'
import { Card, Col, Row,OverlayTrigger,Tooltip } from 'react-bootstrap'
import Rating from 'react-rating'
import styles from './TrendingStocksCard.module.css'
import { getPriceHistory } from '../../api'
import { CardChart } from '../index'
import { Link } from 'react-router-dom'
import { isNumber } from 'lodash'
import { calPriceChange_New, addCommasinPrice } from '../../utils'
import { SocketContext } from '../../socket-context'
import shariyaStarIcon from '../../public/images/shariyaStarIcon.svg'
import shariyaStarIconGrey from '../../public/images/shariyaStarIconGrey.svg'

const  TrendingStocksCard = ({
  stockImage,
  stockTitle,
  stockName,
  stockValue,
  stockIncrement,
  increment,
  style,
  stockChange,
  isHalal,
  rating
}) => {
  const close = stockValue - stockChange;
  const socket = useContext(SocketContext);
  const [isLoaded, setIsloaded] = useState(true)
  // const [graphData, setGraphData] = useState([])
  // useEffect(() => {
  //  const asynchFetch = async () => {
  //    const pricedata = []
  //    if (stockTitle !== '') {
  //      const res = await getPriceHistory(stockTitle, 90)
  //      res &&
  //        res.map((item, index) => {
  //          pricedata.push(item.price)
  //        })
  //      setGraphData(pricedata)
  //    }
  //  }
  //  asynchFetch()
  // }, [stockTitle])
  const [priceTicker, setPriceTicker] = useState({stockTitle, currentPrice: stockValue})
  useEffect(() => {
     setPriceTicker({
      stockTitle, currentPrice: stockValue
     })
  },[stockTitle])
  let currentPrice;

  useEffect(() => {

    const priceListener = (priceState) => {
      // let data = JSON.parse(priceState)
      let { Symbol,price}  = priceState;
      stockValue = price && price;
      currentPrice = parseFloat(stockValue);
    setPriceTicker((prevPrice) => {
        let priceChange = currentPrice - prevPrice.currentPrice
        return {
          stockTitle: Symbol,
          currentPrice,
          prevPrice: prevPrice.currentPrice,
          priceChange,
        }
      })
    }

    if(socket) {
      socket.emit('join_symbol', stockTitle);
      socket.on(stockTitle, priceListener)
    }

    return () => {
      if(socket){
        socket.off(stockTitle, priceListener)
      }
    }
  }, [socket, stockTitle])

  let priceBoxColor;

  useEffect(() => {
    if(priceTicker['priceChange'] && isNumber(priceTicker['priceChange']) && !isNaN(priceTicker['priceChange'])){
      priceBoxColor = priceTicker['priceChange'] > 0 ? '0DC014' : '#FA274A'
    }
  }, [priceTicker['priceChange']])

  let {change, changePer} = calPriceChange_New({
    currPrice: priceTicker.currentPrice,
    close,
   });

   stockChange = change
   stockIncrement = changePer
   increment = stockIncrement > 0 ? true : false

  return (
<Link to={`/stockdetail/${stockTitle}`} style={{ textDecoration: 'none' }}>
		<Card className={styles.trendingCard} style={style}>
			<Card.Body className={styles.cardBody}>
				<Row className={styles.padding}>
					<Col md={4} xs={3} className={styles.imageCol}>
						<div className={styles.stockDiv}>
							{stockImage && isLoaded ? (
								<img
									onError={() => {
										setIsloaded(false)
									}}
									className={styles.stockImage}
									src={stockImage}
									alt={stockTitle.substring(0, 1)}></img>
							) : (
								<h3 className='text-center' style={{ paddingTop: '10px' }}>
									{stockTitle?.substring(0, 1)}
								</h3>
							)}
						</div>
					</Col>
					<Col className={`${styles.companyRes} d-none d-md-block`} md={8}>
						<div className={styles.stockTitle}>{stockTitle}</div>
            <OverlayTrigger
									placement='bottom'
                  trigger={['hover', 'focus','click']}
									overlay={
										<Tooltip>
										{stockName}
										</Tooltip>
									}>
										<div className={styles.stockName}>
							{stockName?.length > 20
								? `${stockName.substring(0, 20)}...`
								: stockName}
						</div>
								</OverlayTrigger>

					</Col>
					<Col className={`${styles.companyRes} d-block d-md-none`} xs={9}>
						<div className={styles.stockTitle}>{stockTitle}</div>
						<div className={styles.stockName}>
							{stockName?.length > 15
								? `${stockName.substring(0, 15)}...`
								: stockName}
						</div>
					</Col>
				</Row>
				<Row className={styles.paddingAnother}>
					<Col md={5} xs={5} className={styles.mobileResponsive}>
						<div
              className={`${styles.stockValue} mb-1`}>{
              // `$${stockValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
              `$${addCommasinPrice(typeof priceTicker.currentPrice === 'number' ? priceTicker.currentPrice.toFixed(2) : priceTicker.currentPrice)}`
              }</div>
            {stockIncrement ?
						<div
							className={styles.stockIncrement}
							style={{
								color: `${stockIncrement > 0 ? '#0DC014' : '#FA274A'}`
							}}>
							{stockIncrement > 0 ? `+${stockIncrement}` : stockIncrement}%
						</div> : ''}
					</Col>
					<Col className={styles.graph} md={7} xs={7} style={{paddingLeft: isHalal ? "0px" : "15px"}}>
          {isHalal && isHalal === true ?
          <div className={styles.halalRating}>
            <p>Halal</p>
            <Rating
              fractions={1}
              initialRating={rating}
              readonly
              emptySymbol={<img src={shariyaStarIconGrey} alt="icon"></img>}
              fullSymbol={<img src={shariyaStarIcon} alt="icon"></img>}
            />
          </div> :
            <div>
              <img className={styles.graphImage} src={`https://price-graphs.stockal.com/${stockTitle}.png`} alt="graph"></img>
            </div>
            }
          </Col>
        </Row>
      </Card.Body>
    </Card>
    </Link>
  )
}
export default TrendingStocksCard
