import React, { useState, useEffect } from 'react'
import queryString from 'query-string'
import styles from './easyfund.module.css'

const StatusScreen = () => {

    const [timer, setTimer] = useState(3)
    const [isSuccess, setIsSuccess] = useState(false)

    useEffect(()=>{
        const interval = setInterval(() => {
            setTimer(timer-1);
        }, 1000);

        return () => clearInterval(interval);
    },[timer])

    useEffect(() => {
        setTimeout(() => {
            window.close()
        },3000)
    },[])

    useEffect(()=>{
        const queryUrl = queryString.parse(window.location.search, {
          parseNumbers: false
        })
        if(queryUrl.status && (queryUrl.status === 'success' || queryUrl.status === 'SUCCESS')) {
          setIsSuccess(true)
        }
      },[])

    return (
        <>
            <div className={styles.statusScreenContainer}>
                <div className={styles.statusMessage}>
                    {/* {isSuccess ? <>
                        <p>Transaction is Successful!!</p>
                    </> : <>
                        <p>Sorry, your transaction failed.</p>
                    </>} */}
                </div>
                <div className={styles.statusTimerMessage}>Redirecting you in...</div>
                <div className={styles.statusTimer}>{timer}</div>
            </div>
        </>
    )
}

export default StatusScreen
