
import React,{useEffect,useState} from 'react'
import {useHistory} from 'react-router'
import {Spinner} from 'react-bootstrap'
import styles from './sso.module.css'

const Sso = () => {
  let history = useHistory()
  const [endpoint,setEndpoint] = useState('')
  useEffect(() => {
    let getCookies = document.cookie.split(';')
    for(let i =0;i<getCookies.length;i++){
      const splited = getCookies[i].split("=")
       if(splited && splited[0].includes("token")){
         if(splited[1])
         sessionStorage.setItem('token', splited[1])
       }
       if(splited && splited[0].includes('endpoint')){
        setEndpoint(splited[1])
       }
    }
    history.push(endpoint)
  },[])

  return(
    <div className={styles.spinner}><Spinner animation="border" className={styles.spinnerColor}/></div>
  )
}

export default Sso
