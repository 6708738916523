import React from 'react';
import {Link} from 'react-router-dom'
import styles from './sso.module.css';
import SignUpButton from '../../components/SignUpButton/SignUpButton'


const SsoError = ({message}) => {
  return (
    <div className={styles.emptyPageParent}>
      <div className={styles.emptyPageHeadText}> {message}</div>
      {message === 'Invalid session credentials' ?
      <Link to='/signin'>
      <SignUpButton
        title='Sign in'
        classes={styles.SigninButton}
      />
      </Link> : ''}
    </div>
  )
}

export default SsoError;
