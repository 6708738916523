import React,{useState,useEffect} from 'react'
import { Row, Col,Button } from 'react-bootstrap'
import {onAccountDetected} from '../../api'
import UserSvg from '../../public/images/newOnboarding/User.svg'
import InfoSvg from '../../public/images/newOnboarding/Info.svg'
import styles from './SignUp.module.css'
import { useHistory } from 'react-router-dom'

const UserName = ({formData,changeCurrent}) => {
  let history = useHistory()
  const [userNameDetail,setUserNameDetail] = useState([])
  const [enableCreate,setEnableCreate] = useState(true)
  const {email = ''} = formData
  const [loading,setLoading] = useState(true)

  useEffect(() => {
     const hanldeEffect = async() => {
        try{
          setLoading(true)
          const resp = await onAccountDetected(email)
          if(resp && resp.code === 200){
            const {data} = resp
            if(data.length > 0){
              if(data.length >= 5){
                const updatedList = resp.data
                setEnableCreate(false,setUserNameDetail([...updatedList]))
              }else{
                setUserNameDetail([...resp.data])
              }
            }else{
              changeCurrent('PhoneNumber')
            }
          }
        }
        catch(error){
          console.log('error')
        }
        setLoading(false)
     }
     hanldeEffect()
  },[])

  console.log('loading',loading)

  return(
    <>
      <Row className={styles.title}>Account(s) detected</Row>
      <Row className={styles.subTitle}>We’ve found the following accounts linked to this email address with the following usernames:</Row>
       {enableCreate ? '' :
        <Row className={styles.assignUser}>
          <Col md={1} className='p-0'>
            <img src={InfoSvg} style={{width:'20px'}}></img>
          </Col>
          <Col className={styles.subTitle} style={{color:'#3C404A',paddingLeft:'8px'}}>
          A single email address cannot be associated with more than 5 accounts. Please log in to
       an existing account or use another email.
          </Col>
        </Row>
       }
      <Row className={styles.userNameCard}>
        {userNameDetail && userNameDetail.map((item,index) => {
          return(
            <Row className={styles.userSigleRow} key={index} style={index+1 === userNameDetail.length ? {borderBottom:0} : {}}>
            <Col md={1}>
              <img src={UserSvg} className={styles.userIcon}></img>
            </Col>
            <Col className={styles.userNameTitle}>{item}</Col>
            <Col className={styles.loginLink} onClick={() => {
              sessionStorage.clear()
              localStorage.clear()
              history.push(`/signin?username=${item}`)
            }}>Login</Col>
          </Row>
          )
        })}
      </Row>
      {enableCreate && !loading ?
      <Row className='ml-0 mr-0 mt-4'>
        <Button
        className={styles.userNamebutton}
        onClick={() => {changeCurrent('PhoneNumber')}}
        >
        Create a New Account
      </Button>
      </Row> : ''}
    </>
  )
}

export default UserName
