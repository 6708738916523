import React from 'react'
import {Button} from 'react-bootstrap'
import DropdownSvg from '../../../public/images/newOnboarding/Dropdown.svg'
import DropUpSvg from '../../../public/images/newOnboarding/DropUp.svg'
import styles from './DropDownButton.module.css'

const DropDownButton = ({onClick,showDropDown,children,error,classes,disabled = false,tabIndex}) => {
   return(
    <Button className={`${styles.dropdownButton} ${classes}`}
      onClick={disabled ? '' : () => onClick()}
      style={error === '' ? {} : {border:'1.5px solid #FA274A'}}
      tabIndex={tabIndex}
      >
      <div className={styles.childrenText}>
        {children}
      </div>
      <div>
      <img src={showDropDown ? DropUpSvg : DropdownSvg} style={{width:'10px'}}></img>
    </div>
    </Button>
   )
}

export default DropDownButton
