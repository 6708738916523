import React, { useState, useEffect, useRef } from 'react'
import styles from './setupOtherBankAccount.module.css'
import { Row, Col } from 'react-bootstrap'
import CheckBox from '../CheckBox/CheckBox'
import backImage from '../../public/images/back.png'
import { postAddBankAccount,getBankAccount } from '../../api'
import DownloadInstruction from './downloadInstruction'
const SetupOtherBankAccount = ({
	setModalData,
	data,
	back,
	buttonColor,
  toggel,
  checkBank,
  setHashId,
	// review,
	// setReview,
	setBankDetails,
	bankDetails,
  setFoundAccount,
  setOtherCountryDetails,
	clickedBankDetails,
	setupIndianAccount,
  setSelectedBank
}) => {
  const editFieldRef = useRef(null);
  const [loading,setLoading] = useState(false)
  const [errorMessage,setErrorMessage] = useState('')
  const [instruction,setInstruction] = useState('')
	const handleChange = e => {
		const re = /^[0-9a-zA-Z\b]+$/
		if (e.target.value === '' || re.test(e.target.value)) {
			setBankDetails({
				...bankDetails,
				beneficiaryAccountNumber: e.target.value
			})
			// setReview({ ...review, accountNumber: e.target.value })
		}
	}

	const [check, setCheck] = useState(true)
	const [name, setName] = useState('')
	useEffect(() => {
    editFieldRef.current.focus()
		const { firstName, lastName } = JSON.parse(sessionStorage.getItem('userInfo'))
		setName(`${firstName} ${lastName}`)
		setBankDetails({
			isMyAccount: false,
      isIndian:toggel,
			beneficiaryAccountNumber: '',
			beneficiaryAccountType:
				back === setupIndianAccount
					? clickedBankDetails && clickedBankDetails.bankType
						? clickedBankDetails.bankType
						: ''
					: '',
			beneficiarySwiftABA: '',
			bankName:
				back === setupIndianAccount
					? clickedBankDetails && clickedBankDetails.bankName
						? clickedBankDetails.bankName
						: ''
					: '',
			bankIFSC: '',
			bankAddress: '',
			nationalBankId:clickedBankDetails && clickedBankDetails.bankId
						? clickedBankDetails.bankId
						: ''
		})
    if(checkBank === 'online' && toggel && clickedBankDetails && clickedBankDetails.bankName){
      let value =''
      if(clickedBankDetails.bankName.includes('ICICI')){
        value = 'ICICI_BANK'
      }
      else if(clickedBankDetails.bankName.includes('HDFC')){
        value = 'HDFC_BANK'
      }
      else if(clickedBankDetails.bankName.includes('IDFC')){
        value ='IDFC_BANK'
      }
      else if(clickedBankDetails.bankName.includes('INDUSIND') || clickedBankDetails.bankName.includes('IndusInd')){
        value = 'INDUSIND_BANK'
      }
      else if(clickedBankDetails.bankName.includes('KOTAK')){
        value = 'KOTAK_BANK'
        }
      else{
        value = ''
      }
      setInstruction(value)
    }
		// setReview({
		// 	bankName: '',
		// 	ifsc: '',
		// 	optional: '',
		// 	accountNumber: '',
		// 	bankAddress: '',
		// 	fund: review.fund
		// })
	}, [])
	useEffect(() => {
    if(toggel){
    bankDetails.bankName !== '' &&
    bankDetails.bankIFSC !== '' &&
    bankDetails.beneficiaryAccountNumber !== '' &&
    bankDetails.bankAddress !== '' &&
    bankDetails.isMyAccount ?
    setCheck(false) :setCheck(true)
    }else{
      bankDetails.bankName !== '' &&
      bankDetails.beneficiaryAccountNumber !== '' &&
      bankDetails.bankAddress !== '' &&
      bankDetails.isMyAccount ?
      setCheck(false) :setCheck(true)
    }
	}, [bankDetails])

	const asynchFetch = async () => {
    setLoading(true)
    try{
		  const res = await postAddBankAccount(bankDetails)
      if(res && res.code === 200){
        const response = await getBankAccount()
        if(response.length){
          setFoundAccount(true,
            setOtherCountryDetails(response),setSelectedBank(response[0]),setModalData(data))
        }
      }
      else{
        setErrorMessage('Something Went Wrong')
      }
    }catch(error){
     setErrorMessage('Something Went Wrong')
    }
    setLoading(false)
	}
	// console.log(clickedBankDetails)
  let userInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')).accountStatus : '';
  let accountStatus = userInfo === 2 ? false : true
	return (
		<div className={styles.card}>
			<Row>
				<Col>
					<div
						className={styles.title}
						onClick={() => setModalData(back)}
						style={{ cursor: 'pointer' }}>
						<img src={backImage} />
						<span
							style={{
								paddingLeft: '20px'
							}}>
							{`Setup Bank Account`}
						</span>
					</div>
				</Col>
			</Row>
        {accountStatus ?
        <Row>
        <Col className={styles.description} style={{color:'#FA274A'}}>
          Your account is not approved, Please try again after sometime
        </Col>
        </Row>
        : ''}
        	<Row>
				<Col>
					<div className={styles.description}>
						<span className={styles.important}>Important:</span> Please make
						sure to enter correct bank details as this will be set as default
						and you will be allowed to add funds from this account.
            {instruction !== '' ?
              <div className='pt-2'>
                {/* <span onClick={()=>downloadInstructionPDF(instruction)} style={{marginLeft:'4px',textDecoration:'underline',color:'#005dd6'}}> */}
				<DownloadInstruction bank={instruction}/>
			  </div>
              :''
            }
					</div>
				</Col>
			</Row>
			<Row>
				<Col md={12}>
					<div className={styles.holder}>Account holder Name</div>
				</Col>
				<Col md={12}>
					<div className={styles.name}>{name}</div>
				</Col>
			</Row>
			<Row>
				<Col md={6} style={{ marginTop: '32px' }}>
					<div className={styles.heading}>Bank’s Name</div>
					<input
						className={styles.bankInput}
						placeholder='Enter Bank name'
						onChange={e =>
							setBankDetails({ ...bankDetails, bankName: e.target.value })
						}
            ref={editFieldRef}
            tabIndex='1'
						value={bankDetails.bankName}
						readOnly={back === setupIndianAccount ? checkBank === 'other' ? false : true : false}
					/>
				</Col>
				{toggel ?
        <Col md={6} style={{ marginTop: '32px' }}>
					<div className={styles.heading}>Bank’s IFSC code</div>
					<input
						className={styles.bankInput}
						placeholder='Enter IFSC code'
            tabIndex='2'
						onChange={e =>
							setBankDetails({ ...bankDetails, bankIFSC: e.target.value })
						}
						value={bankDetails.bankIFSC}
					/>
				</Col> :
        	<Col md={6} style={{ marginTop: '32px' }}>
					<div className={styles.heading}>
            Enter SWIFT
					</div>
					<input
           tabIndex='5'
						className={styles.bankInput}
						placeholder='Enter SWIFT'
						onChange={e =>
              {
              if(e.target.value.length <= 11){
							setBankDetails({
								...bankDetails,
								beneficiarySwiftABA: e.target.value
							})
              }}
						}
						value={bankDetails.beneficiarySwiftABA}
					/>
				</Col>
        }
				<Col md={6} style={{ marginTop: '32px' }}>
					<div className={styles.heading}>Bank account number/IBAN number</div>
					<input
           tabIndex='3'
						className={styles.bankInput}
						placeholder='Enter account number/IBAN number'
						onChange={handleChange}
						value={bankDetails.beneficiaryAccountNumber}
					/>
				</Col>
				<Col md={6} style={{ marginTop: '32px' }}>
					<div className={styles.heading}>Bank’s Address</div>
					<input
           tabIndex='4'
						className={styles.bankInput}
						placeholder='Enter Bank address'
						onChange={e =>
							setBankDetails({ ...bankDetails, bankAddress: e.target.value })
						}
						value={bankDetails.bankAddress}
					/>
				</Col>
        {toggel ?
				<Col md={6} style={{ marginTop: '32px' }}>
					<div className={styles.heading}>
						Enter SWIFT (Optional)
					</div>
					<input
           tabIndex='5'
						className={styles.bankInput}
						placeholder='Enter SWIFT'
						onChange={e =>
              {
                if(e.target.value.length <= 11){
							setBankDetails({
								...bankDetails,
								beneficiarySwiftABA: e.target.value
							})
						} }}
						value={bankDetails.beneficiarySwiftABA}
					/>
				</Col> : '' }
			</Row>
			<Row style={{ marginTop: '24px' }} className={styles.marginBankAccount}>
				<Col>
					<div>
						<input
							type='checkbox'
							onChange={() =>
								setBankDetails({
									...bankDetails,
									isMyAccount: !bankDetails.isMyAccount
								})
							}
							className={styles.box}
							value={bankDetails.isMyAccount}
						/>

						<span className={styles.agree}>
							I agree that this bank account belongs to me
						</span>
					</div>
				</Col>
			</Row>
      {errorMessage === '' ? '' : <div className={styles.erorrMessage}>{errorMessage}</div>}
			<Row className={styles.fixedDiv}>
				<Col>
					<button
						onClick={() => asynchFetch()}
						type='button'
						style={{ backgroundColor: buttonColor }}
						disabled={check || loading || accountStatus}
						className={`btn btn-primary btn-sm ${styles.addfunds} themeButton`}>
						<span className={styles.addfundstext}>Continue</span>
					</button>
				</Col>
			</Row>
		</div>
	)
}

export default SetupOtherBankAccount
