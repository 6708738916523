import React from 'react';
import SuccessLottie from "../../../public/lottie/success.json"
import Lottie from 'react-lottie-player';
import styles from './AccountTransfer.module.css'


const SuccessAcatTransfer = () => {
    return(
        <div className={styles.containerSuccess}>
            <div className={styles.lottieCenter}>
                <Lottie
                rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
                loop={false}
                animationData={SuccessLottie}
                play
                style={{ width: 150, height: 150 }}
                />
            </div>
        <p className={styles.suceessText}>You have successfully initiated the account transfer.</p>
        <p className={styles.suceessText} style={{fontSize:"16px"}}>Kindly expect the transfer to be completed in 3 - 6 business days</p>
        </div>
    )
}

export default SuccessAcatTransfer