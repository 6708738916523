import React,{useState, useEffect, useContext} from 'react'
import styles from './ExploreStocksEtf.module.css'
import ShariahComplianceRating from '../ShariahComplianceRating/ShariahComplianceRating'
import {decimalAdjust,parseNumericMillion,addCommasinPrice,calPriceChange_New}  from '../../utils'
import { OverlayTrigger,Tooltip} from 'react-bootstrap'
import { SocketContext } from '../../socket-context'
const ExploreRow = ({
  onLink,
  link,
  logo,
  code,
  company,
  price,
  change,
  percentChange,
  marketCapitalization,
  yearHigh,
  yearLow,
  close,
  shariahStatus,
  shariahRating,
  userLocation
}) => {
  const socket = useContext(SocketContext);
  const [isLoaded, setIsloaded] = useState(true)
  const [priceTicker, setPriceTicker] = useState({code, currentPrice: price})

  let currentPrice;

  useEffect(() => {

    const priceListener = (priceState) => {
      // let data = JSON.parse(priceState)
      let { Symbol,price}  = priceState;
      price = price && price;
      currentPrice = parseFloat(price);
    setPriceTicker((prevPrice) => {
        let priceChange = currentPrice - prevPrice.currentPrice
        return {
          code: Symbol,
          currentPrice,
          prevPrice: prevPrice.currentPrice,
          priceChange,
        }
      })
    }

    if(socket) {
      socket.emit('join_symbol', code);
      socket.on(code, priceListener)
    }

    return () => {
      if(socket){
        socket.off(code, priceListener)
      }
    }
  }, [socket])

  let {changePer} = calPriceChange_New({
    currPrice: priceTicker.currentPrice,
    close,
   });


   percentChange = changePer;

  return (
    <tr className={styles.tableRow} onClick={() => onLink(link,code)}>
      <td className={styles.stockImage} style={{ paddingRight: '0px',paddingLeft:'0px'}}>
      {logo && isLoaded ? (
									<img
										onError={() => {
											setIsloaded(false)
										}}
										className={styles.imageCompany}
										src={logo}
										alt={code.substring(0, 1)}></img>
								) : (
									<h3 className={`text-center ${styles.imagePaddingRemove}`}>
										{code.substring(0, 1)}
									</h3>
								)}
      </td>
      <td className={styles.symbol}>
        <p style={{margin:"0px"}}>{code}</p>
        <OverlayTrigger
          placement='bottom'
          trigger={['hover', 'focus','click']}
          overlay={
            <Tooltip className={styles.tableData}>
              {company}
            </Tooltip>
          }>
          <div className={styles.creator}>
            {company.length > 8 ? `${company.substring(0,8)}...` : company}
          </div>
				</OverlayTrigger>
      </td>
      <td className={styles.tableData}>
        <p>
          {`$${addCommasinPrice(decimalAdjust('floor',priceTicker.currentPrice,-2)) }`}
        </p>
        <p className={styles.percentageChangeValues}>
          {change.toString().includes('-') ? <span style={{color:"#FA274A"}}>-${change.toString().replace('-','')}</span> : <span style={{color:"#0DC014"}}>+${change}</span>}
          <span style={{color: percentChange.toString().includes('-') ? "#FA274A" : "#0DC014"}}>
            {percentChange.toString().includes('-') ? ` [${percentChange}]` : ` [+${percentChange}]`}%
          </span>
          <span style={{color:"#767C8F"}}>{` 1D`}</span>
        </p>
      </td>
      <td className={styles.tableData}>{marketCapitalization ? parseNumericMillion(marketCapitalization) :'--'}</td>
      <td className={styles.tableData}>{yearHigh ? typeof yearHigh === 'string' ?
                `$${addCommasinPrice(decimalAdjust('floor',yearHigh,-2))}` : `$${addCommasinPrice(decimalAdjust('floor',yearHigh,-2))}` : '--'}</td>
      <td className={styles.tableData}>{yearLow ? typeof yearLow === 'string' ?
                `$${addCommasinPrice(decimalAdjust('floor',yearLow,-2))}` : `$${addCommasinPrice(decimalAdjust('floor',yearLow,-2))}` : '--'}</td>
      {userLocation === 'MENA' ?
        <td className={styles.tableData}>
          <ShariahComplianceRating shariahStatus={shariahStatus} rating={shariahRating}/>
        </td> : ''
      }
    </tr>
  )
}
export default ExploreRow
