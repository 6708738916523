import { gethighLowStocks } from '../../api'
import { YEAR_HIGH } from './action'

const getYearHighStocks = () => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			gethighLowStocks('yearHigh').then(response => {
				if (response && response.length > 0) {
					resolve(dispatch({ type: YEAR_HIGH, payload: response}))
				}
			})
		})
	}
}

export default getYearHighStocks
