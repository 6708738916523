import React, { useEffect, useState } from 'react'
import styles from './ShariahComplianceRating.module.css'
import Rating from 'react-rating'
import shariyaStarIcon from '../../public/images/shariyaStarIcon.svg'
import shariyaStarIconGrey from '../../public/images/shariyaStarIconGrey.svg'

const ShariahComplianceRating  = (props) => {

    const { shariahStatus, rating } = props

    const [ shariahStatusText, setShariahStatusText] = useState()
    const [ isShariahCompliant, setIsShariahCompliant] = useState(false)
    const [ textColor, setTextColor ] = useState()
    const [ background, setBackground] = useState()

    const setShariahData = () => {
        if(shariahStatus) {
            if(shariahStatus === 'COMPLIANT'){
                setShariahStatusText('Halal')
                setTextColor('#0DC014')
                setBackground('#ECFAEC')
                setIsShariahCompliant(true)
            } else if(shariahStatus === 'NON_COMPLIANT') {
                setShariahStatusText('Not Halal')
                setTextColor('#FA274A')
                setBackground('#FFEEF1')
            } else if(shariahStatus === 'QUESTIONABLE') {
                setShariahStatusText('Doubtful')
                setTextColor('#FA5D05')
                setBackground('#FFF2EB')
            } else if(shariahStatus === 'NOT_UNDER_COVERAGE') {
                setShariahStatusText('Not Covered')
                setTextColor('#767C8F')
                setBackground('#F7F7F8')
            } 
        } else {
            setShariahStatusText('Not Covered')
            setTextColor('#767C8F')
            setBackground('#F7F7F8')
        }
    }

    useEffect(()=>{
        setShariahData()
    },[])

    return (
        <div className={styles.shariyaComplianceContainer} style={{backgroundColor:background}}>
            <p style={{color:textColor,textAlign:"center"}}>{shariahStatusText}</p>
            {isShariahCompliant ? 
                <div className={styles.ratingContainer}>
                    <Rating 
                        fractions={1} 
                        initialRating={rating} 
                        readonly 
                        emptySymbol={<img src={shariyaStarIconGrey} alt="icon"></img>} 
                        fullSymbol={<img src={shariyaStarIcon} alt="icon"></img>}
                    />
                </div> : ''
            }
        </div>
    )
}

export default ShariahComplianceRating