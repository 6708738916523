import React, {useEffect} from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import styles from './etfOtherInfo.module.css'


const Etfotherinfo = (etfData,code='') => {
  const otherData = [{otherTitle:"Factset Industry", value: etfData ? etfData.etfData ? etfData.etfData.ETFIndustry ? etfData.etfData.ETFIndustry : '--':'--'  : '--'},
  // {otherTitle:"Instrument class", value:"128.77"},
  {otherTitle:"Instrument level sector", value: etfData ? etfData.etfData ? etfData.etfData.ETFSector ? etfData.etfData.ETFSector : '--':'--'  : '--'},]


  return(
    <>
    <Row>
      <Col className={styles.title}>Other Info</Col>
    </Row>
    <Row className = 'd-none d-lg-flex' style={{ marginTop: '24px' }}>
          {otherData.map((item, i) => {
            return (
              <Col md={4} key={i} style={{ paddingBottom: '24px' }}>
                <div className={styles.head}>{item.otherTitle}</div>
                <div className={styles.val}>{item.value}</div>
              </Col>
            )})}
    </Row>
     <div className='d-block d-lg-none' style={{ marginTop: '24px' }} >
          {otherData.map((item, i) => {
            return (
              <Row xs={3} style={{ paddingBottom:"16px",paddingTop:"16px", borderBottom: i !== 1 && '1px solid #EFEFF1' }}>
              <Col key={i}>
                <div className={styles.head}>{item.otherTitle}</div>
              </Col>
              <Col xs={8}>
                <div className={styles.val}>{item.value}</div>
              </Col>
              </Row>
            )})}
    </div>
  </>
  )

}


export default Etfotherinfo
