import React from 'react'
import styles from './CheckBox.module.css'

const checkBox = ({ id, label, onChange, isChecked, name, value,tabIndex }) => {
	return (
		<div className={`custom-control custom-checkbox ${styles.checkBox}`}>
			<input
        tabIndex={tabIndex}
				type='checkbox'
				className={`custom-control-input ${styles.checkBoxInput}`}
				id={id}
				name={name}
				value={value}
				onChange={onChange}
				checked={isChecked}
			/>
			<label
				className={`custom-control-label ${styles.checkMark}`}
				htmlFor={id}>
				{label}
			</label>
		</div>
	)
}

export default checkBox
