import React from 'react'
import { Link } from 'react-router-dom'
import { Card, Col, Row ,OverlayTrigger,Tooltip} from 'react-bootstrap'
import {addCommasinPrice} from '../../utils'
import styles from './stackCard.module.css'

const stackCard = ({ image, ticker, createdBy, desc, cagr, minInvest }) => {
	return (
		<Link to={`/stackdetail/${ticker}`} className={styles.navLink}>
			<Card className={styles.trendingCard}>
				<Card.Body
					className={styles.cardBody}
				>
					<Row className='mb-3'>
						<Col xs={4} md={4}>
							<img className={styles.stockImage} src={image}></img>
						</Col>
						<Col xs={8} className={styles.stackCardHeading}>
							<div className={styles.stockTitle}>{ticker}</div>
              <OverlayTrigger
									placement='bottom'
                  trigger={['hover', 'focus','click']}
									overlay={
										<Tooltip>
										{createdBy}
										</Tooltip>
									}>
                    <div className={styles.creator}>
									{createdBy?.length < 10
									? createdBy
									: `${createdBy?.substring(0, 10)}...`}
                  </div>
								</OverlayTrigger>
						</Col>
					</Row>
					<Row className='mb-md-2 mb-3'>
						<Col>
							<h3 className={styles.stockName}>{`${desc?.substring(
								0,
								50
							)}...`}</h3>
						</Col>
					</Row>
					<Row>
						<Col xs={6} md={6}>
							<div className={`${styles.cagr} mb-1`}>CAGR</div>
							<div className={styles.cagrValue}>{cagr}%</div>
						</Col>
						<Col xs={6} md={6}>
							<div className={`${styles.minHead} mb-1`}>Min.inv</div>
							<div className={styles.minValue}>${addCommasinPrice(minInvest)}</div>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</Link>
	)
}

export default stackCard
