import React, { useState, useEffect } from 'react'
import Watchlist from '../WatchList/watchList'
// import stockImage from '../../public/images/acgl.svg'
import watchListImage from '../../public/images/watchlist.svg'
import styles from './sideBar.module.css'
import { watchListDataAPI, deleteWatchListTicker } from '../../api'
import watchlistStar from '../../public/images/saveIcon.svg'

const SideBar = ({ handleEffect,change , setInwatchList, pageType}) => {

	const [active, setActive] = useState(false)
	const [option, setOption] = useState(true)
	const [data, setData] = useState([])

  useEffect(() => {
		asynchFetch()
	}, [])

  useEffect(() => {
		asynchFetch()
	}, [change])


	const toggleSideBar = () => {
		setActive(true, handleEffect())
	}

	const closeSideBar = () => {
		setActive(false, handleEffect())
	}

	const watchlist = () => {
		setOption(true)
	}

	const collection = () => {
		setOption(false)
	}

	const asynchFetch = async () => {
		const res = await watchListDataAPI()
		setData(res)
	}

  const removeFromWatchList = async (e) => {
    const res = await deleteWatchListTicker(e)
    if(res.code === 200){
      asynchFetch()
    } else{
      console.log('something went wrong')
    }
  }

	return (
		<div>
			<button className={styles.sideBarButton} onClick={toggleSideBar}>
				<img src={watchlistStar} alt="watchlist"></img>
			</button>
			<Watchlist
				active={active}
				option={option}
				data={data}
				closeSideBar={closeSideBar}
				watchlist={watchlist}
				collection={collection}
        removeTicker={removeFromWatchList}
        callWatchlist={asynchFetch}
        setInwatchList={setInwatchList}
        pageType={pageType}
			/>
		</div>
	)
}

export default SideBar
