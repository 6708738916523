import React,{useEffect, useState} from 'react'
import { useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import styles from './Fund.module.css'
import WithdrawFund from './WithdrawFund'
import withdrawImage from '../../public/images/withdraw.svg'
import moment from 'moment'
import openArrow from '../../public/images/DropdownOpen.png'
import closeArrow from '../../public/images/DropdownClose.png'

const FundDetail = ({
	icon,
	title,
	description,
	value,
	extra = '',
	pendingCommission = '',
  pendingSettlement = '',
  	href='',
	setModalData,
	data,
	handleShow,
	setWithdrawClicked,
	setToggel
}) => {
  const [withdraw,setWithdraw] = useState(false)
  const [settlementToggle, setSettlementToggle] = useState(false)
  const [pendingData, setPendingData] = useState(false)
  const [withdrawValue,setWithdrawValue] = useState('')

  const state = useSelector(state => state)
  const {
    userAccountSummary: {
      userSummary: { accountSummary = {} } = {}
    } = {}
  } = state

  const monthCheck = (e) => {
    var date = moment(e).format('D MMMM, Y')
    var newDate = ''
      if(date.includes('November')){
        newDate = date.replace('November', 'Nov' )
      } else if(date.includes('October')){
        newDate = date.replace('October', 'Oct' )
      } else if(date.includes('December')){
        newDate = date.replace('December', 'Dec' )
      } else if(date.includes('January')){
        newDate = date.replace('January', 'Jan' )
      } else if(date.includes('February')){
        newDate = date.replace('February', 'Feb' )
      } else if(date.includes('September')){
        newDate = date.replace('September', 'Sep' )
      } else {
        newDate = date
      }
      return newDate
  }
  useEffect(()=>{
    if(accountSummary && accountSummary.cashSettlement && accountSummary.cashSettlement.length > 0 ){
      accountSummary.cashSettlement.forEach(item =>{
       if(item.cash !== 0){
        setPendingData(true)
       }
     })
    }
  },[])

  const withdrawFundBalance = (e) => {
    setWithdrawValue(e)
    setWithdraw(true)
  }

  return (
    <>
		<Row className={styles.fundDetail}>
			<Col
				xs={2}
				md={2}
				className={styles.fundImage}
				style={{ paddingTop: '0px' }}>
				<div>
					<img src={icon}></img>
				</div>
			</Col>
			<Col xs={7} md={7} className='pr-0'>
				<div className={styles.fundTitle}>{title}</div>

				{
					extra !== '' ? (
					extra === 'Withdraw funds' ? (
						<>
							<div className={styles.fundDescription}>{description}</div>
              {value === "$--" ?
                <span className={styles.greyedOutLink}>
                  {`${extra}`}
                </span> :
							<span
								className={styles.link}
								// onClick={() =>
								// 	setModalData(
								// 		data,
								// 		setToggel(false, setWithdrawClicked(true, handleShow()))
								// 	)
								// }
                onClick={() => withdrawFundBalance(value)}
                >{`${extra}`}
							</span>}
							{value === "$--" ? "" :<img src={withdrawImage} style={{ paddingLeft: '11.5px' }} />}
						</>
						) : (
							<div className={styles.fundDescription}>
								{description}
								<span style={{ color: '#005DD6', cursor: 'pointer' }}>
									{
										href === '' ?
										extra :
										<a href={href} target="_blank" rel="noopener noreferrer">{extra}</a>}
									</span>
							</div>
							)
							) :
							(
								<div className={styles.fundDescription}>{description}</div>
							)
					}
					{
						pendingCommission !== '' && pendingCommission !== "$0" && pendingCommission !== "$--" ?
						(
							<div className={styles.pendingComm}>
								<span className={styles.pendingCommText}>Funds parked for pending orders:</span>&nbsp;
								<span className={styles.pendingCommAmount}>{pendingCommission}</span>
							</div>
						)
						: ''
					}
          {pendingData ?
            (
              accountSummary && accountSummary.cashSettlement && accountSummary.cashSettlement.length > 0 && title === "Unsettled funds" ?
              <>
                <p className={styles.cashSettlementButton} onClick={()=> setSettlementToggle(!settlementToggle)}>
                  {/* {accountSummary.cashSettlement.length} */}
                  Pending settlements {settlementToggle ? <img className={styles.settlementArrow} src={closeArrow}></img> : <img className={styles.settlementArrow} src={openArrow}></img>}
                </p>
                <div className="pb-2">
                  {settlementToggle ?
                      accountSummary.cashSettlement.map((data, i) => {
                        return (
                          data.cash === 0 ? "" :
                          <p className={styles.cashSettlementData}><span className={styles.cashSettlementAmount}>${data.cash}</span> will be settled on {monthCheck(data.utcTime)}</p>
                        )})
                  : ""}
                </div>
              </>
              : ""
            ): ""
          }
			</Col >
			<Col xs={3} md={2} lg={2} xl={3} className={styles.fundValue}>{value}</Col>
		</Row>
    {withdraw ? <WithdrawFund withdrawValue={withdrawValue} showModal={withdraw} setShowModal={setWithdraw}/> : ''}
    </>
	)
}

export default FundDetail
