import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import styles from '../Profile.module.css'
import InputLabel from '../../InputLabel/InputLabel'
import DatePicker from '../../DatePicker/DatePicker'
import OutsideClickHandler from 'react-outside-click-handler'
import { format } from 'date-fns'
// import CheckBox from './ProfileCheckBox'
import ProfileDropdown from './ProfileDropDown'
import ProfileCheckBox from './ProfileCheckBox'
import axios from 'axios'
import { setUserInfoSession } from '../../../common'
import { updateProfile } from '../../../api';

const EditPersonalData = props => {
  // let date = new Date()
  // date.setFullYear(date.getFullYear() - 18)
  const [dateOfBirth, setDateOfBirth] = useState(props.dateOfBirth && props.dateOfBirth !== '--' ? format(new Date(props.dateOfBirth),'yyyy-MM-dd'):'')
  // const [maxDate] = useState(date)
	const [martialStatus, setMartialStatus] = useState(
		props.martialStatus && props.martialStatus !== '--' ? props.martialStatus : ''
	)
	const [maritialStatusLabel] = useState(
		props.martialStatus
	)
	const [politicallyExposed, setPoliticallyExposed] = useState(
		props.politicallyExposed ? props.politicallyExposed : false
	)
	const [gender, setGender] = useState(props.gender.toUpperCase())
  const [showCalendar, setShowCalendar] = useState(false)

	const [officialName, setOfficialName] = useState(
		props.politicallyExposed && props.politicallyExposed !== '--' ? props.politicallyExposedNames : ''
	)
	const [proceedIdentityProof, setProceedIdentityProof] = useState(true)
	const [panCardImage, setPanCardImage] = useState()
	const [imageUploaded, setImageUploaded] = useState(false)
	const [processComplete, setProcessComplete] = useState(false)
	const [isDobRight, setIsDobRight] = useState(true)
	const [dobErrorMessage, setDobErrorMessage] = useState('')
	const [officialNameErrorMessage, setOfficialErrorMessage] = useState('')
	const [maritalStatusError, setMaritalSatusError] = useState('')
  const [successOrErrorMessage, setSucessOrErrorMessage] = useState('')
  const[buttonDisabled, setButtonDisabled] = useState(false)


	const toggleCalender = () => {
		setShowCalendar(!showCalendar)
	}

	const onOutsideClick = () => {
		setShowCalendar(false)
	}

	const formatDateDisplay = date => {
		if (typeof date === 'string') {
			return date
		}
		return format(date, 'yyyy-MM-dd')
	}

	const genderSelection = gender => {
		setGender(gender)
	}

	const martialStatusOptions = [
		{ value: 'SINGLE', label: 'Single' },
		{ value: 'DIVORCED', label: 'Divorced' },
		{ value: 'MARRIED', label: 'Married' },
		{ value: 'WIDOWED', label: 'Widowed' },
		{ value: 'PARTNER', label: 'Domestic Partner' }
	]

	// const politicallyExposedOfficial = () => {
	//     setShowOfficialName(true)
	// }

	const checkBoxChange = (e) => {
    if(!e.target.checked)
      setOfficialName('')
		setPoliticallyExposed(e.target.checked)
	}

	// const onSavingChanges = () => {
	//     setProceedIdentityProof(false)
	// }

	const hiddenFileInput = React.useRef(null)

	const uplaodImage = event => {
		hiddenFileInput.current.click()
	}

	const handleChange = event => {
		const fileUploaded = URL.createObjectURL(event.target.files[0])
		//  console.log('file',fileUploaded)
		setPanCardImage(fileUploaded, setImageUploaded(true))
	}

	const removeImage = () => {
		setPanCardImage(null, setImageUploaded(false))
	}

	const finishChanges = () => {
		if (imageUploaded) {
			setProcessComplete(true)
		}
	}

	const onHandleDate = date => {
		if (!validateAge(date)) {
			setDobErrorMessage('User must be 18 years or older')
			setIsDobRight(false)
		} else {
			setDobErrorMessage('')
			setIsDobRight(true)
		}
		setDateOfBirth(date, setShowCalendar(false))
	}

	const validateAge = dob => {
		var today = new Date()
		var birthDate = new Date(dob)
		var age = today.getFullYear() - birthDate.getFullYear()
		var m = today.getMonth() - birthDate.getMonth()
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--
		}
		if (age >= 18) return true
		else return false
	}

	const handleInputBox = e => {
		if (e.target.value.length < 1) {
			setOfficialErrorMessage('OfficialName Cannot be Empty')
		} else {
			setOfficialErrorMessage('')
		}
	}

	const handleSubmit = e => {
		const update = {
			type: 'PERSONAL_INFO',
			body: {
        dob: dateOfBirth,
        // politicallyExposed,
				politicallyExposedNames: officialName,
				gender: gender,
				marital: martialStatus,
        otp:props.otp,
        hashId:props.hashId
			}
		}
    // new api calling
    updateProfile(update).then(response=>{
      props.setIsVerifyOtp(false)
      props.setIsEdit(false)
      if(response.data.code === 200){
		delete update.body.otp;
		delete update.body.hashId;
          setUserInfoSession(update.body)
          props.setPersonalDetails(update.body)
        } else {
          setSucessOrErrorMessage('Something Went Wrong!')
        }
    }).catch(function (error) {
        if(error.response.data.code === 440){
          setButtonDisabled(true)
        }
        setSucessOrErrorMessage(error.response.data.message)
      console.log('basicError', error)
    })
	}

	const ApiCall = () => {
		if (
			dateOfBirth !== '' &&
			martialStatus !== '' &&
			gender !== '' &&
			isDobRight
		) {
			if (politicallyExposed && officialName !== null && officialName !== '') {
				handleSubmit()
			} else if (politicallyExposed === false) {
				handleSubmit()
			} else if (
				(politicallyExposed && officialName === null) ||
				officialName === ''
			) {
				setOfficialErrorMessage('Official name cannot be empty')
			}
		} else {
			if (politicallyExposed && officialName === null) {
				setOfficialErrorMessage('Official name cannot be empty')
			} else if (martialStatus === '') {
				setMaritalSatusError('Maritial Status Cannot be empty')
      }else if (dateOfBirth === '') {
				setDobErrorMessage('Date of Birth Cannot be empty')
			}
       else if (!isDobRight) {
				setDobErrorMessage('User must be 18 years or older')
			}
		}
	}

  const goBack = () => {
    props.setIsVerifyOtp(false)
    props.sendOtpApi()
  }

	return (
		<>
			<div style={{ width: '400px' }} className={styles.profileModalBody}>
				{/* {proceedIdentityProof ?  */}
				<>
					<Row className={`p-0 mt-0 ${styles.basicDetail}`}>
						<Col className={`p-0 ${styles.modalHeadingText}`}>
							Personal Details
						</Col>
					</Row>
					<Row className={`${styles.inputRowsEdit}`}>
						{/* date of birth calender */}

						<Col>
							<Form.Group className='mb-0'>
								<div className={`mb-1 ${styles.label}`}>Date of Birth</div>
								<input
									type='text'
									name='dateOfBirth'
									value={formatDateDisplay(dateOfBirth)}
									readOnly={true}
									className={
										dobErrorMessage !== ''
											? styles.profileErrorInputBox
											: styles.profileInputBox
									}
									onChange={event => setDateOfBirth(event.target.value)}
                  onClick={toggleCalender}
                  // maxDate={maxDate}
                  disabled = {props.profileData['accountStatus'] == 2 ? true : false}
                  style={props.profileData['accountStatus'] == 2 ?{background:"#D3D4DA" }: {background:""}}
								/>
								{dobErrorMessage === '' ? (
									''
								) : (
									<p className={styles.profileErrorText}>{dobErrorMessage}</p>
								)}
							</Form.Group>

							{showCalendar ? (
								<div className={styles.calendar}>
									<OutsideClickHandler onOutsideClick={onOutsideClick}>
										<DatePicker
											onChange={date => {
												onHandleDate(date)
												const formatedDate = formatDateDisplay(date)
												const event = formatedDate
												setDateOfBirth(event)
											}}
											date={new Date()}
											color='#0061D3'
										/>
									</OutsideClickHandler>
								</div>
							) : (
								''
							)}
						</Col>
					</Row>
					<Row className={`${styles.inputRowsEdit}`}>
						<Col>
							<ProfileDropdown
								placeholder='Marital Status'
								label='Marital Status'
								data={martialStatusOptions}
								labelValue={maritialStatusLabel}
								name='MartialStatus'
								value={martialStatus}
								onChange={value => {
                  const event = value
                  setMartialStatus(event)
                  setMaritalSatusError('')
									// handleChange(event)
								}}
								className={styles.inputHeight}
							/>
							<p className={styles.profileErrorText}>{maritalStatusError}</p>
						</Col>
					</Row>
					<Row className={styles.profileMarginCheckbox}>
						<Col>
							<ProfileCheckBox
								label='I am a politically exposed person'
								id='politicallyExposed'
								name='politicallyExposed'
								onChange={(e)=>checkBoxChange(e)}
								isChecked={politicallyExposed}
							/>
						</Col>
					</Row>
					{politicallyExposed === true ? (
						<Row className={`${styles.inputRowsEdit}`}>
							<Col>
								<input
									label='Official Names'
									placeholder='Official Names here'
									name='Official Names'
									value={officialName}
									className={
										officialNameErrorMessage
											? styles.profileErrorInputBox
											: styles.profileInputBox
									}
									onBlur={event => handleInputBox(event)}
									onChange={event => setOfficialName(event.target.value)}
								/>
                <p className={styles.profileInputBelowText}>Please provide the names of that official and official's immediate family members (including former spouses).</p>
								<p className={styles.profileErrorText}>
									{officialNameErrorMessage}
								</p>
							</Col>
						</Row>
					) : (
						''
					)}
					<Row className={`${styles.inputRowsEdit}`}>
						<Col md={12} className='mb-1'>
							<div className={styles.label}>Gender</div>
						</Col>
						<Col md={4} xs={4}>
							<div
								className={
									gender === 'MALE'
										? styles.genderSelectBlack
										: styles.genderSelectWhite
								}
								onClick={() => genderSelection('MALE')}>
								<p
									className={
										gender === 'MALE'
											? styles.genderTextWhite
											: styles.genderTextBlack
									}>
									Male
								</p>
							</div>
						</Col>
						<Col md={4} xs={4}>
							<div
								className={
									gender === 'FEMALE'
										? styles.genderSelectBlack
										: styles.genderSelectWhite
								}
								onClick={() => genderSelection('FEMALE')}>
								<p
									className={
										gender === 'FEMALE'
											? styles.genderTextWhite
											: styles.genderTextBlack
									}>
									Female
								</p>
							</div>
						</Col>
            <Col md={4} xs={4}>
							<div
								className={
									gender === 'OTHER'
										? styles.genderSelectBlack
										: styles.genderSelectWhite
								}
								onClick={() => genderSelection('OTHER')}>
								<p
									className={
										gender === 'OTHER'
											? styles.genderTextWhite
											: styles.genderTextBlack
									}>
									Other
								</p>
							</div>
						</Col>
					</Row>
          <Row className={styles.SuccessOrErrorMessage}>{successOrErrorMessage && successOrErrorMessage.length > 0 ? successOrErrorMessage : "" }</Row>
					{buttonDisabled ?
          <Row>
            <Col className={`${styles.bottomButton}`}>
            <div className={styles.goBackButton} style={{marginLeft:"-50px"}} onClick={goBack}>
              Go Back
            </div>
            </Col>
          </Row> :
          <Row>
						<Col md={6} xs={6} className={`${styles.bottomButton}`}>
							<span onClick={props.handleClose}>Cancel</span>
						</Col>
						<Col md={6} xs={6} className={`${styles.saveBottomButton}`}>
							<Button
								className={`${styles.editSaveButton} themeButton`}
								onClick={ApiCall}
								style={{ backgroundColor: props.buttonColor }}>
								{' '}
								Save Changes
							</Button>
						</Col>
					</Row>}
				</>

				{/* upload pictures  */}

				{/* :
            <>
            {processComplete ?
                <Row className={styles.requestRow}>
                    <Col md={12} className={styles.RequestHead}>Request submitted successfully</Col>
                    <Col md={12} className={styles.RequestSubHead}>Your details will be updated in 2 working days</Col>
                </Row>
            :
            <>
                <Row className={`p-0 mt-0 ${styles.basicDetail}`}>
                    <Col className={`p-0 ${styles.basicDetailText}`}>
                        <span className={styles.porfileBackArrow}>
                            <img
                            src={require('../../../public/images/ProfileBackArrow.svg')}
                            // onClick={onSavingChanges}
                            >
                            </img>
                        </span>
                        Upload Identity Proof
                    </Col>
                </Row>
                <Row className={`mt-2 ${styles.profileSubHeading}`}>
                    <Col className={`p-0 m-0 ${styles.sadText}`}>We need your PAN card/National ID proof to continue with name change request.</Col>
                </Row>
                 <Row>
                    <Col md={6} className={`${styles.bottomButton}`}>
                        <span onClick={props.handleClose}>Cancel</span>
                    </Col>
                    <Col md={6} className={`${styles.saveBottomButton}`}>
                        <Button
                        className={styles.editSaveButton}
                        onClick={finishChanges}
                        >
                            save changes
                        </Button>
                    </Col>
                </Row>

                {imageUploaded ?
                    <Row>
                        <Col>
                            <div className={styles.profileRemoveButtonCenter}>
                                <button
                                className={styles.profileRemoveButton}
                                onClick={removeImage}>
                                REMOVE
                                </button>
                            </div>
                            <img
                            className={styles.profileUploaded}
                            src={panCardImage}/>
                        </Col>
                    </Row>:
                    <Row className="p-0 m-0">
                        <Col className={styles.photoUploadBox}>
                            <div className={styles.profileUploadButtonCenter}>
                                <button
                                className={styles.profileUploadButton}
                                onClick={uplaodImage}>
                                    Upload Photo
                                </button>
                                <input type="file"
                                style={{display:'none'}}
                                ref={hiddenFileInput}
                                onChange={handleChange}/>
                            </div>
                        </Col>
                    </Row>
                }
                </>
                }
            </>
            } */}
			</div>
		</>
	)
}

export default EditPersonalData
