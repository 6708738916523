import React,{useState,useEffect,createRef} from 'react'
import {Row,Col} from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import styles from './UploadDocument.module.css'
import SignUpButton from '../../SignUp/SignUpButton'
// import DocumentIcon from '../../../public/images/newOnboarding/onfidoDocument.png'
import DocumentIcon from '../../../public/images/newOnboarding/onfidoDocu.png'
import { set, get } from 'idb-keyval';

const UploadDocument = ({title,onSubmit,loading}) => {
  const [files,setFile] = useState([])
  const [fileSize,setFileSize] = useState('')
  const dropRef = createRef()

  useEffect(() => {
    setFile([])
    setFileSize('')
  },[title])

  const openDialog = () => {
    if (dropRef.current){
      dropRef.current.open()
     }
   }

   const onDrop = async( file) => {
    file[0].preview = URL.createObjectURL(file[0])
     if(title.includes('Back')){
      set('back', file[0])
     }else{
      set('front', file[0])
     }
      setFile(file)
	}

  const onRemove = (name) => {
      setFile([])
      openDialog()
}

  useEffect(() => {
    if(files && files.length){
    setFileSize(files[0].size)
  }
  },[files])

  return(
    <Row className={`justify-content-center ${styles.cardDiv}`}>
    <Col xl={4} sm={8} xs={12} >
      <div className={styles.selectionBox}>
        <div className={styles.title}>{title}</div>
        {title === "Upload Address Proof" ?
        <div className={styles.subTitle} style={{textAlign:"center"}}>We need a picture of your Bank Statement or Utility Bill.</div> : ""}
        <Dropzone
          onDrop={onDrop}
          ref={dropRef}
          noClick
          maxSize={5000000}
          accept='image/jpeg, image/png, image/jpg, application/pdf'
          multiple={false}
      >
			{({ getRootProps, getInputProps }) => (
         files.length === 0 ?
					<div
						{...getRootProps({ className: 'dropzone' })}
						className={styles.dropImageCenter}>
						<input {...getInputProps()} />
            <div className={styles.uploadBox} 	onClick={openDialog}>
              <div style={{height:'250px',textAlign:'center',paddingTop:'50px'}}>
                <img src={DocumentIcon} height='100px'></img>
               </div>
             <div style={{textAlign:'center'}}>
                 <SignUpButton
											title='Upload Document'
                      tabIndex='3'
                      classes={styles.buttonWidth}
										/>
              </div>
            </div>
					</div> :
          files.map(file => {
            return (
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <div className={styles.uploadBox}>
                {fileSize > 5000000 ?
                <>
                <div className={styles.fileSize} style={{color:'#FA274A',paddingBottom:'16px',paddingTop:'16px'}}>
                <div style={{marginBottom:'16px'}}> File should be under 5MB</div>
                <div className={styles.uploadTitle} onClick={() => onRemove()}>Re-upload</div>
                </div>
                </>:
                <div className={styles.title} style={{margin:'0px'}}>
                  <div style={{height:'250px',marginTop:'30px'}}>
                   {/* <img src={file.preview} className={styles.uploadedImage}/> */}
                   {file.type.startsWith('image/') ? (
                        <img src={file.preview} alt="Uploaded" className={styles.uploadedImage} />
                      ) : (
                        <embed src={file.preview} type="application/pdf" width="100%" height="200px" />
                    )}
                  </div>
                  <div className={styles.buttonFlex}>
                     <div>
                        <SignUpButton
                          title='Reupload'
                          tabIndex='3'
                          onClick={() => onRemove()}
                        />
                       </div>
                       <div>
                          <SignUpButton
                          title='Submit'
                          tabIndex='4'
                          onClick={onSubmit}
                          loading={loading}
                        />
                       </div>
                  </div>
                {/* <div className={styles.uploadTitle} style={{color:'#0061D3',paddingTop:'16px'}} onClick={() => onRemove()}>Re-upload</div> */}
                </div>}
              </div>
              </div>
            )
          })
			)}
		</Dropzone>

      </div>
    </Col>
  </Row>
  )
}

export default UploadDocument
