import React, { useState, useEffect, useRef, useContext } from 'react'
import { Row, Col } from 'react-bootstrap'
import { validateOtp, resendOtp } from '../../api'
import styles from './ForgotUserName.module.css'
import SignUpButton from '../SignUp/SignUpButton'
import PinInput from 'react-pin-input'

const VerifyEmail = ({
	hashID,
	updateHashId,
	formData,
	changeCurrent,
	updateFormData
}) => {
	const [otp, setOtp] = useState('')
	const [resendOtpTimer, setresendOtpTimer] = useState(180)
	const [otpSendMessage, setOtpSendMessage] = useState('')
	const [otpError, setOtpError] = useState('')
	const [loading, setLoading] = useState(false)
	const myRef = useRef(null)

	useEffect(() => {
		myRef.current.focus()
	}, [])

	// disable copy and paste in otp-input
	useEffect(() => {
		const pinInputs = document.getElementsByClassName('pincode-input-text')

		if (pinInputs && pinInputs?.length > 0) {
			for (let i = 0; i < pinInputs.length; i++) {
				pinInputs[i].addEventListener('keydown', e => {
					if (
						e.key.toLowerCase() === 'control' ||
						e.key.toLowerCase() === 'meta' ||
						e.key.toLowerCase() === 'v' ||
						e.key.toLowerCase() === 'c'
					) {
						e.preventDefault()
					}
				})
			}
		}
	}, [])

	const handleChange = value => {
		setOtp(value)
	}

  useEffect(() => {
    if(otp.length === 6){
     handleSubmit()
    }
   },[otp])

	useEffect(() => {
		startTimer()
	})

	const startTimer = () => {
		const timer = setTimeout(() => {
			setresendOtpTimer(resendOtpTimer - 1)
		}, 1000)

		if (resendOtpTimer === 178) {
			setOtpSendMessage('')
		}

		if (resendOtpTimer === 0) {
			clearInterval(timer)
		}
	}

	const onResend = () => {
		setOtp('')
		setOtpError('')
		myRef.current.clear()
		setLoading(true)

		resendOtp(hashID).then(res => {
			if (res.code === 200) {
				updateHashId(res.data.hashID)
				setresendOtpTimer(180)
				setOtpSendMessage('OTP has been resend')
				startTimer()
			} else if (res.code === 400) {
				setOtpError('Resend OTP Fail')
			} else {
				setOtpError(res.data.message)
			}
			setLoading(false)
		})
	}

	const inputStyle = {
		spinInput: {
			border: otpError === '' ? '1px solid #D3D4DA' : '1px solid #FA274A',
			textAlign: 'center',
			width: '42px',
			height: '44px',
			background: '#FFFFFF',
			borderRadius: '6px',
			marginLeft: '16px',
			marginRight: '0px',
			fontFamily: 'geomanistregular',
			fontStyle: 'normal',
			fontWeight: '400',
			fontSize: '14px',
			lineHeight: '20px',
			color: '#3C404A'
		}
	}

	const handleSubmit = async () => {
    setLoading(true)
		if (!otp || otp.length !== 6) {
			setOtpError('Incorrect OTP',	setLoading(false))
			return
		}

		if (otp && otp.length === 6) {
			try {
				const response = await validateOtp(hashID, otp)
				if (response && response.code === 200) {
					setOtp(otp)
					updateFormData({ ...formData, otp })
					changeCurrent('AccountDetected')
				} else {
					setOtpError(response.message,	setLoading(false))
				}
			} catch (error) {
				setOtpError('Unable to proceed ahead. Please try again after sometime',	setLoading(false))
			}
		}
    setLoading(false)
	}

	return (
		<>
			<Row className='justify-content-center'></Row>
			<>
				<form
					onSubmit={handleSubmit}
					style={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
					<Row className={styles.title}>Verify your email</Row>
					<Row className={styles.subTitle}>
						Enter the 6-digit OTP sent to your email address:
					</Row>

					<Row className={styles.otpInput}>
						<PinInput
							length={6}
							initialValue=''
							value={otp}
							onChange={(value, index) => {
								setOtpError('')
								const regex = new RegExp('^[0-9]*$')
								if (regex.test(value)) handleChange(value)
							}}
							type='numeric'
							inputMode='number'
							inputStyle={{ ...inputStyle.spinInput }}
							inputFocusStyle={{ border: '1px solid #3C404A' }}
							autoSelect={true}
							regexCriteria={/[0-9]/}
							ref={myRef}
						/>
					</Row>
					{otpError && <div className={styles.otpError}>{otpError}</div>}

					<div className={styles.resendOtp}>
						{resendOtpTimer > 0 ? (
							otpSendMessage ? (
								<>
									<span
										className={styles.goBackText}
										style={{ color: '#767c8f' }}>
										{otpSendMessage}
									</span>
								</>
							) : (
								<>
									<span
										className={
											styles.timer
										}>{`Expires in ${resendOtpTimer}s`}</span>
									{/* <span
										className={styles.goBackText}
										style={{ color: '#767c8f' }}>
										Resend OTP
									</span> */}
								</>
							)
						) : (
							<>
								<span
									className={styles.goBackText}
									onClick={() => {
										!loading && onResend()
									}}
									style={{ cursor: 'pointer' }}>
									Resend OTP
								</span>
							</>
						)}
					</div>

					<Row className={styles.buttonMargin}>
						<SignUpButton
							classes={styles.verifyOtp}
							title='Verify OTP'
              loading={loading}
							onClick={handleSubmit}
						/>
					</Row>
				</form>
			</>
		</>
	)
}

export default VerifyEmail
