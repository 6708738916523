import React, { useEffect, useState } from 'react'
import { Row, Col,Toast } from 'react-bootstrap'
import styles from './otherBankAccount.module.css'
import hdfcImage from '../../public/images/hdfc.png'
import DeleteAccount from '../../public/images/deleteAccount.svg'
import BankSelect from '../../public/images/bankselect.svg'
import AddBank from '../../public/images/bankAdd.svg'
// import WithdrawRectangle from '../../public/images/withdrawrectangle.svg'
import backImage from '../../public/images/back.png'
import { getVirtualAccountDetail, putApplyFunds, deleteBankAccount } from '../../api'

const OtherBankAccount = ({
	setModalData,
	data,
	withdrawClicked,
	title,
	back,
	otherCountryDetails,
	fund,
	setBrokerDetail,
	hashId,
  setCurrent,
	buttonColor,
  interBankName,
  withdrawReview,
  errorWithdraw,
  additionalInstruction,
  reviewWithdrawlLoading,
  contactNumber,
  stringCollection,
  selectedBankNumber,
  setSelectedBankNumber,
  setSelectedBank,
  banksList,
  setClickedBankDetails,
  toggel ,
  setFoundAccount,
  selectedBank,
  backWithDraw,
  resetPreviousTranaction = () => {},
  setCheck = () => {},
  fetchBrokerDetails
}) => {
  const [loading,setLoading] = useState(false)
  const [errorMessage,setErrorMessage] = useState('')
  const [toastHandle,setToastHandle] = useState(false)
  const [termsandcondition,settermsandcondition] = useState(false)
  const {partner = {}} = JSON.parse(sessionStorage.getItem('userInfo'))
  let easyFundTransfer = false;
  if (banksList) {
    banksList.forEach(bank => {
      if(bank.isInstaPay) {
        easyFundTransfer = true;
      }
    });
  }
  useEffect(() => {
  resetPreviousTranaction()
   setCheck('',setSelectedBank(otherCountryDetails[0]))
  },[])


	const asynchFetch = async () => {
    setLoading(true)
    await fetchBrokerDetails()
    setModalData(data)
    // try{
		//   const res = await getVirtualAccountDetail()
    //   let response;
    //   if (selectedBank.beneficiaryAccountType === 'other') {
    //     response = await putApplyFunds(hashId, selectedBank.hashId, false,'');
    //     // response = await putApplyFunds(hashId, selectedBank.hashId, true,termsandcondition);
    //   } else {
    //     response = await putApplyFunds(hashId, selectedBank.hashId, false,'');
    //   }
    //   if(res && res.code === 200 && response && response.code === 200){
    //     setBrokerDetail(res.data, setModalData(data))
    //   }else{
    //     setErrorMessage('Something Went Wrong')
    //   }
    // }catch(error){
    //   setErrorMessage('Something Went Wrong')
    // }
    setLoading(false)
	}

  const deleteAccountHandler = async (hashId) => {
    try{
     const res = await deleteBankAccount(hashId)
     if(res && res.code === 200){
      setToastHandle(true,setTimeout(() => setModalData(stringCollection.howAddFund),1000))
     }
     else{
      setErrorMessage('Something Went Wrong')
     }
    }catch(error){
      setErrorMessage('Something Went Wrong')
    }
  }

  const addAccount = () => {
    const filter = banksList.filter((item) => item.code === 'OTHER_NONINDIAN')
    if(filter.length){
      setClickedBankDetails({ bankName: '', bankType: '', bankId: filter[0]._id})
    }
    setFoundAccount(false)
    toggel ? setModalData(stringCollection.setupIndianAccount):
    setModalData(stringCollection.setupOtherAccount)
  }

 const onAccountSelect = (item,index) => {
  setSelectedBank(item,setErrorMessage(''))
  setSelectedBankNumber(index)
  if(selectedBank.accountNumber !== item.accountNumber){
    settermsandcondition(false)
  }
 }


  const phone = sessionStorage.getItem('userInfo') && JSON.parse(sessionStorage.getItem('userInfo')).partner  && JSON.parse(sessionStorage.getItem('userInfo')).partner.phone
      ? JSON.parse(sessionStorage.getItem('userInfo')).partner.phone
      : contactNumber



  // const checkButtonEnable =  loading || (easyFundTransfer && selectedBank.beneficiaryAccountType === 'other' ? !termsandcondition : false)
  const checkButtonEnable =  loading


	return (
		<div className={styles.card}>
			<Row style={{borderBottom:'1px solid #efeff1',paddingBottom:'24px'}}>
				<Col
        style={{ cursor: 'pointer' }}
        onClick={ withdrawClicked ? () => setCurrent(backWithDraw) : () => setModalData(back)}
        md={8}
        >
					<img src={backImage} />
					<span
						className={styles.title}
						// onClick={ withdrawClicked ? () => setCurrent('SetUpOtherAccount') : () => setModalData(back)}
						style={{
							paddingLeft: '20px',
							// cursor: 'pointer'
						}}>
						{title}
					</span>
				</Col>
        <Col>
        <Toast show={toastHandle}>
          <Toast.Body className={styles.toastMessage}>
            <div className={styles.rec}></div>
            Bank account deleted successfully</Toast.Body>
        </Toast>
        </Col>
			</Row>
      {withdrawClicked ? '' :
      <Row className={styles.note}>Note: Please make sure that the funds are transferred via the selected bank</Row>}
			<Row style={{ marginTop: '30px' }}>
        {otherCountryDetails && otherCountryDetails.map((item,index) => {
        return( <Col className='pr-0' md={6} xs={12}>
					<div className={`${styles.contain}
          //selectedBank.beneficiaryAccountType === 'other' && selectedBank.accountNumber === item.accountNumber && easyFundTransfer ? styles.containPadding : ''   for instapay comment
          ${index === selectedBankNumber ? styles.selectBank : ''}`}
          onClick={() => onAccountSelect(item,index)}>
            <Row>
              <Col style={{textAlign:'end',paddingRight:'0px'}}>
              {index === selectedBankNumber ?
              <img src={BankSelect}></img> : ''}
              </Col>
            </Row>
						<Row>
							<Col>
								<div className={styles.bank}>
                    {item.bankName ? item.bankName : '--'}
								</div>
								<div className={styles.name}>
                    {item.beneficiaryName ? item.beneficiaryName : '--'}
								</div>
							</Col>
							<Col style={{ textAlign: 'end' }}>
                 {item.bank.logo ? <img
												src={item.bank.logo}
												height='40px'
												width='40px'
											/> : '--'}
							</Col>
						</Row>
						<Row style={{ marginTop: '16px' }}>
							<Col>
								<div className={styles.details}>
                     {item.accountNumber ? item.accountNumber : '--'}
								</div>
								<div className={styles.details}>
                    {item.bankIFSC ? item.bankIFSC : item.beneficiarySwiftABA ? item.beneficiarySwiftABA : '--'}
								</div>
								<div className={styles.details}>
                    {item.bankAddress ? item.bankAddress : '--'}
								</div>
							</Col>
              {withdrawClicked ? '' :
               item && item.canBeDeleted ?
              <Col style={{textAlign:'end',placeSelf:'flex-end',cursor:'pointer',marginBottom:'2px'}}>
               <img src={DeleteAccount} onClick={() => deleteAccountHandler(item.hashId)}></img>
              </Col> : ''
              }
						</Row>

            {/* Instapay terms and condition */}
            {/* {
            selectedBank.beneficiaryAccountType === 'other' && item.beneficiaryAccountType === 'other'  && selectedBank.accountNumber === item.accountNumber && easyFundTransfer ?
            <Row style={{ marginTop: '5px'}}>
              <Col>
                <div>
                  <input
                    type='checkbox'
                    onChange={() => settermsandcondition(!termsandcondition)
                    }
                    className={styles.box}
                    value={termsandcondition}
                  />

                  <span className={styles.agree}>
                    <a href='https://stockal.freshdesk.com/support/solutions/articles/43000690742-easyfund-tos' target="_blank">
                    I agree to  Terms and Conditions
                    </a>
                  </span>
                </div>
              </Col>
            </Row> : ''} */}


            {
              withdrawClicked ?
              <>
                <Row style={{ marginTop: '16px' }}>
                <Col>
                  <div className={styles.itermediaryHeading}>
                    Intermediary/Correspondent Bank name
                  </div>
                  <div className={styles.intermediaryDetails}>
                  {item.intermediaryBank ? item.intermediaryBank : '--'}
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: '16px' }}>
              <Col>
                  <div className={styles.itermediaryHeading}>
                    Intermediary/Correspondent Swift number
                  </div>
                  <div className={styles.intermediaryDetails}>
                  {item.intermediarySwiftNumber ? item.intermediarySwiftNumber : '--'}
                  </div>
                </Col>
              </Row>
              </> : ''
            }
					</div>
				</Col>)
        })
				// <Col className='pr-0'>
				// 	<div className={`${styles.contain}`}>
				// 		<Row>
				// 			<Col>
				// 				<div className={styles.bank}>
				// 					{otherCountryDetails
				// 						? otherCountryDetails[0]
				// 							? otherCountryDetails[0].bankName
				// 								? otherCountryDetails[0].bankName
				// 								: '--'
				// 							: '--'
				// 						: '--'}
				// 				</div>
				// 				<div className={styles.name}>
				// 					{otherCountryDetails
				// 						? otherCountryDetails[0]
				// 							? otherCountryDetails[0].beneficiaryName
				// 								? otherCountryDetails[0].beneficiaryName
				// 								: '--'
				// 							: '--'
				// 						: '--'}
				// 				</div>
				// 			</Col>
				// 			<Col style={{ textAlign: 'end' }}>
				// 				{otherCountryDetails ? (
				// 					otherCountryDetails[0] ? (
				// 						otherCountryDetails[0].bank && otherCountryDetails[0].bank.logo ? (
				// 							<img
				// 								src={otherCountryDetails[0].bank.logo}
				// 								height='40px'
				// 								width='40px'
				// 							/>
				// 						) : otherCountryDetails &&
				// 						  otherCountryDetails[0].bankName ? (
				// 							otherCountryDetails[0].bankName.slice(0, 1)
				// 						) : (
				// 							'--'
				// 						)
				// 					) : (
				// 						'--'
				// 					)
				// 				) : (
				// 					'--'
				// 				)}
				// 			</Col>
				// 		</Row>
				// 		<Row style={{ marginTop: '16px' }}>
				// 			<Col>
				// 				<div className={styles.details}>
				// 					{otherCountryDetails
				// 						? otherCountryDetails[0]
				// 							? otherCountryDetails[0].accountNumber
				// 								? otherCountryDetails[0].accountNumber
				// 								: '--'
				// 							: '--'
				// 						: '--'}
				// 				</div>
				// 				<div className={styles.details}>
				// 					{otherCountryDetails
				// 						? otherCountryDetails[0]
				// 							? otherCountryDetails[0].bankIFSC
				// 								? otherCountryDetails[0].bankIFSC
				// 								: '--'
				// 							: '--'
				// 						: '--'}
				// 				</div>
				// 				<div className={styles.details}>
				// 					{otherCountryDetails
				// 						? otherCountryDetails[0]
				// 							? otherCountryDetails[0].bankAddress
				// 								? otherCountryDetails[0].bankAddress
				// 								: '--'
				// 							: '--'
				// 						: '--'}
				// 				</div>
				// 			</Col>
        //       {withdrawClicked ? '' :
        //        otherCountryDetails[0] && otherCountryDetails[0].canBeDeleted ?
        //       <Col style={{textAlign:'end',placeSelf:'flex-end',cursor:'pointer',marginBottom:'2px'}}>
        //        <img src={DeleteAccount} onClick={deleteAccountHandler}></img>
        //       </Col> : ''
        //       }
				// 		</Row>
        //     {
        //       withdrawClicked ?
        //       <>
        //         <Row style={{ marginTop: '16px' }}>
        //         <Col>
        //           <div className={styles.itermediaryHeading}>
        //             Intermediary/Correspondent Bank name
        //           </div>
        //           <div className={styles.intermediaryDetails}>
        //           {otherCountryDetails
        //               ? otherCountryDetails[0]
        //                 ? otherCountryDetails[0].intermediaryBank
        //                   ? otherCountryDetails[0].intermediaryBank
        //                   : '--'
        //                 : '--'
        //               : '--'}
        //           </div>
        //         </Col>
        //       </Row>
        //       <Row style={{ marginTop: '16px' }}>
        //       <Col>
        //           <div className={styles.itermediaryHeading}>
        //             Intermediary/Correspondent Swift number
        //           </div>
        //           <div className={styles.intermediaryDetails}>
        //           {otherCountryDetails
        //               ? otherCountryDetails[0]
        //                 ? otherCountryDetails[0].intermediarySwiftNumber
        //                   ? otherCountryDetails[0].intermediarySwiftNumber
        //                   : '--'
        //                 : '--'
        //               : '--'}
        //           </div>
        //         </Col>
        //       </Row>
        //       </> : ''
        //     }
				// 	</div>
				// </Col>
        }
        {/* {withdrawClicked ?
        <Col className='pl-0'>
        <div className={styles.withdrawcontain}>
           <Col md={12}>
            <div className={styles.withdraw}> Intermediary/Correspondent Bank name</div>
						<div className={styles.value}>{`${otherCountryDetails[0] && otherCountryDetails[0].intermediaryBank}`}</div>
            </Col>
            <Col md={12} className='mt-5'>
            <div className={styles.withdraw}> Intermediary/Correspondent Swift number</div>
						<div className={styles.value}>{`${otherCountryDetails[0] && otherCountryDetails[0].intermediarySwiftNumber}`}</div>
            </Col>
          </div>
        </Col> : ''} */}
			</Row>
      {withdrawClicked ? '' :
			<Row style={{ marginTop: '40px' }}>
				<Col style={{cursor:'pointer'}} className={styles.marginAddBank}>
        <img src={AddBank}></img>
					<div className={styles.addAccount} onClick={addAccount}>
					Add Bank account
					</div>
				</Col>
			</Row>}

			{withdrawClicked ? (
				<Row style={{ marginTop: '40px'}} className={styles.marginBankAccount}>
					<Col md={3}>
						<div className={styles.withdraw}>Withdrawal Amount</div>
						<div className={styles.value}>{`$${fund}`}</div>
					</Col>
          {/* <Col>
						<div className={styles.withdraw}>Additional Instructions</div>
						<div className={styles.value}>{additionalInstruction}</div>
					</Col> */}
				</Row>
			) : null}

       { withdrawClicked ?
       errorWithdraw === '' ? '' : <div className={styles.erorrMessage}>{errorWithdraw}</div>
       :errorMessage === '' ? '' : <div className={styles.erorrMessage}>{errorMessage}</div>
       }

			<Row className={styles.fixedDiv}>
				<Col>
					<button
						onClick={withdrawClicked ? withdrawReview : () => asynchFetch()}
						type='button'
            disabled={withdrawClicked ? reviewWithdrawlLoading : checkButtonEnable}
						style={{ backgroundColor: buttonColor }}
						className={`btn btn-primary btn-sm ${styles.addfunds} themeButton`}>
						<span className={styles.addfundstext}>Continue</span>
					</button>
				</Col>
			</Row>
		</div>
	)
}

export default OtherBankAccount
