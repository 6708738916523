import React, { useContext, useState } from "react"
import styles from "./offline.module.css"
import DownloadIcon from "../../../../public/images/downloadSecondary.svg"
import FundTransferIcon from "../../../../public/images/fundtransfer.png"
import { AddFundContext } from "../../AddFundContext"
import { getLrsReport } from "../../../../api"
import { Spinner } from "react-bootstrap"
import DownloadInstruction from "../Common/DownloadInstruction"

const Instruction = ()=>{
    const {setSubPage, lrsID, onBack, transferOptions, PAGE, SUBPAGE} = useContext(AddFundContext)
    const [loadingPDF, setLoadingPDF] = useState(false)
    const [PDFError, setPDFError] = useState(false)
    const onDownload = async()=>{
        if(!loadingPDF){
            setLoadingPDF(true)
            try{
                let response = await getLrsReport(lrsID)
                if(response.status === 200){
                    let href = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }))
                    let element = document.createElement('a')
                    element.setAttribute('href', href)
                    element.setAttribute('download', '');    
                    element.style.display = 'none'
                    document.body.appendChild(element)       
                    element.dispatchEvent(new MouseEvent('click'))     
                    document.body.removeChild(element)
                    if(PDFError)
                        setPDFError(false)
                }
                else{
                    setPDFError(true)
                }
            }catch(error){
                setPDFError(true)
            }            
            setLoadingPDF(false)
        }
        
    }
    return(<>
        <div className={styles.lrsIDContainer}>
            <img src={FundTransferIcon} />
            <div className={styles.lrsID}>Your LRS ID: {lrsID}</div>
        </div>       
        <div className={styles.subHeader}>Wire instructions generated</div>
        <div className={styles.body}>
            <ol className={styles.instruction}>
                <li>
                   <div className={styles.step}>Download the wire instructions generated</div> 
                   <div className={styles.description}>These contain details of the brokerage account into which you will have to send money.</div>
                   {!PDFError ? <button className={styles.downloadPDF} onClick={onDownload}>
                        {!loadingPDF?<img src={DownloadIcon} />:<Spinner animation="border" className={styles.spinnerColor}/>}
                        Download PDF
                    </button>:<DownloadInstruction fetchPDF={onDownload}/>}
                </li>
                <li>
                    {transferOptions.offline&&<>
                        <div className={styles.step}>Visit Bank</div> 
                        <div className={styles.description}>Submit the form in nearby branch of your bank.</div>
                    </>}
                    {transferOptions.other&&<>
                        <div className={styles.step}>Transfer Funds</div> 
                        <div className={styles.description}>Initiate the fund transfer via your bank</div>
                    </>}                 
                </li>
                <li>
                    <div className={styles.step}>Ready to Invest</div> 
                    <div className={styles.description}>The fund transfer process typically takes 2-5 business days. You will be able to start investing once the funds have been credited to your account.</div>                   
                </li>
            </ol>
        </div>
        <div className={styles.footerContainer}>
            <button className={styles.continueBtn} onClick={()=>setSubPage(SUBPAGE[PAGE.OFFLINE].TRANSACTION)}>Continue</button>
            {/* <button className={styles.cancelBtn} onClick={onBack}>Cancel</button> */}
        </div> 
    </>)
}

export default Instruction