import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router"
import { getAccountInfo, getPartnerCountries, getBankList, fetchIFSCDetail, postAddBankAccountPennyDrop, updateBankAccountPennyDrop, postAddBankAccountAddFund, updateBankAccountAddFund } from "../../../api";
import styles from "./addBank.module.css"
import SelectDropDown from "./SelectDropDown";
import BankSecondaryIcon from '../../../public/images/bankSecondary.svg'
import VerifiedIcon from "../../../public/images/verified.svg"
import { AddFundContext } from "../AddFundContext";
import AlertInfoIcon from '../../../public/images/alertInfo.svg'
import ChevronRightIcon from '../../../public/images/chevronRightPrimary.svg'
import { fetchPAN, submitPAN } from "../pan";
import GenericLoading from "../GenericLoading";
import AlertErrorIcon from '../../../public/images/alertError.svg'
import Success from "../Transfer/Common/Success";
import { Spinner } from "react-bootstrap";
import Verify from "./Verify";
import SelectCheckBox from "../../SelectCheckBox/SelectCheckBox";
import SelectRadioBtn from "../../SelectRadioBtn/SelectRadioBtn";
import { closeFundModal } from "../../../store/actions";
import { useDispatch } from 'react-redux';
import { FundConfigContext } from "../AddFundConfig";

const AddBank = ()=>{
    const {selectedBank} = useContext(FundConfigContext);
    const {bankList,
        setBankList,
        setShowGenericError,
        setOnErrorRetry,
        subPage,
        setSubPage,
        gotoList,
        setShowCancel,
        setCancelDetails,
        setNoHeader,
        selectedAccount,
        PAGE,
        SUBPAGE,
        setFundTitle} = useContext(AddFundContext)
    const [userDetails, setUserDetails] = useState(null)
    const [isMyAccount, setIsMyAccount] = useState(false)
    const [formDetails, setFormDetails] = useState({})
    const [loading, setLoading] = useState(true)
    const [PAN, setPAN] = useState({})
    const [panError, setPanError] = useState(null)
    const [panVerifyLimit, setPanVerifyLimit] = useState(false)
    const [panVeriyfing, setPanVerifying] = useState(false)
    const [validate, setValidate] = useState(false)
    const [formError, setFormError] = useState({})
    const [nonReqFields, setNonReqFields] = useState([])
    const [accountTypeList, setAccountTypeList] = useState(["Savings", "Current"])
    const [addressEdit, setAddressEdit] = useState(false)
    const [fetchingBankDetails, setFetchingBankDetails] = useState(false)
    const [ifscFetchError, setIfscFetchError] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [panRequired, setPanRequired]=useState(true)
    const [submitFail, setSubmitFail] = useState(null)
    const [hashId, setHashId] = useState(null)
    const [verifyTriggered, setVerifyTriggered] = useState(false)
    const [disableCountryChange, setDisableCountryChange] = useState(false)
    const history = useHistory()
    const dispatchAction = useDispatch()
    const ADD = PAGE.ADD

    useEffect(()=>{
        if(selectedAccount){
            setFundTitle('Edit Bank Account')
            // document.getElementById('addfund-title').innerText='Edit Bank Account'
            setDisableCountryChange(true)
        }
        setSubPage(SUBPAGE[ADD].FORM)
        const setUserInfo = async() => {
            let userInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : null
            if(!userInfo){
                const res = await getAccountInfo()
                if (res && res.statusDetails){
                    sessionStorage.setItem('userInfo', JSON.stringify(res));
                    userInfo=res
                }
            }
            const activeCountry = await getCountryList();
            const COR = getCountryDetail(userInfo['country'], activeCountry)
            let SOF=null
            if(userInfo['employerCountryID'])
                SOF = getCountryDetail(userInfo['employerCountryID'], activeCountry)
            setUserDetails({firstName:userInfo.firstName,
                lastName:userInfo.lastName,
                residence:COR,
                source:SOF})
            fillRequiredFields(COR, SOF)
            if(COR.country==='IND'||(SOF && SOF.country==='IND')){
                setPanRequired(true)
                fetchPAN(setPAN, setPanError, setLoading, setShowGenericError, setOnErrorRetry)
            }else{
                setLoading(false);
                setPanRequired(false)
            }
        }
        const getCountryList= async(showGenericError)=>{
            let success=true
            try{
                const response = await getPartnerCountries('sourceoffunds');
                if (response && response.code === 200 && response.data) {
                  return response.data;
                }else{
                    success=false
                }
            }catch(error){
                success=false
            }
            if(success && showGenericError){
                setShowGenericError(false)
                setOnErrorRetry(null)
            }else if(!success){
                setOnErrorRetry(getCountryList)
                setShowGenericError(true)
            }
          }
        const fetchBankList = async(showGenericError) =>{
            let success=true
            try{
                const res = await getBankList()
                if(res && res.code === 200){
                    setBankList(res.data)
                    return res.data
                }else{
                    success=false
                }
            }catch(error){
                success=false
            }
            if(success && showGenericError){
                setShowGenericError(false)
                setOnErrorRetry(null)
            }else if(!success){
                setOnErrorRetry(fetchBankList)
                setShowGenericError(true)
            }
        }
        if(!bankList || bankList.length===0)
            fetchBankList()
        setUserInfo()
    },[])
    useEffect(()=>{
        if(hashId && subPage===SUBPAGE[ADD].FORM){
          setFundTitle('Edit Bank Account')
            // document.getElementById('addfund-title').innerText='Edit Bank Account'
        }else{
          setFundTitle('Add Bank Account')
        }
    },[hashId])
    const getCountryDetail = (countryID, countryList)=>{
        let countryObj;
        if (countryID && countryList && countryList.length) {
            for(let i =0; i<countryList.length; i++){
                if(countryList[i] && (countryList[i].country === countryID)){
                    countryObj = countryList[i];
                    break
                }
            }
        }
        return countryObj
    }
    const getBankDropDownOptions = ()=>{
        const list =[]
        let otherBank = null
        const addedBanks = []
        let uppercaseSelectedBank = selectedBank.toUpperCase();
        const isAxisSelectedBankInList = bankList.some(item => uppercaseSelectedBank === "AXIS" && item.code.includes("AXIS_BANK"));
        // const isHdfcSelectedBankInList = bankList.some(item => uppercaseSelectedBank === "HDFC DIRECT" && item.code.includes("HDFC_BANK"));
        if (uppercaseSelectedBank === "AXIS" && isAxisSelectedBankInList) {
            const selectedBankObject = bankList.find(item => item.code === "AXIS_BANK");
            list.push({ imgUrl: selectedBankObject.logo, title: selectedBankObject.name });
            return list;
        }
        // else if(uppercaseSelectedBank === "HDFC DIRECT" && isHdfcSelectedBankInList){
        //     const selectedBankObject = bankList.find(item => item.code === "HDFC_BANK");
        //     list.push({ imgUrl: selectedBankObject.logo, title: selectedBankObject.name });
        //     return list;
        // }
        else{
        bankList.forEach((item)=>{
            if(item.code!=='OTHER_NONINDIAN' && item.code !== "AXIS_BANK"
            // && item.code !== "HDFC_BANK" &&
            // item.code !== "HDFC_ONLINE"
            ){
                if(!addedBanks.includes(item.name)){
                    if(item.code==='OTHER_INDIAN'){
                        otherBank={imgUrl:item.logo, title:'Other'}
                    }else{
                        addedBanks.push(item.name)
                        list.push({imgUrl:item.logo, title:item.name})
                    }
                }
            }
            })
        }
        if(otherBank)
            list.push(otherBank)
        return list
    }
    const getAccountDropDownOptions =()=>{
        const list =accountTypeList.map((item)=>{return {title:item}})
        return list
    }
    const getCountryDropDownOptions = ()=>{
        const list = []
        list.push({imgUrl:`${process.env.REACT_APP_ASSESTS}/flags/${userDetails.residence.countryCode2}.svg`, title:userDetails.residence.countryName})
        if(userDetails.source)
            list.push({imgUrl:`${process.env.REACT_APP_ASSESTS}/flags/${userDetails.source.countryCode2}.svg`, title:userDetails.source.countryName})
        return list
    }
    const resetFields = (defined)=>{
        let tempFields={}
        Object.keys(formDetails).forEach((key)=>tempFields[key]='')
        setFormDetails({...tempFields, ...defined})
        setIsMyAccount(false)
    }
    const onChange = (value, name)=>{
        if(name==='custID' && !(/^\d*$/.test(value))){
            if(formDetails.custID || formDetails.custID===0){
                return
            }else{
                value=''
            }
        }
        if(name==='swift'){
            if(fetchingBankDetails || value.length>11)
                return
        }
        if(name==='country'){
            const nonRequiredFields=[]
            if(value==='India'){
                setAccountTypeList(["Savings"])
                if(formDetails.country!==value){
                    resetFields({country:'India', type:'Savings'})
                }
                nonRequiredFields.push('interName')
                nonRequiredFields.push('interSwift')
                nonRequiredFields.push('name')
            }else{
                setAccountTypeList(["Savings", "Current"])
                if(formDetails.country!==value){
                    resetFields({country:value, bank:'Other'})
                }
                nonRequiredFields.push('bank')
                nonRequiredFields.push('ifsc')
            }
            setNonReqFields([...nonRequiredFields])
        }
        if(formDetails.country==='India' && name==='bank' && value !==formDetails.bank){
            const nonRequiredFields = [...nonReqFields]
            const temp={}
            Object.keys(formDetails).forEach((key)=>temp[key]=key==='type'?formDetails.type:'')
            if(value!=='Other'){
                let index = nonRequiredFields.indexOf('name');
                if (!(index > -1))
                    nonRequiredFields.push('name')
                index = nonRequiredFields.indexOf('interName');
                if (!(index > -1)) {
                    nonRequiredFields.push('interName')
                }
                index = nonRequiredFields.indexOf('interSwift');
                if (!(index > -1)) {
                    nonRequiredFields.push('interSwift')
                }
                temp.isTaxPayer=true
            }else{
                let index = nonRequiredFields.indexOf('name');
                if (index > -1)
                    nonRequiredFields.splice(index, 1)
                index = nonRequiredFields.indexOf('interName');
                if (index > -1) {
                    nonRequiredFields.splice(index, 1)
                }
                index = nonRequiredFields.indexOf('interSwift');
                if (index > -1) {
                    nonRequiredFields.splice(index, 1)
                }
            }
            setNonReqFields([...nonRequiredFields])
            setFormDetails({...temp, country:formDetails.country, [name]:value})
        }else if(name!=='country'){
            setFormDetails({...formDetails, [name]:value})
        }
        let temp={}
        if(validate){
            if(name==='swift'){
                temp['swift'] = value && value.length>=8 && value.length<=11?false:true
            }else if(name==='interSwift' || name==='interName'){
                if(name==='interName'){
                    if(value&&value.length>0){
                        if(formDetails['interSwift']&&formDetails['interSwift'].length>0)
                            temp['interSwift']=false
                        else
                            temp['interSwift']=true
                    }else{
                        temp['interSwift']=false
                    }
                }
                else
                    temp[name] = (value && value.length>0)||(!formDetails['interName'] || !formDetails['interName'].length>0) ? false:true
            }else{
                if(name !=='confirmAccNum')
                    temp[name] = value && value.length>0 ? false:true
                else
                    temp['confirmAccNum'] = value && value.length>0 && value=== formDetails.accNum ? false:true
                if(name==='accNum')
                    temp['confirmAccNum'] = formDetails.confirmAccNum && formDetails.confirmAccNum.length>0 && formDetails.confirmAccNum===value? false:true
                if(name==='bank' && value!=='HDFC Bank'){
                    temp['isTaxPayer']=false
                }
            }
        }
        setFormError({...formError, ...temp})
    }
    const resetAddBank = ()=>{
        fillRequiredFields(userDetails.residence, userDetails.source)
    }
    const fillRequiredFields=(residence, source)=>{
        const nonRequiredFields=[]
        let temp={}
        if(!source || residence.country===source.country){
            nonRequiredFields.push('country')
        }
        temp ={country:residence.countryName}
        if(residence.country==='IND'){
            setAccountTypeList(["Savings"])
            temp={...temp, type:'Savings'}
            nonRequiredFields.push('interName')
            nonRequiredFields.push('interSwift')
            nonRequiredFields.push('name')
        }else{
            temp={...temp, bank:'Other'}
            nonRequiredFields.push('bank')
            nonRequiredFields.push('ifsc')
        }
        setFormDetails(temp)
        setNonReqFields([...nonRequiredFields])
        if(selectedAccount){
            setAccountTypeList(["Savings"])
            setIsMyAccount(selectedAccount.isMyAccount)
            setHashId(selectedAccount.hashId)
           const tempDetails ={
            accNum : selectedAccount.accountNumber,
            confirmAccNum: selectedAccount.accountNumber,
            type: 'Savings',
            swift : selectedAccount.beneficiarySwiftABA,
            ifsc : selectedAccount.bankIFSC.toUpperCase(),
            interName : selectedAccount.intermediaryBank,
            interSwift : selectedAccount.intermediarySwiftNumber,
            custID : selectedAccount.customerId,
            address : selectedAccount.bankAddress
           }
            tempDetails.country='India'
            if(selectedAccount.isTaxPayer || selectedAccount.isTaxPayer !== false)
                tempDetails.isTaxpayer=true
            else
                tempDetails.isTaxpayer=false
            if(selectedAccount.bank.code!=='OTHER_INDIAN'){
                tempDetails.bank=selectedAccount.bankName
            }else{
                tempDetails.bank='Other'
                tempDetails.name=selectedAccount.bankName
                const nonRequiredFields = [...nonReqFields]
                let index = nonRequiredFields.indexOf('name');
                if (index > -1)
                    nonRequiredFields.splice(index, 1)
                setNonReqFields([...nonRequiredFields])

            }
           setFormDetails({...tempDetails})
           if(selectedAccount.bankAddress){
                setAddressEdit(true)
            }else if(selectedAccount.bankIFSC){
                getIFSCBankDetails(selectedAccount.bankIFSC.toUpperCase(), tempDetails)
            }
        }
    }
    const getAllFields = ()=>{
        return ['country', 'bank', 'type', 'name', 'accNum', 'confirmAccNum', 'ifsc', 'swift', 'address', 'interName', 'interSwift']
    }
    const getIFSCBankDetails = async(ifsc, details)=>{
        if(!fetchingBankDetails && ifsc){
            setIfscFetchError(false)
            setFetchingBankDetails(true)
            let temp={...formError}
            let tempDetails = details?{...details}:{...formDetails}
            try{
                const res = await fetchIFSCDetail(ifsc)
                if(res.data && res.data.code===200 && res.data.data && res.data.data.ADDRESS){
                    const details = res.data.data
                    if(details.SWIFT){
                        setFormDetails({...tempDetails, address:details.ADDRESS, swift:details.SWIFT})
                        if(validate){
                            temp['address'] = details.ADDRESS && details.ADDRESS.length>0 ? false:true
                            temp['swift'] = details.SWIFT && details.SWIFT.length>0 ? false:true
                        }
                    }
                    else{
                        setFormDetails({...tempDetails, address:details.ADDRESS, swift:''})
                        if(validate){
                            temp['address'] = details.ADDRESS && details.ADDRESS.length>0 ? false:true
                            temp['swift'] = true
                        }
                    }
                }else{
                    setFormDetails({...tempDetails, address:'', swift:''})
                    if(validate){
                        temp['address']=true
                        temp['swift']=true
                    }
                    setIfscFetchError(true)
                }
            }catch(error){
                setFormDetails({...tempDetails, address:'', swift:''})
                if(validate){
                    temp['address']=true
                    temp['swift']=true
                }
                setIfscFetchError(true)
            }
            setFormError({...temp})
            setFetchingBankDetails(false)
        }
    }
    const submitData = async(showGenericError)=>{
        const tempDetails={
            isMyAccount: isMyAccount,
            beneficiaryAccountNumber: formDetails.accNum,
            beneficiaryAccountType: formDetails.type==='Savings'?'SAVING_ACCOUNT':'CURRENT_ACCOUNT',
            beneficiarySwiftABA: formDetails.swift,
            bankAddress: formDetails.address,
        }
        if(panRequired && formDetails.country==='India')
            tempDetails.panNo=PAN.number
        if(formDetails.ifsc)
            tempDetails.bankIFSC=formDetails.ifsc
        if(formDetails.interName)
            tempDetails.intermediaryBank = formDetails.interName
        if(formDetails.interSwift)
            tempDetails.intermediarySwiftNumber = formDetails.interSwift
        if(formDetails.country && formDetails.country==='India')
            tempDetails.isIndian=true
        else
            tempDetails.isIndian=false
        if(formDetails.bank && formDetails.bank==='HDFC Bank'){
            tempDetails.customerId=formDetails.custID
            tempDetails.isTaxpayer=formDetails.isTaxpayer
        }
        if(formDetails.bank==='Other' || formDetails.country !=='India'){
            tempDetails.bankName=formDetails.name
            if(formDetails.country==='India'){
                for(let i=0; i<bankList.length;i++){
                    if(bankList[i].code==='OTHER_INDIAN')
                    {
                        tempDetails.nationalBankId=bankList[i]._id
                        break;
                    }
                }
            }else{
                for(let i=0; i<bankList.length;i++){
                    if(bankList[i].code==='OTHER_NONINDIAN')
                    {
                        tempDetails.nationalBankId=bankList[i]._id
                        break;
                    }
                }
            }
        }else{
            tempDetails.bankName=formDetails.bank
            for(let i=0; i<bankList.length;i++){
                if(bankList[i].name===formDetails.bank)
                {
                    tempDetails.nationalBankId=bankList[i]._id
                    break;
                }
            }
        }
        if(hashId)
            tempDetails.hashId=hashId
        let success=true
        let errorState = null
        try{
            setSubmitFail(null)
            setNoHeader(false)
            if(formDetails.country ==='India' && subPage!==SUBPAGE[ADD].VERIFY)
                setSubPage(SUBPAGE[ADD].VERIFY)
            let res
            if(hashId)
                res = await updateBankAccountPennyDrop(hashId, tempDetails)
            else
                res = await postAddBankAccountPennyDrop(tempDetails)
            if(res && res.code===200){
                setSubPage(SUBPAGE.SUCCESS)
            }
        }catch(error){
            if(error && error.data && error.data.data &&error.data.data.hashId)
                setHashId(error.data.data.hashId)
            if(formDetails.country ==='India' && error && error.data && error.data.code===400 ){
                if(error.data.data)
                    errorState = {msg:error.data.message,limit:error.data.data.limit, tryAgain:false}
                else if(error.data.message)
                    errorState = {msg:error.data.message,limit:false, tryAgain:false}
                else
                    errorState = {msg:'Something went wrong', limit:false, tryAgain:false}
            }else if(formDetails.country ==='India'){
                errorState = {msg:'Something went wrong', limit:false, tryAgain:true}
            }
            success=false
        }
        if(!errorState){
            if(success && showGenericError){
                setShowGenericError(false)
                setOnErrorRetry(null)
            }else if(!success){
                setOnErrorRetry(submitData)
                setShowGenericError(true)
            }
        }else{
            setSubmitFail(errorState)
            if(showGenericError){
                setShowGenericError(false)
                setOnErrorRetry(null)
            }
        }
    }
    const onContinue= async()=>{
        if(!submitting){
            setSubmitting(true)
            let error = false
            setValidate(true)
            let temp={}
            getAllFields().forEach((key)=>{
                if(!nonReqFields.includes(key)){
                    if(key==='swift'){
                        temp['swift'] = formDetails['swift'] &&  formDetails['swift'].length>=8 && formDetails['swift'].length<=11? false:(()=>{error=true; return true})()
                    }else if(key==='interSwift' || key==='interName'){
                        if(key==='interName')
                            temp[key]=false
                        else
                            temp[key] = (formDetails[key] && formDetails[key].length>0)||(!formDetails['interName'] || !formDetails['interName'].length>0) ? false:(()=>{error=true; return true})()
                    }else if(key==='address'){
                        temp[key] = formDetails[key] && formDetails[key].length>0 ? false:(()=>{error=true; return true})()
                        if(!addressEdit&&temp[key])
                            setAddressEdit(true)
                    }else{
                        if(key !=='confirmAccNum'){
                            temp[key] = formDetails[key] && formDetails[key].length>0 ? false:(()=>{error=true; return true})()
                            if(key ==='bank' && formDetails[key]==='HDFC Bank'){
                                temp['custID'] = formDetails['custID'] && formDetails['custID'].length>0 ? false:(()=>{error=true; return true})()
                                // temp['isTaxPayer'] = formDetails['isTaxPayer']!==null && formDetails['isTaxPayer'].length>0 ? false:(()=>{error=true; return true})()
                            }
                        }
                        else{
                            temp['confirmAccNum'] = formDetails['confirmAccNum'] && formDetails['confirmAccNum'].length>0 && formDetails['confirmAccNum']=== formDetails['accNum'] ? false:(()=>{error=true; return true})()
                        }
                    }
                }
            })
            setFormError({...temp})
            if(!isMyAccount)
                error=true
            if(!error && (!panRequired|| !panError || formDetails.country!=='India')){
                await submitData()
            }
            setSubmitting(false)
        }
    }
    return(<>{subPage===SUBPAGE[ADD].FORM &&<>{loading ?<div className={styles.genericLoading}><GenericLoading /> </div>:
        <>
            <div className={styles.addBankUserInfo}>
                <div className={`${styles.userContainer} ${!(panRequired && (panError || panVeriyfing))&&styles.dividerSecondary}`}>
                    <img src={`${process.env.REACT_APP_ASSESTS}/flags/${userDetails.residence.countryCode2}.svg`} alt='flag' className={styles.flag}/>
                    <div className={styles.user}>
                        <span className={styles.name}>{`${userDetails.firstName} ${userDetails.lastName}`}</span>
                        <span className={styles.residence}>{`Country of Residence: ${userDetails.residence.countryName}`}</span>
                    </div>
                </div>
                {panRequired && <div className={styles.panContainer}>
                    <div>
                        {(panError || panVeriyfing) &&<div className={`${`${styles.input} ${formDetails.country==='India' &&panError && styles.errorInput}`}`} >
                            <input type="text" placeholder="Enter PAN Number" className="border-0 w-100" value={PAN.number} onChange={(e)=>{/^[A-Za-z0-9]*$/.test(e.target.value)&&setPAN({number:e.target.value.toUpperCase().trim()})}}/>
                            {!panVeriyfing&& formDetails.country==='India' && verifyTriggered && panError&&<img src={AlertErrorIcon} />}
                        </div>}
                        {panError && !panVeriyfing&&<span className={`${formDetails.country==='India' && verifyTriggered ? styles.panError:styles.panInfo}`} style={{fontFamily:'geomanistRegular'}}>
                                {`${panError} `}
                                <span className={styles.textLink} onClick={!panVerifyLimit?()=>{setVerifyTriggered(true); submitPAN(PAN.number, setPanVerifying, setPanError, setPanVerifyLimit, setPAN, setShowGenericError, setOnErrorRetry)}:()=>window.fcWidget.open()}>{!panVerifyLimit?'Verify Now':'Contact us'}</span>
                            </span>
                        }
                        {panVeriyfing&&<span className={styles.panInfo}>Verifying...</span>}
                        {!panError && !panVeriyfing && <div className={styles.panVerified}>
                            <span>Verified PAN</span>
                            <div>
                                <span className={styles.value}>{PAN.masked}</span>
                                <img src={VerifiedIcon}/>
                            </div>
                        </div>}
                    </div>
                </div>}
                <div className={styles.sourceContainer}>
                    <span>Source of Funds</span>
                    <div className={styles.value}>
                        <span>{userDetails.source? userDetails.source.countryName:'--'}</span>
                        <img src={ChevronRightIcon}
                            onClick={()=>{
                                setCancelDetails({
                                    msg:'Are you sure you want  to change source of funds? All your progress will get canceled.',
                                    onConfirm:()=>{dispatchAction(closeFundModal());history.push('/profile');},
                                    onCancel:()=>setShowCancel(false)
                                });
                                setShowCancel(true)
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.addBankForm}>
                {userDetails &&  <div className={styles.inputContainer}>
                    <label>Bank Country</label>
                    <SelectDropDown selectVal={formDetails.country} setSelectVal={(value)=>onChange(value, 'country')} dropDownlist={userDetails && getCountryDropDownOptions()} placeholder={"Select Bank Country"} disabled={disableCountryChange} selectLabelIcon={BankSecondaryIcon} searchBox={false} error={formError.country} noOption={nonReqFields.includes('country')}/>
                    {formError.country && <span className={styles.errorMsg}>This Field is required</span>}
                </div>}
                <div className={styles.row}>
                    {!nonReqFields.includes('bank') && <div className={styles.inputContainer}>
                        <label>Pick a Bank</label>
                        <SelectDropDown selectVal={formDetails.bank} setSelectVal={(value)=>onChange(value, 'bank')} dropDownlist={getBankDropDownOptions()} placeholder={"Select Bank"} selectLabelIcon={BankSecondaryIcon} error={formError.bank} searchBox={false}/>
                        {formError.bank && <span className={styles.errorMsg}>This Field is required</span>}
                    </div>}
                    {!nonReqFields.includes('type') && <div className={styles.inputContainer}>
                        <label>Bank Account Type</label>
                        <SelectDropDown selectVal={formDetails.type} setSelectVal={(value)=>onChange(value, 'type')} dropDownlist={getAccountDropDownOptions()} placeholder={"Select Type"} selectLabelIcon={BankSecondaryIcon} searchBox={false} error={formError.type}/>
                        {formError.type && <span className={styles.errorMsg}>This Field is required</span>}
                    </div>}
                </div>
                {!nonReqFields.includes('name') && <div className={styles.inputContainer}>
                    <label>Bank Name</label>
                    <div className={`${styles.input} ${formError.name && styles.errorInput}`}>
                        <div className={styles.inputLeft}>
                            <img src={BankSecondaryIcon} />
                            <input type='text' name='name' placeholder="Enter Bank Name" value={formDetails.name} onChange={(e)=>onChange(e.target.value, 'name')}/>
                        </div>
                        {formError.name && <img src={AlertErrorIcon}/>}
                    </div>
                    {formError.name && <span className={styles.errorMsg}>This Field is required</span>}
                </div>}
                {formDetails.bank==='HDFC Bank' && <div className={styles.custIDContainer}>
                    <div className={styles.inputContainer}>
                        <label>Customer ID</label>
                        <div className={`${styles.input} ${formError.custID && styles.errorInput}`}>
                            <input type='text' name='name' placeholder="Enter Customer ID" value={formDetails.custID} onChange={(e)=>onChange(e.target.value, 'custID')}/>
                            {formError.custID && <img src={AlertErrorIcon}/>}
                        </div>
                        {formError.custID && <span className={styles.errorMsg}>This Field is required</span>}
                    </div>
                    <div className={styles.taxContainer}>
                        <div className={styles.label}>Tax payer in India?</div>
                        <div className="d-flex">
                            <div className={styles.option} onClick={()=>onChange(true, 'isTaxPayer')}>
                                <SelectRadioBtn value={formDetails.isTaxPayer}/>
                                <label className={styles.label}>Yes</label>
                            </div>
                            <div className={styles.option} onClick={()=>onChange(false, 'isTaxPayer')}>
                                <SelectRadioBtn value={!formDetails.isTaxPayer}/>
                                <label className={styles.label}>No</label>
                            </div>
                        </div>
                        {formError.isTaxPayer && <span className={styles.errorMsg}>This Field is required</span>}
                    </div>
                </div>}
                <div className={styles.inputContainer}>
                    <label>Account Number</label>
                    <div className={`${styles.input} ${formError.accNum && styles.errorInput}`}>
                        <div className={styles.inputLeft}>
                            <img src={BankSecondaryIcon} />
                            <input type='password' name='accNum' placeholder="Enter Bank Account Number" value={formDetails.accNum} onChange={(e)=>onChange(e.target.value, 'accNum')} autoComplete="new-password"
                                onPaste={(e)=>{e.preventDefault();return false;}} onCopy={(e)=>{ e.preventDefault();return false;}}/>
                        </div>
                        {formError.accNum && <img src={AlertErrorIcon}/>}
                    </div>
                    {formError.accNum && <span className={styles.errorMsg}>This Field is required</span>}
                </div>
                <div className={styles.inputContainer}>
                    <label>Confirm Account Number</label>
                    <div className={`${styles.input} ${formError.confirmAccNum && styles.errorInput}`}>
                        <div className={styles.inputLeft}>
                            <img src={BankSecondaryIcon} />
                            <input type='text' name='confirmAccNum' placeholder="Re-enter Bank Account Number" value={formDetails.confirmAccNum} onChange={(e)=>onChange(e.target.value, 'confirmAccNum')}
                                />
                        </div>
                        {formError.confirmAccNum && <img src={AlertErrorIcon}/>}
                        {!formError.confirmAccNum &&validate && <img src={VerifiedIcon}/>}
                    </div>
                    {formError.confirmAccNum &&<span className={styles.errorMsg}>
                        {formDetails.confirmAccNum && formDetails.confirmAccNum.length>0 ?
                            'Account number do not match'
                            :'This Field is required'
                        }
                    </span>}
                </div>
                {!nonReqFields.includes('ifsc') && <><div className={styles.inputContainer}>
                    <label>IFSC Code</label>
                    <div className={`${styles.input} ${formError.ifsc && styles.errorInput}`}>
                        <div className={styles.inputLeft}>
                            <img src={BankSecondaryIcon} />
                            <input type='text' name='ifsc' placeholder="Enter IFSC Code" value={formDetails.ifsc} onChange={(e)=>onChange(e.target.value.toUpperCase(), 'ifsc')} onBlur={()=>getIFSCBankDetails(formDetails.ifsc)}/>
                        </div>
                        {formError.ifsc && <img src={AlertErrorIcon}/>}
                    </div>
                    {formError.ifsc && !fetchingBankDetails && !ifscFetchError && <span className={styles.errorMsg}>This Field is required</span>}
                    {fetchingBankDetails ? <span className={styles.addressDefault}>Fetching bank details...</span>:<>
                        {formDetails.address && !addressEdit &&<span className={styles.addressDefault}>
                            {formDetails.address}
                            <span onClick={()=>setAddressEdit(true)}>Edit</span>
                        </span>}
                        </>
                    }
                    {ifscFetchError && <span className={styles.errorMsg}>
                        {`Unable to fetch data `}
                        <span className={styles.textLink} onClick={()=>{setAddressEdit(true); setIfscFetchError(false)}}>Try Manually</span>
                    </span>}
                </div></>}
                {(addressEdit || formDetails.country!=='India') && <div className={styles.inputContainer}>
                    <label>Bank Address</label>
                    <div className={`${styles.input} ${formError.address && styles.errorInput}`}>
                        <div className={styles.inputLeft}>
                            <img src={BankSecondaryIcon} />
                            <input type='text' name='address' placeholder="Enter Bank Address" value={formDetails.address} onChange={(e)=>onChange(e.target.value, 'address')}/>
                        </div>
                        {formError.address && <img src={AlertErrorIcon}/>}
                    </div>
                    {formError.address && <span className={styles.errorMsg}>This Field is required</span>}
                </div>}
                <div className={styles.inputContainer}>
                    <label>SWIFT Code</label>
                    <div className={`${styles.input} ${formError.swift && styles.errorInput}`}>
                        <div className={styles.inputLeft}>
                            <img src={BankSecondaryIcon} />
                            <input type='text' name='swift' placeholder="Enter SWIFT Code" value={formDetails.swift} onChange={(e)=>onChange(e.target.value, 'swift')}/>
                        </div>
                        {formError.swift && <img src={AlertErrorIcon}/>}
                    </div>
                    {formError.swift && <span className={styles.errorMsg}>{!formDetails.swift||!formDetails.swift.length>0 ?'This Field is required':'Invalid SWIFT code'}</span>}
                </div>
                {!nonReqFields.includes('interName') && !nonReqFields.includes('interSwift')&&<>
                    <div className={styles.divider}></div>
                    <div className={styles.sectionHeader}>Intermediary Bank Details (Optional)</div>
                    <div className={styles.sectionInfo}>Intermediary or sender’s bank may charge you for international payments</div>
                    <div className={styles.row}>
                        <div className={styles.inputContainer}>
                            <label>Bank Name</label>
                            <div className={`${styles.input} ${formError.interName && styles.errorInput}`}>
                                <div className={styles.inputLeft}>
                                    <img src={BankSecondaryIcon} />
                                    <input type='text' name='interName' placeholder="Enter Bank Name" value={formDetails.interName} onChange={(e)=>onChange(e.target.value, 'interName')}/>
                                </div>
                                {formError.interName && <img src={AlertErrorIcon}/>}
                            </div>
                            {formError.interName && <span className={styles.errorMsg}>This Field is required</span>}
                        </div>
                        <div className={styles.inputContainer}>
                            <label>Swift Code</label>
                            <div className={`${styles.input} ${formError.interSwift && styles.errorInput}`}>
                                <div className={styles.inputLeft}>
                                    <img src={BankSecondaryIcon} />
                                    <input type='text' name='interSwift' placeholder="Enter SWIFT Number" value={formDetails.interSwift} onChange={(e)=>onChange(e.target.value, 'interSwift')}/>
                                </div>
                                {formError.interSwift && <img src={AlertErrorIcon}/>}
                            </div>
                            {formError.interSwift && <span className={styles.errorMsg}>This Field is required</span>}
                        </div>
                    </div>
                </>}
                <div className={styles.divider}></div>
                <div className={styles.checkInputContainer}>
                    <SelectCheckBox value={isMyAccount} handleChange={setIsMyAccount} error={!isMyAccount && validate}/>
                    {!isMyAccount && validate ?
                        <span className={styles.errorMsg} style={{fontSize:'14px'}}>This must be your account to proceed</span>
                        :<label style={{color:'#767C8F'}}>This account belongs to me</label>
                    }
                </div>
            </div>
            <div className={styles.footerContainer}>
                <button className={styles.continueBtn} onClick={onContinue}>
                    {submitting&&<Spinner animation="border" className={styles.spinnerColor}/>}
                    Continue
                </button>
                <button className={styles.tertiaryBtn} onClick={()=>window.fcWidget.open()}>Need help?</button>
            </div>
        </>}
        </>}
        {subPage===SUBPAGE.SUCCESS && <Success msg='Bank account added successfully' onComplete={gotoList}/>}
        {subPage===SUBPAGE[ADD].VERIFY && <Verify error={submitFail} retry={submitData} resetAddBank={resetAddBank}/>}
    </>
    )
}

export default AddBank
