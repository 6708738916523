import React, { useState, useEffect, useRef } from 'react'
import { FormControl, Card, Row, Col,Form } from 'react-bootstrap'
import OutsideClickHandler from 'react-outside-click-handler'
import styles from './SearchBar.module.css'
import SearcIcon from '../../public/images/searchIcon.svg'
import CLoseIcon from '../../public/images/Close.png'
import searchIconGrey from '../../public/images/searchIconGrey.svg'
import deleteIcon from '../../public/images/deleteIcon.svg'
import { searchBarDataAPI } from '../../api'
import watchlistStar from '../../public/images/watchlistStar.svg'

import { Link } from 'react-router-dom'

const SearchBarInput = () => {
  const [inputactive, setinputactive] = useState(false)
  const [searchList, setsearchList] = useState([])
  const [searchItem, setsearchItem] = useState('')

	const onHandleChange = event => {
		setinputactive(true)
    setsearchItem(event.target.value)
	}

  const asynchFetch = async () => {
    const res = await searchBarDataAPI(encodeURIComponent(searchItem))
    setsearchList(res)
  }

  const onOutsideClick = () => {
    setinputactive(false)
  }

  const onClear = () => {
    setsearchItem('', setinputactive(false))
  }

  useEffect(() => {
    const handler = setTimeout(() => asynchFetch(), 1000);

    return () => clearTimeout(handler);
    // asynchFetch()
  }, [searchItem])

  const ref = useRef();
  useOnClickOutside(ref, () => setsearchItem('', setinputactive(false)));

	return (
		<>
			<div className={styles.inputbox}>
					{inputactive && (
						<span className={styles.deleteIcon} onClick={onClear}>
							<img src={deleteIcon}></img>
						</span>
					)}
          <Form>
            <span className={styles.searchIcon}>
                <img src={searchIconGrey} alt='search-icon'></img>
            </span>
            <FormControl
              className={styles.searchInput}
              autoComplete="nope"
              placeholder='Stocks, ETFs, Stacks and More...'
              aria-describedby='basic-addon1'
              onChange={onHandleChange}
              value={searchItem}
            />
          </Form>
				{/* </OutsideClickHandler> */}
			</div>

      {inputactive ? (
        <Card ref={ref} className={styles.searchCard}>
          <Card.Body className={styles.cardBody}>
            <Row className={styles.stock}>Search Results</Row>
            <div className={styles.searchResultDivider}></div>
            <div className={styles.searchList}>
              {searchList &&
                searchList.map((item, index) => {
                  const link = item.stockType === 'stack' ? `/stackdetail/${item.code}` : item.stockType === 'stocks' ? `/stockdetail/${item.code}` : item.stockType === 'etf' ? `/etfdetail/${item.code}` : item.stockType === 'adr' ? `/stockdetail/${item.code}` : ''
                  return (
                    <>
                    <Link to={link} className={styles.link}>
                      <Row className={`${styles.searchItem}`} onClick={onClear}>
                        <Col md={3} sm={3} xs={3} className={styles.stockTitle}>
                          {item.code}
                        </Col>
                        <Col xs={6} className={styles.stockName}>{item.company}</Col>
                        <Col md={3} sm={3} xs={3} className={styles.stockType}>
                          {item.stockType}
                        </Col>
                        {/* <Col xs={1} className={styles.MobileWatchListStart}>
                        <div className={`${styles.watchListStar}`}>
                          <img
                            alt='watchlist-icon'
                            type='button'
                            data-testid='img-div'
                            src={watchlistStar}
                          />
                        </div>
                      </Col> */}
                      </Row>
                    </Link>
                    <div className={styles.resultDivider}></div>
                    </>
                  )
                })}
            </div>
            {/* <Row className={`mt-3 ${styles.stock}`}>Explore stacks</Row>
						<Row className={`mt-3 ${styles.stockPadding}`}>
							<Col md={3} xs={3} sm={3} className='text-center'>
								<img
									className={styles.stackImage}
									src={require('../../public/images/bigtech.svg')}></img>
								<span className={styles.stackName}>bigtech</span>
							</Col>
							<Col md={3} xs={3} sm={3} className='text-center'>
								<img
									className={styles.stackImage}
									src={require('../../public/images/bigtech.svg')}></img>
								<span className={styles.stackName}>bigtech</span>
							</Col>
							<Col md={3} xs={3} sm={3} className='text-center'>
								<img
									className={styles.stackImage}
									src={require('../../public/images/bigtech.svg')}></img>
								<span className={styles.stackName}>bigtech</span>
							</Col>
							<Col md={3} xs={3} sm={3} className='text-center'>
								<img
									className={styles.stackImage}
									src={require('../../public/images/bigtech.svg')}></img>
								<span className={styles.stackName}>bigtech</span>
							</Col>
						</Row> */}
          </Card.Body>
        </Card>
      ) : (
        ''
      )}
    </>
  )
}

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    [ref, handler]
  );
}

export default SearchBarInput
