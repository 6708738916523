import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import SkeletonElement from './SkeletonElement'
import Shimmer from './Shimmer'

const AccountSetupSkeleton = () => {
  return (
    <Row className="justify-content-center">
      <Col xs={12} >
        <Card className='skeletonCard' style={{ margin: 0 }}>
          <Card.Body className="my-5" style={{marginBottom:'40px'}}>
            <>
              <Row>
                <Col md={12}>
                  <div>
                    <div className="mb-3"><SkeletonElement width='200px' height='40px'></SkeletonElement></div>
                    <SkeletonElement  height='17px'></SkeletonElement>
                    <SkeletonElement  height='17px'></SkeletonElement>
                    <SkeletonElement  height='17px'></SkeletonElement>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center mt-2">
                <Col md={6} xl={6} col={12}>
                <SkeletonElement height='62px'></SkeletonElement>
                </Col>
                <Col md={6} xl={6} col={12}>
                <SkeletonElement height='62px'></SkeletonElement>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md={6} xl={6} col={12}>
                <SkeletonElement height='62px'></SkeletonElement>
                </Col>
                <Col md={6} xl={6} col={12}>
                <SkeletonElement height='62px'></SkeletonElement>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md={6} xl={6} col={12}>
                <SkeletonElement height='62px'></SkeletonElement>
                </Col>
                <Col md={6} xl={6} col={12}>
                <SkeletonElement height='62px'></SkeletonElement>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md={6} xl={6} col={12}>
                <SkeletonElement height='62px'></SkeletonElement>
                </Col>
                <Col md={6} xl={6} col={12}>
                {/* <SkeletonElement height='62px'></SkeletonElement> */}
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md={9} xl={9} col={12}>
                </Col>
                <Col md={3} xl={3} col={12}>
                <SkeletonElement height='62px' width="150px"></SkeletonElement>
                </Col>
              </Row>


              <Shimmer />
            </>
          </Card.Body>
        </Card>
      </Col>
    </Row >
  )
}

export default AccountSetupSkeleton
