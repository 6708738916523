import React from "react"
import AddFund from "./AddFund"
import AddFundContextProvider from "./AddFundContext"
import AddFundConfigProvider from "./AddFundConfig"

const Fund = ({show, setShow})=>{
    return (<AddFundContextProvider>
        <AddFundConfigProvider>
            <AddFund show={show} setShow={setShow}/>
        </AddFundConfigProvider>
        </AddFundContextProvider>)
}
export default Fund