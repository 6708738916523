import React from 'react'
import { Modal } from 'react-bootstrap'
import styles from './CustomModal.module.css'
import crossImage from '../../public/images/cross.png'
const CustomModal = ({ show, handleClose, children, fromEtf=false, scrollable, className, fromCashManagement=false }) => {
	return (
		<Modal
			show={show}
			onHide={handleClose}
			className={`${styles.modalRight} ${className}`}
			dialogClassName={styles.modalDialog}
			scrollable={scrollable === undefined ? true : false}
      >
			<Modal.Header className={styles.modalHeader}></Modal.Header>
			<Modal.Body className={fromEtf ? styles.etfPadding : fromCashManagement ? styles.cashMangementPadding : styles.modalPadding} id="modal-body">{children}</Modal.Body>
			<div id='crossImage' className={styles.crossImageStyling}>
				<img src={crossImage} onClick={() => handleClose()} />
			</div>
		</Modal>
	)
}

export default CustomModal
