import React, { createContext, useState, useEffect } from 'react';
import { getAddFundConfig, getKycStatus, getConfigDataOfSelectedTransferOption } from '../../api';

export const FundConfigContext = createContext();

// this config is mainly for the axis bank and hdfc direct configuration code
const TRANSFERTYPE={
    AXIS:"Axis",
    HDFCDIRECT:"HDFC"
}

const AddFundConfigProvider = ({ children }) => {
    const [brokerAvailable, setBrokerAvailable] = useState([]);
    const [isAxisBank, setIsAxisBank] = useState(false);
    const [selectedBank,setSelectedBank] = useState('');
    const [selectedBankProcess, setSelectedBankProcess] = useState([]);
    const [stepsToTransactions, setStepsToTransactions] = useState("SELECT_BANK");
    const [makeVerification, setMakeVerification] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showAxis, setShowAxis] = useState(false);
    const [bankId, setBankId] = useState('');
    const [isKycVerified, setIsKycVerified] = useState('');
    const[showError, setShowError] = useState(false);

    useEffect(() => {
        fetchAvailableBrokerToAddFund();
    }, []);

    const AddFundConfigReset = () => {
        setIsAxisBank(false);
        setShowAxis(false);
        setStepsToTransactions("SELECT_BANK");
        setMakeVerification(false);
        setLoading(false);
        setBankId('');
        setIsKycVerified('');
        setShowError(false);
        setSelectedBank('');
    }

    useEffect(() => {
        if(selectedBank == "AXIS"){
            setIsAxisBank(true)
        }
        // else if(selectedBank == "HDFCDIRECT"){
        //     setIsAxisBank(false)
        //     setShowAxis(false)
        // }
        else{
            setIsAxisBank(false)
            setShowAxis(false)
        }
    },[selectedBank])

    const fetchAvailableBrokerToAddFund = async () => {
        try {
            const res = await getAddFundConfig();
            if (res && res.doc) {
                console.log('res',res.doc)
                setBrokerAvailable(res.doc);
            } else {
                console.error('brokerAvailable not found in the API response:', res);
            }
        } catch (error) {
            console.error('Error fetching fund configuration:', error);
        }
    };

    const getConfigData = async() => {
        try{
            const bankSelection = selectedBank == "HDFC Direct" ? "HDFC_DIRECT" : selectedBank;
            const data = await getConfigDataOfSelectedTransferOption(bankSelection);
            if(data.status === 200){
                setSelectedBankProcess(data.doc)
            }else{
                console.log('not working!')
            }
        }catch(error){
            console.log('error',error);
        }
    }

    useEffect(() => {
        if(selectedBank !== "OTHER" && selectedBank !== ""){
            getConfigData();
        }
    },[selectedBank])

    useEffect(() =>{
        if(makeVerification){
            setStepsToTransactions('SHOW_INFO')
            setShowAxis(true)
            setIsKycVerified('verified')
            // fetchData()

        }
    },[makeVerification])

    const fetchData = async () => {
        setShowAxis(true)
        setStepsToTransactions('GEN_PAYMENT')
        setIsKycVerified('verified')
        // try{
        // const resp = await getKycStatus(selectedBank)
        // if(resp && resp.doc && resp.doc.kyc){
        //     if(resp.status && resp.doc.kyc !== 'verified'){
        //         setLoading(false)
        //         // setIsKycVerified('not Verified')
        //         setStepsToTransactions('GEN_PAYMENT')
        //         setIsKycVerified('verified')
        //     }
        //     else{
        //     //     // have to send it to the review page
        //         setLoading(true)
        //         setStepsToTransactions('GEN_PAYMENT')
        //         setIsKycVerified('verified')
        //     }
        // }
        // else{
        //     setIsKycVerified('not Verified')
        //     setLoading(false);
        // }
        // }
        // catch(error){
        //     setLoading(false)
        //     setIsKycVerified('not Verified')
        // }
    }
  
    // const renderStep = (step) => {
    //     switch (step.stepActionKey) {
    //         case "ENTER_AMOUNT":
    //             return <EnterAmountStep />;
    //         case "SELECT_BANK":
    //             return <SelectBankStep />;
    //         case "GEN_PAYMENT":
    //             return <GeneratePaymentLinkStep />;
    //         case "SEND_STATUS":
    //             return <SendStatusStep />;
    //         case "GEN_OTP":
    //             return <GenerateOTPStep />;
    //         case "VERIFY_OTP":
    //             return <VerifyOTPStep />;
    //         case "UPLOAD_DOCS":
    //             return <UploadDocumentsStep />;
    //         default:
    //             return null;
    //     }
    // };



    return (
        <FundConfigContext.Provider
            value={{
                brokerAvailable: brokerAvailable,
                isAxisBank: isAxisBank,
                selectedBank:selectedBank,
                setSelectedBank: setSelectedBank,
                setSelectedBankProcess:setSelectedBankProcess,
                selectedBankProcess:selectedBankProcess,
                stepsToTransactions:stepsToTransactions,
                setStepsToTransactions:setStepsToTransactions,
                setMakeVerification:setMakeVerification,
                makeVerification:makeVerification,
                showAxis:showAxis,
                setShowAxis:setShowAxis,
                setBankId:setBankId,
                bankId:bankId,
                loading:loading,
                setLoading:setLoading,
                isKycVerified:isKycVerified,
                showError:showError,
                setShowError:setShowError,
                AddFundConfigReset:AddFundConfigReset,
                stepsToTransactions:stepsToTransactions,
                fetchData:fetchData
            }}
        >
            {children}
        </FundConfigContext.Provider>
    );
};

export default AddFundConfigProvider;

