import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import SkeletonElement from '../../skeletons/SkeletonElement'
import Shimmer from '../../skeletons/Shimmer'
import styles from './stockdetail.module.css'

const StockHeadingSkeleton = () => {
	return (
		<>
			<Card className='skeletonCard' style={{ width: '100%' }}>
				<Container className={styles.bigScreen}>
					<div className={styles.cardrowMargin}>
						<Row>
							<Col md={8}>
								<Row>
									<Col md={3}>
										<SkeletonElement
											width='70px'
											height='40px'></SkeletonElement>
										<SkeletonElement
											width='120px'
											height='20px'></SkeletonElement>
									</Col>
								</Row>
								<Row className='mt-4'>
									<Col md={2}>
										<SkeletonElement
											width='97px'
											height='29px'></SkeletonElement>
									</Col>
									<Col md={1}></Col>
									<Col md={2}>
										<SkeletonElement
											width='97px'
											height='28px'></SkeletonElement>
									</Col>
									<Col md={3}></Col>
									<Col md={2}>
										<SkeletonElement
											width='97px'
											height='28px'
											className='float-right'></SkeletonElement>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
					<div className={styles.cardrowMargin} className='d-none d-md-flex'>
						<Row>
							<Col md={10}>
								<SkeletonElement width='700px' height='250px'></SkeletonElement>
							</Col>
						</Row>
					</div>
					{/* <div className={styles.cardrowMargin} className='d-block d-md-none'>
						<Row>
							<Col>
								<SkeletonElement width='333px' height='300px'></SkeletonElement>
							</Col>
						</Row>
					</div> */}
					{/* <div className={styles.cardrowMargin}>
						<SkeletonElement width='251px' height='32px'></SkeletonElement>

						<SkeletonElement width='251px' height='24px'></SkeletonElement>
					</div> */}
				</Container>
			</Card>
			<Shimmer />
		</>
	)
}
export default StockHeadingSkeleton
