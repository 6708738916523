import React from "react"
import Copy from './copy'
import styles from './online.module.css'

const KOTAKTable = ({accountDetail})=>{
    return (<div className={styles.beneficiaryTable}>
        <div  className={styles.beneficiaryTableRow}>
          
            <div className={styles.beneficiaryTableContent}>
                <div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
                    Name of Beneficiary Bank
                </div>
                <div className={styles.beneficiaryTableValue}>
                    <span>{accountDetail.bankName}</span>
                    <Copy data={{label:'Beneficiary Bank Name', value:accountDetail.bankName}}/>
                </div>
            </div>
        </div>
        <div  className={styles.beneficiaryTableRow}>
           
            <div className={styles.beneficiaryTableContent}>
                <div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
                    Address of Beneficiary Bank
                </div>
                <div className={styles.beneficiaryTableValue}>
                    <span>{accountDetail.bankAddress}</span>
                    <Copy data={{label:'Beneficiary Bank Address', value:accountDetail.bankAddress}} />
                </div>
            </div>
        </div>
        <div  className={styles.beneficiaryTableRow}>
           
            <div className={styles.beneficiaryTableContent}>
                <div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
                    Beneficiary Bank’s SWIFT Code
                </div>
                <div className={styles.beneficiaryTableValue}>
                    <span>{accountDetail.SWIFTCode}</span>
                    <Copy data={{label:'Beneficiary SWIFT Code', value:accountDetail.SWIFTCode}} />
                </div>
            </div>
        </div>
        <div  className={styles.beneficiaryTableRow}>
           
            <div className={styles.beneficiaryTableContent}>
                <div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
                    Bank’s Routing Number / ABA
                </div>
                <div className={styles.beneficiaryTableValue}>
                    <span>{accountDetail.ABANumber}</span>
                    <Copy data={{label:'Routing Number/ABA', value:accountDetail.ABANumber}} />
                </div>
            </div>
        </div>
        <div className={`${styles.beneficiaryAccNum} ${styles.beneficiaryTableRow}`}>
           
            <div className={styles.beneficiaryTableContent}>
                <div>
                    <div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
                        Account Number
                    </div>
                </div>
                <div className={styles.beneficiaryAccNumValue}>
                    <div className={styles.beneficiaryTableValue}>
                        <span>{accountDetail.accountNumber}</span>
                        <Copy data={{label:'Beneficiary Account Number', value:accountDetail.accountNumber}} />
                    </div>
                    <div className={styles.divider}></div>
                    <div className={styles.beneficiaryAccInfo}>Everyone has an Unique Beneficiary Account Number.</div>
                </div>
            </div>
        </div>
        <div  className={styles.beneficiaryTableRow}>
           
            <div className={styles.beneficiaryTableContent}>
                <div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
                    Receiver Type
                </div>
                <div className={styles.beneficiaryTableValue}>
                    <span>Non-Individual</span>
                    <Copy data={{label:'Beneficiary Type', value:'Non-Individual'}} />
                </div>
            </div>
        </div>
        <div  className={styles.beneficiaryTableRow}>
          
            <div className={styles.beneficiaryTableContent}>
                <div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
                    Receiver Name
                </div>
                <div className={styles.beneficiaryTableValue}>
                    <span>{accountDetail.name}</span>
                    <Copy data={{label:'Beneficiary Name', value:accountDetail.name}} />
                </div>
            </div>
        </div>
        <div  className={styles.beneficiaryTableRow}>
           
            <div className={styles.beneficiaryTableContent}>
                <div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
                    Receiver Nick Name
                </div>
                <div className={styles.beneficiaryTableValue}>
                    <span>{accountDetail.nickName}</span>
                    <Copy data={{label:'Beneficiary Nick Name', value:accountDetail.nickName}} />
                </div>
            </div>
        </div>
        <div  className={styles.beneficiaryTableRow}>
           
            <div className={styles.beneficiaryTableContent}>
                <div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
                    Receiver Mobile
                </div>
                <div className={styles.beneficiaryTableValue}>
                    <span>{accountDetail.contactNumber}</span>
                    <Copy data={{label:'Beneficiary Mobile Number', value:accountDetail.contactNumber}} />
                </div>
            </div>
        </div>
        <div  className={styles.beneficiaryTableRow}>
           
            <div className={styles.beneficiaryTableContent}>
                <div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
                    Receiver Address
                </div>
                <div className={styles.beneficiaryTableValue}>
                    <span>{accountDetail.address}</span>
                    <Copy data={{label:'Beneficiary Address', value:accountDetail.address}} />
                </div>
            </div>
        </div>
        <div  className={styles.beneficiaryTableRow}>
           
            <div className={styles.beneficiaryTableContent}>
                <div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
                    Receiver State
                </div>
                <div className={styles.beneficiaryTableValue}>
                    <span>{accountDetail.state}</span>
                    <Copy data={{label:'Beneficiary State', value:accountDetail.state}} />
                </div>
            </div>
        </div>
        <div  className={styles.beneficiaryTableRow}>
           
            <div className={styles.beneficiaryTableContent}>
                <div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
                    Receiver City
                </div>
                <div className={styles.beneficiaryTableValue}>
                    <span>{accountDetail.city}</span>
                    <Copy data={{label:'Beneficiary City', value:accountDetail.city}} />
                </div>
            </div>
        </div>
        <div  className={styles.beneficiaryTableRow}>
           
            <div className={styles.beneficiaryTableContent}>
                <div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
                    Receiver Pin Code
                </div>
                <div className={styles.beneficiaryTableValue}>
                    <span>{accountDetail.pinCode}</span>
                    <Copy data={{label:'Beneficiary Pin Code', value:accountDetail.pinCode}} />
                </div>
            </div>
        </div>
    </div>)
}

export default KOTAKTable