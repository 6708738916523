/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import styles from './StockDetail.module.css'
import StockDetailBox from './stockDetailBox'
import UserIcon from '../../public/images/userIcon.svg'
import WatchingIcon from '../../public/images/WatchingIcon.svg'
import BuyIcon from '../../public/images/buyIcon.svg'
import { getStockDetails, getHighlights } from '../../api'
import { getMarketDetails } from '../../store/actions/index'
import { useSelector, useDispatch } from 'react-redux'
import {	StockDetailGraph } from '../index'
import watchlistStar from '../../public/images/watchlistStar.svg'
import addedStarImage from '../../public/images/added.svg'
import watchlisticon from '../../public/images/bookmarkOutline.svg'
import watchlisticonfilled from '../../public/images/bookmarkFilled.svg'
import infoIconOrange from '../../public/images/infoIconOrange.svg'
import OwnIcon from '../../public/images/OwnIcon.svg'
import watchEyeIcon from '../../public/images/watchEyeIcon.svg'
import { decimalAdjust, addCommasinPrice, calPriceChange, isMarketOpen, calPriceChange_New } from '../../utils'

const StockDetail = ({ stockName, livePrices,starRotate,setStarRotate,inWatchList,addToWatchlist,isOtc }) => {
	const [change, setChange] = useState('--')
  const [graphTheme, setGraphTheme] = useState();
	const [changePercent, setChangePercent] = useState('--')
	const [isLoaded, setIsloaded] = useState(true)
	const [data, setData] = useState({})
	const [stockValue, setStockValue] = useState('--')
	const [highlightsData, setHighlightsData] = useState({})
  const [prePrice,setPrePrice] = useState('--')
  const [preChange,setPreChange] = useState('--')
  const [preChangePercentage,setPreChangePercentage] = useState('--')

	const dispatchAction = useDispatch()
	const state = useSelector(state => state)
	const {
		logo = '',
		code = '',
		company = ''
	} = data && Object.keys(data).length > 0 && data
	const {
		marketDetails: { marketDetails: { currentMarketStatus = {} } = {} } = {}
	} = state

	Object.keys(state.marketDetails).length === 0 &&
		dispatchAction(getMarketDetails())



	const asynchFetch = async () => {

    const [display, highlights] = await Promise.all([
			getStockDetails(stockName),
			getHighlights(stockName),
		])
		setData(display && Object.keys(display).length > 0 ? display : {})
		setHighlightsData(highlights)
	}
	useEffect(() => {
		asynchFetch()
	}, [stockName])

	useEffect(() => {
		if (livePrices && Object.keys(livePrices).length) {
      calculatePriceShown(currentMarketStatus,livePrices)
      // const {
			// 	lastTrade = '',
			// 	priorClose = '',
      //   close=''
			// } = livePrices

    //   let {change, changePer} = calPriceChange_New({
    //     currPrice: lastTrade,
    //     close: priorClose,
    //    });
    //     setStockValue(lastTrade)
    //     setChange(change)
    //     setChangePercent(changePer);

    // if(currentMarketStatus === 'Pre-Market' || currentMarketStatus === 'Post-Market'){
    //   let {change, changePer} = calPriceChange_New({
    //     currPrice: close,
    //     close: priorClose,
    //    });
    //   setPreChange(change)
    //  setPreChangePercentage(changePer)
    // }
  }
	}, [currentMarketStatus,livePrices,stockName])


  const calculatePriceShown = (currentMarketStatus,livePrices) => {
    const {
      lastTrade = '',
      priorClose = '',
      close=''
    } = livePrices
    switch(currentMarketStatus){
      case 'Open':
        {
          let {change, changePer} = calPriceChange_New({
            currPrice: lastTrade,
            close: close,
          });
            setStockValue(lastTrade)
            setChange(change)
            setChangePercent(changePer);
        }
          break;
      case 'Pre-Market':
      case 'Post-Market':
      case 'Close':
        {
        let {change, changePer} = calPriceChange_New({
          currPrice: close,
          close: priorClose,
        });
       {
        let {change, changePer} = calPriceChange_New({
          currPrice: lastTrade,
          close: close,
        });
        setPrePrice(lastTrade)
        setPreChange(change)
        setPreChangePercentage(changePer)
        }
          setStockValue(close)
          setChange(change)
          setChangePercent(changePer);
      }
    }
  }


  const addWatchList = () => {
    setStarRotate(true, addToWatchlist())
  }

  const otcSecurityToolTip = (props) => {

    return (
      <Tooltip {...props} className={styles.otcToolTip}>
          <p className={styles.otcToolTipHeader}>Over-the-Counter (OTC) Security</p>
          <p className={styles.otcToolTipText}>This security isn’t listed on a supported exchange. However, an over-the-counter trade may be facilitated only during core trading hours (9:30 AM - 4:00 PM EST).</p>
      </Tooltip>
    )
  }

  return (
  <div>
    <Row className={styles.stockValueAndWatchlistContainer}>
        <div className={styles.stockInformation}>
          {isOtc ? <OverlayTrigger
            placement="bottom-start"
            trigger={['hover', 'focus','click']}
            delay={{ show: 250, hide: 400 }}
            overlay={otcSecurityToolTip}
          >
            <div className={styles.circularDiv}>
              {
                logo && isLoaded ? <img height='50px' width='50px' src={logo}
                        onError={() => {
                        setIsloaded(false)
                        }}/>
                      :
                      <div className={styles.stockName} style={{marginTop:"24%",fontSize:"30px",fontStyle:"geomanistRegular"}}>{code ? code.slice(0,1) : "--"}</div>
              }
            </div>
          </OverlayTrigger> :
          <div className={styles.circularDiv}>
              {
                logo && isLoaded ? <img height='50px' width='50px' src={logo}
                        onError={() => {
                        setIsloaded(false)
                        }}/>
                      : <div className={styles.stockName} style={{marginTop:"24%",fontSize:"30px",fontStyle:"geomanistRegular"}}>{code ? code.slice(0,1) : "--"}</div>
              }
            </div>}
            <div>
              <div className={styles.stockName}>
            {code ? code : "--"}
              <div className={styles.circleLive}
                    style={currentMarketStatus === 'Pre-Market' || currentMarketStatus === 'Post-Market' ? {background:'#005DD6'} :
                    currentMarketStatus === 'Close' ? {background:'#FA274A'} : {}
                    }></div>
              <span className={styles.liveText}>
                {currentMarketStatus === 'Open' ? 'Live' : currentMarketStatus }
              </span>
              </div>
              <div className={styles.stockCreator}>{company ? company : "--"}</div>
            </div>
        </div>
        <div>
          <div
            style={{ width: '178px', maxWidth: '178px', display: 'flex',justifyContent:'center',alignItems:'center' }}
            className={styles.watchlist}
            onClick={addWatchList}
          >
            <img src={inWatchList ? watchlisticonfilled : watchlisticon} />
            <span
              className={styles.watchlistText}>{inWatchList ? 'Added to Watchlist' : 'Add to watchlist'}</span>
          </div>
        </div>
    </Row>

    <Row className={styles.stockLogoContainer}>
        <div>
            <div className={styles.stockName}>
              {code ? code : "--"}
              <div className={styles.circleLive}
                    style={currentMarketStatus === 'Pre-Market' || currentMarketStatus === 'Post-Market' ? {background:'#005DD6'} :
                    currentMarketStatus === 'Close' ? {background:'#FA274A'} : {}
                    }></div>
              <span className={styles.liveText}>
                {currentMarketStatus === 'Open' ? 'Live' : currentMarketStatus }
              </span>
              </div>
              <div className={styles.stockCreator}>{company ? company : "--"}</div>
        </div>
        {isOtc ? <OverlayTrigger
              placement="bottom-start"
              trigger={['hover', 'focus','click']}
              delay={{ show: 250, hide: 400 }}
              overlay={otcSecurityToolTip}
            >
              <div className={styles.circularDiv}>
                {
                  logo && isLoaded ? <img height='50px' width='50px' src={logo}
                          onError={() => {
                          setIsloaded(false)
                          }}/>
                        : <div className={styles.stockName} style={{marginTop:"24%",fontSize:"30px",fontStyle:"geomanistRegular"}}>{code ? code.slice(0,1) : "--"}</div>
                }
              </div>
          </OverlayTrigger> :
          <div className={styles.circularDiv}>
              {
                logo && isLoaded ? <img height='50px' width='50px' src={logo}
                        onError={() => {
                        setIsloaded(false)
                        }}/>
                      : <div className={styles.stockName} style={{marginTop:"24%",fontSize:"30px",fontStyle:"geomanistRegular"}}>{code ? code.slice(0,1) : "--"}</div>
              }
        </div>}
    </Row>

    <div className={styles.valueAndGraphContainer} style={graphTheme&&{backgroundColor: graphTheme ==="positive" ? '#ECFAEC' : '#FFEEF1'}}>
        <Row className={`d-flex ${styles.responsiveStockBox}`}>
          <Col
            className={`d-block`}
            style={{paddingRight:'0px'}}
            >
              <div className={styles.stockValue}>
                  {`$${parseFloat(stockValue).toFixed(2)}`}
                  {currentMarketStatus === 'Open' ?
                  ''
                  :
                  <>
                    <span className={styles.liveText} style={{marginLeft:'4px'}}>Close price</span>
                  </>
                  }
              </div>
              <div>
                <span className={styles.incrementValue} style={{color:change >= 0 ? '#0DC014' : '#FA274A'  }}>
                {
                    isNaN(change) ? <span style={{color:'#3C404A'}}>--</span>
                    : change >= 0 ? '$' + addCommasinPrice(change)
                    : '-$' + addCommasinPrice(change.toString().substring(1))

                  }
                </span>
                <span style={{color:change >= 0 ? '#0DC014' : '#FA274A'  }} className={styles.incrementPercentage}>
                {isNaN(changePercent) ? '' : changePercent  >= 0
                                ? `[+${addCommasinPrice(changePercent)}%]`
                                : `[${addCommasinPrice(changePercent)}%]`
                                }
                </span>
                <span className={styles.date}>{`1D`}</span>
              </div>
          </Col>

          {isOtc === false && (currentMarketStatus === 'Pre-Market' || currentMarketStatus === 'Post-Market')?
              <Col style={{textAlign:'end',marginTop:'4px'}}>
                <span className={styles.preMarketText}>{`At ${currentMarketStatus}:`}</span>
                <span className={styles.preChange} style={{color:'#1E1F24',marginRight:'4px'}}> {prePrice ? `$${parseFloat(prePrice).toFixed(2)}` : "--"}</span>
                <span style={{color:preChange >= 0 ? '#13b948' : '#FA274A'  }} className={styles.preChange}>
                  {isNaN(preChangePercentage) ? '' : preChangePercentage  >= 0
                                  ? `[+${addCommasinPrice(preChangePercentage)}%]`
                                  : `[${addCommasinPrice(preChangePercentage)}%]`
                                  }
                    </span>
                    <span className={styles.preChange}> {
                      isNaN(preChange) ? '--'
                      : preChange >= 0 ? '$' + addCommasinPrice(preChange)
                      : '-$' + addCommasinPrice(preChange.toString().substring(1))

                    }</span>
                </Col>
           : ''}

        </Row>

        <Row className='mt-3 d-block'>
          <Col>
            <StockDetailGraph code={stockName} isOtc={isOtc} change={change} graphTheme={graphTheme} setGraphTheme={setGraphTheme}/>
          </Col>
        </Row>
    </div>

    <style jsx>{`
        .tooltip.show {
            opacity: 1.0 !important
        }
    `}</style>
  </div>

	)
}

export default StockDetail
