import React from 'react'
import { Card, Row } from 'react-bootstrap'
import styles from './Portfolio.module.css'

const portfolioCard = ({ value, title, incrementPercentage, color, colorValues }) => {
	return (
		<Card className={styles.portfolioCard}>
			<Card.Body className={styles.paddingNone}>
				<Row className={styles.marginSlide}>
          <span
            className={styles.stockValue}
            style={{color: colorValues}}
          >{value}
          </span>
					{incrementPercentage ? (
						<span
							style={{ color: color }}
							className={styles.incrementPercentage}>
							{incrementPercentage}
						</span>
					) : (
						''
					)}
				</Row>
				<Row className={`${styles.stockTitle} ${styles.marginSlide}`}>{title}</Row>
			</Card.Body>
		</Card>
	)
}

export default portfolioCard
