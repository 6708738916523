import { initialState } from '../initialState'
import { USER_SUMMARY } from '../actions/action'

const setUserSummary = (state = initialState.userSummary, action) => {
	switch (action.type) {
		case USER_SUMMARY:
			return { ...state, userSummary: action.payload }
		default:
			return state
	}
}

export default setUserSummary
