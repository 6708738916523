import React, { useContext } from 'react'
import { Button,Spinner } from 'react-bootstrap'
import { ThemeContext } from '../../../Context/ThemeContext'
import styles from './SignupButton.module.css'

const SignUpButton = ({ classes, title, disabled, onClick,tabIndex,loading }) => {
	const { buttonColor } = useContext(ThemeContext)
	return (
		<Button
			className={`${styles.button} ${classes} themeButton`}
			style={{ backgroundColor: buttonColor }}
      tabIndex={tabIndex}
			disabled={disabled}
			onClick={onClick}>
      {loading && <Spinner animation="border" className={styles.spinnerColor}/>}
			{title}
		</Button>
	)
}

export default SignUpButton
