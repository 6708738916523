import React,{useState, useContext, useEffect} from 'react'
import {OverlayTrigger,Tooltip}  from 'react-bootstrap'
import styles from './themeTable.module.css'
import {decimalAdjust,parseNumericMillion,addCommasinPrice, calPriceChange_New}  from '../../utils'
import { SocketContext } from '../../socket-context'

const ThemeRow = ({
  onLink,
  link,
  logo,
  code,
  company,
  price,
  percentChange,
  marketCapitalization,
  close,
  yearHigh,
  yearLow}) => {
  const socket = useContext(SocketContext);
  const [isLoaded, setIsloaded] = useState(true);
  const [priceTicker, setPriceTicker] = useState({code, currentPrice: price})

  let currentPrice;

  useEffect(() => {
  const priceListener = (priceState) => {
    // let data = JSON.parse(priceState)
    let { Symbol,price}  = priceState;
    code = price && price
    currentPrice = parseFloat(code);

  setPriceTicker((prevPrice) => {
      let priceChange = currentPrice - prevPrice.currentPrice
      return {
        stockTitle: Symbol,
        currentPrice,
        prevPrice: prevPrice.currentPrice,
        priceChange,
      }
    })
  }

  if(socket) {
    socket.emit('join_symbol', code);
    socket.on(code, priceListener)
  }

  return () => {
    if(socket){
      socket.off(code, priceListener)
    }
  }
}, [socket])

let {change, changePer} = calPriceChange_New({
  currPrice: priceTicker.currentPrice,
  close,
 });


 percentChange = changePer

  return (
    <tr className={styles.tableRow} onClick={() => onLink(link,code)}>
      <td className={styles.stockImage} style={{ paddingRight: '0px',paddingLeft:'0px'}}>
      {logo && isLoaded ? (
									<img
										onError={() => {
											setIsloaded(false)
										}}
										className={styles.imageCompany}
										src={logo}
										alt={code.substring(0, 1)}></img>
								) : (
									<h3 className={`text-center ${styles.imagePaddingRemove}`}>
										{code.substring(0, 1)}
									</h3>
								)}
      </td>
      <td className={styles.symbol}>{code}</td>
      <td className={`${styles.tableData}`}>
      <OverlayTrigger
									placement='bottom'
                  trigger={['hover', 'focus','click']}
									overlay={
										<Tooltip>
										{company}
										</Tooltip>
									}>
										<div>
							      {company.length > 8 ? `${company.substring(0,8)}...` : company}
						      </div>
								</OverlayTrigger>
      </td>
      <td className={styles.tableData}>
        {
        `$${addCommasinPrice(decimalAdjust('floor',priceTicker.currentPrice,-2)) } `
      }
      </td>
      <td className={styles.tableData}>{percentChange && percentChange.toString().includes('-') ? percentChange : `+${percentChange}`}%</td>
      {/* <td className={styles.tableData}>{marketCapitalization ? parseNumericMillion(marketCapitalization) :'--'}</td> */}
      <td className={styles.tableData}>{yearHigh ? typeof yearHigh === 'string' ?
                `$${addCommasinPrice(parseFloat(yearHigh))}` : `$${addCommasinPrice(yearHigh)}` : '--'}</td>
      <td className={styles.tableData}>{yearLow ? typeof yearLow === 'string' ?
                `$${addCommasinPrice(parseFloat(yearLow))}` : `$${addCommasinPrice(yearLow)}` : '--'}</td>
    </tr>
  )
}
export default ThemeRow
