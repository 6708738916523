import { Row, Col,useAccordionToggle, AccordionContext } from 'react-bootstrap'
import React, { useContext } from 'react'
import styles from './instruction.module.css'
import minusImage from '../../public/images/minus.png'
import addImage from '../../public/images/add.png'

const CustomToggle = ({ eventKey, title, callback }) => {
	const currentEventKey = useContext(AccordionContext)

    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey)
    )

    const isCurrentEventKey = currentEventKey === eventKey

    return (
        <Row onClick={decoratedOnClick} className='m-0 mt-3'>
            <Col className={styles.step}>
                {title}
            </Col>
            <Col md={2} xs={2} style={{textAlign:"end"}}>
                <img
                    src={
                        isCurrentEventKey
                            ? minusImage
                            : addImage
                    }></img>
            </Col>
        </Row>
    )
}

export default CustomToggle