import React, { useState,createRef } from 'react'
import { Row, Col } from 'react-bootstrap'
import styles from '../PickupDetail/pickupDetail.module.css'
import stylesheet from './Fairexpay.module.css'
import ProgressBar from './ProgessBar'
import UploadImage from '../AccountSetUp/UploadImage/UploadImage'
import plusImage from '../../public/images/plusagain.png'
import { postDocument, processTransaction } from '../../api'

const PickupDetail = ({
	setcurrent,
	hashId,
}) => {
  const [loading,setLoading] = useState(false)
  const [errorMessage,setErrorMessage] = useState('')
  const [statementFile,setstatementFile] = useState([])
  const statmentRef = createRef()

  const [accountStatement1,setaccountStatement1] = useState([])
  const [accountStatement2,setaccountStatement2] = useState([])
  const [accountStatement3,setaccountStatement3] = useState([])
  const [accountStatement4,setaccountStatement4] = useState([])
  const [accountStatementCount,setaccountStatementCount] = useState(1)
  const accountStatement1Ref = createRef()
  const accountStatement2Ref = createRef()
  const accountStatement3Ref = createRef()
  const accountStatement4Ref = createRef()


	const asynchFetch = async () => {
    setLoading(true)
    try{
          const response = await uploadDocument()
          if(response && response.code === 200){
            const r = await completeTransaction()
          }
          if(response.code !== 200){
            setErrorMessage(response.message)
          }
    }catch(error){
      setErrorMessage('Something Went Wrong')
    }
    // setLoading(false)
	}

  //for fairexpay

  const createForm = (side,type,file) => {
    const body = new FormData()
     body.append('side',side)
     body.append('type',type)
     body.append('file',file)
     return body
  }

  const completeTransaction = async() => {
    const res = await processTransaction(hashId)
  }
  const uploadDocument = async() => {
    let res
    if(accountStatement1.length){
      const body = createForm('FRONT','ACCOUNT_STATEMENT',accountStatement1[0])
       res = await postDocument(hashId,body)
    }
    if(accountStatement2.length){
      const body = createForm('FRONT','OTHER',accountStatement2[0])
       res = await postDocument(hashId,body)
    }
    if(accountStatement3.length){
      const body = createForm('FRONT','OTHER',accountStatement3[0])
       res = await postDocument(hashId,body)
    }
    if(accountStatement4.length){
      const body = createForm('FRONT','OTHER',accountStatement4[0])
       res = await postDocument(hashId,body)
    }
    return res
  }

  const onDropPan = (name , file) => {
    file[0].preview = URL.createObjectURL(file[0])
   if(name === 'account1'){
      setaccountStatement1(file)
    }
    else if(name === 'account2'){
      setaccountStatement2(file)
    }
    else if(name === 'account3'){
      setaccountStatement3(file)
    }
    else if(name === 'account4'){
      setaccountStatement4(file)
    }
	}

  const onRemove = (name) => {
    if(name === 'account1'){
      setaccountStatement1([])
    }
    else if(name === 'account2'){
      setaccountStatement2([])
    }
    else if(name === 'account3'){
      setaccountStatement3([])
    }
    else if(name === 'account4'){
      setaccountStatement4([])
    }
	}

	const openPanDialog = (name) => {
	 if(name === 'account1' && accountStatement1Ref.current){
      accountStatement1Ref.current.open()
    }
    else if(name === 'account2' && accountStatement2Ref.current){
      accountStatement2Ref.current.open()
    }
    else if(name === 'account3' && accountStatement3Ref.current){
      accountStatement3Ref.current.open()
    }
    else if(name === 'account4' && accountStatement4Ref.current){
      accountStatement4Ref.current.open()
    }
	}

  // for upload purpose
  // const onDropPan = (file) => {
  //   console.log(file);
  //   file[0].preview = URL.createObjectURL(file[0])
  //   setstatementFile(file)
	// }


	// const onRemove = (name) => {
  //   setstatementFile([])
	// }

	// const openPanDialog = () => {
  //   statmentRef.current.open()
	// }
  const checkRequired = accountStatement1.length ? false : true

	return (
		<div className={styles.card}>
			<Row className='d-block d-md-none'>
				<Col md={12}>
					<div
						className={styles.title}
						style={{ cursor: 'pointer' }}
            >
						<span> Upload Bank statement</span>
					</div>
				</Col>
			</Row>
      <Row className={stylesheet.borderBottom}>
				<Col md={10} className='pr-0'>
					<ProgressBar steps='5' current={4} color='rgb(0, 93, 214)'/>
				</Col>
			</Row>
        <div className='mt-5'>
            <div className={styles.pan} style={{fontSize:'20px',color:'#3C404A'}}>Upload 1 Year Bank Statement</div>
            <div className={styles.pan}>Note: It should include current remittance details</div>
            <Row className='mb-3'>
            <Col md={6}>
            <UploadImage
              files={accountStatement1}
              onDrop={(file) => onDropPan('account1',file)}
              dropRef={accountStatement1Ref}
              openDialog={() => openPanDialog('account1')}
              onRemove={onRemove}
              name='account1'
              accept='.pdf'
              allowedType='pdf'
              allowedSize='File size must be less than 10MB'
            />
          </Col>
          {accountStatementCount >= 2 ?
                  <Col md={6}>
                  <UploadImage
                      files={accountStatement2}
                      onDrop={(file) => onDropPan('account2',file)}
                      dropRef={accountStatement2Ref}
                      openDialog={() => openPanDialog('account2')}
                      onRemove={onRemove}
                      name='account2'
                      accept='.pdf'
                      allowedType='pdf'
                      allowedSize='File size must be less than 10MB'
                    />
                  </Col>
              :''}
              {accountStatementCount >= 3 ?
                  <Col md={6}>
                  <UploadImage
                      files={accountStatement3}
                      onDrop={(file) => onDropPan('account3',file)}
                      dropRef={accountStatement3Ref}
                      openDialog={() => openPanDialog('account3')}
                      onRemove={onRemove}
                      name='account3'
                      accept='.pdf'
                      allowedType='pdf'
                      allowedSize='File size must be less than 10MB'
                    />
                  </Col>
              :''}
              {accountStatementCount >= 4 ?
                  <Col md={6}>
                  <UploadImage
                      files={accountStatement4}
                      onDrop={(file) => onDropPan('account4',file)}
                      dropRef={accountStatement4Ref}
                      openDialog={() => openPanDialog('account4')}
                      onRemove={onRemove}
                      name='account4'
                      accept='.pdf'
                      allowedType='PDF'
                      allowedSize='File size must be less than 10MB'
                    />
                  </Col>
              :''}
        </Row>
        <Row className={styles.marginLast}>
             {accountStatementCount < 4  ?
						<Col style={{ marginTop: '24px' ,paddingLeft:'10px'}} md={3}>
							<div style={{ cursor: 'pointer' }} onClick={() => setaccountStatementCount(accountStatementCount + 1)}>
								<img src={plusImage} />
								<span className={styles.transaction}>Add Other PDF</span>
							</div>
						</Col>
             : ''}
            </Row>


				</div>
      {errorMessage === '' ? '' : <div className={styles.erorrMessage}>{errorMessage}</div>}
      <Row className={styles.fixedDiv}  >
				<Col>
					<button
						onClick={() => asynchFetch()}
						disabled={loading || checkRequired}
						type='button'
						className={`btn btn-primary btn-sm ${styles.addfunds} themeButton`}>
						<span className={styles.addfundstext}>Complete</span>
					</button>
				</Col>
			</Row>
		</div>
	)
}

export default PickupDetail

