import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { lrsPDF } from '../../api'
import styles from './setupOtherBankAccount.module.css'
import { download, infoError } from '../lrsForm/assets'
const DownloadInstruction= ({bank})=>{
    const [counter, setCounter] = useState(0)
    const [fetching, setFetching] = useState(false)
    const [error, setError] = useState(false)
    const onDownload= async()=>{
        if(!fetching && counter <3){
            setFetching(true)
            setError(false)
            try{
                const response= await lrsPDF(bank)
                if (response.status || response.code === 200) {
                    let href = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
                    let element = document.createElement('a');
                    element.setAttribute('href', href);
                    element.setAttribute('target', '_blank');
                    // element.setAttribute('download', bank)		
                    element.style.display = 'none';
                    document.body.appendChild(element);		
                    element.click();		
                    document.body.removeChild(element);
                    setCounter(0)
                }
            }catch(error){
                console.log(error)
                setError(true)
                setCounter(counter+1);
            }        
            setFetching(false)
        }        
    }    
    return (
        <span style={{color:'#005dd6', fontFamily: 'geomanistBook' }} className='d-flex'>
             {!fetching ? 
                <img src={!error?download:infoError} style={{height:'fit-content'}}/>
                :<Spinner animation="border" className={styles.spinnerColor} style={{width:'20px',height:'20px', margin:'2px'}}/>
            }
            &nbsp;
            {counter<3?
                (!error ? 
                    <span className={`d-flex align-items-center ${!fetching && styles.link}`} onClick={()=>onDownload()}>Download Instructions</span>
                    :<span  className='d-flex align-items-center'>
                        <span style={{color:'#DC6803'}}>Unable To Fetch&nbsp;</span>
                        <span className={styles.link} onClick={()=>onDownload()}>Try Again</span>
                    </span>        
                )
                :<span  className='d-flex align-items-center'>
                    <span style={{color:'#DC6803'}}>Something Went Wrong&nbsp;</span>
                    <span className={styles.link} onClick={()=>window.fcWidget.open()}>Get Help</span>                        
                </span>
            }
        </span>
    )
}
export default DownloadInstruction;