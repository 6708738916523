import React from 'react'
import AnimationModal from './animationModal';
import animationData from './successAnimation.json'


const Success=(props)=> {
  // const [modalShow, setModalShow] = React.useState(props.show);

  return (
    <>
      <AnimationModal
        // show={modalShow}
        // onHide={() => setModalShow(false)}
        animationData={animationData}
        message={props.message}
        redirectTo={props.redirectTo}
      />
    </>
  );
}
export default Success


