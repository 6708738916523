import React, { useContext, useEffect, useState } from "react"
import GenericErrorIcon from "../../public/images/genericError.svg"
import RefreshIcon from "../../public/images/refresh.svg"
import style from "./addFund.module.css"
import { AddFundContext } from "./AddFundContext"
import GenericLoading from "./GenericLoading"

const GenericError = ()=>{
    const {onErrorRetry, showGenericError,showTryAgain} = useContext(AddFundContext)
    const [counter, setCounter] = useState(0)
    const [loading, setLoading] = useState(false)
    const onTryAgain = async()=>{
        setLoading(true)
        await onErrorRetry(showGenericError)
        setCounter(counter+1)
        setLoading(false)
    }
    return(<div className={style.genericErrorContainer}>
        {!loading?<>
            <img src={GenericErrorIcon}/>
            <span>Something went wrong</span>
            {showTryAgain ?
            <span className={style.tryAgainContainer} onClick={counter < 3?()=>onTryAgain():()=>window.fcWidget.open()}>
                {counter < 3 ? <>
                    <img src={RefreshIcon} onClick={onTryAgain}/>
                    Try Again
                </>:'Contact us'}
            </span> :
            <span className={style.tryAgainContainer} onClick={()=>window.fcWidget.open()}>
              Contact us
            </span>
            }
        </>:<GenericLoading />}
    </div>)
}

export default GenericError
