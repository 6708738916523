import {Link} from 'react-router-dom'
import React from 'react'

const BAD_REQUEST = 400;
const SERVER_ERROR = 500;
const NOT_FOUND = 404;



const handleErrorResponse = (data) => {
  if (data && data.data && data.data.code === BAD_REQUEST) {
    return data.data.message ==='Please enter a valid PIN'
    ? `Please enter a valid PIN, you are only left with ${data.data.data.allowedAttempts - data.data.data.failedAttempts} attempt`
     : data.data.message === 'Spin Attempts exceeded 5 times. Please reset the pin first.'
     ? <><span style={{marginRight:"1px",display:"inline-block"}}>Spin Attempts exceeded 5 times. Please</span><Link to={`/profile/?limitover=true`}>reset the pin</Link><span style={{paddingLeft:"5px"}}>first</span></>
     : data.data.message
  } else if(data && data.data && data.data.code === NOT_FOUND) {
    return data.data.message === "Please set your PIN to start trading" ?
      <span>
        Please <Link to={`/profile/?limitover=true`}>Set</Link> your PIN to start trading.
      </span> : data.data.message
    }
    else {
    return 'Something Went wrong. Please Try Again!'
  }
}

export {
  BAD_REQUEST,
  SERVER_ERROR,
  handleErrorResponse
}

// Spin Attempts exceeded 5 times. Please reset the pin first.
