import React, { useEffect, useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import styles from '../Profile.module.css'
import 'react-phone-number-input/style.css'
import { setUserInfoSession } from '../../../common'
import {  verifyAndSavePAN } from '../../../api';

const EditIdentificationData = props => {
  const [panNumber, setPanNumber] = useState(props.idNo && props.idNo !== '--' ? props.idNo :'')
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')

  const[buttonDisabled, setButtonDisabled] = useState(false)
  const[inputDisabled, setInputDisabled] = useState(false)



  const validatePAN=(pan)=>{
    if(pan.length === 0){
      setErrorMessage('PAN is required.')
      setButtonDisabled(true);
      return false;
    }
    if(pan.length < 10){
      setErrorMessage('PAN should be of 10 characters [Example : AAAAA8888A]')
      setButtonDisabled(true);
      return false;
    }
    setErrorMessage('');
    setButtonDisabled(false);
    setSuccessMessage('');
    return true;
  }

  useEffect(()=>{
    validatePAN(panNumber);
  },[panNumber])


  const onChangeHandler = e => {
    const pan = e.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
      setPanNumber(pan)
      validatePAN(pan);
  }

	const handleSubmit = async(e) => {
    if(validatePAN(panNumber)){
      let name = props.profileData.firstName.trim();
      name += props.profileData.middleName ? ' '+props.profileData.middleName.trim()+' ' : ' ';
      name += props.profileData.lastName.trim();

      const dobData = props.profileData.dob.split('-');
      const data = {
          pan: panNumber,
          name: name.trim(),
          dob : dobData[2]+'/'+dobData[1]+'/'+dobData[0],
      };
      const response = await verifyAndSavePAN(data);
      if(response.code === 200){
        setSuccessMessage("Request to update PAN has been accepted");
        setButtonDisabled(true);
        setInputDisabled(true);
        props.setDetails();
        setInterval(() => props.handleClose(), 2000);
      }
      else {
        setErrorMessage(response.message)
      }
    }
  }

	return (
		<>
			<div style={{ width: '400px' }} className={styles.profileModalBody}>
				<Row className={`p-0 mt-0 ${styles.basicDetail}`}>
					<Col className={`p-0 ${styles.modalHeadingText}`}>Identification</Col>
				</Row>
				<Row className={`${styles.inputRowsEdit}`}>
					<Col>
						<div className={`mb-1 ${styles.profileInputLabel}`}>PAN Number</div>
						<input
							type='text'
							placeholder='PAN Number'
              name='panNumber'
              id='panNumber'
							className={
								errorMessage
									? styles.profileErrorInputBox
									: styles.profileInputBox
							}
              value={panNumber}
              disabled={inputDisabled}
              maxLength="10"
              onChange={event => onChangeHandler(event)}
              onPaste={(e)=>{
                e.preventDefault()
                return false;
              }} onCopy={(e)=>{
                e.preventDefault()
                return false;}}
						/>
						{errorMessage ?
							<p className={styles.profileErrorText}>{errorMessage}</p>
						: ''}
					</Col>
				</Row>

        <Row className={styles.successMessage}>{successMessage && successMessage.length > 0 ? successMessage : "" }</Row>

				<Row>
					<Col md={6} xs={6} className={`${styles.bottomButton}`}>
						<span onClick={props.handleClose}>Cancel</span>
					</Col>
					<Col md={6} xs={6} className={`${styles.saveBottomButton}`}>
						<Button
            disabled={buttonDisabled}
							className={`${styles.editSaveButton} themeButton`}
							onClick={handleSubmit}
							style={{ backgroundColor: props.buttonColor }}>
							Save Changes
						</Button>
					</Col>
				</Row>

			</div>
		</>
	)
        }

export default EditIdentificationData
