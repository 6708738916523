import React, { useContext, useEffect, useState } from "react"
import BankIcon from "../../public/images/bankIcon.svg"
import ArrowPrimaryRightIcon from "../../public/images/arrowRightPrimary.svg"
import PlusIcon from "../../public/images/plus.svg"
import ArrowRightIcon from "../../public/images/arrowRight.svg"
import DeleteIcon from "../../public/images/delete.svg"
import RefreshIcon from "../../public/images/refresh.svg"
import style from "./addFund.module.css"
import { deleteBankAccount, getBankAccount, getBankList, postFund, putApplyFunds } from "../../api"
import { AddFundContext } from "./AddFundContext"
import { Spinner } from 'react-bootstrap'
import Lottie from 'react-lottie-player';
import FetchError from "../../public/lottie/fetchError.json"
import queryString from 'query-string'
import { FundConfigContext } from "./AddFundConfig"

const Bank = ({validate,selectedBank,setStepsToTransactions,isAxisBank,setMakeVerification})=>{
    const {setBankId} = useContext(FundConfigContext);
    const [addedAccounts , setAddedAccounts] = useState([])
    const {setSelectedAccount, setPage, page, subPage, setSubPage, setHashID, fund, setTransferOptions, bankList, setBankList, onBack, accountType, PAGE, SUBPAGE, manageFund} = useContext(AddFundContext)
    const [deleteIndex, setDeleteIndex] = useState(null)
    const [loadingIndex, setLoadingIndex] = useState(null)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [loadingAccounts, setLoadingAccounts] = useState(null)
    const [fetchError, setFetchError] = useState(false)
    const [selectError, setSelectError] = useState(null)
    const [fetchCounter, setFecthCounter] = useState(0)
    const [deleteCounter, setDeleteCounter] = useState(0)
    const [HDFCEnable, setHDFCEnable] = useState(false)
    const BANK = PAGE.BANK

    useEffect(()=>{
        fetchBankDetails()
        if(manageFund !== null && manageFund.manageBank == true ){
          setSubPage(SUBPAGE[BANK].MANAGE)
        } else{
          setSubPage(SUBPAGE[BANK].LIST)
          setFecthCounter(0)
          setDeleteCounter(0)
        }
    },[page,manageFund,selectedBank])

    useEffect(()=>{
        let queryUrl = queryString.parse(window.location.search, {
			parseNumbers: false
		})
        if (queryUrl.hdfc) {
			setHDFCEnable(true)
        }
    },[])

    const fetchBankDetails = async(enableCounter)=>{
        if(!loadingAccounts){
            setLoadingAccounts(true)
            try{
                const accounts = await getBankAccount()
                // let selectBank = selectedBank === "HDFC Direct" ? "HDFC" : selectedBank;
                // let filteredAccounts = accounts.filter(item => item.bankName.includes(selectedBank));
                // console.log('filteredAccount',filteredAccounts, isAxisBank);  
                // let filteredList = selectedBank === "AXIS" ? filteredAccounts : accounts ;
                // console.log('filteredList',filteredList);
                let filteredAccounts
                if (selectedBank === "AXIS") {
                    filteredAccounts = accounts.filter(item => item.bankName.includes("AXIS"));
                } else {
                    filteredAccounts = accounts.filter(item => !item.bankName.includes("AXIS"));
                }
                let tempList=null
                if(!bankList.length>0){
                  tempList= await fetchBankList()
                  if(!tempList){
                    setFetchError(true)
                    enableCounter && setFecthCounter(fetchCounter+1)
                  }
                }else{
                    tempList = bankList
                }
                let other=null
                // have to go through this why this change ?
                filteredAccounts.forEach((item, index)=>{
                    let matched=false
                    for(let i=0;i<tempList.length;i++){
                        if(!other && tempList[i].code==="OTHER_INDIAN")
                            other=tempList[i]
                        if(item.bank._id===tempList[i]._id){
                            matched=true
                            break;
                        }
                    }
                    if(!matched){
                        accounts[index].bank=other
                    }
                 })
                //  setting the filtered to AddedAccounts
                setAddedAccounts(filteredAccounts)
                if(fetchError){
                    setFetchError(false)
                    enableCounter && setFecthCounter(0)
                }
            }catch(error){
                setFetchError(true)
                enableCounter && setFecthCounter(fetchCounter+1)
            }
            setLoadingAccounts(false)
        }

    }
    const fetchBankList = async() =>{
        try{
            const res = await getBankList()
            if(res && res.code === 200){
                setBankList(res.data)
                return res.data
            }else{
                return null
            }
        }catch(error){
            return null
        }

    }
    const onAccountSelection = async(account , index)=>{
        if(!account.isVerified && subPage===SUBPAGE[BANK].LIST && account.bank.code!=='OTHER_NONINDIAN'){
            setSelectedAccount(account)
            setPage(PAGE.ADD)
        }else if(!loadingIndex && loadingIndex !==0){
            if(subPage === SUBPAGE[BANK].LIST && validate()){
                setLoadingIndex(index)
                try{
                    const amountRes = await postFund(fund, accountType==='Investment'?'INVEST_ACCOUNT':'CASH_ACCOUNT')
                    if (amountRes && amountRes.code === 200 && amountRes.data.hashId) {
                        setHashID(amountRes.data.hashId)
                        const accountRes = await putApplyFunds(amountRes.data.hashId, account.hashId, false,'')
                        if(accountRes && accountRes.code === 200){
                            setSelectedAccount(account)
                            let temp={}
                            if(isAxisBank){
                                setMakeVerification(true)
                                setBankId(account.stoBankId)
                            }
                            if(account.bank.code==="OTHER_INDIAN"){
                                temp.other=true
                            } else if(account.bank.code==="OTHER_NONINDIAN"){
                                temp.other=true
                            }
                            else{
                                let tempBankList=null
                                if(!bankList.length>0){
                                    tempBankList = await fetchBankList()
                                    if(!tempBankList){
                                        setSelectError(index)
                                        setLoadingIndex(null)
                                        return
                                    }
                                }
                                else{
                                    tempBankList=bankList
                                }
                                tempBankList.forEach((item)=>{
                                    if(item && item.name === account.bankName){
                                        if(item.type==="online_transfer" && accountType!=='Saving')
                                            temp.online=true
                                        else if(item.type==="from_pickup")
                                            temp.offline=true
                                    }
                                })
                                if(account && account.bankName === 'HDFC Bank' && HDFCEnable)
                                    temp.direct=true
                                if(!Object.keys(temp).length>0)
                                    temp.other=true
                            }
                            //  if(account.bank.code==="OTHER_NONINDIAN"){
                            //   setTransferOptions(temp)
                            //   setPage(PAGE.OFFLINE)
                            //  }
                            //  else{
                            //   setTransferOptions(temp)
                            //   setPage(PAGE.EASYFUND)
                            //  }
                            if(accountType==='Saving'){
                                setTransferOptions(temp)
                                setPage(PAGE.OFFLINE)
                            }else if(temp.direct){
                                setTransferOptions(temp)
                                setPage(PAGE.DIRECT)
                            }else if(temp.online){
                                setTransferOptions(temp)
                                setPage(PAGE.ONLINE)
                            }else if(temp.offline || temp.other){
                                setTransferOptions(temp)
                                setPage(PAGE.OFFLINE)
                            }
                        }else{
                            setSelectError(index)
                            setLoadingIndex(null)
                            return
                        }
                    }
                    else {
                        setSelectError(index)
                        setLoadingIndex(null)
                        return
                    }
                }catch(error){
                    setSelectError(index)
                    setLoadingIndex(null)
                }
            }else if(subPage === SUBPAGE[BANK].MANAGE && deleteIndex !== index){
                setLoadingDelete(null)
                setDeleteIndex(index)
            }
        }
    }
    const onConfirmDelete = async (account, index)=>{
        if(!loadingDelete){
            setDeleteCounter(deleteCounter+1)
            setLoadingDelete(true)
            try{
                const res = await deleteBankAccount(account.hashId)
                if(res && res.code === 200){
                    setDeleteCounter(0)
                    setDeleteIndex(null)
                    setSelectError(null)
                    await fetchBankDetails()
                }
                else{
                    setSelectError(index)
                }
            }catch(error){
                setSelectError(index)
            }
            setLoadingDelete(false)
        }
    }
    const onDone= ()=>{
        setDeleteIndex(null)
        onBack()
    }
    return (<div className={`${subPage===SUBPAGE[BANK].MANAGE && style.manageContainer}`}>
        <div className={style.subHeader}>
            <div>{subPage ===SUBPAGE[BANK].LIST?'Select Bank Account':'Manage Bank Account'}</div>
            <div className={style.link} onClick={()=>subPage===SUBPAGE[BANK].MANAGE?onDone():setSubPage(SUBPAGE[BANK].MANAGE)}>{subPage===SUBPAGE[BANK].MANAGE?'Done': addedAccounts.length>0&& 'Manage'}</div>
        </div>
        {/* Add new bank */}
        {subPage===SUBPAGE[BANK].LIST && <button className={style.newBankContainer} onClick={()=>{setSelectedAccount(null);setPage(PAGE.ADD);}}>
            <div className="d-flex align-items-center">
                <img src={BankIcon} className={style.bankLogo}/>
                <span>Add New Bank</span>
            </div>
            <img src={ArrowPrimaryRightIcon} />
        </button>}
        {loadingAccounts||loadingAccounts===null?
            // Loading State
            <div className={style.loadingContainer}>
                <Spinner animation="border" className={style.spinnerColor}/>
                Loading...
            </div>
            :<>{fetchError || addedAccounts.length===0?
                    // Error state
                    <div className={style.fetchErrorContainer}>
                        <Lottie
                            rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
                            loop={true}
                            animationData={FetchError}
                            play
                            style={{ width: 132, height: 132 }}
                        />
                        <span>{fetchError? 'Unable to Fetch Banks':'You haven’t added any banks'}</span>
                        {fetchError?<span className={style.linkContainer} onClick={fetchCounter<3 ?()=>fetchBankDetails(true):()=>window.fcWidget.open()}>
                            <img src={RefreshIcon}/>
                            <span>{fetchCounter<3 ?'Try Again':'Contact us'}</span>
                        </span>:<span className={style.linkContainer} onClick={()=>{setSelectedAccount(null);setPage(PAGE.ADD);}}>
                            <img src={PlusIcon}/>
                            <span>Add Now</span>
                        </span>}
                    </div>
                    //Account list/manage
                    :<>
                        {addedAccounts.map((account, index)=>{
                            return <button className={`${style.addedBankContainer} ${(deleteIndex === index||selectError===index) && style.deleteEnabled}`}
                                    onClick={()=>deleteIndex !== index && selectError!==index && onAccountSelection(account, index)}>
                                <div className={style.bankDetails}>
                                    <div className="d-flex align-items-center">
                                        <img src={account.bank.logo} className={style.bankLogo}/>
                                        <div className="d-flex flex-column align-items-start">
                                            <span className={style.bankName}>{account.bankName}</span>
                                            {!(selectError===index&& subPage===SUBPAGE[BANK].LIST)?
                                                <>
                                                    {account.isVerified || account.bank.code==='OTHER_NONINDIAN' ? <span className={style.accNumber}>{account.accountNumber}</span>
                                                    :<span className={style.errorLinkContainer}>
                                                        You need to verify your account
                                                        {subPage===SUBPAGE[BANK].LIST &&<span>Verify</span>}
                                                    </span>}
                                                </>
                                                :<span className={style.errorLinkContainer}>
                                                    Unable to select bank
                                                    <span onClick={()=>window.fcWidget.open()}>Contact Us</span>
                                                </span>
                                            }
                                        </div>
                                    </div>
                                    {deleteIndex !== index && <>
                                        {subPage===SUBPAGE[BANK].LIST ?
                                        <>
                                            {loadingIndex!==index ? <img src={selectError!==index?ArrowRightIcon:RefreshIcon} onClick={()=>selectError===index&&onAccountSelection(account, index)} style={{cursor:'pointer'}}/>
                                            :<Spinner animation="border" className={style.spinnerColor}/>}
                                        </>
                                        :<img src={DeleteIcon} setDeleteIndex={()=>setDeleteIndex(index)}/>}
                                    </>
                                    }
                                </div>
                                {deleteIndex === index && <div className={style.deleteContainer}>
                                    <span>Are you sure you want to delete this bank account from your profile?</span>
                                    <div className={style.deleteOption}>
                                        {selectError!==index ?<>
                                            <button className={`${style.confirmBtn} px-0`} onClick={()=>onConfirmDelete(account, index)}>
                                                {loadingDelete && <Spinner animation="border" className={style.spinnerColorDelete}/>}
                                                Confirm Delete
                                            </button>
                                            <button className={style.cancelBtn} onClick={()=>setDeleteIndex(null)}>Cancel</button>
                                        </>:<span className={style.errorLinkContainer}>
                                            Unable to delete bank <span onClick={deleteCounter<3?()=>onConfirmDelete(account, index):()=>window.fcWidget.open()}>{deleteCounter<3?'Retry':'Contact us'}</span>
                                        </span>
                                        }
                                    </div>
                                </div>
                                }
                            </button>
                        })}
                    </>}
            </>}
    </div>)
}

export default Bank
