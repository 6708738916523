import React, { useContext} from 'react'
import styles from './Footer.module.css'
import { ThemeContext } from '../../Context/ThemeContext'
import { Row, Col } from 'react-bootstrap'
import hotline from '../../public/images/hotline.svg'
import email from '../../public/images/mail.png'
import StockalPowered from '../../public/images/stockalpowered.png'
import Logo from '../../public/images/newlogo.svg'
import { rest } from 'lodash'

const Footer = ({hotlineText, ...rest}) => {
	const { siteDisclaimer, siteDisclaimerLink, contactMail, isPartner,privacyPolicyLink  } =
    useContext(ThemeContext)

    const showPoweredLogo =  isPartner && !window.location.href.includes('landing-page')

    const isCashMmgt = window.location.href.includes('savings') 
    const isAccountSetup = window.location.href.includes('accountsetup')
   return (
		<>
    {
    (window.location.href.includes('signin') || window.location.href.includes('landing-page') || window.location.href.includes('forgot-username') || window.location.href.includes('forgot-password')  ||  window.location.href.includes('document')) ?
(
  <>
      <Row className={`${styles.footer1} ${styles.footerMargin}`}  >
				<Col md={6} xs={12} className={styles.onBoardEmail} >
					<img src={email} className={styles.hotlineImage} alt="email" />

					<span>{contactMail}</span>
				</Col>
				<Col md={6} xs={12}  className={styles.onBoardPhone}>
					<img src={hotline} className={styles.hotlineImage} alt="phone" />
          <span>{hotlineText ? hotlineText :'Hotline : --'}</span>
				</Col>
			</Row>
       <Row className={styles.footer1}>
       {
     showPoweredLogo ? (

          <Col md={4} xl={4} lg={4} style={{textAlign:'end'}} className='d-none d-md-block'>
          <span className={styles.poweredText}>Powered by</span>
          <img src={Logo}  alt="powered by" width='64px' height='16px'/>
          </Col>
			  ) : (
        <Col md={2} xl={3} className={`d-none d-md-flex`}></Col>
			)
      }
       	<Col md={5} xl={4} lg={5} xs={12}  className={`${styles.onBoardMobileBorderless} ${showPoweredLogo ? '' : styles.boardNotDisclaimer}`}>
					<div>©Borderless Investing Inc. All rights reserved {new Date().getFullYear()}</div>
				</Col>
        <Col className={styles.inBoardDisclaimer}  xs={12} md={3}>
          <a href={siteDisclaimerLink} target="_blank" rel="noopener noreferrer"> Disclaimer</a>
        </Col>
       </Row>
       {
       showPoweredLogo ? (

          <Col md={3} xl={4} lg={3} style={{textAlign:'center',marginTop:'15px'}} className='d-block d-md-none'>
          <span className={styles.poweredText}>Powered by</span>
          <img src={Logo}  alt="powered by" width='64px' height='16px'/>
          </Col>
			 ) : (
       ''
			)
      }
       </>

      )
      :
(
  <>
 <Row className={`${styles.footer1} ${styles.footerMargin}`}  >
				<Col md={6} xs={12} className={styles.onBoardEmail} >
					<img src={email} className={styles.hotlineImage} alt="email" />

					<span>{contactMail}</span>
				</Col>
				<Col md={6} xs={12}  className={styles.onBoardPhone}>
					<img src={hotline} className={styles.hotlineImage} alt="phone" />
          <span>{hotlineText ? hotlineText :'Hotline : --'}</span>
				</Col>
			</Row>
       <Row className={styles.footer1}>
       {
      isPartner ? (

          <Col md={4} xl={4} lg={4} style={{textAlign:'end'}} className='d-none d-md-block'>
          <span className={styles.poweredText}>Powered by</span>
          <img src={Logo}  alt="powered by" width='64px' height='16px'/>
          </Col>
			  ) : (
        <Col md={2} xl={3} className={`d-none d-md-flex`}></Col>
			)
      }
       	<Col md={5} xl={4} lg={5} xs={12}  className={`${styles.onBoardMobileBorderless} ${isPartner ? '' : styles.boardNotDisclaimer}`}>
					<div>©Borderless Investing Inc. All rights reserved {new Date().getFullYear()}</div>
				</Col>
        <Col className={styles.inBoardDisclaimer} style={{marginLeft:'0px'}} xs={12} md={3}>
          <a href={siteDisclaimerLink} target="_blank" rel="noopener noreferrer"> Disclaimer</a>
          {isCashMmgt ? (
          <a href='https://stockal.freshdesk.com/support/solutions/articles/43000690779-terms-conditions-cash-account' target="_blank" rel="noopener noreferrer" style={{paddingLeft:"15px"}}>Terms and Conditions</a>
        ) : ('')}
          {isAccountSetup && <a href={privacyPolicyLink} target="_blank" rel="noopener noreferrer" style={{paddingLeft:"15px"}}>Privacy Policy</a>}
        </Col>
       </Row>
       {
       isPartner ? (

          <Col md={3} xl={4} lg={3} style={{textAlign:'center',marginTop:'15px'}} className='d-block d-md-none'>
          <span className={styles.poweredText}>Powered by</span>
          <img src={Logo}  alt="powered by" width='64px' height='16px'/>
          </Col>
			 ) : (
       ''
			)
      }
       </>
  //  <Row className={styles.footer}>
  //     <Col md={6} lg={4} className={styles.inBoardMobileBorderless}>
  //       <div>©Borderless Investing Inc. All rights reserved 2021</div>
  //     </Col>
  //     <Col md={6} lg={2} className={styles.inBoardDisclaimer}>
  //       <a href={siteDisclaimerLink} target="_blank"> Disclaimer</a>
  //     </Col>
  //     <Col md={6} lg={3} className={styles.inBoardEmail}>
  //       <img src={email} className={styles.hotlineImage} alt="email" />

  //       <span>{contactMail}</span>
  //     </Col>
  //     <Col md={6} lg={3} className={styles.inBoardPhone}>
  //       <img src={hotline} className={styles.hotlineImage} alt="phone" />
  //       <span>{hotlineText ? hotlineText :'Hotline : --'}</span>
  //     </Col>
  //   </Row>
    )

    }
		</>
	)
}
export default Footer
