import React, {useEffect} from 'react';
import styles from './Logout.module.css';

const Logout = () => {

  useEffect(() => {
    setTimeout(() => {
      window.closeSite.postMessage('close')
    },5000)
  },[])

  return (
    <div className={styles.emptyPageParent}>
      <div className={styles.emptyPageHeadText}>You are logged out</div>
    </div>
  )
}

export default Logout;
