import React, { useState } from 'react'

import styles from './SelectRadioBtn.module.css'

export default function SelectRadioBtn({
	value = false,
	handleChange,
}) {

	return (
		<label className={styles.container}>
			<input
				type='radio'
				checked={value}
				onChange={()=>handleChange&&handleChange(!value)}
			/>
			<span className={styles.checkmark}></span>
		</label>
	)
}
