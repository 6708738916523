import React, { useContext } from 'react'
import styles from './IDSelection.module.css'
import { Card, Row, Col } from 'react-bootstrap'
import DL from '../../public/images/DL.svg'
import IdCard from '../../public/images/IDCard.svg'
import Passport from '../../public/images/Passport.svg'
import Permit from '../../public/images/Permit.svg'
import Arrow from '../../public/images/ProfileBackArrow.svg'
import UtilityBill from '../../public/images/utilityBill.svg'
import { ThemeContext } from '../../Context/ThemeContext'

const selectionData = [
  {
    icon: DL,
    title: 'Driver\'s license',
    subTitle: 'Front and back',
    code: 'driving_licence',
    excludeCountryList: ['IND']
  },
  {
    icon: IdCard,
    title: 'Aadhar card',
    subTitle: 'Front and back',
    code: 'national_identity_card',
    countryList: ['IND']
  },
  {
    icon: IdCard,
    title: 'Voter ID',
    subTitle: 'Front and back',
    code: 'national_identity_card',
    countryList: ['IND']
  },
  {
    icon: IdCard,
    title: 'National Identity card',
    subTitle: 'Front and back (issued in English)',
    code: 'national_identity_card',
    excludeCountryList: ['IND'],
  },
  {
    icon: Permit,
    title: 'Residence permit',
    subTitle: 'Front and back (issued in English)',
    code: 'residence_permit',
    excludeCountryList: ['IND']
  },
  {
    icon: UtilityBill,
    title: 'Other',
    subTitle: '(issued in English)',
    code: 'OTHER',
    excludeCountryList: ['IND']
  }
]

const AddressSelection = ({ country, onSelection, display }) => {

  const Cards = () => {
    let ui = [];
    selectionData.forEach(doc => {
      let { countryList, icon, title, subTitle, excludeCountryList } = doc
      let flag = true
      // (countryList && countryList.includes(country)) || countryList === undefined
      if (excludeCountryList && excludeCountryList.includes(country)) {
        flag = false
      } else if (countryList && !countryList.includes(country)) {
        flag = false
      }
      if (flag) {
        ui.push(
          <>
          <Card className={styles.card} onClick={e => onSelection(doc)}>
            <Card.Body className={styles.cardBody}>
              <Row>
                <Col xs={3} md={2}>
                  <img alt="icon" className={styles.logoImg} src={icon}></img>
                </Col>
                <Col xs={7} md={8} >
                  <div className={styles.cardTitle}>{title}</div>
                  <div className={styles.cardSubTitle}>{subTitle}</div>
                </Col>
                <Col xs={2} md={2} className={styles.cardAction}>
                  <img alt='action' src={Arrow}></img>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          </>)
      }
    })
    if (ui.length)
      return ui
    else
      return <div className={styles.noSupprted}>Registartion not supported for country Of Residence selected</div>
  }
  const { siteLogo, shortLogo, } = useContext(ThemeContext)
  return (
    <>
      <Row style={{ display }} className='justify-content-center'>
        <img
          className={styles.stockalImage}
          // alt="sitelogo"
          src={shortLogo !== '' ? shortLogo : siteLogo}></img>
      </Row>
      <Row style={{ display }} className="justify-content-center">
        <Col xl={6} sm={8} xs={12} >
          <div className={styles.selectionBox}>
            <div className={styles.title}>Select a document</div>
            <div className={styles.subTitle}>Verify your address </div>
            {Cards()}
          </div>
        </Col>
      </Row>
    </>
  )
}

export default AddressSelection;

