import React,{useEffect} from 'react'
import { useHistory} from 'react-router-dom'
import { Card } from 'react-bootstrap'
import successfulSvg from '../../public/images/newOnboarding/successful.svg'
import styles from '../NewSignupComponents/review.module.css'

const Success = () => {
   const history = useHistory()

  useEffect(() => {
    // eslint-disable-next-line no-undef
    webengage.track('cash account created')
     setTimeout(() => {
      history.push('/home')
     },3000)
  },[])

	return (
		<div className={styles.fullPage}>
			<Card className={styles.cardSignUp} style={{ marginTop: '80px' }}>
				<Card.Body className={styles.cardBodySignup}>
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<img src={successfulSvg} />
					</div>
					<div
						className={styles.submission}
						style={{
							padding: '0px 26px',
							textAlign: 'center',
							marginTop: '8px'
						}}>
						Cash A/C application has been submitted
					</div>
					<div
						className={styles.subTitle}
						style={{
							padding: '0px 85px',
							marginTop: '8px',
							textAlign: 'center'
						}}>
						We’re processing your application and will be getting back to you
						soon.
					</div>
				</Card.Body>
			</Card>
		</div>
	)
}

export default Success
