import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, ToggleButton, ToggleButtonGroup } from 'react-bootstrap'
import styles from './allStacks.module.css'
import { Footer } from '../../components'
import { Profile,Header, SideBar, StackCard } from '../../components'
import { StackCardSkeleton } from '../../skeletons/index'
import { getPerformingStacks } from '../../store/actions/index'
import {addCommasinPrice} from '../../utils'
import { Link } from 'react-router-dom'
import Select from 'react-select';
import Form from 'react'
import Filter from '../../public/images/filterExplore.png'
import SortImage from '../../public/images/SortImage.svg'
import { getTopPerformingStacks } from '../../api'
import CloseImage from '../../public/images/modalClose.png'
import NoDataImage from '../../public/images/Nointernet.svg'



const sortByOptions = [	{ value: 'popular', label: 'Popularity' },
{ value: 'cagr', label: 'CAGR High to Low' },
{ value: 'cagr', label: 'CAGR Low to High' },
{ value: 'minInvestment', label: 'Min. Investment High to Low'},
{ value: 'minInvestment', label: 'Min. Investment Low to High'}]

const riskOptions = [	{ value: '', label: 'All' },
{ value: '1', label: 'Low' },
{ value: '2', label: 'Moderate Low' },
{ value: '3', label: 'Moderate' },
{ value: '4', label: 'Moderate High' },
{ value: '5', label: 'High' }]


const AllStacks = () => {
  const [slideeffect, setslideeffect] = useState(false)
  const [sortBy, setSortBy] = useState('popular')
  const [sortByLabel, setSortByLabel] = useState("Popularity")
  const [risk, setRisk] = useState('')
  const [riskLabel,setRiskLabel] = useState('All')
  const [topStacks,setTopStacks] = useState({});
  const [noThemesFound, setNoThemesFound] = useState(false)
  const [defaultChecked, setDefaultChecked] = useState(true)
  const [filterOpen, setFilterOpen] = useState(false)
  const [cagrHighChecked, setCagrHighChecked] = useState(false)
  const [cagrLowChecked, setCagrLowChecked] = useState(false)
  const [minimumHighChecked, setMinHighChecked] = useState(false)
  const [minimumLowChecked, setMinLowChecked] = useState(false)
  const [sortOpen,setSortOpen] = useState(false)
  const [selectedRisk, setSelectedRisk] = useState('')

  // const dispatchAction = useDispatch()
	// const state = useSelector(state => state)
  // const { topStacks: {topStacks} = {} } = state
  useEffect(() => {
    // if(Object.keys(state.topStacks).length === 0){
    //   dispatchAction(getPerformingStacks())
    // }
    const asynchFetch = async () => {
      setTopStacks({})
      getTopPerformingStacks(sortBy,risk).then(topStacks => {
        if (topStacks && topStacks.length > 0) {
          if(sortByLabel === "Min. Investment Low to High"){
            setTopStacks(topStacks && topStacks.length > 0
              ? [...topStacks.reverse()]
              : null)
          } if(sortByLabel === "CAGR Low to High"){
            setTopStacks(topStacks && topStacks.length > 0
              ? [...topStacks.reverse()]
              : null)
          } else {
            setTopStacks(
              topStacks && topStacks.length > 0
              ? [...topStacks]
              : null
            )
          }
          setNoThemesFound(false)
        }
        else {
          console.log('no themes found')
          setNoThemesFound(true)
        }
      })}
  asynchFetch()
  }, [risk,sortByLabel])

  const customStyle = () => ({
    container: provided => ({
      ...provided,
      backgroundColor:'transparent',
      width: '100%',
      top: '0%',
      borderRadius:'12px',
      border:"none"
    }),
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        color: isSelected || isFocused ? 'black' : 'black', // setting
        backgroundColor: isSelected || isFocused ? '#EFEFF1' : '#F7F7F8',

        ':active': {
          ...styles[':active'],
          backgroundColor: 'white'
        }
      }
    },
    singleValue: styles => ({
      ...styles,
      color: '#666666',
    }),
    indicatorSeparator: provided => ({ ...provided, visibility: 'hidden' }),
    control: base => ({
      ...base,
      fontSize: '15px',
      borderWidth: 0,
      borderRadius: 12,
      backgroundColor:'#EFEFF1',
      boxShadow: 'none',
      '&:hover': {
        border: 0
      }
    }),
    menu: base => ({
      ...base,
      borderRadius: 12,
      outlineStyle: "none",
      border:"1px solid white",
      // border:"none",
      // border: "1px s",
      width:250,
      background: '#F7F7F8',
    })
  })

  const handleEffect = () => {
		setslideeffect(prevState => setslideeffect(!prevState))
	}

  // const newArray = topStacks && topStacks.length && topStacks.slice().reverse();
  const newArray = topStacks

  const selectSortRadioButton = (e) => {
    console.log('e',e.target.name)
    if(e.target.name == "CAGR High to Low"){
      setDefaultChecked(false)
      setCagrHighChecked(true)
      setCagrLowChecked(false)
      setMinLowChecked(false)
      setMinHighChecked(false)
      setSortBy(e.target.value)
      setSortByLabel(e.target.name)
    } if(e.target.name == "CAGR Low to High"){
      setDefaultChecked(false)
      setCagrHighChecked(false)
      setCagrLowChecked(true)
      setMinLowChecked(false)
      setMinHighChecked(false)
      setSortBy(e.target.value)
      setSortByLabel(e.target.name)
    }
     else if(e.target.name == "Popularity"){
      setDefaultChecked(true)
      setCagrHighChecked(false)
      setCagrLowChecked(false)
      setMinLowChecked(false)
      setMinHighChecked(false)
      setSortBy(e.target.value)
      setSortByLabel(e.target.name)
    } else if(e.target.name == "Min. Investment Low to High"){
      setDefaultChecked(false)
      setCagrHighChecked(false)
      setCagrLowChecked(false)
      setMinLowChecked(true)
      setMinHighChecked(false)
      setSortBy(e.target.value)
      setSortByLabel(e.target.name)
    }  else if(e.target.name == "Min. Investment High to Low"){
      setDefaultChecked(false)
      setCagrHighChecked(false)
      setCagrLowChecked(false)
      setMinLowChecked(false)
      setMinHighChecked(true)
      setSortBy(e.target.value)
      setSortByLabel(e.target.name)
    }
  }


  return(
        <>
            <Header/>
            <Container className={slideeffect ? styles.shiftPos : styles.originalPos}>
              {/* <Row className={`${styles.heading} ${styles.mobileMargin}`}> */}
              <Row>
                <Col lg={4} md={1} sm={2} className={`${styles.heading} ${styles.mobileMargin}`}>
                  Stacks
                </Col>
                <Col lg={8} md={11} sm={10} className={styles.DropdownWeb}>
                  <Row>
                    <Col lg={2} md={2} sm={2} className={styles.dropdownHeading}>
                      Sort by
                    </Col>
                    <Col lg={4} md={4} sm={4}>
                      <Select
                        placeholder={sortByLabel.substring(0,17)}
                        value={sortBy}
                        onChange={(value) => setSortBy(value.value,setSortByLabel(value.label))}
                        options={sortByOptions}
                        styles={customStyle()}
                        isSearchable={false}
                      />
                    </Col>
                    <Col lg={2} md={2} sm={2} className={styles.dropdownHeading}>
                      Risk Level
                    </Col>
                    <Col lg={4} md={4} sm={4}>
                      <Select
                        placeholder={riskLabel}
                        value={risk}
                        onChange={(value) => setRisk(value.value,setRiskLabel(value.label))}
                        options={riskOptions}
                        styles={customStyle()}
                        isSearchable={false}
                      />
                    </Col>
                  </Row>
                </Col>
							</Row>
              <Row className={`${styles.semiHeading} ${styles.mobileMargin}`}>
                Expert made model portfolios
							</Row>
              <Row
                className={`${styles.cardtopMargin} ${styles.webDesignSeeAllStack}`}>
                {newArray && newArray.length > 0 ? (
                  newArray.map((item, index) => {
                    return (
                      <Col key={index} md={6} lg={4} sm={6} xs={9} xl={3}>
                        <StackCard
                          image={item.logo}
                          ticker={item.ticker}
                          createdBy={item.createdByTitle}
                          desc={item.description}
                          cagr={item.cagr}
                          minInvest={item.minimumInvestment}
                        />
                      </Col>
                    )
                  })
                ) : (
                  noThemesFound ?
                  <div className={`mx-auto ${styles.noOrdersFound}`}>
                    <img src={NoDataImage}></img>
                    <p className={styles.noOrdersText}>No Stacks Found</p>
                  </div>
                  :
                  <>
                  <div style={{ display: 'flex' }}>
                    <StackCardSkeleton />
                    <StackCardSkeleton />
                    <StackCardSkeleton />
                    <StackCardSkeleton />
                  </div>
                  <div style={{ display: 'flex' }}>
                    <StackCardSkeleton />
                    <StackCardSkeleton />
                    <StackCardSkeleton />
                    <StackCardSkeleton />
                  </div>
                  <div style={{ display: 'flex' }}>
                    <StackCardSkeleton />
                    <StackCardSkeleton />
                    <StackCardSkeleton />
                    <StackCardSkeleton />
                  </div>
                  </>
                )
                }
              </Row>
              <Row className={noThemesFound ? styles.displayBlockSeeAllCard : styles.mobileStackSeeAllCard}>
              {newArray && newArray.length > 0 ? (
                  newArray.map((item, index) => {
                    return(
                      <>
                      <Link to={`/stackdetail/${item.ticker}`} className={styles.navLink}>
                      <Col key={index} xs={12}>
                        <Card className={styles.trendingCard}>
                          <Card.Body
                            className={styles.cardBody}
                          >
                            <Row className='mb-3'>
                              <Col xs={3} md={4}>
                                <img className={styles.stockImage} src={item.logo}></img>
                              </Col>
                              <Col xs={8} className={styles.stackCardHeading}>
                                <div className={styles.stockTitle}>{item.ticker}</div>
                                <div className={styles.creator}>
                                  {item.name.length < 20
                                    ? item.name
                                    : `${item.name.substring(0, 25)}...`}
                                </div>
                              </Col>
                            </Row>
                            <Row className='mb-md-2 mb-3'>
                              <Col>
                                <h3 className={styles.stockName}>{`${item.description.substring(
                                  0,
                                  80
                                )}...`}</h3>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={3} className='p-0'>
                                <div className={`${styles.minHead} ${styles.mininv} mb-1`}>Min.inv</div>
                                <div className={`${styles.minValue} ${styles.mininv}`}>${addCommasinPrice(item.minimumInvestment)}</div>
                              </Col>
                              <Col xs={4}>
                                <div className={`${styles.cagr} mb-1`}>CAGR</div>
                                <div className={styles.cagrValue}>{item.cagr}%</div>
                              </Col>
                              <Col xs={5}>
                                <div className={`${styles.minHead} mb-1`}>Created by</div>
                                <div className={styles.minValue}> {item.name.length <= 12 ?  item.createdByTitle : `${item.createdByTitle.substring(0, 12)}...`} </div>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                      </Link>
                      <hr className={styles.borderBottom}></hr>
                      </>
                  )
                  })
                ) :
                  noThemesFound ?
                  <div className={styles.noOrdersFoundMobile}>
                    <img src={NoDataImage}></img>
                    <p className={styles.noOrdersText}>No Stacks Found</p>
                  </div>
                  :
                <>
                  <div style={{ display: 'flex' }}>
                    <StackCardSkeleton />
                    <StackCardSkeleton />
                  </div>
                  <div style={{ display: 'flex' }}>
                    <StackCardSkeleton />
                    <StackCardSkeleton />
                  </div>
                </>
                }
              </Row>
              <div className={styles.sideBarMobileHide}>
                <SideBar handleEffect={handleEffect} />
              </div>
              {/* <div style={{ marginTop: '100px' }}>
                <Footer />
              </div> */}
            </Container>
            <Row className={`${styles.fixedBottom}`}>
              <Col xs={6} sm={6} className={styles.sortColumn} onClick={()=>setSortOpen(true)}>
                <span><img src={SortImage}></img></span>
                <span className={styles.sortTitle}>Sort</span>
              </Col>
              <Col xs={6} sm={6} className={styles.sortColumn} onClick={()=>setFilterOpen(true)}>
                <span><img src={Filter}></img></span>
                <span className={styles.sortTitle}>Filter</span>
              </Col>
            </Row>
            {sortOpen ?
            <div className={styles.mobileSort}>
              <div className={styles.responsivePinContainerBackground}></div>
              <div className={styles.ResponsiveChangePinOtpContainer}>
                <div>
                  <img src={CloseImage} className={styles.CrossImage} onClick={() => setSortOpen(false)}/>
                </div>
                <div className={styles.mobileHead}>
                  Sort
                </div>
                <div className={styles.radioItem}>
                    <input type="radio" id="ritema"  value='popular' name="Popularity" onClick={(e)=>selectSortRadioButton(e,setSortOpen(false))} checked={defaultChecked ? "checked" : ""} />
                    <label for="ritema">Popular</label>
                </div>

                <div className={styles.radioItem}>
                    <input type="radio" id="ritemb" value='cagr' name="CAGR High to Low" onClick={(e)=>selectSortRadioButton(e,setSortOpen(false))} checked={cagrHighChecked ? "checked" : ""} />
                    <label for="ritemb">CAGR High to Low</label>
                </div>

                <div className={styles.radioItem}>
                    <input type="radio" id="ritemc"  value='cagr' name="CAGR Low to High" onClick={(e)=>selectSortRadioButton(e,setSortOpen(false))} checked={cagrLowChecked ? "checked" : ""} />
                    <label for="ritemc">CAGR Low to High</label>
                </div>

                <div className={styles.radioItem}>
                    <input type="radio" id="ritemd" value='minInvestment' name="Min. Investment High to Low" onClick={(e)=>selectSortRadioButton(e,setSortOpen(false))} checked={minimumHighChecked ? "checked" : ""} />
                    <label for="ritemd">Min. Investment High to Low</label>
                </div>

                <div className={styles.radioItem}>
                    <input type="radio" id="riteme" value='minInvestment' name="Min. Investment Low to High" onClick={(e)=>selectSortRadioButton(e,setSortOpen(false))} checked={minimumLowChecked ? "checked" : ""} />
                    <label for="riteme">Min. Investment Low to High</label>
                </div>
              </div>
            </div> : ""}
            {filterOpen ?
            <div className={styles.mobileSort}>
              <div className={styles.responsivePinContainerBackground}></div>
              <div className={styles.ResponsiveFilterContainer}>
                <div>
                  <img src={CloseImage} className={styles.CrossImage} onClick={() => setFilterOpen(false)}/>
                </div>
                <div className={styles.mobileHead}>
                  Filter
                </div>
                <div className={styles.mobileHead} style={{marginBottom:"16px"}}>
                  Risk Level
                </div>
                <div value="" className={risk === '' ? styles.selectedFilterBox : styles.filterbox} onClick={()=>setRisk('',setFilterOpen(false))}>
                  All
                </div>
                <div value="1" className={risk === '1' ? styles.selectedFilterBox : styles.filterbox } onClick={()=>setRisk('1',setFilterOpen(false))}>
                  Low
                </div>
                <div value="2" className={risk === '2' ? styles.selectedFilterBox : styles.filterbox} onClick={()=>setRisk('2',setFilterOpen(false))}>
                  Moderate Low
                </div>
                <div value="3" className={risk === '3' ? styles.selectedFilterBox : styles.filterbox} onClick={()=>setRisk('3',setFilterOpen(false))}>
                  Moderate
                </div>
                <div value="4" className={risk === '4' ? styles.selectedFilterBox : styles.filterbox} onClick={()=>setRisk('4',setFilterOpen(false))}>
                  Moderate High
                </div>
                <div value="5" className={risk === '5' ? styles.selectedFilterBox : styles.filterbox} onClick={()=>setRisk('5',setFilterOpen(false))}>
                  High
                </div>
              </div>
            </div> : ""}
        </>
    )
}

export default AllStacks
