import React from 'react'
import { Card, Row} from 'react-bootstrap'
import SkeletonElement from '../../skeletons/SkeletonElement'
import Shimmer from '../../skeletons/Shimmer'

const SkeletonPan = () => {
  return(
    <Card className='skeletonCard' style={{ width: '350px',height:'250px',margin:'auto',marginTop: '40px' }}>
    <Card.Body>
        <SkeletonElement height='20px' width='150px'/>
        <SkeletonElement height='30px' width='250px'/>
        <Shimmer />
    </Card.Body>
  </Card>
  )
}

export default SkeletonPan
