import React, {Fragment} from 'react';
import noteImage from '../../public/images/note.png'
import styles from './instruction.module.css'
import {circleA, circleB, circleC, circleD, circleE, circleF} from './assets'
import Copy from './copy'
import { Row, Col,Accordion} from 'react-bootstrap'
import CustomToggle from './customToggle';
import DownloadInstruction from './downloadInstruction';
import TableError from './tableError'
import DetailError from './detailError';

const IdfcLRS= ({info, fetchBrokerDetails}) => {
	const cloudfront = `${process.env.REACT_APP_ASSESTS}/platform/fund/idfc`
	return(
		<>
			<Row>
				<Col>
					<div className={styles.bankName}>IDFC Bank</div>
				</Col>
				<Col style={{ textAlign: 'end'}} className='pl-0'>
					<DownloadInstruction bank={'IDFC_BANK'}/>
				</Col>
				<Col md={12} style={{ marginTop: '30px',marginBottom:"30px" }}>
					<div className={styles.transactionCard}>
						<img src={noteImage} />
						<span className={styles.transaction}>
							There is a limit of $25,000 on online transaction per day. If you
							need to remit more, please use Offline Transfer.
						</span>
					</div>
				</Col>
			</Row>
			<Row>
				<Accordion defaultActiveKey='0' className={styles.responsiveInstructions}>
					<CustomToggle
						eventKey='0'
						title='Step 1. Add Beneficiary' />
					<Accordion.Collapse eventKey='0'>
						<div className={`card-body ${styles.cardBody}`}>
							<ol className={styles.list} >
								<li>
									<div className={styles.netbanking}>
										Login to your IDFC First Netbanking Account  by clicking on below link:
									</div>
									<div className={styles.login}>
										<a href='https://my.idfcfirstbank.com/login' target="_blank" rel="noopener noreferrer">
											{`Login to IDFC Netbanking >`}
										</a>
									</div>
								</li>
								<li> 
									<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
										Following screen will appear, Click on &nbsp;
										<img src={circleA} className={styles.circularPointer} /> 
										<span className={styles.key}>Transfer Funds Abroad</span> 
									</div>
									<div className={styles.marginStepSecondary}>
										<img src={`${cloudfront}/img1.png`} className={styles.responsiveImage}/>
									</div>
								</li>				
								<li> 
									<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
										Select your Debit Account &nbsp;
										<img src={circleA} className={styles.circularPointer} /> 
										<span className={styles.key}>Select Debit Account</span> 
									</div>
									<div className={styles.marginStepSecondary}>
										<img src={`${cloudfront}/img2.png`} className={styles.responsiveImage}/>
									</div>
								</li>
								<li> 
									<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
										Following screen will appear, Click on &nbsp;
										<img src={circleA} className={styles.circularPointer} /> 
										<span className={styles.key}>Beneficiary</span> 
									</div>
									<div className={styles.marginStepSecondary}>
										<img src={`${cloudfront}/img3.png`} className={styles.responsiveImage}/>
									</div>
								</li>
								<li> 
									<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
										Now click on &nbsp;
										<img src={circleA} className={styles.circularPointer} /> 
										<span className={styles.key}>Add New Beneficiary</span> 
									</div>
									<div className={styles.marginStepSecondary}>
										<img src={`${cloudfront}/img4.png`} className={styles.responsiveImage}/>
									</div>
								</li>
								<li> 
									<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
										Enter the details mentioned under the next point in the prescribed colums: &nbsp;
									</div>
									<div className={styles.marginStepSecondary}>
										<img src={`${cloudfront}/img5.png`} className={styles.responsiveImage}/>
									</div>
								</li>				
								<li>
									<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
										Following are the Beneficiary Details.
									</div>
									<div style={{ overflow:'hidden'}} className={`${styles.beneficiaryTable} ${styles.marginStepSecondary} ${!info&&styles.beneficiaryTableError}`}>
										{!info ? <TableError fetchBrokerDetails={fetchBrokerDetails}/>
											:<Fragment>
												<div  className={styles.beneficiaryTableRow}>
													<img src={circleA} className={styles.circularPointer} />
													<div className={styles.beneficiaryTableContent}>
														<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>										
															Receiver SWIFT Code:
														</div>
														<div className={styles.beneficiaryTableValue}>
															<span>{info.SWIFTCode}</span>
															<Copy data={{label:'Receiver SWIFT Code', value:info.SWIFTCode}}/>
														</div>
													</div>
												</div>
												<div className={`${styles.beneficiaryAccNum} ${styles.beneficiaryTableRow}`}>
													<img src={circleB} className={styles.circularPointer} />
													<div className={styles.beneficiaryTableContent}>
														<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
															Receiver Account Number:
														</div>
														<div className={styles.beneficiaryAccNumValue}>
															<div className={styles.beneficiaryTableValue}>
																<span>{info.accountNumber}</span>
																<Copy data={{label:'Receiver Account Number', value:info.accountNumber}} />
															</div>
															<div className={styles.beneficiaryAccInfo}>Everyone has an Unique Beneficiary Account Number.</div>										
														</div>
													</div>
												</div>
												<div  className={styles.beneficiaryTableRow}>
													<img src={circleC} className={styles.circularPointer} />
													<div className={styles.beneficiaryTableContent}>
														<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>										
															Beneficiary Type:
														</div>
														<div className={styles.beneficiaryTableValue}>
															<span>Entity</span>
															<Copy data={{label:'Beneficiary Type:', value:'Entity'}} />
														</div>
													</div>
												</div>
												<div  className={styles.beneficiaryTableRow}>
													<img src={circleD} className={styles.circularPointer} />
													<div className={styles.beneficiaryTableContent}>
														<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>										
															Receiver Name:
														</div>
														<div className={styles.beneficiaryTableValue}>
															<span>{info && info.name}</span>
															<Copy data={{label:'Receiver Name', value:info.name}} />
														</div>
													</div>
												</div>
												<div  className={styles.beneficiaryTableRow}>
													<img src={circleE} className={styles.circularPointer} />
													<div className={styles.beneficiaryTableContent}>
														<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>										
															Receiver Address:
														</div>
														<div className={styles.beneficiaryTableValue}>
															<span>{info.address}</span>
															<Copy data={{label:'Receiver Address', value:info.address}} />
														</div>
													</div>
												</div>
											</Fragment>
										}
									</div>
								</li>
								<li> 
									<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
										Select Purpose of Sending as: &nbsp;
										<img src={circleA} className={styles.circularPointer} /> 
										<span className={styles.key}>Portfolio investment abroad in equity.</span> 
									</div>
									<div className={styles.marginStepSecondary}>
										<img src={`${cloudfront}/img6.png`} className={styles.responsiveImage}/>
									</div>
								</li>
								<li> 
									<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
										After adding details click on &nbsp;
										<img src={circleA} className={styles.circularPointer} /> 
										<span className={styles.key}>Continue</span> 
									</div>
									<div className={styles.marginStepSecondary}>
										<img src={`${cloudfront}/img7.png`} className={styles.responsiveImage}/>
									</div>
								</li>
								<li>
									<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
										The Beneficiary will be added within 30 minutes.
									</div>
								</li>						
							</ol>					
						</div>
					</Accordion.Collapse>
				</Accordion>
				<Accordion className={styles.responsiveInstructions}>
					<CustomToggle
					eventKey='1'
					title='Step 2. Transfer funds' />
					<Accordion.Collapse eventKey='1'>
						<div className={`card-body ${styles.cardBody}`}>
							<ol className={styles.list} >
								<li>
									<div className={styles.netbanking}>
										Login to your Netbanking account
									</div>
									<div className={styles.login}>
										<a href='https://my.idfcfirstbank.com/login' target="_blank" rel="noopener noreferrer">
											{`Login to IDFC Netbanking >`}
										</a>
									</div>
								</li>
								<li>
									<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
										Following screen will appear, Click on &nbsp;
										<img src={circleA} className={styles.circularPointer} />
										<span className={styles.key}>Transfer Funds Abroad</span> 
									</div>
									<div className={styles.marginStepSecondary}>
										<img src={`${cloudfront}/img8.png`} className={styles.responsiveImage}/>
									</div>
								</li>
								<li>
									<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
										Select your &nbsp;
										<img src={circleA} className={styles.circularPointer} />
										<span className={styles.key}>Debit Account</span> 
									</div>
									<div className={styles.marginStepSecondary}>
										<img src={`${cloudfront}/img9.png`} className={styles.responsiveImage}/>
									</div>
								</li>
								<li>
									<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
										Following screen will appear. Click on &nbsp;
										<img src={circleA} className={styles.circularPointer} />
										<span className={styles.key}>Transfer Funds Abroad</span> 
									</div>
									<div className={styles.marginStepSecondary}>
										<img src={`${cloudfront}/img10.png`} className={styles.responsiveImage}/>
									</div>
								</li>
								<li>
									<div className={`d-flex flex-column align-items-start ${styles.netbanking} ${styles.marginStepPrimary}`}>
										Fill the following details.
										<div className={styles.netbanking}>
											<img src={circleA} className={styles.circularPointer} /> 
											Select Receiving Country as
											<span className={styles.key}> USA.</span> 
										</div>
										<div className={`d-flex flex-sm-row flex-column ${styles.netbanking}`}>
											<div>
												<img src={circleB} className={styles.circularPointer} /> 
												Select Beneficiary as &nbsp;
											</div>																				
											{info?
												<span className={styles.key}>{info.name}</span>
												:<DetailError className='pt-sm-0 pt-2' fetchBrokerDetails={fetchBrokerDetails} label='Beneficiary'/>
											}
										</div>
										<div className={styles.netbanking}>
											<img src={circleC} className={styles.circularPointer} /> 
											Enter the Amount in									
											<span className={styles.key}> USD.</span> 
										</div>
										<div className={styles.netbanking}>
											<img src={circleD} className={styles.circularPointer} /> 
											Select Receiving Currency as									
											<span className={styles.key}> USD</span> 
										</div>
										<div className={styles.netbanking}>
											<img src={circleE} className={styles.circularPointer} /> 
											Select Purpose Of Remittance as									
											<span className={styles.key}> Portfolio investment in equity.</span> 
										</div>
										<div className={styles.netbanking}>
											<img src={circleF} className={styles.circularPointer} /> 
											Select Source Of Funds as
											<span className={styles.key}> Income from Salary/Savings.</span> 
										</div>
										<div className={styles.marginStepSecondary}>
											<img src={`${cloudfront}/img11.png`} className={styles.responsiveImage}/>
										</div>
									</div>
								</li>
								<li>
									<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
										The option
										<span className={styles.key}> ‘Upload Your Document’ </span>
										is used in case your bank account with IDFC is 
										less than 1 year old. If this is the case, you will be required to upload the latest one 
										year statement of another bank. If this statement is not available, please upload 
										your Income Tax Assessment Order or Return alternatively. &nbsp;
										<img src={circleA} className={styles.circularPointer} /> 
										<span className={styles.key}>Upload Your Document &nbsp;</span>
										<img src={circleB} className={styles.circularPointer} /> 
										<span className={styles.key}>Continue</span>
									</div>
									<div className={styles.marginStepSecondary}>
										<img src={`${cloudfront}/img12.png`} className={styles.responsiveImage}/>
									</div>
								</li>
								<li>
									<div className={`d-flex flex-column align-items-start ${styles.netbanking} ${styles.marginStepPrimary}`}>
										<span>Fill in the Additional Information box as, &nbsp;</span>
										{info ? 
											<div>
												<img src={circleA} className={styles.circularPointer} /> 
												<span className={styles.key}>{info.accountNumber}</span>  
											</div>
											:<DetailError className='pt-2' fetchBrokerDetails={fetchBrokerDetails} label='Info'/>
										}
										
									</div>
									<div className={styles.marginStepSecondary}>
										<img src={`${cloudfront}/img13.png`} className={styles.responsiveImage}/>
									</div>
								</li>
								<li>
									<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
										Accept all the Terms & Conditons, click on &nbsp;
										<img src={circleA} className={styles.circularPointer} />
										<span className={styles.key}>Continue</span> 
									</div>
									<div className={styles.marginStepSecondary}>
										<img src={`${cloudfront}/img14.png`} className={styles.responsiveImage}/>
									</div>
								</li>
								<li>
									<div className={`d-flex flex-column align-items-start ${styles.netbanking} ${styles.marginStepPrimary}`}>
										<div className={styles.netbanking}>
											The process to Remit the Funds is now complete! 
										</div>
										<div className={styles.netbanking}>
											Please note that it takes
											<span className={styles.key}> 2-3 Business Days </span>									 
											after the Deduction of Funds from your Bank Account for it to reflect in your Investment Account.									
										</div>
									</div>
								</li>		
							</ol>
						</div>
					</Accordion.Collapse>
				</Accordion>
			</Row>
		</>
	)
}

export default IdfcLRS;
