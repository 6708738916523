import React from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import { Formik } from 'formik'
import * as yup from 'yup'
import { format } from 'date-fns'
import OutsideClickHandler from 'react-outside-click-handler'
import DatePicker from '../../../DatePicker/DatePicker'
import { updatedAccount } from '../../../../api'
import SignUpButton from '../../../SignUp/SignUpButton'
import SignUpInput from '../../../SignUp/SignUpInput'
import SignUpDropDown from '../../../SignUp/SignUpDropDown'
import DropDownButton from '../../../SignUp/DropDownButton'
import styles from '../../AccountSetUp.module.css'
import { setUserInfoSession } from '../../../../common'
import { toPascalCase } from '../../../../utils'
import DropdownSvg from '../../../../public/images/newOnboarding/Dropdown.svg'
import CalendarSvg from '../../../../public/images/newOnboarding/Calendar.svg'
import Popup from '../../../NewSignupComponents/Popup'
import { ThemeContext } from '../../../../Context/ThemeContext'



class PersonalDetail extends React.Component {
  static contextType = ThemeContext;

	constructor(props) {
    super(props)
    const { formData: { personalDetail }} = this.props
		this.state = {
			firstName: personalDetail.firstName
				? personalDetail.firstName
				: '',
			middleName: personalDetail.middleName ? personalDetail.middleName : '',
			lastName: personalDetail.lastName
				? personalDetail.lastName
				: '',
			phoneNumber: personalDetail.phoneNumber
				? personalDetail.phoneNumber
				: '',
			gender: personalDetail.gender ? personalDetail.gender : '',
			dateOfBirth: personalDetail.dateOfBirth ? personalDetail.dateOfBirth : '',
      showCalendar: false,
      showDropDownCitizen:false,
      showDropDownGender:false,
      flagCode:personalDetail.flagCode
      ? personalDetail.flagCode
      : '',
      citizenShip: personalDetail.citizenShip
				? personalDetail.citizenShip
        : '',
      citizenShipCode: personalDetail.citizenShipCode
      ? personalDetail.citizenShipCode
      : '',
      citizenshipList:'',
      currentDate : new Date().setFullYear(new Date().getFullYear() - 18),
      showLastNamePopUp:false,
      lastNameRequired:false,
      loading:false
    }
    if(personalDetail.citizenShipCode)
      this.props.setCountryByCitizenship(personalDetail.citizenShipCode);

    this.myRef = React.createRef();
    this.lastNameRef = React.createRef()
  }

  componentDidMount = async () => {
    if (!this.props.disabledValue) {
        this.myRef.current.focus();
    }
    try {
        let countryData = await this.checkCountryList();
        this.setState({citizenshipList:countryData});
    } catch (error) {
        console.log('Error fetching country list:', error);
    }
  }

  checkCountryList = async () => {
    const { partnerConfig } = this.context;
    let countryList = partnerConfig?.[0]?.getCountryList(countryList) || this.props.citizenshipList || "";
    return countryList;
  }

  getCountryList = async(list) => {
    let countryData = []
    list.map(item => {
      if (item) {
        const temp = {
          value:
            item['countryName'],
          label:
            item['countryName'],
          code: item['country'],
          code2:
            item['countryCode2']
        }
        countryData.push(temp)
      }
    })
  return countryData
  }

  // componentDidUpdate = async(prevProps, prevState) => {
  //   const { partnerConfig } = this.context;
  //   if (prevProps !== this.props) {
  //     const { citizenshipList = [] } = this.props;
  //       const countryList = await partnerConfig?.[0]?.countryList;
  //         console.log('countryList',countryList)
  //       if (countryList) {
  //         let countryData = await this.getCountryList(countryList);
  //           console.log('countryData',countryData)
  //           this.setState({citizenshipList:countryData});
  //       } else {
  //         this.setState({ countryList: citizenshipList });
  //       }
  //     }
  //   }

    componentDidUpdate = async (prevProps) => {
      const { partnerConfig } = this.context;
      if (prevProps !== this.props) {
        const { citizenshipList = [] } = this.props;
        const countryList = partnerConfig?.[0]?.citizenshipCountryList || [];
        if (countryList.length) {
          let countryData = await this.getCountryList(countryList);
          this.setState({ citizenshipList: countryData });
        } else {
          this.setState({ citizenshipList });
        }
      }
    };

	formatDateDisplay = date => {
		if (typeof date === 'string') {
			return date
		}
		return format(date, 'yyyy-MM-dd')
	}

	toggleCalender = () => {
		this.setState({
			showCalendar: !this.state.showCalendar
		})
	}
	onOutsideClick = () => {
		this.setState({ showCalendar: false })
	}
	onHandleDate = date => {
    let financialDetailError= {
      ...this.props.errors,
      pancardError:''
    }
    this.props.errorHandler({financialDetailError})
		this.setState({
			dateOfBirth: date
		})
		this.toggleCalender()
	}

	onChangeHandler = (e,handleChange) => {
    if(e.target.name === 'firstName')
    {
      // replace(/[^A-Za-z]/ig, '').replace(/\s\s+/g, ' ')
      const regex = new RegExp('^[a-zA-Z]*$');
      if(regex.test(e.target.value))
        handleChange(e)
    }else if(e.target.name === 'lastName')
    {
      const regex = new RegExp('^[a-zA-Z]*[.]{0,1}[a-zA-Z]*$');
      if(regex.test(e.target.value))
        handleChange(e)
    }
    else if(e.target.name === 'middleName')
    {
      const regex = new RegExp('^[a-zA-Z ]*$');
      if(regex.test(e.target.value) && !e.target.value.includes('  '))
        handleChange(e)
    }
    else if(e.target.name === 'phoneNumber')
    {
      let personalDetailError = {
        ...this.props.errors,
        phoneNumberError:''
      }
      this.props.errorHandler({personalDetailError})
      const regex = new RegExp('^[0-9]*$');
      if(regex.test(e.target.value) && e.target.value.length < 14)
        handleChange(e)
    }
    else{
      this.setState({
        [e.target.name]: e.target.value
      })
      handleChange(e);
    }
     this.onUpdateFormValidation(false)
  }


  onUpdateFormValidation = (value) => {
    if(this.props.formValidation.personalDetail !== value){
    this.props.updateFormValidation({...this.props.formValidation,personalDetail:value})
    }
  }

  onGetFlagCode = () => {

  }

  onCitizenShipChange = (value,handleChange) => {
		const filter = this.state.citizenshipList.filter(item => {
			return value === (item.value)
    })
    let event = {
      target: { name: 'citizenShip', value:'' }
    }
    if(filter.length !== 0){
      this.props.setCountryByCitizenship(filter[0].code);
      this.setState({
        citizenShip: filter[0].value,
        citizenShipCode: filter[0].code,
      })
     event.target.value=value;
    }else{
      event.target.value='';
      this.setState({citizenShip:'',citizenShipCode:''})
    }
    handleChange(event);

  }

  errorHandlerMessage = (message) => {
    let personalDetailError = {
    firstNameError:'',
    lastNameError:'',
    phoneNumberError:'',
    dateOfBirthError:'',
    errorMessage:'',
    error:false}
    if(message.includes('firstName')){
      personalDetailError = {...personalDetailError,firstNameError:message}
    }
    else if(message.includes('lastName')){
      personalDetailError = {...personalDetailError,lastNameError:message}
    }
    else if(message.includes('Phone')){
      personalDetailError = {...personalDetailError,phoneNumberError:message}
    }
    else if(message.includes('date') || message.includes('18 year') ){
      personalDetailError = {...personalDetailError,dateOfBirthError:message}
    }
    else{
      personalDetailError = {...personalDetailError,errorMessage:message}
    }
    personalDetailError = {...personalDetailError,error:true}
    this.props.errorHandler({personalDetailError})
  }

	render() {
		const genderOptions = [
			{ value: 'Male', label: 'Male' },
			{ value: 'Female', label: 'Female' },
      { value: 'Other', label: 'Other' }
		]
		let date = new Date()
    date.setFullYear(date.getFullYear() - 18)
    const {firstNameError,
      lastNameError,
      phoneNumberError,
      dateOfBirthError,
      errorMessage} = this.props.errors
    const {disabledValue} = this.props
		return (
			<>
				<Row className={styles.blackText}>
						Why do we need this?
				</Row>
        <Row className={styles.detail}>We need to know who you are for legal and security reasons associated with creating your account. Please ensure everything you enter is accurate.</Row>
				<Formik
					initialValues={this.state}
					validationSchema={yup.object({
            firstName: yup.string().trim()
                .matches(/^[a-zA-Z]*$/ , 'Only Alphabets are allowed in First Name.')
                .required('First Name is required '),
            middleName: yup.string().trim()
                .matches(/^[a-zA-Z ]*$/ , 'Alphabets and space are allowed in Middle Name.'),
            lastName: this.state.lastNameRequired ? yup.string().trim()
                .matches(/^[a-zA-Z]*[.]{0,1}[a-zA-Z]*$/ , 'Only Alphabets are allowed in Last Name.')
                .required('Last Name is required ') :
                yup.string().trim()
                .matches(/^[a-zA-Z]*[.]{0,1}[a-zA-Z]*$/ , 'Only Alphabets are allowed in Last Name.'),
						// phoneNumber: yup
						// 	.number()
						// 	.typeError('Phone Number must be digit')
						// 	.required('Phone Number is required'),
						gender: yup.string().required('Gender is required '),
						dateOfBirth: yup.string().trim().required('Date of Birth is required'),
						// citizenShip: yup.string().trim().required('citizenShip is required ')
					})}
					onSubmit={values => {
            this.setState({loading:true})
						const {
							firstName,
							middleName,
							lastName,
							phoneNumber,
							gender,
							dateOfBirth,
              				citizenShip,
              // citizenShipCode
            				} = values


						const data = {
							firstName : toPascalCase(firstName.trim()),
							middleName: toPascalCase(middleName.trim()),
							lastName: toPascalCase(lastName.trim()),
							dob: dateOfBirth,
							gender,
							citizenship: this.state.citizenShipCode,
							nextStep: 'ADDRESS'
						}
            let personalDetailError = {
              firstNameError:'',
              lastNameError:'',
              phoneNumberError:'',
              dateOfBirthError:'',
              errorMessage:'',
              error:false
            }
            this.props.errorHandler({personalDetailError})
						updatedAccount(data)
							.then(res => {
                if (res.code === 200) {
                  delete data.phone;
                  // const mdl = data.middleName;
                  // delete data.middleName;
                  // data.firstName = mdl ? firstName+' '+mdl :firstName
                  const userInfo = Object.assign(data,{phoneHome:phoneNumber})
                  setUserInfoSession(userInfo);

									const personalDetail = {
										firstName: toPascalCase(firstName),
										middleName: toPascalCase(middleName.trim()),
										lastName: toPascalCase(lastName),
										phoneNumber,
										gender,
										dateOfBirth,
                    citizenShip:this.state.citizenShip,
                    citizenShipCode:this.state.citizenShipCode,
                    flagCode:this.state.flagCode
                    // countryList: this.state.countryList,
                    // flagData: this.state.flagData
                  }
                  const details = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : '';
                  const firstMiddle = `${firstName} ${middleName}`
                  // eslint-disable-next-line no-undef
                  webengage.user.login(details.username);
                  //webengage
                  // eslint-disable-next-line no-undef
                  webengage.user.setAttribute('we_first_name',firstMiddle);
                  // eslint-disable-next-line no-undef
                  webengage.user.setAttribute('we_last_name', lastName);
                  // eslint-disable-next-line no-undef
                  webengage.user.setAttribute('we_gender', gender);
                  // eslint-disable-next-line no-undef
                  webengage.user.setAttribute('Year Of Birth', parseInt(dateOfBirth.split('-')[0]));
                  // eslint-disable-next-line no-undef
                  webengage.track("Personal Detail added")
                  this.onUpdateFormValidation(true)
                  this.props.changeCurrent('ADDRESS')
                  this.props.nextForm({ personalDetail })
                }
							})
							.catch(error => {
                this.setState({loading:false})
								this.errorHandlerMessage(error.message)
							})
					}}>
					{({
						handleChange,
						handleBlur,
						handleSubmit,
						values,
						touched,
						errors,
            isSubmitting
					}) => {
						const {
							firstName,
							lastName,
							phoneNumber,
							gender,
							dateOfBirth,
							citizenShip
            } = values
            // console.info('values',values)
            // console.info('citizenshipList',this.state.citizenshipList)
						const disable =
							firstName !== '' &&
							gender !== '' &&
							dateOfBirth !== '' &&
							this.state.citizenShip !== ''
							// citizenShip !== ''
								? true
							  : false
						return (
							<>
               {firstName !== '' && disabledValue ? '' :
								<Row className={styles.personalInputRow}>
									<Col
										className={styles.mobileMargin}
										md={4}
										xs={12}>
                      <SignUpInput
                        label='First Name'
                        placeholder = 'Enter First Name'
                        type='text'
                        name='firstName'
                        refValue={this.myRef}
                        tabIndex='1'
												value={toPascalCase(values.firstName)}
                        // onChange={handleChange}
                        onChange={e => this.onChangeHandler(e,handleChange)}
												onBlur={handleBlur}
												isInvalid={(touched.firstName && errors.firstName) || firstNameError !== ''}
                        errorMessage={errors.firstName || firstNameError}
                     />
									</Col>
									<Col md={4} xs={12} className={`${styles.mobileMargin}`}>
                    <SignUpInput
                        label='Middle Name [Optional]'
                        placeholder = 'Enter Middle Name'
                        type='text'
                        tabIndex='2'
                        name='middleName'
												value={toPascalCase(values.middleName)}
                        // onChange={handleChange}
                        onChange={e => this.onChangeHandler(e,handleChange)}
												onBlur={handleBlur}
												isInvalid={touched.middleName && errors.middleName}
                        errorMessage={errors.middleName}
                    />
									</Col>
                  <Col md={4} xs={12} className={styles.mobileMargin}>

                    <SignUpInput
                        label='Last Name'
                        placeholder = 'Enter Last Name'
                        type='text'
                        name='lastName'
                        tabIndex='3'
                        refValue={this.lastNameRef}
												value={toPascalCase(values.lastName)}
                        onChange={e => this.onChangeHandler(e,handleChange)}
												onBlur={handleBlur}
												isInvalid={(touched.lastName && errors.lastName) || lastNameError !== '' || (this.state.lastNameRequired && values.lastName === '')}
                        errorMessage={errors.lastName || lastNameError || (this.state.lastNameRequired && values.lastName === '' ? 'Last Name is required' : '')}
                    />
									</Col>
								</Row>}
								<Row className={styles.personalInputRow}>
									<Col
										className={styles.mobileMargin}
										md={4}
										xs={12}>
                  <div className={styles.label}>Country of Citizenship</div>
                   <div>
                      <DropDownButton
                         tabIndex='4'
                         onClick={() => this.setState({showDropDownCitizen : !this.state.showDropDownCitizen})}
                         showDropDownCitizen={this.state.showDropDownCitizen}
                         error=''
                         >
                          <img className={styles.flagImgCode} src={`${process.env.REACT_APP_ASSESTS}/flags/${this.state.flagCode}.svg`}></img>
                          <span className={styles.countryLabel} style={{paddingLeft:'12px'}}>{this.state.citizenShip}</span>
                      </DropDownButton>
                      <OutsideClickHandler onOutsideClick={() => this.setState({showDropDownCitizen:false})}>
                      <SignUpDropDown
                         classes={styles.accountSetDropDown}
                         data={this.state.citizenshipList}
                         showDropDown={this.state.showDropDownCitizen}
                         onChange={(item) => {
                          this.setState({citizenShip:item.label,citizenShipCode:item.code,showDropDownCitizen:false,flagCode:item.code2.toUpperCase()},this.onUpdateFormValidation(false))
                           }}
                         placeholder='Country of citizenship'
                      />
                      </OutsideClickHandler>
                    </div>
									</Col>
                  <Col
										className={`${styles.mobileMargin}`}
										md={4}
										xs={12}>
                  <div className={styles.label}>Gender</div>
                   <div>
                      <DropDownButton
                         tabIndex='5'
                         onClick={() => this.setState({showDropDownGender : !this.state.showDropDownGender})}
                         showDropDown={this.state.showDropDownGender}
                         error={touched.gender && errors.gender ? errors.gender : ''}
                         >
                        <span className={values.gender !== '' ? styles.countryLabel : styles.accountDropPlaceholder}>{values.gender ? values.gender : 'Select'}</span>
                      </DropDownButton>
                      <OutsideClickHandler onOutsideClick={() => this.setState({showDropDownGender:false})}>
                      <SignUpDropDown
                        classes={styles.accountSetDropDown}
                         data={genderOptions}
                         showDropDown={this.state.showDropDownGender}
                         onChange={item => {
                          const value= item.value
                          const event = { target: { name: 'gender', value } }
                          this.setState({showDropDownGender:false})
                          this.onUpdateFormValidation(false)
                          handleChange(event)
                        }}
                         isSearchable = {false}
                         placeholder='Country of citizenship'
                      />
                      </OutsideClickHandler>
                    </div>
                    <div className={styles.errorState}>{touched.gender && errors.gender ? errors.gender : ''}</div>
									</Col>
                  {disabledValue && dateOfBirth !== '' ? '' :
                  <Col md={4} xs={12} className={styles.mobileMargin}>
                  <OutsideClickHandler
													onOutsideClick={this.onOutsideClick}>
                    <SignUpInput
                        containerClasses={styles.passwordInvalid}
                        tabIndex='6'
                        label='Date of Birth'
                        placeholder = 'Date of Birth'
                        type='text'
												name='dateOfBirth'
												value={this.formatDateDisplay(dateOfBirth)}
												readOnly={true}
												onChange={event => {
													this.onChangeHandler(event)
													handleChange(event)
												}}
                        isInvalid = {(errors.dateOfBirth && touched.dateOfBirth) || dateOfBirthError !== ''}
												onBlur={handleBlur}
												onClick={this.toggleCalender}
                        errorMessage={errors.dateOfBirth || dateOfBirthError}
                    />
                    <img className={styles.calendarImg} src={CalendarSvg} onClick={this.toggleCalender}></img>
										{this.state.showCalendar ? (
											<div className={styles.calendar}>
													<DatePicker
														onChange={date => {
															this.onHandleDate(date)
															const formatedDate = this.formatDateDisplay(date)
															const event = {
																target: {
																	name: 'dateOfBirth',
																	value: formatedDate
																}
															}
                              this.onUpdateFormValidation(false)
															handleChange(event)
														}}
														date={this.state.currentDate}
														color='#0061D3'
														// minDate={minDate}
														maxDate={date}
													/>
											</div>
										 ) : (
											''
										)}
                   </OutsideClickHandler>
                  </Col>}
								</Row>
                {errorMessage !== '' ? <div className={styles.erorrMessage}>{errorMessage}</div> : ''}
								<Row className={styles.buttonMargin}>
									<Col md={2} xs={12} className={styles.mobileMargin}>
										<SignUpButton
                      tabIndex='7'
											title='Next Section'
											classes={styles.nextSectionButton}
											onClick={() => {
                        if(lastName === ''){
                        this.setState({showLastNamePopUp:true})
                        }else{
                          handleSubmit()
                        }
                      }}
                      loading={this.state.loading}
											// disabled={!disable || isSubmitting || (this.state.lastNameRequired && lastName === '')}
                      ></SignUpButton>
									</Col>
								</Row>
                <Popup
                  show={this.state.showLastNamePopUp}
                  onClose={() => this.setState({showLastNamePopUp:false})}
                  onYesClick={() => {
                    this.setState({lastNameRequired:true,showLastNamePopUp:false})
                    // () => this.lastNameRef.current.focus())
                  }}
                  onNoClick={() => {
                    this.setState({showLastNamePopUp:false},() => handleSubmit())
                  }}
                />
							</>
						)
					}}
				</Formik>
			</>
		)
	}
}

export default PersonalDetail
