import React, { useContext, useState } from "react"
import { putBrokerDetail } from "../../../../api"
import { AddFundContext } from "../../AddFundContext"
import styles from "./offline.module.css"
import { Spinner } from "react-bootstrap"

const Review = ()=>{
    const {selectedAccount, fund, setSubPage, setLrsID, hashID, onBack, setOnErrorRetry, setShowGenericError, PAGE, SUBPAGE} = useContext(AddFundContext)
    const [loadingContinue, setLoadingContinue] = useState(false)
    const submitData = async(showGenericError)=>{
        let success = true
        try{
            const res = await putBrokerDetail(hashID,'from_pickup')
            if(res  && res.code==200){
                setLrsID(res.data.requestId)
                setSubPage(SUBPAGE[PAGE.OFFLINE].INSTRUCTION)
            }else{
                success=false
            }
        }catch(error){
            success=false
        }
        if(success && showGenericError){
            setShowGenericError(false)
            setOnErrorRetry(null)
        }else if(!success){
            setOnErrorRetry(submitData)
            setShowGenericError(true)
        }
    }
    const onContinue = async()=>{
        if(!loadingContinue){
            setLoadingContinue(true)
            await submitData()
            setLoadingContinue(false)
        }        
    }
    return <>
        <div className={styles.subHeader}>Review Transfer Details</div>
        <div className={`${styles.body} ${styles.reviewContainer}`}>
            <div className="d-flex justify-content-between">
                <div className={styles.label}>USD Amount</div>
                <div className={styles.value}>${fund}</div>
            </div>
            <div className="d-flex justify-content-between">
                <div className={styles.label}>Bank Account number</div>
                <div className={styles.value}>{selectedAccount.accountNumber}</div>
            </div>
            <div className="d-flex justify-content-between">
                <div className={styles.label}>Bank name</div>
                <div className={styles.value}>{selectedAccount.bankName}</div>
            </div>
            <div className="d-flex justify-content-between">
                <div className={styles.label}>IFSC Code</div>
                <div className={styles.value}>{selectedAccount.bankIFSC}</div>
            </div>
            <div className="d-flex justify-content-between">
                <div className={styles.label}>SWIFT/IBAN Number</div>
                <div className={styles.value}>{selectedAccount.beneficiarySwiftABA?selectedAccount.beneficiarySwiftABA:'--'}</div>
            </div>
            <div className="d-flex justify-content-between">
                <div className={styles.label}>Bank Address</div>
                <div className={styles.value} style={{overflowWrap: 'break-word'}}>{selectedAccount.bankAddress}</div>
            </div>
        </div>
        <div className={styles.footerContainer}>
            <button className={styles.continueBtn} onClick={onContinue}>
                {loadingContinue && <Spinner animation="border" className={styles.spinnerColor}/>}
                Continue
            </button>
            {/* <button className={styles.cancelBtn} onClick={()=>{!loadingContinue && onBack()}}>Cancel</button> */}
        </div> 
    </>
}

export default Review