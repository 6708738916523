import React from 'react'
import {Alert} from 'react-bootstrap'
import styles from './AlertError.module.css'

const AlertError = ({message,color}) => {
   return(
        <Alert style={{backgroundColor:color}} className={styles.alert}>{message}</Alert>
   )
}

export default AlertError