import React, { Component } from 'react'
import MoneyControl from './MoneyControl'
import FinancialExpress from './FinancialExpress'
import { ThemeContext } from '../Context/ThemeContext'

const Components = {
	MONEYCONTROL: MoneyControl,
	FINANCIALEXPRESS: FinancialExpress
}

class Index extends Component {
	constructor(props) {
		super(props)
		this.state = {
			component: ''
		}
	}

	static contextType = ThemeContext

	componentDidUpdate() {
		const { partnerCode } = this.context
		if (partnerCode !== this.state.component) {
			this.setState({ component: partnerCode })
		}
	}

	render() {
		if (this.state.component) {
			const LandingPage = Components[this.state.component]
			return <LandingPage />
		} else return null
	}
}

export default Index
