import React from 'react'
import { Row, Card } from 'react-bootstrap'
import FundTransfer from '../../../../../public/images/fundtransfer.png'
import styles from '../../../Fund.module.css'

const FundInitiated = () => {
	return (
		// <Card className={styles.fundTransferCard}>
		// 	<Card.Body style={{ padding: '0px' }}>
		<>
			<Row>
				<img src={FundTransfer} className={styles.fundInitiatedImage}></img>
			</Row>
			<Row className={styles.initiatedText}>Funds withdrawal initiated!</Row>
			<Row className={styles.fundInitiatedNote}>
				your account will be credited in 1-4 business days.
			</Row>
		</>
		// 	</Card.Body>
		// </Card>
	)
}

export default FundInitiated
