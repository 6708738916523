import React,{useEffect,useContext} from "react"
import { Spinner } from "react-bootstrap"
import { AddFundContext } from "./AddFundContext"
import style from "./addFund.module.css"

const GenericLoading = ()=>{
  const {setNoClose,setNoHeader} = useContext(AddFundContext)

  useEffect(() => {
    setNoClose(true,setNoHeader(true))

    return () =>{
      setNoClose(false,setNoHeader(false))
    }
  },[])

    return(<div className={style.genericErrorLoading}>
        <Spinner animation="border" className={style.spinnerColor}/>
        Loading...
    </div>)
}

export default GenericLoading
