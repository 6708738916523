import React , { useState , useEffect} from 'react'
import { loadVGSCollect } from '@vgs/collect-js'
import { customOTP, validateTheOtp, createPinToken, activateCard } from '../../api'
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import CashMgmtOtp from './CashMgmtOtp'
import styles from './TransactionDetailsModal.module.css'
import modalCrossButton from '../../public/images/modalCrossButton.svg'
import orangeInfoIcon from '../../public/images/orangeInfoIcon.svg'

const VirtualCardPinModal = (props) => {
    
    const { onHide , cardId, virtualCard, title, buttonText, resetPin, setCardStatus } = props

    const [ form , setForm] = useState({})
    const [ otp, setOtp ] = useState()
    const [ hashId, setHashId ] = useState()
    const [ pinToken, setPinToken ] = useState()
    const [ modalStep, setModalStep ] = useState('STEP_1')
    const [ isDisabled , setIsDisabled ] = useState(false)
    const [ errors, setErrors ] = useState(null)
    const [ otpError , setOtpError] = useState('')
    const [ clearOtp, setClearOtp] = useState(false)
    const [ cardActivated , setCardActivated] = useState(false)

    const setTheOtp = (value) => {
        setOtp(value)
    }

    const sendOtpForChangePin = () => {
        const reason = resetPin ? "reset_card_pin" : "set_card_pin"
        const body = { reason:reason}
        customOTP(body).then(response=>{
            if(response.code === 200){
                setHashId(response.data.hashID)
            }
            }).catch((err)=>{
            console.error(err)
          })
    }

    const validateOtpForCard = () => {

        setIsDisabled(true)
        
        if(otp && otp.length === 6) {
            const data = {hashID:hashId, otp:otp}
            validateTheOtp(data).then(response => {
                if(response.data.code === 200){
                    setOtpError('')
                    setIsDisabled(false)
                    setModalStep('STEP_2')
                    getPinToken()
                }
            }).catch((err)=> {
                console.log(err)
                setOtpError('OTP is not valid')
                setClearOtp(true)
                setIsDisabled(false)
            })
        } else {
            setOtpError('Enter a 6-digit OTP')
            setClearOtp(true)
            setIsDisabled(false)
        }
    }

    const getPinToken = () => {
        createPinToken(cardId).then(response => {
            if(response.data.code === 200){
                setPinToken(response.data.data.pinToken)
            }
        }).catch((err)=> {
            console.log(err)
        })
    }

    const activateCardInSolidAndSetPin = (e) => {
        
        setIsDisabled(true)

        const data = {cardId:cardId, isPinSet:false}
        activateCard(data).then(response => {
            if(response.data.code === 200){
                handleFormSubmit(e)
            }
        }).catch((err) => {
            console.log(err)
            setErrors('Something went wrong. Please Try again!')
            setIsDisabled(false)
        })
    }

    const activateVirtualCard = () => {
        const data = {cardId:cardId} 
        activateCard(data).then(response => {
            if(response.data.code === 200){
                setCardStatus(response.data.data.cardStatus)
                if(props.setIsCardActivated){
                    props.setIsCardActivated(true)
                }
                setCardActivated(true)
                setTimeout(onHide, 2000)
                setIsDisabled(false)
            }
        }).catch((err) => {
            console.log(err)
            setCardActivated(false)
            setIsDisabled(false)
        })
    }

    const css = {
        fontFamily: 'geomanistBook',
        fontWeight: "400",
        lineHeight: "20px",
        fontSize: "14px",
        "&::placeholder": {
          color: "#CFD7E0"
        }
    };

    const initForm = (vgsCollect) => {
        
        const form = vgsCollect.init(state => {});

        form.field("#last4", {
            type: "text",
            name: "last4",
            minLength: 4,
            maxLength: 4,
            showCardIcon: false,
            successColor: "#3C404A",
            errorColor: "#D8000C",
            placeholder: "0000",
            validations: ["required", '/^([0-9]{4})$/'],
            css:css
        });

        form.field("#card-expiration-date", {
            type: "text",
            name: "expiryDate",
            minLength: 2,
            maxLength: 2,
            successColor: "#3C404A",
            errorColor: "#D8000C",
            placeholder: "02",
            validations: ["required", '/^([0-9]{2})$/'],
            css:css
        });

        form.field("#card-expiration-year", {
            type: "text",
            name: "expiryYear",
            minLength: 4,
            maxLength: 4,
            successColor: "#3C404A",
            errorColor: "#D8000C",
            placeholder: "2020",
            validations: ["required", '/^([0-9]{4})$/'],
            css:css
        });
       
        form.field("#pin", {
            type: "password",
            name: "pin",
            minLength: 4,
            maxLength: 4,
            successColor: "#3C404A",
            errorColor: "#D8000C",
            placeholder: "0000",
            validations: ["required", '/^([0-9]{4})$/'],
            css:css
        });

        setForm(form);
    };

    const loadForm = async () => {
        const vgsCollect = await loadVGSCollect({
          vaultId: process.env.REACT_APP_CASH_MANAGEMENT_VGS_VAULT_ID,
          environment: process.env.REACT_APP_CASH_MANAGEMENT_VGS_ENV,
          version: "2.15.0"
        }).catch(e => {
          console.log(e);
        });
        initForm(vgsCollect);
    };
    
    const handleFormSubmit = e => {
        
        e.preventDefault();

        setIsDisabled(true)
        setErrors(null)

        if(pinToken) {
            form.submit("/v1/card/"+cardId+"/pin", {
                method: 'POST',
                headers: {
                    'sd-pin-token': pinToken
                },
                data: (formValues) => {
                    return {
                        last4: formValues['last4'],
                        pin: formValues['pin'],
                        expiryMonth: formValues['expiryDate'],
                        expiryYear: formValues['expiryYear'],
                    }
                }
                }, 
                function (status, data) {
                    if(status === 200) {
                        activateVirtualCard()
                    } else if(status === 400) {
                        setErrors('Invalid card details. Try again')
                        getPinToken()
                        setTimeout(() => {setErrors(null)},1000)
                        setIsDisabled(false)
                    } else {
                        setErrors('Something went wrong. Please Try again!')
                        getPinToken()
                        setTimeout(() => {setErrors(null)},1000)
                        setIsDisabled(false)
                    }
                }, 
                function (errors) {
                    setErrors('All fields are mandatory')
                    setIsDisabled(false)
                    console.log(errors)
                }
            );
        }
    
    };

    useEffect(() => {
        sendOtpForChangePin()
    },[])

    useEffect(() => {
        loadForm();
    },[modalStep])

    return (
        <div className={styles.modalContainer}>
            <div className={styles.modalHeader} style={{backgroundColor:"#F7F7F8"}}>
                <div className={styles.transactionStatus}>
                    <p className={`${styles.status} ${styles.textBlack}`}>{title}</p>
                    <p className={`${styles.modalText} ${styles.textGrey}`}>
                        {`XXXX XXXX XXXX ${virtualCard.cardNum}`}
                    </p>
                </div>
                <img src={modalCrossButton} alt="cross-button" onClick={onHide}></img>
            </div>
            {cardActivated ? (
                <div className={`${styles.modalText} ${styles.textGrey}`} style={{padding:"24px"}}>{resetPin ? 'Pin saved successfully!' : 'Your card is activated'}</div>
                ) : (
                    <>
                <div className={styles.transactionDetails} style={{paddingTop:"24px"}}>
                    <div className={styles.modalRow}>
                        <p className={` ${styles.modalText} ${styles.textGrey}`}>Last 4 Digits</p>
                        <p className={` ${styles.modalText} ${styles.textBlack}`}>{virtualCard.cardNum}</p>
                    </div>
                    <div className={styles.modalRow}>
                        <p className={` ${styles.modalText} ${styles.textGrey}`}>Card Expiry</p>
                        <p className={` ${styles.modalText} ${styles.textBlack}`}>{modalStep === 'STEP_1' ? '-- / ----' : `${virtualCard.expiryMonth} / ${virtualCard.expiryYear}`}</p>
                    </div>
                </div>
                {modalStep === 'STEP_1' ? 
                    (
                        <>
                            <div style={{padding:"0px 24px 24px 24px"}}>
                                <hr className={styles.divider}/>
                            </div>
                            <CashMgmtOtp setOtp={setTheOtp} resendOtp={sendOtpForChangePin} otpError={otpError} clearOtp={clearOtp} setClearOtp={setClearOtp}/>
                            <div className={`${styles.modalRow} ${styles.modalButtonContainer}`}>
                                {isDisabled ? (
                                    <Button variant="primary" disabled className={`${styles.modalButton} ${styles.modalButtonRed} ${styles.modalButtonSolidRed}`}>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </Button>
                                ) : (
                                    <button className={`${styles.modalButton} ${styles.modalButtonBlue} ${styles.modalButtonSolidBlue}`} onClick={validateOtpForCard}>
                                        <p className={`${styles.modalText} ${styles.textWhite}`}>Verify OTP</p>
                                    </button>
                                )}
                                <button onClick={onHide} className={`${styles.modalButton} ${styles.modalButtonGrey}`}>
                                    <p className={styles.modalText}>Cancel</p>
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={styles.otpContainer}>
                                <p className={`${styles.modalText} ${styles.textGrey}`} style={{paddingBottom:"20px"}}>Enter your Card Information as shown above and create your Virtual Card PIN.</p>
                                <form id="cardForm">
                                    <label style={{minWidth:"62px"}}>
                                        <p className={`${styles.modalTextSmall} ${styles.textBlack}`}>Last 4 Digits</p>
                                        <div className={styles.modalFormInput} style={{border: errors ? "1px solid #FA274A" : "1px solid #D3D4DA"}}>
                                            <div id="last4" className={styles.formfield}></div>
                                        </div>
                                    </label>
                                    <label style={{minWidth:"62px"}}>
                                        <p className={`${styles.modalTextSmall} ${styles.textBlack}`}>Expiry Date</p>
                                        <div className={`${styles.cardExpiryDate} ${styles.modalFormInput}`} style={{border: errors ? "1px solid #FA274A" : "1px solid #D3D4DA"}}>
                                            <div id="card-expiration-date" className={styles.formfield} style={{width:"35px"}}></div>
                                            <div style={{marginRight:"4px"}}>/</div>
                                            <div id="card-expiration-year" className={styles.formfield}></div>
                                        </div>
                                    </label>
                                    <label style={{minWidth:"62px"}}>
                                        <p className={`${styles.modalTextSmall} ${styles.textBlack}`}>PIN</p>
                                        <div className={styles.modalFormInput} style={{border: errors ? "1px solid #FA274A" : "1px solid #D3D4DA"}}>
                                            <div id="pin" className={styles.formfield}></div>
                                        </div>
                                    </label>
                                </form>
                                {errors ? <p className={`${styles.modalTextSmall} ${styles.textRed}`} style={{marginTop:"8px",float:"left"}}>{errors}</p> : ''}
                            </div>
                            <div className={styles.modalBanner}>
                                <img src={orangeInfoIcon} alt="modal-banner"/>
                                <p className={styles.modalText}>Never share your Virtual Card PIN</p>
                            </div>
                            <div className={`${styles.modalRow} ${styles.modalButtonContainer}`}>
                                {isDisabled ? (
                                    <Button variant="primary" disabled className={`${styles.modalButton} ${styles.modalButtonRed} ${styles.modalButtonSolidRed}`}>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </Button>
                                ) : (
                                    <button className={`${styles.modalButton} ${styles.modalButtonBlue} ${styles.modalButtonSolidBlue}`} onClick={(e) => { resetPin ? handleFormSubmit(e) : activateCardInSolidAndSetPin(e)}}>
                                        <p className={`${styles.modalText} ${styles.textWhite}`}>{buttonText}</p>
                                    </button>
                                )}
                                <button onClick={onHide} className={`${styles.modalButton} ${styles.modalButtonGrey}`}>
                                    <p className={styles.modalText}>Cancel</p>
                                </button>
                            </div>
                        </> 
                    )
                }
            </>
                )}
        </div>
    )
}

export default VirtualCardPinModal
