import React, {Component} from "react";
import ReportDatePicker from "../reportDatePicker/reportDatePicker";

class FinancialReportCalendar extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div>
        <div className="row">
          <span className="col-md-auto col-6" style={{paddingRight: "0px"}}>
            <div
              style={{
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "12px",
                lineHeight: "16px",
                letterSpacing: "0.6px",
                color: "#9096A8",
              }}
            >
              From:
          </div>
            <ReportDatePicker
              changeDate={this.props.changeDate}
              value={this.props.startDate}
              id="Start_Date"
            />
          </span>
          <span className="col-md-auto col-6" style={{paddingLeft: "0px"}}>
            <div
              style={{
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "12px",
                lineHeight: "16px",
                letterSpacing: "0.6px",
                color: "#9096A8",
              }}
            >
              To:
          </div>
            <ReportDatePicker
              changeDate={this.props.changeDate}
              value={this.props.endDate}
              id="End_Date"
            />

          </span>

        </div>
        <div className="row">
          <span className="col-md-auto col-6">
            <div style={{color: 'red', fontSize: '13px'}}>{this.props.errorMessages}</div>
          </span>
        </div>
      </div>
    );
  }
}

export default FinancialReportCalendar;
