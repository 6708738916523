import React, { Fragment } from 'react'
import { useState, useContext } from 'react'
import { Row, Button } from 'react-bootstrap'
import styles from '../Profile.module.css'
import { ThemeContext } from '../../../Context/ThemeContext'
import BackArrow from '../../../public/images/BackArrow.svg'
import { redeemCoupon } from '../../../api'

const RedeemCoupon = (props) =>{
    const [coupon, setCoupon] = useState('')
    const [message, setMessage] = useState({type:null,text:null,show:false})
    const [disable, setDisable] = useState(false)
    const { buttonColor } = useContext(ThemeContext)
    const handleRefresh = () => {
        props.resetTabs('basic detail')
    }
    const onSubmit = async()=>{
        setMessage({type:null,text:null,show:false})
        setDisable(true)
        if(!coupon){
            setMessage({type:'invalid',text:'Enter your coupon',show:true})
        }
        else{
            let res=null
            try{
                res = await redeemCoupon(coupon)
            }catch(error){
                res=error
            }
            if(res.code===200){
                setMessage({type:'valid',text:res.message,show:true})
            }else if(res.code===400){
                setMessage({type:'invalid',text:res.message,show:true})
            }else{
                setMessage({type:'invalid',text:'Could not redeem coupon at the moment, Please try again later!!!',show:true})
            }
        }
        setDisable(false)
    }
    const getTemplate = ()=>{
        return(<div className={`${styles.pinHeadingMargin} ${styles.redeemCouponConatiner}`} >
            <Row className={styles.redeemCouponHeading}>Redeem your coupon </Row>
            <Row className={`mt-4 ${styles.mobileNote}`}>Please enter your coupon</Row>
            <Row className='m-0 mt-2'>
                <input type='text' placeholder='Enter your coupon' value={coupon}
                    className={message.show && message.type==='invalid' ? styles.profileErrorInputBox : styles.profileInputBox}
                    onChange={(e)=>setCoupon(e.target.value.toUpperCase())}/>
                <p className={message.show && message.type==='invalid'?styles.profileErrorText:styles.profileSuccessText}>
                    {message.show?message.text:null}
                </p>
            </Row>
            <Row className={styles.buttonMargin}>
                <Button
                className={`${!disable? styles.saveButton:null} themeButton`}
                style={{
                    height: '48px',
                    width: '122px',
                    backgroundColor: buttonColor,
                    borderRadius: '8px'
                }}
                disabled={disable}
                onClick={onSubmit}>
                Apply
                </Button>
            </Row>
        </div>)
    }
    return (<Fragment>
        <div className={styles.ResetScreenHideResponsive}>
            {getTemplate()}
        </div>
        <div className={styles.ResponsiveModalContainer}>
            <div>
                <img src={BackArrow} onClick={handleRefresh} />
            </div>
            {getTemplate()}
        </div>
    </Fragment>
    )    
}

export default RedeemCoupon
