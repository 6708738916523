import React,{useContext} from 'react'
import GenericErrorIcon from "../../../../public/images/genericError.svg"
import styles from './easyfund.module.css'
import { AddFundContext } from "../../AddFundContext"

const UploadLater = ({setShowUploadLater,onSubmit}) => {
  const {subPage, setSubPage, hashID, selectedAccount, transferOptions, setPage, setOnErrorRetry, setShowGenericError, PAGE, SUBPAGE} = useContext(AddFundContext)
   return(
    <div className={styles.cancelContainer}>
    <div>
        <div className={styles.cancelHeader}>
            <img src={GenericErrorIcon}/>
            <span>Upload bank statement(s)</span>
        </div>
        <div className={styles.cancelBody}>
            <span>Are you sure you want to upload your bank statements later? They need to be uploaded within 2 business days.</span>
            <div className="d-flex w-100">
                <button className={styles.cancelConfirmBtn} onClick={() => onSubmit()}>Yes, I’ll Upload Later</button>
                <button className={styles.cancelBtn} onClick={() => setShowUploadLater(false)}>No, Upload Now</button>
            </div>
        </div>
    </div>
</div>
   )
}

export default UploadLater
