import React from 'react'
import { Calendar } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

const DatePicker = ({ date, onChange, color, minDate, maxDate }) => {
	return (
		<Calendar
			date={date}
			onChange={onChange}
			color={color}
			minDate={minDate}
			maxDate={maxDate}
		/>
	)
}

export default DatePicker
