import React, { useState, useEffect, useRef, useContext } from 'react'
import { SocketContext  } from '../../socket-context';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { showFundModal } from '../../store/actions';
import { Row, Col, Table, Container, OverlayTrigger,Tooltip} from 'react-bootstrap';
import queryString from 'query-string'
import { Header } from '../../components';
import { addCommasinPrice } from '../../utils';
import CashMgmtModal from './CashMgmtModal';
import Copy from '../../components/AddFund/Transfer/Online/copy'
import TransactionDetailsModal from './TransactionDetailsModal'
import AccountDetailsModal from './AccountDetailsModal';
import UnfreezeVirtualCardModal from './UnfreezeVirtualCardModal';
import FreezeVirtualCardModal from './FreezeVirtualCardModal';
import VirtualCardPinModal from './VirtualCardPinModal';
import { FrozenVirtualCard, ActivateVirtualCard, LoadingVirtualCard, UnableToFetchDataVirtualCard, UpComingVirtualCard } from './VirtualCardStates'
import { LoadingState, DataNotFoundState, UnableToFetchDataState } from './TransactionListLoadingStates'
import styles from './cashManagement.module.css';
import UsFlag from '../../public/images/UsFlag.svg';
import Icon from '../../public/images/ThreeDotIcon.svg';
import DownIcon from '../../public/images/iconDown.svg';
import plusAddFunds from '../../public/images/whitePlusSign.svg'
import VirtualCardCash from './VirtualCardCashManagement'
import CashManagementDatePicker from './cashManagementDatePicker';
import {addDays} from "date-fns";
import CashManagementProfileCard from './CashManagementDropDown';
import fraudFlag from '../../public/images/fraudFlag.svg'
import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import leftIcon from '../../public/images/LeftPageIcon.svg';
import rightIcon from '../../public/images/RightPageIcon.svg';
import {getCashManagementTransactionData, getCashManagementData, getCardDetails, getContactListTransferDetails, getShowToken, getAccountInfo} from '../../api'
import moment from 'moment';
import blueIcon from '../../public/images/blueThreeDot.svg';
import AccountDetails from '../../public/images/accountDetails.svg';
import shareDetails from '../../public/images/shareDetails.svg';
import savingDownload from '../../public/images/downloadSavings.svg';
import filter from '../../public/images/savingFilter.svg';
import close from '../../public/images/savingsClose.svg';
import CustomModal from '../../components/CustomModal/CustomModal'
import TransferOfFunds from './TransferFunds';
import { Link } from 'react-router-dom';


const detailDropDown = [
  {
    text:"Transaction Details",
    img: AccountDetails
  },
  {
    text:"Share Details",
    img: AccountDetails
  },
  {
    text:"Report Fraud",
    img: AccountDetails
  }
]

const transactionValue = [
  {value:"All Transactions",
  color:"#767C8F",
  pillValue:"all"},
  {value:"Pending",
  color:"#FA5D05",
  pillValue:"pending"},
  {value:"Completed",
  color:"#0DC014",
  pillValue:"completed"},
  {value:"Declined",
  color:"#FA274A",
  pillValue:"declined"}
]

const CashManagement= () => {
  const dispatchAction = useDispatch()
  const socket = useContext(SocketContext);
  const [slideeffect, setslideeffect] = useState(false)
  const [selectedPill, setSelectedPill] = useState('all')
  const [selectedInputPill, setSelectedInputPill] = useState('All Transactions')
  const date = new Date();
  const [startDate, setStartDate] = useState(addDays(date, -30))
  const [endDate, setEndDate] = useState(date)
  // startDate: addDays(date, -190),
  // endDate: date,
  const [showDropDown, setShowDropDown] = useState(false)
  const [showMobileDropdown, setShowMobileDropdown] = useState(false)
  const [reportFraud, setShowReportFraud] = useState(false)
  const pillSelection = (e,f) => {
    setSelectedPill(e)
    setShowTransactionTypePopup(false)
    setSelectedInputPill(f)
    setCurrentPage(1)
  }
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  // pagination
  const countPerPage = 10;
  const [value, setValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [transactionDataTable, setTransactionDataTable] = useState([]);
  const [collection, setCollection] = useState();
  const [isTransactionListEmpty, setIsTransactionListEmpty] = useState(false)
  const [transactionListError, setTransactionListError] = useState(false)
  const [listLoading, isListLoading] = useState(true)
  const [totalPaginationPage, setTotalPaginationPage] = useState()
  const [bankDetails, setBankDetails] = useState();
  const [stoAccountId, setStoAccountID]= useState();
  const [cardLoading, setCardLoading] = useState(true)
  const [cardError, setCardError] = useState(false)
  const [virtualCard, setVirtualCard] = useState();
  const [virtualCardStatus, setVirtualCardStatus] = useState()
  const [isCardActivated, setIsCardActivated] = useState()
  const [showTransactionTypePopup, setShowTransactionTypePopup] = useState(false);
  const [showTransactionTypePopupMobile, setShowTransactionTypePopupMobile] = useState(false)
  const [showModal,setShowModal] = useState(false)
  const [showTransactionDetails, setShowTransactionDetails] = useState(false)
  const [brokerAccountID, setBrokerAccountId] = useState('')
  const [contactList, setContactList] = useState();
  const [virtualCardId, setVirtaulCardID] = useState()
  const [isTrasnferOfFundSuccess, setIsTransferOfFundSuccess] = useState(false)
  const [tranId, setTranId ] = useState();
  const [showDropdownModal, setShowDropdownModal] = useState(false)
  const [modalData, setModalData ] = useState(null)
  const [width, setWindowWidth] = useState(0);

  const ref = useRef()
  const fraudRef = useRef()
  const transTypeRef = useRef()
  useOnClickOutside(ref, () => setShowTransactionTypePopup(false))
  useOnClickOutside(fraudRef,() => setShow(false))
  useOnClickOutside(transTypeRef, () => setShowTransactionTypePopupMobile(false))

  const cardId = virtualCard ? virtualCard.brokerCardId : ''

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () =>
      window.removeEventListener('resize',updateDimensions);
  }, [])

  const updateDimensions = () => {
    const width = window.innerWidth
    setWindowWidth(width)
  }

  const OpenDropdownModal = () => {
    setShowDropdownModal(!showDropdownModal)
  }

  const getVirtualCardStatus = (status) => {
    setVirtualCardStatus(status)
  }

  useEffect(() => {
    if(isTrasnferOfFundSuccess){
      allTransactionData()
      getCashAccounId()
      setIsTransferOfFundSuccess(false)
    }
  },[isTrasnferOfFundSuccess])

  useEffect(() => {
    const transactionListListener = (value) => {
      setIsTransferOfFundSuccess(true)
    };

    if(socket) {
      socket.on("data_refresh",transactionListListener)
    }

    return () => {
      if(socket){
          socket.off("data_refresh", transactionListListener)
      }
    }
  }, [socket])

  useEffect(() => {
    if(stoAccountId){
    getCashAccounId();
  }
  }, [stoAccountId]);

  useEffect(() => {
    allTransactionData()
    virtualCardDetails()
  },[]);

  useEffect(()=> {
    if(virtualCard){
      setVirtualCardStatus(virtualCard.cardStatus)
      setIsCardActivated(virtualCard.activatedAt ? true : false)
    }
  },[virtualCard])

  useEffect(() => {
    if(virtualCardStatus){
      getVirtualCardStatus(virtualCardStatus)
    }
  },[virtualCardStatus])

  useEffect(() => {
    if(stoAccountId && stoAccountId !== undefined){
      getCashAccounId()
    }
  },[selectedPill,currentPage,startDate,endDate])

  const allTransactionData = () => {
    getCashManagementData().then(response=>{
      if(response && response.code === 200){
        setBankDetails(response.data)
        setStoAccountID(response.data.stoCashAccountId)
        setBrokerAccountId(response.data.stoCashAccount.brokerAccountId)
        isListLoading(false)
      } else {
        isListLoading(false)
        setTransactionListError(true)
      }
      }).catch((err)=>{
        console.error(err)
        isListLoading(false)
        setTransactionListError(true)
      })
  }

  const getCashAccounId = () => {
    isListLoading(true)
    var start_date = dateFormatter(startDate)
    var end_date = dateFormatter(endDate)
    getCashManagementTransactionData(start_date,end_date,stoAccountId, selectedPill,currentPage,false).then(response=>{
      if(response.code === 200){
          setTransactionDataTable(response.data.transactions)
          setTotalPaginationPage(Math.ceil(response.data.total/countPerPage))
          isListLoading(false)
          if(response.data.transactions.length === 0) {
            setIsTransactionListEmpty(true)
          } else {
            setIsTransactionListEmpty(false)
          }
        } else {
          isListLoading(false)
          setTransactionListError(true)
        }
      }).catch((err)=>{
        console.error(err)
        setTransactionListError(true)
        isListLoading(false)

    })
  }

  const getContactDetailsTransferFunds = () => {
    getContactListTransferDetails(brokerAccountID).then(response=>{
      if(response.code === 200){
        setContactList(response.data)
        }
      }).catch((err)=>{
        console.error(err)
    })
  }

  const dateFormatter = (mydate) => {
    var curr_date = mydate.getDate();
    var curr_month = mydate.getMonth() + 1;
    var curr_year = mydate.getFullYear();
    if (curr_date < 10) {
      curr_date = "0" + curr_date;
    }
    if (curr_month < 10) {
      curr_month = "0" + curr_month;
    }
    mydate = `${curr_month}-${curr_date}-${curr_year}`;
    return mydate;
  }

  const virtualCardDetails = () => {
    setCardLoading(true)
    getCardDetails().then(response => {
      if(response.code === 200){
        setVirtualCard(response && response.data ? response.data[0] : [])
        setVirtaulCardID(response && response.data ? response.data[0].brokerCardId : "")
        setCardLoading(false)
      } else {
        setCardLoading(false)
        setCardError(true)
      }
    }).catch((err) => {
        console.log(err)
        setCardLoading(false)
        setCardError(true)
    })
  }

  useEffect(() => {
    if (!value) {
      setCurrentPage(1);
    }
  }, [value]);

  useEffect(() => {
    if(transactionDataTable){
      setCollection(cloneDeep(transactionDataTable.length > 0 ? transactionDataTable.slice(0, countPerPage) : []));
    }
  },[transactionDataTable])

  const profileRef = useRef()
  const profileMobileRef = useRef()

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (showDropDown && profileRef.current && !profileRef.current.contains(e.target)) {
        setShowDropDown(false)
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [showDropDown])

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (showMobileDropdown && profileMobileRef.current && !profileMobileRef.current.contains(e.target)) {
        setShowMobileDropdown(false)
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [showMobileDropdown])

  const goBack = (p) => {
    if(p !== 1){
    const x = p-1;
    setCurrentPage(x);
    const to = countPerPage * x;
    const from = to - countPerPage;
    setCollection(cloneDeep(transactionDataTable.slice(from, to)));
    }else{
      console.log('cannot go back');
    }
  }

  const goNext = (p) => {
    const x = p+1;
    if(totalPaginationPage >= p+1){
      setCurrentPage(x);
      const to = countPerPage * x;
      const from = to - countPerPage;
      setCollection(cloneDeep(transactionDataTable.slice(from, to)));
    } else{
      console.log('cannot go forward');
    }
  }

  const handleClick = (event) => {
    setShow(!show);
    setTarget(`#fraud${event}`);
  };

  const seprateTimeAndDate = (e,type) => {
    if(type === 'date'){
      const date = moment(e).format('D MMM YY');
      return date ? date : "--"
    }
    if(type === 'time'){
      const time = moment(e).format('h:mm A z');
      return time ? time : "--"
    }
  }

  const changeDate = (data, id) => {
    setCurrentPage(1)
    if (id === "Start_Date") {
        setStartDate(data)
    } else {
      setEndDate(data)
    }
  }

  const openTransactionTypePopup = () => {
    setShowTransactionTypePopup(!showTransactionTypePopup)
  }

  const openTransactionTypePopupMobile = () => {
    setShowTransactionTypePopupMobile(!showTransactionTypePopupMobile)
  }


  const openTransactionDetails = () => {
    setShowTransactionDetails(!showTransactionDetails)
  }

  const setTransactionID = (value) => {
    setTranId(value)
  }

  const FraudPopup = (props) => {
    return(
      <div className={styles.fraudPopupContainer} id={`#fraud${props.id}`} style={props.show && props.target === `#fraud${props.id}` ? {display:"block"} : {display:"none"}}>
        <span className={styles.text} style={{borderBottom:"1px solid #D3D4DA"}}  onClick={openTransactionDetails}><img className={styles.flagImage} src={AccountDetails}></img>Transaction Details</span>
        <a href="mailto:customersuccess@stockal.com" target="_blank" rel="noopener noreferrer" style={{textDecoration:"none"}}><span className={styles.text} style={{color:"#FA274A"}}><img className={styles.flagImage} src={fraudFlag}></img>Report Fraud</span></a>
      </div>
    )
  }

  const convertToCSV = (objArray) => {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    for (let i = 0; i < array.length; i++) {
      let line = '';
      let object_ = {
        // 'Transaction Date(UTC)': array[i].tranWhen ? array[i].tranWhen : '',
        'Date and Time': array[i].tranDt ? `${moment(array[i].tranDt).format("DD/MM/YYYY h:mm:ss")} IST` : '',
        'Narration': array[i].tranDesc ? array[i].tranDesc : "" ,
        'Trasaction ID':array[i].stoTranId ? array[i].stoTranId : "",
        'Transaction Type': array[i].transferType ? array[i].transferType === 'INTRABANK' ? 'Intrabank Transfer': array[i].transferType === 'ACH' ? 'ACH Transfer': array[i].transferType === 'DOM_WIRE' ? 'Domestic Wire Transfer': array[i].transferType === 'INTL_WIRE' ? 'International Wire Transfer': array[i].transferType === 'CARD' ? 'Card/Online Payment': '--' : '--',
        'Transaction Amount': array[i].tranAmt ?  array[i].tranType === "debit" ? `- $${array[i].tranAmt}` : `+ $${array[i].tranAmt}` : '',
        'Account Balance': array[i].balanceAmt ? `$${array[i].balanceAmt}` : '',
        // 'Transfer Type': array[i].tranType ? array[i].tranType : ''
      };
      Object.keys(object_).forEach(key => {
        if (line != '') line += ','
        line += `"${object_[key]}"`;
      })
      str += line + '\r\n';
    }
    return str;
  }

  const userInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : {}
  const downloadExcel = (data, fileName =  `${bankDetails.userName}_Stockal Account Statement_${dateFormatter(startDate)}_${dateFormatter(endDate)}.csv`) => {
    var jsonObject = JSON.stringify(data);
    const topHeader = "Stockal Cash Management Account - Statement of Accounts \r\n \r\n"
    const info = `${bankDetails.userName},,,Account Number:  ${`XXXXXXXXXX${lastFourDigit(bankDetails.stoCashAccount.accountNum)}`} \r\n`
    const info2 = `${userInfo.addressLine1} ${userInfo.addressLine2},,,Routing Number: ${bankDetails.stoCashAccount.routingNum} \r\nCity: ${userInfo.city},,,Routing Number: ${bankDetails.stoCashAccount.accountType}\r\n`
    const info3 = `State: ${userInfo.stateProvince},,,Bank Name: ${bankDetails.stoCashAccount.bankName} \r\nCountry: ${userInfo.country},,,Bank Address: ${bankDetails.stoCashAccount.bankAddress.addressLine1}-${bankDetails.stoCashAccount.bankAddress.addressLine2} - ${bankDetails.stoCashAccount.bankAddress.addressCity} ${bankDetails.stoCashAccount.bankAddress.addressZipPostalCd} - ${bankDetails.stoCashAccount.bankAddress.addressState} - ${bankDetails.stoCashAccount.bankAddress.addressCountryCd}\r\n`
    const info4 = `Postal Code: ${userInfo.zipPostalCode},,,Currency: ${bankDetails.stoCashAccount.accountCurrencyCd}\r\n`
    const info5 = `Email: ${userInfo.emailAddress1},,,A/C Open Date: ${moment(bankDetails.stoCashAccount.accountOpenDt).format("DD/MM/YYYY h:mm:ss")} IST,,,\r\nMobile No: ${userInfo.phoneHome},,,Account Status: ${bankDetails.stoCashAccount.accountStatus}\r\n \r\n \r\n`
    const statementFrom = `Statement From: ${dateFormatter(startDate)} To: ${dateFormatter(endDate)}\r\n \r\n \r\n`
    const preHeader = "*************************************************************************************************** \r\n";
    const headers = "Date and Time,Narration,Trasaction ID,Transaction Type,Transaction Amount,Account Balance \r\n";
    const disclaimer = "\r\n Disclaimer & Disclosures: \r\n "
    const disclaimerDescription = "1. This Cash Management Transaction Statement Report (\"Report\") has been compiled based on information provided by Evolve Bank &Trust (\"Evolve Bank\") and made to Borderless Investing Inc. and its subsidiaries (owner of the Stockal platform and collectively referred to as the \"Stockal Group\") which has not been subject to an audit or review engagement at the time of report generation. \r\n 2. Figures stated herein are susceptible and subject to revision due to unexpected changes including without limitation bank charges currency fluctuations general charges and in accounting interpretation. \r\n 3. Accordingly neither Stockal Group its affiliates nor any of its employees make any warranty express or implied nor accept any responsibility for the reliability accuracy or completeness of the compiled financial information nor does it accept liability of any kind whatsoever including liability by reason of negligence to any person for losses incurred as a result of placing reliance on the compiled financial information. \r\n \r\n 4. ERRORS OR DISCREPANCIES \r\n a. If you find an error or discrepancy relating to your Report you must notify us immediately and no later than 10 days after this statement is sent or made available to you. \r\n b. If this statement shows that we have mailed the Report and that you have not received it kindly notify customer support immediately. \r\n c. If we do not receive your response in this stipulated time; we will assume that you have accepted the Report and account balance as being correct (as set out in this notice) with respect to your Cash Management Account. \r\n Please retain this statement for your tax records.";

    try {
      var csv = topHeader + info + info2 + info3 + info4 + info5 + statementFrom + preHeader + headers + preHeader + convertToCSV(jsonObject) + preHeader + disclaimer + disclaimerDescription;
      var exportedFilenmae = fileName;

      var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  const downLaodingCsv = () =>{
    var start_date = dateFormatter(startDate)
    var end_date = dateFormatter(endDate)
    getCashManagementTransactionData(start_date,end_date,stoAccountId, selectedPill,undefined,true).then(response=>{
        if(response.code === 200){
          let x =  response.data.transactions
            if(x !== undefined){
            downloadExcel(x)
            }
          }
        }).catch((err)=>{
          console.error(err)
      })
  }

  const handleClose = () => {
    setShowModal(false)
  }

  const onTransferFunds = () => {
    getContactDetailsTransferFunds()
    setShowModal(true)
  }

  const lastFourDigit = (e) => {
    let sliced = e.slice(12,16);
    return sliced
  }

  useEffect(()=>{
    const queryUrl = queryString.parse(window.location.search, {
      parseNumbers: false
    })
    if(queryUrl.transferFund) {
      allTransactionData()
      if(brokerAccountID !== "") {
        getContactDetailsTransferFunds()
      }
      setShowModal(true)
    }
  },[brokerAccountID])

  const openTranferFundsHeader = () => {
    allTransactionData()
    if(brokerAccountID) {
      getContactDetailsTransferFunds()
    }
    setShowModal(true)
  }

  let history = useHistory()

  const [userDetails, setUserDetails] = useState({dwAccountStatus:"", savingAccountStatus:""})

  const accountDetails = () => {
    getAccountInfo().then(response => {
        if(response && response.accountStatus) {
          setUserDetails(existingValue => ({...existingValue, dwAccountStatus : response.accountStatus, savingAccountStatus : response.savingAccountStatus}))
        }
    }).catch((err)=>{
        console.error(err)
    })
  }

  useEffect(()=>{
    accountDetails()
  },[])

  const userState = () => {
    if(userDetails.dwAccountStatus && userDetails.dwAccountStatus === 2) {
      if(userDetails.savingAccountStatus) {
        if(userDetails.savingAccountStatus === 1 || userDetails.savingAccountStatus === 3) {
          history.push('/savings/status')
        } else {
          history.push('/savings')
        }
      } else {
        history.push('/savings/signup')
      }
    } else {
      history.push('/savings/status')
    }
  }

  useEffect(()=> {
    if(userDetails.dwAccountStatus !== undefined && userDetails.dwAccountStatus !== null && userDetails.dwAccountStatus !== '') {
      userState()
    }
  },[userDetails])

  return (
    <>
      <Header openTranferFundsHeader={openTranferFundsHeader}/>
      <Container className={slideeffect ? styles.shiftPos : styles.originalPos}>
      {showDropdownModal ?
        <CashMgmtModal showModal={showDropdownModal} openModal={OpenDropdownModal}>
            {modalData === 'accountDetails' ? <AccountDetailsModal onHide={OpenDropdownModal}/> : modalData === 'changePin' ? <VirtualCardPinModal resetPin={true} title={'Change Virtual Card PIN'} buttonText={'Change PIN'} onHide={OpenDropdownModal} cardId={cardId} virtualCard={virtualCard} setCardStatus={setVirtualCardStatus}/> : modalData === 'freezeCard' ? <FreezeVirtualCardModal onHide={OpenDropdownModal} cardId={cardId} setCardStatus={setVirtualCardStatus}/> : modalData === 'unfreezeCard' ?  <UnfreezeVirtualCardModal onHide={OpenDropdownModal} cardId={cardId} setCardStatus={setVirtualCardStatus}/> : ''}
        </CashMgmtModal> : ''}
      {showModal ?
      <CustomModal className={styles.customModalPadding}
      show={showModal}
      handleClose={handleClose}
      fromCashManagement={true}>
        <TransferOfFunds contactList={contactList}
        handleClose={handleClose}
        brokerAccountID={brokerAccountID}
        amount={bankDetails && bankDetails.balanceAmt ? addCommasinPrice(parseFloat(bankDetails.balanceAmt)) : ""}
        setIsTransferOfFundSuccess={setIsTransferOfFundSuccess}
        accountNumber={bankDetails && bankDetails.stoCashAccount ?  bankDetails.stoCashAccount.accountNum : "" }/>
      </CustomModal> : ""}
        <Row className='mb-5'>
          <Col lg={8} className={styles.savingsLeftCardPad}>
            <Row className={styles.savingsTitle}>
              <Col>US Account Balance</Col>
            </Row>
            <Row>
              <Col>
              <img className={styles.savingsUsFlag} src={UsFlag}></img>
              {/* make sure to use commas when integrating Api*/}
              <span className={styles.savingsAccountBalance}>${bankDetails ? addCommasinPrice(parseFloat(bankDetails.balanceAmt)) : "--"}</span>
              </Col>
            </Row>
            <div className={styles.mobileVirtualCardShowOnly}>
            <Row className={`d-flex ${styles.savingsRightCardDetailsButtonRow}`} style={{marginBottom:"32px"}}>
              <div className={styles.savingsRightCardDetailsAddButton}
                // onClick={()=> {dispatchAction(showFundModal({saving:true}))}}
                style={{background: "grey"}}>
                <span><img style={{marginRight:"12.17px"}} src={plusAddFunds}></img>Add Funds</span>
              </div>
              <div className={styles.savingsRightCardDetailsTransferButton}
              onClick={transactionListError ? "" : onTransferFunds} style={{color: transactionListError ? "grey" : "",
              borderColor: transactionListError ? "grey" : ""}}>
                <span>Transfer Funds</span>
              </div>
              <div ref={profileMobileRef}>
                <div className={styles.savingsRightCardThreeDots} onClick={() => setShowMobileDropdown(!showMobileDropdown)}>
                  <img className={styles.savingRightCardThreeVerticalDots} src={blueIcon}>
                  </img>
                  {showMobileDropdown ?
                    <div>
                    <CashManagementProfileCard
                        OpenDropdownModal={OpenDropdownModal}
                        dropDownShow={setShowMobileDropdown}
                        setModalData={setModalData}
                        virtualCardStatus={virtualCardStatus}
                        virtualCard={virtualCard}
                        isCardActivated={isCardActivated}
                      />
                    </div>
                  : ""}
                </div>
              </div>
            </Row>
              {cardLoading ? <LoadingVirtualCard width={width}/> : cardError ? <UnableToFetchDataVirtualCard fetchData={virtualCardDetails}/> : virtualCardStatus && virtualCardStatus === 'active' ? <UpComingVirtualCard isCardActivated={isCardActivated} /> : (
                <>
                  {virtualCard && isCardActivated ? <UpComingVirtualCard isCardActivated={isCardActivated} /> : <UpComingVirtualCard isCardActivated={isCardActivated} /> }
                </>
              )}
              <hr className={styles.savingsRightCardTopBorder}></hr>
            <Row className={styles.savingsRightCardMarginBottom}>
              <Col lg={6} xs={6} sm={6} md={6} className={styles.savingsRightCardDetails}>
                <span>Account Number</span>
              </Col>
              <Col lg={6} xs={6} sm={6} md={6} className={styles.savingsRightCardDetailsValue}>
                <div className={styles.cashMgmtRow}>
                  {bankDetails ? `XXXXXXXXXX${lastFourDigit(bankDetails.stoCashAccount.accountNum)}` : "--"}
                  {bankDetails ? <Copy isPrimaryIcon={true} data={{label:'Account Number', value:bankDetails.stoCashAccount.accountNum}}/> : ''}
                </div>
              </Col>
            </Row>
            <Row className={styles.savingsRightCardMarginBottom}>
              <Col lg={6} xs={6} sm={6} md={6} className={styles.savingsRightCardDetails}>
                <span>Routing Numbers</span>
              </Col>
              <Col lg={6} xs={6} sm={6} md={6} className={styles.savingsRightCardDetailsValue}>
                <div className={styles.cashMgmtRow}>
                    {bankDetails ? bankDetails.stoCashAccount.routingNum : "--"}
                    {bankDetails ? <Copy isPrimaryIcon={true} data={{label:'Routing Number', value:bankDetails.stoCashAccount.routingNum}}/> : ''}
                </div>
              </Col>
            </Row>
            <hr className={styles.savingsRightCardTopBorder}></hr>
            </div>
            {/* <TransactionModal/> */}
            <Row className={styles.savingsPillsMargin}>
              <Col lg={12} className={styles.savingsTitleTransactions}>Transaction History</Col>
              {/* for date */}
                <Col lg={3} sm={5} md={5} xs={5}>
                  <CashManagementDatePicker
                    type={'From'}
                    changeDate={changeDate}
                    value={startDate}
                    id="Start_Date"
                    extraPad="0px"
                    maxDate={endDate}
                  />
                </Col>
                <Col lg={3} sm={5} md={5} xs={5} className={styles.savingDatePickerWebPadding}>
                  <CashManagementDatePicker
                    type={'To'}
                    changeDate={changeDate}
                    value={endDate}
                    minDate={startDate}
                    id="End_Date"
                    extraPad="15px"
                  />
              </Col>
              <Col lg={2} xs={2} className={styles.savingsDownloadButtonAlighnment}>
              <img src={savingDownload} onClick={downLaodingCsv} className={styles.savingsDownloadButton}></img>
              </Col>
              <Col lg={4} xs={12} style={{width:"100%"}} ref={ref} onClick={openTransactionTypePopup} className={styles.savingsWebFilter}>
                <span className={styles.savingsPillsDropdownSelection} style={{color: selectedInputPill === "Pending" ? "#FA5D05" : selectedInputPill === "Completed" ? "#0DC014" :  selectedInputPill === "Declined" ? "#FA274A" : ""}}>
                <span className={styles.savingPillCircleColor} style={{backgroundColor: selectedInputPill === "Pending" ? "#FA5D05" : selectedInputPill === "Completed" ? "#0DC014" :  selectedInputPill === "Declined" ? "#FA274A" : ""}}></span>{selectedInputPill}
                <img className={styles.savingPillsDropDownIcon} src={DownIcon}></img></span>
                {showTransactionTypePopup ? <TransactionTypePopUp data={transactionValue} selectPill={pillSelection} openTransaction={openTransactionTypePopup}/> : ""}
              </Col>
              <Col xs={12} md={12} className={styles.savingsHideMobileFilter} onClick={openTransactionTypePopupMobile} ref={transTypeRef}>
                <div className={styles.savingsMobileFilter}>
                <span className={styles.savingsMobileFilterText}><img src={filter} style={{marginRight:"10px"}}></img>{selectedInputPill}</span>
                </div>
                {showTransactionTypePopupMobile ? <TransactionTypePopUp data={transactionValue} selectPill={pillSelection} openTransaction={openTransactionTypePopupMobile}/> : ""}
              </Col>
            </Row>
            {isTransactionListEmpty ? <DataNotFoundState onTransferFunds={onTransferFunds}/> : listLoading ? <LoadingState/> : transactionListError ? <UnableToFetchDataState fetchList={getCashAccounId}/> : (
            <>
                <Row className={styles.savingsTableHeader}>
                  <Col lg={5} md={6} sm={6} xs={6} className={styles.savingsTableHeading}>
                    <span>Transactions</span>
                  </Col>
                  <Col lg={3} md={6} sm={6} xs={6} className={`${styles.savingsTableHeading} ${styles.savingsTableHeadingAmount}`}>
                    <span>Amount</span>
                  </Col>
                  <Col lg={2} md={4} sm={4} className={`${styles.savingsTableHeading} ${styles.savingtableHideMobile}`}>
                    <span>Date & Time</span>
                  </Col>
                  <Col lg={2}  className={`${styles.savingsTableHeading} ${styles.savingtableHideMobile}`}>
                  </Col>
                </Row>
                {collection && collection.map((item, index) => {
                  return(
                    <Row className={styles.savingsTablePoints}>
                      <Col lg={5} xs={6} className={styles.savingTableType} onClick={() => {
                        setTransactionID(item.stoTranId)
                        openTransactionDetails()
                      }}>
                        <span className={styles.savingTableTransactionTypeValue}>
                          {item.tranDesc ?<OverlayTrigger
                          placement='bottom'
                          trigger={['hover', 'focus','click']}
                          overlay={
                            <Tooltip>
                            {item.tranDesc}
                            </Tooltip>
                          }>
                          <div className={styles.overText}>
                          {item.tranDesc.length > 30 ? `${item.tranDesc.substring(0, 30)}...` : item.tranDesc}
                          </div>
                        </OverlayTrigger> : "--"}
                        </span>
                        <span className={`${styles.savingTableId} ${styles.savingtableHideMobile}`}>
                          ID: {item.stoTranId ? item.stoTranId : "--"}
                        </span>
                        <span className={`${styles.savingTableId} ${styles.webDisplayNone}`}>
                          {item.tranType === "debit" && item.tranStatus === "completed" || item.tranStatus === "pending" ?
                          <><span style={{backgroundColor:"#FA5D05",marginRight:"6px"}} className={styles.savingPillCircleColor}></span><span style={{display:"inlineBlock" ,color:"#767C8F;"}}>Pending</span></>
                          :item.tranType === "debit" && item.tranStatus === "declined" ?
                          <><span style={{backgroundColor:"#FA274A",marginRight:"6px"}} className={styles.savingPillCircleColor}></span><span style={{display:"inlineBlock" ,color:"#767C8F;"}}>Declined</span></>
                          : <span className={`${styles.savingTableId}`}>
                          ID: {item.stoTranId ? item.stoTranId : "--"}
                        </span>}
                        </span>
                      </Col>
                      <Col lg={3} xs={6} className={`${styles.savingTableType} ${styles.savingsTableHeadingAmount}`} onClick={() => {
                        setTransactionID(item.stoTranId)
                        openTransactionDetails()
                      }}>
                        {/* amount debited or credited*/}
                        {item.tranType === "debit" && item.tranStatus === "completed" || item.tranStatus === "pending" ?
                        <span className={styles.savingTableTransactionTypeValue}>- ${addCommasinPrice(parseFloat(item.tranAmt))}</span> :
                        item.tranType === "debit" && item.tranStatus === "declined"  ?
                        <span className={styles.savingTableTransactionTypeValue} style={{color:"#767C8F"}}>${addCommasinPrice(parseFloat(item.tranAmt))}</span> :
                        item.tranType === "debit" && item.tranStatus === "settled" ?
                        <span className={styles.savingTableTransactionTypeValue}>- ${addCommasinPrice(parseFloat(item.tranAmt))}</span>:
                        <span className={styles.savingTableTransactionTypeValue}>+ ${addCommasinPrice(parseFloat(item.tranAmt))}</span>}

                        <span className={`${styles.savingTableId} ${styles.savingTableMobileBalance}`}>
                          {item.tranType === "debit" && item.tranStatus === "completed" || item.tranStatus === "pending" ?
                          <><span style={{backgroundColor:"#FA5D05",marginRight:"6px"}} className={styles.savingPillCircleColor}></span><span style={{display:"inlineBlock" ,color:"#FA5D05"}}>Pending</span></>
                          :item.tranType === "debit" && item.tranStatus === "declined" ?
                          <><span style={{backgroundColor:"#FA274A",marginRight:"6px"}} className={styles.savingPillCircleColor}></span><span style={{display:"inlineBlock" ,color:"#FA274A"}}>Declined</span></>
                          :<span>Balance: ${item.balanceAmt ? addCommasinPrice(parseFloat(item.balanceAmt)) : "--"}</span>}
                        </span>
                        <span className={`${styles.savingTableId} ${styles.savingTableMobileTimeDate}`}>
                          {seprateTimeAndDate(item.tranDt,'date')}, {seprateTimeAndDate(item.tranDt,'time')} IST
                        </span>
                      </Col>
                      <Col lg={3} className={`${styles.savingTableType} ${styles.savingtableHideMobile}`} onClick={() => {
                          setTransactionID(item.stoTranId)
                          openTransactionDetails()
                        }}>
                        <span className={styles.savingTableTransactionTypeValue}>
                        {/* {item.dates ? moment(item.tranDt).format('D MMMM, Y') : ""} */}
                          {/* {item.dates ? item.dates : "--"} */}
                          {seprateTimeAndDate(item.tranDt,'date')}
                        </span>
                        <span className={styles.savingTableId}>
                          {seprateTimeAndDate(item.tranDt,'time')} IST
                          {/* {item.time ? item.time : "--"} */}
                        </span>
                      </Col>
                      <Col lg={1} className={`${styles.savingTableType} ${styles.savingtableHideMobile}`} style={{cursor:"pointer"}}
                      onClick={() => {
                        handleClick(index)
                        setTransactionID(item.stoTranId)
                      }}>
                      <img className={styles.savingThreeVerticalDots} value={`#fraud${item.uniqueId}`} src={Icon}></img>
                      <FraudPopup id={index}
                      target={target}
                      show={show}
                      />
                      </Col>
                    </Row>
                  )
                })}
                {showTransactionDetails ?
                <CashMgmtModal showModal={showTransactionDetails} openModal={openTransactionDetails}>
                  <TransactionDetailsModal stoTranId={tranId} onHide={openTransactionDetails}/>
                </CashMgmtModal>
              : ''}
                {/* pagination */}
                <Row className={styles.savingsPagination}>
                  <Col lg={3} xs={4}
                  className={styles.savingPaginationRowPad}
                  onClick={() => goBack(currentPage)}
                  >
                  <img src={leftIcon} style={{marginRight:"8px"}}></img>
                  <span
                  className={styles.savingPaginationButton} style={{cursor: currentPage !== 1 ? "pointer" : "default"}}>
                  Previous
                  </span>
                  </Col>
                  <Col lg={6} xs={4} className={styles.savingPaginationRowCount}>
                    {totalPaginationPage === 0 ||totalPaginationPage === undefined ? "" : `Page ${currentPage} of ${totalPaginationPage}`}
                  </Col>
                  <Col lg={3} xs={4}
                  className={styles.savingPaginationRowPad}
                  style={{textAlign:"right"}}
                  onClick={() => goNext(currentPage)}>
                  <span
                  className={styles.savingPaginationButton} style={{cursor: currentPage !== totalPaginationPage  ? "pointer" : "default"}}>
                  Next</span>
                  <img src={rightIcon} style={{marginLeft:"8px"}}></img>
                  </Col>
                </Row>
            </>
            )}

          </Col>

          {/* savings Account Detail section */}

          <Col lg={4} className={`${styles.savingsRightCardPad} ${styles.WebVirtualCardShowOnly}`}>
            <div className={styles.savingsCardDatailsDiv}>
              <Row className={`d-flex ${styles.savingsRightCardDetailsButtonRow}`} style={{marginBottom:"32px"}}>
                <div className={styles.savingsRightCardDetailsAddButton} style={{background: "grey", cursor:"context-menu"}}
                  // onClick={()=> {dispatchAction(showFundModal({saving:true}))}}
                  >
                  <span><img style={{marginRight:"12.17px"}} src={plusAddFunds}></img>Add Funds</span>
                </div>
                <div className={styles.savingsRightCardDetailsTransferButton}
                onClick={transactionListError ? "" : onTransferFunds} style={{color: transactionListError ? "grey" : "",
                borderColor: transactionListError ? "grey" : ""}}>
                  <span>Transfer Funds</span>
                </div>
                <div ref={profileRef}>
                  <div className={styles.savingsRightCardThreeDots} onClick={() => setShowDropDown(!showDropDown)}>
                    <span>
                      <img className={styles.savingRightCardThreeVerticalDots} src={blueIcon}></img>
                    </span>
                  </div>
                  {showDropDown ?
                    <div>
                      <CashManagementProfileCard
                        OpenDropdownModal={OpenDropdownModal}
                        dropDownShow={setShowMobileDropdown}
                        setModalData={setModalData}
                        virtualCardStatus={virtualCardStatus}
                        virtualCard={virtualCard}
                        isCardActivated={isCardActivated}
                      />
                    </div>
                    : ""}
                </div>
              </Row>
              {cardLoading ? <LoadingVirtualCard width={width}/> : cardError ? <UnableToFetchDataVirtualCard fetchData={virtualCardDetails}/> : virtualCardStatus && virtualCardStatus === 'active' ? <UpComingVirtualCard isCardActivated={isCardActivated} /> : (
                <>
                  {virtualCard && isCardActivated ? <UpComingVirtualCard isCardActivated={isCardActivated} /> : <UpComingVirtualCard isCardActivated={isCardActivated} /> }
                </>
              )}
              <hr className={styles.savingsRightCardTopBorder}></hr>
              <Row className={styles.savingsRightCardMarginBottom}>
                <Col lg={6} xs={6} sm={6} md={6} className={styles.savingsRightCardDetails}>
                  <span>Account Number</span>
                </Col>
                <Col lg={6} xs={6} sm={6} md={6} className={styles.savingsRightCardDetailsValue}>
                <div className={styles.cashMgmtRow}>
                  {bankDetails ? `XXXXXXXXXX${lastFourDigit(bankDetails.stoCashAccount.accountNum)}` : "--"}
                  {bankDetails ? <Copy isPrimaryIcon={true} data={{label:'Account Number', value:bankDetails.stoCashAccount.accountNum}}/> : ''}
                </div>
                </Col>
              </Row>
              <Row className={styles.savingsRightCardMarginBottom}>
                <Col lg={6} xs={6} sm={6} md={6} className={styles.savingsRightCardDetails}>
                  <span>Routing Numbers</span>
                </Col>
                <Col lg={6} xs={6} sm={6} md={6} className={styles.savingsRightCardDetailsValue}>
                <div className={styles.cashMgmtRow}>
                  {bankDetails ? bankDetails.stoCashAccount.routingNum : "--"}
                  {bankDetails ? <Copy isPrimaryIcon={true} data={{label:'Routing Number', value:bankDetails.stoCashAccount.routingNum}}/> : ''}
                </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

const TransactionTypePopUp = (props) => {
  return(
    <div className={styles.transactionTypeContainer}>
      <div className={styles.savingsMobileCross} onClick={props.openTransaction}><img src={close} alt="close-icon"></img></div>
      {props.data.map((e,index) => {
          return( <span onClick={() => props.selectPill(e.pillValue,e.value)} className={styles.transactionTypeValue} style={{color:e.color}}><span className={styles.savingPillCircleColor}  style={{backgroundColor:e.color}}></span>{e.value}</span>)
        })
      }
    </div>
  )
}

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
        handler(event)
      }
      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    [ref, handler]
  )
}

export default CashManagement

