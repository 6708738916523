import React, { useState, useRef, useEffect, useContext } from 'react'
import { Row, Col, Form, Card } from 'react-bootstrap'
import { Formik } from 'formik'
import * as yup from 'yup'
import { forgotPassword } from '../../api'
import VerifyEmail from './VerifyEmail'
import styles from './ForgotPassword.module.css'
import { ThemeContext } from '../../Context/ThemeContext'
import SignUpInput from '../SignUp/SignUpInput'
import SignUpButton from '../SignUp/SignUpButton'
import USerSvg from '../../public/images/newOnboarding/User.svg'
import NewHeader from '../NewSignupComponents/NavBar/NewHeader'
import { useHistory } from 'react-router-dom'
import OnboardingFooter from '../Footer/OnboardingFooter'
import Logo from '../../public/images/newlogo.svg'

const ForgotPassword = () => {
	if (sessionStorage.getItem('currentSignUp'))
		sessionStorage.removeItem('currentSignUp')
	let history = useHistory()
	const { shortLogo } = useContext(ThemeContext)
	const [showVerify, setShowVerify] = useState(false)
	const [errormessage, setErrormessage] = useState('')
	const [hashID, setHashID] = useState('')
	const [username, setUsername] = useState('')
	const [otpSendMessage, setOtpSendMessage] = useState('')
  const [loading, setLoading] = useState(false)
	const editFieldRef = useRef(null)
	const formInitialValues = {
		username
	}
	const { isPartner } = useContext(ThemeContext)
	const showPoweredLogo =
		isPartner && !window.location.href.includes('landing-page')

	useEffect(() => {
		editFieldRef.current.focus()
	}, [])

	const validationSchema = yup.object({
		username: yup
			.string()
			.trim()
			// .matches(/^[a-zA-Z0-9_.@]*$/ , 'Only Alphabets, numbers, _ , @ and . are allowed in Username.')
			.required('Username is required ')
	})

	const callAPI = async from => {
    setLoading(true)
		if (from === 'resend') setOtpSendMessage('OTP sent')
		else setOtpSendMessage('')
		const resp = await forgotPassword(username)
		if (resp && resp.code === 200) {
			try {
				const publicIp = require('public-ip')
				const ipAddress = await publicIp.v4()
				// eslint-disable-next-line no-undef
				webengage.user.setAttribute('we_ip', ipAddress)
				// eslint-disable-next-line no-undef
				webengage.user.setAttribute('dwUsername', username)
				// eslint-disable-next-line no-undef
				webengage.user.setAttribute('platform', 'web')
				// eslint-disable-next-line no-undef
				webengage.track('LOGIN.FORGOT_PASSWORD')
			} catch (error) {
				console.log('error', error)
			}
			setErrormessage('')
			setHashID(resp.data.hashID)
			setShowVerify(true, setLoading(false))
		} else {
			//setErrormessage(resp.message)
			setErrormessage('Username not found', setLoading(false))
		}
	}

	const keyPressHandler = (event, submitCallback) => {
		if (event.which === 13) {
			submitCallback()
		}
	}

	return (
		<>
			<NewHeader
				message='Recollected your password?'
				linkName='Log In'
				onClickLink={() => history.push('/signin')}
			/>
			<div className={styles.forgotPasswordContainer}>
				<div className={styles.cardContainer}>
					<Card className={styles.cardSignUp}>
						<Card.Body className={styles.cardBodySignup}>
							{showVerify ? (
								<VerifyEmail
									hashID={hashID}
									setHashID={setHashID}
									setErrormessage={setErrormessage}
									resendOtp={callAPI}
									otpSendMessage={otpSendMessage}
									setOtpSendMessage={setOtpSendMessage}
									setShowVerify={setShowVerify}
								/>
							) : (
								<>
									<Row className={styles.title}>Forgot password?</Row>
									<Row className={styles.subTitle}>
										Verify your unique username and enter the confirmation code
										sent to your registered email address.
									</Row>
									<Formik
										initialValues={formInitialValues}
										validationSchema={validationSchema}
										onSubmit={async values => {
											try {
												callAPI('forgotpass')
											} catch (error) {
												console.log(error)
											}
										}}>
										{({
											handleChange,
											handleBlur,
											dirty,
											handleSubmit,
											values,
											touched,
											errors
										}) => {
											return (
												<div>
													{/* <Form.Group
												className={`form-focus ${
													values.username !== '' ? 'focused' : null
												}`}>
												<Form.Control
													type='text'
													name='username'
													ref={editFieldRef}
													tabIndex='1'
													value={values.username.toLowerCase()}
													onChange={e => {
														setErrormessage('')
														setUsername(e.target.value.toLowerCase())
														handleChange(e)
													}}
													onBlur={handleBlur}
													onKeyPress={e => keyPressHandler(e, handleSubmit)}
													isInvalid={touched.username && errors.username}
													isValid={touched.username && !errors.username}
													onPaste={e => {
														e.preventDefault()
														return false
													}}
													onCopy={e => {
														e.preventDefault()
														return false
													}}></Form.Control>
												<Form.Label className='focus-label'>
													Enter username
												</Form.Label>
												<Form.Control.Feedback type='invalid'>
													{errors.username}
												</Form.Control.Feedback>
											</Form.Group> */}

													<Row className={styles.inputs}>
														<SignUpInput
															label='Username'
															placeholder='Enter Username'
															type='text'
															name='username'
															refValue={editFieldRef}
															tabIndex='1'
															value={values.username.toLowerCase()}
															onChange={e => {
																setErrormessage('')
																setUsername(e.target.value.toLowerCase())
																handleChange(e)
															}}
															onKeyPress={e => keyPressHandler(e, handleSubmit)}
															//onBlur={event => handleBlur(event)}
															isInvalid={
																(touched.username && errors.username) ||
																errormessage
															}
															isValid={
																touched.username &&
																!errors.username &&
																errormessage === ''
															}
															errorMessage={
																errors.username ? (
																	errors.username
																) : errormessage === 'Username not found' ? (
																	<>
																		<span style={{ marginRight: '5px' }}>
																			{errormessage}
																		</span>
																		<a href='/signup'>Sign Up?</a>
																	</>
																) : (
																	errormessage
																)
															}
															onPaste={e => {
																e.preventDefault()
																return false
															}}
															onCopy={e => {
																e.preventDefault()
																return false
															}}
															icon={USerSvg}
														/>
													</Row>

												<Row className={styles.buttonMargin}>
													<SignUpButton
														classes={styles.buttonSignUP}
														title='Verify Username'
														onClick={handleSubmit}
                            loading={loading}
													/>
												</Row>
											</div>
										)
									}}
								</Formik>
							</>
						)}
					</Card.Body>
				</Card>
			</div>
      </div>
			<div
				style={{
					marginTop: '50px',
					display: 'flex',
					justifyContent: 'center'
				}}>
				{showPoweredLogo ? (
					<Col
						md={4}
						xl={4}
						lg={4}
						style={{ textAlign: 'center' }}
						className='d-none d-md-block'>
						<span className={styles.poweredText}>Powered by</span>
						<img src={Logo} alt='powered by' width='64px' height='16px' />
					</Col>
				) : (
					<Col md={2} xl={3} className={`d-none d-md-flex`}></Col>
				)}
			</div>
			<OnboardingFooter />
			{/* </div> */}
		</>
	)
}

export default ForgotPassword
