import React, { useEffect, useState } from 'react'
import { Redirect, Route,useHistory } from 'react-router-dom'
import { io } from "socket.io-client";
import InnerLayout from './layouts/inner.layout'
import { SocketContext } from './socket-context';
import queryString from 'query-string'

const LoggedInRoute = ({component: Component, ...rest}) =>{
  const [socket, setSocket] = useState(null)
  const authed =  sessionStorage.getItem('token') && JSON.parse(sessionStorage.getItem('token')).accessToken ? true :false

  useEffect(() => {
    if(authed) {
      let newSocket = io.connect(process.env.REACT_APP_SOCKET_ENDPOINT, {
        transports: ['websocket'],
        query : { token: sessionStorage.getItem('socketToken') }
        // query: { token: sessionStorage.getItem('socketToken') }
      });
      setSocket(newSocket);
      return () => newSocket.close();
    }
   }, [setSocket, authed])
   const history = useHistory()


   return (
    <Route
      {...rest}
      render={(props) => authed === false
        ?
         history.push(`/signin${window.location.search}?redirect_uri=${encodeURIComponent(window.location.origin+window.location.pathname)}`)
        // history.push(`/sso-signin${window.location.search}`)

        // <Redirect push to={{pathname: `/signin${window.location.search}`, state: {from: props.location}}} />
      :
      // <Component {...props} />
      <SocketContext.Provider value={socket}>
        <InnerLayout  component={Component} {...props} socket={socket}/>
      </SocketContext.Provider>
    }
    />
  )
}
export default LoggedInRoute;
