import React, { useState , useEffect, useRef } from 'react'
import PinInput from "react-pin-input"
import styles from './TransactionDetailsModal.module.css'

const CashMgmtOtp = (props) => {

    const { setOtp , resendOtp , otpError, clearOtp, setClearOtp } = props

    const otpRef = useRef()

    const [ showResendOtp , setShowResendOtp ] = useState(false)
    const [ otpTimer , setOtpTimer ] = useState(180)
    const [ otpMessage , setOtpMessage] = useState()
    const [width, setWindowWidth] = useState(0);

    const responsiveStyle = {
      input: {
        width: '29px',
        height: '30px',
        marginLeft:"4px",
        marginRight:"4px",
        border:"1px solid #D3D4DA",
        backgroundColor:"#FFFFFF",
        borderRadius:"6px"
      }
    }

    const style = {
      input: {
        width: '40px',
        height: '40px',
        marginLeft:"4px",
        marginRight:"4px",
        border:"1px solid #D3D4DA",
        backgroundColor:"#FFFFFF",
        borderRadius:"6px"
      }
    }

    useEffect(() => {
      updateDimensions();
      window.addEventListener('resize', updateDimensions);
      return () =>
        window.removeEventListener('resize',updateDimensions);
    }, [])

    const updateDimensions = () => {
      const width = window.innerWidth
      setWindowWidth(width)
    }

    const userInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : {}
    const email = userInfo.emailAddress1

    const setTimer = () => {
        if (otpTimer > 0) {
            setTimeout(() => setOtpTimer(otpTimer - 1), 1000)
          } else {
            setOtpTimer(0)
            setOtpMessage('OTP Expired')
            setShowResendOtp(true)
          }
    }

    const onResendOtp = () => {
        resendOtp()
        setOtpTimer(180)
        setOtpMessage('')
        setShowResendOtp(false)
    }

    useEffect(()=> {
        setTimer()
    },[otpTimer])

    useEffect(() => {
        otpRef && otpRef.current && otpRef.current.focus()
    },[])

    useEffect(() => {
      if(clearOtp){
          otpRef && otpRef.current && otpRef.current.clear()
          setClearOtp(false)
      }
    },[clearOtp])

    useEffect(() => {
      const pinInputs = document.getElementsByClassName('pincode-input-text')
      if (pinInputs && pinInputs?.length > 0) {
        for (let i = 0; i < pinInputs.length; i++) {
          pinInputs[i].addEventListener('keydown', e => {
            if (
              e.key.toLowerCase() === 'control' ||
              e.key.toLowerCase() === 'meta' ||
              e.key.toLowerCase() === 'v' ||
              e.key.toLowerCase() === 'c'
            ) {
              e.preventDefault()
            }
          })
        }
      }
    }, [])

    return (
        <div className={`${styles.otpContainer} ${styles.modalText}`}>
            <p className={styles.textGrey}>Enter the 6-digit code sent to</p>
            <p className={styles.textBlack}>{email}  <a href='/profile' className={styles.textBlue}>Edit</a></p>
            <PinInput
                ref={otpRef}
                length={6}
                initialValue=""
                onChange={(value, index) => {setOtp(value)}}
                type="numeric"
                inputMode="number"
                style={{padding: '20px 0px'}}
                inputStyle={width < 400 ? responsiveStyle.input : style.input }
                inputFocusStyle={{borderColor: '#3C404A'}}
                onComplete={(value, index) => {setOtp(value)}}
                autoSelect={true}
                regexCriteria={/[0-9]/}
            />
            {otpError ? <p className={`${styles.modalTextSmall} ${styles.textRed}`} style={{paddingBottom:"16px"}}>{otpError}</p> : ''}
            <p className={styles.textGrey}>
                {otpMessage ? <span className={styles.textRed}>{otpMessage}</span> : `Expires in ${otpTimer}s`}
                {showResendOtp ? (
                    <span className={styles.textBlue} style={{cursor:"pointer"}} onClick={onResendOtp}>
                        &nbsp; Resend Code
                    </span>
                ) : ''}
            </p>
        </div>
    )
}


export default CashMgmtOtp
