
import React, { useEffect, useState, useContext } from 'react'
import { init } from 'onfido-sdk-ui';
import { createApplicant, getSdkToken, identityInitiate, updatedAccount } from '../../api'
import AlertError from '../AlertError/AlertError'
import { ThemeContext } from '../../Context/ThemeContext'
import styles from './VerifyPan.module.css'
import DocumentSkeleton from '../../skeletons/DocumentSkeleton'

const Document = props => {
  const [serverError, setServerError] = useState('')
  const { buttonColor } = useContext(ThemeContext)
  const [loading, setLoading] = useState(false)
  const userData = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : ''
  const [firstName] = useState(userData.firstName ? userData.firstName : '')
  const [lastName] = useState(userData.lastName ? userData.lastName : '')
  const [email] = useState(userData.emailAddress1 ? userData.emailAddress1 : '')
  const [countryOfResidence] = useState(userData.country ? userData.country : 'IND')
  const [phoneNumber] = useState(userData.phoneHome ? userData.phoneHome : '')

  useEffect(() => {
    // eslint-disable-next-line no-undef
    webengage.track('cash account terms accepted')
    onSelection()
  },[])

  const onSelection = async (doc) => {
    setLoading(true)
    try {
      const applicantsReqData = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        source:'CASH_ACCOUNT'
      }
      const applicantsData = await createApplicant(applicantsReqData)
      const { id } = applicantsData.data
      const SdkTokenData = await getSdkToken(id)
      sessionStorage.setItem('oaid', id)
      const onfidoStepsConfig = [
        {
          "type": "document",
          "options": {
            "documentTypes": {
              national_identity_card: {
                "country": countryOfResidence
              },
            }
          }
        },]

      // console.log(JSON.stringify(onfidoStepsConfig))
      const customUI = {
        colorBackgroundButtonPrimary: buttonColor ? buttonColor : '#0061D3',
        colorBackgroundButtonPrimaryHover:buttonColor ? buttonColor : '#0061D3',
        colorBorderButtonSecondary:buttonColor ? buttonColor : '#0061D3',
        colorBackgroundButtonSecondary:'#FFFFFF',
        colorBackgroundButtonSecondaryHover:'#FFFFFF',
        borderRadiusButton:'8px',
        colorBackgroundLinkHover:'transparent',
        colorContentLinkTextHover:'#3C404A',
        fontSizeTitle:'28px',
        fontSizeSubtitle:'16px',
        fontWeightTitle:'500',
        fontWeightSubtitle:'400',
        fontFamilyTitle: 'geomanistmedium',
        fontFamilySubtitle: 'geomanistregular',
        fontFamilyBody: 'geomanistregular',
        borderRadiusSurfaceModal:'16px',
        colorBorderSurfaceModal:'#EFEFF1',
        colorBackgroundSurfaceModal:'#FFFFFF'
      }
      setLoading(false)
      let onfidoObj = init({
        token: SdkTokenData.data.token,
        containerId: 'document-placeholder',
        onError:function(error){
          console.log('id error', JSON.stringify(error))
        },
        onUserExit:function(userExitCode){
          console.log('id Exit', JSON.stringify(userExitCode))
        },
        onComplete: async function (data) {
          // console.log('everything is complete')
          // console.log(data);
          onfidoObj.tearDown()
          let document = []
          Object.keys(data).filter(d => {
            if (d === 'document_front' || d === 'document_back') {
              document.push({
                id: data[d].id,
                type: 'passport',
                side: data[d].side,
                proofType: 'ID',
                proofName: 'Passport'
              })
            }
          })
          // console.log(document)

          await identityInitiate(sessionStorage.getItem('oaid'), document)
             // eslint-disable-next-line no-undef
              webengage.track('cash account kyc uploaded')
          props.setCurrent('success')
        },
        language:{
          phrases:{
            doc_submit: {
              "button_link_upload": "or upload photo – no scans or photocopies",
              "button_primary": "Continue on phone",
              "subtitle": "Take a photo with your phone",
              "title_id_back": "Upload the back of your Passport",
              "title_id_front": "Upload the front of your Passport",
           }
          }
        },
        steps: onfidoStepsConfig,
        customUI,
        userDetails: {
          smsNumber: phoneNumber
        }
      })
    }
    catch (e) {
      console.log(e)
      setServerError('Something Went wrong. Please Try Again!')
    }
  }


  return (
    <>
      {serverError === '' ? null : (
        <AlertError color='#E81346' message={serverError} />
      )}
      <div className={styles.passportDiv}>
        {loading ? <DocumentSkeleton /> : <div id="document-placeholder" className={styles.documentPlaceholder} style={{ height: '500px', position: 'relative' }}></div>}
      </div>
    </>
  )
}

export default Document;

