import React, { useContext, useEffect, useState } from "react"
import styles from "./offline.module.css"
import PlusIcon from "../../../../public/images/plus.svg"
import DeleteIcon from "../../../../public/images/delete.svg"
import CalendardIcon from "../../../../public/images/calendar.svg"
import AlertErrorIcon from "../../../../public/images/alertError.svg"
import VerifiedIcon from "../../../../public/images/verified.svg"
import DatePicker from "../../../DatePicker/DatePicker"
import { format } from "date-fns"
import Transaction from "../Common/Transaction"
import Success from "../Common/Success"
import Instruction from "./Instruction"
import { AddFundContext } from "../../AddFundContext"
import { previousTransactionFund, putLrsAndPickup } from "../../../../api"
import Details from "./Details"
import Review from "./Review"
import { Spinner } from "react-bootstrap"
import GenericLoading from "../../GenericLoading"
import { submitPAN, fetchPAN } from "../../pan"
import SelectRadioBtn from "../../../SelectRadioBtn/SelectRadioBtn"

let financial_year = "";
let today = new Date();
if ((today.getMonth() + 1) <= 3) {
    financial_year = (today.getFullYear() - 1) + "-" + today.getFullYear()
} else {
    financial_year = today.getFullYear() + "-" + (today.getFullYear() + 1)
}
const startDate = new Date(financial_year.split('-')[0],3,1)
const endDate = new Date()
const OfflineTransfer = () =>{
    const {subPage, setSubPage, hashID, selectedAccount, transferOptions, setPage, setOnErrorRetry, setShowGenericError, PAGE, SUBPAGE} = useContext(AddFundContext)
    const [showCalendar, setShowCalendar] = useState(null)
    const [showTransactions, setShowTransactions] = useState(false)
    const [transactions, setTransactions] = useState([])
    const [validate, setValidate] = useState(null)
    const [loadingContinue, setLoadingContinue] = useState(false)
    const [canContact, setCanContact] = useState(false)
    const [PAN, setPAN] = useState({})
    const [panError, setPanError] = useState(false)
    const [panVerifyLimit, setPanVerifyLimit] = useState(false)
    const [panVeriyfing, setPanVerifying] = useState(false)
    const [transactionError, setTransactionsError] = useState([])
    const [loading, setLoading] = useState(false)
    const OFFLINE = PAGE.OFFLINE
    useEffect(()=>{
        if(transferOptions.other){
            setSubPage(SUBPAGE[OFFLINE].DETAILS)
        }else{
            setLoading(true)
            setSubPage(SUBPAGE[OFFLINE].LRSDETAILS)
            fetchPAN(setPAN, setPanError, setLoading, setShowGenericError, setOnErrorRetry)
        }
    },[selectedAccount])
       
    const deleteTransaction = (index)=>{
        let temp = [...transactions]
        temp.splice(index, 1)
        setTransactions(temp)
    }
    const onChange= (index, field, value)=>{
        if(field==='prevLrsDate'){
            value=format(value, 'yyyy-MM-dd')
            setShowCalendar(false)
        }else if(field==='prevLrsAmount' && value > 250000){
            return false
        }else if(field==='prevLrsBank' && value.length > 60){
            return false
        }
        let temp = [...transactions]
        temp[index][field] = value
        setTransactions(temp)
        if(validate>index){
            let transError=[...transactionError]
            if(value && value.length>0){
                transError[index][field]=false
            }else{
                transError[index][field]=true
            }
            setTransactionsError([...transError])
        }
    }
    const submitData = async (showGenericError)=>{
        let success=true
        try{
            const res = await previousTransactionFund(hashID, showTransactions?transactions:[])
            if(res && res.code===200){
                const lrsRes=await putLrsAndPickup(hashID, {applicantPan:PAN.number, hasDoneAnyPreviousLRSinYear:showTransactions, selfPickup:canContact})
                if(lrsRes && lrsRes.code === 200){
                    setSubPage(SUBPAGE[OFFLINE].REVIEW)
                }else{
                    success=false
                }
            }else{
                success=false
            }
        }catch(error){
            success=false
        }  
        if(success && showGenericError){
            setShowGenericError(false)
            setOnErrorRetry(null)
        }else if(!success){
            setOnErrorRetry(submitData)
            setShowGenericError(true)
        }
    }
    
    const onContinue = async()=>{
        let error = false
        if(showTransactions){
            setValidate(transactions.length)
            let transError=[]
            for(let i=0;i<transactions.length;i++){
                transError.push({prevLrsAmount:transactions[i]['prevLrsAmount']&&transactions[i]['prevLrsAmount'].length>0?false:(()=>{error=true; return true})(),
                    prevLrsDate:transactions[i]['prevLrsDate']&&transactions[i]['prevLrsDate'].length>0?false:(()=>{error=true; return true})(),
                    prevLrsBank:transactions[i]['prevLrsBank']&&transactions[i]['prevLrsBank'].length>0?false:(()=>{error=true; return true})()})        
            }
            setTransactionsError([...transError])
        }
        
        if(!loadingContinue && !panError && !error){          
            setLoadingContinue(true)
            await submitData()     
            setLoadingContinue(false)
        }        
    }
    return(<>{loading ? <div className={styles.genericLoading}><GenericLoading /> </div> :
        <>
            {subPage===SUBPAGE[OFFLINE].LRSDETAILS &&<> 
                <div className={styles.subHeader}>
                    Offline Independent Wire
                </div>
                <div className={styles.body}>
                    <div className={styles.panContainer}>
                        <label className={styles.label}>Permanent Account Number (PAN)</label>
                        <div>
                            {(panError || panVeriyfing) &&<div className={`${`${styles.input} ${styles.errorInput}`} ${panError&&styles.panInputError}`} >
                                <input type="text" placeholder="Enter PAN Number" className="border-0 w-100" value={PAN.number} onChange={(e)=>{/^[A-Za-z0-9]*$/.test(e.target.value)&&setPAN({number:e.target.value.toUpperCase().trim()})}}/>
                                {!panVeriyfing&&panError&&<img src={AlertErrorIcon} />}
                            </div>}
                            {panError && !panVeriyfing&&<span className={styles.panError}>
                                    {panError}
                                    <span onClick={!panVerifyLimit?()=>submitPAN(PAN.number, setPanVerifying, setPanError, setPanVerifyLimit, setPAN, setShowGenericError, setOnErrorRetry):()=>window.fcWidget.open()}>{!panVerifyLimit?'Verify Now':'Contact us'}</span>
                                </span>
                            }
                            {panVeriyfing&&<span className={styles.panVerifying}>Verifying...</span>}
                            {!panError && !panVeriyfing && <div className={styles.panVerified}>
                                <span className={styles.value}>{PAN.masked}</span>
                                <img src={VerifiedIcon}/>
                            </div>}
                        </div>
                    </div>
                    <div className={styles.lrsQuestionContainer}>
                        <div className={styles.label}>Have you done any LRS in current financial year?</div>
                        <div className="d-flex">
                            <div className={styles.option} onClick={()=>{setTransactions(transactions.length>0?transactions:[{prevLrsAmount: '', prevLrsBank: '', prevLrsDate: ''}]);setShowTransactions(true)}}>
                                <SelectRadioBtn key='showTrans' value={showTransactions}/>
                                <label className={styles.label}>Yes</label>
                            </div>
                            <div className={styles.option} onClick={()=>setShowTransactions(false)}>
                                <SelectRadioBtn key='showTrans' value={!showTransactions}/>
                                <label className={styles.label}>No</label>
                            </div>
                        </div>
                    </div>
                    {showTransactions && <>
                        <div className={styles.transactionNote}>
                            Please provide details of foreign currency transactions you’ve done in the current financial year (April - March)
                        </div>
                        {transactions.map((item, index)=>{
                            return  <div key={index} className={styles.transactionContainer}>
                            <div className="d-flex justify-content-between">
                                <div className={styles.transactionCount}>
                                    <span>{index+1}.</span>
                                    <span>Transaction</span>
                                </div>
                                {index>0&&<img src={DeleteIcon} className={styles.deleteTransaction} onClick={()=>deleteTransaction(index)}/>}
                            </div>
                            <div className={styles.transaction}>
                                <div>
                                    <label>Amount in USD</label>
                                    <div className={`${styles.inputContainer} ${validate>index&& transactionError[index].prevLrsAmount && styles.errorInput}`}>
                                        <input type="number" placeholder="Enter Amount" value={item.prevLrsAmount} onChange={(e)=>onChange(index, 'prevLrsAmount', e.target.value)}
                                            min={1} max={250000} onKeyPress={(e)=>(e.key==='.'||e.key==='-') && e.preventDefault()}
                                        />
                                        {validate>index&& transactionError[index].prevLrsAmount && <img src={AlertErrorIcon} />}
                                    </div>
                                    {(validate>index&& transactionError[index].prevLrsAmount) &&<span className={styles.errorMsg}>This Feild is required</span>}
                                </div>
                                <div>
                                    <label>Date of Transaction</label>
                                    <div className={`${styles.inputContainer} ${validate>index&& transactionError[index].prevLrsDate && styles.errorInput}`}>
                                        <input
                                            placeholder="Select Date of Transaction"
                                            value={item.prevLrsDate}
                                            onFocus={()=>setShowCalendar(!showCalendar?index:null)}
                                        /> 
                                        <img src={CalendardIcon} onClick={() => {
                                                setShowCalendar(!showCalendar?index:null)
                                            }}/>
                                    </div>
                                    {(validate>index&& transactionError[index].prevLrsDate) && <span className={styles.errorMsg}>This Feild is required</span>}
                                    {showCalendar === index && (
                                        <div
                                        style={{
                                            marginTop:"5px",
                                            zIndex: '1000',
                                            position: 'absolute',
                                            width: '91%'
                                        }}
                                        >
                                        <DatePicker
                                            onChange={date => onChange(index, 'prevLrsDate', date)}
                                            minDate={startDate}
                                            maxDate={endDate}
                                            color='#0061D3'
                                        />
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <label>Bank/AD Name & Address</label>
                                    <div className={`${styles.inputContainer} ${validate>index&& transactionError[index].prevLrsBank && styles.errorInput}`}>
                                        <input type="text" placeholder="Enter Bank/AD Name & Address" value={item.prevLrsBank}
                                            onChange={(e)=>onChange(index, 'prevLrsBank', e.target.value)}/>
                                        {validate>index&& transactionError[index].prevLrsBank &&<img src={AlertErrorIcon} />}
                                    </div>                                
                                    {!(validate>index&& transactionError[index].prevLrsBank) ?<span className={styles.helper}>AD - Authorised Forex Dealer</span>:
                                    <span className={styles.errorMsg}>This Feild is required</span>}
                                </div>
                            </div>    
                        </div>
                        })
                        }
                        {transactions.length < 3&&<div className={styles.addTransaction} onClick={()=>setTransactions([...transactions, {prevLrsAmount: '', prevLrsBank: '', prevLrsDate: ''}])}>
                            <img src={PlusIcon}/>
                            <span>Add New Transaction</span>
                        </div>}
                    </>
                    }
                    <div className={`${styles.lrsQuestionContainer} border-0`}>
                        <div className={styles.label}>Do you want us to contact you?</div>
                        <div className="d-flex">
                            <div className={styles.option} onClick={()=>setCanContact(true)}>
                                <SelectRadioBtn key='canContact' value={canContact}/>
                                <label className={styles.label}>Yes</label>
                            </div>
                            <div className={styles.option} onClick={()=>setCanContact(false)}>
                                <SelectRadioBtn key='canContact' value={!canContact}/>
                                <label className={styles.label}>No</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.footerContainer}>
                    <button className={styles.continueBtn} onClick={onContinue}>
                        {loadingContinue&&<Spinner animation="border" className={styles.spinnerColor}/>}
                        Continue
                    </button>
                    {transferOptions && Object.keys(transferOptions).length>1 && <button className={styles.tertiaryBtn} onClick={()=>!loadingContinue&&setPage(PAGE.TRANSFER)}>Try Another Method</button>}
                </div> 
            </>}
            {subPage===SUBPAGE[OFFLINE].DETAILS && <Details />}
            {subPage===SUBPAGE[OFFLINE].REVIEW && <Review />}
            {subPage===SUBPAGE[OFFLINE].INSTRUCTION && <Instruction/>}
            {subPage===SUBPAGE[OFFLINE].TRANSACTION && <Transaction/>}
            {subPage===SUBPAGE.SUCCESS && <Success remittance={false}/>}

        </>}
    </>)
}

export default OfflineTransfer