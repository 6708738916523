import React from 'react'
import { useState } from 'react'
import { Row, Col, FormControl, Button } from 'react-bootstrap'
import styles from '../Profile.module.css'
import axios from 'axios'
import PinInput from "react-pin-input"


const ChangePinModule = props => {
	const [isVerifyPin, setisVerifyPin] = useState(true)
	const [password, setaskPassword] = useState('')
	// const [first, setFirstSpin] = useState('')
	// const [second, setSecondSpin] = useState('')
	// const [third, setThirdSpin] = useState('')
	// const [fourth, setFourthSpin] = useState('')
	const[spin, setSpin]= useState('')
	const [errorMessage, setErrorMessage] = useState('')
	const [updateSuccess, setUpdateSuccess] = useState(false)
	const [showModal, setShowModal] = useState(false)
  const ssoShown = sessionStorage.getItem('Sso_flag') ? sessionStorage.getItem('Sso_flag') : false
	const handleClose = () => {
		props.setPinModal(false)
	}

	const onPinChange = () => {
    setErrorMessage('')
		// if ((first !== '', second !== '', third !== '', fourth !== '')) {
      let update = {}
      if ((spin.length === 4 )) {
        if(ssoShown){
           update = {
            body: {
              otp: props.password,
              hashId:props.hashId,
              spin: spin
            }
          }
        }
        else{
         update = {
          body: {
            password: props.password,
            spin: spin
          }
       }
			}
			const url = `${process.env.REACT_APP_BASE_API}/v2/users/spin?source=web`
			axios
				.put(url, update.body)
				.then(function (response) {
					if (response.data.code === 200) {
						setErrorMessage('')
						setUpdateSuccess(true)
            setTimeout( () => {
              props.resetTabs('basic detail')
              props.setisVerifyPin(false)},
              2000
            )
					} else {
						setErrorMessage('Something went wrong.')
						setUpdateSuccess(false)
					}
				})
				.catch(function (error) {
					setErrorMessage('Try Again.')
				})
		} else {
			setErrorMessage('Fill the PIN')
		}
	}

	// const checkLength = e => {
	// 	if (e.target.name === 'first') {
	// 		if (e.target.value.length <= 1) {
	// 			setFirstSpin(e.target.value)
	// 		}
	// 	}
	// 	if (e.target.name === 'second') {
	// 		if (e.target.value.length <= 1) {
	// 			setSecondSpin(e.target.value)
	// 		}
	// 	}
	// 	if (e.target.name === 'third') {
	// 		if (e.target.value.length <= 1) {
	// 			setThirdSpin(e.target.value)
	// 		}
	// 	}
	// 	if (e.target.name === 'fourth') {
	// 		if (e.target.value.length <= 1) {
	// 			setFourthSpin(e.target.value)
	// 		}
	// 	}
	// }

	return (
		<>
			<div className={styles.pinHeadingMargin}>
				<Row className={styles.verifyOtpText}>Change PIN</Row>
				<Row className={styles.verifyOtpTextResponsive}>Change PIN</Row>
				<Row className={styles.ResponsiveTextEnterNewPin}>
					Enter your new PIN
				</Row>
				<Row className={styles.verifyOtpSpinResponsive}>
					we don’t ask for your PIN at any moment. so, do not share your PIN
					with anyone.
				</Row>
				<Row className={styles.enterPinText}>
					<Col>Please enter new PIN</Col>
				</Row>
				<Row className={`m-0 mt-2 ${styles.pinCenterMobile}`}>
					<Col md={8} xs={12} className={styles.responsivePadding}>
            <PinInput
              length={4}
              initialValue=""
              secret
              placeHolder="0"
              onChange={(value, index) => {setSpin(value)}}
              onPaste={(e)=>{
                e.preventDefault()
                return false;
              }} onCopy={(e)=>{
                e.preventDefault()
                return false;}}
              type="numeric"
              inputMode="number"
              inputStyle={{...style.spinInput}}
              // inputStyle={{...style.inputStyle}}
              inputFocusStyle={{background: '#EFEFF1'}}
              // onComplete={(value, index) => {setSpin(value)}}
              autoSelect={true}
              regexCriteria={/[0-9]/}
            />
						{/* <Row>
							<Col xs={2} className={styles.responsiveSpin}></Col>
							<Col md={1} xs={2} className='p-0'>
								<FormControl
									name='first'
									type='number'
									placeholder='0'
									className={styles.spinInput}
									maxLength={1}
									value={first}
									// onKeyUp={(e) => checkLength(e)}
									onChange={e => checkLength(e)}
								/>
							</Col>
							<Col md={1} xs={2} className='p-0'>
								<FormControl
									name='second'
									type='number'
									placeholder='0'
									value={second}
									className={styles.spinInput}
									onChange={e => checkLength(e)}
								/>
							</Col>
							<Col md={1} xs={2} className='p-0'>
								<FormControl
									type='number'
									name='third'
									placeholder='0'
									value={third}
									className={styles.spinInput}
									onChange={e => checkLength(e)}
								/>
							</Col>
							<Col md={1} xs={2} className='p-0'>
								<FormControl
									type='number'
									name='fourth'
									placeholder='0'
									value={fourth}
									className={styles.spinInput}
									onChange={e => checkLength(e)}
								/>
							</Col>
							<Col xs={2} className={styles.responsiveSpin}></Col>
						</Row> */}
					</Col>
				</Row>
				<p className={styles.profileErrorText}>{errorMessage}</p>
				<Row className={styles.buttonMargin}>
					<Button
						className={`${styles.pinsaveButton} themeButton`}
						style={{ backgroundColor: props.buttonColor }}
						onClick={onPinChange}>
						{updateSuccess ? 'PIN Updated' : 'Change PIN'}
					</Button>
				</Row>
        <div className={styles.updateBox}>
          {updateSuccess ? 'PIN Updated successfully!' : ''}
        </div>
			</div>
		</>
	)
}

export default ChangePinModule

const style = {
  spinInput :{
    border: 'none',
    textAlign: 'center',
    width: '40px',
    height: '48px',
    background: '#EFEFF1',
    borderRadius: '8px',
    marginLeft: '16px',
  // },
  // inputStyle:{
    fontFamily: 'Geomanist',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: '0.6px',
    color: 'black'
  }
}
