import React, { useState,useEffect } from 'react'
import styles from '../FundTransfer/fundTransfer.module.css'
import { Row, Col } from 'react-bootstrap'
import upImage from '../../public/images/up.png'
import downImage from '../../public/images/down.png'
import Success from '../../public/images/inputSuccess.png'
import stylesheet from './Fairexpay.module.css'
import $ from 'jquery';

const FundTransfer = ({
	setcurrent,
	display,
	withdrawClicked = '',
	buttonColor,
  enterBrokerDetail,
  flow,
  initiateCapitalTransaction,
  hashId,
  disableUploadStatement
  // getPaymentStatus
}) => {
	const [drop, setDrop] = useState({ one: true, two: false, three: false })
	const handleAccordian = value => {
		value === 'one'
			? setDrop({ one: !drop.one, two: false, three: false })
			: value === 'two'
			? setDrop({ one: false, two: !drop.two, three: false })
			: setDrop({ one: false, two: false, three: !drop.three })
	}
  const payLink = initiateCapitalTransaction.payLink || '';
  //  useEffect(() => {
  //   getPaymentStatus()
  //  },[])

  useEffect(() => {
     if(!disableUploadStatement){
       $('#collapseTwo').collapse('show')
      handleAccordian('two')
     }
  },[disableUploadStatement])

	return (
		<div className={styles.card}>
			<Row
				style={{
					textAlign: 'start',
					paddingBottom: '10px',
				}}
        >
				{/* <Col md={12}>
					<img src={display.image} />
				</Col> */}
				<Col className='pl-2 pl-md-0' >
					<div className={styles.great} style={{textAlign:'start'}}>
					{display.title}
					</div>
					{/* <div className={styles.great}>{display.title2}</div>
					<div
						style={{
							marginTop: '16px',
							paddingRight: withdrawClicked ? '90px' : null,
							marginLeft: withdrawClicked ? '100px' : null
						}}
						className={styles.lrs}>
						{display.discription}
					</div> */}
				</Col>
			</Row>
			{withdrawClicked ? null : (
				<>
					<Row style={{ marginTop: '32px' ,marginBottom:'100px'}} className={styles.accordionPadding}>
						<Col>
							<div id='accordion'>

              <div
									className='card'
									style={{
										border: 'none',
										cursor: 'pointer',
										paddingBottom: '24px',
										borderBottom: '1px solid #EFEFF1',
                    // paddingTop: '24px'
									}}>
                    <Row
										className={drop.one ? styles.header : styles.other}
										style={{ cursor: 'pointer' }}>
										<Col style={{ padding: '0px' }}>
											<span>
												1. Update Bank details
											</span>
                      <img className='pl-2' src={Success}></img>
										</Col>
									</Row>
              </div>
              <div
									className='card'
									style={{
										border: 'none',
										cursor: 'pointer',
										paddingBottom: '24px',
										borderBottom: '1px solid #EFEFF1',
                    paddingTop: '24px'
									}}>
                    <Row
										className={drop.one ? styles.header : styles.other}
										style={{ cursor: 'pointer' }}>
										<Col style={{ padding: '0px' }}>
											<span>
												2. PAN & AADHAAR verification
											</span>
                      <img className='pl-2' src={Success}></img>
										</Col>
									</Row>
              </div>
              <div
									className='card'
									style={{
										border: 'none',
										cursor: 'pointer',
										paddingBottom: '24px',
										borderBottom: '1px solid #EFEFF1',
                    paddingTop: '24px'
									}}>
                    <Row
										className={drop.one ? styles.header : styles.other}
										style={{ cursor: 'pointer' }}>
										<Col style={{ padding: '0px' }}>
											<span>
												3. Digitally Sign A2 form
											</span>
                      <img className='pl-2' src={Success}></img>
										</Col>
									</Row>
              </div>

              {disableUploadStatement ?
								<div
									className='card'
									style={{
										border: 'none',
										cursor: 'pointer',
										paddingBottom: '24px',
										borderBottom: '1px solid #EFEFF1',
                    paddingTop: '24px'
									}}>
									<Row
										className={drop.one ? styles.header : styles.other}
										style={{ cursor: 'pointer' }}
										data-toggle='collapse'
										data-target='#collapseOne'
										aria-expanded='true'
										aria-controls='collapseOne'
										onClick={() => handleAccordian('one')}>
										<Col style={{ padding: '0px' }}>
											<span>
												4. Complete Payment
											</span>
                      {disableUploadStatement ? '' :
                      <img className='pl-2' src={Success}></img>}
										</Col>
										<Col style={{ textAlign: 'end', padding: '0px' }}>
											<img src={drop.one ? upImage : downImage} />
										</Col>
									</Row>

									<div
										id='collapseOne'
										class='collapse show'
										aria-labelledby='headingOne'
										data-parent='#accordion'>
										{ (payLink && payLink !== '') ? <>
                    <div className={`card-body ${styles.description}`}>
                    {`Use the below link to wire the funds.`}
                      <p>Note: This link will be valid only for next 30 minutes.</p>
										</div>

                         <button
                         style={{ backgroundColor: buttonColor }}
                         className={`btn btn-primary btn-sm ${styles.addfunds} ${stylesheet.fundTransferButton} themeButton`}>
                          <a href={payLink} target='_blank' className={styles.linkDocument}>
                            <span className={styles.addfundstext}>
                              Please click to initiate the transfer
                            </span>
                          </a>
                        </button> </>:
                        <>
                        <div className={`card-body ${styles.description}`}>
                          <p>Note: The TCS calculation, is underway. Payment link will be genrated shortly. Please check again in 30 Minutes.</p>
                        </div>
                        </>

                    }
									</div>
								</div> :
                   <div
                   className='card'
                   style={{
                     border: 'none',
                     cursor: 'pointer',
                     paddingBottom: '24px',
                     borderBottom: '1px solid #EFEFF1',
                     paddingTop: '24px'
                   }}>
                     <Row
                     className={drop.one ? styles.header : styles.other}
                     style={{ cursor: 'pointer' }}>
                     <Col style={{ padding: '0px' }}>
                       <span>
                       4. Complete Payment
                       </span>
                       <img className='pl-2' src={Success}></img>
                     </Col>
                   </Row>
               </div>
                }


                <div
									className='card'
									style={{
										border: 'none',
										cursor: 'pointer',
										paddingTop: '24px'
									}}>
									<Row
										data-toggle='collapse'
										data-target='#collapseTwo'
										aria-expanded='false'
										aria-controls='collapseTwo'
										onClick={() => handleAccordian('two')}
										style={{ whiteSpace: 'nowrap' }}
										className={drop.two ? styles.header : styles.other}>
										<Col style={{ padding: '0px' }}>
                    <span>5. Upload Bank statement</span>
										</Col>
										<Col style={{ textAlign: 'end', padding: '0px' }}>
											<img src={drop.two ? upImage : downImage} />
										</Col>
									</Row>

									<div
										id='collapseTwo'
										class='collapse'
										aria-labelledby='headingThree'
										data-parent='#accordion'>
										<div className={`card-body ${styles.description}`}>
                    You will need to upload 1 year statement including the current funds transfer
										</div>
                    <button
                      onClick={() => setcurrent(flow.STATEMENT_WAIT)}
                      type='button'
                      disabled={disableUploadStatement}
                      style={{ backgroundColor: buttonColor,height:'50px' }}
                      className={`btn btn-primary btn-sm ${styles.addfunds} ${stylesheet.fundTransferButton} themeButton`}>
                      <span className={styles.addfundstext}>
                      Upload Bank Statement
                      </span>
                    </button>
									</div>
								</div>
								<div
									className='card'
									style={{
										border: 'none',
										cursor: 'pointer',
										paddingTop: '24px'
									}}>
									<Row
										data-toggle='collapse'
										data-target='#collapseThree'
										aria-expanded='false'
										aria-controls='collapseThree'
										onClick={() => handleAccordian('three')}
										style={{ whiteSpace: 'nowrap' }}
										className={drop.three ? styles.header : styles.other}>
										<Col style={{ padding: '0px' }}>
                    <span>6. Ready to Invest</span>
											{/* <span>{check === 'other' ? '3.' : '2.'} Ready to Invest</span>  */}
										</Col>
										<Col style={{ textAlign: 'end', padding: '0px' }}>
											<img src={drop.three ? upImage : downImage} />
										</Col>
									</Row>

									<div
										id='collapseThree'
										class='collapse'
										aria-labelledby='headingThree'
										data-parent='#accordion'>
										<div className={`card-body ${styles.description}`}>
                     {'The fund transfer process typically takes 2-3 business days. You will be able to start investing once the funds have been credited to your account.'}
										</div>
									</div>
								</div>
							</div>
						</Col>
					</Row>

          {/* <Row className={styles.fixedDiv}>
				   <Col>
              <button
                onClick={() => setcurrent(flow.STATEMENT_WAIT)}
                type='button'
                disabled={disableUploadStatement}
                style={{ backgroundColor: buttonColor }}
                className={`btn btn-primary btn-sm ${styles.addfunds} themeButton`}>
                <span className={styles.addfundstext}>
                 Upload Bank Statement
                </span>
              </button>
				</Col>
			</Row> */}
				</>
			)}
		</div>
	)
}

export default FundTransfer
