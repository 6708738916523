import React, { useState, useEffect, useRef, useContext } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux'
import { Card, Row, Button, Col } from 'react-bootstrap'
import NotificationData from './NotificationData'
import styles from './Notification.module.css'
import { NotificationEmptyState } from '../Header/NavigationStates';
import { getNotification } from '../../api'
import closeIcon from '../../public/images/closeIcon.svg'
import bellBlack from '../../public/images/bellBlack.svg'
import bellGrey from '../../public/images/bellGrey.svg'
import NotificationModal from './NotificationModal'
import { SocketContext } from '../../socket-context'
import { getAllNotification, socketNotification } from '../../store/actions/index'



const Notification = props => {
  const socket = useContext(SocketContext);
  const [seeMore, setSeeMore] = useState(false)
  const [onClose, setOnClose] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [notificationData, setShowNotificationData] = useState([])
  const [sendData, setSendData] = useState(1)
  const [firstTimeNotificationCall, setFirstTimeNotificationCall] = useState(true)
  const [sendNotificationId, setSendNotificationId] = useState()

  const dispatchAction = useDispatch()

  useEffect(() => {
    setShowNotificationData(props.myNotification)
    setFirstTimeNotificationCall(false)
  },[props.myNotification])

  useEffect(() => {
    if(props.myNotification ? props.myNotification.length !== notificationData.length : ""){
    dispatchAction(socketNotification(notificationData))}
  }, [notificationData])

  useEffect(() => {
    if(seeMore){
      dispatchAction(socketNotification(notificationData))
    }
  },[notificationData])

  const sendNotificationDataToRedux = (e) => {
    dispatchAction(socketNotification(e))
  }

  const handleClose = () => {
    props.setShowMoreNotification(false)
    setShowModal(false)
    setSeeMore(false)
    props.closeNotification('close')
    const tempArray = [...notificationData]
    const newArray = []
    const y = tempArray && tempArray.length > 0 ? tempArray.forEach(function(item) {
      const tempObj = {...item, read: true}
      newArray.push(tempObj)
    }) : " "
    setShowNotificationData(newArray && newArray.length > 0 ? newArray : "")
    props.setNewNotificationArray(newArray && newArray.length > 0 ? newArray : "")
    emitSocket()
    sendNotificationDataToRedux(newArray && newArray.length > 0 ? newArray : "")
  }

  const emitSocket = () => {
    if(sendNotificationId && sendNotificationId.length > 0) {
      socket.emit('notification_read' , { notificationIDs : sendNotificationId})
    }
  }


  const seeMoreTrue = () => {
    setSeeMore(true)
    props.setShowMoreNotification(true)
  }

  useEffect(() => {
    const messageListener = (message) => {
        setShowNotificationData((prevMessages) => {
          if(prevMessages !== undefined) {
          const newMessages = [message,...prevMessages];
          return newMessages;
        } else {
          console.log('no new message')
        }
        });
    };

    if(socket) {
      socket.on("user_notification",messageListener)
    }

    return () => {
      if(socket){
          socket.off('user_notification', messageListener)
      }
    }
  }, [socket])


  useEffect(()=>{
    if(notificationData && notificationData.length  > 0){
      fetchNotificationID()
    }
  },[notificationData])

  const fetchNotificationID = () => {
    const notificationId = []
    if(notificationData && notificationData.length){
      notificationData.forEach(function (item) {
        notificationId.push(item._id);
      })
    }
    setSendNotificationId(notificationId)
  }

  const [ show, setShow ] = useState(false)

  const closeNavModal = () => {
    setShow(!show)
    const tempArray = [...notificationData]
    const newArray = []
    const y = tempArray && tempArray.length > 0 ? tempArray.forEach(function(item) {
      const tempObj = {...item, read: true}
      newArray.push(tempObj)
    }) : " "
    setShowNotificationData(newArray && newArray.length > 0 ? newArray : "")
    props.setNewNotificationArray(newArray && newArray.length > 0 ? newArray : "")
    emitSocket()
    sendNotificationDataToRedux(newArray && newArray.length > 0 ? newArray : "")
  }
    
  const notificationRef = useRef()
  useOnClickOutside(notificationRef,() => setShow(false))

  return(
    <div>
      {seeMore ?
        <NotificationModal show={seeMore} handleClose={() => handleClose()}>
          <Row className={styles.notificationHeader}>
            <Col md={6} className={styles.header}>Notifications</Col>
          </Row>
          <div className={styles.notificationModalBody}>
            <NotificationData
            data={notificationData ? notificationData : ""} />
          </div>
        </NotificationModal> :

        // smaller card
        <>
        <Dropdown className={styles.notificationDropdown} show={show} ref={notificationRef} alignRight>
            <Dropdown.Toggle id="dropdown-basic" className={styles.notificationButton} onClick={() => closeNavModal()}>
                <span className={`${props.notificationBling  ? styles.bellIconCircle : ""}`}></span>
                <img src={show ? bellBlack : bellGrey} alt='bell-icon' width="18.93px" height="22px"/>
            </Dropdown.Toggle>

            {notificationData && notificationData.length > 0 ? (
              <Dropdown.Menu>
                <Dropdown.Item>
                  <div style={{padding:"12.75px 0px 14px 16px",color:"#767C8F"}}>
                      Notifications
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item>
                  <div>
                    <NotificationData data={notificationData ? notificationData.slice(0,3) : ""}/>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => seeMoreTrue()}>
                  {notificationData && notificationData.length > 0 ?
                  <div className={styles.allNotifications}>
                    See All Notifications
                  </div> : ""}
                </Dropdown.Item>
            </Dropdown.Menu>
            ) : (
              <Dropdown.Menu>
                <NotificationEmptyState/>
              </Dropdown.Menu>
            )}
        </Dropdown>

        {/* responsvie mobile data */}

        <Card className={`${styles.notificationCard} ${styles.mobileNotification}`}>
           <Row className={styles.mobileNotificationHeader}>
            <Col md={6} sm={6} xs={6} className={styles.header}>Notifications</Col>
            <Col md={6} sm={6} xs={6} className={styles.crossImage}>
              <span>
                <img onClick={() => handleClose()} src={closeIcon} alt="close-icon"/>
              </span>
            </Col>
          </Row>
          <Card.Body className={styles.mobileCardPadding} style={{padding:"0px"}}>
            {notificationData && notificationData.length > 0 ? (
              <NotificationData data={notificationData}/>
            ) : (
               <NotificationEmptyState/>
            )}
          </Card.Body>
        </Card>
        </>
      }
    </div>
  )
}

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
        handler(event)
      }
      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    [ref, handler]
  )
}

export default Notification
