import React, {useContext,useEffect} from 'react'
import { Row,Col } from 'react-bootstrap'
import endProcessSvg from '../../../../public/images/easyfund/Password.svg'
import transactionSvg from '../../../../public/images/easyfund/Remmitance.svg'
import upiSvg from '../../../../public/images/easyfund/scan.svg'
import Lottie from 'react-lottie-player';
// import EasyFund from '../../../../public/images/EasyFund.png'
import DigiLogo from '../../../../public/images/easyfund/DigiLogo.svg'
import EasyFundLottie from '../../../../public/lottie/easyfund.json'
import styles from './easyfund.module.css'
import { AddFundContext } from "../../AddFundContext"

const SplashScreen = () => {
  const {setSubPage, PAGE, SUBPAGE,setPage,setNoClose,setNoHeader} = useContext(AddFundContext)
  const renderArray = [
    {
      heading: 'End-to-end Process',
      subHeading: 'Few clicks is all it takes.',
      img: endProcessSvg
    },
    {
      heading: 'Low Transaction Charges',
      subHeading: 'Minimal fees for every transaction.',
      img: transactionSvg
    },
    {
      heading: 'UPI & Netbanking Compatible',
      subHeading: 'Works the same for both',
      img: upiSvg
    }
  ]
  useEffect(() => {
    setNoClose(true,setNoHeader(true))

    return () =>{
      setNoClose(false,setNoHeader(false))
    }
  },[])
  return (
    <div className={styles.container}>
       <Lottie
            rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
            loop={false}
            animationData={EasyFundLottie}
            play
            style={{ width: 160, height: 160 }}
            // onComplete={()=>onComplete && onComplete()}
        />
      {/* <img src={EasyFund} alt='img' style={{ marginBottom: '23px' }} /> */}
      <div className={styles.content} style={{padding:'0px'}}>
        <div className={styles.seamHeading} style={{fontSize:'24px',lineHeight:'32px',marginBottom:'8px',paddingLeft:'20px',paddingRight:'20px'}}>
        Complete your KYC for lightning fast transfers
        </div>
        <div className={styles.splashItemSubHeading} style={{color:'#767C8F',marginBottom:'20px',textAlign:'center',paddingLeft:'20px',paddingRight:'20px'}}>
        Select your preferred KYC method. This usually takes about 2-5 minutes to complete.
        </div>
        <div className={styles.splashKycBox} style={{marginBottom:'20px'}}>
          <Row className={styles.splashItemHeading} style={{marginBottom:'8px'}}>
            <Col>Express KYC</Col>
            <Col className={styles.digiRecom}>Recommended</Col>
            <Col style={{textAlign:'end'}}><img src={DigiLogo}></img></Col>
          </Row>
          <div className={styles.subTitle} style={{marginBottom:'18px'}}>You must have a valid Aadhaar number linked to your registered mobile number.</div>
          <button className={styles.activeButton} onClick={() => setSubPage(SUBPAGE[PAGE.EASYFUND].VERIFYAADHARAUTO)}>
            <div className={styles.activateText}>Enter Aadhaar Number</div>
          </button>
        </div>
        <div className={styles.splashKycBox}>
          <div className={styles.splashItemHeading} style={{marginBottom:'8px'}}>Manual KYC</div>
          <div className={styles.subTitle} style={{marginBottom:'18px'}}>Aadhaar not linked to mobile? Upload ORIGINAL images of your Aadhaar Card. Your Name and Date of Birth on your profile should match your Aadhaar.</div>
          <button className={styles.secondaryBtn}  onClick={() => setSubPage(SUBPAGE[PAGE.EASYFUND].VERIFYAADHAR)}>
            <div className={styles.activateText} style={{color:'#0061D3'}}>Upload Aadhaar Card</div>
          </button>
        </div>
      </div>
      <div className={styles.lowerText} style={{color:'#0061D3',marginTop:'10px'}} onClick={()=>setPage(PAGE.TRANSFER)}>I’ll Do It Later</div>
      {/* <div className={styles.content}>
        <div className={styles.seamHeading}>
          Seamlessly transfer funds with Easyfund
        </div>

        <div className={styles.renderArray}>
          {renderArray.map(item => {
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <div style={{ marginRight: '17px' }}>
                  <img src={item?.img} alt='img-svg' />
                </div>
                <div>
                  <div className={styles.splashItemHeading}>
                    {item?.heading}
                  </div>
                  <div className={styles.splashItemSubHeading}>
                    {item?.subHeading}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <div className={styles.lowerSection}>
          <button className={styles.activeButton} onClick={() => setSubPage(SUBPAGE[PAGE.EASYFUND].VERIFYAADHAR)}>
            <div className={styles.activateText}>Activate Now</div>
          </button>
          <div className={styles.lowerText} onClick={()=>setPage(PAGE.TRANSFER)}>I’ll Do It Later</div>
        </div>
      </div> */}
    </div>
  )
}

export default SplashScreen
