import React, { useState } from 'react'
import styles from './fundTransfer.module.css'
import { Row, Col,Spinner } from 'react-bootstrap'
import {getLrsDoc} from '../../api'
import upImage from '../../public/images/up.png'
import downImage from '../../public/images/down.png'
import { download, infoError } from '../lrsForm/assets'
const FundTransfer = ({
	setModalData,
	data,
	display,
	withdrawClicked = '',
	buttonColor,
  enterBrokerDetail,
  check,
  lrsLoading
}) => {
	const [drop, setDrop] = useState({ one: true, two: false, three: false })
  const [errorMessage,seterrorMessage] = useState('')
  const [loading,setLoading] = useState(false)
  const [counter, setCounter] = useState(0)
  const [error, setError] = useState(false)
	const handleAccordian = value => {
		value === 'one'
			? setDrop({ one: !drop.one, two: false, three: false })
			: value === 'two'
			? setDrop({ one: false, two: !drop.two, three: false })
			: setDrop({ one: false, two: false, three: !drop.three })
	}

  const downloadReport = async() => {
	  if(!loading && counter <3)
	  {
		setError(false)
		setLoading(true)
		let fileKey = enterBrokerDetail && enterBrokerDetail.requestId ? enterBrokerDetail.requestId : ''
		let response = await getLrsDoc(fileKey);
		if(response.status === 200){
		 let  href = response.docs.url;
		 let element = document.createElement('a');
		 element.setAttribute('href', href);
		 element.setAttribute('download', '');
		 element.setAttribute('target', '_blank');
	
		 element.style.display = 'none';
		 document.body.appendChild(element);
	
		 element.click();
	
		 document.body.removeChild(element);
		 setCounter(0)
		  }
		  else{
			seterrorMessage('Something went wrong')
			setError(true)
			setCounter(counter+1)
		  }
		  setLoading(false)
	  }    
  }

	return (
		<div className={styles.card}>
			<Row
				style={{
					textAlign: 'center',
					paddingBottom: '32px',
					borderBottom: '1px solid #EFEFF1'
				}}>
				<Col md={12}>
					<img src={display.image} />
				</Col>
				<Col>
					<div style={{ marginTop: '24px' }} className={styles.great}>
						{display.title}
					</div>
					<div className={styles.great}>{display.title2}</div>
					<div
						style={{
							marginTop: '16px',
							paddingRight: withdrawClicked ? '90px' : null,
							marginLeft: withdrawClicked ? '100px' : null
						}}
						className={styles.lrs}>
						{display.discription}
					</div>
				</Col>
			</Row>
			{withdrawClicked ? null : (
				<>
					<Row style={{ marginTop: '32px' ,marginBottom:'100px'}} className={styles.accordionPadding}>
						<Col>
							<div id='accordion'>
               <div
									className='card'
									style={{
										border: 'none',
										cursor: 'pointer',
										paddingBottom: '24px',
										borderBottom: '1px solid #EFEFF1'
									}}>
									<Row
										className={drop.one ? styles.header : styles.other}
										style={{ cursor: 'pointer' }}
										data-toggle='collapse'
										data-target='#collapseOne'
										aria-expanded='true'
										aria-controls='collapseOne'
										onClick={() => handleAccordian('one')}>
										<Col style={{ padding: '0px' }}>
											<span>
												{display.title === ` Wire instructions`
													? '1. Download the wire instructions generated'
													: '1. Download the form'}
											</span>
										</Col>
										<Col style={{ textAlign: 'end', padding: '0px' }}>
											<img src={drop.one ? upImage : downImage} />
										</Col>
									</Row>

									<div
										id='collapseOne'
										className='collapse show'
										aria-labelledby='headingOne'
										data-parent='#accordion'>
										<div
											className={`card-body ${styles.description}`}
											style={{
												padding: '0px',
												paddingRight: '8px',
												paddingTop: '8px'
											}}>
											<div>
												<div>
													{`These contain details of the brokerage account
                           into which you will have to send money`}
												</div>
                        {lrsLoading || !enterBrokerDetail.pdfUrl  ? '' :
												<div className={styles.download} style={{width:'fit-content'}}>
                          {/* <a href={enterBrokerDetail && enterBrokerDetail.pdfUrl ? enterBrokerDetail.pdfUrl : ''} target="_blank" className={styles.linkDocument}> */}
													<div className={styles.downloadText} onClick={downloadReport}>
														{!loading ? 
															<img src={!error?download:infoError} style={{height:'fit-content'}}/>
															:<Spinner animation="border" className={styles.spinnerColor} style={{width:'20px',height:'20px', margin:'2px'}}/>
														}
														&nbsp;
														{counter<3?
															(!error ? 
																<div className='d-flex align-items-center'>{check === 'other' ? 'Download Instructions' : 'Download Form'}</div>
																:<div className='d-flex align-items-center'>
																	<div style={{color:'#DC6803', paddingRight:'6px'}}>Unable To Fetch</div>
																	<div>Try Again</div>
																</div>        
															)
															:<div className={styles.instructionError} onClick={()=>window.fcWidget.open()}>
																<div style={{color:'#DC6803', paddingRight:'6px'}}>Something Went Wrong</div>
																<div>Get Help</div>
															</div>
														}
													</div>														
												</div> }
											</div>
										</div>
									</div>
								</div>
                {check === 'other' ?
								<div
									className='card'
									style={{
										border: 'none',
										cursor: 'pointer',
										paddingBottom: '24px',
										paddingTop: '24px',
										borderBottom: '1px solid #EFEFF1'
									}}>
									<Row
										data-toggle='collapse'
										data-target='#collapseTwo'
										aria-expanded='false'
										aria-controls='collapseTwo'
										onClick={() => handleAccordian('two')}
										className={drop.two ? styles.header : styles.other}>
										<Col style={{ padding: '0px' }}>
											<span>2. Visit Bank</span>
										</Col>
										<Col style={{ textAlign: 'end', padding: '0px' }}>
											<img src={drop.two ? upImage : downImage} />
										</Col>
									</Row>

									<div
										id='collapseTwo'
										class='collapse'
										aria-labelledby='headingTwo'
										data-parent='#accordion'>
										<div className={`card-body ${styles.description}`}>
										Visit your bank branch and submit the printed copies after signing the downloaded documents.
										</div>
									</div>
								</div> :
                <div
									className='card'
									style={{
										border: 'none',
										cursor: 'pointer',
										paddingBottom: '24px',
										paddingTop: '24px',
										borderBottom: '1px solid #EFEFF1'
									}}>
									<Row
										data-toggle='collapse'
										data-target='#collapseTwo'
										aria-expanded='false'
										aria-controls='collapseTwo'
										onClick={() => handleAccordian('two')}
										className={drop.two ? styles.header : styles.other}>
										<Col style={{ padding: '0px' }}>
											<span>2. Deposit the form at Bank </span>
										</Col>
										<Col style={{ textAlign: 'end', padding: '0px' }}>
											<img src={drop.two ? upImage : downImage} />
										</Col>
									</Row>

									<div
										id='collapseTwo'
										class='collapse'
										aria-labelledby='headingTwo'
										data-parent='#accordion'>
										<div className={`card-body ${styles.description}`}>
                    If you have chosen us to pick up the form, we will be reaching out to you within next 24 hours.
										</div>
									</div>
								</div>}
								<div
									className='card'
									style={{
										border: 'none',
										cursor: 'pointer',
										paddingTop: '24px'
									}}>
									<Row
										data-toggle='collapse'
										data-target='#collapseThree'
										aria-expanded='false'
										aria-controls='collapseThree'
										onClick={() => handleAccordian('three')}
										style={{ whiteSpace: 'nowrap' }}
										className={drop.three ? styles.header : styles.other}>
										<Col style={{ padding: '0px' }}>
											<span>3. Ready to Invest</span>
										</Col>
										<Col style={{ textAlign: 'end', padding: '0px' }}>
											<img src={drop.three ? upImage : downImage} />
										</Col>
									</Row>

									<div
										id='collapseThree'
										class='collapse'
										aria-labelledby='headingThree'
										data-parent='#accordion'>
										<div className={`card-body ${styles.description}`}>
                     The fund transfer process typically takes 2-5 business days. You will be able to start investing once the funds have been credited to your account.
										</div>
									</div>
								</div>
							</div>
						</Col>
					</Row>
					<Row className={styles.fixedDiv}>
						<Col className='pl-0'>
							<button
								type='button'
								style={{ backgroundColor: buttonColor }}
								className={`btn btn-primary btn-sm ${styles.addfunds} themeButton`}
								onClick={() => setModalData(data)}>
								<span className={styles.addfundstext}>Transfer Complete</span>
							</button>
						</Col>
					</Row>
				</>
			)}
		</div>
	)
}

export default FundTransfer
