// Trade

const STACK_DELISTED_MSG = "This holding is available for only liquidation.";

// stack-buy-error
const MIN_INV_MSG = "Amount must be at least minimun investment"
const CASH_AVAIL_BEFORE_TRADE_MSG = "You don’t have enough funds"

// stack-sell-error
const SELL_ALREADY_IN_PROGRESS = 'Sell Order Already in Queue.'
const SELL_REBALANCING = 'Stack Rebalancing in Progress. You cannot sell now'

// spin-pin-error
const SPIN_INVALID_MSG = "Please enter valid pin";

// Empty Portfolio Messages
const EMPTY_HEAD_PF_MSG = 'Start Investing'
const EMPTY_SUB_PF_MSG = 'Create your global portfolio'

// Withdraw
const WITH_AMOUNT_AVAIL_MSG = 'Available cash balance is less than requested amount';

const BLANK_FIELD_ERR_MSG = 'Fields cannot be left empty.'

const STACK_NOT_FOUND_HEAD = 'Oops! The page you were looking for is not listed in Stockal.'
const STACK_NOT_FOUND_SUB = 'You might have mistyped the URL or the page may have been removed'
const SOMETHING_WENT_WRONG_HEAD = 'Something Went Wrong'
const SOMETHING_WENT_WRONG_SUB = 'Try again, we will be right here'

const PLAN_EXPIRATION_MSG = 'Your plan has expired. Please ';
const PLAN_SUBSCRIPTION_MSG = 'Please subscribe to the plan to continue.'
const INSUFFICIENT_AMOUNT_TRADE_MSG = 'oops! you do not have sufficient funds to execute the order.'

export {
  MIN_INV_MSG,
  CASH_AVAIL_BEFORE_TRADE_MSG,
  SPIN_INVALID_MSG,
  EMPTY_HEAD_PF_MSG,
  EMPTY_SUB_PF_MSG,
  WITH_AMOUNT_AVAIL_MSG,
  BLANK_FIELD_ERR_MSG,
  STACK_NOT_FOUND_HEAD,
  STACK_NOT_FOUND_SUB,
  SELL_ALREADY_IN_PROGRESS,
  SELL_REBALANCING,
  SOMETHING_WENT_WRONG_HEAD,
  SOMETHING_WENT_WRONG_SUB,
  PLAN_EXPIRATION_MSG,
  PLAN_SUBSCRIPTION_MSG,
  INSUFFICIENT_AMOUNT_TRADE_MSG,
  STACK_DELISTED_MSG
}
