import React, { useState, useEffect, useContext } from 'react'
import { Row, Col, Card,OverlayTrigger,Tooltip } from 'react-bootstrap'
import styles from './PopularStock.module.css'
import { CardChart } from '../index'
import { Link } from 'react-router-dom'
import { isNumber } from 'lodash'
import { calPriceChange_New, addCommasinPrice } from '../../utils'
import { SocketContext } from '../../socket-context'

const PopularStock = ({
	stockImage,
	stockTitle,
	stockName,
	stockValue,
	stockIncrement,
	increment,
  stockType='',
  stockChange = '',
  close
}) => {
	const socket = useContext(SocketContext)
  const [isLoaded, setIsloaded] = useState(true)
  const [priceTicker, setPriceTicker] = useState({stockTitle, currentPrice: stockValue})

  let currentPrice;

  useEffect(() => {

    const priceListener = (priceState) => {
    //   let data = JSON.parse(priceState)
      let { Symbol,price}  = priceState;
      stockValue = price && price;
      currentPrice = parseFloat(stockValue);
    setPriceTicker((prevPrice) => {
        let priceChange = currentPrice - prevPrice.currentPrice
        return {
          stockTitle: Symbol,
          currentPrice,
          prevPrice: prevPrice.currentPrice,
          priceChange,
          changeColor: prevPrice.currentPrice < currentPrice ?  '#13ae48' : '#e61446'
        }
      })
    }

    if(socket) {
		socket.emit('join_symbol', stockTitle);
      socket.on(stockTitle, priceListener)
    }

    return () => {
      if(socket){
        socket.off(stockTitle, priceListener)
      }
    }
  }, [socket])

  let priceBoxColor = priceTicker.changeColor;

  useEffect(() => {
    const pricesColors = setInterval(() => {
      priceBoxColor = '';
    }, 1);

    return () => {
      clearInterval(pricesColors);
    }
  }, [priceBoxColor])

  let {change, changePer} = calPriceChange_New({
    currPrice: priceTicker.currentPrice,
    close,
   });

   stockChange = change
   stockIncrement = changePer
   increment = stockIncrement > 0 ? true : false

	return (
    <Link to={stockType === 'etf' ? `/etfdetail/${stockTitle}` :`/stockdetail/${stockTitle}`} style={{ textDecoration: 'none',width:'100%'}}>
		<Card className={styles.trendingCard}>
			<Card.Body className={styles.cardBody}>
				<Row>
					<Col md={3} xs={2}>
						<div className={styles.imageDiv}>
							{stockImage && isLoaded ? (
								<img
									onError={() => {
										setIsloaded(false)
									}}
									className={styles.stockImage}
									src={stockImage}
									alt={stockTitle.substring(0, 1)}></img>
							) : (
								<h3 className={`${styles.imagePadding} text-center`}>
									{stockTitle ? stockTitle.substring(0, 1) : null}
								</h3>
							)}
						</div>
					</Col>
					<Col md={4} xs={6} className={styles.titleMargin}>
						<div className={styles.stockTitle}>{stockTitle}</div>
            <OverlayTrigger
									placement='bottom'
                  trigger={['hover', 'focus','click']}
									overlay={
										<Tooltip>
										{stockName}
										</Tooltip>
									}>
										<div className={styles.stockName}>{stockName}</div>
								</OverlayTrigger>
					</Col>
					{/* <Col md={4} xs={2}>
						<div className={`${styles.stockValue} mb-1`}>
							{`$${stockValue}`}
						</div>
						<div
							className={styles.stockIncrement}
							style={{
								color: `${increment ? '#13b948' : '#E61446'}`
							}}>
							{stockIncrement > 0 ? `+${stockIncrement}` : stockIncrement}%
						</div>
					</Col> */}
          <Col md={5} xs={4} className={styles.leftPaddingRemove}>
              <div
                className={`${styles.stockValue} mb-1`}
                // style={{backgroundColor: priceBoxColor}}
              >
								{/* {`$${stockValue}`} */}
                {
                	`$${addCommasinPrice(typeof priceTicker.currentPrice === 'number' ? priceTicker.currentPrice.toFixed(2) : priceTicker.currentPrice)}`
                }
							</div>
							<div
								className={styles.stockIncrement}
								style={{
									color: `${increment ? '#13b948' : '#E61446'}`
								}}>
								{stockChange
									? stockChange < 0
										? stockIncrement > 0
											? `-$${stockChange
													.toString()
													.substring(1)}[+${stockIncrement.toFixed(2)}%]`
											: `-$${stockChange
													.toString()
													.substring(1)}[${stockIncrement.toFixed(2)}%]`
										: stockIncrement > 0
										? `$${stockChange}[+${stockIncrement.toFixed(2)}%]`
										: `$${stockChange}[${stockIncrement.toFixed(2)}%]`
									: stockIncrement > 0
									? `+${stockIncrement}%`
									: `${stockIncrement}%`}
							</div>
						</Col>
					{/* <Col>
						<div>jayesh</div>
					</Col> */}
				</Row>
			</Card.Body>
		</Card>
    </Link>
	)
}

export default PopularStock
