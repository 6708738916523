import React, { useState, useEffect } from 'react'
import styles from './transferComplete.module.css'
import { Row, Col } from 'react-bootstrap'
import backImage from '../../public/images/back.png'
import { putTransaction } from '../../api'

const TransferComplete = ({
	setModalData,
	data,
	back,
	hashId,
	buttonColor
}) => {
	const [transferValue, setTransferValue] = useState('')
	const [check, setCheck] = useState(true)
  const [loading,setLoading] = useState(false)
  const [errorMessage,setErrorMessage] = useState('')

	useEffect(() => {
		transferValue !== '' ? setCheck(false) : setCheck(true)
	}, [transferValue])

	const asynchFetch = async () => {
    setLoading(true)
    try{
		const res = hashId && (await putTransaction(hashId, transferValue))
    if(res && res.code === 200){
		 setModalData(data)
    }else{
      setErrorMessage('Something went Wrong')
    }
    }catch(error){
      setErrorMessage('Something Went Wrong')
    }
   setLoading(false)
	}

  const keyPressHandler = (event) => {
    if(event.which === 13){
     asynchFetch()
    }
   }

	return (
		<div className={styles.card}>
			<Row
        className={styles.rowDiv}
				>
				<Col md={12}>
          <div onClick={() => setModalData(back)}>
					<img src={backImage} style={{cursor:'pointer'}} />
					<span
						className={styles.title}
            style={{cursor:'pointer'}}
						>
						{`Transfer Complete`}
					</span>
          </div>
				</Col>
			</Row>
			<Row style={{ marginTop: '32px' }}>
				<Col md={12}>
					<div className={styles.reference}>Reference / Transaction Number</div>
				</Col>
				<Col style={{ marginTop: '4px' }}>
					<input
						className={styles.inputNew}
						placeholder='Enter Reference / Transaction Number'
						onChange={e => setTransferValue(e.target.value)}
            onKeyPress={keyPressHandler}
					/>
				</Col>
			</Row>
      {errorMessage === '' ? '' : <div className={styles.erorrMessage}>{errorMessage}</div>}
			<Row className={styles.fixedDiv}>
				<Col>
					<button
						onClick={() => asynchFetch()}
						type='button'
						style={{ backgroundColor: buttonColor }}
						disabled={check || loading}
						className={`btn btn-primary btn-sm ${styles.addfunds} themeButton`}>
						<span className={styles.addfundstext}>All Done</span>
					</button>
				</Col>
			</Row>
		</div>
	)
}

export default TransferComplete
