import { initialState } from '../initialState'
import { MARKET_DETAILS } from '../actions/action'

const marketDetails = (state = initialState.marketDetails, action) => {
	switch (action.type) {
		case MARKET_DETAILS: {
			return { ...state, marketDetails: action.payload }
		}
		default: {
			return state
		}
	}
}

export default marketDetails
