import React,{useState, useEffect, useContext} from 'react'
import {Card,Row,Col} from 'react-bootstrap'
import styles from './ExploreStocksEtf.module.css'
import {Link} from 'react-router-dom'
import {decimalAdjust,parseNumericMillion,addCommasinPrice, calPriceChange_New}  from '../../utils'
import { SocketContext } from '../../socket-context'

const ExploreStocksMobile = ({
  logo,code,company,rating,price,change,changePercentage,stockType, close,shariahStatus,shariahRating,userLocation
}) => {
  const socket = useContext(SocketContext);
  const [ shariahStatusText, setShariahStatusText] = useState()
  const [isLoaded, setIsloaded] = useState(true)
  const [priceTicker, setPriceTicker] = useState({code, currentPrice: price})
  const link = stockType === 'etf' ? 'etfdetail' : 'stockdetail'

  const setShariahData = () => {
    if(shariahStatus) {
        if(shariahStatus === 'COMPLIANT'){
            setShariahStatusText(`${shariahRating}-Star Halal`)
        } else if(shariahStatus === 'NON_COMPLIANT') {
            setShariahStatusText('Not Halal')
        } else if(shariahStatus === 'QUESTIONABLE') {
            setShariahStatusText('Doubtful')
        } else if(shariahStatus === 'NOT_UNDER_COVERAGE') {
            setShariahStatusText('Not Covered')
        } 
    } else {
        setShariahStatusText('Not Covered')

    }
  }

  useEffect(()=>{
    if(userLocation === 'MENA') {
      setShariahData()
    }
  },[shariahRating])

  let currentPrice;

  useEffect(() => {

    const priceListener = (priceState) => {
      // let data = JSON.parse(priceState)
      let { Symbol,price}  = priceState;
      price = price && price;
      currentPrice = parseFloat(price);
    setPriceTicker((prevPrice) => {
        let priceChange = currentPrice - prevPrice.currentPrice
        return {
          code: Symbol,
          currentPrice,
          prevPrice: prevPrice.currentPrice,
          priceChange,
        }
      })
    }

    if(socket) {
      socket.emit('join_symbol', code);
      socket.on(code, priceListener)
    }

    return () => {
      if(socket){
        socket.off(code, priceListener)
      }
    }
  }, [socket])
  let changePer;

  ({change, changePer} = calPriceChange_New({
    currPrice: priceTicker.currentPrice,
    close,
   }));


  changePercentage = changePer;
  let increment = changePercentage > 0 ? true : false
  const calculateRating = (value) => {
    let tempValue = '--'
    let color = ''
    if( value > 0.0 && value <= 1.5){
      tempValue = 'Strong Buy'
      color = '#0DC014'
     }
     else if(value > 1.5 && value <= 2.5){
      tempValue = 'Buy'
      color=''
     }
     else if(value > 2.5 && value <= 3.5){
      tempValue = 'Hold'
      color='#1E1F24'
     }
     else if(value > 3.5  && value <= 4.5){
      tempValue = 'Sell'
      color=''
     }
     else if(value > 4.5){
      tempValue = 'Strong Sell'
      color=''
     }
     let item = <>
     <div className={styles.ratingColor} style={{backgroundColor:color}}></div><span>{tempValue}</span>
     </>
     return item
  }

   return(
    <Link to={`/${link}/${code}`} className={styles.linkStyle}>
     <Card className={styles.exploreCard}>
       <Card.Body className={styles.cardBody}>
         <Row>
         <Col xs={2}>
							<div className={styles.imageDiv}>
								{logo && isLoaded ? (
									<img
										onError={() => {
											setIsloaded(false)
										}}
										className={styles.stockImage}
										src={logo}
										alt={code.substring(0, 1)}></img>
								) : (
									<h3 className={`text-center ${styles.imagePaddingRemove}`}>
										{code.substring(0, 1)}
									</h3>
								)}
							</div>
						</Col>
           <Col xs={5}>
						<div className={styles.stockTitle}>{code}</div>
						{userLocation === 'MENA' ? <div className={styles.mobileRating}>{shariahStatusText}</div> : <div className={styles.mobileCompanyName}>{company.length > 8 ? `${company.substring(0,8)}...` : company}</div>}
					</Col>
          <Col xs={5}  style={{paddingLeft:'0px'}}>
          <div className={styles.mobilePrice}>{`$${addCommasinPrice(decimalAdjust('floor',priceTicker.currentPrice,-2))}`}</div>
          <div
            className={styles.changeColor}
            style={{
              color: `${increment ? '#0DC014' : '#FA274A'}`
            }}
            >{`$${change.toString().replace('-','')} [${changePercentage.toString().replace('-','')}%] `}<span style={{color:"#767C8F"}}>1D</span></div>
          </Col>
         </Row>
       </Card.Body>
     </Card>
     </Link>
   )
}

export default ExploreStocksMobile
