import { initialState } from '../initialState'
import { USER_INFO } from '../actions/action'

const userInfo = (state = initialState.userInfo, action) => {
	switch (action.type) {
		case USER_INFO:
			return { ...state, userInfo: action.payload }

		default:
			return state
	}
}

export default userInfo
