import React,{useState} from 'react'
import styles from './AccountTransfer.module.css'
import { Tabs, Tab, Container } from 'react-bootstrap'
import AccountTransfer from './AccountTransfer'
import Header from "../../Header/Header";
import { useEffect } from 'react'
import AccountStatus from './AccountStatus';
import { getAcatStatus } from "../../../api";


const AccountTabs = () => {
    const [slideeffect, setslideeffect] = useState(false)
    const [callstatusApi, setCallStatusApi] = useState(false);
    const [selectedEventKey, setSelectedKey] = useState('account transfer');
    const [statusData, setStatusData] = useState([]);

    useEffect(() =>{
        const acatStatus = async() => {
            const data = await getAcatStatus();
            setStatusData(data.data);
        }
        acatStatus();
    },[callstatusApi]);

    const handleTabSelect = (eventKey) => {
        if (eventKey === 'status acat') {
            setCallStatusApi(true)
            setSelectedKey('status acat')
        }else{
            setCallStatusApi(false)
            setSelectedKey('account transfer')
        }
    };

    return(
        <>
            <Header />
            <Container className={slideeffect ? styles.shiftPos : styles.originalPos}>
            <Tabs id='uncontrolled-tab-example' className="mt-5" activeKey={selectedEventKey} onSelect={handleTabSelect}>
					<Tab eventKey='account transfer' title='Account Transfer'>
                        <AccountTransfer setSelectedKey={setSelectedKey} 
                        setCallStatusApi={setCallStatusApi} 
                        statusData={statusData}/>
					</Tab>
					<Tab eventKey='status acat' title='Account Transfer Status' >
						<AccountStatus callstatusApi={callstatusApi} statusData={statusData}/>
                    </Tab>
            </Tabs>
            </Container>
        </>
    )
}

export default AccountTabs