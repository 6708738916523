import React from 'react'
import { useState, useContext,useEffect } from 'react'
import { Row, Col, FormControl, Button } from 'react-bootstrap'
import VerifyPin from './VerifyPin'
import styles from '../Profile.module.css'
import { ThemeContext } from '../../../Context/ThemeContext'
import axios from 'axios'
import OtpModal from '../../CustomModal/OtpModal'
import ResponsiveProfileModal from '../../CustomModal/ResponsiveModalProfile'
import ChangePinModule from './ChangPinModule'
import BackArrow from '../../../public/images/BackArrow.svg'

const ChangePin = props => {
	const [isVerifyPin, setisVerifyPin] = useState(false)
	const [password, setaskPassword] = useState('')
	const [first, setFirstSpin] = useState('')
	const [second, setSecondSpin] = useState('')
	const [third, setThirdSpin] = useState('')
	const [fourth, setFourthSpin] = useState('')
	// const[spin, setSpin]= useState('')
	const [errorMessage, setErrorMessage] = useState('')
	const [updateSuccess, setUpdateSuccess] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [value, setValue] = useState()
	const { buttonColor } = useContext(ThemeContext)

	const handleClose = () => {
		props.setPinModal(false)
		// setShowModal(false)
	}

	const checkLength = e => {
		if (e.target.name === 'first') {
			if (e.target.value.length <= 1) {
				setFirstSpin(e.target.value)
			}
		}
		if (e.target.name === 'second') {
			if (e.target.value.length <= 1) {
				setSecondSpin(e.target.value)
			}
		}
		if (e.target.name === 'third') {
			if (e.target.value.length <= 1) {
				setThirdSpin(e.target.value)
			}
		}
		if (e.target.name === 'fourth') {
			if (e.target.value.length <= 1) {
				setFourthSpin(e.target.value)
			}
		}
	}

	const handleRefresh = () => {
    props.resetTabs('basic detail')
    setisVerifyPin(false)
		// window.location.reload()
	}

	return isVerifyPin ? (
		<>
			<div className={styles.PinModuleLaptop}>
				<ChangePinModule password={password} buttonColor={buttonColor} resetTabs={props.resetTabs} setisVerifyPin={setisVerifyPin} hashId={props.hashId}/>
			</div>
			<div className={styles.ResponsiveModalContainer}>
				<div>
					<img onClick={handleRefresh} src={BackArrow} />
				</div>
				<ChangePinModule password={password} buttonColor={buttonColor} resetTabs={props.resetTabs} setisVerifyPin={setisVerifyPin} hashId={props.hashId} />
			</div>
			{/* <div className="mt-5">
				<Row className={styles.verifyOtpText}>Change PIN</Row>
				<Row className={styles.mobileNote}>Please enter new PIN</Row>
				<Row className='m-0 mt-2'>
					<Col md={8} xs={12} className={styles.responsivePadding}>
						<Row>
							<Col xs={2} className={styles.responsiveSpin}></Col>
							<Col md={1} xs={2} className='p-0'>
								<FormControl
									name="first"
									type='number'
									placeholder='0'
									className={styles.spinInput}
									maxLength={1}
									value={first}
									// onKeyUp={(e) => checkLength(e)}
									onChange={(e) => checkLength(e)}
								/>
							</Col>
							<Col md={1} xs={2} className='p-0'>
								<FormControl
									name="second"
									type='number'
									placeholder='0'
									value={second}
									className={styles.spinInput}
									onChange={(e) => checkLength(e)}
								/>
							</Col>
							<Col md={1} xs={2} className='p-0'>
								<FormControl
									type='number'
									name="third"
									placeholder='0'
									value={third}
									className={styles.spinInput}
									onChange={(e) => checkLength(e)}
								/>
							</Col>
							<Col md={1} xs={2} className='p-0'>
								<FormControl
									type='number'
									name="fourth"
									placeholder='0'
									value={fourth}
									className={styles.spinInput}
									onChange={(e) => checkLength(e)}
								/>
							</Col>
							<Col xs={2} className={styles.responsiveSpin}></Col>
						</Row>
					</Col>
				</Row>
				<p className={styles.profileErrorText}>{errorMessage}</p>
				<Row className={styles.buttonMargin}>
					<Button
						className={styles.pinsaveButton}
						onClick={onPinChange}>
						{updateSuccess ? "PIN Updated" : "Change PIN"}
					</Button>
				</Row>
				</div> */}

			{/* RESPONSIVE CHANGE PIN */}

			{/* <div>
					 <div className={styles.ResponsiveModalContainer}>
						<div className="mt-5">
						<Row className={styles.verifyOtpText}>Change PIN</Row>
						<Row className={styles.mobileNote}>Please enter new PIN</Row>
						<Row className='m-0 mt-2'>
							<Col md={8} xs={12} className={styles.responsivePadding}>
								<Row>
									<Col xs={2} className={styles.responsiveSpin}></Col>
									<Col md={1} xs={2} className='p-0'>
										<FormControl
											name="first"
											type='number'
											placeholder='0'
											className={styles.spinInput}
											maxLength={1}
											value={first}
											// onKeyUp={(e) => checkLength(e)}
											onChange={(e) => checkLength(e)}
										/>
									</Col>
									<Col md={1} xs={2} className='p-0'>
										<FormControl
											name="second"
											type='number'
											placeholder='0'
											value={second}
											className={styles.spinInput}
											onChange={(e) => checkLength(e)}
										/>
									</Col>
									<Col md={1} xs={2} className='p-0'>
										<FormControl
											type='number'
											name="third"
											placeholder='0'
											value={third}
											className={styles.spinInput}
											onChange={(e) => checkLength(e)}
										/>
									</Col>
									<Col md={1} xs={2} className='p-0'>
										<FormControl
											type='number'
											name="fourth"
											placeholder='0'
											value={fourth}
											className={styles.spinInput}
											onChange={(e) => checkLength(e)}
										/>
									</Col>
									<Col xs={2} className={styles.responsiveSpin}></Col>
								</Row>
							</Col>
						</Row>
						<p className={styles.profileErrorText}>{errorMessage}</p>
						<Row className={styles.buttonMargin}>
							<Button
								className={styles.pinsaveButton}
								onClick={onPinChange}>
								{updateSuccess ? "PIN Updated" : "Change PIN"}
							</Button>
						</Row>
						</div>

					</div>
				</div> */}
		</>
	) : (
		<>
			<div className={styles.changePinOtp}>
				<VerifyPin
					setisVerifyPin={setisVerifyPin}
					askPassword={setaskPassword}
					buttonColor={buttonColor}
          hashId={props.hashId}
          ></VerifyPin>
			</div>

			{/* <div className={styles.changePinOtpResponsive}>
					<OtpModal show={props.pinModal}
					 handleClose={handleClose}
					 >
						<VerifyPin
							setisVerifyPin={setisVerifyPin}
							askPassword={setaskPassword}>
						</VerifyPin>
					</OtpModal>
				</div> */}
			<div className='container'>
				<div className={styles.responsivePinContainerBackground}></div>
				<div className={styles.ResponsiveChangePinOtpContainer}>
					<div>
						<img onClick={handleRefresh} src={BackArrow} />
					</div>
					<VerifyPin
						setisVerifyPin={setisVerifyPin}
						askPassword={setaskPassword}
						buttonColor={buttonColor}
            hashId={props.hashId}></VerifyPin>
				</div>
			</div>
		</>
	)
}

export default ChangePin
