import React, { useEffect, useState, useContext } from 'react'
import { init } from 'onfido-sdk-ui';
import { createApplicant, getSdkToken, identityInitiateUpload,identityDocIndian } from '../../api'
import { handleErrorResponse } from '../../errorResponse'
import AlertError from '../AlertError/AlertError'
import { ThemeContext } from '../../Context/ThemeContext'
import styles from './Document.module.css'
import IDSelection from './IDSelection'
import AadharPanSelection from './AadharPanSelection'
import AddressSelection from './AddressSelection'
import DocumentSkeleton from '../../skeletons/DocumentSkeleton'
import { Col, Row } from 'react-bootstrap'

const Document = props => {
  const [serverError, setServerError] = useState('')
  const { buttonColor,location } = useContext(ThemeContext)
  const [loading, setLoading] = useState(false)
  // let userData = {}
  // if(props.location && props.location.state){
  //   userData = props.location.state
  // }else{
  const userData = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : ''
  // }

  const [firstName] = useState(userData.firstName ? userData.firstName : '')
  const [lastName] = useState(userData.lastName ? userData.lastName : '')
  const [email] = useState(userData.emailAddress1 ? userData.emailAddress1 : '')
  const [countryOfResidence] = useState(userData.country ? userData.country : 'IND')
  const [phoneNumber] = useState(userData.phoneHome ? userData.phoneHome : '')
  const [showSelection, setShowSelection] = useState('flex')
  const [showAddress, setShowAddress] = useState('none')

  const customUI = {
    colorBackgroundButtonPrimary: buttonColor ? buttonColor : '#0061D3',
    colorBackgroundButtonPrimaryHover:buttonColor ? buttonColor : '#0061D3',
    colorBackgroundLinkHover:'transparent',
    colorContentLinkTextHover:'#3C404A',
    fontFamilyTitle: 'geomanistregular',
    fontFamilySubtitle: 'geomanistregular',
    fontFamilyBody: 'geomanistregular',
  }
  const applicantsReqData = {
    first_name: email,
    last_name: email,
    email: email,
  }

  const onSelection = async (doc,callBack) => {
    // alert(JSON.stringify(doc));
    setShowSelection('none')
    setLoading(true)
    try {
      const applicantsData = await createApplicant({...applicantsReqData,source:'INVEST_ACCOUNT',proofType:'ID'})
      const { id } = applicantsData.data
      const SdkTokenData = await getSdkToken(id)
      sessionStorage.setItem('oaid', id)
      const onfidoStepsConfig = [
        {
          "type": "document",
          "options": {
            "documentTypes": {
              [doc.code]: {
                "country": countryOfResidence
              },
            }
          }
        },]

      // console.log(JSON.stringify(onfidoStepsConfig))
      setLoading(false)
      let onfidoObj = init({
        token: SdkTokenData.data.token,
        containerId: 'document-placeholder',
        onComplete: async function (data) {
          // console.log('everything is complete')
          // console.log(data);
          onfidoObj.tearDown()
          let document = []
          Object.keys(data).filter(d => {
            if (d === 'document_front' || d === 'document_back') {
              document.push({
                id: data[d].id,
                type: doc.code,
                side: data[d].side,
                proofType: 'ID',
                proofName: doc.title
              })
            }
          })
          // console.log(document)

          // await identityDocIndian(sessionStorage.getItem('oaid'), document)
          sessionStorage.setItem('documents', JSON.stringify(document))
          setShowAddress('flex')
          callBack()
          // updatedAccount({ nextStep: 'PERSONAL_DETAILS' })
          //   .then(res => {
          //     if (res.code === 200) {
          //       setUserInfoSession({ nextStep: 'PERSONAL_DETAILS' })
          //       props.history.push('/accountsetup', { data, applicantsId })
          //     }
          //   })
          //   .catch(error => {
          //     console.log(error)
          //   })
        },

        steps: onfidoStepsConfig,
        // language:{
        //   phrases:{
        //     doc_submit: {
        //       "button_link_upload": "or upload photo (no scans or photocopies)",
        //       "button_primary": "Continue on phone",
        //       "subtitle": "Take a photo with your phone",
        //       "title_id_back": `Upload the Back of your ${doc.title}`,
        //       "title_id_front": `Upload the Front of your ${doc.title}`,
        //    }
        //   }
        // },
        customUI,
        userDetails: {
          smsNumber: phoneNumber
        }
      })
    }
    catch (e) {
      console.log(e)
      setServerError('Something Went wrong. Please Try Again!')
    }
  }

  const onAddress = async (doc) => {
    // alert(JSON.stringify(doc));
    setShowAddress('none')
    setLoading(true)
    try {
      // const applicantsReqData = {
      //   first_name: firstName,
      //   last_name: lastName,
      //   email: email,
      // }
      // const applicantsData = await createApplicant(applicantsReqData)
      // const { id: applicantId } = applicantsData.data
      const applicantsData = await createApplicant({...applicantsReqData,source:'INVEST_ACCOUNT',proofType:'ADDRESS'})
      const { id } = applicantsData.data
      const SdkTokenData = await getSdkToken(id)

      let onfidoStepsConfig = []
        onfidoStepsConfig = [
          {
            "type": "poa",
            "options": {
              "country": countryOfResidence,
              "documentTypes": {
                bank_building_society_statement: true,
                utility_bill: true,
                government_letter: true
              }
            }
          }]

      setLoading(false)
      let onfidoObj = init({
        token: SdkTokenData.data.token,
        containerId: 'document-placeholder',
        onComplete: async function (data) {
          // console.log('everything is complete')
          // console.log(data);
          onfidoObj.tearDown()
          let document = []
          Object.keys(data).filter(d => {
            if (d === 'document_front' || d === 'document_back' || d === 'poa') {
              document.push({
                id: data[d].id,
                type: doc?.code,
                side: data[d].side,
                proofType: 'ADDRESS',
                proofName: doc?.title
              })
            }
          })
          // console.log(document)
          setLoading(true)
          await identityDocIndian(id, document)
          onFace()
        },
        steps: onfidoStepsConfig,
        customUI,
        userDetails: {
          smsNumber: phoneNumber
        }
      })
    }
    catch (e) {
      console.log(e)
      setServerError('Something Went wrong. Please Try Again!')
    }
  }


  const onFace = async () => {
    setLoading(true)
    try {
      const SdkTokenData = await getSdkToken(sessionStorage.getItem('oaid'))
      let onfidoStepsConfig = ['face', 'complete']

      setLoading(false)
      let onfidoObj = init({
        token: SdkTokenData.data.token,
        containerId: 'document-placeholder',
        onError:function(error){
          console.log('face error', JSON.stringify(error))
        },
        onUserExit:function(userExitCode){
          console.log('face Exit', JSON.stringify(userExitCode))
        },
        onComplete: async function (data) {
          // console.log('everything is complete')
          // console.log(data);
          onfidoObj.tearDown()
          const documents = JSON.parse(sessionStorage.getItem('documents')) ? JSON.parse(sessionStorage.getItem('documents')) : []
          await identityDocIndian(sessionStorage.getItem('oaid'),documents)
          props.setShowSuccessView()
        },
        steps: onfidoStepsConfig,
        customUI,
        userDetails: {
          smsNumber: phoneNumber
        }
      })
    }
    catch (e) {
      console.log(e)
      setServerError('Something Went wrong. Please Try Again!')
    }
  }


  const onAdhar = async () => {
    setShowSelection('none')
    setLoading(true)
    try {
      const applicantsData = await createApplicant({...applicantsReqData,source:'INVEST_ACCOUNT',proofType:'ID'})
      const { id } = applicantsData.data
      setLoading(false)
      const SdkTokenData = await getSdkToken(id)
      sessionStorage.setItem('oaid', id)
      const onfidoStepsConfig = [
        {
          "type": "document",
          "options": {
            "documentTypes": {
              "national_identity_card": {
                "country": countryOfResidence
              },
            }
          }
        },]
      // console.log(JSON.stringify(onfidoStepsConfig))
      let onfidoObj = init({
        token: SdkTokenData.data.token,
        containerId: 'document-placeholder',
        onError:function(error){
          console.log('id error', JSON.stringify(error))
        },
        onUserExit:function(userExitCode){
          console.log('id Exit', JSON.stringify(userExitCode))
        },
        onComplete: async function (data) {
          // console.log('everything is complete')
          // console.log(data);
          onfidoObj.tearDown()
          let document = []
          Object.keys(data).filter(d => {
            if (d === 'document_front' || d === 'document_back') {
              document.push({
                id: data[d].id,
                type: 'national_identity_card',
                side: data[d].side,
                proofType: 'ID',
                proofName: 'Aadhaar Card'
              })
            }
          })
          onIndianFace(document)
        },
        language:{
          phrases:{
            doc_submit: {
              "button_link_upload": "or upload photo (no scans or photocopies)",
              "button_primary": "Continue on phone",
              "subtitle": "Take a photo with your phone",
              "title_id_back": `Upload the back of your Aadhaar Card`,
              "title_id_front": `Upload the front of your Aadhaar Card`,
           }
          }
        },
        steps: onfidoStepsConfig,
        customUI,
        userDetails: {
          smsNumber: phoneNumber
        }
      })
    }
    catch (e) {
      console.log(e)
    }
  }


  const onIndianFace = async (document) => {
    setLoading(true)
    try {
      const SdkTokenData = await getSdkToken(sessionStorage.getItem('oaid'))
      let onfidoStepsConfig = ['face', 'complete']

      setLoading(false)
      let onfidoObj = init({
        token: SdkTokenData.data.token,
        containerId: 'document-placeholder',
        onError:function(error){
          console.log('face error', JSON.stringify(error))
        },
        onUserExit:function(userExitCode){
          console.log('face Exit', JSON.stringify(userExitCode))
        },
        onComplete: async function (data) {
          onfidoObj.tearDown()
          await identityDocIndian(sessionStorage.getItem('oaid'), document)
          props.setShowSuccessView()
        },
        steps: onfidoStepsConfig,
        customUI,
        userDetails: {
          smsNumber: phoneNumber
        }
      })
    }
    catch (e) {
      console.log(e)
      // setServerError('Something Went wrong. Please Try Again!')
    }
  }

  const onIndianSelection = (doc) => {
    //for cash account
    onAdhar()
  }


  const nonIndianSelection = (doc) => {
    onSelection(doc,onAddress)
  }

  useEffect(() =>{
    if(location === 'India' || location === 'INDIA'){
      onIndianSelection()
    }
  },[location])

  return (
    <>
      {serverError === '' ? null : (
        <AlertError color='#E81346' message={serverError} />
      )}
      <Row className={`mt-5 ${styles.docWidth}`} >
        <Col className={styles.formCol}>
        {loading ? <DocumentSkeleton /> :
          location === 'India' || location === 'INDIA' ?
          ''
          // <AadharPanSelection onSelection={onIndianSelection} display={showSelection}/>
          :
           <IDSelection onSelection={nonIndianSelection} display={showSelection}/>  }
         <div id="document-placeholder" className={styles.documentPlaceholder} style={{ minHeight: showSelection === 'none' && showAddress === 'none' ? '500px' : 'auto', position: 'relative' }}></div>
        {/* <IDSelection country={countryOfResidence} onSelection={onSelection} display={showSelection} />
        <AddressSelection country={countryOfResidence} onSelection={onAddress} display={showAddress} />
        {loading ? <DocumentSkeleton isProfile={true} /> : <div id="document-placeholder" className={styles.documentPlaceholder} style={{ minHeight: showSelection === 'none' && showAddress === 'none' ? '500px' : 'auto' }}></div>} */}
        </Col>
      </Row>
    </>
  )
}

export default Document;
