import React from "react";
import { format } from 'date-fns'
import { Row, Col} from 'react-bootstrap'
import styles from './etfdetail.module.css'



const profileType=[
    {
      label: "Trailing Return - 1yr",
      key: "oneYear",
      format: "number"
    },
    {
      label: "Trailing Return - 3yr",
      key: "threeYear",
      format: "number"
    },
    {
      label: "Beta - 3yr",
      key: "three_year_beta",
      format: "number"
    },
    {
      label: "Mean - 3yr",
      key: "three_year_mean",
      format: "number"
    },
    {
      label: "Sharpe - 3yr",
      key: "three_year_sharpe_ratio",
      format: "number"
    },
    {
      label: "Standard Deviation - 3yr",
      key: "three_year_standard_deviation",
      format: "number"
    },
    {
      label: "Treynor - 3yr",
      key: "three_year_treynor_ratio",
      format: "number"
    },
    {
      label: "Beta - 5yr",
      key: "five_year_beta",
      format: "number"
    },
    {
      label: "Mean - 5yr",
      key: "five_year_mean",
      format: "number"
    },
    {
      label: "Sharpe - 5yr",
      key: "five_year_sharpe_ratio",
      format: "number"
    },
    {
      label: "Standard Deviation - 5yr",
      key: "five_year_standard_deviation",
      format: "number"
    },
    {
      label: "Treynor - 5yr",
      key: "five_year_treynor_ratio",
      format: "number"
    }

];
const MetricsEtf = ({etfMetrics}) => {
    
    const dataObject = etfMetrics.reduce((acc, item) => {
        acc[item.key] = item.value;
        return acc;
    }, {});


    const showProfileData = (data) => {
        let tableData = [];
        if (Object.keys(data).length > 0) {
          profileType.forEach((type, i) => {
            let value = null;
            const dataValue = data[type.key]; // Use type.key here
            if (dataValue == null || dataValue === '' || dataValue === '--') {
              value = '--';
            } else {
              if (type.format === 'number') {
                const numValue = parseFloat(dataValue);
                value = isNaN(numValue) ? '--' : numValue.toFixed(2);
              } else {
                value = dataValue; // Default to text
              }
            }
            const card = (
              <div className={styles.statsCard} key={i}>
                <p style={{ color: '#626777', lineHeight: '16px' }} className='m-0 pb-1'>
                  {type.label}
                </p>
                <p className={`m-0 ${styles.cardValue}`}>{value}</p>
              </div>
            );
    
            tableData.push(
              <Col key={i} xl={4} lg={4} md={4} sm={6} xs={6}>
                {card}
              </Col>
            );
          });
        }else{
          tableData.push(
            <Col className={styles.noData}>
              Data not available
            </Col>
          );
        }
        return <Row className={styles.responsiveMargin}>{tableData}</Row>;
    };

    return(
        <div>
            {showProfileData(dataObject)}
        </div>
    )
}

export default MetricsEtf