import React, {Component} from "react";
import { Col,Row } from "react-bootstrap";
import {getFinancialStockData} from '../../../api'
import "./mobileModal.css";
import MobileTable from "./mobileTable";
import dropDownImage from '../../../public/images/seemore.svg'
class MobileModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      income: true,
      balance: false,
      cash: false,
      current: 'annually',
      dropDown: 'Annual',
      data: [],
      show: false
    };
    this.handleClick = this.handleClick.bind(this);

    this.wrapperRef = React.createRef();
    // this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    let getSessionData = sessionStorage.getItem("userInfo");
    getSessionData = JSON.parse(getSessionData);
    const userID = getSessionData.userID;
    getFinancialStockData('annually', this.props.code, 'income_statement', userID).then((response) => {
      this.setState({data: response.data})
    }).catch((error) => {
      console.log(error)
    })
  }

  handleClickOutside(event) {
    // console.log(this.wrapperRef.current)
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
        this.setState({
          show: false
        })
    }
}


  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
}

  handleData(period, statement) {
    let getSessionData = sessionStorage.getItem("userInfo");
    getSessionData = JSON.parse(getSessionData);
    const userID = getSessionData.userID;
    getFinancialStockData(period, this.props.code, statement, userID).then((response) => {
      this.setState({data: response.data})
    }).catch((error) => {
      console.log(error)
    })

  }

  handlePeriod(value) {
    const {current} = this.state
    this.handleData(current, value)
  }

  handleClick(value) {
    switch (value) {
      case 'annual':
        this.handleData('annually', 'income_statement')
        this.setState({dropDown: 'Annual', current: 'annually',show:false}, () => this.handleClick('income'))
        break;
      case 'quaterly':
        this.handleData('quarterly', 'income_statement')
        this.setState({dropDown: 'Quarter', current: 'quarterly',show:false}, () => this.handleClick('income'))
        break;
      case "income":
        this.handlePeriod('income_statement')
        this.setState({
          income: true,
          balance: false,
          cash: false,
          show:false
        });
        break;
      case "balance":
        this.handlePeriod('balance_sheet_statement')
        this.setState({
          income: false,
          balance: true,
          cash: false,
          show:false
        });
        break;
      case "cash":
        this.handlePeriod('cash_flow_statement')
        this.setState({
          income: false,
          balance: false,
          cash: true,
          show:false
        });
        break;
    }
  }

  render() {
    return (
      <div>
            <div className="modal-body mobile-modal-body container">
              <div className="row mobile-row">
                <div className="col mobile-col">
                  <span className="fin">Financials</span>
                </div>
              </div>
              <div
                className="row mobile-row"
                style={{marginTop: "3%",marginLeft:"1px"}}
              >
                <div className="col-8 pl-0">
                  <span className="billion">[All values in $ & thousands]</span>
                </div>
                <div className="col-4 col-xs-4" style={{textAlign: 'end'}}>
                  <span>
                    <div className="btn-group"
                      ref={this.wrapperRef}
                    >
                      <button
                        style={{
                          color: "#005DD6",
                          background: "none",
                          border: "0px",
                          width:'100px',
                          maxWidth:'100px',
                          display: 'inline-block',
                        }}
                        className={`mobileCss`}
                        type="button"
                        onClick={()=>this.setState({
                          show: true
                        })}
                      >
                        {this.state.dropDown}
                        <img src={dropDownImage} style={{marginLeft:"12px"}}/>
                      </button>
                      {this.state.show ?
                      (
                        <div  style={{position:"absolute",top:'30px',zIndex:"9000",background:'white',borderRadius:"8px"}} >
                          <a  onClick={() => this.handleClick('annual')} className='d-block' style={{textAlign:"center",padding:'5px'}}>
                            Annual
                          </a>
                          <a  onClick={() => this.handleClick('quaterly')} className='d-block' style={{textAlign:"center",padding:'5px'}}>
                            Quarter
                        </a>
                      </div>):(null)}
                    </div>
                  </span>
                </div>
              </div>
              <Row style={{marginTop:'16px'}}>
                <Col xs='auto'>
                <a
                      className={this.state.income ? 'active' : 'inActive'}
                      // href="#"
                      onClick={() => this.handleClick("income")}
                      style={{
                        color: this.state.income ? "#005DD6" : "#161D28",
                        borderBottom: this.state.income
                          ? "1px solid #005DD6"
                          : "",
                          textDecoration:"none",
                          fontFamily:'geomanistbook'
                      }}
                    >
                      Income
                    </a>
                </Col>
                <Col xs='auto'>
                <a
                     className={this.state.balance ? 'active' : 'inActive'}
                      // href="#"
                      onClick={() => this.handleClick("balance")}
                      style={{
                        color: this.state.balance ? "#005DD6" : "#161D28",
                        borderBottom: this.state.balance
                          ? "1px solid #005DD6"
                          : "",
                          whiteSpace:'nowrap',
                          textDecoration:"none"
                      }}
                    >
                      Balance Sheet
                    </a>
                </Col>
                <Col xs='auto'>
                <a
                     className={this.state.cash ? 'active' : 'inActive'}
                      // href="#"
                      onClick={() => this.handleClick("cash")}
                      style={{
                        color: this.state.cash ? "#005DD6" : "#161D28",
                        borderBottom: this.state.cash
                          ? "1px solid #005DD6"
                          : "",
                          textDecoration:"none"
                      }}
                    >
                      Cashflow
                    </a>
                </Col>
              </Row>
              {
                this.state.data === 'Oops! Something went wrong.' || !this.state.data.length ? <div style={{marginTop:"10px",fontSize:'10px'}}> DATA NOT AVAILABLE </div> :  <MobileTable data={this.state.data} current={this.state.current}/>
              }

            </div>
      </div>
    );
  }
}

export default MobileModal;
