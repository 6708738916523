import React from 'react'
import styles from './ExploreSearch.module.css'

const marketCap = ({ title, active, onChange,userExclusive }) => {
	return (
		<div style={userExclusive ? {} : {cursor:'default'}} className={active ? styles.marketCapActive : styles.marketCapInactive} onClick={() => onChange(title)}>
			{title}
		</div>
	)
}

export default marketCap
