import React,{ useState , useEffect}  from 'react'
import { useHistory } from 'react-router-dom'
import { getAccountInfo } from '../../api'
import Pan from './Pan'
import Document from './Document'
import Success from './Success'
import Header from '../Header/Header'
import ReviewSubmission from './ReviewSubmission'
import SkeletonPan from './Skeleton'

const VerifyPan = () => {

  let history = useHistory()

  const [current,setCurrent] = useState('pan')
  const [dwAccountStatus, setDwAccountstatus] = useState()
  const [savingAccountStatus, setSavingAccountStatus] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const accountDetails = () => {
    getAccountInfo().then(response => {
        if(response && response.accountStatus){
          sessionStorage.setItem('userInfo', JSON.stringify(response));
          setDwAccountstatus(response.accountStatus)
          setSavingAccountStatus(response.savingAccountStatus)
        }
    }).catch((err)=>{
        console.error(err)
    })
  }
  useEffect(()=>{
    accountDetails()
  },[])

  const userState = () => {
    if(dwAccountStatus && dwAccountStatus === 2) {
      if(savingAccountStatus) {
        if(savingAccountStatus === 1 || savingAccountStatus === 3) {
          history.push('/savings/status')
        } else {
          history.push('/savings')
        }
      } else {
        history.push('/savings/signup')
      }
    } else {
      history.push('/savings/status')
    }
    setIsLoading(false)
  }

  useEffect(()=> {
    if(dwAccountStatus !== undefined && dwAccountStatus !== null && dwAccountStatus !== '') {
      userState()
    }
  },[dwAccountStatus, savingAccountStatus])

   const getCurrent = () => {
     switch(current){
       case 'pan' :
           return(
             <Pan
              current={current}
              setCurrent={setCurrent}
             />
           )
        case 'document' :
        return(
          <Document
          setCurrent={setCurrent}
          />
        )
        case 'reviewsubmission' :
        return(
          <ReviewSubmission
          setCurrent={setCurrent}
          />
        )
        case 'success' :
          return(
            <Success
            setCurrent={setCurrent}
            />
          )
     }
   }

  return(
     <>
     <Header/>
      {isLoading ? <SkeletonPan/>  : getCurrent()}
     </>
  )
}

export default VerifyPan
