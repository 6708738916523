import React, { useState, useEffect, useRef, useContext } from 'react'
import { Row, Col, Card,OverlayTrigger,Tooltip } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styles from './PopularStock.module.css'
import { CardChart } from '../index'
import { getPriceHistory} from '../../api'
import { isNumber } from 'lodash'
import { calPriceChange_New, addCommasinPrice } from '../../utils'
import { SocketContext } from '../../socket-context'


const PopularStock = ({
	stockImage,
	stockTitle,
	stockName,
	stockValue,
	stockIncrement,
	increment,
	stockChange = '',
  stockType='',
  close,
}) => {
	const socket = useContext(SocketContext);
	const [isLoaded, setIsloaded] = useState(true)
  const [graphImage, setGraphImage] = useState(true)
  const [priceTicker, setPriceTicker] = useState({stockTitle, currentPrice: stockValue})

  let currentPrice;

  useEffect(() => {

    const priceListener = (priceState) => {
      //let data = JSON.parse(priceState)
      let { Symbol,price}  = priceState;
      stockValue = price && price;
      currentPrice = parseFloat(stockValue);
    setPriceTicker((prevPrice) => {
        let priceChange = currentPrice - prevPrice.currentPrice
        return {
          stockTitle: Symbol,
          currentPrice,
          prevPrice: prevPrice.currentPrice,
          priceChange,
        }
      })
    }

    if(socket) {
		socket.emit('join_symbol', stockTitle);
      socket.on(stockTitle, priceListener)
    }

    return () => {
      if(socket){
        socket.off(stockTitle, priceListener)
      }
    }
  }, [socket])

  let {change, changePer} = calPriceChange_New({
    currPrice: priceTicker.currentPrice,
    close,
   });

   stockChange = change
   stockIncrement = changePer
   increment = stockIncrement > 0 ? true : false

//    console.log("price ", priceTicker)
//    console.log("price ", close)

	// const [graphData, setGraphData] = useState([])

	// useEffect(() => {
	// 	const asynchFetch = async () => {
	// 		const pricedata = []
	// 		if (stockTitle !== '') {
	// 			let [res] = await Promise.all([getPriceHistory(stockTitle, 90)])
	// 			res &&
	// 				res.map((item, index) => {
	// 					pricedata.push(item.price)
	// 				})
	// 			setGraphData(pricedata)
	// 		}

	// 	}
	// 	asynchFetch()
  // }, [stockTitle])

  let priceBoxColor;

  useEffect(() => {
    if(priceTicker['priceChange'] && isNumber(priceTicker['priceChange']) && !isNaN(priceTicker['priceChange'])){
      priceBoxColor = priceTicker['priceChange'] > 0 ? '0DC014' : '#FA274A'
    }
  }, [priceTicker['priceChange']])

	return (
		<Link to={stockType === 'etf' ? `/etfdetail/${stockTitle}` :`/stockdetail/${stockTitle}`} style={{ textDecoration: 'none' }}>
			<Card className={styles.trendingCard}>
				<Card.Body className={styles.cardBody}>
					<Row>
						<Col lg={2} md={2} xs={2}>
							<div className={styles.imageDiv}>
								{stockImage && isLoaded ? (
									<img
										onError={() => {
											setIsloaded(false)
										}}
										className={styles.stockImage}
										src={stockImage}
										alt={stockTitle?.substring(0, 1)}></img>
								) : (
									<h3 className={`text-center ${styles.imagePaddingRemove}`}>
										{stockTitle?.substring(0, 1)}
									</h3>
								)}
							</div>
						</Col>
						<Col lg={4} md={3} xs={4} className={styles.titleMargin}>
							<div className={styles.stockTitle}>{stockTitle}</div>
              <OverlayTrigger
									placement='bottom'
                  trigger={['hover', 'focus','click']}
									overlay={
										<Tooltip>
										{stockName}
										</Tooltip>
									}>
										<div className={styles.stockName}>{stockName}</div>
								</OverlayTrigger>
						</Col>
						<Col lg={3} md={3} xs={2} className='p-0'>
              {`https://price-graphs.stockal.com/${stockTitle}.png` && graphImage ?
              <div><img className={styles.graphImage}
              onError={() => {setGraphImage(false)}}  src={`https://price-graphs.stockal.com/${stockTitle}.png`}></img></div> : "" }
							{/* <CardChart
								data={graphData}
								color={increment ? '#b0e7cb' : '#e89aad'}
								lineColor={increment ? '#13B948' : '#e51446'}
								width='60px'
								height='40px'
							/> */}
						</Col>
						<Col lg={3} md={4} xs={4} className={styles.mobilePadding}>
              <div style={priceBoxColor}>
                <div className={`${styles.stockValue} mb-1`}>
                  {/* {`$${stockValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`} */}
                  {
                    `$${addCommasinPrice(typeof priceTicker.currentPrice === 'number' ? priceTicker.currentPrice.toFixed(2) : priceTicker.currentPrice)}`
                  }
                </div>
              </div>
							<div
								className={styles.stockIncrement}
								style={{
									color: `${increment ? '#0DC014' : '#FA274A'}`
								}}>
								{stockChange
									? stockChange < 0
										? stockIncrement > 0
											? `-$${stockChange
													.toString()
													.substring(1)}[+${stockIncrement.toFixed(2)}%]`
											: `-$${stockChange
													.toString()
													.substring(1)}[${stockIncrement.toFixed(2)}%]`
										: stockIncrement > 0
										? `$${stockChange}[+${stockIncrement.toFixed(2)}%]`
										: `$${stockChange}[${stockIncrement.toFixed(2)}%]`
									: stockIncrement > 0
									? `+${stockIncrement}%`
									: `${stockIncrement}%`}
							</div>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</Link>
	)
}

export default PopularStock
