import React, { useContext} from 'react'
import { Row, Col } from 'react-bootstrap'
import OnBoardingCarousel from './OnBoardingCarousel'
import styles from './OnBoardingScreen.module.css'
import { ThemeContext } from '../../Context/ThemeContext'
import Footer from '../Footer/Footer'

const OnBoardingScreen = props => {
  const { contactNumber } = useContext(ThemeContext)
  const hotlineSupport = (sessionStorage.getItem('planInfo') &&
    JSON.parse(sessionStorage.getItem('planInfo')).details &&
    JSON.parse(sessionStorage.getItem('planInfo')).details.hotlineSupport) ?
    JSON.parse(sessionStorage.getItem('planInfo')).details.hotlineSupport : [];
  let label, phone = "";
  if (hotlineSupport.length > 0 && hotlineSupport[0].phone && window.location.href.includes('document')) {
    phone = hotlineSupport[0].phone;
    label = hotlineSupport[0].text ? hotlineSupport[0].text : '';
  } else {
    phone = contactNumber;
    label = 'Hotline'
  }
  const hotlineText = label + ' : ' + phone
  return (
    <Row className={styles.fullScreen}>
      {/* <Col md={4} className={styles.slideShow}>
        <OnBoardingCarousel />
      </Col> */}
      <Col className={`d-flex flex-column ${styles.onlyInput}`}>
        <div className={styles.body}>
          {props.children}
        </div>
        {!window.location.href.includes('accountsetup') ? <div className={`container p-0`}>
          <div className="row">
            <div className={`col ${styles.colResponsive}`}>
              <Footer hotlineText={hotlineText}/>
            </div>
          </div>
        </div>: null}
      </Col>
    </Row>

  )
}

export default OnBoardingScreen
