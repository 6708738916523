import React from 'react'
import { number } from 'yup'
import styles from './pagination.module.css'

const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage }) => {
	const pageNumbers = []

	for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
		pageNumbers.push(i)
	}

	return (
		<div className={`${styles.uppderDiv}`}>
			<ul type='button' className={styles.insideUpdderDiv} style={{ listStyleType: 'none', display: 'flex' }}>
				{pageNumbers.map(number => (
					<div
						onClick={() => paginate(number)}
						className={`${styles.innerDiv} d-flex justify-content-center`}
						style={{
							background: `${currentPage === number ? '#EFEFF1' : ''}`
						}}>
						<li key={number} style={{ display: 'inline' }}>
							<p>{number}</p>
						</li>
					</div>
				))}
			</ul>
		</div>
	)
}

export default Pagination

// position: absolute;
// width: 32px;
// height: 32px;
// left: 1176px;
// top: 772px;

/* Neutral/200 */
