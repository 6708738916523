import React, { Component } from "react";
import { string } from "yup/lib/locale";
import { getFinancialStockData } from '../../../api'
import "./modalcss.css";
import FinancialsTable from "./table";
import closeImage from '../../../../src/public/images/Multiply.svg'

class NewFinancialDataFull extends Component {
  constructor(props) {
    super(props);
    this.state = {
      annual: true,
      quaterly: false,
      income: true,
      balance: false,
      cash: false,
      current: 'annually',
      data: []
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleData = this.handleData.bind(this)
    this.handlePeriod = this.handlePeriod.bind(this)
  }

  componentDidMount() {
    let getSessionData = sessionStorage.getItem("userInfo");
    getSessionData = JSON.parse(getSessionData);
    const userID = getSessionData && getSessionData.userID && getSessionData.userID;
    getFinancialStockData('annually', this.props.code, 'income_statement', userID).then((response) => {
      this.setState({ data: response.data })
    }).catch((error) => {
      console.log(error)
    })
  }

  handleData(period, statement) {
    let getSessionData = sessionStorage.getItem("userInfo");
    getSessionData = JSON.parse(getSessionData);
    const userID = getSessionData.userID;
    getFinancialStockData(period, this.props.code, statement, userID).then((response) => {
      this.setState({ data: response.data })
    }).catch((error) => {
      console.log(error)
    })

  }

  handlePeriod(value) {
    const { current } = this.state
    this.handleData(current, value)
  }


  handleClick(value) {
    switch (value) {
      case "annual":
        this.handleData('annually', 'income_statement')
        this.setState({
          annual: true,
          quaterly: false,
          current: 'annually'
        }, () => this.handleClick('income'));
        break;
      case "quaterly":
        this.handleData('quarterly', 'income_statement')
        this.setState({
          annual: false,
          quaterly: true,
          current: 'quarterly'
        }, () => this.handleClick('income'));
        break;
      case "income":
        this.handlePeriod('income_statement')
        this.setState({
          income: true,
          balance: false,
          cash: false,
        });

        break;
      case "balance":
        this.handlePeriod('balance_sheet_statement')
        this.setState({
          income: false,
          balance: true,
          cash: false,
        });
        break;
      case "cash":
        this.handlePeriod('cash_flow_statement')
        this.setState({
          income: false,
          balance: false,
          cash: true,
        });
        break;
    }
  }


  render() {

    return (
      <div
        className="modal create alignment fade bd-example-modal-lg"
        id="myModal2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel2"
      >
        <div
          className="modal-dialog my-dialog modal-lg my-lg"
          role="document"
          style={{ top: "-5%", bottom: "-4%" }}
        >

          <div
            className="modal-content my-content"
            style={{ background: "#FFFFFF"}}
          >

            <div
              class="modal-header my-header"
              style={{ marginBottom: "4.5%",cursor:'pointer',textAlign:"end" }}
            >
              <img src={closeImage}
                data-dismiss="modal"
                aria-label="Close"/>
              <div className="row my-row">
                <div
                  className="col my-col"
                  style={{
                    textAlign: "left",
                  }}
                >
                  <span className="financials">Financials</span>
                </div>
                <div
                  className="col my-col"
                  style={{
                    textAlign: "right",
                  }}
                  type="button"
                >
                  <span
                    style={{
                      color: this.state.annual ? "#005DD6" : "#9096A8",
                      marginRight: "8%",
                    }}
                    className='annuallyActive'
                    onClick={() => this.handleClick("annual")}
                  >
                    Annual
                  </span>
                  <span
                    style={{
                      color: this.state.quaterly ? "#005DD6" : "#9096A8",
                    }}
                    onClick={() => this.handleClick("quaterly")}
                  >
                    Quarter
                  </span>
                </div>
              </div>
              <div
                className="row my-row"
                type="button"
                style={{ paddingBottom: "1%", marginTop: "3.4%" }}
              >
                <div
                  className="col- my-col"
                  style={{
                    textAlign: "left",
                  }}
                >
                  <span
                    style={{
                      borderBottom: this.state.income
                        ? "2px solid #005DD6"
                        : "",
                      color: this.state.income ? "#005DD6" : "#9096A8",
                      paddingBottom: "6%",
                    }}
                    className='tabs'
                    onClick={() => this.handleClick("income")}
                  >
                    Income statement
                  </span>
                </div>
                <div className="col my-col" style={{ textAlign: "center"}}>
                  <span
                    style={{
                      borderBottom: this.state.balance
                        ? "2px solid #005DD6"
                        : "",
                      color: this.state.balance ? "#005DD6" : "#9096A8",
                      paddingBottom: "5%",

                    }}
                    className='tabs'
                    onClick={() => this.handleClick("balance")}
                  >
                    Balance sheet
                  </span>
                </div>
                <div className='col my-col' style={{ textAlign: "left"}}>
                <span
                    style={{
                      borderBottom: this.state.cash ? "2px solid #005DD6" : "",
                      color: this.state.cash ? "#005DD6" : "#9096A8",
                      paddingBottom: "5%",
                    }}
                    className='tabs'
                    onClick={() => this.handleClick("cash")}
                  >
                    Cashflow
                  </span>
                </div>
                <div
                  className="col my-col"
                  style={{
                    textAlign: "right",
                  }}
                >
                  <span className='tabs' style={{color:'#626777',whiteSpace:'nowrap'}}>[All values in $ & thousands]</span>
                </div>
              </div>
            </div>
            {
              this.state.data === 'Oops! Something went wrong.' || !this.state.data.length ? 'DATA NOT AVAILABLE' :  <FinancialsTable data={this.state.data} current={this.state.current} />
            }
          </div>
        </div>
      </div>
    );
  }
}

export default NewFinancialDataFull;
