import React, { useContext, useState } from "react"
import FileUpload from "./FileUpload"
import styles from "./direct.module.css"
import { AddFundContext } from "../../AddFundContext"
import { HDFCPostStatement } from "../../../../api"
import { Spinner } from "react-bootstrap"

const Upload = ({otpId})=>{
    const {setSubPage, setPage, setCancelDetails, setShowCancel, PAGE, SUBPAGE, setNoHeader} = useContext(AddFundContext)
    const [errorMsg, setErrorMsg] = useState(null)
    const [files, setFiles] = useState([])
    const [uploading, setUploading] = useState(false)
    const DIRECT=PAGE.DIRECT
    const onFile = (file, index)=>{
        const temp=[...files]
        if(files.length===index && file){
            temp.push(file)
        }else{
            if(file)
                temp[index] = file
            else
                temp.splice(index, 1)
        }
        setFiles([...temp])
    }
    const onUpload = async ()=>{
        if(!uploading){
            setUploading(true)
            try {
                const formData = new FormData();
                let isFileUploaded=false
                files.forEach((file)=>{
                    formData.append('files', file)
                    if(file)
                        isFileUploaded = true
                })
                formData.append('id', otpId)
                if(!isFileUploaded){
                    setErrorMsg('No statements uploaded')
                }
                else{
                    const res = await HDFCPostStatement(formData)
                    if(res && res.data && res.data.code===200){
                        setSubPage(SUBPAGE.SUCCESS)
                    }else if(res && res.data && res.data.code===400){
                        setErrorMsg(res.data.message)
                    }else{
                        setNoHeader(true)
                        setSubPage(SUBPAGE[DIRECT].ERROR)
                    }  
                }                
            } catch (error) {
                if(error && error.data && error.data.code===400){
                    setErrorMsg(error.data.message)
                }else{
                    setNoHeader(true)
                    setSubPage(SUBPAGE[DIRECT].ERROR)                }  
            }
            setUploading(false)
        }
    }
    return (<div className={styles.uploadContainer}>
        <div className={styles.uploadNote}>
            <div className={styles.noteHeader}>Your account is not older than one year.</div>
            <div className={styles.noteBody}>
                You need to upload bank statements of any other bank that go back 1 year.
            </div>
        </div>
        <div className={styles.fileUpload}>
            {files.map((file, index)=>{
             return <FileUpload key={index} onFileSelectError={setErrorMsg} onFileSelectSuccess={(file)=>onFile(file, index)} file={files[index]}/>
            })}
            {files.length<4 && <FileUpload key={files} onFileSelectError={setErrorMsg} onFileSelectSuccess={(file)=>onFile(file, files.length)}/>}
        </div>
        <div className={styles.errorBook}>{errorMsg}</div>
        <div className={styles.footerContainer}>
            <button className={styles.continueBtn} onClick={onUpload}>
                {uploading && <Spinner animation="border" className={styles.spinnerColor}/>}
                Upload
            </button>
            <button className={styles.tertiaryBtn} onClick={()=>{
                    setCancelDetails({
                        msg:'Are you sure you want to cancel the transaction?',
                        onConfirm:()=>setPage(PAGE.TRANSFER),
                        onCancel:()=>setShowCancel(false)
                    });
                    setShowCancel(true)
                }}>
                Try Another Method
            </button>
        </div>
    </div>
    )
}

export default Upload