import React, { useContext, useEffect, useState } from 'react';
import styles from './IDSelection.module.css';
import { Card, Row, Col, Spinner } from 'react-bootstrap';
import IdCard from '../../public/images/IDCard.svg';
import Passport from '../../public/images/newOnboarding/PassportOnfido.svg';
import Arrow from '../../public/images/ProfileBackArrow.svg';
import { ThemeContext } from '../../Context/ThemeContext';

const selectionData = [
  {
    icon: IdCard,
    title: 'National ID',
    subTitle: 'Front and Back',
    code: 'national_identity_card',
    excludeCountryList: ['IND'],
    type: 'NATIONAL_ID_CARD',
  },
  {
    icon: Passport,
    title: 'Residence permit',
    subTitle: 'Front and Back',
    code: 'residence_permit',
    excludeCountryList: ['IND'],
    type: 'RESIDENCE_PERMIT',
  },
  {
    icon: Passport,
    title: 'Driver\'s license',
    subTitle: 'Front and Back',
    code: 'driving_licence',
    excludeCountryList: ['IND'],
    type: 'DRIVER_LICENSE',
  },
  {
    icon: Passport,
    title: 'Passport',
    subTitle: 'Photo page',
    code: 'passport',
    type: 'PASSPORT',
  },
  {
    icon: Passport,
    title: 'Aadhar',
    subTitle: 'Upload or E-verify using DigiLocker',
    code: 'india kyc',
    type: 'INDIA_KYC',
  }
];

const IDSelection = ({ country, onSelection, display, documentType, configList }) => {
  const [availableDocumentCodes, setAvailableDocumentCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    if (configList) {
      if (typeof configList === 'object' && configList.active) {
        setAvailableDocumentCodes(configList.values);
      }
      setIsLoading(false); // Set isLoading to false after processing configList
    }
  }, [configList]);

  const { siteLogo, shortLogo } = useContext(ThemeContext);

  return (
    <>
      <Row style={{ display }} className="m-0 justify-content-center">
        <Col xl={4} sm={8} xs={12}>
          <div className={styles.selectionBox}>
            {isLoading ? (
              <div className={styles.loader}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <>
                <div className={styles.title}>Verify your identity</div>
                <div className={styles.subTitle}>
                  We need pictures of your Official Identity Document(s) to proceed further.
                </div>
                <div className={styles.cardContainer}>
                  {selectionData.map((doc, idx) => {
                    const { countryList, icon, title, subTitle, excludeCountryList, code } = doc;
                    let flag = true;

                    if (configList && availableDocumentCodes.length > 0 && !availableDocumentCodes.includes(code)) {
                      return null;
                    }

                    if (excludeCountryList && excludeCountryList.includes(country)) {
                      flag = false;
                    } else if (countryList && !countryList.includes(country)) {
                      flag = false;
                    }

                    if (flag) {
                      return (
                        <Card key={idx} className={styles.card} onClick={() => onSelection(doc)}>
                          <Card.Body className={styles.cardBody}>
                            <Row style={{ margin: '0px' }}>
                              <Col style={{ padding: '0px' }} xs={3} md={2}>
                                <img alt='icon' className={styles.logoImg} src={icon}></img>
                              </Col>
                              <Col xs={7} md={8}>
                                <div className={styles.cardTitle}>
                                  {title}
                                </div>
                                <div className={styles.cardSubTitle}>{subTitle}</div>
                              </Col>
                              <Col style={{ padding: '0px' }} xs={2} md={2} className={styles.cardAction}>
                                <img alt='action' src={Arrow}></img>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
}

export default IDSelection;
