import React,{useState,useEffect} from 'react'
import {Row,Form,Col} from 'react-bootstrap'
import SignUpInput from '../../../SignUp/SignUpInput'
import SignUpButton from '../../../SignUp/SignUpButton'
import { updatedAccount } from '../../../../api'
import { setUserInfoSession } from '../../../../common'
import styles from '../../AccountSetUp.module.css'


const politicallyPersonMapping = {
  'FOREIGN_PEPS':'Foreign PEPs',
  'DOMESTIC_PEPS':'Domestic PEPs',
  'INTERNATIONAL_ORGANIZATION_PEPS':'International organization PEPs',
  'NOT_POLITICALLY_EXPOSED':'Not Politically Exposed'
}

const PoliticallyExposed = ({changeCurrent,nextForm,formData,formValidation,updateFormValidation}) => {
  const {politicallyExposed:{politicallyExposedType = '' , politicalPersonName = '',politicalPartyName=''} = {}} = formData
  const findPolitical = politicallyExposedType && Object.keys(politicallyPersonMapping).find(key => politicallyPersonMapping[key] === politicallyExposedType)
  const [politicalPerson,setPoliticalPerson] = useState(findPolitical ? findPolitical : 'NOT_POLITICALLY_EXPOSED')
  const [politicalPersonValue,setPoliticalPersonValue] = useState(politicalPersonName)
  const [politicalPartyValue,setPoliticalPartyValue] = useState(politicalPartyName)
  const [buttonDisable,setButtonDisable] = useState(false)
  const [errorMessage,setErrorMessage] = useState('')
  const [errorPolitical,setErrorPolitical] = useState('')
  const [isForeign,setIsForeign] = useState(false)
  const [isDomestic,setIsDomestic] = useState(false)
  const [isInternational,setIsInternational] = useState(false)
  const [loading,setLoading] = useState(false)

  useEffect(() => {
    setErrorMessage('',setErrorPolitical(''))
     if(politicalPerson === 'FOREIGN_PEPS'){
      setIsForeign(true,setIsDomestic(false,setIsInternational(false)))
     }else if(politicalPerson === 'DOMESTIC_PEPS'){
      setIsForeign(false,setIsDomestic(true,setIsInternational(false)))
    }
    else if(politicalPerson === 'INTERNATIONAL_ORGANIZATION_PEPS'){
      setIsForeign(false,setIsDomestic(false,setIsInternational(true)))
    }
    else{
      setIsForeign(false,setIsDomestic(false,setIsInternational(false)))
    }
    setPoliticalPersonValue('',setPoliticalPartyValue(''))
  },[politicalPerson])

  useEffect(() => {
    setPoliticalPersonValue(politicalPersonName,setPoliticalPartyValue(politicalPartyName))
  },[politicalPersonName,politicalPartyName])


  const inputField = (desc) => {
    return(
      <>
         <div className={styles.detail} style={{marginTop:'4px',color:'#767C8F',paddingLeft:'0px'}}>{desc}</div>
         <Row className={styles.personalInputRow}>
            <Col
              className={styles.mobileMargin}
              style={{paddingLeft:'0px'}}
              md={6}
              xs={12}>
                <SignUpInput
                  label='Political Name'
                  placeholder = 'Enter Your Political Name'
                  type='text'
                  name='politicalPersonValue'
                  tabIndex='1'
                  value={politicalPersonValue}
                  onChange={(event) => setPoliticalPersonValue((event.target.value).replace(/[^A-Za-z ]/ig, '').trim(),setErrorPolitical(''))}
                  isInvalid={errorPolitical !== ''}
                  errorMessage={errorPolitical}
                />
            </Col>
            <Col
              className={styles.mobileMargin}
              style={{paddingLeft:'0px'}}
              md={6}
              xs={12}>
                <SignUpInput
                  label='Name of the Political Party'
                  placeholder = 'Enter Political Party’s Name'
                  type='text'
                  name='politicalPartyValue'
                  tabIndex='1'
                  value={politicalPartyValue}
                  onChange={(event) => {setPoliticalPartyValue((event.target.value).replace(/[^A-Za-z ]/ig, '').trim())
                              onUpdateFormValidation(false)
                            }}
                />
            </Col>
          </Row>
      </>
    )
  }

  const getInputValue = () => {
    switch (politicalPerson){
      case 'FOREIGN_PEPS':
          return(
            inputField('Individuals who are or have been entrusted with prominent public functions by a foreign country, for example, Heads of State or of government, senior politicians, senior government, judicial or military officials, senior executives of state-owned corporations, important political party officials. ')
          )
      case 'DOMESTIC_PEPS':
          return(
            inputField('Individuals who are or have been entrusted domestically with prominent public functions, for example Heads of State or of government, senior politicians, senior government, judicial or military officials, senior executives of state-owned corporations, important political party officials.')
          )
      case 'INTERNATIONAL_ORGANIZATION_PEPS':
          return(
            inputField('Persons who are or have been entrusted with a prominent function by an international organization, refers to members of senior management or individuals who have been entrusted with equivalent functions, i.e., directors, deputy directors and members of the board or equivalent functions.')
          )
    }
  }


 const onUpdateFormValidation = (value) => {
    if(formValidation.politicallyExposedDetail !== value){
    updateFormValidation({...formValidation,politicallyExposedDetail:value})
    }
  }


  const handleSubmit = async() => {
    setLoading(true)
    // const value = politicalPerson === '' ? true :
    // politicalPerson !== 'NOT_POLITICALLY_EXPOSED' && politicalPersonValue === '' ? true:
    // false
      if(politicalPerson === '' ){
      setErrorMessage('Politically Exposed is required',setLoading(false))
      }
      else if(politicalPerson !== 'NOT_POLITICALLY_EXPOSED' && politicalPersonValue === ''){
        setErrorPolitical('Political Name is required',setLoading(false))
      }
      else{
      const data = {
      politicallyExposed: politicalPerson === 'NOT_POLITICALLY_EXPOSED' ? false : true ,
      politicallyExposedType:politicalPerson,
      politicallyExposedNames:`${politicalPersonValue} ${politicalPartyValue}`,
      nextStep:'LEGAL_DETAILS'
    }
    try{
      const resp = await updatedAccount(data)
      if(resp.code === 200){
        const politicallyExposed = {
          politicallyExposed:politicalPerson === 'NOT_POLITICALLY_EXPOSED' ? false : true,
          politicallyExposedType:politicallyPersonMapping[politicalPerson],
          politicalPersonName:politicalPersonValue,
          politicalPartyName:politicalPartyValue,
          nextStep:'LEGAL_DETAILS'
        }
        const userInfo = Object.assign(politicallyExposed)
        setUserInfoSession(userInfo)
        onUpdateFormValidation(true)
				nextForm({ politicallyExposed })
        changeCurrent('LEGAL_DETAILS')
      }
    }catch(error){
      setLoading(false)
      setErrorMessage(error.message)
    }
  }
  }

  // useEffect(() => {
  //   const value = politicalPerson === '' ? true :
  //                 politicalPerson !== 'NOT_POLITICALLY_EXPOSED' && politicalPersonValue === '' ? true :
  //                 false
  //     if(value){
  //       setErrorMessage('Political Exposed is required')
  //     }
  // },[politicalPersonValue,politicalPerson])

  return(
    <>
     	<Row className={styles.blackText}>
						Why do we need this?
				</Row>
        <Row className={styles.detail}>We’re legally required to know if you have any political exposure. If it’s applicable to you or an immediate family member, please let us know.</Row>
      <Form className={styles.politicalDiv}>
      <Row className={styles.politicalRadio} style={{margintTop:'20px'}}>
      <Form.Check
          label="Not Politically Exposed"
          name="group1"
          type='radio'
          checked ={politicalPerson === 'NOT_POLITICALLY_EXPOSED' ? true : false}
          value='NOT_POLITICALLY_EXPOSED'
          onChange={(e) => setPoliticalPerson(e.target.value) }
        />
      </Row>
      <Row className={styles.politicalRadio} style={isForeign ? {border:'0px',background:'#F7F7F8',borderRadius:'8px',paddingBottom:'24px'}:{}}>
      <Form.Check
          label="Foreign PEPs"
          name="group1"
          type='radio'
          value='FOREIGN_PEPS'
          checked={isForeign}
          onChange={(e) => setPoliticalPerson(e.target.value) }
        />
        {isForeign ? <div style={{paddingLeft:'24px'}}>{getInputValue()}</div> : ''}
      </Row>
      <Row className={styles.politicalRadio} style={isDomestic ? {border:'0px',background:'#F7F7F8',borderRadius:'8px',paddingBottom:'24px'} : {}}>
        <Form.Check
          label="Domestic PEPs"
          name="group1"
          type='radio'
          value='DOMESTIC_PEPS'
          checked={isDomestic}
          onChange={(e) => setPoliticalPerson(e.target.value) }
        />
        {isDomestic ? <div style={{paddingLeft:'24px'}}>{getInputValue()}</div> : ''}
      </Row>
      <Row className={styles.politicalRadio} style={isInternational ? {border:'0px',background:'#F7F7F8',borderRadius:'8px',paddingBottom:'24px'} : {border:'0px'}}>
        <Form.Check
          label="International Organisation PEPs"
          name="group1"
          type='radio'
          checked={isInternational}
          value='INTERNATIONAL_ORGANIZATION_PEPS'
          onChange={(e) => setPoliticalPerson(e.target.value) }
        />
        {isInternational ? <div style={{paddingLeft:'24px'}}>{getInputValue()}</div> : ''}
      </Row>
      </Form>
      <Row className={styles.buttonMargin} style={{marginTop:'32px'}}>
					<Col className={styles.mobileMargin}>
          {errorMessage ? <span className={styles.errorMessage} style={{display:'block',paddingBottom:'10px'}}>{errorMessage}</span> : ''}
						<SignUpButton
							title='Next Section'
              tabIndex='1'
              // disabled={buttonDisable}
							classes={styles.nextSectionButton}
              loading={loading}
							onClick={() => handleSubmit() }
              ></SignUpButton>
					</Col>
			</Row>
    </>
  )
}

export default PoliticallyExposed
