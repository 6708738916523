import React, { useContext,useEffect, useState } from 'react'
// import { getAccountInfo } from '../api'
import {Row,Col} from 'react-bootstrap'
import { AddFund } from '../components'
import Footer from '../components/Footer/Footer'
import { ThemeContext } from '../Context/ThemeContext'
import styles from './innerLayout.module.css'

// import { useDispatch } from 'react-redux'
// import { USER_INFO } from '../store/actions/action'

const InnerLayout = ({component: Component, ...rest}) =>{
  const [hotlineText,setHotlineText] = useState('')
  const { contactNumber } = useContext(ThemeContext)


  useEffect(()=>{
    isUserSet()
  })

    const isUserSet =()=>{



     //check if plan has hotline support number and text
    const hotlineSupport = (sessionStorage.getItem('planInfo') &&
    JSON.parse(sessionStorage.getItem('planInfo')).details &&
     JSON.parse(sessionStorage.getItem('planInfo')).details.hotlineSupport) ?
     JSON.parse(sessionStorage.getItem('planInfo')).details.hotlineSupport : [];
     let label, phone = "";
    if (hotlineSupport.length > 0 && hotlineSupport[0].phone) {
      phone = hotlineSupport[0].phone;
      label = hotlineSupport[0].text ? hotlineSupport[0].text : '';
    }else{
      phone = contactNumber;
      label = 'Hotline'
    }

    setHotlineText(label +' : '+phone);
    }


  return (

    (window.location.href.includes('document') || window.location.href.includes('accountsetup')) ?
    <>
      <Component {...rest} isUserSet={()=>isUserSet()}/>
      {!window.location.href.includes('document')?
      <div className="container">
        <div className={`row ${styles.accountsetupFooter}`}>
        <div className="col">
              <Footer hotlineText={hotlineText} {...rest}/>
            </div>
          </div>
        </div>:null
      }
    </>
    :
    <div className={`${styles.parent}`}>
      <div className={`${styles.content}`}>
        <Component {...rest} isUserSet={()=>isUserSet()}/>
        <AddFund/>
      </div>
      <div className={`${styles.FooterParent}`}>
        <div className={`container ${styles.Footer}`}>
          <div className={`row`}>
            <div className={`col ${styles.bottomResponsive}`}>
              <Footer hotlineText={hotlineText} {...rest}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default InnerLayout;

// <div className={`${styles.parent}`}>
// <div className={`${styles.content}`}>
//   <Component {...rest} isUserSet={()=>isUserSet()}/>
// </div>
// <div className={`${styles.FooterParent}`}>
//   <div className={`container ${styles.Footer}`}>
//     <div className={`row`}>
//       <div className={`col`}>
//         <Footer hotlineText={hotlineText} {...rest}/>
//       </div>
//     </div>
//   </div>
// </div>
// </div>

