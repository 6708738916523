import { initialState } from '../initialState'
import { HOME_NEWS } from '../actions/action'

const homeNews = (state = initialState.homeNews, action) => {
	switch (action.type) {
		case HOME_NEWS: {
			return { ...state, homeNews: action.payload }
		}
		default: {
			return state
		}
	}
}

export default homeNews
