import React from 'react'

const Shimmer = () => {
	return (
		<div className='shimmer-wrapper'>
			<div className='shimmer'></div>
		</div>
	)
}

export default Shimmer
