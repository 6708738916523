import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import './index.css'
import stockalblacklogo from '../public/images/newlogo.svg'
import moneyControlLogo from '../public/images/moneyControlLogo.svg'
// import moneyControlHDFCsecurities from '../../public/assets/images/moneyControlHDFCsecurities.png';
// import moneyControlMotilal from '../../public/assets/images/moneyControlMotilal.png';
// import moneyControlGeojit from '../../public/assets/images/moneyControlGeojit.png';
import check from '../public/images/check.svg'
import partnerlogos from '../public/images/partnerlogos2x.svg'
import tesla from '../public/images/tesla.svg'
import ggl from '../public/images/ggl.svg'
import nike from '../public/images/nike.svg'
import spotify from '../public/images/spotify.svg'
import ups from '../public/images/ups.svg'
import uber from '../public/images/uber.svg'
import apple from '../public/images/apple.svg'
import amzn from '../public/images/amzn.svg'
import coca from '../public/images/coca.svg'
import disney from '../public/images/disney.svg'
import mcd from '../public/images/mcd.svg'
import msft from '../public/images/msft.svg'

class MoneyControl extends Component {
	constructor(props) {
		super(props)
		this.state = {
			queryParam: ''
		}
	}

	componentDidMount() {
		// const height = document.getElementsByClassName('infoPart')[0].clientHeight;
		// // const height = window.screen.height;
		// // document.getElementsByClassName('rightColumn')[0].style.height = height + "px !important";
		// // document.getElementsByClassName('infoPart')[0].style.height = height;
		// if (document.getElementsByClassName("slick-list")) {
		//   const vNavimg = document.getElementsByClassName("slick-list");
		//   for (let i = 0; i < vNavimg.length; i++) {
		//     vNavimg[i].style.minHeight = height + "px !important";
		//   }
		// }
		let queryParam = window.location.href.split('?')[1]
		if (queryParam) {
			this.setState({ queryParam })
		}
	}

	render() {
		let settings = {
			infinite: true,
			slidesToShow: 3,
			slidesToScroll: 1,
			vertical: true,
			autoplay: true,
			speed: 4000,
			autoplaySpeed: 0,
			pauseOnHover: false,
			cssEase: 'linear',
			adaptiveHeight: true,
			responsive: [
				{
					breakpoint: 750,
					settings: {
						slidesToShow: 3
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 2
					}
				}
			]
		}
		let settings1 = Object.assign({ ...settings }, { speed: 5000 })
		return (
			<div className='justify-content-end'>
				<div className='container-fluid moneyControl'>
					<div className='row'>
						<div className='col-lg-5 col-12 infoPart'>
							<div className='justify-content-between align-items-center row moneyControlLogo'>
								<div className='col-md-6 col-sm-5 col-5'>
									<a href='https://www.moneycontrol.com/msite/global-investing'>
										<img src={moneyControlLogo} width='83%' />
									</a>
								</div>
								<div className='pr-3'>
									<p className='smallMoneyControltext mb-1'>powered by</p>
									<img
										className='moneyControlStockalLogo pb-1'
										src={stockalblacklogo}
									/>
									{/* <p className="smallMoneyControltext pt-1 mb-1">India’s 1st borderless investment platform</p> */}
								</div>
							</div>
							<div className='row'>
								<div className='col-12 internalPad'>
									<h4 className='header moneyControlMaxWidth'>
										Invest in US Stocks from as low as $1
									</h4>
								</div>
							</div>
							<div className='row'>
								<div className='col-12 internalPad'>
									<ul className='firstTick'>
										<li>
											<div className='row'>
												<div className='col-1'>
													<img className='' src={check} />
												</div>
												<div className='col-10 pl-2 moneyControlMaxWidth'>
													Diversify your portfolio with over 5,500+ US Securities
												</div>
											</div>
										</li>
									</ul>
									<ul className='secondTick'>
										<li>
											<div className='row'>
												<div className='col-1'>
													<img className='' src={check} />
												</div>
												<div className='col-10 pl-2 moneyControlMaxWidth'>
													No annual maintenance cost
												</div>
											</div>
										</li>
									</ul>
									<ul className='thirdTick'>
										<li>
											<div className='row'>
												<div className='col-1'>
													{' '}
													<img className='' src={check} />
												</div>
												<div className='col-10 pl-2 moneyControlMaxWidth'>
													Robust security on platform with brokerage account
													protection*
												</div>
											</div>
										</li>
									</ul>
								</div>
							</div>
							<div className='row my-3'>
								<div className='col-12'>
									<Link
										to={
											this.state.queryParam
												? `/signup?${this.state.queryParam}`
												: '/signup'
										}>
										<button className='accountBtn'>Open Account</button>
									</Link>
								</div>
							</div>
							<div className=''>
								<p className='moneyLoginText mb-0 mt-1'>
									Have an Account?{' '}
									<Link to='/signin' className='textDecorationNone'>
										Login
									</Link>
								</p>
							</div>
							<div className='row partnerType pt-4'>
								<div className='col-12'>
									<p className='trusted'>
										Trusted by top financial institutions
									</p>
								</div>
								<div className='col-12'>
									<div className='row pl-3'>
										<img src={partnerlogos} width='96%' />
										{/* <div className="col-4">
                      <img src={moneyControlHDFCsecurities} width="100%" />
                    </div>
                    <div className="col-4">
                      <img src={moneyControlMotilal} width="100%" height="23" />
                    </div>
                    <div className="col-4">
                      <img src={moneyControlGeojit} width="100%" height="23" />
                    </div> */}
									</div>
								</div>
								<div className='moneyControlDisclaimer mt-3'>
									*DriveWealth LLC, a member of the Securities Investor
									Protection Corporation (SIPC), will serve as the custodian for
									your securities account. In the event that DriveWealth LLC
									fails and is placed in liquidation under the Securities
									Investor Protection Act, securities in your brokerage account
									may be protected up to $500,000. For details, please see{' '}
									<a href='https://www.sipc.org/' target='_blank'>
										www.sipc.org
									</a>
								</div>
								{/* <div className="col-12 py-3 pb-5">
                  <p className="moneyLoginText mb-0 mt-1">Have an Account? <Link to="/signin" className="textDecorationNone">Login</Link></p>
                </div> */}
							</div>
						</div>
						<div className='col-lg-7 col-12 companyCard'>
							{/* new try  */}
							<div className='row px-3 rightColumn'>
								<div className='col-md-3 p-0'>
									<Slider {...settings}>
										<div className='whiteCard'>
											<img className='insideImage' src={ggl} />
										</div>
										<div className='whiteCard'>
											<img className='insideImage' src={msft} />
										</div>
										<div className='whiteCard'>
											<img className='insideImage' src={spotify} />
										</div>
										<div className='whiteCard'>
											<img className='insideImage' src={coca} />
										</div>
									</Slider>
								</div>

								<div className='col-md-3 p-0 ml-0'>
									<Slider {...settings1}>
										<div className='whiteCard'>
											<img className='insideImage' src={tesla} />
										</div>
										<div className='whiteCard'>
											<img className='insideImage' src={coca} />
										</div>
										<div className='whiteCard'>
											<img className='insideImage' src={amzn} />
										</div>
										<div className='whiteCard'>
											<img className='insideImage' src={msft} />
										</div>
									</Slider>
								</div>
								<div className='col-md-3 p-0 ml-0'>
									<Slider {...settings}>
										<div className='whiteCard'>
											<img className='insideImage' src={apple} />
										</div>
										<div className='whiteCard'>
											<img className='insideImage' src={ups} />
										</div>
										<div className='whiteCard'>
											<img className='insideImage' src={uber} />
										</div>
										<div className='whiteCard'>
											<img className='insideImage' src={mcd} />
										</div>
									</Slider>
								</div>
								<div className='col-md-3 p-0'>
									<Slider {...settings1}>
										<div className='whiteCard'>
											<img className='insideImage' src={nike} />
										</div>
										<div className='whiteCard'>
											<img className='insideImage' src={mcd} />
										</div>
										<div className='whiteCard'>
											<img className='insideImage' src={disney} />
										</div>
										<div className='whiteCard'>
											<img className='insideImage' src={uber} />
										</div>
									</Slider>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default MoneyControl
