import React from "react";
import styles from './stockdetail.module.css'

const StockBanner = () => {
    return(
        <div className={styles.banner}>
            Effective for trading beginning May 21, 2024, fractional buy trading capabilities will not be available for Berkshire Hathaway Inc Class A (BRK.A) shares.
        </div>
    )
}

export default StockBanner