import React, { useContext, useEffect, useState } from "react"
import DownloadIcon from "../../../../public/images/downloadSecondary.svg"
import NoteIcon from "../../../../public/images/note.svg"
import { fetchSavingAccount, getVirtualAccountDetail, lrsPDF } from "../../../../api"
import HDFCTable from "../Online/HDFCTable"
import ICICITable from "../Online/ICICITable"
import KOTAKTable from "../Online/KotakTable"
import TableError from "../Online/TableError"
import NonIndianTable from "../Online/NonIndianTable"

import DownloadInstruction from "./DownloadInstruction"
import style from "./common.module.css"
import { Spinner } from "react-bootstrap"
import { AddFundContext } from "../../AddFundContext"

const Beneficiary= ({bank})=>{
    const [accountDetail, setAccountDetail] = useState(null)
    const [loadingPDF, setLoadingPDF] = useState(false)
    const [tableError, setTableError] = useState(false)
    const [loadingDetails, setLoadingDetails] = useState(false)
    const [PDFError, setPDFError] = useState(false)
    const {accountType, savingDetails, setSavingDetails} = useContext(AddFundContext)
    useEffect(()=>{
        fetchAccountDetail()
    },[])
    
    const fetchAccountDetail = async()=>{
        if(!loadingDetails){
            setLoadingDetails(true)
            try{
                let brokerDetail=null
                if(accountType==='Saving'){
                    if(savingDetails)
                        brokerDetail=savingDetails
                    else{
                        const res = await fetchSavingAccount()
                        if(res && res.data && res.data.code===200)
                        {
                            setSavingDetails(res.data.data)
                            brokerDetail=savingDetails
                            setTableError(false)
                        }else{
                            setTableError(true)
                        }                
                    }
                }
                else{
                    const res = await getVirtualAccountDetail()
                    if(res  && res.code==200){
                        brokerDetail = res.data
                        if(tableError)
                            setTableError(false)
                    }
                }
                if(brokerDetail){
                    const data={
                        name: brokerDetail && brokerDetail.beneficiaryName ? brokerDetail.beneficiaryName : '--',
                        address: brokerDetail && brokerDetail.beneficiaryAddress ? brokerDetail.beneficiaryAddress  :'--',
                        country: brokerDetail  && brokerDetail.beneficiaryAddressDetails && brokerDetail.beneficiaryAddressDetails.country ? brokerDetail.beneficiaryAddressDetails.country : '--' ,
                        city: brokerDetail && brokerDetail.beneficiaryAddressDetails && brokerDetail.beneficiaryAddressDetails.city ? brokerDetail.beneficiaryAddressDetails.city : '--' ,
                        state: brokerDetail && brokerDetail.beneficiaryAddressDetails && brokerDetail.beneficiaryAddressDetails.stateProvince ? brokerDetail.beneficiaryAddressDetails.stateProvince : '--' ,
                        pinCode: brokerDetail && brokerDetail.beneficiaryAddressDetails && brokerDetail.beneficiaryAddressDetails.zipPostalCode ? brokerDetail.beneficiaryAddressDetails.zipPostalCode : '--' ,
                        contactNumber: brokerDetail && brokerDetail.contactNumber ? brokerDetail.contactNumber : '--' ,
                        currency: brokerDetail && brokerDetail.currency ? brokerDetail.currency : '--' ,
                        nickName: brokerDetail && brokerDetail.nickname ? brokerDetail.nickname : '--',
                        bankName: brokerDetail && brokerDetail.bankName ? brokerDetail.bankName : '--' ,
                        bankAddress: brokerDetail && brokerDetail.bankAddress ? brokerDetail.bankAddress : '--',
                        bankCountry: brokerDetail && brokerDetail.bankAddressDetails && brokerDetail.bankAddressDetails.country ? brokerDetail.bankAddressDetails.country : '--' ,
                        accountNumber: brokerDetail && brokerDetail.bankAccountNumber ? brokerDetail.bankAccountNumber : '--' ,
                        SWIFTCode: brokerDetail && brokerDetail.bankSwiftCode ? brokerDetail.bankSwiftCode  : '--',
                        ABANumber: brokerDetail && brokerDetail.ABANumber ? brokerDetail.ABANumber : '--',
                        achRoutingNumber: brokerDetail && brokerDetail.achRoutingNumber ? brokerDetail.achRoutingNumber:'--',
                        intermediaryBankName: brokerDetail && brokerDetail.intermediaryBankName ? brokerDetail.intermediaryBankName:'--',
                        intermediaryBankSwiftCode: brokerDetail && brokerDetail.intermediaryBankSwiftCode ? brokerDetail.intermediaryBankSwiftCode:'--'
                    }
                    setAccountDetail(data)
                }else{
                    setTableError(true)
                }
                
            }catch(error){
                setTableError(true)
            }
            setLoadingDetails(false)
        }        
    }
    const onDownloadPDF = async()=>{
        if(!loadingPDF){
            setLoadingPDF(true)
            let bankName = bank.replace(' ', '_').toUpperCase()
            try{
                const response= await lrsPDF(bankName)
                if (response.status || response.code === 200) {
                    let href = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));              
                    // download the file
                    let element = document.createElement('a');
                    element.setAttribute('href', href);
                    element.setAttribute('download', '');
                    element.style.display = 'none';
                    document.body.appendChild(element);		
                    element.dispatchEvent(new MouseEvent('click'));
                    document.body.removeChild(element);
                    if(PDFError)
                        setPDFError(false)
                }else{
                    setPDFError(true)
                }
            }catch(error){
                setPDFError(true)
            }
            setLoadingPDF(false)
        }       
    }
    return(<div className={style.instructionContainer}>
        {bank!=="Others" && <>
            <div className={style.instructionHeader}>
                <span className={style.bankName}>{bank}</span>
                <span className={style.helper}>These contain details of the brokerage account into which you will have to send money.</span>
                {!PDFError?<button className={style.downloadPDF} onClick={onDownloadPDF}>
                    {!loadingPDF?<img src={DownloadIcon} />:<Spinner animation="border" className={style.spinnerColor}/>}
                    PDF
                </button>:<DownloadInstruction fetchPDF={onDownloadPDF}/>}
            </div>
        </>}
        {bank!=="Others" && <>
            <div className={style.instructionBody}>
                <div className={style.limitNote}>
                    <img src={NoteIcon}/>
                    <div>There is a limit of $25,000 on online transactions per day. If you need to remit more, please use offline method.</div>
                </div>
            </div>
        </>
        }
        {accountDetail && !tableError&&!loadingDetails&& <>
            {bank==="HDFC Bank" &&<HDFCTable accountDetail={accountDetail}/>}
            {bank==="ICICI Bank" &&<ICICITable accountDetail={accountDetail}/>}
            {bank==="KOTAK Bank" &&<KOTAKTable accountDetail={accountDetail}/>}
            {bank=="Others" && <NonIndianTable accountDetail={accountDetail}/>}
        </>
        }
        {tableError?<TableError fetchBrokerDetails={fetchAccountDetail}/>:loadingDetails&&<div className={style.loadingContainer}>
            <Spinner animation="border" className={`${style.spinnerColor} ${style.tableLoading}`}/>
            Loading...
        </div> }
    </div>)
}

export default Beneficiary