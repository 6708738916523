import React, { useState } from 'react'
import Lottie from 'react-lottie-player';
import Spinner from 'react-bootstrap/Spinner';
import styles from './TransactionListLoadingStates.module.css'
import retryBlue from '../../public/images/retryBlue.svg'
import FetchError from '../../public/lottie/fetchError.json'
import GlobeSpin from '../../public/lottie/globeSpin.json'

const LoadingState = () => {
    return (
        <div className={`${styles.loadingContainer} ${styles.loadingRow}`}>
            <Spinner animation="border" variant="primary" size="sm" />
            <p className={` ${styles.textSmall} ${styles.textGrey}`}>Loading...</p>
        </div>
    )
}

const DataNotFoundState = (props) => {

    const { onTransferFunds } = props

    return (
        <div className={`${styles.loadingContainer} ${styles.loadingColumn}`}>
            <Lottie
                rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
                loop={true}
                animationData={GlobeSpin}
                play
                style={{ width: 112, height: 112 }}
            />
            <p className={`${styles.textBig} ${styles.textBlack}`} style={{margin:"4px 0px 8px 0px"}}>No Transactions Found</p>
            <p className={`${styles.textSmall} ${styles.textGrey}`} style={{marginBottom:"20px"}}>Spend or Send Money anywhere, anytime</p>
            <button className={`${styles.textSmall} ${styles.textBlue} ${styles.loadingButton}`} onClick={onTransferFunds}>
                Transfer Funds
            </button>
        </div>
    )
}

const UnableToFetchDataState = (props) => {

    const { fetchList} = props

    return (
    <div className={`${styles.loadingContainer} ${styles.loadingColumn}`} onClick={fetchList} style={{cursor:"pointer"}}> 
        <Lottie
            rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
            loop={true}
            animationData={FetchError}
            play
            style={{ width: 132, height: 132 }}
        />
        <p className={`${styles.textBig} ${styles.textBlack}`} style={{margin:"4px 0px 8px 0px"}}>Unable to Fetch Data</p>
        <div className={styles.loadingRow} style={{cursor:"pointer"}}>
            <img src={retryBlue} alt='retry-icon'/>
            <p className={` ${styles.textSmall} ${styles.textBlue}`}>Try Again</p>
        </div>
    </div>
    )
}

const AccountDetailsErrorState = (props) => {

    const { fetchData, isLoading } = props

    return (
        <>
            {isLoading ? (
                <div className={styles.loadingRow} style={{width:"100%",padding:"24px"}}>
                    <Spinner animation="border" variant="primary" size="sm" />
                    <p className={` ${styles.textSmall} ${styles.textGrey}`}>Loading...</p>
                </div>
            ): (
                <div className={styles.loadingColumn} onClick={fetchData} style={{cursor:"pointer",width:"100%",padding:"24px"}}> 
                    <Lottie
                        rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
                        loop={true}
                        animationData={FetchError}
                        play
                        style={{ width: 132, height: 132 }}
                    />
                    <p className={`${styles.textBig} ${styles.textBlack}`} style={{margin:"4px 0px 8px 0px"}}>Unable to Fetch Data</p>
                    <div className={styles.loadingRow} style={{cursor:"pointer"}}>
                        <img src={retryBlue} alt='retry-icon'/>
                        <p className={` ${styles.textSmall} ${styles.textBlue}`}>Try Again</p>
                    </div>
                </div>
            )}
        </>
    )
}

export { LoadingState, DataNotFoundState, UnableToFetchDataState, AccountDetailsErrorState}


