import React, { useState, useEffect } from 'react'
import { Row, Col, FormControl, Button } from 'react-bootstrap'
import styles from '../Profile.module.css'
// import InputLabel from '../../InputLabel/InputLabel'
import axios from 'axios'
import { validateOtp } from '../../../api'

const VerifyBasicOtp = props => {
  const [otp, setOtp] = useState('')
  const [seconds, setSeconds] = React.useState(180)
  // const [sixDigitOtp, setSixDigitOtp] = useState(false)
  const [error, setError] = useState('')
  const [resendButtonToggle, setResendButtonToggle] = useState(false)

  const onChange = event => {
    const re = /^[0-9\b]+$/;
    if (event.target.value.length <= 6 && re.test(event.target.value) || event.target.value === '') {
      setOtp(event.target.value)
    } else {
      setError('')
    }
  }

  // const onChange = (event) => {
  // 	setOtp(event.target.value);
  // };

  // const validate = () => {

  // };

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000)
    } else {
      setSeconds(0)
      setResendButtonToggle(true)
    }
  })

  // const onVerifyOtp = () => {
  // 	setIsVerifyOtp(true)
  // }

  const verifyingOtp = async () => {
    setError('')
    if (otp.length === 6) {
      try {
        const response = await validateOtp(props.hashId, otp)
        console.log('prop', response)
        if (response && response.code && response.code === 200) {
          props.onVerifyOtp(true)
          props.getOtp(otp)
        }
        else {
          setError('OTP is not valid')
        }
      } catch (error) {
        // console.log(error)
        setError('Invalid OTP')
      }
    } else {
      setError('Enter a 6-digit OTP')
      // setSixDigitOtp(true);
    }
  }

  const resendOtp = () => {
    props.sendOtpApi('change_password')
    setSeconds(180)
    setResendButtonToggle(false)
  }

  return (
    <div className={styles.otpBodyWidth}>
      <Row className={styles.verifyOtpText}>Enter OTP to {props.profile}</Row>
      <Row className={styles.verifyOtpTextResponsive}>
        Enter OTP to {props.profile}
      </Row>
      <Row className={styles.enterOtpText}>
        We have sent an OTP to your registered email id
      </Row>
      <Row className={styles.mobileNote}>Please enter OTP</Row>
      <Row className='m-0 mt-2'>
        <input
          // type='number'
          placeholder='Enter 6-digit OTP'
          value={otp}
          className={
            error ? styles.profileErrorInputBox : styles.profileInputBox
          }
          maxLength="6"
          onChange={onChange}
          onPaste={(e) => {
            e.preventDefault()
            return false;
          }} onCopy={(e) => {
            e.preventDefault()
            return false;
          }}
        />
        <p className={styles.profileErrorText}>{error}</p>
      </Row>
      <Row className='mt-4'>
        <Col md={6} xs={6} className={styles.otpText}>
          {seconds} sec left
        </Col>
        <Col md={6} xs={6} className='text-right'>
          <p className={styles.noOtp}>
            Didn’t get OTP?{" "}
            {resendButtonToggle ? (
              <span className={styles.otpResend} onClick={resendOtp}>
                Resend
              </span>
            ) : (
              <span className={styles.otpResend} style={{ color: "#767c8f" }}>Resend</span>
            )}
          </p>
        </Col>
      </Row>
      <Row className={styles.buttonMargin}>
        <Button
          className={`${styles.saveButton} themeButton`}
          style={{
            height: '48px',
            width: '122px',
            backgroundColor: props.buttonColor
          }}
          onClick={verifyingOtp}>
          Verify OTP
        </Button>
      </Row>
    </div>
  )
}

export default VerifyBasicOtp
