import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { Formik } from 'formik'
import OutsideClickHandler from 'react-outside-click-handler'
import * as yup from 'yup'
import DatePicker from '../DatePicker/DatePicker'
import CheckBox from '../CheckBox/CheckBox'
import DropDown from '../DropDown/DropDown'
import styles from './AccountFundingSetUp.module.css'

const LrsPickUp = ({
	pickupSlotOptions,
	countryOptions,
	checkedLrsPick,
	onCheckedLrsPick,
	dateofPickup,
	timeSlotPickup,
	pickupAddressLine1,
	pickupAddressLine2,
	pickupCity,
	pickupCountry,
	pickupPostalCode,
	pickupState,
	onChangeHandler,
	showCalendar,
	toggleCalender,
	onOutsideClick,
	onHandleDate,
	formatDateDisplay
}) => {
	return (
		<>
			<Row className={styles.title}>
				Should we pick the LRS form up from you?
			</Row>
			<Row className={styles.detail} style={{ marginTop: '5px' }}>
				<b style={{ display: 'contents' }}>What is this?</b> These details will
				be used for assisting in LRS pickup and delivery this.
			</Row>
			<Row className={styles.marginCheckBox}>
				<CheckBox
					label='Yes, pick the form up from me'
					id='checkedLrsPick'
					name='checkedLrsPick'
					onChange={onCheckedLrsPick}
					isChecked={checkedLrsPick}
				/>
			</Row>
			<Formik
				initialValues={{
					dateofPickup,
					timeSlotPickup,
					pickupAddressLine1,
					pickupAddressLine2,
					pickupCountry,
					pickupPostalCode,
					pickupCity,
					pickupState
				}}
				validationSchema={yup.object({
					dateofPickup: yup.string().required('Date of pickup is required'),
					timeSlotPickup: yup
						.string()
						.required('Time Slot for pickup is required'),
					pickupAddressLine1: yup
						.string()
						.required('Address Line 1 is required'),
					pickupAddressLine2: yup
						.string()
						.required('Address Line 2 is required'),
					pickupCountry: yup.string().required('Country is required'),
					pickupPostalCode: yup.string().required('Postal Code is required'),
					pickupCity: yup.string().required('City is required'),
					pickupState: yup.string().required('State is required')
				})}>
				{({
					handleChange,
					handleBlur,
					handleSubmit,
					values,
					touched,
					errors
				}) => {
					return (
						<>
							<Row className={styles.inputRow}>
								<Col>
									<Form.Group
										className={`form-focus ${
											values.dateofPickup !== '' ? 'focused' : null
										}`}>
										<img
											src={require('../../public/images/calendarIcon.png')}
											className={styles.calendaricon}
											onClick={toggleCalender}></img>
										<Form.Control
											className={styles.inputHeight}
											type='text'
											name='dateofPickup'
											value={formatDateDisplay(dateofPickup)}
											readOnly={true}
											onChange={event => {
												onChangeHandler(event)
												handleChange(event)
											}}
											onBlur={handleBlur}
											onClick={toggleCalender}
											isInvalid={touched.dateofPickup && errors.dateofPickup}
											isValid={
												touched.dateofPickup && !errors.dateofPickup
											}></Form.Control>
										<Form.Label className='focus-label'>
											Date of Pickup
										</Form.Label>
										<Form.Control.Feedback type='invalid'>
											{errors.dateofPickup}
										</Form.Control.Feedback>
									</Form.Group>
									{showCalendar ? (
										<div className={styles.calendar}>
											<OutsideClickHandler onOutsideClick={onOutsideClick}>
												<DatePicker
													onChange={date => {
														onHandleDate(date)
														const formatedDate = formatDateDisplay(date)
														const event = {
															target: {
																name: 'dateofPickup',
																value: formatedDate
															}
														}
														handleChange(event)
													}}
													date={new Date()}
													color='#0061D3'
												/>
											</OutsideClickHandler>
										</div>
									) : (
										''
									)}
								</Col>
								<Col>
									<DropDown
										placeholder='Time Slot for Pickup'
										data={pickupSlotOptions}
										name='timeSlotPickup'
										value={values.timeSlotPickup}
										onChange={value => {
											const event = {
												target: { name: 'timeSlotPickup', value }
											}
											onChangeHandler(event)
											handleChange(event)
										}}
										className={styles.inputHeight}
									/>
									<Form.Control.Feedback
										type='invalid'
										style={{ display: 'block' }}>
										{touched.timeSlotPickup && errors.timeSlotPickup
											? errors.timeSlotPickup
											: ''}
									</Form.Control.Feedback>
								</Col>
							</Row>
							<Row className={styles.detail} style={{ fontWeight: 'bold' }}>
								Pickup Address
							</Row>
							<Row className={styles.inputRow}>
								<Col>
									<Form.Group
										className={`form-focus ${
											values.pickupAddressLine1 !== '' ? 'focused' : null
										}`}>
										<Form.Control
											className={styles.inputHeight}
											type='text'
											name='pickupAddressLine1'
											value={values.pickupAddressLine1}
											onChange={event => {
												onChangeHandler(event)
												handleChange(event)
											}}
											onBlur={handleBlur}
											isInvalid={
												touched.pickupAddressLine1 && errors.pickupAddressLine1
											}
											isValid={
												touched.pickupAddressLine1 && !errors.pickupAddressLine1
											}></Form.Control>
										<Form.Label className='focus-label'>
											Address Line 1
										</Form.Label>
										<Form.Control.Feedback type='invalid'>
											{errors.pickupAddressLine1}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
							</Row>
							<Row className={styles.inputRow}>
								<Col>
									<Form.Group
										className={`form-focus ${
											values.pickupAddressLine2 !== '' ? 'focused' : null
										}`}>
										<Form.Control
											className={styles.inputHeight}
											type='text'
											name='pickupAddressLine2'
											value={values.pickupAddressLine2}
											onChange={event => {
												onChangeHandler(event)
												handleChange(event)
											}}
											onBlur={handleBlur}
											isInvalid={
												touched.pickupAddressLine2 && errors.pickupAddressLine2
											}
											isValid={
												touched.pickupAddressLine2 && !errors.pickupAddressLine2
											}></Form.Control>
										<Form.Label className='focus-label'>
											Address Line 2
										</Form.Label>
										<Form.Control.Feedback type='invalid'>
											{errors.pickupAddressLine2}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
							</Row>
							<Row className={styles.inputRow}>
								<Col>
									<DropDown
										placeholder='Country'
										data={countryOptions}
										name='pickupCountry'
										value={values.pickupCountry}
										onChange={value => {
											const event = {
												target: { name: 'pickupCountry', value }
											}
											onChangeHandler(event)
											handleChange(event)
										}}
										className={styles.inputHeight}
									/>
									<Form.Control.Feedback
										type='invalid'
										style={{ display: 'block' }}>
										{touched.pickupCountry && errors.pickupCountry
											? errors.pickupCountry
											: ''}
									</Form.Control.Feedback>
								</Col>
								<Col>
									<Form.Group
										className={`form-focus ${
											values.pickupPostalCode !== '' ? 'focused' : null
										}`}>
										<Form.Control
											className={styles.inputHeight}
											type='text'
											name='pickupPostalCode'
											value={values.pickupPostalCode}
											onChange={event => {
												onChangeHandler(event)
												handleChange(event)
											}}
											onBlur={handleBlur}
											isInvalid={
												touched.pickupPostalCode && errors.pickupPostalCode
											}
											isValid={
												touched.pickupPostalCode && !errors.pickupPostalCode
											}></Form.Control>
										<Form.Label className='focus-label'>Postal Code</Form.Label>
										<Form.Control.Feedback type='invalid'>
											{errors.pickupPostalCode}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
							</Row>
							<Row className={styles.inputRow}>
								<Col>
									<Form.Group
										className={`form-focus ${
											values.pickupCity !== '' ? 'focused' : null
										}`}>
										<Form.Control
											className={styles.inputHeight}
											type='text'
											name='pickupCity'
											value={values.pickupCity}
											onChange={event => {
												onChangeHandler(event)
												handleChange(event)
											}}
											onBlur={handleBlur}
											isInvalid={touched.pickupCity && errors.pickupCity}
											isValid={
												touched.pickupCity && !errors.pickupCity
											}></Form.Control>
										<Form.Label className='focus-label'>City</Form.Label>
										<Form.Control.Feedback type='invalid'>
											{errors.pickupCity}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
								<Col>
									<Form.Group
										className={`form-focus ${
											values.pickupState !== '' ? 'focused' : null
										}`}>
										<Form.Control
											className={styles.inputHeight}
											type='text'
											name='pickupState'
											value={values.pickupState}
											onChange={event => {
												onChangeHandler(event)
												handleChange(event)
											}}
											onBlur={handleBlur}
											isInvalid={touched.pickupState && errors.pickupState}
											isValid={
												touched.pickupState && !errors.pickupState
											}></Form.Control>
										<Form.Label className='focus-label'>State</Form.Label>
										<Form.Control.Feedback type='invalid'>
											{errors.pickupState}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
							</Row>
						</>
					)
				}}
			</Formik>
		</>
	)
}

export default LrsPickUp
