import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import styles from './stockOfTheDay.module.css'
import { getStockOfTheDay, getHighlights } from '../../api'
import TrendingStocksCard from '../TrendingStocksCard/TrendingStocksCard'
import Slider from 'react-slick'
import peopleImage from '../../public/images/people.png'

const data = [[40.9], [50.5], [60.4], [70], [80], [120], [120], [150], [60]]

const DayStock = () => {
	const [current, setcurrent] = useState(0)

	const [stockBoughtData, setStockroughtData] = useState([])

	const [stockData, setStockData] = useState([])

  const [highlightsData, setHighlightsData] = useState({})

  const [stockName, setStockName] = useState([])

  const  [allHighlightdedMobileData, setAllHighlightedMobileData] = useState([])

  const [loading, setLoading] = useState(false)

  const [mobileStockData, setMobileStockData] = useState([])


	useEffect(() => {
		const asyncfetch = async () => {
			const res = await getStockOfTheDay()
			const updatedBoughtData = []
      const stockNameData = []
			res &&
				res.slice(0, 5).map((item, index) => {
					updatedBoughtData.push(item.boughtByPeople)
          stockNameData.push(item.code)
				})
      setStockName(stockNameData)
			setStockroughtData(updatedBoughtData)
			res && setStockData([...res])
      setcurrent(1)
      setLoading(true)
		}
		asyncfetch()
	}, [])

  const fetchHighlightData = async () => {
    try{
      const [highlights] = await Promise.all([
        getHighlights(stockData && stockData.length > 0  && stockData[current].code),
      ])
      setHighlightsData(highlights)
    }catch(error){
      console.log('error in watching api')
    }
  }

  useEffect(() => {
      if(stockData && stockData.length > 0){
      fetchHighlightData()
    }
  }, [current])

//   useEffect(() => {
//     console.log('stockName mobile useEffect',stockName)
//     if(stockName && stockName.length > 0){
//       for(let i=0 ; i <= stockName.length-1; i++){
//       fetchMobileHighlightsData(stockName[i])
//     }
//   }
// }, [current])

//   const mobileWatchingData = []
//   const fetchMobileHighlightsData = async (code) => {
//     const res = await getHighlights(code)
//     getHighlights(code).then(res =>{
//       Object.entries(res).slice(1,2).map((item,code) => {
//         mobileWatchingData.push(item[1])
//       });
//       setAllHighlightedMobileData(mobileWatchingData)
//     }
//     )
//   }

  useEffect(() => {
    if(stockData && stockData.length > 0){
    fetchMobileHighlightsData()
    }
  }, [loading])

  const fetchMobileHighlightsData = async() => {
    const tempArray = [];
    if(stockData && stockData.length > 0){
      stockData.forEach(async(element) => {
        try{
        const res = await getHighlights(element.code)
        const tempObj = {...element}
        tempObj.watching = res.watching;
        tempArray.push(tempObj)
        // const ValueObj = {[stockName[i]]:res.watching}
        // mobileWatchingData.push(ValueObj)
        // setAllHighlightedMobileData(mobileWatchingData)
      }catch(error){
        console.error('mobile watching error',error)
      }});
    }
    // if(tempArray.length)
    setMobileStockData(tempArray)
  }

	const onNext = () => {
		setcurrent(current + 1)
	}

	const onPrev = () => {
		setcurrent(current - 1)
	}
	var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    className: 'center',
    centerMode: true,
    centerPadding: '90px',
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 1,
          centerPadding: '70px',
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          centerPadding: '90px',
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          centerPadding: '70px',
          slidesToScroll: 1,
          infinite: true
        }
      }
    ]


		// dots: false,
		// className: 'center',
		// // autoplay: true,
		// autoplaySpeed: 3000,
		// infinite: true,
		// centerMode: true,
		// centerPadding: '60px',
    // infinite: true,
		// // speed: 500,
		// slidesToShow: 1,
		// slidesToScroll: 1,
		// horizontal: true,
		// responsive: [
		// 	{
		// 		breakpoint: 769,
		// 		settings: {
		// 			slidesToShow: 2,
		// 			slidesToScroll: 1,
		// 			centerPadding: '90px',
    //       infinite: true
		// 		}
		// 	},
		// 	{
		// 		breakpoint: 480,
		// 		settings: {
		// 			slidesToShow: 1,
		// 			slidesToScroll: 1,
		// 			centerPadding: '50px',
    //       infinite: true
		// 		}
		// 	}
		// ]
	}

  return (
		<>
			<Row className='d-none d-lg-block'>
				<Col md={12} lg={12}>
					<div className={styles.cardHead}>
						<p className={styles.heading}>Stocks of the Day</p>
						<div className={styles.slides}>
							<div className={current === 1 ? styles.slider1 : styles.slider3}>
								<TrendingStocksCard
									stockImage={stockData[1] ? stockData[1].logo : ''}
									stockTitle={stockData[1] ? stockData[1].code : ''}
									stockName={stockData[1] ? stockData[1].company : ''}
									stockValue={stockData[1] ? stockData[1].price : ''}
									stockIncrement={
										stockData[1] ? stockData[1].percentChange : ''
									}
									stockChange={stockData[1] ? stockData[1].change: ''}
									// graphData={data}
									increment={true}
									style={{
										backgroundColor: current === 1 ? '' : '#F7F9F9 ',
										margin: '20px',
										borderRadius: '10px'
									}}></TrendingStocksCard>
							</div>
							<div
								className={
									current === 1
										? styles.slider3
										: current === 2
										? styles.slider1
										: styles.slider2
								}>
								<TrendingStocksCard
									stockImage={stockData[2] ? stockData[2].logo : ''}
									stockTitle={stockData[2] ? stockData[2].code : ''}
									stockName={stockData[2] ? stockData[2].company : ''}
									stockValue={stockData[2] ? stockData[2].price : ''}
									stockIncrement={
										stockData[2] ? stockData[2].percentChange : ''
									}
									stockChange={stockData[2] ? stockData[2].change: ''}
									// graphData={data}
									increment={false}
									style={{
										backgroundColor:
											current === 1
												? '#F7F9F9'
												: current === 2
												? ''
												: '#FBFCFC',
										margin: '20px',
										borderRadius: '10px'
									}}></TrendingStocksCard>
							</div>
							<div className={current === 3 ? styles.slider1 : styles.slider2}>
								<TrendingStocksCard
									stockImage={stockData[3] ? stockData[3].logo : ''}
									stockTitle={stockData[3] ? stockData[3].code : ''}
									stockName={stockData[3] ? stockData[3].company : ''}
									stockValue={stockData[3] ? stockData[3].price : ''}
									stockIncrement={
										stockData[3] ? stockData[3].percentChange : ''
									}
									stockChange={stockData[3] ? stockData[3].change: ''}
									// graphData={data}
									increment={true}
									style={{
										backgroundColor: current === 3 ? '' : '#FBFCFC ',
										margin: '20px',
										borderRadius: '10px'
									}}></TrendingStocksCard>
							</div>
							<div className={current === 4 ? styles.slider1 : styles.slider2}>
								<TrendingStocksCard
									stockImage={stockData[4] ? stockData[4].logo : ''}
									stockTitle={stockData[4] ? stockData[4].code : ''}
									stockName={stockData[4] ? stockData[4].company : ''}
									stockValue={stockData[4] ? stockData[4].price : ''}
									stockChange={stockData[4] ? stockData[4].change: ''}
									stockIncrement={
										stockData[4] ? stockData[4].percentChange : ''
									}
									// graphData={data}
									increment={false}
									style={{
										backgroundColor: current === 4 ? '' : '#FBFCFC ',
										margin: '20px',
										borderRadius: '10px'
									}}></TrendingStocksCard>
							</div>
              <div className={current === 5 ? styles.slider1 : styles.slider2}>
								<TrendingStocksCard
									stockImage={stockData[5] ? stockData[5].logo : ''}
									stockTitle={stockData[5] ? stockData[5].code : ''}
									stockName={stockData[5] ? stockData[5].company : ''}
									stockValue={stockData[5] ? stockData[5].price : ''}
									stockIncrement={
										stockData[5] ? stockData[5].percentChange : ''
									}
									stockChange={stockData[5] ? stockData[5].change: ''}
									// graphData={data}
									increment={false}
									style={{
										backgroundColor: current === 5 ? '' : '#FBFCFC ',
										margin: '20px',
										borderRadius: '10px'
									}}></TrendingStocksCard>
							</div>
						</div>
						<div className={`${styles.note} mb-4`}>
							<img
								src={require('../../public/images/linechartincrement.svg')}
								className='mr-2'></img>
							{/* {stockBoughtData[current - 1]} people brought it */}
              {/* {stockData && stockData.watching !== undefined && stockData[current].watching} people watching */}
              {highlightsData && highlightsData.watching && highlightsData.watching >= 0 ? highlightsData.watching : "0" } people watching
						</div>
						<div>
							<button
								onClick={onPrev}
								className={`btn ${styles.carouselButton} mr-2`}
								disabled={current === 1 ? true : false}>
								<i className='fa fa-angle-left' aria-hidden='true'></i>
							</button>
							<button
								onClick={onNext}
								className={`btn ${styles.carouselButton}`}
								disabled={current === 5 ? true : false}>
								<i className='fa fa-angle-right' aria-hidden='true'></i>
							</button>
						</div>
					</div>
				</Col>
			</Row>
			<Row className='d-md-block d-lg-none'>
				<Col style={{ padding: '0px' }}>
					<div className={styles.cardHead}>
						<p className={styles.heading}>Stocks of the day</p>

						<Slider {...settings} className={`${styles.buttonRemove} ${styles.StockOfTheDaySlider}`}>
							{mobileStockData &&
								mobileStockData.length &&
								mobileStockData.slice(0,5).map((item, i) => {
									return (
                    <>
										<div
											// style={{
											// 	height: '138px',
											// 	width: '188px',
											// 	borderRadius: '4px'
											// }}
                      >
											<TrendingStocksCard
												stockImage={item.logo ? item.logo : ''}
												stockTitle={item.code ? item.code : ''}
												stockName={item.company ? item.company : ''}
												stockValue={item.price ? item.price : ''}
												stockChange={item.change ? item.change : ''}
												stockIncrement={
													item.percentChange ? item.percentChange : ''
												}
												increment={false}
												style={{
													backgroundColor: current === 4 ? '' : '#FBFCFC ',
													margin: '20px',
													borderRadius: '10px'
												}}
                        ></TrendingStocksCard>
										</div>
                    <div className={`${styles.note} mb-4`}>
                      <img
                        src={require('../../public/images/linechartincrement.svg')}
                        className='mr-2'></img>
                        {item.watching !== undefined ?
                          <>{item.watching} people watching</> : ""}
                          {/* <span>{allHighlightdedMobileData[0]}</span> */}
                    </div>
                    </>
									)
								})}
						</Slider>
					</div>
				</Col>
			</Row>
		</>
	)
}

export default DayStock
