import React, { useEffect, useState } from 'react'
import { Row, Col,Card, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Rating from 'react-rating'
import styles from './StackDetail.module.css'
import star from '../../public/images/Star.png'
import starfilled from '../../public/images/Starfilled.png'
import {StackDetailSkeleton} from '../../skeletons'
import info from '../../public/images/info.svg'

const riskMeter = [
	'Low Risk',
	'Moderate Low Risk',
	'Moderate Risk',
	'Moderate High risk',
	'High Risk'
]

const StackDetail = ({
	image='',
	ticker='',
	creator='',
  createdByTitle='',
  createdByProfilePictureLink='',
	risk=0,
	ranking,
	indexValue=0,
	cagr=0,
  cagrInfo
}) => {

  const [ratings,setRatings] = useState(0)

  useEffect(() => {
    let temp
    if( ranking >= 1 && ranking <= 3){
     temp = 5
    }
    else if(ranking >= 4 && ranking <= 7){
      temp = 4.5
    }
    else if(ranking >= 8 && ranking <= 11){
      temp = 4
    }
    else if(ranking >= 12 && ranking <= 15){
      temp = 3.5
    }
    // else if(ranking == 100){
    //   temp = 0
    // }
    else{
      temp = 3
    }
    setRatings(temp)
  },[ranking])

	const riskDesc = riskMeter.filter((item, index) => {
		if (risk === index + 1) {
			return true
		}
	})

  const showCagrInfo = (cagrInfo) => {
    if(cagrInfo.includes('yr')||cagrInfo.includes('Yr')){
      let x = cagrInfo.slice(0,1)
      return `${x}Y`
    }
    else if(cagrInfo.includes('month')||cagrInfo.includes('Month')){
      let x = cagrInfo.slice(0,1)
      return `${x}Mo`
    } else {
      return ''
    }
  }

	return (
    <>
    {image === '' && ticker === '' ?
    <StackDetailSkeleton/> :
    <>
		<Row>
			<Col md={1}  className={`${styles.imageMargin} d-none d-lg-block`}>
				<img src={image} className={styles.image}></img>
			</Col>
			<Col>
				<div className={styles.stackTicker}>{ticker}</div>
				<div className={styles.stackCreator}>{creator}</div>
				<div
					className={styles.risk}
					style={risk < 4 ? { color: '#34b220' } : {}}>
					{riskDesc}
				</div>
        <div className='d-block d-lg-none'>
          <div className={styles.stackCreator} style={{marginTop:'12px',marginBottom:'4px'}}>Created by</div>
          <div className={styles.craeterProfile}><img src={createdByProfilePictureLink} style={{width:'15px',marginRight:'8px'}}></img>{createdByTitle}</div>
        </div>
			</Col>

       {/* for mobile */}
      <Col xs={3}  className={`${styles.imageMargin} d-block d-lg-none`}>
				<img src={image} className={styles.image}></img>
			</Col>

      {ratings === 0 ? '' :
			<Col md={2} lg={2} className='d-none d-lg-block'>
       <Rating fractions={2} initialRating={ratings} readonly emptySymbol={<img src={star}></img>} fullSymbol={<img src={starfilled}></img>}/>
				<div className={`${styles.stackCreator} text-left`} style={{marginTop:'13px'}}>
					Popularity rating
				</div>
			</Col> }
			<Col md={2} lg={2} className='d-none d-lg-block'>
				<div className={`${styles.stackName} text-right`}>${indexValue}</div>
				<div className={`${styles.stackCreator} text-right`}>index value</div>
			</Col>
			<Col md={2} lg={2} className='d-none d-lg-block'>
				<div className={styles.cacrPercentage}>{cagr}%</div>
				<div className={`${styles.stackCreator} text-right`}>
          {/* {showCagrInfo(cagrInfo)}  */}
          CAGR
          {cagrInfo && cagrInfo.length > 0 ?
          <OverlayTrigger
            placement='bottom'
            trigger={['hover', 'focus','click']}
            overlay={
              <Tooltip>
              {cagrInfo}
              </Tooltip>
            }>
            <img src={info} className={styles.toopTipImage}/>
          </OverlayTrigger> : ""}
        </div>
			</Col>
		</Row>


    {/* for mobile */}
    <Card className={`${styles.cardDetail} d-block d-lg-none`}>
      <Card.Body>
        <Row>
         {ratings === 0 ? '' :
          <Col xs={5} md={4} className='pr-0'>
          <Rating fractions={2} initialRating={ratings} readonly emptySymbol={<img src={star}></img>} fullSymbol={<img src={starfilled}></img>}/>
            <div className={`${styles.stackCreator} text-left`} style={{marginTop:'12px'}}>
              Popularity rating
            </div>
          </Col> }
          <Col xs={4} md={4} className='pl-0'>
            <div className={`${styles.stackName} text-right`}>${indexValue}</div>
            <div className={`${styles.stackCreator} text-right`}>index value</div>
          </Col>
          <Col xs={3} md={4} className='pl-0'>
            <div className={styles.cacrPercentage}>{cagr}%</div>
            <div className={`${styles.stackCreator} text-right`}>CAGR</div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
    </>
    }
    </>
	)
}

export default StackDetail
