import React, { Fragment } from 'react';
import { Row, Col } from 'react-bootstrap'
import styles from './instruction.module.css'
import infoIcon from '../../public/images/info-icon.svg'
import noteImage from '../../public/images/note.png'
import arrow from '../../public/images/icon-arrow-right.svg'
import {circleA, circleB, circleC, circleD, circleE, circleF, circleG, circleH, circleI, circleJ, circleK, circleL, circleM} from './assets'
import Copy from './copy'
import DownloadInstruction from './downloadInstruction';
import TableError from './tableError'

const KotakLrs= ({info, fetchBrokerDetails}) => {
	const cloudfront = `${process.env.REACT_APP_ASSESTS}/platform/fund/kotak`
	return(
		<>
			<Row>
				<Col>
					<div className={styles.bankName}>Kotak Bank</div>
				</Col>
				<Col style={{ textAlign: 'end'}} className='pl-0'>
					<DownloadInstruction bank={'KOTAK_BANK'}/>
				</Col>
				<Col md={12} style={{ marginTop: '30px',marginBottom:"30px" }}>
					<div className={styles.transactionCard}>
						<img src={noteImage} />
						<span className={styles.transaction}>
							There is a limit of $25,000 on online transaction per day. If you
							need to remit more, please use Offline Transfer.
						</span>
					</div>
				</Col>
			</Row>
			<Row>
				<div className={`card-body ${styles.cardBody} ${styles.responsiveInstructions}`}>
					<ol className={styles.list} >
						<li>
							<div className={styles.netbanking}>
								Login to your Kotak Netbanking Account.
							</div>
							<div className={styles.login}>
								<a href='https://netbanking.kotak.com/knb2/' target="_blank" rel="noopener noreferrer" styles={{color:"#0061D3"}}>
									{`Login to Netbanking`}
								</a>
								<a href='https://netbanking.kotak.com/knb2/' target="_blank" rel="noopener noreferrer">
									<img src={arrow} alt="arrow" style={{marginLeft:"7px"}}/>
								</a>
							</div>
						</li>
						<li>
							<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
								Click on &nbsp;
								<img src={circleA} className={styles.circularPointer} />
								<span className={styles.key}>Payments/Taxes &nbsp;</span>
								<img src={circleB} className={styles.circularPointer} />
								<span className={styles.key}>Send Money Abroad &nbsp;</span>
							</div>
							<div className={styles.marginStepSecondary}>
								<img src={`${cloudfront}/img1.jpg`} className={styles.responsiveImage}/>
							</div>
						</li>
						<li>
							<div className={`d-flex flex-column align-items-start ${styles.netbanking} ${styles.marginStepPrimary}`}>
								<div className={styles.netbanking}>
									Read the Terms & Conditions and fill the FATCA Declaration.
								</div>
								<div className={styles.netbanking}>
									Tick the box to	&nbsp;
									<img src={circleA} className={styles.circularPointer} />
									<span className={styles.key}>Accept and Agree to the Terms & Conditions</span>
								</div>
								<div className={styles.netbanking}>
									Tick the box and answer the questions to &nbsp;
									<img src={circleB} className={styles.circularPointer} />
									<span className={styles.key}>Agree to the FATCA Declaration</span>
								</div>
								<div className={styles.netbanking}>
									After agreeing to both, click on &nbsp;
									<img src={circleC} className={styles.circularPointer} />
									<span className={styles.key}>Submit</span>
								</div>
								<div className={styles.marginStepSecondary}>
									<img src={`${cloudfront}/img2.jpg`} className={styles.responsiveImage}/>
								</div>
							</div>
						</li>
						<li>
							<div className={`d-flex flex-column align-items-start ${styles.netbanking} ${styles.marginStepPrimary}`}>
								<div className={styles.netbanking}>
									Fill in the transfer details as shown below:
								</div>
								<ol type = "i" className={styles.list}>
									<li>
										<div className={`${styles.netbanking} ${styles.marginStepPrimary} ${styles.leftMargin}`}>
											Enter the Receiving Country as &nbsp;
											<img src={circleA} className={styles.circularPointer} />
											<span className={styles.key}>United States</span>
										</div>
									</li>
									<li>
										<div className={`${styles.netbanking} ${styles.marginStepPrimary} ${styles.leftMargin}`}>
											Select the Receiving Currency as &nbsp;
											<img src={circleB} className={styles.circularPointer} />
											<span className={styles.key}>USD</span>
										</div>
									</li>
									<li>
										<div className={`${styles.netbanking} ${styles.marginStepPrimary} ${styles.leftMargin}`}>
										Enter the &nbsp;
										<img src={circleC} className={styles.circularPointer} />
										<span className={styles.key}>Transfer Amount </span> in USD.
										</div>
									</li>
									<li>
										<div className={`${styles.netbanking} ${styles.marginStepPrimary} ${styles.leftMargin}`}>
										Enter the &nbsp;
										<img src={circleD} className={styles.circularPointer} />
										<span className={styles.key}>Your IFSC Code </span> as per your Bank Branch.
										</div>
									</li>
									<li>
										<div className={`${styles.netbanking} ${styles.marginStepPrimary} ${styles.leftMargin}`}>
										Select &nbsp;
										<img src={circleE} className={styles.circularPointer} />
										<span className={styles.key}>Your Bank Account Number &nbsp;</span>
										and click on &nbsp;
										<img src={circleF} className={styles.circularPointer} />
										<span className={styles.key}>Send Money &nbsp;</span>
										</div>
										<div className={styles.marginStepSecondary}>
										<img src={`${cloudfront}/img3.jpg`} className={styles.responsiveImage}/>
										</div>
									</li>
								</ol>
							</div>
						</li>
						<li>
							<div className={`d-flex flex-column align-items-start ${styles.netbanking} ${styles.marginStepPrimary}`}>
									<div className={styles.netbanking}>
										Add a Beneficiary by selecting ‘Send To’ as &nbsp;
										<img src={circleA} className={styles.circularPointer} />
										<span className={styles.key}>New Beneficiary</span>
									</div>
									<div className={styles.marginStepSecondary}>
										<img src={`${cloudfront}/img4.jpg`} className={styles.responsiveImage}/>
									</div>
							</div>
						</li>
						<li>
							<div className={`d-flex flex-column align-items-start ${styles.netbanking} ${styles.marginStepPrimary}`}>
								<div className={styles.netbanking}>
									Fill in the following columns with the details mentioned under the next point :
								</div>
								<div className={styles.marginStepSecondary}>
										<img src={`${cloudfront}/img5.jpg`} className={styles.responsiveImage}/>
								</div>
							</div>
						</li>
						<li>
							<div className={`${styles.netbanking} ${styles.marginStepPrimary}`}>
								Enter the beneficiary details as shown below:
							</div>
							<div style={{ overflow:'hidden'}} className={`${styles.beneficiaryTable} ${styles.marginStepSecondary} ${!info&&styles.beneficiaryTableError}`}>
								{!info ? <TableError fetchBrokerDetails={fetchBrokerDetails}/>
									:<Fragment>
										<div  className={styles.beneficiaryTableRow}>
											<img src={circleA} className={styles.circularPointer} />
											<div className={styles.beneficiaryTableContent}>
												<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
													Name of Beneficiary Bank:
												</div>
												<div className={styles.beneficiaryTableValue}>
													<span>{info.bankName}</span>
													<Copy data={{label:'Beneficiary Bank Name', value:info.bankName}}/>
												</div>
											</div>
										</div>
										<div  className={styles.beneficiaryTableRow}>
											<img src={circleB} className={styles.circularPointer} />
											<div className={styles.beneficiaryTableContent}>
												<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
													Address of Beneficiary Bank:
												</div>
												<div className={styles.beneficiaryTableValue}>
													<span>{info.bankAddress}</span>
													<Copy data={{label:'Beneficiary Bank Address', value:info.bankAddress}} />
												</div>
											</div>
										</div>
										<div  className={styles.beneficiaryTableRow}>
											<img src={circleC} className={styles.circularPointer} />
											<div className={styles.beneficiaryTableContent}>
												<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
													Beneficiary Bank’s SWIFT Code:
												</div>
												<div className={styles.beneficiaryTableValue}>
													<span>{info.SWIFTCode}</span>
													<Copy data={{label:'Beneficiary SWIFT Code', value:info.SWIFTCode}} />
												</div>
											</div>
										</div>
										<div  className={styles.beneficiaryTableRow}>
											<img src={circleD} className={styles.circularPointer} />
											<div className={styles.beneficiaryTableContent}>
												<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
													Bank’s Routing Number / ABA:
												</div>
												<div className={styles.beneficiaryTableValue}>
													<span>{info.ABANumber}</span>
													<Copy data={{label:'Routing Number/ABA', value:info.ABANumber}} />
												</div>
											</div>
										</div>
										<div className={`${styles.beneficiaryAccNum} ${styles.beneficiaryTableRow}`}>
											<img src={circleE} className={styles.circularPointer} />
											<div className={styles.beneficiaryTableContent}>
												<div>
													<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
														Account Number:
													</div>
													<div className={styles.divider}></div>
												</div>
												<div className={styles.beneficiaryAccNumValue}>
													<div className={styles.beneficiaryTableValue}>
														<span>{info.accountNumber}</span>
														<Copy data={{label:'Beneficiary Account Number', value:info.accountNumber}} />
													</div>
													<div className={styles.divider}></div>
													<div className={styles.beneficiaryAccInfo}>Everyone has an Unique Beneficiary Account Number.</div>
												</div>
											</div>
										</div>
										<div  className={styles.beneficiaryTableRow}>
											<img src={circleF} className={styles.circularPointer} />
											<div className={styles.beneficiaryTableContent}>
												<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
													Receiver Type:
												</div>
												<div className={styles.beneficiaryTableValue}>
													<span>Non-Individual</span>
													<Copy data={{label:'Beneficiary Type', value:'Non-Individual'}} />
												</div>
											</div>
										</div>
										<div  className={styles.beneficiaryTableRow}>
											<img src={circleG} className={styles.circularPointer} />
											<div className={styles.beneficiaryTableContent}>
												<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
													Receiver Name:
												</div>
												<div className={styles.beneficiaryTableValue}>
													<span>{info.name}</span>
													<Copy data={{label:'Beneficiary Name', value:info.name}} />
												</div>
											</div>
										</div>
										<div  className={styles.beneficiaryTableRow}>
											<img src={circleH} className={styles.circularPointer} />
											<div className={styles.beneficiaryTableContent}>
												<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
													Receiver Nick Name:
												</div>
												<div className={styles.beneficiaryTableValue}>
													<span>{info.nickName}</span>
													<Copy data={{label:'Beneficiary Nick Name', value:info.nickName}} />
												</div>
											</div>
										</div>
										<div  className={styles.beneficiaryTableRow}>
											<img src={circleI} className={styles.circularPointer} />
											<div className={styles.beneficiaryTableContent}>
												<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
													Receiver Mobile:
												</div>
												<div className={styles.beneficiaryTableValue}>
													<span>{info.contactNumber}</span>
													<Copy data={{label:'Beneficiary Mobile Number', value:info.contactNumber}} />
												</div>
											</div>
										</div>
										<div  className={styles.beneficiaryTableRow}>
											<img src={circleJ} className={styles.circularPointer} />
											<div className={styles.beneficiaryTableContent}>
												<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
													Receiver Address:
												</div>
												<div className={styles.beneficiaryTableValue}>
													<span>{info.address}</span>
													<Copy data={{label:'Beneficiary Address', value:info.address}} />
												</div>
											</div>
										</div>
										<div  className={styles.beneficiaryTableRow}>
											<img src={circleK} className={styles.circularPointer} />
											<div className={styles.beneficiaryTableContent}>
												<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
													Receiver State:
												</div>
												<div className={styles.beneficiaryTableValue}>
													<span>{info.state}</span>
													<Copy data={{label:'Beneficiary State', value:info.state}} />
												</div>
											</div>
										</div>
										<div  className={styles.beneficiaryTableRow}>
											<img src={circleL} className={styles.circularPointer} />
											<div className={styles.beneficiaryTableContent}>
												<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
													Receiver City:
												</div>
												<div className={styles.beneficiaryTableValue}>
													<span>{info.city}</span>
													<Copy data={{label:'Beneficiary City', value:info.city}} />
												</div>
											</div>
										</div>
										<div  className={styles.beneficiaryTableRow}>
											<img src={circleM} className={styles.circularPointer} />
											<div className={styles.beneficiaryTableContent}>
												<div className={`${styles.key} ${styles.beneficiaryTableKey}`}>
													Receiver Pin Code:
												</div>
												<div className={styles.beneficiaryTableValue}>
													<span>{info.pinCode}</span>
													<Copy data={{label:'Beneficiary Pin Code', value:info.pinCode}} />
												</div>
											</div>
										</div>
									</Fragment>
								}
							</div>
						</li>
						<li>
							<div className={`d-flex flex-column align-items-start ${styles.netbanking} ${styles.marginStepPrimary}`}>
								<div className={styles.netbanking}>
									Select Purpose of Remittance as &nbsp;
									<img src={circleA} className={styles.circularPointer} />
									<span className={styles.key}>Portfolio Investment Abroad in Equity Shares</span>
								</div>
								<div className={styles.netbanking}>
									And click on &nbsp;
									<img src={circleB} className={styles.circularPointer} />
									<span className={styles.key}>Upload Document</span> to upload your recent bank statement.
								</div>
								<div className={styles.marginStepSecondary}>
									<img src={`${cloudfront}/img6.jpg`} className={styles.responsiveImage}/>
								</div>
							</div>
						</li>
						<li>
							<div className={`d-flex flex-column align-items-start ${styles.netbanking} ${styles.marginStepPrimary}`}>
								<div className={styles.netbanking}>
									Verify your Mobile Number using &nbsp;
									<img src={circleA} className={styles.circularPointer} />
									<span className={styles.key}>OTP Verification &nbsp;</span>
									and click on &nbsp;
									<img src={circleB} className={styles.circularPointer} />
									<span className={styles.key}>Next Step</span>
								</div>
								<div className={styles.marginStepSecondary}>
									<img src={`${cloudfront}/img7.jpg`} className={styles.responsiveImage}/>
								</div>
							</div>
						</li>
						<li>
							<div className={`d-flex flex-column align-items-start ${styles.netbanking} ${styles.marginStepPrimary}`}>
								<div className={styles.netbanking}>
								If this is your first time transferring USD for the current financial year,
								</div>
								<div className={styles.netbanking}>
									Leave all fields blank and tick the box to &nbsp;
									<img src={circleB} className={styles.circularPointer} />
									<span className={styles.key}>Agree to the Declaration</span>
								</div>
								<div className={styles.netbanking}>
									Else, fill in your &nbsp;
									<img src={circleA} className={styles.circularPointer} />
									<span className={styles.key}>Remittance Details for the Current Financial Year</span>
								</div>
								<div className={styles.netbanking}>
									Verify the entered details and tick the box to &nbsp;
									<img src={circleB} className={styles.circularPointer} />
									<span className={styles.key}>Agree to the Declaration</span>
								</div>
								<div className={styles.netbanking}>
									Click on &nbsp;
									<img src={circleC} className={styles.circularPointer} />
									<span className={styles.key}>Continue</span>
								</div>
								<div className={styles.marginStepSecondary}>
									<img src={`${cloudfront}/img8.jpg`} className={styles.responsiveImage}/>
								</div>
							</div>
						</li>
						<li>
							<div className={`d-flex flex-column align-items-start ${styles.netbanking} ${styles.marginStepPrimary}`}>
								<div className={styles.netbanking}>
									Read the Terms & Conditions.
								</div>
								<div className={styles.netbanking}>
									Tick the box to &nbsp;
									<img src={circleA} className={styles.circularPointer} />
									<span className={styles.key}>Accept and Agree to the Terms & Conditions</span>
								</div>
								<div className={styles.marginStepSecondary}>
									<img src={`${cloudfront}/img9.jpg`} className={styles.responsiveImage}/>
								</div>
							</div>
						</li>
						<li>
							<div className={`d-flex flex-column align-items-start ${styles.netbanking} ${styles.marginStepPrimary}`}>
								<div className={styles.netbanking}>
									Read the Declaration for Purchase of Foreign Exchange under LRS Remittance Scheme.
								</div>
								<div className={styles.netbanking}>
									Tick the box, verify the details and click on
									<span className={styles.key}>Accept</span>   to,
								</div>
								<div className={styles.netbanking}>
									<img src={circleA} className={styles.circularPointer} />
									<span className={styles.key}>Accept and Agree to the LRS Remittance Form (A2 Form)</span>
								</div>
								<div className={styles.marginStepSecondary}>
									<img src={`${cloudfront}/img10.jpg`} className={styles.responsiveImage}/>
								</div>
							</div>
						</li>
						<li>
							<div className={`d-flex flex-column align-items-start ${styles.netbanking} ${styles.marginStepPrimary}`}>
								<div className={styles.netbanking}>
									Read the Declaration for Making LRS for Portfolio Investment.
								</div>
								<div className={`${styles.netbanking}`}>
									Tick the box, verify the details and click on
									<span className={styles.key}>Accept</span> to,
								</div>
								<div className={styles.netbanking}>
									<img src={circleA} className={styles.circularPointer} />
									<span className={styles.key}>Accept and Agree to the Declaration &nbsp;</span>
									then click on &nbsp;
									<img src={circleB} className={styles.circularPointer} />
									<span className={styles.key}>Confirm Booking</span>
								</div>
								<div className={styles.marginStepSecondary}>
									<img src={`${cloudfront}/img11.jpg`} className={styles.responsiveImage}/>
								</div>
							</div>
						</li>
						<li>
							<div className={`d-flex flex-column align-items-start ${styles.netbanking} ${styles.marginStepPrimary}`}>
								<div className={styles.netbanking}>
									The process to remit funds is now complete!
								</div>
								<div className={styles.netbanking}>
									Please note that it takes
									<span className={styles.key}>2-3 Business Days &nbsp;</span>
									after the Deduction of Funds from your Bank Account for it to reflect in your Investment Account.
								</div>
							</div>
						</li>
					</ol>
				</div>
			</Row>
		</>
	)
}


export default KotakLrs;
