import React, { useContext, useState } from 'react'
import { Card, Col } from 'react-bootstrap'
import { useHistory } from 'react-router'

import EmailForm from './EmailForm'
import VerifyEmail from './VerifyEmail'
import UserName from './UserName'
import NewHeader from '../NewSignupComponents/NavBar/NewHeader'
import styles from './ForgotUserName.module.css'
import OnboardingFooter from '../Footer/OnboardingFooter'
import { ThemeContext } from '../../Context/ThemeContext'
import Logo from '../../public/images/newlogo.svg'

const ForgotUserName = () => {
	const [formData, setFormData] = useState({})
	const [hashId, setHashID] = useState('')
	const [currentForm, setCurrentForm] = useState('EmailForm')
	const { isPartner } = useContext(ThemeContext)
	const showPoweredLogo =
		isPartner && !window.location.href.includes('landing-page')
	const history = useHistory()

	const updateHashId = value => {
		setHashID(value)
	}

	const updateFormData = data => {
		setFormData(Object.assign(data))
	}

	const changeCurrent = formName => {
		setCurrentForm(formName)
	}

	const getCurrentForm = () => {
		switch (currentForm) {
			case 'EmailForm':
				return (
					<EmailForm
						changeCurrent={changeCurrent}
						updateFormData={updateFormData}
						updateHashId={updateHashId}
					/>
				)
			case 'VerifyEmail':
				return (
					<VerifyEmail
						hashID={hashId}
						updateHashId={updateHashId}
						changeCurrent={changeCurrent}
						formData={formData}
						updateFormData={updateFormData}
					/>
				)
			case 'AccountDetected':
				return <UserName formData={formData} changeCurrent={changeCurrent} />
			default:
				break
		}
	}

	return (
		<>
			<NewHeader
				message='Recollected your username?'
				linkName='Log In'
				onClickLink={() => history.push('/signin')}
			/>
			<div className={styles.forgotUserNameContainer}>
				<div
					className={styles.cardWrapper}
					style={{
						width: '100vw',
						display: 'flex',
						justifyContent: 'center',
						direction: 'row'
					}}>
					<Card className={styles.cardSignUp}>
						<Card.Body className={styles.cardBodySignup}>
							{getCurrentForm()}
						</Card.Body>
					</Card>
				</div>
				<div
					style={{
						marginTop: '50px',
						display: 'flex',
						justifyContent: 'center'
					}}>
					{showPoweredLogo ? (
						<Col md={4} xl={4} lg={4} style={{ textAlign: 'center' }}>
							<span className={styles.poweredText}>Powered by</span>
							<img src={Logo} alt='powered by' width='64px' height='16px' />
						</Col>
					) : (
						<Col md={2} xl={3} className={`d-none d-md-flex`}></Col>
					)}
				</div>
			</div>
			<OnboardingFooter />
		</>
	)
}

export default ForgotUserName
