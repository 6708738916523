import React, {useState,useEffect} from 'react'
import { useHistory } from 'react-router-dom'
import {  Spinner} from 'react-bootstrap'
import { getMyFund } from '../../api';
import PickupDetail from './pickupDetail'
import A2Upload from './A2Upload'
import A2wait from './A2wait'
import KYCSetup from './KYCSetup'
import KYCReview from './KYCReview'
import FundTransfer from './fundTransfer'
import fundImage from '../../public/images/fundtransfer.png'
import Statement from './Statement'
import styles from './Fairexpay.module.css'
import ReviewDetail from './ReviewDetail';
import AdditionalDetails from './AdditionalDetails';
import Complete from './Complete'

const Fairexpay = (props) => {
  const {
    setModalData,
    fund,
    stringCollection,
    pickupInformation,
    setPickupInformation,
    hashId,
    previousTransaction,
    setPreviousTransaction,
    previous,
    setprevious,
    transactionCount,
    setTransactionCount,
    disablePrevious,
    setDisablePrevious,
    buttonColor,
    checkBank,
    panFileFront,
    setpanFileFront,
    panFileBack,
    setpanFileBack,
    accountStatement1,
    setaccountStatement1,
    accountStatement2,
    setaccountStatement2,
    accountStatement3,
    setaccountStatement3,
    accountStatement4,
    setaccountStatement4,
    accountStatementCount,
    setaccountStatementCount,
    selectedBank,
    enterBrokerInformation,
    enterBrokerDetail,
    bankDetails
  } = props;
  const flow = {
    LoadingSkeleton:'Loading_Skeleton',
    KYC_REVIEW: 'KYC_REVIEW',
    KYC_SETUP: 'KYC_SETUP',
    ADDITIONAL_DETAILS:'ADDITIONAL_DETAILS',
    ADDITIONAL_KYC_DETAILS:'ADDITIONAL_KYC_DETAILS',
    A2_WAIT: 'A2_WAIT',
    INITIATE_QUOTE:'INITIATE_QUOTE',
    INITIATE_TRANSACTION: 'INITIATE_TRANSACTION',
    COMPLETE_PAYMENT: 'COMPLETE_PAYMENT',
    TRANSACTION_REVIEW: 'TRANSACTION_REVIEW',
    STATEMENT_WAIT: 'STATEMENT_WAIT',
    COMPLETE: 'COMPLETE',
    review:'review',
    Payment:'Payment',
    PaymentStatus :'PaymentStatus',
    Statement:'Statement'
  }


  const statusStep = [
    {'1':'KYC_REVIEW'},
    {'2':'ADDITIONAL_DETAILS'},
    {'3':'INITIATE_QUOTE'},
    {'4':'INITIATE_TRANSACTION'},
    {'5':'STATEMENT_WAIT'}
  ]

  let history = useHistory()
  const [current,setcurrent] = useState(flow.LoadingSkeleton)
  const [paymentlink,setpaymentlink] = useState('')
  const [paymentStatus,setpaymentStatus] = useState(false)
  const [initiateCapitalTransaction, setCapitalTransaction] = useState({});
  const [initiateQuote, setQuote] = useState({});
  const [disableUploadStatement,setdisableUploadStatement] = useState(true);
  const [idProof, setIdProof] = useState(true);
  const [addressProof, setAddressProof] = useState(true);
  const [step,setStep] = useState(1)
  const [fundTitle,setFundTitle] = useState('Complete Payment')
  let timer;
  const [number,setNumber] = useState(0)
  const asyncFetch = async () => {
     timer = setInterval(
        async () => {
          try {
            const res = await getMyFund(hashId)
            if (res && res.code === 200 && res.data.nextStep && res.data.nextStep !== 'COMPLETE') {
              if(res.data.nextStep === flow.STATEMENT_WAIT){
                  setdisableUploadStatement(false)
                  setFundTitle('Upload Bank Statement')
                  if(number === 0){
                   setNumber(number + 1,  setcurrent(flow.COMPLETE_PAYMENT))
                  }
              }
              else {
                if (current !== res.data.nextStep && current !== flow.STATEMENT_WAIT) {
                  setcurrent(res.data.nextStep);
                  // current = res.data.nextStep;
                  setCapitalTransaction(res.data.transaction || {});
                  setQuote(res.data.quote || {});
                }
              }

            } else if (res.data.nextStep === 'COMPLETE') {
              setcurrent(flow.COMPLETE)
              // history.push('/funds');
            }
            else {
              history.push('/funds');
            }
          } catch (error) {
            history.push('/');
          }
        }, 3000);
  }

  const getQuote = async () => {

  }

  useEffect(() => {
   asyncFetch();
  //  const url = 'ADDITIONAL_DETAILS'
  //  let status = flow.ADDITIONAL_DETAILS
  //  if(url.includes('')){
  //    status =flow.ADDITIONAL_DETAILS
  //  }
  //  else if(url.includes('payment')){
  //    status = flow.Payment
  //  }
  //  else if(url.includes('statement')){
  //    status = flow.Statement
  //  }
  //  setcurrent(status)
   return () => {
    clearInterval(timer);
   }
  },[current])


  // useEffect(() => {
  //   const filtered = statusStep.filter((item) => {
  //     console.log(item)
  //      return Object.values(item) == current
  //   })
  //    if(filtered && filtered.length > 0){
  //      setStep(Object.keys(filtered[0]))
  //    }
  // },[current])



  // const getPaymentLink = () => {
  //   setTimeout(() => setpaymentlink('hhhhh'),10000)
  // }

  // const getPaymentStatus = () => {
  //   // setpaymentStatus('Loading')
  //   setTimeout(() => setpaymentStatus(true),10000)
  // }

  // useEffect(() => {
  //   if(paymentlink !== ''){
  //    setcurrent(flow.Payment)
  //   }
  // },[paymentlink])

  // useEffect(() => {
  //  if(paymentStatus){
  //    setcurrent(flow.Statement)
  //  }

  // },[paymentStatus])

  const showscreen = () => {
    if(current === flow.LoadingSkeleton){
      return(
        <div className={styles.card}>
        <div className={styles.spinner}><Spinner animation="border" className={styles.spinnerColor}/></div>
        <div className={styles.note}>Please wait while we fetch details</div>
        </div>
      )
    }
    else if(current === flow.KYC_REVIEW){
      return(
        <KYCReview
          title='Please wait while we verify your KYC information. You will be able to proceed to the next step automatically once the form is signed.'
          hashId={hashId}
          setIdProof={setIdProof}
          setAddressProof={setAddressProof}
        />
      )
    }
    else if(current === flow.KYC_SETUP){
      return(
        <KYCSetup title='An email has been sent to your registered email Id for KYC  via UDAI. Please keep your AADHAR and PAN Number ready before you proceed. You will be redirected to the next step automatically once verification is complete.'
        hashId={hashId}
        setIdProof={setIdProof}
        setAddressProof={setAddressProof}
        />
      )
    } else if (current === flow.ADDITIONAL_DETAILS) {
      return(
        <AdditionalDetails
            setModalData={setModalData}
            setcurrent = {setcurrent}
            back={stringCollection.otherBankAccount}
            flow={flow}
            idProof={idProof}
            pickupInformation={pickupInformation}
            setPickupInformation={setPickupInformation}
            hashId={hashId}
            previousTransaction={previousTransaction}
            setPreviousTransaction={setPreviousTransaction}
            previous={previous}
            setprevious={setprevious}
            transactionCount={transactionCount}
            setTransactionCount={setTransactionCount}
            disablePrevious={disablePrevious}
            setDisablePrevious={setDisablePrevious}
            buttonColor={buttonColor}
            checkBank={checkBank}
            panFileFront={panFileFront}
            setpanFileFront={setpanFileFront}
            panFileBack={panFileBack}
            setpanFileBack={setpanFileBack}
            accountStatement1={accountStatement1}
            setaccountStatement1={setaccountStatement1}
            accountStatement2={accountStatement2}
            setaccountStatement2={setaccountStatement2}
            accountStatement3={accountStatement3}
            setaccountStatement3={setaccountStatement3}
            accountStatement4={accountStatement4}
            setaccountStatement4={setaccountStatement4}
            accountStatementCount={accountStatementCount}
            setaccountStatementCount={setaccountStatementCount}
            setIdProof={setIdProof}
            setAddressProof={setAddressProof}
        />
      )
    }
    else if(current === flow.ADDITIONAL_KYC_DETAILS){
      return(
        <PickupDetail
            setModalData={setModalData}
            setcurrent = {setcurrent}
            back={stringCollection.otherBankAccount}
            flow={flow}
            idProof={idProof}
            pickupInformation={pickupInformation}
            setPickupInformation={setPickupInformation}
            hashId={hashId}
            previousTransaction={previousTransaction}
            setPreviousTransaction={setPreviousTransaction}
            previous={previous}
            setprevious={setprevious}
            transactionCount={transactionCount}
            setTransactionCount={setTransactionCount}
            disablePrevious={disablePrevious}
            setDisablePrevious={setDisablePrevious}
            buttonColor={buttonColor}
            checkBank={checkBank}
            panFileFront={panFileFront}
            setpanFileFront={setpanFileFront}
            panFileBack={panFileBack}
            setpanFileBack={setpanFileBack}
            accountStatement1={accountStatement1}
            setaccountStatement1={setaccountStatement1}
            accountStatement2={accountStatement2}
            setaccountStatement2={setaccountStatement2}
            accountStatement3={accountStatement3}
            setaccountStatement3={setaccountStatement3}
            accountStatement4={accountStatement4}
            setaccountStatement4={setaccountStatement4}
            accountStatementCount={accountStatementCount}
            setaccountStatementCount={setaccountStatementCount}
            setIdProof={setIdProof}
            setAddressProof={setAddressProof}
        />
      )
    }
    else if(current === flow.A2_WAIT){
      return(
        <A2wait title='An email has been sent to your registered email Id for digitally signing the A2 form and proceed to the next step. You will be able to proceed to the next step automatically once the form is signed.'/>
      )
    }
    else if(current === flow.INITIATE_QUOTE){
      return(
        <A2Upload
        buttonTitle = 'Initate Transaction'
        setcurrent={setcurrent}
        next={flow.review}
        buttonColor={buttonColor}
        hashId={hashId}
        initiateQuote={initiateQuote}
        />
      )
    }
    else if(current === flow.INITIATE_TRANSACTION){
      return(
        <ReviewDetail
        setcurrent={setcurrent}
        next={flow.Payment}
        buttonColor={buttonColor}
        initiateQuote={initiateQuote}
        initiateCapitalTransaction={initiateCapitalTransaction}
        hashId={hashId}
        />
      )
    }
    else if( current ===  flow.STATEMENT_WAIT){
      return(
        <Statement
        hashId={hashId}
        />
      )
    }
    else if(current === flow.COMPLETE_PAYMENT){
      return(
        <FundTransfer
              display={{
                image: fundImage,
                title: fundTitle,
                discription:
                  'You can now invest digitally using Stockal EasyFund Transfer'
              }}
              enterBrokerDetail={enterBrokerDetail}
              setcurrent = {setcurrent}
              flow={flow}
              buttonColor={buttonColor}
              initiateCapitalTransaction={initiateCapitalTransaction}
              hashId={hashId}
              disableUploadStatement={disableUploadStatement}
            />
      )
    }
    else if(current === flow.COMPLETE){
      return(
      <Complete/>
      )
    }
    else{
      return(
        <PickupDetail
            setModalData={setModalData}
            setcurrent = {setcurrent}
            back={stringCollection.otherBankAccount}
            flow={flow}
            pickupInformation={pickupInformation}
            setPickupInformation={setPickupInformation}
            hashId={hashId}
            previousTransaction={previousTransaction}
            setPreviousTransaction={setPreviousTransaction}
            previous={previous}
            setprevious={setprevious}
            transactionCount={transactionCount}
            setTransactionCount={setTransactionCount}
            disablePrevious={disablePrevious}
            setDisablePrevious={setDisablePrevious}
            buttonColor={buttonColor}
            checkBank={checkBank}
            panFileFront={panFileFront}
            setpanFileFront={setpanFileFront}
            panFileBack={panFileBack}
            setpanFileBack={setpanFileBack}
            accountStatement1={accountStatement1}
            setaccountStatement1={setaccountStatement1}
            accountStatement2={accountStatement2}
            setaccountStatement2={setaccountStatement2}
            accountStatement3={accountStatement3}
            setaccountStatement3={setaccountStatement3}
            accountStatement4={accountStatement4}
            setaccountStatement4={setaccountStatement4}
            accountStatementCount={accountStatementCount}
            setaccountStatementCount={setaccountStatementCount}
        />
      )
    }
  }

  // const showscreen = () => {
  //  switch(current) {
  //     case flow.LoadingSkeleton:
  //       return(
  //         <div className={styles.card}>
  //         <div className={styles.spinner}><Spinner animation="border" className={styles.spinnerColor}/></div>
  //         </div>
  //       )

  //     case flow.ADDITIONAL_DETAILS :
  //     return(
  //       <PickupDetail
  //           setModalData={setModalData}
  //           setcurrent = {setcurrent}
  //           back={stringCollection.otherBankAccount}
  //           flow={flow}
  //           pickupInformation={pickupInformation}
  //           setPickupInformation={setPickupInformation}
  //           hashId={hashId}
  //           previousTransaction={previousTransaction}
  //           setPreviousTransaction={setPreviousTransaction}
  //           previous={previous}
  //           setprevious={setprevious}
  //           transactionCount={transactionCount}
  //           setTransactionCount={setTransactionCount}
  //           disablePrevious={disablePrevious}
  //           setDisablePrevious={setDisablePrevious}
  //           buttonColor={buttonColor}
  //           checkBank={checkBank}
  //           panFileFront={panFileFront}
  //           setpanFileFront={setpanFileFront}
  //           panFileBack={panFileBack}
  //           setpanFileBack={setpanFileBack}
  //           accountStatement1={accountStatement1}
  //           setaccountStatement1={setaccountStatement1}
  //           accountStatement2={accountStatement2}
  //           setaccountStatement2={setaccountStatement2}
  //           accountStatement3={accountStatement3}
  //           setaccountStatement3={setaccountStatement3}
  //           accountStatement4={accountStatement4}
  //           setaccountStatement4={setaccountStatement4}
  //           accountStatementCount={accountStatementCount}
  //           setaccountStatementCount={setaccountStatementCount}
  //       />
  //     )

  //     case flow.A2_UPLOADED:
  //       return(
  //         <A2Upload
  //         buttonTitle = 'Initate Transaction'
  //         setcurrent={setcurrent}
  //         next={flow.review}
  //         buttonColor={buttonColor}
  //         hashId={hashId}
  //         />
  //       )
  //     case flow.review:
  //       return(
  //         <ReviewDetail
  //         setcurrent={setcurrent}
  //         next={flow.Payment}
  //         buttonColor={buttonColor}
  //         />
  //       )

  //     case flow.A2_WAIT:
  //         return(
  //           <A2wait title='Loading'/>
  //         )
  //     case flow.A2_PROCESSING:
  //       return(
  //         <A2wait title='Loading'/>
  //       )
  //     case flow.Payment:
  //       return(
  //         <FundTransfer
	// 							display={{
	// 								image: fundImage,
  //                 title: ``,
  //                 discription:
	// 									'You can now invest digital using Stockal EasyFund Transfer'
	// 							}}
  //               enterBrokerDetail={enterBrokerDetail}
	// 	            setcurrent = {setcurrent}
  //               flow={flow}
	// 							buttonColor={buttonColor}
	// 						/>
  //       )
  //     case flow.PaymentStatus:
  //     return(
  //       <A2wait title='Confirming Payment Status'/>
  //     )
  //     case flow.Statement:
  //       return(
  //         <Statement
  //         hashId={hashId}
  //         />
  //       )
  //   default:
  //     return(
  //       <PickupDetail
  //           setModalData={setModalData}
  //           setcurrent = {setcurrent}
  //           back={stringCollection.otherBankAccount}
  //           flow={flow}
  //           pickupInformation={pickupInformation}
  //           setPickupInformation={setPickupInformation}
  //           hashId={hashId}
  //           previousTransaction={previousTransaction}
  //           setPreviousTransaction={setPreviousTransaction}
  //           previous={previous}
  //           setprevious={setprevious}
  //           transactionCount={transactionCount}
  //           setTransactionCount={setTransactionCount}
  //           disablePrevious={disablePrevious}
  //           setDisablePrevious={setDisablePrevious}
  //           buttonColor={buttonColor}
  //           checkBank={checkBank}
  //           panFileFront={panFileFront}
  //           setpanFileFront={setpanFileFront}
  //           panFileBack={panFileBack}
  //           setpanFileBack={setpanFileBack}
  //           accountStatement1={accountStatement1}
  //           setaccountStatement1={setaccountStatement1}
  //           accountStatement2={accountStatement2}
  //           setaccountStatement2={setaccountStatement2}
  //           accountStatement3={accountStatement3}
  //           setaccountStatement3={setaccountStatement3}
  //           accountStatement4={accountStatement4}
  //           setaccountStatement4={setaccountStatement4}
  //           accountStatementCount={accountStatementCount}
  //           setaccountStatementCount={setaccountStatementCount}
  //       />
  //     )
  //  }
  // }

  return(
    <>{showscreen('pickup')}</>
  )
}

export default Fairexpay
