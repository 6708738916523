import React, { useState, useEffect,createRef } from 'react'
import { Row, Col,OverlayTrigger,Tooltip } from 'react-bootstrap'
import styles from './pickupDetail.module.css'
import { format } from 'date-fns'
import UploadImage from '../AccountSetUp/UploadImage/UploadImage'
import questionImage from '../../public/images/TooltipQuestion.svg'
import checkImage from '../../public/images/check.png'
import backImage from '../../public/images/back.png'
import plusImage from '../../public/images/plusagain.png'
import RemoveTransaction from '../../public/images/removeTrnsaction.svg'
import dropImage from '../../public/images/headerarrownew.svg'
import DatePicker from '../../components/DatePicker/DatePicker'
import _ from 'lodash'
import { putLrsAndPickup,geoCodingApi,previousTransactionFund,postDocument } from '../../api'

const PickupDetail = ({
	setModalData,
	data,
	data2,
	back,
	pickupInformation,
	setPickupInformation,
	hashId,
  previousTransaction,
  setPreviousTransaction,
  previous,
  setprevious,
  transactionCount,
  setTransactionCount,
  disablePrevious,
  setDisablePrevious,
  checkBank
}) => {
	const [check, setCheck] = useState(false)
	const [showCalendar, setShowCalendar] = useState(false)
  const [showCalendarTransaction, setshowCalendarTransaction] = useState([false])
  // const [previousTransaction,setPreviousTransaction] = useState([{ "prevLrsAmount": '', "prevLrsDate": '', "prevLrsBank": '' }])
  // const [previous,setprevious] = useState(false)
	const [pickupDate, setPickupDate] = useState('')
  const [manuall, setManuall] = useState(false)
  const [loading,setLoading] = useState(false)
  const [errorMessage,setErrorMessage] = useState('')
  // const [transactionCount,setTransactionCount] = useState(1)
  const [cityManual, setCityManual] = useState(false)
  const [stateManual, setStateManual] = useState(false)
  const [countryManual, setCountryManual] = useState(false)
  // const [panFile,setpanFile] = useState([])
  // const [accountStatement,setaccountStatement] = useState([])

	const dateFormatter = date => {
		var months = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December'
		]
		var day = date.getDate()
		var month = months[date.getMonth()]
		var year = date.getFullYear()
		return `${day} ${month} ${year}`
	}
	const timeSlot = [
		'8:00 am to 12:00 pm',
		'12:00 pm to 04:00 pm',
		'04:00 pm to 08:00 pm'
	]

	useEffect(() => {
		var minDate = new Date()
		minDate.setDate(minDate.getDate() + 2)
		setPickupDate(minDate)
    // getCurrentFinancialyear()
		// setPickupInformation({
		// 	...pickupInformation,
		// 	pickUpDate: dateFormatter(minDate),
		// 	pickUpTime: timeSlot[0],
    //   country: 'India'
		// })
	}, [])

  const getCurrentFinancialyear = () => {
    let financial_year = "";
    let today = new Date(2021, 1, 10);
    if ((today.getMonth() + 1) <= 3) {
        financial_year = (today.getFullYear() - 1) + "-" + today.getFullYear()
    } else {
        financial_year = today.getFullYear() + "-" + (today.getFullYear() + 1)
    }
    const startDate = new Date(financial_year.split('-')[0],4,1)
    const endDate = new Date(financial_year)
    console.log('date',startDate)
  }

	useEffect(() => {
    pickupInformation.applicantPan === ''
    ? setCheck(true)
    : pickupInformation.selfPickup ?
      setCheck(false)
    : pickupInformation.pickUpDate !== '' &&
      pickupInformation.pickUpTime !== '' &&
      pickupInformation.address1 !== '' &&
      pickupInformation.address2 !== '' &&
      pickupInformation.country !== '' &&
      pickupInformation.postalCode !== '' &&
      pickupInformation.city !== '' &&
      pickupInformation.state !== '' ? setCheck(false) :setCheck(true)

	}, [pickupInformation])

  const handleChangeDatePickup = value => {
    setPickupInformation(
      {
        ...pickupInformation,
        pickUpDate: dateFormatter(value)
      })
      setShowCalendar(false)
  }
  const handleChangeTimePickup = value => {
    console.log('value',value)
    setPickupInformation(
      {
        ...pickupInformation,
        pickUpTime: value
      })
      setShowCalendar(false)
  }


  const handleChangeTransaction = (value,index) => {
    const copy = [...previousTransaction]
    copy[index].prevLrsDate = format(value,'yyyy-MM-dd')
    const calendarCopy = [...showCalendarTransaction]
    calendarCopy[index] = !calendarCopy[index]
    setPreviousTransaction(copy,setshowCalendarTransaction(calendarCopy))
	}

  const handlePostal= e => {
    const re = /^[0-9\b]+$/
    if(re.test(e.target.value) && (e.target.value.length) <= 6 ){
      setPickupInformation({
        ...pickupInformation,
        postalCode: e.target.value
      })
     }
     if(e.target.value.length === 0 ){
      setPickupInformation({
        ...pickupInformation,
        postalCode:''
      })
     }
  }

  const handleCity = e =>{
    setPickupInformation({
      ...pickupInformation,
      [e.target.name]: e.target.value
    })
  }

  const onBlurrFunction = (e) => {
        geoCodingApi(e.target.value).then(response => {
        if (response.code === 200 && response.data) {
          const res = response.data;
          setPickupInformation({
            ...pickupInformation,
            city: res.city ? res.city : '',
            state: res.state ? res.state : '',
          })
        }
      }).catch(error => {
        setErrorMessage('Something Went Wrong')
      })
  }

  const checkPan  = () => {
    if(!/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(pickupInformation.applicantPan)){
       setErrorMessage('Please Enter Valid PAN Number')
       return false
    }
    return true
  }

	const asynchFetch = async () => {
    setLoading(true)
    if(checkPan()){
    try{
     const filter = previousTransaction.filter((item) => {
        return item.prevLrsBank !== '' && item.prevLrsDate !== '' && item.prevLrsAmount !== ''
     })
     const updatedDetail = {...pickupInformation,applicantPan:pickupInformation.applicantPan.toUpperCase()}
    const response = hashId && await previousTransactionFund(hashId,filter)
    if(response && response.code === 200){
      setDisablePrevious(true)
    }
		const res =
			hashId &&
			(await putLrsAndPickup(hashId, updatedDetail))
      if(res && res.code === 200){

          if(pickupInformation.selfPickup){
            setModalData(data2)
          }
          else{
            setModalData(data)
          }
      }
      if(res.code !== 200){
        setErrorMessage(res.message)
      }
    }catch(error){
      setErrorMessage('Something Went Wrong')
    }
  }
    setLoading(false)
	}


  const onPreviousHandler = (event,index) => {
    const re = /^[0-9\b]+$/
    if(event.target.name == 'prevLrsAmount'){
    if(event.target.value === '' || re.test(event.target.value)){
    const copy = [...previousTransaction]
    copy[index][event.target.name] = event.target.value
    setPreviousTransaction(copy)
    }
  }
    else{
      const copy = [...previousTransaction]
      copy[index][event.target.name] = event.target.value
      if(event.target.value.length <= 60){
      setPreviousTransaction(copy)
      }
    }
  }



  let financial_year = "";
  let today = new Date();
  if ((today.getMonth() + 1) <= 3) {
      financial_year = (today.getFullYear() - 1) + "-" + today.getFullYear()
  } else {
      financial_year = today.getFullYear() + "-" + (today.getFullYear() + 1)
  }
  const startDate = new Date(financial_year.split('-')[0],3,1)
  const endDate = new Date()
	return (
		<div className={styles.card}>
			<Row>
				<Col md={12}>
					<div
						className={styles.title}
						style={{ cursor: 'pointer' }}
						onClick={() => setModalData(back)}>
						<img src={backImage} />
						<span
							style={{
								paddingLeft: '20px'
							}}>{`Indian Bank Account`}</span>
					</div>
				</Col>
			</Row>
			<Row style={{ marginTop: '40px' }}>
				<Col md={6} xs={12}>
					<div className={styles.pan}>Enter PAN Number *</div>
					<input
						placeholder='Your PAN Number'
						className={styles.panInput}
						value={pickupInformation.applicantPan.replace(/[^a-zA-Z0-9]/g, '')}
						onChange={e =>
							setPickupInformation({
								...pickupInformation,
								applicantPan: e.target.value
							})
            }
            onPaste={(e)=>{
              e.preventDefault()
              return false;
            }} onCopy={(e)=>{
              e.preventDefault()
              return false;}}
					/>
				</Col>
					<Col md={12}
          	style={{
						  borderBottom: '1px solid #EFEFF1',
						  paddingBottom: '32px'
					}}>
						<div className={styles.lrs}>
							Have you done any LRS in current financial year?
							<span style={{ paddingLeft: '10px' }}>
              <OverlayTrigger
									placement='top'
									overlay={
										<Tooltip id='button-tooltip-2'>
											As per RBI guidelines - please provide the previous LRS transaction details done in the current financial year. If you have not done any LRS transaction, please choose 'No'
										</Tooltip>
									}>
									<img src={questionImage} className={styles.tooltipImage}></img>
								</OverlayTrigger>
								{/* <img src={questionImage} /> */}
							</span>
						</div>
					</Col>
					<Col md={12} style={{ marginTop: '16px' }}>
						<span>
							<button
								type='button'
								className='btn btn-light'
								style={{
									paddingLeft: '65.5px',
									paddingRight: '65.5px',
									paddingTop: '12px',
									paddingBottom: '12px',
									background: pickupInformation.hasDoneAnyPreviousLRSinYear
										? ' #1E1F24'
										: '#EFEFF1',
									borderRadius: '8px',
									color: pickupInformation.hasDoneAnyPreviousLRSinYear
										? '#EFEFF1'
										: '#1E1F24',
									border: 'none'
								}}
								onClick={() =>
                  {
									setPickupInformation({
										...pickupInformation,
										hasDoneAnyPreviousLRSinYear: true
									})
                  setDisablePrevious(false)
                  setprevious(true)
                }
								}>
								Yes
							</button>
						</span>
						<span style={{ marginLeft: '20px' }}>
							<button
								type='button'
								className='btn btn-dark'
								style={{
									paddingLeft: '65.5px',
									paddingRight: '65.5px',
									paddingTop: '12px',
									paddingBottom: '12px',
									background: pickupInformation.hasDoneAnyPreviousLRSinYear
										? '#EFEFF1'
										: ' #1E1F24',
									borderRadius: '8px',
									color: pickupInformation.hasDoneAnyPreviousLRSinYear
										? ' #1E1F24'
										: '#EFEFF1',
									border: 'none'
								}}
                // disabled={disablePrevious}
								onClick={() =>{
									setPickupInformation({
										...pickupInformation,
										hasDoneAnyPreviousLRSinYear: false
									},setTransactionCount(1),setPreviousTransaction([{ "prevLrsAmount": '', "prevLrsDate": '', "prevLrsBank": '' }]))
                  // setDisablePrevious(false)
                  setprevious(false)
                }
								}>
								No
							</button>
						</span>
					</Col>
          <div style={{display: previous
								? 'block'
								: 'none'}}>
						<Col md={12} style={{ marginTop: '24px'}}>
							<div className={styles.please}>
								Please provide details of any foreign currency transactions you
								have in current financial year (April - March)
							</div>
						</Col>
             {_.times(transactionCount, (i) => {
                 return(
                   <>
                  <Row className={styles.lrsstyle}>
                  <Col md={6} xs={12} style={{ marginTop: '24px' }}>
                    <div className={styles.us}>Amount in US$</div>
                    <input
                      placeholder='Enter Amount...'
                      name='prevLrsAmount'
                      className={styles.input3}
                      // disabled={disablePrevious}
                      value={previousTransaction[i].prevLrsAmount}
                      onChange={(event) => onPreviousHandler(event,i)}
                    />
                  </Col>
                  <Col md={6} style={{ marginTop: '24px' }}>
                  <div className={styles.date}>Date of transaction</div>
                  <button
                    className={`${styles.selectDate}`}
                    onClick={() => {
                      const copy = [...showCalendarTransaction]
                      copy[i]= !copy[i]
                      setshowCalendarTransaction(copy)
                    }}
                    // disabled={disablePrevious}
                  >
                    {previousTransaction[i].prevLrsDate !== '' ? previousTransaction[i].prevLrsDate : 'Date of transactions'}
                    <img src={dropImage} className={styles.arrowCss}/>
                  </button>
                  {showCalendarTransaction[i] && (
                    <div
                      style={{
                        marginTop:"5px",
                        zIndex: '1000',
                        position: 'absolute',
                        width: '91%'
                      }}
                      className={styles.dateBox}>
                      <DatePicker
                        onChange={date => handleChangeTransaction(date,i)}
                        minDate={startDate}
                        maxDate={endDate}
                        date={pickupDate}
                        color='#0061D3'
                      />
                    </div>
                  )}
                  </Col>
                  </Row>
                  <Col md={6} style={{ marginTop: '32px' }}>
                    <div className={styles.adName}>Bank / AD Name & Address</div>
                    <textarea
                      // disabled={disablePrevious}
                      placeholder='Enter Bank Address'
                      className={styles.input4}
                      name='prevLrsBank'
                      value={previousTransaction[i].prevLrsBank}
                      onChange={(event) => onPreviousHandler(event,i)}
                    />
                    <div className={styles.Forex}>
                      AD Name - Authorized Forex Dealer Name
                    </div>
                  </Col>
                  </>
                 )
             })}
             <Row className='ml-0'>
             {transactionCount < 3 ?
						<Col style={{ marginTop: '24px' ,paddingLeft:'10px'}} md={3}>
							<div style={{ cursor: 'pointer' }} onClick={() => {
                  setPreviousTransaction(
                    [...previousTransaction,{ "prevLrsAmount": '', "prevLrsDate": '', "prevLrsBank": '' }],
                    setTransactionCount(transactionCount + 1))
                  }}>
								<img src={plusImage} />
								<span className={styles.transaction}>Add Transaction</span>
							</div>
						</Col>
             : ''}
             {transactionCount > 1 ?
              	<Col style={{ marginTop: '24px' }}>
                <div style={{ cursor: 'pointer' }} onClick={() => {
                    const retrive = previousTransaction
                    retrive.pop()
                    setPreviousTransaction(retrive,setTransactionCount(transactionCount - 1))
                    }}>
                  <img src={RemoveTransaction} />
                  <span className={styles.transaction} style={{color:'#FA274A'}}>Remove Transaction</span>
                </div>
              </Col>  : ''
            }
            </Row>
            </div>
        {checkBank !== 'other' ?
				<Col md={12} style={{ marginTop: '32px' }}>
					<div className={styles.what}>
						What would you like to do with LRS form?
					</div>
				</Col> : ''}
			</Row>
			{checkBank !== 'other' ? <Row
				style={{ marginTop: '32px', marginBottom: '80px' }}>
				<Col md={6} className={styles.marginCard}>
					<div
						onClick={() =>
							setPickupInformation({
								...pickupInformation,
								selfPickup: true
							})
						}
						className={
							pickupInformation.selfPickup ? styles.schedule : styles.submit
						}
						style={{
							paddingLeft: '20px',
							paddingTop: '16px',
							paddingBottom: '16px',
							paddingRight: '16px',
							cursor: 'pointer'
						}}>
						<Row >
							<Col xs={9}>
								<div className={styles.first}>
									Submit LRS form in nearby branch
								</div>
								<div className={styles.second}>7 Days to credit</div>
							</Col>
							<Col xs={3}>
								<div
									style={{
										background: !pickupInformation.selfPickup
											? '#EFEFF1'
											: '#1E1F24',
										width: '36px',
										height: '36px',
										borderRadius: '50%',
										textAlign: 'center',
										paddingTop: '5px'
									}}>
									{!pickupInformation.selfPickup ? null : (
										<img src={checkImage} />
									)}
								</div>
							</Col>
						</Row>
					</div>
				</Col>
				<Col md={6}>
					<div
						onClick={() =>
							setPickupInformation({
								...pickupInformation,
								selfPickup: false
							})
						}
						className={
							!pickupInformation.selfPickup ? styles.schedule : styles.submit
						}
						style={{
							paddingLeft: '20px',
							paddingTop: '16px',
							paddingBottom: '16px',
							paddingRight: '16px',
							cursor: 'pointer'
						}}>
						<Row>
							<Col xs={9}>
								<div className={styles.first}>Schedule LRS form Pickup</div>
								<div className={styles.second}>15 Days to credit</div>
							</Col>
							<Col xs={3}>
								<div
									style={{
										background: !pickupInformation.selfPickup
											? '#1E1F24'
											: '#EFEFF1',
										width: '36px',
										height: '36px',
										borderRadius: '50%',
										textAlign: 'center',
										paddingTop: '5px'
									}}>
									{!pickupInformation.selfPickup ? (
										<img src={checkImage} />
									) : null}
								</div>
							</Col>
						</Row>
					</div>
				</Col>
			</Row> : ''}
      <div style={{ display: !pickupInformation.selfPickup ? 'block' : 'none' }}>
      <Row>
        <Col md={12}>
          <div className={styles.pickup}>Schedule Pickup</div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} style={{marginTop:"24px"}}>
          <div className={styles.date}>Date of pickup</div>
            <button
							className={`${styles.selectDate}`}
              onClick={() => setShowCalendar(!showCalendar)}
            >
              {pickupInformation.pickUpDate && pickupInformation.pickUpDate.length>0 ? pickupInformation.pickUpDate : 'Date of pickup' }
              <img src={dropImage} className={styles.arrowCss}/>
            </button>
						{showCalendar && (
							<div
								style={{
                  marginTop:"5px",
									zIndex: '1000',
									position: 'absolute',
									width: '91%'
								}}
								className={styles.dateBox}>
								<DatePicker
									onChange={date => handleChangeDatePickup(date)}
									date={pickupDate}
									color='#0061D3'
									minDate={pickupDate}
								/>
							</div>
						)}
        </Col>
        <Col
          xs={12} md={6}
          style={{marginTop:"24px"}}
        >
						<div className={styles.date}>Timeslot for pickup</div>
							<button
								className={`${styles.selectDate}`}
							  onClick={()=>setManuall(true) }
              >
								{pickupInformation.pickUpTime && pickupInformation.pickUpTime.length > 0 ? pickupInformation.pickUpTime : 'Timeslot for pickup'}
                <img src={dropImage} className={styles.arrowCss}/>
							</button>
							{manuall &&
              <div
								className={`${styles.dropElements}`}
                >
								{  timeSlot.map((trav, i) => {
									return (
										<div
											className={styles.mainElement}
											onClick={() => handleChangeTimePickup(trav,setManuall(false))}
											key={i}>
											{trav}
										</div>
									)
								})}
							</div>
            }

					</Col>
      </Row>

      <Row style={{marginTop:"34px"}}>
        <Col>
          <div className={styles.address}>Address</div>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6} style={{marginTop:"24px"}}>
						<div className={styles.date}>Address line 1</div>
						<input
							placeholder='Enter Address ...'
							className={styles.input2}
							value={pickupInformation.address1}
							onChange={e =>
								setPickupInformation({
									...pickupInformation,
									address1: e.target.value
								})
							}
						/>
				</Col>
        <Col xs={12} md={6} style={{marginTop:"24px"}}>
						<div className={styles.date}>Address line 2</div>
						<input
							className={styles.input2}
							placeholder='Enter Address ...'
							value={pickupInformation.address2}
							onChange={e =>
								setPickupInformation({
									...pickupInformation,
									address2: e.target.value
								})
							}
						/>
					</Col>
      </Row>
      <Row className={styles.marginLast}>
        <Col xs={6} md={3} className={styles.paddingState}>
          <div className={styles.date}>Country</div>
          <input
							className={styles.input2}
							value={pickupInformation.country}
              readOnly={true}
						/>
        </Col>
        <Col xs={6} md={3}  className={styles.paddingState}>
          <div className={styles.date}>Postal code</div>
          <input
							className={styles.input2}
							placeholder='Enter Code...'
							value={pickupInformation.postalCode}
              onChange={handlePostal}
              onBlur={onBlurrFunction}
						/>
        </Col>
        <Col xs={6} md={3}  className={styles.paddingState}>
          <div className={styles.date}>City</div>
          <input
							className={styles.input2}
							placeholder='Enter City...'
							value={pickupInformation.city}
              name='city'
              onChange={handleCity}
						/>
        </Col>
        <Col xs={6} md={3} className={styles.paddingState}>
          <div className={styles.date}>State</div>
          <input
							className={styles.input2}
							placeholder='Enter State...'
							value={pickupInformation.state}
              name='state'
              onChange={handleCity}
						/>
        </Col>
      </Row>
      </div>
      {errorMessage === '' ? '' : <div className={styles.erorrMessage}>{errorMessage}</div>}
      <Row className={styles.fixedDiv}>
				<Col>
					<button
						onClick={() => asynchFetch()}
						disabled={check || loading}
						type='button'
						className={`btn btn-primary btn-sm ${styles.addfunds} themeButton`}>
						<span className={styles.addfundstext}>Save and Continue</span>
					</button>
				</Col>
			</Row>
		</div>
	)
}

export default PickupDetail

