import React from 'react'
import { Container } from 'react-bootstrap'
import Header from '../components/Header/Header'
import EmptyPage from '../components/EmptyPage/EmptyPage'
import { STOCK_PAGE } from '../constants'
import NewHeader from './NewSignupComponents/NavBar/NewHeader'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const NotFound = () => {
	let history = useHistory()
	return (
		<>
			<NewHeader
				message='Don’t have an account?'
				linkName='Sign Up'
				onClickLink={() => history.push('/signup')}
			/>
			<Container>
				<EmptyPage type={STOCK_PAGE} />
			</Container>
		</>
	)
}

export default NotFound
