import React, { useContext, useState } from "react"
import { putBrokerDetail } from "../../../../api"
import { AddFundContext } from "../../AddFundContext"
import styles from "./offline.module.css"
import { Spinner } from "react-bootstrap"
import Beneficiary from "../Common/Beneficiary"

const Details = ()=>{
    const {setSubPage, setLrsID, hashID, onBack, transferOptions, setOnErrorRetry, setShowGenericError, PAGE, SUBPAGE} = useContext(AddFundContext)
    const [loadingContinue, setLoadingContinue] = useState(false)
    const submitData = async(showGenericError)=>{
        let success = true
        try{
            let type='from_pickup'
            if(transferOptions.other)
                type='others'
            const res = await putBrokerDetail(hashID,type)       
            if(res  && res.code==200){
                 setLrsID(res.data.requestId)
                 setSubPage(SUBPAGE[PAGE.OFFLINE].INSTRUCTION)
             }else{
                success=false
             }
        }catch(error){
            success=false
        }
        if(success && showGenericError){
            setShowGenericError(false)
            setOnErrorRetry(null)
        }else if(!success){
            setOnErrorRetry(submitData)
            setShowGenericError(true)
        }
    }
    const onContinue = async()=>{
        if(!loadingContinue){
            setLoadingContinue(true)
            await submitData()
            setLoadingContinue(false)
        }
        
    }
    return <>
        <div className={styles.subHeader}>Independent Wire</div>
        {transferOptions.other && <Beneficiary bank='Others'/>}
        <div className={styles.footerContainer}>
            <button className={styles.continueBtn} onClick={onContinue}>
                {loadingContinue && <Spinner animation="border" className={styles.spinnerColor}/>}
                Continue
            </button>
            {/* <button className={styles.cancelBtn} onClick={()=>{!loadingContinue && onBack()}}>Cancel</button> */}
        </div> 
    </>
}

export default Details