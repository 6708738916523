import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import SkeletonElement from './SkeletonElement'
import Shimmer from './Shimmer'
import plusImage from '../public/images/whiteplus.png'

import styles from './manageFundSkeleton.module.css'

const manageFundSkeleton = ({
	isFundButton,
	buttonColor,
	profileData,
	handleShowMobile
}) => {
	return (
		<>
			<Row className={styles.skeletonContainer}>
				<Col xs={12} sm={4}>
					<SkeletonElement
						width='150px'
						height='25px'
						color='#777'
						opacity='0.3'></SkeletonElement>
					<SkeletonElement
						width='150px'
						height='30px'
						color='#777'
						opacity='0.3'></SkeletonElement>
					<Shimmer />
				</Col>
				{/* <Col xs={12} sm={8} className={styles.btnContainer}>
					{isFundButton ? (
						<>
							<button
								style={{ backgroundColor: buttonColor }}
								type='button'
								disabled={
									profileData && profileData['accountStatus'] === 2
										? false
										: true
								}
								onClick={() => handleShowMobile()}
								className={`btn btn-primary btn-sm ${styles.addfunds} themeButton`}>
								<img src={plusImage} />
								<span className={styles.addfundstext}>Add Funds</span>
							</button>
						</>
					) : (
						<></>
					)}
					<button
						type='button'
						className={`btn btn-sm ${styles.withdraw} themeButton`}>
						<span className={styles.withdrawText}>Withdraw</span>
					</button>
					<button
						type='button'
						className={`btn btn-sm ${styles.menuContainer} themeButton`}>
						<div className={styles.menuIcon}></div>
					</button>
				</Col> */}
			</Row>
			<Row className={`mt-4 ${styles.skeletonContainer}`}>
				<Col>
					<SkeletonElement
						width='150px'
						height='15px'
						color='#777'
						opacity='0.3'></SkeletonElement>
					<SkeletonElement
						width='200px'
						height='15px'
						color='#777'
						opacity='0.3'></SkeletonElement>
					<Shimmer />
				</Col>
				<Col style={{ textAlign: '-webkit-right' }}>
					<SkeletonElement
						width='50px'
						height='15px'
						color='#777'
						opacity='0.3'></SkeletonElement>
					<Shimmer />
				</Col>
			</Row>
			<Row className={`mt-3 ${styles.skeletonContainer}`}>
				<Col>
					<SkeletonElement
						width='150px'
						height='15px'
						color='#777'
						opacity='0.3'></SkeletonElement>
					<SkeletonElement
						width='200px'
						height='15px'
						color='#777'
						opacity='0.3'></SkeletonElement>
					<Shimmer />
				</Col>
				<Col style={{ textAlign: '-webkit-right' }}>
					<SkeletonElement
						width='50px'
						height='15px'
						color='#777'
						opacity='0.3'></SkeletonElement>
					<Shimmer />
				</Col>
			</Row>
			<Row className={`mt-3 ${styles.skeletonContainer}`}>
				<Col>
					<SkeletonElement
						width='150px'
						height='15px'
						color='#777'
						opacity='0.3'></SkeletonElement>
					<SkeletonElement
						width='200px'
						height='15px'
						color='#777'
						opacity='0.3'></SkeletonElement>
					<Shimmer />
				</Col>
				<Col style={{ textAlign: '-webkit-right' }}>
					<SkeletonElement
						width='50px'
						height='15px'
						color='#777'
						opacity='0.3'></SkeletonElement>
					<Shimmer />
				</Col>
			</Row>
			{/* <Row className='mt-5'>
				<Col>
					<SkeletonElement
						width='150px'
						height='15px'
						color='#777'
						opacity='0.3'></SkeletonElement>
					<Shimmer />
				</Col>
				<Col style={{ textAlign: '-webkit-right' }}>
					<SkeletonElement
						width='50px'
						height='15px'
						color='#777'
						opacity='0.3'></SkeletonElement>
					<Shimmer />
				</Col>
			</Row>
			<Row className='mt-3'>
				<Col>
					<SkeletonElement
						width='150px'
						height='15px'
						color='#777'
						opacity='0.3'></SkeletonElement>
					<Shimmer />
				</Col>
				<Col style={{ textAlign: '-webkit-right' }}>
					<SkeletonElement
						width='50px'
						height='15px'
						color='#777'
						opacity='0.3'></SkeletonElement>
					<Shimmer />
				</Col>
			</Row> */}
			<Row className={`mt-5 ${styles.skeletonContainer}`}>
				<Col>
					<SkeletonElement
						width='200px'
						height='20px'
						color='#777'
						opacity='0.3'></SkeletonElement>
					<SkeletonElement
						width='300px'
						height='15px'
						color='#777'
						opacity='0.3'></SkeletonElement>
					<Shimmer />
				</Col>
			</Row>
		</>
	)
}

export default manageFundSkeleton
