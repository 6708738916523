import React, { useContext, useEffect, useState } from "react"
import GenericErrorIcon from "../../public/images/genericError.svg"
import ArrowRightIcon from "../../public/images/arrowRight.svg"
import RefreshIcon from "../../public/images/refresh.svg"
import style from "./addFund.module.css"
import { AddFundContext } from "./AddFundContext"
import GenericLoading from "./GenericLoading"

const GenericErrorWithOption = ()=>{
    const {onErrorRetry, setShowGenericErrorOption,transferOptions,setPage,PAGE,showTryAgain,errorAmount} = useContext(AddFundContext)
    const [counter, setCounter] = useState(0)
    const [loading, setLoading] = useState(false)
    const onTryAgain = async()=>{
        setLoading(true)
        await onErrorRetry(setShowGenericErrorOption)
        setCounter(counter+1)
        setLoading(false)
    }

    return(<div className={style.genericErrorContainer}>
        {!loading?<>
            <img src={GenericErrorIcon}/>
            <div className={style.title}>{errorAmount === "More than 25k" ? "Amount is above $25,000" : "Something went wrong"}</div>
            <div className={style.subTitle} style={{"paddingRight": errorAmount === "More than 25k" ? "25px" : "","paddingLeft": errorAmount === "More than 25k" ? "25px" : ""}}>
            {errorAmount === "More than 25k" ? "Entered amount is above $25,000 you need to do this transaction via Offline form." :
            "We’re not able to proceed with your request at the moment. Please try another method."}</div>
            {transferOptions.direct && <button className={style.transferOption} onClick={()=>setShowGenericErrorOption(false,setPage(PAGE.DIRECT))}>
                <div className="d-flex flex-column align-items-start" style={{gap:'4px'}}>
                    <span className={style.primary}>Direct Transfer <span className={style.fastTag}>Fastest</span></span>
                    <span className={style.secondary}>2-3 clicks is all it takes</span>
                </div>
                <img src={ArrowRightIcon} />
            </button>}
            {transferOptions.online && <button className={style.transferOption} onClick={()=>setShowGenericErrorOption(false,setPage(PAGE.ONLINE))}>
                <div className="d-flex flex-column align-items-start" style={{gap:'4px'}}>
                    <span className={style.primary}>Online Independent Wire</span>
                    <span className={style.secondary}>Do it through netbanking</span>
                </div>
                <img src={ArrowRightIcon} />
            </button>}
            {(transferOptions.offline || transferOptions.other) && <button className={style.transferOption} onClick={()=>setShowGenericErrorOption(false,setPage(PAGE.OFFLINE))}>
                <div className="d-flex flex-column align-items-start" style={{gap:'4px'}}>
                    <span className={style.primary}>Offline Independent Wire</span>
                    <span className={style.secondary}>Submit LRS form in nearest branch</span>
                </div>
                <img src={ArrowRightIcon} />
            </button>}
            {showTryAgain?
            <span className={style.tryAgainContainer} onClick={counter < 3?()=>onTryAgain():()=>window.fcWidget.open()}>
                {counter < 3 ? <>
                    <img src={RefreshIcon} onClick={onTryAgain}/>
                    Try Again
                </>:'Contact us'}
            </span>:
             <span className={style.tryAgainContainer} onClick={()=>window.fcWidget.open()}>
             Contact us
           </span>
            }
        </>:<GenericLoading />}
    </div>)
}

export default GenericErrorWithOption
