import { getTopPerformingStacks } from '../../api'
import { TOP_STACKS } from './action'

const getPerformingStacks = () => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			getTopPerformingStacks('popular').then(response => {
				if (response && response.length > 0 ) {
					resolve(dispatch({ type: TOP_STACKS, payload: response}))
				} else if( response === undefined) {
          resolve(dispatch({type: TOP_STACKS, payload: {}}))
        }
			})
		})
	}
}

export default getPerformingStacks
