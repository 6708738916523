import React, { useContext, useEffect } from "react"
import { AddFundContext } from "./AddFundContext"
import GenericErrorIcon from "../../public/images/genericError.svg"
import style from "./addFund.module.css"

const Cancel = ()=>{
    const {setNoHeader, setNoClose, cancelDetails, setCancelDetails, showCancel, setShowCancel} = useContext(AddFundContext)
    useEffect(()=>{
        if(showCancel){
            setNoHeader(true)
            setNoClose(true)
        }
    },[showCancel])
    const closeCancel = ()=>{
        setNoHeader(false)
        setNoClose(false)
        setCancelDetails(null)
        setShowCancel(false)
    }
    const onCancel=()=>{        
        const {onConfirm, onCancel} = cancelDetails
        setCancelDetails(null)
        onConfirm()
        closeCancel(onCancel)
    }
    return (<div className={style.cancelContainer}>
        <div>
            <div className={style.cancelHeader}>
                <img src={GenericErrorIcon}/>
                <span>Cancel Transaction</span>
            </div>
            <div className={style.cancelBody}>
                <span>{cancelDetails&&cancelDetails.msg}</span>
                <div className="d-flex w-100">
                    <button className={style.cancelConfirmBtn} onClick={onCancel}>Yes</button>
                    <button className={style.cancelBtn} onClick={closeCancel}>No</button>
                </div>                
            </div>
        </div>
    </div>)
}

export default Cancel