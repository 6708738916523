import React,{useState,useEffect, useRef} from 'react'
import styles from './SignUpDropDown.module.css'
import SignUpInput from '../SignUpInput'
import SearchSvg from '../../../public/images/newOnboarding/Search.svg'

const SignUpDropDown = ({data,value,showDropDown,onChange,placeholder,classes,isSearchable= true}) => {
  const [options,setOptions] = useState([])
  const [searched,setSearched] = useState('')
  const myRef = useRef(null)

  useEffect(() => {
    if(myRef && myRef.current){
      myRef.current.focus()
    }
  },[showDropDown])

  useEffect(() => {
    setOptions(data)
  },[data])

  useEffect(() => {
    if(searched !== ''){
      const updatedList = data.filter((item) => {
       return item.label.toLowerCase().includes(searched.toLowerCase())
      })
      setOptions([...updatedList])
    }
    else{
      setOptions(data)
    }
  },[searched])

 return(
   <>
    {showDropDown ? <div className={`${classes} ${styles.dropdownBox}`}>
        {isSearchable ?
        <div style={{padding:'12px'}}>
           <SignUpInput
             placeholder={placeholder}
             value={searched}
             refValue={myRef}
             type='text'
             name='serached'
             onChange={(e) => setSearched(e.target.value)}
             icon={SearchSvg}
           />
        </div> : ''}
        {options.length >0 ? options.map((item,index) => {
          return(
             <div key={index} className={styles.optionText} onClick={() => onChange(item)}>{item.label}</div>
          )
        }) : <div className={styles.noData}>No Results Found</div>}
    </div> : ''}
  </>
 )
}

export default SignUpDropDown
