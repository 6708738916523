import React, { Fragment, useState, useEffect } from 'react';
import styles from './Nominee.module.css'
import profileStyles from '../Profile.module.css'
import {Button} from 'react-bootstrap'
import NoNominee from '../../../public/images/Nointernet.svg'
import EditNominee from './EditNominee';
import NomineeDetail from './NomineeDetail';
import {PRIMARY} from './type';
import {getNomineeList} from '../../../api'
import NomineeListSkeleton from '../../../skeletons/NomineeListSkeleton';

const Beneficiary = ()=>{
    const [primaryList, setPrimaryList] = useState([]);
    const [contingentList, setContingentList] = useState([]);
    const [showEdit, setShowEdit] = useState(false);
    const [showInputList, setShowInputList] = useState(false);
    const [editSection, setEditSection] = useState(PRIMARY);
    const [listLoading, setListLoading]=useState(true);
    useEffect(()=>{
        setNomineeList();
    },[]);
    const setNomineeList = async()=>{
        try{
            const res = await getNomineeList();
            if(res.data && res.data.code===200){
                setListLoading(false)
                const nomineeList=res.data.data;
                const primary = [];
                const contingent = []
                const requiredKeys=["stoBeneficiaryId",
                "isPrimary",
                "beneficiaryType",
                "beneficiaryName",
                "benefitPct",
                "beneficiaryPhone",
                "primaryContact",
                "beneficiaryEmail",
                "beneficiaryDob",
                "formationDt"]
                nomineeList.forEach((nominee, index) => {
                    let requiredNomineeData={}
                    requiredKeys.forEach(key=>{
                        requiredNomineeData[key]=nominee[key];
                    })
                    requiredNomineeData.benefitPct = requiredNomineeData.benefitPct*100
                    requiredNomineeData.index = index
                    if(nominee.isPrimary)
                        primary.push(requiredNomineeData);
                    else
                        contingent.push(requiredNomineeData);
                });
                setPrimaryList(primary);
                setContingentList(contingent);
            }
        }catch(error){
            console.log(error)
        }
    }
    const onEdit=(section)=>{
        setShowEdit(true);
        setEditSection(section)
    } 
    const onSave=()=>{
        setShowEdit(false);
        setListLoading(true);
        setNomineeList();
    }
    const onCancel=()=>{
        setShowEdit(false);
        setShowInputList(false);
    } 
    return(<Fragment>
        {!listLoading?
            (primaryList.length>0 && !showEdit?
                <Fragment>
                    <NomineeDetail primaryList={primaryList} contingentList={contingentList} showEdit={setShowEdit} onEdit={onEdit}/>
                </Fragment>
                :(primaryList.length>0 || showInputList? 
                    <EditNominee primaryList={primaryList} contingentList={contingentList} onSave={onSave} onCancel={onCancel} editSection={editSection}/>
                    :<div className={styles.noNomineeContainer}>
                        <div>
                            <img src={NoNominee}></img>
                            <p className={styles.info}>You haven't added any nominees to your account yet.</p>
                            <Button className={`m-auto ${profileStyles.editButton} ${styles.noNomineeButton}`} onClick={()=>setShowInputList(true)}>
                                <span className={styles.plusSymbol}>Add Nominee(s)</span>
                            </Button>
                        </div>
                    </div>))
            :<NomineeListSkeleton/>   
        }
    </Fragment>)
}
export default Beneficiary;