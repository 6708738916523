import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import styles from '../Profile.module.css'
import InputLabel from '../../InputLabel/InputLabel'
import ProfileDropdown from './ProfileDropDown'
import axios from 'axios'
import { setUserInfoSession } from '../../../common'
import { updateProfile } from '../../../api';
import {annualIncomeOptions as annualIncomeData, netWorthOptions as netWorthLiquidData, networthTotalOptions as netWorthTotalData} from '../../AccountSetUp/Forms/FinancialDetail/FinancialData'

const EditInvestingProfile = props => {
	const [netWorthLiquid, setNetWorthLiquid] = useState('--')
  const [netWorthLiquidError, setNetWorthLiquidError] = useState('')
	const [netWorthLiquidLabel, setNetWorthLiquidLabel] = useState('')
	const [netWorthTotal, setNetWorthTotal] = useState('--')
  const [netWorthTotalError,setNetWorthTotalError] = useState('')
	const [netWorthTotalLabel, setNetWorthTotalLabel] = useState('')
	const [annualIncome, setAnnualIncome] = useState('--')
	const [annualIncomeLabel, setAnnualIncomeLabel] = useState('')
  const [annualIncomeError, setAnnualIncomeError] = useState('')
	const [objectives, setObjectives] = useState(props.objectives)
	const [objectivesLabel, setObjectivesLabel] = useState('')
	const [experience, setExperience] = useState(props.experience)
	const [experienceLabel, setExperienceLabel] = useState('')
	const [riskTolerance, setRiskTolerance] = useState(props.riskTolerance)
	const [riskToleranceLabel] = useState(props.riskLabel)
  const [successOrErrorMessage, setSucessOrErrorMessage] = useState('')
  const[buttonDisabled, setButtonDisabled] = useState(false)

	const objectivesOption = [
		{ value: 'LONG_TERM', label: 'Long–term buy & hold investing' },
		{
			value: 'INFREQUENT',
			label: 'Trading infrequently when I see an opportunity'
		},
		{ value: 'FREQUENT', label: 'Frequent trader, depending on the market' },
		{ value: 'ACTIVE_DAILY', label: 'Active trader, daily trader' },
		{ value: 'NEW', label: 'New to investing' }
	]
	let objectiveObject = objectivesOption.find(o => o.value === props.objectives)

	useEffect(() => {
    setNetWorthLiquid(props.netWorthLiquid ? netWordthLiquidObj ? netWordthLiquidObj.value : '--' : '--')
    setNetWorthLiquidLabel(props.netWorthLiquid ? netWordthLiquidObj ? netWordthLiquidObj.label : '--' : '--')
    setNetWorthTotal(props.netWorthTotal ? netWorthTotalObj ? netWorthTotalObj.value : '--' : '--')
    setNetWorthTotalLabel(props.netWorthTotal ? netWorthTotalObj ? netWorthTotalObj.label : '--' : '--')
    setAnnualIncome(props.annualIncome ? annualIncomeObj ? annualIncomeObj.value : "--" : "--")
    setAnnualIncomeLabel(props.annualIncome ? annualIncomeObj ? annualIncomeObj.label : "--" : "--")
		setObjectivesLabel(
			props.objectives
				? objectiveObject
					? objectiveObject.label
					: '--'
				: props.objectives
		)
		setExperienceLabel(
			props.experience
				? experienceObj
					? experienceObj.label
					: '--'
				: props.experience
		)
	}, [])

//   const netWorthLiquidOptions = [
// 		{ value: '4999', label: '$0 - $4,999' },
// 		{ value: '99999', label: '$5,000 - $99,999' },
// 		{ value: '199999', label: '$100,000 - $199,999' },
// 		{ value: '200000', label: '$200,000+' }
// 	]
	const netWorthLiquidOptions = netWorthLiquidData.map((item)=> {return {...item, value: item.value.replace('$','').replace(/,/g,'')}})
  let netWordthLiquidObj = netWorthLiquidOptions.find(o => o.value === props.netWorthLiquid)
  // console.log('netWorthliquid',netWordthLiquidObj)
  // console.log('liquid',props.netWorthLiquid)
  // console.log('net worth', netWorthLiquidOptions.find(o => o.label === props.netWorthLiquid))


	// const netWorthTotalOptions = [
	// 	{ value: '24999', label: '$0 - $24,999' },
	// 	{ value: '99999', label: '$25,000 - $99,999' },
	// 	{ value: '199999', label: '$100,000 - $199,999' },
	// 	{ value: '200000', label: '$200,000+' }
	// ]
  // console.log('networth', props.netWorthTotal)
  const netWorthTotalOptions = netWorthTotalData.map((item)=> {return {...item, value: item.value.replace('$','').replace(/,/g,'')}})
  let netWorthTotalObj = netWorthTotalOptions.find(o => o.value === props.netWorthTotal)
  // console.log('networthObj', props.netWorthTotal ? netWorthTotalObj ? netWorthTotalObj.value : '--' : '--')


	const experienceOptions = [
		{ label: 'None', value: 'NONE' },
		{ label: '1–2 years', value: 'YRS_1_2' },
		{ label: '3–5 years', value: 'YRS_3_5' },
		{ label: '5–10 years', value: 'YRS_5_10' },
		{ label: '10+ years', value: 'YRS_10_' }
	]

	let experienceObj = experienceOptions.find(o => o.value || o.label === props.experience)

	const riskOptions = [
		{ value: 'LOW', label: 'Low Risk' },
		{ value: 'MODERATE', label: 'Medium Risk' },
		// { value: 'SPECULATION', label: 'Speculative Risk' },
		{ value: 'HIGH', label: 'High Risk' }
	]

	// const annualIncomeOptions = [
	// 	{ value: '24999', label: '$0 - $24,999' },
	// 	{ value: '99999', label: '$25,000 - $99,999' },
	// 	{ value: '199999', label: '$100,000 - $199,999' },
	// 	{ value: '200000', label: '$200,000+' }
	// ]
	const annualIncomeOptions = annualIncomeData.map((item)=> {return {...item, value: item.value.replace('$','').replace(/,/g,'')}})
  let annualIncomeObj = annualIncomeOptions.find(o =>  o.value === props.annualIncome)

	const handleSubmit = e => {
		const update = {
			type: 'INVESTOR_PROFILE_INFO',
			body: {
				// investmentObjectives: objectives,
				investmentExperience: experience,
				annualIncome: Number(annualIncome),
				networthLiquid: Number(netWorthLiquid),
				networthTotal: Number(netWorthTotal),
				riskTolerance: riskTolerance,
        otp:props.otp,
        hashId:props.hashId
			}
		}
    // new Api call
    updateProfile(update).then(response=>{
      props.setIsVerifyOtp(false)
      props.setIsEdit(false)
      if(response.data.code === 200){
		delete update.body.otp;
		delete update.body.hashId;
        setUserInfoSession(update.body)
        props.setDetails(update.body)
      }else {
        setSucessOrErrorMessage('Something Went Wrong!')
      }
    }).catch(function (error) {
        if(error.response.data.code === 440){
          setButtonDisabled(true)
        }
        setSucessOrErrorMessage(error.response.data.message)
      console.log('basicError', error)
    })
	}

  const checkData = () => {
    if(netWorthLiquid === '--'){
      setNetWorthLiquidError('Net Worth Liquid Cannot be empty ')
    } else if (netWorthTotal === '--'){
      setNetWorthTotalError('Net Worth Total Cannot be empty ')
      setNetWorthLiquidError('')
    }
     if (annualIncome === '--'){
      setNetWorthTotalError('')
      setAnnualIncomeError('Annual Income Cannot be empty ')
      setNetWorthLiquidError('')
    } else {
      handleSubmit()
      setNetWorthLiquidError('')
      setNetWorthTotalError('')
      setAnnualIncomeError('')
    }
  }

  const goBack = () => {
    props.setIsVerifyOtp(false)
    props.sendOtpApi()
  }

  return (
		<>
			<div style={{ width: '400px' }} className={styles.profileModalBody}>
				<Row className={`p-0 mt-0 ${styles.basicDetail}`}>
					<Col className={`p-0 ${styles.modalHeadingText}`}>
						Investing profile
					</Col>
				</Row>
				<Row className={`${styles.inputRowsEdit}`}>
					<Col>
          {netWorthLiquidLabel !== '' ? (
            <>
						<ProfileDropdown
							placeholder='Net worth (Liquid)'
							label='Net worth (Liquid)'
							data={netWorthLiquidOptions}
							name='Net worth'
							value={netWorthLiquid}
							labelValue={netWorthLiquidLabel}
							onChange={value => {
								const event = value
								setNetWorthLiquid(event)
								// handleChange(event)
							}}
							className={styles.inputHeight}
						/>
            <p className={styles.profileErrorText}>{netWorthLiquidError}</p>
            </>
            ) : (
							''
						)}
					</Col>
				</Row>
				<Row className={`${styles.inputRowsEdit}`}>
					<Col>
          {netWorthTotalLabel !== '' ? (
            <>
						<ProfileDropdown
							placeholder='Net worth (Total)'
							label='Net worth (Total)'
							data={netWorthTotalOptions}
							name='Net worth'
							value={netWorthTotal}
							labelValue={netWorthTotalLabel}
							onChange={value => {
								const event = value
								setNetWorthTotal(event)
								// handleChange(event)
							}}
							className={styles.inputHeight}
						/>
            <p className={styles.profileErrorText}>{netWorthTotalError}</p>
            </>
            ) : (
							''
						)}
					</Col>
				</Row>
				{/* <Row className={`${styles.inputRowsEdit}`}>
					<Col>
						{objectivesLabel !== '' ? (
							<ProfileDropdown
								placeholder='Investment Objective'
								label='Investment Objective'
								data={objectivesOption}
								name='Investment Objective'
								labelValue={objectivesLabel}
								value={objectives}
								onChange={value => {
									const event = value
									setObjectives(event)
									// handleChange(event)
								}}
								className={styles.inputHeight}
							/>
						) : (
							''
						)}
					</Col>
				</Row> */}

				<Row className={`${styles.inputRowsEdit}`}>
					<Col>
						<div className='col-md-2'>
							{/* <ProfileDropdown
                        data={experienceOptions}
                        name="risk"
                        value={riskTolerance}
                        onChange={event => setRiskTolerance(event.target.value)}/> */}
							{/* <select className={styles.profileSelectBox} name="risk" value={riskTolerance}
                        onChange={event => setRiskTolerance(event.target.value)}
                        >
                            {riskOptions.map((item,index)=>
                            <>
                                <option value={item.value}>{item.label}</option>
                            </>
                            ) }
                        </select> */}
						</div>
						{experienceLabel !== '' ? (
							<ProfileDropdown
								placeholder='Investment Experience'
								label='Investment Experience'
								data={experienceOptions}
								name='Investment Experience'
								value={experience}
								labelValue={experienceLabel}
								onChange={value => {
									const event = value
									setExperience(event)
									// handleChange(event)
								}}
								className={styles.inputHeight}
							/>
						) : (
							''
						)}
					</Col>
				</Row>

				<Row className={`${styles.inputRowsEdit}`}>
					<Col>
						{riskToleranceLabel ? (
							<ProfileDropdown
								placeholder='Risk Tolerance'
								label='Risk Tolerance'
								data={riskOptions}
								name='Risk Tolerance'
								value={riskTolerance}
								labelValue={riskToleranceLabel}
								onChange={value => {
									const event = value
									setRiskTolerance(event)
									// handleChange(event)
								}}
								className={styles.inputHeight}
							/>
						) : (
							''
						)}
					</Col>
				</Row>

				<Row className={`${styles.inputRowsEdit}`}>
					<Col>
          {annualIncomeLabel !== '' ? (
						<ProfileDropdown
							placeholder='Annual Income'
							label='Annual Income'
							data={annualIncomeOptions}
							name='Annual Income'
							value={annualIncome}
							labelValue={annualIncomeLabel}
							onChange={value => {
								const event = value
								setAnnualIncome(event)
								// handleChange(event)
							}}
							className={styles.inputHeight}
						/> ) :  (
							''
						)}
            <p className={styles.profileErrorText}>{annualIncomeError}</p>
					</Col>
				</Row>
        <Row className={styles.SuccessOrErrorMessage}>{successOrErrorMessage && successOrErrorMessage.length > 0 ? successOrErrorMessage : "" }</Row>
        {buttonDisabled ?
        <Row>
          <Col className={`${styles.bottomButton}`}>
          <div className={styles.goBackButton} style={{marginLeft:"-50px"}} onClick={goBack}>
            Go Back
          </div>
          </Col>
        </Row> :
				<Row>
					<Col md={6} xs={6} className={`${styles.bottomButton}`}>
						<span onClick={props.handleClose}>Cancel</span>
					</Col>
					<Col md={6} xs={6} className={`${styles.saveBottomButton}`}>
						<Button
							style={{ backgroundColor: props.buttonColor }}
							className={`${styles.editSaveButton} themeButton`}
							onClick={checkData}>
							save changes
						</Button>
					</Col>
				</Row>}
			</div>
		</>
	)
}

export default EditInvestingProfile
