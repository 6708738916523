import React, { useContext } from "react"
import styles from "./direct.module.css"
import GenericErrorIcon from "../../../../public/images/genericError.svg"
import ArrowRightIcon from "../../../../public/images/arrowRight.svg"
import { AddFundContext } from "../../AddFundContext"

const Error = ()=>{
    const {setPage, onBack, PAGE} = useContext(AddFundContext)
    return(<div className={styles.errorContainer}>
            <img src={GenericErrorIcon}/>
            <div>Something went wrong</div>
            <div className={styles.label}>Please try another method to add funds.</div>
            <div className={styles.options}>
                <button className={styles.transferOption} onClick={()=>setPage(PAGE.ONLINE)}>
                    <div className="d-flex flex-column align-items-start" styles={{gap:'4px'}}>
                        <span className={styles.primary}>Online Independent Wire</span>
                        <span className={styles.secondary}>Do it through netbanking</span>
                    </div>
                    <img src={ArrowRightIcon} />
                </button>
                <button className={styles.transferOption} onClick={()=>setPage(PAGE.OFFLINE)}>
                    <div className="d-flex flex-column align-items-start" styles={{gap:'4px'}}>
                        <span className={styles.primary}>Offline Independent Wire</span>
                        <span className={styles.secondary}>Submit LRS form in nearest branch</span>
                    </div>
                    <img src={ArrowRightIcon} />
                </button>
            </div>            
            <div className={styles.textLink} onClick={onBack}>Go Back</div>
    </div>)
}

export default Error