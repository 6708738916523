import React, { Component } from 'react'
import styles  from './transitionPage.module.css'
import { ThemeContext } from './Context/ThemeContext'

class TransitionPage extends Component {
	constructor(props) {
		super(props)
  }

	static contextType = ThemeContext

	componentDidMount() {
		let that = this
		setTimeout(function () {
			window.location.href = that.props.landingUrl
		}, 500)
	}

	render() {
    const { loaderColor } = this.context

		return (
			<div>
				<div className={styles.loader} style={{borderTopColor:loaderColor}}></div>
				<div
					className='text-center'
					style={{ position: 'absolute', top: '47%', left: '48%' }}>
					Loading
				</div>
			</div>
		)
	}
}

export default TransitionPage
