import React, { Fragment, useState } from 'react'
import CalendarIcon from '../../../public/images/calendarIcon.png'
import { format } from 'date-fns'
import OutsideClickHandler from 'react-outside-click-handler'
import DatePicker from '../../DatePicker/DatePicker'
import { Form } from 'react-bootstrap'
import styles from './Nominee.module.css'

const DateInput=({name, value: dob, onChange, onBlur})=>{
    const [showCalendar, setShowCalendar] = useState(false)
    let date = new Date();
    date.setDate(date.getDate()-1);
	const toggleCalender = () => {
		setShowCalendar(!showCalendar);
	}
	const onOutsideClick = () => {
		setShowCalendar(false);
        onBlur();
	}
	const onHandleDate = date => {
		onChange(format(date, 'yyyy-MM-dd'))
		toggleCalender();
	}
    return(
    <Fragment>
        <Form.Group
            className={`form-focus ${
                dob ? 'focused' : null
            }`}>
            <img
                src={CalendarIcon}
                className={styles.calendaricon}
                onClick={toggleCalender}></img>
            <Form.Control
                className={styles.dropdown}
                type='text'
                name='dateOfBirth'
                value={dob}
                readOnly={true}
                onBlur={onBlur}
                onClick={toggleCalender}></Form.Control>
            <Form.Label className='focus-label'>
                {name}
            </Form.Label>
        </Form.Group>
        {showCalendar ? (
            <div className={styles.calendar}>
                <OutsideClickHandler
                    onOutsideClick={onOutsideClick}>
                    <DatePicker
                        onChange={date => {
                            onHandleDate(date)
                        }}
                        date={date}
                        color='#0061D3'
                        maxDate={date}
                    />
                </OutsideClickHandler>
            </div>
        ) : (
            ''
        )}
    </Fragment>);
}
export default DateInput