import React from 'react'
import styles from './bankAccountAdded.module.css'
import { Row, Col } from 'react-bootstrap'

const BankAccountAdded = ({ setModalData, data }) => {
	return (
		<div className={styles.card}>
			<Row style={{ paddingLeft: '100px' }}>
				<Col md={9}>
					<div className={styles.title}>Bank account added successfully</div>
				</Col>
			</Row>
		</div>
	)
}

export default BankAccountAdded
