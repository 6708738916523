import React, { useContext, useEffect } from "react"
import { AddFundContext } from "../../AddFundContext"
import LoadingIcon from "../../../../public/images/easyfund/Loading.svg"
import styles from './easyfund.module.css'

const Cancel = ()=>{
  const {setNoHeader, setNoClose} = useContext(AddFundContext)
  useEffect(()=>{
          setNoHeader(true)
          setNoClose(true)
  },[])
    return (<div className={styles.cancelContainer}>
        <div>
            <div className={styles.cancelHeader}>
                <img src={LoadingIcon}/>
                <span className='text-center'>Your Aadhaar Card details are being processed</span>
            </div>
            <div className={styles.cancelBody}>
                <span>
                  KYC Verification is required for international fund transfers. This usually takes a couple of minutes. We’ll notify as soon as your details are verified.
                </span>
                <span className={styles.tryAgainContainer} style={{color:'#0061D3',cursor:'pointer'}} onClick={()=>window.fcWidget.open()}>
                Contact support
              </span>
            </div>
        </div>
    </div>)
}

export default Cancel
