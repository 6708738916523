import React from 'react'
import { Form } from 'react-bootstrap'
import styles from './SignUpInput.module.css'
import { useEffect, useState } from 'react'

const SignUpInput = ({
  label,
  placeholder,
  type,
  classes,
  name,
  refValue,
  tabIndex,
  value,
  onChange,
  onBlur,
  onFocus,
  onKeyPress,
  isInvalid,
  isValid,
  onPaste,
  onCopy,
  errorMessage,
  icon,
  containerClasses,
  autoComplete,
  readOnly,
  onClick
}) => {
  // console.log('error input',errorMessage)
  return(
    <Form.Group
    className={`${styles.inputContainer} ${containerClasses}`}>
      {label ?
        <Form.Label className={styles.label}>
           {label}
        </Form.Label> :''}
        <div style={{position:'relative'}}>
          <img src={icon} className={styles.icon}></img>
          <Form.Control
            placeholder={placeholder}
            className={`${classes} ${styles.inputs}`}
            style={icon ? {} : {paddingLeft:'16px'}}
            type={type}
            name={name}
            ref={refValue}
            tabIndex={tabIndex}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyPress={onKeyPress}
            isInvalid={isInvalid}
            isValid={isValid}
            onPaste={onPaste}
            onCopy={onCopy}
            readOnly={readOnly}
            onClick={onClick}></Form.Control>
          <Form.Control.Feedback type='invalid' className={styles.errorState}>
            {errorMessage}
          </Form.Control.Feedback>
        </div>
    </Form.Group>
  )
}

export default SignUpInput
