export const initialState = {
	userSummary: {},
	userInfo: {},
	userPortfolio: {},
	userInvestmentHistory: {},
	marketDetails: {},
  // userPlanInfo: {},
  countryList:[],
  homeNews:[],
  topStacks:[],
  yearHighStock:[],
  yearLowStock:[],
  myNotification:[],
  fund:{show:false}
}
