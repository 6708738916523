import React from 'react'
import { Row, Col, Modal,Form} from 'react-bootstrap'
import Dropdown from '../../../DropDown/DropDown'
import OutsideClickHandler from 'react-outside-click-handler'
import SignUpButton from '../../../SignUp/SignUpButton'
import SignUpInput from '../../../SignUp/SignUpInput'
import SignUpDropDown from '../../../SignUp/SignUpDropDown'
import DropDownButton from '../../../SignUp/DropDownButton'
import { updatedAccount,getPAN,verifyPAN } from '../../../../api'
import styles from '../../AccountSetUp.module.css'
// import './modal.css'
import {riskOptions,employmentStatusOptions,employeeTypeOptions,
  employeePositionsOptions,investmentObjOptions,investmentExpOptions,
  annualIncomeOptions,netWorthOptions,networthTotalOptions} from './FinancialData'
import { setUserInfoSession, currencyFormatter } from '../../../../common'
import { ThemeContext } from '../../../../Context/ThemeContext'

class FinancialDetail extends React.Component {
	constructor(props) {
		super(props)
		const {
			formData: { financialDetail }
		} = this.props

    const userInformation = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : ''

		this.state = {
			employmentStatus: financialDetail.employmentStatus ? financialDetail.employmentStatus : userInformation && userInformation.employmentStatus ? userInformation.employmentStatus : '',
			employeeCompany: financialDetail.employeeCompany ? financialDetail.employeeCompany : userInformation && userInformation.employerCompany ? userInformation.employerCompany : '',
			employeeType: financialDetail.employeeType ? financialDetail.employeeType : userInformation && userInformation.employerBusiness ? userInformation.employerBusiness : '',
			employeePositions: financialDetail.employeePositions ? financialDetail.employeePositions :  userInformation && userInformation.employmentPosition ? userInformation.employmentPosition : null,
			panCard: financialDetail.panCard ? financialDetail.panCard : userInformation && userInformation.idNo ? userInformation.idNo : '',
			investmentObject: financialDetail.investmentObject
				? financialDetail.investmentObject : userInformation && userInformation.investmentObjectives ? userInformation.investmentObjectives :
				investmentObjOptions[2].value ? investmentObjOptions[2].value :'' ,
			investmentExper: financialDetail.investmentExper
				? financialDetail.investmentExper :userInformation && userInformation.investmentExperience ? userInformation.investmentExperience
				: investmentExpOptions[2].value ? investmentExpOptions[2].value :'' ,
			riskTolerance: financialDetail.riskTolerance
				? financialDetail.riskTolerance : userInformation && userInformation.riskTolerance ? userInformation.riskTolerance
				: riskOptions[0].value ? riskOptions[0].value :'',
			annualIncome: financialDetail.annualIncome
				? currencyFormatter('USD', this.setAnnualIncomeValue(parseInt(financialDetail.annualIncome.slice(1).replace(/\,/g,'')))) : userInformation && userInformation.annualIncome ? currencyFormatter('USD', this.setAnnualIncomeValue(parseInt(userInformation.annualIncome)))
				: annualIncomeOptions[0].value ? annualIncomeOptions[0].value :'',
			netWorth: financialDetail.netWorth ? currencyFormatter('USD', this.setNetWorthValue(parseInt(financialDetail.netWorth.slice(1).replace(/\,/g,'')))) :userInformation && userInformation.networthLiquid ? currencyFormatter('USD', this.setNetWorthValue(parseInt(userInformation.networthLiquid))) :
          netWorthOptions[0].value ? netWorthOptions[0].value :'',
			networthTotal: financialDetail.networthTotal
        ? currencyFormatter('USD', this.setAnnualIncomeValue(parseInt(financialDetail.networthTotal.slice(1).replace(/\,/g,'')))) : userInformation && userInformation.networthTotal ? currencyFormatter('USD', this.setAnnualIncomeValue(parseInt(userInformation.networthTotal)))
        : networthTotalOptions[0].value ? networthTotalOptions[0].value :'',
			showRiskModal: false,
			calculateRisk: false,
			touchedPanCard: false,
			countryListForSourceOfFunds:props.countryListForSourceOfFunds,
			sourceOfFunds: financialDetail.sourceOfFunds
			? financialDetail.sourceOfFunds
			: '',
			sourceOfFundsCode: financialDetail.sourceOfFundsCode
			? financialDetail.sourceOfFundsCode
			: '',
      flagCode:financialDetail.flagCode ? financialDetail.flagCode :'',
			loading:false,
      showCountryDropDown:false,
      showEmployeeDrop:false,
      showEmployePositionDrop:false,
      showEmployeTypeDrop:false,
      showAnnualDrop:false,
      showTotalDrop:false,
      showLiquidDrop:false,
      showObjectDrop:false,
      showExpDrop:false,
      showRiskDrop:false,
      requiredError:{},
      panVerified:false,
    }
	}

  static contextType = ThemeContext

// componentDidUpdate(prevProps) {
  //   debugger
  //   if(this.props.formData.financialDetail &&
  //     !(this.props.formData.financialDetail.sourceOfFundsCode === prevProps.formData.financialDetail.sourceOfFundsCode))
  //   {
  //     // this.onCountryChange()
  //   }
  // }

  setAnnualIncomeValue = (value) => {
    let finalValue

    if(value >= 0 && value <= 24999){
      finalValue = 24999
    } else if (value >= 25000 && value <= 99999) {
      finalValue = 99999
    } else if (value >= 100000 && value <= 199999) {
      finalValue = 199999
    } else {
      finalValue = 200000
    }
    return finalValue
  }

  setNetWorthValue = (value) => {
    let finalValue

    if(value >= 0 && value <= 4999){
      finalValue = 4999
    } else if (value >= 5000 && value <= 99999) {
      finalValue = 99999
    } else if (value >= 100000 && value <= 199999) {
      finalValue = 199999
    } else {
      finalValue = 200000
    }
    return finalValue
  }


	openRiskModal = () => {
		this.setState({ showRiskModal: true })
	}

	closeRiskModal = () => {
		this.setState({ showRiskModal: false })
	}

	calculateRisk = () => {
		this.setState({ calculateRisk: true })
	}

  updateParentState=(updatedState)=>{
    const financialDetail = Object.assign({},updatedState)
    // delete financialDetail.showRiskModal;
    // delete financialDetail.calculateRisk;
   this.props.setFormPreData({ financialDetail})
  }

	onChangeHandler = event => {
    if(event.target.name === 'panCard') {
		const updatedState = Object.assign(this.state,{ [event.target.name]: event.target.value.replace(/[^a-zA-Z0-9]/g, '')})
    	this.setState({ [event.target.name]: event.target.value.replace(/[^a-zA-Z0-9]/g, '')},this.updateParentState(updatedState))
    }
	else {
		const updatedState = Object.assign(this.state,{ [event.target.name]: event.target.value})
    	this.setState({ [event.target.name]: event.target.value },this.updateParentState(updatedState))
	}
  }

  onChangeHandlerPan = event => {
    const details = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : '';
    const countryCode = details.countryID ? details.countryID : (details.country ? details.country :'')
    if(countryCode === 'IND') {
      if(event.target.value.length <= 10){
		 const updatedState = Object.assign(this.state,{ [event.target.name]: event.target.value.replace(/[^a-zA-Z0-9]/g, '')})
    	this.setState({ [event.target.name]: event.target.value.replace(/[^a-zA-Z0-9]/g, '')},this.updateParentState(updatedState))
      }
    }
	else {
		const updatedState = Object.assign(this.state,{ [event.target.name]: event.target.value})
    	this.setState({ [event.target.name]: event.target.value },this.updateParentState(updatedState))
	}
  }

  onChangeNameAndValue=(name,value)=>{
    if(name === 'employmentStatus'){
      if(value === employmentStatusOptions[0].value || value === employmentStatusOptions[4].value ){
      this.setState({employeeType:employeeTypeOptions[11].value  ? employeeTypeOptions[11].value :''})
      }else{
        this.setState({employeeType:''})
      }
    }
    this.onUpdateFormValidation(false)
    const updatedState = Object.assign(this.state,{[name]:value })
		this.setState({ [name]: value },this.updateParentState(updatedState))
  }

	// onChangeEmploymentStatus = value => {
  //   const updatedState = Object.assign(this.state,{ employmentStatus: value })
	// 	this.setState({ employmentStatus: value },this.updateParentState(updatedState))
  // }

  getFlagCode = (value) => {
    const filter = this.props.countryListForSourceOfFunds.filter(item => {
      return value === (item.code)
    })
    if(filter > 0){
    this.setState({flagCode:filter[0].code2})
  }
  }


  fetchPan = async () => {
    const details = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : '';
    const countryCode = details.countryID ? details.countryID : (details.country ? details.country :'')
    if(countryCode === 'IND'){
    try{
      const res = await getPAN()
      if(res.data.code === 200 && res.data.data){
        if(res.data.data.idNo){
          this.setState({panCard:res.data.data.idNo})
        }
      }
      }catch(error){
        console.log('err',error)
      }
    }else{
      //  this.setState({panVerified:true})
    }
   }

  componentDidMount = () => {
    const details = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : '';
    const countryCode = details.countryID ? details.countryID : (details.country ? details.country :'')
    if(countryCode){
      this.getFlagCode(countryCode)
    }
   this.fetchPan()
  }

  componentDidUpdate = (prevProps,prevState) => {
    if(prevProps.financialDetail !== this.props.financialDetail){
      if(this.props.formData.financialDetail.sourceOfFundsCode){
        this.getFlagCode(this.props.formData.financialDetail.sourceOfFundsCode)
      }
   }
  }

  onCountryChange = (value) => {
    const filter = this.state.countryListForSourceOfFunds.filter(item => {
			return value === (item.value)
    })
    let event = {
      target: { name: 'sourceOfFunds', value:'' }
    }
    let obj;
    if(filter.length !== 0){
      obj = Object.assign({},{
        sourceOfFunds: filter[0].value,
        sourceOfFundsCode: filter[0].code,
      })
     event.target.value=value;
    }else{
      event.target.value='';
      obj = Object.assign({},{sourceOfFunds:'',sourceOfFundsCode:''})
    }
    const updatedState = Object.assign(this.state,obj)
    this.setState(obj,this.updateParentState(updatedState))

    // handleChange(event);

  }

  errorStatus = () => {
    let errorrequired = {
      panrequired:'',
      employeeStatusReq:'',
      annualIncomeReq:'',
      totalnetReq:'',
      liquidnetReq:'',
      investObjReq:'',
      investExpReq:'',
      riskReq:''
    }
    this.setState({requiredError:{...errorrequired}})
  }

   checkPan  = () => {
    const details = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : '';
    const countryCode = details.countryID ? details.countryID : (details.country ? details.country :'')
    console.log('code',countryCode)
    if(countryCode === 'IND' && !this.state.panVerified){
      if(!/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(this.state.panCard)){
        return false
      }
    }
    return true
  }

  onCheckRequired = () => {
    const {
			employmentStatus,
			panCard,
			investmentObject,
			investmentExper,
			riskTolerance,
			annualIncome,
			netWorth,
			networthTotal,
			employeeCompany,
			employeePositions,
      employeeType
		} = this.state
    let errorrequired = {
      panrequired:'',
      employeeStatusReq:'',
      annualIncomeReq:'',
      // totalnetReq:'',
      // liquidnetReq:'',
      // investObjReq:'',
      investExpReq:'',
      riskReq:''
    }
    this.setState({requiredError:{...errorrequired}})
    const panrequired = panCard === '' ? 'Pan Card is required' : ''
    const employeeStatusReq = employmentStatus === '' ? 'Employee Status is required' : ''
    const annualIncomeReq = annualIncome === '' ? 'Annual Income is required' : ''
    // const totalnetReq = networthTotal === '' ? 'Total net worth is required' : ''
    // const liquidnetReq = netWorth === '' ? 'Liquid net worth is required' : ''
    // const investObjReq = investmentObject === '' ? 'Investment Objective is required' : ''
    const investExpReq = investmentExper === '' ? 'Investment Experience is required' : ''
    const riskReq = riskTolerance === '' ? 'Risk Tolerance is required' : ''
    let checkingReq = {
      panrequired,
      employeeStatusReq,
      annualIncomeReq,
      // totalnetReq,
      // liquidnetReq,
      // investObjReq,
      investExpReq,
      riskReq
    }
    if(panrequired !== ''
       || employeeStatusReq !== '' || annualIncomeReq !== '' ||
        investExpReq !== '' || riskReq !== ''){
       this.setState({requiredError:{...checkingReq},loading:false})
       }
    else{
      const checkPanValidation = this.checkPan()
      if(checkPanValidation){
      return true
      }else{
        this.setState({requiredError :{...checkingReq,panrequired:'Please Enter Valid PAN Number'},loading:false})
      }
    }
  }

  onUpdateFormValidation = (value) => {
    if(this.props.formValidation.financialDetail !== value){
    this.props.updateFormValidation({...this.props.formValidation,financialDetail:value})
    }
  }

	onFormSubmit = () => {
    this.setState({loading:true})
		const {
			employmentStatus,
			panCard,
			investmentObject,
			investmentExper,
			riskTolerance,
			annualIncome,
			netWorth,
			networthTotal,
			employeeCompany,
			employeePositions,
      employeeType,
      sourceOfFunds,
      sourceOfFundsCode,
      flagCode
		} = this.state
    let financialDetailError= {
      pancardError:'',
      errorMessage:'',
      error:false
    }
    this.props.errorHandler({financialDetailError})
		const {
			formData: {
				addressDetail: { country = '' }
			}
		} = this.props
		const idType = country === 'India' ? 'PAN' : 'National id'
		const data = {
			investmentObjectives: investmentObject,
			employmentStatus: employmentStatus,
      employmentPosition:employeePositions,
			investmentExperience: investmentExper,
			// employmentType: employeeType,
      employmentCountry: sourceOfFundsCode,
      employerCountryID: sourceOfFundsCode,
			riskTolerance: riskTolerance,
			annualIncome: Number(annualIncome.replace('$','').replace(',','')),
			networthLiquid: Number(netWorth.replace('$','').replace(',','')),
			networthTotal: Number(networthTotal.replace('$','').replace(',','')),
			idType: idType,
			idNo: panCard,
			nextStep: 'POLITICALLY_EXPOSED'
		}
    if(employeeType !== ''){
      data.employmentType = employeeType
    }
    const errorUpdate = this.onCheckRequired()
    if(errorUpdate){
		updatedAccount(data)
			.then(res => {
				if (res.code === 200) {
          delete data.employmentType;
          delete data.employmentCountry;
          const userInfo = Object.assign(data,{employerBusiness:employeeType})
          setUserInfoSession(userInfo)

					const financialDetail = {
						employmentStatus,
						panCard,
						investmentObject,
						investmentExper,
						riskTolerance,
						annualIncome,
						netWorth,
						networthTotal,
						employeeCompany,
						employeePositions,
            employeeType,
            sourceOfFunds,
            sourceOfFundsCode,
            flagCode
					}
          const details = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : '';
            // eslint-disable-next-line no-undef
            webengage.user.login(details.username);
           // eslint-disable-next-line no-undef
          webengage.user.setAttribute('Risk Tolerance', riskTolerance);
           // eslint-disable-next-line no-undef
          webengage.user.setAttribute('Investment Experience', investmentExper);
           // eslint-disable-next-line no-undef
          webengage.user.setAttribute('Employment Type', employmentStatus);
           // eslint-disable-next-line no-undef
           webengage.track("Financial Detail Added")
           this.setState({loading:false})
           this.onUpdateFormValidation(true)
          this.props.changeCurrent('POLITICALLY_EXPOSED')
					this.props.nextForm({ financialDetail })
				}
			})
			.catch(error => {
				this.errorHandlerMessage(error.message)
        this.setState({loading:false})
			})
    }
	}

  errorHandlerMessage = (message) => {
    let financialDetailError= {
      pancardError:'',
      errorMessage:'',
      error:false
    }
    if(message.includes('id')){
      financialDetailError = {...financialDetailError,pancardError:message}
    }
    else{
      financialDetailError = {...financialDetailError,errorMessage:message}
    }
    financialDetailError = {...financialDetailError,error:true}
    this.props.errorHandler({financialDetailError})
  }

  getRiskMessage = () => {
    switch(this.state.riskTolerance){
      case 'HIGH':
         return ('To achieve significant growth or capital appreciation overtime, investment in the medium to high-risk products such as FX trading, individual stocks, Initial Public Offerings (IPO’s). Increased chance of losing capital');
      case 'LOW':
      return ('To preserve capital or real value of investments and earn steady income of interest income. Minimal risk of losing capital. Investment types such as ETF, Government Bonds, Certificate of Bonds.');
      case 'MODERATE':
        return ('To achieve growth through a balance between interest income and capital gain over a medium-term period (five to ten years growth).');
    }
  }

  getValueFromoptions = (value,options) => {
    const filtered = options.filter((item) => item.value === value)
    if(filtered){
      return filtered[0].label
    }
    return;
  }


  // onVerifyPan = async (event) => {
  //     try{
  //       const userInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : {}
  //       if(userInfo){
  //           const fullname= `${userInfo.firstName} ${userInfo.middleName} ${userInfo.lastName}`
  //           const dob=format(new Date(userInfo.dob), "dd/MM/yyyy")
  //           const res= await verifyPAN({pan:event.target.value, name:fullname, dob})
  //           if(res.data && res.data.code === 200){
  //             this.setState({panVerified:true})
  //           }else{
  //             if(res.data && res.data.message){
  //               this.setState({panVerifiedError:res.data.message})
  //             }else{
  //               this.setState({panVerifiedError:'Something went wrong'})
  //             }
  //         }
  //       }
  //   }catch(error){
  //     console.log('error',error)
  //   }
  // }

	render() {
    // console.info('sourceOfFundsCode', this.state.sourceOfFunds)
    // console.info('sourceOfFundsCode',this.state.sourceOfFundsCode)
    const { partnerConfig } = this.context
    let financialDropDownData = partnerConfig?.[0]?.annualIncomeOptions
    let annualDropDownToShow = financialDropDownData !== undefined ?  financialDropDownData : annualIncomeOptions;
		const {
			employmentStatus,
			panCard,
			investmentObject,
			investmentExper,
			riskTolerance,
			annualIncome,
			netWorth,
			networthTotal,
			employeeCompany,
			employeePositions,
			employeeType,
      showRiskModal,
      sourceOfFunds,
      sourceOfFundsCode
		} = this.state

    const {
      panrequired ='',
      employeeStatusReq='',
      annualIncomeReq='',
      totalnetReq='',
      liquidnetReq='',
      investObjReq='',
      investExpReq='',
      riskReq=''
    } = this.state.requiredError

		const {
			formData: {
				addressDetail: { country = '' }
			},disabledValue
		} = this.props

    const userData = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : ''
    const countryCode = userData.country

    const buttonEmployeeDisable =
         employmentStatus === 'EMPLOYED' || employmentStatus === 'SELF_EMPLOYED' ?
          employeePositions !== '' &&
         	employeeType !== '' &&
         	employeeCompany !== '' ? false : true
         : false

	const disableButton =
		employmentStatus !== '' &&
		panCard !== '' &&
		investmentObject !== '' &&
		investmentExper !== '' &&
		riskTolerance !== '' &&
		annualIncome !== '' &&
		netWorth !== '' &&
		networthTotal !== '' &&
    sourceOfFundsCode !== ''
    // &&
    // panVerified
		// && sourceOfFunds !== ''
			? true
			: false
	const {pancardError,errorMessage} = this.props.errors
    const sourceOfFundsDiv = this.state.countryListForSourceOfFunds.length !==0 ?

                <Dropdown
                  className={styles.dropdowmn}
                  placeholder='Country of source of funds'
                  data={this.state.countryListForSourceOfFunds}
                  name='sourceOfFunds'
                  value={sourceOfFunds}
                  // onChange={value => {
                  //   this.onCountryChange(value,handleChange)
                  // }}
                  onChange={value => this.onCountryChange(value)}
                />
                // <Form.Control.Feedback
                //   type='invalid'
                //   style={{ display: 'block' }}>
                //   {touched.country && errors.country ? errors.country : ''}
                //   {countryError}
                // </Form.Control.Feedback>
      :''
		return (
			<>
			<Row className={styles.blackText}>
						Why do we need this?
				</Row>
        <Row className={styles.detail}>
        We need to understand your basic financial background to set up your investment account. We only ask what’s legally required to complete your account setup.</Row>
        <Row className={styles.personalInputRow}>
           <Col
            className={styles.mobileMargin}
            md={4}
            xs={12}>
                <div className={styles.label}>Country of source of funds</div>
                 <div>
                    <DropDownButton
                      tabIndex='1'
                      onClick={() => this.setState({showCountryDropDown : !this.state.showCountryDropDown})}
                      showDropDown={this.state.showCountryDropDown}
                      error=''
                      >
                        <img className={styles.flagImgCode} src={`${process.env.REACT_APP_ASSESTS}/flags/${this.state.flagCode}.svg`}></img>
                        <span className={styles.countryLabel} style={{paddingLeft:'12px'}}>{this.state.sourceOfFunds}</span>
                    </DropDownButton>
                    <OutsideClickHandler onOutsideClick={() => this.setState({showCountryDropDown:false})}>
                    <SignUpDropDown
                      classes={styles.dropdownWidth}
                      data={this.state.countryListForSourceOfFunds}
                      showDropDown={this.state.showCountryDropDown}
                      onChange={(item) => {
                        this.setState({sourceOfFunds:item.label,sourceOfFundsCode:item.code,showCountryDropDown:false,flagCode:item.code2.toUpperCase()},this.onUpdateFormValidation(false))
                        }}
                      placeholder='Country'
                    />
                    </OutsideClickHandler>
                  </div>
                {/* <div className={styles.errorState}>{this.state.countryError}</div> */}
           </Col>
           {country === 'India' && disabledValue && panCard !== '' ? '' :
           <Col md={4} xs={12} className={`${styles.mobileMargin}`}>
                 <SignUpInput
                    tabIndex='2'
                    label={country === 'India' ? 'Permanent Account Number (PAN)' : 'National ID Number'}
                    classes={styles.panCardCapital}
                    placeholder = {country === 'India' ? 'Enter PAN Number' : 'National ID Number'}
                    type='text'
                    name='panCard'
                    value={panCard}
                    onChange={(e) => {
                      let financialDetailError= {
                        ...this.props.errors,
                        pancardError:''
                      }
                      this.onUpdateFormValidation(false)
                      this.errorStatus()
                      this.setState({validatedPanCard:false})
                      this.setState({touchedPanCard:true})
                      this.props.errorHandler({financialDetailError})
                      this.onChangeHandlerPan(e)
                      // this.onChangeHandler(e)
                    }}
                    onPaste={(e)=>{
                      e.preventDefault()
                      return false;
                    }} onCopy={(e)=>{
                      e.preventDefault()
                      return false;}}
                    onBlur={(e) => {
                      this.setState({touchedPanCard:true})
                    }}
                    isInvalid={(this.state.touchedPanCard && panCard === '') || pancardError !== '' || panrequired !== ''}
                    errorMessage={panrequired || pancardError || (country === 'India' ? 'PAN Card Number is required ' : 'National ID Number is required ') }
                  />
           </Col>}
           <Col
            className={styles.mobileMargin}
            md={4}
            xs={12}>
                <div className={styles.label}>Employment Status</div>
                 <div>
                    <DropDownButton
                      tabIndex='3'
                      onClick={() => this.setState({showEmployeeDrop : !this.state.showEmployeeDrop})}
                      showDropDown={this.state.showEmployeeDrop}
                      error={employeeStatusReq}
                      >
                        <span className={employmentStatus !== '' ? styles.countryLabel : styles.accountDropPlaceholder}>
                          {employmentStatus ? this.getValueFromoptions(employmentStatus,employmentStatusOptions) : 'Select'}</span>
                    </DropDownButton>
                    <OutsideClickHandler onOutsideClick={() => this.setState({showEmployeeDrop:false})}>
                    <SignUpDropDown
                      classes={styles.dropdownWidth}
                      data={employmentStatusOptions}
                      showDropDown={this.state.showEmployeeDrop}
                      onChange={(item) => {
                        this.errorStatus()
                        this.onChangeNameAndValue('employmentStatus',item.value)
                        this.setState({showEmployeeDrop:false})
                        }}
                      isSearchable={false}
                      placeholder='Country'
                    />
                    </OutsideClickHandler>
                  </div>
                <div className={styles.errorState}>{employeeStatusReq}</div>
           </Col>
        </Row>
          {employmentStatus === 'EMPLOYED' ||
          employmentStatus === 'SELF_EMPLOYED' ? (
            <>
              <Row className={styles.personalInputRow}>
              {/* <Col md={4} xs={12} className={styles.mobileMargin}>
                <SignUpInput
                    label='Company Name'
                    placeholder = 'Enter'
                    type='text'
                    name='employeeCompany'
                    value={employeeCompany}
                    onChange={this.onChangeHandler}
                />
							</Col> */}
              {/* <Col
                className={styles.mobileMargin}
                md={4}
                xs={12}>
                    <div className={styles.label}>Employee Position</div>
                    <div>
                        <DropDownButton
                          onClick={() => this.setState({showEmployePositionDrop : !this.state.showEmployePositionDrop})}
                          showDropDown={this.state.showEmployePositionDrop}
                          error=''
                          >
                            <span className={employeePositions !== '' ? styles.countryLabel : styles.accountDropPlaceholder}>
                              {employeePositions ? this.getValueFromoptions(employeePositions,employeePositionsOptions) : 'Select'}</span>
                        </DropDownButton>
                        <OutsideClickHandler onOutsideClick={() => this.setState({showEmployePositionDrop:false})}>
                        <SignUpDropDown
                          classes={styles.dropdownWidth}
                          data={employeePositionsOptions}
                          showDropDown={this.state.showEmployePositionDrop}
                          onChange={(item) => {
                            this.errorStatus()
                            this.onChangeNameAndValue('employeePositions',item.value)
                            this.setState({showEmployePositionDrop:false})
                            }}
                          isSearchable={false}
                          placeholder='Country'
                        />
                        </OutsideClickHandler>
                      </div>
              </Col> */}
              <Col
                className={styles.mobileMargin}
                md={4}
                xs={12}>
                    <div className={styles.label}>Employment Type</div>
                    <div>
                        <DropDownButton
                          onClick={() => this.setState({showEmployeTypeDrop : !this.state.showEmployeTypeDrop})}
                          showDropDown={this.state.showEmployeTypeDrop}
                          error=''
                          >
                            <span className={employeeType !== '' ? styles.countryLabel : styles.accountDropPlaceholder}>
                              {employeeType ? this.getValueFromoptions(employeeType,employeeTypeOptions) : 'Select'}</span>
                        </DropDownButton>
                        <OutsideClickHandler onOutsideClick={() => this.setState({showEmployeTypeDrop:false})}>
                        <SignUpDropDown
                          classes={styles.dropdownWidth}
                          data={employeeTypeOptions}
                          showDropDown={this.state.showEmployeTypeDrop}
                          onChange={(item) => {
                            this.errorStatus()
                            this.onChangeNameAndValue('employeeType',item.value)
                            this.setState({showEmployeTypeDrop:false})
                            }}
                          isSearchable={false}
                          placeholder='Country'
                        />
                        </OutsideClickHandler>
                      </div>
              </Col>
              </Row>
            </>
          ) : (
            ' '
          )}

				<Row className={styles.personalInputRow}>
					<div className={styles.heading}>Investments-related Details</div>
					<div className={styles.desc}>
						Tell us why you want to invest and how much general investing
						experience you’ve had in the past - Mutual Funds, stocks, etc, and
						your appetite for{' '}
						<span className={styles.edit} onClick={this.openRiskModal}>
							risk
						</span>
					</div>
				</Row>

				<Row className={styles.personalInputRow}>
        <Col
              className={styles.mobileMargin}
              md={4}
              xs={12}>
                  <div className={styles.label}>Annual Income</div>
                  <div>
                      <DropDownButton
                        tabIndex='4'
                        onClick={() => this.setState({showAnnualDrop : !this.state.showAnnualDrop})}
                        showDropDown={this.state.showAnnualDrop}
                        error={annualIncomeReq}
                        >
                          <span className={annualIncome !== '' ? styles.countryLabel : styles.accountDropPlaceholder}>
                            {annualIncome ?
                            this.getValueFromoptions(annualIncome,annualDropDownToShow)
                            : 'Select'}</span>
                      </DropDownButton>
                      <OutsideClickHandler onOutsideClick={() => this.setState({showAnnualDrop:false})}>
                      <SignUpDropDown
                        classes={styles.dropdownWidth}
                        data={annualDropDownToShow}
                        showDropDown={this.state.showAnnualDrop}
                        onChange={(item) => {
                          this.errorStatus()
                          this.onChangeNameAndValue('annualIncome',item.value)
                          this.setState({showAnnualDrop:false})
                          }}
                        isSearchable={false}
                        placeholder='Country'
                      />
                      </OutsideClickHandler>
                    </div>
                    <div className={styles.errorState}>{annualIncomeReq}</div>
                  </Col>
                  <Col
                className={styles.mobileMargin}
                md={4}
                xs={12}>
                    <div className={styles.label}>Investment Experience</div>
                    <div>
                        <DropDownButton
                         tabIndex='5'
                          onClick={() => this.setState({showExpDrop : !this.state.showExpDrop})}
                          showDropDown={this.state.showExpDrop}
                          error={investExpReq}
                          >
                            <span className={investmentExper !== '' ? styles.countryLabel : styles.accountDropPlaceholder}>
                              {investmentExper ?
                                this.getValueFromoptions(investmentExper,investmentExpOptions)
                              : 'Select'}</span>
                        </DropDownButton>
                        <OutsideClickHandler onOutsideClick={() => this.setState({showExpDrop:false})}>
                        <SignUpDropDown
                          classes={styles.dropdownWidth}
                          data={investmentExpOptions}
                          showDropDown={this.state.showExpDrop}
                          onChange={(item) => {
                            this.errorStatus()
                            this.onChangeNameAndValue('investmentExper',item.value)
                            this.setState({showExpDrop:false})
                            }}
                          isSearchable={false}
                          placeholder='Country'
                        />
                        </OutsideClickHandler>
                      </div>
                      <div className={styles.errorState}>{investExpReq}</div>
                    </Col>
                    <Col
              className={styles.mobileMargin}
              md={4}
              xs={12}>
                  <div className={styles.label}>Risk Tolerance</div>
                  <div>
                      <DropDownButton
                        tabIndex='6'
                        onClick={() => this.setState({showRiskDrop : !this.state.showRiskDrop})}
                        showDropDown={this.state.showRiskDrop}
                        error={riskReq}
                        >
                          <span className={riskTolerance !== '' ? styles.countryLabel : styles.accountDropPlaceholder}>
                            {riskTolerance ? this.getValueFromoptions(riskTolerance,riskOptions) : 'Select'}</span>
                      </DropDownButton>
                      <OutsideClickHandler onOutsideClick={() => this.setState({showRiskDrop:false})}>
                      <SignUpDropDown
                        classes={styles.dropdownWidth}
                        data={riskOptions}
                        showDropDown={this.state.showRiskDrop}
                        onChange={(item) => {
                          this.errorStatus()
                          this.onChangeNameAndValue('riskTolerance',item.value)
                          this.setState({showRiskDrop:false})
                          }}
                        isSearchable={false}
                        placeholder='Country'
                      />
                      </OutsideClickHandler>
                    </div>
                    <div className={styles.errorState}>{riskReq}</div>
                  </Col>
					{/* <Col className={styles.colPaddningMobile} md={4} xl={4} xs={12}>
						<Dropdown
							data={annualIncomeOptions}
							name='annualIncome'
							value={annualIncome}
							className={styles.dropdowmn}
							placeholder='Annual Income'
              isLabeled={true}
              // onChange={value => this.setState({ annualIncome: value })}
              onChange={value => this.onChangeNameAndValue('annualIncome',value)}
						/>
					</Col> */}
					{/* <Col md={4} xl={4} xs={12} className={styles.colMarginMobile}>
						<Dropdown
							data={netWorthOptions}
							name='netWorth'
							value={netWorth}
							className={styles.dropdowmn}
							placeholder='Net Worth Liquid'
              isLabeled={true}
              // onChange={value => this.setState({ netWorth: value })}
              onChange={value => this.onChangeNameAndValue('netWorth',value)}
						/>
					</Col> */}
               {/* <Col
                  className={styles.mobileMargin}
                  md={4}
                  xs={12}>
                  <div className={styles.label}>Total Net Worth</div>
                  <div>
                      <DropDownButton
                        onClick={() => this.setState({showLiquidDrop : !this.state.showLiquidDrop})}
                        showDropDown={this.state.showLiquidDrop}
                        error={totalnetReq}
                        >
                          <span className={networthTotal !== '' ? styles.countryLabel : styles.accountDropPlaceholder}>
                            {networthTotal ? this.getValueFromoptions(networthTotal,networthTotalOptions)  : 'Select'}</span>
                      </DropDownButton>
                      <OutsideClickHandler onOutsideClick={() => this.setState({showLiquidDrop:false})}>
                      <SignUpDropDown
                        classes={styles.dropdownWidth}
                        data={networthTotalOptions}
                        showDropDown={this.state.showLiquidDrop}
                        onChange={(item) => {
                          this.errorStatus()
                          this.onChangeNameAndValue('networthTotal',item.value)
                          this.setState({showLiquidDrop:false})
                          }}
                        isSearchable={false}
                        placeholder='Country'
                      />
                      </OutsideClickHandler>
                    </div>
                    <div className={styles.errorState}>{totalnetReq}</div>
                  </Col> */}

                  {/* <Col
                  className={styles.mobileMargin}
                  md={4}
                  xs={12}>
                  <div className={styles.label}>Liquid Net Worth</div>
                  <div>
                      <DropDownButton
                        onClick={() => this.setState({showTotalDrop : !this.state.showTotalDrop})}
                        showDropDown={this.state.showTotalDrop}
                        error={liquidnetReq}
                        >
                          <span className={netWorth !== '' ? styles.countryLabel : styles.accountDropPlaceholder}>
                            {netWorth ? this.getValueFromoptions(netWorth,netWorthOptions) : 'Select'}</span>
                      </DropDownButton>
                      <OutsideClickHandler onOutsideClick={() => this.setState({showTotalDrop:false})}>
                      <SignUpDropDown
                        classes={styles.dropdownWidth}
                        data={netWorthOptions}
                        showDropDown={this.state.showTotalDrop}
                        onChange={(item) => {
                          this.errorStatus()
                          this.onChangeNameAndValue('netWorth',item.value)
                          this.setState({showTotalDrop:false})
                          }}
                        isSearchable={false}
                        placeholder='Country'
                      />
                      </OutsideClickHandler>
                    </div>
                    <div className={styles.errorState}>{liquidnetReq}</div>
                  </Col> */}
					{/* <Col md={4} xl={4} xs={12} className={styles.colMarginMobile}>
						<Dropdown
							data={networthTotalOptions}
							name='networthTotal'
							value={networthTotal}
							className={styles.dropdowmn}
							placeholder='Total Net Worth'
              isLabeled={true}
              // onChange={value => this.setState({ networthTotal: value })}
              onChange={value => this.onChangeNameAndValue('networthTotal',value)}
						/>
					</Col> */}
				</Row>
        {/* <Row className={styles.personalInputRow}> */}
          {/* <Col
          className={styles.mobileMargin}
          md={4}
          xs={12}>
              <div className={styles.label}>Investment Objective</div>
              <div>
                  <DropDownButton
                    onClick={() => this.setState({showObjectDrop : !this.state.showObjectDrop})}
                    showDropDown={this.state.showObjectDrop}
                    error={investObjReq}
                    >
                      <span className={investmentObject !== '' ? styles.countryLabel : styles.accountDropPlaceholder}>
                        {investmentObject ? this.getValueFromoptions(investmentObject,investmentObjOptions) : 'Select'}
                        </span>
                  </DropDownButton>
                  <OutsideClickHandler onOutsideClick={() => this.setState({showObjectDrop:false})}>
                  <SignUpDropDown
                    classes={styles.dropdownWidth}
                    data={investmentObjOptions}
                    showDropDown={this.state.showObjectDrop}
                    onChange={(item) => {
                      this.errorStatus()
                      this.onChangeNameAndValue('investmentObject',item.value)
                      this.setState({showObjectDrop:false})
                      }}
                    isSearchable={false}
                    placeholder='Country'
                  />
                  </OutsideClickHandler>
                  <div className={styles.errorState}>{investObjReq}</div>
                </div>
              </Col> */}
						{/* <Dropdown
							data={investmentObjOptions}
							name='investmentObject'
							value={investmentObject}
							className={styles.dropdowmn}
              isLabeled={true}
							placeholder='Investment Objective'
              onChange={value => this.onChangeNameAndValue('investmentObject',value)}

						/> */}
              {/* <Col
                className={styles.mobileMargin}
                md={4}
                xs={12}>
                    <div className={styles.label}>Investment Experience</div>
                    <div>
                        <DropDownButton
                          onClick={() => this.setState({showExpDrop : !this.state.showExpDrop})}
                          showDropDown={this.state.showExpDrop}
                          error={investExpReq}
                          >
                            <span className={investmentExper !== '' ? styles.countryLabel : styles.accountDropPlaceholder}>
                              {investmentExper ?
                                this.getValueFromoptions(investmentExper,investmentExpOptions)
                              : 'Select'}</span>
                        </DropDownButton>
                        <OutsideClickHandler onOutsideClick={() => this.setState({showExpDrop:false})}>
                        <SignUpDropDown
                          classes={styles.dropdownWidth}
                          data={investmentExpOptions}
                          showDropDown={this.state.showExpDrop}
                          onChange={(item) => {
                            this.errorStatus()
                            this.onChangeNameAndValue('investmentExper',item.value)
                            this.setState({showExpDrop:false})
                            }}
                          isSearchable={false}
                          placeholder='Country'
                        />
                        </OutsideClickHandler>
                      </div>
                      <div className={styles.errorState}>{investExpReq}</div>
                    </Col> */}
						{/* <Dropdown
							data={investmentExpOptions}
							name='investmentExper'
							value={investmentExper}
							className={styles.dropdowmn}
							placeholder='Investment Experience'
              isLabeled={true}
              // onChange={value => this.setState({ investmentExper: value })}
              onChange={value => this.onChangeNameAndValue('investmentExper',value)}

						/> */}
            {/* <Col
              className={styles.mobileMargin}
              md={4}
              xs={12}>
                  <div className={styles.label}>Risk Tolerance</div>
                  <div>
                      <DropDownButton
                        onClick={() => this.setState({showRiskDrop : !this.state.showRiskDrop})}
                        showDropDown={this.state.showRiskDrop}
                        error={riskReq}
                        >
                          <span className={riskTolerance !== '' ? styles.countryLabel : styles.accountDropPlaceholder}>
                            {riskTolerance ? this.getValueFromoptions(riskTolerance,riskOptions) : 'Select'}</span>
                      </DropDownButton>
                      <OutsideClickHandler onOutsideClick={() => this.setState({showRiskDrop:false})}>
                      <SignUpDropDown
                        classes={styles.dropdownWidth}
                        data={riskOptions}
                        showDropDown={this.state.showRiskDrop}
                        onChange={(item) => {
                          this.errorStatus()
                          this.onChangeNameAndValue('riskTolerance',item.value)
                          this.setState({showRiskDrop:false})
                          }}
                        isSearchable={false}
                        placeholder='Country'
                      />
                      </OutsideClickHandler>
                    </div>
                    <div className={styles.errorState}>{riskReq}</div>
                  </Col> */}
						{/* <Dropdown
							data={riskOptions}
							name='riskTolerance'
							value={riskTolerance}
							className={styles.dropdowmn}
							placeholder='Risk Tolerance'
              isLabeled={true}
              // onChange={value => this.setState({ riskTolerance: value })}
              onChange={value => this.onChangeNameAndValue('riskTolerance',value)}
						/> */}
				{/* </Row> */}
        {riskTolerance !== '' ?
       <Row className={styles.personalInputRow}>
        <div className={styles.heading}>What is {this.state.riskTolerance.charAt(0).toUpperCase() + this.state.riskTolerance.slice(1).toLowerCase()} risk?</div>
					<div className={styles.desc}>
						{this.getRiskMessage()}
					</div>
        </Row>:''}

			 {/* <Row className={styles.personalInputRow} style={{ display: 'block' }}>
					<div className={styles.heading} style={{fontSize:'14px'}}>Financial Status Details</div>
					<div className={styles.desc} style={{fontSize:'12px'}}>
						This lets us know where your overall financial situation stands
					</div>
				</Row>  */}
				<Row className={styles.buttonMargin}>
        {errorMessage !== '' ? <div className={styles.erorrMessage}>{errorMessage}</div> : ''}
					<Col className={styles.mobileMargin}>
						<SignUpButton
							title='Next Section'
              tabIndex='7'
							// disabled={buttonEmployeeDisable || !disableButton || this.state.loading}
							classes={styles.nextSectionButton}
              loading={this.state.loading}
							onClick={this.onFormSubmit}></SignUpButton>
					</Col>
				</Row>

				{/* Risk Modal */}
				<Modal
					show={showRiskModal}
					// aria-labelledby='contained-modal-title-vcenter'
					centered
					dialogClassName={styles.modalDialog}
					onHide={this.closeRiskModal}>
					<Modal.Body className={styles.modalBody}>
						<Row className={styles.riskTitle}>What is Risk?</Row>
						<Row className={styles.riskDetail}>
							<p>
								Risk refers to the degree of uncertainty and/or potential
								financial loss inherent in an investment decision. In general,
								as investment risks rise, investors seek higher returns to
								compensate themselves for taking such risks.
							</p>
							<p>
								Every saving and investment product has different risks and
								returns. Differences include: how readily investors can get
								their money when they need it, how fast their money will grow,
								and how safe their money will be.
							</p>
							{/* <p className={styles.riskDetail} onClick={this.calculateRisk}>
								Still not sure? Calculate your approximate risk appetite here.
							</p> */}
						</Row>
						{this.state.calculateRisk ? (
							<>
								<Row className={styles.personalInputRow}>
									<Col>
										<Dropdown
											name='employmentStatus'
											className={styles.dropdowmn}
											placeholder='Risk Appetite Question 1'
										/>
									</Col>
								</Row>
								<Row className={styles.personalInputRow}>
									<Col>
										<Dropdown
											name='employmentStatus'
											className={styles.dropdowmn}
											placeholder='Risk Appetite Question 2'
										/>
									</Col>
								</Row>
								<Row className={styles.personalInputRow}>
									<Col>
										<Dropdown
											name='employmentStatus'
											className={styles.dropdowmn}
											placeholder='Risk Appetite Question 3'
										/>
									</Col>
								</Row>
							</>
						) : (
							''
						)}
						<Row
							className={`${styles.riskUnderLine} mt-5`}
							onClick={this.closeRiskModal}>
							Got it, go back to the form.
						</Row>
					</Modal.Body>
				</Modal>
			</>
		)
	}
}

export default FinancialDetail
