import React, {Fragment, useState} from 'react'
import { Row, Col } from 'react-bootstrap'
import styles from './otherCountryAccount.module.css'
import instructionStyles from '../lrsForm/instruction.module.css'
import backImage from '../../public/images/back.png'
import downloadImage from '../../public/images/Download.svg'
import { putBrokerDetail } from '../../api'
import Copy from '../lrsForm/copy'
import TableError from '../lrsForm/tableError'
const OtherCountryAccount = ({
	setModalData,
	data,
	back,
	brokerDetail,
	hashId,
  selectedBank,
	buttonColor,
  buttonTitle,
  fetchBrokerDetails
}) => {

  const [loading,setLoading] = useState(false)
  const [errorMessage,setErrorMessage] = useState('')

	const asynchFetch = async () => {
    setLoading(true)
    try{
		const res = hashId && (await putBrokerDetail(hashId,'others'))
    if(	res && res.code === 200){
	  setModalData(data)
    }else{
      setErrorMessage('Something Went Wrong')
    }
    }catch(error){
      setErrorMessage('Something Went Wrong')
    }
    setLoading(false)
	}

	let info = null
	if(brokerDetail){
		info=[{
				'Beneficiary Name':
					brokerDetail && brokerDetail.beneficiaryName
						? brokerDetail.beneficiaryName
						: '--'
			},
			{
				'Beneficiary Address':
					brokerDetail && brokerDetail.beneficiaryAddress
						? brokerDetail.beneficiaryAddress
						: '--'
			},
			{
				Country:
					brokerDetail && brokerDetail.beneficiaryAddress && brokerDetail.beneficiaryAddressDetails.country ? brokerDetail.beneficiaryAddressDetails.country : '--'
			},
			{
				'Beneficiary Contact number':
					brokerDetail && brokerDetail.contactNumber
						? brokerDetail.contactNumber
						: '--'
			},
			{
				Currency:
					brokerDetail && brokerDetail.currency ? brokerDetail.currency : '--'
			},
			{
				'Beneficiary Bank Name':
					brokerDetail && brokerDetail.bankName
						? brokerDetail.bankName
						: '--'
			},
			{
				'Beneficiary Bank Address:':
					brokerDetail && brokerDetail.bankAddress
						? brokerDetail.bankAddress
						: '--'
			},
			{
				'Beneficiary Account Number:':
					brokerDetail && brokerDetail.bankAccountNumber
						? brokerDetail.bankAccountNumber
						: '--'
			},
			{
				'SWIFT Code of Beneficiary bank':
					brokerDetail && brokerDetail.bankSwiftCode
						? brokerDetail.bankSwiftCode
						: '--'
			},
			{
				'ABA number':
					brokerDetail && brokerDetail.ABANumber
						? brokerDetail.ABANumber
						: '--'
			}
		]
	}
	return (
		<div className={styles.card}>
			<Row
        className={styles.responsiveRow}>
				<Col xs={10} md={12}>
          <div onClick={() => setModalData(back)}>
					<img src={backImage} />
					<span
						className={`d-none d-md-inline-block ${styles.title}`}
            >
						{`Other country account`}
					</span>
          <span
						className={`d-inline-block d-md-none ${styles.title}`}
            >
						{`Other Country`}
					</span>
          </div>
				</Col>
        {/* <Col xs={2} className='d-block d-md-none' style={{whiteSpace:"nowrap",paddingLeft:'0px',paddingTop:"5px"}}>
          <img src={downloadImage}/>
          <span className={styles.pdf}>PDF</span>
        </Col> */}
			</Row>
      <div className={styles.scrollDiv}>
			<Row style={{ marginTop: '32px' }}>
				<Col md={12}>
					<div className={styles.wire}>Wire funds to</div>
				</Col>
				<Col xl={11}>
					<div style={{ overflow:'hidden'}} className={`${instructionStyles.beneficiaryTableBg} ${instructionStyles.marginStepSecondary} ${!info&&instructionStyles.beneficiaryTableError}`}>
						{info ? 
							info.map((trav, i) => {
								return (
									<Fragment>
										{Object.keys(trav)[0] != 'Beneficiary Account Number:'?
											<div  className={instructionStyles.beneficiaryTableRow}>
												<div className={instructionStyles.beneficiaryTableContent}>
													<div className={`${instructionStyles.key} ${instructionStyles.beneficiaryTableKey}`}>										
														{Object.keys(trav)[0]}
													</div>
													<div className={instructionStyles.beneficiaryTableValue}>
														<span>{Object.values(trav)[0]}</span>
														<Copy data={{label:Object.keys(trav)[0], value:Object.values(trav)[0]}}/>
													</div>
												</div>
											</div>
											:<div className={`${instructionStyles.beneficiaryAccNum} ${instructionStyles.beneficiaryTableRow}`}>
												<div className={instructionStyles.beneficiaryTableContent}>
													<div className={`${instructionStyles.key} ${instructionStyles.beneficiaryTableKey}`}>
														Beneficiary Account Number:
													</div>
													<div className={instructionStyles.beneficiaryAccNumValue}>
														<div className={instructionStyles.beneficiaryTableValue}>
															<span>{Object.values(trav)[0]}</span>
															<Copy data={{label:Object.keys(trav)[0], value:Object.values(trav)[0]}}/>
														</div>
														<div className={instructionStyles.beneficiaryAccInfo}>Everyone has an Unique Beneficiary Account Number.</div>										
													</div>
												</div>
											</div>
										}
									</Fragment>
								)
							})
							:<TableError fetchBrokerDetails={fetchBrokerDetails}/>
						}
					</div>
				</Col>
			</Row>
      {errorMessage === '' ? '' : <div className={styles.erorrMessage}>{errorMessage}</div>}
      </div>
			<Row className={styles.fixedDiv}>
				<Col>
					<button
						onClick={() => asynchFetch()}
						type='button'
            disabled={loading}
						style={{ backgroundColor: buttonColor }}
						className={`btn btn-primary btn-sm ${styles.addfunds} themeButton`}>
						<span className={styles.addfundstext}>{buttonTitle}</span>
					</button>
				</Col>
			</Row>
		</div>
	)
}

export default OtherCountryAccount
