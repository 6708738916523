export const riskOptions = [
  { value: 'LOW', label: ' Low Risk' },
  { value: 'MODERATE', label: 'Moderate Risk' },
  // { value: 'SPECULATION', label: 'Speculative Risk' },
  { value: 'HIGH', label: 'High Risk' }
]
export const employmentStatusOptions = [
  { value: 'EMPLOYED', label: 'Employed/Self-Employed' },
  { value: 'RETIRED', label: 'Retired' },
  { value: 'STUDENT', label: 'Student' },
  { value: 'UNEMPLOYED', label: 'Not Employed' },
  { value: 'SELF_EMPLOYED', label: 'Self Employed / Business Owner' }
]
export const employeeTypeOptions = [
  {
    value: 'AGRICULTURE',
    label: 'Agriculture, Forestry, Fishing and Hunting'
  },
  {
    value: 'MINING',
    label: 'Mining, Quarrying, and Oil and Gas Extraction'
  },
  { value: 'UTILITIES', label: 'UTILITIES' },
  { value: 'CONSTRUCTION', label: 'Construction' },
  { value: 'MANUFACTURING', label: 'Manufacturing' },
  { value: 'WHOLESALE', label: 'Wholesale Trade' },
  { value: 'RETAIL', label: 'Retail Trade' },
  { value: 'TRANSPORT', label: 'Transportation and Warehousing' },
  { value: 'INFORMATION', label: 'Information' },
  { value: 'FINANCE', label: 'Finance and Insurance' },
  { value: 'REAL_ESTATE', label: 'Real Estate and Rental and Leasing' },
  {
    value: 'PROFESSIONAL',
    label: 'Professional, Scientific, and Technical Services'
  },
  { value: 'MANAGEMENT', label: 'Management of Companies and Enterprises' },
  { value: 'EDUCATION', label: 'Educational Services' },
  { value: 'HEALTH', label: 'Health Care and Social Assistance' },
  { value: 'ART', label: 'Arts, Entertainment, and Recreation' },
  { value: 'FOOD', label: 'Accommodation and Food Services' },
  { value: 'PUBLIC', label: 'Public Administration' },
  {
    value: 'WASTE',
    label:
      'Administrative and Support and Waste Management and Remediation Services'
  }
]
export const employeePositionsOptions = [
  { value: 'ACCOUNTANT', label: 'Accountant/CPA/Bookkeeper/Controller' },
  { value: 'ACTUARY', label: 'Actuary' },
  { value: 'ADJUSTER', label: 'Adjuster' },
  { value: 'ADMINISTRATOR', label: 'Administrator' },
  { value: 'ADVERTISER', label: 'Advertiser/Marketer/PR Professional' },
  { value: 'AGENT', label: 'Agent' },
  { value: 'ATC', label: 'Air Traffic Controller' },
  { value: 'AMBASSADOR', label: 'Ambassador/Consulate Professional' },
  { value: 'ANALYST', label: 'Analyst' },
  { value: 'APPRAISER', label: 'Appraiser' },
  { value: 'ARCHITECT', label: ' Architect' },
  { value: 'ARTIST', label: 'Artist/Performer/Actor/Dancer' },
  { value: 'ASSISTANT', label: 'Assistant' },
  { value: 'ATHLETE', label: 'Athlete' },
  { value: 'ATTENDANT', label: 'Attendant' },
  { value: 'ATTORNEY', label: 'Attorney/Judge/Legal Professional' },
  { value: 'AUCTIONEER', label: 'Auctioneer' },
  { value: 'AUDITOR', label: 'Auditor' },
  { value: 'BARBER', label: 'Barber/Beautician/Hairstylist' },
  { value: 'BROKER', label: 'Broker' },
  {
    value: 'BUSINESS_EXEC',
    label: 'Business Executive (VP, Director, etc.)'
  },
  { value: 'BUSINESS_OWNER', label: 'Business Owner' },
  { value: 'CAREGIVER', label: 'Caregiver' },
  { value: 'CARPENTER', label: 'Carpenter/Construction Worker' },
  { value: 'CASHIER', label: 'Cashier' },
  { value: 'CHEF', label: 'Chef/Cook' },
  { value: 'CHIROPRACTOR', label: 'Chiropractor' },
  { value: 'CIVIL', label: 'Civil Servant' },
  { value: 'CLERGY', label: 'Clergy' },
  { value: 'CLERK', label: 'Clerk' },
  { value: 'COMPLIANCE', label: 'Compliance/Regulatory Professional' },
  { value: 'CONSULTANT', label: 'Consultant' },
  { value: 'CONTRACTOR', label: 'Contractor' },
  { value: 'COUNSELOR', label: 'Counselor/Therapist' },
  {
    value: 'CUSTOMER_SERVICE',
    label: 'Customer Service Representative'
  },
  { value: 'DEALER', label: 'Dealer' },
  { value: 'DEVELOPER', label: 'Developer' },
  { value: 'DISTRIBUTOR', label: 'Distributor' },
  { value: 'DOCTOR', label: 'Doctor/Dentist/Veterinarian/Surgeon' },
  { value: 'DRIVER', label: 'Driver' },
  { value: 'ENGINEER', label: 'Engineer' },
  { value: 'EXAMINER', label: 'Examiner' },
  { value: 'EXTERMINATOR', label: 'Exterminator' },
  { value: 'FACTORY', label: 'Factory/Warehouse Worker' },
  { value: 'FARMER', label: 'Farmer/Rancher' },
  { value: 'FINANCIAL', label: 'Financial Planner' },
  { value: 'FISHERMAN', label: 'Fisherman' },
  { value: 'FLIGHT', label: 'Flight Attendant' },
  { value: 'HR', label: 'Human Resources Professional' },
  { value: 'IMPEX', label: 'Importer/Exporter' },
  { value: 'INSPECTOR', label: 'Inspector/Investigator' },
  { value: 'INTERN', label: 'INTERN' },
  { value: 'INVESTMENT', label: 'Investment Advisor/Investment Manager' },
  { value: 'INVESTOR', label: 'Investor' },
  { value: 'IT', label: 'IT Professional/IT Associate' },
  { value: 'JANITOR', label: 'Janitor' },
  { value: 'JEWELER', label: 'Jeweler' },
  { value: 'LABORER', label: 'Laborer' },
  { value: 'LANDSCAPER', label: 'Landscaper' },
  { value: 'LENDING', label: 'Lending Professional' },
  { value: 'MANAGER', label: 'Manager' },
  { value: 'MECHANIC', label: 'Mechanic' },
  { value: 'MILITARY', label: 'Military, Officer or Associated' },
  { value: 'MORTICIAN', label: ' Mortician/Funeral Director' },
  { value: 'NURSE', label: 'Nurse' },
  { value: 'NUTRITIONIST', label: 'Nutritionist' },
  { value: 'OFFICE', label: 'Office Associate' },
  { value: 'PHARMACIST', label: 'Pharmacist' },
  { value: 'PHYSICAL', label: 'Physical Therapist' },
  { value: 'PILOT', label: 'Pilot' },
  {
    value: 'POLICE',
    label: 'Police Officer/Firefighter/Law Enforcement Professional'
  },
  { value: 'POLITICIAN', label: 'Politician' },
  { value: 'PM', label: 'Project Manager' },
  { value: 'REP', label: 'Registered Rep' },
  { value: 'RESEARCHER', label: 'Researcher' },
  { value: 'SAILOR', label: 'Sailor/Seaman' },
  { value: 'SALES', label: 'Salesperson' },
  { value: 'SCIENTIST', label: 'Scientist' },
  { value: 'SEAMSTRESS', label: 'Seamstress/Tailor' },
  { value: 'SECURITY', label: 'Security Guard' },
  { value: 'SOCIAL', label: 'Social Worker' },
  { value: 'TEACHER', label: 'Teacher/Professor' },
  { value: 'TECHNICIAN', label: 'Technician' },
  { value: 'TELLER', label: 'Teller' },
  { value: 'TRADESPERSON', label: 'Tradesperson/Craftsperson' },
  { value: 'TRAINER', label: 'Trainer/Instructor' },
  { value: 'TRANSPORTER', label: 'Transporter' },
  { value: 'UNDERWRITER', label: 'Underwriter' },
  { value: 'WRITER', label: 'Writer/Journalist/Editor' }
]
export const investmentObjOptions = [
  {
    label: ' Long–term buy & hold investing',
    value: 'LONG_TERM'
  },
  {
    label: 'Trading infrequently when I see an opportunity',
    value: 'INFREQUENT'
  },
  { label: 'Frequent trader, depending on the market', value: 'FREQUENT' },
  { label: 'Active trader, daily trader', value: 'ACTIVE_DAILY' },
  { label: 'New to investing', value: 'NEW' }
]
export const investmentExpOptions = [
  { label: 'None', value: 'NONE' },
  { label: '1–2 years', value: 'YRS_1_2' },
  { label: '3–5 years', value: 'YRS_3_5' },
  { label: '5–10 years', value: 'YRS_5_10' },
  { label: '10+ years', value: 'YRS_10_' }
]
export const annualIncomeOptions = [
  { label: '$0 - $24,999', value: '$24,999', alt: 'Upto Rs. 17 lacs' },
  {
    label: '$25,000 - $99,999',
    value: '$99,999',
    alt: 'Rs. 17 lacs - Rs. 70 lacs'
  },
  {
    label: '$100,000 - $199,999',
    value: '$199,999',
    alt: 'Rs. 70 lacs - Rs. 1.4 cr'
  },
  { label: '$200,000+', value: '$200,000', alt: 'Above Rs. 1.4 cr' }
]
export const netWorthOptions = [
  { label: '$0 - $4,999', value: '$4,999', alt: 'Upto Rs.3.5 lacs' },
  {
    label: '$5,000 - $99,999',
    value: '$99,999',
    alt: 'Rs. 3.5 lacs - Rs. 70 lacs'
  },
  {
    label: '$100,000 - $199,999',
    value: '$199,999',
    alt: 'Rs. 70 lacs - Rs. 1.4 cr'
  },
  { label: '$200,000+', value: '$200,000', alt: 'Above Rs. 1.4 cr' }
]
export const networthTotalOptions = [
  { label: '$0 - $24,999', value: '$24,999', alt: 'Upto Rs. 17 lacs' },
  {
    label: '$25,000 - $99,999',
    value: '$99,999',
    alt: 'Rs. 17 lacs - Rs. 70 lacs'
  },
  {
    label: '$100,000 - $199,999',
    value: '$199,999',
    alt: 'Rs. 70 lacs - Rs. 1.4 cr'
  },
  { label: '$200,000+', value: '$200,000', alt: 'Above Rs. 1.4 cr' }
]
