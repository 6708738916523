import React, { useState, useEffect, useRef } from 'react'
import { Table, Col, Row, OverlayTrigger,Tooltip } from 'react-bootstrap'
import {decimalAdjust, addCommasinPrice} from '../../utils'
import styles from './StockWeightsModal.module.css'
import StocksAndWeightsSkeleton from './Skeleton'
import { isEqual } from 'lodash'

const addField = (data) => {
  let tamperedData;
  if(data && data.length > 0) {
    tamperedData = data.map((d) => {
      d.isImgLoaded = true
      return d;
    })
  }
  return tamperedData;
}

const StocksWeightsTable = ({ rowHeaders = [], data = [], isLoading ,activePlan,exclusive}) => {
  const [weightsData, setWeightsData] = useState([]);

  const prevDataRef = useRef();
  useEffect(() => {
    prevDataRef.current = data;
  });
  const prevData = prevDataRef.current;

  useEffect(() => {
    if(!isEqual(prevData, data)){
      if(data && data.length > 0){
        setWeightsData(data)
      }
    }
  },[data, prevData, weightsData])

  const updateWightsData = (index) => {
    const newArr = [...weightsData]
    newArr[index]["isImgLoaded"] = false
    setWeightsData(newArr)
  }


	return (
    <>
    {
      isLoading ?  <StocksAndWeightsSkeleton /> : data.length > 0 ?
		<Table borderless className={styles.dataTable}>
			<thead>
				<tr>
					<th className={`${styles.tableborder} ${styles.mobileSpacing}`} style={{ width: '1px' }}></th>
					{rowHeaders.map((item, index) => {
						return (
							<th
								key={index}
								className={`${styles.tableHead} ${styles.mobileTable}
                ${item === 'Stock' || item === 'Stocks' || item === 'ETF' ? styles.stocksEtfRow : item === 'price' ? styles.priceRow : ''}`}
								style={
									item === 'Stock' || item === 'Stocks'
										? { textAlign: 'start' }
										: item === 'ETF'
										? { textAlign: 'start'}
										: { textAlign: 'end' }
								}
                >
								{item}
							</th>
						)
					})}
				</tr>
			</thead>
      {
					weightsData.length ?
          (
          <tbody>
          {
          weightsData.map((item, index) => {
						return (
							<tr key={index}>
								<td
									className={`${styles.mobileSpacing}`}
									style={{ textAlign: 'end', paddingRight: '0px' }}>
									{item.image === 'NULL' ? (
										<h3 className={`text-center`}>
											{item.name.substring(0, 1)}
										</h3>
									) : (
										<Col className={styles.mobileSpacing}>
                      		{item.isImgLoaded ? (
                            <img
                              onError={() => updateWightsData(index)}
                              src={item.image}
                              className={styles.imageCompany}
                              alt = ""
                            />
                          ) : (
                            <h3 style={{ paddingRight: '10px' }}>
                              {item.name && item.name.substring(0, 1)}
                            </h3>
                          )}
										</Col>
									)}
								</td>
								<td>
                  <div className={styles.name}>{item.name}</div>
                  <OverlayTrigger
                    placement='bottom'
                    trigger={['hover', 'focus','click']}
                    overlay={
                      <Tooltip>
                      {item.creator}
                      </Tooltip>
                    }>
                    <div className={styles.creator}>{item.creator}</div>
								  </OverlayTrigger>
								</td>
								<td>
									<div
										className={styles.price}>
										${item.price ? isNaN(item.price) ? '--' : addCommasinPrice(decimalAdjust('floor',item.price,-2)) : '--'}
									</div>
									<div
										className={styles.pricePercentage}
										style={{
											color:
												parseFloat(item.pricePercentage) >= 0
													? '#0dc014'
													: '#e51446'
										}}>
                    {
                      item.pricePercentage ? isNaN(item.pricePercentage) ? '--' :
                      String(item.pricePercentage).slice(0, 1) === '-'
											? addCommasinPrice(decimalAdjust('floor',item.pricePercentage,-2)) + '%'
                      : '+' + addCommasinPrice(decimalAdjust('floor',item.pricePercentage,-2)) + '%' : '--'
                    }
									</div>
								</td>
								{item.hasOwnProperty('shares') ? (
									<td className={styles.tableData}>
										{decimalAdjust('floor',item.shares,-8)}
									</td>
								) : (
									''
								)}
								{item.hasOwnProperty('allocationPer') ? (
									<td className={styles.tableData}>{item.allocationPer}</td>
								) : (
									''
								)}
								<td className={styles.tableData}>
									${addCommasinPrice(decimalAdjust('floor',item.amount,-2))}
								</td>
							</tr>
						)
					})
          }
          </tbody>
          )
          : ""
        }
		</Table>

    : ""
    }
    </>

	)
}

export default StocksWeightsTable



