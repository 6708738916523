import React from 'react'
import ReactHighcharts from 'react-highcharts'
import PropTypes from 'prop-types'
import Highcharts from 'highcharts'
import styles from './cardChart.module.css'

const cardChart = ({ data, color, height, width, lineColor }) => {
	const custoumOption = (data, color) => {
		return {
			chart: {
				type: 'area',
				margin: [2, 0, 2, 0],
				style: {
					overflow: 'visible',
					width: width,
					height: height
				}
			},
			legend: {
				enabled: false
			},
			title: {
				text: undefined
			},
			tooltip: {
				enabled: false
			},
			credits: {
				enabled: false
			},
			xAxis: {
				visible: false
			},
			yAxis: {
				visible: false
			},
			plotOptions: {
				series: {
					animation: false,
					lineWidth: 1,
					lineColor: lineColor,
					shadow: false,
					states: {
						hover: {
							enabled: false
						}
					},
					marker: {
						enabled: false,
						states: {
							hover: {
								enabled: false
							}
						}
					},
					fillColor: {
						linearGradient: [0, 0, 0, 32],
						stops: [
							[0, color],
							[1, Highcharts.color(color).setOpacity(0).get('rgba')]
						]
					}
				}
			},
			series: [
				{
					data: data
				}
			]
		}
	}

	return (
		<div className={styles.chart}>
			<ReactHighcharts config={custoumOption(data, color)}></ReactHighcharts>
		</div>
	)
}

// cardChart.propTypes = {
// 	data: PropTypes.array.isRequired,
// 	color: PropTypes.string.isRequired
// }

export default cardChart
