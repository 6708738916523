import React, { useContext, useEffect, useState } from "react"
import style from "./transfer.module.css"
import ArrowRightIcon from "../../../public/images/arrowRight.svg"
import OnlineTransfer from "./Online/OnlineTransfer"
import { AddFundContext } from "../AddFundContext"
import OfflineTransfer from "./Offline/OfflineTransfer"
import DirectTransfer from "./Direct/DirectTransfer"
import EasyFund from './EasyFund'

const Transfer = ()=>{
    const {page, setPage, transferOptions, PAGE} = useContext(AddFundContext)
    return (<>
        {page===PAGE.TRANSFER && <>
            <div className={style.subHeader}>Select Transfer Method</div>
            {transferOptions.direct && <button className={style.transferOption} onClick={()=>setPage(PAGE.DIRECT)}>
                <div className="d-flex flex-column align-items-start" style={{gap:'4px'}}>
                    <span className={style.primary}>Direct Transfer <span className={style.fastTag}>Fastest</span></span>
                    <span className={style.secondary}>2-3 clicks is all it takes</span>
                </div>
                <img src={ArrowRightIcon} />
            </button>}
            {transferOptions.online && <button className={style.transferOption} onClick={()=>setPage(PAGE.ONLINE)}>
                <div className="d-flex flex-column align-items-start" style={{gap:'4px'}}>
                    <span className={style.primary}>Online Independent Wire</span>
                    <span className={style.secondary}>Do it through netbanking</span>
                </div>
                <img src={ArrowRightIcon} />
            </button>}
            {(transferOptions.offline || transferOptions.other) && <button className={style.transferOption} onClick={()=>setPage(PAGE.OFFLINE)}>
                <div className="d-flex flex-column align-items-start" style={{gap:'4px'}}>
                    <span className={style.primary}>Offline Independent Wire</span>
                    <span className={style.secondary}>Submit LRS form in nearest branch</span>
                </div>
                <img src={ArrowRightIcon} />
            </button>}
        </>}
        {page===PAGE.DIRECT && <DirectTransfer />}
        {page===PAGE.ONLINE && <OnlineTransfer />}
        {page===PAGE.OFFLINE && <OfflineTransfer />}
        {page===PAGE.EASYFUND && <EasyFund/>}
    </>)
}

export default Transfer
