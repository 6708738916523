import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import styles from './common.module.css'
import { infoError } from '../Online/assets'
const DownloadInstruction= ({fetchPDF})=>{
    const [counter, setCounter] = useState(0)
    const [fetching, setFetching] = useState(false)
    const onDownload= async()=>{
        if(!fetching && counter <3){
            setFetching(true)
            await fetchPDF()  
            setFetching(false)
            setCounter(counter+1)
        }        
    }    
    return (
        <div style={{ height:'40px',marginTop:'20px' }} className='d-flex'>
            {!fetching ? 
                <img src={infoError} style={{height:'fit-content'}}/>
                :<Spinner animation="border" className={styles.spinnerColor} style={{width:'20px',height:'20px', margin:'2px'}}/>
            }
            &nbsp;
            {counter<3?
                <div className={styles.downloadError}>
                    <div style={{color:'#DC6803'}}>Unable To Fetch</div>
                    <div style={{paddingLeft:'6px'}} className={styles.link} onClick={()=>onDownload()}>Try Again</div>
                </div>                
                :<div className={styles.downloadError}>
                    <div style={{color:'#DC6803'}}>Something Went Wrong</div>
                    <div style={{paddingLeft:'6px'}} className={styles.link} onClick={()=>window.fcWidget.open()}>Get Help</div>
                </div>
            }            
        </div>
    )
}
export default DownloadInstruction;