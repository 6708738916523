import React,{useState,useEffect} from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Col, Container } from 'react-bootstrap'
import styles from './newsCard.module.css'
import './modal.css'
import ModalCLose from '../../public/images/modalClose.png'


const NewsCard = ({ image, headline, description, targetModal, date, link, index }) => {
    const [width,setWidth] = useState(1440)
    const [head,setHead] = useState()
    const [desc,setDesc] = useState()
    const [hover,setHover] = useState(false)

    const widthIsChanged=()=>{
      if(width >= 992 && width <= 1199){
        if (description <= 80) {
          setDesc(description)
        } else if(description === null || description === undefined){
          setDesc('--')
        }else {
          setDesc(description.substring(0, 70)+'...')
        }
        if (headline <= 10) {
          setHead(headline)
        } else {
          setHead(headline.substring(0, 40)+'...')
        }
      } else if(  width <= 991){
        if (description <= 80) {
          setDesc(description)
        }else if(description === null || description === undefined){
          setDesc('--')
        } else {
          setDesc(description.substring(0, 40)+'...')
        }
        if (headline <= 10) {
          setHead(headline)
        } else {
          setHead(headline.substring(0, 35)+'...')
        }
      }else{
        if (description <= 80) {
          setDesc(description)
        }else if(description === null || description === undefined){
          setDesc('--')
        } else {
          setDesc(description.substring(0, 80)+'...')
        }

        if (headline <= 10) {
          setHead(headline)
        } else {
          setHead(headline.substring(0, 60)+'...')
        }
      }
    }
    useEffect(()=>{

      const { innerWidth: width } = window
      // console.log(width)
      setWidth(width,widthIsChanged())
      // if (width <= 375) {
      //   setHead(headline.substring(0, 30)+'...', setDesc(description.substring(0, 40)+'...'))
      // }

    },[width])

    return (
			<>
      {/* desktop code */}
      <a href={link} style={{textDecoration:'none' }} target="_blank" rel="noopener noreferrer">
      <div
      className={`d-none d-lg-block ${styles.newsdiv}`}
        style={{
          background:hover?'#F7F7F8':'none',
          borderBottom: index != 2 && '1px solid #EFEFF1'
        }}
        onMouseEnter={()=>setHover(true)}
        onMouseLeave={()=>setHover(false)}
      >
        <Row>
          <Col md={8}>
          <div className={styles.title}>{head}</div>
          <div className={styles.text}>{desc}</div>
          </Col>
          <Col md={4} style={{paddingLeft:'0px',textAlign:'end'}}>
          <div className={styles.imageResponsive} style={{backgroundImage:`url('${image}')`,backgroundRepeat:'no-repeat',backgroundSize:'100px',backgroundPosition:'center'}}>
          </div>
          </Col>
        </Row>
      </div>
      </a>

      {/* mobile code */}
      <a href={link} style={{textDecoration:'none' }} target="_blank" rel="noopener noreferrer">
      <div className={`d-flex d-lg-none ${styles.newsdiv}`}>
        <Row>
          <Col xs='auto' style={{paddingLeft:'0px',paddingRight:"0px",marginLeft:'15px'}}>
          <div className={styles.imageResponsive} style={{backgroundImage:`url('${image}')`,backgroundRepeat:'no-repeat',backgroundSize:'50px',backgroundPosition:'center'}}></div>
          </Col>
          <Col xs={8}>
            <div className={styles.title}>{head}</div>
            <div className={styles.text}>{desc && desc.length > 0 ? desc : ""}</div>
          </Col>
        </Row>
      </div>
      </a>
			</>
		)
	}

NewsCard.propTypes = {
	headline: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	date: PropTypes.string.isRequired,
	targetModal: PropTypes.string.isRequired
}

export default NewsCard

				{/* <Card className={styles.cardBorder}>
					<Row>
						<Col
							xs={4}
							md={12}
							style={{ textAlign: 'center' }}
							className={styles.mobilePadding}>
							<div style={{ height: '176px' }}>
								<a href={link}>
									<img src={image} className={styles.imageNews}></img>
								</a>
							</div>
						</Col>
						<Col xs={7} md={12} className={styles.mobileText}>
							<Card.Body className={styles.bodyPad}>
								<Card.Title className={styles.title}><span dangerouslySetInnerHTML={{ __html:head }}></span></Card.Title>
								<Card.Text className={styles.text}><span dangerouslySetInnerHTML={{ __html:desc }}></span></Card.Text>
							</Card.Body>
						</Col>
					</Row>
				</Card> */}
