import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import WatchListData from '../WatchListData/watchListData'
import Pagination from '../Pagination/pagination'
import styles from './watchList.module.css'
import { Button } from 'bootstrap'
import BlackPlus from '../../public/images/BlackPlus.svg'
import ArrowBack from '../../public/images/BackArrow.svg'
import PlusImage from '../../public/images/PlusImage.svg'
import noImageWatchlist from '../../public/images/NoWatchlist.svg'
import { SearchBar } from '../index'
import $ from 'jquery'
import AddWatchlistModal from './addWatchListModal'
import { Link } from 'react-router-dom'
import { propTypes } from 'react-bootstrap/esm/Image'
import { getLivePrice } from '../../api'
import { keyBy, filter, find, findIndex, isEqual, map, keys } from 'lodash'
import { isMarketOpen, calPriceChange, calPriceChange_New } from '../../utils'
import { useDispatch, useSelector } from 'react-redux'
import { getMarketDetails } from '../../store/actions'
import { WATCHLIST_LIVE_PRICE_INTERVAL } from '../../constants'

const Watchlist = ({
	active,
	option,
	closeSideBar,
	watchlist,
	collection,
	data,
	BackArrow,
	setWatchlistToggle,
  removeTicker,
  callWatchlist,
  setInwatchList,
  pageType
}) => {
	const [currentPage, setCurrentPage] = useState(1)
  const [AddWatchList, setAddWatchList] = useState(false)
  const [live, setLive] = useState([])
  const [filteredStocks, setFilteredStock] = useState([]);
  const state = useSelector(state => state)
  const dispatchAction = useDispatch()

  const {
    marketDetails: { marketDetails: { currentMarketStatus = {} } = {} } = {}
  } = state

  Object.keys(state.marketDetails).length === 0 &&
    dispatchAction(getMarketDetails())

	const [postsPerPage] = useState(7)
	const indexOfLastPost = currentPage * postsPerPage
	const indexOfFirstPost = indexOfLastPost - postsPerPage

  /**
   * calling live price api for stocks
   * @param  data
   */
  const handleLiveApi = async data => {
		data = data.map(trav => trav.code).join(',')
		let response = await getLivePrice(data)
		setLive(keyBy(response, 'symbol'))
	}

  useEffect(() => {
    if(data && data.length > 0) {
      const filtered = filter(data, ({stockType}) => stockType !== 'stack')
      setFilteredStock(filtered)
      handleLiveApi(filtered)
    }
  }, [data])

  if (
    filteredStocks &&
    filteredStocks.length > 0 &&
    live && Object.keys(live).length > 0 &&
    isEqual(map(filteredStocks, 'code'), keys(live))
    ) {
    filteredStocks.map((trav) => {
        const i = findIndex(data, ({code}) =>  code === trav.code)
        const {lastTrade, priorClose} = live[trav.code];
        let { changePer } = calPriceChange_New({
          currPrice: lastTrade,
          close: priorClose,
          });
          data[i] = {
            ...data[i],
            price: lastTrade,
            percentChange: changePer
          }
    })
  }

  const currentPosts = data && data.slice(indexOfFirstPost, indexOfLastPost)

	const paginate = pageNumber => setCurrentPage(pageNumber)
	const imageElements =
		currentPosts &&
		currentPosts.map(img => <WatchListData key={img.id} img={img} removeTicker={removeTicker} setInwatchList={setInwatchList} pageType={pageType} />)

  const mobileElements = data && data.map(img => <WatchListData key={img.id} img={img} removeTicker={removeTicker}  />)
	// const clickCheck = () => {
	//   console.log('clicked')
	//   setAddWatchList(false)
	// }

  // useEffect(() => {
	// 	$(window).scroll(function () {
	// 		// console.log(window.pageYOffset || document.documentElement.scrollTop)
	// 		let scrollPos = parseInt(
	// 			window.pageYOffset || document.documentElement.scrollTop
	// 		)
	// 		let scrollStop = parseInt($('#scrollStop').position().top) - 650
	// 		if (scrollPos > scrollStop) {
	// 			$('.staticWatchList').css({ position: 'absolute', top: scrollStop })
	// 		} else {
	// 			$('.staticWatchList').css({ position: 'fixed', top: 0 })
	// 		}
	// 	})
	// })

  const BacktoWatchList = () => {
    setAddWatchList(false)
    callWatchlist()
  }

  /**
   * Current Value for the stacks | livePrice for nonStacks
   * running every 5 seconds for now => need to change to every 5 seconds.
   */
  useEffect(() => {
    const setLivePrice = async () => {
      if (filteredStocks && filteredStocks.length > 0) {
        await handleLiveApi(filteredStocks)
      }
    }

    const priceId = setInterval(() => {
      if(isMarketOpen(currentMarketStatus)){
        setLivePrice();
      }
    }, WATCHLIST_LIVE_PRICE_INTERVAL);

    return () => {
      clearInterval(priceId);
    }
  }, [currentMarketStatus, filteredStocks])

	return (
		<>
			<div className={`${styles.desktopWatchlist}`}>
				<Row>
					<div className={active ? styles.sidebar : styles.noSideBar}>
						<button
							className={active ? styles.closeButton : styles.hideCloseButton}
							onClick={closeSideBar}>
							<i className='fa fa-angle-right' aria-hidden='true'></i>
						</button>
						<ul className='list-unstyled components'>
							<Row
								className={`pl-2 pt-2 pr-1 ml-0 pb-0 ${styles.sideBarHeader}`}>
								<Col md={4} sm={4} xs={4}>
									<p
										className={
											option
												? `pt-2 ${styles.head}`
												: `pt-2 ${styles.unclicked}`
										}
										onClick={watchlist}>
										Watchlist
									</p>
								</Col>
								<Col md={4} sm={4} xs={4} className='pl-0'>
									{/* <p
										className={
											option
												? `pt-2 ${styles.unclicked}`
												: `pt-2 ${styles.head}`
										}
										onClick={collection}>
										Collection
									</p> */}
								</Col>
								<Col md={4} sm={4} xs={4} style={{ paddingRight: '6%' }}>
                <Link to='/addWatchList' className={styles.noLinkStyle}>
									<p className={`pt-2 pr-2 ${styles.newhead}`} >
                  <img
                    className={styles.PlusImage}
                    src={PlusImage}
                  />
                  Add
									</p>
                </Link>
								</Col>
							</Row>
						</ul>
						<div>
							{option ? (
								<div>
									<div>{imageElements}</div>
									<div
										className='d-flex justify-content-center'
										style={{
											position: 'absolute',
											bottom: '150px',
											height: '35px',
											right: '0px',
											left: '0px',
											// paddingRight: '41px'
										}}>
										<Pagination
											postsPerPage={postsPerPage}
											totalPosts={data && data.length}
											paginate={paginate}
											currentPage={currentPage}
										/>
									</div>
								</div>
							) : (
								''
							)}
						</div>
					</div>
				</Row>
			</div>

      {/* mobile watchlist */}
			<div className={styles.mobileWatchList}>
				{AddWatchList ? (
					// add watchlist component
					<>
						<div className={styles.backarrowRow}>
							<span onClick={() => BacktoWatchList()}>
								<img src={ArrowBack} />
							</span>
							<p className={styles.WatchlistHeading}>Add to watchlist</p>
						</div>
						<div className={styles.addFundsSearchBar}>
              <AddWatchlistModal />
							{/* <SearchBar /> */}
						</div>
					</>
				) : (
					// watchlist component
					<>
						<div className={styles.backarrowRow}>
							<img
								className={styles.backArrowImage}
								src={BackArrow}
								onClick={() => setWatchlistToggle(false)}
							/>
							<p className={styles.WatchlistHeading}>WatchList</p>
						</div>
						<div className={styles.mobileWatchListBody} style={{ overflowY: 'scroll' }}>
							{option ? (
              mobileElements && mobileElements.length > 0 ?
								<div>
									<div>{mobileElements}</div>
								</div> :
                <div className='text-center'>
                  <img src={noImageWatchlist}/>
                  <p className={styles.addWatchlistTitleText}>Add Stocks/ETFs/Stacks <br/> to your watchlist</p>
                  <p className={styles.addWatchlistSubTitleText}>Keep a close eye on their<br/> movement.</p>
                  <button
                    className={styles.AddButtonMobile}
                    style={{top:"0px"}}
                    onClick={() => setAddWatchList(true)}>
                    <img className={styles.AddPlus} src={BlackPlus} />
                    Add
                  </button>
                </div>
							) : (
								""
							)}
						</div>
            {mobileElements && mobileElements.length > 0 ?
						<div className={styles.mobileAddButton}>
							<button
								className={styles.AddButtonMobile}
								onClick={() => setAddWatchList(true)}>
								<img className={styles.AddPlus} src={BlackPlus} />
								Add
							</button>
						</div> : ""}
					</>
				)}
			</div>
		</>
	)
}

export default Watchlist
