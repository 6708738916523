import React,{useState,useContext,useEffect} from 'react'
import { useHistory } from "react-router"
import {Card,Row,Col} from 'react-bootstrap'
import { init } from 'onfido-sdk-ui'
import { createApplicant, getSdkToken,identityDocIndian,logout} from '../../../api'
import { ThemeContext } from '../../../Context/ThemeContext'
import DocumentSkeleton from '../../../skeletons/DocumentSkeleton'
import NewHeader from '../../NewSignupComponents/NavBar/NewHeader'
import styles from './IndDocument.module.css'

const KycScreen = () => {
  const [showManual,setShowManual] = useState('flex')
  const { buttonColor } = useContext(ThemeContext)
  const userData = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : ''
  const [loading, setLoading] = useState(false)
  const [email] = useState(userData.emailAddress1 ? userData.emailAddress1 : '')
  const [countryOfResidence] = useState(userData.country ? userData.country : 'IND')
  const [phoneNumber] = useState(userData.phoneHome ? userData.phoneHome : '')
  const history = useHistory()

  useEffect(() => {
   onAdhar()
  },[])



  const customUI = {
    colorBackgroundButtonPrimary: buttonColor ? buttonColor : '#0061D3',
    colorBackgroundButtonPrimaryHover:buttonColor ? buttonColor : '#0061D3',
    colorBorderButtonSecondary:buttonColor ? buttonColor : '#0061D3',
    colorBackgroundButtonSecondary:'#FFFFFF',
    colorBackgroundButtonSecondaryHover:'#FFFFFF',
    borderRadiusButton:'8px',
    colorBackgroundLinkHover:'transparent',
    colorContentLinkTextHover:'#3C404A',
    fontSizeTitle:'28px',
    fontSizeSubtitle:'16px',
    fontWeightTitle:'500',
    fontWeightSubtitle:'400',
    fontFamilyTitle: 'geomanistmedium',
    fontFamilySubtitle: 'geomanistregular',
    fontFamilyBody: 'geomanistregular',
    borderRadiusSurfaceModal:'16px',
    colorBorderSurfaceModal:'#EFEFF1',
    colorBackgroundSurfaceModal:'#FFFFFF'
  }
  const applicantsReqData = {
    first_name: email,
    last_name: email,
    email: email,
  }

  const onAdhar = async () => {
    setShowManual('none',setLoading(true))
    try {
      const applicantsData = await createApplicant({...applicantsReqData,source:'INVEST_ACCOUNT',proofType:'ID'})
      const { id } = applicantsData.data
      setLoading(false)
      const SdkTokenData = await getSdkToken(id)
      sessionStorage.setItem('oaid', id)
      const onfidoStepsConfig = [
        {
          "type": "document",
          "options": {
            "documentTypes": {
              "national_identity_card": {
                "country": countryOfResidence
              },
            }
          }
        },]
      // console.log(JSON.stringify(onfidoStepsConfig))
      let onfidoObj = init({
        token: SdkTokenData.data.token,
        containerId: 'document-placeholder',
        onError:function(error){
          console.log('id error', JSON.stringify(error))
        },
        onUserExit:function(userExitCode){
          console.log('id Exit', JSON.stringify(userExitCode))
        },
        onComplete: async function (data) {
          // console.log('everything is complete')
          // console.log(data);
          onfidoObj.tearDown()
          let document = []
          Object.keys(data).filter(d => {
            if (d === 'document_front' || d === 'document_back') {
              document.push({
                id: data[d].id,
                type: 'national_identity_card',
                side: data[d].side,
                proofType: 'ID',
                proofName: 'Aadhaar Card'
              })
            }
          })
          onFace(document)
        },
        language:{
          phrases:{
            doc_submit: {
              "button_link_upload": "or upload photo (no scans or photocopies)",
              "button_primary": "Continue on phone",
              "subtitle": "Take a photo with your phone",
              "title_id_back": `Upload the back of your Aadhaar Card`,
              "title_id_front": `Upload the front of your Aadhaar Card`,
           }
          }
        },
        steps: onfidoStepsConfig,
        customUI,
        userDetails: {
          smsNumber: phoneNumber
        }
      })
    }
    catch (e) {
      console.log(e)
    }
  }


  const onFace = async (document) => {
    setLoading(true)
    try {
      const SdkTokenData = await getSdkToken(sessionStorage.getItem('oaid'))
      let onfidoStepsConfig = ['face', 'complete']
      setLoading(false)
      let onfidoObj = init({
        token: SdkTokenData.data.token,
        containerId: 'document-placeholder',
        onError:function(error){
          console.log('face error', JSON.stringify(error))
        },
        onUserExit:function(userExitCode){
          console.log('face Exit', JSON.stringify(userExitCode))
        },
        onComplete: async function (data) {
          onfidoObj.tearDown()
          await identityDocIndian(sessionStorage.getItem('oaid'), document)
          history.push('/home')
        },
        steps: onfidoStepsConfig,
        customUI,
        userDetails: {
          smsNumber: phoneNumber
        }
      })
    }
    catch (e) {
      console.log(e)
      // setServerError('Something Went wrong. Please Try Again!')
    }
  }

  const onClickLogout = () => {
    var token = JSON.parse(sessionStorage.getItem('token'))
    //webengage logout
    // eslint-disable-next-line no-undef
    webengage.user.logout()
    // delete token.refreshToken
    var body = {
      accessToken: token && token.accessToken
    }
    // var accessToken = JSON.stringify(token)
    logout(JSON.stringify(body)).then(response => {
      sessionStorage.clear()
      localStorage.clear()
        history.push('/')
    })
  }

   return(
     <>
      <NewHeader
    message=''
    linkName='Log Out'
    onClickLink={onClickLogout}
     />
     {
       loading ? <DocumentSkeleton/> :
       <div style={{marginTop:'20px'}}>
        <div id="document-placeholder" className={styles.documentPlaceholder} style={{ minHeight: showManual === 'none' ? '500px' : 'auto', position: 'relative'}}></div>
        </div>
     }
     </>
   )
}

export default KycScreen
