import React, {useEffect,useState, useContext} from 'react'
import {Row} from 'react-bootstrap'
import PlanCard from '../PricingPlan/PlanCard'
import styles from './viewPlan.module.css'
import { ThemeContext } from '../../Context/ThemeContext'



const ViewPlan = ({planList, loader}) => {

  const [heightCalculation, setHeightCalculation] = useState()
  const { siteLogo } = useContext(ThemeContext)

  useEffect(()=>{
    let maxLength = 0;
    planList && planList.map((item,i)=>{
      maxLength = Math.max(maxLength, item.features.length)
    })
    setHeightCalculation(maxLength*40)
  },[planList])

  return(
    <>
    <div className={styles.bigscreenHeader}>
      <nav
        className={`navbar navbar-expand-lg navbar-light ${styles.navbar}`}>
          <div>
            <img
              // alt='stockal Logo'
              src={siteLogo}
              height='32px'
            ></img>
          </div>
        </nav>
      </div>
      <div className={`overlayLoading themeLoaderColor ${loader ? '' : 'd-none'}`}></div>

        <div className={styles.heading}>Pricing</div>
        <Row style={{justifyContent:"center",margin:"0px",marginTop:"42px",marginBottom:"100px"}}>
          {planList && planList.length > 0
              ? (
                  planList.map((item,i)=>{
              return(
                <PlanCard
                  i={i}
                  size={planList.length}
                  features={item.features}
                  planName={item.planName}
                  planTitleAdjacent={item.planTitleAdjacent}
                  planTitleAmount={item.planTitleAmount}
                  subscriptionAmount={item.subscriptionAmount}
                  freeTrades = {item.freeTrades}
                  planId={item.planId}
                  planList={planList}
                  featuresv2={item.featuresv2}
                  heightCalculation={heightCalculation}
                  currency={item.currency}
                  forView = {true}
                />
              )
              })
            ) : ''
          }
          </Row>
          </>
  )
}

export default ViewPlan
