import { MARKET_OPEN_STATUS } from "./constants";
import {isNumber} from 'lodash'

function decimalAdjust(type, value, exp) {
  if(value === '--'){
    return '--'
  }
    if (typeof exp === 'undefined' || +exp === 0) {
        return Math[type](value);
    }
    value = +value;
    exp = +exp;
    // If the value is not a number or the exp is not an integer...
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
        return NaN;
    }
    // Shift
    value = value.toString().split('e');
    value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
    // Shift back
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
}

function calcCurrVal(unit, price) {
  return decimalAdjust('floor',unit * price, -2);
}

function calReturn(currVal, currInv) {
  if(currVal === '--' && currInv === '--'){
    return {
      ret:'--',
    retPer:'--',
    }
  }
  const ret =  decimalAdjust('floor',currVal - currInv, -2);

  if (currInv === 0) {
    currInv = 1;
  }
  const retPer = decimalAdjust('floor',(ret / currInv) * 100, -2)
  return {
    ret,
    retPer,
  };
}

function calPriceChange({currPrice, close, priorClose, isMarketOpen}){
  let prevDayPrice = isMarketOpen ? close : priorClose ;
  const change = decimalAdjust('floor',currPrice - prevDayPrice, -2);
  if(prevDayPrice === 0) {
    prevDayPrice = 1;
  }
  const changePer = decimalAdjust('floor',((currPrice - prevDayPrice) / prevDayPrice) * 100, -2)
  return {
    change,
    changePer,
  }
}

function isMarketOpen(marketStatus) {
  const marketOpen = marketStatus === MARKET_OPEN_STATUS ? true : false;
  return marketOpen;
}

function parseNumericMillion(value) {
  var parseDigits = isNaN(parseFloat(value)) || (value) === null ? " - -" : (parseFloat(parseFloat(value).toFixed(2)) === 0 ? "0" : Number(parseFloat((parseFloat(value) / 1000000).toFixed(2))).toLocaleString('en') + "M");
  return parseDigits;
}

function parseNumericBillion(value) {
  var parseDigits = isNaN(parseFloat(value)) || (value) === null ? " - -" : (parseFloat(parseFloat(value).toFixed(2)) === 0 ? "0" : Number(parseFloat((parseFloat(value) / 1000000000).toFixed(2))).toLocaleString('en') + "B");
  return parseDigits;
}

function addCommasinPrice(price){
  if(price && isNumber(price)) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return price
}

function formatValue(price){
  if(price && isNumber(price)) {
    return price
  }
  return price
}

// function maskPhoneNumber = (phone) => {
//   if(phone && phone.length > 4){
//     phone.slice()
//   }
// }

function validateFields(requiredKeys, data) {
  const missingKeys = [];
  const blankKeys = [];
  // eslint-disable-next-line array-callback-return
  requiredKeys.map((key) => {
    if (!Object.keys(data).includes(key)) {
      missingKeys.push(key);
    }
    if (data[key] === '') {
      blankKeys.push(key);
    }
  });
  return { missingKeys, blankKeys };
};

function validateNumberFieldWithDecial(number){
  const re = /^\d+(\.\d{1,2})?$/
  return number.match(re);
}

function calPriceChange_New({currPrice, close}){
  const change = decimalAdjust('floor',currPrice - close, -2);
  if(close === 0) {
    close = 1;
  }
  const changePer = decimalAdjust('floor',((currPrice - close) / close) * 100, -2)
  return {
    change,
    changePer,
  }
}

function toPascalCase(str){
  return str.toLowerCase().split(' ').map(function(word) {
    return (word.charAt(0).toUpperCase() + word.slice(1));
  }).join(' ');
}

const dateFormatter = (mydate) => {
  var curr_date = mydate.getDate();
  var curr_month = mydate.getMonth() + 1;
  var curr_year = mydate.getFullYear();
  if (curr_date < 10) {
    curr_date = "0" + curr_date;
  }
  if (curr_month < 10) {
    curr_month = "0" + curr_month;
  }
  mydate = `${curr_month}-${curr_date}-${curr_year}`;
  return mydate;
}

export {
    decimalAdjust,
    calcCurrVal,
    calReturn,
    calPriceChange,
    isMarketOpen,
    parseNumericMillion,
    parseNumericBillion,
    addCommasinPrice,
    validateFields,
    validateNumberFieldWithDecial,
    calPriceChange_New,
    toPascalCase,
    dateFormatter,
    formatValue
};
