import React,{useEffect,useState} from 'react'
import {Spinner} from 'react-bootstrap'
import styles from './sso.module.css'
import queryString from 'query-string'
import SsoError from './SsoError'
import {getToken,resendotpsso,ssoLogin} from '../../api'
import SsoOtp from './sso-otp'
import {SsoSignin} from '../../components'

const SsoPartner = () => {
  const [errorMessage,seterrorMessage] = useState('')
  const [otpNeed,setOtpNeed] = useState(false)
  const [newauthcode,setnewauthcode] = useState('')
  const [otpError,setotpError] = useState('')
  const [showLogin,setshowLogin] = useState(false)

  let queryUrl = queryString.parse(window.location.search, {
    parseNumbers: false
  })
    const {client_id='',redirect_uri=''} = queryUrl

  useEffect(() => {
    const asyncFetch = async() => {
    let queryUrl = queryString.parse(window.location.search, {
			parseNumbers: false
    })
    // if(queryUrl && 'code' in queryUrl){ //checking if code parameter in queryurl
    //   await getTokenFromCode(queryUrl,'','')
    // }
     if(queryUrl && queryUrl['response_type'] === 'code'){
     await getAuth(queryUrl)
    }
    else{
      await getTokenFromCode(queryUrl,'','')
    }
    // else{
    //   window.location.pathname='/signin'
    // }
   }
   asyncFetch()
  },[])


    const getAuth = async () => {
      const { accessToken = '' } = JSON.parse(sessionStorage.getItem('token')) ? JSON.parse(sessionStorage.getItem('token')) : {}
      if(accessToken === ''){
        setshowLogin(true)
      }
      else{
        try{
        const data = {
          client_id,
          redirect_uri
        }
         const resp = await getSsoLogin(data)
         }catch(error){
          if(error.code === 401){
            setshowLogin(true)
           }
           else{
            seterrorMessage(error.error)
           }
         }
      }
    }

    const getSsoLogin = async (data) => {
     try{
      const resp = await ssoLogin(data)
      if(resp.status === 200){
        const url = queryUrl.redirect_params ? `${redirect_uri}?code=${resp.data.auth_code}&redirect_params=${queryUrl.redirect_params}` : `${redirect_uri}?code=${resp.data.auth_code}`
        window.open(
          url,
          '_blank'
        );
        window.history.back(1)
      }
      return resp
     }catch(error){
       console.log(error)
       throw error.data
     }
    }


    const getTokenFromCode = async (queryUrl,auth_code,otp) => {
      const { accessToken = '' } = JSON.parse(sessionStorage.getItem('token')) ? JSON.parse(sessionStorage.getItem('token')) : {}
      if(accessToken){
        window.location.pathname='/home'
      }
      try{
        const code  = auth_code === '' ? queryUrl.code : auth_code
        const response = await getToken(code,otp)
        if(response){
          const accessToken = response['accessToken']
          const refreshToken = response['refreshToken']
          if (accessToken || refreshToken) {
            const token = {
              accessToken: accessToken,
              refreshToken: refreshToken
            }
            sessionStorage.setItem('token', JSON.stringify(token))   //storing token in storage
            sessionStorage.setItem('Sso_flag',true)
          }
          if('redirect_uri' in queryUrl){  // checking if redirect_uri in queryurl
            const decoderedirect_uri = decodeURIComponent(queryUrl.redirect_uri)
            if(decoderedirect_uri.startsWith('/'))
            {
              window.location.pathname=decoderedirect_uri
            }
            else{
            const url = new URL(decoderedirect_uri)
            if(url.host === window.location.host ){    //checking if redirect_uri and our host's domain match
              window.location = decoderedirect_uri
            }
            else{
              //log user out, error page to show
              sessionStorage.clear()
              localStorage.clear()
              seterrorMessage('Cannot redirect to a site outside the domain')
            }
           }
          }
          else{
            window.location.pathname='/home'// no redirect_uri, redirect to home
          }
        }
      }catch(error){
        if(error && error.status === 422 && error.data.otp_required){
          setnewauthcode(error.data.auth_code,setOtpNeed(true))
        }
        else if(error && error.status === 401){
          if(error.data.remainingAttempts > 0){
            setotpError('Invalid OTP. Please try again.')
          }
          else if(error.data.remainingAttempts <= 0){
            seterrorMessage('Invalid OTP. Attempts exhausted.')
          }
          else{
            seterrorMessage('Invalid session credentials')
          }
        }
        else if(error && error.status >= 500){
          seterrorMessage('Something went wrong')
        }
        else{
        seterrorMessage('Invalid session credentials')
        }
      }
    }

   return(
     <>
    {showLogin ? <SsoSignin getSsoLogin={getSsoLogin} client_id={client_id} redirect_uri={redirect_uri}/> :
     errorMessage === '' ?
     otpNeed ?
      <SsoOtp auth_code={newauthcode} getTokenFromCode={getTokenFromCode} otpError={otpError} setotpError={setotpError}/>
     :
    <div className={styles.spinner}><Spinner animation="border" className={styles.spinnerColor}/></div>
     :
      <SsoError message={errorMessage}/>
     }
    </>
   )
}

export default SsoPartner
