import React, { useContext, useEffect } from "react"
import styles from "./common.module.css"
import Lottie from 'react-lottie-player';
import SuccessLottie from "../../../../public/lottie/success.json"
// import { format } from "date-fns";
import { Link } from "react-router-dom";
import { AddFundContext } from "../../AddFundContext";
import { useDispatch } from 'react-redux';
import {closeFundModal} from '../../../../store/actions'
const Success = ({onComplete, msg, remittance,desc})=>{
    const {setNoClose} = useContext(AddFundContext)
    const dispatchAction = useDispatch()
    useEffect(()=>{
        if(onComplete)
            setNoClose(true)
        else
            setNoClose(false)
    },[onComplete])
    return (<div className={styles.successContainer}>
        <Lottie
            rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
            loop={false}
            animationData={SuccessLottie}
            play
            style={{ width: 200, height: 200 }}
            onComplete={()=>onComplete && onComplete()}
        />
        <div className={styles.successMsg} style={{marginBottom:'12px'}}>{msg?msg:'Transfer Request Accepted'}</div>
        {desc ?
        <div className={styles.successDesc} style={{marginBottom:'20px',paddingLeft:'12px',paddingRight:'12px'}}>
          {desc}</div>
          : ''}
        {remittance && <>
          <button className={styles.secondaryBtn} style={{width:'80%'}} onClick={()=>{dispatchAction(closeFundModal())}}>
             Remittance History
          </button>
            {/* <div className={styles.date}>{format(Date.now(), 'dd MMM yy, HH:mm')}</div> */}
            {/* <Link to='/remittance' className={styles.textLink} onClick={()=>{dispatchAction(closeFundModal())}}>Remittance History</Link> */}
        </>}
    </div>)
}

export default Success
