import React, { useState, useEffect } from 'react'
import {Row,Col} from 'react-bootstrap'
import { getUserInvestmentHistory, getSnPHistory } from '../../api'
import ReactHighstock from 'react-highcharts/ReactHighstock'
import _, { isEmpty } from 'lodash'
import './StockLine.css'

const StockLineChart = () => {
	const [userGraph, setUserGraph] = useState([])
	const [snpGrpah, setSnpGrpah] = useState([])
  const [active, setActive] = useState('3m')

  useEffect(() => {
		getHistoryDetail(90)
	}, [])


  const getHistoryDetail = async days => {
		try {
			const snpData = []
			const userData = []
      const timeStamp = []
      const {data = []} = await getUserInvestmentHistory(days)
      if(data.length > 0){
				data.map(item => {
					let date = item.Date.split('-')
          timeStamp.push(Date.UTC(date[0], date[1] - 1, date[2]))
					userData.push([
					  Date.UTC(date[0], date[1] - 1, date[2]),
						parseFloat(item.cashBalanceAndTotalCurrentValue)
					])
				})
        userData.sort((a, b) => a[0] - b[0])
        const removeZero = userData.filter((item) => item[1] != 0)
        const respsnp = await getSnPHistory('$SPX',days)
        if(respsnp.length > 0){
          const tempgspc = _.orderBy(respsnp, ['timestamp'])
          for (let i = 0; i < removeZero.length; i++) {
            for (let j = 0; j < tempgspc.length; j++) {
              if(removeZero[i][0] == (tempgspc[j].timestamp)*1000){
                snpData.push([tempgspc[j].timestamp * 1000, parseFloat(tempgspc[j].price)])
              }
            }
          }
        }
        // console.log(snpData,userData)
        setSnpGrpah(snpData)
        setUserGraph(userData)
      }
		} catch (error) {
			console.log(error)
		}
	}

	const handleChange = (value, days) => {
		setActive(value)
		getHistoryDetail(days)
	}


	// useEffect(() => {
	// 	const asynchFetch = async () => {
	// 		const snpData = []
	// 		const userData = []
  //     try{
  //       const {data} = await getUserInvestmentHistory()
  //       if(data.length > 0){
  //       const temp = _.orderBy(data, ['Date'])
  //       const maxData = temp[0]
  //       const oneDay = 24 * 60 * 60 * 1000;
  //       const diffDays = Math.round(Math.abs((new Date() - new Date(maxData.Date)) / oneDay));
	// 			temp.map(data => {
	// 				let date = data.Date.split('-')
	// 				userData.push([
	// 				  Date.UTC(date[0], date[1] - 1, date[2]),
	// 					parseFloat(data.totalCurrentValue)
	// 				])
	// 			})
  //     const respsnp = await getSnPHistory('$SPX',diffDays)
  //       console.log(respsnp)
  //       if(respsnp.length > 0){
  //         const tempgspc = _.orderBy(respsnp, ['timestamp'])
  //         tempgspc.map(item => {
  //           snpData.push([item.timestamp * 1000, parseFloat(item.price)])
  //         })
  //       }
  //     }
  //     }catch(error){
  //       console.log(error)
  //     }
  //     console.log(snpData,userData)
	// 		setSnpGrpah(snpData)
	// 		setUserGraph(userData)
	// 	}
	// 	asynchFetch()
	// }, [])

	const custoumOption = () => {
		return {
			chart: {
				style: {
					overflow: 'visible',
					// width: 700,
					height: 220
				}
			},
			scrollbar: {
				enabled: false
			},
			rangeSelector: {
        enabled:false,
				y: 50,
				verticalAlign: 'bottom',
				allButtonsEnabled: true,
				inputEnabled: false,
				buttons: [
					{
						type: 'day',
						count: 1,
						text: '1d'
					},
					{
						type: 'week',
						count: 1,
						text: '1w'
					},
					{
						type: 'month',
						count: 1,
						text: '1m'
					},
					{
						type: 'year',
						count: 1,
						text: '1y'
					},
					{
						type: 'all',
						text: 'max'
					}
				],
				buttonTheme: {
					fill: 'none',
					style: {
						color: '#9096A8',
						fontSize: '14px',
						lineHeight: '16px',
						fontWeight: 'bold'
					},
					width: 20,
					height: 10,
					padding: 12,
					states: {
						hover: {
							fill: 'none',
							style: {
								color: '#1E1F24'
							}
						},
						select: {
							fill: 'none',
							stroke: '#1E1F24',
							'stroke-dashoffset': '57',
							'stroke-dasharray': '35,100',
							'stroke-width': 1,
							style: {
								color: '#1E1F24',
								borderBottom: '1px solid #1E1F24'
							}
						}
					}
				},
				selected: 4
			},
			title: {
				text: undefined
			},
			navigator: { enabled: false },

			tooltip: {
				crosshairs: false,
				enabled: true,
        backgroundColor: '#FFFFFF',
        borderColor: '#EFEFF1',
        borderRadius: '0',
        valueDecimals: 4,
        split: true,
        style: {
          zIndex: 100
        }
			},
			credits: {
				enabled: false
			},
			xAxis: {
				visible: false
			},
			yAxis: {
				visible: false
			},

			legend: {
				enabled: false
			},

			plotOptions: {
				series: {
					compare: 'percent',
					showInNavigator: true,
					dataGrouping: {
						enabled: false
					},
					marker: {
						enabled: false,
						states: {
							hover: {
								enabled: false
							}
						}
					}
				}
			},

			series: [
				{
					name: 'S&P500',
					data: snpGrpah.sort((a, b) => a[0] - b[0]),
					// type: 'line',
					lineWidth: 2,
					lineColor: '#1E1F24'
				},
				{
					name: 'user',
					data: userGraph.sort((a, b) => a[0] - b[0]),
					// type: 'line',
					lineWidth: 2,
					lineColor: '#0DC014 '
				}
			]
		}
	}
	return (
		<div>
			<ReactHighstock config={custoumOption()}></ReactHighstock>
      <Row className='ml-0 marginTop'>
      <Col>
					{/* <span className={active === '1d' ? styles.active : styles.nonactive} onClick={() => handleChange('1d',1)}>1d</span> */}
					<span
						className={active === '1m' ? 'activeValue' : 'nonactive'}
						onClick={() => handleChange('1m', 30)}>
						1m
					</span>
					<span
						className={active === '3m' ? 'activeValue' : 'nonactive'}
						onClick={() => handleChange('3m', 90)}>
						3m
					</span>
          <span
						className={active === '6m' ? 'activeValue' : 'nonactive'}
						onClick={() => handleChange('6m', 180)}>
						6m
					</span>
					<span
						className={active === '1y' ? 'activeValue' : 'nonactive'}
						onClick={() => handleChange('1y', 365)}>
						1y
					</span>
					<span
						className={active === 'max' ? 'activeValue' : 'nonactive'}
						onClick={() => handleChange('max', 365)}>
						max
					</span>
				</Col>
        <Col className='compare'>
          <div className='legendGraph'></div>
          <span style={{marginLeft:'12px'}}>Portolio</span>
          <div className='legendGraph' style={{background:'#1E1F24',marginLeft:'16px'}}></div>
          <span style={{marginLeft:'12px'}}>S&P500</span>
        {/* Compare with S&P 500 */}
        </Col>
      </Row>
      {/* <div className='compare'>Compare with S&P 500</div> */}
		</div>
	)
}

export default StockLineChart
