import React from 'react'
import { Card, Row} from 'react-bootstrap'
import styles from './cashManagementDropDown.module.css'
import close from '../../public/images/savingsClose.svg';
import VirtualPin from '../../public/images/virtualPin.svg';
import FreezePin from '../../public/images/freezePin.svg';
import unfreezeIcon from '../../public/images/unfreezeIcon.svg'
import AccountDetails from '../../public/images/accountDetails.svg';

const CashManagementProfileCard = (props) => {

  const { virtualCardStatus, setModalData, OpenDropdownModal, isCardActivated } = props

  return (
    <Card className={styles.CashManagementProfileCard}>
      <div className={styles.cashManagementMobileCross} onClick={() => props.dropDownShow(false)}><img src={close} alt='close-icon'></img></div>
      <Card.Body className='p-0'>
        <Row className={`${styles.profileList} ${styles.borderDropDownCashManagement}`}>
            <span className={styles.dropDownImages}>
              <img src={AccountDetails} alt="icon-account"></img>
            </span>
            <span 
              className={styles.listItem}
              onClick={() => {
                OpenDropdownModal()
                setModalData('accountDetails')
              }}>
                Account Details
              </span>
        </Row>
        <Row className={`${styles.profileList} ${styles.borderDropDownCashManagement}`} style={isCardActivated && virtualCardStatus === 'active'  ? {backgroundColor:"#F7F7F8"} : {backgroundColor:"#F7F7F8"}}>
            <span className={styles.dropDownImages}>
              <img src={VirtualPin} alt="icon-account"></img>
            </span>
            <span 
              className={isCardActivated && virtualCardStatus === 'active' ? `${styles.textDisabled}` : `${styles.textDisabled}`}
              // onClick={isCardActivated && virtualCardStatus === 'active' ? () => {
              //   OpenDropdownModal()
              //   setModalData('changePin')
              // } : undefined}
              >
                Change Virtual Card PIN
              </span>
        </Row>
        {virtualCardStatus === 'active' ? (
          <Row className={`${styles.profileList} ${styles.borderDropDownCashManagement}`} style={isCardActivated  ? {backgroundColor:"#F7F7F8"} : {backgroundColor:"#F7F7F8"}}>
            <span className={styles.dropDownImages}>
              <img src={FreezePin} alt="icon-account"></img>
            </span>
            <span 
              className={isCardActivated ? `${styles.textDisabled}` : `${styles.textDisabled}`}
              style={{color: isCardActivated ? "#FA274A" : "#767C8F"}}
              // onClick={isCardActivated ? () => {
              //   OpenDropdownModal()
              //   setModalData('freezeCard')
              // } : undefined}
              >
                Freeze Virtual Card
              </span>
          </Row>
        ) : (
          <Row className={`${styles.profileList} ${styles.borderDropDownCashManagement}`} style={isCardActivated  ? {backgroundColor:"#F7F7F8"} : {backgroundColor:"#F7F7F8"}}>
            <span className={styles.dropDownImages}>
              <img src={unfreezeIcon} alt="icon-account"></img>
            </span>
            <span 
              className={isCardActivated ? `${styles.textDisabled}` : `${styles.textDisabled}`}
              // onClick={isCardActivated ? () => {
              //   OpenDropdownModal()
              //   setModalData('unfreezeCard')
              // } : undefined}
              >
                Unfreeze Virtual Card
              </span>
          </Row>
        )}
      </Card.Body>
    </Card>
  )
}

export default CashManagementProfileCard
