import React, { useState, useEffect,createRef } from 'react'
import { Row, Col,OverlayTrigger,Tooltip } from 'react-bootstrap'
import styles from '../PickupDetail/pickupDetail.module.css'
import { format } from 'date-fns'
import UploadImage from '../AccountSetUp/UploadImage/UploadImage'
import questionImage from '../../public/images/TooltipQuestion.svg'
import checkImage from '../../public/images/check.png'
import backImage from '../../public/images/back.png'
import plusImage from '../../public/images/plusagain.png'
import RemoveTransaction from '../../public/images/removeTrnsaction.svg'
import dropImage from '../../public/images/headerarrownew.svg'
import DatePicker from '../../components/DatePicker/DatePicker'
import ProgressBar from './ProgessBar'
import stylesheet from './Fairexpay.module.css'
import _ from 'lodash'
import { putLrsAndPickup,geoCodingApi,previousTransactionFund,postDocument } from '../../api'

const PickupDetail = ({
	setcurrent,
  setModalData,
	pickupInformation,
	setPickupInformation,
	hashId,
  previousTransaction,
  setPreviousTransaction,
  previous,
  setprevious,
  transactionCount,
  setTransactionCount,
  disablePrevious,
  setDisablePrevious,
  checkBank,
  panFileFront,
  setpanFileFront,
  accountStatement1,
  setaccountStatement1,
  accountStatement2,
  setaccountStatement2,
  accountStatement3,
  setaccountStatement3,
  accountStatement4,
  setaccountStatement4,
  panFileBack,
  setpanFileBack,
  setaccountStatementCount,
  accountStatementCount,
  flow,
  back
}) => {
	const [check, setCheck] = useState(false)
	const [showCalendar, setShowCalendar] = useState(false)
  const [showCalendarTransaction, setshowCalendarTransaction] = useState([false])
  // const [previousTransaction,setPreviousTransaction] = useState([{ "prevLrsAmount": '', "prevLrsDate": '', "prevLrsBank": '' }])
  // const [previous,setprevious] = useState(false)
	const [pickupDate, setPickupDate] = useState('')
  const [manuall, setManuall] = useState(false)
  const [loading,setLoading] = useState(false)
  const [errorMessage,setErrorMessage] = useState('')
  // const [transactionCount,setTransactionCount] = useState(1)
  const [cityManual, setCityManual] = useState(false)
  const [stateManual, setStateManual] = useState(false)
  const [countryManual, setCountryManual] = useState(false)
  // const [panFile,setpanFile] = useState([])
  // const [accountStatement,setaccountStatement] = useState([])
  const panRefFront = createRef()
  const panRefBack = createRef()
  const accountStatement1Ref = createRef()
  const accountStatement2Ref = createRef()
  const accountStatement3Ref = createRef()
  const accountStatement4Ref = createRef()

	const dateFormatter = date => {
		var months = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December'
		]
		var day = date.getDate()
		var month = months[date.getMonth()]
		var year = date.getFullYear()
		return `${day} ${month} ${year}`
	}
	const timeSlot = [
		'8:00 am to 12:00 pm',
		'12:00 pm to 04:00 pm',
		'04:00 pm to 08:00 pm'
	]

	useEffect(() => {
		var minDate = new Date()
		minDate.setDate(minDate.getDate() + 2)
		setPickupDate(minDate)
    // getCurrentFinancialyear()
		// setPickupInformation({
		// 	...pickupInformation,
		// 	pickUpDate: dateFormatter(minDate),
		// 	pickUpTime: timeSlot[0],
    //   country: 'India'
		// })
	}, [])

	useEffect(() => {
    pickupInformation.applicantPan === ''
    ? setCheck(true)
    : pickupInformation.selfPickup ?
      setCheck(false)
    : pickupInformation.pickUpDate !== '' &&
      pickupInformation.pickUpTime !== '' &&
      pickupInformation.address1 !== '' &&
      pickupInformation.address2 !== '' &&
      pickupInformation.country !== '' &&
      pickupInformation.postalCode !== '' &&
      pickupInformation.city !== '' &&
      pickupInformation.state !== '' ? setCheck(false) :setCheck(true)

	}, [pickupInformation])

  const checkRequired =panFileFront.length ?false : true



  const handleChangeTransaction = (value,index) => {
    const copy = [...previousTransaction]
    copy[index].prevLrsDate = format(value,'yyyy-MM-dd')
    const calendarCopy = [...showCalendarTransaction]
    calendarCopy[index] = !calendarCopy[index]
    setPreviousTransaction(copy,setshowCalendarTransaction(calendarCopy))
	}

  const checkPan  = () => {
    if(!/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(pickupInformation.applicantPan)){
       setErrorMessage('Please Enter Valid PAN Number')
       return false
    }
    return true
  }

	const asynchFetch = async () => {
    setLoading(true)
    if(checkPan()){
    try{
     const filter = previousTransaction.filter((item) => {
        return item.prevLrsBank !== '' && item.prevLrsDate !== '' && item.prevLrsAmount !== ''
     })
     const updatedDetail = {...pickupInformation,applicantPan:pickupInformation.applicantPan.toUpperCase()}
    const response = hashId && await previousTransactionFund(hashId,filter)
    if(response && response.code === 200){
      setDisablePrevious(true)
    }
		const res =
			hashId &&
			(await putLrsAndPickup(hashId, updatedDetail))
      if(res && res.code === 200){
          const response = await uploadDocument()
          if(response && response.code === 200){
            setcurrent(flow.A2_WAIT)
          }
          if(response.code !== 200){
            setErrorMessage(response.message)
          }
      }
      if(res.code !== 200){
        setErrorMessage(res.message)
      }
    }catch(error){
      setErrorMessage('Something Went Wrong')
    }
  }
    setLoading(false)
	}

  //for fairexpay

  const createForm = (side,type,file) => {
    const body = new FormData()
     body.append('side',side)
     body.append('type',type)
     body.append('file',file)
     return body
  }

  const uploadDocument = async() => {
    let res
    if(panFileFront.length){
      const body = createForm('FRONT','PAN',panFileFront[0])
       res = await postDocument(hashId,body)
    }
    if(accountStatement1.length){
      const body = createForm('FRONT','ACCOUNT_STATEMENT',accountStatement1[0])
       res = await postDocument(hashId,body)
    }
    if(accountStatement2.length){
      const body = createForm('FRONT','OTHER',accountStatement2[0])
       res = await postDocument(hashId,body)
    }
    if(accountStatement3.length){
      const body = createForm('FRONT','OTHER',accountStatement3[0])
       res = await postDocument(hashId,body)
    }
    if(accountStatement4.length){
      const body = createForm('FRONT','OTHER',accountStatement4[0])
       res = await postDocument(hashId,body)
    }
    return res
  }

  const onPreviousHandler = (event,index) => {
    const re = /^[0-9\b]+$/
    if(event.target.name == 'prevLrsAmount'){
    if(event.target.value === '' || re.test(event.target.value)){
    const copy = [...previousTransaction]
    copy[index][event.target.name] = event.target.value
    setPreviousTransaction(copy)
    }
  }
    else{
      const copy = [...previousTransaction]
      copy[index][event.target.name] = event.target.value
      if(event.target.value.length <= 60){
      setPreviousTransaction(copy)
      }
    }
  }

  // for upload purpose
  const onDropPan = (name , file) => {
    file[0].preview = URL.createObjectURL(file[0])
    if(name === 'panFront'){
    setpanFileFront(file)
    }
    else if(name === 'panBack'){
      setpanFileBack(file)
    }else if(name === 'account1'){
      setaccountStatement1(file)
    }
    else if(name === 'account2'){
      setaccountStatement2(file)
    }
    else if(name === 'account3'){
      setaccountStatement3(file)
    }
    else if(name === 'account4'){
      setaccountStatement4(file)
    }
	}


	const onRemove = (name) => {
    if(name === 'panFront'){
      setpanFileFront([])
    }else if(name === 'panBack'){
      setpanFileBack([])
    }else if(name === 'account1'){
      setaccountStatement1([])
    }
    else if(name === 'account2'){
      setaccountStatement2([])
    }
    else if(name === 'account3'){
      setaccountStatement3([])
    }
    else if(name === 'account4'){
      setaccountStatement4([])
    }
	}

	const openPanDialog = (name) => {
		if (name === 'panFront' &&  panRefFront.current) {
      panRefFront.current.open()
		}
    else if(name === 'panBack' && panRefBack.current){
      panRefBack.current.open()
    }
    else if(name === 'account1' && accountStatement1Ref.current){
      accountStatement1Ref.current.open()
    }
    else if(name === 'account2' && accountStatement2Ref.current){
      accountStatement2Ref.current.open()
    }
    else if(name === 'account3' && accountStatement3Ref.current){
      accountStatement3Ref.current.open()
    }
    else if(name === 'account4' && accountStatement4Ref.current){
      accountStatement4Ref.current.open()
    }
	}


  let financial_year = "";
  let today = new Date();
  if ((today.getMonth() + 1) <= 3) {
      financial_year = (today.getFullYear() - 1) + "-" + today.getFullYear()
  } else {
      financial_year = today.getFullYear() + "-" + (today.getFullYear() + 1)
  }
  const startDate = new Date(financial_year.split('-')[0],3,1)
  const endDate = new Date()
	return (
		<div className={styles.card}>
      		<Row className='d-block d-md-none mb-2'>
				<Col md={12}>
					<div
						className={styles.title}
						style={{ cursor: 'pointer' }}
            >
						<span>PAN & AADHAAR verification</span>
					</div>
				</Col>
			</Row>
       <Row className={stylesheet.borderBottom}>
				<Col md={10} className='pr-0'>
					<ProgressBar steps='5' current={1} color='rgb(0, 93, 214)' status=' Digitally Sign A2 form'/>
				</Col>
			</Row>
			{/* <Row>
				<Col md={12}>
					<div
						className={styles.title}
						style={{ cursor: 'pointer' }}
            >
						<span>{`Stockal EasyFund Transfer `}</span>
					</div>
				</Col>
			</Row> */}

			<Row style={{ marginTop: '40px' }}>
				<Col md={6} xs={12}>
					<div className={styles.pan}>Enter PAN Number *</div>
					<input
						placeholder='Your PAN Number'
						className={styles.panInput}
						value={pickupInformation.applicantPan}
						onChange={e =>
							setPickupInformation({
								...pickupInformation,
								applicantPan: e.target.value
							})
            }
            onPaste={(e)=>{
              e.preventDefault()
              return false;
            }} onCopy={(e)=>{
              e.preventDefault()
              return false;}}
					/>
				</Col>
					<Col md={12}
          	style={{
						  borderBottom: '1px solid #EFEFF1',
						  paddingBottom: '32px'
					}}>
						<div className={styles.lrs}>
							Have you done any LRS in current financial year?
							<span style={{ paddingLeft: '10px' }}>
              <OverlayTrigger
									placement='top'
									overlay={
										<Tooltip id='button-tooltip-2'>
											As per RBI guidelines - please provide the previous LRS transaction details done in the current financial year. If you have not done any LRS transaction, please choose 'No'
										</Tooltip>
									}>
									<img src={questionImage} className={styles.tooltipImage}></img>
								</OverlayTrigger>
								{/* <img src={questionImage} /> */}
							</span>
						</div>
					</Col>
					<Col md={12} style={{ marginTop: '16px' }}>
						<span>
							<button
								type='button'
								className='btn btn-light'
								style={{
									paddingLeft: '65.5px',
									paddingRight: '65.5px',
									paddingTop: '12px',
									paddingBottom: '12px',
									background: pickupInformation.hasDoneAnyPreviousLRSinYear
										? ' #1E1F24'
										: '#EFEFF1',
									borderRadius: '8px',
									color: pickupInformation.hasDoneAnyPreviousLRSinYear
										? '#EFEFF1'
										: '#1E1F24',
									border: 'none'
								}}
								onClick={() =>
                  {
									setPickupInformation({
										...pickupInformation,
										hasDoneAnyPreviousLRSinYear: true
									})
                  setDisablePrevious(false)
                  setprevious(true)
                }
								}>
								Yes
							</button>
						</span>
						<span style={{ marginLeft: '20px' }}>
							<button
								type='button'
								className='btn btn-dark'
								style={{
									paddingLeft: '65.5px',
									paddingRight: '65.5px',
									paddingTop: '12px',
									paddingBottom: '12px',
									background: pickupInformation.hasDoneAnyPreviousLRSinYear
										? '#EFEFF1'
										: ' #1E1F24',
									borderRadius: '8px',
									color: pickupInformation.hasDoneAnyPreviousLRSinYear
										? ' #1E1F24'
										: '#EFEFF1',
									border: 'none'
								}}
                disabled={disablePrevious}
								onClick={() =>{
									setPickupInformation({
										...pickupInformation,
										hasDoneAnyPreviousLRSinYear: false
									},setTransactionCount(1),setPreviousTransaction([{ "prevLrsAmount": '', "prevLrsDate": '', "prevLrsBank": '' }]))
                  // setDisablePrevious(false)
                  setprevious(false)
                }
								}>
								No
							</button>
						</span>
					</Col>
          <div style={{display: previous
								? 'block'
								: 'none'}}>
						<Col md={12} style={{ marginTop: '24px'}}>
							<div className={styles.please}>
								Please provide details of any foreign currency transactions you
								have in current financial year (April - March)
							</div>
						</Col>
             {_.times(transactionCount, (i) => {
                 return(
                   <>
                  <Row className={styles.lrsstyle}>
                  <Col md={6} xs={12} style={{ marginTop: '24px' }}>
                    <div className={styles.us}>Amount in US$</div>
                    <input
                      placeholder='Enter Amount...'
                      name='prevLrsAmount'
                      className={styles.input3}
                      disabled={disablePrevious}
                      value={previousTransaction[i].prevLrsAmount}
                      onChange={(event) => onPreviousHandler(event,i)}
                    />
                  </Col>
                  <Col md={6} style={{ marginTop: '24px' }}>
                  <div className={styles.date}>Date of transaction</div>
                  <button
                    className={`${styles.selectDate}`}
                    onClick={() => {
                      const copy = [...showCalendarTransaction]
                      copy[i]= !copy[i]
                      setshowCalendarTransaction(copy)
                    }}
                    disabled={disablePrevious}
                  >
                    {previousTransaction[i].prevLrsDate !== '' ? previousTransaction[i].prevLrsDate : 'Date of transactions'}
                    <img src={dropImage} className={styles.arrowCss}/>
                  </button>
                  {showCalendarTransaction[i] && (
                    <div
                      style={{
                        marginTop:"5px",
                        zIndex: '1000',
                        position: 'absolute',
                        width: '91%'
                      }}
                      className={styles.dateBox}>
                      <DatePicker
                        onChange={date => handleChangeTransaction(date,i)}
                        minDate={startDate}
                        maxDate={endDate}
                        date={pickupDate}
                        color='#0061D3'
                      />
                    </div>
                  )}
                  </Col>
                  </Row>
                  <Col md={6} style={{ marginTop: '32px' }}>
                    <div className={styles.adName}>Bank / AD Name & Address</div>
                    <textarea
                      disabled={disablePrevious}
                      placeholder='Enter Bank Address'
                      className={styles.input4}
                      name='prevLrsBank'
                      value={previousTransaction[i].prevLrsBank}
                      onChange={(event) => onPreviousHandler(event,i)}
                    />
                    <div className={styles.Forex}>
                      AD Name - Authorized Forex Dealer Name
                    </div>
                  </Col>
                  </>
                 )
             })}
             <Row className='ml-0'>
             {transactionCount < 3 && disablePrevious === false ?
						<Col style={{ marginTop: '24px' ,paddingLeft:'10px'}} md={3}>
							<div style={{ cursor: 'pointer' }} onClick={() => {
                  setPreviousTransaction(
                    [...previousTransaction,{ "prevLrsAmount": '', "prevLrsDate": '', "prevLrsBank": '' }],
                    setTransactionCount(transactionCount + 1))
                  }}>
								<img src={plusImage} />
								<span className={styles.transaction}>Add Transaction</span>
							</div>
						</Col>
             : ''}
             {transactionCount > 1 && disablePrevious === false ?
              	<Col style={{ marginTop: '24px' }}>
                <div style={{ cursor: 'pointer' }} onClick={() => {
                    const retrive = previousTransaction
                    retrive.pop()
                    setPreviousTransaction(retrive,setTransactionCount(transactionCount - 1))
                    }}>
                  <img src={RemoveTransaction} />
                  <span className={styles.transaction} style={{color:'#FA274A'}}>Remove Transaction</span>
                </div>
              </Col>  : ''
            }
            </Row>
            </div>
			</Row>
        <div className='mt-5'>
            <div className={styles.pan} style={{fontSize:'20px',color:'#3C404A'}}>Upload PAN Card</div>
            <Row className='mb-3'>
            <Col md={6}>
            <UploadImage
              files={panFileFront}
              onDrop={(file) => onDropPan('panFront',file)}
              dropRef={panRefFront}
              openDialog={() => openPanDialog('panFront')}
              onRemove={onRemove}
              name='panFront'
              accept='image/jpeg, image/png, image/jpg'
              allowedType='jpg, png,jpeg'
              allowedSize='File size must be less than 10MB'
            />
          </Col>
          {/* <Col>
            <UploadImage
              files={panFileBack}
              onDrop={(file) => onDropPan('panBack',file)}
              dropRef={panRefBack}
              openDialog={() => openPanDialog('panBack')}
              onRemove={onRemove}
              name='panBack'
            />
          </Col> */}
            </Row>
            <div className={styles.pan}
             style={{fontStyle:'italic',marginBottom:'100px',color:'#FA274A'}}
            >Note:You will be required to digitally sign the A2 form via Aadhaar verification as a next step. Please keep your aadhaar no. ready. Please proceed with the steps received in your registered email Id.</div>
				</div>
        {/* <div className='mt-5 mb-5'>
            <div className={styles.pan} style={{fontSize:'20px',color:'#3C404A'}}> 1 year Bank Statement</div>
          <Row className='mb-3'>
              <Col md={6}>
              <UploadImage
                  files={accountStatement1}
                  onDrop={(file) => onDropPan('account1',file)}
                  dropRef={accountStatement1Ref}
                  openDialog={() => openPanDialog('account1')}
                  onRemove={onRemove}
                  name='account1'
                />
              </Col>
              {accountStatementCount >= 2 ?
                  <Col md={6}>
                  <UploadImage
                      files={accountStatement2}
                      onDrop={(file) => onDropPan('account2',file)}
                      dropRef={accountStatement2Ref}
                      openDialog={() => openPanDialog('account2')}
                      onRemove={onRemove}
                      name='account2'
                    />
                  </Col>
              :''}
              {accountStatementCount >= 3 ?
                  <Col md={6}>
                  <UploadImage
                      files={accountStatement3}
                      onDrop={(file) => onDropPan('account3',file)}
                      dropRef={accountStatement3Ref}
                      openDialog={() => openPanDialog('account3')}
                      onRemove={onRemove}
                      name='account3'
                    />
                  </Col>
              :''}
              {accountStatementCount >= 4 ?
                  <Col md={6}>
                  <UploadImage
                      files={accountStatement4}
                      onDrop={(file) => onDropPan('account4',file)}
                      dropRef={accountStatement4Ref}
                      openDialog={() => openPanDialog('account4')}
                      onRemove={onRemove}
                      name='account4'
                    />
                  </Col>
              :''}
          </Row>
          <div className={styles.pan}>Allowed types: pdf</div>
          <div className={styles.pan}>File size must be less than 10MB</div>
          <Row className={styles.marginLast}>
             {accountStatementCount < 4  ?
						<Col style={{ marginTop: '24px' ,paddingLeft:'10px'}} md={3}>
							<div style={{ cursor: 'pointer' }} onClick={() => setaccountStatementCount(accountStatementCount + 1)}>
								<img src={plusImage} />
								<span className={styles.transaction}>Add Other Pdf</span>
							</div>
						</Col>
             : ''}
            </Row>
				</div> */}
      {errorMessage === '' ? '' : <div className={styles.erorrMessage}>{errorMessage}</div>}
      <Row className={styles.fixedDiv}>
				<Col>
					<button
						onClick={() => asynchFetch()}
						disabled={check || loading || checkRequired}
						type='button'
						className={`btn btn-primary btn-sm ${styles.addfunds} themeButton`}>
						<span className={styles.addfundstext}>Save and Continue</span>
					</button>
				</Col>
			</Row>
		</div>
	)
}

export default PickupDetail

