import React from 'react'
import { Row, Col, Container, Card } from 'react-bootstrap'
import { ThemeContext } from '../../Context/ThemeContext'
import PersonalDetail from './Forms/PersonalDetail/PersonalDetail'
import Address from './Forms/Address/Addres'
import FinancialDetail from './Forms/FinancialDetail/FinancialDetail'
import PoliticallyExposed from './Forms/PoliticallyExposed/PoliticallyExposed'
import LegalDetail from './Forms/LegalDetail/LegalDetail'
import SignUpButton from '../SignUp/SignUpButton'
import SignUpInput from '../SignUp/SignUpInput'
// import StockalBrand from '../../public/images/StockalBrand.png'
import styles from './AccountSetUp.module.css'
import {
	extractDocumentContent,
	getPartnerCountries,
	updatedAccount,
  getPAN,
  verifyPAN,
  LeadCapture,
  getAccountInfo
} from '../../api'
import { currencyFormatter,setUserInfoSession } from '../../common'
import { format } from "date-fns"
import AccountError from '../../public/images/accountsetuperror.svg'
import AccountSetupSkeleton from '../../skeletons/AccountSetupSkeleton'
import { toPascalCase } from '../../utils'
import NewHeader from '../NewSignupComponents/NavBar/NewHeader'


const progressList = [
	{
		form: 'PERSONAL_DETAILS',
		percentage: '20%',
		title:  '20% done.',
		quote: 'You’re off to a good start!'
	},
	{
		form: 'ADDRESS',
		percentage: '40%',
		title: '40% done',
		quote: 'Keep going!'
	},
	{
		form: 'FINANCIAL_DETAILS',
		percentage: '60%',
		title: "60% done",
		quote: 'Inching closer!'
	},
  {
		form: 'POLITICALLY_EXPOSED',
		percentage: '80%',
		title: "80% done",
		quote: 'Almost there!'
	},
	{
		form: 'LEGAL_DETAILS',
		percentage: '90%',
		title: '90%',
		quote: 'The final stretch!'
	}
]

const formList = [
	'PERSONAL_DETAILS',
	'ADDRESS',
	'FINANCIAL_DETAILS',
	'POLITICALLY_EXPOSED',
	'LEGAL_DETAILS'
	// 'DOCUMENTS'
]

class AccountSetUp extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			showModal: true,
			activeForm: '',
			isEditPersonalDetail: '',
			isEditAddressDetail: '',
			isEditFinancialDetail: '',
			isEditPoliticallyExposed: '',
			isEditLegalDetail: '',
			formData: {
				personalDetail: {},
				addressDetail: {},
				financialDetail: {},
				politicallyExposed: {},
				legalDetail: {}
				// uploadDocument: {}
			},
			errors: {
				personalDetailError: {
					firstNameError: '',
					lastNameError: '',
					phoneNumberError: '',
					dateOfBirthError: '',
					errorMessage: '',
					error: false
				},
				addressDetailError: {
					addressLine1Error: '',
					addressLine2Error: '',
					postalCodeError: '',
					countryError: '',
					cityError: '',
					stateError: '',
					errorMessage: '',
					error: false
				},
				financialDetailError: {
					pancardError: '',
					errorMessage: '',
					error: false
				},
				politicallyExposedDetailError: {
					errorMessage: '',
					error: false
				},
				legalDetailError: {
					errorMessage: '',
					error: false
				}
			},
      formValidation:{
        personalDetail:false,
        addressDetail:false,
        financialDetail:false,
        politicallyExposedDetail:false,
        // legalDetail:true
      },
			ref: '',
			countryList: [],
			countryListForSourceOfFunds: [],
			citizenshipList: [],
			// flag:false,
			loading: false,
			isSkeleton: true,
			nextStep: null,
			signature: '',
			errorMessage: '',
      disabledValue:false
		}
	}

	setPersonaldata = () => {
    const {
      location
		} = this.context
		const details = sessionStorage.getItem('userInfo')
			? JSON.parse(sessionStorage.getItem('userInfo'))
			: ''

		if(details && details.signedBy) {
			this.setState({ signature: details.signedBy })
		}

    if(details?.firstName && (location === 'India' || location === 'INDIA')){
      this.setState({disabledValue:true})
    }

		if (details && details.nextStep) {
      let validationFormData = {}
			this.setState({ nextStep: details.nextStep })
			let allData = {}
			if (
				details.nextStep === 'PERSONAL_DETAILS' ||
				details.nextStep === 'ADDRESS' ||
				details.nextStep === 'FINANCIAL_DETAILS' ||
        details.nextStep === 'POLITICALLY_EXPOSED' ||
				details.nextStep === 'LEGAL_DETAILS'
			) {
				const personalDetail = {
					firstName: details.firstName ? details.firstName : '',
					middleName: details.middleName
						? toPascalCase(details.middleName)
						: '',
					lastName: details.lastName ? details.lastName : '',
					phoneNumber: details.phoneHome ? details.phoneHome : '',
					gender: details.gender ? details.gender : '',
					dateOfBirth: details.dob ? details.dob : ''
				}
        validationFormData = {...this.state.formValidation,personalDetail:true}
				//set code value
				const citizenShipCode = details.citizenship
					? details.citizenship
					: details.country
					? details.country
					: ''

				if (citizenShipCode) {
					const i = this.state.citizenshipList.findIndex(
						item => item.code === citizenShipCode
					)
					if (i !== -1) {
						personalDetail.citizenShipCode = citizenShipCode
						personalDetail.citizenShip = this.state.citizenshipList[i].value
						personalDetail.flagCode = this.state.citizenshipList[i].code2
					} else {
						if (this.state.citizenshipList.length === 1) {
							personalDetail.citizenShipCode =
								this.state.citizenshipList[0].code
							personalDetail.citizenShip = this.state.citizenshipList[0].value
							personalDetail.flagCode = this.state.citizenshipList[0].code2
						}
					}
				}

				// if(details.firstName){
				//   if(details.firstName.indexOf(' ') >= 0)
				//   {
				//     const nameArr =  details.firstName.split(' ');
				//     personalDetail.firstName = nameArr[0] ? nameArr[0] :'';
				//     personalDetail.middleName = nameArr.toString().substring(details.firstName.indexOf(' ')+1)
				//   }else
				//     personalDetail.firstName = details.firstName ? details.firstName :''
				// }

				if (Object.keys(personalDetail).length !== 0)
					allData = Object.assign(allData, { personalDetail })

				// // default address county
				// const addressDetail = {
				//   countryCode:details.countryID ? details.countryID : (details.country ? details.country :''),
				// }
				// if(addressDetail.countryCode){
				//   const index = this.state.countryList.findIndex(item=>item.code ===addressDetail.countryCode);
				//   addressDetail.country = index !== -1 ? this.state.countryList[index].value :''
				// }
				// if(Object.keys(addressDetail).length !== 0)
				//   allData = Object.assign(allData,{addressDetail})
			}

			if (
				details.nextStep === 'ADDRESS' ||
        details.nextStep === 'POLITICALLY_EXPOSED' ||
				details.nextStep === 'FINANCIAL_DETAILS' ||
				details.nextStep === 'LEGAL_DETAILS'
			) {
				const addressDetail = {
					addressLine1: details.addressLine1 ? details.addressLine1 : '',
					addressLine2: details.addressLine2 ? details.addressLine2 : '',
					state: details.stateProvince ? details.stateProvince : '',
					city: details.city ? details.city : '',
					postalCode: details.zipPostalCode ? details.zipPostalCode : ''
				}
        validationFormData = {...validationFormData,addressDetail:true}
				const countryCode = details.countryID
					? details.countryID
					: details.country
					? details.country
					: ''
				if (countryCode) {
					const index = this.state.countryList.findIndex(
						item => item.code === countryCode
					)
					if (index !== -1) {
						addressDetail.countryCode = countryCode
						addressDetail.country = this.state.countryList[index].value
						addressDetail.flagCode = this.state.countryList[index].code2
					}
				}
				if (Object.keys(addressDetail).length !== 0)
					allData = Object.assign(allData, { addressDetail })
			}
			if (
				details.nextStep === 'FINANCIAL_DETAILS' ||
				details.nextStep === 'POLITICALLY_EXPOSED' ||
				details.nextStep === 'LEGAL_DETAILS'
			) {
				const financialDetail = {
					employmentStatus: details.employmentStatus
						? details.employmentStatus
						: '',
					employeeCompany: details.employerCompany
						? details.employerCompany
						: '',
					employeeType: details.employerBusiness
						? details.employerBusiness
						: '',
					employeePositions: details.employmentPosition
						? details.employmentPosition
						: '',

					panCard: details.idNo ? details.idNo : '',
					investmentObject: details.investmentObjectives
						? details.investmentObjectives
						: '',
					investmentExper: details.investmentExperience
						? details.investmentExperience
						: '',
					riskTolerance: details.riskTolerance ? details.riskTolerance : '',

					annualIncome: details.annualIncome
						? currencyFormatter('USD', details.annualIncome)
						: '',
					netWorth: details.networthLiquid
						? currencyFormatter('USD', details.networthLiquid)
						: '',
					networthTotal: details.networthTotal
						? currencyFormatter('USD', details.networthTotal)
						: ''
				}
        validationFormData = {...validationFormData,financialDetail:true}
				// const sourceOfFundsCode = details.employerCountryID ? details.employerCountryID : (details.country ? details.country :'');
				const sourceOfFundsCode =
					details.employerCountryID || details.employerCountryID === ''
						? details.employerCountryID
						: details.country
						? details.country
						: ''
				if (sourceOfFundsCode) {
					const index = this.state.countryListForSourceOfFunds.findIndex(
						item => item.code === sourceOfFundsCode
					)
					if (index !== -1) {
						financialDetail.sourceOfFundsCode = sourceOfFundsCode
						financialDetail.sourceOfFunds =
							this.state.countryListForSourceOfFunds[index].value
						financialDetail.flagCode =
							this.state.countryListForSourceOfFunds[index].code2
					}
				} else {
					financialDetail.sourceOfFundsCode = ''
					financialDetail.sourceOfFunds = ''
				}

				if (Object.keys(financialDetail).length !== 0)
					allData = Object.assign(allData, { financialDetail })
			}
			if (
				details.nextStep === 'POLITICALLY_EXPOSED' ||
				details.nextStep === 'LEGAL_DETAILS'
			) {
				const politicallyExposed = {
					politicallyExposedType: details.politicallyExposedType,
					politicalPersonName: details.politicalPersonName,
					politicalPartyName: details.politicalPartyName
				}
        validationFormData = {...validationFormData,politicallyExposedDetail:true}
				allData = Object.assign(allData, { politicallyExposed })
			}
			if (Object.keys(allData).length !== 0) {
				const activeForm =
					details && details.nextStep ? details.nextStep : formList[0]
        this.updateFormValidation(validationFormData)
				this.setState({ activeForm })
				this.setFormPreData(allData)
			} else {
				this.setState({ activeForm: formList[0] })
			}
			// this.setState({flag:true})
		}
		// this.props.changeCurrent('ADDRESS')
		// this.setState({isSkeleton:false})
	}

	extractDoc = async applicantID => {
		try {
			const extractData = await extractDocumentContent(applicantID)
			if (extractData && extractData.data && extractData.data.length) {
				const filter = extractData.data.filter(item => {
					if (
						item.extracted_data &&
						Object.keys(item.extracted_data).length > 0
					) {
						return true
					}
				})
				if (filter.length) {
					const data = filter[0].extracted_data
					const name = data.first_name ? data.first_name.split(' ') : []
					const personalDetail = {
						firstName: name.length ? name[0] : '',
						middleName: name.length > 1 ? name[1] : '',
						lastName: data.last_name,
						gender: data.gender,
						dob: data.date_of_birth
					}
          const details = sessionStorage.getItem('userInfo')
          ? JSON.parse(sessionStorage.getItem('userInfo'))
          : ''
          if(!details.firstName){
          setUserInfoSession(personalDetail)
          }
				}
			}
		} catch (error) {
			console.log(error)
		}
		this.setState({ isSkeleton: false })
	}

	getCountryList = async (type, country) => {
		let countryData = []
		const response = await getPartnerCountries(type, country)
		if (response && response.code === 200 && response.data) {
			const list = response.data
			list.map(item => {
				if (item) {
					const temp = {
						value:
							item[type === 'citizenship' ? 'citizenshipName' : 'countryName'],
						label:
							item[type === 'citizenship' ? 'citizenshipName' : 'countryName'],
						code: item[type === 'citizenship' ? 'citizenship' : 'country'],
						code2:
							item[type === 'citizenship' ? 'citizenshipCode2' : 'countryCode2']
					}
					countryData.push(temp)
				}
			})
		}
		return countryData
	}

	setCountryList = async () => {
		const details = sessionStorage.getItem('userInfo')
			? JSON.parse(sessionStorage.getItem('userInfo'))
			: ''
		// if(details.nextStep === "PERSONAL_DETAILS" || details.nextStep === 'ADDRESS' || cb){
		//fetching citizenship list by country
		const citizenShipFilterData = details.country
			? await this.getCountryList('citizenship', details.country)
			: []
		//fetching country list by citizenship
		const countryFilterData = details.citizenship
			? await this.getCountryList('residence', details.citizenship)
			: []
		//fetching countryoffunds list
		const countryListForSourceOfFunds = await this.getCountryList(
			'sourceoffunds'
		)

    const applicantID = sessionStorage.getItem('oaid')
		if (applicantID) {
			await this.extractDoc(applicantID)
		} else {
			this.setState({ isSkeleton: false })
		}

		this.setState(
			{
				countryList: countryFilterData,
				citizenshipList: citizenShipFilterData,
				countryListForSourceOfFunds
			},
			() => {
				this.setPersonaldata()
			}
		)
	}

	setCountryByCitizenship = async citizenship => {
		//fetching country list by citizenship
		const countryFilterData = citizenship
			? await this.getCountryList('residence', citizenship)
			: []
		this.setState({ countryList: countryFilterData })
	}

	setCountryCitizenshipList = async () => {
		// const countryFilterData = []
		// const citizenShipFilterData=[]
		// let countryListForSourceOfFunds=[]
		// const details = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : '';
		// getPartnerCountries1(details.country).then(response => {
		// 	if (response && response.code === 200 && response.data) {
		//       const list = response.data;
		//       // country logic
		//     list.activeCountry.map(item => {
		//         if(item){
		// 			const temp = {
		// 				value: item.countryName,
		// 				label: item.countryName,
		// 				code: item.country
		//       }
		//       countryFilterData.push(temp);
		//     }
		//     })
		//     //citizenship logic
		//     list.activeCitizenship.map(item => {
		//         if(item){
		//         const temp = {
		//       		value: item.citizenshipName,
		//       		label: item.citizenshipName,
		//       		code: item.citizenship
		//         }
		//       citizenShipFilterData.push(temp);
		//       }
		//      })
		//     list.excludedCountry.map(item => {
		//       if(item){
		//       const temp = {
		//         value: item.countryName,
		//         label: item.countryName,
		//         code: item.country
		//       }
		//       countryListForSourceOfFunds.push(temp);
		//     }
		//    })
		//    countryListForSourceOfFunds = countryFilterData.concat(countryListForSourceOfFunds);
		//     this.setState({ countryList: countryFilterData, citizenshipList:citizenShipFilterData, countryListForSourceOfFunds },()=>{
		//       this.setPersonaldata();
		//     })
		// 	}
		// })
		//fetching citizenship list by country
		// const response = await getPartnerCountries('citizenship', details.country);
		// if (response && response.code === 200 && response.data) {
		//   const list = response.data;
		//     list.map(item => {
		//       if(item){
		//       const temp = {
		//         value: item.citizenshipName,
		//         label: item.citizenshipName,
		//         code: item.citizenship
		//       }
		//       citizenShipFilterData.push(temp);
		//     }
		//   })
		// }
		//fetching citizenship list by country
		// const citizenShipFilterData = await this.getCountryList('citizenship', details.country);
		//fetching country list by citizenship
		//  const countryFilterData = await this.getCountryList('residence', details.citizenship);
		//  const countryRes = await getPartnerCountries('residence', details.citizenship);
		//  if (countryRes && countryRes.code === 200 && countryRes.data) {
		//    const list = countryRes.data;
		//      list.map(item => {
		//        if(item){
		//        const temp = {
		//          value: item.countryName,
		//          label: item.countryName,
		//          code: item.country
		//        }
		//        countryFilterData.push(temp);
		//      }
		//    })
		//  }
		//fetching countryoffunds list
		// const countryListForSourceOfFunds = await this.getCountryList('sourceoffunds');
		// this.setState({ countryList: countryFilterData, citizenshipList:citizenShipFilterData, countryListForSourceOfFunds },()=>{
		// this.setPersonaldata();
		// })
	}

	redirect = async () => {
		const userInfo = sessionStorage.getItem('userInfo')
			? JSON.parse(sessionStorage.getItem('userInfo'))
			: {}
		if (
			userInfo &&
			(userInfo.nextStep === 'PERSONAL_DETAILS' ||
				userInfo.nextStep === 'ADDRESS' ||
				userInfo.nextStep === 'FINANCIAL_DETAILS' ||
        userInfo.nextStep === 'POLITICALLY_EXPOSED' ||
				userInfo.nextStep === 'LEGAL_DETAILS')
		)
			return
		else this.props.history.push('/home')
	}

  fetchAccountInfo = async () => {
     try{
       const res = await getAccountInfo()
       sessionStorage.setItem('userInfo', JSON.stringify(res))
     }catch(error){
      console.log('error',error)
     }
  }

	componentDidMount = () => {
		window.history.pushState(null, document.title, window.location.href)
		window.addEventListener('popstate', function (event) {
			window.history.pushState(null, document.title, window.location.href)
		})
    this.fetchAccountInfo()
		this.redirect()
    this.setCountryList()

		// if(this.props.location && this.props.location.state){
		//   let applicantsId = this.props.location.state.applicantsId
		//   console.log(applicantsId)
		//   this.extractDoc(applicantsId)
		// }
	}

	static contextType = ThemeContext

	openModal = () => {
		this.setState({ showModal: true })
	}

	closeModal = () => {
		this.setState({ showModal: false })
	}

	setFormPreData = data => {
		const updateFormflowState = Object.assign(this.state.formData, data)
		this.setState({
			formData: updateFormflowState
			// flag:false
		})
	}

	setFormData = data => {
		const updateFormflowState = {
			...this.state.formData,
			...data
		}
		this.setState({ formData: updateFormflowState }, () => {
			this.updateActiveForm()
		})
	}

	editForm = form => {
		const editArray = {
		'PERSONAL_DETAILS':'isEditPersonalDetail',
		'ADDRESS'	:'isEditAddressDetail',
		'FINANCIAL_DETAILS':	'isEditFinancialDetail',
		'POLITICALLY_EXPOSED':	'isEditPoliticallyExposed',
		'LEGAL_DETAILS':'isEditLegalDetail'
    }
		const isEditForm = editArray[form]
    Object.keys(editArray).map((item) => {
      const formedit = editArray[item]
      if(item != form){
        this.setState({[formedit]:''})
      }
    })
		this.setState({ [isEditForm]: form, activeForm: form })
	}

	updateActiveForm = () => {
		// const { formData } = this.state
		// let form = ''
		// const personalDetail = formData.personalDetail
		// const addressDetail = formData.addressDetail
		// const financialDetail = formData.financialDetail
		// const legalDetail = formData.legalDetail
		// if (Object.keys(personalDetail).length > 0) {
		// 	if (Object.keys(addressDetail).length > 0) {
		// 		if (Object.keys(financialDetail).length > 2) {
		// 			if (Object.keys(legalDetail).length > 0) {
		// 				form = formList[3]
		// 			} else {
		// 				form = formList[3]
		// 			}
		// 		} else {
		// 			form = formList[2]
		// 		}
		// 	} else {
		// 		form = formList[1]
		// 	}
		// } else {
		// 	form = formList[0]
		// }
		let form = null
		const details = sessionStorage.getItem('userInfo')
			? JSON.parse(sessionStorage.getItem('userInfo'))
			: ''
		if (details && details.nextStep) form = details.nextStep
		if (!form) form = this.state.activeForm
		this.setState({
			activeForm: form,
			// currentForm: form,
			isEditPersonalDetail: '',
			isEditAddressDetail: '',
			isEditFinancialDetail: '',
			isEditPoliticallyExposed: '',
			isEditLegalDetail: ''
		})
	}

	nextForm = data => {
		this.setFormData(data)
		// update countrycitizenship list
		if (data.personalDetail && data.personalDetail.citizenShipCode)
			this.setCountryByCitizenship(data.personalDetail.citizenShipCode)
	}

  updateFormValidation = data => {
    this.setState({formValidation:data})
  }

	sectionHeader = (section, details) => {
		// internal state management
		if (section === 'PERSONAL_DETAILS') {
			let headerData = ''
			if (details.firstName) headerData += details.firstName
			if (details.middleName) headerData += ' ' + details.middleName
			if (details.lastName) headerData += ' ' + details.lastName
			if (headerData) headerData += ', '
			if (details.phoneNumber) headerData += details.phoneNumber + ', '
			if (details.gender) headerData += details.gender + ', '
			if (details.dateOfBirth) headerData += details.dateOfBirth + ', '
			if (details.citizenShip) headerData += details.citizenShip
			return headerData
		}

		if (section === 'ADDRESS') {
			let headerData = ''
			if (details.addressLine1) headerData += details.addressLine1 + ', '
			if (details.addressLine2) headerData += details.addressLine2 + ', '
			if (details.country) headerData += details.country + ', '
			if (details.postalCode) headerData += details.postalCode + ', '
			if (details.state) headerData += details.state + ', '
			if (details.city) headerData += details.city
			return headerData
		}

		if (section === 'FINANCIAL_DETAILS') {
			let headerData = ''
			if (details.employmentStatus)
				headerData += details.employmentStatus + ', '
			if (details.panCard) headerData += details.panCard + ', '
			if (details.sourceOfFunds)
				headerData +=  details.sourceOfFunds + ', '
			if (details.investmentObject)
				headerData +=  details.investmentObject + ', '
			if (details.investmentExper)
				headerData +=  details.investmentExper + ', '
			if (details.riskTolerance)
				headerData +=  details.riskTolerance + ', '
			if (details.annualIncome)
				headerData +=  details.annualIncome + ', '
			if (details.netWorth)
				headerData +=  details.netWorth + ', '
			if (details.networthTotal)
				headerData +=  details.networthTotal
			return headerData
		}

		if (section === 'POLITICALLY_EXPOSED') {
			let headerData = ''
			if (details.politicallyExposedType) {
				headerData += details.politicallyExposedType
			}
			return headerData
		}

		if (section === 'LEGAL_DETAILS') {
			let headerData = ''
			// if(details.employmentStatus)
			//   headerData += details.employmentStatus+', ';
			if (details.companyName) headerData += details.companyName + ', '
			return headerData
		}
	}

	errorHandler = data => {
		let updatedValue = { ...this.state.errors, ...data }
		this.setState({ errors: updatedValue })
	}

	personalDetail = () => {
		const { activeForm, formData, isEditPersonalDetail } = this.state
		const personalDetail = formData.personalDetail

		return (
			<Card className={styles.cardAccount}>
				<Card.Body className={styles.cardBodyAccount}>
					<Row className={styles.Title}>
						1. Personal Details
						{this.state.errors.personalDetailError.error ? (
							<span>
								<img src={AccountError} style={{ marginLeft: '17px' }}></img>
							</span>
						) : (
							''
						)}
					</Row>
					{Object.keys(personalDetail).length > 0 &&
					// isEditPersonalDetail === '' ? (
            activeForm !== 'PERSONAL_DETAILS' ? (
						<>
							<Row className='m-0'>
								<Col className={styles.detail}>
									{/* {`
              ${personalDetail.firstName}, ${personalDetail.phoneNumber}, ${personalDetail.gender}, ${personalDetail.dateOfBirth}, ${personalDetail.citizenShip}
              `} */}
									{this.sectionHeader('PERSONAL_DETAILS', personalDetail)}
								</Col>
								<Col
									md={1}
									xs={2}
									className={`${styles.edit} ${styles.editButton}`}
									onClick={() => this.editForm('PERSONAL_DETAILS')}>
									Edit
								</Col>
							</Row>
						</>
					) : (
						''
					)}
					{activeForm === 'PERSONAL_DETAILS' ? (
						<PersonalDetail
							// setFormPreData={this.setFormPreData}
							nextForm={this.nextForm}
							formData={formData}
							citizenshipList={this.state.citizenshipList}
							changeCurrent={this.changeCurrent}
							errors={this.state.errors.personalDetailError}
							errorHandler={this.errorHandler}
							setCountryByCitizenship={this.setCountryByCitizenship}
              formValidation={this.state.formValidation}
              updateFormValidation={this.updateFormValidation}
              disabledValue={this.state.disabledValue}
						/>
					) : (
						''
					)}
				</Card.Body>
			</Card>
		)
	}

	addressDetail = () => {
		const { activeForm, formData, isEditAddressDetail } = this.state
		const addressDetail = formData.addressDetail
		return (
			<Card className={styles.cardAccount}>
				<Card.Body className={styles.cardBodyAccount}>
					<Row className={styles.Title}>
						2. Address
						{this.state.errors.addressDetailError.error ? (
							<span>
								<img src={AccountError} style={{ marginLeft: '17px' }}></img>
							</span>
						) : (
							''
						)}
					</Row>
					{/* try is set to disable first time prefill */}
					{Object.keys(addressDetail).length > 0 &&
					// isEditAddressDetail === '' ? (
            activeForm !== 'ADDRESS' ? (
						// {/* {Object.keys(addressDetail).length > 0  && isEditAddressDetail === '' && this.state.nextStep !== 'PERSONAL_DETAILS' && activeForm !== 'PERSONAL_DETAILS' ? ( */}
						// {Object.keys(addressDetail).length > 0  && isEditAddressDetail === '' && !sessionStorage.getItem('preSet') ? (
						<>
							<Row className='m-0'>
								<Col className={styles.detail}>
									{/* {`
                ${personalDetail.firstName}, ${personalDetail.phoneNumber}, ${personalDetail.gender}, ${personalDetail.dateOfBirth}, ${personalDetail.citizenShip}
                `} */}
									{this.sectionHeader('ADDRESS', addressDetail)}
								</Col>
								<Col
									md={1}
									xs={2}
									className={`${styles.edit} ${styles.editButton}`}
									onClick={() => this.editForm('ADDRESS')}>
									Edit
								</Col>
							</Row>
						</>
					) : (
						''
					)}
					{activeForm === 'ADDRESS' ? (
						<Address
							nextForm={this.nextForm}
							setFormPreData={this.setFormPreData}
							formData={formData}
							changeCurrent={this.changeCurrent}
							countryList={this.state.countryList}
							errors={this.state.errors.addressDetailError}
							errorHandler={this.errorHandler}
              formValidation={this.state.formValidation}
              updateFormValidation={this.updateFormValidation}
						/>
					) : (
						''
					)}
				</Card.Body>
			</Card>
		)
	}

	financialDetail = () => {
		const { activeForm, formData, isEditFinancialDetail } = this.state
		const financialDetails = formData.financialDetail
		return (
			<Card className={styles.cardAccount}>
				<Card.Body className={styles.cardBodyAccount}>
					<Row className={styles.Title}>
						3. Financial Details
						{this.state.errors.financialDetailError.error ? (
							<span>
								<img src={AccountError} style={{ marginLeft: '17px' }}></img>
							</span>
						) : (
							''
						)}
					</Row>
					{Object.keys(financialDetails).length > 0 &&
					// isEditFinancialDetail === '' ? (
            activeForm !== 'FINANCIAL_DETAILS' ? (
						<>
							<Row className='m-0'>
								<Col className={`${styles.detail} ${styles.financialWrap}`}>
									{/* {`${financialDetail.employmentStatus}, ${financialDetail.panCard}, Objective: ${financialDetail.investmentObject}, Experience: ${financialDetail.investmentExper}, Risk Tolerance: ${financialDetail.riskTolerance}, Annual Income: ${financialDetail.annualIncome}, Net Worth: ${financialDetail.netWorth}, Total Worth: ${financialDetail.networthTotal}`} */}
									{this.sectionHeader('FINANCIAL_DETAILS', financialDetails)}
								</Col>
								<Col
									md={1}
									xs={2}
									className={`${styles.edit} ${styles.editButton}`}
									onClick={() => this.editForm('FINANCIAL_DETAILS')}>
									Edit
								</Col>
							</Row>
						</>
					) : (
						''
					)}
					{activeForm === 'FINANCIAL_DETAILS' ? (
						<FinancialDetail
							setFormPreData={this.setFormPreData}
							nextForm={this.nextForm}
							formData={formData}
							countryListForSourceOfFunds={
								this.state.countryListForSourceOfFunds
							}
							changeCurrent={this.changeCurrent}
							errors={this.state.errors.financialDetailError}
							errorHandler={this.errorHandler}
              formValidation={this.state.formValidation}
              updateFormValidation={this.updateFormValidation}
              disabledValue={this.state.disabledValue}
						/>
					) : (
						''
					)}
				</Card.Body>
			</Card>
		)
	}

	politicallyExposedDetail = () => {
		const { activeForm, formData, isEditPoliticallyExposed } = this.state
		const politicallyExposed = formData.politicallyExposed
		return (
			<Card className={styles.cardAccount}>
				<Card.Body className={styles.cardBodyAccount}>
					<Row className={styles.Title}>4. Politically Exposed?</Row>
					{Object.keys(politicallyExposed).length > 0 &&
					// isEditPoliticallyExposed === '' ? (
            activeForm !== 'POLITICALLY_EXPOSED' ? (
						<>
							<Row className='m-0'>
								<Col className={`${styles.detail} ${styles.financialWrap}`}>
									{/* {`${financialDetail.employmentStatus}, ${financialDetail.panCard}, Objective: ${financialDetail.investmentObject}, Experience: ${financialDetail.investmentExper}, Risk Tolerance: ${financialDetail.riskTolerance}, Annual Income: ${financialDetail.annualIncome}, Net Worth: ${financialDetail.netWorth}, Total Worth: ${financialDetail.networthTotal}`} */}
									{this.sectionHeader('POLITICALLY_EXPOSED', politicallyExposed)}
								</Col>
								<Col
									md={1}
									xs={2}
									className={`${styles.edit} ${styles.editButton}`}
									onClick={() => this.editForm('POLITICALLY_EXPOSED')}>
									Edit
								</Col>
							</Row>
						</>
					) : (
						''
					)}
					{activeForm === 'POLITICALLY_EXPOSED' ? (
						<PoliticallyExposed
							setFormPreData={this.setFormPreData}
							nextForm={this.nextForm}
							formData={formData}
							changeCurrent={this.changeCurrent}
							errors={this.state.errors.politicallyExposedDetailError}
							errorHandler={this.errorHandler}
              formValidation={this.state.formValidation}
              updateFormValidation={this.updateFormValidation}
						/>
					) : (
						''
					)}
				</Card.Body>
			</Card>
		)
	}

	legalDetail = () => {
		const { activeForm, formData, isEditLegalDetail } = this.state
		const legalDetail = formData.legalDetail
		return (
			<Card className={styles.cardAccount}>
				<Card.Body className={styles.cardBodyAccount}>
					<Row className={styles.Title}>
						5. Legal Details
						{this.state.errors.legalDetailError.error ? (
							<span>
								<img src={AccountError} style={{ marginLeft: '17px' }}></img>
							</span>
						) : (
							''
						)}
					</Row>
					{Object.keys(legalDetail).length > 0 && activeForm !== 'LEGAL_DETAILS' ? (
						<>
							<Row className='m-0'>
								<Col className={`${styles.detail} ${styles.financialWrap}`}>
									{/* {`${financialDetail.employmentStatus}, ${financialDetail.panCard}, Objective: ${financialDetail.investmentObject}, Experience: ${financialDetail.investmentExper}, Risk Tolerance: ${financialDetail.riskTolerance}, Annual Income: ${financialDetail.annualIncome}, Net Worth: ${financialDetail.netWorth}, Total Worth: ${financialDetail.networthTotal}`} */}
								</Col>
								<Col
									md={1}
									xs={2}
									className={`${styles.edit} ${styles.editButton}`}
									onClick={() => this.editForm('LEGAL_DETAILS')}>
									Edit
								</Col>
							</Row>
						</>
					) : (
						''
					)}
					{activeForm === 'LEGAL_DETAILS' ? (
						<LegalDetail
							setFormPreData={this.setFormPreData}
							nextForm={this.nextForm}
							formData={formData}
							changeCurrent={this.changeCurrent}
							onRef={ref => {
								this.setState({ ref })
							}}
							errors={this.state.errors.legalDetailError}
              formValidation={this.state.formValidation}
              updateFormValidation={this.updateFormValidation}
						/>
					) : (
						''
					)}
				</Card.Body>
			</Card>
		)
	}

	onCompleteErrorHandling = message => {
		let errors = {
			personalDetailError: {
				firstNameError: '',
				lastNameError: '',
				phoneNumberError: '',
				dateOfBirthError: '',
				errorMessage: '',
				error: false
			},
			addressDetailError: {
				addressLine1Error: '',
				addressLine2Error: '',
				postalCodeError: '',
				countryError: '',
				cityError: '',
				stateError: '',
				errorMessage: '',
				error: false
			},
			financialDetailError: {
				pancardError: '',
				errorMessage: '',
				error: false
			},
			legalDetailError: {
				errorMessage: '',
				error: false
			}
		}
		if (message.includes('firstName')) {
			errors.personalDetailError = {
				...errors.personalDetailError,
				firstNameError: 'first name is missing',
				error: true
			}
		} else if (message.includes('lastName')) {
			errors.personalDetailError = {
				...errors.personalDetailError,
				lastNameError: 'last name is missing',
				error: true
			}
    }
	else if (message.includes('phone')) {
			errors.personalDetailError = {
				...errors.personalDetailError,
				phoneNumberError: 'phone number is missing',
				error: true
			}
		}
    else if (message.includes('dob')) {
			errors.personalDetailError = {
				...errors.personalDetailError,
				dateOfBirthError: 'date of birth is missing',
				error: true
			}
		}
    else if (message.includes('gender')) {
			errors.personalDetailError = {
				...errors.personalDetailError,
				errorMessage: 'gender is missing',
				error: true
			}
		} else if (message.includes('address1')) {
			errors.addressDetailError = {
				...errors.addressDetailError,
				addressLine1Error: 'address is missing',
				error: true
			}
		} else if (message.includes('address2')) {
			errors.addressDetailError = {
				...errors.addressDetailError,
				addressLine2Error: 'address is missing',
				error: true
			}
		} else if (message.includes('postal')) {
			errors.addressDetailError = {
				...errors.addressDetailError,
				postalCodeError: 'postal code is missing',
				error: true
			}
		} else if (message.includes('country')) {
			errors.addressDetailError = {
				...errors.addressDetailError,
				countryError: 'country is missing',
				error: true
			}
		} else if (message.includes('city')) {
			errors.addressDetailError = {
				...errors.addressDetailError,
				cityError: 'city is missing',
				error: true
			}
		} else if (message.includes('state')) {
			errors.addressDetailError = {
				...errors.addressDetailError,
				stateError: 'state is missing',
				error: true
			}
		} else if (message.includes('idNo')) {
			errors.financialDetailError = {
				...errors.financialDetailError,
				pancardError: 'id is missing',
				error: true
			}
    }else if (message.includes('PAN')) {
        errors.financialDetailError = {
          ...errors.financialDetailError,
          pancardError: message,
          error: true
        }
		} else if (message.includes('employmentStatus')) {
			errors.financialDetailError = {
				...errors.financialDetailError,
				errorMessage: 'employment status is missing',
				error: true
			}
		} else if (message.includes('employment company')) {
			errors.financialDetailError = {
				...errors.financialDetailError,
				errorMessage: 'employment company is missing',
				error: true
			}
		} else if (message.includes('employerCompany')) {
			errors.financialDetailError = {
				...errors.financialDetailError,
				errorMessage: 'employee company is missing',
				error: true
			}
		} else if (message.includes('employerBusiness')) {
			errors.financialDetailError = {
				...errors.financialDetailError,
				errorMessage: 'employment type is missing',
				error: true
			}
		} else if (message.includes('employmentPosition')) {
			errors.financialDetailError = {
				...errors.financialDetailError,
				errorMessage: 'employment position is missing',
				error: true
			}
		} else if (message.includes('networthLiquid')) {
			errors.financialDetailError = {
				...errors.financialDetailError,
				errorMessage: 'net worth liquid is missing',
				error: true
			}
		} else if (message.includes('investmentExperience')) {
			errors.financialDetailError = {
				...errors.financialDetailError,
				errorMessage: 'Investment Experience is missing',
				error: true
			}
		} else if (message.includes('investmentObjectives')) {
			errors.financialDetailError = {
				...errors.financialDetailError,
				errorMessage: 'Investment Objectives is missing',
				error: true
			}
		} else if (message.includes('networthTotal')) {
			errors.financialDetailError = {
				...errors.financialDetailError,
				errorMessage: 'Net worth total is missing',
				error: true
			}
		} else if (message.includes('annualIncome')) {
			errors.financialDetailError = {
				...errors.financialDetailError,
				errorMessage: 'annual income is missing',
				error: true
			}
		} else if (message.includes('riskTolerance')) {
			errors.financialDetailError = {
				...errors.financialDetailError,
				errorMessage: 'risk tolerance is missing',
				error: true
			}
		} else {
			errors.legalDetailError = {
				...errors.legalDetailError,
				errorMessage: message,
				error: true
			}
		}
		this.setState({ errors: errors })
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
		// let legalDetailError= {
		//   errorMessage:'',
		//   error:false
		// }
		// legalDetailError = {...legalDetailError,errorMessage:message,error:true}
		// this.errorHandler({legalDetailError})
	}


  onVerifyPan = async () => {
    this.setState({ loading: true })
    let message = ''
    const userInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : {}
    const countryCode = userInfo.countryID ? userInfo.countryID : (userInfo.country ? userInfo.country :'')
    // const panCard = userInfo.idNo ? userInfo.idNo : ''
    if(countryCode === 'IND') {
      try{
        if(userInfo){
            const resPan = await getPAN()
            const panCard = resPan && resPan.data && resPan.data.data && resPan.data.data.idNo ? resPan.data.data.idNo : ''
            const fullname= `${userInfo.firstName} ${userInfo.middleName} ${userInfo.lastName}`
            // dob format 1977-09-26
            const dobArr = userInfo.dob.split('-');
            const dob = dobArr.length === 3 ? dobArr[2]+'/'+dobArr[1]+'/'+dobArr[0] : format(new Date(userInfo.dob), "dd/MM/yyyy");
            // const dob=format(new Date(userInfo.dob), "dd/MM/yyyy")
            const res= await verifyPAN({pan:panCard.toUpperCase(), name:fullname, dob})
            if(res.data && res.data.code === 200){
              return true
            }else{
              if(res.data && res.data.message){
                message = res.data.message
              }else{
                message = 'Something went wrong'
              }
          }
         }
        if(message){
          let error = {
            ...this.state.errors,
            financialDetailError: {
              pancardError: message,
              errorMessage: '',
              error: true
            }
          }
          this.setState({errors:error})
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            })
        }
      this.setState({ loading: false })
    }catch(error){
      this.setState({ loading: false })
      console.log('error',error)
    }
  }
  else{
    return true
  }
  }

	onComplete = async() => {
    const {
      leadSqCompID
		} = this.context
    const getCashAccount = JSON.parse(localStorage.getItem('isCashAccount')) ? JSON.parse(localStorage.getItem('isCashAccount')) : false
    const isSavingAccount = JSON.parse(sessionStorage.getItem('savingAccount')) ? JSON.parse(sessionStorage.getItem('savingAccount')) : false
    const details = sessionStorage.getItem('userInfo')
    ? JSON.parse(sessionStorage.getItem('userInfo'))
    : ''
  	const countryCode = details.country
					? details.country
					: ''
		let legalDetailError = {
			errorMessage: '',
			error: false
		}
		this.setState({ errorMessage: '' })
		this.errorHandler({ legalDetailError })
		const { companyName, isBrokerChecked, isUsTaxPayerChecked, employerCompany } =
			this.state.formData.legalDetail
		const { signature } = this.state
		const returnType = this.state?.ref?.onFormSubmit()
		if (signature === '') {
			this.setState({ errorMessage: 'Please type your first or last name' })
			return false
		}
		const {
			formData: { personalDetail: { firstName = '', lastName = '' } } = {}
		} = this.state
		const trimSignature = signature.trim()
		let fullname = firstName.trim() + ' ' + lastName.trim()
		fullname = fullname.replace(/\s\s+/g, ' ')
		let signatureLowercase = trimSignature.replace(/\s\s+/g, ' ')
		fullname = fullname.toLowerCase()
		signatureLowercase = signatureLowercase.toLowerCase()
		let fullnameEachWord = fullname.split(' ')
		let signatureEachWord = signatureLowercase.split(' ')
		let ret = []
		for (let i in fullnameEachWord) {
			if (signatureEachWord.indexOf(fullnameEachWord[i]) > -1) {
				ret.push(fullnameEachWord[i])
			}
		}
		if (ret.length === 0) {
			this.setState({ errorMessage: 'Please type your first or last name' })
			return false
		}
    	// const verifyPanStatus = await this.onVerifyPan()
		// if (verifyPanStatus && (returnType || ret.length > 0)) {
			let data = {
				directorOf: companyName ? companyName : details && details.directorOf ? details.directorOf : '' ,
        		employerCompany:employerCompany ? employerCompany : details && details.employerCompany ? details.employerCompany : '',
				usTaxPayer: isUsTaxPayerChecked ? isUsTaxPayerChecked : details && details.usTaxPayer ? details.usTaxPayer : false,
				isBroker: isBrokerChecked ? isBrokerChecked : details && details.employerIsBroker ?  details.employerIsBroker : false ,
				signedBy: signature.trim(),
				termsOfUse: true,
				iraAgreement: true,
				rule14b: true,
				customerAgreement: true,
				privacyPolicy: true,
				marketDataAgreement: true,
				createBrokerProfile: true,
				extendedHoursAgreement: true,
				nextStep: 'COMPLETE'
				// nextStep: 'CHOOSE_YOUR_PLAN'
			}
			this.setState({ loading: true })
			updatedAccount(data)
				.then(res => {
					this.setState({ loading: false })

					if (res.code === 200) {
						// eslint-disable-next-line no-undef
						webengage.track('Account Created')
             if(isSavingAccount && getCashAccount){
              // eslint-disable-next-line no-undef
              webengage.track('cash account created')
             }

             const LeadData = {
              searchBy: 'email',
              relatedCompanyId: leadSqCompID,
              ProspectStage:'KYC submitted',
              email: details.emailAddress1,
              phone: details.phoneHome,
              name:details.username
            }
            LeadCapture(LeadData)

						//redirect to home page
						this.props.history.push('/home', { showPlanPopUp: true })
					}
				})
				.catch(error => {
					this.setState({ loading: false })
					this.onCompleteErrorHandling(error.message)
				})
		// }
	}

	// uploadDocument = () => {
	// 	const { activeForm, formData } = this.state
	// 	return (
	// 		<div className='mb-5'>
	// 			<Row className={styles.Title}>Upload Documents</Row>
	// 			{activeForm === 'DOCUMENTS' ? (
	// 				<UploadDocument nextForm={this.nextForm} formData={formData} />
	// 			) : (
	// 				''
	// 			)}
	// 		</div>
	// 	)
	// }
	SaveExit = () => {
		this.props.history.push('/home')
	}

	changeCurrent = formName => {
		if (formName === 'FINANCIAL_DETAILS') {
			//set country
			let financialDetail = this.state.formData.financialDetail
			const details = sessionStorage.getItem('userInfo')
				? JSON.parse(sessionStorage.getItem('userInfo'))
				: ''
			const sourceOfFundsCode =
				details.employerCountryID || details.employerCountryID === ''
					? details.employerCountryID
					: details.country
					? details.country
					: ''
			// const sourceOfFundsCode = details.employerCountryID ? details.employerCountryID : '';
			if (sourceOfFundsCode) {
				const index = this.state.countryListForSourceOfFunds.findIndex(
					item => item.code === sourceOfFundsCode
				)
				if (index !== -1) {
					financialDetail.sourceOfFundsCode = sourceOfFundsCode
					financialDetail.sourceOfFunds =
						this.state.countryListForSourceOfFunds[index].value
          financialDetail.flagCode = this.state.countryListForSourceOfFunds[index].code2
				}
			} else {
				financialDetail.sourceOfFundsCode = ''
				financialDetail.sourceOfFunds = ''
			}
			const updateFormflowState = {
				...this.state.formData,
				financialDetail
			}
			this.setState({ formData: updateFormflowState })
		}
		this.setState({ activeForm: formName })
	}

  getDefaultValues = () => {
    const {firstName = '',middleName ='',lastName = '',dateOfBirth=''} = this.state.formData.personalDetail
    const fullName = middleName !== '' ? `${firstName} ${middleName} ${lastName}` : `${firstName} ${lastName}`
    const userInfo = sessionStorage.getItem('userInfo')
                    ? JSON.parse(sessionStorage.getItem('userInfo'))
                    : {}
     return(
       <div style={{marginTop:'32px'}}>
        <Row style={{paddingRight:'5px',paddingLeft:'5px'}}>
          {fullName &&
          <Col md={4} xs={12} className={styles.mobileMargin}>
            <div className={styles.greyBox}>
              <div className={styles.greyBoxTitle}>Official Full Name</div>
              <div className={styles.greyBoxSubTitle}>{fullName}</div>
            </div>
          </Col>}
          <Col md={4} xs={12} className={styles.mobileMargin}>
            <div className={styles.greyBox}>
              <div className={styles.greyBoxTitle}>Permanent Account Number</div>
              <div className={styles.greyBoxSubTitle}>{userInfo?.idNo}</div>
           </div>
          </Col>
          <Col md={4} xs={12} className={styles.mobileMargin}>
            <div className={styles.greyBox}>
              <div className={styles.greyBoxTitle}>Date of Birth</div>
              <div className={styles.greyBoxSubTitle}>{dateOfBirth}</div>
            </div>
          </Col>
        </Row>
        {/* <Row style={{marginTop:'20px'}}>
          <Col>
            <div className={styles.greyBox}>
              <div className={styles.greyBoxTitle}>Official Residence Address</div>
              <div className={styles.greyBoxSubTitle}>D-001, Pamela Palms, Mazal Waddo, Anjuna, North Goa, Goa - 403509, India</div>
            </div>
          </Col>
        </Row> */}
       </div>
     )
  }

	render() {
		const { siteLogo, shortLogo } = this.context
		const { activeForm ,formValidation} = this.state
    const {personalDetail,addressDetail,financialDetail,politicallyExposed} = this.state.formData
		const {
			disclosuresLink,
			termsAndConditionsLink,
			privacyPolicyLink,
			userAgreementLink,
      savingAccountDisclaimer
		} = this.context
    const isSavingAccount = JSON.parse(sessionStorage.getItem('savingAccount')) ? JSON.parse(sessionStorage.getItem('savingAccount')) : false
		let updatedDisclosuresLink = disclosuresLink
		if (disclosuresLink === '') {
			// updatedDisclosuresLink = `https://apps.drivewealth.com/disclosures/?citizenship=${this.state.formData.personalDetail.citizenShip}&country=${this.state.formData.addressDetail.country}&accountType=Individual&extendedHours=true`
			updatedDisclosuresLink = 'https://stockal.freshdesk.com/support/solutions/articles/43000739090-drivewealth-s-terms-and-conditions'
		}
    const getCashAccount = JSON.parse(localStorage.getItem('isCashAccount')) ? JSON.parse(localStorage.getItem('isCashAccount')) : false
    const details = sessionStorage.getItem('userInfo')
    ? JSON.parse(sessionStorage.getItem('userInfo'))
    : ''
  	const countryCode = details.country
					? details.country
					: ''
    const showCompletesetup = Object.keys(personalDetail).length > 0 &&
                              Object.keys(addressDetail).length > 0 &&
                              Object.keys(financialDetail).length > 0 &&
                              Object.keys(politicallyExposed).length > 0 ? true : false
    const disableButton = formValidation.personalDetail
                           && formValidation.addressDetail
                           && formValidation.financialDetail
                           && formValidation.politicallyExposedDetail ? false : true
		return (
			<>
      <div>
       <NewHeader
          message=''
          linkName='Save & Exit'
          onClickLink={this.SaveExit}
       />
       </div>
				{/* <div className={styles.header}>
					<Container className={styles.containerWidth}>
						<Row>
							<Col className={styles.brand}>
								<span>
									<img
										src={siteLogo}
										className={styles.brandImg}
										//  alt="sitelogo"
									></img>
								</span>
							</Col>
							<Col className={styles.save} xs='auto' onClick={this.SaveExit}>
								Save & Exit
							</Col>
						</Row>
					</Container>
				</div> */}

				<Container className={styles.containerWidth}>
					<Row className={styles.accountTitle}>Set up your account</Row>
					<Row className={styles.subTitle}>
						You’re just a few steps away. This will take up to 10 minutes.
					</Row>
					{this.state.isSkeleton ? (
						<AccountSetupSkeleton />
					) : (
						<>
              {this.state.disabledValue && countryCode === 'IND' ? this.getDefaultValues() : ''}
							{this.personalDetail()}
							{this.addressDetail()}
							{this.financialDetail()}
							{this.politicallyExposedDetail()}
							{this.legalDetail()}
							{/* {this.uploadDocument()} */}
						</>
					)}
					<div style={{paddingLeft:'10px'}}>
						<Row className={styles.signatureDiv}>
							<Col className={styles.colPaddningMobile} md={6} xl={6} xs={12}>
								<SignUpInput
									label='Your Signature'
									placeholder='Enter Your Signature'
									type='text'
									name='signature'
									value={this.state.signature}
									onChange={e => this.setState({ signature: e.target.value })}
								/>
								<div className={styles.signatureText}>
								Please enter your first or last name as your signature.
								</div>
								<span className={styles.errorMessage}>
									{this.state.errorMessage ||
										this.state.errors.legalDetailError.errorMessage}
								</span>
							</Col>
							<Col></Col>
						</Row>
						<Row className={styles.disclouresDetail} style={{display:'block'}}>
              <p style={{ marginBottom: '8px', fontFamily: 'geomanistBook', color:'#767C8F' }}>By providing my signature:</p>
							<p style={{ marginBottom: '8px' }}>
								I have read, understood and agree to DriveWealth’s{' '}
								<a
                  tabIndex='-1'
									href={updatedDisclosuresLink}
									target='_blank'
									rel='noopener noreferrer'>
									<span className={styles.disclosures}>Terms & Conditions{details&&details['fpslProhibited']&&'.'}</span>
								</a>
								{details&&!details['fpslProhibited']&&<>
									{' '}
									and Fully Paid Securities Lending{' '}
									<a
										tabIndex='-1'
										href='https://stockal.freshdesk.com/support/solutions/articles/43000690845-fully-paid-securities-lending-disclosure'
										target='_blank'
										rel='noopener noreferrer'
										className={styles.disclosures}>
										Disclosures.
									</a>
								</>}
							</p>
							<p style={{ marginBottom: '8px' }}>
								I have read, understood and agree to
								the Terms & Conditions for Borderless Investing’s{' '}
								<span>
									<a
                    tabIndex='-1'
										href={userAgreementLink}
										target='_blank'
										rel='noopener noreferrer'
										className={styles.disclosures}>
										Investment Account
										{(!isSavingAccount || !getCashAccount) &&'.'}
									</a>
                  {!isSavingAccount || !getCashAccount ? '' :
                  <>
                  {' '}
									&{' '}
									<a
                    tabIndex='-1'
										href={savingAccountDisclaimer}
										target='_blank'
										rel='noopener noreferrer'
										className={styles.disclosures}>
										Cash Account.
									</a>
                  </>
                }
                </span>
							</p>
							<p style={{marginBottom:"16px"}}>
								I authorize my broker and/or clearing
								firm to...{' '}
								<a
									href='https://stockal.freshdesk.com/support/solutions/articles/43000690721-broker-disclaimer'
									target='_blank'
									rel='noopener noreferrer'>
									{' '}
									<span className={styles.disclosures}>Read Details</span>
								</a>
							</p>
							{/* <p style={{ marginBottom: '8px' }}>
								I authorize my broker and/or clearing
								firm to...{' '}
								<a
                  tabIndex='-1'
									href='https://stockal.freshdesk.com/support/solutions/articles/43000690721-broker-disclaimer'
									target='_blank'
									rel='noopener noreferrer'>
									{' '}
									<span className={styles.disclosures}>Read Details</span>
								</a>
							</p> */}
						</Row>
					</div>
				</Container>

				{progressList.map((listItem, index) => {
					if (listItem.form === activeForm) {
						return (
							<>
								<div className={styles.footer} >
									<div className={styles.progress} key={index}>
										<div
											className={styles.complete}
											style={{ width: listItem.percentage }}></div>
									</div>
									<Container className={styles.containerWidth} >
										<Row>
											<Col md={8} xs={4}>
												<div className={styles.progressTitle}>
													{listItem.title}
												</div>
												<div className={styles.progressQuote}>
													{/* {listItem.form === 'ADDRESS'
														? `${listItem.quote}${formData.personalDetail.firstName}`
														: listItem.form === 'FINANCIAL_DETAILS'
														? `${formData.addressDetail.state}${listItem.quote}`
														: listItem.quote} */}
                            {listItem.quote}
												</div>
											</Col>
											<Col className={styles.right} md={4} xs={7} style={{maxWidth:'fit-content'}}>
												{
                         showCompletesetup ?
													<SignUpButton
														title='Complete Set Up'
                            loading={this.state.loading}
                            disabled={disableButton}
														// classes={styles.payButton}
														onClick={this.onComplete}
													/> : ''
												}
											</Col>
										</Row>
									</Container>
								</div>
							</>
						)
					}
					return
				})}
			</>
		)
	}
}

export default AccountSetUp
